import {NextApiResponse} from 'next';
import {HttpError} from 'error';
import chalk from 'chalk';
import {ErrorCode, errorCodes, httpErrorCode} from 'utils/errorCodes';
import createLogger from 'utils/logger';

const logger = createLogger('errorUtils');

export function logError(e: HttpError): void {
  console.log(chalk.blue`${new Date().toISOString()}`, chalk.red`error`, e.statusText, e.message);
  logger.error(e);
}

export function errorResponse(res: NextApiResponse, e: HttpError): void {
  switch (true) {
    case e instanceof HttpError:
      return res.status(e.status ?? 500).json({
        status: e.status,
        message: e.statusText !== errorCodes.BAD_REQUEST.code ? e.message : '',
        statusText: e.statusText
      });
    default: {
      return res
        .status(errorCodes.INTERNAL_SERVER_ERROR.status)
        .json(
          new HttpError(
            errorCodes.INTERNAL_SERVER_ERROR.message,
            errorCodes.INTERNAL_SERVER_ERROR.status,
            errorCodes.INTERNAL_SERVER_ERROR.code
          )
        );
    }
  }
}

export const HttpServerError = (
  errorMessage: string = errorCodes.INTERNAL_SERVER_ERROR.message,
  errorStatus: number = errorCodes.INTERNAL_SERVER_ERROR.status,
  errorCode: string = errorCodes.INTERNAL_SERVER_ERROR.code
): HttpError => new HttpError(errorMessage, errorStatus, errorCode);

export const HttpClientError = (
  errorMessage: string = errorCodes.BAD_REQUEST.message,
  errorStatus: number = errorCodes.BAD_REQUEST.status,
  errorCode: string = errorCodes.BAD_REQUEST.code
): HttpError => new HttpError(errorMessage, errorStatus, errorCode);

export const HttpErrorFromCode = (error: ErrorCode): HttpError => {
  const [message, status, code] = httpErrorCode(error);
  return new HttpError(message, status, code);
};
