import jwt from 'jsonwebtoken';
import {SERVER_ENV} from 'utils/env/getter';
import {decrypt} from 'utils/crypto';
import ISession from 'types/ISession';
import SessionData from 'types/SessionData';
import DecodedUser from 'types/User';

export const getUserFromJwt = (jwtToken: string): DecodedUser => {
  const data = jwt.verify(jwtToken, SERVER_ENV.JWTSecret) as ISession;
  const user = (data as unknown as SessionData)?.user as DecodedUser;
  user.email = (data as unknown as SessionData).email;
  return user;
};

export const getJwtFromSession = (session: string): string => decrypt(session);
