import {Flex} from '@chakra-ui/react';
import React, {FC} from 'react';
import {Header} from 'components/Header/Header';
import {Footer} from 'components/Footer/Footer';
import {useRouter} from 'next/router';
import SEO from 'components/SEO/SEO';
import OriginTrackerProvider from 'context/OriginContext';

type ChakraPropTypes = {
  children?: React.ReactNode;
};

export const Layout: FC<React.PropsWithChildren<ChakraPropTypes>> = ({children}) => {
  const router = useRouter();
  const {slug} = router.query;
  return (
    <>
      <OriginTrackerProvider>
        <SEO title={(slug as string) ?? 'Course Home'} />

        <Flex flexDir="column" minHeight="100vh">
          <Header />
          <Flex flexDir="column" flexGrow={1}>
            {children}
          </Flex>
          <Footer />
        </Flex>
      </OriginTrackerProvider>
    </>
  );
};

export default Layout;
