import {HttpError} from 'error';
import {
  GetUserDocument,
  GetUserQuery,
  GetUserQueryVariables,
  Organizations,
  SetUserMetaDocument,
  SetUserMetaMutation,
  SetUserMetaMutationVariables
} from 'generated/graphql';
import {createApolloClient} from 'lib/apolloClient';
import ISession from 'types/ISession';
import SelectedOrg from 'types/SelectedOrg';
import User from 'types/User';
import {NextApiRequest, NextApiResponse} from 'next';
import ICookies from 'cookies';
import jwt from 'jsonwebtoken';
import {decrypt, encrypt} from 'utils/crypto';
import SessionData from '../types/SessionData';
import {sessionApi, signOutApi} from './apiFactory';
import {formatInsertInstant, formatUserJoinDateToDateOnly} from 'utils/dates/formatUserJoinDate';
import {modifyToken} from './jwt';
import {UserAbilities} from 'lib/userAbilities';

export interface UserUpdatedMetaData {
  joinDate?: string;
  selectedOrg: SelectedOrg;
}

export function getUserEmailFromSession(
  req: NextApiRequest,
  res: NextApiResponse
): string | undefined {
  try {
    const cookies = new ICookies(req, res);
    const session = cookies.get('sia-session');
    const decoded = jwt.decode((session && decrypt(session)) || '') as SessionData;
    return decoded?.email;
  } catch (e) {
    return undefined;
  }
}

export const signOut = async (): Promise<Response> => fetch(signOutApi());

export const getSession = async (): Promise<ISession> =>
  fetch(sessionApi()).then((res: Response) => res.json());

export const getUserMetaData = async (token: string, userId: string) => {
  const apolloClient = createApolloClient({token});

  const {data, error} = await apolloClient.query<GetUserQuery, GetUserQueryVariables>({
    query: GetUserDocument,
    variables: {
      userId: userId
    }
  });

  if (error) {
    throw new HttpError('Unable to fetch user meta data: ' + error, 500);
  }

  const userMeta = data.user?.meta;

  return userMeta;
};

export const getAndUpdateUserMetaData = async (
  token: string,
  user: User,
  locale?: string
): Promise<UserUpdatedMetaData> => {
  const apolloClient = createApolloClient({token});

  const {data, error} = await apolloClient.query<GetUserQuery, GetUserQueryVariables>({
    query: GetUserDocument,
    variables: {
      userId: user.id
    }
  });

  if (error) {
    throw new HttpError('Unable to fetch user data: ' + error, 500);
  }

  const orgs = data.user?.organizationUsers;

  if (!orgs || orgs?.length === 0) {
    throw new HttpError('User does not belong to any organizations', 500);
  }

  let selectedOrgIdFromUser: string | undefined = undefined;

  if (data.user?.meta) {
    selectedOrgIdFromUser = data.user?.meta.selectedOrgId;
  }

  const foundSelectedOrg =
    selectedOrgIdFromUser && orgs.find((org) => org.organization?.id === selectedOrgIdFromUser);

  const selectedOrg = foundSelectedOrg
    ? (foundSelectedOrg.organization as Organizations) // use the selected org
    : (orgs[0].organization as Organizations); // use the first org

  const browserLocale = locale?.split(',')?.[0]?.trim() ?? 'en-US';

  const userJoinDate = data.user?.meta?.joinDate ?? formatInsertInstant(user.insertInstant);

  let userJoinDateReturned: string | undefined;

  try {
    const {data} = await apolloClient.mutate<SetUserMetaMutation, SetUserMetaMutationVariables>({
      mutation: SetUserMetaDocument,
      variables: {
        object: {
          userId: user.id,
          selectedOrgId: selectedOrg.id,
          locale: browserLocale,
          joinDate: userJoinDate
        }
      }
    });
    userJoinDateReturned = data?.insertUsersMetaOne?.joinDate;
  } catch (error) {
    throw error;
  }

  return {
    // creditModel: selectedOrg.meta?.creditModel ?? null,
    joinDate: userJoinDateReturned,
    selectedOrg: {
      id: selectedOrg.id,
      name: selectedOrg.name,
      creditModel: selectedOrg.meta?.creditModel ?? null
    }
  };
};

export async function enhanceAndEncryptToken(token: string, user: User, locale?: string) {
  const {joinDate, selectedOrg} = await getAndUpdateUserMetaData(token, user, locale);
  user.selectedOrg = selectedOrg;

  // User abilities Apollo request needs token to have an org associated with the user
  const tokenWithUserOrg = modifyToken(token, user);
  const userAbilities = new UserAbilities(tokenWithUserOrg, user.id, selectedOrg.id);
  const abilities = await userAbilities.getAllAbilities();

  user.abilities = {
    teamsReport: abilities.can('view', 'MyTeamPage'),
    allMembersReport: abilities.can('view', 'AllMembersReports'),
    editTeamEnrollments: abilities.can('edit', 'TeamEnrollments'),
    coachDashboard: abilities.can('view', 'CoachDashboard'),
    selfEnroll: abilities.can('self-enroll', 'Cohort'),
    canImpersonate: abilities.can('impersonate', 'User')
  };

  user.joinDate = formatUserJoinDateToDateOnly(joinDate);

  const tokenWithAbilities = modifyToken(tokenWithUserOrg, user);
  const encryptedToken = encrypt(tokenWithAbilities);

  return encryptedToken;
}
