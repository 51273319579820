import {
  GetCoachForUserDocument,
  GetCoachForUserQuery,
  GetCoachForUserQueryVariables,
  GetOrganizationPoliciesDocument,
  GetOrganizationPoliciesQuery,
  GetOrganizationPoliciesQueryVariables,
  GetUserManagedTeamsDocument,
  GetUserManagedTeamsQuery,
  GetUserOrganizationsByPlanPolicyFeaturesDocument,
  GetUserOrganizationsByPlanPolicyFeaturesQuery
} from 'generated/graphql';
import {createApolloClient} from 'lib/apolloClient';
import {ApolloClient} from '@apollo/client';
import {NormalizedCacheObject} from '@apollo/client/cache';
import {Ability, AbilityBuilder, MongoQuery} from '@casl/ability';
import {RawRuleFrom} from '@casl/ability/dist/types/RawRule';
import {DecodedToken, decodeToken} from 'utils/jwt';

type Actions = 'view' | 'edit';
type Subjects = 'ReportPage';

type AbilityRules = RawRuleFrom<[Actions, Subjects], MongoQuery>[];

interface Abilities {
  getManagerAbilities(): Promise<UserAbilities>;
  getAllAbilities(): Promise<Ability>;
  build(): Promise<Ability>;
}

export class UserAbilities implements Abilities {
  private apolloClient: ApolloClient<NormalizedCacheObject> | undefined;
  private decodedToken: DecodedToken | undefined;
  private userId: string | undefined;
  private orgId: string | undefined;
  private can: AbilityBuilder<Ability>['can'];
  private rules: AbilityRules;

  constructor(jwtToken?: string, userId?: string, orgId?: string) {
    if (jwtToken) {
      if (userId) this.apolloClient = createApolloClient({token: jwtToken});
      this.decodedToken = decodeToken(jwtToken);
    }
    this.userId = userId;
    this.orgId = orgId;
    const {can, rules} = new AbilityBuilder(Ability);
    this.can = can;
    this.rules = rules as AbilityRules;
  }

  public async getManagerAbilities(): Promise<UserAbilities> {
    if (this.apolloClient) {
      const userOrganizationsQueryResult =
        await this.apolloClient.query<GetUserOrganizationsByPlanPolicyFeaturesQuery>({
          query: GetUserOrganizationsByPlanPolicyFeaturesDocument,
          variables: {
            userId: this.userId,
            features: {
              features: {
                teamReports: true
              }
            }
          }
        });

      if (userOrganizationsQueryResult.data.userOrganizations) {
        const {organizationUsers} = userOrganizationsQueryResult.data.userOrganizations;

        const userManagedTeamsQueryResult = await this.apolloClient.query<GetUserManagedTeamsQuery>(
          {
            query: GetUserManagedTeamsDocument,
            variables: {
              userId: this.userId
            }
          }
        );

        const {userManagedTeams} = userManagedTeamsQueryResult.data;

        const isManager = organizationUsers.some((organizationUser) =>
          userManagedTeams.some(
            (userManagedTeam) =>
              organizationUser.organization.id === userManagedTeam.team.organizationId
          )
        );

        if (isManager) {
          this.can(
            'view', // can view
            'MyTeamPage' //  Reports page
          );
          this.can('edit', 'TeamEnrollments');
          this.can('view', 'UserSubmission');
        }

        const isAllTeamsManager = organizationUsers.some((organizationUser) =>
          userManagedTeams.some(
            (userManagedTeam) =>
              organizationUser.organization.id === userManagedTeam.team.organizationId &&
              userManagedTeam.team.parentId === null
          )
        );

        if (isAllTeamsManager) {
          this.can(
            'view', // can view
            'AllMembersReports' //  All Members Report
          );
        }
      }
    }
    return this;
  }

  public async getCoachAbilities(): Promise<UserAbilities> {
    if (this.apolloClient) {
      const {data: coachData} = await this.apolloClient.query<
        GetCoachForUserQuery,
        GetCoachForUserQueryVariables
      >({
        query: GetCoachForUserDocument,
        variables: {
          userId: this.userId
        }
      });

      if ((coachData?.coaches?.length || 0) > 0) {
        this.can('view', 'CoachDashboard');
      }
    }
    return this;
  }

  public async getLearnerAbilities(): Promise<UserAbilities> {
    if (this.apolloClient) {
      const org = await this.apolloClient.query<
        GetOrganizationPoliciesQuery,
        GetOrganizationPoliciesQueryVariables
      >({
        query: GetOrganizationPoliciesDocument,
        variables: {
          orgId: this.orgId
        }
      });

      if (
        org.error ||
        (org.data?.organizations || []).length === 0 ||
        (org.data?.organizations?.[0]?.planSubscriptions || []).length === 0
      ) {
        return this;
      }

      const plan = org.data?.organizations?.[0]?.planSubscriptions?.[0]?.plan;

      if (!plan?.policy?.features?.restrictEnrollToManager) {
        this.can('self-enroll', 'Cohort');
      }
    }
    return this;
  }

  public async getStaffAbilities(): Promise<UserAbilities> {
    if (this.decodedToken?.roles.includes('impersonator')) this.can('impersonate', 'User');
    return this;
  }

  public async getAllAbilities(): Promise<Ability> {
    await Promise.allSettled([
      this.getManagerAbilities(),
      this.getCoachAbilities(),
      this.getLearnerAbilities(),
      this.getStaffAbilities()
    ]);

    return this.build();
  }

  public async build(): Promise<Ability> {
    return new Ability<[Actions, Subjects]>(this.rules);
  }
}
