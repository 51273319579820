import {SERVER_ENV} from 'utils/env/getter';
import {defaultCookieConfig} from 'config';
import ICookies from 'cookies';
import jwt, {TokenExpiredError} from 'jsonwebtoken';
import {validate} from 'middleware/validate';
import {NextApiRequest, NextApiResponse} from 'next';
import SessionData from 'types/SessionData';
import {decrypt, encrypt} from 'utils/crypto';
import {modifyToken} from 'utils/jwt';
import createLogger from 'utils/logger';
import {applyBaseMiddleware} from '../../middleware/applyBaseMiddleware';
import {getUserMetaData} from 'utils/session';

const logger = createLogger('session');

// TODO: DRY up with code below
export function getUserId(req: NextApiRequest, res: NextApiResponse): string | undefined {
  try {
    const cookies = new ICookies(req, res, defaultCookieConfig);
    const session = cookies.get('sia-session');
    const decoded = jwt.decode((session && decrypt(session)) || '') as SessionData;
    return decoded?.user?.id;
  } catch (e) {
    return undefined;
  }
}

// TODO: DRY up with code below
export function isUserStaff(req: NextApiRequest, res: NextApiResponse): boolean {
  try {
    const cookies = new ICookies(req, res, defaultCookieConfig);
    const session = cookies.get('sia-session');
    const decoded = jwt.decode((session && decrypt(session)) || '') as SessionData;
    const email = decoded?.email;
    if (email.endsWith('@salesimpact.io')) {
      return true;
    }
  } catch (e) {}
  return false;
}

export function isExpiringSoon(exp: number): boolean {
  const now = Math.round(Date.now() / 1000);
  return now >= exp - 60;
}

/**
 * @throws TokenExpiredError
 */
export function throwIfExpiringSoon(sessionData: SessionData): void {
  if (sessionData?.exp && isExpiringSoon(sessionData.exp)) {
    // Auto-refresh if expiring in next 60 seconds.
    throw new TokenExpiredError('jwt expired', new Date(sessionData.exp * 1000));
  }
}

async function SessionHandler(req: NextApiRequest, res: NextApiResponse): Promise<void> {
  const cookies = new ICookies(req, res, defaultCookieConfig);
  const session = cookies.get('sia-session');

  try {
    if (session) {
      const token = decrypt(session);
      const sessionData = jwt.verify(token, SERVER_ENV.JWTSecret) as SessionData;

      throwIfExpiringSoon(sessionData);

      const userMeta = await getUserMetaData(token, sessionData.user.id);

      return res.json({
        data: {
          ...sessionData,
          userMeta,
          token
        },
        status: 'authenticated'
      });
    } else {
      return res.json({
        data: undefined,
        status: 'unauthenticated'
      });
    }
  } catch (error) {
    if (error instanceof TokenExpiredError) {
      logger.info('attempting to refresh token...');
      const refreshToken = cookies.get('sia-refresh-token');
      try {
        const decoded = jwt.decode((session && decrypt(session)) || '') as SessionData;
        const response = await fetch(`${SERVER_ENV.fusionAuthURL}/api/jwt/refresh`, {
          body: JSON.stringify({refreshToken}),
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: SERVER_ENV.fusionAuthAPIKey
          }
        });

        logger.info({status: response.status}, 'refresh token response');

        if (response.status === 400) {
          cookies.set('sia-session', null, defaultCookieConfig);
          cookies.set('sia-refresh-token', null, defaultCookieConfig);
          throw Error('refresh token is expired or revoked');
        }

        logger.info('token successfully refreshed, generating new session...');
        const json = (await response.json()) as {
          refreshToken: string;
          token: string;
        };
        cookies.set('sia-refresh-token', json.refreshToken, defaultCookieConfig);
        const updatedToken = modifyToken(json.token, decoded.user);
        const userMeta = await getUserMetaData(updatedToken, decoded.user.id);
        cookies.set('sia-session', encrypt(updatedToken), defaultCookieConfig);
        logger.info('new session successfully generated');
        return res.json({
          data: {...decoded, userMeta, token: updatedToken},
          status: 'authenticated'
        });
      } catch (error) {
        logger.error(error);
        return res.json({
          data: undefined,
          status: 'unauthenticated'
        });
      }
    }
    logger.error(error);
    return res.json({
      data: undefined,
      status: 'unauthenticated'
    });
  }
}

export default applyBaseMiddleware({pre: [validate]})(SessionHandler);
