import {Url} from 'url';
import NextLink from 'next/link';
import {Link as CLink, LinkProps as CLinkProps} from '@chakra-ui/react';
import {FC} from 'react';
import {useRouter} from 'next/router';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExternalLinkAlt, IconDefinition} from '@fortawesome/free-solid-svg-icons';
import {CustomUrlObject} from 'utils/routeFactory';

export type LinkProps = Omit<CLinkProps, 'href'> & {
  href: Url | string;
  target?: string;
  isExternal?: boolean;
  activeClassName?: string;
  icon?: IconDefinition;
};

export const Link: FC<React.PropsWithChildren<LinkProps>> = ({href, activeClassName, ...props}) => {
  const router = useRouter();
  const isActive =
    router.pathname ===
    (typeof href === 'string' ? href : (href as CustomUrlObject).pathname ?? href);
  return (
    <NextLink href={href} legacyBehavior passHref>
      <CLink
        _hover={{textDecor: 'none'}}
        className={isActive ? activeClassName ?? 'active-link' : ''}
        target={props.target ?? '_self'}
        {...props}
      >
        {props.children}{' '}
        {props.isExternal && <FontAwesomeIcon icon={props.icon ?? faExternalLinkAlt} />}
      </CLink>
    </NextLink>
  );
};
