import React, {createContext, useCallback, useContext, useMemo, useState} from 'react';

interface SignInProviderProps {
  children: React.ReactNode | null;
}

export interface SignInIniDataProps {
  isShowingSignInModal: boolean;
  signInRedirectUri?: string;
  openSignInModal: (redirectUri?: string) => void;
  closeSignInModal: () => void;
}

export const signInInitData: SignInIniDataProps = {
  isShowingSignInModal: false,
  openSignInModal: () => void 0,
  closeSignInModal: () => void 0
};

export const SignInContext = createContext(signInInitData);

export function useSignInModal(): SignInIniDataProps {
  return useContext(SignInContext);
}

export function SignInProvider({children}: SignInProviderProps): React.ReactElement {
  const [isShowingSignInModal, setIsShowingSignInModal] = useState(false);
  const [signInRedirectUri, setSignInRedirectUri] = useState<string>();

  const openSignInModal = useCallback((redirectUri?: string) => {
    setSignInRedirectUri(redirectUri);
    setIsShowingSignInModal(true);
  }, []);
  const closeSignInModal = useCallback(() => {
    setIsShowingSignInModal(false);
  }, []);

  const value = useMemo(
    () => ({
      isShowingSignInModal,
      signInRedirectUri,
      openSignInModal,
      closeSignInModal
    }),
    [closeSignInModal, isShowingSignInModal, openSignInModal, signInRedirectUri]
  );

  return (
    <SignInContext.Provider value={value}>
      {useMemo(() => children, [children])}
    </SignInContext.Provider>
  );
}
