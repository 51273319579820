import Head from 'next/head';
import {FunctionComponent} from 'react';

export interface SEOProps {
  title?: string;
  description?: string;
  siteName?: string;
}

const defaultTitle = "The World's Leading GTM Learning Platform";
const defaultDescription =
  "Sales Impact Academy is the world's leading go-to-market learning platform providing a continuous live learning solution for high-growth technology companies.";

const getTitle = (title?: string) => `${title ?? defaultTitle} | Sales Impact Academy`;
const getDescription = (description?: string) => description ?? defaultDescription;
const getSiteName = (siteName?: string) => siteName || 'salesimpact.io';

const SEO: FunctionComponent<React.PropsWithChildren<SEOProps>> = (props) => {
  const title = getTitle(props.title);
  const desc = getDescription(props.description);
  const site = getSiteName(props.siteName);

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={desc} />
      <meta property="og:type" content="website" />
      <meta name="og:title" property="og:title" content={title} />
      <meta name="og:description" property="og:description" content={desc} />
      <meta property="og:site_name" content={site} />
      <meta property="og:url" content="" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={desc} />
      <meta name="twitter:site" content={site} />
      <meta name="twitter:creator" content="" />
      <link rel="icon" type="image/png" sizes="16x16" href="/images/favicons/favicon-16x16.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/images/favicons/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="96x96" href="/images/favicons/favicon-96x96.png" />
      <link
        rel="icon"
        type="image/png"
        sizes="180x180"
        href="/images/favicons/favicon-180x180.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="300x300"
        href="/images/favicons/favicon-300x300.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="512x512"
        href="/images/favicons/favicon-512x512.png"
      />
      <link rel="apple-touch-icon" href="/images/favicons/favicon-180x180.png" />
      <meta property="og:image" content="" />
      <meta name="twitter:image" content="" />
      {props.children}
    </Head>
  );
};

export default SEO;
