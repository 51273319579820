/**
 * compose(
 *   last
 *   second
 *   first
 * )
 */

export const compose =
  <T>(...funcs: Array<(arg: T) => T>) =>
  (args: T): T =>
    funcs.reduceRight((arg, func) => func(arg), args);
