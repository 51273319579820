import colors from './colors';

const components = {
  Table: {
    variants: {
      simple: {
        table: {
          borderWidth: 1,
          borderColor: 'rgba(255, 255, 255, 0.12)'
        },
        th: {
          textColor: colors.white,
          borderColor: 'rgba(255, 255, 255, 0.12)'
        },
        td: {
          borderColor: 'rgba(255, 255, 255, 0.12)'
        }
      }
    }
  },
  Button: {
    baseStyle: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 'medium',
      letterSpacing: '.03em',
      borderRadius: 'none'
    },
    defaultProps: {
      size: 'md',
      variant: 'solid',
      colorScheme: 'lime'
    },
    variants: {
      link: {
        fontSize: 'sm',
        fontWeight: '400',
        lineHeight: '26px',
        textDecoration: 'underline',
        textColor: colors.lime[300]
      },
      schoollFilter: {
        borderRadius: '20px',
        padding: '6px 15px',
        maxHeight: '40px',
        fontWeight: 500,
        border: '1px solid',
        fontSize: '12px'
      }
    }
  },
  Divider: {
    baseStyle: {
      color: 'gray.200'
    }
  },
  FormLabel: {
    baseStyle: {
      fontSize: '16px',
      lineHeight: '24px'
    }
  },
  Radio: {
    parts: ['container'],
    baseStyle: {
      container: {
        position: 'relative'
      }
    }
  },
  Accordion: {
    parts: ['container'],
    baseStyle: {
      container: {
        borderTopWidth: 0,
        borderBottomWidth: 0
        // _last: {
        //   borderBottomWidth: 0
        // }
      }
    }
  },
  Heading: {
    baseStyle: {
      lineHeight: '90%',
      fontWeight: '500',
      letterSpacing: '.03em',
      textTransform: 'uppercase'
    }
  },
  Select: {
    baseStyle: {
      color: 'green',
      paddingBottom: 0
    }
  },
  Tabs: {
    baseStyle: {
      tab: {
        color: '#B6B7BE',
        _selected: {
          color: 'white',
          fontWeight: 600,
          borderBottom: '3px solid #E2FE62 !important'
        }
      }
    }
  }
};
export default components;
