import {SERVER_ENV} from 'utils/env/getter';
import jsonwebtoken from 'jsonwebtoken';
import SelectedOrg from 'types/SelectedOrg';
import {IncomingHttpHeaders} from 'http';

export interface UserAbilities {
  teamsReport: boolean;
}

export interface DecodedToken {
  aud: string;
  exp: number;
  iat: number;
  iss: string;
  sub: string;
  jti: string;
  authenticationType: string;
  email: string;
  email_verified: true;
  applicationId: string;
  roles: string[];
  'https://hasura.io/jwt/claims': {
    'x-hasura-allowed-roles': string[];
    'x-hasura-default-role': string;
    'x-hasura-user-id': string;
  };
}

export function decodeToken(token: string, verify = false): DecodedToken {
  if (verify)
    return jsonwebtoken.verify(token, SERVER_ENV.JWTSecret, {
      ignoreExpiration: false
    }) as DecodedToken;
  return jsonwebtoken.decode(token) as DecodedToken;
}

// TODO: tests for this function
export function modifyToken(
  token: string,
  user: {
    id: string;
    firstName?: string;
    lastName?: string;
    imageUrl?: string;
    insertInstant?: number;
    selectedOrg?: SelectedOrg;
    data?: Record<string, string>;
    abilities?: UserAbilities;
    joinDate?: string;
  }
): string {
  const decoded = jsonwebtoken.verify(token, SERVER_ENV.JWTSecret) as DecodedToken;
  return jsonwebtoken.sign(
    {
      ...decoded,
      'https://hasura.io/jwt/claims': {
        ...decoded['https://hasura.io/jwt/claims'],
        'x-hasura-custom-org-id': user.selectedOrg && user.selectedOrg.id
      },
      user: {
        id: user.id,
        ...(user.firstName && {firstName: user.firstName}),
        ...(user.lastName && {lastName: user.lastName}),
        ...(user.imageUrl && {imageUrl: user.imageUrl}),
        ...(user.selectedOrg && {selectedOrg: user.selectedOrg}),
        ...(user.abilities && {abilities: user.abilities}),
        ...(user.joinDate && {joinDate: user.joinDate})
      }
    },
    SERVER_ENV.JWTSecret,
    {algorithm: 'HS256'}
  );
}

export const getTokenFromHeaders = (headers: IncomingHttpHeaders): string | null => {
  const authHeader = (headers?.Authorization as string) ?? (headers?.authorization as string);
  if (authHeader && authHeader.trim().split(' ')[0] === 'Bearer') {
    return authHeader.split(' ')[1];
  }
  return null;
};
