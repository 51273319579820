import React, {FC, useMemo} from 'react';
import createCache from '@emotion/cache';
import {CacheProvider} from '@emotion/react';

interface Props {
  nonce: string | undefined;
}

export const EmotionProviderWithNonce: FC<React.PropsWithChildren<Props>> = ({nonce, children}) => {
  const cache = useMemo(() => createCache({nonce, key: 'sia-csp'}), [nonce]);
  return <CacheProvider value={cache}>{children}</CacheProvider>;
};
