import {ApolloProvider} from '@apollo/client';
import {ChakraProvider} from '@chakra-ui/react';
import {Layout} from 'components/Layouts';
import SignInModal from 'components/Modals/SignInModal';
import SignUpModal from 'components/Modals/SignUpModal';
import SessionContextProvider from 'context/SessionContext';
import {useApollo} from 'lib/apolloClient';
import {AppProps} from 'next/app';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import ISession from 'types/ISession';
import {getSession} from 'utils/session';
import {SignInProvider} from 'context/SignInModalContext';
import {SignUpProvider} from 'context/SignUpModalContext';
import NextPageWithLayout from 'types/NextPageWithLayout';
import theme from 'ui/theme';
import {AnalyticsWebProvider} from '../context/AnalyticsWebContext';
import {useRouter} from 'next/router';
import {catalogPath, learnPath} from 'utils/routeFactory';
import AuthHandler from '../components/AuthHandler';
import AppModal from 'components/Modals/AppModal';
import {EmotionProviderWithNonce} from 'components/Next/EmotionProviderWithNonce';
import hsq from 'utils/hubspot';
import {css, Global} from '@emotion/react';
import {PUBLIC_ENV} from 'utils/env/getter';
import {datadogRum, RumInitConfiguration} from '@datadog/browser-rum';
import {InitConfiguration} from '@datadog/browser-core';
import {datadogLogs, LogsInitConfiguration} from '@datadog/browser-logs';
import 'focus-visible/dist/focus-visible';
import {GoogleOAuthProvider} from '@react-oauth/google';

import {config} from '@fortawesome/fontawesome-svg-core';
import '../styles/globals.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import LoggerProvider from '../context/LoggerContext';

config.autoAddCss = false;

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

if (process.env.NEXT_PUBLIC_DATADOG_FRONTEND_TRACING_ENABLED === 'true') {
  const DataDogInitConfiguration: InitConfiguration = {
    clientToken: PUBLIC_ENV.dataDogClientToken,
    site: PUBLIC_ENV.dataDogSite,
    service: PUBLIC_ENV.appName,
    version: PUBLIC_ENV.appVersion,
    env: PUBLIC_ENV.appEnv
  };

  datadogRum.init({
    ...(DataDogInitConfiguration as RumInitConfiguration),

    applicationId: PUBLIC_ENV.dataDogApplicationId,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input'
  });

  datadogLogs.init({
    ...(DataDogInitConfiguration as LogsInitConfiguration),

    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    forwardConsoleLogs: 'all',
    forwardReports: 'all'
  });

  datadogRum.startSessionReplayRecording();
}

const GlobalStyles = css`
  /*
    This will hide the focus indicator if the element receives focus via the mouse,
    but it will still show up on keyboard focus.
  */
  @font-face {
    font-family: 'Reckless';
    font-weight: 300;
    font-style: normal;
    src: url('./fonts/Reckless-Light.woff2') format('woff2'),
      url('./fonts/Reckless-Light.woff') format('woff');
  }
  @font-face {
    font-family: 'Reckless';
    font-weight: 300;
    font-style: italic;
    src: url('./fonts/Reckless-LightItalic.woff2') format('woff2'),
      url('./fonts/Reckless-LightItalic.woff') format('woff');
  }
  @font-face {
    font-family: 'Reckless';
    font-weight: 400;
    font-style: normal;
    src: url('./fonts/Reckless-Regular.woff2') format('woff2'),
      url('./fonts/Reckless-Regular.woff') format('woff');
  }
  @font-face {
    font-family: 'Reckless';
    font-weight: 400;
    font-style: italic;
    src: url('./fonts/Reckless-RegularItalic.woff2') format('woff2'),
      url('./fonts/Reckless-RegularItalic.woff') format('woff');
  }
  @font-face {
    font-family: 'Reckless';
    font-weight: 500;
    font-style: normal;
    src: url('./fonts/Reckless-Medium.woff2') format('woff2'),
      url('./fonts/Reckless-Medium.woff') format('woff');
  }
  @font-face {
    font-family: 'Reckless';
    font-weight: 500;
    font-style: italic;
    src: url('./fonts/Reckless-MediumItalic.woff2') format('woff2'),
      url('./fonts/Reckless-MediumItalic.woff') format('woff');
  }
  @font-face {
    font-family: 'Reckless';
    font-weight: 600;
    font-style: normal;
    src: url('./fonts/Reckless-SemiBold.woff2') format('woff2'),
      url('./fonts/Reckless-SemiBold.woff') format('woff');
  }
  @font-face {
    font-family: 'Reckless';
    font-weight: 600;
    font-style: italic;
    src: url('./fonts/Reckless-SemiBoldItalic.woff2') format('woff2'),
      url('./fonts/Reckless-SemiBoldItalic.woff') format('woff');
  }
  @font-face {
    font-family: 'Reckless';
    font-weight: 700;
    font-style: normal;
    src: url('./fonts/Reckless-Bold.woff2') format('woff2'),
      url('./fonts/Reckless-Bold.woff') format('woff');
  }
  @font-face {
    font-family: 'Reckless';
    font-weight: 700;
    font-style: italic;
    src: url('./fonts/Reckless-BoldItalic.woff2') format('woff2'),
      url('./fonts/Reckless-BoldItalic.woff') format('woff');
  }
  @font-face {
    font-family: 'Reckless';
    font-weight: 900;
    font-style: normal;
    src: url('./fonts/Reckless-Heavy.woff2') format('woff2'),
      url('./fonts/Reckless-Heavy.woff') format('woff');
  }
  @font-face {
    font-family: 'Reckless';
    font-weight: 900;
    font-style: italic;
    src: url('./fonts/Reckless-HeavyItalic.woff2') format('woff2'),
      url('./fonts/Reckless-HeavyItalic.woff') format('woff');
  }
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`;

function MyApp({Component, pageProps}: AppPropsWithLayout): React.ReactNode {
  const [session, setSession] = useState<ISession>({status: 'loading'});
  const [loadedSession, setLoadedSession] = useState<boolean>(false);
  // only create new objects if the data actually changes.
  // previously implementation created new objects every render
  // causing multiple initializations of the `useApollo`
  const [clientDetails, token] = useMemo(
    () => [
      {
        ...pageProps.initialApolloState,
        ...(session.data?.user.id && {userId: session.data?.user.id})
      },
      {token: session?.data?.token}
    ],
    [pageProps.initialApolloState, session?.data?.user?.id, session?.data?.token]
  );

  const apolloClient = useApollo(
    clientDetails,
    token,
    loadedSession,
    setSession,
    session?.data?.exp
  );

  const getMainLayout = Component.getMainLayout ?? ((page) => <Layout>{page}</Layout>);
  const getLayout = Component.getLayout ?? ((page) => page);

  const router = useRouter();
  const {replace, events} = router;

  const updateSession = useCallback(async () => {
    return getSession()
      .then((session) => {
        setSession(session);
        setLoadedSession(true);
      })
      .catch(console.error);
  }, [setSession, setLoadedSession]);

  useEffect(() => {
    updateSession();
  }, [updateSession]);

  /**
   * @todo Extract to custom hook
   */
  // Capture any unauthenticated users trying to access my learning and redirect them to the catalog page
  useEffect(() => {
    if (session.status === 'unauthenticated') {
      const handleRouteChange = (url: string) => {
        if (session.status !== 'loading') {
          if (
            (session.status === 'unauthenticated' || !session.data) &&
            url.includes(learnPath().home())
          )
            replace(catalogPath({asUrl: true}).catalog());
        }
      };
      events.on('routeChangeStart', handleRouteChange);
      return () => {
        events.off('routeChangeStart', handleRouteChange);
      };
    }
  }, [events, replace, session]);

  /**
   * @todo Extract to custom hook
   */
  // track page views in hubspot
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      hsq.push(['setPath', url]);
      hsq.push(['trackPageView']);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  /**
   * @todo Implement spinner.
   */
  if (!loadedSession || !apolloClient) return null;

  /**
   * Chakra UI/Emotion stuff is never SSR'd so this is completely fine for now (until we replace it with Tailwind), otherwise we would need to disable static page optimization.
   *
   * This should also imply that Chakra styles are slightly less safe.
   */
  const nonce = pageProps.nonce;

  return (
    <SessionContextProvider
      session={{
        ...session,
        updateSession
      }}
    >
      <LoggerProvider level="debug">
        <AnalyticsWebProvider>
          <EmotionProviderWithNonce nonce={nonce}>
            <ChakraProvider resetCSS theme={theme}>
              <Global styles={GlobalStyles} />
              <AppModal />
              <ApolloProvider client={apolloClient}>
                <AuthHandler apolloClient={apolloClient} setSession={setSession} />
                <GoogleOAuthProvider
                  clientId={`${process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}`}
                  nonce={nonce}
                >
                  <SignUpProvider>
                    <SignInProvider>
                      {getMainLayout(
                        <>
                          <SignInModal />
                          <SignUpModal />
                          {getLayout(<Component {...pageProps} />)}
                        </>
                      )}
                    </SignInProvider>
                  </SignUpProvider>
                </GoogleOAuthProvider>
              </ApolloProvider>
            </ChakraProvider>
          </EmotionProviderWithNonce>
        </AnalyticsWebProvider>
      </LoggerProvider>
    </SessionContextProvider>
  );
}

export default MyApp;
