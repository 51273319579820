import {extendTheme} from '@chakra-ui/react';
import styles from 'ui/styles';
import colors from 'ui/colors';
import textStyles from 'ui/textStyles';
import layerStyles from 'ui/layerStyles';
import components from 'ui/components';

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
  colors,
  fonts: {
    body: 'Inter, sans-serif',
    heading: 'Inter, Helvetica, sans-serif',
    input: 'Inter, Helvetica, sans-serif'
  },
  fontSizes: {
    '2xs': '10px',
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '20px',
    xl: '24px',
    '2xl': '32px',
    '3xl': '36px',
    '4xl': '48px',
    '5xl': '64px',
    '6xl': '72px',
    '7xl': '88px'
  },
  layerStyles,
  textStyles,
  components,
  styles,
  shadows: {
    outline: '0 0 3pt 1pt cornflowerblue'
  },
  drawer: {
    baseStyle: {
      dialog: {
        bg: 'black'
      }
    }
  }
};

const theme = extendTheme(config);

export default theme;
