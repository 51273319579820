// Check if user is navigating to page from within website or from
// external one (direct link)

import {usePathname} from 'next/navigation';
import {useState, createContext, useEffect, useRef, useContext} from 'react';

export const OriginContext = createContext<boolean>(false);

export default function OriginTrackerProvider({children}: React.PropsWithChildren) {
  const [isWithinPage, setIsWithinPage] = useState(false);
  const pathname = usePathname();
  const isInitialLoadRef = useRef(pathname);

  useEffect(() => {
    if (isInitialLoadRef.current !== pathname) {
      setIsWithinPage(true);
    }
  }, [pathname]);

  return <OriginContext.Provider value={isWithinPage}>{children}</OriginContext.Provider>;
}

export const useOriginTracker = () => {
  return useContext(OriginContext);
};
