const colors = {
  blue: {
    400: '#1527F1'
  },
  lime: {
    100: '#E9FC98',
    200: '#E2FE62',
    300: '#BED655',
    400: '#9CAE49'
  },
  gray: {
    25: '#D9D9D9',
    30: '#B6B7BE',
    50: '#87888F',
    75: '#414145',
    100: '#202126',
    200: '#0F1015',
    300: '#CBD5E0',
    500: '#718096',
    700: '#202126',
    800: '#1A202C'
  },
  teal: {
    400: '#57BBAD'
  },
  textColor: {
    100: '#F6F7FF',
    200: '#B6B7BE'
  },
  black: '#0F1015',
  white: '#F6F7FF',
  berry: '#FF236E',
  red: {
    500: '#ff9595',
    600: '#ff7474',
    700: '#ff3e3e',
    800: '#db0000',
    900: '#850000'
  },
  green: {
    100: '#1AC099'
  },
  confetti: [
    '#E2FE62',
    '#1AC099',
    '#08D7CA',
    '#002DE3',
    '#BE48E7',
    '#E83CD2',
    '#FF236E',
    '#FF5E84',
    '#FE8762',
    '#F9C16F'
  ]
};
export default colors;
