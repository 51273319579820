import {NextApiHandler} from 'next';
import {compose} from 'utils/compose';
import {errorMiddleware} from './errorMiddleware';
import {Middleware} from './MiddlewareTypes';

export function applyBaseMiddleware(
  middleware: Middleware = {}
): (handler: NextApiHandler) => NextApiHandler {
  return compose(errorMiddleware, ...(middleware.pre ?? []), ...(middleware.post ?? []));
}
