import {createContext, FC, ReactNode, useContext, useMemo} from 'react';
import pino, {Logger, Level} from 'pino';
import {useSession} from './SessionContext';

const defaultLoggerOptions = {
  browser: {asObject: true}
};
const LoggerContext = createContext<Logger>(pino(defaultLoggerOptions));

interface Props {
  level?: Level;
  children: ReactNode;
}
const LoggerProvider: FC<Props> = ({level = 'info', children}) => {
  const {data: session} = useSession();
  const userId = session?.user.id;
  const logger = useMemo<Logger>(
    () =>
      pino({
        ...defaultLoggerOptions,
        level
      }).child({userId}),
    [level, userId]
  );
  return (
    <LoggerContext.Provider value={logger}>
      {useMemo(() => children, [children])}
    </LoggerContext.Provider>
  );
};

export function useClientLogger(name: string, context?: Record<string, unknown>) {
  const logger = useContext(LoggerContext);
  return useMemo(() => logger.child({name, ...context}), [logger, name, context]);
}

export default LoggerProvider;
