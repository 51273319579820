import pino, {Logger} from 'pino';
import {PUBLIC_ENV} from 'utils/env/getter';

function createLogger(name: string, context?: Record<string, string>): Logger {
  return pino({
    name,
    service: PUBLIC_ENV.appName,
    version: PUBLIC_ENV.appVersion,
    env: PUBLIC_ENV.appEnv,
    ...context
  });
}

export default createLogger;
