import {ValidationError} from 'yup';
import {NextApiHandler, NextApiRequest, NextApiResponse} from 'next';
import {HttpError} from 'error';
import {errorCodes, httpErrorCode} from 'utils/errorCodes';
import {
  AnyObject,
  AssertsShape,
  ObjectShape,
  RequiredObjectSchema,
  TypeOfShape
} from 'yup/lib/object';

type Methods = Array<string>;

export function validatePartial(
  schema?: RequiredObjectSchema<ObjectShape, AnyObject, TypeOfShape<ObjectShape>>,
  methods: Methods = ['GET']
) {
  return function validateHandler(handler: NextApiHandler): NextApiHandler {
    return validate(handler, schema ? [schema] : [], methods);
  };
}

export function validateMultiplePartial(
  schemas: Array<RequiredObjectSchema<ObjectShape, AnyObject, TypeOfShape<ObjectShape>>>,
  methods: Methods = ['GET']
) {
  console.log('validateMultiplePartial');
  return function validateHandler(handler: NextApiHandler): NextApiHandler {
    return validate(handler, schemas, methods);
  };
}

export function validate(
  handler: NextApiHandler,
  schemas: Array<RequiredObjectSchema<ObjectShape, AnyObject, TypeOfShape<ObjectShape>>> = [],
  methods: Methods = ['GET']
) {
  return async (req: NextApiRequest, res: NextApiResponse) => {
    if (!methods.includes(req.method ?? 'GET'))
      throw new HttpError(...httpErrorCode(errorCodes.METHOD_NOT_ALLOWED));
    if (req.method !== 'GET' && schemas.length === 0)
      throw new HttpError(...httpErrorCode(errorCodes.INVALID_SCHEMA));
    if (req.method !== 'GET') {
      try {
        const settled = await Promise.allSettled(
          schemas.map(async (schema) => {
            const result = await schema.validate(req.body, {abortEarly: true, stripUnknown: true});
            return result;
          })
        );

        const fulfilled = settled.filter(
          (validatedSchema): validatedSchema is PromiseFulfilledResult<AssertsShape<ObjectShape>> =>
            validatedSchema.status === 'fulfilled'
        );

        if (fulfilled.length === 0) {
          const rejected = settled.filter(
            (validatedSchema): validatedSchema is PromiseRejectedResult =>
              validatedSchema.status === 'rejected'
          );

          if (rejected.length === 1) throw rejected[0].reason;

          throw Error(rejected.map((rejected) => (rejected.reason as Error).message).join(' | '));
        }

        req.body = (fulfilled[0] as PromiseFulfilledResult<AssertsShape<ObjectShape>>).value;
      } catch (e) {
        if (e instanceof ValidationError) {
          throw new HttpError(
            (e as ValidationError).message,
            400,
            (e as ValidationError).errors.join(' | ')
          );
        }

        throw new HttpError((e as Error).message, 400);
      }
    }
    return handler(req, res);
  };
}
