export interface ErrorCodes {
  [s: string]: ErrorCode;
}

export interface ErrorCode {
  code: string;
  message: string;
  status: number;
}

const AUTHENTICATION_ERROR_CODES: ErrorCodes = {
  UNAUTHENTICATED: {
    code: 'UNAUTHENTICATED',
    message: 'Not logged in.',
    status: 401
  },
  JWT_EXPIRED: {
    code: 'JWT_EXPIRED',
    message: 'jwt expired',
    status: 401
  },
  JWT_INVALID: {
    code: 'JWT_INVALID',
    message: 'jwt invalid',
    status: 401
  }
};

const SERVER_ERROR_CODES: ErrorCodes = {
  INTERNAL_SERVER_ERROR: {
    code: 'INTERNAL_SERVER_ERROR',
    message: 'something went wrong',
    status: 500
  },
  INVALID_WEBHOOK_REQUEST: {
    code: 'INVALID_WEBHOOK_REQUEST',
    message: 'Request to webhook failed',
    status: 500
  },
  INVALID_SCHEMA: {
    code: 'INVALID_SCHEMA',
    message: 'Schema is invalid',
    status: 500
  }
};

const CLIENT_ERROR_CODES: ErrorCodes = {
  BAD_REQUEST: {
    code: 'BAD_REQUEST',
    message: 'something went wrong',
    status: 400
  },
  CSRF_INVALID: {
    code: 'CSRF_INVALID',
    message: 'CSRF header is invalid',
    status: 400
  },
  CSRF_MISSING: {
    code: 'CSRF_MISSING',
    message: 'CSRF header is missing',
    status: 400
  },
  METHOD_NOT_ALLOWED: {
    code: 'METHOD_NOT_ALLOWED',
    message: 'Method not allowed',
    status: 405
  },
  NOT_FOUND: {
    code: 'NOT_FOUND',
    message: 'not found',
    status: 404
  }
};

const PLAN_ERRORS: ErrorCodes = {
  PLAN_ENROLLMENT_REACHED: {
    code: 'PLAN_ENROLLMENT_REACHED',
    message: 'You reached the max enrollments for your plan.',
    status: 403
  }
};

const ENROLL_ERRORS: ErrorCodes = {
  ENROLL_ERROR: {
    code: 'ENROLL_ERROR',
    message: 'There was an error enrolling in the course.',
    status: 400
  },
  ENROLL_WAITLIST_ERROR: {
    code: 'ENROLL_WAITLIST_ERROR',
    message: 'There was an error waitlisting for the course.',
    status: 400
  },
  UNENROLL_ERROR: {
    code: 'UNENROLL_ERROR',
    message: 'There was an error unenrolling from the course.',
    status: 400
  },
  LEAVE_WAITLIST_ERROR: {
    code: 'LEAVE_WAITLIST_ERROR',
    message: 'There was an error leaving the Waitlist.',
    status: 400
  },
  ALREADY_ENROLLED_ERROR: {
    code: 'ALREADY_ENROLLED_ERROR',
    message: 'You are already enrolled in the course.',
    status: 400
  },
  ALREADY_WAITLISTED_ERROR: {
    code: 'ALREADY_WAITLISTED_ERROR',
    message: 'You are already waitlisted for the course.',
    status: 400
  },
  NOT_ENROLLED_ERROR: {
    code: 'NOT_ENROLLED_ERROR',
    message: 'You are not enrolled in the course.',
    status: 400
  },
  MAX_ENROLLMENT_ERROR: {
    code: 'MAX_ENROLLMENT_ERROR',
    message: 'Course is already at max enrollment.',
    status: 403
  },
  ENROLLMENT_NOT_OPEN: {
    code: 'ENROLLMENT_NOT_OPEN',
    message: 'Course is not open for enrollment.',
    status: 400
  },
  CANT_UNENROLL_DUE_TO_RULES_ERROR: {
    code: 'CANT_UNENROLL_DUE_TO_RULES_ERROR',
    message: 'You cannot unenroll from this course.',
    status: 400
  },
  CANT_ENROLL_DUE_TO_CREDITS: {
    code: 'CANT_ENROLL_DUE_TO_CREDITS',
    message: 'You do not have enough credits.',
    status: 400
  },
  NOT_ALLOWED_UNENROLL_USER_ERROR: {
    code: 'NOT_ALLOWED_UNENROLL_USER_ERROR',
    message: "You're not authorizer to unenroll this user.",
    status: 400
  },
  NOT_ALLOWED_ENROLL_USER_ERROR: {
    code: 'NOT_ALLOWED_ENROLL_USER_ERROR',
    message: "You're not authorizer to enroll this user.",
    status: 400
  }
};

const CLASS_JOIN_ERROR_CODES: ErrorCodes = {
  NOT_ENROLLED: {
    code: 'NOT_ENROLLED',
    message: 'Not enrolled in class',
    status: 400
  },

  CLASS_ENDED: {
    code: 'CLASS_ENDED',
    message: 'Class has already ended.',
    status: 400
  },

  CLASS_NOT_STARTED: {
    code: 'CLASS_NOT_STARTED',
    message: 'Class has not started',
    status: 400
  },

  BOR_HAS_STARTED: {
    code: 'BOR_HAS_STARTED',
    message: 'BOR has already started',
    status: 400
  },

  PRE_COURSE_SURVEY_NOT_SENT: {
    code: 'PRE_COURSE_SURVEY_NOT_SENT',
    message: 'You need to take the Pre-Course Survey before joining the class',
    status: 400
  }
};

const ASSESSMENT_ERROR_CODES: ErrorCodes = {
  ASSESSMENT_NOT_LIVE: {
    code: 'ASSESSMENT_NOT_LIVE',
    message: 'The assessment is not live',
    status: 400
  },
  ASSESSMENT_PASSED: {
    code: 'ASSESSMENT_PASSED',
    message: 'You already passed the assessment',
    status: 400
  },
  ASSESSMENT_ACTIVE: {
    code: 'ASSESSMENT_ACTIVE',
    message: 'You already have an active assessment',
    status: 400
  },
  ASSESSMENT_NOT_FOUND: {
    code: 'ASSESSMENT_NOT_FOUND',
    message: 'Could not find the assessment',
    status: 404
  },
  ASSESSMENT_RESUME: {
    code: 'ASSESSMENT_RESUME',
    message: 'Unable to resume the assessment',
    status: 400
  },
  CERTIFICATE_NOT_FOUND: {
    code: 'CERTIFICATE_NOT_FOUND',
    message: 'Course completion certificate not found',
    status: 404
  }
};

const RESOURCE_ERROR_CODES: ErrorCodes = {
  RESOURCE_NOT_FOUND: {
    code: 'RESOURCE_NOT_FOUND',
    message: 'Resource not found',
    status: 404
  },
  RESOURCE_ERROR: {
    code: 'RESOURCE_ERROR',
    message: 'Unable to get resource',
    status: 400
  }
};

const codes = {
  // authentication
  ...AUTHENTICATION_ERROR_CODES,

  // standard server responses
  ...SERVER_ERROR_CODES,
  ...CLIENT_ERROR_CODES,

  // enroll errors
  ...ENROLL_ERRORS,

  ...PLAN_ERRORS,

  // other
  ...CLASS_JOIN_ERROR_CODES,
  ...ASSESSMENT_ERROR_CODES,
  ...RESOURCE_ERROR_CODES
} as ErrorCodes;

export const httpErrorCode = (errorCode: ErrorCode): [string, number, string] => [
  errorCode.message,
  errorCode.status,
  errorCode.code
];

// proxy to facilitate getting error codes
// if a code doesn't exist, it default to 500 ERROR
const errorCodes = new Proxy(codes as ErrorCodes, {
  get(obj: ErrorCodes, prop: string) {
    return obj[prop] ?? CLIENT_ERROR_CODES.BAD_REQUEST;
  }
});

export {errorCodes};
