import React, {createContext, useContext, useState} from 'react';

interface SingUpProviderProps {
  children: React.ReactNode | null;
}

export interface SignUpIniDataProps {
  isShowingSignUpModal: boolean;
  openModal: () => void;
  closeModal: () => void;
}

export const signUpInitData: SignUpIniDataProps = {
  isShowingSignUpModal: false,
  openModal: () => void 0,
  closeModal: () => void 0
};

export const SignUpContext = createContext(signUpInitData);

export function useSignUpModal(): SignUpIniDataProps {
  return useContext(SignUpContext);
}

export function SignUpProvider({children}: SingUpProviderProps): React.ReactElement {
  const [isShowingSignUpModal, setIsShowingSignUpModal] = useState(false);

  const openModal = () => {
    setIsShowingSignUpModal(true);
  };
  const closeModal = () => {
    setIsShowingSignUpModal(false);
  };

  return (
    <SignUpContext.Provider value={{isShowingSignUpModal, openModal, closeModal}}>
      {children}
    </SignUpContext.Provider>
  );
}
