import {NextApiHandler, NextApiRequest, NextApiResponse} from 'next';
import {HttpError} from 'error';
import {errorResponse, logError} from 'utils/errors/errorUtils';

export function errorMiddleware(handler: NextApiHandler): NextApiHandler {
  return async function errorMiddlewareHandler(req: NextApiRequest, res: NextApiResponse) {
    try {
      const handlerResp = await handler(req, res);
      return handlerResp;
    } catch (e: unknown) {
      logError(e as HttpError);
      return errorResponse(res, e as HttpError);
    }
  };
}
