import {Box, Button, Divider, Flex, Stack, Text} from '@chakra-ui/react';
import Link from 'next/link';
import Image from 'next/legacy/image';
import {Link as Links} from 'components/Links/Link';
import {FC, Fragment} from 'react';
import {useSession} from 'context/SessionContext';
import {doSignOutEvent} from 'utils/behaviorEvents/clientAuthBehaviors';
import {imgproxyOptimizer} from 'utils/images/imageOptimization/imgproxyOptimizer';

export const Footer: FC<React.PropsWithChildren<unknown>> = () => {
  const {data: session} = useSession();
  const currentYear = new Date().getFullYear();

  return (
    <>
      <Divider pt={16} />
      <Box w="100%" p={[5, '64px 64px 32px']}>
        <Flex direction={{base: 'column', md: 'row'}} mb={0}>
          <Box flex="5" paddingRight={8}>
            <Stack direction={{base: 'column', md: 'row'}} spacing={8}>
              <Link href="/" passHref>
                <div
                  style={{
                    position: 'relative',
                    height: '40px',
                    width: '49px',
                    marginBottom: '24px'
                  }}
                >
                  <Image
                    src="/images/logos/SIA_Logo_Full_Light.svg"
                    layout="fill"
                    alt="SIA Logo"
                    loader={imgproxyOptimizer}
                  />
                </div>
              </Link>
              <Text fontSize="sm">© {currentYear} Sales Impact Academy Inc</Text>
              <Text fontSize="sm">
                <Links href="https://www.salesimpact.io/">www.salesimpact.io</Links>
              </Text>
              <Text fontSize="sm">
                <Links href="https://www.salesimpact.io/privacy-policy/">Privacy Policy</Links>
              </Text>
              <Text fontSize="sm">
                <Links href="https://www.salesimpact.io/terms-and-conditions/">
                  Terms and Conditions
                </Links>
              </Text>
              <Text fontSize="sm">
                <Links href="https://app.drata.com/trust/9cb42296-0c38-11ee-865f-029d78a187d9">
                  Security
                </Links>
              </Text>
              <Text fontSize="sm">
                <Links href="https://status.salesimpact.io/">Status</Links>
              </Text>
            </Stack>
          </Box>
          <Box flex="1" pr={9} minWidth={12}>
            <Box key="auth" textAlign="right">
              <Text fontSize="sm" mb={5}>
                {session ? (
                  <Button onClick={() => doSignOutEvent()} variant="outline">
                    Sign Out
                  </Button>
                ) : null}
              </Text>
            </Box>
          </Box>
        </Flex>
      </Box>
    </>
  );
};
