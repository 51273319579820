import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Text,
  Box,
  Heading,
  ModalBody,
  Button
} from '@chakra-ui/react';
import Image from 'next/image';
import {imgproxyOptimizer} from 'utils/images/imageOptimization/imgproxyOptimizer';

interface ErrorProps {
  onOpen?: () => void;
  onClose: () => void;
  isOpen: boolean;
  title: string;
  message: string;
}

export default function ErrorModal({
  title,
  message,
  onClose,
  isOpen
}: ErrorProps): React.ReactElement<ErrorProps> {
  return (
    <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent
        justifyContent="center"
        alignItems="center"
        display="flex"
        flexDirection="column"
        padding="24px"
        maxWidth="420px"
      >
        <ModalHeader display="flex" flexDirection="column" alignItems="center" padding={0}>
          <Image
            width="42"
            height="42"
            src="/images/icons/error-icon.svg"
            loader={imgproxyOptimizer}
            alt="Quote Icon"
          />
          <Heading fontSize="20px" mt={6} mb={0} fontWeight="600" textTransform="capitalize">
            {title}
          </Heading>
        </ModalHeader>
        <ModalBody display="flex" flexDirection="column" alignItems="center">
          <Box width="100%" paddingBottom="20px">
            <Text textAlign="center" fontSize="16px" lineHeight="16px" color="textColor.200">
              {message}
            </Text>
          </Box>
          <Button
            variant="outline"
            margin="0 auto"
            fontSize="20px"
            fontWeight="600"
            onClick={() => window.HubSpotConversations.widget.open()}
          >
            Contact Support
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
