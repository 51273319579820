import {ApolloClient, ApolloLink, from, HttpLink} from '@apollo/client';
import {InMemoryCache, NormalizedCacheObject} from '@apollo/client/cache';
import {onError} from '@apollo/client/link/error';
import {setContext} from '@apollo/client/link/context';
import {Dispatch, SetStateAction, useMemo} from 'react';
import {generateHeadersFromTokenOrHeaders} from 'utils/headers';
import {getTokenFromHeaders} from 'utils/jwt';
import {IncomingHttpHeaders} from 'http';
import {getSession} from 'utils/session';
import fetch from 'isomorphic-unfetch';
import ISession from 'types/ISession';
import {catalogPath} from 'utils/routeFactory';
import {isExpiringSoon} from '../pages/api/session';

type SetSession = Dispatch<SetStateAction<ISession>>;

const jwtExpiredFriendlyMessageLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((op) => {
    op.errors?.forEach((error) => {
      if (error.message.includes('JWTExpired')) {
        error.message = 'Your session has expired. Please refresh the page and try again.';
      }
    });
    return op;
  });
});

const createErrorLink = () =>
  onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({message, locations, path}) => {
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      });
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });

const setAuthHeadersLink = (
  headers: IncomingHttpHeaders,
  setSession?: SetSession,
  exp?: number
) => {
  return setContext(async () => {
    let token = getTokenFromHeaders(headers);

    if (typeof exp === 'number' && isExpiringSoon(exp)) {
      try {
        const session = await getSession();
        if (session?.data?.token) {
          setSession?.(session);
          token = session?.data?.token;
        }
      } catch (err) {
        window.location.href = catalogPath().catalog();
      }
    }

    return {
      headers: {
        ...headers,
        ...(token && {Authorization: `Bearer ${token}`})
      }
    };
  });
};

export interface TokenOrHeaders {
  token?: string;
  headers?: IncomingHttpHeaders;
}

const createHttpLink = () => {
  const getHttpUri = () => {
    if (process.env.NODE_ENV === 'production') {
      return process.env.NEXT_PUBLIC_API_URL;
    }

    return typeof window
      ? process.env.NEXT_PUBLIC_CSR_API_URL
      : process.env.NEXT_PUBLIC_SSR_API_URL;
  };
  return new HttpLink({
    uri: getHttpUri(),
    credentials: 'include',
    fetch
  });
};

let apolloClient: ApolloClient<NormalizedCacheObject>;

const getLink = () => {
  return createHttpLink();
};

export const createApolloClient = (
  tokenOrHeaders: TokenOrHeaders,
  setSession?: SetSession,
  exp?: number
): ApolloClient<NormalizedCacheObject> => {
  const headers = generateHeadersFromTokenOrHeaders(tokenOrHeaders);
  const ssrMode = typeof window === 'undefined';
  return new ApolloClient({
    ssrMode,
    link: from([
      setAuthHeadersLink(headers, setSession, exp),
      jwtExpiredFriendlyMessageLink,
      createErrorLink(),
      getLink()
    ]),
    cache: new InMemoryCache()
  });
};

export const initializeApollo = (
  initialState = {},
  tokenOrHeaders: TokenOrHeaders,
  setSession?: SetSession,
  exp?: number
): ApolloClient<NormalizedCacheObject> => {
  if (apolloClient) {
    const headers = generateHeadersFromTokenOrHeaders(tokenOrHeaders);
    apolloClient.setLink(
      from([
        setAuthHeadersLink(headers, setSession, exp),
        jwtExpiredFriendlyMessageLink,
        createErrorLink(),
        getLink()
      ])
    );
  }
  const _apolloClient = apolloClient ?? createApolloClient(tokenOrHeaders, setSession, exp);

  if (initialState) {
    const existingCache = _apolloClient.extract();

    _apolloClient.cache.restore({...existingCache, ...initialState});
  }
  if (typeof window === 'undefined') {
    return _apolloClient;
  }

  if (!apolloClient) {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
};

export function useApollo(
  initialState: NormalizedCacheObject,
  tokenOrHeaders: TokenOrHeaders,
  loadedSession: boolean,
  setSession?: SetSession,
  exp?: number
): ApolloClient<NormalizedCacheObject> | null {
  return useMemo(() => {
    if (!loadedSession) return null;

    return initializeApollo(initialState, tokenOrHeaders, setSession, exp);
  }, [initialState, tokenOrHeaders, loadedSession, setSession, exp]);
}
