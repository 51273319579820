/***************************************
 **** READ the utils/env/README.md *****
 ***************************************/

export const appVersion = process.env.NEXT_PUBLIC_APP_VERSION;
export const appName = process.env.NEXT_PUBLIC_APP_NAME;
export const appEnv = process.env.NEXT_PUBLIC_APP_ENV;

/////////////////////////////
//          NEXT           //
/////////////////////////////
export const nextPublicApiUrl = process.env.NEXT_PUBLIC_API_URL;
export const nextPublicAppHostUrl = process.env.NEXT_PUBLIC_APP_HOST_URL;

/////////////////////////////
//          Google         //
/////////////////////////////
export const googleClientID = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID;
export const googleIdentityID = process.env.NEXT_PUBLIC_GOOGLE_IDENTITY_ID;

/////////////////////////////
//          Secret         //
/////////////////////////////
export const csrfSecret = process.env.NEXT_PUBLIC_CSRF_SECRET;

/////////////////////////////
//         PostHog         //
/////////////////////////////
export const posthogApiKey = process.env.NEXT_PUBLIC_POSTHOG_API_KEY;
export const posthogApiHost = process.env.NEXT_PUBLIC_POSTHOG_API_HOST;
export const sendLocalDevPosthogEvents = process.env.NEXT_PUBLIC_SEND_LOCAL_DEV_POSTHOG_EVENTS;

/////////////////////////////
//        Resources        //
/////////////////////////////
export const courseCompletionCertificatesHost =
  process.env.NEXT_PUBLIC_COURSE_COMPLETION_CERTIFICATES_HOST;

///////////////////////////////
// Class Recording Threshold //
///////////////////////////////
export const classRecordingThreshold = process.env.NEXT_PUBLIC_CLASS_RECORDING_THRESHOLD;

////////////////////////
// Placeholder Images //
////////////////////////
// These are declared in next.config.js file to be served to the browser
export const courseImagePlaceholder = process.env.courseImagePlaceholder;
export const coachAvatarPlaceholder = process.env.coachAvatarPlaceholder;

/////////
// CDN //
/////////
export const mediaCdnUrl = process.env.NEXT_PUBLIC_MEDIA_CDN_URL;

//////////////////////
// DataDog Frontend //
//////////////////////
export const dataDogApplicationId = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID;
export const dataDogClientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;
export const dataDogSite = process.env.NEXT_PUBLIC_DATADOG_SITE;

/////////////////////////////
//          Stream         //
/////////////////////////////
export const streamApiKey = process.env.NEXT_PUBLIC_STREAM_API_KEY;

/////////////////////////////
//          Playbook       //
/////////////////////////////
export const enablePlaybook = process.env.ENABLE_PLAYBOOK === 'true';
