import posthog from 'posthog-js';
import {PUBLIC_ENV} from 'utils/env/getter';
import {sendLocalDevPosthogEvents} from 'utils/env/public';
import {isAllowedAnalyticsIdentityId} from 'utils/analytics/isAllowedAnalyticsIdentityId';
import AnalyticsClient, {IdentifyUserProps, TrackEventsProps, TrackPageProps} from 'lib/types';
import User from 'types/User';
import {formatAnalyticsJoinDateYYYYMM} from 'utils/dates/formatAnalyticsClientDate';

// Only capture events for dev environemnts if called out with an env var.
const sendPosthogEvents =
  /true/gi.test(sendLocalDevPosthogEvents ?? 'false') || process.env.NODE_ENV === 'production';

class AnalyticsWebClient implements AnalyticsClient {
  private isUnknownUser = true;
  private userId: string | null = null;

  constructor(user?: User, userEmail?: string) {
    posthog.init(PUBLIC_ENV.posthogApiKey ?? '', {
      api_host: PUBLIC_ENV.posthogApiHost ?? '',
      opt_in_site_apps: true,
      loaded: () => {
        // If no userId is passed in, posthog will create an anonymousId for the user
        // that we can later reference with posthog.get_distinct_id()
        if (user?.id) {
          const userPropertiesToSet = {
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: userEmail
          };
          posthog.identify(user.id, userPropertiesToSet);
          this.isUnknownUser = false;
          this.reloadFeatureFlag();
        }
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } as posthog.Config);
  }

  /*
   ** If unknown user, alias posthog anonymousId to known userId.
   */
  public identifyUser({userId, userPropsToSet}: IdentifyUserProps): void {
    if (this.isUnknownUser && !this.userId && isAllowedAnalyticsIdentityId(userId)) {
      const userPropertiesToSet = {
        firstName: userPropsToSet?.firstName,
        lastName: userPropsToSet?.lastName,
        email: userPropsToSet?.email,
        joinDateYYYYMM: formatAnalyticsJoinDateYYYYMM(userPropsToSet?.joinDate)
      };
      this.userId = userId;
      posthog.identify(userId, userPropertiesToSet);
      this.reloadFeatureFlag();
    }
  }

  /*
   ** Capture an event. This is the most important and frequently used PostHog function.
   */
  public trackEvent({eventName, properties}: TrackEventsProps): void {
    posthog.capture(eventName, properties);
  }
  /*
   ** Capture a pageview event.
   */
  public trackPage({properties}: TrackPageProps): void {
    this.trackEvent({eventName: '$pageview', properties});
  }
  /*
   ** Call when a user logs out to unset any distinct_ids
   */
  public destroy(): void {
    posthog.reset();
  }

  public checkFeatureFlag(featureFlag: string, _?: string, defaultValue = false): boolean {
    try {
      return Boolean(posthog.isFeatureEnabled(featureFlag));
    } catch (e) {
      console.error(
        {featureFlag, error: e},
        'Error checking feature flag. Returning default value'
      );
      return defaultValue;
    }
  }

  public reloadFeatureFlag() {
    posthog.reloadFeatureFlags();
  }
}

if (!sendPosthogEvents) {
  /* eslint-disable-next-line */
  // @ts-expect-error
  AnalyticsWebClient = function () {}; // eslint-disable-line @typescript-eslint/no-empty-function
  const voidFn = () => {
    return;
  };
  Object.assign(AnalyticsWebClient.prototype, {
    identifyUser: voidFn,
    trackPage: voidFn,
    trackEvent: voidFn,
    destroy: voidFn,
    checkFeatureFlag: voidFn
  });
}

const analyticsWebClientCheck = () => {
  return AnalyticsWebClient;
};

export const analyticsWebClient = analyticsWebClientCheck();
