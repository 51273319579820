import {IncomingHttpHeaders} from 'http';
import {TokenOrHeaders} from 'lib/apolloClient';
import Cookies from 'cookies';
import {getJwtFromSession} from 'utils/user';
import jwt from 'jsonwebtoken';
import {SERVER_ENV} from 'utils/env/getter';
import {NextApiRequest, NextApiResponse} from 'next';

export function generateApolloClientAdminHeaders(): IncomingHttpHeaders {
  const headers: IncomingHttpHeaders = {};
  headers['x-hasura-admin-secret'] = process.env.HASURA_GRAPHQL_ADMIN_SECRET as string;
  return headers;
}
// TODO - replace the logic in this function for logic in generateApolloClientAdminHeaders when updating to apolloClient
export function generateAdminHeaders(): Headers {
  const headers = new Headers();
  headers.set('x-hasura-admin-secret', process.env.HASURA_GRAPHQL_ADMIN_SECRET as string);
  return headers;
}

export function generateHeadersFromTokenOrHeaders(
  tokenOrHeaders: TokenOrHeaders
): IncomingHttpHeaders {
  const headers = tokenOrHeaders.headers ?? {};
  if (tokenOrHeaders.token) {
    headers.Authorization = `Bearer ${tokenOrHeaders.token}` as string;
  } else if (tokenOrHeaders?.token?.length === 0) {
    headers['x-hasura-role'] = 'anonymous' as string;
  }
  return headers;
}

export function generateHeadersFromRequestHeaders(req: NextApiRequest) {
  const headers: IncomingHttpHeaders = {};
  if (req.headers.authorization) {
    headers.Authorization = req.headers.authorization;
    headers['x-hasura-role'] = 'user';
  } else {
    headers['x-hasura-role'] = 'anonymous';
  }
  return headers;
}

export const generateHeadersFromSessionCookie = (req: NextApiRequest, resp: NextApiResponse) => {
  const sessionToken = new Cookies(req, resp).get('sia-session');
  const authorizationToken = getJwtFromSession(sessionToken as string);

  // only need to verify jwt valid
  jwt.verify(authorizationToken, SERVER_ENV.JWTSecret as string);

  // create the graphql request based on token and let
  // hasura take over based in table permissions
  return generateHeadersFromTokenOrHeaders({
    token: authorizationToken
  });
};
