import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  Text,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
  useMediaQuery,
  VStack
} from '@chakra-ui/react';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars} from '@fortawesome/free-solid-svg-icons';
import Link from 'next/link';
import Image from 'next/image';
import {useSignInModal} from 'context/SignInModalContext';
import {useSession} from 'context/SessionContext';
import {catalogPath, coachPath, learnPath, managePath, upcomingCohorts} from 'utils/routeFactory';
import {imgproxyOptimizer} from 'utils/images/imageOptimization/imgproxyOptimizer';
import {useSearchParams} from 'next/navigation';

interface LinkProps {
  title: string;
  href: string;
  newTab?: boolean;
}
const basedURLs = [
  {title: 'Discover', href: catalogPath().catalog()},
  {title: 'Upcoming', href: upcomingCohorts().upcoming()}
];
const signedOutLinksBase = [...basedURLs];
const signedInLinksBase = [{title: 'Learn', href: learnPath().home()}, ...basedURLs];

export function Header(): JSX.Element {
  const {data: session} = useSession();
  const {openSignInModal} = useSignInModal();
  const ref = useRef<null | HTMLDivElement>(null);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const isLoggedIn = session !== undefined;
  const userAbilities = session?.user.abilities;
  const searchParams = useSearchParams();

  const [signedInLinks, setSignedInLinks] = useState<LinkProps[]>([...signedInLinksBase]);

  const isCoach = useMemo(() => {
    return userAbilities?.coachDashboard === true;
  }, [userAbilities?.coachDashboard]);

  const signedOutLinks = useMemo(() => {
    return [...signedOutLinksBase];
  }, []);

  const isManager = useMemo(() => {
    return userAbilities?.teamsReport === true;
  }, [userAbilities?.teamsReport]);

  const isImpersonating = session?.impersonator;

  useEffect(() => {
    if (isCoach && signedInLinks.some((e) => e.title === 'Coach') === false) {
      setSignedInLinks([{title: 'Coach', href: coachPath().home()}, ...signedInLinks]);
    }
    // Set links here that require explicit permissions for user to have access to
    if (isManager && signedInLinks.some((e) => e.title === 'Manage') === false) {
      setSignedInLinks([{title: 'Manage', href: managePath().home()}, ...signedInLinks]);
    }
  }, [isCoach, isManager, signedInLinks]);

  const renderedLink = useMemo<{title: string; href: string; newTab?: boolean}[]>(
    () =>
      (isLoggedIn ? signedInLinks : signedOutLinks) as {
        title: string;
        href: string;
        newTab?: boolean;
      }[],
    [isLoggedIn, signedInLinks, signedOutLinks]
  );
  const [isLargerThan1000] = useMediaQuery('(min-width: 1000px)');

  const renderUserStatus = useMemo(
    () => (
      <>
        {!isLoggedIn ? (
          <>
            <Button
              size="md"
              onClick={() => {
                openSignInModal(searchParams?.get('redirectUrl') ?? undefined);
                onClose();
              }}
              variant="outline"
            >
              Sign In
            </Button>
          </>
        ) : (
          <Flex alignItems="center">
            <Box marginRight={4}>
              <Avatar
                name={`${session.user.firstName} ${session.user.lastName}`}
                src={session.user.imageUrl}
              />
            </Box>
            <Box>
              <Text fontSize="sm">
                {session.user.firstName} {session.user.lastName}
              </Text>
              <Text fontSize="xs" color="gray.300">
                {session.user.selectedOrg.name}
              </Text>
            </Box>
          </Flex>
        )}
      </>
    ),
    [session, isLoggedIn, openSignInModal, onClose, searchParams]
  );

  return (
    <>
      {isImpersonating && (
        <VStack backgroundColor="berry" position="fixed" width="100%" zIndex={10}>
          <Text color="white">
            You are impersonating user {`${session?.user.firstName} ${session?.user.lastName}`}{' '}
            (Organization: {session?.user.selectedOrg?.name}) and all clicks will be performed on
            this user&apos;s behalf.
          </Text>
        </VStack>
      )}
      <>
        <Flex
          ref={ref}
          rowGap={2}
          columnGap={0}
          paddingY={8}
          paddingInline={[6, 16]}
          alignItems="center"
          position="relative"
        >
          <Link href="/" passHref>
            <div style={{position: 'relative', height: '40px', width: '40px'}}>
              <Image
                src="/images/logos/Light.svg"
                layout="fill"
                alt="SIA Logo"
                loader={imgproxyOptimizer}
              />
            </div>
          </Link>
          {isLargerThan1000 && (
            <Flex marginLeft={[6, 16]} flex={1} gap=".5rem">
              {renderedLink.map((link, index) => (
                <Center key={`link-${index}`} marginX={3}>
                  <Link href={link.href} passHref target={link.newTab ? '_blank' : undefined}>
                    <Button variant="ghost" colorScheme="white" size="md">
                      {link.title}
                    </Button>
                  </Link>
                </Center>
              ))}
            </Flex>
          )}
          {isLargerThan1000 ? (
            <Flex height="100%" alignItems="center" justifyContent="flex-end">
              {renderUserStatus}
            </Flex>
          ) : (
            <Flex alignItems="center" justifyContent="flex-end" flex={1}>
              <FontAwesomeIcon
                icon={faBars}
                size="lg"
                onClick={onOpen}
                focusable
                cursor="pointer"
              />
            </Flex>
          )}
        </Flex>
        <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth="1px">{renderUserStatus}</DrawerHeader>
            <DrawerBody>
              {renderedLink.map((link, index) => (
                <VStack alignItems="flex-start" key={`link-${index}`}>
                  <Flex>
                    <Link
                      href={link.href}
                      passHref
                      target={link.newTab ? '_blank' : undefined}
                      onClick={() => onClose()}
                    >
                      <Button variant="ghost">{link.title}</Button>
                    </Link>
                  </Flex>
                </VStack>
              ))}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    </>
  );
}
