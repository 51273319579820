/***************************************
 **** READ the utils/env/README.md *****
 ***************************************/

/////////////////////////////
//          Auth           //
/////////////////////////////
export const applicationId = process.env.FUSIONAUTH_APPLICATION_ID;
export const fusionAuthTenantId = process.env.FUSIONAUTH_TENANT_ID;
export const fusionAuthRegistrationURL = process.env.FUSIONAUTH_REGISTRATION_URL;
export const fusionAuthClientID = process.env.FUSIONAUTH_CLIENT_ID;

/**
 * @deprecated
 */
export const fusionAuthClientSecret = process.env.FUSIONAUTH_CLIENT_SECRET;

export const fusionAuthAPIKey = process.env.FUSIONAUTH_API_KEY;
export const fusionAuthURL = process.env.FUSIONAUTH_URL;
export const systemUserId = process.env.SYSTEM_USER_ID;

/////////////////////////////
//          Secrets        //
/////////////////////////////
export const hasuraSecret = process.env.HASURA_GRAPHQL_ADMIN_SECRET;
export const JWTSecret = process.env.JWT_SECRET;
export const cryptoKey = process.env.CRYPTO_KEY;
export const cryptoAlgorithm = process.env.CRYPTO_ALGORITHM;
export const cryptoIV = process.env.CRYPTO_IV;
export const hasuraActionSecret = process.env.HASURA_ACTION_SECRET;
export const hubspotAppSecret = process.env.HUBSPOT_APP_SECRET;

/////////////////////////////
//        Mail             //
/////////////////////////////
export const eventsFromEmail = process.env.EVENTS_FROM_EMAIL;
export const smtpPort = process.env.SMTP_PORT;
export const smtpHost = process.env.SMTP_HOST;
export const smtpUsername = process.env.SMTP_USERNAME;
export const smtpPassword = process.env.SMTP_PASSWORD;
export const smtpSecure = process.env.SMTP_SECURE;
export const maxEventsPerInvite = process.env.MAX_EVENTS_PER_INVITE ?? 1;

/////////////////////////////
//        Zoom             //
/////////////////////////////
export const zoomAccountId = process.env.ZOOM_ACCOUNT_ID;
export const zoomOauthClientId = process.env.ZOOM_OAUTH_CLIENT_ID;
export const zoomOauthClientSecret = process.env.ZOOM_OAUTH_CLIENT_SECRET;
export const zoomAccountId_2 = process.env.ZOOM_ACCOUNT_ID_2;
export const zoomOauthClientId_2 = process.env.ZOOM_OAUTH_CLIENT_ID_2;
export const zoomOauthClientSecret_2 = process.env.ZOOM_OAUTH_CLIENT_SECRET_2;

// @deprecated
export const zoomApiKey = process.env.ZOOM_API_KEY;
// @deprecated
export const zoomApiSecret = process.env.ZOOM_API_SECRET;
export const zoomRefreshTokenDisabled = process.env.ZOOM_REFRESH_TOKEN_DISABLED ?? false;

///////////////////////////
//        Daily.co       //
///////////////////////////
export const dailyApiKey = process.env.DAILY_API_KEY;
export const dailyDomainId = process.env.DAILY_DOMAIN_ID;
export const dailySyncDisabled = process.env.DAILY_SYNC_DISABLED === 'true';

////////////
// AWS S3 //
////////////
export const s3Region = process.env.S3_REGION;
export const dailyCallRecordingsRole = process.env.DAILY_CALL_RECORDINGS_ROLE;
export const mediaConvertJobRole = process.env.MEDIA_CONVERT_JOB_ROLE;
export const mediaConvertEndpoint = process.env.MEDIA_CONVERT_ENDPOINT;
export const transcribeEndpoint = process.env.TRANSCRIBE_ENDPOINT;

/////////////////////////////
//          Reports        //
/////////////////////////////
export const sigmaEmbedSecret = process.env.SIGMA_EMBED_SECRET;
export const sigmaClientId = process.env.SIGMA_CLIENT_ID;
export const sigmaEmbedSessionLength = process.env.SIGMA_EMBED_SESSION_LENGTH;
export const localDevReportsUserId = process.env.LOCAL_DEV_REPORTS_USER_ID;

////////////
// Plans //
///////////
export const hubspotCreateUserPlanSlug = process.env.HUBSPOT_CREATE_USER_PLAN_SLUG;

//////////////
// Imgproxy //
//////////////
export const imgproxyKey = process.env.IMGPROXY_KEY;
export const imgproxySalt = process.env.IMGPROXY_SALT;
export const imgProxyBaseUrl = process.env.IMGPROXY_BASE_URL;

//////////////////
// Media Bucket //
//////////////////
export const s3MediaBucket = process.env.S3_MEDIA_BUCKET_NAME;

/////////////////////////////
//          HubSpot        //
/////////////////////////////
export const hubSpotPrivateAppAccessToken = process.env.HUBSPOT_PRIVATE_APP_ACCESS_TOKEN;
export const hubSpotMarketingSubscriptionTypeId =
  process.env.HUBSPOT_MARKETING_SUBSCRIPTION_TYPE_ID;

/////////////////////////////
//         PostHog         //
/////////////////////////////
export const posthogApiKey = process.env.NEXT_PUBLIC_POSTHOG_API_KEY;
export const posthogPersonalApiKey = process.env.POSTHOG_PERSONAL_API_KEY;

/////////////////////////////
//     Google Recaptcha    //
/////////////////////////////
export const googleRecaptchaSiteKey = process.env.GOOGLE_RECAPTCHA_SITE_KEY;
export const googleRecaptchaSecretKey = process.env.GOOGLE_RECAPTCHA_SECRET_KEY;

/////////////////////////////
//     Google SSO APP      //
/////////////////////////////
export const googleClientSecret = process.env.GOOGLE_CLIENT_SECRET;

/////////////////////////////
//         Typeform        //
/////////////////////////////
export const typeformWebhookSecret = process.env.TYPEFORM_WEBHOOK_SECRET;

/////////////////////////////
//          Stream         //
/////////////////////////////
export const streamApiSecret = process.env.STREAM_API_SECRET;

/////////////////////////////
//        Recordings       //
/////////////////////////////
export const recordingsSyncDisabled = process.env.RECORDINGS_SYNC_DISABLED === 'true';

/////////////////////////////
//        Snowflake        //
/////////////////////////////
export const snowflakeAccessUrl = process.env.SNOWFLAKE_URL;
export const snowflakeAccount = process.env.SNOWFLAKE_ACCOUNT;
export const snowflakeWarehouse = process.env.SNOWFLAKE_WAREHOUSE;
export const snowflakeUsername = process.env.SNOWFLAKE_USERNAME;
export const snowflakePassword = process.env.SNOWFLAKE_PASSWORD;

///////////////////////////
//        Open AI        //
///////////////////////////
export const openaiApiKey = process.env.OPENAI_API_KEY;
