import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Text,
  Box,
  Heading
} from '@chakra-ui/react';
import {faEnvelope, faHourglass} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useEffect, useState} from 'react';

interface ErrorProps {
  onOpen: () => void;
  onClose: () => void;
  isOpen: boolean;
  title: string;
  email: string;
  resendFn: (email: string, forceResend: boolean) => void;
}

export default function SignInSuccessModal({
  title,
  email,
  onClose,
  isOpen,
  resendFn
}: ErrorProps): React.ReactElement<ErrorProps> {
  const [resendButtonEnabled, setResendButtonEnabled] = useState(true);

  // 1 minute Cooldown before allowing retries
  useEffect(() => {
    if (!resendButtonEnabled) {
      const timer = setTimeout(() => setResendButtonEnabled(true), 1000 * 60);
      return () => clearTimeout(timer);
    }
  }, [resendButtonEnabled]);

  const handleResend = async () => {
    await resendFn(email, true);
    setResendButtonEnabled(false);
  };

  return (
    <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent
        justifyContent="center"
        alignItems="center"
        display="flex"
        flexDirection="column"
        padding="64px"
        maxWidth="640px"
      >
        <ModalHeader display="flex" flexDirection="column" alignItems="center">
          <Box>
            <FontAwesomeIcon icon={faEnvelope} size="3x" color="brand.500" />
          </Box>
          <Heading size="lg" mt={4}>
            {title}
          </Heading>
        </ModalHeader>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          paddingX="16px"
          width="100%"
          paddingBottom="16px"
        >
          <Text textAlign="center" fontSize="lg">
            An email has been sent to <b>{email}</b> with a magic link to Sign In. If you do not see
            the email within 5 minutes please check your spam folder.
          </Text>
          <Button
            onClick={handleResend}
            marginTop={4}
            paddingX={16}
            leftIcon={<FontAwesomeIcon icon={resendButtonEnabled ? faEnvelope : faHourglass} />}
            disabled={!resendButtonEnabled}
          >
            {resendButtonEnabled ? 'Resend magic link' : 'Wait 1 minute before retrying...'}
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  );
}
