import {GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig} from 'graphql';
import {gql} from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>};
export type RequireFields<T, K extends keyof T> = {[X in Exclude<keyof T, K>]?: T[X]} & {
  [P in K]-?: NonNullable<T[P]>;
};
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  bpchar: any;
  date: any;
  float8: any;
  json: any;
  jsonb: any;
  numeric: number;
  smallint: any;
  timestamp: any;
  timestamptz: string;
  uuid: any;
};

/** columns and relationships of "activity_type_enum" */
export type ActivityType = {
  __typename?: 'ActivityType';
  value: Scalars['String'];
};

/** aggregated selection of "activity_type_enum" */
export type ActivityTypeAggregate = {
  __typename?: 'ActivityTypeAggregate';
  aggregate?: Maybe<ActivityTypeAggregateFields>;
  nodes: Array<ActivityType>;
};

/** aggregate fields of "activity_type_enum" */
export type ActivityTypeAggregateFields = {
  __typename?: 'ActivityTypeAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<ActivityTypeMaxFields>;
  min?: Maybe<ActivityTypeMinFields>;
};

/** aggregate fields of "activity_type_enum" */
export type ActivityTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ActivityTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "activity_type_enum". All fields are combined with a logical 'AND'. */
export type ActivityTypeBoolExp = {
  _and?: InputMaybe<Array<ActivityTypeBoolExp>>;
  _not?: InputMaybe<ActivityTypeBoolExp>;
  _or?: InputMaybe<Array<ActivityTypeBoolExp>>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "activity_type_enum" */
export enum ActivityTypeConstraint {
  /** unique or primary key constraint on columns "value" */
  activity_type_enum_pk = 'activity_type_enum_pk'
}

export enum ActivityTypeEnum {
  ASK_QUESTION = 'ASK_QUESTION',
  BEGIN_CLASS = 'BEGIN_CLASS',
  CLASS_COMPLETE = 'CLASS_COMPLETE',
  END_CLASS = 'END_CLASS',
  JOIN_ZOOM = 'JOIN_ZOOM',
  LAUNCH_SURVEY = 'LAUNCH_SURVEY',
  LEAVE_ZOOM = 'LEAVE_ZOOM',
  PREWORK_COMPLETE = 'PREWORK_COMPLETE',
  RAISE_HAND = 'RAISE_HAND',
  READ_TRANSCRIPT = 'READ_TRANSCRIPT',
  START_PLAYBACK = 'START_PLAYBACK',
  STOP_PLAYBACK = 'STOP_PLAYBACK',
  UPDATE_PLAYBACK = 'UPDATE_PLAYBACK',
  VIEWED_FEEDBACK = 'VIEWED_FEEDBACK',
  WATCHED_RECORDING = 'WATCHED_RECORDING'
}

/** Boolean expression to compare columns of type "ActivityTypeEnum". All fields are combined with logical 'AND'. */
export type ActivityTypeEnumComparisonExp = {
  _eq?: InputMaybe<ActivityTypeEnum>;
  _in?: InputMaybe<Array<ActivityTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ActivityTypeEnum>;
  _nin?: InputMaybe<Array<ActivityTypeEnum>>;
};

/** input type for inserting data into table "activity_type_enum" */
export type ActivityTypeInsertInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ActivityTypeMaxFields = {
  __typename?: 'ActivityTypeMaxFields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ActivityTypeMinFields = {
  __typename?: 'ActivityTypeMinFields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "activity_type_enum" */
export type ActivityTypeMutationResponse = {
  __typename?: 'ActivityTypeMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ActivityType>;
};

/** on_conflict condition type for table "activity_type_enum" */
export type ActivityTypeOnConflict = {
  constraint: ActivityTypeConstraint;
  updateColumns?: Array<ActivityTypeUpdateColumn>;
  where?: InputMaybe<ActivityTypeBoolExp>;
};

/** Ordering options when selecting data from "activity_type_enum". */
export type ActivityTypeOrderBy = {
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: activity_type_enum */
export type ActivityTypePkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "activity_type_enum" */
export enum ActivityTypeSelectColumn {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "activity_type_enum" */
export type ActivityTypeSetInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ActivityType" */
export type ActivityTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ActivityTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ActivityTypeStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "activity_type_enum" */
export enum ActivityTypeUpdateColumn {
  /** column name */
  value = 'value'
}

export type ActivityTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ActivityTypeSetInput>;
  /** filter the rows which have to be updated */
  where: ActivityTypeBoolExp;
};

export type AddUserToCourseWaitlistOutput = {
  __typename?: 'AddUserToCourseWaitlistOutput';
  details?: Maybe<Array<Maybe<Scalars['String']>>>;
  success: Scalars['Boolean'];
};

/** columns and relationships of "attempt_status_enum" */
export type AttemptStatus = {
  __typename?: 'AttemptStatus';
  value: Scalars['String'];
};

/** aggregated selection of "attempt_status_enum" */
export type AttemptStatusAggregate = {
  __typename?: 'AttemptStatusAggregate';
  aggregate?: Maybe<AttemptStatusAggregateFields>;
  nodes: Array<AttemptStatus>;
};

/** aggregate fields of "attempt_status_enum" */
export type AttemptStatusAggregateFields = {
  __typename?: 'AttemptStatusAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<AttemptStatusMaxFields>;
  min?: Maybe<AttemptStatusMinFields>;
};

/** aggregate fields of "attempt_status_enum" */
export type AttemptStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AttemptStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "attempt_status_enum". All fields are combined with a logical 'AND'. */
export type AttemptStatusBoolExp = {
  _and?: InputMaybe<Array<AttemptStatusBoolExp>>;
  _not?: InputMaybe<AttemptStatusBoolExp>;
  _or?: InputMaybe<Array<AttemptStatusBoolExp>>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "attempt_status_enum" */
export enum AttemptStatusConstraint {
  /** unique or primary key constraint on columns "value" */
  attempt_status_enum_pk = 'attempt_status_enum_pk'
}

export enum AttemptStatusEnum {
  GRADED = 'GRADED',
  IN_PROGRESS = 'IN_PROGRESS',
  PAUSED = 'PAUSED',
  REVIEW = 'REVIEW',
  SUBMITTED = 'SUBMITTED',
  TIMED_OUT = 'TIMED_OUT'
}

/** Boolean expression to compare columns of type "AttemptStatusEnum". All fields are combined with logical 'AND'. */
export type AttemptStatusEnumComparisonExp = {
  _eq?: InputMaybe<AttemptStatusEnum>;
  _in?: InputMaybe<Array<AttemptStatusEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<AttemptStatusEnum>;
  _nin?: InputMaybe<Array<AttemptStatusEnum>>;
};

/** input type for inserting data into table "attempt_status_enum" */
export type AttemptStatusInsertInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type AttemptStatusMaxFields = {
  __typename?: 'AttemptStatusMaxFields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AttemptStatusMinFields = {
  __typename?: 'AttemptStatusMinFields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "attempt_status_enum" */
export type AttemptStatusMutationResponse = {
  __typename?: 'AttemptStatusMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AttemptStatus>;
};

/** on_conflict condition type for table "attempt_status_enum" */
export type AttemptStatusOnConflict = {
  constraint: AttemptStatusConstraint;
  updateColumns?: Array<AttemptStatusUpdateColumn>;
  where?: InputMaybe<AttemptStatusBoolExp>;
};

/** Ordering options when selecting data from "attempt_status_enum". */
export type AttemptStatusOrderBy = {
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: attempt_status_enum */
export type AttemptStatusPkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "attempt_status_enum" */
export enum AttemptStatusSelectColumn {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "attempt_status_enum" */
export type AttemptStatusSetInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "AttemptStatus" */
export type AttemptStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AttemptStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AttemptStatusStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "attempt_status_enum" */
export enum AttemptStatusUpdateColumn {
  /** column name */
  value = 'value'
}

export type AttemptStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AttemptStatusSetInput>;
  /** filter the rows which have to be updated */
  where: AttemptStatusBoolExp;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type BigintComparisonExp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "bpchar". All fields are combined with logical 'AND'. */
export type BpcharComparisonExp = {
  _eq?: InputMaybe<Scalars['bpchar']>;
  _gt?: InputMaybe<Scalars['bpchar']>;
  _gte?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['bpchar']>;
  _in?: InputMaybe<Array<Scalars['bpchar']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['bpchar']>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['bpchar']>;
  _lt?: InputMaybe<Scalars['bpchar']>;
  _lte?: InputMaybe<Scalars['bpchar']>;
  _neq?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['bpchar']>;
  _nin?: InputMaybe<Array<Scalars['bpchar']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['bpchar']>;
};

/** columns and relationships of "catalogs_courses_rel" */
export type CatalogCourses = {
  __typename?: 'CatalogCourses';
  /** An object relationship */
  catalog: Catalogs;
  catalogId: Scalars['uuid'];
  /** An object relationship */
  course: Courses;
  courseId: Scalars['uuid'];
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  deletedBy?: Maybe<Users>;
  deletedById?: Maybe<Scalars['uuid']>;
  isEnrollable?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  updatedBy?: Maybe<Users>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "catalogs_courses_rel" */
export type CatalogCoursesAggregate = {
  __typename?: 'CatalogCoursesAggregate';
  aggregate?: Maybe<CatalogCoursesAggregateFields>;
  nodes: Array<CatalogCourses>;
};

export type CatalogCoursesAggregateBoolExp = {
  bool_and?: InputMaybe<CatalogCoursesAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<CatalogCoursesAggregateBoolExpBool_Or>;
  count?: InputMaybe<CatalogCoursesAggregateBoolExpCount>;
};

export type CatalogCoursesAggregateBoolExpBool_And = {
  arguments: CatalogCoursesSelectColumnCatalogCoursesAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CatalogCoursesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CatalogCoursesAggregateBoolExpBool_Or = {
  arguments: CatalogCoursesSelectColumnCatalogCoursesAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CatalogCoursesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CatalogCoursesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CatalogCoursesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CatalogCoursesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "catalogs_courses_rel" */
export type CatalogCoursesAggregateFields = {
  __typename?: 'CatalogCoursesAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<CatalogCoursesMaxFields>;
  min?: Maybe<CatalogCoursesMinFields>;
};

/** aggregate fields of "catalogs_courses_rel" */
export type CatalogCoursesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CatalogCoursesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "catalogs_courses_rel" */
export type CatalogCoursesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CatalogCoursesMaxOrderBy>;
  min?: InputMaybe<CatalogCoursesMinOrderBy>;
};

/** input type for inserting array relation for remote table "catalogs_courses_rel" */
export type CatalogCoursesArrRelInsertInput = {
  data: Array<CatalogCoursesInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<CatalogCoursesOnConflict>;
};

/** Boolean expression to filter rows from the table "catalogs_courses_rel". All fields are combined with a logical 'AND'. */
export type CatalogCoursesBoolExp = {
  _and?: InputMaybe<Array<CatalogCoursesBoolExp>>;
  _not?: InputMaybe<CatalogCoursesBoolExp>;
  _or?: InputMaybe<Array<CatalogCoursesBoolExp>>;
  catalog?: InputMaybe<CatalogsBoolExp>;
  catalogId?: InputMaybe<UuidComparisonExp>;
  course?: InputMaybe<CoursesBoolExp>;
  courseId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  deletedAt?: InputMaybe<TimestampComparisonExp>;
  deletedBy?: InputMaybe<UsersBoolExp>;
  deletedById?: InputMaybe<UuidComparisonExp>;
  isEnrollable?: InputMaybe<BooleanComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  updatedBy?: InputMaybe<UsersBoolExp>;
  updatedById?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "catalogs_courses_rel" */
export enum CatalogCoursesConstraint {
  /** unique or primary key constraint on columns "course_id", "catalog_id" */
  catalogs_courses_rel_pk = 'catalogs_courses_rel_pk'
}

/** input type for inserting data into table "catalogs_courses_rel" */
export type CatalogCoursesInsertInput = {
  catalog?: InputMaybe<CatalogsObjRelInsertInput>;
  catalogId?: InputMaybe<Scalars['uuid']>;
  course?: InputMaybe<CoursesObjRelInsertInput>;
  courseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedBy?: InputMaybe<UsersObjRelInsertInput>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  isEnrollable?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<UsersObjRelInsertInput>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CatalogCoursesMaxFields = {
  __typename?: 'CatalogCoursesMaxFields';
  catalogId?: Maybe<Scalars['uuid']>;
  courseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "catalogs_courses_rel" */
export type CatalogCoursesMaxOrderBy = {
  catalogId?: InputMaybe<OrderBy>;
  courseId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CatalogCoursesMinFields = {
  __typename?: 'CatalogCoursesMinFields';
  catalogId?: Maybe<Scalars['uuid']>;
  courseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "catalogs_courses_rel" */
export type CatalogCoursesMinOrderBy = {
  catalogId?: InputMaybe<OrderBy>;
  courseId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "catalogs_courses_rel" */
export type CatalogCoursesMutationResponse = {
  __typename?: 'CatalogCoursesMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CatalogCourses>;
};

/** on_conflict condition type for table "catalogs_courses_rel" */
export type CatalogCoursesOnConflict = {
  constraint: CatalogCoursesConstraint;
  updateColumns?: Array<CatalogCoursesUpdateColumn>;
  where?: InputMaybe<CatalogCoursesBoolExp>;
};

/** Ordering options when selecting data from "catalogs_courses_rel". */
export type CatalogCoursesOrderBy = {
  catalog?: InputMaybe<CatalogsOrderBy>;
  catalogId?: InputMaybe<OrderBy>;
  course?: InputMaybe<CoursesOrderBy>;
  courseId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedBy?: InputMaybe<UsersOrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  isEnrollable?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<UsersOrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: catalogs_courses_rel */
export type CatalogCoursesPkColumnsInput = {
  catalogId: Scalars['uuid'];
  courseId: Scalars['uuid'];
};

/** select columns of table "catalogs_courses_rel" */
export enum CatalogCoursesSelectColumn {
  /** column name */
  catalogId = 'catalogId',
  /** column name */
  courseId = 'courseId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  isEnrollable = 'isEnrollable',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById'
}

/** select "CatalogCoursesAggregateBoolExpBool_andArgumentsColumns" columns of table "catalogs_courses_rel" */
export enum CatalogCoursesSelectColumnCatalogCoursesAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  isEnrollable = 'isEnrollable'
}

/** select "CatalogCoursesAggregateBoolExpBool_orArgumentsColumns" columns of table "catalogs_courses_rel" */
export enum CatalogCoursesSelectColumnCatalogCoursesAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  isEnrollable = 'isEnrollable'
}

/** input type for updating data in table "catalogs_courses_rel" */
export type CatalogCoursesSetInput = {
  catalogId?: InputMaybe<Scalars['uuid']>;
  courseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  isEnrollable?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "CatalogCourses" */
export type CatalogCoursesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CatalogCoursesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CatalogCoursesStreamCursorValueInput = {
  catalogId?: InputMaybe<Scalars['uuid']>;
  courseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  isEnrollable?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "catalogs_courses_rel" */
export enum CatalogCoursesUpdateColumn {
  /** column name */
  catalogId = 'catalogId',
  /** column name */
  courseId = 'courseId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  isEnrollable = 'isEnrollable',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById'
}

export type CatalogCoursesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CatalogCoursesSetInput>;
  /** filter the rows which have to be updated */
  where: CatalogCoursesBoolExp;
};

/** columns and relationships of "catalogs" */
export type Catalogs = {
  __typename?: 'Catalogs';
  /** An array relationship */
  courses: Array<CatalogCourses>;
  /** An aggregate relationship */
  coursesAggregate: CatalogCoursesAggregate;
  createdAt: Scalars['timestamp'];
  /** An object relationship */
  createdBy: Users;
  createdById: Scalars['uuid'];
  deletedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  deletedBy?: Maybe<Users>;
  deletedById?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** An array relationship */
  journeys: Array<CatalogsJourneysRel>;
  /** An aggregate relationship */
  journeysAggregate: CatalogsJourneysRelAggregate;
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  updatedBy?: Maybe<Users>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "catalogs" */
export type CatalogsCoursesArgs = {
  distinctOn?: InputMaybe<Array<CatalogCoursesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CatalogCoursesOrderBy>>;
  where?: InputMaybe<CatalogCoursesBoolExp>;
};

/** columns and relationships of "catalogs" */
export type CatalogsCoursesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CatalogCoursesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CatalogCoursesOrderBy>>;
  where?: InputMaybe<CatalogCoursesBoolExp>;
};

/** columns and relationships of "catalogs" */
export type CatalogsJourneysArgs = {
  distinctOn?: InputMaybe<Array<CatalogsJourneysRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CatalogsJourneysRelOrderBy>>;
  where?: InputMaybe<CatalogsJourneysRelBoolExp>;
};

/** columns and relationships of "catalogs" */
export type CatalogsJourneysAggregateArgs = {
  distinctOn?: InputMaybe<Array<CatalogsJourneysRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CatalogsJourneysRelOrderBy>>;
  where?: InputMaybe<CatalogsJourneysRelBoolExp>;
};

/** aggregated selection of "catalogs" */
export type CatalogsAggregate = {
  __typename?: 'CatalogsAggregate';
  aggregate?: Maybe<CatalogsAggregateFields>;
  nodes: Array<Catalogs>;
};

/** aggregate fields of "catalogs" */
export type CatalogsAggregateFields = {
  __typename?: 'CatalogsAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<CatalogsMaxFields>;
  min?: Maybe<CatalogsMinFields>;
};

/** aggregate fields of "catalogs" */
export type CatalogsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CatalogsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "catalogs". All fields are combined with a logical 'AND'. */
export type CatalogsBoolExp = {
  _and?: InputMaybe<Array<CatalogsBoolExp>>;
  _not?: InputMaybe<CatalogsBoolExp>;
  _or?: InputMaybe<Array<CatalogsBoolExp>>;
  courses?: InputMaybe<CatalogCoursesBoolExp>;
  coursesAggregate?: InputMaybe<CatalogCoursesAggregateBoolExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  deletedAt?: InputMaybe<TimestampComparisonExp>;
  deletedBy?: InputMaybe<UsersBoolExp>;
  deletedById?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  journeys?: InputMaybe<CatalogsJourneysRelBoolExp>;
  journeysAggregate?: InputMaybe<CatalogsJourneysRelAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  updatedBy?: InputMaybe<UsersBoolExp>;
  updatedById?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "catalogs" */
export enum CatalogsConstraint {
  /** unique or primary key constraint on columns "id" */
  catalogs_pk = 'catalogs_pk',
  /** unique or primary key constraint on columns "slug" */
  catalogs_slug_key = 'catalogs_slug_key',
  /** unique or primary key constraint on columns "slug", "deleted_at" */
  catalogs_uniq_slug = 'catalogs_uniq_slug'
}

/** input type for inserting data into table "catalogs" */
export type CatalogsInsertInput = {
  courses?: InputMaybe<CatalogCoursesArrRelInsertInput>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedBy?: InputMaybe<UsersObjRelInsertInput>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  journeys?: InputMaybe<CatalogsJourneysRelArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<UsersObjRelInsertInput>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "catalogs_journeys_rel" */
export type CatalogsJourneysRel = {
  __typename?: 'CatalogsJourneysRel';
  /** An object relationship */
  catalog?: Maybe<Catalogs>;
  catalogId: Scalars['uuid'];
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  journey?: Maybe<Journeys>;
  journeyId: Scalars['uuid'];
};

/** aggregated selection of "catalogs_journeys_rel" */
export type CatalogsJourneysRelAggregate = {
  __typename?: 'CatalogsJourneysRelAggregate';
  aggregate?: Maybe<CatalogsJourneysRelAggregateFields>;
  nodes: Array<CatalogsJourneysRel>;
};

export type CatalogsJourneysRelAggregateBoolExp = {
  count?: InputMaybe<CatalogsJourneysRelAggregateBoolExpCount>;
};

/** aggregate fields of "catalogs_journeys_rel" */
export type CatalogsJourneysRelAggregateFields = {
  __typename?: 'CatalogsJourneysRelAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<CatalogsJourneysRelMaxFields>;
  min?: Maybe<CatalogsJourneysRelMinFields>;
};

/** aggregate fields of "catalogs_journeys_rel" */
export type CatalogsJourneysRelAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CatalogsJourneysRelSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "catalogs_journeys_rel" */
export type CatalogsJourneysRelAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CatalogsJourneysRelMaxOrderBy>;
  min?: InputMaybe<CatalogsJourneysRelMinOrderBy>;
};

/** input type for inserting array relation for remote table "catalogs_journeys_rel" */
export type CatalogsJourneysRelArrRelInsertInput = {
  data: Array<CatalogsJourneysRelInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<CatalogsJourneysRelOnConflict>;
};

/** Boolean expression to filter rows from the table "catalogs_journeys_rel". All fields are combined with a logical 'AND'. */
export type CatalogsJourneysRelBoolExp = {
  _and?: InputMaybe<Array<CatalogsJourneysRelBoolExp>>;
  _not?: InputMaybe<CatalogsJourneysRelBoolExp>;
  _or?: InputMaybe<Array<CatalogsJourneysRelBoolExp>>;
  catalog?: InputMaybe<CatalogsBoolExp>;
  catalogId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  journey?: InputMaybe<JourneysBoolExp>;
  journeyId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "catalogs_journeys_rel" */
export enum CatalogsJourneysRelConstraint {
  /** unique or primary key constraint on columns "journey_id", "catalog_id" */
  catalogs_journeys_rel_pk = 'catalogs_journeys_rel_pk'
}

/** input type for inserting data into table "catalogs_journeys_rel" */
export type CatalogsJourneysRelInsertInput = {
  catalog?: InputMaybe<CatalogsObjRelInsertInput>;
  catalogId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  journey?: InputMaybe<JourneysObjRelInsertInput>;
  journeyId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CatalogsJourneysRelMaxFields = {
  __typename?: 'CatalogsJourneysRelMaxFields';
  catalogId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  journeyId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "catalogs_journeys_rel" */
export type CatalogsJourneysRelMaxOrderBy = {
  catalogId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  journeyId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CatalogsJourneysRelMinFields = {
  __typename?: 'CatalogsJourneysRelMinFields';
  catalogId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  journeyId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "catalogs_journeys_rel" */
export type CatalogsJourneysRelMinOrderBy = {
  catalogId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  journeyId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "catalogs_journeys_rel" */
export type CatalogsJourneysRelMutationResponse = {
  __typename?: 'CatalogsJourneysRelMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CatalogsJourneysRel>;
};

/** on_conflict condition type for table "catalogs_journeys_rel" */
export type CatalogsJourneysRelOnConflict = {
  constraint: CatalogsJourneysRelConstraint;
  updateColumns?: Array<CatalogsJourneysRelUpdateColumn>;
  where?: InputMaybe<CatalogsJourneysRelBoolExp>;
};

/** Ordering options when selecting data from "catalogs_journeys_rel". */
export type CatalogsJourneysRelOrderBy = {
  catalog?: InputMaybe<CatalogsOrderBy>;
  catalogId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  journey?: InputMaybe<JourneysOrderBy>;
  journeyId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: catalogs_journeys_rel */
export type CatalogsJourneysRelPkColumnsInput = {
  catalogId: Scalars['uuid'];
  journeyId: Scalars['uuid'];
};

/** select columns of table "catalogs_journeys_rel" */
export enum CatalogsJourneysRelSelectColumn {
  /** column name */
  catalogId = 'catalogId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  journeyId = 'journeyId'
}

/** input type for updating data in table "catalogs_journeys_rel" */
export type CatalogsJourneysRelSetInput = {
  catalogId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  journeyId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "catalogs_journeys_rel" */
export type CatalogsJourneysRelStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CatalogsJourneysRelStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CatalogsJourneysRelStreamCursorValueInput = {
  catalogId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  journeyId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "catalogs_journeys_rel" */
export enum CatalogsJourneysRelUpdateColumn {
  /** column name */
  catalogId = 'catalogId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  journeyId = 'journeyId'
}

export type CatalogsJourneysRelUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CatalogsJourneysRelSetInput>;
  /** filter the rows which have to be updated */
  where: CatalogsJourneysRelBoolExp;
};

/** aggregate max on columns */
export type CatalogsMaxFields = {
  __typename?: 'CatalogsMaxFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type CatalogsMinFields = {
  __typename?: 'CatalogsMinFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "catalogs" */
export type CatalogsMutationResponse = {
  __typename?: 'CatalogsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Catalogs>;
};

/** input type for inserting object relation for remote table "catalogs" */
export type CatalogsObjRelInsertInput = {
  data: CatalogsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CatalogsOnConflict>;
};

/** on_conflict condition type for table "catalogs" */
export type CatalogsOnConflict = {
  constraint: CatalogsConstraint;
  updateColumns?: Array<CatalogsUpdateColumn>;
  where?: InputMaybe<CatalogsBoolExp>;
};

/** Ordering options when selecting data from "catalogs". */
export type CatalogsOrderBy = {
  coursesAggregate?: InputMaybe<CatalogCoursesAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedBy?: InputMaybe<UsersOrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  journeysAggregate?: InputMaybe<CatalogsJourneysRelAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<UsersOrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: catalogs */
export type CatalogsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "catalogs" */
export enum CatalogsSelectColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  slug = 'slug',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById'
}

/** input type for updating data in table "catalogs" */
export type CatalogsSetInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "catalogs" */
export type CatalogsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CatalogsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CatalogsStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "catalogs" */
export enum CatalogsUpdateColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  slug = 'slug',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById'
}

export type CatalogsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CatalogsSetInput>;
  /** filter the rows which have to be updated */
  where: CatalogsBoolExp;
};

export type CheckMyTeamCourseWaitlistOutput = {
  __typename?: 'CheckMyTeamCourseWaitlistOutput';
  onWaitlist?: Maybe<Array<Maybe<Scalars['uuid']>>>;
};

/** columns and relationships of "classes_coaches_rel" */
export type ClassCoaches = {
  __typename?: 'ClassCoaches';
  /** An object relationship */
  class: Classes;
  classId: Scalars['uuid'];
  /** An object relationship */
  coach: Coaches;
  coachId: Scalars['uuid'];
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "classes_coaches_rel" */
export type ClassCoachesAggregate = {
  __typename?: 'ClassCoachesAggregate';
  aggregate?: Maybe<ClassCoachesAggregateFields>;
  nodes: Array<ClassCoaches>;
};

export type ClassCoachesAggregateBoolExp = {
  count?: InputMaybe<ClassCoachesAggregateBoolExpCount>;
};

export type ClassCoachesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ClassCoachesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClassCoachesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "classes_coaches_rel" */
export type ClassCoachesAggregateFields = {
  __typename?: 'ClassCoachesAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<ClassCoachesMaxFields>;
  min?: Maybe<ClassCoachesMinFields>;
};

/** aggregate fields of "classes_coaches_rel" */
export type ClassCoachesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClassCoachesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "classes_coaches_rel" */
export type ClassCoachesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ClassCoachesMaxOrderBy>;
  min?: InputMaybe<ClassCoachesMinOrderBy>;
};

/** input type for inserting array relation for remote table "classes_coaches_rel" */
export type ClassCoachesArrRelInsertInput = {
  data: Array<ClassCoachesInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ClassCoachesOnConflict>;
};

/** Boolean expression to filter rows from the table "classes_coaches_rel". All fields are combined with a logical 'AND'. */
export type ClassCoachesBoolExp = {
  _and?: InputMaybe<Array<ClassCoachesBoolExp>>;
  _not?: InputMaybe<ClassCoachesBoolExp>;
  _or?: InputMaybe<Array<ClassCoachesBoolExp>>;
  class?: InputMaybe<ClassesBoolExp>;
  classId?: InputMaybe<UuidComparisonExp>;
  coach?: InputMaybe<CoachesBoolExp>;
  coachId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "classes_coaches_rel" */
export enum ClassCoachesConstraint {
  /** unique or primary key constraint on columns "coach_id", "class_id" */
  classes_coaches_rel_pk = 'classes_coaches_rel_pk'
}

/** input type for inserting data into table "classes_coaches_rel" */
export type ClassCoachesInsertInput = {
  class?: InputMaybe<ClassesObjRelInsertInput>;
  classId?: InputMaybe<Scalars['uuid']>;
  coach?: InputMaybe<CoachesObjRelInsertInput>;
  coachId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ClassCoachesMaxFields = {
  __typename?: 'ClassCoachesMaxFields';
  classId?: Maybe<Scalars['uuid']>;
  coachId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "classes_coaches_rel" */
export type ClassCoachesMaxOrderBy = {
  classId?: InputMaybe<OrderBy>;
  coachId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ClassCoachesMinFields = {
  __typename?: 'ClassCoachesMinFields';
  classId?: Maybe<Scalars['uuid']>;
  coachId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "classes_coaches_rel" */
export type ClassCoachesMinOrderBy = {
  classId?: InputMaybe<OrderBy>;
  coachId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "classes_coaches_rel" */
export type ClassCoachesMutationResponse = {
  __typename?: 'ClassCoachesMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClassCoaches>;
};

/** on_conflict condition type for table "classes_coaches_rel" */
export type ClassCoachesOnConflict = {
  constraint: ClassCoachesConstraint;
  updateColumns?: Array<ClassCoachesUpdateColumn>;
  where?: InputMaybe<ClassCoachesBoolExp>;
};

/** Ordering options when selecting data from "classes_coaches_rel". */
export type ClassCoachesOrderBy = {
  class?: InputMaybe<ClassesOrderBy>;
  classId?: InputMaybe<OrderBy>;
  coach?: InputMaybe<CoachesOrderBy>;
  coachId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: classes_coaches_rel */
export type ClassCoachesPkColumnsInput = {
  classId: Scalars['uuid'];
  coachId: Scalars['uuid'];
};

/** select columns of table "classes_coaches_rel" */
export enum ClassCoachesSelectColumn {
  /** column name */
  classId = 'classId',
  /** column name */
  coachId = 'coachId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById'
}

/** input type for updating data in table "classes_coaches_rel" */
export type ClassCoachesSetInput = {
  classId?: InputMaybe<Scalars['uuid']>;
  coachId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "ClassCoaches" */
export type ClassCoachesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ClassCoachesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ClassCoachesStreamCursorValueInput = {
  classId?: InputMaybe<Scalars['uuid']>;
  coachId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "classes_coaches_rel" */
export enum ClassCoachesUpdateColumn {
  /** column name */
  classId = 'classId',
  /** column name */
  coachId = 'coachId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById'
}

export type ClassCoachesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClassCoachesSetInput>;
  /** filter the rows which have to be updated */
  where: ClassCoachesBoolExp;
};

/** columns and relationships of "class_event_activity" */
export type ClassEventActivity = {
  __typename?: 'ClassEventActivity';
  /** An object relationship */
  classEvent?: Maybe<ClassEvents>;
  classEventId?: Maybe<Scalars['uuid']>;
  context?: Maybe<Scalars['jsonb']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  type?: Maybe<ActivityTypeEnum>;
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "class_event_activity" */
export type ClassEventActivityContextArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "class_event_activity" */
export type ClassEventActivityAggregate = {
  __typename?: 'ClassEventActivityAggregate';
  aggregate?: Maybe<ClassEventActivityAggregateFields>;
  nodes: Array<ClassEventActivity>;
};

export type ClassEventActivityAggregateBoolExp = {
  count?: InputMaybe<ClassEventActivityAggregateBoolExpCount>;
};

/** aggregate fields of "class_event_activity" */
export type ClassEventActivityAggregateFields = {
  __typename?: 'ClassEventActivityAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<ClassEventActivityMaxFields>;
  min?: Maybe<ClassEventActivityMinFields>;
};

/** aggregate fields of "class_event_activity" */
export type ClassEventActivityAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClassEventActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "class_event_activity" */
export type ClassEventActivityAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ClassEventActivityMaxOrderBy>;
  min?: InputMaybe<ClassEventActivityMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ClassEventActivityAppendInput = {
  context?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "class_event_activity" */
export type ClassEventActivityArrRelInsertInput = {
  data: Array<ClassEventActivityInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ClassEventActivityOnConflict>;
};

/** Boolean expression to filter rows from the table "class_event_activity". All fields are combined with a logical 'AND'. */
export type ClassEventActivityBoolExp = {
  _and?: InputMaybe<Array<ClassEventActivityBoolExp>>;
  _not?: InputMaybe<ClassEventActivityBoolExp>;
  _or?: InputMaybe<Array<ClassEventActivityBoolExp>>;
  classEvent?: InputMaybe<ClassEventsBoolExp>;
  classEventId?: InputMaybe<UuidComparisonExp>;
  context?: InputMaybe<JsonbComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  type?: InputMaybe<ActivityTypeEnumComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "class_event_activity" */
export enum ClassEventActivityConstraint {
  /** unique or primary key constraint on columns "id" */
  class_event_activity_pk = 'class_event_activity_pk'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ClassEventActivityDeleteAtPathInput = {
  context?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ClassEventActivityDeleteElemInput = {
  context?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ClassEventActivityDeleteKeyInput = {
  context?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "class_event_activity" */
export type ClassEventActivityInsertInput = {
  classEvent?: InputMaybe<ClassEventsObjRelInsertInput>;
  classEventId?: InputMaybe<Scalars['uuid']>;
  context?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<ActivityTypeEnum>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ClassEventActivityMaxFields = {
  __typename?: 'ClassEventActivityMaxFields';
  classEventId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "class_event_activity" */
export type ClassEventActivityMaxOrderBy = {
  classEventId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ClassEventActivityMinFields = {
  __typename?: 'ClassEventActivityMinFields';
  classEventId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "class_event_activity" */
export type ClassEventActivityMinOrderBy = {
  classEventId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "class_event_activity" */
export type ClassEventActivityMutationResponse = {
  __typename?: 'ClassEventActivityMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClassEventActivity>;
};

/** on_conflict condition type for table "class_event_activity" */
export type ClassEventActivityOnConflict = {
  constraint: ClassEventActivityConstraint;
  updateColumns?: Array<ClassEventActivityUpdateColumn>;
  where?: InputMaybe<ClassEventActivityBoolExp>;
};

/** Ordering options when selecting data from "class_event_activity". */
export type ClassEventActivityOrderBy = {
  classEvent?: InputMaybe<ClassEventsOrderBy>;
  classEventId?: InputMaybe<OrderBy>;
  context?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: class_event_activity */
export type ClassEventActivityPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ClassEventActivityPrependInput = {
  context?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "class_event_activity" */
export enum ClassEventActivitySelectColumn {
  /** column name */
  classEventId = 'classEventId',
  /** column name */
  context = 'context',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  id = 'id',
  /** column name */
  type = 'type',
  /** column name */
  userId = 'userId'
}

/** input type for updating data in table "class_event_activity" */
export type ClassEventActivitySetInput = {
  classEventId?: InputMaybe<Scalars['uuid']>;
  context?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<ActivityTypeEnum>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "class_event_activity" */
export type ClassEventActivityStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ClassEventActivityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ClassEventActivityStreamCursorValueInput = {
  classEventId?: InputMaybe<Scalars['uuid']>;
  context?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<ActivityTypeEnum>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "class_event_activity" */
export enum ClassEventActivityUpdateColumn {
  /** column name */
  classEventId = 'classEventId',
  /** column name */
  context = 'context',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  id = 'id',
  /** column name */
  type = 'type',
  /** column name */
  userId = 'userId'
}

export type ClassEventActivityUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ClassEventActivityAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<ClassEventActivityDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<ClassEventActivityDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<ClassEventActivityDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ClassEventActivityPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClassEventActivitySetInput>;
  /** filter the rows which have to be updated */
  where: ClassEventActivityBoolExp;
};

/** columns and relationships of "class_event_anon_registrations" */
export type ClassEventAnonRegistrations = {
  __typename?: 'ClassEventAnonRegistrations';
  /** An object relationship */
  classEvent?: Maybe<ClassEvents>;
  classEventId: Scalars['uuid'];
  createdAt: Scalars['timestamp'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  lastName?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  zoomJoinLinkUrl: Scalars['String'];
};

/** aggregated selection of "class_event_anon_registrations" */
export type ClassEventAnonRegistrationsAggregate = {
  __typename?: 'ClassEventAnonRegistrationsAggregate';
  aggregate?: Maybe<ClassEventAnonRegistrationsAggregateFields>;
  nodes: Array<ClassEventAnonRegistrations>;
};

/** aggregate fields of "class_event_anon_registrations" */
export type ClassEventAnonRegistrationsAggregateFields = {
  __typename?: 'ClassEventAnonRegistrationsAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<ClassEventAnonRegistrationsMaxFields>;
  min?: Maybe<ClassEventAnonRegistrationsMinFields>;
};

/** aggregate fields of "class_event_anon_registrations" */
export type ClassEventAnonRegistrationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClassEventAnonRegistrationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "class_event_anon_registrations". All fields are combined with a logical 'AND'. */
export type ClassEventAnonRegistrationsBoolExp = {
  _and?: InputMaybe<Array<ClassEventAnonRegistrationsBoolExp>>;
  _not?: InputMaybe<ClassEventAnonRegistrationsBoolExp>;
  _or?: InputMaybe<Array<ClassEventAnonRegistrationsBoolExp>>;
  classEvent?: InputMaybe<ClassEventsBoolExp>;
  classEventId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  firstName?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  lastName?: InputMaybe<StringComparisonExp>;
  token?: InputMaybe<StringComparisonExp>;
  zoomJoinLinkUrl?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "class_event_anon_registrations" */
export enum ClassEventAnonRegistrationsConstraint {
  /** unique or primary key constraint on columns "id" */
  class_event_anon_registrations_pk = 'class_event_anon_registrations_pk'
}

/** input type for inserting data into table "class_event_anon_registrations" */
export type ClassEventAnonRegistrationsInsertInput = {
  classEvent?: InputMaybe<ClassEventsObjRelInsertInput>;
  classEventId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  zoomJoinLinkUrl?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ClassEventAnonRegistrationsMaxFields = {
  __typename?: 'ClassEventAnonRegistrationsMaxFields';
  classEventId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  zoomJoinLinkUrl?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ClassEventAnonRegistrationsMinFields = {
  __typename?: 'ClassEventAnonRegistrationsMinFields';
  classEventId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  zoomJoinLinkUrl?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "class_event_anon_registrations" */
export type ClassEventAnonRegistrationsMutationResponse = {
  __typename?: 'ClassEventAnonRegistrationsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClassEventAnonRegistrations>;
};

/** on_conflict condition type for table "class_event_anon_registrations" */
export type ClassEventAnonRegistrationsOnConflict = {
  constraint: ClassEventAnonRegistrationsConstraint;
  updateColumns?: Array<ClassEventAnonRegistrationsUpdateColumn>;
  where?: InputMaybe<ClassEventAnonRegistrationsBoolExp>;
};

/** Ordering options when selecting data from "class_event_anon_registrations". */
export type ClassEventAnonRegistrationsOrderBy = {
  classEvent?: InputMaybe<ClassEventsOrderBy>;
  classEventId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  token?: InputMaybe<OrderBy>;
  zoomJoinLinkUrl?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: class_event_anon_registrations */
export type ClassEventAnonRegistrationsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "class_event_anon_registrations" */
export enum ClassEventAnonRegistrationsSelectColumn {
  /** column name */
  classEventId = 'classEventId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  email = 'email',
  /** column name */
  firstName = 'firstName',
  /** column name */
  id = 'id',
  /** column name */
  lastName = 'lastName',
  /** column name */
  token = 'token',
  /** column name */
  zoomJoinLinkUrl = 'zoomJoinLinkUrl'
}

/** input type for updating data in table "class_event_anon_registrations" */
export type ClassEventAnonRegistrationsSetInput = {
  classEventId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  zoomJoinLinkUrl?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "class_event_anon_registrations" */
export type ClassEventAnonRegistrationsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ClassEventAnonRegistrationsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ClassEventAnonRegistrationsStreamCursorValueInput = {
  classEventId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  zoomJoinLinkUrl?: InputMaybe<Scalars['String']>;
};

/** update columns of table "class_event_anon_registrations" */
export enum ClassEventAnonRegistrationsUpdateColumn {
  /** column name */
  classEventId = 'classEventId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  email = 'email',
  /** column name */
  firstName = 'firstName',
  /** column name */
  id = 'id',
  /** column name */
  lastName = 'lastName',
  /** column name */
  token = 'token',
  /** column name */
  zoomJoinLinkUrl = 'zoomJoinLinkUrl'
}

export type ClassEventAnonRegistrationsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClassEventAnonRegistrationsSetInput>;
  /** filter the rows which have to be updated */
  where: ClassEventAnonRegistrationsBoolExp;
};

/** columns and relationships of "class_event_invitations" */
export type ClassEventInvitations = {
  __typename?: 'ClassEventInvitations';
  /** An object relationship */
  classEvent?: Maybe<ClassEvents>;
  classEventId?: Maybe<Scalars['uuid']>;
  classEventVersion?: Maybe<Scalars['Int']>;
  context?: Maybe<Scalars['json']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  updatedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  updatedBy?: Maybe<Users>;
  updatedById?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "class_event_invitations" */
export type ClassEventInvitationsContextArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "class_event_invitations" */
export type ClassEventInvitationsAggregate = {
  __typename?: 'ClassEventInvitationsAggregate';
  aggregate?: Maybe<ClassEventInvitationsAggregateFields>;
  nodes: Array<ClassEventInvitations>;
};

/** aggregate fields of "class_event_invitations" */
export type ClassEventInvitationsAggregateFields = {
  __typename?: 'ClassEventInvitationsAggregateFields';
  avg?: Maybe<ClassEventInvitationsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ClassEventInvitationsMaxFields>;
  min?: Maybe<ClassEventInvitationsMinFields>;
  stddev?: Maybe<ClassEventInvitationsStddevFields>;
  stddevPop?: Maybe<ClassEventInvitationsStddevPopFields>;
  stddevSamp?: Maybe<ClassEventInvitationsStddevSampFields>;
  sum?: Maybe<ClassEventInvitationsSumFields>;
  varPop?: Maybe<ClassEventInvitationsVarPopFields>;
  varSamp?: Maybe<ClassEventInvitationsVarSampFields>;
  variance?: Maybe<ClassEventInvitationsVarianceFields>;
};

/** aggregate fields of "class_event_invitations" */
export type ClassEventInvitationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClassEventInvitationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ClassEventInvitationsAvgFields = {
  __typename?: 'ClassEventInvitationsAvgFields';
  classEventVersion?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "class_event_invitations". All fields are combined with a logical 'AND'. */
export type ClassEventInvitationsBoolExp = {
  _and?: InputMaybe<Array<ClassEventInvitationsBoolExp>>;
  _not?: InputMaybe<ClassEventInvitationsBoolExp>;
  _or?: InputMaybe<Array<ClassEventInvitationsBoolExp>>;
  classEvent?: InputMaybe<ClassEventsBoolExp>;
  classEventId?: InputMaybe<UuidComparisonExp>;
  classEventVersion?: InputMaybe<IntComparisonExp>;
  context?: InputMaybe<JsonComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  error?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  updatedBy?: InputMaybe<UsersBoolExp>;
  updatedById?: InputMaybe<UuidComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "class_event_invitations" */
export enum ClassEventInvitationsConstraint {
  /** unique or primary key constraint on columns "id" */
  class_event_invitations_pk = 'class_event_invitations_pk'
}

/** input type for incrementing numeric columns in table "class_event_invitations" */
export type ClassEventInvitationsIncInput = {
  classEventVersion?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "class_event_invitations" */
export type ClassEventInvitationsInsertInput = {
  classEvent?: InputMaybe<ClassEventsObjRelInsertInput>;
  classEventId?: InputMaybe<Scalars['uuid']>;
  classEventVersion?: InputMaybe<Scalars['Int']>;
  context?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<UsersObjRelInsertInput>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ClassEventInvitationsMaxFields = {
  __typename?: 'ClassEventInvitationsMaxFields';
  classEventId?: Maybe<Scalars['uuid']>;
  classEventVersion?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ClassEventInvitationsMinFields = {
  __typename?: 'ClassEventInvitationsMinFields';
  classEventId?: Maybe<Scalars['uuid']>;
  classEventVersion?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "class_event_invitations" */
export type ClassEventInvitationsMutationResponse = {
  __typename?: 'ClassEventInvitationsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClassEventInvitations>;
};

/** on_conflict condition type for table "class_event_invitations" */
export type ClassEventInvitationsOnConflict = {
  constraint: ClassEventInvitationsConstraint;
  updateColumns?: Array<ClassEventInvitationsUpdateColumn>;
  where?: InputMaybe<ClassEventInvitationsBoolExp>;
};

/** Ordering options when selecting data from "class_event_invitations". */
export type ClassEventInvitationsOrderBy = {
  classEvent?: InputMaybe<ClassEventsOrderBy>;
  classEventId?: InputMaybe<OrderBy>;
  classEventVersion?: InputMaybe<OrderBy>;
  context?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  error?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<UsersOrderBy>;
  updatedById?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: class_event_invitations */
export type ClassEventInvitationsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "class_event_invitations" */
export enum ClassEventInvitationsSelectColumn {
  /** column name */
  classEventId = 'classEventId',
  /** column name */
  classEventVersion = 'classEventVersion',
  /** column name */
  context = 'context',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  email = 'email',
  /** column name */
  error = 'error',
  /** column name */
  id = 'id',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById',
  /** column name */
  userId = 'userId'
}

/** input type for updating data in table "class_event_invitations" */
export type ClassEventInvitationsSetInput = {
  classEventId?: InputMaybe<Scalars['uuid']>;
  classEventVersion?: InputMaybe<Scalars['Int']>;
  context?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type ClassEventInvitationsStddevFields = {
  __typename?: 'ClassEventInvitationsStddevFields';
  classEventVersion?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type ClassEventInvitationsStddevPopFields = {
  __typename?: 'ClassEventInvitationsStddevPopFields';
  classEventVersion?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type ClassEventInvitationsStddevSampFields = {
  __typename?: 'ClassEventInvitationsStddevSampFields';
  classEventVersion?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "class_event_invitations" */
export type ClassEventInvitationsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ClassEventInvitationsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ClassEventInvitationsStreamCursorValueInput = {
  classEventId?: InputMaybe<Scalars['uuid']>;
  classEventVersion?: InputMaybe<Scalars['Int']>;
  context?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type ClassEventInvitationsSumFields = {
  __typename?: 'ClassEventInvitationsSumFields';
  classEventVersion?: Maybe<Scalars['Int']>;
};

/** update columns of table "class_event_invitations" */
export enum ClassEventInvitationsUpdateColumn {
  /** column name */
  classEventId = 'classEventId',
  /** column name */
  classEventVersion = 'classEventVersion',
  /** column name */
  context = 'context',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  email = 'email',
  /** column name */
  error = 'error',
  /** column name */
  id = 'id',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById',
  /** column name */
  userId = 'userId'
}

export type ClassEventInvitationsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ClassEventInvitationsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClassEventInvitationsSetInput>;
  /** filter the rows which have to be updated */
  where: ClassEventInvitationsBoolExp;
};

/** aggregate varPop on columns */
export type ClassEventInvitationsVarPopFields = {
  __typename?: 'ClassEventInvitationsVarPopFields';
  classEventVersion?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type ClassEventInvitationsVarSampFields = {
  __typename?: 'ClassEventInvitationsVarSampFields';
  classEventVersion?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ClassEventInvitationsVarianceFields = {
  __typename?: 'ClassEventInvitationsVarianceFields';
  classEventVersion?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "class_event_meeting_participants" */
export type ClassEventMeetingParticipants = {
  __typename?: 'ClassEventMeetingParticipants';
  classEventMeetingId: Scalars['uuid'];
  /** seconds */
  duration?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  joinTime?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  meeting: ClassEventMeetings;
  participantId: Scalars['String'];
  /** An array relationship */
  segments: Array<ClassEventMeetingSegments>;
  /** An aggregate relationship */
  segmentsAggregate: ClassEventMeetingSegmentsAggregate;
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['uuid']>;
  userName?: Maybe<Scalars['String']>;
};

/** columns and relationships of "class_event_meeting_participants" */
export type ClassEventMeetingParticipantsSegmentsArgs = {
  distinctOn?: InputMaybe<Array<ClassEventMeetingSegmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventMeetingSegmentsOrderBy>>;
  where?: InputMaybe<ClassEventMeetingSegmentsBoolExp>;
};

/** columns and relationships of "class_event_meeting_participants" */
export type ClassEventMeetingParticipantsSegmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassEventMeetingSegmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventMeetingSegmentsOrderBy>>;
  where?: InputMaybe<ClassEventMeetingSegmentsBoolExp>;
};

/** aggregated selection of "class_event_meeting_participants" */
export type ClassEventMeetingParticipantsAggregate = {
  __typename?: 'ClassEventMeetingParticipantsAggregate';
  aggregate?: Maybe<ClassEventMeetingParticipantsAggregateFields>;
  nodes: Array<ClassEventMeetingParticipants>;
};

export type ClassEventMeetingParticipantsAggregateBoolExp = {
  count?: InputMaybe<ClassEventMeetingParticipantsAggregateBoolExpCount>;
};

/** aggregate fields of "class_event_meeting_participants" */
export type ClassEventMeetingParticipantsAggregateFields = {
  __typename?: 'ClassEventMeetingParticipantsAggregateFields';
  avg?: Maybe<ClassEventMeetingParticipantsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ClassEventMeetingParticipantsMaxFields>;
  min?: Maybe<ClassEventMeetingParticipantsMinFields>;
  stddev?: Maybe<ClassEventMeetingParticipantsStddevFields>;
  stddevPop?: Maybe<ClassEventMeetingParticipantsStddevPopFields>;
  stddevSamp?: Maybe<ClassEventMeetingParticipantsStddevSampFields>;
  sum?: Maybe<ClassEventMeetingParticipantsSumFields>;
  varPop?: Maybe<ClassEventMeetingParticipantsVarPopFields>;
  varSamp?: Maybe<ClassEventMeetingParticipantsVarSampFields>;
  variance?: Maybe<ClassEventMeetingParticipantsVarianceFields>;
};

/** aggregate fields of "class_event_meeting_participants" */
export type ClassEventMeetingParticipantsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClassEventMeetingParticipantsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "class_event_meeting_participants" */
export type ClassEventMeetingParticipantsAggregateOrderBy = {
  avg?: InputMaybe<ClassEventMeetingParticipantsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ClassEventMeetingParticipantsMaxOrderBy>;
  min?: InputMaybe<ClassEventMeetingParticipantsMinOrderBy>;
  stddev?: InputMaybe<ClassEventMeetingParticipantsStddevOrderBy>;
  stddevPop?: InputMaybe<ClassEventMeetingParticipantsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ClassEventMeetingParticipantsStddevSampOrderBy>;
  sum?: InputMaybe<ClassEventMeetingParticipantsSumOrderBy>;
  varPop?: InputMaybe<ClassEventMeetingParticipantsVarPopOrderBy>;
  varSamp?: InputMaybe<ClassEventMeetingParticipantsVarSampOrderBy>;
  variance?: InputMaybe<ClassEventMeetingParticipantsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "class_event_meeting_participants" */
export type ClassEventMeetingParticipantsArrRelInsertInput = {
  data: Array<ClassEventMeetingParticipantsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ClassEventMeetingParticipantsOnConflict>;
};

/** aggregate avg on columns */
export type ClassEventMeetingParticipantsAvgFields = {
  __typename?: 'ClassEventMeetingParticipantsAvgFields';
  /** seconds */
  duration?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "class_event_meeting_participants" */
export type ClassEventMeetingParticipantsAvgOrderBy = {
  /** seconds */
  duration?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "class_event_meeting_participants". All fields are combined with a logical 'AND'. */
export type ClassEventMeetingParticipantsBoolExp = {
  _and?: InputMaybe<Array<ClassEventMeetingParticipantsBoolExp>>;
  _not?: InputMaybe<ClassEventMeetingParticipantsBoolExp>;
  _or?: InputMaybe<Array<ClassEventMeetingParticipantsBoolExp>>;
  classEventMeetingId?: InputMaybe<UuidComparisonExp>;
  duration?: InputMaybe<IntComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  joinTime?: InputMaybe<TimestampComparisonExp>;
  meeting?: InputMaybe<ClassEventMeetingsBoolExp>;
  participantId?: InputMaybe<StringComparisonExp>;
  segments?: InputMaybe<ClassEventMeetingSegmentsBoolExp>;
  segmentsAggregate?: InputMaybe<ClassEventMeetingSegmentsAggregateBoolExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
  userName?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "class_event_meeting_participants" */
export enum ClassEventMeetingParticipantsConstraint {
  /** unique or primary key constraint on columns "participant_id" */
  class_event_meeting_participants_pkey = 'class_event_meeting_participants_pkey'
}

/** input type for incrementing numeric columns in table "class_event_meeting_participants" */
export type ClassEventMeetingParticipantsIncInput = {
  /** seconds */
  duration?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "class_event_meeting_participants" */
export type ClassEventMeetingParticipantsInsertInput = {
  classEventMeetingId?: InputMaybe<Scalars['uuid']>;
  /** seconds */
  duration?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  joinTime?: InputMaybe<Scalars['timestamp']>;
  meeting?: InputMaybe<ClassEventMeetingsObjRelInsertInput>;
  participantId?: InputMaybe<Scalars['String']>;
  segments?: InputMaybe<ClassEventMeetingSegmentsArrRelInsertInput>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
  userName?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ClassEventMeetingParticipantsMaxFields = {
  __typename?: 'ClassEventMeetingParticipantsMaxFields';
  classEventMeetingId?: Maybe<Scalars['uuid']>;
  /** seconds */
  duration?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  joinTime?: Maybe<Scalars['timestamp']>;
  participantId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
  userName?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "class_event_meeting_participants" */
export type ClassEventMeetingParticipantsMaxOrderBy = {
  classEventMeetingId?: InputMaybe<OrderBy>;
  /** seconds */
  duration?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  joinTime?: InputMaybe<OrderBy>;
  participantId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  userName?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ClassEventMeetingParticipantsMinFields = {
  __typename?: 'ClassEventMeetingParticipantsMinFields';
  classEventMeetingId?: Maybe<Scalars['uuid']>;
  /** seconds */
  duration?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  joinTime?: Maybe<Scalars['timestamp']>;
  participantId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
  userName?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "class_event_meeting_participants" */
export type ClassEventMeetingParticipantsMinOrderBy = {
  classEventMeetingId?: InputMaybe<OrderBy>;
  /** seconds */
  duration?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  joinTime?: InputMaybe<OrderBy>;
  participantId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  userName?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "class_event_meeting_participants" */
export type ClassEventMeetingParticipantsMutationResponse = {
  __typename?: 'ClassEventMeetingParticipantsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClassEventMeetingParticipants>;
};

/** input type for inserting object relation for remote table "class_event_meeting_participants" */
export type ClassEventMeetingParticipantsObjRelInsertInput = {
  data: ClassEventMeetingParticipantsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ClassEventMeetingParticipantsOnConflict>;
};

/** on_conflict condition type for table "class_event_meeting_participants" */
export type ClassEventMeetingParticipantsOnConflict = {
  constraint: ClassEventMeetingParticipantsConstraint;
  updateColumns?: Array<ClassEventMeetingParticipantsUpdateColumn>;
  where?: InputMaybe<ClassEventMeetingParticipantsBoolExp>;
};

/** Ordering options when selecting data from "class_event_meeting_participants". */
export type ClassEventMeetingParticipantsOrderBy = {
  classEventMeetingId?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  joinTime?: InputMaybe<OrderBy>;
  meeting?: InputMaybe<ClassEventMeetingsOrderBy>;
  participantId?: InputMaybe<OrderBy>;
  segmentsAggregate?: InputMaybe<ClassEventMeetingSegmentsAggregateOrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
  userName?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: class_event_meeting_participants */
export type ClassEventMeetingParticipantsPkColumnsInput = {
  participantId: Scalars['String'];
};

/** select columns of table "class_event_meeting_participants" */
export enum ClassEventMeetingParticipantsSelectColumn {
  /** column name */
  classEventMeetingId = 'classEventMeetingId',
  /** column name */
  duration = 'duration',
  /** column name */
  email = 'email',
  /** column name */
  joinTime = 'joinTime',
  /** column name */
  participantId = 'participantId',
  /** column name */
  userId = 'userId',
  /** column name */
  userName = 'userName'
}

/** input type for updating data in table "class_event_meeting_participants" */
export type ClassEventMeetingParticipantsSetInput = {
  classEventMeetingId?: InputMaybe<Scalars['uuid']>;
  /** seconds */
  duration?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  joinTime?: InputMaybe<Scalars['timestamp']>;
  participantId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['uuid']>;
  userName?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ClassEventMeetingParticipantsStddevFields = {
  __typename?: 'ClassEventMeetingParticipantsStddevFields';
  /** seconds */
  duration?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "class_event_meeting_participants" */
export type ClassEventMeetingParticipantsStddevOrderBy = {
  /** seconds */
  duration?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ClassEventMeetingParticipantsStddevPopFields = {
  __typename?: 'ClassEventMeetingParticipantsStddevPopFields';
  /** seconds */
  duration?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "class_event_meeting_participants" */
export type ClassEventMeetingParticipantsStddevPopOrderBy = {
  /** seconds */
  duration?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ClassEventMeetingParticipantsStddevSampFields = {
  __typename?: 'ClassEventMeetingParticipantsStddevSampFields';
  /** seconds */
  duration?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "class_event_meeting_participants" */
export type ClassEventMeetingParticipantsStddevSampOrderBy = {
  /** seconds */
  duration?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "class_event_meeting_participants" */
export type ClassEventMeetingParticipantsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ClassEventMeetingParticipantsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ClassEventMeetingParticipantsStreamCursorValueInput = {
  classEventMeetingId?: InputMaybe<Scalars['uuid']>;
  /** seconds */
  duration?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  joinTime?: InputMaybe<Scalars['timestamp']>;
  participantId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['uuid']>;
  userName?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ClassEventMeetingParticipantsSumFields = {
  __typename?: 'ClassEventMeetingParticipantsSumFields';
  /** seconds */
  duration?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "class_event_meeting_participants" */
export type ClassEventMeetingParticipantsSumOrderBy = {
  /** seconds */
  duration?: InputMaybe<OrderBy>;
};

/** update columns of table "class_event_meeting_participants" */
export enum ClassEventMeetingParticipantsUpdateColumn {
  /** column name */
  classEventMeetingId = 'classEventMeetingId',
  /** column name */
  duration = 'duration',
  /** column name */
  email = 'email',
  /** column name */
  joinTime = 'joinTime',
  /** column name */
  participantId = 'participantId',
  /** column name */
  userId = 'userId',
  /** column name */
  userName = 'userName'
}

export type ClassEventMeetingParticipantsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ClassEventMeetingParticipantsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClassEventMeetingParticipantsSetInput>;
  /** filter the rows which have to be updated */
  where: ClassEventMeetingParticipantsBoolExp;
};

/** aggregate varPop on columns */
export type ClassEventMeetingParticipantsVarPopFields = {
  __typename?: 'ClassEventMeetingParticipantsVarPopFields';
  /** seconds */
  duration?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "class_event_meeting_participants" */
export type ClassEventMeetingParticipantsVarPopOrderBy = {
  /** seconds */
  duration?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ClassEventMeetingParticipantsVarSampFields = {
  __typename?: 'ClassEventMeetingParticipantsVarSampFields';
  /** seconds */
  duration?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "class_event_meeting_participants" */
export type ClassEventMeetingParticipantsVarSampOrderBy = {
  /** seconds */
  duration?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ClassEventMeetingParticipantsVarianceFields = {
  __typename?: 'ClassEventMeetingParticipantsVarianceFields';
  /** seconds */
  duration?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "class_event_meeting_participants" */
export type ClassEventMeetingParticipantsVarianceOrderBy = {
  /** seconds */
  duration?: InputMaybe<OrderBy>;
};

/** columns and relationships of "class_event_meeting_segments" */
export type ClassEventMeetingSegments = {
  __typename?: 'ClassEventMeetingSegments';
  audioClipUrl?: Maybe<Scalars['String']>;
  /** An object relationship */
  classEventMeeting: ClassEventMeetings;
  classEventMeetingId: Scalars['uuid'];
  createdAt?: Maybe<Scalars['timestamp']>;
  /** seconds */
  duration: Scalars['Int'];
  endTimecode: Scalars['numeric'];
  id: Scalars['uuid'];
  /** An object relationship */
  inResponseToSegment?: Maybe<ClassEventMeetingSegments>;
  inResponseToSegmentId?: Maybe<Scalars['uuid']>;
  jobs?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  lightningSubmissions: Array<LightningSessionSubmissions>;
  /** An aggregate relationship */
  lightningSubmissionsAggregate: LightningSessionSubmissionsAggregate;
  /** An array relationship */
  segmentResponses: Array<ClassEventMeetingSegments>;
  /** An aggregate relationship */
  segmentResponsesAggregate: ClassEventMeetingSegmentsAggregate;
  /** An object relationship */
  speakerParticipant: ClassEventMeetingParticipants;
  speakerParticipantId: Scalars['String'];
  startTimecode: Scalars['numeric'];
  synced?: Maybe<Scalars['Boolean']>;
  transcription?: Maybe<Scalars['jsonb']>;
  videoClipUrl?: Maybe<Scalars['String']>;
};

/** columns and relationships of "class_event_meeting_segments" */
export type ClassEventMeetingSegmentsJobsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "class_event_meeting_segments" */
export type ClassEventMeetingSegmentsLightningSubmissionsArgs = {
  distinctOn?: InputMaybe<Array<LightningSessionSubmissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LightningSessionSubmissionsOrderBy>>;
  where?: InputMaybe<LightningSessionSubmissionsBoolExp>;
};

/** columns and relationships of "class_event_meeting_segments" */
export type ClassEventMeetingSegmentsLightningSubmissionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<LightningSessionSubmissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LightningSessionSubmissionsOrderBy>>;
  where?: InputMaybe<LightningSessionSubmissionsBoolExp>;
};

/** columns and relationships of "class_event_meeting_segments" */
export type ClassEventMeetingSegmentsSegmentResponsesArgs = {
  distinctOn?: InputMaybe<Array<ClassEventMeetingSegmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventMeetingSegmentsOrderBy>>;
  where?: InputMaybe<ClassEventMeetingSegmentsBoolExp>;
};

/** columns and relationships of "class_event_meeting_segments" */
export type ClassEventMeetingSegmentsSegmentResponsesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassEventMeetingSegmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventMeetingSegmentsOrderBy>>;
  where?: InputMaybe<ClassEventMeetingSegmentsBoolExp>;
};

/** columns and relationships of "class_event_meeting_segments" */
export type ClassEventMeetingSegmentsTranscriptionArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "class_event_meeting_segments" */
export type ClassEventMeetingSegmentsAggregate = {
  __typename?: 'ClassEventMeetingSegmentsAggregate';
  aggregate?: Maybe<ClassEventMeetingSegmentsAggregateFields>;
  nodes: Array<ClassEventMeetingSegments>;
};

export type ClassEventMeetingSegmentsAggregateBoolExp = {
  bool_and?: InputMaybe<ClassEventMeetingSegmentsAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<ClassEventMeetingSegmentsAggregateBoolExpBool_Or>;
  count?: InputMaybe<ClassEventMeetingSegmentsAggregateBoolExpCount>;
};

/** aggregate fields of "class_event_meeting_segments" */
export type ClassEventMeetingSegmentsAggregateFields = {
  __typename?: 'ClassEventMeetingSegmentsAggregateFields';
  avg?: Maybe<ClassEventMeetingSegmentsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ClassEventMeetingSegmentsMaxFields>;
  min?: Maybe<ClassEventMeetingSegmentsMinFields>;
  stddev?: Maybe<ClassEventMeetingSegmentsStddevFields>;
  stddevPop?: Maybe<ClassEventMeetingSegmentsStddevPopFields>;
  stddevSamp?: Maybe<ClassEventMeetingSegmentsStddevSampFields>;
  sum?: Maybe<ClassEventMeetingSegmentsSumFields>;
  varPop?: Maybe<ClassEventMeetingSegmentsVarPopFields>;
  varSamp?: Maybe<ClassEventMeetingSegmentsVarSampFields>;
  variance?: Maybe<ClassEventMeetingSegmentsVarianceFields>;
};

/** aggregate fields of "class_event_meeting_segments" */
export type ClassEventMeetingSegmentsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClassEventMeetingSegmentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "class_event_meeting_segments" */
export type ClassEventMeetingSegmentsAggregateOrderBy = {
  avg?: InputMaybe<ClassEventMeetingSegmentsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ClassEventMeetingSegmentsMaxOrderBy>;
  min?: InputMaybe<ClassEventMeetingSegmentsMinOrderBy>;
  stddev?: InputMaybe<ClassEventMeetingSegmentsStddevOrderBy>;
  stddevPop?: InputMaybe<ClassEventMeetingSegmentsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ClassEventMeetingSegmentsStddevSampOrderBy>;
  sum?: InputMaybe<ClassEventMeetingSegmentsSumOrderBy>;
  varPop?: InputMaybe<ClassEventMeetingSegmentsVarPopOrderBy>;
  varSamp?: InputMaybe<ClassEventMeetingSegmentsVarSampOrderBy>;
  variance?: InputMaybe<ClassEventMeetingSegmentsVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ClassEventMeetingSegmentsAppendInput = {
  jobs?: InputMaybe<Scalars['jsonb']>;
  transcription?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "class_event_meeting_segments" */
export type ClassEventMeetingSegmentsArrRelInsertInput = {
  data: Array<ClassEventMeetingSegmentsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ClassEventMeetingSegmentsOnConflict>;
};

/** aggregate avg on columns */
export type ClassEventMeetingSegmentsAvgFields = {
  __typename?: 'ClassEventMeetingSegmentsAvgFields';
  /** seconds */
  duration?: Maybe<Scalars['Float']>;
  endTimecode?: Maybe<Scalars['Float']>;
  startTimecode?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "class_event_meeting_segments" */
export type ClassEventMeetingSegmentsAvgOrderBy = {
  /** seconds */
  duration?: InputMaybe<OrderBy>;
  endTimecode?: InputMaybe<OrderBy>;
  startTimecode?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "class_event_meeting_segments". All fields are combined with a logical 'AND'. */
export type ClassEventMeetingSegmentsBoolExp = {
  _and?: InputMaybe<Array<ClassEventMeetingSegmentsBoolExp>>;
  _not?: InputMaybe<ClassEventMeetingSegmentsBoolExp>;
  _or?: InputMaybe<Array<ClassEventMeetingSegmentsBoolExp>>;
  audioClipUrl?: InputMaybe<StringComparisonExp>;
  classEventMeeting?: InputMaybe<ClassEventMeetingsBoolExp>;
  classEventMeetingId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  duration?: InputMaybe<IntComparisonExp>;
  endTimecode?: InputMaybe<NumericComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  inResponseToSegment?: InputMaybe<ClassEventMeetingSegmentsBoolExp>;
  inResponseToSegmentId?: InputMaybe<UuidComparisonExp>;
  jobs?: InputMaybe<JsonbComparisonExp>;
  lightningSubmissions?: InputMaybe<LightningSessionSubmissionsBoolExp>;
  lightningSubmissionsAggregate?: InputMaybe<LightningSessionSubmissionsAggregateBoolExp>;
  segmentResponses?: InputMaybe<ClassEventMeetingSegmentsBoolExp>;
  segmentResponsesAggregate?: InputMaybe<ClassEventMeetingSegmentsAggregateBoolExp>;
  speakerParticipant?: InputMaybe<ClassEventMeetingParticipantsBoolExp>;
  speakerParticipantId?: InputMaybe<StringComparisonExp>;
  startTimecode?: InputMaybe<NumericComparisonExp>;
  synced?: InputMaybe<BooleanComparisonExp>;
  transcription?: InputMaybe<JsonbComparisonExp>;
  videoClipUrl?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "class_event_meeting_segments" */
export enum ClassEventMeetingSegmentsConstraint {
  /** unique or primary key constraint on columns "id" */
  class_event_meeting_segments_pkey = 'class_event_meeting_segments_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ClassEventMeetingSegmentsDeleteAtPathInput = {
  jobs?: InputMaybe<Array<Scalars['String']>>;
  transcription?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ClassEventMeetingSegmentsDeleteElemInput = {
  jobs?: InputMaybe<Scalars['Int']>;
  transcription?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ClassEventMeetingSegmentsDeleteKeyInput = {
  jobs?: InputMaybe<Scalars['String']>;
  transcription?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "class_event_meeting_segments" */
export type ClassEventMeetingSegmentsIncInput = {
  /** seconds */
  duration?: InputMaybe<Scalars['Int']>;
  endTimecode?: InputMaybe<Scalars['numeric']>;
  startTimecode?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "class_event_meeting_segments" */
export type ClassEventMeetingSegmentsInsertInput = {
  audioClipUrl?: InputMaybe<Scalars['String']>;
  classEventMeeting?: InputMaybe<ClassEventMeetingsObjRelInsertInput>;
  classEventMeetingId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  /** seconds */
  duration?: InputMaybe<Scalars['Int']>;
  endTimecode?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  inResponseToSegment?: InputMaybe<ClassEventMeetingSegmentsObjRelInsertInput>;
  inResponseToSegmentId?: InputMaybe<Scalars['uuid']>;
  jobs?: InputMaybe<Scalars['jsonb']>;
  lightningSubmissions?: InputMaybe<LightningSessionSubmissionsArrRelInsertInput>;
  segmentResponses?: InputMaybe<ClassEventMeetingSegmentsArrRelInsertInput>;
  speakerParticipant?: InputMaybe<ClassEventMeetingParticipantsObjRelInsertInput>;
  speakerParticipantId?: InputMaybe<Scalars['String']>;
  startTimecode?: InputMaybe<Scalars['numeric']>;
  synced?: InputMaybe<Scalars['Boolean']>;
  transcription?: InputMaybe<Scalars['jsonb']>;
  videoClipUrl?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ClassEventMeetingSegmentsMaxFields = {
  __typename?: 'ClassEventMeetingSegmentsMaxFields';
  audioClipUrl?: Maybe<Scalars['String']>;
  classEventMeetingId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  /** seconds */
  duration?: Maybe<Scalars['Int']>;
  endTimecode?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  inResponseToSegmentId?: Maybe<Scalars['uuid']>;
  speakerParticipantId?: Maybe<Scalars['String']>;
  startTimecode?: Maybe<Scalars['numeric']>;
  videoClipUrl?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "class_event_meeting_segments" */
export type ClassEventMeetingSegmentsMaxOrderBy = {
  audioClipUrl?: InputMaybe<OrderBy>;
  classEventMeetingId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  /** seconds */
  duration?: InputMaybe<OrderBy>;
  endTimecode?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  inResponseToSegmentId?: InputMaybe<OrderBy>;
  speakerParticipantId?: InputMaybe<OrderBy>;
  startTimecode?: InputMaybe<OrderBy>;
  videoClipUrl?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ClassEventMeetingSegmentsMinFields = {
  __typename?: 'ClassEventMeetingSegmentsMinFields';
  audioClipUrl?: Maybe<Scalars['String']>;
  classEventMeetingId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  /** seconds */
  duration?: Maybe<Scalars['Int']>;
  endTimecode?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  inResponseToSegmentId?: Maybe<Scalars['uuid']>;
  speakerParticipantId?: Maybe<Scalars['String']>;
  startTimecode?: Maybe<Scalars['numeric']>;
  videoClipUrl?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "class_event_meeting_segments" */
export type ClassEventMeetingSegmentsMinOrderBy = {
  audioClipUrl?: InputMaybe<OrderBy>;
  classEventMeetingId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  /** seconds */
  duration?: InputMaybe<OrderBy>;
  endTimecode?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  inResponseToSegmentId?: InputMaybe<OrderBy>;
  speakerParticipantId?: InputMaybe<OrderBy>;
  startTimecode?: InputMaybe<OrderBy>;
  videoClipUrl?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "class_event_meeting_segments" */
export type ClassEventMeetingSegmentsMutationResponse = {
  __typename?: 'ClassEventMeetingSegmentsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClassEventMeetingSegments>;
};

/** input type for inserting object relation for remote table "class_event_meeting_segments" */
export type ClassEventMeetingSegmentsObjRelInsertInput = {
  data: ClassEventMeetingSegmentsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ClassEventMeetingSegmentsOnConflict>;
};

/** on_conflict condition type for table "class_event_meeting_segments" */
export type ClassEventMeetingSegmentsOnConflict = {
  constraint: ClassEventMeetingSegmentsConstraint;
  updateColumns?: Array<ClassEventMeetingSegmentsUpdateColumn>;
  where?: InputMaybe<ClassEventMeetingSegmentsBoolExp>;
};

/** Ordering options when selecting data from "class_event_meeting_segments". */
export type ClassEventMeetingSegmentsOrderBy = {
  audioClipUrl?: InputMaybe<OrderBy>;
  classEventMeeting?: InputMaybe<ClassEventMeetingsOrderBy>;
  classEventMeetingId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  endTimecode?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  inResponseToSegment?: InputMaybe<ClassEventMeetingSegmentsOrderBy>;
  inResponseToSegmentId?: InputMaybe<OrderBy>;
  jobs?: InputMaybe<OrderBy>;
  lightningSubmissionsAggregate?: InputMaybe<LightningSessionSubmissionsAggregateOrderBy>;
  segmentResponsesAggregate?: InputMaybe<ClassEventMeetingSegmentsAggregateOrderBy>;
  speakerParticipant?: InputMaybe<ClassEventMeetingParticipantsOrderBy>;
  speakerParticipantId?: InputMaybe<OrderBy>;
  startTimecode?: InputMaybe<OrderBy>;
  synced?: InputMaybe<OrderBy>;
  transcription?: InputMaybe<OrderBy>;
  videoClipUrl?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: class_event_meeting_segments */
export type ClassEventMeetingSegmentsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ClassEventMeetingSegmentsPrependInput = {
  jobs?: InputMaybe<Scalars['jsonb']>;
  transcription?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "class_event_meeting_segments" */
export enum ClassEventMeetingSegmentsSelectColumn {
  /** column name */
  audioClipUrl = 'audioClipUrl',
  /** column name */
  classEventMeetingId = 'classEventMeetingId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  duration = 'duration',
  /** column name */
  endTimecode = 'endTimecode',
  /** column name */
  id = 'id',
  /** column name */
  inResponseToSegmentId = 'inResponseToSegmentId',
  /** column name */
  jobs = 'jobs',
  /** column name */
  speakerParticipantId = 'speakerParticipantId',
  /** column name */
  startTimecode = 'startTimecode',
  /** column name */
  synced = 'synced',
  /** column name */
  transcription = 'transcription',
  /** column name */
  videoClipUrl = 'videoClipUrl'
}

/** select "classEventMeetingSegmentsAggregateBoolExpBool_andArgumentsColumns" columns of table "class_event_meeting_segments" */
export enum ClassEventMeetingSegmentsSelectColumnClassEventMeetingSegmentsAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  synced = 'synced'
}

/** select "classEventMeetingSegmentsAggregateBoolExpBool_orArgumentsColumns" columns of table "class_event_meeting_segments" */
export enum ClassEventMeetingSegmentsSelectColumnClassEventMeetingSegmentsAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  synced = 'synced'
}

/** input type for updating data in table "class_event_meeting_segments" */
export type ClassEventMeetingSegmentsSetInput = {
  audioClipUrl?: InputMaybe<Scalars['String']>;
  classEventMeetingId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  /** seconds */
  duration?: InputMaybe<Scalars['Int']>;
  endTimecode?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  inResponseToSegmentId?: InputMaybe<Scalars['uuid']>;
  jobs?: InputMaybe<Scalars['jsonb']>;
  speakerParticipantId?: InputMaybe<Scalars['String']>;
  startTimecode?: InputMaybe<Scalars['numeric']>;
  synced?: InputMaybe<Scalars['Boolean']>;
  transcription?: InputMaybe<Scalars['jsonb']>;
  videoClipUrl?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ClassEventMeetingSegmentsStddevFields = {
  __typename?: 'ClassEventMeetingSegmentsStddevFields';
  /** seconds */
  duration?: Maybe<Scalars['Float']>;
  endTimecode?: Maybe<Scalars['Float']>;
  startTimecode?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "class_event_meeting_segments" */
export type ClassEventMeetingSegmentsStddevOrderBy = {
  /** seconds */
  duration?: InputMaybe<OrderBy>;
  endTimecode?: InputMaybe<OrderBy>;
  startTimecode?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ClassEventMeetingSegmentsStddevPopFields = {
  __typename?: 'ClassEventMeetingSegmentsStddevPopFields';
  /** seconds */
  duration?: Maybe<Scalars['Float']>;
  endTimecode?: Maybe<Scalars['Float']>;
  startTimecode?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "class_event_meeting_segments" */
export type ClassEventMeetingSegmentsStddevPopOrderBy = {
  /** seconds */
  duration?: InputMaybe<OrderBy>;
  endTimecode?: InputMaybe<OrderBy>;
  startTimecode?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ClassEventMeetingSegmentsStddevSampFields = {
  __typename?: 'ClassEventMeetingSegmentsStddevSampFields';
  /** seconds */
  duration?: Maybe<Scalars['Float']>;
  endTimecode?: Maybe<Scalars['Float']>;
  startTimecode?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "class_event_meeting_segments" */
export type ClassEventMeetingSegmentsStddevSampOrderBy = {
  /** seconds */
  duration?: InputMaybe<OrderBy>;
  endTimecode?: InputMaybe<OrderBy>;
  startTimecode?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "class_event_meeting_segments" */
export type ClassEventMeetingSegmentsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ClassEventMeetingSegmentsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ClassEventMeetingSegmentsStreamCursorValueInput = {
  audioClipUrl?: InputMaybe<Scalars['String']>;
  classEventMeetingId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  /** seconds */
  duration?: InputMaybe<Scalars['Int']>;
  endTimecode?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  inResponseToSegmentId?: InputMaybe<Scalars['uuid']>;
  jobs?: InputMaybe<Scalars['jsonb']>;
  speakerParticipantId?: InputMaybe<Scalars['String']>;
  startTimecode?: InputMaybe<Scalars['numeric']>;
  synced?: InputMaybe<Scalars['Boolean']>;
  transcription?: InputMaybe<Scalars['jsonb']>;
  videoClipUrl?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ClassEventMeetingSegmentsSumFields = {
  __typename?: 'ClassEventMeetingSegmentsSumFields';
  /** seconds */
  duration?: Maybe<Scalars['Int']>;
  endTimecode?: Maybe<Scalars['numeric']>;
  startTimecode?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "class_event_meeting_segments" */
export type ClassEventMeetingSegmentsSumOrderBy = {
  /** seconds */
  duration?: InputMaybe<OrderBy>;
  endTimecode?: InputMaybe<OrderBy>;
  startTimecode?: InputMaybe<OrderBy>;
};

/** update columns of table "class_event_meeting_segments" */
export enum ClassEventMeetingSegmentsUpdateColumn {
  /** column name */
  audioClipUrl = 'audioClipUrl',
  /** column name */
  classEventMeetingId = 'classEventMeetingId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  duration = 'duration',
  /** column name */
  endTimecode = 'endTimecode',
  /** column name */
  id = 'id',
  /** column name */
  inResponseToSegmentId = 'inResponseToSegmentId',
  /** column name */
  jobs = 'jobs',
  /** column name */
  speakerParticipantId = 'speakerParticipantId',
  /** column name */
  startTimecode = 'startTimecode',
  /** column name */
  synced = 'synced',
  /** column name */
  transcription = 'transcription',
  /** column name */
  videoClipUrl = 'videoClipUrl'
}

export type ClassEventMeetingSegmentsUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ClassEventMeetingSegmentsAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<ClassEventMeetingSegmentsDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<ClassEventMeetingSegmentsDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<ClassEventMeetingSegmentsDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ClassEventMeetingSegmentsIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ClassEventMeetingSegmentsPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClassEventMeetingSegmentsSetInput>;
  /** filter the rows which have to be updated */
  where: ClassEventMeetingSegmentsBoolExp;
};

/** aggregate varPop on columns */
export type ClassEventMeetingSegmentsVarPopFields = {
  __typename?: 'ClassEventMeetingSegmentsVarPopFields';
  /** seconds */
  duration?: Maybe<Scalars['Float']>;
  endTimecode?: Maybe<Scalars['Float']>;
  startTimecode?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "class_event_meeting_segments" */
export type ClassEventMeetingSegmentsVarPopOrderBy = {
  /** seconds */
  duration?: InputMaybe<OrderBy>;
  endTimecode?: InputMaybe<OrderBy>;
  startTimecode?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ClassEventMeetingSegmentsVarSampFields = {
  __typename?: 'ClassEventMeetingSegmentsVarSampFields';
  /** seconds */
  duration?: Maybe<Scalars['Float']>;
  endTimecode?: Maybe<Scalars['Float']>;
  startTimecode?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "class_event_meeting_segments" */
export type ClassEventMeetingSegmentsVarSampOrderBy = {
  /** seconds */
  duration?: InputMaybe<OrderBy>;
  endTimecode?: InputMaybe<OrderBy>;
  startTimecode?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ClassEventMeetingSegmentsVarianceFields = {
  __typename?: 'ClassEventMeetingSegmentsVarianceFields';
  /** seconds */
  duration?: Maybe<Scalars['Float']>;
  endTimecode?: Maybe<Scalars['Float']>;
  startTimecode?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "class_event_meeting_segments" */
export type ClassEventMeetingSegmentsVarianceOrderBy = {
  /** seconds */
  duration?: InputMaybe<OrderBy>;
  endTimecode?: InputMaybe<OrderBy>;
  startTimecode?: InputMaybe<OrderBy>;
};

/** columns and relationships of "class_event_meetings" */
export type ClassEventMeetings = {
  __typename?: 'ClassEventMeetings';
  altRecordingUrl?: Maybe<Scalars['String']>;
  awsMediaConvertJobId?: Maybe<Scalars['String']>;
  /** An array relationship */
  breakoutMeetings: Array<ClassEventMeetings>;
  /** An aggregate relationship */
  breakoutMeetingsAggregate: ClassEventMeetingsAggregate;
  /** An object relationship */
  classEvent?: Maybe<ClassEvents>;
  classEventId: Scalars['uuid'];
  dailySessionId?: Maybe<Scalars['uuid']>;
  /** seconds */
  duration?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  maxParticipants?: Maybe<Scalars['Int']>;
  /** An object relationship */
  parentMeeting?: Maybe<ClassEventMeetings>;
  parentMeetingId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  participants: Array<ClassEventMeetingParticipants>;
  /** An aggregate relationship */
  participantsAggregate: ClassEventMeetingParticipantsAggregate;
  posterFrameUrl?: Maybe<Scalars['String']>;
  recordingUrl?: Maybe<Scalars['String']>;
  room: Scalars['String'];
  /** An array relationship */
  segments: Array<ClassEventMeetingSegments>;
  /** An aggregate relationship */
  segmentsAggregate: ClassEventMeetingSegmentsAggregate;
  startTime?: Maybe<Scalars['timestamp']>;
  synced: Scalars['Boolean'];
};

/** columns and relationships of "class_event_meetings" */
export type ClassEventMeetingsBreakoutMeetingsArgs = {
  distinctOn?: InputMaybe<Array<ClassEventMeetingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventMeetingsOrderBy>>;
  where?: InputMaybe<ClassEventMeetingsBoolExp>;
};

/** columns and relationships of "class_event_meetings" */
export type ClassEventMeetingsBreakoutMeetingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassEventMeetingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventMeetingsOrderBy>>;
  where?: InputMaybe<ClassEventMeetingsBoolExp>;
};

/** columns and relationships of "class_event_meetings" */
export type ClassEventMeetingsParticipantsArgs = {
  distinctOn?: InputMaybe<Array<ClassEventMeetingParticipantsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventMeetingParticipantsOrderBy>>;
  where?: InputMaybe<ClassEventMeetingParticipantsBoolExp>;
};

/** columns and relationships of "class_event_meetings" */
export type ClassEventMeetingsParticipantsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassEventMeetingParticipantsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventMeetingParticipantsOrderBy>>;
  where?: InputMaybe<ClassEventMeetingParticipantsBoolExp>;
};

/** columns and relationships of "class_event_meetings" */
export type ClassEventMeetingsSegmentsArgs = {
  distinctOn?: InputMaybe<Array<ClassEventMeetingSegmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventMeetingSegmentsOrderBy>>;
  where?: InputMaybe<ClassEventMeetingSegmentsBoolExp>;
};

/** columns and relationships of "class_event_meetings" */
export type ClassEventMeetingsSegmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassEventMeetingSegmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventMeetingSegmentsOrderBy>>;
  where?: InputMaybe<ClassEventMeetingSegmentsBoolExp>;
};

/** aggregated selection of "class_event_meetings" */
export type ClassEventMeetingsAggregate = {
  __typename?: 'ClassEventMeetingsAggregate';
  aggregate?: Maybe<ClassEventMeetingsAggregateFields>;
  nodes: Array<ClassEventMeetings>;
};

export type ClassEventMeetingsAggregateBoolExp = {
  bool_and?: InputMaybe<ClassEventMeetingsAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<ClassEventMeetingsAggregateBoolExpBool_Or>;
  count?: InputMaybe<ClassEventMeetingsAggregateBoolExpCount>;
};

/** aggregate fields of "class_event_meetings" */
export type ClassEventMeetingsAggregateFields = {
  __typename?: 'ClassEventMeetingsAggregateFields';
  avg?: Maybe<ClassEventMeetingsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ClassEventMeetingsMaxFields>;
  min?: Maybe<ClassEventMeetingsMinFields>;
  stddev?: Maybe<ClassEventMeetingsStddevFields>;
  stddevPop?: Maybe<ClassEventMeetingsStddevPopFields>;
  stddevSamp?: Maybe<ClassEventMeetingsStddevSampFields>;
  sum?: Maybe<ClassEventMeetingsSumFields>;
  varPop?: Maybe<ClassEventMeetingsVarPopFields>;
  varSamp?: Maybe<ClassEventMeetingsVarSampFields>;
  variance?: Maybe<ClassEventMeetingsVarianceFields>;
};

/** aggregate fields of "class_event_meetings" */
export type ClassEventMeetingsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClassEventMeetingsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "class_event_meetings" */
export type ClassEventMeetingsAggregateOrderBy = {
  avg?: InputMaybe<ClassEventMeetingsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ClassEventMeetingsMaxOrderBy>;
  min?: InputMaybe<ClassEventMeetingsMinOrderBy>;
  stddev?: InputMaybe<ClassEventMeetingsStddevOrderBy>;
  stddevPop?: InputMaybe<ClassEventMeetingsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ClassEventMeetingsStddevSampOrderBy>;
  sum?: InputMaybe<ClassEventMeetingsSumOrderBy>;
  varPop?: InputMaybe<ClassEventMeetingsVarPopOrderBy>;
  varSamp?: InputMaybe<ClassEventMeetingsVarSampOrderBy>;
  variance?: InputMaybe<ClassEventMeetingsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "class_event_meetings" */
export type ClassEventMeetingsArrRelInsertInput = {
  data: Array<ClassEventMeetingsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ClassEventMeetingsOnConflict>;
};

/** aggregate avg on columns */
export type ClassEventMeetingsAvgFields = {
  __typename?: 'ClassEventMeetingsAvgFields';
  /** seconds */
  duration?: Maybe<Scalars['Float']>;
  maxParticipants?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "class_event_meetings" */
export type ClassEventMeetingsAvgOrderBy = {
  /** seconds */
  duration?: InputMaybe<OrderBy>;
  maxParticipants?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "class_event_meetings". All fields are combined with a logical 'AND'. */
export type ClassEventMeetingsBoolExp = {
  _and?: InputMaybe<Array<ClassEventMeetingsBoolExp>>;
  _not?: InputMaybe<ClassEventMeetingsBoolExp>;
  _or?: InputMaybe<Array<ClassEventMeetingsBoolExp>>;
  altRecordingUrl?: InputMaybe<StringComparisonExp>;
  awsMediaConvertJobId?: InputMaybe<StringComparisonExp>;
  breakoutMeetings?: InputMaybe<ClassEventMeetingsBoolExp>;
  breakoutMeetingsAggregate?: InputMaybe<ClassEventMeetingsAggregateBoolExp>;
  classEvent?: InputMaybe<ClassEventsBoolExp>;
  classEventId?: InputMaybe<UuidComparisonExp>;
  dailySessionId?: InputMaybe<UuidComparisonExp>;
  duration?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  maxParticipants?: InputMaybe<IntComparisonExp>;
  parentMeeting?: InputMaybe<ClassEventMeetingsBoolExp>;
  parentMeetingId?: InputMaybe<UuidComparisonExp>;
  participants?: InputMaybe<ClassEventMeetingParticipantsBoolExp>;
  participantsAggregate?: InputMaybe<ClassEventMeetingParticipantsAggregateBoolExp>;
  posterFrameUrl?: InputMaybe<StringComparisonExp>;
  recordingUrl?: InputMaybe<StringComparisonExp>;
  room?: InputMaybe<StringComparisonExp>;
  segments?: InputMaybe<ClassEventMeetingSegmentsBoolExp>;
  segmentsAggregate?: InputMaybe<ClassEventMeetingSegmentsAggregateBoolExp>;
  startTime?: InputMaybe<TimestampComparisonExp>;
  synced?: InputMaybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "class_event_meetings" */
export enum ClassEventMeetingsConstraint {
  /** unique or primary key constraint on columns "id" */
  class_event_meetings_pk = 'class_event_meetings_pk',
  /** unique or primary key constraint on columns "room", "class_event_id", "start_time" */
  class_event_meetings_pk2 = 'class_event_meetings_pk2'
}

/** input type for incrementing numeric columns in table "class_event_meetings" */
export type ClassEventMeetingsIncInput = {
  /** seconds */
  duration?: InputMaybe<Scalars['Int']>;
  maxParticipants?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "class_event_meetings" */
export type ClassEventMeetingsInsertInput = {
  altRecordingUrl?: InputMaybe<Scalars['String']>;
  awsMediaConvertJobId?: InputMaybe<Scalars['String']>;
  breakoutMeetings?: InputMaybe<ClassEventMeetingsArrRelInsertInput>;
  classEvent?: InputMaybe<ClassEventsObjRelInsertInput>;
  classEventId?: InputMaybe<Scalars['uuid']>;
  dailySessionId?: InputMaybe<Scalars['uuid']>;
  /** seconds */
  duration?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  maxParticipants?: InputMaybe<Scalars['Int']>;
  parentMeeting?: InputMaybe<ClassEventMeetingsObjRelInsertInput>;
  parentMeetingId?: InputMaybe<Scalars['uuid']>;
  participants?: InputMaybe<ClassEventMeetingParticipantsArrRelInsertInput>;
  posterFrameUrl?: InputMaybe<Scalars['String']>;
  recordingUrl?: InputMaybe<Scalars['String']>;
  room?: InputMaybe<Scalars['String']>;
  segments?: InputMaybe<ClassEventMeetingSegmentsArrRelInsertInput>;
  startTime?: InputMaybe<Scalars['timestamp']>;
  synced?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type ClassEventMeetingsMaxFields = {
  __typename?: 'ClassEventMeetingsMaxFields';
  altRecordingUrl?: Maybe<Scalars['String']>;
  awsMediaConvertJobId?: Maybe<Scalars['String']>;
  classEventId?: Maybe<Scalars['uuid']>;
  dailySessionId?: Maybe<Scalars['uuid']>;
  /** seconds */
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  maxParticipants?: Maybe<Scalars['Int']>;
  parentMeetingId?: Maybe<Scalars['uuid']>;
  posterFrameUrl?: Maybe<Scalars['String']>;
  recordingUrl?: Maybe<Scalars['String']>;
  room?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "class_event_meetings" */
export type ClassEventMeetingsMaxOrderBy = {
  altRecordingUrl?: InputMaybe<OrderBy>;
  awsMediaConvertJobId?: InputMaybe<OrderBy>;
  classEventId?: InputMaybe<OrderBy>;
  dailySessionId?: InputMaybe<OrderBy>;
  /** seconds */
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxParticipants?: InputMaybe<OrderBy>;
  parentMeetingId?: InputMaybe<OrderBy>;
  posterFrameUrl?: InputMaybe<OrderBy>;
  recordingUrl?: InputMaybe<OrderBy>;
  room?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ClassEventMeetingsMinFields = {
  __typename?: 'ClassEventMeetingsMinFields';
  altRecordingUrl?: Maybe<Scalars['String']>;
  awsMediaConvertJobId?: Maybe<Scalars['String']>;
  classEventId?: Maybe<Scalars['uuid']>;
  dailySessionId?: Maybe<Scalars['uuid']>;
  /** seconds */
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  maxParticipants?: Maybe<Scalars['Int']>;
  parentMeetingId?: Maybe<Scalars['uuid']>;
  posterFrameUrl?: Maybe<Scalars['String']>;
  recordingUrl?: Maybe<Scalars['String']>;
  room?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "class_event_meetings" */
export type ClassEventMeetingsMinOrderBy = {
  altRecordingUrl?: InputMaybe<OrderBy>;
  awsMediaConvertJobId?: InputMaybe<OrderBy>;
  classEventId?: InputMaybe<OrderBy>;
  dailySessionId?: InputMaybe<OrderBy>;
  /** seconds */
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxParticipants?: InputMaybe<OrderBy>;
  parentMeetingId?: InputMaybe<OrderBy>;
  posterFrameUrl?: InputMaybe<OrderBy>;
  recordingUrl?: InputMaybe<OrderBy>;
  room?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "class_event_meetings" */
export type ClassEventMeetingsMutationResponse = {
  __typename?: 'ClassEventMeetingsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClassEventMeetings>;
};

/** input type for inserting object relation for remote table "class_event_meetings" */
export type ClassEventMeetingsObjRelInsertInput = {
  data: ClassEventMeetingsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ClassEventMeetingsOnConflict>;
};

/** on_conflict condition type for table "class_event_meetings" */
export type ClassEventMeetingsOnConflict = {
  constraint: ClassEventMeetingsConstraint;
  updateColumns?: Array<ClassEventMeetingsUpdateColumn>;
  where?: InputMaybe<ClassEventMeetingsBoolExp>;
};

/** Ordering options when selecting data from "class_event_meetings". */
export type ClassEventMeetingsOrderBy = {
  altRecordingUrl?: InputMaybe<OrderBy>;
  awsMediaConvertJobId?: InputMaybe<OrderBy>;
  breakoutMeetingsAggregate?: InputMaybe<ClassEventMeetingsAggregateOrderBy>;
  classEvent?: InputMaybe<ClassEventsOrderBy>;
  classEventId?: InputMaybe<OrderBy>;
  dailySessionId?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxParticipants?: InputMaybe<OrderBy>;
  parentMeeting?: InputMaybe<ClassEventMeetingsOrderBy>;
  parentMeetingId?: InputMaybe<OrderBy>;
  participantsAggregate?: InputMaybe<ClassEventMeetingParticipantsAggregateOrderBy>;
  posterFrameUrl?: InputMaybe<OrderBy>;
  recordingUrl?: InputMaybe<OrderBy>;
  room?: InputMaybe<OrderBy>;
  segmentsAggregate?: InputMaybe<ClassEventMeetingSegmentsAggregateOrderBy>;
  startTime?: InputMaybe<OrderBy>;
  synced?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: class_event_meetings */
export type ClassEventMeetingsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "class_event_meetings" */
export enum ClassEventMeetingsSelectColumn {
  /** column name */
  altRecordingUrl = 'altRecordingUrl',
  /** column name */
  awsMediaConvertJobId = 'awsMediaConvertJobId',
  /** column name */
  classEventId = 'classEventId',
  /** column name */
  dailySessionId = 'dailySessionId',
  /** column name */
  duration = 'duration',
  /** column name */
  id = 'id',
  /** column name */
  maxParticipants = 'maxParticipants',
  /** column name */
  parentMeetingId = 'parentMeetingId',
  /** column name */
  posterFrameUrl = 'posterFrameUrl',
  /** column name */
  recordingUrl = 'recordingUrl',
  /** column name */
  room = 'room',
  /** column name */
  startTime = 'startTime',
  /** column name */
  synced = 'synced'
}

/** select "classEventMeetingsAggregateBoolExpBool_andArgumentsColumns" columns of table "class_event_meetings" */
export enum ClassEventMeetingsSelectColumnClassEventMeetingsAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  synced = 'synced'
}

/** select "classEventMeetingsAggregateBoolExpBool_orArgumentsColumns" columns of table "class_event_meetings" */
export enum ClassEventMeetingsSelectColumnClassEventMeetingsAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  synced = 'synced'
}

/** input type for updating data in table "class_event_meetings" */
export type ClassEventMeetingsSetInput = {
  altRecordingUrl?: InputMaybe<Scalars['String']>;
  awsMediaConvertJobId?: InputMaybe<Scalars['String']>;
  classEventId?: InputMaybe<Scalars['uuid']>;
  dailySessionId?: InputMaybe<Scalars['uuid']>;
  /** seconds */
  duration?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  maxParticipants?: InputMaybe<Scalars['Int']>;
  parentMeetingId?: InputMaybe<Scalars['uuid']>;
  posterFrameUrl?: InputMaybe<Scalars['String']>;
  recordingUrl?: InputMaybe<Scalars['String']>;
  room?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['timestamp']>;
  synced?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type ClassEventMeetingsStddevFields = {
  __typename?: 'ClassEventMeetingsStddevFields';
  /** seconds */
  duration?: Maybe<Scalars['Float']>;
  maxParticipants?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "class_event_meetings" */
export type ClassEventMeetingsStddevOrderBy = {
  /** seconds */
  duration?: InputMaybe<OrderBy>;
  maxParticipants?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ClassEventMeetingsStddevPopFields = {
  __typename?: 'ClassEventMeetingsStddevPopFields';
  /** seconds */
  duration?: Maybe<Scalars['Float']>;
  maxParticipants?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "class_event_meetings" */
export type ClassEventMeetingsStddevPopOrderBy = {
  /** seconds */
  duration?: InputMaybe<OrderBy>;
  maxParticipants?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ClassEventMeetingsStddevSampFields = {
  __typename?: 'ClassEventMeetingsStddevSampFields';
  /** seconds */
  duration?: Maybe<Scalars['Float']>;
  maxParticipants?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "class_event_meetings" */
export type ClassEventMeetingsStddevSampOrderBy = {
  /** seconds */
  duration?: InputMaybe<OrderBy>;
  maxParticipants?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "class_event_meetings" */
export type ClassEventMeetingsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ClassEventMeetingsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ClassEventMeetingsStreamCursorValueInput = {
  altRecordingUrl?: InputMaybe<Scalars['String']>;
  awsMediaConvertJobId?: InputMaybe<Scalars['String']>;
  classEventId?: InputMaybe<Scalars['uuid']>;
  dailySessionId?: InputMaybe<Scalars['uuid']>;
  /** seconds */
  duration?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  maxParticipants?: InputMaybe<Scalars['Int']>;
  parentMeetingId?: InputMaybe<Scalars['uuid']>;
  posterFrameUrl?: InputMaybe<Scalars['String']>;
  recordingUrl?: InputMaybe<Scalars['String']>;
  room?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['timestamp']>;
  synced?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type ClassEventMeetingsSumFields = {
  __typename?: 'ClassEventMeetingsSumFields';
  /** seconds */
  duration?: Maybe<Scalars['Int']>;
  maxParticipants?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "class_event_meetings" */
export type ClassEventMeetingsSumOrderBy = {
  /** seconds */
  duration?: InputMaybe<OrderBy>;
  maxParticipants?: InputMaybe<OrderBy>;
};

/** update columns of table "class_event_meetings" */
export enum ClassEventMeetingsUpdateColumn {
  /** column name */
  altRecordingUrl = 'altRecordingUrl',
  /** column name */
  awsMediaConvertJobId = 'awsMediaConvertJobId',
  /** column name */
  classEventId = 'classEventId',
  /** column name */
  dailySessionId = 'dailySessionId',
  /** column name */
  duration = 'duration',
  /** column name */
  id = 'id',
  /** column name */
  maxParticipants = 'maxParticipants',
  /** column name */
  parentMeetingId = 'parentMeetingId',
  /** column name */
  posterFrameUrl = 'posterFrameUrl',
  /** column name */
  recordingUrl = 'recordingUrl',
  /** column name */
  room = 'room',
  /** column name */
  startTime = 'startTime',
  /** column name */
  synced = 'synced'
}

export type ClassEventMeetingsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ClassEventMeetingsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClassEventMeetingsSetInput>;
  /** filter the rows which have to be updated */
  where: ClassEventMeetingsBoolExp;
};

/** aggregate varPop on columns */
export type ClassEventMeetingsVarPopFields = {
  __typename?: 'ClassEventMeetingsVarPopFields';
  /** seconds */
  duration?: Maybe<Scalars['Float']>;
  maxParticipants?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "class_event_meetings" */
export type ClassEventMeetingsVarPopOrderBy = {
  /** seconds */
  duration?: InputMaybe<OrderBy>;
  maxParticipants?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ClassEventMeetingsVarSampFields = {
  __typename?: 'ClassEventMeetingsVarSampFields';
  /** seconds */
  duration?: Maybe<Scalars['Float']>;
  maxParticipants?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "class_event_meetings" */
export type ClassEventMeetingsVarSampOrderBy = {
  /** seconds */
  duration?: InputMaybe<OrderBy>;
  maxParticipants?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ClassEventMeetingsVarianceFields = {
  __typename?: 'ClassEventMeetingsVarianceFields';
  /** seconds */
  duration?: Maybe<Scalars['Float']>;
  maxParticipants?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "class_event_meetings" */
export type ClassEventMeetingsVarianceOrderBy = {
  /** seconds */
  duration?: InputMaybe<OrderBy>;
  maxParticipants?: InputMaybe<OrderBy>;
};

/** columns and relationships of "class_event_registrations" */
export type ClassEventRegistrations = {
  __typename?: 'ClassEventRegistrations';
  /** An object relationship */
  classEvent?: Maybe<ClassEvents>;
  classEventId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  updatedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  updatedBy?: Maybe<Users>;
  updatedById?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['uuid']>;
  zoomJoinLinkUrl?: Maybe<Scalars['String']>;
};

/** aggregated selection of "class_event_registrations" */
export type ClassEventRegistrationsAggregate = {
  __typename?: 'ClassEventRegistrationsAggregate';
  aggregate?: Maybe<ClassEventRegistrationsAggregateFields>;
  nodes: Array<ClassEventRegistrations>;
};

/** aggregate fields of "class_event_registrations" */
export type ClassEventRegistrationsAggregateFields = {
  __typename?: 'ClassEventRegistrationsAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<ClassEventRegistrationsMaxFields>;
  min?: Maybe<ClassEventRegistrationsMinFields>;
};

/** aggregate fields of "class_event_registrations" */
export type ClassEventRegistrationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClassEventRegistrationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "class_event_registrations". All fields are combined with a logical 'AND'. */
export type ClassEventRegistrationsBoolExp = {
  _and?: InputMaybe<Array<ClassEventRegistrationsBoolExp>>;
  _not?: InputMaybe<ClassEventRegistrationsBoolExp>;
  _or?: InputMaybe<Array<ClassEventRegistrationsBoolExp>>;
  classEvent?: InputMaybe<ClassEventsBoolExp>;
  classEventId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  updatedBy?: InputMaybe<UsersBoolExp>;
  updatedById?: InputMaybe<UuidComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
  zoomJoinLinkUrl?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "class_event_registrations" */
export enum ClassEventRegistrationsConstraint {
  /** unique or primary key constraint on columns "id" */
  class_event_registrations_pk = 'class_event_registrations_pk',
  /** unique or primary key constraint on columns "user_id", "class_event_id" */
  class_event_registrations_user_id_class_event_id_key = 'class_event_registrations_user_id_class_event_id_key'
}

/** input type for inserting data into table "class_event_registrations" */
export type ClassEventRegistrationsInsertInput = {
  classEvent?: InputMaybe<ClassEventsObjRelInsertInput>;
  classEventId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<UsersObjRelInsertInput>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
  zoomJoinLinkUrl?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ClassEventRegistrationsMaxFields = {
  __typename?: 'ClassEventRegistrationsMaxFields';
  classEventId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
  zoomJoinLinkUrl?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ClassEventRegistrationsMinFields = {
  __typename?: 'ClassEventRegistrationsMinFields';
  classEventId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
  zoomJoinLinkUrl?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "class_event_registrations" */
export type ClassEventRegistrationsMutationResponse = {
  __typename?: 'ClassEventRegistrationsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClassEventRegistrations>;
};

/** on_conflict condition type for table "class_event_registrations" */
export type ClassEventRegistrationsOnConflict = {
  constraint: ClassEventRegistrationsConstraint;
  updateColumns?: Array<ClassEventRegistrationsUpdateColumn>;
  where?: InputMaybe<ClassEventRegistrationsBoolExp>;
};

/** Ordering options when selecting data from "class_event_registrations". */
export type ClassEventRegistrationsOrderBy = {
  classEvent?: InputMaybe<ClassEventsOrderBy>;
  classEventId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<UsersOrderBy>;
  updatedById?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
  zoomJoinLinkUrl?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: class_event_registrations */
export type ClassEventRegistrationsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "class_event_registrations" */
export enum ClassEventRegistrationsSelectColumn {
  /** column name */
  classEventId = 'classEventId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  id = 'id',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById',
  /** column name */
  userId = 'userId',
  /** column name */
  zoomJoinLinkUrl = 'zoomJoinLinkUrl'
}

/** input type for updating data in table "class_event_registrations" */
export type ClassEventRegistrationsSetInput = {
  classEventId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
  zoomJoinLinkUrl?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "class_event_registrations" */
export type ClassEventRegistrationsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ClassEventRegistrationsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ClassEventRegistrationsStreamCursorValueInput = {
  classEventId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
  zoomJoinLinkUrl?: InputMaybe<Scalars['String']>;
};

/** update columns of table "class_event_registrations" */
export enum ClassEventRegistrationsUpdateColumn {
  /** column name */
  classEventId = 'classEventId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  id = 'id',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById',
  /** column name */
  userId = 'userId',
  /** column name */
  zoomJoinLinkUrl = 'zoomJoinLinkUrl'
}

export type ClassEventRegistrationsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClassEventRegistrationsSetInput>;
  /** filter the rows which have to be updated */
  where: ClassEventRegistrationsBoolExp;
};

/** columns and relationships of "class_events" */
export type ClassEvents = {
  __typename?: 'ClassEvents';
  /** An object relationship */
  class?: Maybe<Classes>;
  /** An array relationship */
  classEventActivities: Array<ClassEventActivity>;
  /** An aggregate relationship */
  classEventActivitiesAggregate: ClassEventActivityAggregate;
  classId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  cohort?: Maybe<Cohorts>;
  cohortId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "class_event_duration" */
  durationInSeconds?: Maybe<Scalars['numeric']>;
  endTime?: Maybe<Scalars['timestamp']>;
  eventInviteVersion?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  /** An object relationship */
  lightningSession?: Maybe<LightningSession>;
  /** An array relationship */
  meetings: Array<ClassEventMeetings>;
  /** An aggregate relationship */
  meetingsAggregate: ClassEventMeetingsAggregate;
  /** A computed field, executes function "class_event_num_meetings" */
  numMeetings?: Maybe<Scalars['numeric']>;
  recordingUrl?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  startTime?: Maybe<Scalars['timestamp']>;
  transcript?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  updatedBy?: Maybe<Users>;
  updatedById?: Maybe<Scalars['uuid']>;
  zoomLinkUrl?: Maybe<Scalars['String']>;
  zoomMeetingId?: Maybe<Scalars['String']>;
};

/** columns and relationships of "class_events" */
export type ClassEventsClassEventActivitiesArgs = {
  distinctOn?: InputMaybe<Array<ClassEventActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventActivityOrderBy>>;
  where?: InputMaybe<ClassEventActivityBoolExp>;
};

/** columns and relationships of "class_events" */
export type ClassEventsClassEventActivitiesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassEventActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventActivityOrderBy>>;
  where?: InputMaybe<ClassEventActivityBoolExp>;
};

/** columns and relationships of "class_events" */
export type ClassEventsMeetingsArgs = {
  distinctOn?: InputMaybe<Array<ClassEventMeetingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventMeetingsOrderBy>>;
  where?: InputMaybe<ClassEventMeetingsBoolExp>;
};

/** columns and relationships of "class_events" */
export type ClassEventsMeetingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassEventMeetingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventMeetingsOrderBy>>;
  where?: InputMaybe<ClassEventMeetingsBoolExp>;
};

/** aggregated selection of "class_events" */
export type ClassEventsAggregate = {
  __typename?: 'ClassEventsAggregate';
  aggregate?: Maybe<ClassEventsAggregateFields>;
  nodes: Array<ClassEvents>;
};

export type ClassEventsAggregateBoolExp = {
  count?: InputMaybe<ClassEventsAggregateBoolExpCount>;
};

/** aggregate fields of "class_events" */
export type ClassEventsAggregateFields = {
  __typename?: 'ClassEventsAggregateFields';
  avg?: Maybe<ClassEventsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ClassEventsMaxFields>;
  min?: Maybe<ClassEventsMinFields>;
  stddev?: Maybe<ClassEventsStddevFields>;
  stddevPop?: Maybe<ClassEventsStddevPopFields>;
  stddevSamp?: Maybe<ClassEventsStddevSampFields>;
  sum?: Maybe<ClassEventsSumFields>;
  varPop?: Maybe<ClassEventsVarPopFields>;
  varSamp?: Maybe<ClassEventsVarSampFields>;
  variance?: Maybe<ClassEventsVarianceFields>;
};

/** aggregate fields of "class_events" */
export type ClassEventsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClassEventsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "class_events" */
export type ClassEventsAggregateOrderBy = {
  avg?: InputMaybe<ClassEventsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ClassEventsMaxOrderBy>;
  min?: InputMaybe<ClassEventsMinOrderBy>;
  stddev?: InputMaybe<ClassEventsStddevOrderBy>;
  stddevPop?: InputMaybe<ClassEventsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ClassEventsStddevSampOrderBy>;
  sum?: InputMaybe<ClassEventsSumOrderBy>;
  varPop?: InputMaybe<ClassEventsVarPopOrderBy>;
  varSamp?: InputMaybe<ClassEventsVarSampOrderBy>;
  variance?: InputMaybe<ClassEventsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "class_events" */
export type ClassEventsArrRelInsertInput = {
  data: Array<ClassEventsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ClassEventsOnConflict>;
};

/** aggregate avg on columns */
export type ClassEventsAvgFields = {
  __typename?: 'ClassEventsAvgFields';
  /** A computed field, executes function "class_event_duration" */
  durationInSeconds?: Maybe<Scalars['numeric']>;
  eventInviteVersion?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "class_event_num_meetings" */
  numMeetings?: Maybe<Scalars['numeric']>;
};

/** order by avg() on columns of table "class_events" */
export type ClassEventsAvgOrderBy = {
  eventInviteVersion?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "class_events". All fields are combined with a logical 'AND'. */
export type ClassEventsBoolExp = {
  _and?: InputMaybe<Array<ClassEventsBoolExp>>;
  _not?: InputMaybe<ClassEventsBoolExp>;
  _or?: InputMaybe<Array<ClassEventsBoolExp>>;
  class?: InputMaybe<ClassesBoolExp>;
  classEventActivities?: InputMaybe<ClassEventActivityBoolExp>;
  classEventActivitiesAggregate?: InputMaybe<ClassEventActivityAggregateBoolExp>;
  classId?: InputMaybe<UuidComparisonExp>;
  cohort?: InputMaybe<CohortsBoolExp>;
  cohortId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  durationInSeconds?: InputMaybe<NumericComparisonExp>;
  endTime?: InputMaybe<TimestampComparisonExp>;
  eventInviteVersion?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  lightningSession?: InputMaybe<LightningSessionBoolExp>;
  meetings?: InputMaybe<ClassEventMeetingsBoolExp>;
  meetingsAggregate?: InputMaybe<ClassEventMeetingsAggregateBoolExp>;
  numMeetings?: InputMaybe<NumericComparisonExp>;
  recordingUrl?: InputMaybe<StringComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  startTime?: InputMaybe<TimestampComparisonExp>;
  transcript?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  updatedBy?: InputMaybe<UsersBoolExp>;
  updatedById?: InputMaybe<UuidComparisonExp>;
  zoomLinkUrl?: InputMaybe<StringComparisonExp>;
  zoomMeetingId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "class_events" */
export enum ClassEventsConstraint {
  /** unique or primary key constraint on columns "id" */
  class_events_pk = 'class_events_pk'
}

/** input type for incrementing numeric columns in table "class_events" */
export type ClassEventsIncInput = {
  eventInviteVersion?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "class_events" */
export type ClassEventsInsertInput = {
  class?: InputMaybe<ClassesObjRelInsertInput>;
  classEventActivities?: InputMaybe<ClassEventActivityArrRelInsertInput>;
  classId?: InputMaybe<Scalars['uuid']>;
  cohort?: InputMaybe<CohortsObjRelInsertInput>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  endTime?: InputMaybe<Scalars['timestamp']>;
  eventInviteVersion?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  lightningSession?: InputMaybe<LightningSessionObjRelInsertInput>;
  meetings?: InputMaybe<ClassEventMeetingsArrRelInsertInput>;
  recordingUrl?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['timestamp']>;
  transcript?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<UsersObjRelInsertInput>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  zoomLinkUrl?: InputMaybe<Scalars['String']>;
  zoomMeetingId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ClassEventsMaxFields = {
  __typename?: 'ClassEventsMaxFields';
  classId?: Maybe<Scalars['uuid']>;
  cohortId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "class_event_duration" */
  durationInSeconds?: Maybe<Scalars['numeric']>;
  endTime?: Maybe<Scalars['timestamp']>;
  eventInviteVersion?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "class_event_num_meetings" */
  numMeetings?: Maybe<Scalars['numeric']>;
  recordingUrl?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['timestamp']>;
  transcript?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
  zoomLinkUrl?: Maybe<Scalars['String']>;
  zoomMeetingId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "class_events" */
export type ClassEventsMaxOrderBy = {
  classId?: InputMaybe<OrderBy>;
  cohortId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  endTime?: InputMaybe<OrderBy>;
  eventInviteVersion?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  recordingUrl?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<OrderBy>;
  transcript?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
  zoomLinkUrl?: InputMaybe<OrderBy>;
  zoomMeetingId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ClassEventsMinFields = {
  __typename?: 'ClassEventsMinFields';
  classId?: Maybe<Scalars['uuid']>;
  cohortId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "class_event_duration" */
  durationInSeconds?: Maybe<Scalars['numeric']>;
  endTime?: Maybe<Scalars['timestamp']>;
  eventInviteVersion?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "class_event_num_meetings" */
  numMeetings?: Maybe<Scalars['numeric']>;
  recordingUrl?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['timestamp']>;
  transcript?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
  zoomLinkUrl?: Maybe<Scalars['String']>;
  zoomMeetingId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "class_events" */
export type ClassEventsMinOrderBy = {
  classId?: InputMaybe<OrderBy>;
  cohortId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  endTime?: InputMaybe<OrderBy>;
  eventInviteVersion?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  recordingUrl?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<OrderBy>;
  transcript?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
  zoomLinkUrl?: InputMaybe<OrderBy>;
  zoomMeetingId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "class_events" */
export type ClassEventsMutationResponse = {
  __typename?: 'ClassEventsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClassEvents>;
};

/** input type for inserting object relation for remote table "class_events" */
export type ClassEventsObjRelInsertInput = {
  data: ClassEventsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ClassEventsOnConflict>;
};

/** on_conflict condition type for table "class_events" */
export type ClassEventsOnConflict = {
  constraint: ClassEventsConstraint;
  updateColumns?: Array<ClassEventsUpdateColumn>;
  where?: InputMaybe<ClassEventsBoolExp>;
};

/** Ordering options when selecting data from "class_events". */
export type ClassEventsOrderBy = {
  class?: InputMaybe<ClassesOrderBy>;
  classEventActivitiesAggregate?: InputMaybe<ClassEventActivityAggregateOrderBy>;
  classId?: InputMaybe<OrderBy>;
  cohort?: InputMaybe<CohortsOrderBy>;
  cohortId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  durationInSeconds?: InputMaybe<OrderBy>;
  endTime?: InputMaybe<OrderBy>;
  eventInviteVersion?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lightningSession?: InputMaybe<LightningSessionOrderBy>;
  meetingsAggregate?: InputMaybe<ClassEventMeetingsAggregateOrderBy>;
  numMeetings?: InputMaybe<OrderBy>;
  recordingUrl?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<OrderBy>;
  transcript?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<UsersOrderBy>;
  updatedById?: InputMaybe<OrderBy>;
  zoomLinkUrl?: InputMaybe<OrderBy>;
  zoomMeetingId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: class_events */
export type ClassEventsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "class_events" */
export enum ClassEventsSelectColumn {
  /** column name */
  classId = 'classId',
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  endTime = 'endTime',
  /** column name */
  eventInviteVersion = 'eventInviteVersion',
  /** column name */
  id = 'id',
  /** column name */
  recordingUrl = 'recordingUrl',
  /** column name */
  slug = 'slug',
  /** column name */
  startTime = 'startTime',
  /** column name */
  transcript = 'transcript',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById',
  /** column name */
  zoomLinkUrl = 'zoomLinkUrl',
  /** column name */
  zoomMeetingId = 'zoomMeetingId'
}

/** input type for updating data in table "class_events" */
export type ClassEventsSetInput = {
  classId?: InputMaybe<Scalars['uuid']>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  endTime?: InputMaybe<Scalars['timestamp']>;
  eventInviteVersion?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  recordingUrl?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['timestamp']>;
  transcript?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  zoomLinkUrl?: InputMaybe<Scalars['String']>;
  zoomMeetingId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ClassEventsStddevFields = {
  __typename?: 'ClassEventsStddevFields';
  /** A computed field, executes function "class_event_duration" */
  durationInSeconds?: Maybe<Scalars['numeric']>;
  eventInviteVersion?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "class_event_num_meetings" */
  numMeetings?: Maybe<Scalars['numeric']>;
};

/** order by stddev() on columns of table "class_events" */
export type ClassEventsStddevOrderBy = {
  eventInviteVersion?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ClassEventsStddevPopFields = {
  __typename?: 'ClassEventsStddevPopFields';
  /** A computed field, executes function "class_event_duration" */
  durationInSeconds?: Maybe<Scalars['numeric']>;
  eventInviteVersion?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "class_event_num_meetings" */
  numMeetings?: Maybe<Scalars['numeric']>;
};

/** order by stddevPop() on columns of table "class_events" */
export type ClassEventsStddevPopOrderBy = {
  eventInviteVersion?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ClassEventsStddevSampFields = {
  __typename?: 'ClassEventsStddevSampFields';
  /** A computed field, executes function "class_event_duration" */
  durationInSeconds?: Maybe<Scalars['numeric']>;
  eventInviteVersion?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "class_event_num_meetings" */
  numMeetings?: Maybe<Scalars['numeric']>;
};

/** order by stddevSamp() on columns of table "class_events" */
export type ClassEventsStddevSampOrderBy = {
  eventInviteVersion?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "class_events" */
export type ClassEventsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ClassEventsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ClassEventsStreamCursorValueInput = {
  classId?: InputMaybe<Scalars['uuid']>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  endTime?: InputMaybe<Scalars['timestamp']>;
  eventInviteVersion?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  recordingUrl?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['timestamp']>;
  transcript?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  zoomLinkUrl?: InputMaybe<Scalars['String']>;
  zoomMeetingId?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ClassEventsSumFields = {
  __typename?: 'ClassEventsSumFields';
  /** A computed field, executes function "class_event_duration" */
  durationInSeconds?: Maybe<Scalars['numeric']>;
  eventInviteVersion?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "class_event_num_meetings" */
  numMeetings?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "class_events" */
export type ClassEventsSumOrderBy = {
  eventInviteVersion?: InputMaybe<OrderBy>;
};

/** update columns of table "class_events" */
export enum ClassEventsUpdateColumn {
  /** column name */
  classId = 'classId',
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  endTime = 'endTime',
  /** column name */
  eventInviteVersion = 'eventInviteVersion',
  /** column name */
  id = 'id',
  /** column name */
  recordingUrl = 'recordingUrl',
  /** column name */
  slug = 'slug',
  /** column name */
  startTime = 'startTime',
  /** column name */
  transcript = 'transcript',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById',
  /** column name */
  zoomLinkUrl = 'zoomLinkUrl',
  /** column name */
  zoomMeetingId = 'zoomMeetingId'
}

export type ClassEventsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ClassEventsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClassEventsSetInput>;
  /** filter the rows which have to be updated */
  where: ClassEventsBoolExp;
};

/** aggregate varPop on columns */
export type ClassEventsVarPopFields = {
  __typename?: 'ClassEventsVarPopFields';
  /** A computed field, executes function "class_event_duration" */
  durationInSeconds?: Maybe<Scalars['numeric']>;
  eventInviteVersion?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "class_event_num_meetings" */
  numMeetings?: Maybe<Scalars['numeric']>;
};

/** order by varPop() on columns of table "class_events" */
export type ClassEventsVarPopOrderBy = {
  eventInviteVersion?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ClassEventsVarSampFields = {
  __typename?: 'ClassEventsVarSampFields';
  /** A computed field, executes function "class_event_duration" */
  durationInSeconds?: Maybe<Scalars['numeric']>;
  eventInviteVersion?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "class_event_num_meetings" */
  numMeetings?: Maybe<Scalars['numeric']>;
};

/** order by varSamp() on columns of table "class_events" */
export type ClassEventsVarSampOrderBy = {
  eventInviteVersion?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ClassEventsVarianceFields = {
  __typename?: 'ClassEventsVarianceFields';
  /** A computed field, executes function "class_event_duration" */
  durationInSeconds?: Maybe<Scalars['numeric']>;
  eventInviteVersion?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "class_event_num_meetings" */
  numMeetings?: Maybe<Scalars['numeric']>;
};

/** order by variance() on columns of table "class_events" */
export type ClassEventsVarianceOrderBy = {
  eventInviteVersion?: InputMaybe<OrderBy>;
};

/** columns and relationships of "class_playback_activity" */
export type ClassPlaybackActivity = {
  __typename?: 'ClassPlaybackActivity';
  classEventId: Scalars['uuid'];
  /** An object relationship */
  class_event: ClassEvents;
  context?: Maybe<Scalars['jsonb']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdBy?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  type: ClassPlaybackActivityTypeEnum;
  /** An object relationship */
  user?: Maybe<Users>;
  /** An object relationship */
  userByUserId: Users;
  userId: Scalars['uuid'];
};

/** columns and relationships of "class_playback_activity" */
export type ClassPlaybackActivityContextArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "class_playback_activity" */
export type ClassPlaybackActivityAggregate = {
  __typename?: 'ClassPlaybackActivityAggregate';
  aggregate?: Maybe<ClassPlaybackActivityAggregateFields>;
  nodes: Array<ClassPlaybackActivity>;
};

/** aggregate fields of "class_playback_activity" */
export type ClassPlaybackActivityAggregateFields = {
  __typename?: 'ClassPlaybackActivityAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<ClassPlaybackActivityMaxFields>;
  min?: Maybe<ClassPlaybackActivityMinFields>;
};

/** aggregate fields of "class_playback_activity" */
export type ClassPlaybackActivityAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClassPlaybackActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ClassPlaybackActivityAppendInput = {
  context?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "class_playback_activity". All fields are combined with a logical 'AND'. */
export type ClassPlaybackActivityBoolExp = {
  _and?: InputMaybe<Array<ClassPlaybackActivityBoolExp>>;
  _not?: InputMaybe<ClassPlaybackActivityBoolExp>;
  _or?: InputMaybe<Array<ClassPlaybackActivityBoolExp>>;
  classEventId?: InputMaybe<UuidComparisonExp>;
  class_event?: InputMaybe<ClassEventsBoolExp>;
  context?: InputMaybe<JsonbComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  type?: InputMaybe<ClassPlaybackActivityTypeEnumComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userByUserId?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "class_playback_activity" */
export enum ClassPlaybackActivityConstraint {
  /** unique or primary key constraint on columns "id" */
  class_playback_activity_pkey = 'class_playback_activity_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ClassPlaybackActivityDeleteAtPathInput = {
  context?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ClassPlaybackActivityDeleteElemInput = {
  context?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ClassPlaybackActivityDeleteKeyInput = {
  context?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "class_playback_activity" */
export type ClassPlaybackActivityInsertInput = {
  classEventId?: InputMaybe<Scalars['uuid']>;
  class_event?: InputMaybe<ClassEventsObjRelInsertInput>;
  context?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<ClassPlaybackActivityTypeEnum>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userByUserId?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ClassPlaybackActivityMaxFields = {
  __typename?: 'ClassPlaybackActivityMaxFields';
  classEventId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdBy?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ClassPlaybackActivityMinFields = {
  __typename?: 'ClassPlaybackActivityMinFields';
  classEventId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdBy?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "class_playback_activity" */
export type ClassPlaybackActivityMutationResponse = {
  __typename?: 'ClassPlaybackActivityMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClassPlaybackActivity>;
};

/** on_conflict condition type for table "class_playback_activity" */
export type ClassPlaybackActivityOnConflict = {
  constraint: ClassPlaybackActivityConstraint;
  updateColumns?: Array<ClassPlaybackActivityUpdateColumn>;
  where?: InputMaybe<ClassPlaybackActivityBoolExp>;
};

/** Ordering options when selecting data from "class_playback_activity". */
export type ClassPlaybackActivityOrderBy = {
  classEventId?: InputMaybe<OrderBy>;
  class_event?: InputMaybe<ClassEventsOrderBy>;
  context?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userByUserId?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: class_playback_activity */
export type ClassPlaybackActivityPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ClassPlaybackActivityPrependInput = {
  context?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "class_playback_activity" */
export enum ClassPlaybackActivitySelectColumn {
  /** column name */
  classEventId = 'classEventId',
  /** column name */
  context = 'context',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdBy = 'createdBy',
  /** column name */
  id = 'id',
  /** column name */
  type = 'type',
  /** column name */
  userId = 'userId'
}

/** input type for updating data in table "class_playback_activity" */
export type ClassPlaybackActivitySetInput = {
  classEventId?: InputMaybe<Scalars['uuid']>;
  context?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<ClassPlaybackActivityTypeEnum>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "class_playback_activity" */
export type ClassPlaybackActivityStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ClassPlaybackActivityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ClassPlaybackActivityStreamCursorValueInput = {
  classEventId?: InputMaybe<Scalars['uuid']>;
  context?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<ClassPlaybackActivityTypeEnum>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "class_playback_activity_type_enum" */
export type ClassPlaybackActivityType = {
  __typename?: 'ClassPlaybackActivityType';
  value: Scalars['String'];
};

/** aggregated selection of "class_playback_activity_type_enum" */
export type ClassPlaybackActivityTypeAggregate = {
  __typename?: 'ClassPlaybackActivityTypeAggregate';
  aggregate?: Maybe<ClassPlaybackActivityTypeAggregateFields>;
  nodes: Array<ClassPlaybackActivityType>;
};

/** aggregate fields of "class_playback_activity_type_enum" */
export type ClassPlaybackActivityTypeAggregateFields = {
  __typename?: 'ClassPlaybackActivityTypeAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<ClassPlaybackActivityTypeMaxFields>;
  min?: Maybe<ClassPlaybackActivityTypeMinFields>;
};

/** aggregate fields of "class_playback_activity_type_enum" */
export type ClassPlaybackActivityTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClassPlaybackActivityTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "class_playback_activity_type_enum". All fields are combined with a logical 'AND'. */
export type ClassPlaybackActivityTypeBoolExp = {
  _and?: InputMaybe<Array<ClassPlaybackActivityTypeBoolExp>>;
  _not?: InputMaybe<ClassPlaybackActivityTypeBoolExp>;
  _or?: InputMaybe<Array<ClassPlaybackActivityTypeBoolExp>>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "class_playback_activity_type_enum" */
export enum ClassPlaybackActivityTypeConstraint {
  /** unique or primary key constraint on columns "value" */
  class_playback_activity_type_enum_pkey = 'class_playback_activity_type_enum_pkey'
}

export enum ClassPlaybackActivityTypeEnum {
  START_PLAYBACK = 'START_PLAYBACK',
  STOP_PLAYBACK = 'STOP_PLAYBACK',
  UPDATE_PLAYBACK = 'UPDATE_PLAYBACK'
}

/** Boolean expression to compare columns of type "ClassPlaybackActivityTypeEnum". All fields are combined with logical 'AND'. */
export type ClassPlaybackActivityTypeEnumComparisonExp = {
  _eq?: InputMaybe<ClassPlaybackActivityTypeEnum>;
  _in?: InputMaybe<Array<ClassPlaybackActivityTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ClassPlaybackActivityTypeEnum>;
  _nin?: InputMaybe<Array<ClassPlaybackActivityTypeEnum>>;
};

/** input type for inserting data into table "class_playback_activity_type_enum" */
export type ClassPlaybackActivityTypeInsertInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ClassPlaybackActivityTypeMaxFields = {
  __typename?: 'ClassPlaybackActivityTypeMaxFields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ClassPlaybackActivityTypeMinFields = {
  __typename?: 'ClassPlaybackActivityTypeMinFields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "class_playback_activity_type_enum" */
export type ClassPlaybackActivityTypeMutationResponse = {
  __typename?: 'ClassPlaybackActivityTypeMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClassPlaybackActivityType>;
};

/** on_conflict condition type for table "class_playback_activity_type_enum" */
export type ClassPlaybackActivityTypeOnConflict = {
  constraint: ClassPlaybackActivityTypeConstraint;
  updateColumns?: Array<ClassPlaybackActivityTypeUpdateColumn>;
  where?: InputMaybe<ClassPlaybackActivityTypeBoolExp>;
};

/** Ordering options when selecting data from "class_playback_activity_type_enum". */
export type ClassPlaybackActivityTypeOrderBy = {
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: class_playback_activity_type_enum */
export type ClassPlaybackActivityTypePkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "class_playback_activity_type_enum" */
export enum ClassPlaybackActivityTypeSelectColumn {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "class_playback_activity_type_enum" */
export type ClassPlaybackActivityTypeSetInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ClassPlaybackActivityType" */
export type ClassPlaybackActivityTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ClassPlaybackActivityTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ClassPlaybackActivityTypeStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "class_playback_activity_type_enum" */
export enum ClassPlaybackActivityTypeUpdateColumn {
  /** column name */
  value = 'value'
}

export type ClassPlaybackActivityTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClassPlaybackActivityTypeSetInput>;
  /** filter the rows which have to be updated */
  where: ClassPlaybackActivityTypeBoolExp;
};

/** update columns of table "class_playback_activity" */
export enum ClassPlaybackActivityUpdateColumn {
  /** column name */
  classEventId = 'classEventId',
  /** column name */
  context = 'context',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdBy = 'createdBy',
  /** column name */
  id = 'id',
  /** column name */
  type = 'type',
  /** column name */
  userId = 'userId'
}

export type ClassPlaybackActivityUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ClassPlaybackActivityAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<ClassPlaybackActivityDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<ClassPlaybackActivityDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<ClassPlaybackActivityDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ClassPlaybackActivityPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClassPlaybackActivitySetInput>;
  /** filter the rows which have to be updated */
  where: ClassPlaybackActivityBoolExp;
};

/** columns and relationships of "classes_resources_rel" */
export type ClassResources = {
  __typename?: 'ClassResources';
  /** An object relationship */
  class: Classes;
  classId: Scalars['uuid'];
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  resource: Resources;
  resourceId: Scalars['uuid'];
};

/** aggregated selection of "classes_resources_rel" */
export type ClassResourcesAggregate = {
  __typename?: 'ClassResourcesAggregate';
  aggregate?: Maybe<ClassResourcesAggregateFields>;
  nodes: Array<ClassResources>;
};

export type ClassResourcesAggregateBoolExp = {
  count?: InputMaybe<ClassResourcesAggregateBoolExpCount>;
};

export type ClassResourcesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ClassResourcesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClassResourcesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "classes_resources_rel" */
export type ClassResourcesAggregateFields = {
  __typename?: 'ClassResourcesAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<ClassResourcesMaxFields>;
  min?: Maybe<ClassResourcesMinFields>;
};

/** aggregate fields of "classes_resources_rel" */
export type ClassResourcesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClassResourcesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "classes_resources_rel" */
export type ClassResourcesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ClassResourcesMaxOrderBy>;
  min?: InputMaybe<ClassResourcesMinOrderBy>;
};

/** input type for inserting array relation for remote table "classes_resources_rel" */
export type ClassResourcesArrRelInsertInput = {
  data: Array<ClassResourcesInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ClassResourcesOnConflict>;
};

/** Boolean expression to filter rows from the table "classes_resources_rel". All fields are combined with a logical 'AND'. */
export type ClassResourcesBoolExp = {
  _and?: InputMaybe<Array<ClassResourcesBoolExp>>;
  _not?: InputMaybe<ClassResourcesBoolExp>;
  _or?: InputMaybe<Array<ClassResourcesBoolExp>>;
  class?: InputMaybe<ClassesBoolExp>;
  classId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  resource?: InputMaybe<ResourcesBoolExp>;
  resourceId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "classes_resources_rel" */
export enum ClassResourcesConstraint {
  /** unique or primary key constraint on columns "resource_id", "class_id" */
  classes_resources_rel_pk = 'classes_resources_rel_pk'
}

/** input type for inserting data into table "classes_resources_rel" */
export type ClassResourcesInsertInput = {
  class?: InputMaybe<ClassesObjRelInsertInput>;
  classId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  resource?: InputMaybe<ResourcesObjRelInsertInput>;
  resourceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ClassResourcesMaxFields = {
  __typename?: 'ClassResourcesMaxFields';
  classId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  resourceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "classes_resources_rel" */
export type ClassResourcesMaxOrderBy = {
  classId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  resourceId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ClassResourcesMinFields = {
  __typename?: 'ClassResourcesMinFields';
  classId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  resourceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "classes_resources_rel" */
export type ClassResourcesMinOrderBy = {
  classId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  resourceId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "classes_resources_rel" */
export type ClassResourcesMutationResponse = {
  __typename?: 'ClassResourcesMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClassResources>;
};

/** input type for inserting object relation for remote table "classes_resources_rel" */
export type ClassResourcesObjRelInsertInput = {
  data: ClassResourcesInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ClassResourcesOnConflict>;
};

/** on_conflict condition type for table "classes_resources_rel" */
export type ClassResourcesOnConflict = {
  constraint: ClassResourcesConstraint;
  updateColumns?: Array<ClassResourcesUpdateColumn>;
  where?: InputMaybe<ClassResourcesBoolExp>;
};

/** Ordering options when selecting data from "classes_resources_rel". */
export type ClassResourcesOrderBy = {
  class?: InputMaybe<ClassesOrderBy>;
  classId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  resource?: InputMaybe<ResourcesOrderBy>;
  resourceId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: classes_resources_rel */
export type ClassResourcesPkColumnsInput = {
  classId: Scalars['uuid'];
  resourceId: Scalars['uuid'];
};

/** select columns of table "classes_resources_rel" */
export enum ClassResourcesSelectColumn {
  /** column name */
  classId = 'classId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  resourceId = 'resourceId'
}

/** input type for updating data in table "classes_resources_rel" */
export type ClassResourcesSetInput = {
  classId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  resourceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "ClassResources" */
export type ClassResourcesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ClassResourcesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ClassResourcesStreamCursorValueInput = {
  classId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  resourceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "classes_resources_rel" */
export enum ClassResourcesUpdateColumn {
  /** column name */
  classId = 'classId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  resourceId = 'resourceId'
}

export type ClassResourcesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClassResourcesSetInput>;
  /** filter the rows which have to be updated */
  where: ClassResourcesBoolExp;
};

/** columns and relationships of "classes" */
export type Classes = {
  __typename?: 'Classes';
  /** An array relationship */
  classEvents: Array<ClassEvents>;
  /** An aggregate relationship */
  classEventsAggregate: ClassEventsAggregate;
  /** An array relationship */
  coaches: Array<ClassCoaches>;
  /** An aggregate relationship */
  coachesAggregate: ClassCoachesAggregate;
  /** An object relationship */
  course?: Maybe<Courses>;
  courseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  descriptionRich?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  isLive?: Maybe<Scalars['Boolean']>;
  listDescription?: Maybe<Scalars['json']>;
  name: Scalars['String'];
  number?: Maybe<Scalars['Int']>;
  /** An array relationship */
  resources: Array<ClassResources>;
  /** An aggregate relationship */
  resourcesAggregate: ClassResourcesAggregate;
  slug: Scalars['String'];
  /** An array relationship */
  tags: Array<ClassesTagsRel>;
  /** An aggregate relationship */
  tagsAggregate: ClassesTagsRelAggregate;
  updatedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  updatedBy?: Maybe<Users>;
  updatedById?: Maybe<Scalars['uuid']>;
  version?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "classes" */
export type ClassesClassEventsArgs = {
  distinctOn?: InputMaybe<Array<ClassEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventsOrderBy>>;
  where?: InputMaybe<ClassEventsBoolExp>;
};

/** columns and relationships of "classes" */
export type ClassesClassEventsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventsOrderBy>>;
  where?: InputMaybe<ClassEventsBoolExp>;
};

/** columns and relationships of "classes" */
export type ClassesCoachesArgs = {
  distinctOn?: InputMaybe<Array<ClassCoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassCoachesOrderBy>>;
  where?: InputMaybe<ClassCoachesBoolExp>;
};

/** columns and relationships of "classes" */
export type ClassesCoachesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassCoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassCoachesOrderBy>>;
  where?: InputMaybe<ClassCoachesBoolExp>;
};

/** columns and relationships of "classes" */
export type ClassesListDescriptionArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "classes" */
export type ClassesResourcesArgs = {
  distinctOn?: InputMaybe<Array<ClassResourcesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassResourcesOrderBy>>;
  where?: InputMaybe<ClassResourcesBoolExp>;
};

/** columns and relationships of "classes" */
export type ClassesResourcesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassResourcesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassResourcesOrderBy>>;
  where?: InputMaybe<ClassResourcesBoolExp>;
};

/** columns and relationships of "classes" */
export type ClassesTagsArgs = {
  distinctOn?: InputMaybe<Array<ClassesTagsRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassesTagsRelOrderBy>>;
  where?: InputMaybe<ClassesTagsRelBoolExp>;
};

/** columns and relationships of "classes" */
export type ClassesTagsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassesTagsRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassesTagsRelOrderBy>>;
  where?: InputMaybe<ClassesTagsRelBoolExp>;
};

/** aggregated selection of "classes" */
export type ClassesAggregate = {
  __typename?: 'ClassesAggregate';
  aggregate?: Maybe<ClassesAggregateFields>;
  nodes: Array<Classes>;
};

export type ClassesAggregateBoolExp = {
  bool_and?: InputMaybe<ClassesAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<ClassesAggregateBoolExpBool_Or>;
  count?: InputMaybe<ClassesAggregateBoolExpCount>;
};

/** aggregate fields of "classes" */
export type ClassesAggregateFields = {
  __typename?: 'ClassesAggregateFields';
  avg?: Maybe<ClassesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ClassesMaxFields>;
  min?: Maybe<ClassesMinFields>;
  stddev?: Maybe<ClassesStddevFields>;
  stddevPop?: Maybe<ClassesStddevPopFields>;
  stddevSamp?: Maybe<ClassesStddevSampFields>;
  sum?: Maybe<ClassesSumFields>;
  varPop?: Maybe<ClassesVarPopFields>;
  varSamp?: Maybe<ClassesVarSampFields>;
  variance?: Maybe<ClassesVarianceFields>;
};

/** aggregate fields of "classes" */
export type ClassesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClassesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "classes" */
export type ClassesAggregateOrderBy = {
  avg?: InputMaybe<ClassesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ClassesMaxOrderBy>;
  min?: InputMaybe<ClassesMinOrderBy>;
  stddev?: InputMaybe<ClassesStddevOrderBy>;
  stddevPop?: InputMaybe<ClassesStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ClassesStddevSampOrderBy>;
  sum?: InputMaybe<ClassesSumOrderBy>;
  varPop?: InputMaybe<ClassesVarPopOrderBy>;
  varSamp?: InputMaybe<ClassesVarSampOrderBy>;
  variance?: InputMaybe<ClassesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "classes" */
export type ClassesArrRelInsertInput = {
  data: Array<ClassesInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ClassesOnConflict>;
};

/** aggregate avg on columns */
export type ClassesAvgFields = {
  __typename?: 'ClassesAvgFields';
  durationInMinutes?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "classes" */
export type ClassesAvgOrderBy = {
  durationInMinutes?: InputMaybe<OrderBy>;
  number?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "classes". All fields are combined with a logical 'AND'. */
export type ClassesBoolExp = {
  _and?: InputMaybe<Array<ClassesBoolExp>>;
  _not?: InputMaybe<ClassesBoolExp>;
  _or?: InputMaybe<Array<ClassesBoolExp>>;
  classEvents?: InputMaybe<ClassEventsBoolExp>;
  classEventsAggregate?: InputMaybe<ClassEventsAggregateBoolExp>;
  coaches?: InputMaybe<ClassCoachesBoolExp>;
  coachesAggregate?: InputMaybe<ClassCoachesAggregateBoolExp>;
  course?: InputMaybe<CoursesBoolExp>;
  courseId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  descriptionRich?: InputMaybe<StringComparisonExp>;
  durationInMinutes?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isLive?: InputMaybe<BooleanComparisonExp>;
  listDescription?: InputMaybe<JsonComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  number?: InputMaybe<IntComparisonExp>;
  resources?: InputMaybe<ClassResourcesBoolExp>;
  resourcesAggregate?: InputMaybe<ClassResourcesAggregateBoolExp>;
  slug?: InputMaybe<StringComparisonExp>;
  tags?: InputMaybe<ClassesTagsRelBoolExp>;
  tagsAggregate?: InputMaybe<ClassesTagsRelAggregateBoolExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  updatedBy?: InputMaybe<UsersBoolExp>;
  updatedById?: InputMaybe<UuidComparisonExp>;
  version?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "classes" */
export enum ClassesConstraint {
  /** unique or primary key constraint on columns "id" */
  classes_pk = 'classes_pk'
}

/** input type for incrementing numeric columns in table "classes" */
export type ClassesIncInput = {
  durationInMinutes?: InputMaybe<Scalars['Int']>;
  number?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "classes" */
export type ClassesInsertInput = {
  classEvents?: InputMaybe<ClassEventsArrRelInsertInput>;
  coaches?: InputMaybe<ClassCoachesArrRelInsertInput>;
  course?: InputMaybe<CoursesObjRelInsertInput>;
  courseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  descriptionRich?: InputMaybe<Scalars['String']>;
  durationInMinutes?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  isLive?: InputMaybe<Scalars['Boolean']>;
  listDescription?: InputMaybe<Scalars['json']>;
  name?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['Int']>;
  resources?: InputMaybe<ClassResourcesArrRelInsertInput>;
  slug?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<ClassesTagsRelArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<UsersObjRelInsertInput>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ClassesMaxFields = {
  __typename?: 'ClassesMaxFields';
  courseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  descriptionRich?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
  version?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "classes" */
export type ClassesMaxOrderBy = {
  courseId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  descriptionRich?: InputMaybe<OrderBy>;
  durationInMinutes?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  number?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ClassesMinFields = {
  __typename?: 'ClassesMinFields';
  courseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  descriptionRich?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
  version?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "classes" */
export type ClassesMinOrderBy = {
  courseId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  descriptionRich?: InputMaybe<OrderBy>;
  durationInMinutes?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  number?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "classes" */
export type ClassesMutationResponse = {
  __typename?: 'ClassesMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Classes>;
};

/** input type for inserting object relation for remote table "classes" */
export type ClassesObjRelInsertInput = {
  data: ClassesInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ClassesOnConflict>;
};

/** on_conflict condition type for table "classes" */
export type ClassesOnConflict = {
  constraint: ClassesConstraint;
  updateColumns?: Array<ClassesUpdateColumn>;
  where?: InputMaybe<ClassesBoolExp>;
};

/** Ordering options when selecting data from "classes". */
export type ClassesOrderBy = {
  classEventsAggregate?: InputMaybe<ClassEventsAggregateOrderBy>;
  coachesAggregate?: InputMaybe<ClassCoachesAggregateOrderBy>;
  course?: InputMaybe<CoursesOrderBy>;
  courseId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  descriptionRich?: InputMaybe<OrderBy>;
  durationInMinutes?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isLive?: InputMaybe<OrderBy>;
  listDescription?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  number?: InputMaybe<OrderBy>;
  resourcesAggregate?: InputMaybe<ClassResourcesAggregateOrderBy>;
  slug?: InputMaybe<OrderBy>;
  tagsAggregate?: InputMaybe<ClassesTagsRelAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<UsersOrderBy>;
  updatedById?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: classes */
export type ClassesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "classes" */
export enum ClassesSelectColumn {
  /** column name */
  courseId = 'courseId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  descriptionRich = 'descriptionRich',
  /** column name */
  durationInMinutes = 'durationInMinutes',
  /** column name */
  id = 'id',
  /** column name */
  isLive = 'isLive',
  /** column name */
  listDescription = 'listDescription',
  /** column name */
  name = 'name',
  /** column name */
  number = 'number',
  /** column name */
  slug = 'slug',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById',
  /** column name */
  version = 'version'
}

/** select "classesAggregateBoolExpBool_andArgumentsColumns" columns of table "classes" */
export enum ClassesSelectColumnClassesAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  isLive = 'isLive'
}

/** select "classesAggregateBoolExpBool_orArgumentsColumns" columns of table "classes" */
export enum ClassesSelectColumnClassesAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  isLive = 'isLive'
}

/** input type for updating data in table "classes" */
export type ClassesSetInput = {
  courseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  descriptionRich?: InputMaybe<Scalars['String']>;
  durationInMinutes?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  isLive?: InputMaybe<Scalars['Boolean']>;
  listDescription?: InputMaybe<Scalars['json']>;
  name?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ClassesStddevFields = {
  __typename?: 'ClassesStddevFields';
  durationInMinutes?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "classes" */
export type ClassesStddevOrderBy = {
  durationInMinutes?: InputMaybe<OrderBy>;
  number?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ClassesStddevPopFields = {
  __typename?: 'ClassesStddevPopFields';
  durationInMinutes?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "classes" */
export type ClassesStddevPopOrderBy = {
  durationInMinutes?: InputMaybe<OrderBy>;
  number?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ClassesStddevSampFields = {
  __typename?: 'ClassesStddevSampFields';
  durationInMinutes?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "classes" */
export type ClassesStddevSampOrderBy = {
  durationInMinutes?: InputMaybe<OrderBy>;
  number?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "classes" */
export type ClassesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ClassesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ClassesStreamCursorValueInput = {
  courseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  descriptionRich?: InputMaybe<Scalars['String']>;
  durationInMinutes?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  isLive?: InputMaybe<Scalars['Boolean']>;
  listDescription?: InputMaybe<Scalars['json']>;
  name?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ClassesSumFields = {
  __typename?: 'ClassesSumFields';
  durationInMinutes?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "classes" */
export type ClassesSumOrderBy = {
  durationInMinutes?: InputMaybe<OrderBy>;
  number?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** columns and relationships of "classes_tags_rel" */
export type ClassesTagsRel = {
  __typename?: 'ClassesTagsRel';
  /** An object relationship */
  class?: Maybe<Classes>;
  classId: Scalars['uuid'];
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  tag?: Maybe<Tags>;
  tagId: Scalars['uuid'];
};

/** aggregated selection of "classes_tags_rel" */
export type ClassesTagsRelAggregate = {
  __typename?: 'ClassesTagsRelAggregate';
  aggregate?: Maybe<ClassesTagsRelAggregateFields>;
  nodes: Array<ClassesTagsRel>;
};

export type ClassesTagsRelAggregateBoolExp = {
  count?: InputMaybe<ClassesTagsRelAggregateBoolExpCount>;
};

/** aggregate fields of "classes_tags_rel" */
export type ClassesTagsRelAggregateFields = {
  __typename?: 'ClassesTagsRelAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<ClassesTagsRelMaxFields>;
  min?: Maybe<ClassesTagsRelMinFields>;
};

/** aggregate fields of "classes_tags_rel" */
export type ClassesTagsRelAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClassesTagsRelSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "classes_tags_rel" */
export type ClassesTagsRelAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ClassesTagsRelMaxOrderBy>;
  min?: InputMaybe<ClassesTagsRelMinOrderBy>;
};

/** input type for inserting array relation for remote table "classes_tags_rel" */
export type ClassesTagsRelArrRelInsertInput = {
  data: Array<ClassesTagsRelInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ClassesTagsRelOnConflict>;
};

/** Boolean expression to filter rows from the table "classes_tags_rel". All fields are combined with a logical 'AND'. */
export type ClassesTagsRelBoolExp = {
  _and?: InputMaybe<Array<ClassesTagsRelBoolExp>>;
  _not?: InputMaybe<ClassesTagsRelBoolExp>;
  _or?: InputMaybe<Array<ClassesTagsRelBoolExp>>;
  class?: InputMaybe<ClassesBoolExp>;
  classId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  tag?: InputMaybe<TagsBoolExp>;
  tagId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "classes_tags_rel" */
export enum ClassesTagsRelConstraint {
  /** unique or primary key constraint on columns "tag_id", "class_id" */
  classes_tags_rel_pk = 'classes_tags_rel_pk'
}

/** input type for inserting data into table "classes_tags_rel" */
export type ClassesTagsRelInsertInput = {
  class?: InputMaybe<ClassesObjRelInsertInput>;
  classId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  tag?: InputMaybe<TagsObjRelInsertInput>;
  tagId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ClassesTagsRelMaxFields = {
  __typename?: 'ClassesTagsRelMaxFields';
  classId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  tagId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "classes_tags_rel" */
export type ClassesTagsRelMaxOrderBy = {
  classId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  tagId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ClassesTagsRelMinFields = {
  __typename?: 'ClassesTagsRelMinFields';
  classId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  tagId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "classes_tags_rel" */
export type ClassesTagsRelMinOrderBy = {
  classId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  tagId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "classes_tags_rel" */
export type ClassesTagsRelMutationResponse = {
  __typename?: 'ClassesTagsRelMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClassesTagsRel>;
};

/** on_conflict condition type for table "classes_tags_rel" */
export type ClassesTagsRelOnConflict = {
  constraint: ClassesTagsRelConstraint;
  updateColumns?: Array<ClassesTagsRelUpdateColumn>;
  where?: InputMaybe<ClassesTagsRelBoolExp>;
};

/** Ordering options when selecting data from "classes_tags_rel". */
export type ClassesTagsRelOrderBy = {
  class?: InputMaybe<ClassesOrderBy>;
  classId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  tag?: InputMaybe<TagsOrderBy>;
  tagId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: classes_tags_rel */
export type ClassesTagsRelPkColumnsInput = {
  classId: Scalars['uuid'];
  tagId: Scalars['uuid'];
};

/** select columns of table "classes_tags_rel" */
export enum ClassesTagsRelSelectColumn {
  /** column name */
  classId = 'classId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  tagId = 'tagId'
}

/** input type for updating data in table "classes_tags_rel" */
export type ClassesTagsRelSetInput = {
  classId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  tagId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "classes_tags_rel" */
export type ClassesTagsRelStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ClassesTagsRelStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ClassesTagsRelStreamCursorValueInput = {
  classId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  tagId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "classes_tags_rel" */
export enum ClassesTagsRelUpdateColumn {
  /** column name */
  classId = 'classId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  tagId = 'tagId'
}

export type ClassesTagsRelUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClassesTagsRelSetInput>;
  /** filter the rows which have to be updated */
  where: ClassesTagsRelBoolExp;
};

/** update columns of table "classes" */
export enum ClassesUpdateColumn {
  /** column name */
  courseId = 'courseId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  descriptionRich = 'descriptionRich',
  /** column name */
  durationInMinutes = 'durationInMinutes',
  /** column name */
  id = 'id',
  /** column name */
  isLive = 'isLive',
  /** column name */
  listDescription = 'listDescription',
  /** column name */
  name = 'name',
  /** column name */
  number = 'number',
  /** column name */
  slug = 'slug',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById',
  /** column name */
  version = 'version'
}

export type ClassesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ClassesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClassesSetInput>;
  /** filter the rows which have to be updated */
  where: ClassesBoolExp;
};

/** aggregate varPop on columns */
export type ClassesVarPopFields = {
  __typename?: 'ClassesVarPopFields';
  durationInMinutes?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "classes" */
export type ClassesVarPopOrderBy = {
  durationInMinutes?: InputMaybe<OrderBy>;
  number?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ClassesVarSampFields = {
  __typename?: 'ClassesVarSampFields';
  durationInMinutes?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "classes" */
export type ClassesVarSampOrderBy = {
  durationInMinutes?: InputMaybe<OrderBy>;
  number?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ClassesVarianceFields = {
  __typename?: 'ClassesVarianceFields';
  durationInMinutes?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "classes" */
export type ClassesVarianceOrderBy = {
  durationInMinutes?: InputMaybe<OrderBy>;
  number?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** columns and relationships of "coaches" */
export type Coaches = {
  __typename?: 'Coaches';
  avatarUrl?: Maybe<Scalars['String']>;
  /** An array relationship */
  classes: Array<ClassCoaches>;
  /** An aggregate relationship */
  classesAggregate: ClassCoachesAggregate;
  /** An array relationship */
  cohorts: Array<CohortCoaches>;
  /** An aggregate relationship */
  cohortsAggregate: CohortCoachesAggregate;
  company?: Maybe<Scalars['String']>;
  /** An array relationship */
  courses: Array<CourseCoaches>;
  /** An aggregate relationship */
  coursesAggregate: CourseCoachesAggregate;
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  deletedBy?: Maybe<Users>;
  deletedById?: Maybe<Scalars['uuid']>;
  headshotUrl?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  introVideoUrl?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  longBioRich?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  shortBio?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  updatedBy?: Maybe<Users>;
  updatedById?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "coaches" */
export type CoachesClassesArgs = {
  distinctOn?: InputMaybe<Array<ClassCoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassCoachesOrderBy>>;
  where?: InputMaybe<ClassCoachesBoolExp>;
};

/** columns and relationships of "coaches" */
export type CoachesClassesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassCoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassCoachesOrderBy>>;
  where?: InputMaybe<ClassCoachesBoolExp>;
};

/** columns and relationships of "coaches" */
export type CoachesCohortsArgs = {
  distinctOn?: InputMaybe<Array<CohortCoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortCoachesOrderBy>>;
  where?: InputMaybe<CohortCoachesBoolExp>;
};

/** columns and relationships of "coaches" */
export type CoachesCohortsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CohortCoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortCoachesOrderBy>>;
  where?: InputMaybe<CohortCoachesBoolExp>;
};

/** columns and relationships of "coaches" */
export type CoachesCoursesArgs = {
  distinctOn?: InputMaybe<Array<CourseCoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourseCoachesOrderBy>>;
  where?: InputMaybe<CourseCoachesBoolExp>;
};

/** columns and relationships of "coaches" */
export type CoachesCoursesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CourseCoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourseCoachesOrderBy>>;
  where?: InputMaybe<CourseCoachesBoolExp>;
};

/** aggregated selection of "coaches" */
export type CoachesAggregate = {
  __typename?: 'CoachesAggregate';
  aggregate?: Maybe<CoachesAggregateFields>;
  nodes: Array<Coaches>;
};

export type CoachesAggregateBoolExp = {
  count?: InputMaybe<CoachesAggregateBoolExpCount>;
};

/** aggregate fields of "coaches" */
export type CoachesAggregateFields = {
  __typename?: 'CoachesAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<CoachesMaxFields>;
  min?: Maybe<CoachesMinFields>;
};

/** aggregate fields of "coaches" */
export type CoachesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CoachesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "coaches" */
export type CoachesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CoachesMaxOrderBy>;
  min?: InputMaybe<CoachesMinOrderBy>;
};

/** input type for inserting array relation for remote table "coaches" */
export type CoachesArrRelInsertInput = {
  data: Array<CoachesInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<CoachesOnConflict>;
};

/** Boolean expression to filter rows from the table "coaches". All fields are combined with a logical 'AND'. */
export type CoachesBoolExp = {
  _and?: InputMaybe<Array<CoachesBoolExp>>;
  _not?: InputMaybe<CoachesBoolExp>;
  _or?: InputMaybe<Array<CoachesBoolExp>>;
  avatarUrl?: InputMaybe<StringComparisonExp>;
  classes?: InputMaybe<ClassCoachesBoolExp>;
  classesAggregate?: InputMaybe<ClassCoachesAggregateBoolExp>;
  cohorts?: InputMaybe<CohortCoachesBoolExp>;
  cohortsAggregate?: InputMaybe<CohortCoachesAggregateBoolExp>;
  company?: InputMaybe<StringComparisonExp>;
  courses?: InputMaybe<CourseCoachesBoolExp>;
  coursesAggregate?: InputMaybe<CourseCoachesAggregateBoolExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  deletedAt?: InputMaybe<TimestampComparisonExp>;
  deletedBy?: InputMaybe<UsersBoolExp>;
  deletedById?: InputMaybe<UuidComparisonExp>;
  headshotUrl?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  introVideoUrl?: InputMaybe<StringComparisonExp>;
  linkedinUrl?: InputMaybe<StringComparisonExp>;
  longBioRich?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  shortBio?: InputMaybe<StringComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  twitterUrl?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  updatedBy?: InputMaybe<UsersBoolExp>;
  updatedById?: InputMaybe<UuidComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "coaches" */
export enum CoachesConstraint {
  /** unique or primary key constraint on columns "id" */
  coaches_pk = 'coaches_pk',
  /** unique or primary key constraint on columns "slug", "deleted_at" */
  coaches_uniq_slug = 'coaches_uniq_slug'
}

/** input type for inserting data into table "coaches" */
export type CoachesInsertInput = {
  avatarUrl?: InputMaybe<Scalars['String']>;
  classes?: InputMaybe<ClassCoachesArrRelInsertInput>;
  cohorts?: InputMaybe<CohortCoachesArrRelInsertInput>;
  company?: InputMaybe<Scalars['String']>;
  courses?: InputMaybe<CourseCoachesArrRelInsertInput>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedBy?: InputMaybe<UsersObjRelInsertInput>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  headshotUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  introVideoUrl?: InputMaybe<Scalars['String']>;
  linkedinUrl?: InputMaybe<Scalars['String']>;
  longBioRich?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  shortBio?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  twitterUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<UsersObjRelInsertInput>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CoachesMaxFields = {
  __typename?: 'CoachesMaxFields';
  avatarUrl?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  headshotUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  introVideoUrl?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  longBioRich?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  shortBio?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "coaches" */
export type CoachesMaxOrderBy = {
  avatarUrl?: InputMaybe<OrderBy>;
  company?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  headshotUrl?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  introVideoUrl?: InputMaybe<OrderBy>;
  linkedinUrl?: InputMaybe<OrderBy>;
  longBioRich?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  shortBio?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  twitterUrl?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CoachesMinFields = {
  __typename?: 'CoachesMinFields';
  avatarUrl?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  headshotUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  introVideoUrl?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  longBioRich?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  shortBio?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "coaches" */
export type CoachesMinOrderBy = {
  avatarUrl?: InputMaybe<OrderBy>;
  company?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  headshotUrl?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  introVideoUrl?: InputMaybe<OrderBy>;
  linkedinUrl?: InputMaybe<OrderBy>;
  longBioRich?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  shortBio?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  twitterUrl?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "coaches" */
export type CoachesMutationResponse = {
  __typename?: 'CoachesMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Coaches>;
};

/** input type for inserting object relation for remote table "coaches" */
export type CoachesObjRelInsertInput = {
  data: CoachesInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CoachesOnConflict>;
};

/** on_conflict condition type for table "coaches" */
export type CoachesOnConflict = {
  constraint: CoachesConstraint;
  updateColumns?: Array<CoachesUpdateColumn>;
  where?: InputMaybe<CoachesBoolExp>;
};

/** Ordering options when selecting data from "coaches". */
export type CoachesOrderBy = {
  avatarUrl?: InputMaybe<OrderBy>;
  classesAggregate?: InputMaybe<ClassCoachesAggregateOrderBy>;
  cohortsAggregate?: InputMaybe<CohortCoachesAggregateOrderBy>;
  company?: InputMaybe<OrderBy>;
  coursesAggregate?: InputMaybe<CourseCoachesAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedBy?: InputMaybe<UsersOrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  headshotUrl?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  introVideoUrl?: InputMaybe<OrderBy>;
  linkedinUrl?: InputMaybe<OrderBy>;
  longBioRich?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  shortBio?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  twitterUrl?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<UsersOrderBy>;
  updatedById?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: coaches */
export type CoachesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "coaches" */
export enum CoachesSelectColumn {
  /** column name */
  avatarUrl = 'avatarUrl',
  /** column name */
  company = 'company',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  headshotUrl = 'headshotUrl',
  /** column name */
  id = 'id',
  /** column name */
  introVideoUrl = 'introVideoUrl',
  /** column name */
  linkedinUrl = 'linkedinUrl',
  /** column name */
  longBioRich = 'longBioRich',
  /** column name */
  name = 'name',
  /** column name */
  shortBio = 'shortBio',
  /** column name */
  slug = 'slug',
  /** column name */
  title = 'title',
  /** column name */
  twitterUrl = 'twitterUrl',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById',
  /** column name */
  userId = 'userId'
}

/** input type for updating data in table "coaches" */
export type CoachesSetInput = {
  avatarUrl?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  headshotUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  introVideoUrl?: InputMaybe<Scalars['String']>;
  linkedinUrl?: InputMaybe<Scalars['String']>;
  longBioRich?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  shortBio?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  twitterUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "coaches" */
export type CoachesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CoachesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CoachesStreamCursorValueInput = {
  avatarUrl?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  headshotUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  introVideoUrl?: InputMaybe<Scalars['String']>;
  linkedinUrl?: InputMaybe<Scalars['String']>;
  longBioRich?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  shortBio?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  twitterUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "coaches" */
export enum CoachesUpdateColumn {
  /** column name */
  avatarUrl = 'avatarUrl',
  /** column name */
  company = 'company',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  headshotUrl = 'headshotUrl',
  /** column name */
  id = 'id',
  /** column name */
  introVideoUrl = 'introVideoUrl',
  /** column name */
  linkedinUrl = 'linkedinUrl',
  /** column name */
  longBioRich = 'longBioRich',
  /** column name */
  name = 'name',
  /** column name */
  shortBio = 'shortBio',
  /** column name */
  slug = 'slug',
  /** column name */
  title = 'title',
  /** column name */
  twitterUrl = 'twitterUrl',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById',
  /** column name */
  userId = 'userId'
}

export type CoachesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CoachesSetInput>;
  /** filter the rows which have to be updated */
  where: CoachesBoolExp;
};

/** columns and relationships of "cohorts_coaches_rel" */
export type CohortCoaches = {
  __typename?: 'CohortCoaches';
  /** An object relationship */
  coach: Coaches;
  coachId: Scalars['uuid'];
  /** An object relationship */
  cohort: Cohorts;
  cohortId: Scalars['uuid'];
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  deletedBy?: Maybe<Users>;
  deletedById?: Maybe<Scalars['uuid']>;
  isLeadCoach?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  updatedBy?: Maybe<Users>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "cohorts_coaches_rel" */
export type CohortCoachesAggregate = {
  __typename?: 'CohortCoachesAggregate';
  aggregate?: Maybe<CohortCoachesAggregateFields>;
  nodes: Array<CohortCoaches>;
};

export type CohortCoachesAggregateBoolExp = {
  bool_and?: InputMaybe<CohortCoachesAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<CohortCoachesAggregateBoolExpBool_Or>;
  count?: InputMaybe<CohortCoachesAggregateBoolExpCount>;
};

export type CohortCoachesAggregateBoolExpBool_And = {
  arguments: CohortCoachesSelectColumnCohortCoachesAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CohortCoachesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CohortCoachesAggregateBoolExpBool_Or = {
  arguments: CohortCoachesSelectColumnCohortCoachesAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CohortCoachesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CohortCoachesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CohortCoachesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CohortCoachesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "cohorts_coaches_rel" */
export type CohortCoachesAggregateFields = {
  __typename?: 'CohortCoachesAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<CohortCoachesMaxFields>;
  min?: Maybe<CohortCoachesMinFields>;
};

/** aggregate fields of "cohorts_coaches_rel" */
export type CohortCoachesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CohortCoachesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cohorts_coaches_rel" */
export type CohortCoachesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CohortCoachesMaxOrderBy>;
  min?: InputMaybe<CohortCoachesMinOrderBy>;
};

/** input type for inserting array relation for remote table "cohorts_coaches_rel" */
export type CohortCoachesArrRelInsertInput = {
  data: Array<CohortCoachesInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<CohortCoachesOnConflict>;
};

/** Boolean expression to filter rows from the table "cohorts_coaches_rel". All fields are combined with a logical 'AND'. */
export type CohortCoachesBoolExp = {
  _and?: InputMaybe<Array<CohortCoachesBoolExp>>;
  _not?: InputMaybe<CohortCoachesBoolExp>;
  _or?: InputMaybe<Array<CohortCoachesBoolExp>>;
  coach?: InputMaybe<CoachesBoolExp>;
  coachId?: InputMaybe<UuidComparisonExp>;
  cohort?: InputMaybe<CohortsBoolExp>;
  cohortId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  deletedAt?: InputMaybe<TimestampComparisonExp>;
  deletedBy?: InputMaybe<UsersBoolExp>;
  deletedById?: InputMaybe<UuidComparisonExp>;
  isLeadCoach?: InputMaybe<BooleanComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  updatedBy?: InputMaybe<UsersBoolExp>;
  updatedById?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "cohorts_coaches_rel" */
export enum CohortCoachesConstraint {
  /** unique or primary key constraint on columns "coach_id", "cohort_id" */
  cohorts_coaches_rel_pk = 'cohorts_coaches_rel_pk'
}

/** input type for inserting data into table "cohorts_coaches_rel" */
export type CohortCoachesInsertInput = {
  coach?: InputMaybe<CoachesObjRelInsertInput>;
  coachId?: InputMaybe<Scalars['uuid']>;
  cohort?: InputMaybe<CohortsObjRelInsertInput>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedBy?: InputMaybe<UsersObjRelInsertInput>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  isLeadCoach?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<UsersObjRelInsertInput>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CohortCoachesMaxFields = {
  __typename?: 'CohortCoachesMaxFields';
  coachId?: Maybe<Scalars['uuid']>;
  cohortId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "cohorts_coaches_rel" */
export type CohortCoachesMaxOrderBy = {
  coachId?: InputMaybe<OrderBy>;
  cohortId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CohortCoachesMinFields = {
  __typename?: 'CohortCoachesMinFields';
  coachId?: Maybe<Scalars['uuid']>;
  cohortId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "cohorts_coaches_rel" */
export type CohortCoachesMinOrderBy = {
  coachId?: InputMaybe<OrderBy>;
  cohortId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "cohorts_coaches_rel" */
export type CohortCoachesMutationResponse = {
  __typename?: 'CohortCoachesMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CohortCoaches>;
};

/** on_conflict condition type for table "cohorts_coaches_rel" */
export type CohortCoachesOnConflict = {
  constraint: CohortCoachesConstraint;
  updateColumns?: Array<CohortCoachesUpdateColumn>;
  where?: InputMaybe<CohortCoachesBoolExp>;
};

/** Ordering options when selecting data from "cohorts_coaches_rel". */
export type CohortCoachesOrderBy = {
  coach?: InputMaybe<CoachesOrderBy>;
  coachId?: InputMaybe<OrderBy>;
  cohort?: InputMaybe<CohortsOrderBy>;
  cohortId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedBy?: InputMaybe<UsersOrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  isLeadCoach?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<UsersOrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: cohorts_coaches_rel */
export type CohortCoachesPkColumnsInput = {
  coachId: Scalars['uuid'];
  cohortId: Scalars['uuid'];
};

/** select columns of table "cohorts_coaches_rel" */
export enum CohortCoachesSelectColumn {
  /** column name */
  coachId = 'coachId',
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  isLeadCoach = 'isLeadCoach',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById'
}

/** select "CohortCoachesAggregateBoolExpBool_andArgumentsColumns" columns of table "cohorts_coaches_rel" */
export enum CohortCoachesSelectColumnCohortCoachesAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  isLeadCoach = 'isLeadCoach'
}

/** select "CohortCoachesAggregateBoolExpBool_orArgumentsColumns" columns of table "cohorts_coaches_rel" */
export enum CohortCoachesSelectColumnCohortCoachesAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  isLeadCoach = 'isLeadCoach'
}

/** input type for updating data in table "cohorts_coaches_rel" */
export type CohortCoachesSetInput = {
  coachId?: InputMaybe<Scalars['uuid']>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  isLeadCoach?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "CohortCoaches" */
export type CohortCoachesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CohortCoachesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CohortCoachesStreamCursorValueInput = {
  coachId?: InputMaybe<Scalars['uuid']>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  isLeadCoach?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "cohorts_coaches_rel" */
export enum CohortCoachesUpdateColumn {
  /** column name */
  coachId = 'coachId',
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  isLeadCoach = 'isLeadCoach',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById'
}

export type CohortCoachesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CohortCoachesSetInput>;
  /** filter the rows which have to be updated */
  where: CohortCoachesBoolExp;
};

/** columns and relationships of "cohort_progress_view" */
export type CohortProgress = {
  __typename?: 'CohortProgress';
  /** An object relationship */
  cohort?: Maybe<Cohorts>;
  id?: Maybe<Scalars['uuid']>;
  numClasses?: Maybe<Scalars['bigint']>;
  numClassesCompleted?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "cohort_progress_view" */
export type CohortProgressAggregate = {
  __typename?: 'CohortProgressAggregate';
  aggregate?: Maybe<CohortProgressAggregateFields>;
  nodes: Array<CohortProgress>;
};

/** aggregate fields of "cohort_progress_view" */
export type CohortProgressAggregateFields = {
  __typename?: 'CohortProgressAggregateFields';
  avg?: Maybe<CohortProgressAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CohortProgressMaxFields>;
  min?: Maybe<CohortProgressMinFields>;
  stddev?: Maybe<CohortProgressStddevFields>;
  stddevPop?: Maybe<CohortProgressStddevPopFields>;
  stddevSamp?: Maybe<CohortProgressStddevSampFields>;
  sum?: Maybe<CohortProgressSumFields>;
  varPop?: Maybe<CohortProgressVarPopFields>;
  varSamp?: Maybe<CohortProgressVarSampFields>;
  variance?: Maybe<CohortProgressVarianceFields>;
};

/** aggregate fields of "cohort_progress_view" */
export type CohortProgressAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CohortProgressSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CohortProgressAvgFields = {
  __typename?: 'CohortProgressAvgFields';
  numClasses?: Maybe<Scalars['Float']>;
  numClassesCompleted?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "cohort_progress_view". All fields are combined with a logical 'AND'. */
export type CohortProgressBoolExp = {
  _and?: InputMaybe<Array<CohortProgressBoolExp>>;
  _not?: InputMaybe<CohortProgressBoolExp>;
  _or?: InputMaybe<Array<CohortProgressBoolExp>>;
  cohort?: InputMaybe<CohortsBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  numClasses?: InputMaybe<BigintComparisonExp>;
  numClassesCompleted?: InputMaybe<BigintComparisonExp>;
};

/** aggregate max on columns */
export type CohortProgressMaxFields = {
  __typename?: 'CohortProgressMaxFields';
  id?: Maybe<Scalars['uuid']>;
  numClasses?: Maybe<Scalars['bigint']>;
  numClassesCompleted?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type CohortProgressMinFields = {
  __typename?: 'CohortProgressMinFields';
  id?: Maybe<Scalars['uuid']>;
  numClasses?: Maybe<Scalars['bigint']>;
  numClassesCompleted?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "cohort_progress_view". */
export type CohortProgressOrderBy = {
  cohort?: InputMaybe<CohortsOrderBy>;
  id?: InputMaybe<OrderBy>;
  numClasses?: InputMaybe<OrderBy>;
  numClassesCompleted?: InputMaybe<OrderBy>;
};

/** select columns of table "cohort_progress_view" */
export enum CohortProgressSelectColumn {
  /** column name */
  id = 'id',
  /** column name */
  numClasses = 'numClasses',
  /** column name */
  numClassesCompleted = 'numClassesCompleted'
}

/** aggregate stddev on columns */
export type CohortProgressStddevFields = {
  __typename?: 'CohortProgressStddevFields';
  numClasses?: Maybe<Scalars['Float']>;
  numClassesCompleted?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type CohortProgressStddevPopFields = {
  __typename?: 'CohortProgressStddevPopFields';
  numClasses?: Maybe<Scalars['Float']>;
  numClassesCompleted?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type CohortProgressStddevSampFields = {
  __typename?: 'CohortProgressStddevSampFields';
  numClasses?: Maybe<Scalars['Float']>;
  numClassesCompleted?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "CohortProgress" */
export type CohortProgressStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CohortProgressStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CohortProgressStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  numClasses?: InputMaybe<Scalars['bigint']>;
  numClassesCompleted?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type CohortProgressSumFields = {
  __typename?: 'CohortProgressSumFields';
  numClasses?: Maybe<Scalars['bigint']>;
  numClassesCompleted?: Maybe<Scalars['bigint']>;
};

/** aggregate varPop on columns */
export type CohortProgressVarPopFields = {
  __typename?: 'CohortProgressVarPopFields';
  numClasses?: Maybe<Scalars['Float']>;
  numClassesCompleted?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type CohortProgressVarSampFields = {
  __typename?: 'CohortProgressVarSampFields';
  numClasses?: Maybe<Scalars['Float']>;
  numClassesCompleted?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CohortProgressVarianceFields = {
  __typename?: 'CohortProgressVarianceFields';
  numClasses?: Maybe<Scalars['Float']>;
  numClassesCompleted?: Maybe<Scalars['Float']>;
};

/** Types of supported cohorts */
export type CohortType = {
  __typename?: 'CohortType';
  value: Scalars['String'];
};

/** aggregated selection of "cohort_type_enum" */
export type CohortTypeAggregate = {
  __typename?: 'CohortTypeAggregate';
  aggregate?: Maybe<CohortTypeAggregateFields>;
  nodes: Array<CohortType>;
};

/** aggregate fields of "cohort_type_enum" */
export type CohortTypeAggregateFields = {
  __typename?: 'CohortTypeAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<CohortTypeMaxFields>;
  min?: Maybe<CohortTypeMinFields>;
};

/** aggregate fields of "cohort_type_enum" */
export type CohortTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CohortTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "cohort_type_enum". All fields are combined with a logical 'AND'. */
export type CohortTypeBoolExp = {
  _and?: InputMaybe<Array<CohortTypeBoolExp>>;
  _not?: InputMaybe<CohortTypeBoolExp>;
  _or?: InputMaybe<Array<CohortTypeBoolExp>>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "cohort_type_enum" */
export enum CohortTypeConstraint {
  /** unique or primary key constraint on columns "value" */
  cohort_type_enum_pkey = 'cohort_type_enum_pkey'
}

export enum CohortTypeEnum {
  LIVE = 'LIVE',
  RECORDED = 'RECORDED'
}

/** Boolean expression to compare columns of type "CohortTypeEnum". All fields are combined with logical 'AND'. */
export type CohortTypeEnumComparisonExp = {
  _eq?: InputMaybe<CohortTypeEnum>;
  _in?: InputMaybe<Array<CohortTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<CohortTypeEnum>;
  _nin?: InputMaybe<Array<CohortTypeEnum>>;
};

/** input type for inserting data into table "cohort_type_enum" */
export type CohortTypeInsertInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CohortTypeMaxFields = {
  __typename?: 'CohortTypeMaxFields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type CohortTypeMinFields = {
  __typename?: 'CohortTypeMinFields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "cohort_type_enum" */
export type CohortTypeMutationResponse = {
  __typename?: 'CohortTypeMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CohortType>;
};

/** on_conflict condition type for table "cohort_type_enum" */
export type CohortTypeOnConflict = {
  constraint: CohortTypeConstraint;
  updateColumns?: Array<CohortTypeUpdateColumn>;
  where?: InputMaybe<CohortTypeBoolExp>;
};

/** Ordering options when selecting data from "cohort_type_enum". */
export type CohortTypeOrderBy = {
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: cohort_type_enum */
export type CohortTypePkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "cohort_type_enum" */
export enum CohortTypeSelectColumn {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "cohort_type_enum" */
export type CohortTypeSetInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "CohortType" */
export type CohortTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CohortTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CohortTypeStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "cohort_type_enum" */
export enum CohortTypeUpdateColumn {
  /** column name */
  value = 'value'
}

export type CohortTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CohortTypeSetInput>;
  /** filter the rows which have to be updated */
  where: CohortTypeBoolExp;
};

/** columns and relationships of "cohort_visibility_enum" */
export type CohortVisibilityEnum = {
  __typename?: 'CohortVisibilityEnum';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "cohort_visibility_enum" */
export type CohortVisibilityEnumAggregate = {
  __typename?: 'CohortVisibilityEnumAggregate';
  aggregate?: Maybe<CohortVisibilityEnumAggregateFields>;
  nodes: Array<CohortVisibilityEnum>;
};

/** aggregate fields of "cohort_visibility_enum" */
export type CohortVisibilityEnumAggregateFields = {
  __typename?: 'CohortVisibilityEnumAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<CohortVisibilityEnumMaxFields>;
  min?: Maybe<CohortVisibilityEnumMinFields>;
};

/** aggregate fields of "cohort_visibility_enum" */
export type CohortVisibilityEnumAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CohortVisibilityEnumSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "cohort_visibility_enum". All fields are combined with a logical 'AND'. */
export type CohortVisibilityEnumBoolExp = {
  _and?: InputMaybe<Array<CohortVisibilityEnumBoolExp>>;
  _not?: InputMaybe<CohortVisibilityEnumBoolExp>;
  _or?: InputMaybe<Array<CohortVisibilityEnumBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "cohort_visibility_enum" */
export enum CohortVisibilityEnumConstraint {
  /** unique or primary key constraint on columns "value" */
  cohort_visibility_enum_pkey = 'cohort_visibility_enum_pkey'
}

export enum CohortVisibilityEnumEnum {
  /** invisible cohorts */
  NOT_VISIBLE = 'NOT_VISIBLE',
  /** visible cohorts */
  VISIBLE = 'VISIBLE'
}

/** Boolean expression to compare columns of type "CohortVisibilityEnumEnum". All fields are combined with logical 'AND'. */
export type CohortVisibilityEnumEnumComparisonExp = {
  _eq?: InputMaybe<CohortVisibilityEnumEnum>;
  _in?: InputMaybe<Array<CohortVisibilityEnumEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<CohortVisibilityEnumEnum>;
  _nin?: InputMaybe<Array<CohortVisibilityEnumEnum>>;
};

/** input type for inserting data into table "cohort_visibility_enum" */
export type CohortVisibilityEnumInsertInput = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CohortVisibilityEnumMaxFields = {
  __typename?: 'CohortVisibilityEnumMaxFields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type CohortVisibilityEnumMinFields = {
  __typename?: 'CohortVisibilityEnumMinFields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "cohort_visibility_enum" */
export type CohortVisibilityEnumMutationResponse = {
  __typename?: 'CohortVisibilityEnumMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CohortVisibilityEnum>;
};

/** on_conflict condition type for table "cohort_visibility_enum" */
export type CohortVisibilityEnumOnConflict = {
  constraint: CohortVisibilityEnumConstraint;
  updateColumns?: Array<CohortVisibilityEnumUpdateColumn>;
  where?: InputMaybe<CohortVisibilityEnumBoolExp>;
};

/** Ordering options when selecting data from "cohort_visibility_enum". */
export type CohortVisibilityEnumOrderBy = {
  description?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: cohort_visibility_enum */
export type CohortVisibilityEnumPkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "cohort_visibility_enum" */
export enum CohortVisibilityEnumSelectColumn {
  /** column name */
  description = 'description',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "cohort_visibility_enum" */
export type CohortVisibilityEnumSetInput = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "cohort_visibility_enum" */
export type CohortVisibilityEnumStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CohortVisibilityEnumStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CohortVisibilityEnumStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "cohort_visibility_enum" */
export enum CohortVisibilityEnumUpdateColumn {
  /** column name */
  description = 'description',
  /** column name */
  value = 'value'
}

export type CohortVisibilityEnumUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CohortVisibilityEnumSetInput>;
  /** filter the rows which have to be updated */
  where: CohortVisibilityEnumBoolExp;
};

/** columns and relationships of "cohorts" */
export type Cohorts = {
  __typename?: 'Cohorts';
  /** A computed field, executes function "assessment_due_date" */
  assessmentDueDate?: Maybe<Scalars['timestamp']>;
  /** A computed field, executes function "assessment_live_date" */
  assessmentLiveDate?: Maybe<Scalars['timestamp']>;
  /** An array relationship */
  classEvents: Array<ClassEvents>;
  /** An aggregate relationship */
  classEventsAggregate: ClassEventsAggregate;
  classTimeOfDay?: Maybe<Scalars['timestamp']>;
  /** An array relationship */
  coaches: Array<CohortCoaches>;
  /** An aggregate relationship */
  coachesAggregate: CohortCoachesAggregate;
  code: Scalars['String'];
  communitySlackChannelUrl?: Maybe<Scalars['String']>;
  communitySlackInviteUrl?: Maybe<Scalars['String']>;
  /** An object relationship */
  course?: Maybe<Courses>;
  courseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  dayOfTheWeek?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['timestamp']>;
  /** An array relationship */
  enrollmentWaitlist: Array<EnrollmentWaitlists>;
  /** An aggregate relationship */
  enrollmentWaitlistAggregate: EnrollmentWaitlistsAggregate;
  /** An array relationship */
  enrollments: Array<Enrollments>;
  /** An aggregate relationship */
  enrollmentsAggregate: EnrollmentsAggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  impactAssessmentAttempts: Array<ImpactAssessmentAttempts>;
  /** An aggregate relationship */
  impactAssessmentAttemptsAggregate: ImpactAssessmentAttemptsAggregate;
  inAppVideo?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "assessment_islive" */
  isAssessmentLive?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "post_course_survey_available" */
  isPostCourseSurveyAvailable?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "is_user_enrolled" */
  isUserEnrolled?: Maybe<Scalars['Boolean']>;
  maxEnrollment?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  preworkRichText?: Maybe<Scalars['String']>;
  preworkVideoUrl?: Maybe<Scalars['String']>;
  promoVideoUrl?: Maybe<Scalars['String']>;
  requiresPractice: Scalars['Boolean'];
  /** An array relationship */
  resources: Array<CohortsResourcesRel>;
  /** An aggregate relationship */
  resourcesAggregate: CohortsResourcesRelAggregate;
  slug: Scalars['String'];
  startDate?: Maybe<Scalars['timestamp']>;
  /** An array relationship */
  submissions: Array<LightningSessionSubmissions>;
  /** An aggregate relationship */
  submissionsAggregate: LightningSessionSubmissionsAggregate;
  surveyLinkUrl?: Maybe<Scalars['String']>;
  /** A computed field, executes function "total_enrollments" */
  totalEnrollments?: Maybe<Scalars['bigint']>;
  type: CohortTypeEnum;
  updatedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  updatedBy?: Maybe<Users>;
  updatedById?: Maybe<Scalars['uuid']>;
  visibility: CohortVisibilityEnumEnum;
};

/** columns and relationships of "cohorts" */
export type CohortsClassEventsArgs = {
  distinctOn?: InputMaybe<Array<ClassEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventsOrderBy>>;
  where?: InputMaybe<ClassEventsBoolExp>;
};

/** columns and relationships of "cohorts" */
export type CohortsClassEventsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventsOrderBy>>;
  where?: InputMaybe<ClassEventsBoolExp>;
};

/** columns and relationships of "cohorts" */
export type CohortsCoachesArgs = {
  distinctOn?: InputMaybe<Array<CohortCoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortCoachesOrderBy>>;
  where?: InputMaybe<CohortCoachesBoolExp>;
};

/** columns and relationships of "cohorts" */
export type CohortsCoachesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CohortCoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortCoachesOrderBy>>;
  where?: InputMaybe<CohortCoachesBoolExp>;
};

/** columns and relationships of "cohorts" */
export type CohortsEnrollmentWaitlistArgs = {
  distinctOn?: InputMaybe<Array<EnrollmentWaitlistsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnrollmentWaitlistsOrderBy>>;
  where?: InputMaybe<EnrollmentWaitlistsBoolExp>;
};

/** columns and relationships of "cohorts" */
export type CohortsEnrollmentWaitlistAggregateArgs = {
  distinctOn?: InputMaybe<Array<EnrollmentWaitlistsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnrollmentWaitlistsOrderBy>>;
  where?: InputMaybe<EnrollmentWaitlistsBoolExp>;
};

/** columns and relationships of "cohorts" */
export type CohortsEnrollmentsArgs = {
  distinctOn?: InputMaybe<Array<EnrollmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnrollmentsOrderBy>>;
  where?: InputMaybe<EnrollmentsBoolExp>;
};

/** columns and relationships of "cohorts" */
export type CohortsEnrollmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<EnrollmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnrollmentsOrderBy>>;
  where?: InputMaybe<EnrollmentsBoolExp>;
};

/** columns and relationships of "cohorts" */
export type CohortsImpactAssessmentAttemptsArgs = {
  distinctOn?: InputMaybe<Array<ImpactAssessmentAttemptsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImpactAssessmentAttemptsOrderBy>>;
  where?: InputMaybe<ImpactAssessmentAttemptsBoolExp>;
};

/** columns and relationships of "cohorts" */
export type CohortsImpactAssessmentAttemptsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ImpactAssessmentAttemptsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImpactAssessmentAttemptsOrderBy>>;
  where?: InputMaybe<ImpactAssessmentAttemptsBoolExp>;
};

/** columns and relationships of "cohorts" */
export type CohortsResourcesArgs = {
  distinctOn?: InputMaybe<Array<CohortsResourcesRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortsResourcesRelOrderBy>>;
  where?: InputMaybe<CohortsResourcesRelBoolExp>;
};

/** columns and relationships of "cohorts" */
export type CohortsResourcesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CohortsResourcesRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortsResourcesRelOrderBy>>;
  where?: InputMaybe<CohortsResourcesRelBoolExp>;
};

/** columns and relationships of "cohorts" */
export type CohortsSubmissionsArgs = {
  distinctOn?: InputMaybe<Array<LightningSessionSubmissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LightningSessionSubmissionsOrderBy>>;
  where?: InputMaybe<LightningSessionSubmissionsBoolExp>;
};

/** columns and relationships of "cohorts" */
export type CohortsSubmissionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<LightningSessionSubmissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LightningSessionSubmissionsOrderBy>>;
  where?: InputMaybe<LightningSessionSubmissionsBoolExp>;
};

/** aggregated selection of "cohorts" */
export type CohortsAggregate = {
  __typename?: 'CohortsAggregate';
  aggregate?: Maybe<CohortsAggregateFields>;
  nodes: Array<Cohorts>;
};

export type CohortsAggregateBoolExp = {
  bool_and?: InputMaybe<CohortsAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<CohortsAggregateBoolExpBool_Or>;
  count?: InputMaybe<CohortsAggregateBoolExpCount>;
};

/** aggregate fields of "cohorts" */
export type CohortsAggregateFields = {
  __typename?: 'CohortsAggregateFields';
  avg?: Maybe<CohortsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CohortsMaxFields>;
  min?: Maybe<CohortsMinFields>;
  stddev?: Maybe<CohortsStddevFields>;
  stddevPop?: Maybe<CohortsStddevPopFields>;
  stddevSamp?: Maybe<CohortsStddevSampFields>;
  sum?: Maybe<CohortsSumFields>;
  varPop?: Maybe<CohortsVarPopFields>;
  varSamp?: Maybe<CohortsVarSampFields>;
  variance?: Maybe<CohortsVarianceFields>;
};

/** aggregate fields of "cohorts" */
export type CohortsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CohortsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cohorts" */
export type CohortsAggregateOrderBy = {
  avg?: InputMaybe<CohortsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CohortsMaxOrderBy>;
  min?: InputMaybe<CohortsMinOrderBy>;
  stddev?: InputMaybe<CohortsStddevOrderBy>;
  stddevPop?: InputMaybe<CohortsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<CohortsStddevSampOrderBy>;
  sum?: InputMaybe<CohortsSumOrderBy>;
  varPop?: InputMaybe<CohortsVarPopOrderBy>;
  varSamp?: InputMaybe<CohortsVarSampOrderBy>;
  variance?: InputMaybe<CohortsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "cohorts" */
export type CohortsArrRelInsertInput = {
  data: Array<CohortsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<CohortsOnConflict>;
};

/** aggregate avg on columns */
export type CohortsAvgFields = {
  __typename?: 'CohortsAvgFields';
  maxEnrollment?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "total_enrollments" */
  totalEnrollments?: Maybe<Scalars['bigint']>;
};

/** order by avg() on columns of table "cohorts" */
export type CohortsAvgOrderBy = {
  maxEnrollment?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "cohorts". All fields are combined with a logical 'AND'. */
export type CohortsBoolExp = {
  _and?: InputMaybe<Array<CohortsBoolExp>>;
  _not?: InputMaybe<CohortsBoolExp>;
  _or?: InputMaybe<Array<CohortsBoolExp>>;
  assessmentDueDate?: InputMaybe<TimestampComparisonExp>;
  assessmentLiveDate?: InputMaybe<TimestampComparisonExp>;
  classEvents?: InputMaybe<ClassEventsBoolExp>;
  classEventsAggregate?: InputMaybe<ClassEventsAggregateBoolExp>;
  classTimeOfDay?: InputMaybe<TimestampComparisonExp>;
  coaches?: InputMaybe<CohortCoachesBoolExp>;
  coachesAggregate?: InputMaybe<CohortCoachesAggregateBoolExp>;
  code?: InputMaybe<StringComparisonExp>;
  communitySlackChannelUrl?: InputMaybe<StringComparisonExp>;
  communitySlackInviteUrl?: InputMaybe<StringComparisonExp>;
  course?: InputMaybe<CoursesBoolExp>;
  courseId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  dayOfTheWeek?: InputMaybe<StringComparisonExp>;
  endDate?: InputMaybe<TimestampComparisonExp>;
  enrollmentWaitlist?: InputMaybe<EnrollmentWaitlistsBoolExp>;
  enrollmentWaitlistAggregate?: InputMaybe<EnrollmentWaitlistsAggregateBoolExp>;
  enrollments?: InputMaybe<EnrollmentsBoolExp>;
  enrollmentsAggregate?: InputMaybe<EnrollmentsAggregateBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  impactAssessmentAttempts?: InputMaybe<ImpactAssessmentAttemptsBoolExp>;
  impactAssessmentAttemptsAggregate?: InputMaybe<ImpactAssessmentAttemptsAggregateBoolExp>;
  inAppVideo?: InputMaybe<BooleanComparisonExp>;
  isAssessmentLive?: InputMaybe<BooleanComparisonExp>;
  isPostCourseSurveyAvailable?: InputMaybe<BooleanComparisonExp>;
  isUserEnrolled?: InputMaybe<BooleanComparisonExp>;
  maxEnrollment?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  preworkRichText?: InputMaybe<StringComparisonExp>;
  preworkVideoUrl?: InputMaybe<StringComparisonExp>;
  promoVideoUrl?: InputMaybe<StringComparisonExp>;
  requiresPractice?: InputMaybe<BooleanComparisonExp>;
  resources?: InputMaybe<CohortsResourcesRelBoolExp>;
  resourcesAggregate?: InputMaybe<CohortsResourcesRelAggregateBoolExp>;
  slug?: InputMaybe<StringComparisonExp>;
  startDate?: InputMaybe<TimestampComparisonExp>;
  submissions?: InputMaybe<LightningSessionSubmissionsBoolExp>;
  submissionsAggregate?: InputMaybe<LightningSessionSubmissionsAggregateBoolExp>;
  surveyLinkUrl?: InputMaybe<StringComparisonExp>;
  totalEnrollments?: InputMaybe<BigintComparisonExp>;
  type?: InputMaybe<CohortTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  updatedBy?: InputMaybe<UsersBoolExp>;
  updatedById?: InputMaybe<UuidComparisonExp>;
  visibility?: InputMaybe<CohortVisibilityEnumEnumComparisonExp>;
};

/** unique or primary key constraints on table "cohorts" */
export enum CohortsConstraint {
  /** unique or primary key constraint on columns "id" */
  cohorts_pk = 'cohorts_pk',
  /** unique or primary key constraint on columns "slug" */
  cohorts_slug_key = 'cohorts_slug_key'
}

/** input type for incrementing numeric columns in table "cohorts" */
export type CohortsIncInput = {
  maxEnrollment?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "cohorts" */
export type CohortsInsertInput = {
  classEvents?: InputMaybe<ClassEventsArrRelInsertInput>;
  classTimeOfDay?: InputMaybe<Scalars['timestamp']>;
  coaches?: InputMaybe<CohortCoachesArrRelInsertInput>;
  code?: InputMaybe<Scalars['String']>;
  communitySlackChannelUrl?: InputMaybe<Scalars['String']>;
  communitySlackInviteUrl?: InputMaybe<Scalars['String']>;
  course?: InputMaybe<CoursesObjRelInsertInput>;
  courseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  dayOfTheWeek?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['timestamp']>;
  enrollmentWaitlist?: InputMaybe<EnrollmentWaitlistsArrRelInsertInput>;
  enrollments?: InputMaybe<EnrollmentsArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']>;
  impactAssessmentAttempts?: InputMaybe<ImpactAssessmentAttemptsArrRelInsertInput>;
  inAppVideo?: InputMaybe<Scalars['Boolean']>;
  maxEnrollment?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  preworkRichText?: InputMaybe<Scalars['String']>;
  preworkVideoUrl?: InputMaybe<Scalars['String']>;
  promoVideoUrl?: InputMaybe<Scalars['String']>;
  requiresPractice?: InputMaybe<Scalars['Boolean']>;
  resources?: InputMaybe<CohortsResourcesRelArrRelInsertInput>;
  slug?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['timestamp']>;
  submissions?: InputMaybe<LightningSessionSubmissionsArrRelInsertInput>;
  surveyLinkUrl?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CohortTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<UsersObjRelInsertInput>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  visibility?: InputMaybe<CohortVisibilityEnumEnum>;
};

/** aggregate max on columns */
export type CohortsMaxFields = {
  __typename?: 'CohortsMaxFields';
  /** A computed field, executes function "assessment_due_date" */
  assessmentDueDate?: Maybe<Scalars['timestamp']>;
  /** A computed field, executes function "assessment_live_date" */
  assessmentLiveDate?: Maybe<Scalars['timestamp']>;
  classTimeOfDay?: Maybe<Scalars['timestamp']>;
  code?: Maybe<Scalars['String']>;
  communitySlackChannelUrl?: Maybe<Scalars['String']>;
  communitySlackInviteUrl?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  dayOfTheWeek?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  maxEnrollment?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  preworkRichText?: Maybe<Scalars['String']>;
  preworkVideoUrl?: Maybe<Scalars['String']>;
  promoVideoUrl?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['timestamp']>;
  surveyLinkUrl?: Maybe<Scalars['String']>;
  /** A computed field, executes function "total_enrollments" */
  totalEnrollments?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "cohorts" */
export type CohortsMaxOrderBy = {
  classTimeOfDay?: InputMaybe<OrderBy>;
  code?: InputMaybe<OrderBy>;
  communitySlackChannelUrl?: InputMaybe<OrderBy>;
  communitySlackInviteUrl?: InputMaybe<OrderBy>;
  courseId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  dayOfTheWeek?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxEnrollment?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  preworkRichText?: InputMaybe<OrderBy>;
  preworkVideoUrl?: InputMaybe<OrderBy>;
  promoVideoUrl?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  surveyLinkUrl?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CohortsMinFields = {
  __typename?: 'CohortsMinFields';
  /** A computed field, executes function "assessment_due_date" */
  assessmentDueDate?: Maybe<Scalars['timestamp']>;
  /** A computed field, executes function "assessment_live_date" */
  assessmentLiveDate?: Maybe<Scalars['timestamp']>;
  classTimeOfDay?: Maybe<Scalars['timestamp']>;
  code?: Maybe<Scalars['String']>;
  communitySlackChannelUrl?: Maybe<Scalars['String']>;
  communitySlackInviteUrl?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  dayOfTheWeek?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  maxEnrollment?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  preworkRichText?: Maybe<Scalars['String']>;
  preworkVideoUrl?: Maybe<Scalars['String']>;
  promoVideoUrl?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['timestamp']>;
  surveyLinkUrl?: Maybe<Scalars['String']>;
  /** A computed field, executes function "total_enrollments" */
  totalEnrollments?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "cohorts" */
export type CohortsMinOrderBy = {
  classTimeOfDay?: InputMaybe<OrderBy>;
  code?: InputMaybe<OrderBy>;
  communitySlackChannelUrl?: InputMaybe<OrderBy>;
  communitySlackInviteUrl?: InputMaybe<OrderBy>;
  courseId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  dayOfTheWeek?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxEnrollment?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  preworkRichText?: InputMaybe<OrderBy>;
  preworkVideoUrl?: InputMaybe<OrderBy>;
  promoVideoUrl?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  surveyLinkUrl?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "cohorts" */
export type CohortsMutationResponse = {
  __typename?: 'CohortsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Cohorts>;
};

/** input type for inserting object relation for remote table "cohorts" */
export type CohortsObjRelInsertInput = {
  data: CohortsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CohortsOnConflict>;
};

/** on_conflict condition type for table "cohorts" */
export type CohortsOnConflict = {
  constraint: CohortsConstraint;
  updateColumns?: Array<CohortsUpdateColumn>;
  where?: InputMaybe<CohortsBoolExp>;
};

/** Ordering options when selecting data from "cohorts". */
export type CohortsOrderBy = {
  assessmentDueDate?: InputMaybe<OrderBy>;
  assessmentLiveDate?: InputMaybe<OrderBy>;
  classEventsAggregate?: InputMaybe<ClassEventsAggregateOrderBy>;
  classTimeOfDay?: InputMaybe<OrderBy>;
  coachesAggregate?: InputMaybe<CohortCoachesAggregateOrderBy>;
  code?: InputMaybe<OrderBy>;
  communitySlackChannelUrl?: InputMaybe<OrderBy>;
  communitySlackInviteUrl?: InputMaybe<OrderBy>;
  course?: InputMaybe<CoursesOrderBy>;
  courseId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  dayOfTheWeek?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  enrollmentWaitlistAggregate?: InputMaybe<EnrollmentWaitlistsAggregateOrderBy>;
  enrollmentsAggregate?: InputMaybe<EnrollmentsAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  impactAssessmentAttemptsAggregate?: InputMaybe<ImpactAssessmentAttemptsAggregateOrderBy>;
  inAppVideo?: InputMaybe<OrderBy>;
  isAssessmentLive?: InputMaybe<OrderBy>;
  isPostCourseSurveyAvailable?: InputMaybe<OrderBy>;
  isUserEnrolled?: InputMaybe<OrderBy>;
  maxEnrollment?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  preworkRichText?: InputMaybe<OrderBy>;
  preworkVideoUrl?: InputMaybe<OrderBy>;
  promoVideoUrl?: InputMaybe<OrderBy>;
  requiresPractice?: InputMaybe<OrderBy>;
  resourcesAggregate?: InputMaybe<CohortsResourcesRelAggregateOrderBy>;
  slug?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  submissionsAggregate?: InputMaybe<LightningSessionSubmissionsAggregateOrderBy>;
  surveyLinkUrl?: InputMaybe<OrderBy>;
  totalEnrollments?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<UsersOrderBy>;
  updatedById?: InputMaybe<OrderBy>;
  visibility?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: cohorts */
export type CohortsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** columns and relationships of "cohorts_resources_rel" */
export type CohortsResourcesRel = {
  __typename?: 'CohortsResourcesRel';
  /** An object relationship */
  cohort?: Maybe<Cohorts>;
  cohortId: Scalars['uuid'];
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  resource?: Maybe<Resources>;
  resourceId: Scalars['uuid'];
};

/** aggregated selection of "cohorts_resources_rel" */
export type CohortsResourcesRelAggregate = {
  __typename?: 'CohortsResourcesRelAggregate';
  aggregate?: Maybe<CohortsResourcesRelAggregateFields>;
  nodes: Array<CohortsResourcesRel>;
};

export type CohortsResourcesRelAggregateBoolExp = {
  count?: InputMaybe<CohortsResourcesRelAggregateBoolExpCount>;
};

/** aggregate fields of "cohorts_resources_rel" */
export type CohortsResourcesRelAggregateFields = {
  __typename?: 'CohortsResourcesRelAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<CohortsResourcesRelMaxFields>;
  min?: Maybe<CohortsResourcesRelMinFields>;
};

/** aggregate fields of "cohorts_resources_rel" */
export type CohortsResourcesRelAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CohortsResourcesRelSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cohorts_resources_rel" */
export type CohortsResourcesRelAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CohortsResourcesRelMaxOrderBy>;
  min?: InputMaybe<CohortsResourcesRelMinOrderBy>;
};

/** input type for inserting array relation for remote table "cohorts_resources_rel" */
export type CohortsResourcesRelArrRelInsertInput = {
  data: Array<CohortsResourcesRelInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<CohortsResourcesRelOnConflict>;
};

/** Boolean expression to filter rows from the table "cohorts_resources_rel". All fields are combined with a logical 'AND'. */
export type CohortsResourcesRelBoolExp = {
  _and?: InputMaybe<Array<CohortsResourcesRelBoolExp>>;
  _not?: InputMaybe<CohortsResourcesRelBoolExp>;
  _or?: InputMaybe<Array<CohortsResourcesRelBoolExp>>;
  cohort?: InputMaybe<CohortsBoolExp>;
  cohortId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  resource?: InputMaybe<ResourcesBoolExp>;
  resourceId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "cohorts_resources_rel" */
export enum CohortsResourcesRelConstraint {
  /** unique or primary key constraint on columns "resource_id", "cohort_id" */
  cohorts_resources_rel_pk = 'cohorts_resources_rel_pk'
}

/** input type for inserting data into table "cohorts_resources_rel" */
export type CohortsResourcesRelInsertInput = {
  cohort?: InputMaybe<CohortsObjRelInsertInput>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  resource?: InputMaybe<ResourcesObjRelInsertInput>;
  resourceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CohortsResourcesRelMaxFields = {
  __typename?: 'CohortsResourcesRelMaxFields';
  cohortId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  resourceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "cohorts_resources_rel" */
export type CohortsResourcesRelMaxOrderBy = {
  cohortId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  resourceId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CohortsResourcesRelMinFields = {
  __typename?: 'CohortsResourcesRelMinFields';
  cohortId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  resourceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "cohorts_resources_rel" */
export type CohortsResourcesRelMinOrderBy = {
  cohortId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  resourceId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "cohorts_resources_rel" */
export type CohortsResourcesRelMutationResponse = {
  __typename?: 'CohortsResourcesRelMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CohortsResourcesRel>;
};

/** input type for inserting object relation for remote table "cohorts_resources_rel" */
export type CohortsResourcesRelObjRelInsertInput = {
  data: CohortsResourcesRelInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CohortsResourcesRelOnConflict>;
};

/** on_conflict condition type for table "cohorts_resources_rel" */
export type CohortsResourcesRelOnConflict = {
  constraint: CohortsResourcesRelConstraint;
  updateColumns?: Array<CohortsResourcesRelUpdateColumn>;
  where?: InputMaybe<CohortsResourcesRelBoolExp>;
};

/** Ordering options when selecting data from "cohorts_resources_rel". */
export type CohortsResourcesRelOrderBy = {
  cohort?: InputMaybe<CohortsOrderBy>;
  cohortId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  resource?: InputMaybe<ResourcesOrderBy>;
  resourceId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: cohorts_resources_rel */
export type CohortsResourcesRelPkColumnsInput = {
  cohortId: Scalars['uuid'];
  resourceId: Scalars['uuid'];
};

/** select columns of table "cohorts_resources_rel" */
export enum CohortsResourcesRelSelectColumn {
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  resourceId = 'resourceId'
}

/** input type for updating data in table "cohorts_resources_rel" */
export type CohortsResourcesRelSetInput = {
  cohortId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  resourceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "cohorts_resources_rel" */
export type CohortsResourcesRelStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CohortsResourcesRelStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CohortsResourcesRelStreamCursorValueInput = {
  cohortId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  resourceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "cohorts_resources_rel" */
export enum CohortsResourcesRelUpdateColumn {
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  resourceId = 'resourceId'
}

export type CohortsResourcesRelUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CohortsResourcesRelSetInput>;
  /** filter the rows which have to be updated */
  where: CohortsResourcesRelBoolExp;
};

/** select columns of table "cohorts" */
export enum CohortsSelectColumn {
  /** column name */
  classTimeOfDay = 'classTimeOfDay',
  /** column name */
  code = 'code',
  /** column name */
  communitySlackChannelUrl = 'communitySlackChannelUrl',
  /** column name */
  communitySlackInviteUrl = 'communitySlackInviteUrl',
  /** column name */
  courseId = 'courseId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  dayOfTheWeek = 'dayOfTheWeek',
  /** column name */
  endDate = 'endDate',
  /** column name */
  id = 'id',
  /** column name */
  inAppVideo = 'inAppVideo',
  /** column name */
  maxEnrollment = 'maxEnrollment',
  /** column name */
  name = 'name',
  /** column name */
  preworkRichText = 'preworkRichText',
  /** column name */
  preworkVideoUrl = 'preworkVideoUrl',
  /** column name */
  promoVideoUrl = 'promoVideoUrl',
  /** column name */
  requiresPractice = 'requiresPractice',
  /** column name */
  slug = 'slug',
  /** column name */
  startDate = 'startDate',
  /** column name */
  surveyLinkUrl = 'surveyLinkUrl',
  /** column name */
  type = 'type',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById',
  /** column name */
  visibility = 'visibility'
}

/** select "cohortsAggregateBoolExpBool_andArgumentsColumns" columns of table "cohorts" */
export enum CohortsSelectColumnCohortsAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  inAppVideo = 'inAppVideo',
  /** column name */
  requiresPractice = 'requiresPractice'
}

/** select "cohortsAggregateBoolExpBool_orArgumentsColumns" columns of table "cohorts" */
export enum CohortsSelectColumnCohortsAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  inAppVideo = 'inAppVideo',
  /** column name */
  requiresPractice = 'requiresPractice'
}

/** input type for updating data in table "cohorts" */
export type CohortsSetInput = {
  classTimeOfDay?: InputMaybe<Scalars['timestamp']>;
  code?: InputMaybe<Scalars['String']>;
  communitySlackChannelUrl?: InputMaybe<Scalars['String']>;
  communitySlackInviteUrl?: InputMaybe<Scalars['String']>;
  courseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  dayOfTheWeek?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  inAppVideo?: InputMaybe<Scalars['Boolean']>;
  maxEnrollment?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  preworkRichText?: InputMaybe<Scalars['String']>;
  preworkVideoUrl?: InputMaybe<Scalars['String']>;
  promoVideoUrl?: InputMaybe<Scalars['String']>;
  requiresPractice?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['timestamp']>;
  surveyLinkUrl?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CohortTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  visibility?: InputMaybe<CohortVisibilityEnumEnum>;
};

/** aggregate stddev on columns */
export type CohortsStddevFields = {
  __typename?: 'CohortsStddevFields';
  maxEnrollment?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "total_enrollments" */
  totalEnrollments?: Maybe<Scalars['bigint']>;
};

/** order by stddev() on columns of table "cohorts" */
export type CohortsStddevOrderBy = {
  maxEnrollment?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type CohortsStddevPopFields = {
  __typename?: 'CohortsStddevPopFields';
  maxEnrollment?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "total_enrollments" */
  totalEnrollments?: Maybe<Scalars['bigint']>;
};

/** order by stddevPop() on columns of table "cohorts" */
export type CohortsStddevPopOrderBy = {
  maxEnrollment?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type CohortsStddevSampFields = {
  __typename?: 'CohortsStddevSampFields';
  maxEnrollment?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "total_enrollments" */
  totalEnrollments?: Maybe<Scalars['bigint']>;
};

/** order by stddevSamp() on columns of table "cohorts" */
export type CohortsStddevSampOrderBy = {
  maxEnrollment?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "cohorts" */
export type CohortsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CohortsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CohortsStreamCursorValueInput = {
  classTimeOfDay?: InputMaybe<Scalars['timestamp']>;
  code?: InputMaybe<Scalars['String']>;
  communitySlackChannelUrl?: InputMaybe<Scalars['String']>;
  communitySlackInviteUrl?: InputMaybe<Scalars['String']>;
  courseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  dayOfTheWeek?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  inAppVideo?: InputMaybe<Scalars['Boolean']>;
  maxEnrollment?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  preworkRichText?: InputMaybe<Scalars['String']>;
  preworkVideoUrl?: InputMaybe<Scalars['String']>;
  promoVideoUrl?: InputMaybe<Scalars['String']>;
  requiresPractice?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['timestamp']>;
  surveyLinkUrl?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CohortTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  visibility?: InputMaybe<CohortVisibilityEnumEnum>;
};

/** aggregate sum on columns */
export type CohortsSumFields = {
  __typename?: 'CohortsSumFields';
  maxEnrollment?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "total_enrollments" */
  totalEnrollments?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "cohorts" */
export type CohortsSumOrderBy = {
  maxEnrollment?: InputMaybe<OrderBy>;
};

/** update columns of table "cohorts" */
export enum CohortsUpdateColumn {
  /** column name */
  classTimeOfDay = 'classTimeOfDay',
  /** column name */
  code = 'code',
  /** column name */
  communitySlackChannelUrl = 'communitySlackChannelUrl',
  /** column name */
  communitySlackInviteUrl = 'communitySlackInviteUrl',
  /** column name */
  courseId = 'courseId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  dayOfTheWeek = 'dayOfTheWeek',
  /** column name */
  endDate = 'endDate',
  /** column name */
  id = 'id',
  /** column name */
  inAppVideo = 'inAppVideo',
  /** column name */
  maxEnrollment = 'maxEnrollment',
  /** column name */
  name = 'name',
  /** column name */
  preworkRichText = 'preworkRichText',
  /** column name */
  preworkVideoUrl = 'preworkVideoUrl',
  /** column name */
  promoVideoUrl = 'promoVideoUrl',
  /** column name */
  requiresPractice = 'requiresPractice',
  /** column name */
  slug = 'slug',
  /** column name */
  startDate = 'startDate',
  /** column name */
  surveyLinkUrl = 'surveyLinkUrl',
  /** column name */
  type = 'type',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById',
  /** column name */
  visibility = 'visibility'
}

export type CohortsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CohortsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CohortsSetInput>;
  /** filter the rows which have to be updated */
  where: CohortsBoolExp;
};

/** aggregate varPop on columns */
export type CohortsVarPopFields = {
  __typename?: 'CohortsVarPopFields';
  maxEnrollment?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "total_enrollments" */
  totalEnrollments?: Maybe<Scalars['bigint']>;
};

/** order by varPop() on columns of table "cohorts" */
export type CohortsVarPopOrderBy = {
  maxEnrollment?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type CohortsVarSampFields = {
  __typename?: 'CohortsVarSampFields';
  maxEnrollment?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "total_enrollments" */
  totalEnrollments?: Maybe<Scalars['bigint']>;
};

/** order by varSamp() on columns of table "cohorts" */
export type CohortsVarSampOrderBy = {
  maxEnrollment?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CohortsVarianceFields = {
  __typename?: 'CohortsVarianceFields';
  maxEnrollment?: Maybe<Scalars['Float']>;
  /** A computed field, executes function "total_enrollments" */
  totalEnrollments?: Maybe<Scalars['bigint']>;
};

/** order by variance() on columns of table "cohorts" */
export type CohortsVarianceOrderBy = {
  maxEnrollment?: InputMaybe<OrderBy>;
};

/** runtime/live configurations */
export type Configurations = {
  __typename?: 'Configurations';
  key: Scalars['String'];
  value: Scalars['jsonb'];
};

/** runtime/live configurations */
export type ConfigurationsValueArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "configurations" */
export type ConfigurationsAggregate = {
  __typename?: 'ConfigurationsAggregate';
  aggregate?: Maybe<ConfigurationsAggregateFields>;
  nodes: Array<Configurations>;
};

/** aggregate fields of "configurations" */
export type ConfigurationsAggregateFields = {
  __typename?: 'ConfigurationsAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<ConfigurationsMaxFields>;
  min?: Maybe<ConfigurationsMinFields>;
};

/** aggregate fields of "configurations" */
export type ConfigurationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ConfigurationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ConfigurationsAppendInput = {
  value?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "configurations". All fields are combined with a logical 'AND'. */
export type ConfigurationsBoolExp = {
  _and?: InputMaybe<Array<ConfigurationsBoolExp>>;
  _not?: InputMaybe<ConfigurationsBoolExp>;
  _or?: InputMaybe<Array<ConfigurationsBoolExp>>;
  key?: InputMaybe<StringComparisonExp>;
  value?: InputMaybe<JsonbComparisonExp>;
};

/** unique or primary key constraints on table "configurations" */
export enum ConfigurationsConstraint {
  /** unique or primary key constraint on columns "key" */
  configurations_pkey = 'configurations_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ConfigurationsDeleteAtPathInput = {
  value?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ConfigurationsDeleteElemInput = {
  value?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ConfigurationsDeleteKeyInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "configurations" */
export type ConfigurationsInsertInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type ConfigurationsMaxFields = {
  __typename?: 'ConfigurationsMaxFields';
  key?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ConfigurationsMinFields = {
  __typename?: 'ConfigurationsMinFields';
  key?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "configurations" */
export type ConfigurationsMutationResponse = {
  __typename?: 'ConfigurationsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Configurations>;
};

/** on_conflict condition type for table "configurations" */
export type ConfigurationsOnConflict = {
  constraint: ConfigurationsConstraint;
  updateColumns?: Array<ConfigurationsUpdateColumn>;
  where?: InputMaybe<ConfigurationsBoolExp>;
};

/** Ordering options when selecting data from "configurations". */
export type ConfigurationsOrderBy = {
  key?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: configurations */
export type ConfigurationsPkColumnsInput = {
  key: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ConfigurationsPrependInput = {
  value?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "configurations" */
export enum ConfigurationsSelectColumn {
  /** column name */
  key = 'key',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "configurations" */
export type ConfigurationsSetInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "configurations" */
export type ConfigurationsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ConfigurationsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ConfigurationsStreamCursorValueInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['jsonb']>;
};

/** update columns of table "configurations" */
export enum ConfigurationsUpdateColumn {
  /** column name */
  key = 'key',
  /** column name */
  value = 'value'
}

export type ConfigurationsUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ConfigurationsAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<ConfigurationsDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<ConfigurationsDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<ConfigurationsDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ConfigurationsPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ConfigurationsSetInput>;
  /** filter the rows which have to be updated */
  where: ConfigurationsBoolExp;
};

export type CountTeamSessionsItem = {
  __typename?: 'CountTeamSessionsItem';
  count: Scalars['Int'];
  firstName: Scalars['String'];
  id: Scalars['uuid'];
  imageUrl: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
};

export type CountTeamSessionsOutput = {
  __typename?: 'CountTeamSessionsOutput';
  users?: Maybe<Array<Maybe<CountTeamSessionsItem>>>;
};

/** columns and relationships of "course_certifications" */
export type CourseCertifications = {
  __typename?: 'CourseCertifications';
  certificationFileUrl?: Maybe<Scalars['String']>;
  certificationImageUrl?: Maybe<Scalars['String']>;
  /** An object relationship */
  cohort?: Maybe<Cohorts>;
  cohortId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  deletedBy?: Maybe<Users>;
  deletedById?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** An object relationship */
  impactAssessment?: Maybe<ImpactAssessments>;
  impactAssessmentId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "course_certifications" */
export type CourseCertificationsAggregate = {
  __typename?: 'CourseCertificationsAggregate';
  aggregate?: Maybe<CourseCertificationsAggregateFields>;
  nodes: Array<CourseCertifications>;
};

/** aggregate fields of "course_certifications" */
export type CourseCertificationsAggregateFields = {
  __typename?: 'CourseCertificationsAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<CourseCertificationsMaxFields>;
  min?: Maybe<CourseCertificationsMinFields>;
};

/** aggregate fields of "course_certifications" */
export type CourseCertificationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CourseCertificationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "course_certifications". All fields are combined with a logical 'AND'. */
export type CourseCertificationsBoolExp = {
  _and?: InputMaybe<Array<CourseCertificationsBoolExp>>;
  _not?: InputMaybe<CourseCertificationsBoolExp>;
  _or?: InputMaybe<Array<CourseCertificationsBoolExp>>;
  certificationFileUrl?: InputMaybe<StringComparisonExp>;
  certificationImageUrl?: InputMaybe<StringComparisonExp>;
  cohort?: InputMaybe<CohortsBoolExp>;
  cohortId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  deletedBy?: InputMaybe<UsersBoolExp>;
  deletedById?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  impactAssessment?: InputMaybe<ImpactAssessmentsBoolExp>;
  impactAssessmentId?: InputMaybe<UuidComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "course_certifications" */
export enum CourseCertificationsConstraint {
  /** unique or primary key constraint on columns "id" */
  course_certifications_pk = 'course_certifications_pk'
}

/** input type for inserting data into table "course_certifications" */
export type CourseCertificationsInsertInput = {
  certificationFileUrl?: InputMaybe<Scalars['String']>;
  certificationImageUrl?: InputMaybe<Scalars['String']>;
  cohort?: InputMaybe<CohortsObjRelInsertInput>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedBy?: InputMaybe<UsersObjRelInsertInput>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  impactAssessment?: InputMaybe<ImpactAssessmentsObjRelInsertInput>;
  impactAssessmentId?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CourseCertificationsMaxFields = {
  __typename?: 'CourseCertificationsMaxFields';
  certificationFileUrl?: Maybe<Scalars['String']>;
  certificationImageUrl?: Maybe<Scalars['String']>;
  cohortId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedById?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  impactAssessmentId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type CourseCertificationsMinFields = {
  __typename?: 'CourseCertificationsMinFields';
  certificationFileUrl?: Maybe<Scalars['String']>;
  certificationImageUrl?: Maybe<Scalars['String']>;
  cohortId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedById?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  impactAssessmentId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "course_certifications" */
export type CourseCertificationsMutationResponse = {
  __typename?: 'CourseCertificationsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CourseCertifications>;
};

/** on_conflict condition type for table "course_certifications" */
export type CourseCertificationsOnConflict = {
  constraint: CourseCertificationsConstraint;
  updateColumns?: Array<CourseCertificationsUpdateColumn>;
  where?: InputMaybe<CourseCertificationsBoolExp>;
};

/** Ordering options when selecting data from "course_certifications". */
export type CourseCertificationsOrderBy = {
  certificationFileUrl?: InputMaybe<OrderBy>;
  certificationImageUrl?: InputMaybe<OrderBy>;
  cohort?: InputMaybe<CohortsOrderBy>;
  cohortId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedBy?: InputMaybe<UsersOrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  impactAssessment?: InputMaybe<ImpactAssessmentsOrderBy>;
  impactAssessmentId?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: course_certifications */
export type CourseCertificationsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "course_certifications" */
export enum CourseCertificationsSelectColumn {
  /** column name */
  certificationFileUrl = 'certificationFileUrl',
  /** column name */
  certificationImageUrl = 'certificationImageUrl',
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  id = 'id',
  /** column name */
  impactAssessmentId = 'impactAssessmentId',
  /** column name */
  userId = 'userId'
}

/** input type for updating data in table "course_certifications" */
export type CourseCertificationsSetInput = {
  certificationFileUrl?: InputMaybe<Scalars['String']>;
  certificationImageUrl?: InputMaybe<Scalars['String']>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  impactAssessmentId?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "course_certifications" */
export type CourseCertificationsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CourseCertificationsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CourseCertificationsStreamCursorValueInput = {
  certificationFileUrl?: InputMaybe<Scalars['String']>;
  certificationImageUrl?: InputMaybe<Scalars['String']>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  impactAssessmentId?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "course_certifications" */
export enum CourseCertificationsUpdateColumn {
  /** column name */
  certificationFileUrl = 'certificationFileUrl',
  /** column name */
  certificationImageUrl = 'certificationImageUrl',
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  id = 'id',
  /** column name */
  impactAssessmentId = 'impactAssessmentId',
  /** column name */
  userId = 'userId'
}

export type CourseCertificationsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CourseCertificationsSetInput>;
  /** filter the rows which have to be updated */
  where: CourseCertificationsBoolExp;
};

/** columns and relationships of "courses_coaches_rel" */
export type CourseCoaches = {
  __typename?: 'CourseCoaches';
  /** An object relationship */
  coach: Coaches;
  coachId: Scalars['uuid'];
  /** An object relationship */
  course: Courses;
  courseId: Scalars['uuid'];
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "courses_coaches_rel" */
export type CourseCoachesAggregate = {
  __typename?: 'CourseCoachesAggregate';
  aggregate?: Maybe<CourseCoachesAggregateFields>;
  nodes: Array<CourseCoaches>;
};

export type CourseCoachesAggregateBoolExp = {
  count?: InputMaybe<CourseCoachesAggregateBoolExpCount>;
};

export type CourseCoachesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CourseCoachesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CourseCoachesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "courses_coaches_rel" */
export type CourseCoachesAggregateFields = {
  __typename?: 'CourseCoachesAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<CourseCoachesMaxFields>;
  min?: Maybe<CourseCoachesMinFields>;
};

/** aggregate fields of "courses_coaches_rel" */
export type CourseCoachesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CourseCoachesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "courses_coaches_rel" */
export type CourseCoachesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CourseCoachesMaxOrderBy>;
  min?: InputMaybe<CourseCoachesMinOrderBy>;
};

/** input type for inserting array relation for remote table "courses_coaches_rel" */
export type CourseCoachesArrRelInsertInput = {
  data: Array<CourseCoachesInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<CourseCoachesOnConflict>;
};

/** Boolean expression to filter rows from the table "courses_coaches_rel". All fields are combined with a logical 'AND'. */
export type CourseCoachesBoolExp = {
  _and?: InputMaybe<Array<CourseCoachesBoolExp>>;
  _not?: InputMaybe<CourseCoachesBoolExp>;
  _or?: InputMaybe<Array<CourseCoachesBoolExp>>;
  coach?: InputMaybe<CoachesBoolExp>;
  coachId?: InputMaybe<UuidComparisonExp>;
  course?: InputMaybe<CoursesBoolExp>;
  courseId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "courses_coaches_rel" */
export enum CourseCoachesConstraint {
  /** unique or primary key constraint on columns "coach_id", "course_id" */
  courses_coaches_rel_pk = 'courses_coaches_rel_pk'
}

/** input type for inserting data into table "courses_coaches_rel" */
export type CourseCoachesInsertInput = {
  coach?: InputMaybe<CoachesObjRelInsertInput>;
  coachId?: InputMaybe<Scalars['uuid']>;
  course?: InputMaybe<CoursesObjRelInsertInput>;
  courseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CourseCoachesMaxFields = {
  __typename?: 'CourseCoachesMaxFields';
  coachId?: Maybe<Scalars['uuid']>;
  courseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "courses_coaches_rel" */
export type CourseCoachesMaxOrderBy = {
  coachId?: InputMaybe<OrderBy>;
  courseId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CourseCoachesMinFields = {
  __typename?: 'CourseCoachesMinFields';
  coachId?: Maybe<Scalars['uuid']>;
  courseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "courses_coaches_rel" */
export type CourseCoachesMinOrderBy = {
  coachId?: InputMaybe<OrderBy>;
  courseId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "courses_coaches_rel" */
export type CourseCoachesMutationResponse = {
  __typename?: 'CourseCoachesMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CourseCoaches>;
};

/** on_conflict condition type for table "courses_coaches_rel" */
export type CourseCoachesOnConflict = {
  constraint: CourseCoachesConstraint;
  updateColumns?: Array<CourseCoachesUpdateColumn>;
  where?: InputMaybe<CourseCoachesBoolExp>;
};

/** Ordering options when selecting data from "courses_coaches_rel". */
export type CourseCoachesOrderBy = {
  coach?: InputMaybe<CoachesOrderBy>;
  coachId?: InputMaybe<OrderBy>;
  course?: InputMaybe<CoursesOrderBy>;
  courseId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: courses_coaches_rel */
export type CourseCoachesPkColumnsInput = {
  coachId: Scalars['uuid'];
  courseId: Scalars['uuid'];
};

/** select columns of table "courses_coaches_rel" */
export enum CourseCoachesSelectColumn {
  /** column name */
  coachId = 'coachId',
  /** column name */
  courseId = 'courseId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById'
}

/** input type for updating data in table "courses_coaches_rel" */
export type CourseCoachesSetInput = {
  coachId?: InputMaybe<Scalars['uuid']>;
  courseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "CourseCoaches" */
export type CourseCoachesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CourseCoachesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CourseCoachesStreamCursorValueInput = {
  coachId?: InputMaybe<Scalars['uuid']>;
  courseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "courses_coaches_rel" */
export enum CourseCoachesUpdateColumn {
  /** column name */
  coachId = 'coachId',
  /** column name */
  courseId = 'courseId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById'
}

export type CourseCoachesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CourseCoachesSetInput>;
  /** filter the rows which have to be updated */
  where: CourseCoachesBoolExp;
};

/** columns and relationships of "course_enrollment_status_enum" */
export type CourseEnrollmentStatus = {
  __typename?: 'CourseEnrollmentStatus';
  value: Scalars['String'];
};

/** aggregated selection of "course_enrollment_status_enum" */
export type CourseEnrollmentStatusAggregate = {
  __typename?: 'CourseEnrollmentStatusAggregate';
  aggregate?: Maybe<CourseEnrollmentStatusAggregateFields>;
  nodes: Array<CourseEnrollmentStatus>;
};

/** aggregate fields of "course_enrollment_status_enum" */
export type CourseEnrollmentStatusAggregateFields = {
  __typename?: 'CourseEnrollmentStatusAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<CourseEnrollmentStatusMaxFields>;
  min?: Maybe<CourseEnrollmentStatusMinFields>;
};

/** aggregate fields of "course_enrollment_status_enum" */
export type CourseEnrollmentStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CourseEnrollmentStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "course_enrollment_status_enum". All fields are combined with a logical 'AND'. */
export type CourseEnrollmentStatusBoolExp = {
  _and?: InputMaybe<Array<CourseEnrollmentStatusBoolExp>>;
  _not?: InputMaybe<CourseEnrollmentStatusBoolExp>;
  _or?: InputMaybe<Array<CourseEnrollmentStatusBoolExp>>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "course_enrollment_status_enum" */
export enum CourseEnrollmentStatusConstraint {
  /** unique or primary key constraint on columns "value" */
  course_enrollment_status_enum_pkey = 'course_enrollment_status_enum_pkey'
}

export enum CourseEnrollmentStatusEnum {
  CLOSED = 'CLOSED',
  COMING_SOON = 'COMING_SOON',
  OPEN = 'OPEN'
}

/** Boolean expression to compare columns of type "CourseEnrollmentStatusEnum". All fields are combined with logical 'AND'. */
export type CourseEnrollmentStatusEnumComparisonExp = {
  _eq?: InputMaybe<CourseEnrollmentStatusEnum>;
  _in?: InputMaybe<Array<CourseEnrollmentStatusEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<CourseEnrollmentStatusEnum>;
  _nin?: InputMaybe<Array<CourseEnrollmentStatusEnum>>;
};

/** input type for inserting data into table "course_enrollment_status_enum" */
export type CourseEnrollmentStatusInsertInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CourseEnrollmentStatusMaxFields = {
  __typename?: 'CourseEnrollmentStatusMaxFields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type CourseEnrollmentStatusMinFields = {
  __typename?: 'CourseEnrollmentStatusMinFields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "course_enrollment_status_enum" */
export type CourseEnrollmentStatusMutationResponse = {
  __typename?: 'CourseEnrollmentStatusMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CourseEnrollmentStatus>;
};

/** on_conflict condition type for table "course_enrollment_status_enum" */
export type CourseEnrollmentStatusOnConflict = {
  constraint: CourseEnrollmentStatusConstraint;
  updateColumns?: Array<CourseEnrollmentStatusUpdateColumn>;
  where?: InputMaybe<CourseEnrollmentStatusBoolExp>;
};

/** Ordering options when selecting data from "course_enrollment_status_enum". */
export type CourseEnrollmentStatusOrderBy = {
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: course_enrollment_status_enum */
export type CourseEnrollmentStatusPkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "course_enrollment_status_enum" */
export enum CourseEnrollmentStatusSelectColumn {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "course_enrollment_status_enum" */
export type CourseEnrollmentStatusSetInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "CourseEnrollmentStatus" */
export type CourseEnrollmentStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CourseEnrollmentStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CourseEnrollmentStatusStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "course_enrollment_status_enum" */
export enum CourseEnrollmentStatusUpdateColumn {
  /** column name */
  value = 'value'
}

export type CourseEnrollmentStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CourseEnrollmentStatusSetInput>;
  /** filter the rows which have to be updated */
  where: CourseEnrollmentStatusBoolExp;
};

/** columns and relationships of "course_publish_status_enum" */
export type CoursePublishStatus = {
  __typename?: 'CoursePublishStatus';
  value: Scalars['String'];
};

/** aggregated selection of "course_publish_status_enum" */
export type CoursePublishStatusAggregate = {
  __typename?: 'CoursePublishStatusAggregate';
  aggregate?: Maybe<CoursePublishStatusAggregateFields>;
  nodes: Array<CoursePublishStatus>;
};

/** aggregate fields of "course_publish_status_enum" */
export type CoursePublishStatusAggregateFields = {
  __typename?: 'CoursePublishStatusAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<CoursePublishStatusMaxFields>;
  min?: Maybe<CoursePublishStatusMinFields>;
};

/** aggregate fields of "course_publish_status_enum" */
export type CoursePublishStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CoursePublishStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "course_publish_status_enum". All fields are combined with a logical 'AND'. */
export type CoursePublishStatusBoolExp = {
  _and?: InputMaybe<Array<CoursePublishStatusBoolExp>>;
  _not?: InputMaybe<CoursePublishStatusBoolExp>;
  _or?: InputMaybe<Array<CoursePublishStatusBoolExp>>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "course_publish_status_enum" */
export enum CoursePublishStatusConstraint {
  /** unique or primary key constraint on columns "value" */
  course_publish_status_enum_pkey = 'course_publish_status_enum_pkey'
}

export enum CoursePublishStatusEnum {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  PUBLISHED = 'PUBLISHED',
  RETIRED = 'RETIRED'
}

/** Boolean expression to compare columns of type "CoursePublishStatusEnum". All fields are combined with logical 'AND'. */
export type CoursePublishStatusEnumComparisonExp = {
  _eq?: InputMaybe<CoursePublishStatusEnum>;
  _in?: InputMaybe<Array<CoursePublishStatusEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<CoursePublishStatusEnum>;
  _nin?: InputMaybe<Array<CoursePublishStatusEnum>>;
};

/** input type for inserting data into table "course_publish_status_enum" */
export type CoursePublishStatusInsertInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CoursePublishStatusMaxFields = {
  __typename?: 'CoursePublishStatusMaxFields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type CoursePublishStatusMinFields = {
  __typename?: 'CoursePublishStatusMinFields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "course_publish_status_enum" */
export type CoursePublishStatusMutationResponse = {
  __typename?: 'CoursePublishStatusMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoursePublishStatus>;
};

/** input type for inserting object relation for remote table "course_publish_status_enum" */
export type CoursePublishStatusObjRelInsertInput = {
  data: CoursePublishStatusInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CoursePublishStatusOnConflict>;
};

/** on_conflict condition type for table "course_publish_status_enum" */
export type CoursePublishStatusOnConflict = {
  constraint: CoursePublishStatusConstraint;
  updateColumns?: Array<CoursePublishStatusUpdateColumn>;
  where?: InputMaybe<CoursePublishStatusBoolExp>;
};

/** Ordering options when selecting data from "course_publish_status_enum". */
export type CoursePublishStatusOrderBy = {
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: course_publish_status_enum */
export type CoursePublishStatusPkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "course_publish_status_enum" */
export enum CoursePublishStatusSelectColumn {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "course_publish_status_enum" */
export type CoursePublishStatusSetInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "CoursePublishStatus" */
export type CoursePublishStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CoursePublishStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CoursePublishStatusStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "course_publish_status_enum" */
export enum CoursePublishStatusUpdateColumn {
  /** column name */
  value = 'value'
}

export type CoursePublishStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CoursePublishStatusSetInput>;
  /** filter the rows which have to be updated */
  where: CoursePublishStatusBoolExp;
};

/** columns and relationships of "course_type_enum" */
export type CourseType = {
  __typename?: 'CourseType';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "course_type_enum" */
export type CourseTypeAggregate = {
  __typename?: 'CourseTypeAggregate';
  aggregate?: Maybe<CourseTypeAggregateFields>;
  nodes: Array<CourseType>;
};

/** aggregate fields of "course_type_enum" */
export type CourseTypeAggregateFields = {
  __typename?: 'CourseTypeAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<CourseTypeMaxFields>;
  min?: Maybe<CourseTypeMinFields>;
};

/** aggregate fields of "course_type_enum" */
export type CourseTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CourseTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "course_type_enum". All fields are combined with a logical 'AND'. */
export type CourseTypeBoolExp = {
  _and?: InputMaybe<Array<CourseTypeBoolExp>>;
  _not?: InputMaybe<CourseTypeBoolExp>;
  _or?: InputMaybe<Array<CourseTypeBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "course_type_enum" */
export enum CourseTypeConstraint {
  /** unique or primary key constraint on columns "value" */
  course_type_enum_pkey = 'course_type_enum_pkey'
}

export enum CourseTypeEnum {
  /** Lightning session course teaching style */
  LIGHTNING_SESSION = 'LIGHTNING_SESSION',
  /** Pilot On Demand Coaching Course Type */
  PILOT_ON_DEMAND_COACHING = 'PILOT_ON_DEMAND_COACHING',
  /** Traditional course teaching style */
  TRADITIONAL = 'TRADITIONAL'
}

/** Boolean expression to compare columns of type "CourseTypeEnum". All fields are combined with logical 'AND'. */
export type CourseTypeEnumComparisonExp = {
  _eq?: InputMaybe<CourseTypeEnum>;
  _in?: InputMaybe<Array<CourseTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<CourseTypeEnum>;
  _nin?: InputMaybe<Array<CourseTypeEnum>>;
};

/** input type for inserting data into table "course_type_enum" */
export type CourseTypeInsertInput = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CourseTypeMaxFields = {
  __typename?: 'CourseTypeMaxFields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type CourseTypeMinFields = {
  __typename?: 'CourseTypeMinFields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "course_type_enum" */
export type CourseTypeMutationResponse = {
  __typename?: 'CourseTypeMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CourseType>;
};

/** on_conflict condition type for table "course_type_enum" */
export type CourseTypeOnConflict = {
  constraint: CourseTypeConstraint;
  updateColumns?: Array<CourseTypeUpdateColumn>;
  where?: InputMaybe<CourseTypeBoolExp>;
};

/** Ordering options when selecting data from "course_type_enum". */
export type CourseTypeOrderBy = {
  description?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: course_type_enum */
export type CourseTypePkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "course_type_enum" */
export enum CourseTypeSelectColumn {
  /** column name */
  description = 'description',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "course_type_enum" */
export type CourseTypeSetInput = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "CourseType" */
export type CourseTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CourseTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CourseTypeStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "course_type_enum" */
export enum CourseTypeUpdateColumn {
  /** column name */
  description = 'description',
  /** column name */
  value = 'value'
}

export type CourseTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CourseTypeSetInput>;
  /** filter the rows which have to be updated */
  where: CourseTypeBoolExp;
};

/** columns and relationships of "course_waitlist" */
export type CourseWaitlist = {
  __typename?: 'CourseWaitlist';
  /** An object relationship */
  UpdatedBy?: Maybe<Users>;
  availabilityNotificationSent: Scalars['Boolean'];
  /** An object relationship */
  course?: Maybe<Courses>;
  courseSlug: Scalars['String'];
  createdAt: Scalars['timestamp'];
  /** An object relationship */
  createdBy: Users;
  createdById: Scalars['uuid'];
  /** An object relationship */
  createdOnBehalfOf?: Maybe<Users>;
  createdOnBehalfOfId?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  deletedBy?: Maybe<Users>;
  deletedById?: Maybe<Scalars['uuid']>;
  deletedOnBehalfOfId?: Maybe<Scalars['uuid']>;
  desiredTimezone?: Maybe<Scalars['String']>;
  desiredUrgency: CourseWaitlistDesiredUrgencyTypeEnum;
  id: Scalars['uuid'];
  isNotDeleted?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  organization?: Maybe<Organizations>;
  organizationId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user?: Maybe<Users>;
  userId: Scalars['uuid'];
};

/** aggregated selection of "course_waitlist" */
export type CourseWaitlistAggregate = {
  __typename?: 'CourseWaitlistAggregate';
  aggregate?: Maybe<CourseWaitlistAggregateFields>;
  nodes: Array<CourseWaitlist>;
};

/** aggregate fields of "course_waitlist" */
export type CourseWaitlistAggregateFields = {
  __typename?: 'CourseWaitlistAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<CourseWaitlistMaxFields>;
  min?: Maybe<CourseWaitlistMinFields>;
};

/** aggregate fields of "course_waitlist" */
export type CourseWaitlistAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CourseWaitlistSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "course_waitlist". All fields are combined with a logical 'AND'. */
export type CourseWaitlistBoolExp = {
  UpdatedBy?: InputMaybe<UsersBoolExp>;
  _and?: InputMaybe<Array<CourseWaitlistBoolExp>>;
  _not?: InputMaybe<CourseWaitlistBoolExp>;
  _or?: InputMaybe<Array<CourseWaitlistBoolExp>>;
  availabilityNotificationSent?: InputMaybe<BooleanComparisonExp>;
  course?: InputMaybe<CoursesBoolExp>;
  courseSlug?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  createdOnBehalfOf?: InputMaybe<UsersBoolExp>;
  createdOnBehalfOfId?: InputMaybe<UuidComparisonExp>;
  deletedAt?: InputMaybe<TimestampComparisonExp>;
  deletedBy?: InputMaybe<UsersBoolExp>;
  deletedById?: InputMaybe<UuidComparisonExp>;
  deletedOnBehalfOfId?: InputMaybe<UuidComparisonExp>;
  desiredTimezone?: InputMaybe<StringComparisonExp>;
  desiredUrgency?: InputMaybe<CourseWaitlistDesiredUrgencyTypeEnumComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isNotDeleted?: InputMaybe<BooleanComparisonExp>;
  organization?: InputMaybe<OrganizationsBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  updatedById?: InputMaybe<UuidComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "course_waitlist" */
export enum CourseWaitlistConstraint {
  /** unique or primary key constraint on columns "is_not_deleted", "user_id", "course_slug" */
  course_waitlist_is_not_deleted_course_slug_user_id_key = 'course_waitlist_is_not_deleted_course_slug_user_id_key',
  /** unique or primary key constraint on columns "id" */
  course_waitlist_pkey = 'course_waitlist_pkey'
}

/** columns and relationships of "course_waitlist_desired_urgency_type" */
export type CourseWaitlistDesiredUrgencyType = {
  __typename?: 'CourseWaitlistDesiredUrgencyType';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "course_waitlist_desired_urgency_type" */
export type CourseWaitlistDesiredUrgencyTypeAggregate = {
  __typename?: 'CourseWaitlistDesiredUrgencyTypeAggregate';
  aggregate?: Maybe<CourseWaitlistDesiredUrgencyTypeAggregateFields>;
  nodes: Array<CourseWaitlistDesiredUrgencyType>;
};

/** aggregate fields of "course_waitlist_desired_urgency_type" */
export type CourseWaitlistDesiredUrgencyTypeAggregateFields = {
  __typename?: 'CourseWaitlistDesiredUrgencyTypeAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<CourseWaitlistDesiredUrgencyTypeMaxFields>;
  min?: Maybe<CourseWaitlistDesiredUrgencyTypeMinFields>;
};

/** aggregate fields of "course_waitlist_desired_urgency_type" */
export type CourseWaitlistDesiredUrgencyTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CourseWaitlistDesiredUrgencyTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "course_waitlist_desired_urgency_type". All fields are combined with a logical 'AND'. */
export type CourseWaitlistDesiredUrgencyTypeBoolExp = {
  _and?: InputMaybe<Array<CourseWaitlistDesiredUrgencyTypeBoolExp>>;
  _not?: InputMaybe<CourseWaitlistDesiredUrgencyTypeBoolExp>;
  _or?: InputMaybe<Array<CourseWaitlistDesiredUrgencyTypeBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "course_waitlist_desired_urgency_type" */
export enum CourseWaitlistDesiredUrgencyTypeConstraint {
  /** unique or primary key constraint on columns "value" */
  course_waitlist_desired_urgency_type_pkey = 'course_waitlist_desired_urgency_type_pkey'
}

export enum CourseWaitlistDesiredUrgencyTypeEnum {
  /** Within 3 months */
  NEXT_3_MONTHS = 'NEXT_3_MONTHS',
  /** Within 3 weeks */
  NEXT_3_WEEKS = 'NEXT_3_WEEKS',
  /** Within 6 weeks */
  NEXT_6_WEEKS = 'NEXT_6_WEEKS'
}

/** Boolean expression to compare columns of type "CourseWaitlistDesiredUrgencyTypeEnum". All fields are combined with logical 'AND'. */
export type CourseWaitlistDesiredUrgencyTypeEnumComparisonExp = {
  _eq?: InputMaybe<CourseWaitlistDesiredUrgencyTypeEnum>;
  _in?: InputMaybe<Array<CourseWaitlistDesiredUrgencyTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<CourseWaitlistDesiredUrgencyTypeEnum>;
  _nin?: InputMaybe<Array<CourseWaitlistDesiredUrgencyTypeEnum>>;
};

/** input type for inserting data into table "course_waitlist_desired_urgency_type" */
export type CourseWaitlistDesiredUrgencyTypeInsertInput = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CourseWaitlistDesiredUrgencyTypeMaxFields = {
  __typename?: 'CourseWaitlistDesiredUrgencyTypeMaxFields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type CourseWaitlistDesiredUrgencyTypeMinFields = {
  __typename?: 'CourseWaitlistDesiredUrgencyTypeMinFields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "course_waitlist_desired_urgency_type" */
export type CourseWaitlistDesiredUrgencyTypeMutationResponse = {
  __typename?: 'CourseWaitlistDesiredUrgencyTypeMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CourseWaitlistDesiredUrgencyType>;
};

/** on_conflict condition type for table "course_waitlist_desired_urgency_type" */
export type CourseWaitlistDesiredUrgencyTypeOnConflict = {
  constraint: CourseWaitlistDesiredUrgencyTypeConstraint;
  updateColumns?: Array<CourseWaitlistDesiredUrgencyTypeUpdateColumn>;
  where?: InputMaybe<CourseWaitlistDesiredUrgencyTypeBoolExp>;
};

/** Ordering options when selecting data from "course_waitlist_desired_urgency_type". */
export type CourseWaitlistDesiredUrgencyTypeOrderBy = {
  description?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: course_waitlist_desired_urgency_type */
export type CourseWaitlistDesiredUrgencyTypePkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "course_waitlist_desired_urgency_type" */
export enum CourseWaitlistDesiredUrgencyTypeSelectColumn {
  /** column name */
  description = 'description',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "course_waitlist_desired_urgency_type" */
export type CourseWaitlistDesiredUrgencyTypeSetInput = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "course_waitlist_desired_urgency_type" */
export type CourseWaitlistDesiredUrgencyTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CourseWaitlistDesiredUrgencyTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CourseWaitlistDesiredUrgencyTypeStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "course_waitlist_desired_urgency_type" */
export enum CourseWaitlistDesiredUrgencyTypeUpdateColumn {
  /** column name */
  description = 'description',
  /** column name */
  value = 'value'
}

export type CourseWaitlistDesiredUrgencyTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CourseWaitlistDesiredUrgencyTypeSetInput>;
  /** filter the rows which have to be updated */
  where: CourseWaitlistDesiredUrgencyTypeBoolExp;
};

export type CourseWaitlistInput = {
  courseSlug: Scalars['String'];
  desiredTimezone: Scalars['String'];
  desiredUrgency: Scalars['String'];
  onBehalfOfId?: InputMaybe<Scalars['uuid']>;
  orgId?: InputMaybe<Scalars['String']>;
  userIds?: InputMaybe<Array<Scalars['uuid']>>;
};

/** input type for inserting data into table "course_waitlist" */
export type CourseWaitlistInsertInput = {
  UpdatedBy?: InputMaybe<UsersObjRelInsertInput>;
  availabilityNotificationSent?: InputMaybe<Scalars['Boolean']>;
  course?: InputMaybe<CoursesObjRelInsertInput>;
  courseSlug?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  createdOnBehalfOf?: InputMaybe<UsersObjRelInsertInput>;
  createdOnBehalfOfId?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedBy?: InputMaybe<UsersObjRelInsertInput>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  deletedOnBehalfOfId?: InputMaybe<Scalars['uuid']>;
  desiredTimezone?: InputMaybe<Scalars['String']>;
  desiredUrgency?: InputMaybe<CourseWaitlistDesiredUrgencyTypeEnum>;
  id?: InputMaybe<Scalars['uuid']>;
  organization?: InputMaybe<OrganizationsObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CourseWaitlistMaxFields = {
  __typename?: 'CourseWaitlistMaxFields';
  courseSlug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  createdOnBehalfOfId?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  deletedOnBehalfOfId?: Maybe<Scalars['uuid']>;
  desiredTimezone?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  organizationId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type CourseWaitlistMinFields = {
  __typename?: 'CourseWaitlistMinFields';
  courseSlug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  createdOnBehalfOfId?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  deletedOnBehalfOfId?: Maybe<Scalars['uuid']>;
  desiredTimezone?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  organizationId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "course_waitlist" */
export type CourseWaitlistMutationResponse = {
  __typename?: 'CourseWaitlistMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CourseWaitlist>;
};

/** on_conflict condition type for table "course_waitlist" */
export type CourseWaitlistOnConflict = {
  constraint: CourseWaitlistConstraint;
  updateColumns?: Array<CourseWaitlistUpdateColumn>;
  where?: InputMaybe<CourseWaitlistBoolExp>;
};

/** Ordering options when selecting data from "course_waitlist". */
export type CourseWaitlistOrderBy = {
  UpdatedBy?: InputMaybe<UsersOrderBy>;
  availabilityNotificationSent?: InputMaybe<OrderBy>;
  course?: InputMaybe<CoursesOrderBy>;
  courseSlug?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  createdOnBehalfOf?: InputMaybe<UsersOrderBy>;
  createdOnBehalfOfId?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedBy?: InputMaybe<UsersOrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  deletedOnBehalfOfId?: InputMaybe<OrderBy>;
  desiredTimezone?: InputMaybe<OrderBy>;
  desiredUrgency?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isNotDeleted?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationsOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: course_waitlist */
export type CourseWaitlistPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "course_waitlist" */
export enum CourseWaitlistSelectColumn {
  /** column name */
  availabilityNotificationSent = 'availabilityNotificationSent',
  /** column name */
  courseSlug = 'courseSlug',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  createdOnBehalfOfId = 'createdOnBehalfOfId',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  deletedOnBehalfOfId = 'deletedOnBehalfOfId',
  /** column name */
  desiredTimezone = 'desiredTimezone',
  /** column name */
  desiredUrgency = 'desiredUrgency',
  /** column name */
  id = 'id',
  /** column name */
  isNotDeleted = 'isNotDeleted',
  /** column name */
  organizationId = 'organizationId',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById',
  /** column name */
  userId = 'userId'
}

export type CourseWaitlistSendAvailabilityEmailInput = {
  cohortId: Scalars['String'];
  courseWaitlistIds: Array<Scalars['String']>;
};

export type CourseWaitlistSendAvailabilityEmailOutput = {
  __typename?: 'CourseWaitlistSendAvailabilityEmailOutput';
  details?: Maybe<Array<Maybe<Scalars['String']>>>;
  results: Array<CourseWaitlistSendAvailabilityEmailUserOutPut>;
  success: Scalars['Boolean'];
};

export type CourseWaitlistSendAvailabilityEmailUserOutPut = {
  __typename?: 'CourseWaitlistSendAvailabilityEmailUserOutPut';
  sent: Scalars['Boolean'];
  userFullname: Scalars['String'];
  userId: Scalars['String'];
};

/** input type for updating data in table "course_waitlist" */
export type CourseWaitlistSetInput = {
  availabilityNotificationSent?: InputMaybe<Scalars['Boolean']>;
  courseSlug?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  createdOnBehalfOfId?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  deletedOnBehalfOfId?: InputMaybe<Scalars['uuid']>;
  desiredTimezone?: InputMaybe<Scalars['String']>;
  desiredUrgency?: InputMaybe<CourseWaitlistDesiredUrgencyTypeEnum>;
  id?: InputMaybe<Scalars['uuid']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "course_waitlist" */
export type CourseWaitlistStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CourseWaitlistStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CourseWaitlistStreamCursorValueInput = {
  availabilityNotificationSent?: InputMaybe<Scalars['Boolean']>;
  courseSlug?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  createdOnBehalfOfId?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  deletedOnBehalfOfId?: InputMaybe<Scalars['uuid']>;
  desiredTimezone?: InputMaybe<Scalars['String']>;
  desiredUrgency?: InputMaybe<CourseWaitlistDesiredUrgencyTypeEnum>;
  id?: InputMaybe<Scalars['uuid']>;
  isNotDeleted?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "course_waitlist" */
export enum CourseWaitlistUpdateColumn {
  /** column name */
  availabilityNotificationSent = 'availabilityNotificationSent',
  /** column name */
  courseSlug = 'courseSlug',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  createdOnBehalfOfId = 'createdOnBehalfOfId',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  deletedOnBehalfOfId = 'deletedOnBehalfOfId',
  /** column name */
  desiredTimezone = 'desiredTimezone',
  /** column name */
  desiredUrgency = 'desiredUrgency',
  /** column name */
  id = 'id',
  /** column name */
  organizationId = 'organizationId',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById',
  /** column name */
  userId = 'userId'
}

export type CourseWaitlistUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CourseWaitlistSetInput>;
  /** filter the rows which have to be updated */
  where: CourseWaitlistBoolExp;
};

/** columns and relationships of "courses" */
export type Courses = {
  __typename?: 'Courses';
  applicationRich?: Maybe<Scalars['String']>;
  audience?: Maybe<Scalars['String']>;
  /** A computed field, executes function "available_cohorts" */
  availableCohorts?: Maybe<Array<Cohorts>>;
  backgroundImageUrl?: Maybe<Scalars['String']>;
  bannerUrl?: Maybe<Scalars['String']>;
  /** An array relationship */
  catalogs: Array<CatalogCourses>;
  /** An aggregate relationship */
  catalogsAggregate: CatalogCoursesAggregate;
  certificateBackgroundUrl?: Maybe<Scalars['String']>;
  certificateTemplate?: Maybe<Scalars['json']>;
  changeDescription?: Maybe<Scalars['String']>;
  /** An array relationship */
  classes: Array<Classes>;
  /** An aggregate relationship */
  classesAggregate: ClassesAggregate;
  /** An array relationship */
  coaches: Array<CourseCoaches>;
  /** An aggregate relationship */
  coachesAggregate: CourseCoachesAggregate;
  code?: Maybe<Scalars['String']>;
  /** An array relationship */
  cohorts: Array<Cohorts>;
  /** An aggregate relationship */
  cohortsAggregate: CohortsAggregate;
  /** An object relationship */
  course_publish_status_enum?: Maybe<CoursePublishStatus>;
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  enrollmentStatus?: Maybe<CourseEnrollmentStatusEnum>;
  frameworkDescription?: Maybe<Scalars['String']>;
  frameworkHighlight?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  impactAssessment?: Maybe<ImpactAssessments>;
  impactAssessmentId?: Maybe<Scalars['uuid']>;
  impactDescriptionList?: Maybe<Scalars['json']>;
  impactDescriptionRich?: Maybe<Scalars['String']>;
  impactRich?: Maybe<Scalars['String']>;
  is_permitted?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "course_latest_published_at" */
  latestPublishedAt?: Maybe<Scalars['timestamp']>;
  /** A computed field, executes function "course_latest_version" */
  latestVersion?: Maybe<Scalars['numeric']>;
  longDescriptionRich?: Maybe<Scalars['String']>;
  /** An object relationship */
  manager?: Maybe<Users>;
  managerId?: Maybe<Scalars['uuid']>;
  name: Scalars['String'];
  /** The start date of the next upcoming cohort for this course */
  nextCohortStartDate?: Maybe<Scalars['timestamp']>;
  /** A computed field, executes function "next_cohort_start_time_of_day" */
  nextCohortStartTimeOfDay?: Maybe<Scalars['timestamp']>;
  postCourseSurveyFormId?: Maybe<Scalars['String']>;
  practicedRich?: Maybe<Scalars['String']>;
  preCourseSurveyFormId?: Maybe<Scalars['String']>;
  promoVideoUrl?: Maybe<Scalars['String']>;
  publishStatus?: Maybe<CoursePublishStatusEnum>;
  publishedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  publishedBy?: Maybe<Users>;
  publishedById?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  relatedCourses: Array<RelatedCourses>;
  /** An aggregate relationship */
  relatedCoursesAggregate: RelatedCoursesAggregate;
  /** An array relationship */
  resources: Array<CoursesResourcesRel>;
  /** An aggregate relationship */
  resourcesAggregate: CoursesResourcesRelAggregate;
  /** An object relationship */
  school?: Maybe<Schools>;
  schoolId?: Maybe<Scalars['uuid']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  /** An array relationship */
  tags: Array<CoursesTagsRel>;
  /** An aggregate relationship */
  tagsAggregate: CoursesTagsRelAggregate;
  thumbnailCardUrl?: Maybe<Scalars['String']>;
  thumbnailListUrl?: Maybe<Scalars['String']>;
  timeLengthInClasses?: Maybe<Scalars['Int']>;
  timeLengthInWeeks?: Maybe<Scalars['Int']>;
  type: CourseTypeEnum;
  updatedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  updatedBy?: Maybe<Users>;
  updatedById?: Maybe<Scalars['uuid']>;
  version?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "courses" */
export type CoursesAvailableCohortsArgs = {
  distinctOn?: InputMaybe<Array<CohortsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortsOrderBy>>;
  where?: InputMaybe<CohortsBoolExp>;
};

/** columns and relationships of "courses" */
export type CoursesCatalogsArgs = {
  distinctOn?: InputMaybe<Array<CatalogCoursesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CatalogCoursesOrderBy>>;
  where?: InputMaybe<CatalogCoursesBoolExp>;
};

/** columns and relationships of "courses" */
export type CoursesCatalogsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CatalogCoursesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CatalogCoursesOrderBy>>;
  where?: InputMaybe<CatalogCoursesBoolExp>;
};

/** columns and relationships of "courses" */
export type CoursesCertificateTemplateArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "courses" */
export type CoursesClassesArgs = {
  distinctOn?: InputMaybe<Array<ClassesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassesOrderBy>>;
  where?: InputMaybe<ClassesBoolExp>;
};

/** columns and relationships of "courses" */
export type CoursesClassesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassesOrderBy>>;
  where?: InputMaybe<ClassesBoolExp>;
};

/** columns and relationships of "courses" */
export type CoursesCoachesArgs = {
  distinctOn?: InputMaybe<Array<CourseCoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourseCoachesOrderBy>>;
  where?: InputMaybe<CourseCoachesBoolExp>;
};

/** columns and relationships of "courses" */
export type CoursesCoachesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CourseCoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourseCoachesOrderBy>>;
  where?: InputMaybe<CourseCoachesBoolExp>;
};

/** columns and relationships of "courses" */
export type CoursesCohortsArgs = {
  distinctOn?: InputMaybe<Array<CohortsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortsOrderBy>>;
  where?: InputMaybe<CohortsBoolExp>;
};

/** columns and relationships of "courses" */
export type CoursesCohortsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CohortsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortsOrderBy>>;
  where?: InputMaybe<CohortsBoolExp>;
};

/** columns and relationships of "courses" */
export type CoursesImpactDescriptionListArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "courses" */
export type CoursesRelatedCoursesArgs = {
  distinctOn?: InputMaybe<Array<RelatedCoursesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RelatedCoursesOrderBy>>;
  where?: InputMaybe<RelatedCoursesBoolExp>;
};

/** columns and relationships of "courses" */
export type CoursesRelatedCoursesAggregateArgs = {
  distinctOn?: InputMaybe<Array<RelatedCoursesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RelatedCoursesOrderBy>>;
  where?: InputMaybe<RelatedCoursesBoolExp>;
};

/** columns and relationships of "courses" */
export type CoursesResourcesArgs = {
  distinctOn?: InputMaybe<Array<CoursesResourcesRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesResourcesRelOrderBy>>;
  where?: InputMaybe<CoursesResourcesRelBoolExp>;
};

/** columns and relationships of "courses" */
export type CoursesResourcesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CoursesResourcesRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesResourcesRelOrderBy>>;
  where?: InputMaybe<CoursesResourcesRelBoolExp>;
};

/** columns and relationships of "courses" */
export type CoursesTagsArgs = {
  distinctOn?: InputMaybe<Array<CoursesTagsRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesTagsRelOrderBy>>;
  where?: InputMaybe<CoursesTagsRelBoolExp>;
};

/** columns and relationships of "courses" */
export type CoursesTagsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CoursesTagsRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesTagsRelOrderBy>>;
  where?: InputMaybe<CoursesTagsRelBoolExp>;
};

/** aggregated selection of "courses" */
export type CoursesAggregate = {
  __typename?: 'CoursesAggregate';
  aggregate?: Maybe<CoursesAggregateFields>;
  nodes: Array<Courses>;
};

export type CoursesAggregateBoolExp = {
  count?: InputMaybe<CoursesAggregateBoolExpCount>;
};

/** aggregate fields of "courses" */
export type CoursesAggregateFields = {
  __typename?: 'CoursesAggregateFields';
  avg?: Maybe<CoursesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CoursesMaxFields>;
  min?: Maybe<CoursesMinFields>;
  stddev?: Maybe<CoursesStddevFields>;
  stddevPop?: Maybe<CoursesStddevPopFields>;
  stddevSamp?: Maybe<CoursesStddevSampFields>;
  sum?: Maybe<CoursesSumFields>;
  varPop?: Maybe<CoursesVarPopFields>;
  varSamp?: Maybe<CoursesVarSampFields>;
  variance?: Maybe<CoursesVarianceFields>;
};

/** aggregate fields of "courses" */
export type CoursesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CoursesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "courses" */
export type CoursesAggregateOrderBy = {
  avg?: InputMaybe<CoursesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CoursesMaxOrderBy>;
  min?: InputMaybe<CoursesMinOrderBy>;
  stddev?: InputMaybe<CoursesStddevOrderBy>;
  stddevPop?: InputMaybe<CoursesStddevPopOrderBy>;
  stddevSamp?: InputMaybe<CoursesStddevSampOrderBy>;
  sum?: InputMaybe<CoursesSumOrderBy>;
  varPop?: InputMaybe<CoursesVarPopOrderBy>;
  varSamp?: InputMaybe<CoursesVarSampOrderBy>;
  variance?: InputMaybe<CoursesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "courses" */
export type CoursesArrRelInsertInput = {
  data: Array<CoursesInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<CoursesOnConflict>;
};

/** aggregate avg on columns */
export type CoursesAvgFields = {
  __typename?: 'CoursesAvgFields';
  /** A computed field, executes function "course_latest_version" */
  latestVersion?: Maybe<Scalars['numeric']>;
  timeLengthInClasses?: Maybe<Scalars['Float']>;
  timeLengthInWeeks?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "courses" */
export type CoursesAvgOrderBy = {
  timeLengthInClasses?: InputMaybe<OrderBy>;
  timeLengthInWeeks?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "courses". All fields are combined with a logical 'AND'. */
export type CoursesBoolExp = {
  _and?: InputMaybe<Array<CoursesBoolExp>>;
  _not?: InputMaybe<CoursesBoolExp>;
  _or?: InputMaybe<Array<CoursesBoolExp>>;
  applicationRich?: InputMaybe<StringComparisonExp>;
  audience?: InputMaybe<StringComparisonExp>;
  availableCohorts?: InputMaybe<CohortsBoolExp>;
  backgroundImageUrl?: InputMaybe<StringComparisonExp>;
  bannerUrl?: InputMaybe<StringComparisonExp>;
  catalogs?: InputMaybe<CatalogCoursesBoolExp>;
  catalogsAggregate?: InputMaybe<CatalogCoursesAggregateBoolExp>;
  certificateBackgroundUrl?: InputMaybe<StringComparisonExp>;
  certificateTemplate?: InputMaybe<JsonComparisonExp>;
  changeDescription?: InputMaybe<StringComparisonExp>;
  classes?: InputMaybe<ClassesBoolExp>;
  classesAggregate?: InputMaybe<ClassesAggregateBoolExp>;
  coaches?: InputMaybe<CourseCoachesBoolExp>;
  coachesAggregate?: InputMaybe<CourseCoachesAggregateBoolExp>;
  code?: InputMaybe<StringComparisonExp>;
  cohorts?: InputMaybe<CohortsBoolExp>;
  cohortsAggregate?: InputMaybe<CohortsAggregateBoolExp>;
  course_publish_status_enum?: InputMaybe<CoursePublishStatusBoolExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  enrollmentStatus?: InputMaybe<CourseEnrollmentStatusEnumComparisonExp>;
  frameworkDescription?: InputMaybe<StringComparisonExp>;
  frameworkHighlight?: InputMaybe<StringComparisonExp>;
  iconUrl?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  impactAssessment?: InputMaybe<ImpactAssessmentsBoolExp>;
  impactAssessmentId?: InputMaybe<UuidComparisonExp>;
  impactDescriptionList?: InputMaybe<JsonComparisonExp>;
  impactDescriptionRich?: InputMaybe<StringComparisonExp>;
  impactRich?: InputMaybe<StringComparisonExp>;
  is_permitted?: InputMaybe<BooleanComparisonExp>;
  latestPublishedAt?: InputMaybe<TimestampComparisonExp>;
  latestVersion?: InputMaybe<NumericComparisonExp>;
  longDescriptionRich?: InputMaybe<StringComparisonExp>;
  manager?: InputMaybe<UsersBoolExp>;
  managerId?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  nextCohortStartDate?: InputMaybe<TimestampComparisonExp>;
  nextCohortStartTimeOfDay?: InputMaybe<TimestampComparisonExp>;
  postCourseSurveyFormId?: InputMaybe<StringComparisonExp>;
  practicedRich?: InputMaybe<StringComparisonExp>;
  preCourseSurveyFormId?: InputMaybe<StringComparisonExp>;
  promoVideoUrl?: InputMaybe<StringComparisonExp>;
  publishStatus?: InputMaybe<CoursePublishStatusEnumComparisonExp>;
  publishedAt?: InputMaybe<TimestampComparisonExp>;
  publishedBy?: InputMaybe<UsersBoolExp>;
  publishedById?: InputMaybe<UuidComparisonExp>;
  relatedCourses?: InputMaybe<RelatedCoursesBoolExp>;
  relatedCoursesAggregate?: InputMaybe<RelatedCoursesAggregateBoolExp>;
  resources?: InputMaybe<CoursesResourcesRelBoolExp>;
  resourcesAggregate?: InputMaybe<CoursesResourcesRelAggregateBoolExp>;
  school?: InputMaybe<SchoolsBoolExp>;
  schoolId?: InputMaybe<UuidComparisonExp>;
  shortDescription?: InputMaybe<StringComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  tags?: InputMaybe<CoursesTagsRelBoolExp>;
  tagsAggregate?: InputMaybe<CoursesTagsRelAggregateBoolExp>;
  thumbnailCardUrl?: InputMaybe<StringComparisonExp>;
  thumbnailListUrl?: InputMaybe<StringComparisonExp>;
  timeLengthInClasses?: InputMaybe<IntComparisonExp>;
  timeLengthInWeeks?: InputMaybe<IntComparisonExp>;
  type?: InputMaybe<CourseTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  updatedBy?: InputMaybe<UsersBoolExp>;
  updatedById?: InputMaybe<UuidComparisonExp>;
  version?: InputMaybe<IntComparisonExp>;
};

/** columns and relationships of "courses_by_plan" */
export type CoursesByPlan = {
  __typename?: 'CoursesByPlan';
  backgroundImageUrl?: Maybe<Scalars['String']>;
  bannerUrl?: Maybe<Scalars['String']>;
  /** An array relationship */
  coaches: Array<CourseCoaches>;
  /** An aggregate relationship */
  coachesAggregate: CourseCoachesAggregate;
  cohortId?: Maybe<Scalars['uuid']>;
  cohortType?: Maybe<Scalars['String']>;
  cohortTypeBucket?: Maybe<Scalars['String']>;
  /** An array relationship */
  cohorts: Array<Cohorts>;
  /** An aggregate relationship */
  cohortsAggregate: CohortsAggregate;
  courseType?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['timestamp']>;
  enrollmentStatus?: Maybe<Scalars['String']>;
  firstClassStartTime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "courses_by_plan_permitted" */
  is_permitted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  nextCohortStartDate?: Maybe<Scalars['timestamp']>;
  nextCohortStartTimeOfDay?: Maybe<Scalars['timestamp']>;
  publishStatus?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  school?: Maybe<Schools>;
  schoolId?: Maybe<Scalars['uuid']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['timestamp']>;
  /** An array relationship */
  tags: Array<CoursesTagsRel>;
  /** An aggregate relationship */
  tagsAggregate: CoursesTagsRelAggregate;
  thumbnailCardUrl?: Maybe<Scalars['String']>;
  timeLengthInClasses?: Maybe<Scalars['Int']>;
  timeLengthInWeeks?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "courses_by_plan" */
export type CoursesByPlanCoachesArgs = {
  distinctOn?: InputMaybe<Array<CourseCoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourseCoachesOrderBy>>;
  where?: InputMaybe<CourseCoachesBoolExp>;
};

/** columns and relationships of "courses_by_plan" */
export type CoursesByPlanCoachesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CourseCoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourseCoachesOrderBy>>;
  where?: InputMaybe<CourseCoachesBoolExp>;
};

/** columns and relationships of "courses_by_plan" */
export type CoursesByPlanCohortsArgs = {
  distinctOn?: InputMaybe<Array<CohortsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortsOrderBy>>;
  where?: InputMaybe<CohortsBoolExp>;
};

/** columns and relationships of "courses_by_plan" */
export type CoursesByPlanCohortsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CohortsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortsOrderBy>>;
  where?: InputMaybe<CohortsBoolExp>;
};

/** columns and relationships of "courses_by_plan" */
export type CoursesByPlanTagsArgs = {
  distinctOn?: InputMaybe<Array<CoursesTagsRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesTagsRelOrderBy>>;
  where?: InputMaybe<CoursesTagsRelBoolExp>;
};

/** columns and relationships of "courses_by_plan" */
export type CoursesByPlanTagsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CoursesTagsRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesTagsRelOrderBy>>;
  where?: InputMaybe<CoursesTagsRelBoolExp>;
};

/** aggregated selection of "courses_by_plan" */
export type CoursesByPlanAggregate = {
  __typename?: 'CoursesByPlanAggregate';
  aggregate?: Maybe<CoursesByPlanAggregateFields>;
  nodes: Array<CoursesByPlan>;
};

/** aggregate fields of "courses_by_plan" */
export type CoursesByPlanAggregateFields = {
  __typename?: 'CoursesByPlanAggregateFields';
  avg?: Maybe<CoursesByPlanAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CoursesByPlanMaxFields>;
  min?: Maybe<CoursesByPlanMinFields>;
  stddev?: Maybe<CoursesByPlanStddevFields>;
  stddevPop?: Maybe<CoursesByPlanStddevPopFields>;
  stddevSamp?: Maybe<CoursesByPlanStddevSampFields>;
  sum?: Maybe<CoursesByPlanSumFields>;
  varPop?: Maybe<CoursesByPlanVarPopFields>;
  varSamp?: Maybe<CoursesByPlanVarSampFields>;
  variance?: Maybe<CoursesByPlanVarianceFields>;
};

/** aggregate fields of "courses_by_plan" */
export type CoursesByPlanAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CoursesByPlanSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CoursesByPlanAvgFields = {
  __typename?: 'CoursesByPlanAvgFields';
  timeLengthInClasses?: Maybe<Scalars['Float']>;
  timeLengthInWeeks?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "courses_by_plan". All fields are combined with a logical 'AND'. */
export type CoursesByPlanBoolExp = {
  _and?: InputMaybe<Array<CoursesByPlanBoolExp>>;
  _not?: InputMaybe<CoursesByPlanBoolExp>;
  _or?: InputMaybe<Array<CoursesByPlanBoolExp>>;
  backgroundImageUrl?: InputMaybe<StringComparisonExp>;
  bannerUrl?: InputMaybe<StringComparisonExp>;
  coaches?: InputMaybe<CourseCoachesBoolExp>;
  coachesAggregate?: InputMaybe<CourseCoachesAggregateBoolExp>;
  cohortId?: InputMaybe<UuidComparisonExp>;
  cohortType?: InputMaybe<StringComparisonExp>;
  cohortTypeBucket?: InputMaybe<StringComparisonExp>;
  cohorts?: InputMaybe<CohortsBoolExp>;
  cohortsAggregate?: InputMaybe<CohortsAggregateBoolExp>;
  courseType?: InputMaybe<StringComparisonExp>;
  endDate?: InputMaybe<TimestampComparisonExp>;
  enrollmentStatus?: InputMaybe<StringComparisonExp>;
  firstClassStartTime?: InputMaybe<TimestampComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  is_permitted?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  nextCohortStartDate?: InputMaybe<TimestampComparisonExp>;
  nextCohortStartTimeOfDay?: InputMaybe<TimestampComparisonExp>;
  publishStatus?: InputMaybe<StringComparisonExp>;
  publishedAt?: InputMaybe<TimestampComparisonExp>;
  school?: InputMaybe<SchoolsBoolExp>;
  schoolId?: InputMaybe<UuidComparisonExp>;
  shortDescription?: InputMaybe<StringComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  startDate?: InputMaybe<TimestampComparisonExp>;
  tags?: InputMaybe<CoursesTagsRelBoolExp>;
  tagsAggregate?: InputMaybe<CoursesTagsRelAggregateBoolExp>;
  thumbnailCardUrl?: InputMaybe<StringComparisonExp>;
  timeLengthInClasses?: InputMaybe<IntComparisonExp>;
  timeLengthInWeeks?: InputMaybe<IntComparisonExp>;
  version?: InputMaybe<IntComparisonExp>;
};

/** aggregate max on columns */
export type CoursesByPlanMaxFields = {
  __typename?: 'CoursesByPlanMaxFields';
  backgroundImageUrl?: Maybe<Scalars['String']>;
  bannerUrl?: Maybe<Scalars['String']>;
  cohortId?: Maybe<Scalars['uuid']>;
  cohortType?: Maybe<Scalars['String']>;
  cohortTypeBucket?: Maybe<Scalars['String']>;
  courseType?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['timestamp']>;
  enrollmentStatus?: Maybe<Scalars['String']>;
  firstClassStartTime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  nextCohortStartDate?: Maybe<Scalars['timestamp']>;
  nextCohortStartTimeOfDay?: Maybe<Scalars['timestamp']>;
  publishStatus?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['timestamp']>;
  schoolId?: Maybe<Scalars['uuid']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['timestamp']>;
  thumbnailCardUrl?: Maybe<Scalars['String']>;
  timeLengthInClasses?: Maybe<Scalars['Int']>;
  timeLengthInWeeks?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type CoursesByPlanMinFields = {
  __typename?: 'CoursesByPlanMinFields';
  backgroundImageUrl?: Maybe<Scalars['String']>;
  bannerUrl?: Maybe<Scalars['String']>;
  cohortId?: Maybe<Scalars['uuid']>;
  cohortType?: Maybe<Scalars['String']>;
  cohortTypeBucket?: Maybe<Scalars['String']>;
  courseType?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['timestamp']>;
  enrollmentStatus?: Maybe<Scalars['String']>;
  firstClassStartTime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  nextCohortStartDate?: Maybe<Scalars['timestamp']>;
  nextCohortStartTimeOfDay?: Maybe<Scalars['timestamp']>;
  publishStatus?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['timestamp']>;
  schoolId?: Maybe<Scalars['uuid']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['timestamp']>;
  thumbnailCardUrl?: Maybe<Scalars['String']>;
  timeLengthInClasses?: Maybe<Scalars['Int']>;
  timeLengthInWeeks?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "courses_by_plan". */
export type CoursesByPlanOrderBy = {
  backgroundImageUrl?: InputMaybe<OrderBy>;
  bannerUrl?: InputMaybe<OrderBy>;
  coachesAggregate?: InputMaybe<CourseCoachesAggregateOrderBy>;
  cohortId?: InputMaybe<OrderBy>;
  cohortType?: InputMaybe<OrderBy>;
  cohortTypeBucket?: InputMaybe<OrderBy>;
  cohortsAggregate?: InputMaybe<CohortsAggregateOrderBy>;
  courseType?: InputMaybe<OrderBy>;
  endDate?: InputMaybe<OrderBy>;
  enrollmentStatus?: InputMaybe<OrderBy>;
  firstClassStartTime?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  is_permitted?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nextCohortStartDate?: InputMaybe<OrderBy>;
  nextCohortStartTimeOfDay?: InputMaybe<OrderBy>;
  publishStatus?: InputMaybe<OrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  school?: InputMaybe<SchoolsOrderBy>;
  schoolId?: InputMaybe<OrderBy>;
  shortDescription?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<OrderBy>;
  tagsAggregate?: InputMaybe<CoursesTagsRelAggregateOrderBy>;
  thumbnailCardUrl?: InputMaybe<OrderBy>;
  timeLengthInClasses?: InputMaybe<OrderBy>;
  timeLengthInWeeks?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** select columns of table "courses_by_plan" */
export enum CoursesByPlanSelectColumn {
  /** column name */
  backgroundImageUrl = 'backgroundImageUrl',
  /** column name */
  bannerUrl = 'bannerUrl',
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  cohortType = 'cohortType',
  /** column name */
  cohortTypeBucket = 'cohortTypeBucket',
  /** column name */
  courseType = 'courseType',
  /** column name */
  endDate = 'endDate',
  /** column name */
  enrollmentStatus = 'enrollmentStatus',
  /** column name */
  firstClassStartTime = 'firstClassStartTime',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  nextCohortStartDate = 'nextCohortStartDate',
  /** column name */
  nextCohortStartTimeOfDay = 'nextCohortStartTimeOfDay',
  /** column name */
  publishStatus = 'publishStatus',
  /** column name */
  publishedAt = 'publishedAt',
  /** column name */
  schoolId = 'schoolId',
  /** column name */
  shortDescription = 'shortDescription',
  /** column name */
  slug = 'slug',
  /** column name */
  startDate = 'startDate',
  /** column name */
  thumbnailCardUrl = 'thumbnailCardUrl',
  /** column name */
  timeLengthInClasses = 'timeLengthInClasses',
  /** column name */
  timeLengthInWeeks = 'timeLengthInWeeks',
  /** column name */
  version = 'version'
}

/** aggregate stddev on columns */
export type CoursesByPlanStddevFields = {
  __typename?: 'CoursesByPlanStddevFields';
  timeLengthInClasses?: Maybe<Scalars['Float']>;
  timeLengthInWeeks?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type CoursesByPlanStddevPopFields = {
  __typename?: 'CoursesByPlanStddevPopFields';
  timeLengthInClasses?: Maybe<Scalars['Float']>;
  timeLengthInWeeks?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type CoursesByPlanStddevSampFields = {
  __typename?: 'CoursesByPlanStddevSampFields';
  timeLengthInClasses?: Maybe<Scalars['Float']>;
  timeLengthInWeeks?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "courses_by_plan" */
export type CoursesByPlanStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CoursesByPlanStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CoursesByPlanStreamCursorValueInput = {
  backgroundImageUrl?: InputMaybe<Scalars['String']>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  cohortType?: InputMaybe<Scalars['String']>;
  cohortTypeBucket?: InputMaybe<Scalars['String']>;
  courseType?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['timestamp']>;
  enrollmentStatus?: InputMaybe<Scalars['String']>;
  firstClassStartTime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  nextCohortStartDate?: InputMaybe<Scalars['timestamp']>;
  nextCohortStartTimeOfDay?: InputMaybe<Scalars['timestamp']>;
  publishStatus?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['timestamp']>;
  schoolId?: InputMaybe<Scalars['uuid']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['timestamp']>;
  thumbnailCardUrl?: InputMaybe<Scalars['String']>;
  timeLengthInClasses?: InputMaybe<Scalars['Int']>;
  timeLengthInWeeks?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type CoursesByPlanSumFields = {
  __typename?: 'CoursesByPlanSumFields';
  timeLengthInClasses?: Maybe<Scalars['Int']>;
  timeLengthInWeeks?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
};

/** aggregate varPop on columns */
export type CoursesByPlanVarPopFields = {
  __typename?: 'CoursesByPlanVarPopFields';
  timeLengthInClasses?: Maybe<Scalars['Float']>;
  timeLengthInWeeks?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type CoursesByPlanVarSampFields = {
  __typename?: 'CoursesByPlanVarSampFields';
  timeLengthInClasses?: Maybe<Scalars['Float']>;
  timeLengthInWeeks?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CoursesByPlanVarianceFields = {
  __typename?: 'CoursesByPlanVarianceFields';
  timeLengthInClasses?: Maybe<Scalars['Float']>;
  timeLengthInWeeks?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** unique or primary key constraints on table "courses" */
export enum CoursesConstraint {
  /** unique or primary key constraint on columns "id" */
  courses_pk = 'courses_pk'
}

/** input type for incrementing numeric columns in table "courses" */
export type CoursesIncInput = {
  timeLengthInClasses?: InputMaybe<Scalars['Int']>;
  timeLengthInWeeks?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "courses" */
export type CoursesInsertInput = {
  applicationRich?: InputMaybe<Scalars['String']>;
  audience?: InputMaybe<Scalars['String']>;
  backgroundImageUrl?: InputMaybe<Scalars['String']>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  catalogs?: InputMaybe<CatalogCoursesArrRelInsertInput>;
  certificateBackgroundUrl?: InputMaybe<Scalars['String']>;
  certificateTemplate?: InputMaybe<Scalars['json']>;
  changeDescription?: InputMaybe<Scalars['String']>;
  classes?: InputMaybe<ClassesArrRelInsertInput>;
  coaches?: InputMaybe<CourseCoachesArrRelInsertInput>;
  code?: InputMaybe<Scalars['String']>;
  cohorts?: InputMaybe<CohortsArrRelInsertInput>;
  course_publish_status_enum?: InputMaybe<CoursePublishStatusObjRelInsertInput>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  enrollmentStatus?: InputMaybe<CourseEnrollmentStatusEnum>;
  frameworkDescription?: InputMaybe<Scalars['String']>;
  frameworkHighlight?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  impactAssessment?: InputMaybe<ImpactAssessmentsObjRelInsertInput>;
  impactAssessmentId?: InputMaybe<Scalars['uuid']>;
  impactDescriptionList?: InputMaybe<Scalars['json']>;
  impactDescriptionRich?: InputMaybe<Scalars['String']>;
  impactRich?: InputMaybe<Scalars['String']>;
  longDescriptionRich?: InputMaybe<Scalars['String']>;
  manager?: InputMaybe<UsersObjRelInsertInput>;
  managerId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  postCourseSurveyFormId?: InputMaybe<Scalars['String']>;
  practicedRich?: InputMaybe<Scalars['String']>;
  preCourseSurveyFormId?: InputMaybe<Scalars['String']>;
  promoVideoUrl?: InputMaybe<Scalars['String']>;
  publishStatus?: InputMaybe<CoursePublishStatusEnum>;
  publishedAt?: InputMaybe<Scalars['timestamp']>;
  publishedBy?: InputMaybe<UsersObjRelInsertInput>;
  publishedById?: InputMaybe<Scalars['uuid']>;
  relatedCourses?: InputMaybe<RelatedCoursesArrRelInsertInput>;
  resources?: InputMaybe<CoursesResourcesRelArrRelInsertInput>;
  school?: InputMaybe<SchoolsObjRelInsertInput>;
  schoolId?: InputMaybe<Scalars['uuid']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<CoursesTagsRelArrRelInsertInput>;
  thumbnailCardUrl?: InputMaybe<Scalars['String']>;
  thumbnailListUrl?: InputMaybe<Scalars['String']>;
  timeLengthInClasses?: InputMaybe<Scalars['Int']>;
  timeLengthInWeeks?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<CourseTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<UsersObjRelInsertInput>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type CoursesMaxFields = {
  __typename?: 'CoursesMaxFields';
  applicationRich?: Maybe<Scalars['String']>;
  audience?: Maybe<Scalars['String']>;
  backgroundImageUrl?: Maybe<Scalars['String']>;
  bannerUrl?: Maybe<Scalars['String']>;
  certificateBackgroundUrl?: Maybe<Scalars['String']>;
  changeDescription?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  frameworkDescription?: Maybe<Scalars['String']>;
  frameworkHighlight?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  impactAssessmentId?: Maybe<Scalars['uuid']>;
  impactDescriptionRich?: Maybe<Scalars['String']>;
  impactRich?: Maybe<Scalars['String']>;
  /** A computed field, executes function "course_latest_published_at" */
  latestPublishedAt?: Maybe<Scalars['timestamp']>;
  /** A computed field, executes function "course_latest_version" */
  latestVersion?: Maybe<Scalars['numeric']>;
  longDescriptionRich?: Maybe<Scalars['String']>;
  managerId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  /** The start date of the next upcoming cohort for this course */
  nextCohortStartDate?: Maybe<Scalars['timestamp']>;
  /** A computed field, executes function "next_cohort_start_time_of_day" */
  nextCohortStartTimeOfDay?: Maybe<Scalars['timestamp']>;
  postCourseSurveyFormId?: Maybe<Scalars['String']>;
  practicedRich?: Maybe<Scalars['String']>;
  preCourseSurveyFormId?: Maybe<Scalars['String']>;
  promoVideoUrl?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['timestamp']>;
  publishedById?: Maybe<Scalars['uuid']>;
  schoolId?: Maybe<Scalars['uuid']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  thumbnailCardUrl?: Maybe<Scalars['String']>;
  thumbnailListUrl?: Maybe<Scalars['String']>;
  timeLengthInClasses?: Maybe<Scalars['Int']>;
  timeLengthInWeeks?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
  version?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "courses" */
export type CoursesMaxOrderBy = {
  applicationRich?: InputMaybe<OrderBy>;
  audience?: InputMaybe<OrderBy>;
  backgroundImageUrl?: InputMaybe<OrderBy>;
  bannerUrl?: InputMaybe<OrderBy>;
  certificateBackgroundUrl?: InputMaybe<OrderBy>;
  changeDescription?: InputMaybe<OrderBy>;
  code?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  frameworkDescription?: InputMaybe<OrderBy>;
  frameworkHighlight?: InputMaybe<OrderBy>;
  iconUrl?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  impactAssessmentId?: InputMaybe<OrderBy>;
  impactDescriptionRich?: InputMaybe<OrderBy>;
  impactRich?: InputMaybe<OrderBy>;
  longDescriptionRich?: InputMaybe<OrderBy>;
  managerId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  postCourseSurveyFormId?: InputMaybe<OrderBy>;
  practicedRich?: InputMaybe<OrderBy>;
  preCourseSurveyFormId?: InputMaybe<OrderBy>;
  promoVideoUrl?: InputMaybe<OrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  publishedById?: InputMaybe<OrderBy>;
  schoolId?: InputMaybe<OrderBy>;
  shortDescription?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  thumbnailCardUrl?: InputMaybe<OrderBy>;
  thumbnailListUrl?: InputMaybe<OrderBy>;
  timeLengthInClasses?: InputMaybe<OrderBy>;
  timeLengthInWeeks?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CoursesMinFields = {
  __typename?: 'CoursesMinFields';
  applicationRich?: Maybe<Scalars['String']>;
  audience?: Maybe<Scalars['String']>;
  backgroundImageUrl?: Maybe<Scalars['String']>;
  bannerUrl?: Maybe<Scalars['String']>;
  certificateBackgroundUrl?: Maybe<Scalars['String']>;
  changeDescription?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  frameworkDescription?: Maybe<Scalars['String']>;
  frameworkHighlight?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  impactAssessmentId?: Maybe<Scalars['uuid']>;
  impactDescriptionRich?: Maybe<Scalars['String']>;
  impactRich?: Maybe<Scalars['String']>;
  /** A computed field, executes function "course_latest_published_at" */
  latestPublishedAt?: Maybe<Scalars['timestamp']>;
  /** A computed field, executes function "course_latest_version" */
  latestVersion?: Maybe<Scalars['numeric']>;
  longDescriptionRich?: Maybe<Scalars['String']>;
  managerId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  /** The start date of the next upcoming cohort for this course */
  nextCohortStartDate?: Maybe<Scalars['timestamp']>;
  /** A computed field, executes function "next_cohort_start_time_of_day" */
  nextCohortStartTimeOfDay?: Maybe<Scalars['timestamp']>;
  postCourseSurveyFormId?: Maybe<Scalars['String']>;
  practicedRich?: Maybe<Scalars['String']>;
  preCourseSurveyFormId?: Maybe<Scalars['String']>;
  promoVideoUrl?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['timestamp']>;
  publishedById?: Maybe<Scalars['uuid']>;
  schoolId?: Maybe<Scalars['uuid']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  thumbnailCardUrl?: Maybe<Scalars['String']>;
  thumbnailListUrl?: Maybe<Scalars['String']>;
  timeLengthInClasses?: Maybe<Scalars['Int']>;
  timeLengthInWeeks?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
  version?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "courses" */
export type CoursesMinOrderBy = {
  applicationRich?: InputMaybe<OrderBy>;
  audience?: InputMaybe<OrderBy>;
  backgroundImageUrl?: InputMaybe<OrderBy>;
  bannerUrl?: InputMaybe<OrderBy>;
  certificateBackgroundUrl?: InputMaybe<OrderBy>;
  changeDescription?: InputMaybe<OrderBy>;
  code?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  frameworkDescription?: InputMaybe<OrderBy>;
  frameworkHighlight?: InputMaybe<OrderBy>;
  iconUrl?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  impactAssessmentId?: InputMaybe<OrderBy>;
  impactDescriptionRich?: InputMaybe<OrderBy>;
  impactRich?: InputMaybe<OrderBy>;
  longDescriptionRich?: InputMaybe<OrderBy>;
  managerId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  postCourseSurveyFormId?: InputMaybe<OrderBy>;
  practicedRich?: InputMaybe<OrderBy>;
  preCourseSurveyFormId?: InputMaybe<OrderBy>;
  promoVideoUrl?: InputMaybe<OrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  publishedById?: InputMaybe<OrderBy>;
  schoolId?: InputMaybe<OrderBy>;
  shortDescription?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  thumbnailCardUrl?: InputMaybe<OrderBy>;
  thumbnailListUrl?: InputMaybe<OrderBy>;
  timeLengthInClasses?: InputMaybe<OrderBy>;
  timeLengthInWeeks?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "courses" */
export type CoursesMutationResponse = {
  __typename?: 'CoursesMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Courses>;
};

/** input type for inserting object relation for remote table "courses" */
export type CoursesObjRelInsertInput = {
  data: CoursesInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CoursesOnConflict>;
};

/** on_conflict condition type for table "courses" */
export type CoursesOnConflict = {
  constraint: CoursesConstraint;
  updateColumns?: Array<CoursesUpdateColumn>;
  where?: InputMaybe<CoursesBoolExp>;
};

/** Ordering options when selecting data from "courses". */
export type CoursesOrderBy = {
  applicationRich?: InputMaybe<OrderBy>;
  audience?: InputMaybe<OrderBy>;
  availableCohortsAggregate?: InputMaybe<CohortsAggregateOrderBy>;
  backgroundImageUrl?: InputMaybe<OrderBy>;
  bannerUrl?: InputMaybe<OrderBy>;
  catalogsAggregate?: InputMaybe<CatalogCoursesAggregateOrderBy>;
  certificateBackgroundUrl?: InputMaybe<OrderBy>;
  certificateTemplate?: InputMaybe<OrderBy>;
  changeDescription?: InputMaybe<OrderBy>;
  classesAggregate?: InputMaybe<ClassesAggregateOrderBy>;
  coachesAggregate?: InputMaybe<CourseCoachesAggregateOrderBy>;
  code?: InputMaybe<OrderBy>;
  cohortsAggregate?: InputMaybe<CohortsAggregateOrderBy>;
  course_publish_status_enum?: InputMaybe<CoursePublishStatusOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  enrollmentStatus?: InputMaybe<OrderBy>;
  frameworkDescription?: InputMaybe<OrderBy>;
  frameworkHighlight?: InputMaybe<OrderBy>;
  iconUrl?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  impactAssessment?: InputMaybe<ImpactAssessmentsOrderBy>;
  impactAssessmentId?: InputMaybe<OrderBy>;
  impactDescriptionList?: InputMaybe<OrderBy>;
  impactDescriptionRich?: InputMaybe<OrderBy>;
  impactRich?: InputMaybe<OrderBy>;
  is_permitted?: InputMaybe<OrderBy>;
  latestPublishedAt?: InputMaybe<OrderBy>;
  latestVersion?: InputMaybe<OrderBy>;
  longDescriptionRich?: InputMaybe<OrderBy>;
  manager?: InputMaybe<UsersOrderBy>;
  managerId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nextCohortStartDate?: InputMaybe<OrderBy>;
  nextCohortStartTimeOfDay?: InputMaybe<OrderBy>;
  postCourseSurveyFormId?: InputMaybe<OrderBy>;
  practicedRich?: InputMaybe<OrderBy>;
  preCourseSurveyFormId?: InputMaybe<OrderBy>;
  promoVideoUrl?: InputMaybe<OrderBy>;
  publishStatus?: InputMaybe<OrderBy>;
  publishedAt?: InputMaybe<OrderBy>;
  publishedBy?: InputMaybe<UsersOrderBy>;
  publishedById?: InputMaybe<OrderBy>;
  relatedCoursesAggregate?: InputMaybe<RelatedCoursesAggregateOrderBy>;
  resourcesAggregate?: InputMaybe<CoursesResourcesRelAggregateOrderBy>;
  school?: InputMaybe<SchoolsOrderBy>;
  schoolId?: InputMaybe<OrderBy>;
  shortDescription?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  tagsAggregate?: InputMaybe<CoursesTagsRelAggregateOrderBy>;
  thumbnailCardUrl?: InputMaybe<OrderBy>;
  thumbnailListUrl?: InputMaybe<OrderBy>;
  timeLengthInClasses?: InputMaybe<OrderBy>;
  timeLengthInWeeks?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<UsersOrderBy>;
  updatedById?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: courses */
export type CoursesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** columns and relationships of "courses_resources_rel" */
export type CoursesResourcesRel = {
  __typename?: 'CoursesResourcesRel';
  /** An object relationship */
  course: Courses;
  courseId: Scalars['uuid'];
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  resource: Resources;
  resourceId: Scalars['uuid'];
};

/** aggregated selection of "courses_resources_rel" */
export type CoursesResourcesRelAggregate = {
  __typename?: 'CoursesResourcesRelAggregate';
  aggregate?: Maybe<CoursesResourcesRelAggregateFields>;
  nodes: Array<CoursesResourcesRel>;
};

export type CoursesResourcesRelAggregateBoolExp = {
  count?: InputMaybe<CoursesResourcesRelAggregateBoolExpCount>;
};

export type CoursesResourcesRelAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CoursesResourcesRelSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoursesResourcesRelBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "courses_resources_rel" */
export type CoursesResourcesRelAggregateFields = {
  __typename?: 'CoursesResourcesRelAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<CoursesResourcesRelMaxFields>;
  min?: Maybe<CoursesResourcesRelMinFields>;
};

/** aggregate fields of "courses_resources_rel" */
export type CoursesResourcesRelAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CoursesResourcesRelSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "courses_resources_rel" */
export type CoursesResourcesRelAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CoursesResourcesRelMaxOrderBy>;
  min?: InputMaybe<CoursesResourcesRelMinOrderBy>;
};

/** input type for inserting array relation for remote table "courses_resources_rel" */
export type CoursesResourcesRelArrRelInsertInput = {
  data: Array<CoursesResourcesRelInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<CoursesResourcesRelOnConflict>;
};

/** Boolean expression to filter rows from the table "courses_resources_rel". All fields are combined with a logical 'AND'. */
export type CoursesResourcesRelBoolExp = {
  _and?: InputMaybe<Array<CoursesResourcesRelBoolExp>>;
  _not?: InputMaybe<CoursesResourcesRelBoolExp>;
  _or?: InputMaybe<Array<CoursesResourcesRelBoolExp>>;
  course?: InputMaybe<CoursesBoolExp>;
  courseId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  resource?: InputMaybe<ResourcesBoolExp>;
  resourceId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "courses_resources_rel" */
export enum CoursesResourcesRelConstraint {
  /** unique or primary key constraint on columns "resource_id", "course_id" */
  courses_resources_rel_pk = 'courses_resources_rel_pk'
}

/** input type for inserting data into table "courses_resources_rel" */
export type CoursesResourcesRelInsertInput = {
  course?: InputMaybe<CoursesObjRelInsertInput>;
  courseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  resource?: InputMaybe<ResourcesObjRelInsertInput>;
  resourceId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CoursesResourcesRelMaxFields = {
  __typename?: 'CoursesResourcesRelMaxFields';
  courseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  resourceId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "courses_resources_rel" */
export type CoursesResourcesRelMaxOrderBy = {
  courseId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  resourceId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CoursesResourcesRelMinFields = {
  __typename?: 'CoursesResourcesRelMinFields';
  courseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  resourceId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "courses_resources_rel" */
export type CoursesResourcesRelMinOrderBy = {
  courseId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  resourceId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "courses_resources_rel" */
export type CoursesResourcesRelMutationResponse = {
  __typename?: 'CoursesResourcesRelMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoursesResourcesRel>;
};

/** input type for inserting object relation for remote table "courses_resources_rel" */
export type CoursesResourcesRelObjRelInsertInput = {
  data: CoursesResourcesRelInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CoursesResourcesRelOnConflict>;
};

/** on_conflict condition type for table "courses_resources_rel" */
export type CoursesResourcesRelOnConflict = {
  constraint: CoursesResourcesRelConstraint;
  updateColumns?: Array<CoursesResourcesRelUpdateColumn>;
  where?: InputMaybe<CoursesResourcesRelBoolExp>;
};

/** Ordering options when selecting data from "courses_resources_rel". */
export type CoursesResourcesRelOrderBy = {
  course?: InputMaybe<CoursesOrderBy>;
  courseId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  resource?: InputMaybe<ResourcesOrderBy>;
  resourceId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: courses_resources_rel */
export type CoursesResourcesRelPkColumnsInput = {
  courseId: Scalars['uuid'];
  resourceId: Scalars['uuid'];
};

/** select columns of table "courses_resources_rel" */
export enum CoursesResourcesRelSelectColumn {
  /** column name */
  courseId = 'courseId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  resourceId = 'resourceId'
}

/** input type for updating data in table "courses_resources_rel" */
export type CoursesResourcesRelSetInput = {
  courseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  resourceId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "CoursesResourcesRel" */
export type CoursesResourcesRelStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CoursesResourcesRelStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CoursesResourcesRelStreamCursorValueInput = {
  courseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  resourceId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "courses_resources_rel" */
export enum CoursesResourcesRelUpdateColumn {
  /** column name */
  courseId = 'courseId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  resourceId = 'resourceId'
}

export type CoursesResourcesRelUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CoursesResourcesRelSetInput>;
  /** filter the rows which have to be updated */
  where: CoursesResourcesRelBoolExp;
};

/** select columns of table "courses" */
export enum CoursesSelectColumn {
  /** column name */
  applicationRich = 'applicationRich',
  /** column name */
  audience = 'audience',
  /** column name */
  backgroundImageUrl = 'backgroundImageUrl',
  /** column name */
  bannerUrl = 'bannerUrl',
  /** column name */
  certificateBackgroundUrl = 'certificateBackgroundUrl',
  /** column name */
  certificateTemplate = 'certificateTemplate',
  /** column name */
  changeDescription = 'changeDescription',
  /** column name */
  code = 'code',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  enrollmentStatus = 'enrollmentStatus',
  /** column name */
  frameworkDescription = 'frameworkDescription',
  /** column name */
  frameworkHighlight = 'frameworkHighlight',
  /** column name */
  iconUrl = 'iconUrl',
  /** column name */
  id = 'id',
  /** column name */
  impactAssessmentId = 'impactAssessmentId',
  /** column name */
  impactDescriptionList = 'impactDescriptionList',
  /** column name */
  impactDescriptionRich = 'impactDescriptionRich',
  /** column name */
  impactRich = 'impactRich',
  /** column name */
  longDescriptionRich = 'longDescriptionRich',
  /** column name */
  managerId = 'managerId',
  /** column name */
  name = 'name',
  /** column name */
  postCourseSurveyFormId = 'postCourseSurveyFormId',
  /** column name */
  practicedRich = 'practicedRich',
  /** column name */
  preCourseSurveyFormId = 'preCourseSurveyFormId',
  /** column name */
  promoVideoUrl = 'promoVideoUrl',
  /** column name */
  publishStatus = 'publishStatus',
  /** column name */
  publishedAt = 'publishedAt',
  /** column name */
  publishedById = 'publishedById',
  /** column name */
  schoolId = 'schoolId',
  /** column name */
  shortDescription = 'shortDescription',
  /** column name */
  slug = 'slug',
  /** column name */
  thumbnailCardUrl = 'thumbnailCardUrl',
  /** column name */
  thumbnailListUrl = 'thumbnailListUrl',
  /** column name */
  timeLengthInClasses = 'timeLengthInClasses',
  /** column name */
  timeLengthInWeeks = 'timeLengthInWeeks',
  /** column name */
  type = 'type',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById',
  /** column name */
  version = 'version'
}

/** input type for updating data in table "courses" */
export type CoursesSetInput = {
  applicationRich?: InputMaybe<Scalars['String']>;
  audience?: InputMaybe<Scalars['String']>;
  backgroundImageUrl?: InputMaybe<Scalars['String']>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  certificateBackgroundUrl?: InputMaybe<Scalars['String']>;
  certificateTemplate?: InputMaybe<Scalars['json']>;
  changeDescription?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  enrollmentStatus?: InputMaybe<CourseEnrollmentStatusEnum>;
  frameworkDescription?: InputMaybe<Scalars['String']>;
  frameworkHighlight?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  impactAssessmentId?: InputMaybe<Scalars['uuid']>;
  impactDescriptionList?: InputMaybe<Scalars['json']>;
  impactDescriptionRich?: InputMaybe<Scalars['String']>;
  impactRich?: InputMaybe<Scalars['String']>;
  longDescriptionRich?: InputMaybe<Scalars['String']>;
  managerId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  postCourseSurveyFormId?: InputMaybe<Scalars['String']>;
  practicedRich?: InputMaybe<Scalars['String']>;
  preCourseSurveyFormId?: InputMaybe<Scalars['String']>;
  promoVideoUrl?: InputMaybe<Scalars['String']>;
  publishStatus?: InputMaybe<CoursePublishStatusEnum>;
  publishedAt?: InputMaybe<Scalars['timestamp']>;
  publishedById?: InputMaybe<Scalars['uuid']>;
  schoolId?: InputMaybe<Scalars['uuid']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  thumbnailCardUrl?: InputMaybe<Scalars['String']>;
  thumbnailListUrl?: InputMaybe<Scalars['String']>;
  timeLengthInClasses?: InputMaybe<Scalars['Int']>;
  timeLengthInWeeks?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<CourseTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type CoursesStddevFields = {
  __typename?: 'CoursesStddevFields';
  /** A computed field, executes function "course_latest_version" */
  latestVersion?: Maybe<Scalars['numeric']>;
  timeLengthInClasses?: Maybe<Scalars['Float']>;
  timeLengthInWeeks?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "courses" */
export type CoursesStddevOrderBy = {
  timeLengthInClasses?: InputMaybe<OrderBy>;
  timeLengthInWeeks?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type CoursesStddevPopFields = {
  __typename?: 'CoursesStddevPopFields';
  /** A computed field, executes function "course_latest_version" */
  latestVersion?: Maybe<Scalars['numeric']>;
  timeLengthInClasses?: Maybe<Scalars['Float']>;
  timeLengthInWeeks?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "courses" */
export type CoursesStddevPopOrderBy = {
  timeLengthInClasses?: InputMaybe<OrderBy>;
  timeLengthInWeeks?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type CoursesStddevSampFields = {
  __typename?: 'CoursesStddevSampFields';
  /** A computed field, executes function "course_latest_version" */
  latestVersion?: Maybe<Scalars['numeric']>;
  timeLengthInClasses?: Maybe<Scalars['Float']>;
  timeLengthInWeeks?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "courses" */
export type CoursesStddevSampOrderBy = {
  timeLengthInClasses?: InputMaybe<OrderBy>;
  timeLengthInWeeks?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "courses" */
export type CoursesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CoursesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CoursesStreamCursorValueInput = {
  applicationRich?: InputMaybe<Scalars['String']>;
  audience?: InputMaybe<Scalars['String']>;
  backgroundImageUrl?: InputMaybe<Scalars['String']>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  certificateBackgroundUrl?: InputMaybe<Scalars['String']>;
  certificateTemplate?: InputMaybe<Scalars['json']>;
  changeDescription?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  enrollmentStatus?: InputMaybe<CourseEnrollmentStatusEnum>;
  frameworkDescription?: InputMaybe<Scalars['String']>;
  frameworkHighlight?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  impactAssessmentId?: InputMaybe<Scalars['uuid']>;
  impactDescriptionList?: InputMaybe<Scalars['json']>;
  impactDescriptionRich?: InputMaybe<Scalars['String']>;
  impactRich?: InputMaybe<Scalars['String']>;
  longDescriptionRich?: InputMaybe<Scalars['String']>;
  managerId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  postCourseSurveyFormId?: InputMaybe<Scalars['String']>;
  practicedRich?: InputMaybe<Scalars['String']>;
  preCourseSurveyFormId?: InputMaybe<Scalars['String']>;
  promoVideoUrl?: InputMaybe<Scalars['String']>;
  publishStatus?: InputMaybe<CoursePublishStatusEnum>;
  publishedAt?: InputMaybe<Scalars['timestamp']>;
  publishedById?: InputMaybe<Scalars['uuid']>;
  schoolId?: InputMaybe<Scalars['uuid']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  thumbnailCardUrl?: InputMaybe<Scalars['String']>;
  thumbnailListUrl?: InputMaybe<Scalars['String']>;
  timeLengthInClasses?: InputMaybe<Scalars['Int']>;
  timeLengthInWeeks?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<CourseTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type CoursesSumFields = {
  __typename?: 'CoursesSumFields';
  /** A computed field, executes function "course_latest_version" */
  latestVersion?: Maybe<Scalars['numeric']>;
  timeLengthInClasses?: Maybe<Scalars['Int']>;
  timeLengthInWeeks?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "courses" */
export type CoursesSumOrderBy = {
  timeLengthInClasses?: InputMaybe<OrderBy>;
  timeLengthInWeeks?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** columns and relationships of "courses_tags_rel" */
export type CoursesTagsRel = {
  __typename?: 'CoursesTagsRel';
  /** An object relationship */
  course?: Maybe<Courses>;
  courseId: Scalars['uuid'];
  courseSlug: Scalars['String'];
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  tag?: Maybe<Tags>;
  tagId: Scalars['uuid'];
};

/** aggregated selection of "courses_tags_rel" */
export type CoursesTagsRelAggregate = {
  __typename?: 'CoursesTagsRelAggregate';
  aggregate?: Maybe<CoursesTagsRelAggregateFields>;
  nodes: Array<CoursesTagsRel>;
};

export type CoursesTagsRelAggregateBoolExp = {
  count?: InputMaybe<CoursesTagsRelAggregateBoolExpCount>;
};

/** aggregate fields of "courses_tags_rel" */
export type CoursesTagsRelAggregateFields = {
  __typename?: 'CoursesTagsRelAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<CoursesTagsRelMaxFields>;
  min?: Maybe<CoursesTagsRelMinFields>;
};

/** aggregate fields of "courses_tags_rel" */
export type CoursesTagsRelAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CoursesTagsRelSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "courses_tags_rel" */
export type CoursesTagsRelAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CoursesTagsRelMaxOrderBy>;
  min?: InputMaybe<CoursesTagsRelMinOrderBy>;
};

/** input type for inserting array relation for remote table "courses_tags_rel" */
export type CoursesTagsRelArrRelInsertInput = {
  data: Array<CoursesTagsRelInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<CoursesTagsRelOnConflict>;
};

/** Boolean expression to filter rows from the table "courses_tags_rel". All fields are combined with a logical 'AND'. */
export type CoursesTagsRelBoolExp = {
  _and?: InputMaybe<Array<CoursesTagsRelBoolExp>>;
  _not?: InputMaybe<CoursesTagsRelBoolExp>;
  _or?: InputMaybe<Array<CoursesTagsRelBoolExp>>;
  course?: InputMaybe<CoursesBoolExp>;
  courseId?: InputMaybe<UuidComparisonExp>;
  courseSlug?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  tag?: InputMaybe<TagsBoolExp>;
  tagId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "courses_tags_rel" */
export enum CoursesTagsRelConstraint {
  /** unique or primary key constraint on columns "course_id", "tag_id" */
  courses_tags_rel_pk = 'courses_tags_rel_pk'
}

/** input type for inserting data into table "courses_tags_rel" */
export type CoursesTagsRelInsertInput = {
  course?: InputMaybe<CoursesObjRelInsertInput>;
  courseId?: InputMaybe<Scalars['uuid']>;
  courseSlug?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  tag?: InputMaybe<TagsObjRelInsertInput>;
  tagId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CoursesTagsRelMaxFields = {
  __typename?: 'CoursesTagsRelMaxFields';
  courseId?: Maybe<Scalars['uuid']>;
  courseSlug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  tagId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "courses_tags_rel" */
export type CoursesTagsRelMaxOrderBy = {
  courseId?: InputMaybe<OrderBy>;
  courseSlug?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  tagId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CoursesTagsRelMinFields = {
  __typename?: 'CoursesTagsRelMinFields';
  courseId?: Maybe<Scalars['uuid']>;
  courseSlug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  tagId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "courses_tags_rel" */
export type CoursesTagsRelMinOrderBy = {
  courseId?: InputMaybe<OrderBy>;
  courseSlug?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  tagId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "courses_tags_rel" */
export type CoursesTagsRelMutationResponse = {
  __typename?: 'CoursesTagsRelMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoursesTagsRel>;
};

/** on_conflict condition type for table "courses_tags_rel" */
export type CoursesTagsRelOnConflict = {
  constraint: CoursesTagsRelConstraint;
  updateColumns?: Array<CoursesTagsRelUpdateColumn>;
  where?: InputMaybe<CoursesTagsRelBoolExp>;
};

/** Ordering options when selecting data from "courses_tags_rel". */
export type CoursesTagsRelOrderBy = {
  course?: InputMaybe<CoursesOrderBy>;
  courseId?: InputMaybe<OrderBy>;
  courseSlug?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  tag?: InputMaybe<TagsOrderBy>;
  tagId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: courses_tags_rel */
export type CoursesTagsRelPkColumnsInput = {
  courseId: Scalars['uuid'];
  tagId: Scalars['uuid'];
};

/** select columns of table "courses_tags_rel" */
export enum CoursesTagsRelSelectColumn {
  /** column name */
  courseId = 'courseId',
  /** column name */
  courseSlug = 'courseSlug',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  tagId = 'tagId'
}

/** input type for updating data in table "courses_tags_rel" */
export type CoursesTagsRelSetInput = {
  courseId?: InputMaybe<Scalars['uuid']>;
  courseSlug?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  tagId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "courses_tags_rel" */
export type CoursesTagsRelStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CoursesTagsRelStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CoursesTagsRelStreamCursorValueInput = {
  courseId?: InputMaybe<Scalars['uuid']>;
  courseSlug?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  tagId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "courses_tags_rel" */
export enum CoursesTagsRelUpdateColumn {
  /** column name */
  courseId = 'courseId',
  /** column name */
  courseSlug = 'courseSlug',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  tagId = 'tagId'
}

export type CoursesTagsRelUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CoursesTagsRelSetInput>;
  /** filter the rows which have to be updated */
  where: CoursesTagsRelBoolExp;
};

/** update columns of table "courses" */
export enum CoursesUpdateColumn {
  /** column name */
  applicationRich = 'applicationRich',
  /** column name */
  audience = 'audience',
  /** column name */
  backgroundImageUrl = 'backgroundImageUrl',
  /** column name */
  bannerUrl = 'bannerUrl',
  /** column name */
  certificateBackgroundUrl = 'certificateBackgroundUrl',
  /** column name */
  certificateTemplate = 'certificateTemplate',
  /** column name */
  changeDescription = 'changeDescription',
  /** column name */
  code = 'code',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  enrollmentStatus = 'enrollmentStatus',
  /** column name */
  frameworkDescription = 'frameworkDescription',
  /** column name */
  frameworkHighlight = 'frameworkHighlight',
  /** column name */
  iconUrl = 'iconUrl',
  /** column name */
  id = 'id',
  /** column name */
  impactAssessmentId = 'impactAssessmentId',
  /** column name */
  impactDescriptionList = 'impactDescriptionList',
  /** column name */
  impactDescriptionRich = 'impactDescriptionRich',
  /** column name */
  impactRich = 'impactRich',
  /** column name */
  longDescriptionRich = 'longDescriptionRich',
  /** column name */
  managerId = 'managerId',
  /** column name */
  name = 'name',
  /** column name */
  postCourseSurveyFormId = 'postCourseSurveyFormId',
  /** column name */
  practicedRich = 'practicedRich',
  /** column name */
  preCourseSurveyFormId = 'preCourseSurveyFormId',
  /** column name */
  promoVideoUrl = 'promoVideoUrl',
  /** column name */
  publishStatus = 'publishStatus',
  /** column name */
  publishedAt = 'publishedAt',
  /** column name */
  publishedById = 'publishedById',
  /** column name */
  schoolId = 'schoolId',
  /** column name */
  shortDescription = 'shortDescription',
  /** column name */
  slug = 'slug',
  /** column name */
  thumbnailCardUrl = 'thumbnailCardUrl',
  /** column name */
  thumbnailListUrl = 'thumbnailListUrl',
  /** column name */
  timeLengthInClasses = 'timeLengthInClasses',
  /** column name */
  timeLengthInWeeks = 'timeLengthInWeeks',
  /** column name */
  type = 'type',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById',
  /** column name */
  version = 'version'
}

export type CoursesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CoursesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CoursesSetInput>;
  /** filter the rows which have to be updated */
  where: CoursesBoolExp;
};

/** aggregate varPop on columns */
export type CoursesVarPopFields = {
  __typename?: 'CoursesVarPopFields';
  /** A computed field, executes function "course_latest_version" */
  latestVersion?: Maybe<Scalars['numeric']>;
  timeLengthInClasses?: Maybe<Scalars['Float']>;
  timeLengthInWeeks?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "courses" */
export type CoursesVarPopOrderBy = {
  timeLengthInClasses?: InputMaybe<OrderBy>;
  timeLengthInWeeks?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type CoursesVarSampFields = {
  __typename?: 'CoursesVarSampFields';
  /** A computed field, executes function "course_latest_version" */
  latestVersion?: Maybe<Scalars['numeric']>;
  timeLengthInClasses?: Maybe<Scalars['Float']>;
  timeLengthInWeeks?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "courses" */
export type CoursesVarSampOrderBy = {
  timeLengthInClasses?: InputMaybe<OrderBy>;
  timeLengthInWeeks?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CoursesVarianceFields = {
  __typename?: 'CoursesVarianceFields';
  /** A computed field, executes function "course_latest_version" */
  latestVersion?: Maybe<Scalars['numeric']>;
  timeLengthInClasses?: Maybe<Scalars['Float']>;
  timeLengthInWeeks?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "courses" */
export type CoursesVarianceOrderBy = {
  timeLengthInClasses?: InputMaybe<OrderBy>;
  timeLengthInWeeks?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

export type CreateOrganizationOutput = {
  __typename?: 'CreateOrganizationOutput';
  id: Scalars['String'];
};

export type CreateUserOutput = {
  __typename?: 'CreateUserOutput';
  id: Scalars['String'];
};

/** ordering argument of a cursor */
export enum CursorOrdering {
  /** ascending ordering of the cursor */
  ASC = 'ASC',
  /** descending ordering of the cursor */
  DESC = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type DateComparisonExp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "email_queue" */
export type EmailQueue = {
  __typename?: 'EmailQueue';
  createdAt: Scalars['timestamptz'];
  data?: Maybe<Scalars['jsonb']>;
  emailName: Scalars['String'];
  id: Scalars['uuid'];
  processed: Scalars['Boolean'];
  processedAt?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "email_queue" */
export type EmailQueueDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "email_queue" */
export type EmailQueueAggregate = {
  __typename?: 'EmailQueueAggregate';
  aggregate?: Maybe<EmailQueueAggregateFields>;
  nodes: Array<EmailQueue>;
};

/** aggregate fields of "email_queue" */
export type EmailQueueAggregateFields = {
  __typename?: 'EmailQueueAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<EmailQueueMaxFields>;
  min?: Maybe<EmailQueueMinFields>;
};

/** aggregate fields of "email_queue" */
export type EmailQueueAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EmailQueueSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type EmailQueueAppendInput = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "email_queue". All fields are combined with a logical 'AND'. */
export type EmailQueueBoolExp = {
  _and?: InputMaybe<Array<EmailQueueBoolExp>>;
  _not?: InputMaybe<EmailQueueBoolExp>;
  _or?: InputMaybe<Array<EmailQueueBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  data?: InputMaybe<JsonbComparisonExp>;
  emailName?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  processed?: InputMaybe<BooleanComparisonExp>;
  processedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "email_queue" */
export enum EmailQueueConstraint {
  /** unique or primary key constraint on columns "id" */
  email_queue_pkey = 'email_queue_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type EmailQueueDeleteAtPathInput = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type EmailQueueDeleteElemInput = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type EmailQueueDeleteKeyInput = {
  data?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "email_queue" */
export type EmailQueueInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  emailName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  processed?: InputMaybe<Scalars['Boolean']>;
  processedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EmailQueueMaxFields = {
  __typename?: 'EmailQueueMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  emailName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  processedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type EmailQueueMinFields = {
  __typename?: 'EmailQueueMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  emailName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  processedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "email_queue" */
export type EmailQueueMutationResponse = {
  __typename?: 'EmailQueueMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EmailQueue>;
};

/** on_conflict condition type for table "email_queue" */
export type EmailQueueOnConflict = {
  constraint: EmailQueueConstraint;
  updateColumns?: Array<EmailQueueUpdateColumn>;
  where?: InputMaybe<EmailQueueBoolExp>;
};

/** Ordering options when selecting data from "email_queue". */
export type EmailQueueOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  data?: InputMaybe<OrderBy>;
  emailName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  processed?: InputMaybe<OrderBy>;
  processedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: email_queue */
export type EmailQueuePkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type EmailQueuePrependInput = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "email_queue" */
export enum EmailQueueSelectColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  data = 'data',
  /** column name */
  emailName = 'emailName',
  /** column name */
  id = 'id',
  /** column name */
  processed = 'processed',
  /** column name */
  processedAt = 'processedAt'
}

/** input type for updating data in table "email_queue" */
export type EmailQueueSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  emailName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  processed?: InputMaybe<Scalars['Boolean']>;
  processedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "email_queue" */
export type EmailQueueStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EmailQueueStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EmailQueueStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  emailName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  processed?: InputMaybe<Scalars['Boolean']>;
  processedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "email_queue" */
export enum EmailQueueUpdateColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  data = 'data',
  /** column name */
  emailName = 'emailName',
  /** column name */
  id = 'id',
  /** column name */
  processed = 'processed',
  /** column name */
  processedAt = 'processedAt'
}

export type EmailQueueUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<EmailQueueAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<EmailQueueDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<EmailQueueDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<EmailQueueDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<EmailQueuePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EmailQueueSetInput>;
  /** filter the rows which have to be updated */
  where: EmailQueueBoolExp;
};

export type EnrollInput = {
  cohortId: Scalars['uuid'];
  mode?: InputMaybe<Scalars['String']>;
  onBehalfOfUserId?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
  waitlistEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type EnrollOutput = {
  __typename?: 'EnrollOutput';
  enrollmentId?: Maybe<Scalars['uuid']>;
  waitlistId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "enrollment_waitlists" */
export type EnrollmentWaitlists = {
  __typename?: 'EnrollmentWaitlists';
  /** An object relationship */
  cohort?: Maybe<Cohorts>;
  cohortId: Scalars['uuid'];
  createdAt: Scalars['timestamp'];
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById: Scalars['uuid'];
  /** An object relationship */
  createdOnBehalfOf?: Maybe<Users>;
  createdOnBehalfOfId?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedBy?: Maybe<Scalars['uuid']>;
  deletedOnBehalfOf?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  isNotDeleted?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  organization?: Maybe<Organizations>;
  organizationId: Scalars['uuid'];
  /** An object relationship */
  user?: Maybe<Users>;
  userId: Scalars['uuid'];
};

/** aggregated selection of "enrollment_waitlists" */
export type EnrollmentWaitlistsAggregate = {
  __typename?: 'EnrollmentWaitlistsAggregate';
  aggregate?: Maybe<EnrollmentWaitlistsAggregateFields>;
  nodes: Array<EnrollmentWaitlists>;
};

export type EnrollmentWaitlistsAggregateBoolExp = {
  bool_and?: InputMaybe<EnrollmentWaitlistsAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<EnrollmentWaitlistsAggregateBoolExpBool_Or>;
  count?: InputMaybe<EnrollmentWaitlistsAggregateBoolExpCount>;
};

/** aggregate fields of "enrollment_waitlists" */
export type EnrollmentWaitlistsAggregateFields = {
  __typename?: 'EnrollmentWaitlistsAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<EnrollmentWaitlistsMaxFields>;
  min?: Maybe<EnrollmentWaitlistsMinFields>;
};

/** aggregate fields of "enrollment_waitlists" */
export type EnrollmentWaitlistsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EnrollmentWaitlistsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "enrollment_waitlists" */
export type EnrollmentWaitlistsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EnrollmentWaitlistsMaxOrderBy>;
  min?: InputMaybe<EnrollmentWaitlistsMinOrderBy>;
};

/** input type for inserting array relation for remote table "enrollment_waitlists" */
export type EnrollmentWaitlistsArrRelInsertInput = {
  data: Array<EnrollmentWaitlistsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<EnrollmentWaitlistsOnConflict>;
};

/** Boolean expression to filter rows from the table "enrollment_waitlists". All fields are combined with a logical 'AND'. */
export type EnrollmentWaitlistsBoolExp = {
  _and?: InputMaybe<Array<EnrollmentWaitlistsBoolExp>>;
  _not?: InputMaybe<EnrollmentWaitlistsBoolExp>;
  _or?: InputMaybe<Array<EnrollmentWaitlistsBoolExp>>;
  cohort?: InputMaybe<CohortsBoolExp>;
  cohortId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  createdOnBehalfOf?: InputMaybe<UsersBoolExp>;
  createdOnBehalfOfId?: InputMaybe<UuidComparisonExp>;
  deletedAt?: InputMaybe<TimestampComparisonExp>;
  deletedBy?: InputMaybe<UuidComparisonExp>;
  deletedOnBehalfOf?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isNotDeleted?: InputMaybe<BooleanComparisonExp>;
  organization?: InputMaybe<OrganizationsBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "enrollment_waitlists" */
export enum EnrollmentWaitlistsConstraint {
  /** unique or primary key constraint on columns "is_not_deleted", "user_id", "cohort_id" */
  enrollment_waitlists_cohort_id_user_id_deleted_at_key = 'enrollment_waitlists_cohort_id_user_id_deleted_at_key',
  /** unique or primary key constraint on columns "id" */
  enrollment_waitlists_pkey = 'enrollment_waitlists_pkey'
}

/** input type for inserting data into table "enrollment_waitlists" */
export type EnrollmentWaitlistsInsertInput = {
  cohort?: InputMaybe<CohortsObjRelInsertInput>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  createdOnBehalfOf?: InputMaybe<UsersObjRelInsertInput>;
  createdOnBehalfOfId?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedBy?: InputMaybe<Scalars['uuid']>;
  deletedOnBehalfOf?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization?: InputMaybe<OrganizationsObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type EnrollmentWaitlistsMaxFields = {
  __typename?: 'EnrollmentWaitlistsMaxFields';
  cohortId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  createdOnBehalfOfId?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedBy?: Maybe<Scalars['uuid']>;
  deletedOnBehalfOf?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  organizationId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "enrollment_waitlists" */
export type EnrollmentWaitlistsMaxOrderBy = {
  cohortId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  createdOnBehalfOfId?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedBy?: InputMaybe<OrderBy>;
  deletedOnBehalfOf?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EnrollmentWaitlistsMinFields = {
  __typename?: 'EnrollmentWaitlistsMinFields';
  cohortId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  createdOnBehalfOfId?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedBy?: Maybe<Scalars['uuid']>;
  deletedOnBehalfOf?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  organizationId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "enrollment_waitlists" */
export type EnrollmentWaitlistsMinOrderBy = {
  cohortId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  createdOnBehalfOfId?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedBy?: InputMaybe<OrderBy>;
  deletedOnBehalfOf?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "enrollment_waitlists" */
export type EnrollmentWaitlistsMutationResponse = {
  __typename?: 'EnrollmentWaitlistsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EnrollmentWaitlists>;
};

/** on_conflict condition type for table "enrollment_waitlists" */
export type EnrollmentWaitlistsOnConflict = {
  constraint: EnrollmentWaitlistsConstraint;
  updateColumns?: Array<EnrollmentWaitlistsUpdateColumn>;
  where?: InputMaybe<EnrollmentWaitlistsBoolExp>;
};

/** Ordering options when selecting data from "enrollment_waitlists". */
export type EnrollmentWaitlistsOrderBy = {
  cohort?: InputMaybe<CohortsOrderBy>;
  cohortId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  createdOnBehalfOf?: InputMaybe<UsersOrderBy>;
  createdOnBehalfOfId?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedBy?: InputMaybe<OrderBy>;
  deletedOnBehalfOf?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isNotDeleted?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationsOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: enrollment_waitlists */
export type EnrollmentWaitlistsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "enrollment_waitlists" */
export enum EnrollmentWaitlistsSelectColumn {
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  createdOnBehalfOfId = 'createdOnBehalfOfId',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedBy = 'deletedBy',
  /** column name */
  deletedOnBehalfOf = 'deletedOnBehalfOf',
  /** column name */
  id = 'id',
  /** column name */
  isNotDeleted = 'isNotDeleted',
  /** column name */
  organizationId = 'organizationId',
  /** column name */
  userId = 'userId'
}

/** select "enrollmentWaitlistsAggregateBoolExpBool_andArgumentsColumns" columns of table "enrollment_waitlists" */
export enum EnrollmentWaitlistsSelectColumnEnrollmentWaitlistsAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  isNotDeleted = 'isNotDeleted'
}

/** select "enrollmentWaitlistsAggregateBoolExpBool_orArgumentsColumns" columns of table "enrollment_waitlists" */
export enum EnrollmentWaitlistsSelectColumnEnrollmentWaitlistsAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  isNotDeleted = 'isNotDeleted'
}

/** input type for updating data in table "enrollment_waitlists" */
export type EnrollmentWaitlistsSetInput = {
  cohortId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  createdOnBehalfOfId?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedBy?: InputMaybe<Scalars['uuid']>;
  deletedOnBehalfOf?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "enrollment_waitlists" */
export type EnrollmentWaitlistsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnrollmentWaitlistsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnrollmentWaitlistsStreamCursorValueInput = {
  cohortId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  createdOnBehalfOfId?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedBy?: InputMaybe<Scalars['uuid']>;
  deletedOnBehalfOf?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isNotDeleted?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "enrollment_waitlists" */
export enum EnrollmentWaitlistsUpdateColumn {
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  createdOnBehalfOfId = 'createdOnBehalfOfId',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedBy = 'deletedBy',
  /** column name */
  deletedOnBehalfOf = 'deletedOnBehalfOf',
  /** column name */
  id = 'id',
  /** column name */
  organizationId = 'organizationId',
  /** column name */
  userId = 'userId'
}

export type EnrollmentWaitlistsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EnrollmentWaitlistsSetInput>;
  /** filter the rows which have to be updated */
  where: EnrollmentWaitlistsBoolExp;
};

/** columns and relationships of "enrollments" */
export type Enrollments = {
  __typename?: 'Enrollments';
  /** An object relationship */
  cohort?: Maybe<Cohorts>;
  cohortId?: Maybe<Scalars['uuid']>;
  /** Completion date for submission, if applicable */
  completedAt?: Maybe<Scalars['timestamp']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  createdOnBehalfOf?: Maybe<Users>;
  createdOnBehalfOfId?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  deletedBy?: Maybe<Users>;
  deletedById?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  deletedOnBehalfOf?: Maybe<Users>;
  deletedOnBehalfOfId?: Maybe<Scalars['uuid']>;
  deletedReason?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  organization?: Maybe<Organizations>;
  organizationId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "enrollments" */
export type EnrollmentsAggregate = {
  __typename?: 'EnrollmentsAggregate';
  aggregate?: Maybe<EnrollmentsAggregateFields>;
  nodes: Array<Enrollments>;
};

export type EnrollmentsAggregateBoolExp = {
  count?: InputMaybe<EnrollmentsAggregateBoolExpCount>;
};

/** aggregate fields of "enrollments" */
export type EnrollmentsAggregateFields = {
  __typename?: 'EnrollmentsAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<EnrollmentsMaxFields>;
  min?: Maybe<EnrollmentsMinFields>;
};

/** aggregate fields of "enrollments" */
export type EnrollmentsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EnrollmentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "enrollments" */
export type EnrollmentsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EnrollmentsMaxOrderBy>;
  min?: InputMaybe<EnrollmentsMinOrderBy>;
};

/** input type for inserting array relation for remote table "enrollments" */
export type EnrollmentsArrRelInsertInput = {
  data: Array<EnrollmentsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<EnrollmentsOnConflict>;
};

/** Boolean expression to filter rows from the table "enrollments". All fields are combined with a logical 'AND'. */
export type EnrollmentsBoolExp = {
  _and?: InputMaybe<Array<EnrollmentsBoolExp>>;
  _not?: InputMaybe<EnrollmentsBoolExp>;
  _or?: InputMaybe<Array<EnrollmentsBoolExp>>;
  cohort?: InputMaybe<CohortsBoolExp>;
  cohortId?: InputMaybe<UuidComparisonExp>;
  completedAt?: InputMaybe<TimestampComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  createdOnBehalfOf?: InputMaybe<UsersBoolExp>;
  createdOnBehalfOfId?: InputMaybe<UuidComparisonExp>;
  deletedAt?: InputMaybe<TimestampComparisonExp>;
  deletedBy?: InputMaybe<UsersBoolExp>;
  deletedById?: InputMaybe<UuidComparisonExp>;
  deletedOnBehalfOf?: InputMaybe<UsersBoolExp>;
  deletedOnBehalfOfId?: InputMaybe<UuidComparisonExp>;
  deletedReason?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  organization?: InputMaybe<OrganizationsBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "enrollments" */
export enum EnrollmentsConstraint {
  /** unique or primary key constraint on columns "user_id", "cohort_id", "organization_id" */
  enrollments_deleted_at_cohort_id_user_id_organization_id_key = 'enrollments_deleted_at_cohort_id_user_id_organization_id_key',
  /** unique or primary key constraint on columns "id" */
  enrollments_pk = 'enrollments_pk'
}

/** input type for inserting data into table "enrollments" */
export type EnrollmentsInsertInput = {
  cohort?: InputMaybe<CohortsObjRelInsertInput>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  createdOnBehalfOf?: InputMaybe<UsersObjRelInsertInput>;
  createdOnBehalfOfId?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedBy?: InputMaybe<UsersObjRelInsertInput>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  deletedOnBehalfOf?: InputMaybe<UsersObjRelInsertInput>;
  deletedOnBehalfOfId?: InputMaybe<Scalars['uuid']>;
  deletedReason?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization?: InputMaybe<OrganizationsObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type EnrollmentsMaxFields = {
  __typename?: 'EnrollmentsMaxFields';
  cohortId?: Maybe<Scalars['uuid']>;
  /** Completion date for submission, if applicable */
  completedAt?: Maybe<Scalars['timestamp']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  createdOnBehalfOfId?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  deletedOnBehalfOfId?: Maybe<Scalars['uuid']>;
  deletedReason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  organizationId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "enrollments" */
export type EnrollmentsMaxOrderBy = {
  cohortId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  createdOnBehalfOfId?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  deletedOnBehalfOfId?: InputMaybe<OrderBy>;
  deletedReason?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EnrollmentsMinFields = {
  __typename?: 'EnrollmentsMinFields';
  cohortId?: Maybe<Scalars['uuid']>;
  /** Completion date for submission, if applicable */
  completedAt?: Maybe<Scalars['timestamp']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  createdOnBehalfOfId?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  deletedOnBehalfOfId?: Maybe<Scalars['uuid']>;
  deletedReason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  organizationId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "enrollments" */
export type EnrollmentsMinOrderBy = {
  cohortId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  createdOnBehalfOfId?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  deletedOnBehalfOfId?: InputMaybe<OrderBy>;
  deletedReason?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "enrollments" */
export type EnrollmentsMutationResponse = {
  __typename?: 'EnrollmentsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enrollments>;
};

/** input type for inserting object relation for remote table "enrollments" */
export type EnrollmentsObjRelInsertInput = {
  data: EnrollmentsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<EnrollmentsOnConflict>;
};

/** on_conflict condition type for table "enrollments" */
export type EnrollmentsOnConflict = {
  constraint: EnrollmentsConstraint;
  updateColumns?: Array<EnrollmentsUpdateColumn>;
  where?: InputMaybe<EnrollmentsBoolExp>;
};

/** Ordering options when selecting data from "enrollments". */
export type EnrollmentsOrderBy = {
  cohort?: InputMaybe<CohortsOrderBy>;
  cohortId?: InputMaybe<OrderBy>;
  completedAt?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  createdOnBehalfOf?: InputMaybe<UsersOrderBy>;
  createdOnBehalfOfId?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedBy?: InputMaybe<UsersOrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  deletedOnBehalfOf?: InputMaybe<UsersOrderBy>;
  deletedOnBehalfOfId?: InputMaybe<OrderBy>;
  deletedReason?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationsOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: enrollments */
export type EnrollmentsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "enrollments" */
export enum EnrollmentsSelectColumn {
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  createdOnBehalfOfId = 'createdOnBehalfOfId',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  deletedOnBehalfOfId = 'deletedOnBehalfOfId',
  /** column name */
  deletedReason = 'deletedReason',
  /** column name */
  id = 'id',
  /** column name */
  organizationId = 'organizationId',
  /** column name */
  userId = 'userId'
}

/** input type for updating data in table "enrollments" */
export type EnrollmentsSetInput = {
  cohortId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  createdOnBehalfOfId?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  deletedOnBehalfOfId?: InputMaybe<Scalars['uuid']>;
  deletedReason?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "enrollments" */
export type EnrollmentsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnrollmentsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnrollmentsStreamCursorValueInput = {
  cohortId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  createdOnBehalfOfId?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  deletedOnBehalfOfId?: InputMaybe<Scalars['uuid']>;
  deletedReason?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "enrollments" */
export enum EnrollmentsUpdateColumn {
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  createdOnBehalfOfId = 'createdOnBehalfOfId',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  deletedOnBehalfOfId = 'deletedOnBehalfOfId',
  /** column name */
  deletedReason = 'deletedReason',
  /** column name */
  id = 'id',
  /** column name */
  organizationId = 'organizationId',
  /** column name */
  userId = 'userId'
}

export type EnrollmentsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EnrollmentsSetInput>;
  /** filter the rows which have to be updated */
  where: EnrollmentsBoolExp;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8ComparisonExp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

/** columns and relationships of "fusionauth.external_identifiers" */
export type FusionauthExternalIdentifiers = {
  __typename?: 'FusionauthExternalIdentifiers';
  applicationsId?: Maybe<Scalars['uuid']>;
  data?: Maybe<Scalars['String']>;
  expirationInstant?: Maybe<Scalars['bigint']>;
  id: Scalars['String'];
  insertInstant: Scalars['bigint'];
  tenantsId: Scalars['uuid'];
  type: Scalars['smallint'];
  /** An object relationship */
  user?: Maybe<Users>;
  usersId?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "fusionauth.external_identifiers" */
export type FusionauthExternalIdentifiersAggregate = {
  __typename?: 'FusionauthExternalIdentifiersAggregate';
  aggregate?: Maybe<FusionauthExternalIdentifiersAggregateFields>;
  nodes: Array<FusionauthExternalIdentifiers>;
};

/** aggregate fields of "fusionauth.external_identifiers" */
export type FusionauthExternalIdentifiersAggregateFields = {
  __typename?: 'FusionauthExternalIdentifiersAggregateFields';
  avg?: Maybe<FusionauthExternalIdentifiersAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<FusionauthExternalIdentifiersMaxFields>;
  min?: Maybe<FusionauthExternalIdentifiersMinFields>;
  stddev?: Maybe<FusionauthExternalIdentifiersStddevFields>;
  stddevPop?: Maybe<FusionauthExternalIdentifiersStddevPopFields>;
  stddevSamp?: Maybe<FusionauthExternalIdentifiersStddevSampFields>;
  sum?: Maybe<FusionauthExternalIdentifiersSumFields>;
  varPop?: Maybe<FusionauthExternalIdentifiersVarPopFields>;
  varSamp?: Maybe<FusionauthExternalIdentifiersVarSampFields>;
  variance?: Maybe<FusionauthExternalIdentifiersVarianceFields>;
};

/** aggregate fields of "fusionauth.external_identifiers" */
export type FusionauthExternalIdentifiersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FusionauthExternalIdentifiersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type FusionauthExternalIdentifiersAvgFields = {
  __typename?: 'FusionauthExternalIdentifiersAvgFields';
  expirationInstant?: Maybe<Scalars['Float']>;
  insertInstant?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "fusionauth.external_identifiers". All fields are combined with a logical 'AND'. */
export type FusionauthExternalIdentifiersBoolExp = {
  _and?: InputMaybe<Array<FusionauthExternalIdentifiersBoolExp>>;
  _not?: InputMaybe<FusionauthExternalIdentifiersBoolExp>;
  _or?: InputMaybe<Array<FusionauthExternalIdentifiersBoolExp>>;
  applicationsId?: InputMaybe<UuidComparisonExp>;
  data?: InputMaybe<StringComparisonExp>;
  expirationInstant?: InputMaybe<BigintComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  insertInstant?: InputMaybe<BigintComparisonExp>;
  tenantsId?: InputMaybe<UuidComparisonExp>;
  type?: InputMaybe<SmallintComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  usersId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "fusionauth.external_identifiers" */
export enum FusionauthExternalIdentifiersConstraint {
  /** unique or primary key constraint on columns "id" */
  external_identifiers_pkey = 'external_identifiers_pkey'
}

/** input type for incrementing numeric columns in table "fusionauth.external_identifiers" */
export type FusionauthExternalIdentifiersIncInput = {
  expirationInstant?: InputMaybe<Scalars['bigint']>;
  insertInstant?: InputMaybe<Scalars['bigint']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "fusionauth.external_identifiers" */
export type FusionauthExternalIdentifiersInsertInput = {
  applicationsId?: InputMaybe<Scalars['uuid']>;
  data?: InputMaybe<Scalars['String']>;
  expirationInstant?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['String']>;
  insertInstant?: InputMaybe<Scalars['bigint']>;
  tenantsId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['smallint']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  usersId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type FusionauthExternalIdentifiersMaxFields = {
  __typename?: 'FusionauthExternalIdentifiersMaxFields';
  applicationsId?: Maybe<Scalars['uuid']>;
  data?: Maybe<Scalars['String']>;
  expirationInstant?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['String']>;
  insertInstant?: Maybe<Scalars['bigint']>;
  tenantsId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['smallint']>;
  usersId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type FusionauthExternalIdentifiersMinFields = {
  __typename?: 'FusionauthExternalIdentifiersMinFields';
  applicationsId?: Maybe<Scalars['uuid']>;
  data?: Maybe<Scalars['String']>;
  expirationInstant?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['String']>;
  insertInstant?: Maybe<Scalars['bigint']>;
  tenantsId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['smallint']>;
  usersId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "fusionauth.external_identifiers" */
export type FusionauthExternalIdentifiersMutationResponse = {
  __typename?: 'FusionauthExternalIdentifiersMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FusionauthExternalIdentifiers>;
};

/** on_conflict condition type for table "fusionauth.external_identifiers" */
export type FusionauthExternalIdentifiersOnConflict = {
  constraint: FusionauthExternalIdentifiersConstraint;
  updateColumns?: Array<FusionauthExternalIdentifiersUpdateColumn>;
  where?: InputMaybe<FusionauthExternalIdentifiersBoolExp>;
};

/** Ordering options when selecting data from "fusionauth.external_identifiers". */
export type FusionauthExternalIdentifiersOrderBy = {
  applicationsId?: InputMaybe<OrderBy>;
  data?: InputMaybe<OrderBy>;
  expirationInstant?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertInstant?: InputMaybe<OrderBy>;
  tenantsId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  usersId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: fusionauth.external_identifiers */
export type FusionauthExternalIdentifiersPkColumnsInput = {
  id: Scalars['String'];
};

/** select columns of table "fusionauth.external_identifiers" */
export enum FusionauthExternalIdentifiersSelectColumn {
  /** column name */
  applicationsId = 'applicationsId',
  /** column name */
  data = 'data',
  /** column name */
  expirationInstant = 'expirationInstant',
  /** column name */
  id = 'id',
  /** column name */
  insertInstant = 'insertInstant',
  /** column name */
  tenantsId = 'tenantsId',
  /** column name */
  type = 'type',
  /** column name */
  usersId = 'usersId'
}

/** input type for updating data in table "fusionauth.external_identifiers" */
export type FusionauthExternalIdentifiersSetInput = {
  applicationsId?: InputMaybe<Scalars['uuid']>;
  data?: InputMaybe<Scalars['String']>;
  expirationInstant?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['String']>;
  insertInstant?: InputMaybe<Scalars['bigint']>;
  tenantsId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['smallint']>;
  usersId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type FusionauthExternalIdentifiersStddevFields = {
  __typename?: 'FusionauthExternalIdentifiersStddevFields';
  expirationInstant?: Maybe<Scalars['Float']>;
  insertInstant?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type FusionauthExternalIdentifiersStddevPopFields = {
  __typename?: 'FusionauthExternalIdentifiersStddevPopFields';
  expirationInstant?: Maybe<Scalars['Float']>;
  insertInstant?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type FusionauthExternalIdentifiersStddevSampFields = {
  __typename?: 'FusionauthExternalIdentifiersStddevSampFields';
  expirationInstant?: Maybe<Scalars['Float']>;
  insertInstant?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "fusionauth_external_identifiers" */
export type FusionauthExternalIdentifiersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: FusionauthExternalIdentifiersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FusionauthExternalIdentifiersStreamCursorValueInput = {
  applicationsId?: InputMaybe<Scalars['uuid']>;
  data?: InputMaybe<Scalars['String']>;
  expirationInstant?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['String']>;
  insertInstant?: InputMaybe<Scalars['bigint']>;
  tenantsId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['smallint']>;
  usersId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type FusionauthExternalIdentifiersSumFields = {
  __typename?: 'FusionauthExternalIdentifiersSumFields';
  expirationInstant?: Maybe<Scalars['bigint']>;
  insertInstant?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['smallint']>;
};

/** update columns of table "fusionauth.external_identifiers" */
export enum FusionauthExternalIdentifiersUpdateColumn {
  /** column name */
  applicationsId = 'applicationsId',
  /** column name */
  data = 'data',
  /** column name */
  expirationInstant = 'expirationInstant',
  /** column name */
  id = 'id',
  /** column name */
  insertInstant = 'insertInstant',
  /** column name */
  tenantsId = 'tenantsId',
  /** column name */
  type = 'type',
  /** column name */
  usersId = 'usersId'
}

export type FusionauthExternalIdentifiersUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FusionauthExternalIdentifiersIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FusionauthExternalIdentifiersSetInput>;
  /** filter the rows which have to be updated */
  where: FusionauthExternalIdentifiersBoolExp;
};

/** aggregate varPop on columns */
export type FusionauthExternalIdentifiersVarPopFields = {
  __typename?: 'FusionauthExternalIdentifiersVarPopFields';
  expirationInstant?: Maybe<Scalars['Float']>;
  insertInstant?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type FusionauthExternalIdentifiersVarSampFields = {
  __typename?: 'FusionauthExternalIdentifiersVarSampFields';
  expirationInstant?: Maybe<Scalars['Float']>;
  insertInstant?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type FusionauthExternalIdentifiersVarianceFields = {
  __typename?: 'FusionauthExternalIdentifiersVarianceFields';
  expirationInstant?: Maybe<Scalars['Float']>;
  insertInstant?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "fusionauth.user_registrations" */
export type FusionauthUserRegistrations = {
  __typename?: 'FusionauthUserRegistrations';
  applicationsId: Scalars['uuid'];
  authenticationToken?: Maybe<Scalars['String']>;
  cleanSpeakId?: Maybe<Scalars['uuid']>;
  data?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  insertInstant: Scalars['bigint'];
  lastLoginInstant?: Maybe<Scalars['bigint']>;
  lastUpdateInstant: Scalars['bigint'];
  timezone?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  usernameStatus: Scalars['smallint'];
  usersId: Scalars['uuid'];
  verified: Scalars['Boolean'];
};

/** aggregated selection of "fusionauth.user_registrations" */
export type FusionauthUserRegistrationsAggregate = {
  __typename?: 'FusionauthUserRegistrationsAggregate';
  aggregate?: Maybe<FusionauthUserRegistrationsAggregateFields>;
  nodes: Array<FusionauthUserRegistrations>;
};

export type FusionauthUserRegistrationsAggregateBoolExp = {
  bool_and?: InputMaybe<FusionauthUserRegistrationsAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<FusionauthUserRegistrationsAggregateBoolExpBool_Or>;
  count?: InputMaybe<FusionauthUserRegistrationsAggregateBoolExpCount>;
};

/** aggregate fields of "fusionauth.user_registrations" */
export type FusionauthUserRegistrationsAggregateFields = {
  __typename?: 'FusionauthUserRegistrationsAggregateFields';
  avg?: Maybe<FusionauthUserRegistrationsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<FusionauthUserRegistrationsMaxFields>;
  min?: Maybe<FusionauthUserRegistrationsMinFields>;
  stddev?: Maybe<FusionauthUserRegistrationsStddevFields>;
  stddevPop?: Maybe<FusionauthUserRegistrationsStddevPopFields>;
  stddevSamp?: Maybe<FusionauthUserRegistrationsStddevSampFields>;
  sum?: Maybe<FusionauthUserRegistrationsSumFields>;
  varPop?: Maybe<FusionauthUserRegistrationsVarPopFields>;
  varSamp?: Maybe<FusionauthUserRegistrationsVarSampFields>;
  variance?: Maybe<FusionauthUserRegistrationsVarianceFields>;
};

/** aggregate fields of "fusionauth.user_registrations" */
export type FusionauthUserRegistrationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FusionauthUserRegistrationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "fusionauth.user_registrations" */
export type FusionauthUserRegistrationsAggregateOrderBy = {
  avg?: InputMaybe<FusionauthUserRegistrationsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<FusionauthUserRegistrationsMaxOrderBy>;
  min?: InputMaybe<FusionauthUserRegistrationsMinOrderBy>;
  stddev?: InputMaybe<FusionauthUserRegistrationsStddevOrderBy>;
  stddevPop?: InputMaybe<FusionauthUserRegistrationsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<FusionauthUserRegistrationsStddevSampOrderBy>;
  sum?: InputMaybe<FusionauthUserRegistrationsSumOrderBy>;
  varPop?: InputMaybe<FusionauthUserRegistrationsVarPopOrderBy>;
  varSamp?: InputMaybe<FusionauthUserRegistrationsVarSampOrderBy>;
  variance?: InputMaybe<FusionauthUserRegistrationsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "fusionauth.user_registrations" */
export type FusionauthUserRegistrationsArrRelInsertInput = {
  data: Array<FusionauthUserRegistrationsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<FusionauthUserRegistrationsOnConflict>;
};

/** aggregate avg on columns */
export type FusionauthUserRegistrationsAvgFields = {
  __typename?: 'FusionauthUserRegistrationsAvgFields';
  insertInstant?: Maybe<Scalars['Float']>;
  lastLoginInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
  usernameStatus?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "fusionauth.user_registrations" */
export type FusionauthUserRegistrationsAvgOrderBy = {
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
  lastUpdateInstant?: InputMaybe<OrderBy>;
  usernameStatus?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "fusionauth.user_registrations". All fields are combined with a logical 'AND'. */
export type FusionauthUserRegistrationsBoolExp = {
  _and?: InputMaybe<Array<FusionauthUserRegistrationsBoolExp>>;
  _not?: InputMaybe<FusionauthUserRegistrationsBoolExp>;
  _or?: InputMaybe<Array<FusionauthUserRegistrationsBoolExp>>;
  applicationsId?: InputMaybe<UuidComparisonExp>;
  authenticationToken?: InputMaybe<StringComparisonExp>;
  cleanSpeakId?: InputMaybe<UuidComparisonExp>;
  data?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  insertInstant?: InputMaybe<BigintComparisonExp>;
  lastLoginInstant?: InputMaybe<BigintComparisonExp>;
  lastUpdateInstant?: InputMaybe<BigintComparisonExp>;
  timezone?: InputMaybe<StringComparisonExp>;
  username?: InputMaybe<StringComparisonExp>;
  usernameStatus?: InputMaybe<SmallintComparisonExp>;
  usersId?: InputMaybe<UuidComparisonExp>;
  verified?: InputMaybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "fusionauth.user_registrations" */
export enum FusionauthUserRegistrationsConstraint {
  /** unique or primary key constraint on columns "id" */
  user_registrations_pkey = 'user_registrations_pkey',
  /** unique or primary key constraint on columns "users_id", "applications_id" */
  user_registrations_uk_1 = 'user_registrations_uk_1'
}

/** input type for incrementing numeric columns in table "fusionauth.user_registrations" */
export type FusionauthUserRegistrationsIncInput = {
  insertInstant?: InputMaybe<Scalars['bigint']>;
  lastLoginInstant?: InputMaybe<Scalars['bigint']>;
  lastUpdateInstant?: InputMaybe<Scalars['bigint']>;
  usernameStatus?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "fusionauth.user_registrations" */
export type FusionauthUserRegistrationsInsertInput = {
  applicationsId?: InputMaybe<Scalars['uuid']>;
  authenticationToken?: InputMaybe<Scalars['String']>;
  cleanSpeakId?: InputMaybe<Scalars['uuid']>;
  data?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertInstant?: InputMaybe<Scalars['bigint']>;
  lastLoginInstant?: InputMaybe<Scalars['bigint']>;
  lastUpdateInstant?: InputMaybe<Scalars['bigint']>;
  timezone?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
  usernameStatus?: InputMaybe<Scalars['smallint']>;
  usersId?: InputMaybe<Scalars['uuid']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type FusionauthUserRegistrationsMaxFields = {
  __typename?: 'FusionauthUserRegistrationsMaxFields';
  applicationsId?: Maybe<Scalars['uuid']>;
  authenticationToken?: Maybe<Scalars['String']>;
  cleanSpeakId?: Maybe<Scalars['uuid']>;
  data?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insertInstant?: Maybe<Scalars['bigint']>;
  lastLoginInstant?: Maybe<Scalars['bigint']>;
  lastUpdateInstant?: Maybe<Scalars['bigint']>;
  timezone?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  usernameStatus?: Maybe<Scalars['smallint']>;
  usersId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "fusionauth.user_registrations" */
export type FusionauthUserRegistrationsMaxOrderBy = {
  applicationsId?: InputMaybe<OrderBy>;
  authenticationToken?: InputMaybe<OrderBy>;
  cleanSpeakId?: InputMaybe<OrderBy>;
  data?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
  lastUpdateInstant?: InputMaybe<OrderBy>;
  timezone?: InputMaybe<OrderBy>;
  username?: InputMaybe<OrderBy>;
  usernameStatus?: InputMaybe<OrderBy>;
  usersId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type FusionauthUserRegistrationsMinFields = {
  __typename?: 'FusionauthUserRegistrationsMinFields';
  applicationsId?: Maybe<Scalars['uuid']>;
  authenticationToken?: Maybe<Scalars['String']>;
  cleanSpeakId?: Maybe<Scalars['uuid']>;
  data?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insertInstant?: Maybe<Scalars['bigint']>;
  lastLoginInstant?: Maybe<Scalars['bigint']>;
  lastUpdateInstant?: Maybe<Scalars['bigint']>;
  timezone?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  usernameStatus?: Maybe<Scalars['smallint']>;
  usersId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "fusionauth.user_registrations" */
export type FusionauthUserRegistrationsMinOrderBy = {
  applicationsId?: InputMaybe<OrderBy>;
  authenticationToken?: InputMaybe<OrderBy>;
  cleanSpeakId?: InputMaybe<OrderBy>;
  data?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
  lastUpdateInstant?: InputMaybe<OrderBy>;
  timezone?: InputMaybe<OrderBy>;
  username?: InputMaybe<OrderBy>;
  usernameStatus?: InputMaybe<OrderBy>;
  usersId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "fusionauth.user_registrations" */
export type FusionauthUserRegistrationsMutationResponse = {
  __typename?: 'FusionauthUserRegistrationsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FusionauthUserRegistrations>;
};

/** on_conflict condition type for table "fusionauth.user_registrations" */
export type FusionauthUserRegistrationsOnConflict = {
  constraint: FusionauthUserRegistrationsConstraint;
  updateColumns?: Array<FusionauthUserRegistrationsUpdateColumn>;
  where?: InputMaybe<FusionauthUserRegistrationsBoolExp>;
};

/** Ordering options when selecting data from "fusionauth.user_registrations". */
export type FusionauthUserRegistrationsOrderBy = {
  applicationsId?: InputMaybe<OrderBy>;
  authenticationToken?: InputMaybe<OrderBy>;
  cleanSpeakId?: InputMaybe<OrderBy>;
  data?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
  lastUpdateInstant?: InputMaybe<OrderBy>;
  timezone?: InputMaybe<OrderBy>;
  username?: InputMaybe<OrderBy>;
  usernameStatus?: InputMaybe<OrderBy>;
  usersId?: InputMaybe<OrderBy>;
  verified?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: fusionauth.user_registrations */
export type FusionauthUserRegistrationsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "fusionauth.user_registrations" */
export enum FusionauthUserRegistrationsSelectColumn {
  /** column name */
  applicationsId = 'applicationsId',
  /** column name */
  authenticationToken = 'authenticationToken',
  /** column name */
  cleanSpeakId = 'cleanSpeakId',
  /** column name */
  data = 'data',
  /** column name */
  id = 'id',
  /** column name */
  insertInstant = 'insertInstant',
  /** column name */
  lastLoginInstant = 'lastLoginInstant',
  /** column name */
  lastUpdateInstant = 'lastUpdateInstant',
  /** column name */
  timezone = 'timezone',
  /** column name */
  username = 'username',
  /** column name */
  usernameStatus = 'usernameStatus',
  /** column name */
  usersId = 'usersId',
  /** column name */
  verified = 'verified'
}

/** select "fusionauthUserRegistrationsAggregateBoolExpBool_andArgumentsColumns" columns of table "fusionauth.user_registrations" */
export enum FusionauthUserRegistrationsSelectColumnFusionauthUserRegistrationsAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  verified = 'verified'
}

/** select "fusionauthUserRegistrationsAggregateBoolExpBool_orArgumentsColumns" columns of table "fusionauth.user_registrations" */
export enum FusionauthUserRegistrationsSelectColumnFusionauthUserRegistrationsAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  verified = 'verified'
}

/** input type for updating data in table "fusionauth.user_registrations" */
export type FusionauthUserRegistrationsSetInput = {
  applicationsId?: InputMaybe<Scalars['uuid']>;
  authenticationToken?: InputMaybe<Scalars['String']>;
  cleanSpeakId?: InputMaybe<Scalars['uuid']>;
  data?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertInstant?: InputMaybe<Scalars['bigint']>;
  lastLoginInstant?: InputMaybe<Scalars['bigint']>;
  lastUpdateInstant?: InputMaybe<Scalars['bigint']>;
  timezone?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
  usernameStatus?: InputMaybe<Scalars['smallint']>;
  usersId?: InputMaybe<Scalars['uuid']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type FusionauthUserRegistrationsStddevFields = {
  __typename?: 'FusionauthUserRegistrationsStddevFields';
  insertInstant?: Maybe<Scalars['Float']>;
  lastLoginInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
  usernameStatus?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "fusionauth.user_registrations" */
export type FusionauthUserRegistrationsStddevOrderBy = {
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
  lastUpdateInstant?: InputMaybe<OrderBy>;
  usernameStatus?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type FusionauthUserRegistrationsStddevPopFields = {
  __typename?: 'FusionauthUserRegistrationsStddevPopFields';
  insertInstant?: Maybe<Scalars['Float']>;
  lastLoginInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
  usernameStatus?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "fusionauth.user_registrations" */
export type FusionauthUserRegistrationsStddevPopOrderBy = {
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
  lastUpdateInstant?: InputMaybe<OrderBy>;
  usernameStatus?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type FusionauthUserRegistrationsStddevSampFields = {
  __typename?: 'FusionauthUserRegistrationsStddevSampFields';
  insertInstant?: Maybe<Scalars['Float']>;
  lastLoginInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
  usernameStatus?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "fusionauth.user_registrations" */
export type FusionauthUserRegistrationsStddevSampOrderBy = {
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
  lastUpdateInstant?: InputMaybe<OrderBy>;
  usernameStatus?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "fusionauth_user_registrations" */
export type FusionauthUserRegistrationsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: FusionauthUserRegistrationsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FusionauthUserRegistrationsStreamCursorValueInput = {
  applicationsId?: InputMaybe<Scalars['uuid']>;
  authenticationToken?: InputMaybe<Scalars['String']>;
  cleanSpeakId?: InputMaybe<Scalars['uuid']>;
  data?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertInstant?: InputMaybe<Scalars['bigint']>;
  lastLoginInstant?: InputMaybe<Scalars['bigint']>;
  lastUpdateInstant?: InputMaybe<Scalars['bigint']>;
  timezone?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
  usernameStatus?: InputMaybe<Scalars['smallint']>;
  usersId?: InputMaybe<Scalars['uuid']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type FusionauthUserRegistrationsSumFields = {
  __typename?: 'FusionauthUserRegistrationsSumFields';
  insertInstant?: Maybe<Scalars['bigint']>;
  lastLoginInstant?: Maybe<Scalars['bigint']>;
  lastUpdateInstant?: Maybe<Scalars['bigint']>;
  usernameStatus?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "fusionauth.user_registrations" */
export type FusionauthUserRegistrationsSumOrderBy = {
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
  lastUpdateInstant?: InputMaybe<OrderBy>;
  usernameStatus?: InputMaybe<OrderBy>;
};

/** update columns of table "fusionauth.user_registrations" */
export enum FusionauthUserRegistrationsUpdateColumn {
  /** column name */
  applicationsId = 'applicationsId',
  /** column name */
  authenticationToken = 'authenticationToken',
  /** column name */
  cleanSpeakId = 'cleanSpeakId',
  /** column name */
  data = 'data',
  /** column name */
  id = 'id',
  /** column name */
  insertInstant = 'insertInstant',
  /** column name */
  lastLoginInstant = 'lastLoginInstant',
  /** column name */
  lastUpdateInstant = 'lastUpdateInstant',
  /** column name */
  timezone = 'timezone',
  /** column name */
  username = 'username',
  /** column name */
  usernameStatus = 'usernameStatus',
  /** column name */
  usersId = 'usersId',
  /** column name */
  verified = 'verified'
}

export type FusionauthUserRegistrationsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FusionauthUserRegistrationsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FusionauthUserRegistrationsSetInput>;
  /** filter the rows which have to be updated */
  where: FusionauthUserRegistrationsBoolExp;
};

/** aggregate varPop on columns */
export type FusionauthUserRegistrationsVarPopFields = {
  __typename?: 'FusionauthUserRegistrationsVarPopFields';
  insertInstant?: Maybe<Scalars['Float']>;
  lastLoginInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
  usernameStatus?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "fusionauth.user_registrations" */
export type FusionauthUserRegistrationsVarPopOrderBy = {
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
  lastUpdateInstant?: InputMaybe<OrderBy>;
  usernameStatus?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type FusionauthUserRegistrationsVarSampFields = {
  __typename?: 'FusionauthUserRegistrationsVarSampFields';
  insertInstant?: Maybe<Scalars['Float']>;
  lastLoginInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
  usernameStatus?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "fusionauth.user_registrations" */
export type FusionauthUserRegistrationsVarSampOrderBy = {
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
  lastUpdateInstant?: InputMaybe<OrderBy>;
  usernameStatus?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type FusionauthUserRegistrationsVarianceFields = {
  __typename?: 'FusionauthUserRegistrationsVarianceFields';
  insertInstant?: Maybe<Scalars['Float']>;
  lastLoginInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
  usernameStatus?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "fusionauth.user_registrations" */
export type FusionauthUserRegistrationsVarianceOrderBy = {
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
  lastUpdateInstant?: InputMaybe<OrderBy>;
  usernameStatus?: InputMaybe<OrderBy>;
};

export type GetCohortEnrollmentStatusOutput = {
  __typename?: 'GetCohortEnrollmentStatusOutput';
  isFull: Scalars['Boolean'];
  remainingSeats: Scalars['Int'];
};

export type GetMySessionsAttendedCountOutput = {
  __typename?: 'GetMySessionsAttendedCountOutput';
  count: Scalars['Int'];
};

export type GetPastSessionAsyncAverageMetricsOutput = {
  __typename?: 'GetPastSessionAsyncAverageMetricsOutput';
  asyncMetrics?: Maybe<GetPastSessionAsyncAverageMetricsSnowflakeMetrics>;
  pastSessionsWithMetrics: Array<Maybe<GetPastSessionAsyncAverageMetricsPastSession>>;
};

export type GetPastSessionAsyncAverageMetricsPastSession = {
  __typename?: 'GetPastSessionAsyncAverageMetricsPastSession';
  cohortId: Scalars['uuid'];
  metrics?: Maybe<GetPastSessionAsyncMetricsSnowflakeMetrics>;
};

export type GetPastSessionAsyncAverageMetricsSnowflakeMetrics = {
  __typename?: 'GetPastSessionAsyncAverageMetricsSnowflakeMetrics';
  averageAttendanceRateMetric?: Maybe<GetPastSessionAsyncMetricsMetric>;
  averageHighConfidenceMetric?: Maybe<GetPastSessionAsyncMetricsMetric>;
  averageHighEngagementMetric?: Maybe<GetPastSessionAsyncMetricsMetric>;
  averageHighJobImpactMetric?: Maybe<GetPastSessionAsyncMetricsMetric>;
};

export type GetPastSessionAsyncMetricsMemberSessionNps = {
  __typename?: 'GetPastSessionAsyncMetricsMemberSessionNps';
  confidenceScore: Scalars['String'];
  engagementScore: Scalars['String'];
  jobImpactScore: Scalars['String'];
  npsScore: Scalars['String'];
  numDealsWhereApplied: Scalars['String'];
  takeawayQuote: Scalars['String'];
  whichDeals: Scalars['String'];
};

export type GetPastSessionAsyncMetricsMemberWithSessionNps = {
  __typename?: 'GetPastSessionAsyncMetricsMemberWithSessionNps';
  id: Scalars['uuid'];
  pollSubmission?: Maybe<GetPastSessionAsyncMetricsMemberSessionNps>;
};

export type GetPastSessionAsyncMetricsMetric = {
  __typename?: 'GetPastSessionAsyncMetricsMetric';
  async: Scalars['Boolean'];
  benchmark?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['Float']>;
  title: Scalars['String'];
  type: Scalars['String'];
};

export type GetPastSessionAsyncMetricsOutput = {
  __typename?: 'GetPastSessionAsyncMetricsOutput';
  asyncMetrics?: Maybe<GetPastSessionAsyncMetricsSnowflakeMetrics>;
  sessionMembersWithNPS: Array<Maybe<GetPastSessionAsyncMetricsMemberWithSessionNps>>;
};

export type GetPastSessionAsyncMetricsSnowflakeMetrics = {
  __typename?: 'GetPastSessionAsyncMetricsSnowflakeMetrics';
  attendanceRateMetric?: Maybe<GetPastSessionAsyncMetricsMetric>;
  highCoachFeedbackMetric?: Maybe<GetPastSessionAsyncMetricsMetric>;
  highConfidenceMetric?: Maybe<GetPastSessionAsyncMetricsMetric>;
  highEngagementMetric?: Maybe<GetPastSessionAsyncMetricsMetric>;
  highJobImpactMetric?: Maybe<GetPastSessionAsyncMetricsMetric>;
};

/** columns and relationships of "fusionauth.identities" */
export type Identities = {
  __typename?: 'Identities';
  breachedPasswordLastCheckedInstant?: Maybe<Scalars['bigint']>;
  breachedPasswordStatus?: Maybe<Scalars['smallint']>;
  connectorsId: Scalars['uuid'];
  email?: Maybe<Scalars['String']>;
  encryptionScheme: Scalars['String'];
  factor: Scalars['Int'];
  id: Scalars['bigint'];
  insertInstant: Scalars['bigint'];
  lastLoginInstant?: Maybe<Scalars['bigint']>;
  lastUpdateInstant: Scalars['bigint'];
  password: Scalars['String'];
  passwordChangeReason?: Maybe<Scalars['smallint']>;
  passwordChangeRequired: Scalars['Boolean'];
  passwordLastUpdateInstant: Scalars['bigint'];
  salt: Scalars['String'];
  status: Scalars['smallint'];
  tenantsId: Scalars['uuid'];
  /** An object relationship */
  user: Users;
  username?: Maybe<Scalars['String']>;
  usernameIndex?: Maybe<Scalars['String']>;
  usernameStatus: Scalars['smallint'];
  usersId: Scalars['uuid'];
  verified: Scalars['Boolean'];
};

/** aggregated selection of "fusionauth.identities" */
export type IdentitiesAggregate = {
  __typename?: 'IdentitiesAggregate';
  aggregate?: Maybe<IdentitiesAggregateFields>;
  nodes: Array<Identities>;
};

export type IdentitiesAggregateBoolExp = {
  bool_and?: InputMaybe<IdentitiesAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<IdentitiesAggregateBoolExpBool_Or>;
  count?: InputMaybe<IdentitiesAggregateBoolExpCount>;
};

export type IdentitiesAggregateBoolExpBool_And = {
  arguments: IdentitiesSelectColumnIdentitiesAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<IdentitiesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type IdentitiesAggregateBoolExpBool_Or = {
  arguments: IdentitiesSelectColumnIdentitiesAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<IdentitiesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type IdentitiesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IdentitiesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<IdentitiesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "fusionauth.identities" */
export type IdentitiesAggregateFields = {
  __typename?: 'IdentitiesAggregateFields';
  avg?: Maybe<IdentitiesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IdentitiesMaxFields>;
  min?: Maybe<IdentitiesMinFields>;
  stddev?: Maybe<IdentitiesStddevFields>;
  stddevPop?: Maybe<IdentitiesStddevPopFields>;
  stddevSamp?: Maybe<IdentitiesStddevSampFields>;
  sum?: Maybe<IdentitiesSumFields>;
  varPop?: Maybe<IdentitiesVarPopFields>;
  varSamp?: Maybe<IdentitiesVarSampFields>;
  variance?: Maybe<IdentitiesVarianceFields>;
};

/** aggregate fields of "fusionauth.identities" */
export type IdentitiesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IdentitiesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "fusionauth.identities" */
export type IdentitiesAggregateOrderBy = {
  avg?: InputMaybe<IdentitiesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<IdentitiesMaxOrderBy>;
  min?: InputMaybe<IdentitiesMinOrderBy>;
  stddev?: InputMaybe<IdentitiesStddevOrderBy>;
  stddevPop?: InputMaybe<IdentitiesStddevPopOrderBy>;
  stddevSamp?: InputMaybe<IdentitiesStddevSampOrderBy>;
  sum?: InputMaybe<IdentitiesSumOrderBy>;
  varPop?: InputMaybe<IdentitiesVarPopOrderBy>;
  varSamp?: InputMaybe<IdentitiesVarSampOrderBy>;
  variance?: InputMaybe<IdentitiesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "fusionauth.identities" */
export type IdentitiesArrRelInsertInput = {
  data: Array<IdentitiesInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<IdentitiesOnConflict>;
};

/** aggregate avg on columns */
export type IdentitiesAvgFields = {
  __typename?: 'IdentitiesAvgFields';
  breachedPasswordLastCheckedInstant?: Maybe<Scalars['Float']>;
  breachedPasswordStatus?: Maybe<Scalars['Float']>;
  factor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insertInstant?: Maybe<Scalars['Float']>;
  lastLoginInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
  passwordChangeReason?: Maybe<Scalars['Float']>;
  passwordLastUpdateInstant?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  usernameStatus?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "fusionauth.identities" */
export type IdentitiesAvgOrderBy = {
  breachedPasswordLastCheckedInstant?: InputMaybe<OrderBy>;
  breachedPasswordStatus?: InputMaybe<OrderBy>;
  factor?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
  lastUpdateInstant?: InputMaybe<OrderBy>;
  passwordChangeReason?: InputMaybe<OrderBy>;
  passwordLastUpdateInstant?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  usernameStatus?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "fusionauth.identities". All fields are combined with a logical 'AND'. */
export type IdentitiesBoolExp = {
  _and?: InputMaybe<Array<IdentitiesBoolExp>>;
  _not?: InputMaybe<IdentitiesBoolExp>;
  _or?: InputMaybe<Array<IdentitiesBoolExp>>;
  breachedPasswordLastCheckedInstant?: InputMaybe<BigintComparisonExp>;
  breachedPasswordStatus?: InputMaybe<SmallintComparisonExp>;
  connectorsId?: InputMaybe<UuidComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  encryptionScheme?: InputMaybe<StringComparisonExp>;
  factor?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  insertInstant?: InputMaybe<BigintComparisonExp>;
  lastLoginInstant?: InputMaybe<BigintComparisonExp>;
  lastUpdateInstant?: InputMaybe<BigintComparisonExp>;
  password?: InputMaybe<StringComparisonExp>;
  passwordChangeReason?: InputMaybe<SmallintComparisonExp>;
  passwordChangeRequired?: InputMaybe<BooleanComparisonExp>;
  passwordLastUpdateInstant?: InputMaybe<BigintComparisonExp>;
  salt?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<SmallintComparisonExp>;
  tenantsId?: InputMaybe<UuidComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  username?: InputMaybe<StringComparisonExp>;
  usernameIndex?: InputMaybe<StringComparisonExp>;
  usernameStatus?: InputMaybe<SmallintComparisonExp>;
  usersId?: InputMaybe<UuidComparisonExp>;
  verified?: InputMaybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "fusionauth.identities" */
export enum IdentitiesConstraint {
  /** unique or primary key constraint on columns "id" */
  identities_pkey = 'identities_pkey',
  /** unique or primary key constraint on columns "tenants_id", "email" */
  identities_uk_1 = 'identities_uk_1',
  /** unique or primary key constraint on columns "username_index", "tenants_id" */
  identities_uk_2 = 'identities_uk_2'
}

/** input type for incrementing numeric columns in table "fusionauth.identities" */
export type IdentitiesIncInput = {
  breachedPasswordLastCheckedInstant?: InputMaybe<Scalars['bigint']>;
  breachedPasswordStatus?: InputMaybe<Scalars['smallint']>;
  factor?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  insertInstant?: InputMaybe<Scalars['bigint']>;
  lastLoginInstant?: InputMaybe<Scalars['bigint']>;
  lastUpdateInstant?: InputMaybe<Scalars['bigint']>;
  passwordChangeReason?: InputMaybe<Scalars['smallint']>;
  passwordLastUpdateInstant?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Scalars['smallint']>;
  usernameStatus?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "fusionauth.identities" */
export type IdentitiesInsertInput = {
  breachedPasswordLastCheckedInstant?: InputMaybe<Scalars['bigint']>;
  breachedPasswordStatus?: InputMaybe<Scalars['smallint']>;
  connectorsId?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  encryptionScheme?: InputMaybe<Scalars['String']>;
  factor?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  insertInstant?: InputMaybe<Scalars['bigint']>;
  lastLoginInstant?: InputMaybe<Scalars['bigint']>;
  lastUpdateInstant?: InputMaybe<Scalars['bigint']>;
  password?: InputMaybe<Scalars['String']>;
  passwordChangeReason?: InputMaybe<Scalars['smallint']>;
  passwordChangeRequired?: InputMaybe<Scalars['Boolean']>;
  passwordLastUpdateInstant?: InputMaybe<Scalars['bigint']>;
  salt?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['smallint']>;
  tenantsId?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  username?: InputMaybe<Scalars['String']>;
  usernameIndex?: InputMaybe<Scalars['String']>;
  usernameStatus?: InputMaybe<Scalars['smallint']>;
  usersId?: InputMaybe<Scalars['uuid']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type IdentitiesMaxFields = {
  __typename?: 'IdentitiesMaxFields';
  breachedPasswordLastCheckedInstant?: Maybe<Scalars['bigint']>;
  breachedPasswordStatus?: Maybe<Scalars['smallint']>;
  connectorsId?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  encryptionScheme?: Maybe<Scalars['String']>;
  factor?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  insertInstant?: Maybe<Scalars['bigint']>;
  lastLoginInstant?: Maybe<Scalars['bigint']>;
  lastUpdateInstant?: Maybe<Scalars['bigint']>;
  password?: Maybe<Scalars['String']>;
  passwordChangeReason?: Maybe<Scalars['smallint']>;
  passwordLastUpdateInstant?: Maybe<Scalars['bigint']>;
  salt?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['smallint']>;
  tenantsId?: Maybe<Scalars['uuid']>;
  username?: Maybe<Scalars['String']>;
  usernameIndex?: Maybe<Scalars['String']>;
  usernameStatus?: Maybe<Scalars['smallint']>;
  usersId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "fusionauth.identities" */
export type IdentitiesMaxOrderBy = {
  breachedPasswordLastCheckedInstant?: InputMaybe<OrderBy>;
  breachedPasswordStatus?: InputMaybe<OrderBy>;
  connectorsId?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  encryptionScheme?: InputMaybe<OrderBy>;
  factor?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
  lastUpdateInstant?: InputMaybe<OrderBy>;
  password?: InputMaybe<OrderBy>;
  passwordChangeReason?: InputMaybe<OrderBy>;
  passwordLastUpdateInstant?: InputMaybe<OrderBy>;
  salt?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  tenantsId?: InputMaybe<OrderBy>;
  username?: InputMaybe<OrderBy>;
  usernameIndex?: InputMaybe<OrderBy>;
  usernameStatus?: InputMaybe<OrderBy>;
  usersId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type IdentitiesMinFields = {
  __typename?: 'IdentitiesMinFields';
  breachedPasswordLastCheckedInstant?: Maybe<Scalars['bigint']>;
  breachedPasswordStatus?: Maybe<Scalars['smallint']>;
  connectorsId?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  encryptionScheme?: Maybe<Scalars['String']>;
  factor?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  insertInstant?: Maybe<Scalars['bigint']>;
  lastLoginInstant?: Maybe<Scalars['bigint']>;
  lastUpdateInstant?: Maybe<Scalars['bigint']>;
  password?: Maybe<Scalars['String']>;
  passwordChangeReason?: Maybe<Scalars['smallint']>;
  passwordLastUpdateInstant?: Maybe<Scalars['bigint']>;
  salt?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['smallint']>;
  tenantsId?: Maybe<Scalars['uuid']>;
  username?: Maybe<Scalars['String']>;
  usernameIndex?: Maybe<Scalars['String']>;
  usernameStatus?: Maybe<Scalars['smallint']>;
  usersId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "fusionauth.identities" */
export type IdentitiesMinOrderBy = {
  breachedPasswordLastCheckedInstant?: InputMaybe<OrderBy>;
  breachedPasswordStatus?: InputMaybe<OrderBy>;
  connectorsId?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  encryptionScheme?: InputMaybe<OrderBy>;
  factor?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
  lastUpdateInstant?: InputMaybe<OrderBy>;
  password?: InputMaybe<OrderBy>;
  passwordChangeReason?: InputMaybe<OrderBy>;
  passwordLastUpdateInstant?: InputMaybe<OrderBy>;
  salt?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  tenantsId?: InputMaybe<OrderBy>;
  username?: InputMaybe<OrderBy>;
  usernameIndex?: InputMaybe<OrderBy>;
  usernameStatus?: InputMaybe<OrderBy>;
  usersId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "fusionauth.identities" */
export type IdentitiesMutationResponse = {
  __typename?: 'IdentitiesMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Identities>;
};

/** on_conflict condition type for table "fusionauth.identities" */
export type IdentitiesOnConflict = {
  constraint: IdentitiesConstraint;
  updateColumns?: Array<IdentitiesUpdateColumn>;
  where?: InputMaybe<IdentitiesBoolExp>;
};

/** Ordering options when selecting data from "fusionauth.identities". */
export type IdentitiesOrderBy = {
  breachedPasswordLastCheckedInstant?: InputMaybe<OrderBy>;
  breachedPasswordStatus?: InputMaybe<OrderBy>;
  connectorsId?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  encryptionScheme?: InputMaybe<OrderBy>;
  factor?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
  lastUpdateInstant?: InputMaybe<OrderBy>;
  password?: InputMaybe<OrderBy>;
  passwordChangeReason?: InputMaybe<OrderBy>;
  passwordChangeRequired?: InputMaybe<OrderBy>;
  passwordLastUpdateInstant?: InputMaybe<OrderBy>;
  salt?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  tenantsId?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  username?: InputMaybe<OrderBy>;
  usernameIndex?: InputMaybe<OrderBy>;
  usernameStatus?: InputMaybe<OrderBy>;
  usersId?: InputMaybe<OrderBy>;
  verified?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: fusionauth.identities */
export type IdentitiesPkColumnsInput = {
  id: Scalars['bigint'];
};

/** select columns of table "fusionauth.identities" */
export enum IdentitiesSelectColumn {
  /** column name */
  breachedPasswordLastCheckedInstant = 'breachedPasswordLastCheckedInstant',
  /** column name */
  breachedPasswordStatus = 'breachedPasswordStatus',
  /** column name */
  connectorsId = 'connectorsId',
  /** column name */
  email = 'email',
  /** column name */
  encryptionScheme = 'encryptionScheme',
  /** column name */
  factor = 'factor',
  /** column name */
  id = 'id',
  /** column name */
  insertInstant = 'insertInstant',
  /** column name */
  lastLoginInstant = 'lastLoginInstant',
  /** column name */
  lastUpdateInstant = 'lastUpdateInstant',
  /** column name */
  password = 'password',
  /** column name */
  passwordChangeReason = 'passwordChangeReason',
  /** column name */
  passwordChangeRequired = 'passwordChangeRequired',
  /** column name */
  passwordLastUpdateInstant = 'passwordLastUpdateInstant',
  /** column name */
  salt = 'salt',
  /** column name */
  status = 'status',
  /** column name */
  tenantsId = 'tenantsId',
  /** column name */
  username = 'username',
  /** column name */
  usernameIndex = 'usernameIndex',
  /** column name */
  usernameStatus = 'usernameStatus',
  /** column name */
  usersId = 'usersId',
  /** column name */
  verified = 'verified'
}

/** select "IdentitiesAggregateBoolExpBool_andArgumentsColumns" columns of table "fusionauth.identities" */
export enum IdentitiesSelectColumnIdentitiesAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  passwordChangeRequired = 'passwordChangeRequired',
  /** column name */
  verified = 'verified'
}

/** select "IdentitiesAggregateBoolExpBool_orArgumentsColumns" columns of table "fusionauth.identities" */
export enum IdentitiesSelectColumnIdentitiesAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  passwordChangeRequired = 'passwordChangeRequired',
  /** column name */
  verified = 'verified'
}

/** input type for updating data in table "fusionauth.identities" */
export type IdentitiesSetInput = {
  breachedPasswordLastCheckedInstant?: InputMaybe<Scalars['bigint']>;
  breachedPasswordStatus?: InputMaybe<Scalars['smallint']>;
  connectorsId?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  encryptionScheme?: InputMaybe<Scalars['String']>;
  factor?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  insertInstant?: InputMaybe<Scalars['bigint']>;
  lastLoginInstant?: InputMaybe<Scalars['bigint']>;
  lastUpdateInstant?: InputMaybe<Scalars['bigint']>;
  password?: InputMaybe<Scalars['String']>;
  passwordChangeReason?: InputMaybe<Scalars['smallint']>;
  passwordChangeRequired?: InputMaybe<Scalars['Boolean']>;
  passwordLastUpdateInstant?: InputMaybe<Scalars['bigint']>;
  salt?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['smallint']>;
  tenantsId?: InputMaybe<Scalars['uuid']>;
  username?: InputMaybe<Scalars['String']>;
  usernameIndex?: InputMaybe<Scalars['String']>;
  usernameStatus?: InputMaybe<Scalars['smallint']>;
  usersId?: InputMaybe<Scalars['uuid']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type IdentitiesStddevFields = {
  __typename?: 'IdentitiesStddevFields';
  breachedPasswordLastCheckedInstant?: Maybe<Scalars['Float']>;
  breachedPasswordStatus?: Maybe<Scalars['Float']>;
  factor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insertInstant?: Maybe<Scalars['Float']>;
  lastLoginInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
  passwordChangeReason?: Maybe<Scalars['Float']>;
  passwordLastUpdateInstant?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  usernameStatus?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "fusionauth.identities" */
export type IdentitiesStddevOrderBy = {
  breachedPasswordLastCheckedInstant?: InputMaybe<OrderBy>;
  breachedPasswordStatus?: InputMaybe<OrderBy>;
  factor?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
  lastUpdateInstant?: InputMaybe<OrderBy>;
  passwordChangeReason?: InputMaybe<OrderBy>;
  passwordLastUpdateInstant?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  usernameStatus?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type IdentitiesStddevPopFields = {
  __typename?: 'IdentitiesStddevPopFields';
  breachedPasswordLastCheckedInstant?: Maybe<Scalars['Float']>;
  breachedPasswordStatus?: Maybe<Scalars['Float']>;
  factor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insertInstant?: Maybe<Scalars['Float']>;
  lastLoginInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
  passwordChangeReason?: Maybe<Scalars['Float']>;
  passwordLastUpdateInstant?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  usernameStatus?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "fusionauth.identities" */
export type IdentitiesStddevPopOrderBy = {
  breachedPasswordLastCheckedInstant?: InputMaybe<OrderBy>;
  breachedPasswordStatus?: InputMaybe<OrderBy>;
  factor?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
  lastUpdateInstant?: InputMaybe<OrderBy>;
  passwordChangeReason?: InputMaybe<OrderBy>;
  passwordLastUpdateInstant?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  usernameStatus?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type IdentitiesStddevSampFields = {
  __typename?: 'IdentitiesStddevSampFields';
  breachedPasswordLastCheckedInstant?: Maybe<Scalars['Float']>;
  breachedPasswordStatus?: Maybe<Scalars['Float']>;
  factor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insertInstant?: Maybe<Scalars['Float']>;
  lastLoginInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
  passwordChangeReason?: Maybe<Scalars['Float']>;
  passwordLastUpdateInstant?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  usernameStatus?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "fusionauth.identities" */
export type IdentitiesStddevSampOrderBy = {
  breachedPasswordLastCheckedInstant?: InputMaybe<OrderBy>;
  breachedPasswordStatus?: InputMaybe<OrderBy>;
  factor?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
  lastUpdateInstant?: InputMaybe<OrderBy>;
  passwordChangeReason?: InputMaybe<OrderBy>;
  passwordLastUpdateInstant?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  usernameStatus?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "Identities" */
export type IdentitiesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: IdentitiesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IdentitiesStreamCursorValueInput = {
  breachedPasswordLastCheckedInstant?: InputMaybe<Scalars['bigint']>;
  breachedPasswordStatus?: InputMaybe<Scalars['smallint']>;
  connectorsId?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  encryptionScheme?: InputMaybe<Scalars['String']>;
  factor?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  insertInstant?: InputMaybe<Scalars['bigint']>;
  lastLoginInstant?: InputMaybe<Scalars['bigint']>;
  lastUpdateInstant?: InputMaybe<Scalars['bigint']>;
  password?: InputMaybe<Scalars['String']>;
  passwordChangeReason?: InputMaybe<Scalars['smallint']>;
  passwordChangeRequired?: InputMaybe<Scalars['Boolean']>;
  passwordLastUpdateInstant?: InputMaybe<Scalars['bigint']>;
  salt?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['smallint']>;
  tenantsId?: InputMaybe<Scalars['uuid']>;
  username?: InputMaybe<Scalars['String']>;
  usernameIndex?: InputMaybe<Scalars['String']>;
  usernameStatus?: InputMaybe<Scalars['smallint']>;
  usersId?: InputMaybe<Scalars['uuid']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type IdentitiesSumFields = {
  __typename?: 'IdentitiesSumFields';
  breachedPasswordLastCheckedInstant?: Maybe<Scalars['bigint']>;
  breachedPasswordStatus?: Maybe<Scalars['smallint']>;
  factor?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  insertInstant?: Maybe<Scalars['bigint']>;
  lastLoginInstant?: Maybe<Scalars['bigint']>;
  lastUpdateInstant?: Maybe<Scalars['bigint']>;
  passwordChangeReason?: Maybe<Scalars['smallint']>;
  passwordLastUpdateInstant?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['smallint']>;
  usernameStatus?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "fusionauth.identities" */
export type IdentitiesSumOrderBy = {
  breachedPasswordLastCheckedInstant?: InputMaybe<OrderBy>;
  breachedPasswordStatus?: InputMaybe<OrderBy>;
  factor?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
  lastUpdateInstant?: InputMaybe<OrderBy>;
  passwordChangeReason?: InputMaybe<OrderBy>;
  passwordLastUpdateInstant?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  usernameStatus?: InputMaybe<OrderBy>;
};

/** update columns of table "fusionauth.identities" */
export enum IdentitiesUpdateColumn {
  /** column name */
  breachedPasswordLastCheckedInstant = 'breachedPasswordLastCheckedInstant',
  /** column name */
  breachedPasswordStatus = 'breachedPasswordStatus',
  /** column name */
  connectorsId = 'connectorsId',
  /** column name */
  email = 'email',
  /** column name */
  encryptionScheme = 'encryptionScheme',
  /** column name */
  factor = 'factor',
  /** column name */
  id = 'id',
  /** column name */
  insertInstant = 'insertInstant',
  /** column name */
  lastLoginInstant = 'lastLoginInstant',
  /** column name */
  lastUpdateInstant = 'lastUpdateInstant',
  /** column name */
  password = 'password',
  /** column name */
  passwordChangeReason = 'passwordChangeReason',
  /** column name */
  passwordChangeRequired = 'passwordChangeRequired',
  /** column name */
  passwordLastUpdateInstant = 'passwordLastUpdateInstant',
  /** column name */
  salt = 'salt',
  /** column name */
  status = 'status',
  /** column name */
  tenantsId = 'tenantsId',
  /** column name */
  username = 'username',
  /** column name */
  usernameIndex = 'usernameIndex',
  /** column name */
  usernameStatus = 'usernameStatus',
  /** column name */
  usersId = 'usersId',
  /** column name */
  verified = 'verified'
}

export type IdentitiesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IdentitiesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IdentitiesSetInput>;
  /** filter the rows which have to be updated */
  where: IdentitiesBoolExp;
};

/** aggregate varPop on columns */
export type IdentitiesVarPopFields = {
  __typename?: 'IdentitiesVarPopFields';
  breachedPasswordLastCheckedInstant?: Maybe<Scalars['Float']>;
  breachedPasswordStatus?: Maybe<Scalars['Float']>;
  factor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insertInstant?: Maybe<Scalars['Float']>;
  lastLoginInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
  passwordChangeReason?: Maybe<Scalars['Float']>;
  passwordLastUpdateInstant?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  usernameStatus?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "fusionauth.identities" */
export type IdentitiesVarPopOrderBy = {
  breachedPasswordLastCheckedInstant?: InputMaybe<OrderBy>;
  breachedPasswordStatus?: InputMaybe<OrderBy>;
  factor?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
  lastUpdateInstant?: InputMaybe<OrderBy>;
  passwordChangeReason?: InputMaybe<OrderBy>;
  passwordLastUpdateInstant?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  usernameStatus?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type IdentitiesVarSampFields = {
  __typename?: 'IdentitiesVarSampFields';
  breachedPasswordLastCheckedInstant?: Maybe<Scalars['Float']>;
  breachedPasswordStatus?: Maybe<Scalars['Float']>;
  factor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insertInstant?: Maybe<Scalars['Float']>;
  lastLoginInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
  passwordChangeReason?: Maybe<Scalars['Float']>;
  passwordLastUpdateInstant?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  usernameStatus?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "fusionauth.identities" */
export type IdentitiesVarSampOrderBy = {
  breachedPasswordLastCheckedInstant?: InputMaybe<OrderBy>;
  breachedPasswordStatus?: InputMaybe<OrderBy>;
  factor?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
  lastUpdateInstant?: InputMaybe<OrderBy>;
  passwordChangeReason?: InputMaybe<OrderBy>;
  passwordLastUpdateInstant?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  usernameStatus?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type IdentitiesVarianceFields = {
  __typename?: 'IdentitiesVarianceFields';
  breachedPasswordLastCheckedInstant?: Maybe<Scalars['Float']>;
  breachedPasswordStatus?: Maybe<Scalars['Float']>;
  factor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insertInstant?: Maybe<Scalars['Float']>;
  lastLoginInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
  passwordChangeReason?: Maybe<Scalars['Float']>;
  passwordLastUpdateInstant?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  usernameStatus?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "fusionauth.identities" */
export type IdentitiesVarianceOrderBy = {
  breachedPasswordLastCheckedInstant?: InputMaybe<OrderBy>;
  breachedPasswordStatus?: InputMaybe<OrderBy>;
  factor?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
  lastUpdateInstant?: InputMaybe<OrderBy>;
  passwordChangeReason?: InputMaybe<OrderBy>;
  passwordLastUpdateInstant?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  usernameStatus?: InputMaybe<OrderBy>;
};

/** columns and relationships of "fusionauth.identity_provider_links" */
export type IdentityProviderLinks = {
  __typename?: 'IdentityProviderLinks';
  data: Scalars['String'];
  /** An object relationship */
  identityProvider: IdentityProviders;
  identityProvidersId: Scalars['uuid'];
  identityProvidersUserId: Scalars['String'];
  insertInstant: Scalars['bigint'];
  lastLoginInstant: Scalars['bigint'];
  tenantsId: Scalars['uuid'];
  /** An object relationship */
  user: Users;
  usersId: Scalars['uuid'];
};

/** aggregated selection of "fusionauth.identity_provider_links" */
export type IdentityProviderLinksAggregate = {
  __typename?: 'IdentityProviderLinksAggregate';
  aggregate?: Maybe<IdentityProviderLinksAggregateFields>;
  nodes: Array<IdentityProviderLinks>;
};

export type IdentityProviderLinksAggregateBoolExp = {
  count?: InputMaybe<IdentityProviderLinksAggregateBoolExpCount>;
};

export type IdentityProviderLinksAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IdentityProviderLinksSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<IdentityProviderLinksBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "fusionauth.identity_provider_links" */
export type IdentityProviderLinksAggregateFields = {
  __typename?: 'IdentityProviderLinksAggregateFields';
  avg?: Maybe<IdentityProviderLinksAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IdentityProviderLinksMaxFields>;
  min?: Maybe<IdentityProviderLinksMinFields>;
  stddev?: Maybe<IdentityProviderLinksStddevFields>;
  stddevPop?: Maybe<IdentityProviderLinksStddevPopFields>;
  stddevSamp?: Maybe<IdentityProviderLinksStddevSampFields>;
  sum?: Maybe<IdentityProviderLinksSumFields>;
  varPop?: Maybe<IdentityProviderLinksVarPopFields>;
  varSamp?: Maybe<IdentityProviderLinksVarSampFields>;
  variance?: Maybe<IdentityProviderLinksVarianceFields>;
};

/** aggregate fields of "fusionauth.identity_provider_links" */
export type IdentityProviderLinksAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IdentityProviderLinksSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "fusionauth.identity_provider_links" */
export type IdentityProviderLinksAggregateOrderBy = {
  avg?: InputMaybe<IdentityProviderLinksAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<IdentityProviderLinksMaxOrderBy>;
  min?: InputMaybe<IdentityProviderLinksMinOrderBy>;
  stddev?: InputMaybe<IdentityProviderLinksStddevOrderBy>;
  stddevPop?: InputMaybe<IdentityProviderLinksStddevPopOrderBy>;
  stddevSamp?: InputMaybe<IdentityProviderLinksStddevSampOrderBy>;
  sum?: InputMaybe<IdentityProviderLinksSumOrderBy>;
  varPop?: InputMaybe<IdentityProviderLinksVarPopOrderBy>;
  varSamp?: InputMaybe<IdentityProviderLinksVarSampOrderBy>;
  variance?: InputMaybe<IdentityProviderLinksVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "fusionauth.identity_provider_links" */
export type IdentityProviderLinksArrRelInsertInput = {
  data: Array<IdentityProviderLinksInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<IdentityProviderLinksOnConflict>;
};

/** aggregate avg on columns */
export type IdentityProviderLinksAvgFields = {
  __typename?: 'IdentityProviderLinksAvgFields';
  insertInstant?: Maybe<Scalars['Float']>;
  lastLoginInstant?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "fusionauth.identity_provider_links" */
export type IdentityProviderLinksAvgOrderBy = {
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "fusionauth.identity_provider_links". All fields are combined with a logical 'AND'. */
export type IdentityProviderLinksBoolExp = {
  _and?: InputMaybe<Array<IdentityProviderLinksBoolExp>>;
  _not?: InputMaybe<IdentityProviderLinksBoolExp>;
  _or?: InputMaybe<Array<IdentityProviderLinksBoolExp>>;
  data?: InputMaybe<StringComparisonExp>;
  identityProvider?: InputMaybe<IdentityProvidersBoolExp>;
  identityProvidersId?: InputMaybe<UuidComparisonExp>;
  identityProvidersUserId?: InputMaybe<StringComparisonExp>;
  insertInstant?: InputMaybe<BigintComparisonExp>;
  lastLoginInstant?: InputMaybe<BigintComparisonExp>;
  tenantsId?: InputMaybe<UuidComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  usersId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "fusionauth.identity_provider_links" */
export enum IdentityProviderLinksConstraint {
  /** unique or primary key constraint on columns "identity_providers_user_id", "identity_providers_id", "tenants_id" */
  identity_provider_links_uk_1 = 'identity_provider_links_uk_1'
}

/** input type for incrementing numeric columns in table "fusionauth.identity_provider_links" */
export type IdentityProviderLinksIncInput = {
  insertInstant?: InputMaybe<Scalars['bigint']>;
  lastLoginInstant?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "fusionauth.identity_provider_links" */
export type IdentityProviderLinksInsertInput = {
  data?: InputMaybe<Scalars['String']>;
  identityProvider?: InputMaybe<IdentityProvidersObjRelInsertInput>;
  identityProvidersId?: InputMaybe<Scalars['uuid']>;
  identityProvidersUserId?: InputMaybe<Scalars['String']>;
  insertInstant?: InputMaybe<Scalars['bigint']>;
  lastLoginInstant?: InputMaybe<Scalars['bigint']>;
  tenantsId?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  usersId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type IdentityProviderLinksMaxFields = {
  __typename?: 'IdentityProviderLinksMaxFields';
  data?: Maybe<Scalars['String']>;
  identityProvidersId?: Maybe<Scalars['uuid']>;
  identityProvidersUserId?: Maybe<Scalars['String']>;
  insertInstant?: Maybe<Scalars['bigint']>;
  lastLoginInstant?: Maybe<Scalars['bigint']>;
  tenantsId?: Maybe<Scalars['uuid']>;
  usersId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "fusionauth.identity_provider_links" */
export type IdentityProviderLinksMaxOrderBy = {
  data?: InputMaybe<OrderBy>;
  identityProvidersId?: InputMaybe<OrderBy>;
  identityProvidersUserId?: InputMaybe<OrderBy>;
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
  tenantsId?: InputMaybe<OrderBy>;
  usersId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type IdentityProviderLinksMinFields = {
  __typename?: 'IdentityProviderLinksMinFields';
  data?: Maybe<Scalars['String']>;
  identityProvidersId?: Maybe<Scalars['uuid']>;
  identityProvidersUserId?: Maybe<Scalars['String']>;
  insertInstant?: Maybe<Scalars['bigint']>;
  lastLoginInstant?: Maybe<Scalars['bigint']>;
  tenantsId?: Maybe<Scalars['uuid']>;
  usersId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "fusionauth.identity_provider_links" */
export type IdentityProviderLinksMinOrderBy = {
  data?: InputMaybe<OrderBy>;
  identityProvidersId?: InputMaybe<OrderBy>;
  identityProvidersUserId?: InputMaybe<OrderBy>;
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
  tenantsId?: InputMaybe<OrderBy>;
  usersId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "fusionauth.identity_provider_links" */
export type IdentityProviderLinksMutationResponse = {
  __typename?: 'IdentityProviderLinksMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IdentityProviderLinks>;
};

/** on_conflict condition type for table "fusionauth.identity_provider_links" */
export type IdentityProviderLinksOnConflict = {
  constraint: IdentityProviderLinksConstraint;
  updateColumns?: Array<IdentityProviderLinksUpdateColumn>;
  where?: InputMaybe<IdentityProviderLinksBoolExp>;
};

/** Ordering options when selecting data from "fusionauth.identity_provider_links". */
export type IdentityProviderLinksOrderBy = {
  data?: InputMaybe<OrderBy>;
  identityProvider?: InputMaybe<IdentityProvidersOrderBy>;
  identityProvidersId?: InputMaybe<OrderBy>;
  identityProvidersUserId?: InputMaybe<OrderBy>;
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
  tenantsId?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  usersId?: InputMaybe<OrderBy>;
};

/** select columns of table "fusionauth.identity_provider_links" */
export enum IdentityProviderLinksSelectColumn {
  /** column name */
  data = 'data',
  /** column name */
  identityProvidersId = 'identityProvidersId',
  /** column name */
  identityProvidersUserId = 'identityProvidersUserId',
  /** column name */
  insertInstant = 'insertInstant',
  /** column name */
  lastLoginInstant = 'lastLoginInstant',
  /** column name */
  tenantsId = 'tenantsId',
  /** column name */
  usersId = 'usersId'
}

/** input type for updating data in table "fusionauth.identity_provider_links" */
export type IdentityProviderLinksSetInput = {
  data?: InputMaybe<Scalars['String']>;
  identityProvidersId?: InputMaybe<Scalars['uuid']>;
  identityProvidersUserId?: InputMaybe<Scalars['String']>;
  insertInstant?: InputMaybe<Scalars['bigint']>;
  lastLoginInstant?: InputMaybe<Scalars['bigint']>;
  tenantsId?: InputMaybe<Scalars['uuid']>;
  usersId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type IdentityProviderLinksStddevFields = {
  __typename?: 'IdentityProviderLinksStddevFields';
  insertInstant?: Maybe<Scalars['Float']>;
  lastLoginInstant?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "fusionauth.identity_provider_links" */
export type IdentityProviderLinksStddevOrderBy = {
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type IdentityProviderLinksStddevPopFields = {
  __typename?: 'IdentityProviderLinksStddevPopFields';
  insertInstant?: Maybe<Scalars['Float']>;
  lastLoginInstant?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "fusionauth.identity_provider_links" */
export type IdentityProviderLinksStddevPopOrderBy = {
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type IdentityProviderLinksStddevSampFields = {
  __typename?: 'IdentityProviderLinksStddevSampFields';
  insertInstant?: Maybe<Scalars['Float']>;
  lastLoginInstant?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "fusionauth.identity_provider_links" */
export type IdentityProviderLinksStddevSampOrderBy = {
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "IdentityProviderLinks" */
export type IdentityProviderLinksStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: IdentityProviderLinksStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IdentityProviderLinksStreamCursorValueInput = {
  data?: InputMaybe<Scalars['String']>;
  identityProvidersId?: InputMaybe<Scalars['uuid']>;
  identityProvidersUserId?: InputMaybe<Scalars['String']>;
  insertInstant?: InputMaybe<Scalars['bigint']>;
  lastLoginInstant?: InputMaybe<Scalars['bigint']>;
  tenantsId?: InputMaybe<Scalars['uuid']>;
  usersId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type IdentityProviderLinksSumFields = {
  __typename?: 'IdentityProviderLinksSumFields';
  insertInstant?: Maybe<Scalars['bigint']>;
  lastLoginInstant?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "fusionauth.identity_provider_links" */
export type IdentityProviderLinksSumOrderBy = {
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
};

/** update columns of table "fusionauth.identity_provider_links" */
export enum IdentityProviderLinksUpdateColumn {
  /** column name */
  data = 'data',
  /** column name */
  identityProvidersId = 'identityProvidersId',
  /** column name */
  identityProvidersUserId = 'identityProvidersUserId',
  /** column name */
  insertInstant = 'insertInstant',
  /** column name */
  lastLoginInstant = 'lastLoginInstant',
  /** column name */
  tenantsId = 'tenantsId',
  /** column name */
  usersId = 'usersId'
}

export type IdentityProviderLinksUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IdentityProviderLinksIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IdentityProviderLinksSetInput>;
  /** filter the rows which have to be updated */
  where: IdentityProviderLinksBoolExp;
};

/** aggregate varPop on columns */
export type IdentityProviderLinksVarPopFields = {
  __typename?: 'IdentityProviderLinksVarPopFields';
  insertInstant?: Maybe<Scalars['Float']>;
  lastLoginInstant?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "fusionauth.identity_provider_links" */
export type IdentityProviderLinksVarPopOrderBy = {
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type IdentityProviderLinksVarSampFields = {
  __typename?: 'IdentityProviderLinksVarSampFields';
  insertInstant?: Maybe<Scalars['Float']>;
  lastLoginInstant?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "fusionauth.identity_provider_links" */
export type IdentityProviderLinksVarSampOrderBy = {
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type IdentityProviderLinksVarianceFields = {
  __typename?: 'IdentityProviderLinksVarianceFields';
  insertInstant?: Maybe<Scalars['Float']>;
  lastLoginInstant?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "fusionauth.identity_provider_links" */
export type IdentityProviderLinksVarianceOrderBy = {
  insertInstant?: InputMaybe<OrderBy>;
  lastLoginInstant?: InputMaybe<OrderBy>;
};

/** columns and relationships of "fusionauth.identity_providers" */
export type IdentityProviders = {
  __typename?: 'IdentityProviders';
  data: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['uuid'];
  /** An array relationship */
  identityProviderLinks: Array<IdentityProviderLinks>;
  /** An aggregate relationship */
  identityProviderLinksAggregate: IdentityProviderLinksAggregate;
  insertInstant: Scalars['bigint'];
  keysId?: Maybe<Scalars['uuid']>;
  lastUpdateInstant: Scalars['bigint'];
  name: Scalars['String'];
  reconcileLambdasId?: Maybe<Scalars['uuid']>;
  requestSigningKeysId?: Maybe<Scalars['uuid']>;
  type: Scalars['String'];
};

/** columns and relationships of "fusionauth.identity_providers" */
export type IdentityProvidersIdentityProviderLinksArgs = {
  distinctOn?: InputMaybe<Array<IdentityProviderLinksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IdentityProviderLinksOrderBy>>;
  where?: InputMaybe<IdentityProviderLinksBoolExp>;
};

/** columns and relationships of "fusionauth.identity_providers" */
export type IdentityProvidersIdentityProviderLinksAggregateArgs = {
  distinctOn?: InputMaybe<Array<IdentityProviderLinksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IdentityProviderLinksOrderBy>>;
  where?: InputMaybe<IdentityProviderLinksBoolExp>;
};

/** aggregated selection of "fusionauth.identity_providers" */
export type IdentityProvidersAggregate = {
  __typename?: 'IdentityProvidersAggregate';
  aggregate?: Maybe<IdentityProvidersAggregateFields>;
  nodes: Array<IdentityProviders>;
};

/** aggregate fields of "fusionauth.identity_providers" */
export type IdentityProvidersAggregateFields = {
  __typename?: 'IdentityProvidersAggregateFields';
  avg?: Maybe<IdentityProvidersAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<IdentityProvidersMaxFields>;
  min?: Maybe<IdentityProvidersMinFields>;
  stddev?: Maybe<IdentityProvidersStddevFields>;
  stddevPop?: Maybe<IdentityProvidersStddevPopFields>;
  stddevSamp?: Maybe<IdentityProvidersStddevSampFields>;
  sum?: Maybe<IdentityProvidersSumFields>;
  varPop?: Maybe<IdentityProvidersVarPopFields>;
  varSamp?: Maybe<IdentityProvidersVarSampFields>;
  variance?: Maybe<IdentityProvidersVarianceFields>;
};

/** aggregate fields of "fusionauth.identity_providers" */
export type IdentityProvidersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IdentityProvidersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type IdentityProvidersAvgFields = {
  __typename?: 'IdentityProvidersAvgFields';
  insertInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "fusionauth.identity_providers". All fields are combined with a logical 'AND'. */
export type IdentityProvidersBoolExp = {
  _and?: InputMaybe<Array<IdentityProvidersBoolExp>>;
  _not?: InputMaybe<IdentityProvidersBoolExp>;
  _or?: InputMaybe<Array<IdentityProvidersBoolExp>>;
  data?: InputMaybe<StringComparisonExp>;
  enabled?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  identityProviderLinks?: InputMaybe<IdentityProviderLinksBoolExp>;
  identityProviderLinksAggregate?: InputMaybe<IdentityProviderLinksAggregateBoolExp>;
  insertInstant?: InputMaybe<BigintComparisonExp>;
  keysId?: InputMaybe<UuidComparisonExp>;
  lastUpdateInstant?: InputMaybe<BigintComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  reconcileLambdasId?: InputMaybe<UuidComparisonExp>;
  requestSigningKeysId?: InputMaybe<UuidComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "fusionauth.identity_providers" */
export enum IdentityProvidersConstraint {
  /** unique or primary key constraint on columns "id" */
  identity_providers_pkey = 'identity_providers_pkey',
  /** unique or primary key constraint on columns "name" */
  identity_providers_uk_1 = 'identity_providers_uk_1'
}

/** input type for incrementing numeric columns in table "fusionauth.identity_providers" */
export type IdentityProvidersIncInput = {
  insertInstant?: InputMaybe<Scalars['bigint']>;
  lastUpdateInstant?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "fusionauth.identity_providers" */
export type IdentityProvidersInsertInput = {
  data?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  identityProviderLinks?: InputMaybe<IdentityProviderLinksArrRelInsertInput>;
  insertInstant?: InputMaybe<Scalars['bigint']>;
  keysId?: InputMaybe<Scalars['uuid']>;
  lastUpdateInstant?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  reconcileLambdasId?: InputMaybe<Scalars['uuid']>;
  requestSigningKeysId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type IdentityProvidersMaxFields = {
  __typename?: 'IdentityProvidersMaxFields';
  data?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insertInstant?: Maybe<Scalars['bigint']>;
  keysId?: Maybe<Scalars['uuid']>;
  lastUpdateInstant?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  reconcileLambdasId?: Maybe<Scalars['uuid']>;
  requestSigningKeysId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type IdentityProvidersMinFields = {
  __typename?: 'IdentityProvidersMinFields';
  data?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insertInstant?: Maybe<Scalars['bigint']>;
  keysId?: Maybe<Scalars['uuid']>;
  lastUpdateInstant?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  reconcileLambdasId?: Maybe<Scalars['uuid']>;
  requestSigningKeysId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "fusionauth.identity_providers" */
export type IdentityProvidersMutationResponse = {
  __typename?: 'IdentityProvidersMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<IdentityProviders>;
};

/** input type for inserting object relation for remote table "fusionauth.identity_providers" */
export type IdentityProvidersObjRelInsertInput = {
  data: IdentityProvidersInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<IdentityProvidersOnConflict>;
};

/** on_conflict condition type for table "fusionauth.identity_providers" */
export type IdentityProvidersOnConflict = {
  constraint: IdentityProvidersConstraint;
  updateColumns?: Array<IdentityProvidersUpdateColumn>;
  where?: InputMaybe<IdentityProvidersBoolExp>;
};

/** Ordering options when selecting data from "fusionauth.identity_providers". */
export type IdentityProvidersOrderBy = {
  data?: InputMaybe<OrderBy>;
  enabled?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  identityProviderLinksAggregate?: InputMaybe<IdentityProviderLinksAggregateOrderBy>;
  insertInstant?: InputMaybe<OrderBy>;
  keysId?: InputMaybe<OrderBy>;
  lastUpdateInstant?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  reconcileLambdasId?: InputMaybe<OrderBy>;
  requestSigningKeysId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: fusionauth.identity_providers */
export type IdentityProvidersPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "fusionauth.identity_providers" */
export enum IdentityProvidersSelectColumn {
  /** column name */
  data = 'data',
  /** column name */
  enabled = 'enabled',
  /** column name */
  id = 'id',
  /** column name */
  insertInstant = 'insertInstant',
  /** column name */
  keysId = 'keysId',
  /** column name */
  lastUpdateInstant = 'lastUpdateInstant',
  /** column name */
  name = 'name',
  /** column name */
  reconcileLambdasId = 'reconcileLambdasId',
  /** column name */
  requestSigningKeysId = 'requestSigningKeysId',
  /** column name */
  type = 'type'
}

/** input type for updating data in table "fusionauth.identity_providers" */
export type IdentityProvidersSetInput = {
  data?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertInstant?: InputMaybe<Scalars['bigint']>;
  keysId?: InputMaybe<Scalars['uuid']>;
  lastUpdateInstant?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  reconcileLambdasId?: InputMaybe<Scalars['uuid']>;
  requestSigningKeysId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type IdentityProvidersStddevFields = {
  __typename?: 'IdentityProvidersStddevFields';
  insertInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type IdentityProvidersStddevPopFields = {
  __typename?: 'IdentityProvidersStddevPopFields';
  insertInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type IdentityProvidersStddevSampFields = {
  __typename?: 'IdentityProvidersStddevSampFields';
  insertInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "IdentityProviders" */
export type IdentityProvidersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: IdentityProvidersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IdentityProvidersStreamCursorValueInput = {
  data?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertInstant?: InputMaybe<Scalars['bigint']>;
  keysId?: InputMaybe<Scalars['uuid']>;
  lastUpdateInstant?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  reconcileLambdasId?: InputMaybe<Scalars['uuid']>;
  requestSigningKeysId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type IdentityProvidersSumFields = {
  __typename?: 'IdentityProvidersSumFields';
  insertInstant?: Maybe<Scalars['bigint']>;
  lastUpdateInstant?: Maybe<Scalars['bigint']>;
};

/** update columns of table "fusionauth.identity_providers" */
export enum IdentityProvidersUpdateColumn {
  /** column name */
  data = 'data',
  /** column name */
  enabled = 'enabled',
  /** column name */
  id = 'id',
  /** column name */
  insertInstant = 'insertInstant',
  /** column name */
  keysId = 'keysId',
  /** column name */
  lastUpdateInstant = 'lastUpdateInstant',
  /** column name */
  name = 'name',
  /** column name */
  reconcileLambdasId = 'reconcileLambdasId',
  /** column name */
  requestSigningKeysId = 'requestSigningKeysId',
  /** column name */
  type = 'type'
}

export type IdentityProvidersUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IdentityProvidersIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IdentityProvidersSetInput>;
  /** filter the rows which have to be updated */
  where: IdentityProvidersBoolExp;
};

/** aggregate varPop on columns */
export type IdentityProvidersVarPopFields = {
  __typename?: 'IdentityProvidersVarPopFields';
  insertInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type IdentityProvidersVarSampFields = {
  __typename?: 'IdentityProvidersVarSampFields';
  insertInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type IdentityProvidersVarianceFields = {
  __typename?: 'IdentityProvidersVarianceFields';
  insertInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "impact_assessment_attempts" */
export type ImpactAssessmentAttempts = {
  __typename?: 'ImpactAssessmentAttempts';
  /** An object relationship */
  cohort: Cohorts;
  cohortId: Scalars['uuid'];
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** An object relationship */
  impactAssessment?: Maybe<ImpactAssessments>;
  impactAssessmentId?: Maybe<Scalars['uuid']>;
  isPassing?: Maybe<Scalars['Boolean']>;
  learnerAnswers?: Maybe<Scalars['json']>;
  percentCorrect?: Maybe<Scalars['numeric']>;
  /** Holds a list of question ids from impact_assessment_questions table */
  questionIds?: Maybe<Scalars['json']>;
  /** A computed field, executes function "get_attempt_questions" */
  questions?: Maybe<Array<ImpactAssessmentQuestions>>;
  remainingTime?: Maybe<Scalars['bigint']>;
  startedAt?: Maybe<Scalars['timestamp']>;
  status?: Maybe<AttemptStatusEnum>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  updatedBy?: Maybe<Users>;
  updatedById?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "impact_assessment_attempts" */
export type ImpactAssessmentAttemptsLearnerAnswersArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "impact_assessment_attempts" */
export type ImpactAssessmentAttemptsQuestionIdsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "impact_assessment_attempts" */
export type ImpactAssessmentAttemptsQuestionsArgs = {
  distinctOn?: InputMaybe<Array<ImpactAssessmentQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImpactAssessmentQuestionsOrderBy>>;
  where?: InputMaybe<ImpactAssessmentQuestionsBoolExp>;
};

/** aggregated selection of "impact_assessment_attempts" */
export type ImpactAssessmentAttemptsAggregate = {
  __typename?: 'ImpactAssessmentAttemptsAggregate';
  aggregate?: Maybe<ImpactAssessmentAttemptsAggregateFields>;
  nodes: Array<ImpactAssessmentAttempts>;
};

export type ImpactAssessmentAttemptsAggregateBoolExp = {
  bool_and?: InputMaybe<ImpactAssessmentAttemptsAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<ImpactAssessmentAttemptsAggregateBoolExpBool_Or>;
  count?: InputMaybe<ImpactAssessmentAttemptsAggregateBoolExpCount>;
};

/** aggregate fields of "impact_assessment_attempts" */
export type ImpactAssessmentAttemptsAggregateFields = {
  __typename?: 'ImpactAssessmentAttemptsAggregateFields';
  avg?: Maybe<ImpactAssessmentAttemptsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ImpactAssessmentAttemptsMaxFields>;
  min?: Maybe<ImpactAssessmentAttemptsMinFields>;
  stddev?: Maybe<ImpactAssessmentAttemptsStddevFields>;
  stddevPop?: Maybe<ImpactAssessmentAttemptsStddevPopFields>;
  stddevSamp?: Maybe<ImpactAssessmentAttemptsStddevSampFields>;
  sum?: Maybe<ImpactAssessmentAttemptsSumFields>;
  varPop?: Maybe<ImpactAssessmentAttemptsVarPopFields>;
  varSamp?: Maybe<ImpactAssessmentAttemptsVarSampFields>;
  variance?: Maybe<ImpactAssessmentAttemptsVarianceFields>;
};

/** aggregate fields of "impact_assessment_attempts" */
export type ImpactAssessmentAttemptsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ImpactAssessmentAttemptsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "impact_assessment_attempts" */
export type ImpactAssessmentAttemptsAggregateOrderBy = {
  avg?: InputMaybe<ImpactAssessmentAttemptsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ImpactAssessmentAttemptsMaxOrderBy>;
  min?: InputMaybe<ImpactAssessmentAttemptsMinOrderBy>;
  stddev?: InputMaybe<ImpactAssessmentAttemptsStddevOrderBy>;
  stddevPop?: InputMaybe<ImpactAssessmentAttemptsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ImpactAssessmentAttemptsStddevSampOrderBy>;
  sum?: InputMaybe<ImpactAssessmentAttemptsSumOrderBy>;
  varPop?: InputMaybe<ImpactAssessmentAttemptsVarPopOrderBy>;
  varSamp?: InputMaybe<ImpactAssessmentAttemptsVarSampOrderBy>;
  variance?: InputMaybe<ImpactAssessmentAttemptsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "impact_assessment_attempts" */
export type ImpactAssessmentAttemptsArrRelInsertInput = {
  data: Array<ImpactAssessmentAttemptsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ImpactAssessmentAttemptsOnConflict>;
};

/** aggregate avg on columns */
export type ImpactAssessmentAttemptsAvgFields = {
  __typename?: 'ImpactAssessmentAttemptsAvgFields';
  percentCorrect?: Maybe<Scalars['Float']>;
  remainingTime?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "impact_assessment_attempts" */
export type ImpactAssessmentAttemptsAvgOrderBy = {
  percentCorrect?: InputMaybe<OrderBy>;
  remainingTime?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "impact_assessment_attempts". All fields are combined with a logical 'AND'. */
export type ImpactAssessmentAttemptsBoolExp = {
  _and?: InputMaybe<Array<ImpactAssessmentAttemptsBoolExp>>;
  _not?: InputMaybe<ImpactAssessmentAttemptsBoolExp>;
  _or?: InputMaybe<Array<ImpactAssessmentAttemptsBoolExp>>;
  cohort?: InputMaybe<CohortsBoolExp>;
  cohortId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  impactAssessment?: InputMaybe<ImpactAssessmentsBoolExp>;
  impactAssessmentId?: InputMaybe<UuidComparisonExp>;
  isPassing?: InputMaybe<BooleanComparisonExp>;
  learnerAnswers?: InputMaybe<JsonComparisonExp>;
  percentCorrect?: InputMaybe<NumericComparisonExp>;
  questionIds?: InputMaybe<JsonComparisonExp>;
  questions?: InputMaybe<ImpactAssessmentQuestionsBoolExp>;
  remainingTime?: InputMaybe<BigintComparisonExp>;
  startedAt?: InputMaybe<TimestampComparisonExp>;
  status?: InputMaybe<AttemptStatusEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  updatedBy?: InputMaybe<UsersBoolExp>;
  updatedById?: InputMaybe<UuidComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "impact_assessment_attempts" */
export enum ImpactAssessmentAttemptsConstraint {
  /** unique or primary key constraint on columns "id" */
  impact_assessment_attempts_pk = 'impact_assessment_attempts_pk'
}

/** input type for incrementing numeric columns in table "impact_assessment_attempts" */
export type ImpactAssessmentAttemptsIncInput = {
  percentCorrect?: InputMaybe<Scalars['numeric']>;
  remainingTime?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "impact_assessment_attempts" */
export type ImpactAssessmentAttemptsInsertInput = {
  cohort?: InputMaybe<CohortsObjRelInsertInput>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  impactAssessment?: InputMaybe<ImpactAssessmentsObjRelInsertInput>;
  impactAssessmentId?: InputMaybe<Scalars['uuid']>;
  isPassing?: InputMaybe<Scalars['Boolean']>;
  learnerAnswers?: InputMaybe<Scalars['json']>;
  percentCorrect?: InputMaybe<Scalars['numeric']>;
  /** Holds a list of question ids from impact_assessment_questions table */
  questionIds?: InputMaybe<Scalars['json']>;
  remainingTime?: InputMaybe<Scalars['bigint']>;
  startedAt?: InputMaybe<Scalars['timestamp']>;
  status?: InputMaybe<AttemptStatusEnum>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<UsersObjRelInsertInput>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ImpactAssessmentAttemptsMaxFields = {
  __typename?: 'ImpactAssessmentAttemptsMaxFields';
  cohortId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  impactAssessmentId?: Maybe<Scalars['uuid']>;
  percentCorrect?: Maybe<Scalars['numeric']>;
  remainingTime?: Maybe<Scalars['bigint']>;
  startedAt?: Maybe<Scalars['timestamp']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "impact_assessment_attempts" */
export type ImpactAssessmentAttemptsMaxOrderBy = {
  cohortId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  impactAssessmentId?: InputMaybe<OrderBy>;
  percentCorrect?: InputMaybe<OrderBy>;
  remainingTime?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ImpactAssessmentAttemptsMinFields = {
  __typename?: 'ImpactAssessmentAttemptsMinFields';
  cohortId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  impactAssessmentId?: Maybe<Scalars['uuid']>;
  percentCorrect?: Maybe<Scalars['numeric']>;
  remainingTime?: Maybe<Scalars['bigint']>;
  startedAt?: Maybe<Scalars['timestamp']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "impact_assessment_attempts" */
export type ImpactAssessmentAttemptsMinOrderBy = {
  cohortId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  impactAssessmentId?: InputMaybe<OrderBy>;
  percentCorrect?: InputMaybe<OrderBy>;
  remainingTime?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "impact_assessment_attempts" */
export type ImpactAssessmentAttemptsMutationResponse = {
  __typename?: 'ImpactAssessmentAttemptsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ImpactAssessmentAttempts>;
};

/** on_conflict condition type for table "impact_assessment_attempts" */
export type ImpactAssessmentAttemptsOnConflict = {
  constraint: ImpactAssessmentAttemptsConstraint;
  updateColumns?: Array<ImpactAssessmentAttemptsUpdateColumn>;
  where?: InputMaybe<ImpactAssessmentAttemptsBoolExp>;
};

/** Ordering options when selecting data from "impact_assessment_attempts". */
export type ImpactAssessmentAttemptsOrderBy = {
  cohort?: InputMaybe<CohortsOrderBy>;
  cohortId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  impactAssessment?: InputMaybe<ImpactAssessmentsOrderBy>;
  impactAssessmentId?: InputMaybe<OrderBy>;
  isPassing?: InputMaybe<OrderBy>;
  learnerAnswers?: InputMaybe<OrderBy>;
  percentCorrect?: InputMaybe<OrderBy>;
  questionIds?: InputMaybe<OrderBy>;
  questionsAggregate?: InputMaybe<ImpactAssessmentQuestionsAggregateOrderBy>;
  remainingTime?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<UsersOrderBy>;
  updatedById?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: impact_assessment_attempts */
export type ImpactAssessmentAttemptsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "impact_assessment_attempts" */
export enum ImpactAssessmentAttemptsSelectColumn {
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  id = 'id',
  /** column name */
  impactAssessmentId = 'impactAssessmentId',
  /** column name */
  isPassing = 'isPassing',
  /** column name */
  learnerAnswers = 'learnerAnswers',
  /** column name */
  percentCorrect = 'percentCorrect',
  /** column name */
  questionIds = 'questionIds',
  /** column name */
  remainingTime = 'remainingTime',
  /** column name */
  startedAt = 'startedAt',
  /** column name */
  status = 'status',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById',
  /** column name */
  userId = 'userId'
}

/** select "impactAssessmentAttemptsAggregateBoolExpBool_andArgumentsColumns" columns of table "impact_assessment_attempts" */
export enum ImpactAssessmentAttemptsSelectColumnImpactAssessmentAttemptsAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  isPassing = 'isPassing'
}

/** select "impactAssessmentAttemptsAggregateBoolExpBool_orArgumentsColumns" columns of table "impact_assessment_attempts" */
export enum ImpactAssessmentAttemptsSelectColumnImpactAssessmentAttemptsAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  isPassing = 'isPassing'
}

/** input type for updating data in table "impact_assessment_attempts" */
export type ImpactAssessmentAttemptsSetInput = {
  cohortId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  impactAssessmentId?: InputMaybe<Scalars['uuid']>;
  isPassing?: InputMaybe<Scalars['Boolean']>;
  learnerAnswers?: InputMaybe<Scalars['json']>;
  percentCorrect?: InputMaybe<Scalars['numeric']>;
  /** Holds a list of question ids from impact_assessment_questions table */
  questionIds?: InputMaybe<Scalars['json']>;
  remainingTime?: InputMaybe<Scalars['bigint']>;
  startedAt?: InputMaybe<Scalars['timestamp']>;
  status?: InputMaybe<AttemptStatusEnum>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type ImpactAssessmentAttemptsStddevFields = {
  __typename?: 'ImpactAssessmentAttemptsStddevFields';
  percentCorrect?: Maybe<Scalars['Float']>;
  remainingTime?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "impact_assessment_attempts" */
export type ImpactAssessmentAttemptsStddevOrderBy = {
  percentCorrect?: InputMaybe<OrderBy>;
  remainingTime?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ImpactAssessmentAttemptsStddevPopFields = {
  __typename?: 'ImpactAssessmentAttemptsStddevPopFields';
  percentCorrect?: Maybe<Scalars['Float']>;
  remainingTime?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "impact_assessment_attempts" */
export type ImpactAssessmentAttemptsStddevPopOrderBy = {
  percentCorrect?: InputMaybe<OrderBy>;
  remainingTime?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ImpactAssessmentAttemptsStddevSampFields = {
  __typename?: 'ImpactAssessmentAttemptsStddevSampFields';
  percentCorrect?: Maybe<Scalars['Float']>;
  remainingTime?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "impact_assessment_attempts" */
export type ImpactAssessmentAttemptsStddevSampOrderBy = {
  percentCorrect?: InputMaybe<OrderBy>;
  remainingTime?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "impact_assessment_attempts" */
export type ImpactAssessmentAttemptsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ImpactAssessmentAttemptsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ImpactAssessmentAttemptsStreamCursorValueInput = {
  cohortId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  impactAssessmentId?: InputMaybe<Scalars['uuid']>;
  isPassing?: InputMaybe<Scalars['Boolean']>;
  learnerAnswers?: InputMaybe<Scalars['json']>;
  percentCorrect?: InputMaybe<Scalars['numeric']>;
  /** Holds a list of question ids from impact_assessment_questions table */
  questionIds?: InputMaybe<Scalars['json']>;
  remainingTime?: InputMaybe<Scalars['bigint']>;
  startedAt?: InputMaybe<Scalars['timestamp']>;
  status?: InputMaybe<AttemptStatusEnum>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type ImpactAssessmentAttemptsSumFields = {
  __typename?: 'ImpactAssessmentAttemptsSumFields';
  percentCorrect?: Maybe<Scalars['numeric']>;
  remainingTime?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "impact_assessment_attempts" */
export type ImpactAssessmentAttemptsSumOrderBy = {
  percentCorrect?: InputMaybe<OrderBy>;
  remainingTime?: InputMaybe<OrderBy>;
};

/** update columns of table "impact_assessment_attempts" */
export enum ImpactAssessmentAttemptsUpdateColumn {
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  id = 'id',
  /** column name */
  impactAssessmentId = 'impactAssessmentId',
  /** column name */
  isPassing = 'isPassing',
  /** column name */
  learnerAnswers = 'learnerAnswers',
  /** column name */
  percentCorrect = 'percentCorrect',
  /** column name */
  questionIds = 'questionIds',
  /** column name */
  remainingTime = 'remainingTime',
  /** column name */
  startedAt = 'startedAt',
  /** column name */
  status = 'status',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById',
  /** column name */
  userId = 'userId'
}

export type ImpactAssessmentAttemptsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ImpactAssessmentAttemptsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ImpactAssessmentAttemptsSetInput>;
  /** filter the rows which have to be updated */
  where: ImpactAssessmentAttemptsBoolExp;
};

/** aggregate varPop on columns */
export type ImpactAssessmentAttemptsVarPopFields = {
  __typename?: 'ImpactAssessmentAttemptsVarPopFields';
  percentCorrect?: Maybe<Scalars['Float']>;
  remainingTime?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "impact_assessment_attempts" */
export type ImpactAssessmentAttemptsVarPopOrderBy = {
  percentCorrect?: InputMaybe<OrderBy>;
  remainingTime?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ImpactAssessmentAttemptsVarSampFields = {
  __typename?: 'ImpactAssessmentAttemptsVarSampFields';
  percentCorrect?: Maybe<Scalars['Float']>;
  remainingTime?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "impact_assessment_attempts" */
export type ImpactAssessmentAttemptsVarSampOrderBy = {
  percentCorrect?: InputMaybe<OrderBy>;
  remainingTime?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ImpactAssessmentAttemptsVarianceFields = {
  __typename?: 'ImpactAssessmentAttemptsVarianceFields';
  percentCorrect?: Maybe<Scalars['Float']>;
  remainingTime?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "impact_assessment_attempts" */
export type ImpactAssessmentAttemptsVarianceOrderBy = {
  percentCorrect?: InputMaybe<OrderBy>;
  remainingTime?: InputMaybe<OrderBy>;
};

/** columns and relationships of "impact_assessment_questions" */
export type ImpactAssessmentQuestions = {
  __typename?: 'ImpactAssessmentQuestions';
  answers?: Maybe<Scalars['json']>;
  correctAnswers?: Maybe<Scalars['json']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  impactAssessment?: Maybe<ImpactAssessments>;
  impactAssessmentId?: Maybe<Scalars['uuid']>;
  points: Scalars['numeric'];
  questionRich?: Maybe<Scalars['String']>;
  /** An object relationship */
  questionType?: Maybe<QuestionType>;
  type?: Maybe<QuestionTypeEnum>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  updatedBy?: Maybe<Users>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "impact_assessment_questions" */
export type ImpactAssessmentQuestionsAnswersArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "impact_assessment_questions" */
export type ImpactAssessmentQuestionsCorrectAnswersArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "impact_assessment_questions" */
export type ImpactAssessmentQuestionsAggregate = {
  __typename?: 'ImpactAssessmentQuestionsAggregate';
  aggregate?: Maybe<ImpactAssessmentQuestionsAggregateFields>;
  nodes: Array<ImpactAssessmentQuestions>;
};

export type ImpactAssessmentQuestionsAggregateBoolExp = {
  count?: InputMaybe<ImpactAssessmentQuestionsAggregateBoolExpCount>;
};

/** aggregate fields of "impact_assessment_questions" */
export type ImpactAssessmentQuestionsAggregateFields = {
  __typename?: 'ImpactAssessmentQuestionsAggregateFields';
  avg?: Maybe<ImpactAssessmentQuestionsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ImpactAssessmentQuestionsMaxFields>;
  min?: Maybe<ImpactAssessmentQuestionsMinFields>;
  stddev?: Maybe<ImpactAssessmentQuestionsStddevFields>;
  stddevPop?: Maybe<ImpactAssessmentQuestionsStddevPopFields>;
  stddevSamp?: Maybe<ImpactAssessmentQuestionsStddevSampFields>;
  sum?: Maybe<ImpactAssessmentQuestionsSumFields>;
  varPop?: Maybe<ImpactAssessmentQuestionsVarPopFields>;
  varSamp?: Maybe<ImpactAssessmentQuestionsVarSampFields>;
  variance?: Maybe<ImpactAssessmentQuestionsVarianceFields>;
};

/** aggregate fields of "impact_assessment_questions" */
export type ImpactAssessmentQuestionsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ImpactAssessmentQuestionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "impact_assessment_questions" */
export type ImpactAssessmentQuestionsAggregateOrderBy = {
  avg?: InputMaybe<ImpactAssessmentQuestionsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ImpactAssessmentQuestionsMaxOrderBy>;
  min?: InputMaybe<ImpactAssessmentQuestionsMinOrderBy>;
  stddev?: InputMaybe<ImpactAssessmentQuestionsStddevOrderBy>;
  stddevPop?: InputMaybe<ImpactAssessmentQuestionsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ImpactAssessmentQuestionsStddevSampOrderBy>;
  sum?: InputMaybe<ImpactAssessmentQuestionsSumOrderBy>;
  varPop?: InputMaybe<ImpactAssessmentQuestionsVarPopOrderBy>;
  varSamp?: InputMaybe<ImpactAssessmentQuestionsVarSampOrderBy>;
  variance?: InputMaybe<ImpactAssessmentQuestionsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "impact_assessment_questions" */
export type ImpactAssessmentQuestionsArrRelInsertInput = {
  data: Array<ImpactAssessmentQuestionsInsertInput>;
};

/** aggregate avg on columns */
export type ImpactAssessmentQuestionsAvgFields = {
  __typename?: 'ImpactAssessmentQuestionsAvgFields';
  points?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "impact_assessment_questions" */
export type ImpactAssessmentQuestionsAvgOrderBy = {
  points?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "impact_assessment_questions". All fields are combined with a logical 'AND'. */
export type ImpactAssessmentQuestionsBoolExp = {
  _and?: InputMaybe<Array<ImpactAssessmentQuestionsBoolExp>>;
  _not?: InputMaybe<ImpactAssessmentQuestionsBoolExp>;
  _or?: InputMaybe<Array<ImpactAssessmentQuestionsBoolExp>>;
  answers?: InputMaybe<JsonComparisonExp>;
  correctAnswers?: InputMaybe<JsonComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  impactAssessment?: InputMaybe<ImpactAssessmentsBoolExp>;
  impactAssessmentId?: InputMaybe<UuidComparisonExp>;
  points?: InputMaybe<NumericComparisonExp>;
  questionRich?: InputMaybe<StringComparisonExp>;
  questionType?: InputMaybe<QuestionTypeBoolExp>;
  type?: InputMaybe<QuestionTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  updatedBy?: InputMaybe<UsersBoolExp>;
  updatedById?: InputMaybe<UuidComparisonExp>;
};

/** input type for incrementing numeric columns in table "impact_assessment_questions" */
export type ImpactAssessmentQuestionsIncInput = {
  points?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "impact_assessment_questions" */
export type ImpactAssessmentQuestionsInsertInput = {
  answers?: InputMaybe<Scalars['json']>;
  correctAnswers?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  impactAssessment?: InputMaybe<ImpactAssessmentsObjRelInsertInput>;
  impactAssessmentId?: InputMaybe<Scalars['uuid']>;
  points?: InputMaybe<Scalars['numeric']>;
  questionRich?: InputMaybe<Scalars['String']>;
  questionType?: InputMaybe<QuestionTypeObjRelInsertInput>;
  type?: InputMaybe<QuestionTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<UsersObjRelInsertInput>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ImpactAssessmentQuestionsMaxFields = {
  __typename?: 'ImpactAssessmentQuestionsMaxFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  impactAssessmentId?: Maybe<Scalars['uuid']>;
  points?: Maybe<Scalars['numeric']>;
  questionRich?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "impact_assessment_questions" */
export type ImpactAssessmentQuestionsMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  impactAssessmentId?: InputMaybe<OrderBy>;
  points?: InputMaybe<OrderBy>;
  questionRich?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ImpactAssessmentQuestionsMinFields = {
  __typename?: 'ImpactAssessmentQuestionsMinFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  impactAssessmentId?: Maybe<Scalars['uuid']>;
  points?: Maybe<Scalars['numeric']>;
  questionRich?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "impact_assessment_questions" */
export type ImpactAssessmentQuestionsMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  impactAssessmentId?: InputMaybe<OrderBy>;
  points?: InputMaybe<OrderBy>;
  questionRich?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "impact_assessment_questions" */
export type ImpactAssessmentQuestionsMutationResponse = {
  __typename?: 'ImpactAssessmentQuestionsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ImpactAssessmentQuestions>;
};

/** Ordering options when selecting data from "impact_assessment_questions". */
export type ImpactAssessmentQuestionsOrderBy = {
  answers?: InputMaybe<OrderBy>;
  correctAnswers?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  impactAssessment?: InputMaybe<ImpactAssessmentsOrderBy>;
  impactAssessmentId?: InputMaybe<OrderBy>;
  points?: InputMaybe<OrderBy>;
  questionRich?: InputMaybe<OrderBy>;
  questionType?: InputMaybe<QuestionTypeOrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<UsersOrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** select columns of table "impact_assessment_questions" */
export enum ImpactAssessmentQuestionsSelectColumn {
  /** column name */
  answers = 'answers',
  /** column name */
  correctAnswers = 'correctAnswers',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  id = 'id',
  /** column name */
  impactAssessmentId = 'impactAssessmentId',
  /** column name */
  points = 'points',
  /** column name */
  questionRich = 'questionRich',
  /** column name */
  type = 'type',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById'
}

/** input type for updating data in table "impact_assessment_questions" */
export type ImpactAssessmentQuestionsSetInput = {
  answers?: InputMaybe<Scalars['json']>;
  correctAnswers?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  impactAssessmentId?: InputMaybe<Scalars['uuid']>;
  points?: InputMaybe<Scalars['numeric']>;
  questionRich?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<QuestionTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type ImpactAssessmentQuestionsStddevFields = {
  __typename?: 'ImpactAssessmentQuestionsStddevFields';
  points?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "impact_assessment_questions" */
export type ImpactAssessmentQuestionsStddevOrderBy = {
  points?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ImpactAssessmentQuestionsStddevPopFields = {
  __typename?: 'ImpactAssessmentQuestionsStddevPopFields';
  points?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "impact_assessment_questions" */
export type ImpactAssessmentQuestionsStddevPopOrderBy = {
  points?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ImpactAssessmentQuestionsStddevSampFields = {
  __typename?: 'ImpactAssessmentQuestionsStddevSampFields';
  points?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "impact_assessment_questions" */
export type ImpactAssessmentQuestionsStddevSampOrderBy = {
  points?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "impact_assessment_questions" */
export type ImpactAssessmentQuestionsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ImpactAssessmentQuestionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ImpactAssessmentQuestionsStreamCursorValueInput = {
  answers?: InputMaybe<Scalars['json']>;
  correctAnswers?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  impactAssessmentId?: InputMaybe<Scalars['uuid']>;
  points?: InputMaybe<Scalars['numeric']>;
  questionRich?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<QuestionTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type ImpactAssessmentQuestionsSumFields = {
  __typename?: 'ImpactAssessmentQuestionsSumFields';
  points?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "impact_assessment_questions" */
export type ImpactAssessmentQuestionsSumOrderBy = {
  points?: InputMaybe<OrderBy>;
};

export type ImpactAssessmentQuestionsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ImpactAssessmentQuestionsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ImpactAssessmentQuestionsSetInput>;
  /** filter the rows which have to be updated */
  where: ImpactAssessmentQuestionsBoolExp;
};

/** aggregate varPop on columns */
export type ImpactAssessmentQuestionsVarPopFields = {
  __typename?: 'ImpactAssessmentQuestionsVarPopFields';
  points?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "impact_assessment_questions" */
export type ImpactAssessmentQuestionsVarPopOrderBy = {
  points?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ImpactAssessmentQuestionsVarSampFields = {
  __typename?: 'ImpactAssessmentQuestionsVarSampFields';
  points?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "impact_assessment_questions" */
export type ImpactAssessmentQuestionsVarSampOrderBy = {
  points?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ImpactAssessmentQuestionsVarianceFields = {
  __typename?: 'ImpactAssessmentQuestionsVarianceFields';
  points?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "impact_assessment_questions" */
export type ImpactAssessmentQuestionsVarianceOrderBy = {
  points?: InputMaybe<OrderBy>;
};

/** columns and relationships of "impact_assessments" */
export type ImpactAssessments = {
  __typename?: 'ImpactAssessments';
  /** An object relationship */
  course?: Maybe<Courses>;
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  descriptionRich?: Maybe<Scalars['String']>;
  dueWithinDays?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  /** A computed field, executes function "assessment_is_lastest_version" */
  isLastestVersion?: Maybe<Scalars['Boolean']>;
  percentToPass?: Maybe<Scalars['numeric']>;
  questionCount: Scalars['Int'];
  /** An array relationship */
  questions: Array<ImpactAssessmentQuestions>;
  /** An aggregate relationship */
  questionsAggregate: ImpactAssessmentQuestionsAggregate;
  slug: Scalars['String'];
  timeLimitInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  updatedBy?: Maybe<Users>;
  updatedById?: Maybe<Scalars['uuid']>;
  version?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "impact_assessments" */
export type ImpactAssessmentsQuestionsArgs = {
  distinctOn?: InputMaybe<Array<ImpactAssessmentQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImpactAssessmentQuestionsOrderBy>>;
  where?: InputMaybe<ImpactAssessmentQuestionsBoolExp>;
};

/** columns and relationships of "impact_assessments" */
export type ImpactAssessmentsQuestionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ImpactAssessmentQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImpactAssessmentQuestionsOrderBy>>;
  where?: InputMaybe<ImpactAssessmentQuestionsBoolExp>;
};

/** aggregated selection of "impact_assessments" */
export type ImpactAssessmentsAggregate = {
  __typename?: 'ImpactAssessmentsAggregate';
  aggregate?: Maybe<ImpactAssessmentsAggregateFields>;
  nodes: Array<ImpactAssessments>;
};

/** aggregate fields of "impact_assessments" */
export type ImpactAssessmentsAggregateFields = {
  __typename?: 'ImpactAssessmentsAggregateFields';
  avg?: Maybe<ImpactAssessmentsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ImpactAssessmentsMaxFields>;
  min?: Maybe<ImpactAssessmentsMinFields>;
  stddev?: Maybe<ImpactAssessmentsStddevFields>;
  stddevPop?: Maybe<ImpactAssessmentsStddevPopFields>;
  stddevSamp?: Maybe<ImpactAssessmentsStddevSampFields>;
  sum?: Maybe<ImpactAssessmentsSumFields>;
  varPop?: Maybe<ImpactAssessmentsVarPopFields>;
  varSamp?: Maybe<ImpactAssessmentsVarSampFields>;
  variance?: Maybe<ImpactAssessmentsVarianceFields>;
};

/** aggregate fields of "impact_assessments" */
export type ImpactAssessmentsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ImpactAssessmentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ImpactAssessmentsAvgFields = {
  __typename?: 'ImpactAssessmentsAvgFields';
  dueWithinDays?: Maybe<Scalars['Float']>;
  percentToPass?: Maybe<Scalars['Float']>;
  questionCount?: Maybe<Scalars['Float']>;
  timeLimitInMinutes?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "impact_assessments". All fields are combined with a logical 'AND'. */
export type ImpactAssessmentsBoolExp = {
  _and?: InputMaybe<Array<ImpactAssessmentsBoolExp>>;
  _not?: InputMaybe<ImpactAssessmentsBoolExp>;
  _or?: InputMaybe<Array<ImpactAssessmentsBoolExp>>;
  course?: InputMaybe<CoursesBoolExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  descriptionRich?: InputMaybe<StringComparisonExp>;
  dueWithinDays?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isLastestVersion?: InputMaybe<BooleanComparisonExp>;
  percentToPass?: InputMaybe<NumericComparisonExp>;
  questionCount?: InputMaybe<IntComparisonExp>;
  questions?: InputMaybe<ImpactAssessmentQuestionsBoolExp>;
  questionsAggregate?: InputMaybe<ImpactAssessmentQuestionsAggregateBoolExp>;
  slug?: InputMaybe<StringComparisonExp>;
  timeLimitInMinutes?: InputMaybe<IntComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  updatedBy?: InputMaybe<UsersBoolExp>;
  updatedById?: InputMaybe<UuidComparisonExp>;
  version?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "impact_assessments" */
export enum ImpactAssessmentsConstraint {
  /** unique or primary key constraint on columns "id" */
  impact_assessments_pk = 'impact_assessments_pk',
  /** unique or primary key constraint on columns "slug", "version" */
  impact_assessments_slug_version_key = 'impact_assessments_slug_version_key'
}

/** input type for incrementing numeric columns in table "impact_assessments" */
export type ImpactAssessmentsIncInput = {
  dueWithinDays?: InputMaybe<Scalars['Int']>;
  percentToPass?: InputMaybe<Scalars['numeric']>;
  questionCount?: InputMaybe<Scalars['Int']>;
  timeLimitInMinutes?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "impact_assessments" */
export type ImpactAssessmentsInsertInput = {
  course?: InputMaybe<CoursesObjRelInsertInput>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  descriptionRich?: InputMaybe<Scalars['String']>;
  dueWithinDays?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  percentToPass?: InputMaybe<Scalars['numeric']>;
  questionCount?: InputMaybe<Scalars['Int']>;
  questions?: InputMaybe<ImpactAssessmentQuestionsArrRelInsertInput>;
  slug?: InputMaybe<Scalars['String']>;
  timeLimitInMinutes?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<UsersObjRelInsertInput>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ImpactAssessmentsMaxFields = {
  __typename?: 'ImpactAssessmentsMaxFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  descriptionRich?: Maybe<Scalars['String']>;
  dueWithinDays?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  percentToPass?: Maybe<Scalars['numeric']>;
  questionCount?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  timeLimitInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
  version?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ImpactAssessmentsMinFields = {
  __typename?: 'ImpactAssessmentsMinFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  descriptionRich?: Maybe<Scalars['String']>;
  dueWithinDays?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  percentToPass?: Maybe<Scalars['numeric']>;
  questionCount?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  timeLimitInMinutes?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
  version?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "impact_assessments" */
export type ImpactAssessmentsMutationResponse = {
  __typename?: 'ImpactAssessmentsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ImpactAssessments>;
};

/** input type for inserting object relation for remote table "impact_assessments" */
export type ImpactAssessmentsObjRelInsertInput = {
  data: ImpactAssessmentsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ImpactAssessmentsOnConflict>;
};

/** on_conflict condition type for table "impact_assessments" */
export type ImpactAssessmentsOnConflict = {
  constraint: ImpactAssessmentsConstraint;
  updateColumns?: Array<ImpactAssessmentsUpdateColumn>;
  where?: InputMaybe<ImpactAssessmentsBoolExp>;
};

/** Ordering options when selecting data from "impact_assessments". */
export type ImpactAssessmentsOrderBy = {
  course?: InputMaybe<CoursesOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  descriptionRich?: InputMaybe<OrderBy>;
  dueWithinDays?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isLastestVersion?: InputMaybe<OrderBy>;
  percentToPass?: InputMaybe<OrderBy>;
  questionCount?: InputMaybe<OrderBy>;
  questionsAggregate?: InputMaybe<ImpactAssessmentQuestionsAggregateOrderBy>;
  slug?: InputMaybe<OrderBy>;
  timeLimitInMinutes?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<UsersOrderBy>;
  updatedById?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: impact_assessments */
export type ImpactAssessmentsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "impact_assessments" */
export enum ImpactAssessmentsSelectColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  descriptionRich = 'descriptionRich',
  /** column name */
  dueWithinDays = 'dueWithinDays',
  /** column name */
  id = 'id',
  /** column name */
  percentToPass = 'percentToPass',
  /** column name */
  questionCount = 'questionCount',
  /** column name */
  slug = 'slug',
  /** column name */
  timeLimitInMinutes = 'timeLimitInMinutes',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById',
  /** column name */
  version = 'version'
}

/** input type for updating data in table "impact_assessments" */
export type ImpactAssessmentsSetInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  descriptionRich?: InputMaybe<Scalars['String']>;
  dueWithinDays?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  percentToPass?: InputMaybe<Scalars['numeric']>;
  questionCount?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  timeLimitInMinutes?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ImpactAssessmentsStddevFields = {
  __typename?: 'ImpactAssessmentsStddevFields';
  dueWithinDays?: Maybe<Scalars['Float']>;
  percentToPass?: Maybe<Scalars['Float']>;
  questionCount?: Maybe<Scalars['Float']>;
  timeLimitInMinutes?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type ImpactAssessmentsStddevPopFields = {
  __typename?: 'ImpactAssessmentsStddevPopFields';
  dueWithinDays?: Maybe<Scalars['Float']>;
  percentToPass?: Maybe<Scalars['Float']>;
  questionCount?: Maybe<Scalars['Float']>;
  timeLimitInMinutes?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type ImpactAssessmentsStddevSampFields = {
  __typename?: 'ImpactAssessmentsStddevSampFields';
  dueWithinDays?: Maybe<Scalars['Float']>;
  percentToPass?: Maybe<Scalars['Float']>;
  questionCount?: Maybe<Scalars['Float']>;
  timeLimitInMinutes?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "impact_assessments" */
export type ImpactAssessmentsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ImpactAssessmentsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ImpactAssessmentsStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  descriptionRich?: InputMaybe<Scalars['String']>;
  dueWithinDays?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  percentToPass?: InputMaybe<Scalars['numeric']>;
  questionCount?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  timeLimitInMinutes?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ImpactAssessmentsSumFields = {
  __typename?: 'ImpactAssessmentsSumFields';
  dueWithinDays?: Maybe<Scalars['Int']>;
  percentToPass?: Maybe<Scalars['numeric']>;
  questionCount?: Maybe<Scalars['Int']>;
  timeLimitInMinutes?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
};

/** update columns of table "impact_assessments" */
export enum ImpactAssessmentsUpdateColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  descriptionRich = 'descriptionRich',
  /** column name */
  dueWithinDays = 'dueWithinDays',
  /** column name */
  id = 'id',
  /** column name */
  percentToPass = 'percentToPass',
  /** column name */
  questionCount = 'questionCount',
  /** column name */
  slug = 'slug',
  /** column name */
  timeLimitInMinutes = 'timeLimitInMinutes',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById',
  /** column name */
  version = 'version'
}

export type ImpactAssessmentsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ImpactAssessmentsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ImpactAssessmentsSetInput>;
  /** filter the rows which have to be updated */
  where: ImpactAssessmentsBoolExp;
};

/** aggregate varPop on columns */
export type ImpactAssessmentsVarPopFields = {
  __typename?: 'ImpactAssessmentsVarPopFields';
  dueWithinDays?: Maybe<Scalars['Float']>;
  percentToPass?: Maybe<Scalars['Float']>;
  questionCount?: Maybe<Scalars['Float']>;
  timeLimitInMinutes?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type ImpactAssessmentsVarSampFields = {
  __typename?: 'ImpactAssessmentsVarSampFields';
  dueWithinDays?: Maybe<Scalars['Float']>;
  percentToPass?: Maybe<Scalars['Float']>;
  questionCount?: Maybe<Scalars['Float']>;
  timeLimitInMinutes?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ImpactAssessmentsVarianceFields = {
  __typename?: 'ImpactAssessmentsVarianceFields';
  dueWithinDays?: Maybe<Scalars['Float']>;
  percentToPass?: Maybe<Scalars['Float']>;
  questionCount?: Maybe<Scalars['Float']>;
  timeLimitInMinutes?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
};

export type ImportUser = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  isManager?: InputMaybe<Scalars['Boolean']>;
  jobFunction?: InputMaybe<Scalars['String']>;
  jobFunctionLength?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  managerEmail?: InputMaybe<Scalars['String']>;
  seatType?: InputMaybe<Scalars['String']>;
  team?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** columns and relationships of "journey_enrollments" */
export type JourneyEnrollments = {
  __typename?: 'JourneyEnrollments';
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  deletedBy?: Maybe<Users>;
  deletedById?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  isComplete?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  journey?: Maybe<Journeys>;
  journeyId?: Maybe<Scalars['uuid']>;
  meta?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  nextTask?: Maybe<Tasks>;
  nextTaskAt?: Maybe<Scalars['timestamp']>;
  nextTaskId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  organization?: Maybe<Organizations>;
  organizationId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "journey_enrollments" */
export type JourneyEnrollmentsMetaArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "journey_enrollments" */
export type JourneyEnrollmentsAggregate = {
  __typename?: 'JourneyEnrollmentsAggregate';
  aggregate?: Maybe<JourneyEnrollmentsAggregateFields>;
  nodes: Array<JourneyEnrollments>;
};

export type JourneyEnrollmentsAggregateBoolExp = {
  bool_and?: InputMaybe<JourneyEnrollmentsAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<JourneyEnrollmentsAggregateBoolExpBool_Or>;
  count?: InputMaybe<JourneyEnrollmentsAggregateBoolExpCount>;
};

/** aggregate fields of "journey_enrollments" */
export type JourneyEnrollmentsAggregateFields = {
  __typename?: 'JourneyEnrollmentsAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<JourneyEnrollmentsMaxFields>;
  min?: Maybe<JourneyEnrollmentsMinFields>;
};

/** aggregate fields of "journey_enrollments" */
export type JourneyEnrollmentsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<JourneyEnrollmentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "journey_enrollments" */
export type JourneyEnrollmentsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<JourneyEnrollmentsMaxOrderBy>;
  min?: InputMaybe<JourneyEnrollmentsMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type JourneyEnrollmentsAppendInput = {
  meta?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "journey_enrollments" */
export type JourneyEnrollmentsArrRelInsertInput = {
  data: Array<JourneyEnrollmentsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<JourneyEnrollmentsOnConflict>;
};

/** Boolean expression to filter rows from the table "journey_enrollments". All fields are combined with a logical 'AND'. */
export type JourneyEnrollmentsBoolExp = {
  _and?: InputMaybe<Array<JourneyEnrollmentsBoolExp>>;
  _not?: InputMaybe<JourneyEnrollmentsBoolExp>;
  _or?: InputMaybe<Array<JourneyEnrollmentsBoolExp>>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  deletedAt?: InputMaybe<TimestampComparisonExp>;
  deletedBy?: InputMaybe<UsersBoolExp>;
  deletedById?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isComplete?: InputMaybe<BooleanComparisonExp>;
  journey?: InputMaybe<JourneysBoolExp>;
  journeyId?: InputMaybe<UuidComparisonExp>;
  meta?: InputMaybe<JsonbComparisonExp>;
  nextTask?: InputMaybe<TasksBoolExp>;
  nextTaskAt?: InputMaybe<TimestampComparisonExp>;
  nextTaskId?: InputMaybe<UuidComparisonExp>;
  organization?: InputMaybe<OrganizationsBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "journey_enrollments" */
export enum JourneyEnrollmentsConstraint {
  /** unique or primary key constraint on columns "id" */
  journey_enrollments_pk = 'journey_enrollments_pk',
  /** unique or primary key constraint on columns "journey_id", "user_id", "organization_id" */
  journey_enrollments_soft_delete_unique_key = 'journey_enrollments_soft_delete_unique_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type JourneyEnrollmentsDeleteAtPathInput = {
  meta?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type JourneyEnrollmentsDeleteElemInput = {
  meta?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type JourneyEnrollmentsDeleteKeyInput = {
  meta?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "journey_enrollments" */
export type JourneyEnrollmentsInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedBy?: InputMaybe<UsersObjRelInsertInput>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isComplete?: InputMaybe<Scalars['Boolean']>;
  journey?: InputMaybe<JourneysObjRelInsertInput>;
  journeyId?: InputMaybe<Scalars['uuid']>;
  meta?: InputMaybe<Scalars['jsonb']>;
  nextTask?: InputMaybe<TasksObjRelInsertInput>;
  nextTaskAt?: InputMaybe<Scalars['timestamp']>;
  nextTaskId?: InputMaybe<Scalars['uuid']>;
  organization?: InputMaybe<OrganizationsObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type JourneyEnrollmentsMaxFields = {
  __typename?: 'JourneyEnrollmentsMaxFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  journeyId?: Maybe<Scalars['uuid']>;
  nextTaskAt?: Maybe<Scalars['timestamp']>;
  nextTaskId?: Maybe<Scalars['uuid']>;
  organizationId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "journey_enrollments" */
export type JourneyEnrollmentsMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  journeyId?: InputMaybe<OrderBy>;
  nextTaskAt?: InputMaybe<OrderBy>;
  nextTaskId?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type JourneyEnrollmentsMinFields = {
  __typename?: 'JourneyEnrollmentsMinFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  journeyId?: Maybe<Scalars['uuid']>;
  nextTaskAt?: Maybe<Scalars['timestamp']>;
  nextTaskId?: Maybe<Scalars['uuid']>;
  organizationId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "journey_enrollments" */
export type JourneyEnrollmentsMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  journeyId?: InputMaybe<OrderBy>;
  nextTaskAt?: InputMaybe<OrderBy>;
  nextTaskId?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "journey_enrollments" */
export type JourneyEnrollmentsMutationResponse = {
  __typename?: 'JourneyEnrollmentsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<JourneyEnrollments>;
};

/** on_conflict condition type for table "journey_enrollments" */
export type JourneyEnrollmentsOnConflict = {
  constraint: JourneyEnrollmentsConstraint;
  updateColumns?: Array<JourneyEnrollmentsUpdateColumn>;
  where?: InputMaybe<JourneyEnrollmentsBoolExp>;
};

/** Ordering options when selecting data from "journey_enrollments". */
export type JourneyEnrollmentsOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedBy?: InputMaybe<UsersOrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isComplete?: InputMaybe<OrderBy>;
  journey?: InputMaybe<JourneysOrderBy>;
  journeyId?: InputMaybe<OrderBy>;
  meta?: InputMaybe<OrderBy>;
  nextTask?: InputMaybe<TasksOrderBy>;
  nextTaskAt?: InputMaybe<OrderBy>;
  nextTaskId?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationsOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: journey_enrollments */
export type JourneyEnrollmentsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type JourneyEnrollmentsPrependInput = {
  meta?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "journey_enrollments" */
export enum JourneyEnrollmentsSelectColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  id = 'id',
  /** column name */
  isComplete = 'isComplete',
  /** column name */
  journeyId = 'journeyId',
  /** column name */
  meta = 'meta',
  /** column name */
  nextTaskAt = 'nextTaskAt',
  /** column name */
  nextTaskId = 'nextTaskId',
  /** column name */
  organizationId = 'organizationId',
  /** column name */
  userId = 'userId'
}

/** select "journeyEnrollmentsAggregateBoolExpBool_andArgumentsColumns" columns of table "journey_enrollments" */
export enum JourneyEnrollmentsSelectColumnJourneyEnrollmentsAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  isComplete = 'isComplete'
}

/** select "journeyEnrollmentsAggregateBoolExpBool_orArgumentsColumns" columns of table "journey_enrollments" */
export enum JourneyEnrollmentsSelectColumnJourneyEnrollmentsAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  isComplete = 'isComplete'
}

/** input type for updating data in table "journey_enrollments" */
export type JourneyEnrollmentsSetInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isComplete?: InputMaybe<Scalars['Boolean']>;
  journeyId?: InputMaybe<Scalars['uuid']>;
  meta?: InputMaybe<Scalars['jsonb']>;
  nextTaskAt?: InputMaybe<Scalars['timestamp']>;
  nextTaskId?: InputMaybe<Scalars['uuid']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "journey_enrollments" */
export type JourneyEnrollmentsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: JourneyEnrollmentsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type JourneyEnrollmentsStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isComplete?: InputMaybe<Scalars['Boolean']>;
  journeyId?: InputMaybe<Scalars['uuid']>;
  meta?: InputMaybe<Scalars['jsonb']>;
  nextTaskAt?: InputMaybe<Scalars['timestamp']>;
  nextTaskId?: InputMaybe<Scalars['uuid']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "journey_enrollments" */
export enum JourneyEnrollmentsUpdateColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  id = 'id',
  /** column name */
  isComplete = 'isComplete',
  /** column name */
  journeyId = 'journeyId',
  /** column name */
  meta = 'meta',
  /** column name */
  nextTaskAt = 'nextTaskAt',
  /** column name */
  nextTaskId = 'nextTaskId',
  /** column name */
  organizationId = 'organizationId',
  /** column name */
  userId = 'userId'
}

export type JourneyEnrollmentsUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<JourneyEnrollmentsAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<JourneyEnrollmentsDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<JourneyEnrollmentsDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<JourneyEnrollmentsDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<JourneyEnrollmentsPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<JourneyEnrollmentsSetInput>;
  /** filter the rows which have to be updated */
  where: JourneyEnrollmentsBoolExp;
};

/** columns and relationships of "journeys" */
export type Journeys = {
  __typename?: 'Journeys';
  assignInternalLeader?: Maybe<Scalars['Boolean']>;
  behaviorsToInfluence?: Maybe<Scalars['String']>;
  businessGoalsOutcomes?: Maybe<Scalars['String']>;
  createSiaSlackChannel?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['timestamp'];
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById: Scalars['uuid'];
  cspSfId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  giagFollowUp?: Maybe<Scalars['Boolean']>;
  giagMandatory?: Maybe<Scalars['Boolean']>;
  id: Scalars['uuid'];
  leadingIndicatorsOfBehaviorChange?: Maybe<Scalars['String']>;
  learnerReview?: Maybe<Scalars['Boolean']>;
  lunchLearnReview?: Maybe<Scalars['Boolean']>;
  managerIdentified?: Maybe<Scalars['Boolean']>;
  managerMeetingBooked?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  /** An object relationship */
  organization?: Maybe<Organizations>;
  organizationId?: Maybe<Scalars['uuid']>;
  reinforcementPlanNotes?: Maybe<Scalars['String']>;
  relevantLearningsIdentified?: Maybe<Scalars['Boolean']>;
  roiDelivered?: Maybe<Scalars['Boolean']>;
  roiDeliveredDetails?: Maybe<Scalars['String']>;
  skillGaps?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasksAggregate: TasksAggregate;
  topLearnerIncentive?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  updatedBy?: Maybe<Users>;
  updatedById?: Maybe<Scalars['uuid']>;
  watchClasses?: Maybe<Scalars['Boolean']>;
};

/** columns and relationships of "journeys" */
export type JourneysTasksArgs = {
  distinctOn?: InputMaybe<Array<TasksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
  where?: InputMaybe<TasksBoolExp>;
};

/** columns and relationships of "journeys" */
export type JourneysTasksAggregateArgs = {
  distinctOn?: InputMaybe<Array<TasksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
  where?: InputMaybe<TasksBoolExp>;
};

/** aggregated selection of "journeys" */
export type JourneysAggregate = {
  __typename?: 'JourneysAggregate';
  aggregate?: Maybe<JourneysAggregateFields>;
  nodes: Array<Journeys>;
};

export type JourneysAggregateBoolExp = {
  bool_and?: InputMaybe<JourneysAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<JourneysAggregateBoolExpBool_Or>;
  count?: InputMaybe<JourneysAggregateBoolExpCount>;
};

/** aggregate fields of "journeys" */
export type JourneysAggregateFields = {
  __typename?: 'JourneysAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<JourneysMaxFields>;
  min?: Maybe<JourneysMinFields>;
};

/** aggregate fields of "journeys" */
export type JourneysAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<JourneysSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "journeys" */
export type JourneysAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<JourneysMaxOrderBy>;
  min?: InputMaybe<JourneysMinOrderBy>;
};

/** input type for inserting array relation for remote table "journeys" */
export type JourneysArrRelInsertInput = {
  data: Array<JourneysInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<JourneysOnConflict>;
};

/** Boolean expression to filter rows from the table "journeys". All fields are combined with a logical 'AND'. */
export type JourneysBoolExp = {
  _and?: InputMaybe<Array<JourneysBoolExp>>;
  _not?: InputMaybe<JourneysBoolExp>;
  _or?: InputMaybe<Array<JourneysBoolExp>>;
  assignInternalLeader?: InputMaybe<BooleanComparisonExp>;
  behaviorsToInfluence?: InputMaybe<StringComparisonExp>;
  businessGoalsOutcomes?: InputMaybe<StringComparisonExp>;
  createSiaSlackChannel?: InputMaybe<BooleanComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  cspSfId?: InputMaybe<StringComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  giagFollowUp?: InputMaybe<BooleanComparisonExp>;
  giagMandatory?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  leadingIndicatorsOfBehaviorChange?: InputMaybe<StringComparisonExp>;
  learnerReview?: InputMaybe<BooleanComparisonExp>;
  lunchLearnReview?: InputMaybe<BooleanComparisonExp>;
  managerIdentified?: InputMaybe<BooleanComparisonExp>;
  managerMeetingBooked?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  notes?: InputMaybe<StringComparisonExp>;
  organization?: InputMaybe<OrganizationsBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  reinforcementPlanNotes?: InputMaybe<StringComparisonExp>;
  relevantLearningsIdentified?: InputMaybe<BooleanComparisonExp>;
  roiDelivered?: InputMaybe<BooleanComparisonExp>;
  roiDeliveredDetails?: InputMaybe<StringComparisonExp>;
  skillGaps?: InputMaybe<StringComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  tasks?: InputMaybe<TasksBoolExp>;
  tasksAggregate?: InputMaybe<TasksAggregateBoolExp>;
  topLearnerIncentive?: InputMaybe<BooleanComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  updatedBy?: InputMaybe<UsersBoolExp>;
  updatedById?: InputMaybe<UuidComparisonExp>;
  watchClasses?: InputMaybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "journeys" */
export enum JourneysConstraint {
  /** unique or primary key constraint on columns "id" */
  journeys_pk = 'journeys_pk'
}

/** input type for inserting data into table "journeys" */
export type JourneysInsertInput = {
  assignInternalLeader?: InputMaybe<Scalars['Boolean']>;
  behaviorsToInfluence?: InputMaybe<Scalars['String']>;
  businessGoalsOutcomes?: InputMaybe<Scalars['String']>;
  createSiaSlackChannel?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  cspSfId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  giagFollowUp?: InputMaybe<Scalars['Boolean']>;
  giagMandatory?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  leadingIndicatorsOfBehaviorChange?: InputMaybe<Scalars['String']>;
  learnerReview?: InputMaybe<Scalars['Boolean']>;
  lunchLearnReview?: InputMaybe<Scalars['Boolean']>;
  managerIdentified?: InputMaybe<Scalars['Boolean']>;
  managerMeetingBooked?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationsObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  reinforcementPlanNotes?: InputMaybe<Scalars['String']>;
  relevantLearningsIdentified?: InputMaybe<Scalars['Boolean']>;
  roiDelivered?: InputMaybe<Scalars['Boolean']>;
  roiDeliveredDetails?: InputMaybe<Scalars['String']>;
  skillGaps?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  tasks?: InputMaybe<TasksArrRelInsertInput>;
  topLearnerIncentive?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<UsersObjRelInsertInput>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  watchClasses?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type JourneysMaxFields = {
  __typename?: 'JourneysMaxFields';
  behaviorsToInfluence?: Maybe<Scalars['String']>;
  businessGoalsOutcomes?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  cspSfId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  leadingIndicatorsOfBehaviorChange?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  reinforcementPlanNotes?: Maybe<Scalars['String']>;
  roiDeliveredDetails?: Maybe<Scalars['String']>;
  skillGaps?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "journeys" */
export type JourneysMaxOrderBy = {
  behaviorsToInfluence?: InputMaybe<OrderBy>;
  businessGoalsOutcomes?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  cspSfId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  leadingIndicatorsOfBehaviorChange?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  reinforcementPlanNotes?: InputMaybe<OrderBy>;
  roiDeliveredDetails?: InputMaybe<OrderBy>;
  skillGaps?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type JourneysMinFields = {
  __typename?: 'JourneysMinFields';
  behaviorsToInfluence?: Maybe<Scalars['String']>;
  businessGoalsOutcomes?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  cspSfId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  leadingIndicatorsOfBehaviorChange?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  reinforcementPlanNotes?: Maybe<Scalars['String']>;
  roiDeliveredDetails?: Maybe<Scalars['String']>;
  skillGaps?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "journeys" */
export type JourneysMinOrderBy = {
  behaviorsToInfluence?: InputMaybe<OrderBy>;
  businessGoalsOutcomes?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  cspSfId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  leadingIndicatorsOfBehaviorChange?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  reinforcementPlanNotes?: InputMaybe<OrderBy>;
  roiDeliveredDetails?: InputMaybe<OrderBy>;
  skillGaps?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "journeys" */
export type JourneysMutationResponse = {
  __typename?: 'JourneysMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Journeys>;
};

/** input type for inserting object relation for remote table "journeys" */
export type JourneysObjRelInsertInput = {
  data: JourneysInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<JourneysOnConflict>;
};

/** on_conflict condition type for table "journeys" */
export type JourneysOnConflict = {
  constraint: JourneysConstraint;
  updateColumns?: Array<JourneysUpdateColumn>;
  where?: InputMaybe<JourneysBoolExp>;
};

/** Ordering options when selecting data from "journeys". */
export type JourneysOrderBy = {
  assignInternalLeader?: InputMaybe<OrderBy>;
  behaviorsToInfluence?: InputMaybe<OrderBy>;
  businessGoalsOutcomes?: InputMaybe<OrderBy>;
  createSiaSlackChannel?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  cspSfId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  giagFollowUp?: InputMaybe<OrderBy>;
  giagMandatory?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  leadingIndicatorsOfBehaviorChange?: InputMaybe<OrderBy>;
  learnerReview?: InputMaybe<OrderBy>;
  lunchLearnReview?: InputMaybe<OrderBy>;
  managerIdentified?: InputMaybe<OrderBy>;
  managerMeetingBooked?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationsOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  reinforcementPlanNotes?: InputMaybe<OrderBy>;
  relevantLearningsIdentified?: InputMaybe<OrderBy>;
  roiDelivered?: InputMaybe<OrderBy>;
  roiDeliveredDetails?: InputMaybe<OrderBy>;
  skillGaps?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  tasksAggregate?: InputMaybe<TasksAggregateOrderBy>;
  topLearnerIncentive?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<UsersOrderBy>;
  updatedById?: InputMaybe<OrderBy>;
  watchClasses?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: journeys */
export type JourneysPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "journeys" */
export enum JourneysSelectColumn {
  /** column name */
  assignInternalLeader = 'assignInternalLeader',
  /** column name */
  behaviorsToInfluence = 'behaviorsToInfluence',
  /** column name */
  businessGoalsOutcomes = 'businessGoalsOutcomes',
  /** column name */
  createSiaSlackChannel = 'createSiaSlackChannel',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  cspSfId = 'cspSfId',
  /** column name */
  description = 'description',
  /** column name */
  giagFollowUp = 'giagFollowUp',
  /** column name */
  giagMandatory = 'giagMandatory',
  /** column name */
  id = 'id',
  /** column name */
  leadingIndicatorsOfBehaviorChange = 'leadingIndicatorsOfBehaviorChange',
  /** column name */
  learnerReview = 'learnerReview',
  /** column name */
  lunchLearnReview = 'lunchLearnReview',
  /** column name */
  managerIdentified = 'managerIdentified',
  /** column name */
  managerMeetingBooked = 'managerMeetingBooked',
  /** column name */
  name = 'name',
  /** column name */
  notes = 'notes',
  /** column name */
  organizationId = 'organizationId',
  /** column name */
  reinforcementPlanNotes = 'reinforcementPlanNotes',
  /** column name */
  relevantLearningsIdentified = 'relevantLearningsIdentified',
  /** column name */
  roiDelivered = 'roiDelivered',
  /** column name */
  roiDeliveredDetails = 'roiDeliveredDetails',
  /** column name */
  skillGaps = 'skillGaps',
  /** column name */
  slug = 'slug',
  /** column name */
  topLearnerIncentive = 'topLearnerIncentive',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById',
  /** column name */
  watchClasses = 'watchClasses'
}

/** select "journeysAggregateBoolExpBool_andArgumentsColumns" columns of table "journeys" */
export enum JourneysSelectColumnJourneysAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  assignInternalLeader = 'assignInternalLeader',
  /** column name */
  createSiaSlackChannel = 'createSiaSlackChannel',
  /** column name */
  giagFollowUp = 'giagFollowUp',
  /** column name */
  giagMandatory = 'giagMandatory',
  /** column name */
  learnerReview = 'learnerReview',
  /** column name */
  lunchLearnReview = 'lunchLearnReview',
  /** column name */
  managerIdentified = 'managerIdentified',
  /** column name */
  managerMeetingBooked = 'managerMeetingBooked',
  /** column name */
  relevantLearningsIdentified = 'relevantLearningsIdentified',
  /** column name */
  roiDelivered = 'roiDelivered',
  /** column name */
  topLearnerIncentive = 'topLearnerIncentive',
  /** column name */
  watchClasses = 'watchClasses'
}

/** select "journeysAggregateBoolExpBool_orArgumentsColumns" columns of table "journeys" */
export enum JourneysSelectColumnJourneysAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  assignInternalLeader = 'assignInternalLeader',
  /** column name */
  createSiaSlackChannel = 'createSiaSlackChannel',
  /** column name */
  giagFollowUp = 'giagFollowUp',
  /** column name */
  giagMandatory = 'giagMandatory',
  /** column name */
  learnerReview = 'learnerReview',
  /** column name */
  lunchLearnReview = 'lunchLearnReview',
  /** column name */
  managerIdentified = 'managerIdentified',
  /** column name */
  managerMeetingBooked = 'managerMeetingBooked',
  /** column name */
  relevantLearningsIdentified = 'relevantLearningsIdentified',
  /** column name */
  roiDelivered = 'roiDelivered',
  /** column name */
  topLearnerIncentive = 'topLearnerIncentive',
  /** column name */
  watchClasses = 'watchClasses'
}

/** input type for updating data in table "journeys" */
export type JourneysSetInput = {
  assignInternalLeader?: InputMaybe<Scalars['Boolean']>;
  behaviorsToInfluence?: InputMaybe<Scalars['String']>;
  businessGoalsOutcomes?: InputMaybe<Scalars['String']>;
  createSiaSlackChannel?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  cspSfId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  giagFollowUp?: InputMaybe<Scalars['Boolean']>;
  giagMandatory?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  leadingIndicatorsOfBehaviorChange?: InputMaybe<Scalars['String']>;
  learnerReview?: InputMaybe<Scalars['Boolean']>;
  lunchLearnReview?: InputMaybe<Scalars['Boolean']>;
  managerIdentified?: InputMaybe<Scalars['Boolean']>;
  managerMeetingBooked?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  reinforcementPlanNotes?: InputMaybe<Scalars['String']>;
  relevantLearningsIdentified?: InputMaybe<Scalars['Boolean']>;
  roiDelivered?: InputMaybe<Scalars['Boolean']>;
  roiDeliveredDetails?: InputMaybe<Scalars['String']>;
  skillGaps?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  topLearnerIncentive?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  watchClasses?: InputMaybe<Scalars['Boolean']>;
};

/** Streaming cursor of the table "journeys" */
export type JourneysStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: JourneysStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type JourneysStreamCursorValueInput = {
  assignInternalLeader?: InputMaybe<Scalars['Boolean']>;
  behaviorsToInfluence?: InputMaybe<Scalars['String']>;
  businessGoalsOutcomes?: InputMaybe<Scalars['String']>;
  createSiaSlackChannel?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  cspSfId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  giagFollowUp?: InputMaybe<Scalars['Boolean']>;
  giagMandatory?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  leadingIndicatorsOfBehaviorChange?: InputMaybe<Scalars['String']>;
  learnerReview?: InputMaybe<Scalars['Boolean']>;
  lunchLearnReview?: InputMaybe<Scalars['Boolean']>;
  managerIdentified?: InputMaybe<Scalars['Boolean']>;
  managerMeetingBooked?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  reinforcementPlanNotes?: InputMaybe<Scalars['String']>;
  relevantLearningsIdentified?: InputMaybe<Scalars['Boolean']>;
  roiDelivered?: InputMaybe<Scalars['Boolean']>;
  roiDeliveredDetails?: InputMaybe<Scalars['String']>;
  skillGaps?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  topLearnerIncentive?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  watchClasses?: InputMaybe<Scalars['Boolean']>;
};

/** update columns of table "journeys" */
export enum JourneysUpdateColumn {
  /** column name */
  assignInternalLeader = 'assignInternalLeader',
  /** column name */
  behaviorsToInfluence = 'behaviorsToInfluence',
  /** column name */
  businessGoalsOutcomes = 'businessGoalsOutcomes',
  /** column name */
  createSiaSlackChannel = 'createSiaSlackChannel',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  cspSfId = 'cspSfId',
  /** column name */
  description = 'description',
  /** column name */
  giagFollowUp = 'giagFollowUp',
  /** column name */
  giagMandatory = 'giagMandatory',
  /** column name */
  id = 'id',
  /** column name */
  leadingIndicatorsOfBehaviorChange = 'leadingIndicatorsOfBehaviorChange',
  /** column name */
  learnerReview = 'learnerReview',
  /** column name */
  lunchLearnReview = 'lunchLearnReview',
  /** column name */
  managerIdentified = 'managerIdentified',
  /** column name */
  managerMeetingBooked = 'managerMeetingBooked',
  /** column name */
  name = 'name',
  /** column name */
  notes = 'notes',
  /** column name */
  organizationId = 'organizationId',
  /** column name */
  reinforcementPlanNotes = 'reinforcementPlanNotes',
  /** column name */
  relevantLearningsIdentified = 'relevantLearningsIdentified',
  /** column name */
  roiDelivered = 'roiDelivered',
  /** column name */
  roiDeliveredDetails = 'roiDeliveredDetails',
  /** column name */
  skillGaps = 'skillGaps',
  /** column name */
  slug = 'slug',
  /** column name */
  topLearnerIncentive = 'topLearnerIncentive',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById',
  /** column name */
  watchClasses = 'watchClasses'
}

export type JourneysUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<JourneysSetInput>;
  /** filter the rows which have to be updated */
  where: JourneysBoolExp;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type JsonComparisonExp = {
  _eq?: InputMaybe<Scalars['json']>;
  _gt?: InputMaybe<Scalars['json']>;
  _gte?: InputMaybe<Scalars['json']>;
  _in?: InputMaybe<Array<Scalars['json']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['json']>;
  _lte?: InputMaybe<Scalars['json']>;
  _neq?: InputMaybe<Scalars['json']>;
  _nin?: InputMaybe<Array<Scalars['json']>>;
};

export type JsonbCastExp = {
  String?: InputMaybe<StringComparisonExp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  _cast?: InputMaybe<JsonbCastExp>;
  /** is the column contained in the given json value */
  _containedIn?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _hasKey?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _hasKeysAll?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _hasKeysAny?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "ledger" */
export type Ledger = {
  __typename?: 'Ledger';
  allocatedCreditMovement: Scalars['Int'];
  availableCreditBalance: Scalars['Int'];
  creditBalance: Scalars['Int'];
  creditLedgerId: Scalars['Int'];
  creditModel: Scalars['String'];
  creditMovement: Scalars['Int'];
  /** An object relationship */
  enrollment?: Maybe<Enrollments>;
  enrollmentId?: Maybe<Scalars['uuid']>;
  id: Scalars['String'];
  isCurrentSubscription: Scalars['Boolean'];
  isReconciled: Scalars['Boolean'];
  /** An object relationship */
  organization?: Maybe<Organizations>;
  organizationId: Scalars['uuid'];
  product?: Maybe<LedgerProductEnumEnum>;
  subscriptionCreditsBought?: Maybe<Scalars['Int']>;
  subscriptionEndDate?: Maybe<Scalars['timestamp']>;
  subscriptionStartDate?: Maybe<Scalars['timestamp']>;
  totalAllocatedCredits: Scalars['Int'];
  totalCreditsSpent?: Maybe<Scalars['Int']>;
  transactionDate: Scalars['timestamp'];
  transactionType: LedgerTransactionTypeEnumEnum;
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "ledger" */
export type LedgerAggregate = {
  __typename?: 'LedgerAggregate';
  aggregate?: Maybe<LedgerAggregateFields>;
  nodes: Array<Ledger>;
};

/** aggregate fields of "ledger" */
export type LedgerAggregateFields = {
  __typename?: 'LedgerAggregateFields';
  avg?: Maybe<LedgerAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LedgerMaxFields>;
  min?: Maybe<LedgerMinFields>;
  stddev?: Maybe<LedgerStddevFields>;
  stddevPop?: Maybe<LedgerStddevPopFields>;
  stddevSamp?: Maybe<LedgerStddevSampFields>;
  sum?: Maybe<LedgerSumFields>;
  varPop?: Maybe<LedgerVarPopFields>;
  varSamp?: Maybe<LedgerVarSampFields>;
  variance?: Maybe<LedgerVarianceFields>;
};

/** aggregate fields of "ledger" */
export type LedgerAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LedgerSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type LedgerAvgFields = {
  __typename?: 'LedgerAvgFields';
  allocatedCreditMovement?: Maybe<Scalars['Float']>;
  availableCreditBalance?: Maybe<Scalars['Float']>;
  creditBalance?: Maybe<Scalars['Float']>;
  creditLedgerId?: Maybe<Scalars['Float']>;
  creditMovement?: Maybe<Scalars['Float']>;
  subscriptionCreditsBought?: Maybe<Scalars['Float']>;
  totalAllocatedCredits?: Maybe<Scalars['Float']>;
  totalCreditsSpent?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ledger". All fields are combined with a logical 'AND'. */
export type LedgerBoolExp = {
  _and?: InputMaybe<Array<LedgerBoolExp>>;
  _not?: InputMaybe<LedgerBoolExp>;
  _or?: InputMaybe<Array<LedgerBoolExp>>;
  allocatedCreditMovement?: InputMaybe<IntComparisonExp>;
  availableCreditBalance?: InputMaybe<IntComparisonExp>;
  creditBalance?: InputMaybe<IntComparisonExp>;
  creditLedgerId?: InputMaybe<IntComparisonExp>;
  creditModel?: InputMaybe<StringComparisonExp>;
  creditMovement?: InputMaybe<IntComparisonExp>;
  enrollment?: InputMaybe<EnrollmentsBoolExp>;
  enrollmentId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  isCurrentSubscription?: InputMaybe<BooleanComparisonExp>;
  isReconciled?: InputMaybe<BooleanComparisonExp>;
  organization?: InputMaybe<OrganizationsBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  product?: InputMaybe<LedgerProductEnumEnumComparisonExp>;
  subscriptionCreditsBought?: InputMaybe<IntComparisonExp>;
  subscriptionEndDate?: InputMaybe<TimestampComparisonExp>;
  subscriptionStartDate?: InputMaybe<TimestampComparisonExp>;
  totalAllocatedCredits?: InputMaybe<IntComparisonExp>;
  totalCreditsSpent?: InputMaybe<IntComparisonExp>;
  transactionDate?: InputMaybe<TimestampComparisonExp>;
  transactionType?: InputMaybe<LedgerTransactionTypeEnumEnumComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "ledger" */
export enum LedgerConstraint {
  /** unique or primary key constraint on columns "id" */
  ledger_pkey = 'ledger_pkey'
}

/** input type for incrementing numeric columns in table "ledger" */
export type LedgerIncInput = {
  allocatedCreditMovement?: InputMaybe<Scalars['Int']>;
  availableCreditBalance?: InputMaybe<Scalars['Int']>;
  creditBalance?: InputMaybe<Scalars['Int']>;
  creditLedgerId?: InputMaybe<Scalars['Int']>;
  creditMovement?: InputMaybe<Scalars['Int']>;
  subscriptionCreditsBought?: InputMaybe<Scalars['Int']>;
  totalAllocatedCredits?: InputMaybe<Scalars['Int']>;
  totalCreditsSpent?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ledger" */
export type LedgerInsertInput = {
  allocatedCreditMovement?: InputMaybe<Scalars['Int']>;
  availableCreditBalance?: InputMaybe<Scalars['Int']>;
  creditBalance?: InputMaybe<Scalars['Int']>;
  creditLedgerId?: InputMaybe<Scalars['Int']>;
  creditModel?: InputMaybe<Scalars['String']>;
  creditMovement?: InputMaybe<Scalars['Int']>;
  enrollment?: InputMaybe<EnrollmentsObjRelInsertInput>;
  enrollmentId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['String']>;
  isCurrentSubscription?: InputMaybe<Scalars['Boolean']>;
  isReconciled?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<OrganizationsObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  product?: InputMaybe<LedgerProductEnumEnum>;
  subscriptionCreditsBought?: InputMaybe<Scalars['Int']>;
  subscriptionEndDate?: InputMaybe<Scalars['timestamp']>;
  subscriptionStartDate?: InputMaybe<Scalars['timestamp']>;
  totalAllocatedCredits?: InputMaybe<Scalars['Int']>;
  totalCreditsSpent?: InputMaybe<Scalars['Int']>;
  transactionDate?: InputMaybe<Scalars['timestamp']>;
  transactionType?: InputMaybe<LedgerTransactionTypeEnumEnum>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type LedgerMaxFields = {
  __typename?: 'LedgerMaxFields';
  allocatedCreditMovement?: Maybe<Scalars['Int']>;
  availableCreditBalance?: Maybe<Scalars['Int']>;
  creditBalance?: Maybe<Scalars['Int']>;
  creditLedgerId?: Maybe<Scalars['Int']>;
  creditModel?: Maybe<Scalars['String']>;
  creditMovement?: Maybe<Scalars['Int']>;
  enrollmentId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  subscriptionCreditsBought?: Maybe<Scalars['Int']>;
  subscriptionEndDate?: Maybe<Scalars['timestamp']>;
  subscriptionStartDate?: Maybe<Scalars['timestamp']>;
  totalAllocatedCredits?: Maybe<Scalars['Int']>;
  totalCreditsSpent?: Maybe<Scalars['Int']>;
  transactionDate?: Maybe<Scalars['timestamp']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type LedgerMinFields = {
  __typename?: 'LedgerMinFields';
  allocatedCreditMovement?: Maybe<Scalars['Int']>;
  availableCreditBalance?: Maybe<Scalars['Int']>;
  creditBalance?: Maybe<Scalars['Int']>;
  creditLedgerId?: Maybe<Scalars['Int']>;
  creditModel?: Maybe<Scalars['String']>;
  creditMovement?: Maybe<Scalars['Int']>;
  enrollmentId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  subscriptionCreditsBought?: Maybe<Scalars['Int']>;
  subscriptionEndDate?: Maybe<Scalars['timestamp']>;
  subscriptionStartDate?: Maybe<Scalars['timestamp']>;
  totalAllocatedCredits?: Maybe<Scalars['Int']>;
  totalCreditsSpent?: Maybe<Scalars['Int']>;
  transactionDate?: Maybe<Scalars['timestamp']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "ledger" */
export type LedgerMutationResponse = {
  __typename?: 'LedgerMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ledger>;
};

/** on_conflict condition type for table "ledger" */
export type LedgerOnConflict = {
  constraint: LedgerConstraint;
  updateColumns?: Array<LedgerUpdateColumn>;
  where?: InputMaybe<LedgerBoolExp>;
};

/** Ordering options when selecting data from "ledger". */
export type LedgerOrderBy = {
  allocatedCreditMovement?: InputMaybe<OrderBy>;
  availableCreditBalance?: InputMaybe<OrderBy>;
  creditBalance?: InputMaybe<OrderBy>;
  creditLedgerId?: InputMaybe<OrderBy>;
  creditModel?: InputMaybe<OrderBy>;
  creditMovement?: InputMaybe<OrderBy>;
  enrollment?: InputMaybe<EnrollmentsOrderBy>;
  enrollmentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isCurrentSubscription?: InputMaybe<OrderBy>;
  isReconciled?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationsOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  product?: InputMaybe<OrderBy>;
  subscriptionCreditsBought?: InputMaybe<OrderBy>;
  subscriptionEndDate?: InputMaybe<OrderBy>;
  subscriptionStartDate?: InputMaybe<OrderBy>;
  totalAllocatedCredits?: InputMaybe<OrderBy>;
  totalCreditsSpent?: InputMaybe<OrderBy>;
  transactionDate?: InputMaybe<OrderBy>;
  transactionType?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: ledger */
export type LedgerPkColumnsInput = {
  id: Scalars['String'];
};

/** columns and relationships of "ledger_product_enum" */
export type LedgerProductEnum = {
  __typename?: 'LedgerProductEnum';
  value: Scalars['String'];
};

/** aggregated selection of "ledger_product_enum" */
export type LedgerProductEnumAggregate = {
  __typename?: 'LedgerProductEnumAggregate';
  aggregate?: Maybe<LedgerProductEnumAggregateFields>;
  nodes: Array<LedgerProductEnum>;
};

/** aggregate fields of "ledger_product_enum" */
export type LedgerProductEnumAggregateFields = {
  __typename?: 'LedgerProductEnumAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<LedgerProductEnumMaxFields>;
  min?: Maybe<LedgerProductEnumMinFields>;
};

/** aggregate fields of "ledger_product_enum" */
export type LedgerProductEnumAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LedgerProductEnumSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ledger_product_enum". All fields are combined with a logical 'AND'. */
export type LedgerProductEnumBoolExp = {
  _and?: InputMaybe<Array<LedgerProductEnumBoolExp>>;
  _not?: InputMaybe<LedgerProductEnumBoolExp>;
  _or?: InputMaybe<Array<LedgerProductEnumBoolExp>>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "ledger_product_enum" */
export enum LedgerProductEnumConstraint {
  /** unique or primary key constraint on columns "value" */
  ledger_product_enum_pkey = 'ledger_product_enum_pkey'
}

export enum LedgerProductEnumEnum {
  INITIAL_PURCHASE = 'INITIAL_PURCHASE',
  ONE_OFF = 'ONE_OFF',
  PLATFORM_ENROLLMENT = 'PLATFORM_ENROLLMENT',
  PLATFORM_UNENROLLMENT = 'PLATFORM_UNENROLLMENT',
  RENEWAL = 'RENEWAL',
  USER_BECAME_LIMITED_SEAT = 'USER_BECAME_LIMITED_SEAT',
  USER_DEACTIVATION = 'USER_DEACTIVATION',
  WORKSHOP = 'WORKSHOP'
}

/** Boolean expression to compare columns of type "LedgerProductEnumEnum". All fields are combined with logical 'AND'. */
export type LedgerProductEnumEnumComparisonExp = {
  _eq?: InputMaybe<LedgerProductEnumEnum>;
  _in?: InputMaybe<Array<LedgerProductEnumEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<LedgerProductEnumEnum>;
  _nin?: InputMaybe<Array<LedgerProductEnumEnum>>;
};

/** input type for inserting data into table "ledger_product_enum" */
export type LedgerProductEnumInsertInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type LedgerProductEnumMaxFields = {
  __typename?: 'LedgerProductEnumMaxFields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type LedgerProductEnumMinFields = {
  __typename?: 'LedgerProductEnumMinFields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ledger_product_enum" */
export type LedgerProductEnumMutationResponse = {
  __typename?: 'LedgerProductEnumMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LedgerProductEnum>;
};

/** on_conflict condition type for table "ledger_product_enum" */
export type LedgerProductEnumOnConflict = {
  constraint: LedgerProductEnumConstraint;
  updateColumns?: Array<LedgerProductEnumUpdateColumn>;
  where?: InputMaybe<LedgerProductEnumBoolExp>;
};

/** Ordering options when selecting data from "ledger_product_enum". */
export type LedgerProductEnumOrderBy = {
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: ledger_product_enum */
export type LedgerProductEnumPkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "ledger_product_enum" */
export enum LedgerProductEnumSelectColumn {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "ledger_product_enum" */
export type LedgerProductEnumSetInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ledger_product_enum" */
export type LedgerProductEnumStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: LedgerProductEnumStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LedgerProductEnumStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ledger_product_enum" */
export enum LedgerProductEnumUpdateColumn {
  /** column name */
  value = 'value'
}

export type LedgerProductEnumUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LedgerProductEnumSetInput>;
  /** filter the rows which have to be updated */
  where: LedgerProductEnumBoolExp;
};

/** select columns of table "ledger" */
export enum LedgerSelectColumn {
  /** column name */
  allocatedCreditMovement = 'allocatedCreditMovement',
  /** column name */
  availableCreditBalance = 'availableCreditBalance',
  /** column name */
  creditBalance = 'creditBalance',
  /** column name */
  creditLedgerId = 'creditLedgerId',
  /** column name */
  creditModel = 'creditModel',
  /** column name */
  creditMovement = 'creditMovement',
  /** column name */
  enrollmentId = 'enrollmentId',
  /** column name */
  id = 'id',
  /** column name */
  isCurrentSubscription = 'isCurrentSubscription',
  /** column name */
  isReconciled = 'isReconciled',
  /** column name */
  organizationId = 'organizationId',
  /** column name */
  product = 'product',
  /** column name */
  subscriptionCreditsBought = 'subscriptionCreditsBought',
  /** column name */
  subscriptionEndDate = 'subscriptionEndDate',
  /** column name */
  subscriptionStartDate = 'subscriptionStartDate',
  /** column name */
  totalAllocatedCredits = 'totalAllocatedCredits',
  /** column name */
  totalCreditsSpent = 'totalCreditsSpent',
  /** column name */
  transactionDate = 'transactionDate',
  /** column name */
  transactionType = 'transactionType',
  /** column name */
  userId = 'userId'
}

/** input type for updating data in table "ledger" */
export type LedgerSetInput = {
  allocatedCreditMovement?: InputMaybe<Scalars['Int']>;
  availableCreditBalance?: InputMaybe<Scalars['Int']>;
  creditBalance?: InputMaybe<Scalars['Int']>;
  creditLedgerId?: InputMaybe<Scalars['Int']>;
  creditModel?: InputMaybe<Scalars['String']>;
  creditMovement?: InputMaybe<Scalars['Int']>;
  enrollmentId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['String']>;
  isCurrentSubscription?: InputMaybe<Scalars['Boolean']>;
  isReconciled?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  product?: InputMaybe<LedgerProductEnumEnum>;
  subscriptionCreditsBought?: InputMaybe<Scalars['Int']>;
  subscriptionEndDate?: InputMaybe<Scalars['timestamp']>;
  subscriptionStartDate?: InputMaybe<Scalars['timestamp']>;
  totalAllocatedCredits?: InputMaybe<Scalars['Int']>;
  totalCreditsSpent?: InputMaybe<Scalars['Int']>;
  transactionDate?: InputMaybe<Scalars['timestamp']>;
  transactionType?: InputMaybe<LedgerTransactionTypeEnumEnum>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type LedgerStddevFields = {
  __typename?: 'LedgerStddevFields';
  allocatedCreditMovement?: Maybe<Scalars['Float']>;
  availableCreditBalance?: Maybe<Scalars['Float']>;
  creditBalance?: Maybe<Scalars['Float']>;
  creditLedgerId?: Maybe<Scalars['Float']>;
  creditMovement?: Maybe<Scalars['Float']>;
  subscriptionCreditsBought?: Maybe<Scalars['Float']>;
  totalAllocatedCredits?: Maybe<Scalars['Float']>;
  totalCreditsSpent?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type LedgerStddevPopFields = {
  __typename?: 'LedgerStddevPopFields';
  allocatedCreditMovement?: Maybe<Scalars['Float']>;
  availableCreditBalance?: Maybe<Scalars['Float']>;
  creditBalance?: Maybe<Scalars['Float']>;
  creditLedgerId?: Maybe<Scalars['Float']>;
  creditMovement?: Maybe<Scalars['Float']>;
  subscriptionCreditsBought?: Maybe<Scalars['Float']>;
  totalAllocatedCredits?: Maybe<Scalars['Float']>;
  totalCreditsSpent?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type LedgerStddevSampFields = {
  __typename?: 'LedgerStddevSampFields';
  allocatedCreditMovement?: Maybe<Scalars['Float']>;
  availableCreditBalance?: Maybe<Scalars['Float']>;
  creditBalance?: Maybe<Scalars['Float']>;
  creditLedgerId?: Maybe<Scalars['Float']>;
  creditMovement?: Maybe<Scalars['Float']>;
  subscriptionCreditsBought?: Maybe<Scalars['Float']>;
  totalAllocatedCredits?: Maybe<Scalars['Float']>;
  totalCreditsSpent?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ledger" */
export type LedgerStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: LedgerStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LedgerStreamCursorValueInput = {
  allocatedCreditMovement?: InputMaybe<Scalars['Int']>;
  availableCreditBalance?: InputMaybe<Scalars['Int']>;
  creditBalance?: InputMaybe<Scalars['Int']>;
  creditLedgerId?: InputMaybe<Scalars['Int']>;
  creditModel?: InputMaybe<Scalars['String']>;
  creditMovement?: InputMaybe<Scalars['Int']>;
  enrollmentId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['String']>;
  isCurrentSubscription?: InputMaybe<Scalars['Boolean']>;
  isReconciled?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  product?: InputMaybe<LedgerProductEnumEnum>;
  subscriptionCreditsBought?: InputMaybe<Scalars['Int']>;
  subscriptionEndDate?: InputMaybe<Scalars['timestamp']>;
  subscriptionStartDate?: InputMaybe<Scalars['timestamp']>;
  totalAllocatedCredits?: InputMaybe<Scalars['Int']>;
  totalCreditsSpent?: InputMaybe<Scalars['Int']>;
  transactionDate?: InputMaybe<Scalars['timestamp']>;
  transactionType?: InputMaybe<LedgerTransactionTypeEnumEnum>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type LedgerSumFields = {
  __typename?: 'LedgerSumFields';
  allocatedCreditMovement?: Maybe<Scalars['Int']>;
  availableCreditBalance?: Maybe<Scalars['Int']>;
  creditBalance?: Maybe<Scalars['Int']>;
  creditLedgerId?: Maybe<Scalars['Int']>;
  creditMovement?: Maybe<Scalars['Int']>;
  subscriptionCreditsBought?: Maybe<Scalars['Int']>;
  totalAllocatedCredits?: Maybe<Scalars['Int']>;
  totalCreditsSpent?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "ledger_transaction_type_enum" */
export type LedgerTransactionTypeEnum = {
  __typename?: 'LedgerTransactionTypeEnum';
  comment?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "ledger_transaction_type_enum" */
export type LedgerTransactionTypeEnumAggregate = {
  __typename?: 'LedgerTransactionTypeEnumAggregate';
  aggregate?: Maybe<LedgerTransactionTypeEnumAggregateFields>;
  nodes: Array<LedgerTransactionTypeEnum>;
};

/** aggregate fields of "ledger_transaction_type_enum" */
export type LedgerTransactionTypeEnumAggregateFields = {
  __typename?: 'LedgerTransactionTypeEnumAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<LedgerTransactionTypeEnumMaxFields>;
  min?: Maybe<LedgerTransactionTypeEnumMinFields>;
};

/** aggregate fields of "ledger_transaction_type_enum" */
export type LedgerTransactionTypeEnumAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LedgerTransactionTypeEnumSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ledger_transaction_type_enum". All fields are combined with a logical 'AND'. */
export type LedgerTransactionTypeEnumBoolExp = {
  _and?: InputMaybe<Array<LedgerTransactionTypeEnumBoolExp>>;
  _not?: InputMaybe<LedgerTransactionTypeEnumBoolExp>;
  _or?: InputMaybe<Array<LedgerTransactionTypeEnumBoolExp>>;
  comment?: InputMaybe<StringComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "ledger_transaction_type_enum" */
export enum LedgerTransactionTypeEnumConstraint {
  /** unique or primary key constraint on columns "value" */
  ledger_transaction_type_enum_pkey = 'ledger_transaction_type_enum_pkey'
}

export enum LedgerTransactionTypeEnumEnum {
  /** Credits allocated */
  ALLOCATED_CREDITS = 'ALLOCATED_CREDITS',
  /** Credits bought */
  BUY_CREDITS = 'BUY_CREDITS',
  /** Credits expired */
  EXPIRE_CREDITS = 'EXPIRE_CREDITS',
  /** Credits refunded */
  REFUND_CREDITS = 'REFUND_CREDITS',
  /** Credits spent */
  SPEND_CREDITS = 'SPEND_CREDITS',
  /** Credits unallocated */
  UNALLOCATED_CREDITS = 'UNALLOCATED_CREDITS'
}

/** Boolean expression to compare columns of type "LedgerTransactionTypeEnumEnum". All fields are combined with logical 'AND'. */
export type LedgerTransactionTypeEnumEnumComparisonExp = {
  _eq?: InputMaybe<LedgerTransactionTypeEnumEnum>;
  _in?: InputMaybe<Array<LedgerTransactionTypeEnumEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<LedgerTransactionTypeEnumEnum>;
  _nin?: InputMaybe<Array<LedgerTransactionTypeEnumEnum>>;
};

/** input type for inserting data into table "ledger_transaction_type_enum" */
export type LedgerTransactionTypeEnumInsertInput = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type LedgerTransactionTypeEnumMaxFields = {
  __typename?: 'LedgerTransactionTypeEnumMaxFields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type LedgerTransactionTypeEnumMinFields = {
  __typename?: 'LedgerTransactionTypeEnumMinFields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ledger_transaction_type_enum" */
export type LedgerTransactionTypeEnumMutationResponse = {
  __typename?: 'LedgerTransactionTypeEnumMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LedgerTransactionTypeEnum>;
};

/** on_conflict condition type for table "ledger_transaction_type_enum" */
export type LedgerTransactionTypeEnumOnConflict = {
  constraint: LedgerTransactionTypeEnumConstraint;
  updateColumns?: Array<LedgerTransactionTypeEnumUpdateColumn>;
  where?: InputMaybe<LedgerTransactionTypeEnumBoolExp>;
};

/** Ordering options when selecting data from "ledger_transaction_type_enum". */
export type LedgerTransactionTypeEnumOrderBy = {
  comment?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: ledger_transaction_type_enum */
export type LedgerTransactionTypeEnumPkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "ledger_transaction_type_enum" */
export enum LedgerTransactionTypeEnumSelectColumn {
  /** column name */
  comment = 'comment',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "ledger_transaction_type_enum" */
export type LedgerTransactionTypeEnumSetInput = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ledger_transaction_type_enum" */
export type LedgerTransactionTypeEnumStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: LedgerTransactionTypeEnumStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LedgerTransactionTypeEnumStreamCursorValueInput = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ledger_transaction_type_enum" */
export enum LedgerTransactionTypeEnumUpdateColumn {
  /** column name */
  comment = 'comment',
  /** column name */
  value = 'value'
}

export type LedgerTransactionTypeEnumUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LedgerTransactionTypeEnumSetInput>;
  /** filter the rows which have to be updated */
  where: LedgerTransactionTypeEnumBoolExp;
};

/** update columns of table "ledger" */
export enum LedgerUpdateColumn {
  /** column name */
  allocatedCreditMovement = 'allocatedCreditMovement',
  /** column name */
  availableCreditBalance = 'availableCreditBalance',
  /** column name */
  creditBalance = 'creditBalance',
  /** column name */
  creditLedgerId = 'creditLedgerId',
  /** column name */
  creditModel = 'creditModel',
  /** column name */
  creditMovement = 'creditMovement',
  /** column name */
  enrollmentId = 'enrollmentId',
  /** column name */
  id = 'id',
  /** column name */
  isCurrentSubscription = 'isCurrentSubscription',
  /** column name */
  isReconciled = 'isReconciled',
  /** column name */
  organizationId = 'organizationId',
  /** column name */
  product = 'product',
  /** column name */
  subscriptionCreditsBought = 'subscriptionCreditsBought',
  /** column name */
  subscriptionEndDate = 'subscriptionEndDate',
  /** column name */
  subscriptionStartDate = 'subscriptionStartDate',
  /** column name */
  totalAllocatedCredits = 'totalAllocatedCredits',
  /** column name */
  totalCreditsSpent = 'totalCreditsSpent',
  /** column name */
  transactionDate = 'transactionDate',
  /** column name */
  transactionType = 'transactionType',
  /** column name */
  userId = 'userId'
}

export type LedgerUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<LedgerIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LedgerSetInput>;
  /** filter the rows which have to be updated */
  where: LedgerBoolExp;
};

/** aggregate varPop on columns */
export type LedgerVarPopFields = {
  __typename?: 'LedgerVarPopFields';
  allocatedCreditMovement?: Maybe<Scalars['Float']>;
  availableCreditBalance?: Maybe<Scalars['Float']>;
  creditBalance?: Maybe<Scalars['Float']>;
  creditLedgerId?: Maybe<Scalars['Float']>;
  creditMovement?: Maybe<Scalars['Float']>;
  subscriptionCreditsBought?: Maybe<Scalars['Float']>;
  totalAllocatedCredits?: Maybe<Scalars['Float']>;
  totalCreditsSpent?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type LedgerVarSampFields = {
  __typename?: 'LedgerVarSampFields';
  allocatedCreditMovement?: Maybe<Scalars['Float']>;
  availableCreditBalance?: Maybe<Scalars['Float']>;
  creditBalance?: Maybe<Scalars['Float']>;
  creditLedgerId?: Maybe<Scalars['Float']>;
  creditMovement?: Maybe<Scalars['Float']>;
  subscriptionCreditsBought?: Maybe<Scalars['Float']>;
  totalAllocatedCredits?: Maybe<Scalars['Float']>;
  totalCreditsSpent?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LedgerVarianceFields = {
  __typename?: 'LedgerVarianceFields';
  allocatedCreditMovement?: Maybe<Scalars['Float']>;
  availableCreditBalance?: Maybe<Scalars['Float']>;
  creditBalance?: Maybe<Scalars['Float']>;
  creditLedgerId?: Maybe<Scalars['Float']>;
  creditMovement?: Maybe<Scalars['Float']>;
  subscriptionCreditsBought?: Maybe<Scalars['Float']>;
  totalAllocatedCredits?: Maybe<Scalars['Float']>;
  totalCreditsSpent?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "lightning_session" */
export type LightningSession = {
  __typename?: 'LightningSession';
  borDetails?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  classEvent: ClassEvents;
  classEventId: Scalars['uuid'];
  /** An object relationship */
  createdBy: Users;
  createdById: Scalars['uuid'];
  endTime?: Maybe<Scalars['timestamp']>;
  id: Scalars['uuid'];
  rooms: Scalars['jsonb'];
  startTime: Scalars['timestamp'];
  /** An object relationship */
  updatedBy?: Maybe<Users>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "lightning_session" */
export type LightningSessionBorDetailsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "lightning_session" */
export type LightningSessionRoomsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "lightning_session" */
export type LightningSessionAggregate = {
  __typename?: 'LightningSessionAggregate';
  aggregate?: Maybe<LightningSessionAggregateFields>;
  nodes: Array<LightningSession>;
};

/** aggregate fields of "lightning_session" */
export type LightningSessionAggregateFields = {
  __typename?: 'LightningSessionAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<LightningSessionMaxFields>;
  min?: Maybe<LightningSessionMinFields>;
};

/** aggregate fields of "lightning_session" */
export type LightningSessionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LightningSessionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type LightningSessionAppendInput = {
  borDetails?: InputMaybe<Scalars['jsonb']>;
  rooms?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "lightning_session". All fields are combined with a logical 'AND'. */
export type LightningSessionBoolExp = {
  _and?: InputMaybe<Array<LightningSessionBoolExp>>;
  _not?: InputMaybe<LightningSessionBoolExp>;
  _or?: InputMaybe<Array<LightningSessionBoolExp>>;
  borDetails?: InputMaybe<JsonbComparisonExp>;
  classEvent?: InputMaybe<ClassEventsBoolExp>;
  classEventId?: InputMaybe<UuidComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  endTime?: InputMaybe<TimestampComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  rooms?: InputMaybe<JsonbComparisonExp>;
  startTime?: InputMaybe<TimestampComparisonExp>;
  updatedBy?: InputMaybe<UsersBoolExp>;
  updatedById?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "lightning_session" */
export enum LightningSessionConstraint {
  /** unique or primary key constraint on columns "class_event_id" */
  lightning_session_class_event_id_key = 'lightning_session_class_event_id_key',
  /** unique or primary key constraint on columns "id" */
  lightning_session_pkey = 'lightning_session_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type LightningSessionDeleteAtPathInput = {
  borDetails?: InputMaybe<Array<Scalars['String']>>;
  rooms?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type LightningSessionDeleteElemInput = {
  borDetails?: InputMaybe<Scalars['Int']>;
  rooms?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type LightningSessionDeleteKeyInput = {
  borDetails?: InputMaybe<Scalars['String']>;
  rooms?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "lightning_session" */
export type LightningSessionInsertInput = {
  borDetails?: InputMaybe<Scalars['jsonb']>;
  classEvent?: InputMaybe<ClassEventsObjRelInsertInput>;
  classEventId?: InputMaybe<Scalars['uuid']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  endTime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  rooms?: InputMaybe<Scalars['jsonb']>;
  startTime?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<UsersObjRelInsertInput>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type LightningSessionMaxFields = {
  __typename?: 'LightningSessionMaxFields';
  classEventId?: Maybe<Scalars['uuid']>;
  createdById?: Maybe<Scalars['uuid']>;
  endTime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  startTime?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type LightningSessionMinFields = {
  __typename?: 'LightningSessionMinFields';
  classEventId?: Maybe<Scalars['uuid']>;
  createdById?: Maybe<Scalars['uuid']>;
  endTime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  startTime?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "lightning_session" */
export type LightningSessionMutationResponse = {
  __typename?: 'LightningSessionMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LightningSession>;
};

/** input type for inserting object relation for remote table "lightning_session" */
export type LightningSessionObjRelInsertInput = {
  data: LightningSessionInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<LightningSessionOnConflict>;
};

/** on_conflict condition type for table "lightning_session" */
export type LightningSessionOnConflict = {
  constraint: LightningSessionConstraint;
  updateColumns?: Array<LightningSessionUpdateColumn>;
  where?: InputMaybe<LightningSessionBoolExp>;
};

/** Ordering options when selecting data from "lightning_session". */
export type LightningSessionOrderBy = {
  borDetails?: InputMaybe<OrderBy>;
  classEvent?: InputMaybe<ClassEventsOrderBy>;
  classEventId?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  endTime?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  rooms?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<UsersOrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: lightning_session */
export type LightningSessionPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type LightningSessionPrependInput = {
  borDetails?: InputMaybe<Scalars['jsonb']>;
  rooms?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "lightning_session" */
export enum LightningSessionSelectColumn {
  /** column name */
  borDetails = 'borDetails',
  /** column name */
  classEventId = 'classEventId',
  /** column name */
  createdById = 'createdById',
  /** column name */
  endTime = 'endTime',
  /** column name */
  id = 'id',
  /** column name */
  rooms = 'rooms',
  /** column name */
  startTime = 'startTime',
  /** column name */
  updatedById = 'updatedById'
}

/** input type for updating data in table "lightning_session" */
export type LightningSessionSetInput = {
  borDetails?: InputMaybe<Scalars['jsonb']>;
  classEventId?: InputMaybe<Scalars['uuid']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  endTime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  rooms?: InputMaybe<Scalars['jsonb']>;
  startTime?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "lightning_session" */
export type LightningSessionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: LightningSessionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LightningSessionStreamCursorValueInput = {
  borDetails?: InputMaybe<Scalars['jsonb']>;
  classEventId?: InputMaybe<Scalars['uuid']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  endTime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  rooms?: InputMaybe<Scalars['jsonb']>;
  startTime?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "lightning_session_submissions" */
export type LightningSessionSubmissions = {
  __typename?: 'LightningSessionSubmissions';
  /** An object relationship */
  coach?: Maybe<Users>;
  coachEmailSent?: Maybe<Scalars['Boolean']>;
  coachUserId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  cohort: Cohorts;
  cohortId: Scalars['uuid'];
  comment?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['timestamp']>;
  createdAt: Scalars['timestamp'];
  createdBy?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  interactionsCount?: Maybe<Scalars['Int']>;
  isNotDeleted?: Maybe<Scalars['Boolean']>;
  learnerEmailSent?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  pitchSegment?: Maybe<ClassEventMeetingSegments>;
  pitchSegmentId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  relatedUsers: Array<LightningSessionSubmissionsUsersRel>;
  /** An aggregate relationship */
  relatedUsersAggregate: LightningSessionSubmissionsUsersRelAggregate;
  score?: Maybe<Scalars['String']>;
  status: LightningSessionSubmissionsStatusEnum;
  streamChannelId: Scalars['String'];
  /** An array relationship */
  streamMessages: Array<StreamMessages>;
  /** An aggregate relationship */
  streamMessagesAggregate: StreamMessagesAggregate;
  updatedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  user: Users;
  userId: Scalars['uuid'];
};

/** columns and relationships of "lightning_session_submissions" */
export type LightningSessionSubmissionsRelatedUsersArgs = {
  distinctOn?: InputMaybe<Array<LightningSessionSubmissionsUsersRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LightningSessionSubmissionsUsersRelOrderBy>>;
  where?: InputMaybe<LightningSessionSubmissionsUsersRelBoolExp>;
};

/** columns and relationships of "lightning_session_submissions" */
export type LightningSessionSubmissionsRelatedUsersAggregateArgs = {
  distinctOn?: InputMaybe<Array<LightningSessionSubmissionsUsersRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LightningSessionSubmissionsUsersRelOrderBy>>;
  where?: InputMaybe<LightningSessionSubmissionsUsersRelBoolExp>;
};

/** columns and relationships of "lightning_session_submissions" */
export type LightningSessionSubmissionsStreamMessagesArgs = {
  distinctOn?: InputMaybe<Array<StreamMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StreamMessagesOrderBy>>;
  where?: InputMaybe<StreamMessagesBoolExp>;
};

/** columns and relationships of "lightning_session_submissions" */
export type LightningSessionSubmissionsStreamMessagesAggregateArgs = {
  distinctOn?: InputMaybe<Array<StreamMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StreamMessagesOrderBy>>;
  where?: InputMaybe<StreamMessagesBoolExp>;
};

/** aggregated selection of "lightning_session_submissions" */
export type LightningSessionSubmissionsAggregate = {
  __typename?: 'LightningSessionSubmissionsAggregate';
  aggregate?: Maybe<LightningSessionSubmissionsAggregateFields>;
  nodes: Array<LightningSessionSubmissions>;
};

export type LightningSessionSubmissionsAggregateBoolExp = {
  bool_and?: InputMaybe<LightningSessionSubmissionsAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<LightningSessionSubmissionsAggregateBoolExpBool_Or>;
  count?: InputMaybe<LightningSessionSubmissionsAggregateBoolExpCount>;
};

/** aggregate fields of "lightning_session_submissions" */
export type LightningSessionSubmissionsAggregateFields = {
  __typename?: 'LightningSessionSubmissionsAggregateFields';
  avg?: Maybe<LightningSessionSubmissionsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LightningSessionSubmissionsMaxFields>;
  min?: Maybe<LightningSessionSubmissionsMinFields>;
  stddev?: Maybe<LightningSessionSubmissionsStddevFields>;
  stddevPop?: Maybe<LightningSessionSubmissionsStddevPopFields>;
  stddevSamp?: Maybe<LightningSessionSubmissionsStddevSampFields>;
  sum?: Maybe<LightningSessionSubmissionsSumFields>;
  varPop?: Maybe<LightningSessionSubmissionsVarPopFields>;
  varSamp?: Maybe<LightningSessionSubmissionsVarSampFields>;
  variance?: Maybe<LightningSessionSubmissionsVarianceFields>;
};

/** aggregate fields of "lightning_session_submissions" */
export type LightningSessionSubmissionsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LightningSessionSubmissionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lightning_session_submissions" */
export type LightningSessionSubmissionsAggregateOrderBy = {
  avg?: InputMaybe<LightningSessionSubmissionsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<LightningSessionSubmissionsMaxOrderBy>;
  min?: InputMaybe<LightningSessionSubmissionsMinOrderBy>;
  stddev?: InputMaybe<LightningSessionSubmissionsStddevOrderBy>;
  stddevPop?: InputMaybe<LightningSessionSubmissionsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<LightningSessionSubmissionsStddevSampOrderBy>;
  sum?: InputMaybe<LightningSessionSubmissionsSumOrderBy>;
  varPop?: InputMaybe<LightningSessionSubmissionsVarPopOrderBy>;
  varSamp?: InputMaybe<LightningSessionSubmissionsVarSampOrderBy>;
  variance?: InputMaybe<LightningSessionSubmissionsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "lightning_session_submissions" */
export type LightningSessionSubmissionsArrRelInsertInput = {
  data: Array<LightningSessionSubmissionsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<LightningSessionSubmissionsOnConflict>;
};

/** aggregate avg on columns */
export type LightningSessionSubmissionsAvgFields = {
  __typename?: 'LightningSessionSubmissionsAvgFields';
  interactionsCount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lightning_session_submissions" */
export type LightningSessionSubmissionsAvgOrderBy = {
  interactionsCount?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "lightning_session_submissions". All fields are combined with a logical 'AND'. */
export type LightningSessionSubmissionsBoolExp = {
  _and?: InputMaybe<Array<LightningSessionSubmissionsBoolExp>>;
  _not?: InputMaybe<LightningSessionSubmissionsBoolExp>;
  _or?: InputMaybe<Array<LightningSessionSubmissionsBoolExp>>;
  coach?: InputMaybe<UsersBoolExp>;
  coachEmailSent?: InputMaybe<BooleanComparisonExp>;
  coachUserId?: InputMaybe<UuidComparisonExp>;
  cohort?: InputMaybe<CohortsBoolExp>;
  cohortId?: InputMaybe<UuidComparisonExp>;
  comment?: InputMaybe<StringComparisonExp>;
  completedAt?: InputMaybe<TimestampComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UuidComparisonExp>;
  deletedAt?: InputMaybe<TimestampComparisonExp>;
  deletedById?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  interactionsCount?: InputMaybe<IntComparisonExp>;
  isNotDeleted?: InputMaybe<BooleanComparisonExp>;
  learnerEmailSent?: InputMaybe<BooleanComparisonExp>;
  pitchSegment?: InputMaybe<ClassEventMeetingSegmentsBoolExp>;
  pitchSegmentId?: InputMaybe<UuidComparisonExp>;
  relatedUsers?: InputMaybe<LightningSessionSubmissionsUsersRelBoolExp>;
  relatedUsersAggregate?: InputMaybe<LightningSessionSubmissionsUsersRelAggregateBoolExp>;
  score?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<LightningSessionSubmissionsStatusEnumComparisonExp>;
  streamChannelId?: InputMaybe<StringComparisonExp>;
  streamMessages?: InputMaybe<StreamMessagesBoolExp>;
  streamMessagesAggregate?: InputMaybe<StreamMessagesAggregateBoolExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "lightning_session_submissions" */
export enum LightningSessionSubmissionsConstraint {
  /** unique or primary key constraint on columns "is_not_deleted", "user_id", "cohort_id" */
  lightning_session_submissions_cohort_id_user_id_deleted_at_key = 'lightning_session_submissions_cohort_id_user_id_deleted_at_key',
  /** unique or primary key constraint on columns "id" */
  lightning_session_submissions_pkey = 'lightning_session_submissions_pkey'
}

/** input type for incrementing numeric columns in table "lightning_session_submissions" */
export type LightningSessionSubmissionsIncInput = {
  interactionsCount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "lightning_session_submissions" */
export type LightningSessionSubmissionsInsertInput = {
  coach?: InputMaybe<UsersObjRelInsertInput>;
  coachEmailSent?: InputMaybe<Scalars['Boolean']>;
  coachUserId?: InputMaybe<Scalars['uuid']>;
  cohort?: InputMaybe<CohortsObjRelInsertInput>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  comment?: InputMaybe<Scalars['String']>;
  completedAt?: InputMaybe<Scalars['timestamp']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  interactionsCount?: InputMaybe<Scalars['Int']>;
  learnerEmailSent?: InputMaybe<Scalars['Boolean']>;
  pitchSegment?: InputMaybe<ClassEventMeetingSegmentsObjRelInsertInput>;
  pitchSegmentId?: InputMaybe<Scalars['uuid']>;
  relatedUsers?: InputMaybe<LightningSessionSubmissionsUsersRelArrRelInsertInput>;
  score?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<LightningSessionSubmissionsStatusEnum>;
  streamChannelId?: InputMaybe<Scalars['String']>;
  streamMessages?: InputMaybe<StreamMessagesArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type LightningSessionSubmissionsMaxFields = {
  __typename?: 'LightningSessionSubmissionsMaxFields';
  coachUserId?: Maybe<Scalars['uuid']>;
  cohortId?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['timestamp']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdBy?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  interactionsCount?: Maybe<Scalars['Int']>;
  pitchSegmentId?: Maybe<Scalars['uuid']>;
  score?: Maybe<Scalars['String']>;
  streamChannelId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "lightning_session_submissions" */
export type LightningSessionSubmissionsMaxOrderBy = {
  coachUserId?: InputMaybe<OrderBy>;
  cohortId?: InputMaybe<OrderBy>;
  comment?: InputMaybe<OrderBy>;
  completedAt?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  interactionsCount?: InputMaybe<OrderBy>;
  pitchSegmentId?: InputMaybe<OrderBy>;
  score?: InputMaybe<OrderBy>;
  streamChannelId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type LightningSessionSubmissionsMinFields = {
  __typename?: 'LightningSessionSubmissionsMinFields';
  coachUserId?: Maybe<Scalars['uuid']>;
  cohortId?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['timestamp']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdBy?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  interactionsCount?: Maybe<Scalars['Int']>;
  pitchSegmentId?: Maybe<Scalars['uuid']>;
  score?: Maybe<Scalars['String']>;
  streamChannelId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "lightning_session_submissions" */
export type LightningSessionSubmissionsMinOrderBy = {
  coachUserId?: InputMaybe<OrderBy>;
  cohortId?: InputMaybe<OrderBy>;
  comment?: InputMaybe<OrderBy>;
  completedAt?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  interactionsCount?: InputMaybe<OrderBy>;
  pitchSegmentId?: InputMaybe<OrderBy>;
  score?: InputMaybe<OrderBy>;
  streamChannelId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "lightning_session_submissions" */
export type LightningSessionSubmissionsMutationResponse = {
  __typename?: 'LightningSessionSubmissionsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LightningSessionSubmissions>;
};

/** input type for inserting object relation for remote table "lightning_session_submissions" */
export type LightningSessionSubmissionsObjRelInsertInput = {
  data: LightningSessionSubmissionsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<LightningSessionSubmissionsOnConflict>;
};

/** on_conflict condition type for table "lightning_session_submissions" */
export type LightningSessionSubmissionsOnConflict = {
  constraint: LightningSessionSubmissionsConstraint;
  updateColumns?: Array<LightningSessionSubmissionsUpdateColumn>;
  where?: InputMaybe<LightningSessionSubmissionsBoolExp>;
};

/** Ordering options when selecting data from "lightning_session_submissions". */
export type LightningSessionSubmissionsOrderBy = {
  coach?: InputMaybe<UsersOrderBy>;
  coachEmailSent?: InputMaybe<OrderBy>;
  coachUserId?: InputMaybe<OrderBy>;
  cohort?: InputMaybe<CohortsOrderBy>;
  cohortId?: InputMaybe<OrderBy>;
  comment?: InputMaybe<OrderBy>;
  completedAt?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  interactionsCount?: InputMaybe<OrderBy>;
  isNotDeleted?: InputMaybe<OrderBy>;
  learnerEmailSent?: InputMaybe<OrderBy>;
  pitchSegment?: InputMaybe<ClassEventMeetingSegmentsOrderBy>;
  pitchSegmentId?: InputMaybe<OrderBy>;
  relatedUsersAggregate?: InputMaybe<LightningSessionSubmissionsUsersRelAggregateOrderBy>;
  score?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  streamChannelId?: InputMaybe<OrderBy>;
  streamMessagesAggregate?: InputMaybe<StreamMessagesAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: lightning_session_submissions */
export type LightningSessionSubmissionsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "lightning_session_submissions" */
export enum LightningSessionSubmissionsSelectColumn {
  /** column name */
  coachEmailSent = 'coachEmailSent',
  /** column name */
  coachUserId = 'coachUserId',
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  comment = 'comment',
  /** column name */
  completedAt = 'completedAt',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdBy = 'createdBy',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  id = 'id',
  /** column name */
  interactionsCount = 'interactionsCount',
  /** column name */
  isNotDeleted = 'isNotDeleted',
  /** column name */
  learnerEmailSent = 'learnerEmailSent',
  /** column name */
  pitchSegmentId = 'pitchSegmentId',
  /** column name */
  score = 'score',
  /** column name */
  status = 'status',
  /** column name */
  streamChannelId = 'streamChannelId',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  userId = 'userId'
}

/** select "lightningSessionSubmissionsAggregateBoolExpBool_andArgumentsColumns" columns of table "lightning_session_submissions" */
export enum LightningSessionSubmissionsSelectColumnLightningSessionSubmissionsAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  coachEmailSent = 'coachEmailSent',
  /** column name */
  isNotDeleted = 'isNotDeleted',
  /** column name */
  learnerEmailSent = 'learnerEmailSent'
}

/** select "lightningSessionSubmissionsAggregateBoolExpBool_orArgumentsColumns" columns of table "lightning_session_submissions" */
export enum LightningSessionSubmissionsSelectColumnLightningSessionSubmissionsAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  coachEmailSent = 'coachEmailSent',
  /** column name */
  isNotDeleted = 'isNotDeleted',
  /** column name */
  learnerEmailSent = 'learnerEmailSent'
}

/** input type for updating data in table "lightning_session_submissions" */
export type LightningSessionSubmissionsSetInput = {
  coachEmailSent?: InputMaybe<Scalars['Boolean']>;
  coachUserId?: InputMaybe<Scalars['uuid']>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  comment?: InputMaybe<Scalars['String']>;
  completedAt?: InputMaybe<Scalars['timestamp']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  interactionsCount?: InputMaybe<Scalars['Int']>;
  learnerEmailSent?: InputMaybe<Scalars['Boolean']>;
  pitchSegmentId?: InputMaybe<Scalars['uuid']>;
  score?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<LightningSessionSubmissionsStatusEnum>;
  streamChannelId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Boolean expression to compare columns of type "lightningSessionSubmissionsStatusEnum". All fields are combined with logical 'AND'. */
export type LightningSessionSubmissionsStatusEnumComparisonExp = {
  _eq?: InputMaybe<LightningSessionSubmissionsStatusEnum>;
  _in?: InputMaybe<Array<LightningSessionSubmissionsStatusEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<LightningSessionSubmissionsStatusEnum>;
  _nin?: InputMaybe<Array<LightningSessionSubmissionsStatusEnum>>;
};

/** aggregate stddev on columns */
export type LightningSessionSubmissionsStddevFields = {
  __typename?: 'LightningSessionSubmissionsStddevFields';
  interactionsCount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lightning_session_submissions" */
export type LightningSessionSubmissionsStddevOrderBy = {
  interactionsCount?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type LightningSessionSubmissionsStddevPopFields = {
  __typename?: 'LightningSessionSubmissionsStddevPopFields';
  interactionsCount?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "lightning_session_submissions" */
export type LightningSessionSubmissionsStddevPopOrderBy = {
  interactionsCount?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type LightningSessionSubmissionsStddevSampFields = {
  __typename?: 'LightningSessionSubmissionsStddevSampFields';
  interactionsCount?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "lightning_session_submissions" */
export type LightningSessionSubmissionsStddevSampOrderBy = {
  interactionsCount?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "lightning_session_submissions" */
export type LightningSessionSubmissionsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: LightningSessionSubmissionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LightningSessionSubmissionsStreamCursorValueInput = {
  coachEmailSent?: InputMaybe<Scalars['Boolean']>;
  coachUserId?: InputMaybe<Scalars['uuid']>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  comment?: InputMaybe<Scalars['String']>;
  completedAt?: InputMaybe<Scalars['timestamp']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  interactionsCount?: InputMaybe<Scalars['Int']>;
  isNotDeleted?: InputMaybe<Scalars['Boolean']>;
  learnerEmailSent?: InputMaybe<Scalars['Boolean']>;
  pitchSegmentId?: InputMaybe<Scalars['uuid']>;
  score?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<LightningSessionSubmissionsStatusEnum>;
  streamChannelId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type LightningSessionSubmissionsSumFields = {
  __typename?: 'LightningSessionSubmissionsSumFields';
  interactionsCount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lightning_session_submissions" */
export type LightningSessionSubmissionsSumOrderBy = {
  interactionsCount?: InputMaybe<OrderBy>;
};

/** update columns of table "lightning_session_submissions" */
export enum LightningSessionSubmissionsUpdateColumn {
  /** column name */
  coachEmailSent = 'coachEmailSent',
  /** column name */
  coachUserId = 'coachUserId',
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  comment = 'comment',
  /** column name */
  completedAt = 'completedAt',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdBy = 'createdBy',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  id = 'id',
  /** column name */
  interactionsCount = 'interactionsCount',
  /** column name */
  learnerEmailSent = 'learnerEmailSent',
  /** column name */
  pitchSegmentId = 'pitchSegmentId',
  /** column name */
  score = 'score',
  /** column name */
  status = 'status',
  /** column name */
  streamChannelId = 'streamChannelId',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  userId = 'userId'
}

export type LightningSessionSubmissionsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<LightningSessionSubmissionsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LightningSessionSubmissionsSetInput>;
  /** filter the rows which have to be updated */
  where: LightningSessionSubmissionsBoolExp;
};

/** columns and relationships of "lightning_session_submissions_users_rel" */
export type LightningSessionSubmissionsUsersRel = {
  __typename?: 'LightningSessionSubmissionsUsersRel';
  lightningSessionSubmissionId: Scalars['uuid'];
  /** An object relationship */
  submission: LightningSessionSubmissions;
  /** An object relationship */
  user: Users;
  userId: Scalars['uuid'];
};

/** aggregated selection of "lightning_session_submissions_users_rel" */
export type LightningSessionSubmissionsUsersRelAggregate = {
  __typename?: 'LightningSessionSubmissionsUsersRelAggregate';
  aggregate?: Maybe<LightningSessionSubmissionsUsersRelAggregateFields>;
  nodes: Array<LightningSessionSubmissionsUsersRel>;
};

export type LightningSessionSubmissionsUsersRelAggregateBoolExp = {
  count?: InputMaybe<LightningSessionSubmissionsUsersRelAggregateBoolExpCount>;
};

/** aggregate fields of "lightning_session_submissions_users_rel" */
export type LightningSessionSubmissionsUsersRelAggregateFields = {
  __typename?: 'LightningSessionSubmissionsUsersRelAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<LightningSessionSubmissionsUsersRelMaxFields>;
  min?: Maybe<LightningSessionSubmissionsUsersRelMinFields>;
};

/** aggregate fields of "lightning_session_submissions_users_rel" */
export type LightningSessionSubmissionsUsersRelAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LightningSessionSubmissionsUsersRelSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lightning_session_submissions_users_rel" */
export type LightningSessionSubmissionsUsersRelAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<LightningSessionSubmissionsUsersRelMaxOrderBy>;
  min?: InputMaybe<LightningSessionSubmissionsUsersRelMinOrderBy>;
};

/** input type for inserting array relation for remote table "lightning_session_submissions_users_rel" */
export type LightningSessionSubmissionsUsersRelArrRelInsertInput = {
  data: Array<LightningSessionSubmissionsUsersRelInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<LightningSessionSubmissionsUsersRelOnConflict>;
};

/** Boolean expression to filter rows from the table "lightning_session_submissions_users_rel". All fields are combined with a logical 'AND'. */
export type LightningSessionSubmissionsUsersRelBoolExp = {
  _and?: InputMaybe<Array<LightningSessionSubmissionsUsersRelBoolExp>>;
  _not?: InputMaybe<LightningSessionSubmissionsUsersRelBoolExp>;
  _or?: InputMaybe<Array<LightningSessionSubmissionsUsersRelBoolExp>>;
  lightningSessionSubmissionId?: InputMaybe<UuidComparisonExp>;
  submission?: InputMaybe<LightningSessionSubmissionsBoolExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "lightning_session_submissions_users_rel" */
export enum LightningSessionSubmissionsUsersRelConstraint {
  /** unique or primary key constraint on columns "user_id", "lightning_session_submission_id" */
  lightning_session_submissions_users_rel_pkey = 'lightning_session_submissions_users_rel_pkey'
}

/** input type for inserting data into table "lightning_session_submissions_users_rel" */
export type LightningSessionSubmissionsUsersRelInsertInput = {
  lightningSessionSubmissionId?: InputMaybe<Scalars['uuid']>;
  submission?: InputMaybe<LightningSessionSubmissionsObjRelInsertInput>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type LightningSessionSubmissionsUsersRelMaxFields = {
  __typename?: 'LightningSessionSubmissionsUsersRelMaxFields';
  lightningSessionSubmissionId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "lightning_session_submissions_users_rel" */
export type LightningSessionSubmissionsUsersRelMaxOrderBy = {
  lightningSessionSubmissionId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type LightningSessionSubmissionsUsersRelMinFields = {
  __typename?: 'LightningSessionSubmissionsUsersRelMinFields';
  lightningSessionSubmissionId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "lightning_session_submissions_users_rel" */
export type LightningSessionSubmissionsUsersRelMinOrderBy = {
  lightningSessionSubmissionId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "lightning_session_submissions_users_rel" */
export type LightningSessionSubmissionsUsersRelMutationResponse = {
  __typename?: 'LightningSessionSubmissionsUsersRelMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LightningSessionSubmissionsUsersRel>;
};

/** on_conflict condition type for table "lightning_session_submissions_users_rel" */
export type LightningSessionSubmissionsUsersRelOnConflict = {
  constraint: LightningSessionSubmissionsUsersRelConstraint;
  updateColumns?: Array<LightningSessionSubmissionsUsersRelUpdateColumn>;
  where?: InputMaybe<LightningSessionSubmissionsUsersRelBoolExp>;
};

/** Ordering options when selecting data from "lightning_session_submissions_users_rel". */
export type LightningSessionSubmissionsUsersRelOrderBy = {
  lightningSessionSubmissionId?: InputMaybe<OrderBy>;
  submission?: InputMaybe<LightningSessionSubmissionsOrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: lightning_session_submissions_users_rel */
export type LightningSessionSubmissionsUsersRelPkColumnsInput = {
  lightningSessionSubmissionId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

/** select columns of table "lightning_session_submissions_users_rel" */
export enum LightningSessionSubmissionsUsersRelSelectColumn {
  /** column name */
  lightningSessionSubmissionId = 'lightningSessionSubmissionId',
  /** column name */
  userId = 'userId'
}

/** input type for updating data in table "lightning_session_submissions_users_rel" */
export type LightningSessionSubmissionsUsersRelSetInput = {
  lightningSessionSubmissionId?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "lightning_session_submissions_users_rel" */
export type LightningSessionSubmissionsUsersRelStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: LightningSessionSubmissionsUsersRelStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LightningSessionSubmissionsUsersRelStreamCursorValueInput = {
  lightningSessionSubmissionId?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "lightning_session_submissions_users_rel" */
export enum LightningSessionSubmissionsUsersRelUpdateColumn {
  /** column name */
  lightningSessionSubmissionId = 'lightningSessionSubmissionId',
  /** column name */
  userId = 'userId'
}

export type LightningSessionSubmissionsUsersRelUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LightningSessionSubmissionsUsersRelSetInput>;
  /** filter the rows which have to be updated */
  where: LightningSessionSubmissionsUsersRelBoolExp;
};

/** aggregate varPop on columns */
export type LightningSessionSubmissionsVarPopFields = {
  __typename?: 'LightningSessionSubmissionsVarPopFields';
  interactionsCount?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "lightning_session_submissions" */
export type LightningSessionSubmissionsVarPopOrderBy = {
  interactionsCount?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type LightningSessionSubmissionsVarSampFields = {
  __typename?: 'LightningSessionSubmissionsVarSampFields';
  interactionsCount?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "lightning_session_submissions" */
export type LightningSessionSubmissionsVarSampOrderBy = {
  interactionsCount?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type LightningSessionSubmissionsVarianceFields = {
  __typename?: 'LightningSessionSubmissionsVarianceFields';
  interactionsCount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lightning_session_submissions" */
export type LightningSessionSubmissionsVarianceOrderBy = {
  interactionsCount?: InputMaybe<OrderBy>;
};

/** update columns of table "lightning_session" */
export enum LightningSessionUpdateColumn {
  /** column name */
  borDetails = 'borDetails',
  /** column name */
  classEventId = 'classEventId',
  /** column name */
  createdById = 'createdById',
  /** column name */
  endTime = 'endTime',
  /** column name */
  id = 'id',
  /** column name */
  rooms = 'rooms',
  /** column name */
  startTime = 'startTime',
  /** column name */
  updatedById = 'updatedById'
}

export type LightningSessionUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<LightningSessionAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<LightningSessionDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<LightningSessionDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<LightningSessionDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<LightningSessionPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LightningSessionSetInput>;
  /** filter the rows which have to be updated */
  where: LightningSessionBoolExp;
};

/** columns and relationships of "live_classes" */
export type LiveClasses = {
  __typename?: 'LiveClasses';
  cohortId?: Maybe<Scalars['uuid']>;
  totalActionableClasses?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "live_classes" */
export type LiveClassesAggregate = {
  __typename?: 'LiveClassesAggregate';
  aggregate?: Maybe<LiveClassesAggregateFields>;
  nodes: Array<LiveClasses>;
};

/** aggregate fields of "live_classes" */
export type LiveClassesAggregateFields = {
  __typename?: 'LiveClassesAggregateFields';
  avg?: Maybe<LiveClassesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LiveClassesMaxFields>;
  min?: Maybe<LiveClassesMinFields>;
  stddev?: Maybe<LiveClassesStddevFields>;
  stddevPop?: Maybe<LiveClassesStddevPopFields>;
  stddevSamp?: Maybe<LiveClassesStddevSampFields>;
  sum?: Maybe<LiveClassesSumFields>;
  varPop?: Maybe<LiveClassesVarPopFields>;
  varSamp?: Maybe<LiveClassesVarSampFields>;
  variance?: Maybe<LiveClassesVarianceFields>;
};

/** aggregate fields of "live_classes" */
export type LiveClassesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LiveClassesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type LiveClassesAvgFields = {
  __typename?: 'LiveClassesAvgFields';
  totalActionableClasses?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "live_classes". All fields are combined with a logical 'AND'. */
export type LiveClassesBoolExp = {
  _and?: InputMaybe<Array<LiveClassesBoolExp>>;
  _not?: InputMaybe<LiveClassesBoolExp>;
  _or?: InputMaybe<Array<LiveClassesBoolExp>>;
  cohortId?: InputMaybe<UuidComparisonExp>;
  totalActionableClasses?: InputMaybe<BigintComparisonExp>;
};

/** aggregate max on columns */
export type LiveClassesMaxFields = {
  __typename?: 'LiveClassesMaxFields';
  cohortId?: Maybe<Scalars['uuid']>;
  totalActionableClasses?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type LiveClassesMinFields = {
  __typename?: 'LiveClassesMinFields';
  cohortId?: Maybe<Scalars['uuid']>;
  totalActionableClasses?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "live_classes". */
export type LiveClassesOrderBy = {
  cohortId?: InputMaybe<OrderBy>;
  totalActionableClasses?: InputMaybe<OrderBy>;
};

/** select columns of table "live_classes" */
export enum LiveClassesSelectColumn {
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  totalActionableClasses = 'totalActionableClasses'
}

/** aggregate stddev on columns */
export type LiveClassesStddevFields = {
  __typename?: 'LiveClassesStddevFields';
  totalActionableClasses?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type LiveClassesStddevPopFields = {
  __typename?: 'LiveClassesStddevPopFields';
  totalActionableClasses?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type LiveClassesStddevSampFields = {
  __typename?: 'LiveClassesStddevSampFields';
  totalActionableClasses?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "live_classes" */
export type LiveClassesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: LiveClassesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LiveClassesStreamCursorValueInput = {
  cohortId?: InputMaybe<Scalars['uuid']>;
  totalActionableClasses?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type LiveClassesSumFields = {
  __typename?: 'LiveClassesSumFields';
  totalActionableClasses?: Maybe<Scalars['bigint']>;
};

/** aggregate varPop on columns */
export type LiveClassesVarPopFields = {
  __typename?: 'LiveClassesVarPopFields';
  totalActionableClasses?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type LiveClassesVarSampFields = {
  __typename?: 'LiveClassesVarSampFields';
  totalActionableClasses?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LiveClassesVarianceFields = {
  __typename?: 'LiveClassesVarianceFields';
  totalActionableClasses?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "manager_session_emails" */
export type ManagerSessionEmails = {
  __typename?: 'ManagerSessionEmails';
  attendeesCommaSeparatedList?: Maybe<Scalars['String']>;
  avgImpactScore?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  cohort: Cohorts;
  cohortId: Scalars['uuid'];
  courseName: Scalars['String'];
  coursePlatformUrl: Scalars['String'];
  creditModel?: Maybe<Scalars['String']>;
  csmEmail?: Maybe<Scalars['String']>;
  csmFullName?: Maybe<Scalars['String']>;
  csmSignature?: Maybe<Scalars['String']>;
  enrolleeEmails: Scalars['String'];
  enrolleesCommaSeparatedList: Scalars['String'];
  enrolleesForPast: Scalars['String'];
  enrolleesForUpcoming: Scalars['String'];
  firstClassDuration: Scalars['numeric'];
  firstClassStartTime: Scalars['timestamptz'];
  frameworkHighlight?: Maybe<Scalars['String']>;
  hasPrivateBor: Scalars['Boolean'];
  hasSentPostSessionEmail?: Maybe<Scalars['Boolean']>;
  hasSentPreSessionEmail: Scalars['Boolean'];
  highImpactAttendees?: Maybe<Scalars['String']>;
  isAllFeedbackSkippedOrReady?: Maybe<Scalars['Boolean']>;
  isStartingSoon: Scalars['Boolean'];
  isWithinLast7Days: Scalars['Boolean'];
  managerCohortId: Scalars['String'];
  managerId: Scalars['uuid'];
  managerPastSessionUrl: Scalars['String'];
  managerUpcomingSessionUrl: Scalars['String'];
  numHighImpactAttendees: Scalars['numeric'];
  /** An object relationship */
  organization: Organizations;
  organizationId: Scalars['uuid'];
  percentUsersAttended: Scalars['numeric'];
  posterFrameUrl?: Maybe<Scalars['String']>;
  recommendations?: Maybe<Scalars['String']>;
  sentPostSessionEmailAt?: Maybe<Scalars['timestamptz']>;
  sentPreSessionEmailAt?: Maybe<Scalars['timestamptz']>;
  skills?: Maybe<Scalars['String']>;
  startTimeFormatted: Scalars['String'];
  /** An object relationship */
  user: Users;
};

/** aggregated selection of "manager_session_emails" */
export type ManagerSessionEmailsAggregate = {
  __typename?: 'ManagerSessionEmailsAggregate';
  aggregate?: Maybe<ManagerSessionEmailsAggregateFields>;
  nodes: Array<ManagerSessionEmails>;
};

/** aggregate fields of "manager_session_emails" */
export type ManagerSessionEmailsAggregateFields = {
  __typename?: 'ManagerSessionEmailsAggregateFields';
  avg?: Maybe<ManagerSessionEmailsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ManagerSessionEmailsMaxFields>;
  min?: Maybe<ManagerSessionEmailsMinFields>;
  stddev?: Maybe<ManagerSessionEmailsStddevFields>;
  stddevPop?: Maybe<ManagerSessionEmailsStddevPopFields>;
  stddevSamp?: Maybe<ManagerSessionEmailsStddevSampFields>;
  sum?: Maybe<ManagerSessionEmailsSumFields>;
  varPop?: Maybe<ManagerSessionEmailsVarPopFields>;
  varSamp?: Maybe<ManagerSessionEmailsVarSampFields>;
  variance?: Maybe<ManagerSessionEmailsVarianceFields>;
};

/** aggregate fields of "manager_session_emails" */
export type ManagerSessionEmailsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ManagerSessionEmailsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ManagerSessionEmailsAvgFields = {
  __typename?: 'ManagerSessionEmailsAvgFields';
  avgImpactScore?: Maybe<Scalars['Float']>;
  firstClassDuration?: Maybe<Scalars['Float']>;
  numHighImpactAttendees?: Maybe<Scalars['Float']>;
  percentUsersAttended?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "manager_session_emails". All fields are combined with a logical 'AND'. */
export type ManagerSessionEmailsBoolExp = {
  _and?: InputMaybe<Array<ManagerSessionEmailsBoolExp>>;
  _not?: InputMaybe<ManagerSessionEmailsBoolExp>;
  _or?: InputMaybe<Array<ManagerSessionEmailsBoolExp>>;
  attendeesCommaSeparatedList?: InputMaybe<StringComparisonExp>;
  avgImpactScore?: InputMaybe<NumericComparisonExp>;
  cohort?: InputMaybe<CohortsBoolExp>;
  cohortId?: InputMaybe<UuidComparisonExp>;
  courseName?: InputMaybe<StringComparisonExp>;
  coursePlatformUrl?: InputMaybe<StringComparisonExp>;
  creditModel?: InputMaybe<StringComparisonExp>;
  csmEmail?: InputMaybe<StringComparisonExp>;
  csmFullName?: InputMaybe<StringComparisonExp>;
  csmSignature?: InputMaybe<StringComparisonExp>;
  enrolleeEmails?: InputMaybe<StringComparisonExp>;
  enrolleesCommaSeparatedList?: InputMaybe<StringComparisonExp>;
  enrolleesForPast?: InputMaybe<StringComparisonExp>;
  enrolleesForUpcoming?: InputMaybe<StringComparisonExp>;
  firstClassDuration?: InputMaybe<NumericComparisonExp>;
  firstClassStartTime?: InputMaybe<TimestamptzComparisonExp>;
  frameworkHighlight?: InputMaybe<StringComparisonExp>;
  hasPrivateBor?: InputMaybe<BooleanComparisonExp>;
  hasSentPostSessionEmail?: InputMaybe<BooleanComparisonExp>;
  hasSentPreSessionEmail?: InputMaybe<BooleanComparisonExp>;
  highImpactAttendees?: InputMaybe<StringComparisonExp>;
  isAllFeedbackSkippedOrReady?: InputMaybe<BooleanComparisonExp>;
  isStartingSoon?: InputMaybe<BooleanComparisonExp>;
  isWithinLast7Days?: InputMaybe<BooleanComparisonExp>;
  managerCohortId?: InputMaybe<StringComparisonExp>;
  managerId?: InputMaybe<UuidComparisonExp>;
  managerPastSessionUrl?: InputMaybe<StringComparisonExp>;
  managerUpcomingSessionUrl?: InputMaybe<StringComparisonExp>;
  numHighImpactAttendees?: InputMaybe<NumericComparisonExp>;
  organization?: InputMaybe<OrganizationsBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  percentUsersAttended?: InputMaybe<NumericComparisonExp>;
  posterFrameUrl?: InputMaybe<StringComparisonExp>;
  recommendations?: InputMaybe<StringComparisonExp>;
  sentPostSessionEmailAt?: InputMaybe<TimestamptzComparisonExp>;
  sentPreSessionEmailAt?: InputMaybe<TimestamptzComparisonExp>;
  skills?: InputMaybe<StringComparisonExp>;
  startTimeFormatted?: InputMaybe<StringComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
};

/** unique or primary key constraints on table "manager_session_emails" */
export enum ManagerSessionEmailsConstraint {
  /** unique or primary key constraint on columns "manager_cohort_id" */
  manager_session_emails_pkey = 'manager_session_emails_pkey'
}

/** input type for incrementing numeric columns in table "manager_session_emails" */
export type ManagerSessionEmailsIncInput = {
  avgImpactScore?: InputMaybe<Scalars['numeric']>;
  firstClassDuration?: InputMaybe<Scalars['numeric']>;
  numHighImpactAttendees?: InputMaybe<Scalars['numeric']>;
  percentUsersAttended?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "manager_session_emails" */
export type ManagerSessionEmailsInsertInput = {
  attendeesCommaSeparatedList?: InputMaybe<Scalars['String']>;
  avgImpactScore?: InputMaybe<Scalars['numeric']>;
  cohort?: InputMaybe<CohortsObjRelInsertInput>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  courseName?: InputMaybe<Scalars['String']>;
  coursePlatformUrl?: InputMaybe<Scalars['String']>;
  creditModel?: InputMaybe<Scalars['String']>;
  csmEmail?: InputMaybe<Scalars['String']>;
  csmFullName?: InputMaybe<Scalars['String']>;
  csmSignature?: InputMaybe<Scalars['String']>;
  enrolleeEmails?: InputMaybe<Scalars['String']>;
  enrolleesCommaSeparatedList?: InputMaybe<Scalars['String']>;
  enrolleesForPast?: InputMaybe<Scalars['String']>;
  enrolleesForUpcoming?: InputMaybe<Scalars['String']>;
  firstClassDuration?: InputMaybe<Scalars['numeric']>;
  firstClassStartTime?: InputMaybe<Scalars['timestamptz']>;
  frameworkHighlight?: InputMaybe<Scalars['String']>;
  hasPrivateBor?: InputMaybe<Scalars['Boolean']>;
  hasSentPostSessionEmail?: InputMaybe<Scalars['Boolean']>;
  hasSentPreSessionEmail?: InputMaybe<Scalars['Boolean']>;
  highImpactAttendees?: InputMaybe<Scalars['String']>;
  isAllFeedbackSkippedOrReady?: InputMaybe<Scalars['Boolean']>;
  isStartingSoon?: InputMaybe<Scalars['Boolean']>;
  isWithinLast7Days?: InputMaybe<Scalars['Boolean']>;
  managerCohortId?: InputMaybe<Scalars['String']>;
  managerId?: InputMaybe<Scalars['uuid']>;
  managerPastSessionUrl?: InputMaybe<Scalars['String']>;
  managerUpcomingSessionUrl?: InputMaybe<Scalars['String']>;
  numHighImpactAttendees?: InputMaybe<Scalars['numeric']>;
  organization?: InputMaybe<OrganizationsObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  percentUsersAttended?: InputMaybe<Scalars['numeric']>;
  posterFrameUrl?: InputMaybe<Scalars['String']>;
  recommendations?: InputMaybe<Scalars['String']>;
  sentPostSessionEmailAt?: InputMaybe<Scalars['timestamptz']>;
  sentPreSessionEmailAt?: InputMaybe<Scalars['timestamptz']>;
  skills?: InputMaybe<Scalars['String']>;
  startTimeFormatted?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
};

/** aggregate max on columns */
export type ManagerSessionEmailsMaxFields = {
  __typename?: 'ManagerSessionEmailsMaxFields';
  attendeesCommaSeparatedList?: Maybe<Scalars['String']>;
  avgImpactScore?: Maybe<Scalars['numeric']>;
  cohortId?: Maybe<Scalars['uuid']>;
  courseName?: Maybe<Scalars['String']>;
  coursePlatformUrl?: Maybe<Scalars['String']>;
  creditModel?: Maybe<Scalars['String']>;
  csmEmail?: Maybe<Scalars['String']>;
  csmFullName?: Maybe<Scalars['String']>;
  csmSignature?: Maybe<Scalars['String']>;
  enrolleeEmails?: Maybe<Scalars['String']>;
  enrolleesCommaSeparatedList?: Maybe<Scalars['String']>;
  enrolleesForPast?: Maybe<Scalars['String']>;
  enrolleesForUpcoming?: Maybe<Scalars['String']>;
  firstClassDuration?: Maybe<Scalars['numeric']>;
  firstClassStartTime?: Maybe<Scalars['timestamptz']>;
  frameworkHighlight?: Maybe<Scalars['String']>;
  highImpactAttendees?: Maybe<Scalars['String']>;
  managerCohortId?: Maybe<Scalars['String']>;
  managerId?: Maybe<Scalars['uuid']>;
  managerPastSessionUrl?: Maybe<Scalars['String']>;
  managerUpcomingSessionUrl?: Maybe<Scalars['String']>;
  numHighImpactAttendees?: Maybe<Scalars['numeric']>;
  organizationId?: Maybe<Scalars['uuid']>;
  percentUsersAttended?: Maybe<Scalars['numeric']>;
  posterFrameUrl?: Maybe<Scalars['String']>;
  recommendations?: Maybe<Scalars['String']>;
  sentPostSessionEmailAt?: Maybe<Scalars['timestamptz']>;
  sentPreSessionEmailAt?: Maybe<Scalars['timestamptz']>;
  skills?: Maybe<Scalars['String']>;
  startTimeFormatted?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ManagerSessionEmailsMinFields = {
  __typename?: 'ManagerSessionEmailsMinFields';
  attendeesCommaSeparatedList?: Maybe<Scalars['String']>;
  avgImpactScore?: Maybe<Scalars['numeric']>;
  cohortId?: Maybe<Scalars['uuid']>;
  courseName?: Maybe<Scalars['String']>;
  coursePlatformUrl?: Maybe<Scalars['String']>;
  creditModel?: Maybe<Scalars['String']>;
  csmEmail?: Maybe<Scalars['String']>;
  csmFullName?: Maybe<Scalars['String']>;
  csmSignature?: Maybe<Scalars['String']>;
  enrolleeEmails?: Maybe<Scalars['String']>;
  enrolleesCommaSeparatedList?: Maybe<Scalars['String']>;
  enrolleesForPast?: Maybe<Scalars['String']>;
  enrolleesForUpcoming?: Maybe<Scalars['String']>;
  firstClassDuration?: Maybe<Scalars['numeric']>;
  firstClassStartTime?: Maybe<Scalars['timestamptz']>;
  frameworkHighlight?: Maybe<Scalars['String']>;
  highImpactAttendees?: Maybe<Scalars['String']>;
  managerCohortId?: Maybe<Scalars['String']>;
  managerId?: Maybe<Scalars['uuid']>;
  managerPastSessionUrl?: Maybe<Scalars['String']>;
  managerUpcomingSessionUrl?: Maybe<Scalars['String']>;
  numHighImpactAttendees?: Maybe<Scalars['numeric']>;
  organizationId?: Maybe<Scalars['uuid']>;
  percentUsersAttended?: Maybe<Scalars['numeric']>;
  posterFrameUrl?: Maybe<Scalars['String']>;
  recommendations?: Maybe<Scalars['String']>;
  sentPostSessionEmailAt?: Maybe<Scalars['timestamptz']>;
  sentPreSessionEmailAt?: Maybe<Scalars['timestamptz']>;
  skills?: Maybe<Scalars['String']>;
  startTimeFormatted?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "manager_session_emails" */
export type ManagerSessionEmailsMutationResponse = {
  __typename?: 'ManagerSessionEmailsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ManagerSessionEmails>;
};

/** on_conflict condition type for table "manager_session_emails" */
export type ManagerSessionEmailsOnConflict = {
  constraint: ManagerSessionEmailsConstraint;
  updateColumns?: Array<ManagerSessionEmailsUpdateColumn>;
  where?: InputMaybe<ManagerSessionEmailsBoolExp>;
};

/** Ordering options when selecting data from "manager_session_emails". */
export type ManagerSessionEmailsOrderBy = {
  attendeesCommaSeparatedList?: InputMaybe<OrderBy>;
  avgImpactScore?: InputMaybe<OrderBy>;
  cohort?: InputMaybe<CohortsOrderBy>;
  cohortId?: InputMaybe<OrderBy>;
  courseName?: InputMaybe<OrderBy>;
  coursePlatformUrl?: InputMaybe<OrderBy>;
  creditModel?: InputMaybe<OrderBy>;
  csmEmail?: InputMaybe<OrderBy>;
  csmFullName?: InputMaybe<OrderBy>;
  csmSignature?: InputMaybe<OrderBy>;
  enrolleeEmails?: InputMaybe<OrderBy>;
  enrolleesCommaSeparatedList?: InputMaybe<OrderBy>;
  enrolleesForPast?: InputMaybe<OrderBy>;
  enrolleesForUpcoming?: InputMaybe<OrderBy>;
  firstClassDuration?: InputMaybe<OrderBy>;
  firstClassStartTime?: InputMaybe<OrderBy>;
  frameworkHighlight?: InputMaybe<OrderBy>;
  hasPrivateBor?: InputMaybe<OrderBy>;
  hasSentPostSessionEmail?: InputMaybe<OrderBy>;
  hasSentPreSessionEmail?: InputMaybe<OrderBy>;
  highImpactAttendees?: InputMaybe<OrderBy>;
  isAllFeedbackSkippedOrReady?: InputMaybe<OrderBy>;
  isStartingSoon?: InputMaybe<OrderBy>;
  isWithinLast7Days?: InputMaybe<OrderBy>;
  managerCohortId?: InputMaybe<OrderBy>;
  managerId?: InputMaybe<OrderBy>;
  managerPastSessionUrl?: InputMaybe<OrderBy>;
  managerUpcomingSessionUrl?: InputMaybe<OrderBy>;
  numHighImpactAttendees?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationsOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  percentUsersAttended?: InputMaybe<OrderBy>;
  posterFrameUrl?: InputMaybe<OrderBy>;
  recommendations?: InputMaybe<OrderBy>;
  sentPostSessionEmailAt?: InputMaybe<OrderBy>;
  sentPreSessionEmailAt?: InputMaybe<OrderBy>;
  skills?: InputMaybe<OrderBy>;
  startTimeFormatted?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
};

/** primary key columns input for table: manager_session_emails */
export type ManagerSessionEmailsPkColumnsInput = {
  managerCohortId: Scalars['String'];
};

/** select columns of table "manager_session_emails" */
export enum ManagerSessionEmailsSelectColumn {
  /** column name */
  attendeesCommaSeparatedList = 'attendeesCommaSeparatedList',
  /** column name */
  avgImpactScore = 'avgImpactScore',
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  courseName = 'courseName',
  /** column name */
  coursePlatformUrl = 'coursePlatformUrl',
  /** column name */
  creditModel = 'creditModel',
  /** column name */
  csmEmail = 'csmEmail',
  /** column name */
  csmFullName = 'csmFullName',
  /** column name */
  csmSignature = 'csmSignature',
  /** column name */
  enrolleeEmails = 'enrolleeEmails',
  /** column name */
  enrolleesCommaSeparatedList = 'enrolleesCommaSeparatedList',
  /** column name */
  enrolleesForPast = 'enrolleesForPast',
  /** column name */
  enrolleesForUpcoming = 'enrolleesForUpcoming',
  /** column name */
  firstClassDuration = 'firstClassDuration',
  /** column name */
  firstClassStartTime = 'firstClassStartTime',
  /** column name */
  frameworkHighlight = 'frameworkHighlight',
  /** column name */
  hasPrivateBor = 'hasPrivateBor',
  /** column name */
  hasSentPostSessionEmail = 'hasSentPostSessionEmail',
  /** column name */
  hasSentPreSessionEmail = 'hasSentPreSessionEmail',
  /** column name */
  highImpactAttendees = 'highImpactAttendees',
  /** column name */
  isAllFeedbackSkippedOrReady = 'isAllFeedbackSkippedOrReady',
  /** column name */
  isStartingSoon = 'isStartingSoon',
  /** column name */
  isWithinLast7Days = 'isWithinLast7Days',
  /** column name */
  managerCohortId = 'managerCohortId',
  /** column name */
  managerId = 'managerId',
  /** column name */
  managerPastSessionUrl = 'managerPastSessionUrl',
  /** column name */
  managerUpcomingSessionUrl = 'managerUpcomingSessionUrl',
  /** column name */
  numHighImpactAttendees = 'numHighImpactAttendees',
  /** column name */
  organizationId = 'organizationId',
  /** column name */
  percentUsersAttended = 'percentUsersAttended',
  /** column name */
  posterFrameUrl = 'posterFrameUrl',
  /** column name */
  recommendations = 'recommendations',
  /** column name */
  sentPostSessionEmailAt = 'sentPostSessionEmailAt',
  /** column name */
  sentPreSessionEmailAt = 'sentPreSessionEmailAt',
  /** column name */
  skills = 'skills',
  /** column name */
  startTimeFormatted = 'startTimeFormatted'
}

/** input type for updating data in table "manager_session_emails" */
export type ManagerSessionEmailsSetInput = {
  attendeesCommaSeparatedList?: InputMaybe<Scalars['String']>;
  avgImpactScore?: InputMaybe<Scalars['numeric']>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  courseName?: InputMaybe<Scalars['String']>;
  coursePlatformUrl?: InputMaybe<Scalars['String']>;
  creditModel?: InputMaybe<Scalars['String']>;
  csmEmail?: InputMaybe<Scalars['String']>;
  csmFullName?: InputMaybe<Scalars['String']>;
  csmSignature?: InputMaybe<Scalars['String']>;
  enrolleeEmails?: InputMaybe<Scalars['String']>;
  enrolleesCommaSeparatedList?: InputMaybe<Scalars['String']>;
  enrolleesForPast?: InputMaybe<Scalars['String']>;
  enrolleesForUpcoming?: InputMaybe<Scalars['String']>;
  firstClassDuration?: InputMaybe<Scalars['numeric']>;
  firstClassStartTime?: InputMaybe<Scalars['timestamptz']>;
  frameworkHighlight?: InputMaybe<Scalars['String']>;
  hasPrivateBor?: InputMaybe<Scalars['Boolean']>;
  hasSentPostSessionEmail?: InputMaybe<Scalars['Boolean']>;
  hasSentPreSessionEmail?: InputMaybe<Scalars['Boolean']>;
  highImpactAttendees?: InputMaybe<Scalars['String']>;
  isAllFeedbackSkippedOrReady?: InputMaybe<Scalars['Boolean']>;
  isStartingSoon?: InputMaybe<Scalars['Boolean']>;
  isWithinLast7Days?: InputMaybe<Scalars['Boolean']>;
  managerCohortId?: InputMaybe<Scalars['String']>;
  managerId?: InputMaybe<Scalars['uuid']>;
  managerPastSessionUrl?: InputMaybe<Scalars['String']>;
  managerUpcomingSessionUrl?: InputMaybe<Scalars['String']>;
  numHighImpactAttendees?: InputMaybe<Scalars['numeric']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  percentUsersAttended?: InputMaybe<Scalars['numeric']>;
  posterFrameUrl?: InputMaybe<Scalars['String']>;
  recommendations?: InputMaybe<Scalars['String']>;
  sentPostSessionEmailAt?: InputMaybe<Scalars['timestamptz']>;
  sentPreSessionEmailAt?: InputMaybe<Scalars['timestamptz']>;
  skills?: InputMaybe<Scalars['String']>;
  startTimeFormatted?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ManagerSessionEmailsStddevFields = {
  __typename?: 'ManagerSessionEmailsStddevFields';
  avgImpactScore?: Maybe<Scalars['Float']>;
  firstClassDuration?: Maybe<Scalars['Float']>;
  numHighImpactAttendees?: Maybe<Scalars['Float']>;
  percentUsersAttended?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type ManagerSessionEmailsStddevPopFields = {
  __typename?: 'ManagerSessionEmailsStddevPopFields';
  avgImpactScore?: Maybe<Scalars['Float']>;
  firstClassDuration?: Maybe<Scalars['Float']>;
  numHighImpactAttendees?: Maybe<Scalars['Float']>;
  percentUsersAttended?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type ManagerSessionEmailsStddevSampFields = {
  __typename?: 'ManagerSessionEmailsStddevSampFields';
  avgImpactScore?: Maybe<Scalars['Float']>;
  firstClassDuration?: Maybe<Scalars['Float']>;
  numHighImpactAttendees?: Maybe<Scalars['Float']>;
  percentUsersAttended?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "manager_session_emails" */
export type ManagerSessionEmailsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ManagerSessionEmailsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ManagerSessionEmailsStreamCursorValueInput = {
  attendeesCommaSeparatedList?: InputMaybe<Scalars['String']>;
  avgImpactScore?: InputMaybe<Scalars['numeric']>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  courseName?: InputMaybe<Scalars['String']>;
  coursePlatformUrl?: InputMaybe<Scalars['String']>;
  creditModel?: InputMaybe<Scalars['String']>;
  csmEmail?: InputMaybe<Scalars['String']>;
  csmFullName?: InputMaybe<Scalars['String']>;
  csmSignature?: InputMaybe<Scalars['String']>;
  enrolleeEmails?: InputMaybe<Scalars['String']>;
  enrolleesCommaSeparatedList?: InputMaybe<Scalars['String']>;
  enrolleesForPast?: InputMaybe<Scalars['String']>;
  enrolleesForUpcoming?: InputMaybe<Scalars['String']>;
  firstClassDuration?: InputMaybe<Scalars['numeric']>;
  firstClassStartTime?: InputMaybe<Scalars['timestamptz']>;
  frameworkHighlight?: InputMaybe<Scalars['String']>;
  hasPrivateBor?: InputMaybe<Scalars['Boolean']>;
  hasSentPostSessionEmail?: InputMaybe<Scalars['Boolean']>;
  hasSentPreSessionEmail?: InputMaybe<Scalars['Boolean']>;
  highImpactAttendees?: InputMaybe<Scalars['String']>;
  isAllFeedbackSkippedOrReady?: InputMaybe<Scalars['Boolean']>;
  isStartingSoon?: InputMaybe<Scalars['Boolean']>;
  isWithinLast7Days?: InputMaybe<Scalars['Boolean']>;
  managerCohortId?: InputMaybe<Scalars['String']>;
  managerId?: InputMaybe<Scalars['uuid']>;
  managerPastSessionUrl?: InputMaybe<Scalars['String']>;
  managerUpcomingSessionUrl?: InputMaybe<Scalars['String']>;
  numHighImpactAttendees?: InputMaybe<Scalars['numeric']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  percentUsersAttended?: InputMaybe<Scalars['numeric']>;
  posterFrameUrl?: InputMaybe<Scalars['String']>;
  recommendations?: InputMaybe<Scalars['String']>;
  sentPostSessionEmailAt?: InputMaybe<Scalars['timestamptz']>;
  sentPreSessionEmailAt?: InputMaybe<Scalars['timestamptz']>;
  skills?: InputMaybe<Scalars['String']>;
  startTimeFormatted?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ManagerSessionEmailsSumFields = {
  __typename?: 'ManagerSessionEmailsSumFields';
  avgImpactScore?: Maybe<Scalars['numeric']>;
  firstClassDuration?: Maybe<Scalars['numeric']>;
  numHighImpactAttendees?: Maybe<Scalars['numeric']>;
  percentUsersAttended?: Maybe<Scalars['numeric']>;
};

/** update columns of table "manager_session_emails" */
export enum ManagerSessionEmailsUpdateColumn {
  /** column name */
  attendeesCommaSeparatedList = 'attendeesCommaSeparatedList',
  /** column name */
  avgImpactScore = 'avgImpactScore',
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  courseName = 'courseName',
  /** column name */
  coursePlatformUrl = 'coursePlatformUrl',
  /** column name */
  creditModel = 'creditModel',
  /** column name */
  csmEmail = 'csmEmail',
  /** column name */
  csmFullName = 'csmFullName',
  /** column name */
  csmSignature = 'csmSignature',
  /** column name */
  enrolleeEmails = 'enrolleeEmails',
  /** column name */
  enrolleesCommaSeparatedList = 'enrolleesCommaSeparatedList',
  /** column name */
  enrolleesForPast = 'enrolleesForPast',
  /** column name */
  enrolleesForUpcoming = 'enrolleesForUpcoming',
  /** column name */
  firstClassDuration = 'firstClassDuration',
  /** column name */
  firstClassStartTime = 'firstClassStartTime',
  /** column name */
  frameworkHighlight = 'frameworkHighlight',
  /** column name */
  hasPrivateBor = 'hasPrivateBor',
  /** column name */
  hasSentPostSessionEmail = 'hasSentPostSessionEmail',
  /** column name */
  hasSentPreSessionEmail = 'hasSentPreSessionEmail',
  /** column name */
  highImpactAttendees = 'highImpactAttendees',
  /** column name */
  isAllFeedbackSkippedOrReady = 'isAllFeedbackSkippedOrReady',
  /** column name */
  isStartingSoon = 'isStartingSoon',
  /** column name */
  isWithinLast7Days = 'isWithinLast7Days',
  /** column name */
  managerCohortId = 'managerCohortId',
  /** column name */
  managerId = 'managerId',
  /** column name */
  managerPastSessionUrl = 'managerPastSessionUrl',
  /** column name */
  managerUpcomingSessionUrl = 'managerUpcomingSessionUrl',
  /** column name */
  numHighImpactAttendees = 'numHighImpactAttendees',
  /** column name */
  organizationId = 'organizationId',
  /** column name */
  percentUsersAttended = 'percentUsersAttended',
  /** column name */
  posterFrameUrl = 'posterFrameUrl',
  /** column name */
  recommendations = 'recommendations',
  /** column name */
  sentPostSessionEmailAt = 'sentPostSessionEmailAt',
  /** column name */
  sentPreSessionEmailAt = 'sentPreSessionEmailAt',
  /** column name */
  skills = 'skills',
  /** column name */
  startTimeFormatted = 'startTimeFormatted'
}

export type ManagerSessionEmailsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ManagerSessionEmailsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ManagerSessionEmailsSetInput>;
  /** filter the rows which have to be updated */
  where: ManagerSessionEmailsBoolExp;
};

/** aggregate varPop on columns */
export type ManagerSessionEmailsVarPopFields = {
  __typename?: 'ManagerSessionEmailsVarPopFields';
  avgImpactScore?: Maybe<Scalars['Float']>;
  firstClassDuration?: Maybe<Scalars['Float']>;
  numHighImpactAttendees?: Maybe<Scalars['Float']>;
  percentUsersAttended?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type ManagerSessionEmailsVarSampFields = {
  __typename?: 'ManagerSessionEmailsVarSampFields';
  avgImpactScore?: Maybe<Scalars['Float']>;
  firstClassDuration?: Maybe<Scalars['Float']>;
  numHighImpactAttendees?: Maybe<Scalars['Float']>;
  percentUsersAttended?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ManagerSessionEmailsVarianceFields = {
  __typename?: 'ManagerSessionEmailsVarianceFields';
  avgImpactScore?: Maybe<Scalars['Float']>;
  firstClassDuration?: Maybe<Scalars['Float']>;
  numHighImpactAttendees?: Maybe<Scalars['Float']>;
  percentUsersAttended?: Maybe<Scalars['Float']>;
};

export type MarkRecordingWatchedOutput = {
  __typename?: 'MarkRecordingWatchedOutput';
  done: Scalars['Boolean'];
};

/** columns and relationships of "my_cohort_learning_status" */
export type MyCohortLearningStatus = {
  __typename?: 'MyCohortLearningStatus';
  assessmentPassed?: Maybe<Scalars['Boolean']>;
  certDate?: Maybe<Scalars['timestamptz']>;
  classCount?: Maybe<Scalars['bigint']>;
  cohortCompleted?: Maybe<Scalars['Boolean']>;
  cohortDayOfWeek?: Maybe<Scalars['String']>;
  cohortEnd?: Maybe<Scalars['timestamp']>;
  cohortHasPrework?: Maybe<Scalars['Boolean']>;
  cohortId?: Maybe<Scalars['uuid']>;
  cohortInAppVideo?: Maybe<Scalars['Boolean']>;
  cohortName?: Maybe<Scalars['String']>;
  cohortRequiresPractice?: Maybe<Scalars['Boolean']>;
  cohortSlug?: Maybe<Scalars['String']>;
  cohortStart?: Maybe<Scalars['timestamp']>;
  cohortTimeOfDay?: Maybe<Scalars['timestamp']>;
  cohortType?: Maybe<Scalars['String']>;
  courseBackgroundImageUrl?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['uuid']>;
  courseName?: Maybe<Scalars['String']>;
  courseSlug?: Maybe<Scalars['String']>;
  courseType?: Maybe<Scalars['String']>;
  enrolledAt?: Maybe<Scalars['timestamp']>;
  hasUserCompletedAllClasses?: Maybe<Scalars['Boolean']>;
  hasUserSkippedPractice?: Maybe<Scalars['Boolean']>;
  isFeedbackReady?: Maybe<Scalars['Boolean']>;
  isOnTrack?: Maybe<Scalars['Boolean']>;
  isWaitlisted?: Maybe<Scalars['Boolean']>;
  lastCompletedClassDate?: Maybe<Scalars['timestamp']>;
  nextClassDate?: Maybe<Scalars['timestamp']>;
  numTasksRequired?: Maybe<Scalars['bigint']>;
  progress?: Maybe<Scalars['float8']>;
  schoolName?: Maybe<Scalars['String']>;
  schoolSlug?: Maybe<Scalars['String']>;
  totalAssessments?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "my_cohort_learning_status" */
export type MyCohortLearningStatusAggregate = {
  __typename?: 'MyCohortLearningStatusAggregate';
  aggregate?: Maybe<MyCohortLearningStatusAggregateFields>;
  nodes: Array<MyCohortLearningStatus>;
};

/** aggregate fields of "my_cohort_learning_status" */
export type MyCohortLearningStatusAggregateFields = {
  __typename?: 'MyCohortLearningStatusAggregateFields';
  avg?: Maybe<MyCohortLearningStatusAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<MyCohortLearningStatusMaxFields>;
  min?: Maybe<MyCohortLearningStatusMinFields>;
  stddev?: Maybe<MyCohortLearningStatusStddevFields>;
  stddevPop?: Maybe<MyCohortLearningStatusStddevPopFields>;
  stddevSamp?: Maybe<MyCohortLearningStatusStddevSampFields>;
  sum?: Maybe<MyCohortLearningStatusSumFields>;
  varPop?: Maybe<MyCohortLearningStatusVarPopFields>;
  varSamp?: Maybe<MyCohortLearningStatusVarSampFields>;
  variance?: Maybe<MyCohortLearningStatusVarianceFields>;
};

/** aggregate fields of "my_cohort_learning_status" */
export type MyCohortLearningStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MyCohortLearningStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type MyCohortLearningStatusAvgFields = {
  __typename?: 'MyCohortLearningStatusAvgFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "my_cohort_learning_status". All fields are combined with a logical 'AND'. */
export type MyCohortLearningStatusBoolExp = {
  _and?: InputMaybe<Array<MyCohortLearningStatusBoolExp>>;
  _not?: InputMaybe<MyCohortLearningStatusBoolExp>;
  _or?: InputMaybe<Array<MyCohortLearningStatusBoolExp>>;
  assessmentPassed?: InputMaybe<BooleanComparisonExp>;
  certDate?: InputMaybe<TimestamptzComparisonExp>;
  classCount?: InputMaybe<BigintComparisonExp>;
  cohortCompleted?: InputMaybe<BooleanComparisonExp>;
  cohortDayOfWeek?: InputMaybe<StringComparisonExp>;
  cohortEnd?: InputMaybe<TimestampComparisonExp>;
  cohortHasPrework?: InputMaybe<BooleanComparisonExp>;
  cohortId?: InputMaybe<UuidComparisonExp>;
  cohortInAppVideo?: InputMaybe<BooleanComparisonExp>;
  cohortName?: InputMaybe<StringComparisonExp>;
  cohortRequiresPractice?: InputMaybe<BooleanComparisonExp>;
  cohortSlug?: InputMaybe<StringComparisonExp>;
  cohortStart?: InputMaybe<TimestampComparisonExp>;
  cohortTimeOfDay?: InputMaybe<TimestampComparisonExp>;
  cohortType?: InputMaybe<StringComparisonExp>;
  courseBackgroundImageUrl?: InputMaybe<StringComparisonExp>;
  courseId?: InputMaybe<UuidComparisonExp>;
  courseName?: InputMaybe<StringComparisonExp>;
  courseSlug?: InputMaybe<StringComparisonExp>;
  courseType?: InputMaybe<StringComparisonExp>;
  enrolledAt?: InputMaybe<TimestampComparisonExp>;
  hasUserCompletedAllClasses?: InputMaybe<BooleanComparisonExp>;
  hasUserSkippedPractice?: InputMaybe<BooleanComparisonExp>;
  isFeedbackReady?: InputMaybe<BooleanComparisonExp>;
  isOnTrack?: InputMaybe<BooleanComparisonExp>;
  isWaitlisted?: InputMaybe<BooleanComparisonExp>;
  lastCompletedClassDate?: InputMaybe<TimestampComparisonExp>;
  nextClassDate?: InputMaybe<TimestampComparisonExp>;
  numTasksRequired?: InputMaybe<BigintComparisonExp>;
  progress?: InputMaybe<Float8ComparisonExp>;
  schoolName?: InputMaybe<StringComparisonExp>;
  schoolSlug?: InputMaybe<StringComparisonExp>;
  totalAssessments?: InputMaybe<IntComparisonExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** aggregate max on columns */
export type MyCohortLearningStatusMaxFields = {
  __typename?: 'MyCohortLearningStatusMaxFields';
  certDate?: Maybe<Scalars['timestamptz']>;
  classCount?: Maybe<Scalars['bigint']>;
  cohortDayOfWeek?: Maybe<Scalars['String']>;
  cohortEnd?: Maybe<Scalars['timestamp']>;
  cohortId?: Maybe<Scalars['uuid']>;
  cohortName?: Maybe<Scalars['String']>;
  cohortSlug?: Maybe<Scalars['String']>;
  cohortStart?: Maybe<Scalars['timestamp']>;
  cohortTimeOfDay?: Maybe<Scalars['timestamp']>;
  cohortType?: Maybe<Scalars['String']>;
  courseBackgroundImageUrl?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['uuid']>;
  courseName?: Maybe<Scalars['String']>;
  courseSlug?: Maybe<Scalars['String']>;
  courseType?: Maybe<Scalars['String']>;
  enrolledAt?: Maybe<Scalars['timestamp']>;
  lastCompletedClassDate?: Maybe<Scalars['timestamp']>;
  nextClassDate?: Maybe<Scalars['timestamp']>;
  numTasksRequired?: Maybe<Scalars['bigint']>;
  progress?: Maybe<Scalars['float8']>;
  schoolName?: Maybe<Scalars['String']>;
  schoolSlug?: Maybe<Scalars['String']>;
  totalAssessments?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type MyCohortLearningStatusMinFields = {
  __typename?: 'MyCohortLearningStatusMinFields';
  certDate?: Maybe<Scalars['timestamptz']>;
  classCount?: Maybe<Scalars['bigint']>;
  cohortDayOfWeek?: Maybe<Scalars['String']>;
  cohortEnd?: Maybe<Scalars['timestamp']>;
  cohortId?: Maybe<Scalars['uuid']>;
  cohortName?: Maybe<Scalars['String']>;
  cohortSlug?: Maybe<Scalars['String']>;
  cohortStart?: Maybe<Scalars['timestamp']>;
  cohortTimeOfDay?: Maybe<Scalars['timestamp']>;
  cohortType?: Maybe<Scalars['String']>;
  courseBackgroundImageUrl?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['uuid']>;
  courseName?: Maybe<Scalars['String']>;
  courseSlug?: Maybe<Scalars['String']>;
  courseType?: Maybe<Scalars['String']>;
  enrolledAt?: Maybe<Scalars['timestamp']>;
  lastCompletedClassDate?: Maybe<Scalars['timestamp']>;
  nextClassDate?: Maybe<Scalars['timestamp']>;
  numTasksRequired?: Maybe<Scalars['bigint']>;
  progress?: Maybe<Scalars['float8']>;
  schoolName?: Maybe<Scalars['String']>;
  schoolSlug?: Maybe<Scalars['String']>;
  totalAssessments?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "my_cohort_learning_status". */
export type MyCohortLearningStatusOrderBy = {
  assessmentPassed?: InputMaybe<OrderBy>;
  certDate?: InputMaybe<OrderBy>;
  classCount?: InputMaybe<OrderBy>;
  cohortCompleted?: InputMaybe<OrderBy>;
  cohortDayOfWeek?: InputMaybe<OrderBy>;
  cohortEnd?: InputMaybe<OrderBy>;
  cohortHasPrework?: InputMaybe<OrderBy>;
  cohortId?: InputMaybe<OrderBy>;
  cohortInAppVideo?: InputMaybe<OrderBy>;
  cohortName?: InputMaybe<OrderBy>;
  cohortRequiresPractice?: InputMaybe<OrderBy>;
  cohortSlug?: InputMaybe<OrderBy>;
  cohortStart?: InputMaybe<OrderBy>;
  cohortTimeOfDay?: InputMaybe<OrderBy>;
  cohortType?: InputMaybe<OrderBy>;
  courseBackgroundImageUrl?: InputMaybe<OrderBy>;
  courseId?: InputMaybe<OrderBy>;
  courseName?: InputMaybe<OrderBy>;
  courseSlug?: InputMaybe<OrderBy>;
  courseType?: InputMaybe<OrderBy>;
  enrolledAt?: InputMaybe<OrderBy>;
  hasUserCompletedAllClasses?: InputMaybe<OrderBy>;
  hasUserSkippedPractice?: InputMaybe<OrderBy>;
  isFeedbackReady?: InputMaybe<OrderBy>;
  isOnTrack?: InputMaybe<OrderBy>;
  isWaitlisted?: InputMaybe<OrderBy>;
  lastCompletedClassDate?: InputMaybe<OrderBy>;
  nextClassDate?: InputMaybe<OrderBy>;
  numTasksRequired?: InputMaybe<OrderBy>;
  progress?: InputMaybe<OrderBy>;
  schoolName?: InputMaybe<OrderBy>;
  schoolSlug?: InputMaybe<OrderBy>;
  totalAssessments?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** select columns of table "my_cohort_learning_status" */
export enum MyCohortLearningStatusSelectColumn {
  /** column name */
  assessmentPassed = 'assessmentPassed',
  /** column name */
  certDate = 'certDate',
  /** column name */
  classCount = 'classCount',
  /** column name */
  cohortCompleted = 'cohortCompleted',
  /** column name */
  cohortDayOfWeek = 'cohortDayOfWeek',
  /** column name */
  cohortEnd = 'cohortEnd',
  /** column name */
  cohortHasPrework = 'cohortHasPrework',
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  cohortInAppVideo = 'cohortInAppVideo',
  /** column name */
  cohortName = 'cohortName',
  /** column name */
  cohortRequiresPractice = 'cohortRequiresPractice',
  /** column name */
  cohortSlug = 'cohortSlug',
  /** column name */
  cohortStart = 'cohortStart',
  /** column name */
  cohortTimeOfDay = 'cohortTimeOfDay',
  /** column name */
  cohortType = 'cohortType',
  /** column name */
  courseBackgroundImageUrl = 'courseBackgroundImageUrl',
  /** column name */
  courseId = 'courseId',
  /** column name */
  courseName = 'courseName',
  /** column name */
  courseSlug = 'courseSlug',
  /** column name */
  courseType = 'courseType',
  /** column name */
  enrolledAt = 'enrolledAt',
  /** column name */
  hasUserCompletedAllClasses = 'hasUserCompletedAllClasses',
  /** column name */
  hasUserSkippedPractice = 'hasUserSkippedPractice',
  /** column name */
  isFeedbackReady = 'isFeedbackReady',
  /** column name */
  isOnTrack = 'isOnTrack',
  /** column name */
  isWaitlisted = 'isWaitlisted',
  /** column name */
  lastCompletedClassDate = 'lastCompletedClassDate',
  /** column name */
  nextClassDate = 'nextClassDate',
  /** column name */
  numTasksRequired = 'numTasksRequired',
  /** column name */
  progress = 'progress',
  /** column name */
  schoolName = 'schoolName',
  /** column name */
  schoolSlug = 'schoolSlug',
  /** column name */
  totalAssessments = 'totalAssessments',
  /** column name */
  userId = 'userId'
}

/** aggregate stddev on columns */
export type MyCohortLearningStatusStddevFields = {
  __typename?: 'MyCohortLearningStatusStddevFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type MyCohortLearningStatusStddevPopFields = {
  __typename?: 'MyCohortLearningStatusStddevPopFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type MyCohortLearningStatusStddevSampFields = {
  __typename?: 'MyCohortLearningStatusStddevSampFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "my_cohort_learning_status" */
export type MyCohortLearningStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MyCohortLearningStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MyCohortLearningStatusStreamCursorValueInput = {
  assessmentPassed?: InputMaybe<Scalars['Boolean']>;
  certDate?: InputMaybe<Scalars['timestamptz']>;
  classCount?: InputMaybe<Scalars['bigint']>;
  cohortCompleted?: InputMaybe<Scalars['Boolean']>;
  cohortDayOfWeek?: InputMaybe<Scalars['String']>;
  cohortEnd?: InputMaybe<Scalars['timestamp']>;
  cohortHasPrework?: InputMaybe<Scalars['Boolean']>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  cohortInAppVideo?: InputMaybe<Scalars['Boolean']>;
  cohortName?: InputMaybe<Scalars['String']>;
  cohortRequiresPractice?: InputMaybe<Scalars['Boolean']>;
  cohortSlug?: InputMaybe<Scalars['String']>;
  cohortStart?: InputMaybe<Scalars['timestamp']>;
  cohortTimeOfDay?: InputMaybe<Scalars['timestamp']>;
  cohortType?: InputMaybe<Scalars['String']>;
  courseBackgroundImageUrl?: InputMaybe<Scalars['String']>;
  courseId?: InputMaybe<Scalars['uuid']>;
  courseName?: InputMaybe<Scalars['String']>;
  courseSlug?: InputMaybe<Scalars['String']>;
  courseType?: InputMaybe<Scalars['String']>;
  enrolledAt?: InputMaybe<Scalars['timestamp']>;
  hasUserCompletedAllClasses?: InputMaybe<Scalars['Boolean']>;
  hasUserSkippedPractice?: InputMaybe<Scalars['Boolean']>;
  isFeedbackReady?: InputMaybe<Scalars['Boolean']>;
  isOnTrack?: InputMaybe<Scalars['Boolean']>;
  isWaitlisted?: InputMaybe<Scalars['Boolean']>;
  lastCompletedClassDate?: InputMaybe<Scalars['timestamp']>;
  nextClassDate?: InputMaybe<Scalars['timestamp']>;
  numTasksRequired?: InputMaybe<Scalars['bigint']>;
  progress?: InputMaybe<Scalars['float8']>;
  schoolName?: InputMaybe<Scalars['String']>;
  schoolSlug?: InputMaybe<Scalars['String']>;
  totalAssessments?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type MyCohortLearningStatusSumFields = {
  __typename?: 'MyCohortLearningStatusSumFields';
  classCount?: Maybe<Scalars['bigint']>;
  numTasksRequired?: Maybe<Scalars['bigint']>;
  progress?: Maybe<Scalars['float8']>;
  totalAssessments?: Maybe<Scalars['Int']>;
};

/** aggregate varPop on columns */
export type MyCohortLearningStatusVarPopFields = {
  __typename?: 'MyCohortLearningStatusVarPopFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type MyCohortLearningStatusVarSampFields = {
  __typename?: 'MyCohortLearningStatusVarSampFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type MyCohortLearningStatusVarianceFields = {
  __typename?: 'MyCohortLearningStatusVarianceFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "my_learner_home_courses" */
export type MyLearnerHomeCourses = {
  __typename?: 'MyLearnerHomeCourses';
  assessmentPassed?: Maybe<Scalars['Boolean']>;
  certDate?: Maybe<Scalars['timestamp']>;
  classCount?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  cohort?: Maybe<Cohorts>;
  cohortCompleted?: Maybe<Scalars['Boolean']>;
  cohortDayOfWeek?: Maybe<Scalars['String']>;
  cohortEnd?: Maybe<Scalars['timestamp']>;
  cohortHasPrework?: Maybe<Scalars['Boolean']>;
  cohortId?: Maybe<Scalars['uuid']>;
  cohortInAppVideo?: Maybe<Scalars['Boolean']>;
  cohortName?: Maybe<Scalars['String']>;
  cohortRequiresPractice?: Maybe<Scalars['Boolean']>;
  cohortSlug?: Maybe<Scalars['String']>;
  cohortStart?: Maybe<Scalars['timestamp']>;
  cohortTimeOfDay?: Maybe<Scalars['timestamp']>;
  cohortType?: Maybe<Scalars['String']>;
  courseBackgroundImageUrl?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['uuid']>;
  courseName?: Maybe<Scalars['String']>;
  courseSlug?: Maybe<Scalars['String']>;
  courseType?: Maybe<Scalars['String']>;
  enrolledAt?: Maybe<Scalars['timestamp']>;
  hasUserCompletedAllClasses?: Maybe<Scalars['Boolean']>;
  hasUserSkippedPractice?: Maybe<Scalars['Boolean']>;
  isFeedbackReady?: Maybe<Scalars['Boolean']>;
  isOnTrack?: Maybe<Scalars['Boolean']>;
  lastCompletedClassDate?: Maybe<Scalars['timestamp']>;
  nextClassDate?: Maybe<Scalars['timestamp']>;
  numTasksRequired?: Maybe<Scalars['bigint']>;
  progress?: Maybe<Scalars['float8']>;
  schoolName?: Maybe<Scalars['String']>;
  schoolSlug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  /** An array relationship */
  tags: Array<CoursesTagsRel>;
  /** An aggregate relationship */
  tagsAggregate: CoursesTagsRelAggregate;
  totalAssessments?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "my_learner_home_courses" */
export type MyLearnerHomeCoursesTagsArgs = {
  distinctOn?: InputMaybe<Array<CoursesTagsRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesTagsRelOrderBy>>;
  where?: InputMaybe<CoursesTagsRelBoolExp>;
};

/** columns and relationships of "my_learner_home_courses" */
export type MyLearnerHomeCoursesTagsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CoursesTagsRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesTagsRelOrderBy>>;
  where?: InputMaybe<CoursesTagsRelBoolExp>;
};

/** aggregated selection of "my_learner_home_courses" */
export type MyLearnerHomeCoursesAggregate = {
  __typename?: 'MyLearnerHomeCoursesAggregate';
  aggregate?: Maybe<MyLearnerHomeCoursesAggregateFields>;
  nodes: Array<MyLearnerHomeCourses>;
};

/** aggregate fields of "my_learner_home_courses" */
export type MyLearnerHomeCoursesAggregateFields = {
  __typename?: 'MyLearnerHomeCoursesAggregateFields';
  avg?: Maybe<MyLearnerHomeCoursesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<MyLearnerHomeCoursesMaxFields>;
  min?: Maybe<MyLearnerHomeCoursesMinFields>;
  stddev?: Maybe<MyLearnerHomeCoursesStddevFields>;
  stddevPop?: Maybe<MyLearnerHomeCoursesStddevPopFields>;
  stddevSamp?: Maybe<MyLearnerHomeCoursesStddevSampFields>;
  sum?: Maybe<MyLearnerHomeCoursesSumFields>;
  varPop?: Maybe<MyLearnerHomeCoursesVarPopFields>;
  varSamp?: Maybe<MyLearnerHomeCoursesVarSampFields>;
  variance?: Maybe<MyLearnerHomeCoursesVarianceFields>;
};

/** aggregate fields of "my_learner_home_courses" */
export type MyLearnerHomeCoursesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MyLearnerHomeCoursesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type MyLearnerHomeCoursesAvgFields = {
  __typename?: 'MyLearnerHomeCoursesAvgFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "my_learner_home_courses". All fields are combined with a logical 'AND'. */
export type MyLearnerHomeCoursesBoolExp = {
  _and?: InputMaybe<Array<MyLearnerHomeCoursesBoolExp>>;
  _not?: InputMaybe<MyLearnerHomeCoursesBoolExp>;
  _or?: InputMaybe<Array<MyLearnerHomeCoursesBoolExp>>;
  assessmentPassed?: InputMaybe<BooleanComparisonExp>;
  certDate?: InputMaybe<TimestampComparisonExp>;
  classCount?: InputMaybe<BigintComparisonExp>;
  cohort?: InputMaybe<CohortsBoolExp>;
  cohortCompleted?: InputMaybe<BooleanComparisonExp>;
  cohortDayOfWeek?: InputMaybe<StringComparisonExp>;
  cohortEnd?: InputMaybe<TimestampComparisonExp>;
  cohortHasPrework?: InputMaybe<BooleanComparisonExp>;
  cohortId?: InputMaybe<UuidComparisonExp>;
  cohortInAppVideo?: InputMaybe<BooleanComparisonExp>;
  cohortName?: InputMaybe<StringComparisonExp>;
  cohortRequiresPractice?: InputMaybe<BooleanComparisonExp>;
  cohortSlug?: InputMaybe<StringComparisonExp>;
  cohortStart?: InputMaybe<TimestampComparisonExp>;
  cohortTimeOfDay?: InputMaybe<TimestampComparisonExp>;
  cohortType?: InputMaybe<StringComparisonExp>;
  courseBackgroundImageUrl?: InputMaybe<StringComparisonExp>;
  courseId?: InputMaybe<UuidComparisonExp>;
  courseName?: InputMaybe<StringComparisonExp>;
  courseSlug?: InputMaybe<StringComparisonExp>;
  courseType?: InputMaybe<StringComparisonExp>;
  enrolledAt?: InputMaybe<TimestampComparisonExp>;
  hasUserCompletedAllClasses?: InputMaybe<BooleanComparisonExp>;
  hasUserSkippedPractice?: InputMaybe<BooleanComparisonExp>;
  isFeedbackReady?: InputMaybe<BooleanComparisonExp>;
  isOnTrack?: InputMaybe<BooleanComparisonExp>;
  lastCompletedClassDate?: InputMaybe<TimestampComparisonExp>;
  nextClassDate?: InputMaybe<TimestampComparisonExp>;
  numTasksRequired?: InputMaybe<BigintComparisonExp>;
  progress?: InputMaybe<Float8ComparisonExp>;
  schoolName?: InputMaybe<StringComparisonExp>;
  schoolSlug?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  tags?: InputMaybe<CoursesTagsRelBoolExp>;
  tagsAggregate?: InputMaybe<CoursesTagsRelAggregateBoolExp>;
  totalAssessments?: InputMaybe<IntComparisonExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** aggregate max on columns */
export type MyLearnerHomeCoursesMaxFields = {
  __typename?: 'MyLearnerHomeCoursesMaxFields';
  certDate?: Maybe<Scalars['timestamp']>;
  classCount?: Maybe<Scalars['bigint']>;
  cohortDayOfWeek?: Maybe<Scalars['String']>;
  cohortEnd?: Maybe<Scalars['timestamp']>;
  cohortId?: Maybe<Scalars['uuid']>;
  cohortName?: Maybe<Scalars['String']>;
  cohortSlug?: Maybe<Scalars['String']>;
  cohortStart?: Maybe<Scalars['timestamp']>;
  cohortTimeOfDay?: Maybe<Scalars['timestamp']>;
  cohortType?: Maybe<Scalars['String']>;
  courseBackgroundImageUrl?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['uuid']>;
  courseName?: Maybe<Scalars['String']>;
  courseSlug?: Maybe<Scalars['String']>;
  courseType?: Maybe<Scalars['String']>;
  enrolledAt?: Maybe<Scalars['timestamp']>;
  lastCompletedClassDate?: Maybe<Scalars['timestamp']>;
  nextClassDate?: Maybe<Scalars['timestamp']>;
  numTasksRequired?: Maybe<Scalars['bigint']>;
  progress?: Maybe<Scalars['float8']>;
  schoolName?: Maybe<Scalars['String']>;
  schoolSlug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  totalAssessments?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type MyLearnerHomeCoursesMinFields = {
  __typename?: 'MyLearnerHomeCoursesMinFields';
  certDate?: Maybe<Scalars['timestamp']>;
  classCount?: Maybe<Scalars['bigint']>;
  cohortDayOfWeek?: Maybe<Scalars['String']>;
  cohortEnd?: Maybe<Scalars['timestamp']>;
  cohortId?: Maybe<Scalars['uuid']>;
  cohortName?: Maybe<Scalars['String']>;
  cohortSlug?: Maybe<Scalars['String']>;
  cohortStart?: Maybe<Scalars['timestamp']>;
  cohortTimeOfDay?: Maybe<Scalars['timestamp']>;
  cohortType?: Maybe<Scalars['String']>;
  courseBackgroundImageUrl?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['uuid']>;
  courseName?: Maybe<Scalars['String']>;
  courseSlug?: Maybe<Scalars['String']>;
  courseType?: Maybe<Scalars['String']>;
  enrolledAt?: Maybe<Scalars['timestamp']>;
  lastCompletedClassDate?: Maybe<Scalars['timestamp']>;
  nextClassDate?: Maybe<Scalars['timestamp']>;
  numTasksRequired?: Maybe<Scalars['bigint']>;
  progress?: Maybe<Scalars['float8']>;
  schoolName?: Maybe<Scalars['String']>;
  schoolSlug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  totalAssessments?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "my_learner_home_courses". */
export type MyLearnerHomeCoursesOrderBy = {
  assessmentPassed?: InputMaybe<OrderBy>;
  certDate?: InputMaybe<OrderBy>;
  classCount?: InputMaybe<OrderBy>;
  cohort?: InputMaybe<CohortsOrderBy>;
  cohortCompleted?: InputMaybe<OrderBy>;
  cohortDayOfWeek?: InputMaybe<OrderBy>;
  cohortEnd?: InputMaybe<OrderBy>;
  cohortHasPrework?: InputMaybe<OrderBy>;
  cohortId?: InputMaybe<OrderBy>;
  cohortInAppVideo?: InputMaybe<OrderBy>;
  cohortName?: InputMaybe<OrderBy>;
  cohortRequiresPractice?: InputMaybe<OrderBy>;
  cohortSlug?: InputMaybe<OrderBy>;
  cohortStart?: InputMaybe<OrderBy>;
  cohortTimeOfDay?: InputMaybe<OrderBy>;
  cohortType?: InputMaybe<OrderBy>;
  courseBackgroundImageUrl?: InputMaybe<OrderBy>;
  courseId?: InputMaybe<OrderBy>;
  courseName?: InputMaybe<OrderBy>;
  courseSlug?: InputMaybe<OrderBy>;
  courseType?: InputMaybe<OrderBy>;
  enrolledAt?: InputMaybe<OrderBy>;
  hasUserCompletedAllClasses?: InputMaybe<OrderBy>;
  hasUserSkippedPractice?: InputMaybe<OrderBy>;
  isFeedbackReady?: InputMaybe<OrderBy>;
  isOnTrack?: InputMaybe<OrderBy>;
  lastCompletedClassDate?: InputMaybe<OrderBy>;
  nextClassDate?: InputMaybe<OrderBy>;
  numTasksRequired?: InputMaybe<OrderBy>;
  progress?: InputMaybe<OrderBy>;
  schoolName?: InputMaybe<OrderBy>;
  schoolSlug?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  tagsAggregate?: InputMaybe<CoursesTagsRelAggregateOrderBy>;
  totalAssessments?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** select columns of table "my_learner_home_courses" */
export enum MyLearnerHomeCoursesSelectColumn {
  /** column name */
  assessmentPassed = 'assessmentPassed',
  /** column name */
  certDate = 'certDate',
  /** column name */
  classCount = 'classCount',
  /** column name */
  cohortCompleted = 'cohortCompleted',
  /** column name */
  cohortDayOfWeek = 'cohortDayOfWeek',
  /** column name */
  cohortEnd = 'cohortEnd',
  /** column name */
  cohortHasPrework = 'cohortHasPrework',
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  cohortInAppVideo = 'cohortInAppVideo',
  /** column name */
  cohortName = 'cohortName',
  /** column name */
  cohortRequiresPractice = 'cohortRequiresPractice',
  /** column name */
  cohortSlug = 'cohortSlug',
  /** column name */
  cohortStart = 'cohortStart',
  /** column name */
  cohortTimeOfDay = 'cohortTimeOfDay',
  /** column name */
  cohortType = 'cohortType',
  /** column name */
  courseBackgroundImageUrl = 'courseBackgroundImageUrl',
  /** column name */
  courseId = 'courseId',
  /** column name */
  courseName = 'courseName',
  /** column name */
  courseSlug = 'courseSlug',
  /** column name */
  courseType = 'courseType',
  /** column name */
  enrolledAt = 'enrolledAt',
  /** column name */
  hasUserCompletedAllClasses = 'hasUserCompletedAllClasses',
  /** column name */
  hasUserSkippedPractice = 'hasUserSkippedPractice',
  /** column name */
  isFeedbackReady = 'isFeedbackReady',
  /** column name */
  isOnTrack = 'isOnTrack',
  /** column name */
  lastCompletedClassDate = 'lastCompletedClassDate',
  /** column name */
  nextClassDate = 'nextClassDate',
  /** column name */
  numTasksRequired = 'numTasksRequired',
  /** column name */
  progress = 'progress',
  /** column name */
  schoolName = 'schoolName',
  /** column name */
  schoolSlug = 'schoolSlug',
  /** column name */
  status = 'status',
  /** column name */
  totalAssessments = 'totalAssessments',
  /** column name */
  userId = 'userId'
}

/** aggregate stddev on columns */
export type MyLearnerHomeCoursesStddevFields = {
  __typename?: 'MyLearnerHomeCoursesStddevFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type MyLearnerHomeCoursesStddevPopFields = {
  __typename?: 'MyLearnerHomeCoursesStddevPopFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type MyLearnerHomeCoursesStddevSampFields = {
  __typename?: 'MyLearnerHomeCoursesStddevSampFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "my_learner_home_courses" */
export type MyLearnerHomeCoursesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MyLearnerHomeCoursesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MyLearnerHomeCoursesStreamCursorValueInput = {
  assessmentPassed?: InputMaybe<Scalars['Boolean']>;
  certDate?: InputMaybe<Scalars['timestamp']>;
  classCount?: InputMaybe<Scalars['bigint']>;
  cohortCompleted?: InputMaybe<Scalars['Boolean']>;
  cohortDayOfWeek?: InputMaybe<Scalars['String']>;
  cohortEnd?: InputMaybe<Scalars['timestamp']>;
  cohortHasPrework?: InputMaybe<Scalars['Boolean']>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  cohortInAppVideo?: InputMaybe<Scalars['Boolean']>;
  cohortName?: InputMaybe<Scalars['String']>;
  cohortRequiresPractice?: InputMaybe<Scalars['Boolean']>;
  cohortSlug?: InputMaybe<Scalars['String']>;
  cohortStart?: InputMaybe<Scalars['timestamp']>;
  cohortTimeOfDay?: InputMaybe<Scalars['timestamp']>;
  cohortType?: InputMaybe<Scalars['String']>;
  courseBackgroundImageUrl?: InputMaybe<Scalars['String']>;
  courseId?: InputMaybe<Scalars['uuid']>;
  courseName?: InputMaybe<Scalars['String']>;
  courseSlug?: InputMaybe<Scalars['String']>;
  courseType?: InputMaybe<Scalars['String']>;
  enrolledAt?: InputMaybe<Scalars['timestamp']>;
  hasUserCompletedAllClasses?: InputMaybe<Scalars['Boolean']>;
  hasUserSkippedPractice?: InputMaybe<Scalars['Boolean']>;
  isFeedbackReady?: InputMaybe<Scalars['Boolean']>;
  isOnTrack?: InputMaybe<Scalars['Boolean']>;
  lastCompletedClassDate?: InputMaybe<Scalars['timestamp']>;
  nextClassDate?: InputMaybe<Scalars['timestamp']>;
  numTasksRequired?: InputMaybe<Scalars['bigint']>;
  progress?: InputMaybe<Scalars['float8']>;
  schoolName?: InputMaybe<Scalars['String']>;
  schoolSlug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  totalAssessments?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type MyLearnerHomeCoursesSumFields = {
  __typename?: 'MyLearnerHomeCoursesSumFields';
  classCount?: Maybe<Scalars['bigint']>;
  numTasksRequired?: Maybe<Scalars['bigint']>;
  progress?: Maybe<Scalars['float8']>;
  totalAssessments?: Maybe<Scalars['Int']>;
};

/** aggregate varPop on columns */
export type MyLearnerHomeCoursesVarPopFields = {
  __typename?: 'MyLearnerHomeCoursesVarPopFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type MyLearnerHomeCoursesVarSampFields = {
  __typename?: 'MyLearnerHomeCoursesVarSampFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type MyLearnerHomeCoursesVarianceFields = {
  __typename?: 'MyLearnerHomeCoursesVarianceFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "my_learner_home_live_coaching" */
export type MyLearnerHomeLiveCoaching = {
  __typename?: 'MyLearnerHomeLiveCoaching';
  assessmentPassed?: Maybe<Scalars['Boolean']>;
  certDate?: Maybe<Scalars['timestamptz']>;
  classCount?: Maybe<Scalars['bigint']>;
  cohortCompleted?: Maybe<Scalars['Boolean']>;
  cohortDayOfWeek?: Maybe<Scalars['String']>;
  cohortEnd?: Maybe<Scalars['timestamp']>;
  cohortHasPrework?: Maybe<Scalars['Boolean']>;
  cohortId?: Maybe<Scalars['uuid']>;
  cohortInAppVideo?: Maybe<Scalars['Boolean']>;
  cohortName?: Maybe<Scalars['String']>;
  cohortRequiresPractice?: Maybe<Scalars['Boolean']>;
  cohortSlug?: Maybe<Scalars['String']>;
  cohortStart?: Maybe<Scalars['timestamp']>;
  cohortTimeOfDay?: Maybe<Scalars['timestamp']>;
  cohortType?: Maybe<Scalars['String']>;
  courseBackgroundImageUrl?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['uuid']>;
  courseName?: Maybe<Scalars['String']>;
  courseSlug?: Maybe<Scalars['String']>;
  courseType?: Maybe<Scalars['String']>;
  enrolledAt?: Maybe<Scalars['timestamp']>;
  hasUserCompletedAllClasses?: Maybe<Scalars['Boolean']>;
  hasUserSkippedPractice?: Maybe<Scalars['Boolean']>;
  isFeedbackReady?: Maybe<Scalars['Boolean']>;
  isOnTrack?: Maybe<Scalars['Boolean']>;
  isWaitlisted?: Maybe<Scalars['Boolean']>;
  lastCompletedClassDate?: Maybe<Scalars['timestamp']>;
  nextClassDate?: Maybe<Scalars['timestamp']>;
  numTasksRequired?: Maybe<Scalars['bigint']>;
  progress?: Maybe<Scalars['float8']>;
  schoolName?: Maybe<Scalars['String']>;
  schoolSlug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  /** An array relationship */
  tags: Array<CoursesTagsRel>;
  /** An aggregate relationship */
  tagsAggregate: CoursesTagsRelAggregate;
  totalAssessments?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "my_learner_home_live_coaching" */
export type MyLearnerHomeLiveCoachingTagsArgs = {
  distinctOn?: InputMaybe<Array<CoursesTagsRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesTagsRelOrderBy>>;
  where?: InputMaybe<CoursesTagsRelBoolExp>;
};

/** columns and relationships of "my_learner_home_live_coaching" */
export type MyLearnerHomeLiveCoachingTagsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CoursesTagsRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesTagsRelOrderBy>>;
  where?: InputMaybe<CoursesTagsRelBoolExp>;
};

/** aggregated selection of "my_learner_home_live_coaching" */
export type MyLearnerHomeLiveCoachingAggregate = {
  __typename?: 'MyLearnerHomeLiveCoachingAggregate';
  aggregate?: Maybe<MyLearnerHomeLiveCoachingAggregateFields>;
  nodes: Array<MyLearnerHomeLiveCoaching>;
};

/** aggregate fields of "my_learner_home_live_coaching" */
export type MyLearnerHomeLiveCoachingAggregateFields = {
  __typename?: 'MyLearnerHomeLiveCoachingAggregateFields';
  avg?: Maybe<MyLearnerHomeLiveCoachingAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<MyLearnerHomeLiveCoachingMaxFields>;
  min?: Maybe<MyLearnerHomeLiveCoachingMinFields>;
  stddev?: Maybe<MyLearnerHomeLiveCoachingStddevFields>;
  stddevPop?: Maybe<MyLearnerHomeLiveCoachingStddevPopFields>;
  stddevSamp?: Maybe<MyLearnerHomeLiveCoachingStddevSampFields>;
  sum?: Maybe<MyLearnerHomeLiveCoachingSumFields>;
  varPop?: Maybe<MyLearnerHomeLiveCoachingVarPopFields>;
  varSamp?: Maybe<MyLearnerHomeLiveCoachingVarSampFields>;
  variance?: Maybe<MyLearnerHomeLiveCoachingVarianceFields>;
};

/** aggregate fields of "my_learner_home_live_coaching" */
export type MyLearnerHomeLiveCoachingAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MyLearnerHomeLiveCoachingSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type MyLearnerHomeLiveCoachingAvgFields = {
  __typename?: 'MyLearnerHomeLiveCoachingAvgFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "my_learner_home_live_coaching". All fields are combined with a logical 'AND'. */
export type MyLearnerHomeLiveCoachingBoolExp = {
  _and?: InputMaybe<Array<MyLearnerHomeLiveCoachingBoolExp>>;
  _not?: InputMaybe<MyLearnerHomeLiveCoachingBoolExp>;
  _or?: InputMaybe<Array<MyLearnerHomeLiveCoachingBoolExp>>;
  assessmentPassed?: InputMaybe<BooleanComparisonExp>;
  certDate?: InputMaybe<TimestamptzComparisonExp>;
  classCount?: InputMaybe<BigintComparisonExp>;
  cohortCompleted?: InputMaybe<BooleanComparisonExp>;
  cohortDayOfWeek?: InputMaybe<StringComparisonExp>;
  cohortEnd?: InputMaybe<TimestampComparisonExp>;
  cohortHasPrework?: InputMaybe<BooleanComparisonExp>;
  cohortId?: InputMaybe<UuidComparisonExp>;
  cohortInAppVideo?: InputMaybe<BooleanComparisonExp>;
  cohortName?: InputMaybe<StringComparisonExp>;
  cohortRequiresPractice?: InputMaybe<BooleanComparisonExp>;
  cohortSlug?: InputMaybe<StringComparisonExp>;
  cohortStart?: InputMaybe<TimestampComparisonExp>;
  cohortTimeOfDay?: InputMaybe<TimestampComparisonExp>;
  cohortType?: InputMaybe<StringComparisonExp>;
  courseBackgroundImageUrl?: InputMaybe<StringComparisonExp>;
  courseId?: InputMaybe<UuidComparisonExp>;
  courseName?: InputMaybe<StringComparisonExp>;
  courseSlug?: InputMaybe<StringComparisonExp>;
  courseType?: InputMaybe<StringComparisonExp>;
  enrolledAt?: InputMaybe<TimestampComparisonExp>;
  hasUserCompletedAllClasses?: InputMaybe<BooleanComparisonExp>;
  hasUserSkippedPractice?: InputMaybe<BooleanComparisonExp>;
  isFeedbackReady?: InputMaybe<BooleanComparisonExp>;
  isOnTrack?: InputMaybe<BooleanComparisonExp>;
  isWaitlisted?: InputMaybe<BooleanComparisonExp>;
  lastCompletedClassDate?: InputMaybe<TimestampComparisonExp>;
  nextClassDate?: InputMaybe<TimestampComparisonExp>;
  numTasksRequired?: InputMaybe<BigintComparisonExp>;
  progress?: InputMaybe<Float8ComparisonExp>;
  schoolName?: InputMaybe<StringComparisonExp>;
  schoolSlug?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  tags?: InputMaybe<CoursesTagsRelBoolExp>;
  tagsAggregate?: InputMaybe<CoursesTagsRelAggregateBoolExp>;
  totalAssessments?: InputMaybe<IntComparisonExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** aggregate max on columns */
export type MyLearnerHomeLiveCoachingMaxFields = {
  __typename?: 'MyLearnerHomeLiveCoachingMaxFields';
  certDate?: Maybe<Scalars['timestamptz']>;
  classCount?: Maybe<Scalars['bigint']>;
  cohortDayOfWeek?: Maybe<Scalars['String']>;
  cohortEnd?: Maybe<Scalars['timestamp']>;
  cohortId?: Maybe<Scalars['uuid']>;
  cohortName?: Maybe<Scalars['String']>;
  cohortSlug?: Maybe<Scalars['String']>;
  cohortStart?: Maybe<Scalars['timestamp']>;
  cohortTimeOfDay?: Maybe<Scalars['timestamp']>;
  cohortType?: Maybe<Scalars['String']>;
  courseBackgroundImageUrl?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['uuid']>;
  courseName?: Maybe<Scalars['String']>;
  courseSlug?: Maybe<Scalars['String']>;
  courseType?: Maybe<Scalars['String']>;
  enrolledAt?: Maybe<Scalars['timestamp']>;
  lastCompletedClassDate?: Maybe<Scalars['timestamp']>;
  nextClassDate?: Maybe<Scalars['timestamp']>;
  numTasksRequired?: Maybe<Scalars['bigint']>;
  progress?: Maybe<Scalars['float8']>;
  schoolName?: Maybe<Scalars['String']>;
  schoolSlug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  totalAssessments?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type MyLearnerHomeLiveCoachingMinFields = {
  __typename?: 'MyLearnerHomeLiveCoachingMinFields';
  certDate?: Maybe<Scalars['timestamptz']>;
  classCount?: Maybe<Scalars['bigint']>;
  cohortDayOfWeek?: Maybe<Scalars['String']>;
  cohortEnd?: Maybe<Scalars['timestamp']>;
  cohortId?: Maybe<Scalars['uuid']>;
  cohortName?: Maybe<Scalars['String']>;
  cohortSlug?: Maybe<Scalars['String']>;
  cohortStart?: Maybe<Scalars['timestamp']>;
  cohortTimeOfDay?: Maybe<Scalars['timestamp']>;
  cohortType?: Maybe<Scalars['String']>;
  courseBackgroundImageUrl?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['uuid']>;
  courseName?: Maybe<Scalars['String']>;
  courseSlug?: Maybe<Scalars['String']>;
  courseType?: Maybe<Scalars['String']>;
  enrolledAt?: Maybe<Scalars['timestamp']>;
  lastCompletedClassDate?: Maybe<Scalars['timestamp']>;
  nextClassDate?: Maybe<Scalars['timestamp']>;
  numTasksRequired?: Maybe<Scalars['bigint']>;
  progress?: Maybe<Scalars['float8']>;
  schoolName?: Maybe<Scalars['String']>;
  schoolSlug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  totalAssessments?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "my_learner_home_live_coaching". */
export type MyLearnerHomeLiveCoachingOrderBy = {
  assessmentPassed?: InputMaybe<OrderBy>;
  certDate?: InputMaybe<OrderBy>;
  classCount?: InputMaybe<OrderBy>;
  cohortCompleted?: InputMaybe<OrderBy>;
  cohortDayOfWeek?: InputMaybe<OrderBy>;
  cohortEnd?: InputMaybe<OrderBy>;
  cohortHasPrework?: InputMaybe<OrderBy>;
  cohortId?: InputMaybe<OrderBy>;
  cohortInAppVideo?: InputMaybe<OrderBy>;
  cohortName?: InputMaybe<OrderBy>;
  cohortRequiresPractice?: InputMaybe<OrderBy>;
  cohortSlug?: InputMaybe<OrderBy>;
  cohortStart?: InputMaybe<OrderBy>;
  cohortTimeOfDay?: InputMaybe<OrderBy>;
  cohortType?: InputMaybe<OrderBy>;
  courseBackgroundImageUrl?: InputMaybe<OrderBy>;
  courseId?: InputMaybe<OrderBy>;
  courseName?: InputMaybe<OrderBy>;
  courseSlug?: InputMaybe<OrderBy>;
  courseType?: InputMaybe<OrderBy>;
  enrolledAt?: InputMaybe<OrderBy>;
  hasUserCompletedAllClasses?: InputMaybe<OrderBy>;
  hasUserSkippedPractice?: InputMaybe<OrderBy>;
  isFeedbackReady?: InputMaybe<OrderBy>;
  isOnTrack?: InputMaybe<OrderBy>;
  isWaitlisted?: InputMaybe<OrderBy>;
  lastCompletedClassDate?: InputMaybe<OrderBy>;
  nextClassDate?: InputMaybe<OrderBy>;
  numTasksRequired?: InputMaybe<OrderBy>;
  progress?: InputMaybe<OrderBy>;
  schoolName?: InputMaybe<OrderBy>;
  schoolSlug?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  tagsAggregate?: InputMaybe<CoursesTagsRelAggregateOrderBy>;
  totalAssessments?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** select columns of table "my_learner_home_live_coaching" */
export enum MyLearnerHomeLiveCoachingSelectColumn {
  /** column name */
  assessmentPassed = 'assessmentPassed',
  /** column name */
  certDate = 'certDate',
  /** column name */
  classCount = 'classCount',
  /** column name */
  cohortCompleted = 'cohortCompleted',
  /** column name */
  cohortDayOfWeek = 'cohortDayOfWeek',
  /** column name */
  cohortEnd = 'cohortEnd',
  /** column name */
  cohortHasPrework = 'cohortHasPrework',
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  cohortInAppVideo = 'cohortInAppVideo',
  /** column name */
  cohortName = 'cohortName',
  /** column name */
  cohortRequiresPractice = 'cohortRequiresPractice',
  /** column name */
  cohortSlug = 'cohortSlug',
  /** column name */
  cohortStart = 'cohortStart',
  /** column name */
  cohortTimeOfDay = 'cohortTimeOfDay',
  /** column name */
  cohortType = 'cohortType',
  /** column name */
  courseBackgroundImageUrl = 'courseBackgroundImageUrl',
  /** column name */
  courseId = 'courseId',
  /** column name */
  courseName = 'courseName',
  /** column name */
  courseSlug = 'courseSlug',
  /** column name */
  courseType = 'courseType',
  /** column name */
  enrolledAt = 'enrolledAt',
  /** column name */
  hasUserCompletedAllClasses = 'hasUserCompletedAllClasses',
  /** column name */
  hasUserSkippedPractice = 'hasUserSkippedPractice',
  /** column name */
  isFeedbackReady = 'isFeedbackReady',
  /** column name */
  isOnTrack = 'isOnTrack',
  /** column name */
  isWaitlisted = 'isWaitlisted',
  /** column name */
  lastCompletedClassDate = 'lastCompletedClassDate',
  /** column name */
  nextClassDate = 'nextClassDate',
  /** column name */
  numTasksRequired = 'numTasksRequired',
  /** column name */
  progress = 'progress',
  /** column name */
  schoolName = 'schoolName',
  /** column name */
  schoolSlug = 'schoolSlug',
  /** column name */
  status = 'status',
  /** column name */
  totalAssessments = 'totalAssessments',
  /** column name */
  userId = 'userId'
}

/** aggregate stddev on columns */
export type MyLearnerHomeLiveCoachingStddevFields = {
  __typename?: 'MyLearnerHomeLiveCoachingStddevFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type MyLearnerHomeLiveCoachingStddevPopFields = {
  __typename?: 'MyLearnerHomeLiveCoachingStddevPopFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type MyLearnerHomeLiveCoachingStddevSampFields = {
  __typename?: 'MyLearnerHomeLiveCoachingStddevSampFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "my_learner_home_live_coaching" */
export type MyLearnerHomeLiveCoachingStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MyLearnerHomeLiveCoachingStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MyLearnerHomeLiveCoachingStreamCursorValueInput = {
  assessmentPassed?: InputMaybe<Scalars['Boolean']>;
  certDate?: InputMaybe<Scalars['timestamptz']>;
  classCount?: InputMaybe<Scalars['bigint']>;
  cohortCompleted?: InputMaybe<Scalars['Boolean']>;
  cohortDayOfWeek?: InputMaybe<Scalars['String']>;
  cohortEnd?: InputMaybe<Scalars['timestamp']>;
  cohortHasPrework?: InputMaybe<Scalars['Boolean']>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  cohortInAppVideo?: InputMaybe<Scalars['Boolean']>;
  cohortName?: InputMaybe<Scalars['String']>;
  cohortRequiresPractice?: InputMaybe<Scalars['Boolean']>;
  cohortSlug?: InputMaybe<Scalars['String']>;
  cohortStart?: InputMaybe<Scalars['timestamp']>;
  cohortTimeOfDay?: InputMaybe<Scalars['timestamp']>;
  cohortType?: InputMaybe<Scalars['String']>;
  courseBackgroundImageUrl?: InputMaybe<Scalars['String']>;
  courseId?: InputMaybe<Scalars['uuid']>;
  courseName?: InputMaybe<Scalars['String']>;
  courseSlug?: InputMaybe<Scalars['String']>;
  courseType?: InputMaybe<Scalars['String']>;
  enrolledAt?: InputMaybe<Scalars['timestamp']>;
  hasUserCompletedAllClasses?: InputMaybe<Scalars['Boolean']>;
  hasUserSkippedPractice?: InputMaybe<Scalars['Boolean']>;
  isFeedbackReady?: InputMaybe<Scalars['Boolean']>;
  isOnTrack?: InputMaybe<Scalars['Boolean']>;
  isWaitlisted?: InputMaybe<Scalars['Boolean']>;
  lastCompletedClassDate?: InputMaybe<Scalars['timestamp']>;
  nextClassDate?: InputMaybe<Scalars['timestamp']>;
  numTasksRequired?: InputMaybe<Scalars['bigint']>;
  progress?: InputMaybe<Scalars['float8']>;
  schoolName?: InputMaybe<Scalars['String']>;
  schoolSlug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  totalAssessments?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type MyLearnerHomeLiveCoachingSumFields = {
  __typename?: 'MyLearnerHomeLiveCoachingSumFields';
  classCount?: Maybe<Scalars['bigint']>;
  numTasksRequired?: Maybe<Scalars['bigint']>;
  progress?: Maybe<Scalars['float8']>;
  totalAssessments?: Maybe<Scalars['Int']>;
};

/** aggregate varPop on columns */
export type MyLearnerHomeLiveCoachingVarPopFields = {
  __typename?: 'MyLearnerHomeLiveCoachingVarPopFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type MyLearnerHomeLiveCoachingVarSampFields = {
  __typename?: 'MyLearnerHomeLiveCoachingVarSampFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type MyLearnerHomeLiveCoachingVarianceFields = {
  __typename?: 'MyLearnerHomeLiveCoachingVarianceFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "my_lightning_session_cohorts_learning_status" */
export type MyLightningSessionCohortsLearningStatus = {
  __typename?: 'MyLightningSessionCohortsLearningStatus';
  assessmentPassed?: Maybe<Scalars['Boolean']>;
  certDate?: Maybe<Scalars['timestamptz']>;
  classCount?: Maybe<Scalars['bigint']>;
  cohortCompleted?: Maybe<Scalars['Boolean']>;
  cohortDayOfWeek?: Maybe<Scalars['String']>;
  cohortEnd?: Maybe<Scalars['timestamp']>;
  cohortHasPrework?: Maybe<Scalars['Boolean']>;
  cohortId?: Maybe<Scalars['uuid']>;
  cohortInAppVideo?: Maybe<Scalars['Boolean']>;
  cohortName?: Maybe<Scalars['String']>;
  cohortRequiresPractice?: Maybe<Scalars['Boolean']>;
  cohortSlug?: Maybe<Scalars['String']>;
  cohortStart?: Maybe<Scalars['timestamp']>;
  cohortTimeOfDay?: Maybe<Scalars['timestamp']>;
  cohortType?: Maybe<Scalars['String']>;
  courseBackgroundImageUrl?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['uuid']>;
  courseName?: Maybe<Scalars['String']>;
  courseSlug?: Maybe<Scalars['String']>;
  courseType?: Maybe<Scalars['String']>;
  enrolledAt?: Maybe<Scalars['timestamp']>;
  hasUserCompletedAllClasses?: Maybe<Scalars['Boolean']>;
  hasUserSkippedPractice?: Maybe<Scalars['Boolean']>;
  isFeedbackReady?: Maybe<Scalars['Boolean']>;
  isOnTrack?: Maybe<Scalars['Boolean']>;
  isWaitlisted?: Maybe<Scalars['Boolean']>;
  lastCompletedClassDate?: Maybe<Scalars['timestamp']>;
  nextClassDate?: Maybe<Scalars['timestamp']>;
  numTasksRequired?: Maybe<Scalars['bigint']>;
  progress?: Maybe<Scalars['float8']>;
  schoolName?: Maybe<Scalars['String']>;
  schoolSlug?: Maybe<Scalars['String']>;
  totalAssessments?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "my_lightning_session_cohorts_learning_status" */
export type MyLightningSessionCohortsLearningStatusAggregate = {
  __typename?: 'MyLightningSessionCohortsLearningStatusAggregate';
  aggregate?: Maybe<MyLightningSessionCohortsLearningStatusAggregateFields>;
  nodes: Array<MyLightningSessionCohortsLearningStatus>;
};

/** aggregate fields of "my_lightning_session_cohorts_learning_status" */
export type MyLightningSessionCohortsLearningStatusAggregateFields = {
  __typename?: 'MyLightningSessionCohortsLearningStatusAggregateFields';
  avg?: Maybe<MyLightningSessionCohortsLearningStatusAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<MyLightningSessionCohortsLearningStatusMaxFields>;
  min?: Maybe<MyLightningSessionCohortsLearningStatusMinFields>;
  stddev?: Maybe<MyLightningSessionCohortsLearningStatusStddevFields>;
  stddevPop?: Maybe<MyLightningSessionCohortsLearningStatusStddevPopFields>;
  stddevSamp?: Maybe<MyLightningSessionCohortsLearningStatusStddevSampFields>;
  sum?: Maybe<MyLightningSessionCohortsLearningStatusSumFields>;
  varPop?: Maybe<MyLightningSessionCohortsLearningStatusVarPopFields>;
  varSamp?: Maybe<MyLightningSessionCohortsLearningStatusVarSampFields>;
  variance?: Maybe<MyLightningSessionCohortsLearningStatusVarianceFields>;
};

/** aggregate fields of "my_lightning_session_cohorts_learning_status" */
export type MyLightningSessionCohortsLearningStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MyLightningSessionCohortsLearningStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type MyLightningSessionCohortsLearningStatusAvgFields = {
  __typename?: 'MyLightningSessionCohortsLearningStatusAvgFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "my_lightning_session_cohorts_learning_status". All fields are combined with a logical 'AND'. */
export type MyLightningSessionCohortsLearningStatusBoolExp = {
  _and?: InputMaybe<Array<MyLightningSessionCohortsLearningStatusBoolExp>>;
  _not?: InputMaybe<MyLightningSessionCohortsLearningStatusBoolExp>;
  _or?: InputMaybe<Array<MyLightningSessionCohortsLearningStatusBoolExp>>;
  assessmentPassed?: InputMaybe<BooleanComparisonExp>;
  certDate?: InputMaybe<TimestamptzComparisonExp>;
  classCount?: InputMaybe<BigintComparisonExp>;
  cohortCompleted?: InputMaybe<BooleanComparisonExp>;
  cohortDayOfWeek?: InputMaybe<StringComparisonExp>;
  cohortEnd?: InputMaybe<TimestampComparisonExp>;
  cohortHasPrework?: InputMaybe<BooleanComparisonExp>;
  cohortId?: InputMaybe<UuidComparisonExp>;
  cohortInAppVideo?: InputMaybe<BooleanComparisonExp>;
  cohortName?: InputMaybe<StringComparisonExp>;
  cohortRequiresPractice?: InputMaybe<BooleanComparisonExp>;
  cohortSlug?: InputMaybe<StringComparisonExp>;
  cohortStart?: InputMaybe<TimestampComparisonExp>;
  cohortTimeOfDay?: InputMaybe<TimestampComparisonExp>;
  cohortType?: InputMaybe<StringComparisonExp>;
  courseBackgroundImageUrl?: InputMaybe<StringComparisonExp>;
  courseId?: InputMaybe<UuidComparisonExp>;
  courseName?: InputMaybe<StringComparisonExp>;
  courseSlug?: InputMaybe<StringComparisonExp>;
  courseType?: InputMaybe<StringComparisonExp>;
  enrolledAt?: InputMaybe<TimestampComparisonExp>;
  hasUserCompletedAllClasses?: InputMaybe<BooleanComparisonExp>;
  hasUserSkippedPractice?: InputMaybe<BooleanComparisonExp>;
  isFeedbackReady?: InputMaybe<BooleanComparisonExp>;
  isOnTrack?: InputMaybe<BooleanComparisonExp>;
  isWaitlisted?: InputMaybe<BooleanComparisonExp>;
  lastCompletedClassDate?: InputMaybe<TimestampComparisonExp>;
  nextClassDate?: InputMaybe<TimestampComparisonExp>;
  numTasksRequired?: InputMaybe<BigintComparisonExp>;
  progress?: InputMaybe<Float8ComparisonExp>;
  schoolName?: InputMaybe<StringComparisonExp>;
  schoolSlug?: InputMaybe<StringComparisonExp>;
  totalAssessments?: InputMaybe<IntComparisonExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** aggregate max on columns */
export type MyLightningSessionCohortsLearningStatusMaxFields = {
  __typename?: 'MyLightningSessionCohortsLearningStatusMaxFields';
  certDate?: Maybe<Scalars['timestamptz']>;
  classCount?: Maybe<Scalars['bigint']>;
  cohortDayOfWeek?: Maybe<Scalars['String']>;
  cohortEnd?: Maybe<Scalars['timestamp']>;
  cohortId?: Maybe<Scalars['uuid']>;
  cohortName?: Maybe<Scalars['String']>;
  cohortSlug?: Maybe<Scalars['String']>;
  cohortStart?: Maybe<Scalars['timestamp']>;
  cohortTimeOfDay?: Maybe<Scalars['timestamp']>;
  cohortType?: Maybe<Scalars['String']>;
  courseBackgroundImageUrl?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['uuid']>;
  courseName?: Maybe<Scalars['String']>;
  courseSlug?: Maybe<Scalars['String']>;
  courseType?: Maybe<Scalars['String']>;
  enrolledAt?: Maybe<Scalars['timestamp']>;
  lastCompletedClassDate?: Maybe<Scalars['timestamp']>;
  nextClassDate?: Maybe<Scalars['timestamp']>;
  numTasksRequired?: Maybe<Scalars['bigint']>;
  progress?: Maybe<Scalars['float8']>;
  schoolName?: Maybe<Scalars['String']>;
  schoolSlug?: Maybe<Scalars['String']>;
  totalAssessments?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type MyLightningSessionCohortsLearningStatusMinFields = {
  __typename?: 'MyLightningSessionCohortsLearningStatusMinFields';
  certDate?: Maybe<Scalars['timestamptz']>;
  classCount?: Maybe<Scalars['bigint']>;
  cohortDayOfWeek?: Maybe<Scalars['String']>;
  cohortEnd?: Maybe<Scalars['timestamp']>;
  cohortId?: Maybe<Scalars['uuid']>;
  cohortName?: Maybe<Scalars['String']>;
  cohortSlug?: Maybe<Scalars['String']>;
  cohortStart?: Maybe<Scalars['timestamp']>;
  cohortTimeOfDay?: Maybe<Scalars['timestamp']>;
  cohortType?: Maybe<Scalars['String']>;
  courseBackgroundImageUrl?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['uuid']>;
  courseName?: Maybe<Scalars['String']>;
  courseSlug?: Maybe<Scalars['String']>;
  courseType?: Maybe<Scalars['String']>;
  enrolledAt?: Maybe<Scalars['timestamp']>;
  lastCompletedClassDate?: Maybe<Scalars['timestamp']>;
  nextClassDate?: Maybe<Scalars['timestamp']>;
  numTasksRequired?: Maybe<Scalars['bigint']>;
  progress?: Maybe<Scalars['float8']>;
  schoolName?: Maybe<Scalars['String']>;
  schoolSlug?: Maybe<Scalars['String']>;
  totalAssessments?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "my_lightning_session_cohorts_learning_status". */
export type MyLightningSessionCohortsLearningStatusOrderBy = {
  assessmentPassed?: InputMaybe<OrderBy>;
  certDate?: InputMaybe<OrderBy>;
  classCount?: InputMaybe<OrderBy>;
  cohortCompleted?: InputMaybe<OrderBy>;
  cohortDayOfWeek?: InputMaybe<OrderBy>;
  cohortEnd?: InputMaybe<OrderBy>;
  cohortHasPrework?: InputMaybe<OrderBy>;
  cohortId?: InputMaybe<OrderBy>;
  cohortInAppVideo?: InputMaybe<OrderBy>;
  cohortName?: InputMaybe<OrderBy>;
  cohortRequiresPractice?: InputMaybe<OrderBy>;
  cohortSlug?: InputMaybe<OrderBy>;
  cohortStart?: InputMaybe<OrderBy>;
  cohortTimeOfDay?: InputMaybe<OrderBy>;
  cohortType?: InputMaybe<OrderBy>;
  courseBackgroundImageUrl?: InputMaybe<OrderBy>;
  courseId?: InputMaybe<OrderBy>;
  courseName?: InputMaybe<OrderBy>;
  courseSlug?: InputMaybe<OrderBy>;
  courseType?: InputMaybe<OrderBy>;
  enrolledAt?: InputMaybe<OrderBy>;
  hasUserCompletedAllClasses?: InputMaybe<OrderBy>;
  hasUserSkippedPractice?: InputMaybe<OrderBy>;
  isFeedbackReady?: InputMaybe<OrderBy>;
  isOnTrack?: InputMaybe<OrderBy>;
  isWaitlisted?: InputMaybe<OrderBy>;
  lastCompletedClassDate?: InputMaybe<OrderBy>;
  nextClassDate?: InputMaybe<OrderBy>;
  numTasksRequired?: InputMaybe<OrderBy>;
  progress?: InputMaybe<OrderBy>;
  schoolName?: InputMaybe<OrderBy>;
  schoolSlug?: InputMaybe<OrderBy>;
  totalAssessments?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** select columns of table "my_lightning_session_cohorts_learning_status" */
export enum MyLightningSessionCohortsLearningStatusSelectColumn {
  /** column name */
  assessmentPassed = 'assessmentPassed',
  /** column name */
  certDate = 'certDate',
  /** column name */
  classCount = 'classCount',
  /** column name */
  cohortCompleted = 'cohortCompleted',
  /** column name */
  cohortDayOfWeek = 'cohortDayOfWeek',
  /** column name */
  cohortEnd = 'cohortEnd',
  /** column name */
  cohortHasPrework = 'cohortHasPrework',
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  cohortInAppVideo = 'cohortInAppVideo',
  /** column name */
  cohortName = 'cohortName',
  /** column name */
  cohortRequiresPractice = 'cohortRequiresPractice',
  /** column name */
  cohortSlug = 'cohortSlug',
  /** column name */
  cohortStart = 'cohortStart',
  /** column name */
  cohortTimeOfDay = 'cohortTimeOfDay',
  /** column name */
  cohortType = 'cohortType',
  /** column name */
  courseBackgroundImageUrl = 'courseBackgroundImageUrl',
  /** column name */
  courseId = 'courseId',
  /** column name */
  courseName = 'courseName',
  /** column name */
  courseSlug = 'courseSlug',
  /** column name */
  courseType = 'courseType',
  /** column name */
  enrolledAt = 'enrolledAt',
  /** column name */
  hasUserCompletedAllClasses = 'hasUserCompletedAllClasses',
  /** column name */
  hasUserSkippedPractice = 'hasUserSkippedPractice',
  /** column name */
  isFeedbackReady = 'isFeedbackReady',
  /** column name */
  isOnTrack = 'isOnTrack',
  /** column name */
  isWaitlisted = 'isWaitlisted',
  /** column name */
  lastCompletedClassDate = 'lastCompletedClassDate',
  /** column name */
  nextClassDate = 'nextClassDate',
  /** column name */
  numTasksRequired = 'numTasksRequired',
  /** column name */
  progress = 'progress',
  /** column name */
  schoolName = 'schoolName',
  /** column name */
  schoolSlug = 'schoolSlug',
  /** column name */
  totalAssessments = 'totalAssessments',
  /** column name */
  userId = 'userId'
}

/** aggregate stddev on columns */
export type MyLightningSessionCohortsLearningStatusStddevFields = {
  __typename?: 'MyLightningSessionCohortsLearningStatusStddevFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type MyLightningSessionCohortsLearningStatusStddevPopFields = {
  __typename?: 'MyLightningSessionCohortsLearningStatusStddevPopFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type MyLightningSessionCohortsLearningStatusStddevSampFields = {
  __typename?: 'MyLightningSessionCohortsLearningStatusStddevSampFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "my_lightning_session_cohorts_learning_status" */
export type MyLightningSessionCohortsLearningStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MyLightningSessionCohortsLearningStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MyLightningSessionCohortsLearningStatusStreamCursorValueInput = {
  assessmentPassed?: InputMaybe<Scalars['Boolean']>;
  certDate?: InputMaybe<Scalars['timestamptz']>;
  classCount?: InputMaybe<Scalars['bigint']>;
  cohortCompleted?: InputMaybe<Scalars['Boolean']>;
  cohortDayOfWeek?: InputMaybe<Scalars['String']>;
  cohortEnd?: InputMaybe<Scalars['timestamp']>;
  cohortHasPrework?: InputMaybe<Scalars['Boolean']>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  cohortInAppVideo?: InputMaybe<Scalars['Boolean']>;
  cohortName?: InputMaybe<Scalars['String']>;
  cohortRequiresPractice?: InputMaybe<Scalars['Boolean']>;
  cohortSlug?: InputMaybe<Scalars['String']>;
  cohortStart?: InputMaybe<Scalars['timestamp']>;
  cohortTimeOfDay?: InputMaybe<Scalars['timestamp']>;
  cohortType?: InputMaybe<Scalars['String']>;
  courseBackgroundImageUrl?: InputMaybe<Scalars['String']>;
  courseId?: InputMaybe<Scalars['uuid']>;
  courseName?: InputMaybe<Scalars['String']>;
  courseSlug?: InputMaybe<Scalars['String']>;
  courseType?: InputMaybe<Scalars['String']>;
  enrolledAt?: InputMaybe<Scalars['timestamp']>;
  hasUserCompletedAllClasses?: InputMaybe<Scalars['Boolean']>;
  hasUserSkippedPractice?: InputMaybe<Scalars['Boolean']>;
  isFeedbackReady?: InputMaybe<Scalars['Boolean']>;
  isOnTrack?: InputMaybe<Scalars['Boolean']>;
  isWaitlisted?: InputMaybe<Scalars['Boolean']>;
  lastCompletedClassDate?: InputMaybe<Scalars['timestamp']>;
  nextClassDate?: InputMaybe<Scalars['timestamp']>;
  numTasksRequired?: InputMaybe<Scalars['bigint']>;
  progress?: InputMaybe<Scalars['float8']>;
  schoolName?: InputMaybe<Scalars['String']>;
  schoolSlug?: InputMaybe<Scalars['String']>;
  totalAssessments?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type MyLightningSessionCohortsLearningStatusSumFields = {
  __typename?: 'MyLightningSessionCohortsLearningStatusSumFields';
  classCount?: Maybe<Scalars['bigint']>;
  numTasksRequired?: Maybe<Scalars['bigint']>;
  progress?: Maybe<Scalars['float8']>;
  totalAssessments?: Maybe<Scalars['Int']>;
};

/** aggregate varPop on columns */
export type MyLightningSessionCohortsLearningStatusVarPopFields = {
  __typename?: 'MyLightningSessionCohortsLearningStatusVarPopFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type MyLightningSessionCohortsLearningStatusVarSampFields = {
  __typename?: 'MyLightningSessionCohortsLearningStatusVarSampFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type MyLightningSessionCohortsLearningStatusVarianceFields = {
  __typename?: 'MyLightningSessionCohortsLearningStatusVarianceFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

export type MyTeamMember = {
  __typename?: 'MyTeamMember';
  avatarUrl?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['String'];
  teamId?: Maybe<Scalars['String']>;
  teamName?: Maybe<Scalars['String']>;
};

export type MyTeamMembersOutput = {
  __typename?: 'MyTeamMembersOutput';
  members?: Maybe<Array<MyTeamMember>>;
};

/** columns and relationships of "my_traditional_cohorts_learning_status" */
export type MyTraditionalCohortsLearningStatus = {
  __typename?: 'MyTraditionalCohortsLearningStatus';
  assessmentPassed?: Maybe<Scalars['Boolean']>;
  certDate?: Maybe<Scalars['timestamp']>;
  classCount?: Maybe<Scalars['bigint']>;
  cohortCompleted?: Maybe<Scalars['Boolean']>;
  cohortDayOfWeek?: Maybe<Scalars['String']>;
  cohortEnd?: Maybe<Scalars['timestamp']>;
  cohortHasPrework?: Maybe<Scalars['Boolean']>;
  cohortId?: Maybe<Scalars['uuid']>;
  cohortInAppVideo?: Maybe<Scalars['Boolean']>;
  cohortName?: Maybe<Scalars['String']>;
  cohortRequiresPractice?: Maybe<Scalars['Boolean']>;
  cohortSlug?: Maybe<Scalars['String']>;
  cohortStart?: Maybe<Scalars['timestamp']>;
  cohortTimeOfDay?: Maybe<Scalars['timestamp']>;
  cohortType?: Maybe<Scalars['String']>;
  courseBackgroundImageUrl?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['uuid']>;
  courseName?: Maybe<Scalars['String']>;
  courseSlug?: Maybe<Scalars['String']>;
  courseType?: Maybe<Scalars['String']>;
  enrolledAt?: Maybe<Scalars['timestamp']>;
  hasUserCompletedAllClasses?: Maybe<Scalars['Boolean']>;
  hasUserSkippedPractice?: Maybe<Scalars['Boolean']>;
  isFeedbackReady?: Maybe<Scalars['Boolean']>;
  isOnTrack?: Maybe<Scalars['Boolean']>;
  lastCompletedClassDate?: Maybe<Scalars['timestamp']>;
  nextClassDate?: Maybe<Scalars['timestamp']>;
  numTasksRequired?: Maybe<Scalars['bigint']>;
  progress?: Maybe<Scalars['float8']>;
  schoolName?: Maybe<Scalars['String']>;
  schoolSlug?: Maybe<Scalars['String']>;
  totalAssessments?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "my_traditional_cohorts_learning_status" */
export type MyTraditionalCohortsLearningStatusAggregate = {
  __typename?: 'MyTraditionalCohortsLearningStatusAggregate';
  aggregate?: Maybe<MyTraditionalCohortsLearningStatusAggregateFields>;
  nodes: Array<MyTraditionalCohortsLearningStatus>;
};

/** aggregate fields of "my_traditional_cohorts_learning_status" */
export type MyTraditionalCohortsLearningStatusAggregateFields = {
  __typename?: 'MyTraditionalCohortsLearningStatusAggregateFields';
  avg?: Maybe<MyTraditionalCohortsLearningStatusAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<MyTraditionalCohortsLearningStatusMaxFields>;
  min?: Maybe<MyTraditionalCohortsLearningStatusMinFields>;
  stddev?: Maybe<MyTraditionalCohortsLearningStatusStddevFields>;
  stddevPop?: Maybe<MyTraditionalCohortsLearningStatusStddevPopFields>;
  stddevSamp?: Maybe<MyTraditionalCohortsLearningStatusStddevSampFields>;
  sum?: Maybe<MyTraditionalCohortsLearningStatusSumFields>;
  varPop?: Maybe<MyTraditionalCohortsLearningStatusVarPopFields>;
  varSamp?: Maybe<MyTraditionalCohortsLearningStatusVarSampFields>;
  variance?: Maybe<MyTraditionalCohortsLearningStatusVarianceFields>;
};

/** aggregate fields of "my_traditional_cohorts_learning_status" */
export type MyTraditionalCohortsLearningStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MyTraditionalCohortsLearningStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type MyTraditionalCohortsLearningStatusAvgFields = {
  __typename?: 'MyTraditionalCohortsLearningStatusAvgFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "my_traditional_cohorts_learning_status". All fields are combined with a logical 'AND'. */
export type MyTraditionalCohortsLearningStatusBoolExp = {
  _and?: InputMaybe<Array<MyTraditionalCohortsLearningStatusBoolExp>>;
  _not?: InputMaybe<MyTraditionalCohortsLearningStatusBoolExp>;
  _or?: InputMaybe<Array<MyTraditionalCohortsLearningStatusBoolExp>>;
  assessmentPassed?: InputMaybe<BooleanComparisonExp>;
  certDate?: InputMaybe<TimestampComparisonExp>;
  classCount?: InputMaybe<BigintComparisonExp>;
  cohortCompleted?: InputMaybe<BooleanComparisonExp>;
  cohortDayOfWeek?: InputMaybe<StringComparisonExp>;
  cohortEnd?: InputMaybe<TimestampComparisonExp>;
  cohortHasPrework?: InputMaybe<BooleanComparisonExp>;
  cohortId?: InputMaybe<UuidComparisonExp>;
  cohortInAppVideo?: InputMaybe<BooleanComparisonExp>;
  cohortName?: InputMaybe<StringComparisonExp>;
  cohortRequiresPractice?: InputMaybe<BooleanComparisonExp>;
  cohortSlug?: InputMaybe<StringComparisonExp>;
  cohortStart?: InputMaybe<TimestampComparisonExp>;
  cohortTimeOfDay?: InputMaybe<TimestampComparisonExp>;
  cohortType?: InputMaybe<StringComparisonExp>;
  courseBackgroundImageUrl?: InputMaybe<StringComparisonExp>;
  courseId?: InputMaybe<UuidComparisonExp>;
  courseName?: InputMaybe<StringComparisonExp>;
  courseSlug?: InputMaybe<StringComparisonExp>;
  courseType?: InputMaybe<StringComparisonExp>;
  enrolledAt?: InputMaybe<TimestampComparisonExp>;
  hasUserCompletedAllClasses?: InputMaybe<BooleanComparisonExp>;
  hasUserSkippedPractice?: InputMaybe<BooleanComparisonExp>;
  isFeedbackReady?: InputMaybe<BooleanComparisonExp>;
  isOnTrack?: InputMaybe<BooleanComparisonExp>;
  lastCompletedClassDate?: InputMaybe<TimestampComparisonExp>;
  nextClassDate?: InputMaybe<TimestampComparisonExp>;
  numTasksRequired?: InputMaybe<BigintComparisonExp>;
  progress?: InputMaybe<Float8ComparisonExp>;
  schoolName?: InputMaybe<StringComparisonExp>;
  schoolSlug?: InputMaybe<StringComparisonExp>;
  totalAssessments?: InputMaybe<IntComparisonExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** aggregate max on columns */
export type MyTraditionalCohortsLearningStatusMaxFields = {
  __typename?: 'MyTraditionalCohortsLearningStatusMaxFields';
  certDate?: Maybe<Scalars['timestamp']>;
  classCount?: Maybe<Scalars['bigint']>;
  cohortDayOfWeek?: Maybe<Scalars['String']>;
  cohortEnd?: Maybe<Scalars['timestamp']>;
  cohortId?: Maybe<Scalars['uuid']>;
  cohortName?: Maybe<Scalars['String']>;
  cohortSlug?: Maybe<Scalars['String']>;
  cohortStart?: Maybe<Scalars['timestamp']>;
  cohortTimeOfDay?: Maybe<Scalars['timestamp']>;
  cohortType?: Maybe<Scalars['String']>;
  courseBackgroundImageUrl?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['uuid']>;
  courseName?: Maybe<Scalars['String']>;
  courseSlug?: Maybe<Scalars['String']>;
  courseType?: Maybe<Scalars['String']>;
  enrolledAt?: Maybe<Scalars['timestamp']>;
  lastCompletedClassDate?: Maybe<Scalars['timestamp']>;
  nextClassDate?: Maybe<Scalars['timestamp']>;
  numTasksRequired?: Maybe<Scalars['bigint']>;
  progress?: Maybe<Scalars['float8']>;
  schoolName?: Maybe<Scalars['String']>;
  schoolSlug?: Maybe<Scalars['String']>;
  totalAssessments?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type MyTraditionalCohortsLearningStatusMinFields = {
  __typename?: 'MyTraditionalCohortsLearningStatusMinFields';
  certDate?: Maybe<Scalars['timestamp']>;
  classCount?: Maybe<Scalars['bigint']>;
  cohortDayOfWeek?: Maybe<Scalars['String']>;
  cohortEnd?: Maybe<Scalars['timestamp']>;
  cohortId?: Maybe<Scalars['uuid']>;
  cohortName?: Maybe<Scalars['String']>;
  cohortSlug?: Maybe<Scalars['String']>;
  cohortStart?: Maybe<Scalars['timestamp']>;
  cohortTimeOfDay?: Maybe<Scalars['timestamp']>;
  cohortType?: Maybe<Scalars['String']>;
  courseBackgroundImageUrl?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['uuid']>;
  courseName?: Maybe<Scalars['String']>;
  courseSlug?: Maybe<Scalars['String']>;
  courseType?: Maybe<Scalars['String']>;
  enrolledAt?: Maybe<Scalars['timestamp']>;
  lastCompletedClassDate?: Maybe<Scalars['timestamp']>;
  nextClassDate?: Maybe<Scalars['timestamp']>;
  numTasksRequired?: Maybe<Scalars['bigint']>;
  progress?: Maybe<Scalars['float8']>;
  schoolName?: Maybe<Scalars['String']>;
  schoolSlug?: Maybe<Scalars['String']>;
  totalAssessments?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "my_traditional_cohorts_learning_status". */
export type MyTraditionalCohortsLearningStatusOrderBy = {
  assessmentPassed?: InputMaybe<OrderBy>;
  certDate?: InputMaybe<OrderBy>;
  classCount?: InputMaybe<OrderBy>;
  cohortCompleted?: InputMaybe<OrderBy>;
  cohortDayOfWeek?: InputMaybe<OrderBy>;
  cohortEnd?: InputMaybe<OrderBy>;
  cohortHasPrework?: InputMaybe<OrderBy>;
  cohortId?: InputMaybe<OrderBy>;
  cohortInAppVideo?: InputMaybe<OrderBy>;
  cohortName?: InputMaybe<OrderBy>;
  cohortRequiresPractice?: InputMaybe<OrderBy>;
  cohortSlug?: InputMaybe<OrderBy>;
  cohortStart?: InputMaybe<OrderBy>;
  cohortTimeOfDay?: InputMaybe<OrderBy>;
  cohortType?: InputMaybe<OrderBy>;
  courseBackgroundImageUrl?: InputMaybe<OrderBy>;
  courseId?: InputMaybe<OrderBy>;
  courseName?: InputMaybe<OrderBy>;
  courseSlug?: InputMaybe<OrderBy>;
  courseType?: InputMaybe<OrderBy>;
  enrolledAt?: InputMaybe<OrderBy>;
  hasUserCompletedAllClasses?: InputMaybe<OrderBy>;
  hasUserSkippedPractice?: InputMaybe<OrderBy>;
  isFeedbackReady?: InputMaybe<OrderBy>;
  isOnTrack?: InputMaybe<OrderBy>;
  lastCompletedClassDate?: InputMaybe<OrderBy>;
  nextClassDate?: InputMaybe<OrderBy>;
  numTasksRequired?: InputMaybe<OrderBy>;
  progress?: InputMaybe<OrderBy>;
  schoolName?: InputMaybe<OrderBy>;
  schoolSlug?: InputMaybe<OrderBy>;
  totalAssessments?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** select columns of table "my_traditional_cohorts_learning_status" */
export enum MyTraditionalCohortsLearningStatusSelectColumn {
  /** column name */
  assessmentPassed = 'assessmentPassed',
  /** column name */
  certDate = 'certDate',
  /** column name */
  classCount = 'classCount',
  /** column name */
  cohortCompleted = 'cohortCompleted',
  /** column name */
  cohortDayOfWeek = 'cohortDayOfWeek',
  /** column name */
  cohortEnd = 'cohortEnd',
  /** column name */
  cohortHasPrework = 'cohortHasPrework',
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  cohortInAppVideo = 'cohortInAppVideo',
  /** column name */
  cohortName = 'cohortName',
  /** column name */
  cohortRequiresPractice = 'cohortRequiresPractice',
  /** column name */
  cohortSlug = 'cohortSlug',
  /** column name */
  cohortStart = 'cohortStart',
  /** column name */
  cohortTimeOfDay = 'cohortTimeOfDay',
  /** column name */
  cohortType = 'cohortType',
  /** column name */
  courseBackgroundImageUrl = 'courseBackgroundImageUrl',
  /** column name */
  courseId = 'courseId',
  /** column name */
  courseName = 'courseName',
  /** column name */
  courseSlug = 'courseSlug',
  /** column name */
  courseType = 'courseType',
  /** column name */
  enrolledAt = 'enrolledAt',
  /** column name */
  hasUserCompletedAllClasses = 'hasUserCompletedAllClasses',
  /** column name */
  hasUserSkippedPractice = 'hasUserSkippedPractice',
  /** column name */
  isFeedbackReady = 'isFeedbackReady',
  /** column name */
  isOnTrack = 'isOnTrack',
  /** column name */
  lastCompletedClassDate = 'lastCompletedClassDate',
  /** column name */
  nextClassDate = 'nextClassDate',
  /** column name */
  numTasksRequired = 'numTasksRequired',
  /** column name */
  progress = 'progress',
  /** column name */
  schoolName = 'schoolName',
  /** column name */
  schoolSlug = 'schoolSlug',
  /** column name */
  totalAssessments = 'totalAssessments',
  /** column name */
  userId = 'userId'
}

/** aggregate stddev on columns */
export type MyTraditionalCohortsLearningStatusStddevFields = {
  __typename?: 'MyTraditionalCohortsLearningStatusStddevFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type MyTraditionalCohortsLearningStatusStddevPopFields = {
  __typename?: 'MyTraditionalCohortsLearningStatusStddevPopFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type MyTraditionalCohortsLearningStatusStddevSampFields = {
  __typename?: 'MyTraditionalCohortsLearningStatusStddevSampFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "my_traditional_cohorts_learning_status" */
export type MyTraditionalCohortsLearningStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MyTraditionalCohortsLearningStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MyTraditionalCohortsLearningStatusStreamCursorValueInput = {
  assessmentPassed?: InputMaybe<Scalars['Boolean']>;
  certDate?: InputMaybe<Scalars['timestamp']>;
  classCount?: InputMaybe<Scalars['bigint']>;
  cohortCompleted?: InputMaybe<Scalars['Boolean']>;
  cohortDayOfWeek?: InputMaybe<Scalars['String']>;
  cohortEnd?: InputMaybe<Scalars['timestamp']>;
  cohortHasPrework?: InputMaybe<Scalars['Boolean']>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  cohortInAppVideo?: InputMaybe<Scalars['Boolean']>;
  cohortName?: InputMaybe<Scalars['String']>;
  cohortRequiresPractice?: InputMaybe<Scalars['Boolean']>;
  cohortSlug?: InputMaybe<Scalars['String']>;
  cohortStart?: InputMaybe<Scalars['timestamp']>;
  cohortTimeOfDay?: InputMaybe<Scalars['timestamp']>;
  cohortType?: InputMaybe<Scalars['String']>;
  courseBackgroundImageUrl?: InputMaybe<Scalars['String']>;
  courseId?: InputMaybe<Scalars['uuid']>;
  courseName?: InputMaybe<Scalars['String']>;
  courseSlug?: InputMaybe<Scalars['String']>;
  courseType?: InputMaybe<Scalars['String']>;
  enrolledAt?: InputMaybe<Scalars['timestamp']>;
  hasUserCompletedAllClasses?: InputMaybe<Scalars['Boolean']>;
  hasUserSkippedPractice?: InputMaybe<Scalars['Boolean']>;
  isFeedbackReady?: InputMaybe<Scalars['Boolean']>;
  isOnTrack?: InputMaybe<Scalars['Boolean']>;
  lastCompletedClassDate?: InputMaybe<Scalars['timestamp']>;
  nextClassDate?: InputMaybe<Scalars['timestamp']>;
  numTasksRequired?: InputMaybe<Scalars['bigint']>;
  progress?: InputMaybe<Scalars['float8']>;
  schoolName?: InputMaybe<Scalars['String']>;
  schoolSlug?: InputMaybe<Scalars['String']>;
  totalAssessments?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type MyTraditionalCohortsLearningStatusSumFields = {
  __typename?: 'MyTraditionalCohortsLearningStatusSumFields';
  classCount?: Maybe<Scalars['bigint']>;
  numTasksRequired?: Maybe<Scalars['bigint']>;
  progress?: Maybe<Scalars['float8']>;
  totalAssessments?: Maybe<Scalars['Int']>;
};

/** aggregate varPop on columns */
export type MyTraditionalCohortsLearningStatusVarPopFields = {
  __typename?: 'MyTraditionalCohortsLearningStatusVarPopFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type MyTraditionalCohortsLearningStatusVarSampFields = {
  __typename?: 'MyTraditionalCohortsLearningStatusVarSampFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type MyTraditionalCohortsLearningStatusVarianceFields = {
  __typename?: 'MyTraditionalCohortsLearningStatusVarianceFields';
  classCount?: Maybe<Scalars['Float']>;
  numTasksRequired?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  totalAssessments?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "nonce_access_token" */
export type NonceAccessToken = {
  __typename?: 'NonceAccessToken';
  createdAt: Scalars['timestamp'];
  expirationDate: Scalars['timestamp'];
  id: Scalars['uuid'];
  targetResource: Scalars['String'];
  token: Scalars['String'];
};

/** aggregated selection of "nonce_access_token" */
export type NonceAccessTokenAggregate = {
  __typename?: 'NonceAccessTokenAggregate';
  aggregate?: Maybe<NonceAccessTokenAggregateFields>;
  nodes: Array<NonceAccessToken>;
};

/** aggregate fields of "nonce_access_token" */
export type NonceAccessTokenAggregateFields = {
  __typename?: 'NonceAccessTokenAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<NonceAccessTokenMaxFields>;
  min?: Maybe<NonceAccessTokenMinFields>;
};

/** aggregate fields of "nonce_access_token" */
export type NonceAccessTokenAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<NonceAccessTokenSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "nonce_access_token". All fields are combined with a logical 'AND'. */
export type NonceAccessTokenBoolExp = {
  _and?: InputMaybe<Array<NonceAccessTokenBoolExp>>;
  _not?: InputMaybe<NonceAccessTokenBoolExp>;
  _or?: InputMaybe<Array<NonceAccessTokenBoolExp>>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  expirationDate?: InputMaybe<TimestampComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  targetResource?: InputMaybe<StringComparisonExp>;
  token?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "nonce_access_token" */
export enum NonceAccessTokenConstraint {
  /** unique or primary key constraint on columns "id" */
  nonce_access_token_pkey = 'nonce_access_token_pkey',
  /** unique or primary key constraint on columns "token" */
  nonce_access_token_token_key = 'nonce_access_token_token_key'
}

/** input type for inserting data into table "nonce_access_token" */
export type NonceAccessTokenInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  expirationDate?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  targetResource?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type NonceAccessTokenMaxFields = {
  __typename?: 'NonceAccessTokenMaxFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  expirationDate?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  targetResource?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type NonceAccessTokenMinFields = {
  __typename?: 'NonceAccessTokenMinFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  expirationDate?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  targetResource?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "nonce_access_token" */
export type NonceAccessTokenMutationResponse = {
  __typename?: 'NonceAccessTokenMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<NonceAccessToken>;
};

/** on_conflict condition type for table "nonce_access_token" */
export type NonceAccessTokenOnConflict = {
  constraint: NonceAccessTokenConstraint;
  updateColumns?: Array<NonceAccessTokenUpdateColumn>;
  where?: InputMaybe<NonceAccessTokenBoolExp>;
};

/** Ordering options when selecting data from "nonce_access_token". */
export type NonceAccessTokenOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  expirationDate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  targetResource?: InputMaybe<OrderBy>;
  token?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: nonce_access_token */
export type NonceAccessTokenPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "nonce_access_token" */
export enum NonceAccessTokenSelectColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  expirationDate = 'expirationDate',
  /** column name */
  id = 'id',
  /** column name */
  targetResource = 'targetResource',
  /** column name */
  token = 'token'
}

/** input type for updating data in table "nonce_access_token" */
export type NonceAccessTokenSetInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  expirationDate?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  targetResource?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "nonce_access_token" */
export type NonceAccessTokenStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: NonceAccessTokenStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type NonceAccessTokenStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  expirationDate?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  targetResource?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

/** update columns of table "nonce_access_token" */
export enum NonceAccessTokenUpdateColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  expirationDate = 'expirationDate',
  /** column name */
  id = 'id',
  /** column name */
  targetResource = 'targetResource',
  /** column name */
  token = 'token'
}

export type NonceAccessTokenUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<NonceAccessTokenSetInput>;
  /** filter the rows which have to be updated */
  where: NonceAccessTokenBoolExp;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type NumericComparisonExp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  ASC = 'ASC',
  /** in ascending order, nulls first */
  ASC_NULLS_FIRST = 'ASC_NULLS_FIRST',
  /** in ascending order, nulls last */
  ASC_NULLS_LAST = 'ASC_NULLS_LAST',
  /** in descending order, nulls first */
  DESC = 'DESC',
  /** in descending order, nulls first */
  DESC_NULLS_FIRST = 'DESC_NULLS_FIRST',
  /** in descending order, nulls last */
  DESC_NULLS_LAST = 'DESC_NULLS_LAST'
}

/** columns and relationships of "fusionauth.group_members" */
export type OrganizationUsers = {
  __typename?: 'OrganizationUsers';
  data?: Maybe<Scalars['String']>;
  groupsId: Scalars['uuid'];
  id: Scalars['uuid'];
  insertInstant: Scalars['bigint'];
  /** An object relationship */
  organization: Organizations;
  /** An object relationship */
  user: Users;
  usersId: Scalars['uuid'];
};

/** aggregated selection of "fusionauth.group_members" */
export type OrganizationUsersAggregate = {
  __typename?: 'OrganizationUsersAggregate';
  aggregate?: Maybe<OrganizationUsersAggregateFields>;
  nodes: Array<OrganizationUsers>;
};

export type OrganizationUsersAggregateBoolExp = {
  count?: InputMaybe<OrganizationUsersAggregateBoolExpCount>;
};

export type OrganizationUsersAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<OrganizationUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<OrganizationUsersBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "fusionauth.group_members" */
export type OrganizationUsersAggregateFields = {
  __typename?: 'OrganizationUsersAggregateFields';
  avg?: Maybe<OrganizationUsersAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OrganizationUsersMaxFields>;
  min?: Maybe<OrganizationUsersMinFields>;
  stddev?: Maybe<OrganizationUsersStddevFields>;
  stddevPop?: Maybe<OrganizationUsersStddevPopFields>;
  stddevSamp?: Maybe<OrganizationUsersStddevSampFields>;
  sum?: Maybe<OrganizationUsersSumFields>;
  varPop?: Maybe<OrganizationUsersVarPopFields>;
  varSamp?: Maybe<OrganizationUsersVarSampFields>;
  variance?: Maybe<OrganizationUsersVarianceFields>;
};

/** aggregate fields of "fusionauth.group_members" */
export type OrganizationUsersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrganizationUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "fusionauth.group_members" */
export type OrganizationUsersAggregateOrderBy = {
  avg?: InputMaybe<OrganizationUsersAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OrganizationUsersMaxOrderBy>;
  min?: InputMaybe<OrganizationUsersMinOrderBy>;
  stddev?: InputMaybe<OrganizationUsersStddevOrderBy>;
  stddevPop?: InputMaybe<OrganizationUsersStddevPopOrderBy>;
  stddevSamp?: InputMaybe<OrganizationUsersStddevSampOrderBy>;
  sum?: InputMaybe<OrganizationUsersSumOrderBy>;
  varPop?: InputMaybe<OrganizationUsersVarPopOrderBy>;
  varSamp?: InputMaybe<OrganizationUsersVarSampOrderBy>;
  variance?: InputMaybe<OrganizationUsersVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "fusionauth.group_members" */
export type OrganizationUsersArrRelInsertInput = {
  data: Array<OrganizationUsersInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<OrganizationUsersOnConflict>;
};

/** aggregate avg on columns */
export type OrganizationUsersAvgFields = {
  __typename?: 'OrganizationUsersAvgFields';
  insertInstant?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "fusionauth.group_members" */
export type OrganizationUsersAvgOrderBy = {
  insertInstant?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "fusionauth.group_members". All fields are combined with a logical 'AND'. */
export type OrganizationUsersBoolExp = {
  _and?: InputMaybe<Array<OrganizationUsersBoolExp>>;
  _not?: InputMaybe<OrganizationUsersBoolExp>;
  _or?: InputMaybe<Array<OrganizationUsersBoolExp>>;
  data?: InputMaybe<StringComparisonExp>;
  groupsId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  insertInstant?: InputMaybe<BigintComparisonExp>;
  organization?: InputMaybe<OrganizationsBoolExp>;
  user?: InputMaybe<UsersBoolExp>;
  usersId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "fusionauth.group_members" */
export enum OrganizationUsersConstraint {
  /** unique or primary key constraint on columns "id" */
  group_members_pkey = 'group_members_pkey',
  /** unique or primary key constraint on columns "users_id", "groups_id" */
  group_members_uk_1 = 'group_members_uk_1'
}

/** input type for incrementing numeric columns in table "fusionauth.group_members" */
export type OrganizationUsersIncInput = {
  insertInstant?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "fusionauth.group_members" */
export type OrganizationUsersInsertInput = {
  data?: InputMaybe<Scalars['String']>;
  groupsId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertInstant?: InputMaybe<Scalars['bigint']>;
  organization?: InputMaybe<OrganizationsObjRelInsertInput>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  usersId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type OrganizationUsersMaxFields = {
  __typename?: 'OrganizationUsersMaxFields';
  data?: Maybe<Scalars['String']>;
  groupsId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  insertInstant?: Maybe<Scalars['bigint']>;
  usersId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "fusionauth.group_members" */
export type OrganizationUsersMaxOrderBy = {
  data?: InputMaybe<OrderBy>;
  groupsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertInstant?: InputMaybe<OrderBy>;
  usersId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type OrganizationUsersMinFields = {
  __typename?: 'OrganizationUsersMinFields';
  data?: Maybe<Scalars['String']>;
  groupsId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  insertInstant?: Maybe<Scalars['bigint']>;
  usersId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "fusionauth.group_members" */
export type OrganizationUsersMinOrderBy = {
  data?: InputMaybe<OrderBy>;
  groupsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertInstant?: InputMaybe<OrderBy>;
  usersId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "fusionauth.group_members" */
export type OrganizationUsersMutationResponse = {
  __typename?: 'OrganizationUsersMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OrganizationUsers>;
};

/** on_conflict condition type for table "fusionauth.group_members" */
export type OrganizationUsersOnConflict = {
  constraint: OrganizationUsersConstraint;
  updateColumns?: Array<OrganizationUsersUpdateColumn>;
  where?: InputMaybe<OrganizationUsersBoolExp>;
};

/** Ordering options when selecting data from "fusionauth.group_members". */
export type OrganizationUsersOrderBy = {
  data?: InputMaybe<OrderBy>;
  groupsId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertInstant?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationsOrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  usersId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: fusionauth.group_members */
export type OrganizationUsersPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "fusionauth.group_members" */
export enum OrganizationUsersSelectColumn {
  /** column name */
  data = 'data',
  /** column name */
  groupsId = 'groupsId',
  /** column name */
  id = 'id',
  /** column name */
  insertInstant = 'insertInstant',
  /** column name */
  usersId = 'usersId'
}

/** input type for updating data in table "fusionauth.group_members" */
export type OrganizationUsersSetInput = {
  data?: InputMaybe<Scalars['String']>;
  groupsId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertInstant?: InputMaybe<Scalars['bigint']>;
  usersId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type OrganizationUsersStddevFields = {
  __typename?: 'OrganizationUsersStddevFields';
  insertInstant?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "fusionauth.group_members" */
export type OrganizationUsersStddevOrderBy = {
  insertInstant?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type OrganizationUsersStddevPopFields = {
  __typename?: 'OrganizationUsersStddevPopFields';
  insertInstant?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "fusionauth.group_members" */
export type OrganizationUsersStddevPopOrderBy = {
  insertInstant?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type OrganizationUsersStddevSampFields = {
  __typename?: 'OrganizationUsersStddevSampFields';
  insertInstant?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "fusionauth.group_members" */
export type OrganizationUsersStddevSampOrderBy = {
  insertInstant?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "OrganizationUsers" */
export type OrganizationUsersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: OrganizationUsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OrganizationUsersStreamCursorValueInput = {
  data?: InputMaybe<Scalars['String']>;
  groupsId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertInstant?: InputMaybe<Scalars['bigint']>;
  usersId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type OrganizationUsersSumFields = {
  __typename?: 'OrganizationUsersSumFields';
  insertInstant?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "fusionauth.group_members" */
export type OrganizationUsersSumOrderBy = {
  insertInstant?: InputMaybe<OrderBy>;
};

/** update columns of table "fusionauth.group_members" */
export enum OrganizationUsersUpdateColumn {
  /** column name */
  data = 'data',
  /** column name */
  groupsId = 'groupsId',
  /** column name */
  id = 'id',
  /** column name */
  insertInstant = 'insertInstant',
  /** column name */
  usersId = 'usersId'
}

export type OrganizationUsersUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OrganizationUsersIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrganizationUsersSetInput>;
  /** filter the rows which have to be updated */
  where: OrganizationUsersBoolExp;
};

/** aggregate varPop on columns */
export type OrganizationUsersVarPopFields = {
  __typename?: 'OrganizationUsersVarPopFields';
  insertInstant?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "fusionauth.group_members" */
export type OrganizationUsersVarPopOrderBy = {
  insertInstant?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type OrganizationUsersVarSampFields = {
  __typename?: 'OrganizationUsersVarSampFields';
  insertInstant?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "fusionauth.group_members" */
export type OrganizationUsersVarSampOrderBy = {
  insertInstant?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type OrganizationUsersVarianceFields = {
  __typename?: 'OrganizationUsersVarianceFields';
  insertInstant?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "fusionauth.group_members" */
export type OrganizationUsersVarianceOrderBy = {
  insertInstant?: InputMaybe<OrderBy>;
};

/** columns and relationships of "fusionauth.groups" */
export type Organizations = {
  __typename?: 'Organizations';
  data?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  insertInstant: Scalars['bigint'];
  /** An array relationship */
  journeys: Array<Journeys>;
  /** An aggregate relationship */
  journeysAggregate: JourneysAggregate;
  lastUpdateInstant: Scalars['bigint'];
  /** An object relationship */
  meta?: Maybe<OrganizationsMeta>;
  name: Scalars['String'];
  /** An array relationship */
  organizationUsers: Array<OrganizationUsers>;
  /** An aggregate relationship */
  organizationUsersAggregate: OrganizationUsersAggregate;
  /** An array relationship */
  planSubscriptions: Array<PlanSubscriptions>;
  /** An aggregate relationship */
  planSubscriptionsAggregate: PlanSubscriptionsAggregate;
  tenantsId: Scalars['uuid'];
};

/** columns and relationships of "fusionauth.groups" */
export type OrganizationsJourneysArgs = {
  distinctOn?: InputMaybe<Array<JourneysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<JourneysOrderBy>>;
  where?: InputMaybe<JourneysBoolExp>;
};

/** columns and relationships of "fusionauth.groups" */
export type OrganizationsJourneysAggregateArgs = {
  distinctOn?: InputMaybe<Array<JourneysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<JourneysOrderBy>>;
  where?: InputMaybe<JourneysBoolExp>;
};

/** columns and relationships of "fusionauth.groups" */
export type OrganizationsOrganizationUsersArgs = {
  distinctOn?: InputMaybe<Array<OrganizationUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationUsersOrderBy>>;
  where?: InputMaybe<OrganizationUsersBoolExp>;
};

/** columns and relationships of "fusionauth.groups" */
export type OrganizationsOrganizationUsersAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationUsersOrderBy>>;
  where?: InputMaybe<OrganizationUsersBoolExp>;
};

/** columns and relationships of "fusionauth.groups" */
export type OrganizationsPlanSubscriptionsArgs = {
  distinctOn?: InputMaybe<Array<PlanSubscriptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlanSubscriptionsOrderBy>>;
  where?: InputMaybe<PlanSubscriptionsBoolExp>;
};

/** columns and relationships of "fusionauth.groups" */
export type OrganizationsPlanSubscriptionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<PlanSubscriptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlanSubscriptionsOrderBy>>;
  where?: InputMaybe<PlanSubscriptionsBoolExp>;
};

/** aggregated selection of "fusionauth.groups" */
export type OrganizationsAggregate = {
  __typename?: 'OrganizationsAggregate';
  aggregate?: Maybe<OrganizationsAggregateFields>;
  nodes: Array<Organizations>;
};

/** aggregate fields of "fusionauth.groups" */
export type OrganizationsAggregateFields = {
  __typename?: 'OrganizationsAggregateFields';
  avg?: Maybe<OrganizationsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OrganizationsMaxFields>;
  min?: Maybe<OrganizationsMinFields>;
  stddev?: Maybe<OrganizationsStddevFields>;
  stddevPop?: Maybe<OrganizationsStddevPopFields>;
  stddevSamp?: Maybe<OrganizationsStddevSampFields>;
  sum?: Maybe<OrganizationsSumFields>;
  varPop?: Maybe<OrganizationsVarPopFields>;
  varSamp?: Maybe<OrganizationsVarSampFields>;
  variance?: Maybe<OrganizationsVarianceFields>;
};

/** aggregate fields of "fusionauth.groups" */
export type OrganizationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrganizationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type OrganizationsAvgFields = {
  __typename?: 'OrganizationsAvgFields';
  insertInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "fusionauth.groups". All fields are combined with a logical 'AND'. */
export type OrganizationsBoolExp = {
  _and?: InputMaybe<Array<OrganizationsBoolExp>>;
  _not?: InputMaybe<OrganizationsBoolExp>;
  _or?: InputMaybe<Array<OrganizationsBoolExp>>;
  data?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  insertInstant?: InputMaybe<BigintComparisonExp>;
  journeys?: InputMaybe<JourneysBoolExp>;
  journeysAggregate?: InputMaybe<JourneysAggregateBoolExp>;
  lastUpdateInstant?: InputMaybe<BigintComparisonExp>;
  meta?: InputMaybe<OrganizationsMetaBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  organizationUsers?: InputMaybe<OrganizationUsersBoolExp>;
  organizationUsersAggregate?: InputMaybe<OrganizationUsersAggregateBoolExp>;
  planSubscriptions?: InputMaybe<PlanSubscriptionsBoolExp>;
  planSubscriptionsAggregate?: InputMaybe<PlanSubscriptionsAggregateBoolExp>;
  tenantsId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "fusionauth.groups" */
export enum OrganizationsConstraint {
  /** unique or primary key constraint on columns "id" */
  groups_pkey = 'groups_pkey',
  /** unique or primary key constraint on columns "tenants_id", "name" */
  groups_uk_1 = 'groups_uk_1'
}

/** input type for incrementing numeric columns in table "fusionauth.groups" */
export type OrganizationsIncInput = {
  insertInstant?: InputMaybe<Scalars['bigint']>;
  lastUpdateInstant?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "fusionauth.groups" */
export type OrganizationsInsertInput = {
  data?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertInstant?: InputMaybe<Scalars['bigint']>;
  journeys?: InputMaybe<JourneysArrRelInsertInput>;
  lastUpdateInstant?: InputMaybe<Scalars['bigint']>;
  meta?: InputMaybe<OrganizationsMetaObjRelInsertInput>;
  name?: InputMaybe<Scalars['String']>;
  organizationUsers?: InputMaybe<OrganizationUsersArrRelInsertInput>;
  planSubscriptions?: InputMaybe<PlanSubscriptionsArrRelInsertInput>;
  tenantsId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type OrganizationsMaxFields = {
  __typename?: 'OrganizationsMaxFields';
  data?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insertInstant?: Maybe<Scalars['bigint']>;
  lastUpdateInstant?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  tenantsId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "organizations_meta" */
export type OrganizationsMeta = {
  __typename?: 'OrganizationsMeta';
  companySize?: Maybe<Scalars['String']>;
  creditModel?: Maybe<Scalars['String']>;
  customerCreatedAt?: Maybe<Scalars['timestamptz']>;
  industry?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  /** An object relationship */
  organization: Organizations;
  organizationId: Scalars['uuid'];
  productSold?: Maybe<Scalars['String']>;
  salesforceAccountId: Scalars['String'];
  targetCustomer?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  websiteLink?: Maybe<Scalars['String']>;
};

/** aggregated selection of "organizations_meta" */
export type OrganizationsMetaAggregate = {
  __typename?: 'OrganizationsMetaAggregate';
  aggregate?: Maybe<OrganizationsMetaAggregateFields>;
  nodes: Array<OrganizationsMeta>;
};

/** aggregate fields of "organizations_meta" */
export type OrganizationsMetaAggregateFields = {
  __typename?: 'OrganizationsMetaAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<OrganizationsMetaMaxFields>;
  min?: Maybe<OrganizationsMetaMinFields>;
};

/** aggregate fields of "organizations_meta" */
export type OrganizationsMetaAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrganizationsMetaSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "organizations_meta". All fields are combined with a logical 'AND'. */
export type OrganizationsMetaBoolExp = {
  _and?: InputMaybe<Array<OrganizationsMetaBoolExp>>;
  _not?: InputMaybe<OrganizationsMetaBoolExp>;
  _or?: InputMaybe<Array<OrganizationsMetaBoolExp>>;
  companySize?: InputMaybe<StringComparisonExp>;
  creditModel?: InputMaybe<StringComparisonExp>;
  customerCreatedAt?: InputMaybe<TimestamptzComparisonExp>;
  industry?: InputMaybe<StringComparisonExp>;
  linkedInUrl?: InputMaybe<StringComparisonExp>;
  organization?: InputMaybe<OrganizationsBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  productSold?: InputMaybe<StringComparisonExp>;
  salesforceAccountId?: InputMaybe<StringComparisonExp>;
  targetCustomer?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
  websiteLink?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "organizations_meta" */
export enum OrganizationsMetaConstraint {
  /** unique or primary key constraint on columns "organization_id" */
  organizations_meta_pk = 'organizations_meta_pk',
  /** unique or primary key constraint on columns "salesforce_account_id" */
  organizations_meta_uniq_sfdc_account_id = 'organizations_meta_uniq_sfdc_account_id'
}

/** input type for inserting data into table "organizations_meta" */
export type OrganizationsMetaInsertInput = {
  companySize?: InputMaybe<Scalars['String']>;
  creditModel?: InputMaybe<Scalars['String']>;
  customerCreatedAt?: InputMaybe<Scalars['timestamptz']>;
  industry?: InputMaybe<Scalars['String']>;
  linkedInUrl?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationsObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  productSold?: InputMaybe<Scalars['String']>;
  salesforceAccountId?: InputMaybe<Scalars['String']>;
  targetCustomer?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  websiteLink?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type OrganizationsMetaMaxFields = {
  __typename?: 'OrganizationsMetaMaxFields';
  companySize?: Maybe<Scalars['String']>;
  creditModel?: Maybe<Scalars['String']>;
  customerCreatedAt?: Maybe<Scalars['timestamptz']>;
  industry?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  productSold?: Maybe<Scalars['String']>;
  salesforceAccountId?: Maybe<Scalars['String']>;
  targetCustomer?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  websiteLink?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type OrganizationsMetaMinFields = {
  __typename?: 'OrganizationsMetaMinFields';
  companySize?: Maybe<Scalars['String']>;
  creditModel?: Maybe<Scalars['String']>;
  customerCreatedAt?: Maybe<Scalars['timestamptz']>;
  industry?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  productSold?: Maybe<Scalars['String']>;
  salesforceAccountId?: Maybe<Scalars['String']>;
  targetCustomer?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  websiteLink?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "organizations_meta" */
export type OrganizationsMetaMutationResponse = {
  __typename?: 'OrganizationsMetaMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OrganizationsMeta>;
};

/** input type for inserting object relation for remote table "organizations_meta" */
export type OrganizationsMetaObjRelInsertInput = {
  data: OrganizationsMetaInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<OrganizationsMetaOnConflict>;
};

/** on_conflict condition type for table "organizations_meta" */
export type OrganizationsMetaOnConflict = {
  constraint: OrganizationsMetaConstraint;
  updateColumns?: Array<OrganizationsMetaUpdateColumn>;
  where?: InputMaybe<OrganizationsMetaBoolExp>;
};

/** Ordering options when selecting data from "organizations_meta". */
export type OrganizationsMetaOrderBy = {
  companySize?: InputMaybe<OrderBy>;
  creditModel?: InputMaybe<OrderBy>;
  customerCreatedAt?: InputMaybe<OrderBy>;
  industry?: InputMaybe<OrderBy>;
  linkedInUrl?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationsOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  productSold?: InputMaybe<OrderBy>;
  salesforceAccountId?: InputMaybe<OrderBy>;
  targetCustomer?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  websiteLink?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: organizations_meta */
export type OrganizationsMetaPkColumnsInput = {
  organizationId: Scalars['uuid'];
};

/** select columns of table "organizations_meta" */
export enum OrganizationsMetaSelectColumn {
  /** column name */
  companySize = 'companySize',
  /** column name */
  creditModel = 'creditModel',
  /** column name */
  customerCreatedAt = 'customerCreatedAt',
  /** column name */
  industry = 'industry',
  /** column name */
  linkedInUrl = 'linkedInUrl',
  /** column name */
  organizationId = 'organizationId',
  /** column name */
  productSold = 'productSold',
  /** column name */
  salesforceAccountId = 'salesforceAccountId',
  /** column name */
  targetCustomer = 'targetCustomer',
  /** column name */
  type = 'type',
  /** column name */
  websiteLink = 'websiteLink'
}

/** input type for updating data in table "organizations_meta" */
export type OrganizationsMetaSetInput = {
  companySize?: InputMaybe<Scalars['String']>;
  creditModel?: InputMaybe<Scalars['String']>;
  customerCreatedAt?: InputMaybe<Scalars['timestamptz']>;
  industry?: InputMaybe<Scalars['String']>;
  linkedInUrl?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  productSold?: InputMaybe<Scalars['String']>;
  salesforceAccountId?: InputMaybe<Scalars['String']>;
  targetCustomer?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  websiteLink?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "organizations_meta" */
export type OrganizationsMetaStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: OrganizationsMetaStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OrganizationsMetaStreamCursorValueInput = {
  companySize?: InputMaybe<Scalars['String']>;
  creditModel?: InputMaybe<Scalars['String']>;
  customerCreatedAt?: InputMaybe<Scalars['timestamptz']>;
  industry?: InputMaybe<Scalars['String']>;
  linkedInUrl?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  productSold?: InputMaybe<Scalars['String']>;
  salesforceAccountId?: InputMaybe<Scalars['String']>;
  targetCustomer?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  websiteLink?: InputMaybe<Scalars['String']>;
};

/** update columns of table "organizations_meta" */
export enum OrganizationsMetaUpdateColumn {
  /** column name */
  companySize = 'companySize',
  /** column name */
  creditModel = 'creditModel',
  /** column name */
  customerCreatedAt = 'customerCreatedAt',
  /** column name */
  industry = 'industry',
  /** column name */
  linkedInUrl = 'linkedInUrl',
  /** column name */
  organizationId = 'organizationId',
  /** column name */
  productSold = 'productSold',
  /** column name */
  salesforceAccountId = 'salesforceAccountId',
  /** column name */
  targetCustomer = 'targetCustomer',
  /** column name */
  type = 'type',
  /** column name */
  websiteLink = 'websiteLink'
}

export type OrganizationsMetaUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrganizationsMetaSetInput>;
  /** filter the rows which have to be updated */
  where: OrganizationsMetaBoolExp;
};

/** aggregate min on columns */
export type OrganizationsMinFields = {
  __typename?: 'OrganizationsMinFields';
  data?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insertInstant?: Maybe<Scalars['bigint']>;
  lastUpdateInstant?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  tenantsId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "fusionauth.groups" */
export type OrganizationsMutationResponse = {
  __typename?: 'OrganizationsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organizations>;
};

/** input type for inserting object relation for remote table "fusionauth.groups" */
export type OrganizationsObjRelInsertInput = {
  data: OrganizationsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<OrganizationsOnConflict>;
};

/** on_conflict condition type for table "fusionauth.groups" */
export type OrganizationsOnConflict = {
  constraint: OrganizationsConstraint;
  updateColumns?: Array<OrganizationsUpdateColumn>;
  where?: InputMaybe<OrganizationsBoolExp>;
};

/** Ordering options when selecting data from "fusionauth.groups". */
export type OrganizationsOrderBy = {
  data?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertInstant?: InputMaybe<OrderBy>;
  journeysAggregate?: InputMaybe<JourneysAggregateOrderBy>;
  lastUpdateInstant?: InputMaybe<OrderBy>;
  meta?: InputMaybe<OrganizationsMetaOrderBy>;
  name?: InputMaybe<OrderBy>;
  organizationUsersAggregate?: InputMaybe<OrganizationUsersAggregateOrderBy>;
  planSubscriptionsAggregate?: InputMaybe<PlanSubscriptionsAggregateOrderBy>;
  tenantsId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: fusionauth.groups */
export type OrganizationsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "fusionauth.groups" */
export enum OrganizationsSelectColumn {
  /** column name */
  data = 'data',
  /** column name */
  id = 'id',
  /** column name */
  insertInstant = 'insertInstant',
  /** column name */
  lastUpdateInstant = 'lastUpdateInstant',
  /** column name */
  name = 'name',
  /** column name */
  tenantsId = 'tenantsId'
}

/** input type for updating data in table "fusionauth.groups" */
export type OrganizationsSetInput = {
  data?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertInstant?: InputMaybe<Scalars['bigint']>;
  lastUpdateInstant?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  tenantsId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type OrganizationsStddevFields = {
  __typename?: 'OrganizationsStddevFields';
  insertInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type OrganizationsStddevPopFields = {
  __typename?: 'OrganizationsStddevPopFields';
  insertInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type OrganizationsStddevSampFields = {
  __typename?: 'OrganizationsStddevSampFields';
  insertInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "Organizations" */
export type OrganizationsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: OrganizationsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OrganizationsStreamCursorValueInput = {
  data?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertInstant?: InputMaybe<Scalars['bigint']>;
  lastUpdateInstant?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  tenantsId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type OrganizationsSumFields = {
  __typename?: 'OrganizationsSumFields';
  insertInstant?: Maybe<Scalars['bigint']>;
  lastUpdateInstant?: Maybe<Scalars['bigint']>;
};

/** update columns of table "fusionauth.groups" */
export enum OrganizationsUpdateColumn {
  /** column name */
  data = 'data',
  /** column name */
  id = 'id',
  /** column name */
  insertInstant = 'insertInstant',
  /** column name */
  lastUpdateInstant = 'lastUpdateInstant',
  /** column name */
  name = 'name',
  /** column name */
  tenantsId = 'tenantsId'
}

export type OrganizationsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OrganizationsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrganizationsSetInput>;
  /** filter the rows which have to be updated */
  where: OrganizationsBoolExp;
};

/** aggregate varPop on columns */
export type OrganizationsVarPopFields = {
  __typename?: 'OrganizationsVarPopFields';
  insertInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type OrganizationsVarSampFields = {
  __typename?: 'OrganizationsVarSampFields';
  insertInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type OrganizationsVarianceFields = {
  __typename?: 'OrganizationsVarianceFields';
  insertInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
};

export type ParticipantInput = {
  avatarUrl?: InputMaybe<Scalars['String']>;
  isCoach?: InputMaybe<Scalars['Boolean']>;
  isOwner?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<Scalars['String']>;
  sessionId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
  userName?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "plan_subscriptions" */
export type PlanSubscriptions = {
  __typename?: 'PlanSubscriptions';
  createdAt: Scalars['timestamp'];
  /** An object relationship */
  createdBy: Users;
  createdById: Scalars['uuid'];
  deletedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  deletedBy?: Maybe<Users>;
  deletedById?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  notes?: Maybe<Scalars['String']>;
  /** An object relationship */
  organization: Organizations;
  organizationId: Scalars['uuid'];
  /** An object relationship */
  plan: Plans;
  planId: Scalars['uuid'];
  updatedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  updatedBy?: Maybe<Users>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "plan_subscriptions" */
export type PlanSubscriptionsAggregate = {
  __typename?: 'PlanSubscriptionsAggregate';
  aggregate?: Maybe<PlanSubscriptionsAggregateFields>;
  nodes: Array<PlanSubscriptions>;
};

export type PlanSubscriptionsAggregateBoolExp = {
  count?: InputMaybe<PlanSubscriptionsAggregateBoolExpCount>;
};

/** aggregate fields of "plan_subscriptions" */
export type PlanSubscriptionsAggregateFields = {
  __typename?: 'PlanSubscriptionsAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<PlanSubscriptionsMaxFields>;
  min?: Maybe<PlanSubscriptionsMinFields>;
};

/** aggregate fields of "plan_subscriptions" */
export type PlanSubscriptionsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PlanSubscriptionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "plan_subscriptions" */
export type PlanSubscriptionsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PlanSubscriptionsMaxOrderBy>;
  min?: InputMaybe<PlanSubscriptionsMinOrderBy>;
};

/** input type for inserting array relation for remote table "plan_subscriptions" */
export type PlanSubscriptionsArrRelInsertInput = {
  data: Array<PlanSubscriptionsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<PlanSubscriptionsOnConflict>;
};

/** Boolean expression to filter rows from the table "plan_subscriptions". All fields are combined with a logical 'AND'. */
export type PlanSubscriptionsBoolExp = {
  _and?: InputMaybe<Array<PlanSubscriptionsBoolExp>>;
  _not?: InputMaybe<PlanSubscriptionsBoolExp>;
  _or?: InputMaybe<Array<PlanSubscriptionsBoolExp>>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  deletedAt?: InputMaybe<TimestampComparisonExp>;
  deletedBy?: InputMaybe<UsersBoolExp>;
  deletedById?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  notes?: InputMaybe<StringComparisonExp>;
  organization?: InputMaybe<OrganizationsBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  plan?: InputMaybe<PlansBoolExp>;
  planId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  updatedBy?: InputMaybe<UsersBoolExp>;
  updatedById?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "plan_subscriptions" */
export enum PlanSubscriptionsConstraint {
  /** unique or primary key constraint on columns "id" */
  plan_subscriptions_pk = 'plan_subscriptions_pk'
}

/** input type for inserting data into table "plan_subscriptions" */
export type PlanSubscriptionsInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedBy?: InputMaybe<UsersObjRelInsertInput>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  notes?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationsObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  plan?: InputMaybe<PlansObjRelInsertInput>;
  planId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<UsersObjRelInsertInput>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type PlanSubscriptionsMaxFields = {
  __typename?: 'PlanSubscriptionsMaxFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  planId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "plan_subscriptions" */
export type PlanSubscriptionsMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type PlanSubscriptionsMinFields = {
  __typename?: 'PlanSubscriptionsMinFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  planId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "plan_subscriptions" */
export type PlanSubscriptionsMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "plan_subscriptions" */
export type PlanSubscriptionsMutationResponse = {
  __typename?: 'PlanSubscriptionsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PlanSubscriptions>;
};

/** on_conflict condition type for table "plan_subscriptions" */
export type PlanSubscriptionsOnConflict = {
  constraint: PlanSubscriptionsConstraint;
  updateColumns?: Array<PlanSubscriptionsUpdateColumn>;
  where?: InputMaybe<PlanSubscriptionsBoolExp>;
};

/** Ordering options when selecting data from "plan_subscriptions". */
export type PlanSubscriptionsOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedBy?: InputMaybe<UsersOrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationsOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  plan?: InputMaybe<PlansOrderBy>;
  planId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<UsersOrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: plan_subscriptions */
export type PlanSubscriptionsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "plan_subscriptions" */
export enum PlanSubscriptionsSelectColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  id = 'id',
  /** column name */
  notes = 'notes',
  /** column name */
  organizationId = 'organizationId',
  /** column name */
  planId = 'planId',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById'
}

/** input type for updating data in table "plan_subscriptions" */
export type PlanSubscriptionsSetInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  notes?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  planId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "plan_subscriptions" */
export type PlanSubscriptionsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PlanSubscriptionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PlanSubscriptionsStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  notes?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  planId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "plan_subscriptions" */
export enum PlanSubscriptionsUpdateColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  id = 'id',
  /** column name */
  notes = 'notes',
  /** column name */
  organizationId = 'organizationId',
  /** column name */
  planId = 'planId',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById'
}

export type PlanSubscriptionsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PlanSubscriptionsSetInput>;
  /** filter the rows which have to be updated */
  where: PlanSubscriptionsBoolExp;
};

/** columns and relationships of "plans" */
export type Plans = {
  __typename?: 'Plans';
  createdAt: Scalars['timestamp'];
  /** An object relationship */
  createdBy: Users;
  createdById: Scalars['uuid'];
  deletedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  deletedBy?: Maybe<Users>;
  deletedById?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  policy: Scalars['jsonb'];
  slug: Scalars['String'];
  /** An array relationship */
  subscriptions: Array<PlanSubscriptions>;
  /** An aggregate relationship */
  subscriptionsAggregate: PlanSubscriptionsAggregate;
  updatedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  updatedBy?: Maybe<Users>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "plans" */
export type PlansPolicyArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "plans" */
export type PlansSubscriptionsArgs = {
  distinctOn?: InputMaybe<Array<PlanSubscriptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlanSubscriptionsOrderBy>>;
  where?: InputMaybe<PlanSubscriptionsBoolExp>;
};

/** columns and relationships of "plans" */
export type PlansSubscriptionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<PlanSubscriptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlanSubscriptionsOrderBy>>;
  where?: InputMaybe<PlanSubscriptionsBoolExp>;
};

/** aggregated selection of "plans" */
export type PlansAggregate = {
  __typename?: 'PlansAggregate';
  aggregate?: Maybe<PlansAggregateFields>;
  nodes: Array<Plans>;
};

/** aggregate fields of "plans" */
export type PlansAggregateFields = {
  __typename?: 'PlansAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<PlansMaxFields>;
  min?: Maybe<PlansMinFields>;
};

/** aggregate fields of "plans" */
export type PlansAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PlansSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "plans" */
export type PlansAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PlansMaxOrderBy>;
  min?: InputMaybe<PlansMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type PlansAppendInput = {
  policy?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "plans". All fields are combined with a logical 'AND'. */
export type PlansBoolExp = {
  _and?: InputMaybe<Array<PlansBoolExp>>;
  _not?: InputMaybe<PlansBoolExp>;
  _or?: InputMaybe<Array<PlansBoolExp>>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  deletedAt?: InputMaybe<TimestampComparisonExp>;
  deletedBy?: InputMaybe<UsersBoolExp>;
  deletedById?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  policy?: InputMaybe<JsonbComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  subscriptions?: InputMaybe<PlanSubscriptionsBoolExp>;
  subscriptionsAggregate?: InputMaybe<PlanSubscriptionsAggregateBoolExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  updatedBy?: InputMaybe<UsersBoolExp>;
  updatedById?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "plans" */
export enum PlansConstraint {
  /** unique or primary key constraint on columns "id" */
  plans_pk = 'plans_pk',
  /** unique or primary key constraint on columns "slug" */
  plans_slug_uindex = 'plans_slug_uindex'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type PlansDeleteAtPathInput = {
  policy?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type PlansDeleteElemInput = {
  policy?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type PlansDeleteKeyInput = {
  policy?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "plans" */
export type PlansInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedBy?: InputMaybe<UsersObjRelInsertInput>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  policy?: InputMaybe<Scalars['jsonb']>;
  slug?: InputMaybe<Scalars['String']>;
  subscriptions?: InputMaybe<PlanSubscriptionsArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<UsersObjRelInsertInput>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type PlansMaxFields = {
  __typename?: 'PlansMaxFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "plans" */
export type PlansMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type PlansMinFields = {
  __typename?: 'PlansMinFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "plans" */
export type PlansMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "plans" */
export type PlansMutationResponse = {
  __typename?: 'PlansMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Plans>;
};

/** input type for inserting object relation for remote table "plans" */
export type PlansObjRelInsertInput = {
  data: PlansInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<PlansOnConflict>;
};

/** on_conflict condition type for table "plans" */
export type PlansOnConflict = {
  constraint: PlansConstraint;
  updateColumns?: Array<PlansUpdateColumn>;
  where?: InputMaybe<PlansBoolExp>;
};

/** Ordering options when selecting data from "plans". */
export type PlansOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedBy?: InputMaybe<UsersOrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  policy?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  subscriptionsAggregate?: InputMaybe<PlanSubscriptionsAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<UsersOrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: plans */
export type PlansPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type PlansPrependInput = {
  policy?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "plans" */
export enum PlansSelectColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  policy = 'policy',
  /** column name */
  slug = 'slug',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById'
}

/** input type for updating data in table "plans" */
export type PlansSetInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  policy?: InputMaybe<Scalars['jsonb']>;
  slug?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "plans" */
export type PlansStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PlansStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PlansStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  policy?: InputMaybe<Scalars['jsonb']>;
  slug?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "plans" */
export enum PlansUpdateColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  policy = 'policy',
  /** column name */
  slug = 'slug',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById'
}

export type PlansUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<PlansAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<PlansDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<PlansDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<PlansDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<PlansPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PlansSetInput>;
  /** filter the rows which have to be updated */
  where: PlansBoolExp;
};

/** columns and relationships of "auxiliary.play_execution" */
export type PlayExecution = {
  __typename?: 'PlayExecution';
  createdAt: Scalars['timestamp'];
  data?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  message?: Maybe<Scalars['String']>;
  params?: Maybe<Scalars['jsonb']>;
  playName?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  users?: Maybe<Scalars['jsonb']>;
};

/** columns and relationships of "auxiliary.play_execution" */
export type PlayExecutionDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "auxiliary.play_execution" */
export type PlayExecutionParamsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "auxiliary.play_execution" */
export type PlayExecutionUsersArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "auxiliary.play_execution" */
export type PlayExecutionAggregate = {
  __typename?: 'PlayExecutionAggregate';
  aggregate?: Maybe<PlayExecutionAggregateFields>;
  nodes: Array<PlayExecution>;
};

/** aggregate fields of "auxiliary.play_execution" */
export type PlayExecutionAggregateFields = {
  __typename?: 'PlayExecutionAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<PlayExecutionMaxFields>;
  min?: Maybe<PlayExecutionMinFields>;
};

/** aggregate fields of "auxiliary.play_execution" */
export type PlayExecutionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PlayExecutionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type PlayExecutionAppendInput = {
  data?: InputMaybe<Scalars['jsonb']>;
  params?: InputMaybe<Scalars['jsonb']>;
  users?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "auxiliary.play_execution". All fields are combined with a logical 'AND'. */
export type PlayExecutionBoolExp = {
  _and?: InputMaybe<Array<PlayExecutionBoolExp>>;
  _not?: InputMaybe<PlayExecutionBoolExp>;
  _or?: InputMaybe<Array<PlayExecutionBoolExp>>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  data?: InputMaybe<JsonbComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  message?: InputMaybe<StringComparisonExp>;
  params?: InputMaybe<JsonbComparisonExp>;
  playName?: InputMaybe<StringComparisonExp>;
  success?: InputMaybe<BooleanComparisonExp>;
  users?: InputMaybe<JsonbComparisonExp>;
};

/** unique or primary key constraints on table "auxiliary.play_execution" */
export enum PlayExecutionConstraint {
  /** unique or primary key constraint on columns "id" */
  play_execution_pkey = 'play_execution_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type PlayExecutionDeleteAtPathInput = {
  data?: InputMaybe<Array<Scalars['String']>>;
  params?: InputMaybe<Array<Scalars['String']>>;
  users?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type PlayExecutionDeleteElemInput = {
  data?: InputMaybe<Scalars['Int']>;
  params?: InputMaybe<Scalars['Int']>;
  users?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type PlayExecutionDeleteKeyInput = {
  data?: InputMaybe<Scalars['String']>;
  params?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "auxiliary.play_execution" */
export type PlayExecutionInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  params?: InputMaybe<Scalars['jsonb']>;
  playName?: InputMaybe<Scalars['String']>;
  success?: InputMaybe<Scalars['Boolean']>;
  users?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type PlayExecutionMaxFields = {
  __typename?: 'PlayExecutionMaxFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  playName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type PlayExecutionMinFields = {
  __typename?: 'PlayExecutionMinFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  playName?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "auxiliary.play_execution" */
export type PlayExecutionMutationResponse = {
  __typename?: 'PlayExecutionMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PlayExecution>;
};

/** on_conflict condition type for table "auxiliary.play_execution" */
export type PlayExecutionOnConflict = {
  constraint: PlayExecutionConstraint;
  updateColumns?: Array<PlayExecutionUpdateColumn>;
  where?: InputMaybe<PlayExecutionBoolExp>;
};

/** Ordering options when selecting data from "auxiliary.play_execution". */
export type PlayExecutionOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  data?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  message?: InputMaybe<OrderBy>;
  params?: InputMaybe<OrderBy>;
  playName?: InputMaybe<OrderBy>;
  success?: InputMaybe<OrderBy>;
  users?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: auxiliary.play_execution */
export type PlayExecutionPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type PlayExecutionPrependInput = {
  data?: InputMaybe<Scalars['jsonb']>;
  params?: InputMaybe<Scalars['jsonb']>;
  users?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "auxiliary.play_execution" */
export enum PlayExecutionSelectColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  data = 'data',
  /** column name */
  id = 'id',
  /** column name */
  message = 'message',
  /** column name */
  params = 'params',
  /** column name */
  playName = 'playName',
  /** column name */
  success = 'success',
  /** column name */
  users = 'users'
}

/** input type for updating data in table "auxiliary.play_execution" */
export type PlayExecutionSetInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  params?: InputMaybe<Scalars['jsonb']>;
  playName?: InputMaybe<Scalars['String']>;
  success?: InputMaybe<Scalars['Boolean']>;
  users?: InputMaybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "PlayExecution" */
export type PlayExecutionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PlayExecutionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PlayExecutionStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  params?: InputMaybe<Scalars['jsonb']>;
  playName?: InputMaybe<Scalars['String']>;
  success?: InputMaybe<Scalars['Boolean']>;
  users?: InputMaybe<Scalars['jsonb']>;
};

/** update columns of table "auxiliary.play_execution" */
export enum PlayExecutionUpdateColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  data = 'data',
  /** column name */
  id = 'id',
  /** column name */
  message = 'message',
  /** column name */
  params = 'params',
  /** column name */
  playName = 'playName',
  /** column name */
  success = 'success',
  /** column name */
  users = 'users'
}

export type PlayExecutionUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<PlayExecutionAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<PlayExecutionDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<PlayExecutionDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<PlayExecutionDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<PlayExecutionPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PlayExecutionSetInput>;
  /** filter the rows which have to be updated */
  where: PlayExecutionBoolExp;
};

export type PlaybackTimes = {
  marker: Scalars['numeric'];
  total: Scalars['numeric'];
};

/** columns and relationships of "program_actions_taken" */
export type ProgramActionsTaken = {
  __typename?: 'ProgramActionsTaken';
  action: Scalars['String'];
  cohortId?: Maybe<Scalars['uuid']>;
  courseId: Scalars['uuid'];
  organizationId: Scalars['uuid'];
  programId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

/** aggregated selection of "program_actions_taken" */
export type ProgramActionsTakenAggregate = {
  __typename?: 'ProgramActionsTakenAggregate';
  aggregate?: Maybe<ProgramActionsTakenAggregateFields>;
  nodes: Array<ProgramActionsTaken>;
};

export type ProgramActionsTakenAggregateBoolExp = {
  count?: InputMaybe<ProgramActionsTakenAggregateBoolExpCount>;
};

/** aggregate fields of "program_actions_taken" */
export type ProgramActionsTakenAggregateFields = {
  __typename?: 'ProgramActionsTakenAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<ProgramActionsTakenMaxFields>;
  min?: Maybe<ProgramActionsTakenMinFields>;
};

/** aggregate fields of "program_actions_taken" */
export type ProgramActionsTakenAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ProgramActionsTakenSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "program_actions_taken" */
export type ProgramActionsTakenAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ProgramActionsTakenMaxOrderBy>;
  min?: InputMaybe<ProgramActionsTakenMinOrderBy>;
};

/** input type for inserting array relation for remote table "program_actions_taken" */
export type ProgramActionsTakenArrRelInsertInput = {
  data: Array<ProgramActionsTakenInsertInput>;
};

/** Boolean expression to filter rows from the table "program_actions_taken". All fields are combined with a logical 'AND'. */
export type ProgramActionsTakenBoolExp = {
  _and?: InputMaybe<Array<ProgramActionsTakenBoolExp>>;
  _not?: InputMaybe<ProgramActionsTakenBoolExp>;
  _or?: InputMaybe<Array<ProgramActionsTakenBoolExp>>;
  action?: InputMaybe<StringComparisonExp>;
  cohortId?: InputMaybe<UuidComparisonExp>;
  courseId?: InputMaybe<UuidComparisonExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  programId?: InputMaybe<UuidComparisonExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** input type for inserting data into table "program_actions_taken" */
export type ProgramActionsTakenInsertInput = {
  action?: InputMaybe<Scalars['String']>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  courseId?: InputMaybe<Scalars['uuid']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  programId?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ProgramActionsTakenMaxFields = {
  __typename?: 'ProgramActionsTakenMaxFields';
  action?: Maybe<Scalars['String']>;
  cohortId?: Maybe<Scalars['uuid']>;
  courseId?: Maybe<Scalars['uuid']>;
  organizationId?: Maybe<Scalars['uuid']>;
  programId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "program_actions_taken" */
export type ProgramActionsTakenMaxOrderBy = {
  action?: InputMaybe<OrderBy>;
  cohortId?: InputMaybe<OrderBy>;
  courseId?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  programId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ProgramActionsTakenMinFields = {
  __typename?: 'ProgramActionsTakenMinFields';
  action?: Maybe<Scalars['String']>;
  cohortId?: Maybe<Scalars['uuid']>;
  courseId?: Maybe<Scalars['uuid']>;
  organizationId?: Maybe<Scalars['uuid']>;
  programId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "program_actions_taken" */
export type ProgramActionsTakenMinOrderBy = {
  action?: InputMaybe<OrderBy>;
  cohortId?: InputMaybe<OrderBy>;
  courseId?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  programId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "program_actions_taken" */
export type ProgramActionsTakenMutationResponse = {
  __typename?: 'ProgramActionsTakenMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProgramActionsTaken>;
};

/** Ordering options when selecting data from "program_actions_taken". */
export type ProgramActionsTakenOrderBy = {
  action?: InputMaybe<OrderBy>;
  cohortId?: InputMaybe<OrderBy>;
  courseId?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  programId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** select columns of table "program_actions_taken" */
export enum ProgramActionsTakenSelectColumn {
  /** column name */
  action = 'action',
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  courseId = 'courseId',
  /** column name */
  organizationId = 'organizationId',
  /** column name */
  programId = 'programId',
  /** column name */
  userId = 'userId'
}

/** input type for updating data in table "program_actions_taken" */
export type ProgramActionsTakenSetInput = {
  action?: InputMaybe<Scalars['String']>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  courseId?: InputMaybe<Scalars['uuid']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  programId?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "program_actions_taken" */
export type ProgramActionsTakenStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProgramActionsTakenStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProgramActionsTakenStreamCursorValueInput = {
  action?: InputMaybe<Scalars['String']>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  courseId?: InputMaybe<Scalars['uuid']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  programId?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

export type ProgramActionsTakenUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProgramActionsTakenSetInput>;
  /** filter the rows which have to be updated */
  where: ProgramActionsTakenBoolExp;
};

/** columns and relationships of "program_courses_shown" */
export type ProgramCoursesShown = {
  __typename?: 'ProgramCoursesShown';
  courseId: Scalars['uuid'];
  programId: Scalars['uuid'];
};

/** aggregated selection of "program_courses_shown" */
export type ProgramCoursesShownAggregate = {
  __typename?: 'ProgramCoursesShownAggregate';
  aggregate?: Maybe<ProgramCoursesShownAggregateFields>;
  nodes: Array<ProgramCoursesShown>;
};

export type ProgramCoursesShownAggregateBoolExp = {
  count?: InputMaybe<ProgramCoursesShownAggregateBoolExpCount>;
};

/** aggregate fields of "program_courses_shown" */
export type ProgramCoursesShownAggregateFields = {
  __typename?: 'ProgramCoursesShownAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<ProgramCoursesShownMaxFields>;
  min?: Maybe<ProgramCoursesShownMinFields>;
};

/** aggregate fields of "program_courses_shown" */
export type ProgramCoursesShownAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ProgramCoursesShownSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "program_courses_shown" */
export type ProgramCoursesShownAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ProgramCoursesShownMaxOrderBy>;
  min?: InputMaybe<ProgramCoursesShownMinOrderBy>;
};

/** input type for inserting array relation for remote table "program_courses_shown" */
export type ProgramCoursesShownArrRelInsertInput = {
  data: Array<ProgramCoursesShownInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ProgramCoursesShownOnConflict>;
};

/** Boolean expression to filter rows from the table "program_courses_shown". All fields are combined with a logical 'AND'. */
export type ProgramCoursesShownBoolExp = {
  _and?: InputMaybe<Array<ProgramCoursesShownBoolExp>>;
  _not?: InputMaybe<ProgramCoursesShownBoolExp>;
  _or?: InputMaybe<Array<ProgramCoursesShownBoolExp>>;
  courseId?: InputMaybe<UuidComparisonExp>;
  programId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "program_courses_shown" */
export enum ProgramCoursesShownConstraint {
  /** unique or primary key constraint on columns "program_id", "course_id" */
  program_courses_shown_pk = 'program_courses_shown_pk'
}

/** input type for inserting data into table "program_courses_shown" */
export type ProgramCoursesShownInsertInput = {
  courseId?: InputMaybe<Scalars['uuid']>;
  programId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ProgramCoursesShownMaxFields = {
  __typename?: 'ProgramCoursesShownMaxFields';
  courseId?: Maybe<Scalars['uuid']>;
  programId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "program_courses_shown" */
export type ProgramCoursesShownMaxOrderBy = {
  courseId?: InputMaybe<OrderBy>;
  programId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ProgramCoursesShownMinFields = {
  __typename?: 'ProgramCoursesShownMinFields';
  courseId?: Maybe<Scalars['uuid']>;
  programId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "program_courses_shown" */
export type ProgramCoursesShownMinOrderBy = {
  courseId?: InputMaybe<OrderBy>;
  programId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "program_courses_shown" */
export type ProgramCoursesShownMutationResponse = {
  __typename?: 'ProgramCoursesShownMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProgramCoursesShown>;
};

/** on_conflict condition type for table "program_courses_shown" */
export type ProgramCoursesShownOnConflict = {
  constraint: ProgramCoursesShownConstraint;
  updateColumns?: Array<ProgramCoursesShownUpdateColumn>;
  where?: InputMaybe<ProgramCoursesShownBoolExp>;
};

/** Ordering options when selecting data from "program_courses_shown". */
export type ProgramCoursesShownOrderBy = {
  courseId?: InputMaybe<OrderBy>;
  programId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: program_courses_shown */
export type ProgramCoursesShownPkColumnsInput = {
  courseId: Scalars['uuid'];
  programId: Scalars['uuid'];
};

/** select columns of table "program_courses_shown" */
export enum ProgramCoursesShownSelectColumn {
  /** column name */
  courseId = 'courseId',
  /** column name */
  programId = 'programId'
}

/** input type for updating data in table "program_courses_shown" */
export type ProgramCoursesShownSetInput = {
  courseId?: InputMaybe<Scalars['uuid']>;
  programId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "program_courses_shown" */
export type ProgramCoursesShownStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProgramCoursesShownStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProgramCoursesShownStreamCursorValueInput = {
  courseId?: InputMaybe<Scalars['uuid']>;
  programId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "program_courses_shown" */
export enum ProgramCoursesShownUpdateColumn {
  /** column name */
  courseId = 'courseId',
  /** column name */
  programId = 'programId'
}

export type ProgramCoursesShownUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProgramCoursesShownSetInput>;
  /** filter the rows which have to be updated */
  where: ProgramCoursesShownBoolExp;
};

/** columns and relationships of "program_tags" */
export type ProgramTags = {
  __typename?: 'ProgramTags';
  programId: Scalars['uuid'];
  tagId: Scalars['uuid'];
};

/** aggregated selection of "program_tags" */
export type ProgramTagsAggregate = {
  __typename?: 'ProgramTagsAggregate';
  aggregate?: Maybe<ProgramTagsAggregateFields>;
  nodes: Array<ProgramTags>;
};

export type ProgramTagsAggregateBoolExp = {
  count?: InputMaybe<ProgramTagsAggregateBoolExpCount>;
};

/** aggregate fields of "program_tags" */
export type ProgramTagsAggregateFields = {
  __typename?: 'ProgramTagsAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<ProgramTagsMaxFields>;
  min?: Maybe<ProgramTagsMinFields>;
};

/** aggregate fields of "program_tags" */
export type ProgramTagsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ProgramTagsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "program_tags" */
export type ProgramTagsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ProgramTagsMaxOrderBy>;
  min?: InputMaybe<ProgramTagsMinOrderBy>;
};

/** input type for inserting array relation for remote table "program_tags" */
export type ProgramTagsArrRelInsertInput = {
  data: Array<ProgramTagsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ProgramTagsOnConflict>;
};

/** Boolean expression to filter rows from the table "program_tags". All fields are combined with a logical 'AND'. */
export type ProgramTagsBoolExp = {
  _and?: InputMaybe<Array<ProgramTagsBoolExp>>;
  _not?: InputMaybe<ProgramTagsBoolExp>;
  _or?: InputMaybe<Array<ProgramTagsBoolExp>>;
  programId?: InputMaybe<UuidComparisonExp>;
  tagId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "program_tags" */
export enum ProgramTagsConstraint {
  /** unique or primary key constraint on columns "program_id", "tag_id" */
  program_tags_pk = 'program_tags_pk'
}

/** input type for inserting data into table "program_tags" */
export type ProgramTagsInsertInput = {
  programId?: InputMaybe<Scalars['uuid']>;
  tagId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ProgramTagsMaxFields = {
  __typename?: 'ProgramTagsMaxFields';
  programId?: Maybe<Scalars['uuid']>;
  tagId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "program_tags" */
export type ProgramTagsMaxOrderBy = {
  programId?: InputMaybe<OrderBy>;
  tagId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ProgramTagsMinFields = {
  __typename?: 'ProgramTagsMinFields';
  programId?: Maybe<Scalars['uuid']>;
  tagId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "program_tags" */
export type ProgramTagsMinOrderBy = {
  programId?: InputMaybe<OrderBy>;
  tagId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "program_tags" */
export type ProgramTagsMutationResponse = {
  __typename?: 'ProgramTagsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProgramTags>;
};

/** on_conflict condition type for table "program_tags" */
export type ProgramTagsOnConflict = {
  constraint: ProgramTagsConstraint;
  updateColumns?: Array<ProgramTagsUpdateColumn>;
  where?: InputMaybe<ProgramTagsBoolExp>;
};

/** Ordering options when selecting data from "program_tags". */
export type ProgramTagsOrderBy = {
  programId?: InputMaybe<OrderBy>;
  tagId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: program_tags */
export type ProgramTagsPkColumnsInput = {
  programId: Scalars['uuid'];
  tagId: Scalars['uuid'];
};

/** select columns of table "program_tags" */
export enum ProgramTagsSelectColumn {
  /** column name */
  programId = 'programId',
  /** column name */
  tagId = 'tagId'
}

/** input type for updating data in table "program_tags" */
export type ProgramTagsSetInput = {
  programId?: InputMaybe<Scalars['uuid']>;
  tagId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "program_tags" */
export type ProgramTagsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProgramTagsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProgramTagsStreamCursorValueInput = {
  programId?: InputMaybe<Scalars['uuid']>;
  tagId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "program_tags" */
export enum ProgramTagsUpdateColumn {
  /** column name */
  programId = 'programId',
  /** column name */
  tagId = 'tagId'
}

export type ProgramTagsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProgramTagsSetInput>;
  /** filter the rows which have to be updated */
  where: ProgramTagsBoolExp;
};

/** columns and relationships of "program_users_selected" */
export type ProgramUsersSelected = {
  __typename?: 'ProgramUsersSelected';
  programId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

/** aggregated selection of "program_users_selected" */
export type ProgramUsersSelectedAggregate = {
  __typename?: 'ProgramUsersSelectedAggregate';
  aggregate?: Maybe<ProgramUsersSelectedAggregateFields>;
  nodes: Array<ProgramUsersSelected>;
};

export type ProgramUsersSelectedAggregateBoolExp = {
  count?: InputMaybe<ProgramUsersSelectedAggregateBoolExpCount>;
};

/** aggregate fields of "program_users_selected" */
export type ProgramUsersSelectedAggregateFields = {
  __typename?: 'ProgramUsersSelectedAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<ProgramUsersSelectedMaxFields>;
  min?: Maybe<ProgramUsersSelectedMinFields>;
};

/** aggregate fields of "program_users_selected" */
export type ProgramUsersSelectedAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ProgramUsersSelectedSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "program_users_selected" */
export type ProgramUsersSelectedAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ProgramUsersSelectedMaxOrderBy>;
  min?: InputMaybe<ProgramUsersSelectedMinOrderBy>;
};

/** input type for inserting array relation for remote table "program_users_selected" */
export type ProgramUsersSelectedArrRelInsertInput = {
  data: Array<ProgramUsersSelectedInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ProgramUsersSelectedOnConflict>;
};

/** Boolean expression to filter rows from the table "program_users_selected". All fields are combined with a logical 'AND'. */
export type ProgramUsersSelectedBoolExp = {
  _and?: InputMaybe<Array<ProgramUsersSelectedBoolExp>>;
  _not?: InputMaybe<ProgramUsersSelectedBoolExp>;
  _or?: InputMaybe<Array<ProgramUsersSelectedBoolExp>>;
  programId?: InputMaybe<UuidComparisonExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "program_users_selected" */
export enum ProgramUsersSelectedConstraint {
  /** unique or primary key constraint on columns "user_id", "program_id" */
  program_users_selected_pk = 'program_users_selected_pk'
}

/** input type for inserting data into table "program_users_selected" */
export type ProgramUsersSelectedInsertInput = {
  programId?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ProgramUsersSelectedMaxFields = {
  __typename?: 'ProgramUsersSelectedMaxFields';
  programId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "program_users_selected" */
export type ProgramUsersSelectedMaxOrderBy = {
  programId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ProgramUsersSelectedMinFields = {
  __typename?: 'ProgramUsersSelectedMinFields';
  programId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "program_users_selected" */
export type ProgramUsersSelectedMinOrderBy = {
  programId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "program_users_selected" */
export type ProgramUsersSelectedMutationResponse = {
  __typename?: 'ProgramUsersSelectedMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProgramUsersSelected>;
};

/** on_conflict condition type for table "program_users_selected" */
export type ProgramUsersSelectedOnConflict = {
  constraint: ProgramUsersSelectedConstraint;
  updateColumns?: Array<ProgramUsersSelectedUpdateColumn>;
  where?: InputMaybe<ProgramUsersSelectedBoolExp>;
};

/** Ordering options when selecting data from "program_users_selected". */
export type ProgramUsersSelectedOrderBy = {
  programId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: program_users_selected */
export type ProgramUsersSelectedPkColumnsInput = {
  programId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

/** select columns of table "program_users_selected" */
export enum ProgramUsersSelectedSelectColumn {
  /** column name */
  programId = 'programId',
  /** column name */
  userId = 'userId'
}

/** input type for updating data in table "program_users_selected" */
export type ProgramUsersSelectedSetInput = {
  programId?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "program_users_selected" */
export type ProgramUsersSelectedStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProgramUsersSelectedStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProgramUsersSelectedStreamCursorValueInput = {
  programId?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "program_users_selected" */
export enum ProgramUsersSelectedUpdateColumn {
  /** column name */
  programId = 'programId',
  /** column name */
  userId = 'userId'
}

export type ProgramUsersSelectedUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProgramUsersSelectedSetInput>;
  /** filter the rows which have to be updated */
  where: ProgramUsersSelectedBoolExp;
};

/** columns and relationships of "programs" */
export type Programs = {
  __typename?: 'Programs';
  /** An array relationship */
  actionsTaken: Array<ProgramActionsTaken>;
  /** An aggregate relationship */
  actionsTakenAggregate: ProgramActionsTakenAggregate;
  /** An array relationship */
  coursesShown: Array<ProgramCoursesShown>;
  /** An aggregate relationship */
  coursesShownAggregate: ProgramCoursesShownAggregate;
  createdAt: Scalars['timestamp'];
  createdById: Scalars['uuid'];
  id: Scalars['uuid'];
  methodology?: Maybe<Scalars['String']>;
  /** An array relationship */
  selectedUsers: Array<ProgramUsersSelected>;
  /** An aggregate relationship */
  selectedUsersAggregate: ProgramUsersSelectedAggregate;
  /** An array relationship */
  tags: Array<ProgramTags>;
  /** An aggregate relationship */
  tagsAggregate: ProgramTagsAggregate;
  timeframe: CourseWaitlistDesiredUrgencyTypeEnum;
};

/** columns and relationships of "programs" */
export type ProgramsActionsTakenArgs = {
  distinctOn?: InputMaybe<Array<ProgramActionsTakenSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramActionsTakenOrderBy>>;
  where?: InputMaybe<ProgramActionsTakenBoolExp>;
};

/** columns and relationships of "programs" */
export type ProgramsActionsTakenAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProgramActionsTakenSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramActionsTakenOrderBy>>;
  where?: InputMaybe<ProgramActionsTakenBoolExp>;
};

/** columns and relationships of "programs" */
export type ProgramsCoursesShownArgs = {
  distinctOn?: InputMaybe<Array<ProgramCoursesShownSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramCoursesShownOrderBy>>;
  where?: InputMaybe<ProgramCoursesShownBoolExp>;
};

/** columns and relationships of "programs" */
export type ProgramsCoursesShownAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProgramCoursesShownSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramCoursesShownOrderBy>>;
  where?: InputMaybe<ProgramCoursesShownBoolExp>;
};

/** columns and relationships of "programs" */
export type ProgramsSelectedUsersArgs = {
  distinctOn?: InputMaybe<Array<ProgramUsersSelectedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramUsersSelectedOrderBy>>;
  where?: InputMaybe<ProgramUsersSelectedBoolExp>;
};

/** columns and relationships of "programs" */
export type ProgramsSelectedUsersAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProgramUsersSelectedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramUsersSelectedOrderBy>>;
  where?: InputMaybe<ProgramUsersSelectedBoolExp>;
};

/** columns and relationships of "programs" */
export type ProgramsTagsArgs = {
  distinctOn?: InputMaybe<Array<ProgramTagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramTagsOrderBy>>;
  where?: InputMaybe<ProgramTagsBoolExp>;
};

/** columns and relationships of "programs" */
export type ProgramsTagsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProgramTagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramTagsOrderBy>>;
  where?: InputMaybe<ProgramTagsBoolExp>;
};

/** aggregated selection of "programs" */
export type ProgramsAggregate = {
  __typename?: 'ProgramsAggregate';
  aggregate?: Maybe<ProgramsAggregateFields>;
  nodes: Array<Programs>;
};

/** aggregate fields of "programs" */
export type ProgramsAggregateFields = {
  __typename?: 'ProgramsAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<ProgramsMaxFields>;
  min?: Maybe<ProgramsMinFields>;
};

/** aggregate fields of "programs" */
export type ProgramsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ProgramsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "programs". All fields are combined with a logical 'AND'. */
export type ProgramsBoolExp = {
  _and?: InputMaybe<Array<ProgramsBoolExp>>;
  _not?: InputMaybe<ProgramsBoolExp>;
  _or?: InputMaybe<Array<ProgramsBoolExp>>;
  actionsTaken?: InputMaybe<ProgramActionsTakenBoolExp>;
  actionsTakenAggregate?: InputMaybe<ProgramActionsTakenAggregateBoolExp>;
  coursesShown?: InputMaybe<ProgramCoursesShownBoolExp>;
  coursesShownAggregate?: InputMaybe<ProgramCoursesShownAggregateBoolExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  methodology?: InputMaybe<StringComparisonExp>;
  selectedUsers?: InputMaybe<ProgramUsersSelectedBoolExp>;
  selectedUsersAggregate?: InputMaybe<ProgramUsersSelectedAggregateBoolExp>;
  tags?: InputMaybe<ProgramTagsBoolExp>;
  tagsAggregate?: InputMaybe<ProgramTagsAggregateBoolExp>;
  timeframe?: InputMaybe<CourseWaitlistDesiredUrgencyTypeEnumComparisonExp>;
};

/** unique or primary key constraints on table "programs" */
export enum ProgramsConstraint {
  /** unique or primary key constraint on columns "id" */
  programs_pk = 'programs_pk'
}

/** input type for inserting data into table "programs" */
export type ProgramsInsertInput = {
  actionsTaken?: InputMaybe<ProgramActionsTakenArrRelInsertInput>;
  coursesShown?: InputMaybe<ProgramCoursesShownArrRelInsertInput>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  methodology?: InputMaybe<Scalars['String']>;
  selectedUsers?: InputMaybe<ProgramUsersSelectedArrRelInsertInput>;
  tags?: InputMaybe<ProgramTagsArrRelInsertInput>;
  timeframe?: InputMaybe<CourseWaitlistDesiredUrgencyTypeEnum>;
};

/** aggregate max on columns */
export type ProgramsMaxFields = {
  __typename?: 'ProgramsMaxFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  methodology?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ProgramsMinFields = {
  __typename?: 'ProgramsMinFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  methodology?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "programs" */
export type ProgramsMutationResponse = {
  __typename?: 'ProgramsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Programs>;
};

/** on_conflict condition type for table "programs" */
export type ProgramsOnConflict = {
  constraint: ProgramsConstraint;
  updateColumns?: Array<ProgramsUpdateColumn>;
  where?: InputMaybe<ProgramsBoolExp>;
};

/** Ordering options when selecting data from "programs". */
export type ProgramsOrderBy = {
  actionsTakenAggregate?: InputMaybe<ProgramActionsTakenAggregateOrderBy>;
  coursesShownAggregate?: InputMaybe<ProgramCoursesShownAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  methodology?: InputMaybe<OrderBy>;
  selectedUsersAggregate?: InputMaybe<ProgramUsersSelectedAggregateOrderBy>;
  tagsAggregate?: InputMaybe<ProgramTagsAggregateOrderBy>;
  timeframe?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: programs */
export type ProgramsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "programs" */
export enum ProgramsSelectColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  id = 'id',
  /** column name */
  methodology = 'methodology',
  /** column name */
  timeframe = 'timeframe'
}

/** input type for updating data in table "programs" */
export type ProgramsSetInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  methodology?: InputMaybe<Scalars['String']>;
  timeframe?: InputMaybe<CourseWaitlistDesiredUrgencyTypeEnum>;
};

/** Streaming cursor of the table "programs" */
export type ProgramsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProgramsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProgramsStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  methodology?: InputMaybe<Scalars['String']>;
  timeframe?: InputMaybe<CourseWaitlistDesiredUrgencyTypeEnum>;
};

/** update columns of table "programs" */
export enum ProgramsUpdateColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  id = 'id',
  /** column name */
  methodology = 'methodology',
  /** column name */
  timeframe = 'timeframe'
}

export type ProgramsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProgramsSetInput>;
  /** filter the rows which have to be updated */
  where: ProgramsBoolExp;
};

/** columns and relationships of "question_type_enum" */
export type QuestionType = {
  __typename?: 'QuestionType';
  value: Scalars['String'];
};

/** aggregated selection of "question_type_enum" */
export type QuestionTypeAggregate = {
  __typename?: 'QuestionTypeAggregate';
  aggregate?: Maybe<QuestionTypeAggregateFields>;
  nodes: Array<QuestionType>;
};

/** aggregate fields of "question_type_enum" */
export type QuestionTypeAggregateFields = {
  __typename?: 'QuestionTypeAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<QuestionTypeMaxFields>;
  min?: Maybe<QuestionTypeMinFields>;
};

/** aggregate fields of "question_type_enum" */
export type QuestionTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<QuestionTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "question_type_enum". All fields are combined with a logical 'AND'. */
export type QuestionTypeBoolExp = {
  _and?: InputMaybe<Array<QuestionTypeBoolExp>>;
  _not?: InputMaybe<QuestionTypeBoolExp>;
  _or?: InputMaybe<Array<QuestionTypeBoolExp>>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "question_type_enum" */
export enum QuestionTypeConstraint {
  /** unique or primary key constraint on columns "value" */
  question_type_enum_pk = 'question_type_enum_pk'
}

export enum QuestionTypeEnum {
  MATCH = 'MATCH',
  MULTI = 'MULTI',
  ORDER = 'ORDER',
  SINGLE = 'SINGLE',
  YES_NO = 'YES_NO'
}

/** Boolean expression to compare columns of type "QuestionTypeEnum". All fields are combined with logical 'AND'. */
export type QuestionTypeEnumComparisonExp = {
  _eq?: InputMaybe<QuestionTypeEnum>;
  _in?: InputMaybe<Array<QuestionTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<QuestionTypeEnum>;
  _nin?: InputMaybe<Array<QuestionTypeEnum>>;
};

/** input type for inserting data into table "question_type_enum" */
export type QuestionTypeInsertInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type QuestionTypeMaxFields = {
  __typename?: 'QuestionTypeMaxFields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type QuestionTypeMinFields = {
  __typename?: 'QuestionTypeMinFields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "question_type_enum" */
export type QuestionTypeMutationResponse = {
  __typename?: 'QuestionTypeMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<QuestionType>;
};

/** input type for inserting object relation for remote table "question_type_enum" */
export type QuestionTypeObjRelInsertInput = {
  data: QuestionTypeInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<QuestionTypeOnConflict>;
};

/** on_conflict condition type for table "question_type_enum" */
export type QuestionTypeOnConflict = {
  constraint: QuestionTypeConstraint;
  updateColumns?: Array<QuestionTypeUpdateColumn>;
  where?: InputMaybe<QuestionTypeBoolExp>;
};

/** Ordering options when selecting data from "question_type_enum". */
export type QuestionTypeOrderBy = {
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: question_type_enum */
export type QuestionTypePkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "question_type_enum" */
export enum QuestionTypeSelectColumn {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "question_type_enum" */
export type QuestionTypeSetInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "QuestionType" */
export type QuestionTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: QuestionTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type QuestionTypeStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "question_type_enum" */
export enum QuestionTypeUpdateColumn {
  /** column name */
  value = 'value'
}

export type QuestionTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<QuestionTypeSetInput>;
  /** filter the rows which have to be updated */
  where: QuestionTypeBoolExp;
};

/** Store activities for the in app recording system */
export type RecordingActivity = {
  __typename?: 'RecordingActivity';
  context?: Maybe<Scalars['jsonb']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  hash: Scalars['String'];
  id: Scalars['uuid'];
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** Store activities for the in app recording system */
export type RecordingActivityContextArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "recording_activity" */
export type RecordingActivityAggregate = {
  __typename?: 'RecordingActivityAggregate';
  aggregate?: Maybe<RecordingActivityAggregateFields>;
  nodes: Array<RecordingActivity>;
};

/** aggregate fields of "recording_activity" */
export type RecordingActivityAggregateFields = {
  __typename?: 'RecordingActivityAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<RecordingActivityMaxFields>;
  min?: Maybe<RecordingActivityMinFields>;
};

/** aggregate fields of "recording_activity" */
export type RecordingActivityAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RecordingActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type RecordingActivityAppendInput = {
  context?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "recording_activity". All fields are combined with a logical 'AND'. */
export type RecordingActivityBoolExp = {
  _and?: InputMaybe<Array<RecordingActivityBoolExp>>;
  _not?: InputMaybe<RecordingActivityBoolExp>;
  _or?: InputMaybe<Array<RecordingActivityBoolExp>>;
  context?: InputMaybe<JsonbComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  hash?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "recording_activity" */
export enum RecordingActivityConstraint {
  /** unique or primary key constraint on columns "user_id", "type", "hash" */
  recording_activity_hash_user_id_type_key = 'recording_activity_hash_user_id_type_key',
  /** unique or primary key constraint on columns "id" */
  recording_activity_pkey = 'recording_activity_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type RecordingActivityDeleteAtPathInput = {
  context?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type RecordingActivityDeleteElemInput = {
  context?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type RecordingActivityDeleteKeyInput = {
  context?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "recording_activity" */
export type RecordingActivityInsertInput = {
  context?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type RecordingActivityMaxFields = {
  __typename?: 'RecordingActivityMaxFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type RecordingActivityMinFields = {
  __typename?: 'RecordingActivityMinFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "recording_activity" */
export type RecordingActivityMutationResponse = {
  __typename?: 'RecordingActivityMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RecordingActivity>;
};

/** on_conflict condition type for table "recording_activity" */
export type RecordingActivityOnConflict = {
  constraint: RecordingActivityConstraint;
  updateColumns?: Array<RecordingActivityUpdateColumn>;
  where?: InputMaybe<RecordingActivityBoolExp>;
};

/** Ordering options when selecting data from "recording_activity". */
export type RecordingActivityOrderBy = {
  context?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  hash?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: recording_activity */
export type RecordingActivityPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type RecordingActivityPrependInput = {
  context?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "recording_activity" */
export enum RecordingActivitySelectColumn {
  /** column name */
  context = 'context',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  hash = 'hash',
  /** column name */
  id = 'id',
  /** column name */
  type = 'type',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  userId = 'userId'
}

/** input type for updating data in table "recording_activity" */
export type RecordingActivitySetInput = {
  context?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "recording_activity" */
export type RecordingActivityStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: RecordingActivityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RecordingActivityStreamCursorValueInput = {
  context?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "recording_activity" */
export enum RecordingActivityUpdateColumn {
  /** column name */
  context = 'context',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  hash = 'hash',
  /** column name */
  id = 'id',
  /** column name */
  type = 'type',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  userId = 'userId'
}

export type RecordingActivityUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<RecordingActivityAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<RecordingActivityDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<RecordingActivityDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<RecordingActivityDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<RecordingActivityPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RecordingActivitySetInput>;
  /** filter the rows which have to be updated */
  where: RecordingActivityBoolExp;
};

/** columns and relationships of "recordings" */
export type Recordings = {
  __typename?: 'Recordings';
  createdAt: Scalars['timestamp'];
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById: Scalars['uuid'];
  description?: Maybe<Scalars['String']>;
  /** A computed field, executes function "recording_transcription" */
  fullTranscription?: Maybe<Scalars['String']>;
  hash: Scalars['String'];
  id: Scalars['uuid'];
  lightningSubmissionId?: Maybe<Scalars['uuid']>;
  meta?: Maybe<Scalars['jsonb']>;
  public: Scalars['Boolean'];
  publicUrl?: Maybe<Scalars['String']>;
  sentToChannel?: Maybe<Scalars['Boolean']>;
  sourceUrl: Scalars['String'];
  summary?: Maybe<Scalars['jsonb']>;
  synced?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  transcription?: Maybe<Scalars['jsonb']>;
  type: Scalars['String'];
};

/** columns and relationships of "recordings" */
export type RecordingsMetaArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "recordings" */
export type RecordingsSummaryArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "recordings" */
export type RecordingsTranscriptionArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "recordings" */
export type RecordingsAggregate = {
  __typename?: 'RecordingsAggregate';
  aggregate?: Maybe<RecordingsAggregateFields>;
  nodes: Array<Recordings>;
};

/** aggregate fields of "recordings" */
export type RecordingsAggregateFields = {
  __typename?: 'RecordingsAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<RecordingsMaxFields>;
  min?: Maybe<RecordingsMinFields>;
};

/** aggregate fields of "recordings" */
export type RecordingsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RecordingsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type RecordingsAppendInput = {
  meta?: InputMaybe<Scalars['jsonb']>;
  summary?: InputMaybe<Scalars['jsonb']>;
  transcription?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "recordings". All fields are combined with a logical 'AND'. */
export type RecordingsBoolExp = {
  _and?: InputMaybe<Array<RecordingsBoolExp>>;
  _not?: InputMaybe<RecordingsBoolExp>;
  _or?: InputMaybe<Array<RecordingsBoolExp>>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  fullTranscription?: InputMaybe<StringComparisonExp>;
  hash?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  lightningSubmissionId?: InputMaybe<UuidComparisonExp>;
  meta?: InputMaybe<JsonbComparisonExp>;
  public?: InputMaybe<BooleanComparisonExp>;
  publicUrl?: InputMaybe<StringComparisonExp>;
  sentToChannel?: InputMaybe<BooleanComparisonExp>;
  sourceUrl?: InputMaybe<StringComparisonExp>;
  summary?: InputMaybe<JsonbComparisonExp>;
  synced?: InputMaybe<BooleanComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  transcription?: InputMaybe<JsonbComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "recordings" */
export enum RecordingsConstraint {
  /** unique or primary key constraint on columns "hash" */
  recordings_hash_uniq = 'recordings_hash_uniq',
  /** unique or primary key constraint on columns "id" */
  recordings_pk = 'recordings_pk'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type RecordingsDeleteAtPathInput = {
  meta?: InputMaybe<Array<Scalars['String']>>;
  summary?: InputMaybe<Array<Scalars['String']>>;
  transcription?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type RecordingsDeleteElemInput = {
  meta?: InputMaybe<Scalars['Int']>;
  summary?: InputMaybe<Scalars['Int']>;
  transcription?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type RecordingsDeleteKeyInput = {
  meta?: InputMaybe<Scalars['String']>;
  summary?: InputMaybe<Scalars['String']>;
  transcription?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "recordings" */
export type RecordingsInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lightningSubmissionId?: InputMaybe<Scalars['uuid']>;
  meta?: InputMaybe<Scalars['jsonb']>;
  public?: InputMaybe<Scalars['Boolean']>;
  publicUrl?: InputMaybe<Scalars['String']>;
  sentToChannel?: InputMaybe<Scalars['Boolean']>;
  sourceUrl?: InputMaybe<Scalars['String']>;
  summary?: InputMaybe<Scalars['jsonb']>;
  synced?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  transcription?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type RecordingsMaxFields = {
  __typename?: 'RecordingsMaxFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  /** A computed field, executes function "recording_transcription" */
  fullTranscription?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lightningSubmissionId?: Maybe<Scalars['uuid']>;
  publicUrl?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type RecordingsMinFields = {
  __typename?: 'RecordingsMinFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  /** A computed field, executes function "recording_transcription" */
  fullTranscription?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lightningSubmissionId?: Maybe<Scalars['uuid']>;
  publicUrl?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "recordings" */
export type RecordingsMutationResponse = {
  __typename?: 'RecordingsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Recordings>;
};

/** on_conflict condition type for table "recordings" */
export type RecordingsOnConflict = {
  constraint: RecordingsConstraint;
  updateColumns?: Array<RecordingsUpdateColumn>;
  where?: InputMaybe<RecordingsBoolExp>;
};

/** Ordering options when selecting data from "recordings". */
export type RecordingsOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  fullTranscription?: InputMaybe<OrderBy>;
  hash?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lightningSubmissionId?: InputMaybe<OrderBy>;
  meta?: InputMaybe<OrderBy>;
  public?: InputMaybe<OrderBy>;
  publicUrl?: InputMaybe<OrderBy>;
  sentToChannel?: InputMaybe<OrderBy>;
  sourceUrl?: InputMaybe<OrderBy>;
  summary?: InputMaybe<OrderBy>;
  synced?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  transcription?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: recordings */
export type RecordingsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type RecordingsPrependInput = {
  meta?: InputMaybe<Scalars['jsonb']>;
  summary?: InputMaybe<Scalars['jsonb']>;
  transcription?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "recordings" */
export enum RecordingsSelectColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  description = 'description',
  /** column name */
  hash = 'hash',
  /** column name */
  id = 'id',
  /** column name */
  lightningSubmissionId = 'lightningSubmissionId',
  /** column name */
  meta = 'meta',
  /** column name */
  public = 'public',
  /** column name */
  publicUrl = 'publicUrl',
  /** column name */
  sentToChannel = 'sentToChannel',
  /** column name */
  sourceUrl = 'sourceUrl',
  /** column name */
  summary = 'summary',
  /** column name */
  synced = 'synced',
  /** column name */
  title = 'title',
  /** column name */
  transcription = 'transcription',
  /** column name */
  type = 'type'
}

/** input type for updating data in table "recordings" */
export type RecordingsSetInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lightningSubmissionId?: InputMaybe<Scalars['uuid']>;
  meta?: InputMaybe<Scalars['jsonb']>;
  public?: InputMaybe<Scalars['Boolean']>;
  publicUrl?: InputMaybe<Scalars['String']>;
  sentToChannel?: InputMaybe<Scalars['Boolean']>;
  sourceUrl?: InputMaybe<Scalars['String']>;
  summary?: InputMaybe<Scalars['jsonb']>;
  synced?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  transcription?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "recordings" */
export type RecordingsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: RecordingsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RecordingsStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lightningSubmissionId?: InputMaybe<Scalars['uuid']>;
  meta?: InputMaybe<Scalars['jsonb']>;
  public?: InputMaybe<Scalars['Boolean']>;
  publicUrl?: InputMaybe<Scalars['String']>;
  sentToChannel?: InputMaybe<Scalars['Boolean']>;
  sourceUrl?: InputMaybe<Scalars['String']>;
  summary?: InputMaybe<Scalars['jsonb']>;
  synced?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  transcription?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "recordings" */
export enum RecordingsUpdateColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  description = 'description',
  /** column name */
  hash = 'hash',
  /** column name */
  id = 'id',
  /** column name */
  lightningSubmissionId = 'lightningSubmissionId',
  /** column name */
  meta = 'meta',
  /** column name */
  public = 'public',
  /** column name */
  publicUrl = 'publicUrl',
  /** column name */
  sentToChannel = 'sentToChannel',
  /** column name */
  sourceUrl = 'sourceUrl',
  /** column name */
  summary = 'summary',
  /** column name */
  synced = 'synced',
  /** column name */
  title = 'title',
  /** column name */
  transcription = 'transcription',
  /** column name */
  type = 'type'
}

export type RecordingsUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<RecordingsAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<RecordingsDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<RecordingsDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<RecordingsDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<RecordingsPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RecordingsSetInput>;
  /** filter the rows which have to be updated */
  where: RecordingsBoolExp;
};

export type RegisterAnonOutput = {
  __typename?: 'RegisterAnonOutput';
  code?: Maybe<Scalars['String']>;
  joinLinkUrl?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** columns and relationships of "related_courses_rel" */
export type RelatedCourses = {
  __typename?: 'RelatedCourses';
  /** An object relationship */
  course?: Maybe<Courses>;
  courseId: Scalars['uuid'];
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  deletedBy?: Maybe<Users>;
  deletedById?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "related_course" */
  relatedCourse?: Maybe<Array<Courses>>;
  relatedCourseSlug: Scalars['String'];
};

/** columns and relationships of "related_courses_rel" */
export type RelatedCoursesRelatedCourseArgs = {
  distinctOn?: InputMaybe<Array<CoursesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesOrderBy>>;
  where?: InputMaybe<CoursesBoolExp>;
};

/** aggregated selection of "related_courses_rel" */
export type RelatedCoursesAggregate = {
  __typename?: 'RelatedCoursesAggregate';
  aggregate?: Maybe<RelatedCoursesAggregateFields>;
  nodes: Array<RelatedCourses>;
};

export type RelatedCoursesAggregateBoolExp = {
  count?: InputMaybe<RelatedCoursesAggregateBoolExpCount>;
};

export type RelatedCoursesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<RelatedCoursesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<RelatedCoursesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "related_courses_rel" */
export type RelatedCoursesAggregateFields = {
  __typename?: 'RelatedCoursesAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<RelatedCoursesMaxFields>;
  min?: Maybe<RelatedCoursesMinFields>;
};

/** aggregate fields of "related_courses_rel" */
export type RelatedCoursesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RelatedCoursesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "related_courses_rel" */
export type RelatedCoursesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<RelatedCoursesMaxOrderBy>;
  min?: InputMaybe<RelatedCoursesMinOrderBy>;
};

/** input type for inserting array relation for remote table "related_courses_rel" */
export type RelatedCoursesArrRelInsertInput = {
  data: Array<RelatedCoursesInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<RelatedCoursesOnConflict>;
};

/** Boolean expression to filter rows from the table "related_courses_rel". All fields are combined with a logical 'AND'. */
export type RelatedCoursesBoolExp = {
  _and?: InputMaybe<Array<RelatedCoursesBoolExp>>;
  _not?: InputMaybe<RelatedCoursesBoolExp>;
  _or?: InputMaybe<Array<RelatedCoursesBoolExp>>;
  course?: InputMaybe<CoursesBoolExp>;
  courseId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  deletedAt?: InputMaybe<TimestampComparisonExp>;
  deletedBy?: InputMaybe<UsersBoolExp>;
  deletedById?: InputMaybe<UuidComparisonExp>;
  relatedCourse?: InputMaybe<CoursesBoolExp>;
  relatedCourseSlug?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "related_courses_rel" */
export enum RelatedCoursesConstraint {
  /** unique or primary key constraint on columns "related_course_slug", "course_id" */
  related_courses_rel_pk = 'related_courses_rel_pk'
}

/** input type for inserting data into table "related_courses_rel" */
export type RelatedCoursesInsertInput = {
  course?: InputMaybe<CoursesObjRelInsertInput>;
  courseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedBy?: InputMaybe<UsersObjRelInsertInput>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  relatedCourseSlug?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type RelatedCoursesMaxFields = {
  __typename?: 'RelatedCoursesMaxFields';
  courseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  relatedCourseSlug?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "related_courses_rel" */
export type RelatedCoursesMaxOrderBy = {
  courseId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  relatedCourseSlug?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type RelatedCoursesMinFields = {
  __typename?: 'RelatedCoursesMinFields';
  courseId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  relatedCourseSlug?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "related_courses_rel" */
export type RelatedCoursesMinOrderBy = {
  courseId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  relatedCourseSlug?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "related_courses_rel" */
export type RelatedCoursesMutationResponse = {
  __typename?: 'RelatedCoursesMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RelatedCourses>;
};

/** on_conflict condition type for table "related_courses_rel" */
export type RelatedCoursesOnConflict = {
  constraint: RelatedCoursesConstraint;
  updateColumns?: Array<RelatedCoursesUpdateColumn>;
  where?: InputMaybe<RelatedCoursesBoolExp>;
};

/** Ordering options when selecting data from "related_courses_rel". */
export type RelatedCoursesOrderBy = {
  course?: InputMaybe<CoursesOrderBy>;
  courseId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedBy?: InputMaybe<UsersOrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  relatedCourseAggregate?: InputMaybe<CoursesAggregateOrderBy>;
  relatedCourseSlug?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: related_courses_rel */
export type RelatedCoursesPkColumnsInput = {
  courseId: Scalars['uuid'];
  relatedCourseSlug: Scalars['String'];
};

/** select columns of table "related_courses_rel" */
export enum RelatedCoursesSelectColumn {
  /** column name */
  courseId = 'courseId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  relatedCourseSlug = 'relatedCourseSlug'
}

/** input type for updating data in table "related_courses_rel" */
export type RelatedCoursesSetInput = {
  courseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  relatedCourseSlug?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "RelatedCourses" */
export type RelatedCoursesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: RelatedCoursesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RelatedCoursesStreamCursorValueInput = {
  courseId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  relatedCourseSlug?: InputMaybe<Scalars['String']>;
};

/** update columns of table "related_courses_rel" */
export enum RelatedCoursesUpdateColumn {
  /** column name */
  courseId = 'courseId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  relatedCourseSlug = 'relatedCourseSlug'
}

export type RelatedCoursesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RelatedCoursesSetInput>;
  /** filter the rows which have to be updated */
  where: RelatedCoursesBoolExp;
};

/** Types of report publish status */
export type ReportPublishStatus = {
  __typename?: 'ReportPublishStatus';
  value: Scalars['String'];
};

/** aggregated selection of "report_publish_status_enum" */
export type ReportPublishStatusAggregate = {
  __typename?: 'ReportPublishStatusAggregate';
  aggregate?: Maybe<ReportPublishStatusAggregateFields>;
  nodes: Array<ReportPublishStatus>;
};

/** aggregate fields of "report_publish_status_enum" */
export type ReportPublishStatusAggregateFields = {
  __typename?: 'ReportPublishStatusAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<ReportPublishStatusMaxFields>;
  min?: Maybe<ReportPublishStatusMinFields>;
};

/** aggregate fields of "report_publish_status_enum" */
export type ReportPublishStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ReportPublishStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "report_publish_status_enum". All fields are combined with a logical 'AND'. */
export type ReportPublishStatusBoolExp = {
  _and?: InputMaybe<Array<ReportPublishStatusBoolExp>>;
  _not?: InputMaybe<ReportPublishStatusBoolExp>;
  _or?: InputMaybe<Array<ReportPublishStatusBoolExp>>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "report_publish_status_enum" */
export enum ReportPublishStatusConstraint {
  /** unique or primary key constraint on columns "value" */
  report_publish_status_enum_pkey = 'report_publish_status_enum_pkey'
}

export enum ReportPublishStatusEnum {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED'
}

/** Boolean expression to compare columns of type "ReportPublishStatusEnum". All fields are combined with logical 'AND'. */
export type ReportPublishStatusEnumComparisonExp = {
  _eq?: InputMaybe<ReportPublishStatusEnum>;
  _in?: InputMaybe<Array<ReportPublishStatusEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ReportPublishStatusEnum>;
  _nin?: InputMaybe<Array<ReportPublishStatusEnum>>;
};

/** input type for inserting data into table "report_publish_status_enum" */
export type ReportPublishStatusInsertInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ReportPublishStatusMaxFields = {
  __typename?: 'ReportPublishStatusMaxFields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ReportPublishStatusMinFields = {
  __typename?: 'ReportPublishStatusMinFields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "report_publish_status_enum" */
export type ReportPublishStatusMutationResponse = {
  __typename?: 'ReportPublishStatusMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ReportPublishStatus>;
};

/** on_conflict condition type for table "report_publish_status_enum" */
export type ReportPublishStatusOnConflict = {
  constraint: ReportPublishStatusConstraint;
  updateColumns?: Array<ReportPublishStatusUpdateColumn>;
  where?: InputMaybe<ReportPublishStatusBoolExp>;
};

/** Ordering options when selecting data from "report_publish_status_enum". */
export type ReportPublishStatusOrderBy = {
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: report_publish_status_enum */
export type ReportPublishStatusPkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "report_publish_status_enum" */
export enum ReportPublishStatusSelectColumn {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "report_publish_status_enum" */
export type ReportPublishStatusSetInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ReportPublishStatus" */
export type ReportPublishStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ReportPublishStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ReportPublishStatusStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "report_publish_status_enum" */
export enum ReportPublishStatusUpdateColumn {
  /** column name */
  value = 'value'
}

export type ReportPublishStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReportPublishStatusSetInput>;
  /** filter the rows which have to be updated */
  where: ReportPublishStatusBoolExp;
};

/** Types of supported reports */
export type ReportType = {
  __typename?: 'ReportType';
  value: Scalars['String'];
};

/** aggregated selection of "report_type_enum" */
export type ReportTypeAggregate = {
  __typename?: 'ReportTypeAggregate';
  aggregate?: Maybe<ReportTypeAggregateFields>;
  nodes: Array<ReportType>;
};

/** aggregate fields of "report_type_enum" */
export type ReportTypeAggregateFields = {
  __typename?: 'ReportTypeAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<ReportTypeMaxFields>;
  min?: Maybe<ReportTypeMinFields>;
};

/** aggregate fields of "report_type_enum" */
export type ReportTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ReportTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "report_type_enum". All fields are combined with a logical 'AND'. */
export type ReportTypeBoolExp = {
  _and?: InputMaybe<Array<ReportTypeBoolExp>>;
  _not?: InputMaybe<ReportTypeBoolExp>;
  _or?: InputMaybe<Array<ReportTypeBoolExp>>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "report_type_enum" */
export enum ReportTypeConstraint {
  /** unique or primary key constraint on columns "value" */
  report_type_enum_pkey = 'report_type_enum_pkey'
}

export enum ReportTypeEnum {
  PERSONAL = 'PERSONAL',
  TEAM = 'TEAM'
}

/** Boolean expression to compare columns of type "ReportTypeEnum". All fields are combined with logical 'AND'. */
export type ReportTypeEnumComparisonExp = {
  _eq?: InputMaybe<ReportTypeEnum>;
  _in?: InputMaybe<Array<ReportTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ReportTypeEnum>;
  _nin?: InputMaybe<Array<ReportTypeEnum>>;
};

/** input type for inserting data into table "report_type_enum" */
export type ReportTypeInsertInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ReportTypeMaxFields = {
  __typename?: 'ReportTypeMaxFields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ReportTypeMinFields = {
  __typename?: 'ReportTypeMinFields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "report_type_enum" */
export type ReportTypeMutationResponse = {
  __typename?: 'ReportTypeMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ReportType>;
};

/** on_conflict condition type for table "report_type_enum" */
export type ReportTypeOnConflict = {
  constraint: ReportTypeConstraint;
  updateColumns?: Array<ReportTypeUpdateColumn>;
  where?: InputMaybe<ReportTypeBoolExp>;
};

/** Ordering options when selecting data from "report_type_enum". */
export type ReportTypeOrderBy = {
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: report_type_enum */
export type ReportTypePkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "report_type_enum" */
export enum ReportTypeSelectColumn {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "report_type_enum" */
export type ReportTypeSetInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ReportType" */
export type ReportTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ReportTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ReportTypeStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "report_type_enum" */
export enum ReportTypeUpdateColumn {
  /** column name */
  value = 'value'
}

export type ReportTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReportTypeSetInput>;
  /** filter the rows which have to be updated */
  where: ReportTypeBoolExp;
};

/** columns and relationships of "reports" */
export type Reports = {
  __typename?: 'Reports';
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  desc: Scalars['String'];
  embedPath: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  publishStatus: ReportPublishStatusEnum;
  reportRefUrl: Scalars['String'];
  slug: Scalars['String'];
  type: ReportTypeEnum;
  updatedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  updatedBy?: Maybe<Users>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "reports" */
export type ReportsAggregate = {
  __typename?: 'ReportsAggregate';
  aggregate?: Maybe<ReportsAggregateFields>;
  nodes: Array<Reports>;
};

/** aggregate fields of "reports" */
export type ReportsAggregateFields = {
  __typename?: 'ReportsAggregateFields';
  avg?: Maybe<ReportsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ReportsMaxFields>;
  min?: Maybe<ReportsMinFields>;
  stddev?: Maybe<ReportsStddevFields>;
  stddevPop?: Maybe<ReportsStddevPopFields>;
  stddevSamp?: Maybe<ReportsStddevSampFields>;
  sum?: Maybe<ReportsSumFields>;
  varPop?: Maybe<ReportsVarPopFields>;
  varSamp?: Maybe<ReportsVarSampFields>;
  variance?: Maybe<ReportsVarianceFields>;
};

/** aggregate fields of "reports" */
export type ReportsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ReportsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ReportsAvgFields = {
  __typename?: 'ReportsAvgFields';
  height?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "reports". All fields are combined with a logical 'AND'. */
export type ReportsBoolExp = {
  _and?: InputMaybe<Array<ReportsBoolExp>>;
  _not?: InputMaybe<ReportsBoolExp>;
  _or?: InputMaybe<Array<ReportsBoolExp>>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  desc?: InputMaybe<StringComparisonExp>;
  embedPath?: InputMaybe<StringComparisonExp>;
  height?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  publishStatus?: InputMaybe<ReportPublishStatusEnumComparisonExp>;
  reportRefUrl?: InputMaybe<StringComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<ReportTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  updatedBy?: InputMaybe<UsersBoolExp>;
  updatedById?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "reports" */
export enum ReportsConstraint {
  /** unique or primary key constraint on columns "id" */
  reports_pk = 'reports_pk',
  /** unique or primary key constraint on columns "slug" */
  reports_uniq_version = 'reports_uniq_version'
}

/** input type for incrementing numeric columns in table "reports" */
export type ReportsIncInput = {
  height?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "reports" */
export type ReportsInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  desc?: InputMaybe<Scalars['String']>;
  embedPath?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  publishStatus?: InputMaybe<ReportPublishStatusEnum>;
  reportRefUrl?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ReportTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<UsersObjRelInsertInput>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ReportsMaxFields = {
  __typename?: 'ReportsMaxFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  desc?: Maybe<Scalars['String']>;
  embedPath?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  reportRefUrl?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ReportsMinFields = {
  __typename?: 'ReportsMinFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  desc?: Maybe<Scalars['String']>;
  embedPath?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  reportRefUrl?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "reports" */
export type ReportsMutationResponse = {
  __typename?: 'ReportsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reports>;
};

/** on_conflict condition type for table "reports" */
export type ReportsOnConflict = {
  constraint: ReportsConstraint;
  updateColumns?: Array<ReportsUpdateColumn>;
  where?: InputMaybe<ReportsBoolExp>;
};

/** Ordering options when selecting data from "reports". */
export type ReportsOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  desc?: InputMaybe<OrderBy>;
  embedPath?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  publishStatus?: InputMaybe<OrderBy>;
  reportRefUrl?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<UsersOrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: reports */
export type ReportsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "reports" */
export enum ReportsSelectColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  desc = 'desc',
  /** column name */
  embedPath = 'embedPath',
  /** column name */
  height = 'height',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  publishStatus = 'publishStatus',
  /** column name */
  reportRefUrl = 'reportRefUrl',
  /** column name */
  slug = 'slug',
  /** column name */
  type = 'type',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById'
}

/** input type for updating data in table "reports" */
export type ReportsSetInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  desc?: InputMaybe<Scalars['String']>;
  embedPath?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  publishStatus?: InputMaybe<ReportPublishStatusEnum>;
  reportRefUrl?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ReportTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type ReportsStddevFields = {
  __typename?: 'ReportsStddevFields';
  height?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type ReportsStddevPopFields = {
  __typename?: 'ReportsStddevPopFields';
  height?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type ReportsStddevSampFields = {
  __typename?: 'ReportsStddevSampFields';
  height?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "reports" */
export type ReportsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ReportsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ReportsStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  desc?: InputMaybe<Scalars['String']>;
  embedPath?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  publishStatus?: InputMaybe<ReportPublishStatusEnum>;
  reportRefUrl?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ReportTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type ReportsSumFields = {
  __typename?: 'ReportsSumFields';
  height?: Maybe<Scalars['Int']>;
};

/** update columns of table "reports" */
export enum ReportsUpdateColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  desc = 'desc',
  /** column name */
  embedPath = 'embedPath',
  /** column name */
  height = 'height',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  publishStatus = 'publishStatus',
  /** column name */
  reportRefUrl = 'reportRefUrl',
  /** column name */
  slug = 'slug',
  /** column name */
  type = 'type',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById'
}

export type ReportsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ReportsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReportsSetInput>;
  /** filter the rows which have to be updated */
  where: ReportsBoolExp;
};

/** aggregate varPop on columns */
export type ReportsVarPopFields = {
  __typename?: 'ReportsVarPopFields';
  height?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type ReportsVarSampFields = {
  __typename?: 'ReportsVarSampFields';
  height?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ReportsVarianceFields = {
  __typename?: 'ReportsVarianceFields';
  height?: Maybe<Scalars['Float']>;
};

/** resource types */
export type ResourceType = {
  __typename?: 'ResourceType';
  value: Scalars['String'];
};

/** aggregated selection of "resource_type_enum" */
export type ResourceTypeAggregate = {
  __typename?: 'ResourceTypeAggregate';
  aggregate?: Maybe<ResourceTypeAggregateFields>;
  nodes: Array<ResourceType>;
};

/** aggregate fields of "resource_type_enum" */
export type ResourceTypeAggregateFields = {
  __typename?: 'ResourceTypeAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<ResourceTypeMaxFields>;
  min?: Maybe<ResourceTypeMinFields>;
};

/** aggregate fields of "resource_type_enum" */
export type ResourceTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ResourceTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "resource_type_enum". All fields are combined with a logical 'AND'. */
export type ResourceTypeBoolExp = {
  _and?: InputMaybe<Array<ResourceTypeBoolExp>>;
  _not?: InputMaybe<ResourceTypeBoolExp>;
  _or?: InputMaybe<Array<ResourceTypeBoolExp>>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "resource_type_enum" */
export enum ResourceTypeConstraint {
  /** unique or primary key constraint on columns "value" */
  resource_type_enum_pkey = 'resource_type_enum_pkey'
}

export enum ResourceTypeEnum {
  CLASS_DECK = 'CLASS_DECK',
  COURSE_SUMMARY = 'COURSE_SUMMARY',
  MANAGER_RESOURCE = 'MANAGER_RESOURCE',
  RECOMMENDED_RESOURCES = 'RECOMMENDED_RESOURCES',
  SESSION_RESOURCE = 'SESSION_RESOURCE',
  TRANSCRIPT = 'TRANSCRIPT'
}

/** Boolean expression to compare columns of type "ResourceTypeEnum". All fields are combined with logical 'AND'. */
export type ResourceTypeEnumComparisonExp = {
  _eq?: InputMaybe<ResourceTypeEnum>;
  _in?: InputMaybe<Array<ResourceTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ResourceTypeEnum>;
  _nin?: InputMaybe<Array<ResourceTypeEnum>>;
};

/** input type for inserting data into table "resource_type_enum" */
export type ResourceTypeInsertInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ResourceTypeMaxFields = {
  __typename?: 'ResourceTypeMaxFields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ResourceTypeMinFields = {
  __typename?: 'ResourceTypeMinFields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "resource_type_enum" */
export type ResourceTypeMutationResponse = {
  __typename?: 'ResourceTypeMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ResourceType>;
};

/** on_conflict condition type for table "resource_type_enum" */
export type ResourceTypeOnConflict = {
  constraint: ResourceTypeConstraint;
  updateColumns?: Array<ResourceTypeUpdateColumn>;
  where?: InputMaybe<ResourceTypeBoolExp>;
};

/** Ordering options when selecting data from "resource_type_enum". */
export type ResourceTypeOrderBy = {
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: resource_type_enum */
export type ResourceTypePkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "resource_type_enum" */
export enum ResourceTypeSelectColumn {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "resource_type_enum" */
export type ResourceTypeSetInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ResourceType" */
export type ResourceTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ResourceTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ResourceTypeStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "resource_type_enum" */
export enum ResourceTypeUpdateColumn {
  /** column name */
  value = 'value'
}

export type ResourceTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ResourceTypeSetInput>;
  /** filter the rows which have to be updated */
  where: ResourceTypeBoolExp;
};

/** columns and relationships of "resources" */
export type Resources = {
  __typename?: 'Resources';
  contentType: Scalars['String'];
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  descriptionRich?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  resourceClass?: Maybe<ClassResources>;
  /** An object relationship */
  resourceCohort?: Maybe<CohortsResourcesRel>;
  /** An object relationship */
  resourceCourse?: Maybe<CoursesResourcesRel>;
  slug: Scalars['String'];
  type: ResourceTypeEnum;
  updatedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  updatedBy?: Maybe<Users>;
  updatedById?: Maybe<Scalars['uuid']>;
  url: Scalars['String'];
  version?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "resources" */
export type ResourcesAggregate = {
  __typename?: 'ResourcesAggregate';
  aggregate?: Maybe<ResourcesAggregateFields>;
  nodes: Array<Resources>;
};

/** aggregate fields of "resources" */
export type ResourcesAggregateFields = {
  __typename?: 'ResourcesAggregateFields';
  avg?: Maybe<ResourcesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ResourcesMaxFields>;
  min?: Maybe<ResourcesMinFields>;
  stddev?: Maybe<ResourcesStddevFields>;
  stddevPop?: Maybe<ResourcesStddevPopFields>;
  stddevSamp?: Maybe<ResourcesStddevSampFields>;
  sum?: Maybe<ResourcesSumFields>;
  varPop?: Maybe<ResourcesVarPopFields>;
  varSamp?: Maybe<ResourcesVarSampFields>;
  variance?: Maybe<ResourcesVarianceFields>;
};

/** aggregate fields of "resources" */
export type ResourcesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ResourcesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ResourcesAvgFields = {
  __typename?: 'ResourcesAvgFields';
  version?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "resources". All fields are combined with a logical 'AND'. */
export type ResourcesBoolExp = {
  _and?: InputMaybe<Array<ResourcesBoolExp>>;
  _not?: InputMaybe<ResourcesBoolExp>;
  _or?: InputMaybe<Array<ResourcesBoolExp>>;
  contentType?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  descriptionRich?: InputMaybe<StringComparisonExp>;
  filename?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  resourceClass?: InputMaybe<ClassResourcesBoolExp>;
  resourceCohort?: InputMaybe<CohortsResourcesRelBoolExp>;
  resourceCourse?: InputMaybe<CoursesResourcesRelBoolExp>;
  slug?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<ResourceTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  updatedBy?: InputMaybe<UsersBoolExp>;
  updatedById?: InputMaybe<UuidComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
  version?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "resources" */
export enum ResourcesConstraint {
  /** unique or primary key constraint on columns "id" */
  resources_pk = 'resources_pk',
  /** unique or primary key constraint on columns "slug", "version" */
  resources_slug_version_key = 'resources_slug_version_key'
}

/** input type for incrementing numeric columns in table "resources" */
export type ResourcesIncInput = {
  version?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "resources" */
export type ResourcesInsertInput = {
  contentType?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  descriptionRich?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  resourceClass?: InputMaybe<ClassResourcesObjRelInsertInput>;
  resourceCohort?: InputMaybe<CohortsResourcesRelObjRelInsertInput>;
  resourceCourse?: InputMaybe<CoursesResourcesRelObjRelInsertInput>;
  slug?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ResourceTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<UsersObjRelInsertInput>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  url?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ResourcesMaxFields = {
  __typename?: 'ResourcesMaxFields';
  contentType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  descriptionRich?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ResourcesMinFields = {
  __typename?: 'ResourcesMinFields';
  contentType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  descriptionRich?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "resources" */
export type ResourcesMutationResponse = {
  __typename?: 'ResourcesMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Resources>;
};

/** input type for inserting object relation for remote table "resources" */
export type ResourcesObjRelInsertInput = {
  data: ResourcesInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ResourcesOnConflict>;
};

/** on_conflict condition type for table "resources" */
export type ResourcesOnConflict = {
  constraint: ResourcesConstraint;
  updateColumns?: Array<ResourcesUpdateColumn>;
  where?: InputMaybe<ResourcesBoolExp>;
};

/** Ordering options when selecting data from "resources". */
export type ResourcesOrderBy = {
  contentType?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  descriptionRich?: InputMaybe<OrderBy>;
  filename?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  resourceClass?: InputMaybe<ClassResourcesOrderBy>;
  resourceCohort?: InputMaybe<CohortsResourcesRelOrderBy>;
  resourceCourse?: InputMaybe<CoursesResourcesRelOrderBy>;
  slug?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<UsersOrderBy>;
  updatedById?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: resources */
export type ResourcesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "resources" */
export enum ResourcesSelectColumn {
  /** column name */
  contentType = 'contentType',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  descriptionRich = 'descriptionRich',
  /** column name */
  filename = 'filename',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  slug = 'slug',
  /** column name */
  type = 'type',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById',
  /** column name */
  url = 'url',
  /** column name */
  version = 'version'
}

/** input type for updating data in table "resources" */
export type ResourcesSetInput = {
  contentType?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  descriptionRich?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ResourceTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  url?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ResourcesStddevFields = {
  __typename?: 'ResourcesStddevFields';
  version?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type ResourcesStddevPopFields = {
  __typename?: 'ResourcesStddevPopFields';
  version?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type ResourcesStddevSampFields = {
  __typename?: 'ResourcesStddevSampFields';
  version?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "resources" */
export type ResourcesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ResourcesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ResourcesStreamCursorValueInput = {
  contentType?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  descriptionRich?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ResourceTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  url?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ResourcesSumFields = {
  __typename?: 'ResourcesSumFields';
  version?: Maybe<Scalars['Int']>;
};

/** update columns of table "resources" */
export enum ResourcesUpdateColumn {
  /** column name */
  contentType = 'contentType',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  descriptionRich = 'descriptionRich',
  /** column name */
  filename = 'filename',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  slug = 'slug',
  /** column name */
  type = 'type',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById',
  /** column name */
  url = 'url',
  /** column name */
  version = 'version'
}

export type ResourcesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ResourcesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ResourcesSetInput>;
  /** filter the rows which have to be updated */
  where: ResourcesBoolExp;
};

/** aggregate varPop on columns */
export type ResourcesVarPopFields = {
  __typename?: 'ResourcesVarPopFields';
  version?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type ResourcesVarSampFields = {
  __typename?: 'ResourcesVarSampFields';
  version?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ResourcesVarianceFields = {
  __typename?: 'ResourcesVarianceFields';
  version?: Maybe<Scalars['Float']>;
};

export type ResumeAssessmentOutput = {
  __typename?: 'ResumeAssessmentOutput';
  id: Scalars['uuid'];
  remainingTime?: Maybe<Scalars['bigint']>;
};

export type RoomInput = {
  coach: ParticipantInput;
  learners: Array<InputMaybe<ParticipantInput>>;
  roomName: Scalars['String'];
};

export type RunPlayOutput = {
  __typename?: 'RunPlayOutput';
  success: Scalars['Boolean'];
};

export type SampleOutput = {
  __typename?: 'SampleOutput';
  assumeSessionUrl: Scalars['String'];
};

export type SaveAssessmentOutput = {
  __typename?: 'SaveAssessmentOutput';
  id: Scalars['uuid'];
  remainingTime?: Maybe<Scalars['bigint']>;
  startedAt?: Maybe<Scalars['timestamp']>;
  status?: Maybe<Scalars['String']>;
};

/** columns and relationships of "schools" */
export type Schools = {
  __typename?: 'Schools';
  color?: Maybe<Scalars['bpchar']>;
  /** An array relationship */
  courses: Array<Courses>;
  /** An aggregate relationship */
  coursesAggregate: CoursesAggregate;
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  deletedBy?: Maybe<Users>;
  deletedById?: Maybe<Scalars['uuid']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  updatedBy?: Maybe<Users>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "schools" */
export type SchoolsCoursesArgs = {
  distinctOn?: InputMaybe<Array<CoursesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesOrderBy>>;
  where?: InputMaybe<CoursesBoolExp>;
};

/** columns and relationships of "schools" */
export type SchoolsCoursesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CoursesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesOrderBy>>;
  where?: InputMaybe<CoursesBoolExp>;
};

/** aggregated selection of "schools" */
export type SchoolsAggregate = {
  __typename?: 'SchoolsAggregate';
  aggregate?: Maybe<SchoolsAggregateFields>;
  nodes: Array<Schools>;
};

/** aggregate fields of "schools" */
export type SchoolsAggregateFields = {
  __typename?: 'SchoolsAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<SchoolsMaxFields>;
  min?: Maybe<SchoolsMinFields>;
};

/** aggregate fields of "schools" */
export type SchoolsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SchoolsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "schools". All fields are combined with a logical 'AND'. */
export type SchoolsBoolExp = {
  _and?: InputMaybe<Array<SchoolsBoolExp>>;
  _not?: InputMaybe<SchoolsBoolExp>;
  _or?: InputMaybe<Array<SchoolsBoolExp>>;
  color?: InputMaybe<BpcharComparisonExp>;
  courses?: InputMaybe<CoursesBoolExp>;
  coursesAggregate?: InputMaybe<CoursesAggregateBoolExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  deletedAt?: InputMaybe<TimestampComparisonExp>;
  deletedBy?: InputMaybe<UsersBoolExp>;
  deletedById?: InputMaybe<UuidComparisonExp>;
  iconUrl?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  updatedBy?: InputMaybe<UsersBoolExp>;
  updatedById?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "schools" */
export enum SchoolsConstraint {
  /** unique or primary key constraint on columns "id" */
  school_pk = 'school_pk',
  /** unique or primary key constraint on columns "slug" */
  schools_slug_key = 'schools_slug_key'
}

/** input type for inserting data into table "schools" */
export type SchoolsInsertInput = {
  color?: InputMaybe<Scalars['bpchar']>;
  courses?: InputMaybe<CoursesArrRelInsertInput>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedBy?: InputMaybe<UsersObjRelInsertInput>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<UsersObjRelInsertInput>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type SchoolsMaxFields = {
  __typename?: 'SchoolsMaxFields';
  color?: Maybe<Scalars['bpchar']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  iconUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type SchoolsMinFields = {
  __typename?: 'SchoolsMinFields';
  color?: Maybe<Scalars['bpchar']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  iconUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "schools" */
export type SchoolsMutationResponse = {
  __typename?: 'SchoolsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Schools>;
};

/** input type for inserting object relation for remote table "schools" */
export type SchoolsObjRelInsertInput = {
  data: SchoolsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<SchoolsOnConflict>;
};

/** on_conflict condition type for table "schools" */
export type SchoolsOnConflict = {
  constraint: SchoolsConstraint;
  updateColumns?: Array<SchoolsUpdateColumn>;
  where?: InputMaybe<SchoolsBoolExp>;
};

/** Ordering options when selecting data from "schools". */
export type SchoolsOrderBy = {
  color?: InputMaybe<OrderBy>;
  coursesAggregate?: InputMaybe<CoursesAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedBy?: InputMaybe<UsersOrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  iconUrl?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<UsersOrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: schools */
export type SchoolsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "schools" */
export enum SchoolsSelectColumn {
  /** column name */
  color = 'color',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  iconUrl = 'iconUrl',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  slug = 'slug',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById'
}

/** input type for updating data in table "schools" */
export type SchoolsSetInput = {
  color?: InputMaybe<Scalars['bpchar']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "schools" */
export type SchoolsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SchoolsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SchoolsStreamCursorValueInput = {
  color?: InputMaybe<Scalars['bpchar']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "schools" */
export enum SchoolsUpdateColumn {
  /** column name */
  color = 'color',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  iconUrl = 'iconUrl',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  slug = 'slug',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById'
}

export type SchoolsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SchoolsSetInput>;
  /** filter the rows which have to be updated */
  where: SchoolsBoolExp;
};

/** columns and relationships of "seat_type_enum" */
export type SeatType = {
  __typename?: 'SeatType';
  value: Scalars['String'];
};

/** aggregated selection of "seat_type_enum" */
export type SeatTypeAggregate = {
  __typename?: 'SeatTypeAggregate';
  aggregate?: Maybe<SeatTypeAggregateFields>;
  nodes: Array<SeatType>;
};

/** aggregate fields of "seat_type_enum" */
export type SeatTypeAggregateFields = {
  __typename?: 'SeatTypeAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<SeatTypeMaxFields>;
  min?: Maybe<SeatTypeMinFields>;
};

/** aggregate fields of "seat_type_enum" */
export type SeatTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SeatTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "seat_type_enum". All fields are combined with a logical 'AND'. */
export type SeatTypeBoolExp = {
  _and?: InputMaybe<Array<SeatTypeBoolExp>>;
  _not?: InputMaybe<SeatTypeBoolExp>;
  _or?: InputMaybe<Array<SeatTypeBoolExp>>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "seat_type_enum" */
export enum SeatTypeConstraint {
  /** unique or primary key constraint on columns "value" */
  seat_type_enum_pk = 'seat_type_enum_pk'
}

export enum SeatTypeEnum {
  FULL = 'FULL',
  LIMITED = 'LIMITED'
}

/** Boolean expression to compare columns of type "SeatTypeEnum". All fields are combined with logical 'AND'. */
export type SeatTypeEnumComparisonExp = {
  _eq?: InputMaybe<SeatTypeEnum>;
  _in?: InputMaybe<Array<SeatTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<SeatTypeEnum>;
  _nin?: InputMaybe<Array<SeatTypeEnum>>;
};

/** input type for inserting data into table "seat_type_enum" */
export type SeatTypeInsertInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type SeatTypeMaxFields = {
  __typename?: 'SeatTypeMaxFields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type SeatTypeMinFields = {
  __typename?: 'SeatTypeMinFields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "seat_type_enum" */
export type SeatTypeMutationResponse = {
  __typename?: 'SeatTypeMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SeatType>;
};

/** on_conflict condition type for table "seat_type_enum" */
export type SeatTypeOnConflict = {
  constraint: SeatTypeConstraint;
  updateColumns?: Array<SeatTypeUpdateColumn>;
  where?: InputMaybe<SeatTypeBoolExp>;
};

/** Ordering options when selecting data from "seat_type_enum". */
export type SeatTypeOrderBy = {
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: seat_type_enum */
export type SeatTypePkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "seat_type_enum" */
export enum SeatTypeSelectColumn {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "seat_type_enum" */
export type SeatTypeSetInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "SeatType" */
export type SeatTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SeatTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SeatTypeStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "seat_type_enum" */
export enum SeatTypeUpdateColumn {
  /** column name */
  value = 'value'
}

export type SeatTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SeatTypeSetInput>;
  /** filter the rows which have to be updated */
  where: SeatTypeBoolExp;
};

/** columns and relationships of "seats" */
export type Seats = {
  __typename?: 'Seats';
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  deletedBy?: Maybe<Users>;
  deletedById?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** An object relationship */
  organization?: Maybe<Organizations>;
  organizationId?: Maybe<Scalars['uuid']>;
  type?: Maybe<SeatTypeEnum>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  updatedBy?: Maybe<Users>;
  updatedById?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "seats" */
export type SeatsAggregate = {
  __typename?: 'SeatsAggregate';
  aggregate?: Maybe<SeatsAggregateFields>;
  nodes: Array<Seats>;
};

/** aggregate fields of "seats" */
export type SeatsAggregateFields = {
  __typename?: 'SeatsAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<SeatsMaxFields>;
  min?: Maybe<SeatsMinFields>;
};

/** aggregate fields of "seats" */
export type SeatsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SeatsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "seats". All fields are combined with a logical 'AND'. */
export type SeatsBoolExp = {
  _and?: InputMaybe<Array<SeatsBoolExp>>;
  _not?: InputMaybe<SeatsBoolExp>;
  _or?: InputMaybe<Array<SeatsBoolExp>>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  deletedAt?: InputMaybe<TimestampComparisonExp>;
  deletedBy?: InputMaybe<UsersBoolExp>;
  deletedById?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  organization?: InputMaybe<OrganizationsBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  type?: InputMaybe<SeatTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  updatedBy?: InputMaybe<UsersBoolExp>;
  updatedById?: InputMaybe<UuidComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "seats" */
export enum SeatsConstraint {
  /** unique or primary key constraint on columns "id" */
  seats_pk = 'seats_pk',
  /** unique or primary key constraint on columns "user_id", "organization_id" */
  seats_user_id_organization_id_deleted_at_uindex = 'seats_user_id_organization_id_deleted_at_uindex'
}

/** input type for inserting data into table "seats" */
export type SeatsInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedBy?: InputMaybe<UsersObjRelInsertInput>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  organization?: InputMaybe<OrganizationsObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<SeatTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<UsersObjRelInsertInput>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type SeatsMaxFields = {
  __typename?: 'SeatsMaxFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  organizationId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type SeatsMinFields = {
  __typename?: 'SeatsMinFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  organizationId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "seats" */
export type SeatsMutationResponse = {
  __typename?: 'SeatsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Seats>;
};

/** on_conflict condition type for table "seats" */
export type SeatsOnConflict = {
  constraint: SeatsConstraint;
  updateColumns?: Array<SeatsUpdateColumn>;
  where?: InputMaybe<SeatsBoolExp>;
};

/** Ordering options when selecting data from "seats". */
export type SeatsOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedBy?: InputMaybe<UsersOrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationsOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<UsersOrderBy>;
  updatedById?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: seats */
export type SeatsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "seats" */
export enum SeatsSelectColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  id = 'id',
  /** column name */
  organizationId = 'organizationId',
  /** column name */
  type = 'type',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById',
  /** column name */
  userId = 'userId'
}

/** input type for updating data in table "seats" */
export type SeatsSetInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<SeatTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "seats" */
export type SeatsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SeatsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SeatsStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<SeatTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "seats" */
export enum SeatsUpdateColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  id = 'id',
  /** column name */
  organizationId = 'organizationId',
  /** column name */
  type = 'type',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById',
  /** column name */
  userId = 'userId'
}

export type SeatsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SeatsSetInput>;
  /** filter the rows which have to be updated */
  where: SeatsBoolExp;
};

export type SetClassStatusContext = {
  done: Scalars['Boolean'];
  method: Scalars['String'];
};

export type SetClassStatusOutput = {
  __typename?: 'SetClassStatusOutput';
  done: Scalars['Boolean'];
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type SmallintComparisonExp = {
  _eq?: InputMaybe<Scalars['smallint']>;
  _gt?: InputMaybe<Scalars['smallint']>;
  _gte?: InputMaybe<Scalars['smallint']>;
  _in?: InputMaybe<Array<Scalars['smallint']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['smallint']>;
  _lte?: InputMaybe<Scalars['smallint']>;
  _neq?: InputMaybe<Scalars['smallint']>;
  _nin?: InputMaybe<Array<Scalars['smallint']>>;
};

export type StartAssessmentOutput = {
  __typename?: 'StartAssessmentOutput';
  cohortSlug: Scalars['String'];
  id: Scalars['uuid'];
  impactAssessmentId: Scalars['uuid'];
};

export type StartPlaybackInput = {
  classEventId?: InputMaybe<Scalars['uuid']>;
  hash?: InputMaybe<Scalars['String']>;
  playbackType: Scalars['String'];
};

export type StartPlaybackOutput = {
  __typename?: 'StartPlaybackOutput';
  success: Scalars['Boolean'];
};

/** Hold all messages sent via Stream */
export type StreamMessages = {
  __typename?: 'StreamMessages';
  /** An object relationship */
  author?: Maybe<Users>;
  authorId: Scalars['uuid'];
  channelId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  data: Scalars['jsonb'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  lightningSessionSubmission?: Maybe<LightningSessionSubmissions>;
  messageId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** Hold all messages sent via Stream */
export type StreamMessagesDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "stream_messages" */
export type StreamMessagesAggregate = {
  __typename?: 'StreamMessagesAggregate';
  aggregate?: Maybe<StreamMessagesAggregateFields>;
  nodes: Array<StreamMessages>;
};

export type StreamMessagesAggregateBoolExp = {
  count?: InputMaybe<StreamMessagesAggregateBoolExpCount>;
};

/** aggregate fields of "stream_messages" */
export type StreamMessagesAggregateFields = {
  __typename?: 'StreamMessagesAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<StreamMessagesMaxFields>;
  min?: Maybe<StreamMessagesMinFields>;
};

/** aggregate fields of "stream_messages" */
export type StreamMessagesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<StreamMessagesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stream_messages" */
export type StreamMessagesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<StreamMessagesMaxOrderBy>;
  min?: InputMaybe<StreamMessagesMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type StreamMessagesAppendInput = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "stream_messages" */
export type StreamMessagesArrRelInsertInput = {
  data: Array<StreamMessagesInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<StreamMessagesOnConflict>;
};

/** Boolean expression to filter rows from the table "stream_messages". All fields are combined with a logical 'AND'. */
export type StreamMessagesBoolExp = {
  _and?: InputMaybe<Array<StreamMessagesBoolExp>>;
  _not?: InputMaybe<StreamMessagesBoolExp>;
  _or?: InputMaybe<Array<StreamMessagesBoolExp>>;
  author?: InputMaybe<UsersBoolExp>;
  authorId?: InputMaybe<UuidComparisonExp>;
  channelId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  data?: InputMaybe<JsonbComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  lightningSessionSubmission?: InputMaybe<LightningSessionSubmissionsBoolExp>;
  messageId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "stream_messages" */
export enum StreamMessagesConstraint {
  /** unique or primary key constraint on columns "message_id" */
  stream_messages_message_id_key = 'stream_messages_message_id_key',
  /** unique or primary key constraint on columns "id" */
  stream_messages_pkey = 'stream_messages_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type StreamMessagesDeleteAtPathInput = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type StreamMessagesDeleteElemInput = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type StreamMessagesDeleteKeyInput = {
  data?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "stream_messages" */
export type StreamMessagesInsertInput = {
  author?: InputMaybe<UsersObjRelInsertInput>;
  authorId?: InputMaybe<Scalars['uuid']>;
  channelId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lightningSessionSubmission?: InputMaybe<LightningSessionSubmissionsObjRelInsertInput>;
  messageId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type StreamMessagesMaxFields = {
  __typename?: 'StreamMessagesMaxFields';
  authorId?: Maybe<Scalars['uuid']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  messageId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "stream_messages" */
export type StreamMessagesMaxOrderBy = {
  authorId?: InputMaybe<OrderBy>;
  channelId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type StreamMessagesMinFields = {
  __typename?: 'StreamMessagesMinFields';
  authorId?: Maybe<Scalars['uuid']>;
  channelId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  messageId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "stream_messages" */
export type StreamMessagesMinOrderBy = {
  authorId?: InputMaybe<OrderBy>;
  channelId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "stream_messages" */
export type StreamMessagesMutationResponse = {
  __typename?: 'StreamMessagesMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<StreamMessages>;
};

/** on_conflict condition type for table "stream_messages" */
export type StreamMessagesOnConflict = {
  constraint: StreamMessagesConstraint;
  updateColumns?: Array<StreamMessagesUpdateColumn>;
  where?: InputMaybe<StreamMessagesBoolExp>;
};

/** Ordering options when selecting data from "stream_messages". */
export type StreamMessagesOrderBy = {
  author?: InputMaybe<UsersOrderBy>;
  authorId?: InputMaybe<OrderBy>;
  channelId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  data?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lightningSessionSubmission?: InputMaybe<LightningSessionSubmissionsOrderBy>;
  messageId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: stream_messages */
export type StreamMessagesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type StreamMessagesPrependInput = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "stream_messages" */
export enum StreamMessagesSelectColumn {
  /** column name */
  authorId = 'authorId',
  /** column name */
  channelId = 'channelId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  data = 'data',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  id = 'id',
  /** column name */
  messageId = 'messageId',
  /** column name */
  updatedAt = 'updatedAt'
}

/** input type for updating data in table "stream_messages" */
export type StreamMessagesSetInput = {
  authorId?: InputMaybe<Scalars['uuid']>;
  channelId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  messageId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "stream_messages" */
export type StreamMessagesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: StreamMessagesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type StreamMessagesStreamCursorValueInput = {
  authorId?: InputMaybe<Scalars['uuid']>;
  channelId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  messageId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "stream_messages" */
export enum StreamMessagesUpdateColumn {
  /** column name */
  authorId = 'authorId',
  /** column name */
  channelId = 'channelId',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  data = 'data',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  id = 'id',
  /** column name */
  messageId = 'messageId',
  /** column name */
  updatedAt = 'updatedAt'
}

export type StreamMessagesUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<StreamMessagesAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<StreamMessagesDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<StreamMessagesDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<StreamMessagesDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<StreamMessagesPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<StreamMessagesSetInput>;
  /** filter the rows which have to be updated */
  where: StreamMessagesBoolExp;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringArrayComparisonExp = {
  /** is the array contained in the given array value */
  _containedIn?: InputMaybe<Array<Scalars['String']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['String']>>;
  _eq?: InputMaybe<Array<Scalars['String']>>;
  _gt?: InputMaybe<Array<Scalars['String']>>;
  _gte?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Array<Scalars['String']>>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Array<Scalars['String']>>;
  _lte?: InputMaybe<Array<Scalars['String']>>;
  _neq?: InputMaybe<Array<Scalars['String']>>;
  _nin?: InputMaybe<Array<Array<Scalars['String']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type SubmitAndGradeAssessmentOutput = {
  __typename?: 'SubmitAndGradeAssessmentOutput';
  impactAssessmentAttemptId: Scalars['uuid'];
  impactAssessmentId?: Maybe<Scalars['uuid']>;
  learnerScoreIsPassing?: Maybe<Scalars['Boolean']>;
  learnerScorePercent?: Maybe<Scalars['numeric']>;
};

/** columns and relationships of "subordinates" */
export type Subordinates = {
  __typename?: 'Subordinates';
  email?: Maybe<Scalars['String']>;
  enrollments?: Maybe<Array<Scalars['uuid']>>;
  firstName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  /** An object relationship */
  manager?: Maybe<Users>;
  managerId?: Maybe<Scalars['uuid']>;
  teamIds?: Maybe<Array<Scalars['String']>>;
  teamNames?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
  waitlists?: Maybe<Array<Scalars['uuid']>>;
};

/** aggregated selection of "subordinates" */
export type SubordinatesAggregate = {
  __typename?: 'SubordinatesAggregate';
  aggregate?: Maybe<SubordinatesAggregateFields>;
  nodes: Array<Subordinates>;
};

/** aggregate fields of "subordinates" */
export type SubordinatesAggregateFields = {
  __typename?: 'SubordinatesAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<SubordinatesMaxFields>;
  min?: Maybe<SubordinatesMinFields>;
};

/** aggregate fields of "subordinates" */
export type SubordinatesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SubordinatesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "subordinates". All fields are combined with a logical 'AND'. */
export type SubordinatesBoolExp = {
  _and?: InputMaybe<Array<SubordinatesBoolExp>>;
  _not?: InputMaybe<SubordinatesBoolExp>;
  _or?: InputMaybe<Array<SubordinatesBoolExp>>;
  email?: InputMaybe<StringComparisonExp>;
  enrollments?: InputMaybe<UuidArrayComparisonExp>;
  firstName?: InputMaybe<StringComparisonExp>;
  imageUrl?: InputMaybe<StringComparisonExp>;
  isActive?: InputMaybe<BooleanComparisonExp>;
  lastName?: InputMaybe<StringComparisonExp>;
  manager?: InputMaybe<UsersBoolExp>;
  managerId?: InputMaybe<UuidComparisonExp>;
  teamIds?: InputMaybe<StringArrayComparisonExp>;
  teamNames?: InputMaybe<StringComparisonExp>;
  userId?: InputMaybe<UuidComparisonExp>;
  waitlists?: InputMaybe<UuidArrayComparisonExp>;
};

/** aggregate max on columns */
export type SubordinatesMaxFields = {
  __typename?: 'SubordinatesMaxFields';
  email?: Maybe<Scalars['String']>;
  enrollments?: Maybe<Array<Scalars['uuid']>>;
  firstName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  managerId?: Maybe<Scalars['uuid']>;
  teamIds?: Maybe<Array<Scalars['String']>>;
  teamNames?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
  waitlists?: Maybe<Array<Scalars['uuid']>>;
};

/** aggregate min on columns */
export type SubordinatesMinFields = {
  __typename?: 'SubordinatesMinFields';
  email?: Maybe<Scalars['String']>;
  enrollments?: Maybe<Array<Scalars['uuid']>>;
  firstName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  managerId?: Maybe<Scalars['uuid']>;
  teamIds?: Maybe<Array<Scalars['String']>>;
  teamNames?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
  waitlists?: Maybe<Array<Scalars['uuid']>>;
};

/** Ordering options when selecting data from "subordinates". */
export type SubordinatesOrderBy = {
  email?: InputMaybe<OrderBy>;
  enrollments?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  isActive?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  manager?: InputMaybe<UsersOrderBy>;
  managerId?: InputMaybe<OrderBy>;
  teamIds?: InputMaybe<OrderBy>;
  teamNames?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  waitlists?: InputMaybe<OrderBy>;
};

/** select columns of table "subordinates" */
export enum SubordinatesSelectColumn {
  /** column name */
  email = 'email',
  /** column name */
  enrollments = 'enrollments',
  /** column name */
  firstName = 'firstName',
  /** column name */
  imageUrl = 'imageUrl',
  /** column name */
  isActive = 'isActive',
  /** column name */
  lastName = 'lastName',
  /** column name */
  managerId = 'managerId',
  /** column name */
  teamIds = 'teamIds',
  /** column name */
  teamNames = 'teamNames',
  /** column name */
  userId = 'userId',
  /** column name */
  waitlists = 'waitlists'
}

/** Streaming cursor of the table "subordinates" */
export type SubordinatesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SubordinatesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SubordinatesStreamCursorValueInput = {
  email?: InputMaybe<Scalars['String']>;
  enrollments?: InputMaybe<Array<Scalars['uuid']>>;
  firstName?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  managerId?: InputMaybe<Scalars['uuid']>;
  teamIds?: InputMaybe<Array<Scalars['String']>>;
  teamNames?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['uuid']>;
  waitlists?: InputMaybe<Array<Scalars['uuid']>>;
};

export type SuccessOutput = {
  __typename?: 'SuccessOutput';
  details?: Maybe<Array<Maybe<Scalars['String']>>>;
  success: Scalars['Boolean'];
};

/** columns and relationships of "tag_type_scopes_enum" */
export type TagTypeScope = {
  __typename?: 'TagTypeScope';
  value: Scalars['String'];
};

/** aggregated selection of "tag_type_scopes_enum" */
export type TagTypeScopeAggregate = {
  __typename?: 'TagTypeScopeAggregate';
  aggregate?: Maybe<TagTypeScopeAggregateFields>;
  nodes: Array<TagTypeScope>;
};

/** aggregate fields of "tag_type_scopes_enum" */
export type TagTypeScopeAggregateFields = {
  __typename?: 'TagTypeScopeAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<TagTypeScopeMaxFields>;
  min?: Maybe<TagTypeScopeMinFields>;
};

/** aggregate fields of "tag_type_scopes_enum" */
export type TagTypeScopeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TagTypeScopeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "tag_type_scopes_enum". All fields are combined with a logical 'AND'. */
export type TagTypeScopeBoolExp = {
  _and?: InputMaybe<Array<TagTypeScopeBoolExp>>;
  _not?: InputMaybe<TagTypeScopeBoolExp>;
  _or?: InputMaybe<Array<TagTypeScopeBoolExp>>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "tag_type_scopes_enum" */
export enum TagTypeScopeConstraint {
  /** unique or primary key constraint on columns "value" */
  tag_type_scopes_enum_pkey = 'tag_type_scopes_enum_pkey'
}

export enum TagTypeScopeEnum {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

/** Boolean expression to compare columns of type "TagTypeScopeEnum". All fields are combined with logical 'AND'. */
export type TagTypeScopeEnumComparisonExp = {
  _eq?: InputMaybe<TagTypeScopeEnum>;
  _in?: InputMaybe<Array<TagTypeScopeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<TagTypeScopeEnum>;
  _nin?: InputMaybe<Array<TagTypeScopeEnum>>;
};

/** input type for inserting data into table "tag_type_scopes_enum" */
export type TagTypeScopeInsertInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type TagTypeScopeMaxFields = {
  __typename?: 'TagTypeScopeMaxFields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type TagTypeScopeMinFields = {
  __typename?: 'TagTypeScopeMinFields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "tag_type_scopes_enum" */
export type TagTypeScopeMutationResponse = {
  __typename?: 'TagTypeScopeMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TagTypeScope>;
};

/** on_conflict condition type for table "tag_type_scopes_enum" */
export type TagTypeScopeOnConflict = {
  constraint: TagTypeScopeConstraint;
  updateColumns?: Array<TagTypeScopeUpdateColumn>;
  where?: InputMaybe<TagTypeScopeBoolExp>;
};

/** Ordering options when selecting data from "tag_type_scopes_enum". */
export type TagTypeScopeOrderBy = {
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: tag_type_scopes_enum */
export type TagTypeScopePkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "tag_type_scopes_enum" */
export enum TagTypeScopeSelectColumn {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "tag_type_scopes_enum" */
export type TagTypeScopeSetInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "TagTypeScope" */
export type TagTypeScopeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TagTypeScopeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TagTypeScopeStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "tag_type_scopes_enum" */
export enum TagTypeScopeUpdateColumn {
  /** column name */
  value = 'value'
}

export type TagTypeScopeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TagTypeScopeSetInput>;
  /** filter the rows which have to be updated */
  where: TagTypeScopeBoolExp;
};

/** columns and relationships of "tag_types" */
export type TagTypes = {
  __typename?: 'TagTypes';
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamp'];
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  deletedBy?: Maybe<Users>;
  deletedById?: Maybe<Scalars['uuid']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  scope: TagTypeScopeEnum;
  slug: Scalars['String'];
  /** An array relationship */
  tags: Array<Tags>;
  /** An aggregate relationship */
  tagsAggregate: TagsAggregate;
  updatedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  updatedBy?: Maybe<Users>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "tag_types" */
export type TagTypesTagsArgs = {
  distinctOn?: InputMaybe<Array<TagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TagsOrderBy>>;
  where?: InputMaybe<TagsBoolExp>;
};

/** columns and relationships of "tag_types" */
export type TagTypesTagsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TagsOrderBy>>;
  where?: InputMaybe<TagsBoolExp>;
};

/** aggregated selection of "tag_types" */
export type TagTypesAggregate = {
  __typename?: 'TagTypesAggregate';
  aggregate?: Maybe<TagTypesAggregateFields>;
  nodes: Array<TagTypes>;
};

/** aggregate fields of "tag_types" */
export type TagTypesAggregateFields = {
  __typename?: 'TagTypesAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<TagTypesMaxFields>;
  min?: Maybe<TagTypesMinFields>;
};

/** aggregate fields of "tag_types" */
export type TagTypesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TagTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "tag_types". All fields are combined with a logical 'AND'. */
export type TagTypesBoolExp = {
  _and?: InputMaybe<Array<TagTypesBoolExp>>;
  _not?: InputMaybe<TagTypesBoolExp>;
  _or?: InputMaybe<Array<TagTypesBoolExp>>;
  color?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  deletedAt?: InputMaybe<TimestampComparisonExp>;
  deletedBy?: InputMaybe<UsersBoolExp>;
  deletedById?: InputMaybe<UuidComparisonExp>;
  icon?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  scope?: InputMaybe<TagTypeScopeEnumComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  tags?: InputMaybe<TagsBoolExp>;
  tagsAggregate?: InputMaybe<TagsAggregateBoolExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  updatedBy?: InputMaybe<UsersBoolExp>;
  updatedById?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "tag_types" */
export enum TagTypesConstraint {
  /** unique or primary key constraint on columns "id" */
  tag_types_pk = 'tag_types_pk',
  /** unique or primary key constraint on columns "slug" */
  tag_types_slug_key = 'tag_types_slug_key'
}

/** input type for inserting data into table "tag_types" */
export type TagTypesInsertInput = {
  color?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedBy?: InputMaybe<UsersObjRelInsertInput>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<TagTypeScopeEnum>;
  slug?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<TagsArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<UsersObjRelInsertInput>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type TagTypesMaxFields = {
  __typename?: 'TagTypesMaxFields';
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type TagTypesMinFields = {
  __typename?: 'TagTypesMinFields';
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "tag_types" */
export type TagTypesMutationResponse = {
  __typename?: 'TagTypesMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TagTypes>;
};

/** input type for inserting object relation for remote table "tag_types" */
export type TagTypesObjRelInsertInput = {
  data: TagTypesInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<TagTypesOnConflict>;
};

/** on_conflict condition type for table "tag_types" */
export type TagTypesOnConflict = {
  constraint: TagTypesConstraint;
  updateColumns?: Array<TagTypesUpdateColumn>;
  where?: InputMaybe<TagTypesBoolExp>;
};

/** Ordering options when selecting data from "tag_types". */
export type TagTypesOrderBy = {
  color?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedBy?: InputMaybe<UsersOrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  icon?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  scope?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  tagsAggregate?: InputMaybe<TagsAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<UsersOrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: tag_types */
export type TagTypesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "tag_types" */
export enum TagTypesSelectColumn {
  /** column name */
  color = 'color',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  icon = 'icon',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  scope = 'scope',
  /** column name */
  slug = 'slug',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById'
}

/** input type for updating data in table "tag_types" */
export type TagTypesSetInput = {
  color?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<TagTypeScopeEnum>;
  slug?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "tag_types" */
export type TagTypesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TagTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TagTypesStreamCursorValueInput = {
  color?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<TagTypeScopeEnum>;
  slug?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "tag_types" */
export enum TagTypesUpdateColumn {
  /** column name */
  color = 'color',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  icon = 'icon',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  scope = 'scope',
  /** column name */
  slug = 'slug',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById'
}

export type TagTypesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TagTypesSetInput>;
  /** filter the rows which have to be updated */
  where: TagTypesBoolExp;
};

/** columns and relationships of "tags" */
export type Tags = {
  __typename?: 'Tags';
  createdAt: Scalars['timestamp'];
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  deletedBy?: Maybe<Users>;
  deletedById?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  slug: Scalars['String'];
  /** An object relationship */
  tagType?: Maybe<TagTypes>;
  tagTypeId: Scalars['uuid'];
  /** An array relationship */
  taggedClasses: Array<ClassesTagsRel>;
  /** An aggregate relationship */
  taggedClassesAggregate: ClassesTagsRelAggregate;
  /** An array relationship */
  taggedCourses: Array<CoursesTagsRel>;
  /** An aggregate relationship */
  taggedCoursesAggregate: CoursesTagsRelAggregate;
  updatedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  updatedBy?: Maybe<Users>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "tags" */
export type TagsTaggedClassesArgs = {
  distinctOn?: InputMaybe<Array<ClassesTagsRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassesTagsRelOrderBy>>;
  where?: InputMaybe<ClassesTagsRelBoolExp>;
};

/** columns and relationships of "tags" */
export type TagsTaggedClassesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassesTagsRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassesTagsRelOrderBy>>;
  where?: InputMaybe<ClassesTagsRelBoolExp>;
};

/** columns and relationships of "tags" */
export type TagsTaggedCoursesArgs = {
  distinctOn?: InputMaybe<Array<CoursesTagsRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesTagsRelOrderBy>>;
  where?: InputMaybe<CoursesTagsRelBoolExp>;
};

/** columns and relationships of "tags" */
export type TagsTaggedCoursesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CoursesTagsRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesTagsRelOrderBy>>;
  where?: InputMaybe<CoursesTagsRelBoolExp>;
};

/** aggregated selection of "tags" */
export type TagsAggregate = {
  __typename?: 'TagsAggregate';
  aggregate?: Maybe<TagsAggregateFields>;
  nodes: Array<Tags>;
};

export type TagsAggregateBoolExp = {
  count?: InputMaybe<TagsAggregateBoolExpCount>;
};

/** aggregate fields of "tags" */
export type TagsAggregateFields = {
  __typename?: 'TagsAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<TagsMaxFields>;
  min?: Maybe<TagsMinFields>;
};

/** aggregate fields of "tags" */
export type TagsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TagsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tags" */
export type TagsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TagsMaxOrderBy>;
  min?: InputMaybe<TagsMinOrderBy>;
};

/** input type for inserting array relation for remote table "tags" */
export type TagsArrRelInsertInput = {
  data: Array<TagsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TagsOnConflict>;
};

/** Boolean expression to filter rows from the table "tags". All fields are combined with a logical 'AND'. */
export type TagsBoolExp = {
  _and?: InputMaybe<Array<TagsBoolExp>>;
  _not?: InputMaybe<TagsBoolExp>;
  _or?: InputMaybe<Array<TagsBoolExp>>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  deletedAt?: InputMaybe<TimestampComparisonExp>;
  deletedBy?: InputMaybe<UsersBoolExp>;
  deletedById?: InputMaybe<UuidComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  tagType?: InputMaybe<TagTypesBoolExp>;
  tagTypeId?: InputMaybe<UuidComparisonExp>;
  taggedClasses?: InputMaybe<ClassesTagsRelBoolExp>;
  taggedClassesAggregate?: InputMaybe<ClassesTagsRelAggregateBoolExp>;
  taggedCourses?: InputMaybe<CoursesTagsRelBoolExp>;
  taggedCoursesAggregate?: InputMaybe<CoursesTagsRelAggregateBoolExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  updatedBy?: InputMaybe<UsersBoolExp>;
  updatedById?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "tags" */
export enum TagsConstraint {
  /** unique or primary key constraint on columns "id" */
  tags_pk = 'tags_pk',
  /** unique or primary key constraint on columns "slug" */
  tags_slug_key = 'tags_slug_key'
}

/** input type for inserting data into table "tags" */
export type TagsInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedBy?: InputMaybe<UsersObjRelInsertInput>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  tagType?: InputMaybe<TagTypesObjRelInsertInput>;
  tagTypeId?: InputMaybe<Scalars['uuid']>;
  taggedClasses?: InputMaybe<ClassesTagsRelArrRelInsertInput>;
  taggedCourses?: InputMaybe<CoursesTagsRelArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<UsersObjRelInsertInput>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type TagsMaxFields = {
  __typename?: 'TagsMaxFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  tagTypeId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "tags" */
export type TagsMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  tagTypeId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TagsMinFields = {
  __typename?: 'TagsMinFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  deletedById?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  tagTypeId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "tags" */
export type TagsMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  tagTypeId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "tags" */
export type TagsMutationResponse = {
  __typename?: 'TagsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tags>;
};

/** input type for inserting object relation for remote table "tags" */
export type TagsObjRelInsertInput = {
  data: TagsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<TagsOnConflict>;
};

/** on_conflict condition type for table "tags" */
export type TagsOnConflict = {
  constraint: TagsConstraint;
  updateColumns?: Array<TagsUpdateColumn>;
  where?: InputMaybe<TagsBoolExp>;
};

/** Ordering options when selecting data from "tags". */
export type TagsOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  deletedBy?: InputMaybe<UsersOrderBy>;
  deletedById?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  tagType?: InputMaybe<TagTypesOrderBy>;
  tagTypeId?: InputMaybe<OrderBy>;
  taggedClassesAggregate?: InputMaybe<ClassesTagsRelAggregateOrderBy>;
  taggedCoursesAggregate?: InputMaybe<CoursesTagsRelAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<UsersOrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: tags */
export type TagsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "tags" */
export enum TagsSelectColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  description = 'description',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  slug = 'slug',
  /** column name */
  tagTypeId = 'tagTypeId',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById'
}

/** input type for updating data in table "tags" */
export type TagsSetInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  tagTypeId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "tags" */
export type TagsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TagsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TagsStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedById?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  tagTypeId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "tags" */
export enum TagsUpdateColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  deletedAt = 'deletedAt',
  /** column name */
  deletedById = 'deletedById',
  /** column name */
  description = 'description',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  slug = 'slug',
  /** column name */
  tagTypeId = 'tagTypeId',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById'
}

export type TagsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TagsSetInput>;
  /** filter the rows which have to be updated */
  where: TagsBoolExp;
};

/** columns and relationships of "task_definition" */
export type TaskDefinition = {
  __typename?: 'TaskDefinition';
  createdAt: Scalars['timestamp'];
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById: Scalars['uuid'];
  id: Scalars['uuid'];
  schema: Scalars['json'];
  type: TaskDefinitionTypeEnum;
  updatedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  updatedBy?: Maybe<Users>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "task_definition" */
export type TaskDefinitionSchemaArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "task_definition" */
export type TaskDefinitionAggregate = {
  __typename?: 'TaskDefinitionAggregate';
  aggregate?: Maybe<TaskDefinitionAggregateFields>;
  nodes: Array<TaskDefinition>;
};

/** aggregate fields of "task_definition" */
export type TaskDefinitionAggregateFields = {
  __typename?: 'TaskDefinitionAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<TaskDefinitionMaxFields>;
  min?: Maybe<TaskDefinitionMinFields>;
};

/** aggregate fields of "task_definition" */
export type TaskDefinitionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TaskDefinitionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "task_definition". All fields are combined with a logical 'AND'. */
export type TaskDefinitionBoolExp = {
  _and?: InputMaybe<Array<TaskDefinitionBoolExp>>;
  _not?: InputMaybe<TaskDefinitionBoolExp>;
  _or?: InputMaybe<Array<TaskDefinitionBoolExp>>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  schema?: InputMaybe<JsonComparisonExp>;
  type?: InputMaybe<TaskDefinitionTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  updatedBy?: InputMaybe<UsersBoolExp>;
  updatedById?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "task_definition" */
export enum TaskDefinitionConstraint {
  /** unique or primary key constraint on columns "id" */
  task_definition_pk = 'task_definition_pk'
}

/** input type for inserting data into table "task_definition" */
export type TaskDefinitionInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  schema?: InputMaybe<Scalars['json']>;
  type?: InputMaybe<TaskDefinitionTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<UsersObjRelInsertInput>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type TaskDefinitionMaxFields = {
  __typename?: 'TaskDefinitionMaxFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type TaskDefinitionMinFields = {
  __typename?: 'TaskDefinitionMinFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "task_definition" */
export type TaskDefinitionMutationResponse = {
  __typename?: 'TaskDefinitionMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TaskDefinition>;
};

/** input type for inserting object relation for remote table "task_definition" */
export type TaskDefinitionObjRelInsertInput = {
  data: TaskDefinitionInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<TaskDefinitionOnConflict>;
};

/** on_conflict condition type for table "task_definition" */
export type TaskDefinitionOnConflict = {
  constraint: TaskDefinitionConstraint;
  updateColumns?: Array<TaskDefinitionUpdateColumn>;
  where?: InputMaybe<TaskDefinitionBoolExp>;
};

/** Ordering options when selecting data from "task_definition". */
export type TaskDefinitionOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  schema?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<UsersOrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: task_definition */
export type TaskDefinitionPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "task_definition" */
export enum TaskDefinitionSelectColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  id = 'id',
  /** column name */
  schema = 'schema',
  /** column name */
  type = 'type',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById'
}

/** input type for updating data in table "task_definition" */
export type TaskDefinitionSetInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  schema?: InputMaybe<Scalars['json']>;
  type?: InputMaybe<TaskDefinitionTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "task_definition" */
export type TaskDefinitionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TaskDefinitionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TaskDefinitionStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  schema?: InputMaybe<Scalars['json']>;
  type?: InputMaybe<TaskDefinitionTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "task_definition_type_enum" */
export type TaskDefinitionType = {
  __typename?: 'TaskDefinitionType';
  value: Scalars['String'];
};

/** aggregated selection of "task_definition_type_enum" */
export type TaskDefinitionTypeAggregate = {
  __typename?: 'TaskDefinitionTypeAggregate';
  aggregate?: Maybe<TaskDefinitionTypeAggregateFields>;
  nodes: Array<TaskDefinitionType>;
};

/** aggregate fields of "task_definition_type_enum" */
export type TaskDefinitionTypeAggregateFields = {
  __typename?: 'TaskDefinitionTypeAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<TaskDefinitionTypeMaxFields>;
  min?: Maybe<TaskDefinitionTypeMinFields>;
};

/** aggregate fields of "task_definition_type_enum" */
export type TaskDefinitionTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TaskDefinitionTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "task_definition_type_enum". All fields are combined with a logical 'AND'. */
export type TaskDefinitionTypeBoolExp = {
  _and?: InputMaybe<Array<TaskDefinitionTypeBoolExp>>;
  _not?: InputMaybe<TaskDefinitionTypeBoolExp>;
  _or?: InputMaybe<Array<TaskDefinitionTypeBoolExp>>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "task_definition_type_enum" */
export enum TaskDefinitionTypeConstraint {
  /** unique or primary key constraint on columns "value" */
  task_definition_type_enum_pk = 'task_definition_type_enum_pk'
}

export enum TaskDefinitionTypeEnum {
  COMPLETE_COURSE = 'COMPLETE_COURSE',
  ENROLL = 'ENROLL'
}

/** Boolean expression to compare columns of type "TaskDefinitionTypeEnum". All fields are combined with logical 'AND'. */
export type TaskDefinitionTypeEnumComparisonExp = {
  _eq?: InputMaybe<TaskDefinitionTypeEnum>;
  _in?: InputMaybe<Array<TaskDefinitionTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<TaskDefinitionTypeEnum>;
  _nin?: InputMaybe<Array<TaskDefinitionTypeEnum>>;
};

/** input type for inserting data into table "task_definition_type_enum" */
export type TaskDefinitionTypeInsertInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type TaskDefinitionTypeMaxFields = {
  __typename?: 'TaskDefinitionTypeMaxFields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type TaskDefinitionTypeMinFields = {
  __typename?: 'TaskDefinitionTypeMinFields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "task_definition_type_enum" */
export type TaskDefinitionTypeMutationResponse = {
  __typename?: 'TaskDefinitionTypeMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TaskDefinitionType>;
};

/** on_conflict condition type for table "task_definition_type_enum" */
export type TaskDefinitionTypeOnConflict = {
  constraint: TaskDefinitionTypeConstraint;
  updateColumns?: Array<TaskDefinitionTypeUpdateColumn>;
  where?: InputMaybe<TaskDefinitionTypeBoolExp>;
};

/** Ordering options when selecting data from "task_definition_type_enum". */
export type TaskDefinitionTypeOrderBy = {
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: task_definition_type_enum */
export type TaskDefinitionTypePkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "task_definition_type_enum" */
export enum TaskDefinitionTypeSelectColumn {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "task_definition_type_enum" */
export type TaskDefinitionTypeSetInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "TaskDefinitionType" */
export type TaskDefinitionTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TaskDefinitionTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TaskDefinitionTypeStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "task_definition_type_enum" */
export enum TaskDefinitionTypeUpdateColumn {
  /** column name */
  value = 'value'
}

export type TaskDefinitionTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TaskDefinitionTypeSetInput>;
  /** filter the rows which have to be updated */
  where: TaskDefinitionTypeBoolExp;
};

/** update columns of table "task_definition" */
export enum TaskDefinitionUpdateColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  id = 'id',
  /** column name */
  schema = 'schema',
  /** column name */
  type = 'type',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById'
}

export type TaskDefinitionUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TaskDefinitionSetInput>;
  /** filter the rows which have to be updated */
  where: TaskDefinitionBoolExp;
};

/** columns and relationships of "tasks" */
export type Tasks = {
  __typename?: 'Tasks';
  createdAt: Scalars['timestamp'];
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById: Scalars['uuid'];
  data: Scalars['jsonb'];
  dueDate?: Maybe<Scalars['timestamp']>;
  effectiveDate?: Maybe<Scalars['timestamp']>;
  id: Scalars['uuid'];
  /** An object relationship */
  journey?: Maybe<Journeys>;
  journeyId: Scalars['uuid'];
  seq: Scalars['String'];
  /** An object relationship */
  taskDefinition?: Maybe<TaskDefinition>;
  taskDefinitionId: Scalars['uuid'];
  updatedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  updatedBy?: Maybe<Users>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "tasks" */
export type TasksDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "tasks" */
export type TasksAggregate = {
  __typename?: 'TasksAggregate';
  aggregate?: Maybe<TasksAggregateFields>;
  nodes: Array<Tasks>;
};

export type TasksAggregateBoolExp = {
  count?: InputMaybe<TasksAggregateBoolExpCount>;
};

/** aggregate fields of "tasks" */
export type TasksAggregateFields = {
  __typename?: 'TasksAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<TasksMaxFields>;
  min?: Maybe<TasksMinFields>;
};

/** aggregate fields of "tasks" */
export type TasksAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TasksSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tasks" */
export type TasksAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TasksMaxOrderBy>;
  min?: InputMaybe<TasksMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type TasksAppendInput = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "tasks" */
export type TasksArrRelInsertInput = {
  data: Array<TasksInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TasksOnConflict>;
};

/** Boolean expression to filter rows from the table "tasks". All fields are combined with a logical 'AND'. */
export type TasksBoolExp = {
  _and?: InputMaybe<Array<TasksBoolExp>>;
  _not?: InputMaybe<TasksBoolExp>;
  _or?: InputMaybe<Array<TasksBoolExp>>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  data?: InputMaybe<JsonbComparisonExp>;
  dueDate?: InputMaybe<TimestampComparisonExp>;
  effectiveDate?: InputMaybe<TimestampComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  journey?: InputMaybe<JourneysBoolExp>;
  journeyId?: InputMaybe<UuidComparisonExp>;
  seq?: InputMaybe<StringComparisonExp>;
  taskDefinition?: InputMaybe<TaskDefinitionBoolExp>;
  taskDefinitionId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  updatedBy?: InputMaybe<UsersBoolExp>;
  updatedById?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "tasks" */
export enum TasksConstraint {
  /** unique or primary key constraint on columns "journey_id", "seq" */
  tasks_journey_seq_uniq = 'tasks_journey_seq_uniq',
  /** unique or primary key constraint on columns "id" */
  tasks_pk = 'tasks_pk'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type TasksDeleteAtPathInput = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type TasksDeleteElemInput = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type TasksDeleteKeyInput = {
  data?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "tasks" */
export type TasksInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  data?: InputMaybe<Scalars['jsonb']>;
  dueDate?: InputMaybe<Scalars['timestamp']>;
  effectiveDate?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  journey?: InputMaybe<JourneysObjRelInsertInput>;
  journeyId?: InputMaybe<Scalars['uuid']>;
  seq?: InputMaybe<Scalars['String']>;
  taskDefinition?: InputMaybe<TaskDefinitionObjRelInsertInput>;
  taskDefinitionId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<UsersObjRelInsertInput>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type TasksMaxFields = {
  __typename?: 'TasksMaxFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  dueDate?: Maybe<Scalars['timestamp']>;
  effectiveDate?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  journeyId?: Maybe<Scalars['uuid']>;
  seq?: Maybe<Scalars['String']>;
  taskDefinitionId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "tasks" */
export type TasksMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  dueDate?: InputMaybe<OrderBy>;
  effectiveDate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  journeyId?: InputMaybe<OrderBy>;
  seq?: InputMaybe<OrderBy>;
  taskDefinitionId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TasksMinFields = {
  __typename?: 'TasksMinFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  dueDate?: Maybe<Scalars['timestamp']>;
  effectiveDate?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  journeyId?: Maybe<Scalars['uuid']>;
  seq?: Maybe<Scalars['String']>;
  taskDefinitionId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "tasks" */
export type TasksMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  dueDate?: InputMaybe<OrderBy>;
  effectiveDate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  journeyId?: InputMaybe<OrderBy>;
  seq?: InputMaybe<OrderBy>;
  taskDefinitionId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "tasks" */
export type TasksMutationResponse = {
  __typename?: 'TasksMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tasks>;
};

/** input type for inserting object relation for remote table "tasks" */
export type TasksObjRelInsertInput = {
  data: TasksInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<TasksOnConflict>;
};

/** on_conflict condition type for table "tasks" */
export type TasksOnConflict = {
  constraint: TasksConstraint;
  updateColumns?: Array<TasksUpdateColumn>;
  where?: InputMaybe<TasksBoolExp>;
};

/** Ordering options when selecting data from "tasks". */
export type TasksOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  data?: InputMaybe<OrderBy>;
  dueDate?: InputMaybe<OrderBy>;
  effectiveDate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  journey?: InputMaybe<JourneysOrderBy>;
  journeyId?: InputMaybe<OrderBy>;
  seq?: InputMaybe<OrderBy>;
  taskDefinition?: InputMaybe<TaskDefinitionOrderBy>;
  taskDefinitionId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<UsersOrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: tasks */
export type TasksPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type TasksPrependInput = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "tasks" */
export enum TasksSelectColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  data = 'data',
  /** column name */
  dueDate = 'dueDate',
  /** column name */
  effectiveDate = 'effectiveDate',
  /** column name */
  id = 'id',
  /** column name */
  journeyId = 'journeyId',
  /** column name */
  seq = 'seq',
  /** column name */
  taskDefinitionId = 'taskDefinitionId',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById'
}

/** input type for updating data in table "tasks" */
export type TasksSetInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  data?: InputMaybe<Scalars['jsonb']>;
  dueDate?: InputMaybe<Scalars['timestamp']>;
  effectiveDate?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  journeyId?: InputMaybe<Scalars['uuid']>;
  seq?: InputMaybe<Scalars['String']>;
  taskDefinitionId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "tasks" */
export type TasksStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TasksStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TasksStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  data?: InputMaybe<Scalars['jsonb']>;
  dueDate?: InputMaybe<Scalars['timestamp']>;
  effectiveDate?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  journeyId?: InputMaybe<Scalars['uuid']>;
  seq?: InputMaybe<Scalars['String']>;
  taskDefinitionId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "tasks" */
export enum TasksUpdateColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  data = 'data',
  /** column name */
  dueDate = 'dueDate',
  /** column name */
  effectiveDate = 'effectiveDate',
  /** column name */
  id = 'id',
  /** column name */
  journeyId = 'journeyId',
  /** column name */
  seq = 'seq',
  /** column name */
  taskDefinitionId = 'taskDefinitionId',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById'
}

export type TasksUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<TasksAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<TasksDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<TasksDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<TasksDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<TasksPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TasksSetInput>;
  /** filter the rows which have to be updated */
  where: TasksBoolExp;
};

export type TeamOutput = {
  __typename?: 'TeamOutput';
  id: Scalars['uuid'];
};

/** columns and relationships of "teams" */
export type Teams = {
  __typename?: 'Teams';
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  manualLearnerJourneyStartDate?: Maybe<Scalars['date']>;
  name: Scalars['String'];
  onManualLearnerJourney: Scalars['Boolean'];
  /** An object relationship */
  organization?: Maybe<Organizations>;
  organizationId?: Maybe<Scalars['uuid']>;
  parentId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  parentTeam?: Maybe<Teams>;
  slug: Scalars['String'];
  teamJobFunction?: Maybe<UsersFunctionsEnum>;
  /** An array relationship */
  teamUsers: Array<TeamsUsers>;
  /** An aggregate relationship */
  teamUsersAggregate: TeamsUsersAggregate;
  updatedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  updatedBy?: Maybe<Users>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "teams" */
export type TeamsTeamUsersArgs = {
  distinctOn?: InputMaybe<Array<TeamsUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamsUsersOrderBy>>;
  where?: InputMaybe<TeamsUsersBoolExp>;
};

/** columns and relationships of "teams" */
export type TeamsTeamUsersAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamsUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamsUsersOrderBy>>;
  where?: InputMaybe<TeamsUsersBoolExp>;
};

/** aggregated selection of "teams" */
export type TeamsAggregate = {
  __typename?: 'TeamsAggregate';
  aggregate?: Maybe<TeamsAggregateFields>;
  nodes: Array<Teams>;
};

/** aggregate fields of "teams" */
export type TeamsAggregateFields = {
  __typename?: 'TeamsAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<TeamsMaxFields>;
  min?: Maybe<TeamsMinFields>;
};

/** aggregate fields of "teams" */
export type TeamsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TeamsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "teams". All fields are combined with a logical 'AND'. */
export type TeamsBoolExp = {
  _and?: InputMaybe<Array<TeamsBoolExp>>;
  _not?: InputMaybe<TeamsBoolExp>;
  _or?: InputMaybe<Array<TeamsBoolExp>>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  manualLearnerJourneyStartDate?: InputMaybe<DateComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  onManualLearnerJourney?: InputMaybe<BooleanComparisonExp>;
  organization?: InputMaybe<OrganizationsBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  parentId?: InputMaybe<UuidComparisonExp>;
  parentTeam?: InputMaybe<TeamsBoolExp>;
  slug?: InputMaybe<StringComparisonExp>;
  teamJobFunction?: InputMaybe<UsersFunctionsEnumComparisonExp>;
  teamUsers?: InputMaybe<TeamsUsersBoolExp>;
  teamUsersAggregate?: InputMaybe<TeamsUsersAggregateBoolExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  updatedBy?: InputMaybe<UsersBoolExp>;
  updatedById?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "teams" */
export enum TeamsConstraint {
  /** unique or primary key constraint on columns "id" */
  teams_pk = 'teams_pk',
  /** unique or primary key constraint on columns "slug" */
  teams_slug_key = 'teams_slug_key'
}

/** input type for inserting data into table "teams" */
export type TeamsInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  manualLearnerJourneyStartDate?: InputMaybe<Scalars['date']>;
  name?: InputMaybe<Scalars['String']>;
  onManualLearnerJourney?: InputMaybe<Scalars['Boolean']>;
  organization?: InputMaybe<OrganizationsObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  parentId?: InputMaybe<Scalars['uuid']>;
  parentTeam?: InputMaybe<TeamsObjRelInsertInput>;
  slug?: InputMaybe<Scalars['String']>;
  teamJobFunction?: InputMaybe<UsersFunctionsEnum>;
  teamUsers?: InputMaybe<TeamsUsersArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<UsersObjRelInsertInput>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type TeamsMaxFields = {
  __typename?: 'TeamsMaxFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  manualLearnerJourneyStartDate?: Maybe<Scalars['date']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  parentId?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type TeamsMinFields = {
  __typename?: 'TeamsMinFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  manualLearnerJourneyStartDate?: Maybe<Scalars['date']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  parentId?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "teams" */
export type TeamsMutationResponse = {
  __typename?: 'TeamsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Teams>;
};

/** input type for inserting object relation for remote table "teams" */
export type TeamsObjRelInsertInput = {
  data: TeamsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<TeamsOnConflict>;
};

/** on_conflict condition type for table "teams" */
export type TeamsOnConflict = {
  constraint: TeamsConstraint;
  updateColumns?: Array<TeamsUpdateColumn>;
  where?: InputMaybe<TeamsBoolExp>;
};

/** Ordering options when selecting data from "teams". */
export type TeamsOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  manualLearnerJourneyStartDate?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  onManualLearnerJourney?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationsOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  parentId?: InputMaybe<OrderBy>;
  parentTeam?: InputMaybe<TeamsOrderBy>;
  slug?: InputMaybe<OrderBy>;
  teamJobFunction?: InputMaybe<OrderBy>;
  teamUsersAggregate?: InputMaybe<TeamsUsersAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<UsersOrderBy>;
  updatedById?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: teams */
export type TeamsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "teams" */
export enum TeamsSelectColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  id = 'id',
  /** column name */
  manualLearnerJourneyStartDate = 'manualLearnerJourneyStartDate',
  /** column name */
  name = 'name',
  /** column name */
  onManualLearnerJourney = 'onManualLearnerJourney',
  /** column name */
  organizationId = 'organizationId',
  /** column name */
  parentId = 'parentId',
  /** column name */
  slug = 'slug',
  /** column name */
  teamJobFunction = 'teamJobFunction',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById'
}

/** input type for updating data in table "teams" */
export type TeamsSetInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  manualLearnerJourneyStartDate?: InputMaybe<Scalars['date']>;
  name?: InputMaybe<Scalars['String']>;
  onManualLearnerJourney?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  parentId?: InputMaybe<Scalars['uuid']>;
  slug?: InputMaybe<Scalars['String']>;
  teamJobFunction?: InputMaybe<UsersFunctionsEnum>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "teams" */
export type TeamsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TeamsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TeamsStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  manualLearnerJourneyStartDate?: InputMaybe<Scalars['date']>;
  name?: InputMaybe<Scalars['String']>;
  onManualLearnerJourney?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  parentId?: InputMaybe<Scalars['uuid']>;
  slug?: InputMaybe<Scalars['String']>;
  teamJobFunction?: InputMaybe<UsersFunctionsEnum>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "teams" */
export enum TeamsUpdateColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  id = 'id',
  /** column name */
  manualLearnerJourneyStartDate = 'manualLearnerJourneyStartDate',
  /** column name */
  name = 'name',
  /** column name */
  onManualLearnerJourney = 'onManualLearnerJourney',
  /** column name */
  organizationId = 'organizationId',
  /** column name */
  parentId = 'parentId',
  /** column name */
  slug = 'slug',
  /** column name */
  teamJobFunction = 'teamJobFunction',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById'
}

export type TeamsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TeamsSetInput>;
  /** filter the rows which have to be updated */
  where: TeamsBoolExp;
};

/** columns and relationships of "teams_users_rel" */
export type TeamsUsers = {
  __typename?: 'TeamsUsers';
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  createdBy?: Maybe<Users>;
  createdById?: Maybe<Scalars['uuid']>;
  role: Scalars['String'];
  /** An object relationship */
  team: Teams;
  teamId: Scalars['uuid'];
  updatedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  updatedBy?: Maybe<Users>;
  updatedById?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user: Users;
  userId: Scalars['uuid'];
};

/** aggregated selection of "teams_users_rel" */
export type TeamsUsersAggregate = {
  __typename?: 'TeamsUsersAggregate';
  aggregate?: Maybe<TeamsUsersAggregateFields>;
  nodes: Array<TeamsUsers>;
};

export type TeamsUsersAggregateBoolExp = {
  count?: InputMaybe<TeamsUsersAggregateBoolExpCount>;
};

export type TeamsUsersAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TeamsUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TeamsUsersBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "teams_users_rel" */
export type TeamsUsersAggregateFields = {
  __typename?: 'TeamsUsersAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<TeamsUsersMaxFields>;
  min?: Maybe<TeamsUsersMinFields>;
};

/** aggregate fields of "teams_users_rel" */
export type TeamsUsersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TeamsUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "teams_users_rel" */
export type TeamsUsersAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TeamsUsersMaxOrderBy>;
  min?: InputMaybe<TeamsUsersMinOrderBy>;
};

/** input type for inserting array relation for remote table "teams_users_rel" */
export type TeamsUsersArrRelInsertInput = {
  data: Array<TeamsUsersInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TeamsUsersOnConflict>;
};

/** Boolean expression to filter rows from the table "teams_users_rel". All fields are combined with a logical 'AND'. */
export type TeamsUsersBoolExp = {
  _and?: InputMaybe<Array<TeamsUsersBoolExp>>;
  _not?: InputMaybe<TeamsUsersBoolExp>;
  _or?: InputMaybe<Array<TeamsUsersBoolExp>>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  createdBy?: InputMaybe<UsersBoolExp>;
  createdById?: InputMaybe<UuidComparisonExp>;
  role?: InputMaybe<StringComparisonExp>;
  team?: InputMaybe<TeamsBoolExp>;
  teamId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  updatedBy?: InputMaybe<UsersBoolExp>;
  updatedById?: InputMaybe<UuidComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "teams_users_rel" */
export enum TeamsUsersConstraint {
  /** unique or primary key constraint on columns "user_id", "team_id" */
  teams_users_rel_pk = 'teams_users_rel_pk'
}

/** input type for inserting data into table "teams_users_rel" */
export type TeamsUsersInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<UsersObjRelInsertInput>;
  createdById?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Scalars['String']>;
  team?: InputMaybe<TeamsObjRelInsertInput>;
  teamId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedBy?: InputMaybe<UsersObjRelInsertInput>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type TeamsUsersMaxFields = {
  __typename?: 'TeamsUsersMaxFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "teams_users_rel" */
export type TeamsUsersMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TeamsUsersMinFields = {
  __typename?: 'TeamsUsersMinFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdById?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  updatedById?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "teams_users_rel" */
export type TeamsUsersMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdById?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedById?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "teams_users_rel" */
export type TeamsUsersMutationResponse = {
  __typename?: 'TeamsUsersMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TeamsUsers>;
};

/** on_conflict condition type for table "teams_users_rel" */
export type TeamsUsersOnConflict = {
  constraint: TeamsUsersConstraint;
  updateColumns?: Array<TeamsUsersUpdateColumn>;
  where?: InputMaybe<TeamsUsersBoolExp>;
};

/** Ordering options when selecting data from "teams_users_rel". */
export type TeamsUsersOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UsersOrderBy>;
  createdById?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  team?: InputMaybe<TeamsOrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<UsersOrderBy>;
  updatedById?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: teams_users_rel */
export type TeamsUsersPkColumnsInput = {
  teamId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

/** select columns of table "teams_users_rel" */
export enum TeamsUsersSelectColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  role = 'role',
  /** column name */
  teamId = 'teamId',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById',
  /** column name */
  userId = 'userId'
}

/** input type for updating data in table "teams_users_rel" */
export type TeamsUsersSetInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "TeamsUsers" */
export type TeamsUsersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TeamsUsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TeamsUsersStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdById?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  updatedById?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "teams_users_rel" */
export enum TeamsUsersUpdateColumn {
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  createdById = 'createdById',
  /** column name */
  role = 'role',
  /** column name */
  teamId = 'teamId',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  updatedById = 'updatedById',
  /** column name */
  userId = 'userId'
}

export type TeamsUsersUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TeamsUsersSetInput>;
  /** filter the rows which have to be updated */
  where: TeamsUsersBoolExp;
};

/** columns and relationships of "time_elapsed_live_classes" */
export type TimeElapsedLiveClasses = {
  __typename?: 'TimeElapsedLiveClasses';
  cohortId?: Maybe<Scalars['uuid']>;
  numLiveClassesElapsed?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "time_elapsed_live_classes" */
export type TimeElapsedLiveClassesAggregate = {
  __typename?: 'TimeElapsedLiveClassesAggregate';
  aggregate?: Maybe<TimeElapsedLiveClassesAggregateFields>;
  nodes: Array<TimeElapsedLiveClasses>;
};

/** aggregate fields of "time_elapsed_live_classes" */
export type TimeElapsedLiveClassesAggregateFields = {
  __typename?: 'TimeElapsedLiveClassesAggregateFields';
  avg?: Maybe<TimeElapsedLiveClassesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TimeElapsedLiveClassesMaxFields>;
  min?: Maybe<TimeElapsedLiveClassesMinFields>;
  stddev?: Maybe<TimeElapsedLiveClassesStddevFields>;
  stddevPop?: Maybe<TimeElapsedLiveClassesStddevPopFields>;
  stddevSamp?: Maybe<TimeElapsedLiveClassesStddevSampFields>;
  sum?: Maybe<TimeElapsedLiveClassesSumFields>;
  varPop?: Maybe<TimeElapsedLiveClassesVarPopFields>;
  varSamp?: Maybe<TimeElapsedLiveClassesVarSampFields>;
  variance?: Maybe<TimeElapsedLiveClassesVarianceFields>;
};

/** aggregate fields of "time_elapsed_live_classes" */
export type TimeElapsedLiveClassesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TimeElapsedLiveClassesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type TimeElapsedLiveClassesAvgFields = {
  __typename?: 'TimeElapsedLiveClassesAvgFields';
  numLiveClassesElapsed?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "time_elapsed_live_classes". All fields are combined with a logical 'AND'. */
export type TimeElapsedLiveClassesBoolExp = {
  _and?: InputMaybe<Array<TimeElapsedLiveClassesBoolExp>>;
  _not?: InputMaybe<TimeElapsedLiveClassesBoolExp>;
  _or?: InputMaybe<Array<TimeElapsedLiveClassesBoolExp>>;
  cohortId?: InputMaybe<UuidComparisonExp>;
  numLiveClassesElapsed?: InputMaybe<BigintComparisonExp>;
};

/** aggregate max on columns */
export type TimeElapsedLiveClassesMaxFields = {
  __typename?: 'TimeElapsedLiveClassesMaxFields';
  cohortId?: Maybe<Scalars['uuid']>;
  numLiveClassesElapsed?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type TimeElapsedLiveClassesMinFields = {
  __typename?: 'TimeElapsedLiveClassesMinFields';
  cohortId?: Maybe<Scalars['uuid']>;
  numLiveClassesElapsed?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "time_elapsed_live_classes". */
export type TimeElapsedLiveClassesOrderBy = {
  cohortId?: InputMaybe<OrderBy>;
  numLiveClassesElapsed?: InputMaybe<OrderBy>;
};

/** select columns of table "time_elapsed_live_classes" */
export enum TimeElapsedLiveClassesSelectColumn {
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  numLiveClassesElapsed = 'numLiveClassesElapsed'
}

/** aggregate stddev on columns */
export type TimeElapsedLiveClassesStddevFields = {
  __typename?: 'TimeElapsedLiveClassesStddevFields';
  numLiveClassesElapsed?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type TimeElapsedLiveClassesStddevPopFields = {
  __typename?: 'TimeElapsedLiveClassesStddevPopFields';
  numLiveClassesElapsed?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type TimeElapsedLiveClassesStddevSampFields = {
  __typename?: 'TimeElapsedLiveClassesStddevSampFields';
  numLiveClassesElapsed?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "time_elapsed_live_classes" */
export type TimeElapsedLiveClassesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TimeElapsedLiveClassesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TimeElapsedLiveClassesStreamCursorValueInput = {
  cohortId?: InputMaybe<Scalars['uuid']>;
  numLiveClassesElapsed?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type TimeElapsedLiveClassesSumFields = {
  __typename?: 'TimeElapsedLiveClassesSumFields';
  numLiveClassesElapsed?: Maybe<Scalars['bigint']>;
};

/** aggregate varPop on columns */
export type TimeElapsedLiveClassesVarPopFields = {
  __typename?: 'TimeElapsedLiveClassesVarPopFields';
  numLiveClassesElapsed?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type TimeElapsedLiveClassesVarSampFields = {
  __typename?: 'TimeElapsedLiveClassesVarSampFields';
  numLiveClassesElapsed?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type TimeElapsedLiveClassesVarianceFields = {
  __typename?: 'TimeElapsedLiveClassesVarianceFields';
  numLiveClassesElapsed?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type TimestampComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "typeform_submissions" */
export type TypeformSubmissions = {
  __typename?: 'TypeformSubmissions';
  classEventId?: Maybe<Scalars['uuid']>;
  cohortId?: Maybe<Scalars['uuid']>;
  courseId?: Maybe<Scalars['uuid']>;
  data?: Maybe<Scalars['jsonb']>;
  eventId?: Maybe<Scalars['String']>;
  formId: Scalars['String'];
  id: Scalars['uuid'];
  responseId?: Maybe<Scalars['String']>;
  submittedAt: Scalars['timestamp'];
  userId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "typeform_submissions" */
export type TypeformSubmissionsDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "typeform_submissions" */
export type TypeformSubmissionsAggregate = {
  __typename?: 'TypeformSubmissionsAggregate';
  aggregate?: Maybe<TypeformSubmissionsAggregateFields>;
  nodes: Array<TypeformSubmissions>;
};

/** aggregate fields of "typeform_submissions" */
export type TypeformSubmissionsAggregateFields = {
  __typename?: 'TypeformSubmissionsAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<TypeformSubmissionsMaxFields>;
  min?: Maybe<TypeformSubmissionsMinFields>;
};

/** aggregate fields of "typeform_submissions" */
export type TypeformSubmissionsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TypeformSubmissionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type TypeformSubmissionsAppendInput = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "typeform_submissions". All fields are combined with a logical 'AND'. */
export type TypeformSubmissionsBoolExp = {
  _and?: InputMaybe<Array<TypeformSubmissionsBoolExp>>;
  _not?: InputMaybe<TypeformSubmissionsBoolExp>;
  _or?: InputMaybe<Array<TypeformSubmissionsBoolExp>>;
  classEventId?: InputMaybe<UuidComparisonExp>;
  cohortId?: InputMaybe<UuidComparisonExp>;
  courseId?: InputMaybe<UuidComparisonExp>;
  data?: InputMaybe<JsonbComparisonExp>;
  eventId?: InputMaybe<StringComparisonExp>;
  formId?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  responseId?: InputMaybe<StringComparisonExp>;
  submittedAt?: InputMaybe<TimestampComparisonExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "typeform_submissions" */
export enum TypeformSubmissionsConstraint {
  /** unique or primary key constraint on columns "id" */
  typeform_submissions_pkey = 'typeform_submissions_pkey',
  /** unique or primary key constraint on columns "form_id", "response_id" */
  typeform_submissions_response_id_form_id_key = 'typeform_submissions_response_id_form_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type TypeformSubmissionsDeleteAtPathInput = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type TypeformSubmissionsDeleteElemInput = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type TypeformSubmissionsDeleteKeyInput = {
  data?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "typeform_submissions" */
export type TypeformSubmissionsInsertInput = {
  classEventId?: InputMaybe<Scalars['uuid']>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  courseId?: InputMaybe<Scalars['uuid']>;
  data?: InputMaybe<Scalars['jsonb']>;
  eventId?: InputMaybe<Scalars['String']>;
  formId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  responseId?: InputMaybe<Scalars['String']>;
  submittedAt?: InputMaybe<Scalars['timestamp']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type TypeformSubmissionsMaxFields = {
  __typename?: 'TypeformSubmissionsMaxFields';
  classEventId?: Maybe<Scalars['uuid']>;
  cohortId?: Maybe<Scalars['uuid']>;
  courseId?: Maybe<Scalars['uuid']>;
  eventId?: Maybe<Scalars['String']>;
  formId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  responseId?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['timestamp']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type TypeformSubmissionsMinFields = {
  __typename?: 'TypeformSubmissionsMinFields';
  classEventId?: Maybe<Scalars['uuid']>;
  cohortId?: Maybe<Scalars['uuid']>;
  courseId?: Maybe<Scalars['uuid']>;
  eventId?: Maybe<Scalars['String']>;
  formId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  responseId?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['timestamp']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "typeform_submissions" */
export type TypeformSubmissionsMutationResponse = {
  __typename?: 'TypeformSubmissionsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TypeformSubmissions>;
};

/** on_conflict condition type for table "typeform_submissions" */
export type TypeformSubmissionsOnConflict = {
  constraint: TypeformSubmissionsConstraint;
  updateColumns?: Array<TypeformSubmissionsUpdateColumn>;
  where?: InputMaybe<TypeformSubmissionsBoolExp>;
};

/** Ordering options when selecting data from "typeform_submissions". */
export type TypeformSubmissionsOrderBy = {
  classEventId?: InputMaybe<OrderBy>;
  cohortId?: InputMaybe<OrderBy>;
  courseId?: InputMaybe<OrderBy>;
  data?: InputMaybe<OrderBy>;
  eventId?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  responseId?: InputMaybe<OrderBy>;
  submittedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: typeform_submissions */
export type TypeformSubmissionsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type TypeformSubmissionsPrependInput = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "typeform_submissions" */
export enum TypeformSubmissionsSelectColumn {
  /** column name */
  classEventId = 'classEventId',
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  courseId = 'courseId',
  /** column name */
  data = 'data',
  /** column name */
  eventId = 'eventId',
  /** column name */
  formId = 'formId',
  /** column name */
  id = 'id',
  /** column name */
  responseId = 'responseId',
  /** column name */
  submittedAt = 'submittedAt',
  /** column name */
  userId = 'userId'
}

/** input type for updating data in table "typeform_submissions" */
export type TypeformSubmissionsSetInput = {
  classEventId?: InputMaybe<Scalars['uuid']>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  courseId?: InputMaybe<Scalars['uuid']>;
  data?: InputMaybe<Scalars['jsonb']>;
  eventId?: InputMaybe<Scalars['String']>;
  formId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  responseId?: InputMaybe<Scalars['String']>;
  submittedAt?: InputMaybe<Scalars['timestamp']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "typeform_submissions" */
export type TypeformSubmissionsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TypeformSubmissionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TypeformSubmissionsStreamCursorValueInput = {
  classEventId?: InputMaybe<Scalars['uuid']>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  courseId?: InputMaybe<Scalars['uuid']>;
  data?: InputMaybe<Scalars['jsonb']>;
  eventId?: InputMaybe<Scalars['String']>;
  formId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  responseId?: InputMaybe<Scalars['String']>;
  submittedAt?: InputMaybe<Scalars['timestamp']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "typeform_submissions" */
export enum TypeformSubmissionsUpdateColumn {
  /** column name */
  classEventId = 'classEventId',
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  courseId = 'courseId',
  /** column name */
  data = 'data',
  /** column name */
  eventId = 'eventId',
  /** column name */
  formId = 'formId',
  /** column name */
  id = 'id',
  /** column name */
  responseId = 'responseId',
  /** column name */
  submittedAt = 'submittedAt',
  /** column name */
  userId = 'userId'
}

export type TypeformSubmissionsUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<TypeformSubmissionsAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<TypeformSubmissionsDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<TypeformSubmissionsDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<TypeformSubmissionsDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<TypeformSubmissionsPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TypeformSubmissionsSetInput>;
  /** filter the rows which have to be updated */
  where: TypeformSubmissionsBoolExp;
};

export type UnenrollInput = {
  cohortId: Scalars['uuid'];
  onBehalfOfUserId?: InputMaybe<Scalars['uuid']>;
  reason?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['uuid']>;
  waitlistEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type UnenrollOutput = {
  __typename?: 'UnenrollOutput';
  enrollmentId?: Maybe<Scalars['uuid']>;
  unenrollmentStatus?: Maybe<Scalars['Boolean']>;
  waitlistId?: Maybe<Scalars['uuid']>;
};

export type UpdatePlaybackInput = {
  classEventId?: InputMaybe<Scalars['uuid']>;
  hash?: InputMaybe<Scalars['String']>;
  objects: Array<PlaybackTimes>;
  playbackType: Scalars['String'];
};

export type UpdatePlaybackOutput = {
  __typename?: 'UpdatePlaybackOutput';
  success?: Maybe<Scalars['Boolean']>;
};

/** columns and relationships of "user_completed_surveys" */
export type UserCompletedSurveys = {
  __typename?: 'UserCompletedSurveys';
  cohortId?: Maybe<Scalars['uuid']>;
  numCompleted?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "user_completed_surveys" */
export type UserCompletedSurveysAggregate = {
  __typename?: 'UserCompletedSurveysAggregate';
  aggregate?: Maybe<UserCompletedSurveysAggregateFields>;
  nodes: Array<UserCompletedSurveys>;
};

/** aggregate fields of "user_completed_surveys" */
export type UserCompletedSurveysAggregateFields = {
  __typename?: 'UserCompletedSurveysAggregateFields';
  avg?: Maybe<UserCompletedSurveysAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<UserCompletedSurveysMaxFields>;
  min?: Maybe<UserCompletedSurveysMinFields>;
  stddev?: Maybe<UserCompletedSurveysStddevFields>;
  stddevPop?: Maybe<UserCompletedSurveysStddevPopFields>;
  stddevSamp?: Maybe<UserCompletedSurveysStddevSampFields>;
  sum?: Maybe<UserCompletedSurveysSumFields>;
  varPop?: Maybe<UserCompletedSurveysVarPopFields>;
  varSamp?: Maybe<UserCompletedSurveysVarSampFields>;
  variance?: Maybe<UserCompletedSurveysVarianceFields>;
};

/** aggregate fields of "user_completed_surveys" */
export type UserCompletedSurveysAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserCompletedSurveysSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type UserCompletedSurveysAvgFields = {
  __typename?: 'UserCompletedSurveysAvgFields';
  numCompleted?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_completed_surveys". All fields are combined with a logical 'AND'. */
export type UserCompletedSurveysBoolExp = {
  _and?: InputMaybe<Array<UserCompletedSurveysBoolExp>>;
  _not?: InputMaybe<UserCompletedSurveysBoolExp>;
  _or?: InputMaybe<Array<UserCompletedSurveysBoolExp>>;
  cohortId?: InputMaybe<UuidComparisonExp>;
  numCompleted?: InputMaybe<BigintComparisonExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** aggregate max on columns */
export type UserCompletedSurveysMaxFields = {
  __typename?: 'UserCompletedSurveysMaxFields';
  cohortId?: Maybe<Scalars['uuid']>;
  numCompleted?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type UserCompletedSurveysMinFields = {
  __typename?: 'UserCompletedSurveysMinFields';
  cohortId?: Maybe<Scalars['uuid']>;
  numCompleted?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "user_completed_surveys". */
export type UserCompletedSurveysOrderBy = {
  cohortId?: InputMaybe<OrderBy>;
  numCompleted?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** select columns of table "user_completed_surveys" */
export enum UserCompletedSurveysSelectColumn {
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  numCompleted = 'numCompleted',
  /** column name */
  userId = 'userId'
}

/** aggregate stddev on columns */
export type UserCompletedSurveysStddevFields = {
  __typename?: 'UserCompletedSurveysStddevFields';
  numCompleted?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type UserCompletedSurveysStddevPopFields = {
  __typename?: 'UserCompletedSurveysStddevPopFields';
  numCompleted?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type UserCompletedSurveysStddevSampFields = {
  __typename?: 'UserCompletedSurveysStddevSampFields';
  numCompleted?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_completed_surveys" */
export type UserCompletedSurveysStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: UserCompletedSurveysStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserCompletedSurveysStreamCursorValueInput = {
  cohortId?: InputMaybe<Scalars['uuid']>;
  numCompleted?: InputMaybe<Scalars['bigint']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type UserCompletedSurveysSumFields = {
  __typename?: 'UserCompletedSurveysSumFields';
  numCompleted?: Maybe<Scalars['bigint']>;
};

/** aggregate varPop on columns */
export type UserCompletedSurveysVarPopFields = {
  __typename?: 'UserCompletedSurveysVarPopFields';
  numCompleted?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type UserCompletedSurveysVarSampFields = {
  __typename?: 'UserCompletedSurveysVarSampFields';
  numCompleted?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type UserCompletedSurveysVarianceFields = {
  __typename?: 'UserCompletedSurveysVarianceFields';
  numCompleted?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "user_num_completed_classes" */
export type UserNumCompletedClasses = {
  __typename?: 'UserNumCompletedClasses';
  activityDate?: Maybe<Scalars['timestamp']>;
  classEventStartDate?: Maybe<Scalars['timestamp']>;
  cohortId?: Maybe<Scalars['uuid']>;
  numCompleted?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "user_num_completed_classes" */
export type UserNumCompletedClassesAggregate = {
  __typename?: 'UserNumCompletedClassesAggregate';
  aggregate?: Maybe<UserNumCompletedClassesAggregateFields>;
  nodes: Array<UserNumCompletedClasses>;
};

/** aggregate fields of "user_num_completed_classes" */
export type UserNumCompletedClassesAggregateFields = {
  __typename?: 'UserNumCompletedClassesAggregateFields';
  avg?: Maybe<UserNumCompletedClassesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<UserNumCompletedClassesMaxFields>;
  min?: Maybe<UserNumCompletedClassesMinFields>;
  stddev?: Maybe<UserNumCompletedClassesStddevFields>;
  stddevPop?: Maybe<UserNumCompletedClassesStddevPopFields>;
  stddevSamp?: Maybe<UserNumCompletedClassesStddevSampFields>;
  sum?: Maybe<UserNumCompletedClassesSumFields>;
  varPop?: Maybe<UserNumCompletedClassesVarPopFields>;
  varSamp?: Maybe<UserNumCompletedClassesVarSampFields>;
  variance?: Maybe<UserNumCompletedClassesVarianceFields>;
};

/** aggregate fields of "user_num_completed_classes" */
export type UserNumCompletedClassesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserNumCompletedClassesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type UserNumCompletedClassesAvgFields = {
  __typename?: 'UserNumCompletedClassesAvgFields';
  numCompleted?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_num_completed_classes". All fields are combined with a logical 'AND'. */
export type UserNumCompletedClassesBoolExp = {
  _and?: InputMaybe<Array<UserNumCompletedClassesBoolExp>>;
  _not?: InputMaybe<UserNumCompletedClassesBoolExp>;
  _or?: InputMaybe<Array<UserNumCompletedClassesBoolExp>>;
  activityDate?: InputMaybe<TimestampComparisonExp>;
  classEventStartDate?: InputMaybe<TimestampComparisonExp>;
  cohortId?: InputMaybe<UuidComparisonExp>;
  numCompleted?: InputMaybe<BigintComparisonExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** aggregate max on columns */
export type UserNumCompletedClassesMaxFields = {
  __typename?: 'UserNumCompletedClassesMaxFields';
  activityDate?: Maybe<Scalars['timestamp']>;
  classEventStartDate?: Maybe<Scalars['timestamp']>;
  cohortId?: Maybe<Scalars['uuid']>;
  numCompleted?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type UserNumCompletedClassesMinFields = {
  __typename?: 'UserNumCompletedClassesMinFields';
  activityDate?: Maybe<Scalars['timestamp']>;
  classEventStartDate?: Maybe<Scalars['timestamp']>;
  cohortId?: Maybe<Scalars['uuid']>;
  numCompleted?: Maybe<Scalars['bigint']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "user_num_completed_classes". */
export type UserNumCompletedClassesOrderBy = {
  activityDate?: InputMaybe<OrderBy>;
  classEventStartDate?: InputMaybe<OrderBy>;
  cohortId?: InputMaybe<OrderBy>;
  numCompleted?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** select columns of table "user_num_completed_classes" */
export enum UserNumCompletedClassesSelectColumn {
  /** column name */
  activityDate = 'activityDate',
  /** column name */
  classEventStartDate = 'classEventStartDate',
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  numCompleted = 'numCompleted',
  /** column name */
  userId = 'userId'
}

/** aggregate stddev on columns */
export type UserNumCompletedClassesStddevFields = {
  __typename?: 'UserNumCompletedClassesStddevFields';
  numCompleted?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type UserNumCompletedClassesStddevPopFields = {
  __typename?: 'UserNumCompletedClassesStddevPopFields';
  numCompleted?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type UserNumCompletedClassesStddevSampFields = {
  __typename?: 'UserNumCompletedClassesStddevSampFields';
  numCompleted?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_num_completed_classes" */
export type UserNumCompletedClassesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: UserNumCompletedClassesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserNumCompletedClassesStreamCursorValueInput = {
  activityDate?: InputMaybe<Scalars['timestamp']>;
  classEventStartDate?: InputMaybe<Scalars['timestamp']>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  numCompleted?: InputMaybe<Scalars['bigint']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type UserNumCompletedClassesSumFields = {
  __typename?: 'UserNumCompletedClassesSumFields';
  numCompleted?: Maybe<Scalars['bigint']>;
};

/** aggregate varPop on columns */
export type UserNumCompletedClassesVarPopFields = {
  __typename?: 'UserNumCompletedClassesVarPopFields';
  numCompleted?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type UserNumCompletedClassesVarSampFields = {
  __typename?: 'UserNumCompletedClassesVarSampFields';
  numCompleted?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type UserNumCompletedClassesVarianceFields = {
  __typename?: 'UserNumCompletedClassesVarianceFields';
  numCompleted?: Maybe<Scalars['Float']>;
};

export type UserProfileCaptureOutput = {
  __typename?: 'UserProfileCaptureOutput';
  id: Scalars['String'];
};

/** columns and relationships of "user_session_emails" */
export type UserSessionEmails = {
  __typename?: 'UserSessionEmails';
  attendanceStatus: Scalars['String'];
  /** An object relationship */
  classEvent: ClassEvents;
  classEventEndTime: Scalars['timestamptz'];
  classEventStartTime: Scalars['timestamptz'];
  classEventsId: Scalars['uuid'];
  /** An object relationship */
  cohort: Cohorts;
  cohortId: Scalars['uuid'];
  cohortSlug: Scalars['String'];
  cohortType: Scalars['String'];
  courseName: Scalars['String'];
  courseType: Scalars['String'];
  creditModel?: Maybe<Scalars['String']>;
  /** An object relationship */
  enrollment: Enrollments;
  enrollmentId: Scalars['uuid'];
  formattedTimestamp: Scalars['String'];
  frameworkUrl?: Maybe<Scalars['String']>;
  hasSentHourReminderEmail?: Maybe<Scalars['Boolean']>;
  hasSentMondayReminderEmail?: Maybe<Scalars['Boolean']>;
  isReturningUser: Scalars['Boolean'];
  isStartingSoon: Scalars['Boolean'];
  isThisWeek: Scalars['Boolean'];
  leadCoachCompany?: Maybe<Scalars['String']>;
  leadCoachName?: Maybe<Scalars['String']>;
  sentHourReminderEmailAt?: Maybe<Scalars['timestamptz']>;
  sentMondayReminderEmailAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user: Users;
  userClassEventId: Scalars['String'];
  userId: Scalars['uuid'];
};

/** aggregated selection of "user_session_emails" */
export type UserSessionEmailsAggregate = {
  __typename?: 'UserSessionEmailsAggregate';
  aggregate?: Maybe<UserSessionEmailsAggregateFields>;
  nodes: Array<UserSessionEmails>;
};

/** aggregate fields of "user_session_emails" */
export type UserSessionEmailsAggregateFields = {
  __typename?: 'UserSessionEmailsAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<UserSessionEmailsMaxFields>;
  min?: Maybe<UserSessionEmailsMinFields>;
};

/** aggregate fields of "user_session_emails" */
export type UserSessionEmailsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserSessionEmailsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_session_emails". All fields are combined with a logical 'AND'. */
export type UserSessionEmailsBoolExp = {
  _and?: InputMaybe<Array<UserSessionEmailsBoolExp>>;
  _not?: InputMaybe<UserSessionEmailsBoolExp>;
  _or?: InputMaybe<Array<UserSessionEmailsBoolExp>>;
  attendanceStatus?: InputMaybe<StringComparisonExp>;
  classEvent?: InputMaybe<ClassEventsBoolExp>;
  classEventEndTime?: InputMaybe<TimestamptzComparisonExp>;
  classEventStartTime?: InputMaybe<TimestamptzComparisonExp>;
  classEventsId?: InputMaybe<UuidComparisonExp>;
  cohort?: InputMaybe<CohortsBoolExp>;
  cohortId?: InputMaybe<UuidComparisonExp>;
  cohortSlug?: InputMaybe<StringComparisonExp>;
  cohortType?: InputMaybe<StringComparisonExp>;
  courseName?: InputMaybe<StringComparisonExp>;
  courseType?: InputMaybe<StringComparisonExp>;
  creditModel?: InputMaybe<StringComparisonExp>;
  enrollment?: InputMaybe<EnrollmentsBoolExp>;
  enrollmentId?: InputMaybe<UuidComparisonExp>;
  formattedTimestamp?: InputMaybe<StringComparisonExp>;
  frameworkUrl?: InputMaybe<StringComparisonExp>;
  hasSentHourReminderEmail?: InputMaybe<BooleanComparisonExp>;
  hasSentMondayReminderEmail?: InputMaybe<BooleanComparisonExp>;
  isReturningUser?: InputMaybe<BooleanComparisonExp>;
  isStartingSoon?: InputMaybe<BooleanComparisonExp>;
  isThisWeek?: InputMaybe<BooleanComparisonExp>;
  leadCoachCompany?: InputMaybe<StringComparisonExp>;
  leadCoachName?: InputMaybe<StringComparisonExp>;
  sentHourReminderEmailAt?: InputMaybe<TimestamptzComparisonExp>;
  sentMondayReminderEmailAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userClassEventId?: InputMaybe<StringComparisonExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "user_session_emails" */
export enum UserSessionEmailsConstraint {
  /** unique or primary key constraint on columns "user_class_event_id" */
  user_session_emails_pkey = 'user_session_emails_pkey'
}

/** input type for inserting data into table "user_session_emails" */
export type UserSessionEmailsInsertInput = {
  attendanceStatus?: InputMaybe<Scalars['String']>;
  classEvent?: InputMaybe<ClassEventsObjRelInsertInput>;
  classEventEndTime?: InputMaybe<Scalars['timestamptz']>;
  classEventStartTime?: InputMaybe<Scalars['timestamptz']>;
  classEventsId?: InputMaybe<Scalars['uuid']>;
  cohort?: InputMaybe<CohortsObjRelInsertInput>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  cohortSlug?: InputMaybe<Scalars['String']>;
  cohortType?: InputMaybe<Scalars['String']>;
  courseName?: InputMaybe<Scalars['String']>;
  courseType?: InputMaybe<Scalars['String']>;
  creditModel?: InputMaybe<Scalars['String']>;
  enrollment?: InputMaybe<EnrollmentsObjRelInsertInput>;
  enrollmentId?: InputMaybe<Scalars['uuid']>;
  formattedTimestamp?: InputMaybe<Scalars['String']>;
  frameworkUrl?: InputMaybe<Scalars['String']>;
  hasSentHourReminderEmail?: InputMaybe<Scalars['Boolean']>;
  hasSentMondayReminderEmail?: InputMaybe<Scalars['Boolean']>;
  isReturningUser?: InputMaybe<Scalars['Boolean']>;
  isStartingSoon?: InputMaybe<Scalars['Boolean']>;
  isThisWeek?: InputMaybe<Scalars['Boolean']>;
  leadCoachCompany?: InputMaybe<Scalars['String']>;
  leadCoachName?: InputMaybe<Scalars['String']>;
  sentHourReminderEmailAt?: InputMaybe<Scalars['timestamptz']>;
  sentMondayReminderEmailAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userClassEventId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type UserSessionEmailsMaxFields = {
  __typename?: 'UserSessionEmailsMaxFields';
  attendanceStatus?: Maybe<Scalars['String']>;
  classEventEndTime?: Maybe<Scalars['timestamptz']>;
  classEventStartTime?: Maybe<Scalars['timestamptz']>;
  classEventsId?: Maybe<Scalars['uuid']>;
  cohortId?: Maybe<Scalars['uuid']>;
  cohortSlug?: Maybe<Scalars['String']>;
  cohortType?: Maybe<Scalars['String']>;
  courseName?: Maybe<Scalars['String']>;
  courseType?: Maybe<Scalars['String']>;
  creditModel?: Maybe<Scalars['String']>;
  enrollmentId?: Maybe<Scalars['uuid']>;
  formattedTimestamp?: Maybe<Scalars['String']>;
  frameworkUrl?: Maybe<Scalars['String']>;
  leadCoachCompany?: Maybe<Scalars['String']>;
  leadCoachName?: Maybe<Scalars['String']>;
  sentHourReminderEmailAt?: Maybe<Scalars['timestamptz']>;
  sentMondayReminderEmailAt?: Maybe<Scalars['timestamptz']>;
  userClassEventId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type UserSessionEmailsMinFields = {
  __typename?: 'UserSessionEmailsMinFields';
  attendanceStatus?: Maybe<Scalars['String']>;
  classEventEndTime?: Maybe<Scalars['timestamptz']>;
  classEventStartTime?: Maybe<Scalars['timestamptz']>;
  classEventsId?: Maybe<Scalars['uuid']>;
  cohortId?: Maybe<Scalars['uuid']>;
  cohortSlug?: Maybe<Scalars['String']>;
  cohortType?: Maybe<Scalars['String']>;
  courseName?: Maybe<Scalars['String']>;
  courseType?: Maybe<Scalars['String']>;
  creditModel?: Maybe<Scalars['String']>;
  enrollmentId?: Maybe<Scalars['uuid']>;
  formattedTimestamp?: Maybe<Scalars['String']>;
  frameworkUrl?: Maybe<Scalars['String']>;
  leadCoachCompany?: Maybe<Scalars['String']>;
  leadCoachName?: Maybe<Scalars['String']>;
  sentHourReminderEmailAt?: Maybe<Scalars['timestamptz']>;
  sentMondayReminderEmailAt?: Maybe<Scalars['timestamptz']>;
  userClassEventId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_session_emails" */
export type UserSessionEmailsMutationResponse = {
  __typename?: 'UserSessionEmailsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserSessionEmails>;
};

/** on_conflict condition type for table "user_session_emails" */
export type UserSessionEmailsOnConflict = {
  constraint: UserSessionEmailsConstraint;
  updateColumns?: Array<UserSessionEmailsUpdateColumn>;
  where?: InputMaybe<UserSessionEmailsBoolExp>;
};

/** Ordering options when selecting data from "user_session_emails". */
export type UserSessionEmailsOrderBy = {
  attendanceStatus?: InputMaybe<OrderBy>;
  classEvent?: InputMaybe<ClassEventsOrderBy>;
  classEventEndTime?: InputMaybe<OrderBy>;
  classEventStartTime?: InputMaybe<OrderBy>;
  classEventsId?: InputMaybe<OrderBy>;
  cohort?: InputMaybe<CohortsOrderBy>;
  cohortId?: InputMaybe<OrderBy>;
  cohortSlug?: InputMaybe<OrderBy>;
  cohortType?: InputMaybe<OrderBy>;
  courseName?: InputMaybe<OrderBy>;
  courseType?: InputMaybe<OrderBy>;
  creditModel?: InputMaybe<OrderBy>;
  enrollment?: InputMaybe<EnrollmentsOrderBy>;
  enrollmentId?: InputMaybe<OrderBy>;
  formattedTimestamp?: InputMaybe<OrderBy>;
  frameworkUrl?: InputMaybe<OrderBy>;
  hasSentHourReminderEmail?: InputMaybe<OrderBy>;
  hasSentMondayReminderEmail?: InputMaybe<OrderBy>;
  isReturningUser?: InputMaybe<OrderBy>;
  isStartingSoon?: InputMaybe<OrderBy>;
  isThisWeek?: InputMaybe<OrderBy>;
  leadCoachCompany?: InputMaybe<OrderBy>;
  leadCoachName?: InputMaybe<OrderBy>;
  sentHourReminderEmailAt?: InputMaybe<OrderBy>;
  sentMondayReminderEmailAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userClassEventId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: user_session_emails */
export type UserSessionEmailsPkColumnsInput = {
  userClassEventId: Scalars['String'];
};

/** select columns of table "user_session_emails" */
export enum UserSessionEmailsSelectColumn {
  /** column name */
  attendanceStatus = 'attendanceStatus',
  /** column name */
  classEventEndTime = 'classEventEndTime',
  /** column name */
  classEventStartTime = 'classEventStartTime',
  /** column name */
  classEventsId = 'classEventsId',
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  cohortSlug = 'cohortSlug',
  /** column name */
  cohortType = 'cohortType',
  /** column name */
  courseName = 'courseName',
  /** column name */
  courseType = 'courseType',
  /** column name */
  creditModel = 'creditModel',
  /** column name */
  enrollmentId = 'enrollmentId',
  /** column name */
  formattedTimestamp = 'formattedTimestamp',
  /** column name */
  frameworkUrl = 'frameworkUrl',
  /** column name */
  hasSentHourReminderEmail = 'hasSentHourReminderEmail',
  /** column name */
  hasSentMondayReminderEmail = 'hasSentMondayReminderEmail',
  /** column name */
  isReturningUser = 'isReturningUser',
  /** column name */
  isStartingSoon = 'isStartingSoon',
  /** column name */
  isThisWeek = 'isThisWeek',
  /** column name */
  leadCoachCompany = 'leadCoachCompany',
  /** column name */
  leadCoachName = 'leadCoachName',
  /** column name */
  sentHourReminderEmailAt = 'sentHourReminderEmailAt',
  /** column name */
  sentMondayReminderEmailAt = 'sentMondayReminderEmailAt',
  /** column name */
  userClassEventId = 'userClassEventId',
  /** column name */
  userId = 'userId'
}

/** input type for updating data in table "user_session_emails" */
export type UserSessionEmailsSetInput = {
  attendanceStatus?: InputMaybe<Scalars['String']>;
  classEventEndTime?: InputMaybe<Scalars['timestamptz']>;
  classEventStartTime?: InputMaybe<Scalars['timestamptz']>;
  classEventsId?: InputMaybe<Scalars['uuid']>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  cohortSlug?: InputMaybe<Scalars['String']>;
  cohortType?: InputMaybe<Scalars['String']>;
  courseName?: InputMaybe<Scalars['String']>;
  courseType?: InputMaybe<Scalars['String']>;
  creditModel?: InputMaybe<Scalars['String']>;
  enrollmentId?: InputMaybe<Scalars['uuid']>;
  formattedTimestamp?: InputMaybe<Scalars['String']>;
  frameworkUrl?: InputMaybe<Scalars['String']>;
  hasSentHourReminderEmail?: InputMaybe<Scalars['Boolean']>;
  hasSentMondayReminderEmail?: InputMaybe<Scalars['Boolean']>;
  isReturningUser?: InputMaybe<Scalars['Boolean']>;
  isStartingSoon?: InputMaybe<Scalars['Boolean']>;
  isThisWeek?: InputMaybe<Scalars['Boolean']>;
  leadCoachCompany?: InputMaybe<Scalars['String']>;
  leadCoachName?: InputMaybe<Scalars['String']>;
  sentHourReminderEmailAt?: InputMaybe<Scalars['timestamptz']>;
  sentMondayReminderEmailAt?: InputMaybe<Scalars['timestamptz']>;
  userClassEventId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_session_emails" */
export type UserSessionEmailsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: UserSessionEmailsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserSessionEmailsStreamCursorValueInput = {
  attendanceStatus?: InputMaybe<Scalars['String']>;
  classEventEndTime?: InputMaybe<Scalars['timestamptz']>;
  classEventStartTime?: InputMaybe<Scalars['timestamptz']>;
  classEventsId?: InputMaybe<Scalars['uuid']>;
  cohortId?: InputMaybe<Scalars['uuid']>;
  cohortSlug?: InputMaybe<Scalars['String']>;
  cohortType?: InputMaybe<Scalars['String']>;
  courseName?: InputMaybe<Scalars['String']>;
  courseType?: InputMaybe<Scalars['String']>;
  creditModel?: InputMaybe<Scalars['String']>;
  enrollmentId?: InputMaybe<Scalars['uuid']>;
  formattedTimestamp?: InputMaybe<Scalars['String']>;
  frameworkUrl?: InputMaybe<Scalars['String']>;
  hasSentHourReminderEmail?: InputMaybe<Scalars['Boolean']>;
  hasSentMondayReminderEmail?: InputMaybe<Scalars['Boolean']>;
  isReturningUser?: InputMaybe<Scalars['Boolean']>;
  isStartingSoon?: InputMaybe<Scalars['Boolean']>;
  isThisWeek?: InputMaybe<Scalars['Boolean']>;
  leadCoachCompany?: InputMaybe<Scalars['String']>;
  leadCoachName?: InputMaybe<Scalars['String']>;
  sentHourReminderEmailAt?: InputMaybe<Scalars['timestamptz']>;
  sentMondayReminderEmailAt?: InputMaybe<Scalars['timestamptz']>;
  userClassEventId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_session_emails" */
export enum UserSessionEmailsUpdateColumn {
  /** column name */
  attendanceStatus = 'attendanceStatus',
  /** column name */
  classEventEndTime = 'classEventEndTime',
  /** column name */
  classEventStartTime = 'classEventStartTime',
  /** column name */
  classEventsId = 'classEventsId',
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  cohortSlug = 'cohortSlug',
  /** column name */
  cohortType = 'cohortType',
  /** column name */
  courseName = 'courseName',
  /** column name */
  courseType = 'courseType',
  /** column name */
  creditModel = 'creditModel',
  /** column name */
  enrollmentId = 'enrollmentId',
  /** column name */
  formattedTimestamp = 'formattedTimestamp',
  /** column name */
  frameworkUrl = 'frameworkUrl',
  /** column name */
  hasSentHourReminderEmail = 'hasSentHourReminderEmail',
  /** column name */
  hasSentMondayReminderEmail = 'hasSentMondayReminderEmail',
  /** column name */
  isReturningUser = 'isReturningUser',
  /** column name */
  isStartingSoon = 'isStartingSoon',
  /** column name */
  isThisWeek = 'isThisWeek',
  /** column name */
  leadCoachCompany = 'leadCoachCompany',
  /** column name */
  leadCoachName = 'leadCoachName',
  /** column name */
  sentHourReminderEmailAt = 'sentHourReminderEmailAt',
  /** column name */
  sentMondayReminderEmailAt = 'sentMondayReminderEmailAt',
  /** column name */
  userClassEventId = 'userClassEventId',
  /** column name */
  userId = 'userId'
}

export type UserSessionEmailsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserSessionEmailsSetInput>;
  /** filter the rows which have to be updated */
  where: UserSessionEmailsBoolExp;
};

/** columns and relationships of "fusionauth.users" */
export type Users = {
  __typename?: 'Users';
  active: Scalars['Boolean'];
  activeSubscription?: Maybe<Array<Plans>>;
  /** A computed field, executes function "allow_enrollments_computed" */
  allowEnrollmentsBySubscription?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['bpchar']>;
  /** An array relationship */
  classEventMeetingParticipants: Array<ClassEventMeetingParticipants>;
  /** An aggregate relationship */
  classEventMeetingParticipantsAggregate: ClassEventMeetingParticipantsAggregate;
  cleanSpeakId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  coachLightningSessionSubmissions: Array<LightningSessionSubmissions>;
  /** An aggregate relationship */
  coachLightningSessionSubmissionsAggregate: LightningSessionSubmissionsAggregate;
  /** An array relationship */
  coaches: Array<Coaches>;
  /** An aggregate relationship */
  coachesAggregate: CoachesAggregate;
  data?: Maybe<Scalars['String']>;
  /** An array relationship */
  enrollments: Array<Enrollments>;
  /** An aggregate relationship */
  enrollmentsAggregate: EnrollmentsAggregate;
  expiry?: Maybe<Scalars['bigint']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An array relationship */
  identity: Array<Identities>;
  /** An aggregate relationship */
  identityAggregate: IdentitiesAggregate;
  /** An array relationship */
  identityProviderLinks: Array<IdentityProviderLinks>;
  /** An aggregate relationship */
  identityProviderLinksAggregate: IdentityProviderLinksAggregate;
  imageUrl?: Maybe<Scalars['String']>;
  insertInstant: Scalars['bigint'];
  /** An array relationship */
  journeys: Array<JourneyEnrollments>;
  /** An aggregate relationship */
  journeysAggregate: JourneyEnrollmentsAggregate;
  lastName?: Maybe<Scalars['String']>;
  lastUpdateInstant: Scalars['bigint'];
  /** An array relationship */
  learnerLightningSessionSubmissions: Array<LightningSessionSubmissions>;
  /** An aggregate relationship */
  learnerLightningSessionSubmissionsAggregate: LightningSessionSubmissionsAggregate;
  maxSubscriptionEnrollments?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  meta?: Maybe<UsersMeta>;
  middleName?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  /** An array relationship */
  organizationUsers: Array<OrganizationUsers>;
  /** An aggregate relationship */
  organizationUsersAggregate: OrganizationUsersAggregate;
  parentEmail?: Maybe<Scalars['String']>;
  /** An array relationship */
  teams: Array<TeamsUsers>;
  /** An aggregate relationship */
  teamsAggregate: TeamsUsersAggregate;
  tenantsId: Scalars['uuid'];
  timezone?: Maybe<Scalars['String']>;
  /** An array relationship */
  userRegistrations: Array<FusionauthUserRegistrations>;
  /** An aggregate relationship */
  userRegistrationsAggregate: FusionauthUserRegistrationsAggregate;
  /** An array relationship */
  usersClassEventActivities: Array<ClassEventActivity>;
  /** An aggregate relationship */
  usersClassEventActivitiesAggregate: ClassEventActivityAggregate;
  /** An array relationship */
  waitlists: Array<EnrollmentWaitlists>;
  /** An aggregate relationship */
  waitlistsAggregate: EnrollmentWaitlistsAggregate;
};

/** columns and relationships of "fusionauth.users" */
export type UsersActiveSubscriptionArgs = {
  distinctOn?: InputMaybe<Array<PlansSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlansOrderBy>>;
  where?: InputMaybe<PlansBoolExp>;
};

/** columns and relationships of "fusionauth.users" */
export type UsersClassEventMeetingParticipantsArgs = {
  distinctOn?: InputMaybe<Array<ClassEventMeetingParticipantsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventMeetingParticipantsOrderBy>>;
  where?: InputMaybe<ClassEventMeetingParticipantsBoolExp>;
};

/** columns and relationships of "fusionauth.users" */
export type UsersClassEventMeetingParticipantsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassEventMeetingParticipantsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventMeetingParticipantsOrderBy>>;
  where?: InputMaybe<ClassEventMeetingParticipantsBoolExp>;
};

/** columns and relationships of "fusionauth.users" */
export type UsersCoachLightningSessionSubmissionsArgs = {
  distinctOn?: InputMaybe<Array<LightningSessionSubmissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LightningSessionSubmissionsOrderBy>>;
  where?: InputMaybe<LightningSessionSubmissionsBoolExp>;
};

/** columns and relationships of "fusionauth.users" */
export type UsersCoachLightningSessionSubmissionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<LightningSessionSubmissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LightningSessionSubmissionsOrderBy>>;
  where?: InputMaybe<LightningSessionSubmissionsBoolExp>;
};

/** columns and relationships of "fusionauth.users" */
export type UsersCoachesArgs = {
  distinctOn?: InputMaybe<Array<CoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoachesOrderBy>>;
  where?: InputMaybe<CoachesBoolExp>;
};

/** columns and relationships of "fusionauth.users" */
export type UsersCoachesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoachesOrderBy>>;
  where?: InputMaybe<CoachesBoolExp>;
};

/** columns and relationships of "fusionauth.users" */
export type UsersEnrollmentsArgs = {
  distinctOn?: InputMaybe<Array<EnrollmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnrollmentsOrderBy>>;
  where?: InputMaybe<EnrollmentsBoolExp>;
};

/** columns and relationships of "fusionauth.users" */
export type UsersEnrollmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<EnrollmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnrollmentsOrderBy>>;
  where?: InputMaybe<EnrollmentsBoolExp>;
};

/** columns and relationships of "fusionauth.users" */
export type UsersIdentityArgs = {
  distinctOn?: InputMaybe<Array<IdentitiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IdentitiesOrderBy>>;
  where?: InputMaybe<IdentitiesBoolExp>;
};

/** columns and relationships of "fusionauth.users" */
export type UsersIdentityAggregateArgs = {
  distinctOn?: InputMaybe<Array<IdentitiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IdentitiesOrderBy>>;
  where?: InputMaybe<IdentitiesBoolExp>;
};

/** columns and relationships of "fusionauth.users" */
export type UsersIdentityProviderLinksArgs = {
  distinctOn?: InputMaybe<Array<IdentityProviderLinksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IdentityProviderLinksOrderBy>>;
  where?: InputMaybe<IdentityProviderLinksBoolExp>;
};

/** columns and relationships of "fusionauth.users" */
export type UsersIdentityProviderLinksAggregateArgs = {
  distinctOn?: InputMaybe<Array<IdentityProviderLinksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IdentityProviderLinksOrderBy>>;
  where?: InputMaybe<IdentityProviderLinksBoolExp>;
};

/** columns and relationships of "fusionauth.users" */
export type UsersJourneysArgs = {
  distinctOn?: InputMaybe<Array<JourneyEnrollmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<JourneyEnrollmentsOrderBy>>;
  where?: InputMaybe<JourneyEnrollmentsBoolExp>;
};

/** columns and relationships of "fusionauth.users" */
export type UsersJourneysAggregateArgs = {
  distinctOn?: InputMaybe<Array<JourneyEnrollmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<JourneyEnrollmentsOrderBy>>;
  where?: InputMaybe<JourneyEnrollmentsBoolExp>;
};

/** columns and relationships of "fusionauth.users" */
export type UsersLearnerLightningSessionSubmissionsArgs = {
  distinctOn?: InputMaybe<Array<LightningSessionSubmissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LightningSessionSubmissionsOrderBy>>;
  where?: InputMaybe<LightningSessionSubmissionsBoolExp>;
};

/** columns and relationships of "fusionauth.users" */
export type UsersLearnerLightningSessionSubmissionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<LightningSessionSubmissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LightningSessionSubmissionsOrderBy>>;
  where?: InputMaybe<LightningSessionSubmissionsBoolExp>;
};

/** columns and relationships of "fusionauth.users" */
export type UsersOrganizationUsersArgs = {
  distinctOn?: InputMaybe<Array<OrganizationUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationUsersOrderBy>>;
  where?: InputMaybe<OrganizationUsersBoolExp>;
};

/** columns and relationships of "fusionauth.users" */
export type UsersOrganizationUsersAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationUsersOrderBy>>;
  where?: InputMaybe<OrganizationUsersBoolExp>;
};

/** columns and relationships of "fusionauth.users" */
export type UsersTeamsArgs = {
  distinctOn?: InputMaybe<Array<TeamsUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamsUsersOrderBy>>;
  where?: InputMaybe<TeamsUsersBoolExp>;
};

/** columns and relationships of "fusionauth.users" */
export type UsersTeamsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamsUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamsUsersOrderBy>>;
  where?: InputMaybe<TeamsUsersBoolExp>;
};

/** columns and relationships of "fusionauth.users" */
export type UsersUserRegistrationsArgs = {
  distinctOn?: InputMaybe<Array<FusionauthUserRegistrationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FusionauthUserRegistrationsOrderBy>>;
  where?: InputMaybe<FusionauthUserRegistrationsBoolExp>;
};

/** columns and relationships of "fusionauth.users" */
export type UsersUserRegistrationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<FusionauthUserRegistrationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FusionauthUserRegistrationsOrderBy>>;
  where?: InputMaybe<FusionauthUserRegistrationsBoolExp>;
};

/** columns and relationships of "fusionauth.users" */
export type UsersUsersClassEventActivitiesArgs = {
  distinctOn?: InputMaybe<Array<ClassEventActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventActivityOrderBy>>;
  where?: InputMaybe<ClassEventActivityBoolExp>;
};

/** columns and relationships of "fusionauth.users" */
export type UsersUsersClassEventActivitiesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassEventActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventActivityOrderBy>>;
  where?: InputMaybe<ClassEventActivityBoolExp>;
};

/** columns and relationships of "fusionauth.users" */
export type UsersWaitlistsArgs = {
  distinctOn?: InputMaybe<Array<EnrollmentWaitlistsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnrollmentWaitlistsOrderBy>>;
  where?: InputMaybe<EnrollmentWaitlistsBoolExp>;
};

/** columns and relationships of "fusionauth.users" */
export type UsersWaitlistsAggregateArgs = {
  distinctOn?: InputMaybe<Array<EnrollmentWaitlistsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnrollmentWaitlistsOrderBy>>;
  where?: InputMaybe<EnrollmentWaitlistsBoolExp>;
};

/** aggregated selection of "fusionauth.users" */
export type UsersAggregate = {
  __typename?: 'UsersAggregate';
  aggregate?: Maybe<UsersAggregateFields>;
  nodes: Array<Users>;
};

/** aggregate fields of "fusionauth.users" */
export type UsersAggregateFields = {
  __typename?: 'UsersAggregateFields';
  avg?: Maybe<UsersAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<UsersMaxFields>;
  min?: Maybe<UsersMinFields>;
  stddev?: Maybe<UsersStddevFields>;
  stddevPop?: Maybe<UsersStddevPopFields>;
  stddevSamp?: Maybe<UsersStddevSampFields>;
  sum?: Maybe<UsersSumFields>;
  varPop?: Maybe<UsersVarPopFields>;
  varSamp?: Maybe<UsersVarSampFields>;
  variance?: Maybe<UsersVarianceFields>;
};

/** aggregate fields of "fusionauth.users" */
export type UsersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type UsersAvgFields = {
  __typename?: 'UsersAvgFields';
  expiry?: Maybe<Scalars['Float']>;
  insertInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
  maxSubscriptionEnrollments?: Maybe<Scalars['numeric']>;
};

/** Boolean expression to filter rows from the table "fusionauth.users". All fields are combined with a logical 'AND'. */
export type UsersBoolExp = {
  _and?: InputMaybe<Array<UsersBoolExp>>;
  _not?: InputMaybe<UsersBoolExp>;
  _or?: InputMaybe<Array<UsersBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  activeSubscription?: InputMaybe<PlansBoolExp>;
  allowEnrollmentsBySubscription?: InputMaybe<BooleanComparisonExp>;
  birthDate?: InputMaybe<BpcharComparisonExp>;
  classEventMeetingParticipants?: InputMaybe<ClassEventMeetingParticipantsBoolExp>;
  classEventMeetingParticipantsAggregate?: InputMaybe<ClassEventMeetingParticipantsAggregateBoolExp>;
  cleanSpeakId?: InputMaybe<UuidComparisonExp>;
  coachLightningSessionSubmissions?: InputMaybe<LightningSessionSubmissionsBoolExp>;
  coachLightningSessionSubmissionsAggregate?: InputMaybe<LightningSessionSubmissionsAggregateBoolExp>;
  coaches?: InputMaybe<CoachesBoolExp>;
  coachesAggregate?: InputMaybe<CoachesAggregateBoolExp>;
  data?: InputMaybe<StringComparisonExp>;
  enrollments?: InputMaybe<EnrollmentsBoolExp>;
  enrollmentsAggregate?: InputMaybe<EnrollmentsAggregateBoolExp>;
  expiry?: InputMaybe<BigintComparisonExp>;
  firstName?: InputMaybe<StringComparisonExp>;
  fullName?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  identity?: InputMaybe<IdentitiesBoolExp>;
  identityAggregate?: InputMaybe<IdentitiesAggregateBoolExp>;
  identityProviderLinks?: InputMaybe<IdentityProviderLinksBoolExp>;
  identityProviderLinksAggregate?: InputMaybe<IdentityProviderLinksAggregateBoolExp>;
  imageUrl?: InputMaybe<StringComparisonExp>;
  insertInstant?: InputMaybe<BigintComparisonExp>;
  journeys?: InputMaybe<JourneyEnrollmentsBoolExp>;
  journeysAggregate?: InputMaybe<JourneyEnrollmentsAggregateBoolExp>;
  lastName?: InputMaybe<StringComparisonExp>;
  lastUpdateInstant?: InputMaybe<BigintComparisonExp>;
  learnerLightningSessionSubmissions?: InputMaybe<LightningSessionSubmissionsBoolExp>;
  learnerLightningSessionSubmissionsAggregate?: InputMaybe<LightningSessionSubmissionsAggregateBoolExp>;
  maxSubscriptionEnrollments?: InputMaybe<NumericComparisonExp>;
  meta?: InputMaybe<UsersMetaBoolExp>;
  middleName?: InputMaybe<StringComparisonExp>;
  mobilePhone?: InputMaybe<StringComparisonExp>;
  organizationUsers?: InputMaybe<OrganizationUsersBoolExp>;
  organizationUsersAggregate?: InputMaybe<OrganizationUsersAggregateBoolExp>;
  parentEmail?: InputMaybe<StringComparisonExp>;
  teams?: InputMaybe<TeamsUsersBoolExp>;
  teamsAggregate?: InputMaybe<TeamsUsersAggregateBoolExp>;
  tenantsId?: InputMaybe<UuidComparisonExp>;
  timezone?: InputMaybe<StringComparisonExp>;
  userRegistrations?: InputMaybe<FusionauthUserRegistrationsBoolExp>;
  userRegistrationsAggregate?: InputMaybe<FusionauthUserRegistrationsAggregateBoolExp>;
  usersClassEventActivities?: InputMaybe<ClassEventActivityBoolExp>;
  usersClassEventActivitiesAggregate?: InputMaybe<ClassEventActivityAggregateBoolExp>;
  waitlists?: InputMaybe<EnrollmentWaitlistsBoolExp>;
  waitlistsAggregate?: InputMaybe<EnrollmentWaitlistsAggregateBoolExp>;
};

/** unique or primary key constraints on table "fusionauth.users" */
export enum UsersConstraint {
  /** unique or primary key constraint on columns "id" */
  users_pkey = 'users_pkey'
}

/** columns and relationships of "users_functions_enum" */
export type UsersFunctions = {
  __typename?: 'UsersFunctions';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "users_functions_enum" */
export type UsersFunctionsAggregate = {
  __typename?: 'UsersFunctionsAggregate';
  aggregate?: Maybe<UsersFunctionsAggregateFields>;
  nodes: Array<UsersFunctions>;
};

/** aggregate fields of "users_functions_enum" */
export type UsersFunctionsAggregateFields = {
  __typename?: 'UsersFunctionsAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<UsersFunctionsMaxFields>;
  min?: Maybe<UsersFunctionsMinFields>;
};

/** aggregate fields of "users_functions_enum" */
export type UsersFunctionsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UsersFunctionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "users_functions_enum". All fields are combined with a logical 'AND'. */
export type UsersFunctionsBoolExp = {
  _and?: InputMaybe<Array<UsersFunctionsBoolExp>>;
  _not?: InputMaybe<UsersFunctionsBoolExp>;
  _or?: InputMaybe<Array<UsersFunctionsBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "users_functions_enum" */
export enum UsersFunctionsConstraint {
  /** unique or primary key constraint on columns "value" */
  users_functions_enum_pkey = 'users_functions_enum_pkey'
}

export enum UsersFunctionsEnum {
  /** Sales Rep (AE/AM/SE/Full-cycle) */
  AE_AM_SE_FULL_CYCLE = 'AE_AM_SE_FULL_CYCLE',
  /** Customer Success */
  CS = 'CS',
  /** Other */
  OTHER = 'OTHER',
  /** Sales Enablement */
  SALES_ENABLEMENT = 'SALES_ENABLEMENT',
  /** Development Rep (SDR/BDR) */
  SDR_BDR = 'SDR_BDR'
}

/** Boolean expression to compare columns of type "UsersFunctionsEnum". All fields are combined with logical 'AND'. */
export type UsersFunctionsEnumComparisonExp = {
  _eq?: InputMaybe<UsersFunctionsEnum>;
  _in?: InputMaybe<Array<UsersFunctionsEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<UsersFunctionsEnum>;
  _nin?: InputMaybe<Array<UsersFunctionsEnum>>;
};

/** input type for inserting data into table "users_functions_enum" */
export type UsersFunctionsInsertInput = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type UsersFunctionsMaxFields = {
  __typename?: 'UsersFunctionsMaxFields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type UsersFunctionsMinFields = {
  __typename?: 'UsersFunctionsMinFields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "users_functions_enum" */
export type UsersFunctionsMutationResponse = {
  __typename?: 'UsersFunctionsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UsersFunctions>;
};

/** input type for inserting object relation for remote table "users_functions_enum" */
export type UsersFunctionsObjRelInsertInput = {
  data: UsersFunctionsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<UsersFunctionsOnConflict>;
};

/** on_conflict condition type for table "users_functions_enum" */
export type UsersFunctionsOnConflict = {
  constraint: UsersFunctionsConstraint;
  updateColumns?: Array<UsersFunctionsUpdateColumn>;
  where?: InputMaybe<UsersFunctionsBoolExp>;
};

/** Ordering options when selecting data from "users_functions_enum". */
export type UsersFunctionsOrderBy = {
  description?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: users_functions_enum */
export type UsersFunctionsPkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "users_functions_enum" */
export enum UsersFunctionsSelectColumn {
  /** column name */
  description = 'description',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "users_functions_enum" */
export type UsersFunctionsSetInput = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "UsersFunctions" */
export type UsersFunctionsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: UsersFunctionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UsersFunctionsStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "users_functions_enum" */
export enum UsersFunctionsUpdateColumn {
  /** column name */
  description = 'description',
  /** column name */
  value = 'value'
}

export type UsersFunctionsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UsersFunctionsSetInput>;
  /** filter the rows which have to be updated */
  where: UsersFunctionsBoolExp;
};

/** input type for incrementing numeric columns in table "fusionauth.users" */
export type UsersIncInput = {
  expiry?: InputMaybe<Scalars['bigint']>;
  insertInstant?: InputMaybe<Scalars['bigint']>;
  lastUpdateInstant?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "fusionauth.users" */
export type UsersInsertInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  birthDate?: InputMaybe<Scalars['bpchar']>;
  classEventMeetingParticipants?: InputMaybe<ClassEventMeetingParticipantsArrRelInsertInput>;
  cleanSpeakId?: InputMaybe<Scalars['uuid']>;
  coachLightningSessionSubmissions?: InputMaybe<LightningSessionSubmissionsArrRelInsertInput>;
  coaches?: InputMaybe<CoachesArrRelInsertInput>;
  data?: InputMaybe<Scalars['String']>;
  enrollments?: InputMaybe<EnrollmentsArrRelInsertInput>;
  expiry?: InputMaybe<Scalars['bigint']>;
  firstName?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  identity?: InputMaybe<IdentitiesArrRelInsertInput>;
  identityProviderLinks?: InputMaybe<IdentityProviderLinksArrRelInsertInput>;
  imageUrl?: InputMaybe<Scalars['String']>;
  insertInstant?: InputMaybe<Scalars['bigint']>;
  journeys?: InputMaybe<JourneyEnrollmentsArrRelInsertInput>;
  lastName?: InputMaybe<Scalars['String']>;
  lastUpdateInstant?: InputMaybe<Scalars['bigint']>;
  learnerLightningSessionSubmissions?: InputMaybe<LightningSessionSubmissionsArrRelInsertInput>;
  meta?: InputMaybe<UsersMetaObjRelInsertInput>;
  middleName?: InputMaybe<Scalars['String']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  organizationUsers?: InputMaybe<OrganizationUsersArrRelInsertInput>;
  parentEmail?: InputMaybe<Scalars['String']>;
  teams?: InputMaybe<TeamsUsersArrRelInsertInput>;
  tenantsId?: InputMaybe<Scalars['uuid']>;
  timezone?: InputMaybe<Scalars['String']>;
  userRegistrations?: InputMaybe<FusionauthUserRegistrationsArrRelInsertInput>;
  usersClassEventActivities?: InputMaybe<ClassEventActivityArrRelInsertInput>;
  waitlists?: InputMaybe<EnrollmentWaitlistsArrRelInsertInput>;
};

/** columns and relationships of "users_length_in_function_enum" */
export type UsersLengthInFunction = {
  __typename?: 'UsersLengthInFunction';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "users_length_in_function_enum" */
export type UsersLengthInFunctionAggregate = {
  __typename?: 'UsersLengthInFunctionAggregate';
  aggregate?: Maybe<UsersLengthInFunctionAggregateFields>;
  nodes: Array<UsersLengthInFunction>;
};

/** aggregate fields of "users_length_in_function_enum" */
export type UsersLengthInFunctionAggregateFields = {
  __typename?: 'UsersLengthInFunctionAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<UsersLengthInFunctionMaxFields>;
  min?: Maybe<UsersLengthInFunctionMinFields>;
};

/** aggregate fields of "users_length_in_function_enum" */
export type UsersLengthInFunctionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UsersLengthInFunctionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "users_length_in_function_enum". All fields are combined with a logical 'AND'. */
export type UsersLengthInFunctionBoolExp = {
  _and?: InputMaybe<Array<UsersLengthInFunctionBoolExp>>;
  _not?: InputMaybe<UsersLengthInFunctionBoolExp>;
  _or?: InputMaybe<Array<UsersLengthInFunctionBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "users_length_in_function_enum" */
export enum UsersLengthInFunctionConstraint {
  /** unique or primary key constraint on columns "value" */
  users_length_in_function_enum_pkey = 'users_length_in_function_enum_pkey'
}

export enum UsersLengthInFunctionEnum {
  /** 0-6 months */
  MONTHS_0_6 = 'MONTHS_0_6',
  /** 6-18 months */
  MONTHS_6_18 = 'MONTHS_6_18',
  /** 18 months- 3 years */
  MONTHS_18_3_YEARS = 'MONTHS_18_3_YEARS',
  /** 3+ years */
  YEARS_3_PLUS = 'YEARS_3_PLUS'
}

/** Boolean expression to compare columns of type "UsersLengthInFunctionEnum". All fields are combined with logical 'AND'. */
export type UsersLengthInFunctionEnumComparisonExp = {
  _eq?: InputMaybe<UsersLengthInFunctionEnum>;
  _in?: InputMaybe<Array<UsersLengthInFunctionEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<UsersLengthInFunctionEnum>;
  _nin?: InputMaybe<Array<UsersLengthInFunctionEnum>>;
};

/** input type for inserting data into table "users_length_in_function_enum" */
export type UsersLengthInFunctionInsertInput = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type UsersLengthInFunctionMaxFields = {
  __typename?: 'UsersLengthInFunctionMaxFields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type UsersLengthInFunctionMinFields = {
  __typename?: 'UsersLengthInFunctionMinFields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "users_length_in_function_enum" */
export type UsersLengthInFunctionMutationResponse = {
  __typename?: 'UsersLengthInFunctionMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UsersLengthInFunction>;
};

/** on_conflict condition type for table "users_length_in_function_enum" */
export type UsersLengthInFunctionOnConflict = {
  constraint: UsersLengthInFunctionConstraint;
  updateColumns?: Array<UsersLengthInFunctionUpdateColumn>;
  where?: InputMaybe<UsersLengthInFunctionBoolExp>;
};

/** Ordering options when selecting data from "users_length_in_function_enum". */
export type UsersLengthInFunctionOrderBy = {
  description?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: users_length_in_function_enum */
export type UsersLengthInFunctionPkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "users_length_in_function_enum" */
export enum UsersLengthInFunctionSelectColumn {
  /** column name */
  description = 'description',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "users_length_in_function_enum" */
export type UsersLengthInFunctionSetInput = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "UsersLengthInFunction" */
export type UsersLengthInFunctionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: UsersLengthInFunctionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UsersLengthInFunctionStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "users_length_in_function_enum" */
export enum UsersLengthInFunctionUpdateColumn {
  /** column name */
  description = 'description',
  /** column name */
  value = 'value'
}

export type UsersLengthInFunctionUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UsersLengthInFunctionSetInput>;
  /** filter the rows which have to be updated */
  where: UsersLengthInFunctionBoolExp;
};

/** aggregate max on columns */
export type UsersMaxFields = {
  __typename?: 'UsersMaxFields';
  birthDate?: Maybe<Scalars['bpchar']>;
  cleanSpeakId?: Maybe<Scalars['uuid']>;
  data?: Maybe<Scalars['String']>;
  expiry?: Maybe<Scalars['bigint']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  imageUrl?: Maybe<Scalars['String']>;
  insertInstant?: Maybe<Scalars['bigint']>;
  lastName?: Maybe<Scalars['String']>;
  lastUpdateInstant?: Maybe<Scalars['bigint']>;
  maxSubscriptionEnrollments?: Maybe<Scalars['numeric']>;
  middleName?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  parentEmail?: Maybe<Scalars['String']>;
  tenantsId?: Maybe<Scalars['uuid']>;
  timezone?: Maybe<Scalars['String']>;
};

/** columns and relationships of "users_meta" */
export type UsersMeta = {
  __typename?: 'UsersMeta';
  acceptedTermsOfUse?: Maybe<Scalars['Boolean']>;
  hasFilledUserProfileData?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "check_lightning_session_participation" */
  hasParticipatedInLightningSession?: Maybe<Scalars['Boolean']>;
  isSubscribedToRecommendationEmails: Scalars['Boolean'];
  isSubscribedToReminderEmails: Scalars['Boolean'];
  jobFunction?: Maybe<UsersFunctionsEnum>;
  /** An object relationship */
  jobFunctionData?: Maybe<UsersFunctions>;
  jobFunctionLength?: Maybe<UsersLengthInFunctionEnum>;
  jobLevel?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  joinDate?: Maybe<Scalars['timestamp']>;
  legacy?: Maybe<Scalars['jsonb']>;
  locale?: Maybe<Scalars['String']>;
  managerId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  organization?: Maybe<Organizations>;
  selectedOrgId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  user: Users;
  userId: Scalars['uuid'];
};

/** columns and relationships of "users_meta" */
export type UsersMetaLegacyArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "users_meta" */
export type UsersMetaAggregate = {
  __typename?: 'UsersMetaAggregate';
  aggregate?: Maybe<UsersMetaAggregateFields>;
  nodes: Array<UsersMeta>;
};

/** aggregate fields of "users_meta" */
export type UsersMetaAggregateFields = {
  __typename?: 'UsersMetaAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<UsersMetaMaxFields>;
  min?: Maybe<UsersMetaMinFields>;
};

/** aggregate fields of "users_meta" */
export type UsersMetaAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UsersMetaSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type UsersMetaAppendInput = {
  legacy?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "users_meta". All fields are combined with a logical 'AND'. */
export type UsersMetaBoolExp = {
  _and?: InputMaybe<Array<UsersMetaBoolExp>>;
  _not?: InputMaybe<UsersMetaBoolExp>;
  _or?: InputMaybe<Array<UsersMetaBoolExp>>;
  acceptedTermsOfUse?: InputMaybe<BooleanComparisonExp>;
  hasFilledUserProfileData?: InputMaybe<BooleanComparisonExp>;
  hasParticipatedInLightningSession?: InputMaybe<BooleanComparisonExp>;
  isSubscribedToRecommendationEmails?: InputMaybe<BooleanComparisonExp>;
  isSubscribedToReminderEmails?: InputMaybe<BooleanComparisonExp>;
  jobFunction?: InputMaybe<UsersFunctionsEnumComparisonExp>;
  jobFunctionData?: InputMaybe<UsersFunctionsBoolExp>;
  jobFunctionLength?: InputMaybe<UsersLengthInFunctionEnumComparisonExp>;
  jobLevel?: InputMaybe<StringComparisonExp>;
  jobTitle?: InputMaybe<StringComparisonExp>;
  joinDate?: InputMaybe<TimestampComparisonExp>;
  legacy?: InputMaybe<JsonbComparisonExp>;
  locale?: InputMaybe<StringComparisonExp>;
  managerId?: InputMaybe<UuidComparisonExp>;
  organization?: InputMaybe<OrganizationsBoolExp>;
  selectedOrgId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "users_meta" */
export enum UsersMetaConstraint {
  /** unique or primary key constraint on columns "user_id" */
  users_meta_pk = 'users_meta_pk'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type UsersMetaDeleteAtPathInput = {
  legacy?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type UsersMetaDeleteElemInput = {
  legacy?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type UsersMetaDeleteKeyInput = {
  legacy?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "users_meta" */
export type UsersMetaInsertInput = {
  acceptedTermsOfUse?: InputMaybe<Scalars['Boolean']>;
  hasFilledUserProfileData?: InputMaybe<Scalars['Boolean']>;
  isSubscribedToRecommendationEmails?: InputMaybe<Scalars['Boolean']>;
  isSubscribedToReminderEmails?: InputMaybe<Scalars['Boolean']>;
  jobFunction?: InputMaybe<UsersFunctionsEnum>;
  jobFunctionData?: InputMaybe<UsersFunctionsObjRelInsertInput>;
  jobFunctionLength?: InputMaybe<UsersLengthInFunctionEnum>;
  jobLevel?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  joinDate?: InputMaybe<Scalars['timestamp']>;
  legacy?: InputMaybe<Scalars['jsonb']>;
  locale?: InputMaybe<Scalars['String']>;
  managerId?: InputMaybe<Scalars['uuid']>;
  organization?: InputMaybe<OrganizationsObjRelInsertInput>;
  selectedOrgId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type UsersMetaMaxFields = {
  __typename?: 'UsersMetaMaxFields';
  jobLevel?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  joinDate?: Maybe<Scalars['timestamp']>;
  locale?: Maybe<Scalars['String']>;
  managerId?: Maybe<Scalars['uuid']>;
  selectedOrgId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type UsersMetaMinFields = {
  __typename?: 'UsersMetaMinFields';
  jobLevel?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  joinDate?: Maybe<Scalars['timestamp']>;
  locale?: Maybe<Scalars['String']>;
  managerId?: Maybe<Scalars['uuid']>;
  selectedOrgId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "users_meta" */
export type UsersMetaMutationResponse = {
  __typename?: 'UsersMetaMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UsersMeta>;
};

/** input type for inserting object relation for remote table "users_meta" */
export type UsersMetaObjRelInsertInput = {
  data: UsersMetaInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<UsersMetaOnConflict>;
};

/** on_conflict condition type for table "users_meta" */
export type UsersMetaOnConflict = {
  constraint: UsersMetaConstraint;
  updateColumns?: Array<UsersMetaUpdateColumn>;
  where?: InputMaybe<UsersMetaBoolExp>;
};

/** Ordering options when selecting data from "users_meta". */
export type UsersMetaOrderBy = {
  acceptedTermsOfUse?: InputMaybe<OrderBy>;
  hasFilledUserProfileData?: InputMaybe<OrderBy>;
  hasParticipatedInLightningSession?: InputMaybe<OrderBy>;
  isSubscribedToRecommendationEmails?: InputMaybe<OrderBy>;
  isSubscribedToReminderEmails?: InputMaybe<OrderBy>;
  jobFunction?: InputMaybe<OrderBy>;
  jobFunctionData?: InputMaybe<UsersFunctionsOrderBy>;
  jobFunctionLength?: InputMaybe<OrderBy>;
  jobLevel?: InputMaybe<OrderBy>;
  jobTitle?: InputMaybe<OrderBy>;
  joinDate?: InputMaybe<OrderBy>;
  legacy?: InputMaybe<OrderBy>;
  locale?: InputMaybe<OrderBy>;
  managerId?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationsOrderBy>;
  selectedOrgId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: users_meta */
export type UsersMetaPkColumnsInput = {
  userId: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type UsersMetaPrependInput = {
  legacy?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "users_meta" */
export enum UsersMetaSelectColumn {
  /** column name */
  acceptedTermsOfUse = 'acceptedTermsOfUse',
  /** column name */
  hasFilledUserProfileData = 'hasFilledUserProfileData',
  /** column name */
  isSubscribedToRecommendationEmails = 'isSubscribedToRecommendationEmails',
  /** column name */
  isSubscribedToReminderEmails = 'isSubscribedToReminderEmails',
  /** column name */
  jobFunction = 'jobFunction',
  /** column name */
  jobFunctionLength = 'jobFunctionLength',
  /** column name */
  jobLevel = 'jobLevel',
  /** column name */
  jobTitle = 'jobTitle',
  /** column name */
  joinDate = 'joinDate',
  /** column name */
  legacy = 'legacy',
  /** column name */
  locale = 'locale',
  /** column name */
  managerId = 'managerId',
  /** column name */
  selectedOrgId = 'selectedOrgId',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  userId = 'userId'
}

/** input type for updating data in table "users_meta" */
export type UsersMetaSetInput = {
  acceptedTermsOfUse?: InputMaybe<Scalars['Boolean']>;
  hasFilledUserProfileData?: InputMaybe<Scalars['Boolean']>;
  isSubscribedToRecommendationEmails?: InputMaybe<Scalars['Boolean']>;
  isSubscribedToReminderEmails?: InputMaybe<Scalars['Boolean']>;
  jobFunction?: InputMaybe<UsersFunctionsEnum>;
  jobFunctionLength?: InputMaybe<UsersLengthInFunctionEnum>;
  jobLevel?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  joinDate?: InputMaybe<Scalars['timestamp']>;
  legacy?: InputMaybe<Scalars['jsonb']>;
  locale?: InputMaybe<Scalars['String']>;
  managerId?: InputMaybe<Scalars['uuid']>;
  selectedOrgId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "users_meta" */
export type UsersMetaStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: UsersMetaStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UsersMetaStreamCursorValueInput = {
  acceptedTermsOfUse?: InputMaybe<Scalars['Boolean']>;
  hasFilledUserProfileData?: InputMaybe<Scalars['Boolean']>;
  isSubscribedToRecommendationEmails?: InputMaybe<Scalars['Boolean']>;
  isSubscribedToReminderEmails?: InputMaybe<Scalars['Boolean']>;
  jobFunction?: InputMaybe<UsersFunctionsEnum>;
  jobFunctionLength?: InputMaybe<UsersLengthInFunctionEnum>;
  jobLevel?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  joinDate?: InputMaybe<Scalars['timestamp']>;
  legacy?: InputMaybe<Scalars['jsonb']>;
  locale?: InputMaybe<Scalars['String']>;
  managerId?: InputMaybe<Scalars['uuid']>;
  selectedOrgId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "users_meta" */
export enum UsersMetaUpdateColumn {
  /** column name */
  acceptedTermsOfUse = 'acceptedTermsOfUse',
  /** column name */
  hasFilledUserProfileData = 'hasFilledUserProfileData',
  /** column name */
  isSubscribedToRecommendationEmails = 'isSubscribedToRecommendationEmails',
  /** column name */
  isSubscribedToReminderEmails = 'isSubscribedToReminderEmails',
  /** column name */
  jobFunction = 'jobFunction',
  /** column name */
  jobFunctionLength = 'jobFunctionLength',
  /** column name */
  jobLevel = 'jobLevel',
  /** column name */
  jobTitle = 'jobTitle',
  /** column name */
  joinDate = 'joinDate',
  /** column name */
  legacy = 'legacy',
  /** column name */
  locale = 'locale',
  /** column name */
  managerId = 'managerId',
  /** column name */
  selectedOrgId = 'selectedOrgId',
  /** column name */
  updatedAt = 'updatedAt',
  /** column name */
  userId = 'userId'
}

export type UsersMetaUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<UsersMetaAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<UsersMetaDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<UsersMetaDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<UsersMetaDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<UsersMetaPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UsersMetaSetInput>;
  /** filter the rows which have to be updated */
  where: UsersMetaBoolExp;
};

/** aggregate min on columns */
export type UsersMinFields = {
  __typename?: 'UsersMinFields';
  birthDate?: Maybe<Scalars['bpchar']>;
  cleanSpeakId?: Maybe<Scalars['uuid']>;
  data?: Maybe<Scalars['String']>;
  expiry?: Maybe<Scalars['bigint']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  imageUrl?: Maybe<Scalars['String']>;
  insertInstant?: Maybe<Scalars['bigint']>;
  lastName?: Maybe<Scalars['String']>;
  lastUpdateInstant?: Maybe<Scalars['bigint']>;
  maxSubscriptionEnrollments?: Maybe<Scalars['numeric']>;
  middleName?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  parentEmail?: Maybe<Scalars['String']>;
  tenantsId?: Maybe<Scalars['uuid']>;
  timezone?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "fusionauth.users" */
export type UsersMutationResponse = {
  __typename?: 'UsersMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "fusionauth.users" */
export type UsersObjRelInsertInput = {
  data: UsersInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<UsersOnConflict>;
};

/** on_conflict condition type for table "fusionauth.users" */
export type UsersOnConflict = {
  constraint: UsersConstraint;
  updateColumns?: Array<UsersUpdateColumn>;
  where?: InputMaybe<UsersBoolExp>;
};

/** Ordering options when selecting data from "fusionauth.users". */
export type UsersOrderBy = {
  active?: InputMaybe<OrderBy>;
  activeSubscriptionAggregate?: InputMaybe<PlansAggregateOrderBy>;
  allowEnrollmentsBySubscription?: InputMaybe<OrderBy>;
  birthDate?: InputMaybe<OrderBy>;
  classEventMeetingParticipantsAggregate?: InputMaybe<ClassEventMeetingParticipantsAggregateOrderBy>;
  cleanSpeakId?: InputMaybe<OrderBy>;
  coachLightningSessionSubmissionsAggregate?: InputMaybe<LightningSessionSubmissionsAggregateOrderBy>;
  coachesAggregate?: InputMaybe<CoachesAggregateOrderBy>;
  data?: InputMaybe<OrderBy>;
  enrollmentsAggregate?: InputMaybe<EnrollmentsAggregateOrderBy>;
  expiry?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  fullName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  identityAggregate?: InputMaybe<IdentitiesAggregateOrderBy>;
  identityProviderLinksAggregate?: InputMaybe<IdentityProviderLinksAggregateOrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  insertInstant?: InputMaybe<OrderBy>;
  journeysAggregate?: InputMaybe<JourneyEnrollmentsAggregateOrderBy>;
  lastName?: InputMaybe<OrderBy>;
  lastUpdateInstant?: InputMaybe<OrderBy>;
  learnerLightningSessionSubmissionsAggregate?: InputMaybe<LightningSessionSubmissionsAggregateOrderBy>;
  maxSubscriptionEnrollments?: InputMaybe<OrderBy>;
  meta?: InputMaybe<UsersMetaOrderBy>;
  middleName?: InputMaybe<OrderBy>;
  mobilePhone?: InputMaybe<OrderBy>;
  organizationUsersAggregate?: InputMaybe<OrganizationUsersAggregateOrderBy>;
  parentEmail?: InputMaybe<OrderBy>;
  teamsAggregate?: InputMaybe<TeamsUsersAggregateOrderBy>;
  tenantsId?: InputMaybe<OrderBy>;
  timezone?: InputMaybe<OrderBy>;
  userRegistrationsAggregate?: InputMaybe<FusionauthUserRegistrationsAggregateOrderBy>;
  usersClassEventActivitiesAggregate?: InputMaybe<ClassEventActivityAggregateOrderBy>;
  waitlistsAggregate?: InputMaybe<EnrollmentWaitlistsAggregateOrderBy>;
};

/** primary key columns input for table: fusionauth.users */
export type UsersPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "fusionauth.users" */
export enum UsersSelectColumn {
  /** column name */
  active = 'active',
  /** column name */
  birthDate = 'birthDate',
  /** column name */
  cleanSpeakId = 'cleanSpeakId',
  /** column name */
  data = 'data',
  /** column name */
  expiry = 'expiry',
  /** column name */
  firstName = 'firstName',
  /** column name */
  fullName = 'fullName',
  /** column name */
  id = 'id',
  /** column name */
  imageUrl = 'imageUrl',
  /** column name */
  insertInstant = 'insertInstant',
  /** column name */
  lastName = 'lastName',
  /** column name */
  lastUpdateInstant = 'lastUpdateInstant',
  /** column name */
  middleName = 'middleName',
  /** column name */
  mobilePhone = 'mobilePhone',
  /** column name */
  parentEmail = 'parentEmail',
  /** column name */
  tenantsId = 'tenantsId',
  /** column name */
  timezone = 'timezone'
}

/** input type for updating data in table "fusionauth.users" */
export type UsersSetInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  birthDate?: InputMaybe<Scalars['bpchar']>;
  cleanSpeakId?: InputMaybe<Scalars['uuid']>;
  data?: InputMaybe<Scalars['String']>;
  expiry?: InputMaybe<Scalars['bigint']>;
  firstName?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  insertInstant?: InputMaybe<Scalars['bigint']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastUpdateInstant?: InputMaybe<Scalars['bigint']>;
  middleName?: InputMaybe<Scalars['String']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  parentEmail?: InputMaybe<Scalars['String']>;
  tenantsId?: InputMaybe<Scalars['uuid']>;
  timezone?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type UsersStddevFields = {
  __typename?: 'UsersStddevFields';
  expiry?: Maybe<Scalars['Float']>;
  insertInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
  maxSubscriptionEnrollments?: Maybe<Scalars['numeric']>;
};

/** aggregate stddevPop on columns */
export type UsersStddevPopFields = {
  __typename?: 'UsersStddevPopFields';
  expiry?: Maybe<Scalars['Float']>;
  insertInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
  maxSubscriptionEnrollments?: Maybe<Scalars['numeric']>;
};

/** aggregate stddevSamp on columns */
export type UsersStddevSampFields = {
  __typename?: 'UsersStddevSampFields';
  expiry?: Maybe<Scalars['Float']>;
  insertInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
  maxSubscriptionEnrollments?: Maybe<Scalars['numeric']>;
};

/** Streaming cursor of the table "Users" */
export type UsersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: UsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UsersStreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  birthDate?: InputMaybe<Scalars['bpchar']>;
  cleanSpeakId?: InputMaybe<Scalars['uuid']>;
  data?: InputMaybe<Scalars['String']>;
  expiry?: InputMaybe<Scalars['bigint']>;
  firstName?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  insertInstant?: InputMaybe<Scalars['bigint']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastUpdateInstant?: InputMaybe<Scalars['bigint']>;
  middleName?: InputMaybe<Scalars['String']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  parentEmail?: InputMaybe<Scalars['String']>;
  tenantsId?: InputMaybe<Scalars['uuid']>;
  timezone?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type UsersSumFields = {
  __typename?: 'UsersSumFields';
  expiry?: Maybe<Scalars['bigint']>;
  insertInstant?: Maybe<Scalars['bigint']>;
  lastUpdateInstant?: Maybe<Scalars['bigint']>;
  maxSubscriptionEnrollments?: Maybe<Scalars['numeric']>;
};

/** update columns of table "fusionauth.users" */
export enum UsersUpdateColumn {
  /** column name */
  active = 'active',
  /** column name */
  birthDate = 'birthDate',
  /** column name */
  cleanSpeakId = 'cleanSpeakId',
  /** column name */
  data = 'data',
  /** column name */
  expiry = 'expiry',
  /** column name */
  firstName = 'firstName',
  /** column name */
  fullName = 'fullName',
  /** column name */
  id = 'id',
  /** column name */
  imageUrl = 'imageUrl',
  /** column name */
  insertInstant = 'insertInstant',
  /** column name */
  lastName = 'lastName',
  /** column name */
  lastUpdateInstant = 'lastUpdateInstant',
  /** column name */
  middleName = 'middleName',
  /** column name */
  mobilePhone = 'mobilePhone',
  /** column name */
  parentEmail = 'parentEmail',
  /** column name */
  tenantsId = 'tenantsId',
  /** column name */
  timezone = 'timezone'
}

export type UsersUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UsersIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UsersSetInput>;
  /** filter the rows which have to be updated */
  where: UsersBoolExp;
};

/** aggregate varPop on columns */
export type UsersVarPopFields = {
  __typename?: 'UsersVarPopFields';
  expiry?: Maybe<Scalars['Float']>;
  insertInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
  maxSubscriptionEnrollments?: Maybe<Scalars['numeric']>;
};

/** aggregate varSamp on columns */
export type UsersVarSampFields = {
  __typename?: 'UsersVarSampFields';
  expiry?: Maybe<Scalars['Float']>;
  insertInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
  maxSubscriptionEnrollments?: Maybe<Scalars['numeric']>;
};

/** aggregate variance on columns */
export type UsersVarianceFields = {
  __typename?: 'UsersVarianceFields';
  expiry?: Maybe<Scalars['Float']>;
  insertInstant?: Maybe<Scalars['Float']>;
  lastUpdateInstant?: Maybe<Scalars['Float']>;
  maxSubscriptionEnrollments?: Maybe<Scalars['numeric']>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type UuidArrayComparisonExp = {
  /** is the array contained in the given array value */
  _containedIn?: InputMaybe<Array<Scalars['uuid']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['uuid']>>;
  _eq?: InputMaybe<Array<Scalars['uuid']>>;
  _gt?: InputMaybe<Array<Scalars['uuid']>>;
  _gte?: InputMaybe<Array<Scalars['uuid']>>;
  _in?: InputMaybe<Array<Array<Scalars['uuid']>>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Array<Scalars['uuid']>>;
  _lte?: InputMaybe<Array<Scalars['uuid']>>;
  _neq?: InputMaybe<Array<Scalars['uuid']>>;
  _nin?: InputMaybe<Array<Array<Scalars['uuid']>>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type UuidComparisonExp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "zoom_oauth_tokens" */
export type ZoomOauthTokens = {
  __typename?: 'ZoomOauthTokens';
  bearerToken: Scalars['String'];
  createdAt: Scalars['timestamp'];
  expiresAt: Scalars['timestamp'];
  index: Scalars['Int'];
};

/** aggregated selection of "zoom_oauth_tokens" */
export type ZoomOauthTokensAggregate = {
  __typename?: 'ZoomOauthTokensAggregate';
  aggregate?: Maybe<ZoomOauthTokensAggregateFields>;
  nodes: Array<ZoomOauthTokens>;
};

/** aggregate fields of "zoom_oauth_tokens" */
export type ZoomOauthTokensAggregateFields = {
  __typename?: 'ZoomOauthTokensAggregateFields';
  avg?: Maybe<ZoomOauthTokensAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ZoomOauthTokensMaxFields>;
  min?: Maybe<ZoomOauthTokensMinFields>;
  stddev?: Maybe<ZoomOauthTokensStddevFields>;
  stddevPop?: Maybe<ZoomOauthTokensStddevPopFields>;
  stddevSamp?: Maybe<ZoomOauthTokensStddevSampFields>;
  sum?: Maybe<ZoomOauthTokensSumFields>;
  varPop?: Maybe<ZoomOauthTokensVarPopFields>;
  varSamp?: Maybe<ZoomOauthTokensVarSampFields>;
  variance?: Maybe<ZoomOauthTokensVarianceFields>;
};

/** aggregate fields of "zoom_oauth_tokens" */
export type ZoomOauthTokensAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomOauthTokensSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ZoomOauthTokensAvgFields = {
  __typename?: 'ZoomOauthTokensAvgFields';
  index?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zoom_oauth_tokens". All fields are combined with a logical 'AND'. */
export type ZoomOauthTokensBoolExp = {
  _and?: InputMaybe<Array<ZoomOauthTokensBoolExp>>;
  _not?: InputMaybe<ZoomOauthTokensBoolExp>;
  _or?: InputMaybe<Array<ZoomOauthTokensBoolExp>>;
  bearerToken?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  expiresAt?: InputMaybe<TimestampComparisonExp>;
  index?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "zoom_oauth_tokens" */
export enum ZoomOauthTokensConstraint {
  /** unique or primary key constraint on columns "bearer_token" */
  zoom_oauth_tokens_pk = 'zoom_oauth_tokens_pk'
}

/** input type for incrementing numeric columns in table "zoom_oauth_tokens" */
export type ZoomOauthTokensIncInput = {
  index?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "zoom_oauth_tokens" */
export type ZoomOauthTokensInsertInput = {
  bearerToken?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  expiresAt?: InputMaybe<Scalars['timestamp']>;
  index?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ZoomOauthTokensMaxFields = {
  __typename?: 'ZoomOauthTokensMaxFields';
  bearerToken?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  expiresAt?: Maybe<Scalars['timestamp']>;
  index?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ZoomOauthTokensMinFields = {
  __typename?: 'ZoomOauthTokensMinFields';
  bearerToken?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  expiresAt?: Maybe<Scalars['timestamp']>;
  index?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "zoom_oauth_tokens" */
export type ZoomOauthTokensMutationResponse = {
  __typename?: 'ZoomOauthTokensMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZoomOauthTokens>;
};

/** on_conflict condition type for table "zoom_oauth_tokens" */
export type ZoomOauthTokensOnConflict = {
  constraint: ZoomOauthTokensConstraint;
  updateColumns?: Array<ZoomOauthTokensUpdateColumn>;
  where?: InputMaybe<ZoomOauthTokensBoolExp>;
};

/** Ordering options when selecting data from "zoom_oauth_tokens". */
export type ZoomOauthTokensOrderBy = {
  bearerToken?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: zoom_oauth_tokens */
export type ZoomOauthTokensPkColumnsInput = {
  bearerToken: Scalars['String'];
};

/** select columns of table "zoom_oauth_tokens" */
export enum ZoomOauthTokensSelectColumn {
  /** column name */
  bearerToken = 'bearerToken',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  expiresAt = 'expiresAt',
  /** column name */
  index = 'index'
}

/** input type for updating data in table "zoom_oauth_tokens" */
export type ZoomOauthTokensSetInput = {
  bearerToken?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  expiresAt?: InputMaybe<Scalars['timestamp']>;
  index?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ZoomOauthTokensStddevFields = {
  __typename?: 'ZoomOauthTokensStddevFields';
  index?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type ZoomOauthTokensStddevPopFields = {
  __typename?: 'ZoomOauthTokensStddevPopFields';
  index?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type ZoomOauthTokensStddevSampFields = {
  __typename?: 'ZoomOauthTokensStddevSampFields';
  index?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zoom_oauth_tokens" */
export type ZoomOauthTokensStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ZoomOauthTokensStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomOauthTokensStreamCursorValueInput = {
  bearerToken?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  expiresAt?: InputMaybe<Scalars['timestamp']>;
  index?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ZoomOauthTokensSumFields = {
  __typename?: 'ZoomOauthTokensSumFields';
  index?: Maybe<Scalars['Int']>;
};

/** update columns of table "zoom_oauth_tokens" */
export enum ZoomOauthTokensUpdateColumn {
  /** column name */
  bearerToken = 'bearerToken',
  /** column name */
  createdAt = 'createdAt',
  /** column name */
  expiresAt = 'expiresAt',
  /** column name */
  index = 'index'
}

export type ZoomOauthTokensUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ZoomOauthTokensIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZoomOauthTokensSetInput>;
  /** filter the rows which have to be updated */
  where: ZoomOauthTokensBoolExp;
};

/** aggregate varPop on columns */
export type ZoomOauthTokensVarPopFields = {
  __typename?: 'ZoomOauthTokensVarPopFields';
  index?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type ZoomOauthTokensVarSampFields = {
  __typename?: 'ZoomOauthTokensVarSampFields';
  index?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ZoomOauthTokensVarianceFields = {
  __typename?: 'ZoomOauthTokensVarianceFields';
  index?: Maybe<Scalars['Float']>;
};

export type CatalogsJourneysRelAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CatalogsJourneysRelSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CatalogsJourneysRelBoolExp>;
  predicate: IntComparisonExp;
};

export type ClassEventActivityAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ClassEventActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClassEventActivityBoolExp>;
  predicate: IntComparisonExp;
};

export type ClassEventMeetingParticipantsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ClassEventMeetingParticipantsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClassEventMeetingParticipantsBoolExp>;
  predicate: IntComparisonExp;
};

export type ClassEventMeetingSegmentsAggregateBoolExpBool_And = {
  arguments: ClassEventMeetingSegmentsSelectColumnClassEventMeetingSegmentsAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClassEventMeetingSegmentsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ClassEventMeetingSegmentsAggregateBoolExpBool_Or = {
  arguments: ClassEventMeetingSegmentsSelectColumnClassEventMeetingSegmentsAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClassEventMeetingSegmentsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ClassEventMeetingSegmentsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ClassEventMeetingSegmentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClassEventMeetingSegmentsBoolExp>;
  predicate: IntComparisonExp;
};

export type ClassEventMeetingsAggregateBoolExpBool_And = {
  arguments: ClassEventMeetingsSelectColumnClassEventMeetingsAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClassEventMeetingsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ClassEventMeetingsAggregateBoolExpBool_Or = {
  arguments: ClassEventMeetingsSelectColumnClassEventMeetingsAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClassEventMeetingsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ClassEventMeetingsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ClassEventMeetingsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClassEventMeetingsBoolExp>;
  predicate: IntComparisonExp;
};

export type ClassEventsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ClassEventsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClassEventsBoolExp>;
  predicate: IntComparisonExp;
};

export type ClassesAggregateBoolExpBool_And = {
  arguments: ClassesSelectColumnClassesAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClassesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ClassesAggregateBoolExpBool_Or = {
  arguments: ClassesSelectColumnClassesAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClassesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ClassesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ClassesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClassesBoolExp>;
  predicate: IntComparisonExp;
};

export type ClassesTagsRelAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ClassesTagsRelSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClassesTagsRelBoolExp>;
  predicate: IntComparisonExp;
};

export type CoachesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CoachesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoachesBoolExp>;
  predicate: IntComparisonExp;
};

export type CohortsAggregateBoolExpBool_And = {
  arguments: CohortsSelectColumnCohortsAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CohortsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CohortsAggregateBoolExpBool_Or = {
  arguments: CohortsSelectColumnCohortsAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CohortsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CohortsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CohortsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CohortsBoolExp>;
  predicate: IntComparisonExp;
};

export type CohortsResourcesRelAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CohortsResourcesRelSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CohortsResourcesRelBoolExp>;
  predicate: IntComparisonExp;
};

export type CoursesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CoursesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoursesBoolExp>;
  predicate: IntComparisonExp;
};

export type CoursesTagsRelAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CoursesTagsRelSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CoursesTagsRelBoolExp>;
  predicate: IntComparisonExp;
};

export type CreditUsage = {
  __typename?: 'creditUsage';
  cohortName: Scalars['String'];
  cohortStartDate: Scalars['timestamp'];
  courseName: Scalars['String'];
  enrollmentId: Scalars['uuid'];
  organizationId: Scalars['uuid'];
  scheduled: Scalars['Int'];
  spent: Scalars['Int'];
  userId: Scalars['uuid'];
};

/** Boolean expression to filter rows from the logical model for "creditUsage". All fields are combined with a logical 'AND'. */
export type CreditUsageBoolExpBoolExp = {
  _and?: InputMaybe<Array<CreditUsageBoolExpBoolExp>>;
  _not?: InputMaybe<CreditUsageBoolExpBoolExp>;
  _or?: InputMaybe<Array<CreditUsageBoolExpBoolExp>>;
  cohortName?: InputMaybe<StringComparisonExp>;
  cohortStartDate?: InputMaybe<TimestampComparisonExp>;
  courseName?: InputMaybe<StringComparisonExp>;
  enrollmentId?: InputMaybe<UuidComparisonExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  scheduled?: InputMaybe<IntComparisonExp>;
  spent?: InputMaybe<IntComparisonExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

export type CreditUsageByUser = {
  __typename?: 'creditUsageByUser';
  organizationId: Scalars['uuid'];
  scheduled: Scalars['Int'];
  spent: Scalars['Int'];
  userId: Scalars['uuid'];
};

/** Boolean expression to filter rows from the logical model for "creditUsageByUser". All fields are combined with a logical 'AND'. */
export type CreditUsageByUserBoolExpBoolExp = {
  _and?: InputMaybe<Array<CreditUsageByUserBoolExpBoolExp>>;
  _not?: InputMaybe<CreditUsageByUserBoolExpBoolExp>;
  _or?: InputMaybe<Array<CreditUsageByUserBoolExpBoolExp>>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  scheduled?: InputMaybe<IntComparisonExp>;
  spent?: InputMaybe<IntComparisonExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** Ordering options when selecting data from "creditUsageByUser". */
export type CreditUsageByUserOrderBy = {
  organizationId?: InputMaybe<OrderBy>;
  scheduled?: InputMaybe<OrderBy>;
  spent?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

export enum CreditUsageByUser_Enum_Name {
  /** column name */
  organizationId = 'organizationId',
  /** column name */
  scheduled = 'scheduled',
  /** column name */
  spent = 'spent',
  /** column name */
  userId = 'userId'
}

/** Ordering options when selecting data from "creditUsage". */
export type CreditUsageOrderBy = {
  cohortName?: InputMaybe<OrderBy>;
  cohortStartDate?: InputMaybe<OrderBy>;
  courseName?: InputMaybe<OrderBy>;
  enrollmentId?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  scheduled?: InputMaybe<OrderBy>;
  spent?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

export enum CreditUsage_Enum_Name {
  /** column name */
  cohortName = 'cohortName',
  /** column name */
  cohortStartDate = 'cohortStartDate',
  /** column name */
  courseName = 'courseName',
  /** column name */
  enrollmentId = 'enrollmentId',
  /** column name */
  organizationId = 'organizationId',
  /** column name */
  scheduled = 'scheduled',
  /** column name */
  spent = 'spent',
  /** column name */
  userId = 'userId'
}

export type EnrollmentWaitlistsAggregateBoolExpBool_And = {
  arguments: EnrollmentWaitlistsSelectColumnEnrollmentWaitlistsAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EnrollmentWaitlistsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EnrollmentWaitlistsAggregateBoolExpBool_Or = {
  arguments: EnrollmentWaitlistsSelectColumnEnrollmentWaitlistsAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EnrollmentWaitlistsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EnrollmentWaitlistsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EnrollmentWaitlistsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EnrollmentWaitlistsBoolExp>;
  predicate: IntComparisonExp;
};

export type EnrollmentsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EnrollmentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EnrollmentsBoolExp>;
  predicate: IntComparisonExp;
};

export type FusionauthUserRegistrationsAggregateBoolExpBool_And = {
  arguments: FusionauthUserRegistrationsSelectColumnFusionauthUserRegistrationsAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FusionauthUserRegistrationsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type FusionauthUserRegistrationsAggregateBoolExpBool_Or = {
  arguments: FusionauthUserRegistrationsSelectColumnFusionauthUserRegistrationsAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FusionauthUserRegistrationsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type FusionauthUserRegistrationsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<FusionauthUserRegistrationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FusionauthUserRegistrationsBoolExp>;
  predicate: IntComparisonExp;
};

export type GetAssessmentQuestionsArgs = {
  assessment_id?: InputMaybe<Scalars['uuid']>;
};

export type GetTeamMembersNew = {
  __typename?: 'getTeamMembersNew';
  avatarUrl?: Maybe<Scalars['String']>;
  /** An array relationship */
  creditUsage?: Maybe<Array<CreditUsage>>;
  /** An object relationship */
  creditUsageAggregate?: Maybe<CreditUsageByUser>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['uuid'];
  isActive: Scalars['Boolean'];
  /** An array relationship */
  pollSubmissions?: Maybe<Array<TeamMemberPollSubmissions>>;
  role: Scalars['String'];
  teamId: Scalars['uuid'];
};

export type GetTeamMembersNewCreditUsageArgs = {
  distinctOn?: InputMaybe<Array<CreditUsage_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CreditUsageOrderBy>>;
  where?: InputMaybe<CreditUsageBoolExpBoolExp>;
};

export type GetTeamMembersNewPollSubmissionsArgs = {
  distinctOn?: InputMaybe<Array<TeamMemberPollSubmissions_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamMemberPollSubmissionsOrderBy>>;
  where?: InputMaybe<TeamMemberPollSubmissionsBoolExpBoolExp>;
};

/** getTeamMembersNative Query Arguments */
export type GetTeamMembers_Arguments = {
  /** Boolean flag that either includes or excludes Active users */
  isActive: Scalars['Boolean'];
  /** JSONB Array of elements from the "my_team_flat_tree" native query, only with fields "treeId", "nodeHeight" and "teamId" */
  teamFlatTree: Scalars['jsonb'];
};

export type ImpactAssessmentAttemptsAggregateBoolExpBool_And = {
  arguments: ImpactAssessmentAttemptsSelectColumnImpactAssessmentAttemptsAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ImpactAssessmentAttemptsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ImpactAssessmentAttemptsAggregateBoolExpBool_Or = {
  arguments: ImpactAssessmentAttemptsSelectColumnImpactAssessmentAttemptsAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ImpactAssessmentAttemptsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ImpactAssessmentAttemptsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ImpactAssessmentAttemptsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ImpactAssessmentAttemptsBoolExp>;
  predicate: IntComparisonExp;
};

export type ImpactAssessmentQuestionsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ImpactAssessmentQuestionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ImpactAssessmentQuestionsBoolExp>;
  predicate: IntComparisonExp;
};

export type JourneyEnrollmentsAggregateBoolExpBool_And = {
  arguments: JourneyEnrollmentsSelectColumnJourneyEnrollmentsAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<JourneyEnrollmentsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type JourneyEnrollmentsAggregateBoolExpBool_Or = {
  arguments: JourneyEnrollmentsSelectColumnJourneyEnrollmentsAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<JourneyEnrollmentsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type JourneyEnrollmentsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<JourneyEnrollmentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<JourneyEnrollmentsBoolExp>;
  predicate: IntComparisonExp;
};

export type JourneysAggregateBoolExpBool_And = {
  arguments: JourneysSelectColumnJourneysAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<JourneysBoolExp>;
  predicate: BooleanComparisonExp;
};

export type JourneysAggregateBoolExpBool_Or = {
  arguments: JourneysSelectColumnJourneysAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<JourneysBoolExp>;
  predicate: BooleanComparisonExp;
};

export type JourneysAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<JourneysSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<JourneysBoolExp>;
  predicate: IntComparisonExp;
};

export type Learner_Task = {
  __typename?: 'learner_task';
  cohort_id: Scalars['uuid'];
  cohort_slug: Scalars['String'];
  course_name: Scalars['String'];
  course_type: Scalars['String'];
  date?: Maybe<Scalars['timestamp']>;
  is_done: Scalars['Boolean'];
  join_id_or_link?: Maybe<Scalars['String']>;
  task_name: Scalars['String'];
  user_id: Scalars['uuid'];
};

/** Boolean expression to filter rows from the logical model for "learner_task". All fields are combined with a logical 'AND'. */
export type Learner_TaskBoolExpBoolExp = {
  _and?: InputMaybe<Array<Learner_TaskBoolExpBoolExp>>;
  _not?: InputMaybe<Learner_TaskBoolExpBoolExp>;
  _or?: InputMaybe<Array<Learner_TaskBoolExpBoolExp>>;
  cohort_id?: InputMaybe<UuidComparisonExp>;
  cohort_slug?: InputMaybe<StringComparisonExp>;
  course_name?: InputMaybe<StringComparisonExp>;
  course_type?: InputMaybe<StringComparisonExp>;
  date?: InputMaybe<TimestampComparisonExp>;
  is_done?: InputMaybe<BooleanComparisonExp>;
  join_id_or_link?: InputMaybe<StringComparisonExp>;
  task_name?: InputMaybe<StringComparisonExp>;
  user_id?: InputMaybe<UuidComparisonExp>;
};

/** Ordering options when selecting data from "learner_task". */
export type Learner_TaskOrderBy = {
  cohort_id?: InputMaybe<OrderBy>;
  cohort_slug?: InputMaybe<OrderBy>;
  course_name?: InputMaybe<OrderBy>;
  course_type?: InputMaybe<OrderBy>;
  date?: InputMaybe<OrderBy>;
  is_done?: InputMaybe<OrderBy>;
  join_id_or_link?: InputMaybe<OrderBy>;
  task_name?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

export enum Learner_Task_Enum_Name {
  /** column name */
  cohort_id = 'cohort_id',
  /** column name */
  cohort_slug = 'cohort_slug',
  /** column name */
  course_name = 'course_name',
  /** column name */
  course_type = 'course_type',
  /** column name */
  date = 'date',
  /** column name */
  is_done = 'is_done',
  /** column name */
  join_id_or_link = 'join_id_or_link',
  /** column name */
  task_name = 'task_name',
  /** column name */
  user_id = 'user_id'
}

export type LightningSessionSubmissionsAggregateBoolExpBool_And = {
  arguments: LightningSessionSubmissionsSelectColumnLightningSessionSubmissionsAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LightningSessionSubmissionsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type LightningSessionSubmissionsAggregateBoolExpBool_Or = {
  arguments: LightningSessionSubmissionsSelectColumnLightningSessionSubmissionsAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LightningSessionSubmissionsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type LightningSessionSubmissionsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<LightningSessionSubmissionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LightningSessionSubmissionsBoolExp>;
  predicate: IntComparisonExp;
};

/** columns and relationships of "lightning_session_submissions_status_enum" */
export type LightningSessionSubmissionsStatus = {
  __typename?: 'lightningSessionSubmissionsStatus';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "lightning_session_submissions_status_enum" */
export type LightningSessionSubmissionsStatusAggregate = {
  __typename?: 'lightningSessionSubmissionsStatusAggregate';
  aggregate?: Maybe<LightningSessionSubmissionsStatusAggregateFields>;
  nodes: Array<LightningSessionSubmissionsStatus>;
};

/** aggregate fields of "lightning_session_submissions_status_enum" */
export type LightningSessionSubmissionsStatusAggregateFields = {
  __typename?: 'lightningSessionSubmissionsStatusAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<LightningSessionSubmissionsStatusMaxFields>;
  min?: Maybe<LightningSessionSubmissionsStatusMinFields>;
};

/** aggregate fields of "lightning_session_submissions_status_enum" */
export type LightningSessionSubmissionsStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LightningSessionSubmissionsStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "lightning_session_submissions_status_enum". All fields are combined with a logical 'AND'. */
export type LightningSessionSubmissionsStatusBoolExp = {
  _and?: InputMaybe<Array<LightningSessionSubmissionsStatusBoolExp>>;
  _not?: InputMaybe<LightningSessionSubmissionsStatusBoolExp>;
  _or?: InputMaybe<Array<LightningSessionSubmissionsStatusBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "lightning_session_submissions_status_enum" */
export enum LightningSessionSubmissionsStatusConstraint {
  /** unique or primary key constraint on columns "value" */
  lightning_session_submissions_status_enum_pkey = 'lightning_session_submissions_status_enum_pkey'
}

export enum LightningSessionSubmissionsStatusEnum {
  /** Complete */
  COMPLETE = 'COMPLETE',
  /** Meeting segment is transcoding and/or transcribing */
  PROCESSING = 'PROCESSING',
  /** User missed/skipped practice session */
  SKIPPED_PRACTICE = 'SKIPPED_PRACTICE',
  /** Waiting for coach review */
  WAITING_FOR_COACH_REVIEW = 'WAITING_FOR_COACH_REVIEW',
  /** Waiting for learner to check coach review */
  WAITING_FOR_COMPLETION = 'WAITING_FOR_COMPLETION',
  /** Waiting for learner pitch */
  WAITING_FOR_PITCH = 'WAITING_FOR_PITCH'
}

/** input type for inserting data into table "lightning_session_submissions_status_enum" */
export type LightningSessionSubmissionsStatusInsertInput = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type LightningSessionSubmissionsStatusMaxFields = {
  __typename?: 'lightningSessionSubmissionsStatusMaxFields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type LightningSessionSubmissionsStatusMinFields = {
  __typename?: 'lightningSessionSubmissionsStatusMinFields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "lightning_session_submissions_status_enum" */
export type LightningSessionSubmissionsStatusMutationResponse = {
  __typename?: 'lightningSessionSubmissionsStatusMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LightningSessionSubmissionsStatus>;
};

/** on_conflict condition type for table "lightning_session_submissions_status_enum" */
export type LightningSessionSubmissionsStatusOnConflict = {
  constraint: LightningSessionSubmissionsStatusConstraint;
  updateColumns?: Array<LightningSessionSubmissionsStatusUpdateColumn>;
  where?: InputMaybe<LightningSessionSubmissionsStatusBoolExp>;
};

/** Ordering options when selecting data from "lightning_session_submissions_status_enum". */
export type LightningSessionSubmissionsStatusOrderBy = {
  description?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: lightning_session_submissions_status_enum */
export type LightningSessionSubmissionsStatusPkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "lightning_session_submissions_status_enum" */
export enum LightningSessionSubmissionsStatusSelectColumn {
  /** column name */
  description = 'description',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "lightning_session_submissions_status_enum" */
export type LightningSessionSubmissionsStatusSetInput = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "lightningSessionSubmissionsStatus" */
export type LightningSessionSubmissionsStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: LightningSessionSubmissionsStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LightningSessionSubmissionsStatusStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "lightning_session_submissions_status_enum" */
export enum LightningSessionSubmissionsStatusUpdateColumn {
  /** column name */
  description = 'description',
  /** column name */
  value = 'value'
}

export type LightningSessionSubmissionsStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LightningSessionSubmissionsStatusSetInput>;
  /** filter the rows which have to be updated */
  where: LightningSessionSubmissionsStatusBoolExp;
};

export type LightningSessionSubmissionsUsersRelAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<LightningSessionSubmissionsUsersRelSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<LightningSessionSubmissionsUsersRelBoolExp>;
  predicate: IntComparisonExp;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** Adds one or more users to a Team */
  AddMembersToTeam?: Maybe<SuccessOutput>;
  AddUserToCourseWaitlist?: Maybe<AddUserToCourseWaitlistOutput>;
  AddUserToOrganization?: Maybe<SuccessOutput>;
  BeginClass?: Maybe<SuccessOutput>;
  BulkImportUsers?: Maybe<SuccessOutput>;
  ChangeTeamMemberRole?: Maybe<SuccessOutput>;
  /** CourseWaitlistSendAvailabilityEmail */
  CourseWaitlistSendAvailabilityEmail?: Maybe<CourseWaitlistSendAvailabilityEmailOutput>;
  CreateOrganization?: Maybe<CreateOrganizationOutput>;
  CreateRecording?: Maybe<SuccessOutput>;
  CreateUser?: Maybe<CreateUserOutput>;
  DeactivateUser?: Maybe<SuccessOutput>;
  /** Delete Team */
  DeleteTeam?: Maybe<SuccessOutput>;
  EndClass?: Maybe<SuccessOutput>;
  EnrollLearner?: Maybe<EnrollOutput>;
  GetCohortEnrollmentStatus?: Maybe<GetCohortEnrollmentStatusOutput>;
  /** Admin webhook for impersonating an user */
  ImpersonateUser?: Maybe<SampleOutput>;
  LaunchClassSurvey?: Maybe<SuccessOutput>;
  LightningSession?: Maybe<SuccessOutput>;
  MarkRecordingWatched?: Maybe<MarkRecordingWatchedOutput>;
  MigrateSingleUser?: Maybe<SuccessOutput>;
  /** Moves an user from one team to another */
  MoveMemberToTeam?: Maybe<SuccessOutput>;
  PosterFrameBackfill?: Maybe<SuccessOutput>;
  PurgePii?: Maybe<SuccessOutput>;
  ReactivateUser?: Maybe<SuccessOutput>;
  RegisterAnon?: Maybe<RegisterAnonOutput>;
  RegisterLearner?: Maybe<SuccessOutput>;
  /** Removes user from Team */
  RemoveTeamMember?: Maybe<SuccessOutput>;
  RemoveUserFromOrganization?: Maybe<SuccessOutput>;
  ResumeAssessment?: Maybe<ResumeAssessmentOutput>;
  RunPlay?: Maybe<RunPlayOutput>;
  SaveAssessment?: Maybe<SaveAssessmentOutput>;
  SendEventInvite?: Maybe<SuccessOutput>;
  SetClassStatus?: Maybe<SetClassStatusOutput>;
  SignToken?: Maybe<SuccessOutput>;
  SignUpload?: Maybe<SuccessOutput>;
  StartAssessment?: Maybe<StartAssessmentOutput>;
  StartPlayback?: Maybe<StartPlaybackOutput>;
  SubmitAndGradeAssessment?: Maybe<SubmitAndGradeAssessmentOutput>;
  SyncZoomMeeting?: Maybe<SuccessOutput>;
  /** Unenroll learner from cohort. Checks if requester is authorized to unenroll learner. */
  UnenrollLearner?: Maybe<UnenrollOutput>;
  UpdatePlayback?: Maybe<UpdatePlaybackOutput>;
  /** Update user and profile data */
  UpdateUser?: Maybe<SuccessOutput>;
  /** Create or update Team */
  UpsertTeam?: Maybe<TeamOutput>;
  UserProfileCapture?: Maybe<UserProfileCaptureOutput>;
  /** delete data from the table: "activity_type_enum" */
  deleteActivityType?: Maybe<ActivityTypeMutationResponse>;
  /** delete single row from the table: "activity_type_enum" */
  deleteActivityTypeByPk?: Maybe<ActivityType>;
  /** delete data from the table: "attempt_status_enum" */
  deleteAttemptStatus?: Maybe<AttemptStatusMutationResponse>;
  /** delete single row from the table: "attempt_status_enum" */
  deleteAttemptStatusByPk?: Maybe<AttemptStatus>;
  /** delete data from the table: "catalogs_courses_rel" */
  deleteCatalogCourses?: Maybe<CatalogCoursesMutationResponse>;
  /** delete single row from the table: "catalogs_courses_rel" */
  deleteCatalogCoursesByPk?: Maybe<CatalogCourses>;
  /** delete data from the table: "catalogs" */
  deleteCatalogs?: Maybe<CatalogsMutationResponse>;
  /** delete single row from the table: "catalogs" */
  deleteCatalogsByPk?: Maybe<Catalogs>;
  /** delete data from the table: "catalogs_journeys_rel" */
  deleteCatalogsJourneysRel?: Maybe<CatalogsJourneysRelMutationResponse>;
  /** delete single row from the table: "catalogs_journeys_rel" */
  deleteCatalogsJourneysRelByPk?: Maybe<CatalogsJourneysRel>;
  /** delete data from the table: "classes_coaches_rel" */
  deleteClassCoaches?: Maybe<ClassCoachesMutationResponse>;
  /** delete single row from the table: "classes_coaches_rel" */
  deleteClassCoachesByPk?: Maybe<ClassCoaches>;
  /** delete data from the table: "class_event_activity" */
  deleteClassEventActivity?: Maybe<ClassEventActivityMutationResponse>;
  /** delete single row from the table: "class_event_activity" */
  deleteClassEventActivityByPk?: Maybe<ClassEventActivity>;
  /** delete data from the table: "class_event_anon_registrations" */
  deleteClassEventAnonRegistrations?: Maybe<ClassEventAnonRegistrationsMutationResponse>;
  /** delete single row from the table: "class_event_anon_registrations" */
  deleteClassEventAnonRegistrationsByPk?: Maybe<ClassEventAnonRegistrations>;
  /** delete data from the table: "class_event_invitations" */
  deleteClassEventInvitations?: Maybe<ClassEventInvitationsMutationResponse>;
  /** delete single row from the table: "class_event_invitations" */
  deleteClassEventInvitationsByPk?: Maybe<ClassEventInvitations>;
  /** delete data from the table: "class_event_meeting_participants" */
  deleteClassEventMeetingParticipants?: Maybe<ClassEventMeetingParticipantsMutationResponse>;
  /** delete single row from the table: "class_event_meeting_participants" */
  deleteClassEventMeetingParticipantsByPk?: Maybe<ClassEventMeetingParticipants>;
  /** delete data from the table: "class_event_meeting_segments" */
  deleteClassEventMeetingSegments?: Maybe<ClassEventMeetingSegmentsMutationResponse>;
  /** delete single row from the table: "class_event_meeting_segments" */
  deleteClassEventMeetingSegmentsByPk?: Maybe<ClassEventMeetingSegments>;
  /** delete data from the table: "class_event_meetings" */
  deleteClassEventMeetings?: Maybe<ClassEventMeetingsMutationResponse>;
  /** delete single row from the table: "class_event_meetings" */
  deleteClassEventMeetingsByPk?: Maybe<ClassEventMeetings>;
  /** delete data from the table: "class_event_registrations" */
  deleteClassEventRegistrations?: Maybe<ClassEventRegistrationsMutationResponse>;
  /** delete single row from the table: "class_event_registrations" */
  deleteClassEventRegistrationsByPk?: Maybe<ClassEventRegistrations>;
  /** delete data from the table: "class_events" */
  deleteClassEvents?: Maybe<ClassEventsMutationResponse>;
  /** delete single row from the table: "class_events" */
  deleteClassEventsByPk?: Maybe<ClassEvents>;
  /** delete data from the table: "class_playback_activity" */
  deleteClassPlaybackActivity?: Maybe<ClassPlaybackActivityMutationResponse>;
  /** delete single row from the table: "class_playback_activity" */
  deleteClassPlaybackActivityByPk?: Maybe<ClassPlaybackActivity>;
  /** delete data from the table: "class_playback_activity_type_enum" */
  deleteClassPlaybackActivityType?: Maybe<ClassPlaybackActivityTypeMutationResponse>;
  /** delete single row from the table: "class_playback_activity_type_enum" */
  deleteClassPlaybackActivityTypeByPk?: Maybe<ClassPlaybackActivityType>;
  /** delete data from the table: "classes_resources_rel" */
  deleteClassResources?: Maybe<ClassResourcesMutationResponse>;
  /** delete single row from the table: "classes_resources_rel" */
  deleteClassResourcesByPk?: Maybe<ClassResources>;
  /** delete data from the table: "classes" */
  deleteClasses?: Maybe<ClassesMutationResponse>;
  /** delete single row from the table: "classes" */
  deleteClassesByPk?: Maybe<Classes>;
  /** delete data from the table: "classes_tags_rel" */
  deleteClassesTagsRel?: Maybe<ClassesTagsRelMutationResponse>;
  /** delete single row from the table: "classes_tags_rel" */
  deleteClassesTagsRelByPk?: Maybe<ClassesTagsRel>;
  /** delete data from the table: "coaches" */
  deleteCoaches?: Maybe<CoachesMutationResponse>;
  /** delete single row from the table: "coaches" */
  deleteCoachesByPk?: Maybe<Coaches>;
  /** delete data from the table: "cohorts_coaches_rel" */
  deleteCohortCoaches?: Maybe<CohortCoachesMutationResponse>;
  /** delete single row from the table: "cohorts_coaches_rel" */
  deleteCohortCoachesByPk?: Maybe<CohortCoaches>;
  /** delete data from the table: "cohort_type_enum" */
  deleteCohortType?: Maybe<CohortTypeMutationResponse>;
  /** delete single row from the table: "cohort_type_enum" */
  deleteCohortTypeByPk?: Maybe<CohortType>;
  /** delete data from the table: "cohort_visibility_enum" */
  deleteCohortVisibilityEnum?: Maybe<CohortVisibilityEnumMutationResponse>;
  /** delete single row from the table: "cohort_visibility_enum" */
  deleteCohortVisibilityEnumByPk?: Maybe<CohortVisibilityEnum>;
  /** delete data from the table: "cohorts" */
  deleteCohorts?: Maybe<CohortsMutationResponse>;
  /** delete single row from the table: "cohorts" */
  deleteCohortsByPk?: Maybe<Cohorts>;
  /** delete data from the table: "cohorts_resources_rel" */
  deleteCohortsResourcesRel?: Maybe<CohortsResourcesRelMutationResponse>;
  /** delete single row from the table: "cohorts_resources_rel" */
  deleteCohortsResourcesRelByPk?: Maybe<CohortsResourcesRel>;
  /** delete data from the table: "configurations" */
  deleteConfigurations?: Maybe<ConfigurationsMutationResponse>;
  /** delete single row from the table: "configurations" */
  deleteConfigurationsByPk?: Maybe<Configurations>;
  /** delete data from the table: "course_certifications" */
  deleteCourseCertifications?: Maybe<CourseCertificationsMutationResponse>;
  /** delete single row from the table: "course_certifications" */
  deleteCourseCertificationsByPk?: Maybe<CourseCertifications>;
  /** delete data from the table: "courses_coaches_rel" */
  deleteCourseCoaches?: Maybe<CourseCoachesMutationResponse>;
  /** delete single row from the table: "courses_coaches_rel" */
  deleteCourseCoachesByPk?: Maybe<CourseCoaches>;
  /** delete data from the table: "course_enrollment_status_enum" */
  deleteCourseEnrollmentStatus?: Maybe<CourseEnrollmentStatusMutationResponse>;
  /** delete single row from the table: "course_enrollment_status_enum" */
  deleteCourseEnrollmentStatusByPk?: Maybe<CourseEnrollmentStatus>;
  /** delete data from the table: "course_publish_status_enum" */
  deleteCoursePublishStatus?: Maybe<CoursePublishStatusMutationResponse>;
  /** delete single row from the table: "course_publish_status_enum" */
  deleteCoursePublishStatusByPk?: Maybe<CoursePublishStatus>;
  /** delete data from the table: "course_type_enum" */
  deleteCourseType?: Maybe<CourseTypeMutationResponse>;
  /** delete single row from the table: "course_type_enum" */
  deleteCourseTypeByPk?: Maybe<CourseType>;
  /** delete data from the table: "course_waitlist" */
  deleteCourseWaitlist?: Maybe<CourseWaitlistMutationResponse>;
  /** delete single row from the table: "course_waitlist" */
  deleteCourseWaitlistByPk?: Maybe<CourseWaitlist>;
  /** delete data from the table: "course_waitlist_desired_urgency_type" */
  deleteCourseWaitlistDesiredUrgencyType?: Maybe<CourseWaitlistDesiredUrgencyTypeMutationResponse>;
  /** delete single row from the table: "course_waitlist_desired_urgency_type" */
  deleteCourseWaitlistDesiredUrgencyTypeByPk?: Maybe<CourseWaitlistDesiredUrgencyType>;
  /** delete data from the table: "courses" */
  deleteCourses?: Maybe<CoursesMutationResponse>;
  /** delete single row from the table: "courses" */
  deleteCoursesByPk?: Maybe<Courses>;
  /** delete data from the table: "courses_resources_rel" */
  deleteCoursesResourcesRel?: Maybe<CoursesResourcesRelMutationResponse>;
  /** delete single row from the table: "courses_resources_rel" */
  deleteCoursesResourcesRelByPk?: Maybe<CoursesResourcesRel>;
  /** delete data from the table: "courses_tags_rel" */
  deleteCoursesTagsRel?: Maybe<CoursesTagsRelMutationResponse>;
  /** delete single row from the table: "courses_tags_rel" */
  deleteCoursesTagsRelByPk?: Maybe<CoursesTagsRel>;
  /** delete data from the table: "email_queue" */
  deleteEmailQueue?: Maybe<EmailQueueMutationResponse>;
  /** delete single row from the table: "email_queue" */
  deleteEmailQueueByPk?: Maybe<EmailQueue>;
  /** delete data from the table: "enrollment_waitlists" */
  deleteEnrollmentWaitlists?: Maybe<EnrollmentWaitlistsMutationResponse>;
  /** delete single row from the table: "enrollment_waitlists" */
  deleteEnrollmentWaitlistsByPk?: Maybe<EnrollmentWaitlists>;
  /** delete data from the table: "enrollments" */
  deleteEnrollments?: Maybe<EnrollmentsMutationResponse>;
  /** delete single row from the table: "enrollments" */
  deleteEnrollmentsByPk?: Maybe<Enrollments>;
  /** delete data from the table: "fusionauth.external_identifiers" */
  deleteFusionauthExternalIdentifiers?: Maybe<FusionauthExternalIdentifiersMutationResponse>;
  /** delete single row from the table: "fusionauth.external_identifiers" */
  deleteFusionauthExternalIdentifiersByPk?: Maybe<FusionauthExternalIdentifiers>;
  /** delete data from the table: "fusionauth.user_registrations" */
  deleteFusionauthUserRegistrations?: Maybe<FusionauthUserRegistrationsMutationResponse>;
  /** delete single row from the table: "fusionauth.user_registrations" */
  deleteFusionauthUserRegistrationsByPk?: Maybe<FusionauthUserRegistrations>;
  /** delete data from the table: "fusionauth.identities" */
  deleteIdentities?: Maybe<IdentitiesMutationResponse>;
  /** delete single row from the table: "fusionauth.identities" */
  deleteIdentitiesByPk?: Maybe<Identities>;
  /** delete data from the table: "fusionauth.identity_provider_links" */
  deleteIdentityProviderLinks?: Maybe<IdentityProviderLinksMutationResponse>;
  /** delete data from the table: "fusionauth.identity_providers" */
  deleteIdentityProviders?: Maybe<IdentityProvidersMutationResponse>;
  /** delete single row from the table: "fusionauth.identity_providers" */
  deleteIdentityProvidersByPk?: Maybe<IdentityProviders>;
  /** delete data from the table: "impact_assessment_attempts" */
  deleteImpactAssessmentAttempts?: Maybe<ImpactAssessmentAttemptsMutationResponse>;
  /** delete single row from the table: "impact_assessment_attempts" */
  deleteImpactAssessmentAttemptsByPk?: Maybe<ImpactAssessmentAttempts>;
  /** delete data from the table: "impact_assessment_questions" */
  deleteImpactAssessmentQuestions?: Maybe<ImpactAssessmentQuestionsMutationResponse>;
  /** delete data from the table: "impact_assessments" */
  deleteImpactAssessments?: Maybe<ImpactAssessmentsMutationResponse>;
  /** delete single row from the table: "impact_assessments" */
  deleteImpactAssessmentsByPk?: Maybe<ImpactAssessments>;
  /** delete data from the table: "journey_enrollments" */
  deleteJourneyEnrollments?: Maybe<JourneyEnrollmentsMutationResponse>;
  /** delete single row from the table: "journey_enrollments" */
  deleteJourneyEnrollmentsByPk?: Maybe<JourneyEnrollments>;
  /** delete data from the table: "journeys" */
  deleteJourneys?: Maybe<JourneysMutationResponse>;
  /** delete single row from the table: "journeys" */
  deleteJourneysByPk?: Maybe<Journeys>;
  /** delete data from the table: "ledger" */
  deleteLedger?: Maybe<LedgerMutationResponse>;
  /** delete single row from the table: "ledger" */
  deleteLedgerByPk?: Maybe<Ledger>;
  /** delete data from the table: "ledger_product_enum" */
  deleteLedgerProductEnum?: Maybe<LedgerProductEnumMutationResponse>;
  /** delete single row from the table: "ledger_product_enum" */
  deleteLedgerProductEnumByPk?: Maybe<LedgerProductEnum>;
  /** delete data from the table: "ledger_transaction_type_enum" */
  deleteLedgerTransactionTypeEnum?: Maybe<LedgerTransactionTypeEnumMutationResponse>;
  /** delete single row from the table: "ledger_transaction_type_enum" */
  deleteLedgerTransactionTypeEnumByPk?: Maybe<LedgerTransactionTypeEnum>;
  /** delete data from the table: "lightning_session" */
  deleteLightningSession?: Maybe<LightningSessionMutationResponse>;
  /** delete single row from the table: "lightning_session" */
  deleteLightningSessionByPk?: Maybe<LightningSession>;
  /** delete data from the table: "lightning_session_submissions" */
  deleteLightningSessionSubmissions?: Maybe<LightningSessionSubmissionsMutationResponse>;
  /** delete single row from the table: "lightning_session_submissions" */
  deleteLightningSessionSubmissionsByPk?: Maybe<LightningSessionSubmissions>;
  /** delete data from the table: "lightning_session_submissions_status_enum" */
  deleteLightningSessionSubmissionsStatus?: Maybe<LightningSessionSubmissionsStatusMutationResponse>;
  /** delete single row from the table: "lightning_session_submissions_status_enum" */
  deleteLightningSessionSubmissionsStatusByPk?: Maybe<LightningSessionSubmissionsStatus>;
  /** delete data from the table: "lightning_session_submissions_users_rel" */
  deleteLightningSessionSubmissionsUsersRel?: Maybe<LightningSessionSubmissionsUsersRelMutationResponse>;
  /** delete single row from the table: "lightning_session_submissions_users_rel" */
  deleteLightningSessionSubmissionsUsersRelByPk?: Maybe<LightningSessionSubmissionsUsersRel>;
  /** delete data from the table: "manager_session_emails" */
  deleteManagerSessionEmails?: Maybe<ManagerSessionEmailsMutationResponse>;
  /** delete single row from the table: "manager_session_emails" */
  deleteManagerSessionEmailsByPk?: Maybe<ManagerSessionEmails>;
  /** delete data from the table: "nonce_access_token" */
  deleteNonceAccessToken?: Maybe<NonceAccessTokenMutationResponse>;
  /** delete single row from the table: "nonce_access_token" */
  deleteNonceAccessTokenByPk?: Maybe<NonceAccessToken>;
  /** delete data from the table: "fusionauth.group_members" */
  deleteOrganizationUsers?: Maybe<OrganizationUsersMutationResponse>;
  /** delete single row from the table: "fusionauth.group_members" */
  deleteOrganizationUsersByPk?: Maybe<OrganizationUsers>;
  /** delete data from the table: "fusionauth.groups" */
  deleteOrganizations?: Maybe<OrganizationsMutationResponse>;
  /** delete single row from the table: "fusionauth.groups" */
  deleteOrganizationsByPk?: Maybe<Organizations>;
  /** delete data from the table: "organizations_meta" */
  deleteOrganizationsMeta?: Maybe<OrganizationsMetaMutationResponse>;
  /** delete single row from the table: "organizations_meta" */
  deleteOrganizationsMetaByPk?: Maybe<OrganizationsMeta>;
  /** delete data from the table: "plan_subscriptions" */
  deletePlanSubscriptions?: Maybe<PlanSubscriptionsMutationResponse>;
  /** delete single row from the table: "plan_subscriptions" */
  deletePlanSubscriptionsByPk?: Maybe<PlanSubscriptions>;
  /** delete data from the table: "plans" */
  deletePlans?: Maybe<PlansMutationResponse>;
  /** delete single row from the table: "plans" */
  deletePlansByPk?: Maybe<Plans>;
  /** delete data from the table: "auxiliary.play_execution" */
  deletePlayExecution?: Maybe<PlayExecutionMutationResponse>;
  /** delete single row from the table: "auxiliary.play_execution" */
  deletePlayExecutionByPk?: Maybe<PlayExecution>;
  /** delete data from the table: "program_actions_taken" */
  deleteProgramActionsTaken?: Maybe<ProgramActionsTakenMutationResponse>;
  /** delete data from the table: "program_courses_shown" */
  deleteProgramCoursesShown?: Maybe<ProgramCoursesShownMutationResponse>;
  /** delete single row from the table: "program_courses_shown" */
  deleteProgramCoursesShownByPk?: Maybe<ProgramCoursesShown>;
  /** delete data from the table: "program_tags" */
  deleteProgramTags?: Maybe<ProgramTagsMutationResponse>;
  /** delete single row from the table: "program_tags" */
  deleteProgramTagsByPk?: Maybe<ProgramTags>;
  /** delete data from the table: "program_users_selected" */
  deleteProgramUsersSelected?: Maybe<ProgramUsersSelectedMutationResponse>;
  /** delete single row from the table: "program_users_selected" */
  deleteProgramUsersSelectedByPk?: Maybe<ProgramUsersSelected>;
  /** delete data from the table: "programs" */
  deletePrograms?: Maybe<ProgramsMutationResponse>;
  /** delete single row from the table: "programs" */
  deleteProgramsByPk?: Maybe<Programs>;
  /** delete data from the table: "question_type_enum" */
  deleteQuestionType?: Maybe<QuestionTypeMutationResponse>;
  /** delete single row from the table: "question_type_enum" */
  deleteQuestionTypeByPk?: Maybe<QuestionType>;
  /** delete data from the table: "recording_activity" */
  deleteRecordingActivity?: Maybe<RecordingActivityMutationResponse>;
  /** delete single row from the table: "recording_activity" */
  deleteRecordingActivityByPk?: Maybe<RecordingActivity>;
  /** delete data from the table: "recordings" */
  deleteRecordings?: Maybe<RecordingsMutationResponse>;
  /** delete single row from the table: "recordings" */
  deleteRecordingsByPk?: Maybe<Recordings>;
  /** delete data from the table: "related_courses_rel" */
  deleteRelatedCourses?: Maybe<RelatedCoursesMutationResponse>;
  /** delete single row from the table: "related_courses_rel" */
  deleteRelatedCoursesByPk?: Maybe<RelatedCourses>;
  /** delete data from the table: "report_publish_status_enum" */
  deleteReportPublishStatus?: Maybe<ReportPublishStatusMutationResponse>;
  /** delete single row from the table: "report_publish_status_enum" */
  deleteReportPublishStatusByPk?: Maybe<ReportPublishStatus>;
  /** delete data from the table: "report_type_enum" */
  deleteReportType?: Maybe<ReportTypeMutationResponse>;
  /** delete single row from the table: "report_type_enum" */
  deleteReportTypeByPk?: Maybe<ReportType>;
  /** delete data from the table: "reports" */
  deleteReports?: Maybe<ReportsMutationResponse>;
  /** delete single row from the table: "reports" */
  deleteReportsByPk?: Maybe<Reports>;
  /** delete data from the table: "resource_type_enum" */
  deleteResourceType?: Maybe<ResourceTypeMutationResponse>;
  /** delete single row from the table: "resource_type_enum" */
  deleteResourceTypeByPk?: Maybe<ResourceType>;
  /** delete data from the table: "resources" */
  deleteResources?: Maybe<ResourcesMutationResponse>;
  /** delete single row from the table: "resources" */
  deleteResourcesByPk?: Maybe<Resources>;
  /** delete data from the table: "schools" */
  deleteSchools?: Maybe<SchoolsMutationResponse>;
  /** delete single row from the table: "schools" */
  deleteSchoolsByPk?: Maybe<Schools>;
  /** delete data from the table: "seat_type_enum" */
  deleteSeatType?: Maybe<SeatTypeMutationResponse>;
  /** delete single row from the table: "seat_type_enum" */
  deleteSeatTypeByPk?: Maybe<SeatType>;
  /** delete data from the table: "seats" */
  deleteSeats?: Maybe<SeatsMutationResponse>;
  /** delete single row from the table: "seats" */
  deleteSeatsByPk?: Maybe<Seats>;
  /** delete data from the table: "stream_messages" */
  deleteStreamMessages?: Maybe<StreamMessagesMutationResponse>;
  /** delete single row from the table: "stream_messages" */
  deleteStreamMessagesByPk?: Maybe<StreamMessages>;
  /** delete data from the table: "tag_type_scopes_enum" */
  deleteTagTypeScope?: Maybe<TagTypeScopeMutationResponse>;
  /** delete single row from the table: "tag_type_scopes_enum" */
  deleteTagTypeScopeByPk?: Maybe<TagTypeScope>;
  /** delete data from the table: "tag_types" */
  deleteTagTypes?: Maybe<TagTypesMutationResponse>;
  /** delete single row from the table: "tag_types" */
  deleteTagTypesByPk?: Maybe<TagTypes>;
  /** delete data from the table: "tags" */
  deleteTags?: Maybe<TagsMutationResponse>;
  /** delete single row from the table: "tags" */
  deleteTagsByPk?: Maybe<Tags>;
  /** delete data from the table: "task_definition" */
  deleteTaskDefinition?: Maybe<TaskDefinitionMutationResponse>;
  /** delete single row from the table: "task_definition" */
  deleteTaskDefinitionByPk?: Maybe<TaskDefinition>;
  /** delete data from the table: "task_definition_type_enum" */
  deleteTaskDefinitionType?: Maybe<TaskDefinitionTypeMutationResponse>;
  /** delete single row from the table: "task_definition_type_enum" */
  deleteTaskDefinitionTypeByPk?: Maybe<TaskDefinitionType>;
  /** delete data from the table: "tasks" */
  deleteTasks?: Maybe<TasksMutationResponse>;
  /** delete single row from the table: "tasks" */
  deleteTasksByPk?: Maybe<Tasks>;
  /** delete data from the table: "teams" */
  deleteTeams?: Maybe<TeamsMutationResponse>;
  /** delete single row from the table: "teams" */
  deleteTeamsByPk?: Maybe<Teams>;
  /** delete data from the table: "teams_users_rel" */
  deleteTeamsUsers?: Maybe<TeamsUsersMutationResponse>;
  /** delete single row from the table: "teams_users_rel" */
  deleteTeamsUsersByPk?: Maybe<TeamsUsers>;
  /** delete data from the table: "typeform_submissions" */
  deleteTypeformSubmissions?: Maybe<TypeformSubmissionsMutationResponse>;
  /** delete single row from the table: "typeform_submissions" */
  deleteTypeformSubmissionsByPk?: Maybe<TypeformSubmissions>;
  /** delete data from the table: "user_session_emails" */
  deleteUserSessionEmails?: Maybe<UserSessionEmailsMutationResponse>;
  /** delete single row from the table: "user_session_emails" */
  deleteUserSessionEmailsByPk?: Maybe<UserSessionEmails>;
  /** delete data from the table: "fusionauth.users" */
  deleteUsers?: Maybe<UsersMutationResponse>;
  /** delete single row from the table: "fusionauth.users" */
  deleteUsersByPk?: Maybe<Users>;
  /** delete data from the table: "users_functions_enum" */
  deleteUsersFunctions?: Maybe<UsersFunctionsMutationResponse>;
  /** delete single row from the table: "users_functions_enum" */
  deleteUsersFunctionsByPk?: Maybe<UsersFunctions>;
  /** delete data from the table: "users_length_in_function_enum" */
  deleteUsersLengthInFunction?: Maybe<UsersLengthInFunctionMutationResponse>;
  /** delete single row from the table: "users_length_in_function_enum" */
  deleteUsersLengthInFunctionByPk?: Maybe<UsersLengthInFunction>;
  /** delete data from the table: "users_meta" */
  deleteUsersMeta?: Maybe<UsersMetaMutationResponse>;
  /** delete single row from the table: "users_meta" */
  deleteUsersMetaByPk?: Maybe<UsersMeta>;
  /** delete data from the table: "zoom_oauth_tokens" */
  deleteZoomOauthTokens?: Maybe<ZoomOauthTokensMutationResponse>;
  /** delete single row from the table: "zoom_oauth_tokens" */
  deleteZoomOauthTokensByPk?: Maybe<ZoomOauthTokens>;
  /** insert data into the table: "activity_type_enum" */
  insertActivityType?: Maybe<ActivityTypeMutationResponse>;
  /** insert a single row into the table: "activity_type_enum" */
  insertActivityTypeOne?: Maybe<ActivityType>;
  /** insert data into the table: "attempt_status_enum" */
  insertAttemptStatus?: Maybe<AttemptStatusMutationResponse>;
  /** insert a single row into the table: "attempt_status_enum" */
  insertAttemptStatusOne?: Maybe<AttemptStatus>;
  /** insert data into the table: "catalogs_courses_rel" */
  insertCatalogCourses?: Maybe<CatalogCoursesMutationResponse>;
  /** insert a single row into the table: "catalogs_courses_rel" */
  insertCatalogCoursesOne?: Maybe<CatalogCourses>;
  /** insert data into the table: "catalogs" */
  insertCatalogs?: Maybe<CatalogsMutationResponse>;
  /** insert data into the table: "catalogs_journeys_rel" */
  insertCatalogsJourneysRel?: Maybe<CatalogsJourneysRelMutationResponse>;
  /** insert a single row into the table: "catalogs_journeys_rel" */
  insertCatalogsJourneysRelOne?: Maybe<CatalogsJourneysRel>;
  /** insert a single row into the table: "catalogs" */
  insertCatalogsOne?: Maybe<Catalogs>;
  /** insert data into the table: "classes_coaches_rel" */
  insertClassCoaches?: Maybe<ClassCoachesMutationResponse>;
  /** insert a single row into the table: "classes_coaches_rel" */
  insertClassCoachesOne?: Maybe<ClassCoaches>;
  /** insert data into the table: "class_event_activity" */
  insertClassEventActivity?: Maybe<ClassEventActivityMutationResponse>;
  /** insert a single row into the table: "class_event_activity" */
  insertClassEventActivityOne?: Maybe<ClassEventActivity>;
  /** insert data into the table: "class_event_anon_registrations" */
  insertClassEventAnonRegistrations?: Maybe<ClassEventAnonRegistrationsMutationResponse>;
  /** insert a single row into the table: "class_event_anon_registrations" */
  insertClassEventAnonRegistrationsOne?: Maybe<ClassEventAnonRegistrations>;
  /** insert data into the table: "class_event_invitations" */
  insertClassEventInvitations?: Maybe<ClassEventInvitationsMutationResponse>;
  /** insert a single row into the table: "class_event_invitations" */
  insertClassEventInvitationsOne?: Maybe<ClassEventInvitations>;
  /** insert data into the table: "class_event_meeting_participants" */
  insertClassEventMeetingParticipants?: Maybe<ClassEventMeetingParticipantsMutationResponse>;
  /** insert a single row into the table: "class_event_meeting_participants" */
  insertClassEventMeetingParticipantsOne?: Maybe<ClassEventMeetingParticipants>;
  /** insert data into the table: "class_event_meeting_segments" */
  insertClassEventMeetingSegments?: Maybe<ClassEventMeetingSegmentsMutationResponse>;
  /** insert a single row into the table: "class_event_meeting_segments" */
  insertClassEventMeetingSegmentsOne?: Maybe<ClassEventMeetingSegments>;
  /** insert data into the table: "class_event_meetings" */
  insertClassEventMeetings?: Maybe<ClassEventMeetingsMutationResponse>;
  /** insert a single row into the table: "class_event_meetings" */
  insertClassEventMeetingsOne?: Maybe<ClassEventMeetings>;
  /** insert data into the table: "class_event_registrations" */
  insertClassEventRegistrations?: Maybe<ClassEventRegistrationsMutationResponse>;
  /** insert a single row into the table: "class_event_registrations" */
  insertClassEventRegistrationsOne?: Maybe<ClassEventRegistrations>;
  /** insert data into the table: "class_events" */
  insertClassEvents?: Maybe<ClassEventsMutationResponse>;
  /** insert a single row into the table: "class_events" */
  insertClassEventsOne?: Maybe<ClassEvents>;
  /** insert data into the table: "class_playback_activity" */
  insertClassPlaybackActivity?: Maybe<ClassPlaybackActivityMutationResponse>;
  /** insert a single row into the table: "class_playback_activity" */
  insertClassPlaybackActivityOne?: Maybe<ClassPlaybackActivity>;
  /** insert data into the table: "class_playback_activity_type_enum" */
  insertClassPlaybackActivityType?: Maybe<ClassPlaybackActivityTypeMutationResponse>;
  /** insert a single row into the table: "class_playback_activity_type_enum" */
  insertClassPlaybackActivityTypeOne?: Maybe<ClassPlaybackActivityType>;
  /** insert data into the table: "classes_resources_rel" */
  insertClassResources?: Maybe<ClassResourcesMutationResponse>;
  /** insert a single row into the table: "classes_resources_rel" */
  insertClassResourcesOne?: Maybe<ClassResources>;
  /** insert data into the table: "classes" */
  insertClasses?: Maybe<ClassesMutationResponse>;
  /** insert a single row into the table: "classes" */
  insertClassesOne?: Maybe<Classes>;
  /** insert data into the table: "classes_tags_rel" */
  insertClassesTagsRel?: Maybe<ClassesTagsRelMutationResponse>;
  /** insert a single row into the table: "classes_tags_rel" */
  insertClassesTagsRelOne?: Maybe<ClassesTagsRel>;
  /** insert data into the table: "coaches" */
  insertCoaches?: Maybe<CoachesMutationResponse>;
  /** insert a single row into the table: "coaches" */
  insertCoachesOne?: Maybe<Coaches>;
  /** insert data into the table: "cohorts_coaches_rel" */
  insertCohortCoaches?: Maybe<CohortCoachesMutationResponse>;
  /** insert a single row into the table: "cohorts_coaches_rel" */
  insertCohortCoachesOne?: Maybe<CohortCoaches>;
  /** insert data into the table: "cohort_type_enum" */
  insertCohortType?: Maybe<CohortTypeMutationResponse>;
  /** insert a single row into the table: "cohort_type_enum" */
  insertCohortTypeOne?: Maybe<CohortType>;
  /** insert data into the table: "cohort_visibility_enum" */
  insertCohortVisibilityEnum?: Maybe<CohortVisibilityEnumMutationResponse>;
  /** insert a single row into the table: "cohort_visibility_enum" */
  insertCohortVisibilityEnumOne?: Maybe<CohortVisibilityEnum>;
  /** insert data into the table: "cohorts" */
  insertCohorts?: Maybe<CohortsMutationResponse>;
  /** insert a single row into the table: "cohorts" */
  insertCohortsOne?: Maybe<Cohorts>;
  /** insert data into the table: "cohorts_resources_rel" */
  insertCohortsResourcesRel?: Maybe<CohortsResourcesRelMutationResponse>;
  /** insert a single row into the table: "cohorts_resources_rel" */
  insertCohortsResourcesRelOne?: Maybe<CohortsResourcesRel>;
  /** insert data into the table: "configurations" */
  insertConfigurations?: Maybe<ConfigurationsMutationResponse>;
  /** insert a single row into the table: "configurations" */
  insertConfigurationsOne?: Maybe<Configurations>;
  /** insert data into the table: "course_certifications" */
  insertCourseCertifications?: Maybe<CourseCertificationsMutationResponse>;
  /** insert a single row into the table: "course_certifications" */
  insertCourseCertificationsOne?: Maybe<CourseCertifications>;
  /** insert data into the table: "courses_coaches_rel" */
  insertCourseCoaches?: Maybe<CourseCoachesMutationResponse>;
  /** insert a single row into the table: "courses_coaches_rel" */
  insertCourseCoachesOne?: Maybe<CourseCoaches>;
  /** insert data into the table: "course_enrollment_status_enum" */
  insertCourseEnrollmentStatus?: Maybe<CourseEnrollmentStatusMutationResponse>;
  /** insert a single row into the table: "course_enrollment_status_enum" */
  insertCourseEnrollmentStatusOne?: Maybe<CourseEnrollmentStatus>;
  /** insert data into the table: "course_publish_status_enum" */
  insertCoursePublishStatus?: Maybe<CoursePublishStatusMutationResponse>;
  /** insert a single row into the table: "course_publish_status_enum" */
  insertCoursePublishStatusOne?: Maybe<CoursePublishStatus>;
  /** insert data into the table: "course_type_enum" */
  insertCourseType?: Maybe<CourseTypeMutationResponse>;
  /** insert a single row into the table: "course_type_enum" */
  insertCourseTypeOne?: Maybe<CourseType>;
  /** insert data into the table: "course_waitlist" */
  insertCourseWaitlist?: Maybe<CourseWaitlistMutationResponse>;
  /** insert data into the table: "course_waitlist_desired_urgency_type" */
  insertCourseWaitlistDesiredUrgencyType?: Maybe<CourseWaitlistDesiredUrgencyTypeMutationResponse>;
  /** insert a single row into the table: "course_waitlist_desired_urgency_type" */
  insertCourseWaitlistDesiredUrgencyTypeOne?: Maybe<CourseWaitlistDesiredUrgencyType>;
  /** insert a single row into the table: "course_waitlist" */
  insertCourseWaitlistOne?: Maybe<CourseWaitlist>;
  /** insert data into the table: "courses" */
  insertCourses?: Maybe<CoursesMutationResponse>;
  /** insert a single row into the table: "courses" */
  insertCoursesOne?: Maybe<Courses>;
  /** insert data into the table: "courses_resources_rel" */
  insertCoursesResourcesRel?: Maybe<CoursesResourcesRelMutationResponse>;
  /** insert a single row into the table: "courses_resources_rel" */
  insertCoursesResourcesRelOne?: Maybe<CoursesResourcesRel>;
  /** insert data into the table: "courses_tags_rel" */
  insertCoursesTagsRel?: Maybe<CoursesTagsRelMutationResponse>;
  /** insert a single row into the table: "courses_tags_rel" */
  insertCoursesTagsRelOne?: Maybe<CoursesTagsRel>;
  /** insert data into the table: "email_queue" */
  insertEmailQueue?: Maybe<EmailQueueMutationResponse>;
  /** insert a single row into the table: "email_queue" */
  insertEmailQueueOne?: Maybe<EmailQueue>;
  /** insert data into the table: "enrollment_waitlists" */
  insertEnrollmentWaitlists?: Maybe<EnrollmentWaitlistsMutationResponse>;
  /** insert a single row into the table: "enrollment_waitlists" */
  insertEnrollmentWaitlistsOne?: Maybe<EnrollmentWaitlists>;
  /** insert data into the table: "enrollments" */
  insertEnrollments?: Maybe<EnrollmentsMutationResponse>;
  /** insert a single row into the table: "enrollments" */
  insertEnrollmentsOne?: Maybe<Enrollments>;
  /** insert data into the table: "fusionauth.external_identifiers" */
  insertFusionauthExternalIdentifiers?: Maybe<FusionauthExternalIdentifiersMutationResponse>;
  /** insert a single row into the table: "fusionauth.external_identifiers" */
  insertFusionauthExternalIdentifiersOne?: Maybe<FusionauthExternalIdentifiers>;
  /** insert data into the table: "fusionauth.user_registrations" */
  insertFusionauthUserRegistrations?: Maybe<FusionauthUserRegistrationsMutationResponse>;
  /** insert a single row into the table: "fusionauth.user_registrations" */
  insertFusionauthUserRegistrationsOne?: Maybe<FusionauthUserRegistrations>;
  /** insert data into the table: "fusionauth.identities" */
  insertIdentities?: Maybe<IdentitiesMutationResponse>;
  /** insert a single row into the table: "fusionauth.identities" */
  insertIdentitiesOne?: Maybe<Identities>;
  /** insert data into the table: "fusionauth.identity_provider_links" */
  insertIdentityProviderLinks?: Maybe<IdentityProviderLinksMutationResponse>;
  /** insert a single row into the table: "fusionauth.identity_provider_links" */
  insertIdentityProviderLinksOne?: Maybe<IdentityProviderLinks>;
  /** insert data into the table: "fusionauth.identity_providers" */
  insertIdentityProviders?: Maybe<IdentityProvidersMutationResponse>;
  /** insert a single row into the table: "fusionauth.identity_providers" */
  insertIdentityProvidersOne?: Maybe<IdentityProviders>;
  /** insert data into the table: "impact_assessment_attempts" */
  insertImpactAssessmentAttempts?: Maybe<ImpactAssessmentAttemptsMutationResponse>;
  /** insert a single row into the table: "impact_assessment_attempts" */
  insertImpactAssessmentAttemptsOne?: Maybe<ImpactAssessmentAttempts>;
  /** insert data into the table: "impact_assessment_questions" */
  insertImpactAssessmentQuestions?: Maybe<ImpactAssessmentQuestionsMutationResponse>;
  /** insert a single row into the table: "impact_assessment_questions" */
  insertImpactAssessmentQuestionsOne?: Maybe<ImpactAssessmentQuestions>;
  /** insert data into the table: "impact_assessments" */
  insertImpactAssessments?: Maybe<ImpactAssessmentsMutationResponse>;
  /** insert a single row into the table: "impact_assessments" */
  insertImpactAssessmentsOne?: Maybe<ImpactAssessments>;
  /** insert data into the table: "journey_enrollments" */
  insertJourneyEnrollments?: Maybe<JourneyEnrollmentsMutationResponse>;
  /** insert a single row into the table: "journey_enrollments" */
  insertJourneyEnrollmentsOne?: Maybe<JourneyEnrollments>;
  /** insert data into the table: "journeys" */
  insertJourneys?: Maybe<JourneysMutationResponse>;
  /** insert a single row into the table: "journeys" */
  insertJourneysOne?: Maybe<Journeys>;
  /** insert data into the table: "ledger" */
  insertLedger?: Maybe<LedgerMutationResponse>;
  /** insert a single row into the table: "ledger" */
  insertLedgerOne?: Maybe<Ledger>;
  /** insert data into the table: "ledger_product_enum" */
  insertLedgerProductEnum?: Maybe<LedgerProductEnumMutationResponse>;
  /** insert a single row into the table: "ledger_product_enum" */
  insertLedgerProductEnumOne?: Maybe<LedgerProductEnum>;
  /** insert data into the table: "ledger_transaction_type_enum" */
  insertLedgerTransactionTypeEnum?: Maybe<LedgerTransactionTypeEnumMutationResponse>;
  /** insert a single row into the table: "ledger_transaction_type_enum" */
  insertLedgerTransactionTypeEnumOne?: Maybe<LedgerTransactionTypeEnum>;
  /** insert data into the table: "lightning_session" */
  insertLightningSession?: Maybe<LightningSessionMutationResponse>;
  /** insert a single row into the table: "lightning_session" */
  insertLightningSessionOne?: Maybe<LightningSession>;
  /** insert data into the table: "lightning_session_submissions" */
  insertLightningSessionSubmissions?: Maybe<LightningSessionSubmissionsMutationResponse>;
  /** insert a single row into the table: "lightning_session_submissions" */
  insertLightningSessionSubmissionsOne?: Maybe<LightningSessionSubmissions>;
  /** insert data into the table: "lightning_session_submissions_status_enum" */
  insertLightningSessionSubmissionsStatus?: Maybe<LightningSessionSubmissionsStatusMutationResponse>;
  /** insert a single row into the table: "lightning_session_submissions_status_enum" */
  insertLightningSessionSubmissionsStatusOne?: Maybe<LightningSessionSubmissionsStatus>;
  /** insert data into the table: "lightning_session_submissions_users_rel" */
  insertLightningSessionSubmissionsUsersRel?: Maybe<LightningSessionSubmissionsUsersRelMutationResponse>;
  /** insert a single row into the table: "lightning_session_submissions_users_rel" */
  insertLightningSessionSubmissionsUsersRelOne?: Maybe<LightningSessionSubmissionsUsersRel>;
  /** insert data into the table: "manager_session_emails" */
  insertManagerSessionEmails?: Maybe<ManagerSessionEmailsMutationResponse>;
  /** insert a single row into the table: "manager_session_emails" */
  insertManagerSessionEmailsOne?: Maybe<ManagerSessionEmails>;
  /** insert data into the table: "nonce_access_token" */
  insertNonceAccessToken?: Maybe<NonceAccessTokenMutationResponse>;
  /** insert a single row into the table: "nonce_access_token" */
  insertNonceAccessTokenOne?: Maybe<NonceAccessToken>;
  /** insert data into the table: "fusionauth.group_members" */
  insertOrganizationUsers?: Maybe<OrganizationUsersMutationResponse>;
  /** insert a single row into the table: "fusionauth.group_members" */
  insertOrganizationUsersOne?: Maybe<OrganizationUsers>;
  /** insert data into the table: "fusionauth.groups" */
  insertOrganizations?: Maybe<OrganizationsMutationResponse>;
  /** insert data into the table: "organizations_meta" */
  insertOrganizationsMeta?: Maybe<OrganizationsMetaMutationResponse>;
  /** insert a single row into the table: "organizations_meta" */
  insertOrganizationsMetaOne?: Maybe<OrganizationsMeta>;
  /** insert a single row into the table: "fusionauth.groups" */
  insertOrganizationsOne?: Maybe<Organizations>;
  /** insert data into the table: "plan_subscriptions" */
  insertPlanSubscriptions?: Maybe<PlanSubscriptionsMutationResponse>;
  /** insert a single row into the table: "plan_subscriptions" */
  insertPlanSubscriptionsOne?: Maybe<PlanSubscriptions>;
  /** insert data into the table: "plans" */
  insertPlans?: Maybe<PlansMutationResponse>;
  /** insert a single row into the table: "plans" */
  insertPlansOne?: Maybe<Plans>;
  /** insert data into the table: "auxiliary.play_execution" */
  insertPlayExecution?: Maybe<PlayExecutionMutationResponse>;
  /** insert a single row into the table: "auxiliary.play_execution" */
  insertPlayExecutionOne?: Maybe<PlayExecution>;
  /** insert data into the table: "program_actions_taken" */
  insertProgramActionsTaken?: Maybe<ProgramActionsTakenMutationResponse>;
  /** insert a single row into the table: "program_actions_taken" */
  insertProgramActionsTakenOne?: Maybe<ProgramActionsTaken>;
  /** insert data into the table: "program_courses_shown" */
  insertProgramCoursesShown?: Maybe<ProgramCoursesShownMutationResponse>;
  /** insert a single row into the table: "program_courses_shown" */
  insertProgramCoursesShownOne?: Maybe<ProgramCoursesShown>;
  /** insert data into the table: "program_tags" */
  insertProgramTags?: Maybe<ProgramTagsMutationResponse>;
  /** insert a single row into the table: "program_tags" */
  insertProgramTagsOne?: Maybe<ProgramTags>;
  /** insert data into the table: "program_users_selected" */
  insertProgramUsersSelected?: Maybe<ProgramUsersSelectedMutationResponse>;
  /** insert a single row into the table: "program_users_selected" */
  insertProgramUsersSelectedOne?: Maybe<ProgramUsersSelected>;
  /** insert data into the table: "programs" */
  insertPrograms?: Maybe<ProgramsMutationResponse>;
  /** insert a single row into the table: "programs" */
  insertProgramsOne?: Maybe<Programs>;
  /** insert data into the table: "question_type_enum" */
  insertQuestionType?: Maybe<QuestionTypeMutationResponse>;
  /** insert a single row into the table: "question_type_enum" */
  insertQuestionTypeOne?: Maybe<QuestionType>;
  /** insert data into the table: "recording_activity" */
  insertRecordingActivity?: Maybe<RecordingActivityMutationResponse>;
  /** insert a single row into the table: "recording_activity" */
  insertRecordingActivityOne?: Maybe<RecordingActivity>;
  /** insert data into the table: "recordings" */
  insertRecordings?: Maybe<RecordingsMutationResponse>;
  /** insert a single row into the table: "recordings" */
  insertRecordingsOne?: Maybe<Recordings>;
  /** insert data into the table: "related_courses_rel" */
  insertRelatedCourses?: Maybe<RelatedCoursesMutationResponse>;
  /** insert a single row into the table: "related_courses_rel" */
  insertRelatedCoursesOne?: Maybe<RelatedCourses>;
  /** insert data into the table: "report_publish_status_enum" */
  insertReportPublishStatus?: Maybe<ReportPublishStatusMutationResponse>;
  /** insert a single row into the table: "report_publish_status_enum" */
  insertReportPublishStatusOne?: Maybe<ReportPublishStatus>;
  /** insert data into the table: "report_type_enum" */
  insertReportType?: Maybe<ReportTypeMutationResponse>;
  /** insert a single row into the table: "report_type_enum" */
  insertReportTypeOne?: Maybe<ReportType>;
  /** insert data into the table: "reports" */
  insertReports?: Maybe<ReportsMutationResponse>;
  /** insert a single row into the table: "reports" */
  insertReportsOne?: Maybe<Reports>;
  /** insert data into the table: "resource_type_enum" */
  insertResourceType?: Maybe<ResourceTypeMutationResponse>;
  /** insert a single row into the table: "resource_type_enum" */
  insertResourceTypeOne?: Maybe<ResourceType>;
  /** insert data into the table: "resources" */
  insertResources?: Maybe<ResourcesMutationResponse>;
  /** insert a single row into the table: "resources" */
  insertResourcesOne?: Maybe<Resources>;
  /** insert data into the table: "schools" */
  insertSchools?: Maybe<SchoolsMutationResponse>;
  /** insert a single row into the table: "schools" */
  insertSchoolsOne?: Maybe<Schools>;
  /** insert data into the table: "seat_type_enum" */
  insertSeatType?: Maybe<SeatTypeMutationResponse>;
  /** insert a single row into the table: "seat_type_enum" */
  insertSeatTypeOne?: Maybe<SeatType>;
  /** insert data into the table: "seats" */
  insertSeats?: Maybe<SeatsMutationResponse>;
  /** insert a single row into the table: "seats" */
  insertSeatsOne?: Maybe<Seats>;
  /** insert data into the table: "stream_messages" */
  insertStreamMessages?: Maybe<StreamMessagesMutationResponse>;
  /** insert a single row into the table: "stream_messages" */
  insertStreamMessagesOne?: Maybe<StreamMessages>;
  /** insert data into the table: "tag_type_scopes_enum" */
  insertTagTypeScope?: Maybe<TagTypeScopeMutationResponse>;
  /** insert a single row into the table: "tag_type_scopes_enum" */
  insertTagTypeScopeOne?: Maybe<TagTypeScope>;
  /** insert data into the table: "tag_types" */
  insertTagTypes?: Maybe<TagTypesMutationResponse>;
  /** insert a single row into the table: "tag_types" */
  insertTagTypesOne?: Maybe<TagTypes>;
  /** insert data into the table: "tags" */
  insertTags?: Maybe<TagsMutationResponse>;
  /** insert a single row into the table: "tags" */
  insertTagsOne?: Maybe<Tags>;
  /** insert data into the table: "task_definition" */
  insertTaskDefinition?: Maybe<TaskDefinitionMutationResponse>;
  /** insert a single row into the table: "task_definition" */
  insertTaskDefinitionOne?: Maybe<TaskDefinition>;
  /** insert data into the table: "task_definition_type_enum" */
  insertTaskDefinitionType?: Maybe<TaskDefinitionTypeMutationResponse>;
  /** insert a single row into the table: "task_definition_type_enum" */
  insertTaskDefinitionTypeOne?: Maybe<TaskDefinitionType>;
  /** insert data into the table: "tasks" */
  insertTasks?: Maybe<TasksMutationResponse>;
  /** insert a single row into the table: "tasks" */
  insertTasksOne?: Maybe<Tasks>;
  /** insert data into the table: "teams" */
  insertTeams?: Maybe<TeamsMutationResponse>;
  /** insert a single row into the table: "teams" */
  insertTeamsOne?: Maybe<Teams>;
  /** insert data into the table: "teams_users_rel" */
  insertTeamsUsers?: Maybe<TeamsUsersMutationResponse>;
  /** insert a single row into the table: "teams_users_rel" */
  insertTeamsUsersOne?: Maybe<TeamsUsers>;
  /** insert data into the table: "typeform_submissions" */
  insertTypeformSubmissions?: Maybe<TypeformSubmissionsMutationResponse>;
  /** insert a single row into the table: "typeform_submissions" */
  insertTypeformSubmissionsOne?: Maybe<TypeformSubmissions>;
  /** insert data into the table: "user_session_emails" */
  insertUserSessionEmails?: Maybe<UserSessionEmailsMutationResponse>;
  /** insert a single row into the table: "user_session_emails" */
  insertUserSessionEmailsOne?: Maybe<UserSessionEmails>;
  /** insert data into the table: "fusionauth.users" */
  insertUsers?: Maybe<UsersMutationResponse>;
  /** insert data into the table: "users_functions_enum" */
  insertUsersFunctions?: Maybe<UsersFunctionsMutationResponse>;
  /** insert a single row into the table: "users_functions_enum" */
  insertUsersFunctionsOne?: Maybe<UsersFunctions>;
  /** insert data into the table: "users_length_in_function_enum" */
  insertUsersLengthInFunction?: Maybe<UsersLengthInFunctionMutationResponse>;
  /** insert a single row into the table: "users_length_in_function_enum" */
  insertUsersLengthInFunctionOne?: Maybe<UsersLengthInFunction>;
  /** insert data into the table: "users_meta" */
  insertUsersMeta?: Maybe<UsersMetaMutationResponse>;
  /** insert a single row into the table: "users_meta" */
  insertUsersMetaOne?: Maybe<UsersMeta>;
  /** insert a single row into the table: "fusionauth.users" */
  insertUsersOne?: Maybe<Users>;
  /** insert data into the table: "zoom_oauth_tokens" */
  insertZoomOauthTokens?: Maybe<ZoomOauthTokensMutationResponse>;
  /** insert a single row into the table: "zoom_oauth_tokens" */
  insertZoomOauthTokensOne?: Maybe<ZoomOauthTokens>;
  /** execute VOLATILE function "publish_course" which returns "courses" */
  publishCourse: Array<Courses>;
  /** update data of the table: "activity_type_enum" */
  updateActivityType?: Maybe<ActivityTypeMutationResponse>;
  /** update single row of the table: "activity_type_enum" */
  updateActivityTypeByPk?: Maybe<ActivityType>;
  /** update multiples rows of table: "activity_type_enum" */
  updateActivityTypeMany?: Maybe<Array<Maybe<ActivityTypeMutationResponse>>>;
  /** update data of the table: "attempt_status_enum" */
  updateAttemptStatus?: Maybe<AttemptStatusMutationResponse>;
  /** update single row of the table: "attempt_status_enum" */
  updateAttemptStatusByPk?: Maybe<AttemptStatus>;
  /** update multiples rows of table: "attempt_status_enum" */
  updateAttemptStatusMany?: Maybe<Array<Maybe<AttemptStatusMutationResponse>>>;
  /** update data of the table: "catalogs_courses_rel" */
  updateCatalogCourses?: Maybe<CatalogCoursesMutationResponse>;
  /** update single row of the table: "catalogs_courses_rel" */
  updateCatalogCoursesByPk?: Maybe<CatalogCourses>;
  /** update multiples rows of table: "catalogs_courses_rel" */
  updateCatalogCoursesMany?: Maybe<Array<Maybe<CatalogCoursesMutationResponse>>>;
  /** update data of the table: "catalogs" */
  updateCatalogs?: Maybe<CatalogsMutationResponse>;
  /** update single row of the table: "catalogs" */
  updateCatalogsByPk?: Maybe<Catalogs>;
  /** update data of the table: "catalogs_journeys_rel" */
  updateCatalogsJourneysRel?: Maybe<CatalogsJourneysRelMutationResponse>;
  /** update single row of the table: "catalogs_journeys_rel" */
  updateCatalogsJourneysRelByPk?: Maybe<CatalogsJourneysRel>;
  /** update multiples rows of table: "catalogs_journeys_rel" */
  updateCatalogsJourneysRelMany?: Maybe<Array<Maybe<CatalogsJourneysRelMutationResponse>>>;
  /** update multiples rows of table: "catalogs" */
  updateCatalogsMany?: Maybe<Array<Maybe<CatalogsMutationResponse>>>;
  /** update data of the table: "classes_coaches_rel" */
  updateClassCoaches?: Maybe<ClassCoachesMutationResponse>;
  /** update single row of the table: "classes_coaches_rel" */
  updateClassCoachesByPk?: Maybe<ClassCoaches>;
  /** update multiples rows of table: "classes_coaches_rel" */
  updateClassCoachesMany?: Maybe<Array<Maybe<ClassCoachesMutationResponse>>>;
  /** update data of the table: "class_event_activity" */
  updateClassEventActivity?: Maybe<ClassEventActivityMutationResponse>;
  /** update single row of the table: "class_event_activity" */
  updateClassEventActivityByPk?: Maybe<ClassEventActivity>;
  /** update multiples rows of table: "class_event_activity" */
  updateClassEventActivityMany?: Maybe<Array<Maybe<ClassEventActivityMutationResponse>>>;
  /** update data of the table: "class_event_anon_registrations" */
  updateClassEventAnonRegistrations?: Maybe<ClassEventAnonRegistrationsMutationResponse>;
  /** update single row of the table: "class_event_anon_registrations" */
  updateClassEventAnonRegistrationsByPk?: Maybe<ClassEventAnonRegistrations>;
  /** update multiples rows of table: "class_event_anon_registrations" */
  updateClassEventAnonRegistrationsMany?: Maybe<
    Array<Maybe<ClassEventAnonRegistrationsMutationResponse>>
  >;
  /** update data of the table: "class_event_invitations" */
  updateClassEventInvitations?: Maybe<ClassEventInvitationsMutationResponse>;
  /** update single row of the table: "class_event_invitations" */
  updateClassEventInvitationsByPk?: Maybe<ClassEventInvitations>;
  /** update multiples rows of table: "class_event_invitations" */
  updateClassEventInvitationsMany?: Maybe<Array<Maybe<ClassEventInvitationsMutationResponse>>>;
  /** update data of the table: "class_event_meeting_participants" */
  updateClassEventMeetingParticipants?: Maybe<ClassEventMeetingParticipantsMutationResponse>;
  /** update single row of the table: "class_event_meeting_participants" */
  updateClassEventMeetingParticipantsByPk?: Maybe<ClassEventMeetingParticipants>;
  /** update multiples rows of table: "class_event_meeting_participants" */
  updateClassEventMeetingParticipantsMany?: Maybe<
    Array<Maybe<ClassEventMeetingParticipantsMutationResponse>>
  >;
  /** update data of the table: "class_event_meeting_segments" */
  updateClassEventMeetingSegments?: Maybe<ClassEventMeetingSegmentsMutationResponse>;
  /** update single row of the table: "class_event_meeting_segments" */
  updateClassEventMeetingSegmentsByPk?: Maybe<ClassEventMeetingSegments>;
  /** update multiples rows of table: "class_event_meeting_segments" */
  updateClassEventMeetingSegmentsMany?: Maybe<
    Array<Maybe<ClassEventMeetingSegmentsMutationResponse>>
  >;
  /** update data of the table: "class_event_meetings" */
  updateClassEventMeetings?: Maybe<ClassEventMeetingsMutationResponse>;
  /** update single row of the table: "class_event_meetings" */
  updateClassEventMeetingsByPk?: Maybe<ClassEventMeetings>;
  /** update multiples rows of table: "class_event_meetings" */
  updateClassEventMeetingsMany?: Maybe<Array<Maybe<ClassEventMeetingsMutationResponse>>>;
  /** update data of the table: "class_event_registrations" */
  updateClassEventRegistrations?: Maybe<ClassEventRegistrationsMutationResponse>;
  /** update single row of the table: "class_event_registrations" */
  updateClassEventRegistrationsByPk?: Maybe<ClassEventRegistrations>;
  /** update multiples rows of table: "class_event_registrations" */
  updateClassEventRegistrationsMany?: Maybe<Array<Maybe<ClassEventRegistrationsMutationResponse>>>;
  /** update data of the table: "class_events" */
  updateClassEvents?: Maybe<ClassEventsMutationResponse>;
  /** update single row of the table: "class_events" */
  updateClassEventsByPk?: Maybe<ClassEvents>;
  /** update multiples rows of table: "class_events" */
  updateClassEventsMany?: Maybe<Array<Maybe<ClassEventsMutationResponse>>>;
  /** update data of the table: "class_playback_activity" */
  updateClassPlaybackActivity?: Maybe<ClassPlaybackActivityMutationResponse>;
  /** update single row of the table: "class_playback_activity" */
  updateClassPlaybackActivityByPk?: Maybe<ClassPlaybackActivity>;
  /** update multiples rows of table: "class_playback_activity" */
  updateClassPlaybackActivityMany?: Maybe<Array<Maybe<ClassPlaybackActivityMutationResponse>>>;
  /** update data of the table: "class_playback_activity_type_enum" */
  updateClassPlaybackActivityType?: Maybe<ClassPlaybackActivityTypeMutationResponse>;
  /** update single row of the table: "class_playback_activity_type_enum" */
  updateClassPlaybackActivityTypeByPk?: Maybe<ClassPlaybackActivityType>;
  /** update multiples rows of table: "class_playback_activity_type_enum" */
  updateClassPlaybackActivityTypeMany?: Maybe<
    Array<Maybe<ClassPlaybackActivityTypeMutationResponse>>
  >;
  /** update data of the table: "classes_resources_rel" */
  updateClassResources?: Maybe<ClassResourcesMutationResponse>;
  /** update single row of the table: "classes_resources_rel" */
  updateClassResourcesByPk?: Maybe<ClassResources>;
  /** update multiples rows of table: "classes_resources_rel" */
  updateClassResourcesMany?: Maybe<Array<Maybe<ClassResourcesMutationResponse>>>;
  /** update data of the table: "classes" */
  updateClasses?: Maybe<ClassesMutationResponse>;
  /** update single row of the table: "classes" */
  updateClassesByPk?: Maybe<Classes>;
  /** update multiples rows of table: "classes" */
  updateClassesMany?: Maybe<Array<Maybe<ClassesMutationResponse>>>;
  /** update data of the table: "classes_tags_rel" */
  updateClassesTagsRel?: Maybe<ClassesTagsRelMutationResponse>;
  /** update single row of the table: "classes_tags_rel" */
  updateClassesTagsRelByPk?: Maybe<ClassesTagsRel>;
  /** update multiples rows of table: "classes_tags_rel" */
  updateClassesTagsRelMany?: Maybe<Array<Maybe<ClassesTagsRelMutationResponse>>>;
  /** update data of the table: "coaches" */
  updateCoaches?: Maybe<CoachesMutationResponse>;
  /** update single row of the table: "coaches" */
  updateCoachesByPk?: Maybe<Coaches>;
  /** update multiples rows of table: "coaches" */
  updateCoachesMany?: Maybe<Array<Maybe<CoachesMutationResponse>>>;
  /** update data of the table: "cohorts_coaches_rel" */
  updateCohortCoaches?: Maybe<CohortCoachesMutationResponse>;
  /** update single row of the table: "cohorts_coaches_rel" */
  updateCohortCoachesByPk?: Maybe<CohortCoaches>;
  /** update multiples rows of table: "cohorts_coaches_rel" */
  updateCohortCoachesMany?: Maybe<Array<Maybe<CohortCoachesMutationResponse>>>;
  /** update data of the table: "cohort_type_enum" */
  updateCohortType?: Maybe<CohortTypeMutationResponse>;
  /** update single row of the table: "cohort_type_enum" */
  updateCohortTypeByPk?: Maybe<CohortType>;
  /** update multiples rows of table: "cohort_type_enum" */
  updateCohortTypeMany?: Maybe<Array<Maybe<CohortTypeMutationResponse>>>;
  /** update data of the table: "cohort_visibility_enum" */
  updateCohortVisibilityEnum?: Maybe<CohortVisibilityEnumMutationResponse>;
  /** update single row of the table: "cohort_visibility_enum" */
  updateCohortVisibilityEnumByPk?: Maybe<CohortVisibilityEnum>;
  /** update multiples rows of table: "cohort_visibility_enum" */
  updateCohortVisibilityEnumMany?: Maybe<Array<Maybe<CohortVisibilityEnumMutationResponse>>>;
  /** update data of the table: "cohorts" */
  updateCohorts?: Maybe<CohortsMutationResponse>;
  /** update single row of the table: "cohorts" */
  updateCohortsByPk?: Maybe<Cohorts>;
  /** update multiples rows of table: "cohorts" */
  updateCohortsMany?: Maybe<Array<Maybe<CohortsMutationResponse>>>;
  /** update data of the table: "cohorts_resources_rel" */
  updateCohortsResourcesRel?: Maybe<CohortsResourcesRelMutationResponse>;
  /** update single row of the table: "cohorts_resources_rel" */
  updateCohortsResourcesRelByPk?: Maybe<CohortsResourcesRel>;
  /** update multiples rows of table: "cohorts_resources_rel" */
  updateCohortsResourcesRelMany?: Maybe<Array<Maybe<CohortsResourcesRelMutationResponse>>>;
  /** update data of the table: "configurations" */
  updateConfigurations?: Maybe<ConfigurationsMutationResponse>;
  /** update single row of the table: "configurations" */
  updateConfigurationsByPk?: Maybe<Configurations>;
  /** update multiples rows of table: "configurations" */
  updateConfigurationsMany?: Maybe<Array<Maybe<ConfigurationsMutationResponse>>>;
  /** update data of the table: "course_certifications" */
  updateCourseCertifications?: Maybe<CourseCertificationsMutationResponse>;
  /** update single row of the table: "course_certifications" */
  updateCourseCertificationsByPk?: Maybe<CourseCertifications>;
  /** update multiples rows of table: "course_certifications" */
  updateCourseCertificationsMany?: Maybe<Array<Maybe<CourseCertificationsMutationResponse>>>;
  /** update data of the table: "courses_coaches_rel" */
  updateCourseCoaches?: Maybe<CourseCoachesMutationResponse>;
  /** update single row of the table: "courses_coaches_rel" */
  updateCourseCoachesByPk?: Maybe<CourseCoaches>;
  /** update multiples rows of table: "courses_coaches_rel" */
  updateCourseCoachesMany?: Maybe<Array<Maybe<CourseCoachesMutationResponse>>>;
  /** update data of the table: "course_enrollment_status_enum" */
  updateCourseEnrollmentStatus?: Maybe<CourseEnrollmentStatusMutationResponse>;
  /** update single row of the table: "course_enrollment_status_enum" */
  updateCourseEnrollmentStatusByPk?: Maybe<CourseEnrollmentStatus>;
  /** update multiples rows of table: "course_enrollment_status_enum" */
  updateCourseEnrollmentStatusMany?: Maybe<Array<Maybe<CourseEnrollmentStatusMutationResponse>>>;
  /** update data of the table: "course_publish_status_enum" */
  updateCoursePublishStatus?: Maybe<CoursePublishStatusMutationResponse>;
  /** update single row of the table: "course_publish_status_enum" */
  updateCoursePublishStatusByPk?: Maybe<CoursePublishStatus>;
  /** update multiples rows of table: "course_publish_status_enum" */
  updateCoursePublishStatusMany?: Maybe<Array<Maybe<CoursePublishStatusMutationResponse>>>;
  /** update data of the table: "course_type_enum" */
  updateCourseType?: Maybe<CourseTypeMutationResponse>;
  /** update single row of the table: "course_type_enum" */
  updateCourseTypeByPk?: Maybe<CourseType>;
  /** update multiples rows of table: "course_type_enum" */
  updateCourseTypeMany?: Maybe<Array<Maybe<CourseTypeMutationResponse>>>;
  /** update data of the table: "course_waitlist" */
  updateCourseWaitlist?: Maybe<CourseWaitlistMutationResponse>;
  /** update single row of the table: "course_waitlist" */
  updateCourseWaitlistByPk?: Maybe<CourseWaitlist>;
  /** update data of the table: "course_waitlist_desired_urgency_type" */
  updateCourseWaitlistDesiredUrgencyType?: Maybe<CourseWaitlistDesiredUrgencyTypeMutationResponse>;
  /** update single row of the table: "course_waitlist_desired_urgency_type" */
  updateCourseWaitlistDesiredUrgencyTypeByPk?: Maybe<CourseWaitlistDesiredUrgencyType>;
  /** update multiples rows of table: "course_waitlist_desired_urgency_type" */
  updateCourseWaitlistDesiredUrgencyTypeMany?: Maybe<
    Array<Maybe<CourseWaitlistDesiredUrgencyTypeMutationResponse>>
  >;
  /** update multiples rows of table: "course_waitlist" */
  updateCourseWaitlistMany?: Maybe<Array<Maybe<CourseWaitlistMutationResponse>>>;
  /** update data of the table: "courses" */
  updateCourses?: Maybe<CoursesMutationResponse>;
  /** update single row of the table: "courses" */
  updateCoursesByPk?: Maybe<Courses>;
  /** update multiples rows of table: "courses" */
  updateCoursesMany?: Maybe<Array<Maybe<CoursesMutationResponse>>>;
  /** update data of the table: "courses_resources_rel" */
  updateCoursesResourcesRel?: Maybe<CoursesResourcesRelMutationResponse>;
  /** update single row of the table: "courses_resources_rel" */
  updateCoursesResourcesRelByPk?: Maybe<CoursesResourcesRel>;
  /** update multiples rows of table: "courses_resources_rel" */
  updateCoursesResourcesRelMany?: Maybe<Array<Maybe<CoursesResourcesRelMutationResponse>>>;
  /** update data of the table: "courses_tags_rel" */
  updateCoursesTagsRel?: Maybe<CoursesTagsRelMutationResponse>;
  /** update single row of the table: "courses_tags_rel" */
  updateCoursesTagsRelByPk?: Maybe<CoursesTagsRel>;
  /** update multiples rows of table: "courses_tags_rel" */
  updateCoursesTagsRelMany?: Maybe<Array<Maybe<CoursesTagsRelMutationResponse>>>;
  /** update data of the table: "email_queue" */
  updateEmailQueue?: Maybe<EmailQueueMutationResponse>;
  /** update single row of the table: "email_queue" */
  updateEmailQueueByPk?: Maybe<EmailQueue>;
  /** update multiples rows of table: "email_queue" */
  updateEmailQueueMany?: Maybe<Array<Maybe<EmailQueueMutationResponse>>>;
  /** update data of the table: "enrollment_waitlists" */
  updateEnrollmentWaitlists?: Maybe<EnrollmentWaitlistsMutationResponse>;
  /** update single row of the table: "enrollment_waitlists" */
  updateEnrollmentWaitlistsByPk?: Maybe<EnrollmentWaitlists>;
  /** update multiples rows of table: "enrollment_waitlists" */
  updateEnrollmentWaitlistsMany?: Maybe<Array<Maybe<EnrollmentWaitlistsMutationResponse>>>;
  /** update data of the table: "enrollments" */
  updateEnrollments?: Maybe<EnrollmentsMutationResponse>;
  /** update single row of the table: "enrollments" */
  updateEnrollmentsByPk?: Maybe<Enrollments>;
  /** update multiples rows of table: "enrollments" */
  updateEnrollmentsMany?: Maybe<Array<Maybe<EnrollmentsMutationResponse>>>;
  /** update data of the table: "fusionauth.external_identifiers" */
  updateFusionauthExternalIdentifiers?: Maybe<FusionauthExternalIdentifiersMutationResponse>;
  /** update single row of the table: "fusionauth.external_identifiers" */
  updateFusionauthExternalIdentifiersByPk?: Maybe<FusionauthExternalIdentifiers>;
  /** update multiples rows of table: "fusionauth.external_identifiers" */
  updateFusionauthExternalIdentifiersMany?: Maybe<
    Array<Maybe<FusionauthExternalIdentifiersMutationResponse>>
  >;
  /** update data of the table: "fusionauth.user_registrations" */
  updateFusionauthUserRegistrations?: Maybe<FusionauthUserRegistrationsMutationResponse>;
  /** update single row of the table: "fusionauth.user_registrations" */
  updateFusionauthUserRegistrationsByPk?: Maybe<FusionauthUserRegistrations>;
  /** update multiples rows of table: "fusionauth.user_registrations" */
  updateFusionauthUserRegistrationsMany?: Maybe<
    Array<Maybe<FusionauthUserRegistrationsMutationResponse>>
  >;
  /** update data of the table: "fusionauth.identities" */
  updateIdentities?: Maybe<IdentitiesMutationResponse>;
  /** update single row of the table: "fusionauth.identities" */
  updateIdentitiesByPk?: Maybe<Identities>;
  /** update multiples rows of table: "fusionauth.identities" */
  updateIdentitiesMany?: Maybe<Array<Maybe<IdentitiesMutationResponse>>>;
  /** update data of the table: "fusionauth.identity_provider_links" */
  updateIdentityProviderLinks?: Maybe<IdentityProviderLinksMutationResponse>;
  /** update multiples rows of table: "fusionauth.identity_provider_links" */
  updateIdentityProviderLinksMany?: Maybe<Array<Maybe<IdentityProviderLinksMutationResponse>>>;
  /** update data of the table: "fusionauth.identity_providers" */
  updateIdentityProviders?: Maybe<IdentityProvidersMutationResponse>;
  /** update single row of the table: "fusionauth.identity_providers" */
  updateIdentityProvidersByPk?: Maybe<IdentityProviders>;
  /** update multiples rows of table: "fusionauth.identity_providers" */
  updateIdentityProvidersMany?: Maybe<Array<Maybe<IdentityProvidersMutationResponse>>>;
  /** update data of the table: "impact_assessment_attempts" */
  updateImpactAssessmentAttempts?: Maybe<ImpactAssessmentAttemptsMutationResponse>;
  /** update single row of the table: "impact_assessment_attempts" */
  updateImpactAssessmentAttemptsByPk?: Maybe<ImpactAssessmentAttempts>;
  /** update multiples rows of table: "impact_assessment_attempts" */
  updateImpactAssessmentAttemptsMany?: Maybe<
    Array<Maybe<ImpactAssessmentAttemptsMutationResponse>>
  >;
  /** update data of the table: "impact_assessment_questions" */
  updateImpactAssessmentQuestions?: Maybe<ImpactAssessmentQuestionsMutationResponse>;
  /** update multiples rows of table: "impact_assessment_questions" */
  updateImpactAssessmentQuestionsMany?: Maybe<
    Array<Maybe<ImpactAssessmentQuestionsMutationResponse>>
  >;
  /** update data of the table: "impact_assessments" */
  updateImpactAssessments?: Maybe<ImpactAssessmentsMutationResponse>;
  /** update single row of the table: "impact_assessments" */
  updateImpactAssessmentsByPk?: Maybe<ImpactAssessments>;
  /** update multiples rows of table: "impact_assessments" */
  updateImpactAssessmentsMany?: Maybe<Array<Maybe<ImpactAssessmentsMutationResponse>>>;
  /** update data of the table: "journey_enrollments" */
  updateJourneyEnrollments?: Maybe<JourneyEnrollmentsMutationResponse>;
  /** update single row of the table: "journey_enrollments" */
  updateJourneyEnrollmentsByPk?: Maybe<JourneyEnrollments>;
  /** update multiples rows of table: "journey_enrollments" */
  updateJourneyEnrollmentsMany?: Maybe<Array<Maybe<JourneyEnrollmentsMutationResponse>>>;
  /** update data of the table: "journeys" */
  updateJourneys?: Maybe<JourneysMutationResponse>;
  /** update single row of the table: "journeys" */
  updateJourneysByPk?: Maybe<Journeys>;
  /** update multiples rows of table: "journeys" */
  updateJourneysMany?: Maybe<Array<Maybe<JourneysMutationResponse>>>;
  /** update data of the table: "ledger" */
  updateLedger?: Maybe<LedgerMutationResponse>;
  /** update single row of the table: "ledger" */
  updateLedgerByPk?: Maybe<Ledger>;
  /** update multiples rows of table: "ledger" */
  updateLedgerMany?: Maybe<Array<Maybe<LedgerMutationResponse>>>;
  /** update data of the table: "ledger_product_enum" */
  updateLedgerProductEnum?: Maybe<LedgerProductEnumMutationResponse>;
  /** update single row of the table: "ledger_product_enum" */
  updateLedgerProductEnumByPk?: Maybe<LedgerProductEnum>;
  /** update multiples rows of table: "ledger_product_enum" */
  updateLedgerProductEnumMany?: Maybe<Array<Maybe<LedgerProductEnumMutationResponse>>>;
  /** update data of the table: "ledger_transaction_type_enum" */
  updateLedgerTransactionTypeEnum?: Maybe<LedgerTransactionTypeEnumMutationResponse>;
  /** update single row of the table: "ledger_transaction_type_enum" */
  updateLedgerTransactionTypeEnumByPk?: Maybe<LedgerTransactionTypeEnum>;
  /** update multiples rows of table: "ledger_transaction_type_enum" */
  updateLedgerTransactionTypeEnumMany?: Maybe<
    Array<Maybe<LedgerTransactionTypeEnumMutationResponse>>
  >;
  /** update data of the table: "lightning_session" */
  updateLightningSession?: Maybe<LightningSessionMutationResponse>;
  /** update single row of the table: "lightning_session" */
  updateLightningSessionByPk?: Maybe<LightningSession>;
  /** update multiples rows of table: "lightning_session" */
  updateLightningSessionMany?: Maybe<Array<Maybe<LightningSessionMutationResponse>>>;
  /** update data of the table: "lightning_session_submissions" */
  updateLightningSessionSubmissions?: Maybe<LightningSessionSubmissionsMutationResponse>;
  /** update single row of the table: "lightning_session_submissions" */
  updateLightningSessionSubmissionsByPk?: Maybe<LightningSessionSubmissions>;
  /** update multiples rows of table: "lightning_session_submissions" */
  updateLightningSessionSubmissionsMany?: Maybe<
    Array<Maybe<LightningSessionSubmissionsMutationResponse>>
  >;
  /** update data of the table: "lightning_session_submissions_status_enum" */
  updateLightningSessionSubmissionsStatus?: Maybe<LightningSessionSubmissionsStatusMutationResponse>;
  /** update single row of the table: "lightning_session_submissions_status_enum" */
  updateLightningSessionSubmissionsStatusByPk?: Maybe<LightningSessionSubmissionsStatus>;
  /** update multiples rows of table: "lightning_session_submissions_status_enum" */
  updateLightningSessionSubmissionsStatusMany?: Maybe<
    Array<Maybe<LightningSessionSubmissionsStatusMutationResponse>>
  >;
  /** update data of the table: "lightning_session_submissions_users_rel" */
  updateLightningSessionSubmissionsUsersRel?: Maybe<LightningSessionSubmissionsUsersRelMutationResponse>;
  /** update single row of the table: "lightning_session_submissions_users_rel" */
  updateLightningSessionSubmissionsUsersRelByPk?: Maybe<LightningSessionSubmissionsUsersRel>;
  /** update multiples rows of table: "lightning_session_submissions_users_rel" */
  updateLightningSessionSubmissionsUsersRelMany?: Maybe<
    Array<Maybe<LightningSessionSubmissionsUsersRelMutationResponse>>
  >;
  /** update data of the table: "manager_session_emails" */
  updateManagerSessionEmails?: Maybe<ManagerSessionEmailsMutationResponse>;
  /** update single row of the table: "manager_session_emails" */
  updateManagerSessionEmailsByPk?: Maybe<ManagerSessionEmails>;
  /** update multiples rows of table: "manager_session_emails" */
  updateManagerSessionEmailsMany?: Maybe<Array<Maybe<ManagerSessionEmailsMutationResponse>>>;
  /** update multiples rows of table: "auxiliary.play_execution" */
  updateManyPlayExecution?: Maybe<Array<Maybe<PlayExecutionMutationResponse>>>;
  /** update data of the table: "nonce_access_token" */
  updateNonceAccessToken?: Maybe<NonceAccessTokenMutationResponse>;
  /** update single row of the table: "nonce_access_token" */
  updateNonceAccessTokenByPk?: Maybe<NonceAccessToken>;
  /** update multiples rows of table: "nonce_access_token" */
  updateNonceAccessTokenMany?: Maybe<Array<Maybe<NonceAccessTokenMutationResponse>>>;
  /** update data of the table: "fusionauth.group_members" */
  updateOrganizationUsers?: Maybe<OrganizationUsersMutationResponse>;
  /** update single row of the table: "fusionauth.group_members" */
  updateOrganizationUsersByPk?: Maybe<OrganizationUsers>;
  /** update multiples rows of table: "fusionauth.group_members" */
  updateOrganizationUsersMany?: Maybe<Array<Maybe<OrganizationUsersMutationResponse>>>;
  /** update data of the table: "fusionauth.groups" */
  updateOrganizations?: Maybe<OrganizationsMutationResponse>;
  /** update single row of the table: "fusionauth.groups" */
  updateOrganizationsByPk?: Maybe<Organizations>;
  /** update multiples rows of table: "fusionauth.groups" */
  updateOrganizationsMany?: Maybe<Array<Maybe<OrganizationsMutationResponse>>>;
  /** update data of the table: "organizations_meta" */
  updateOrganizationsMeta?: Maybe<OrganizationsMetaMutationResponse>;
  /** update single row of the table: "organizations_meta" */
  updateOrganizationsMetaByPk?: Maybe<OrganizationsMeta>;
  /** update multiples rows of table: "organizations_meta" */
  updateOrganizationsMetaMany?: Maybe<Array<Maybe<OrganizationsMetaMutationResponse>>>;
  /** update data of the table: "plan_subscriptions" */
  updatePlanSubscriptions?: Maybe<PlanSubscriptionsMutationResponse>;
  /** update single row of the table: "plan_subscriptions" */
  updatePlanSubscriptionsByPk?: Maybe<PlanSubscriptions>;
  /** update multiples rows of table: "plan_subscriptions" */
  updatePlanSubscriptionsMany?: Maybe<Array<Maybe<PlanSubscriptionsMutationResponse>>>;
  /** update data of the table: "plans" */
  updatePlans?: Maybe<PlansMutationResponse>;
  /** update single row of the table: "plans" */
  updatePlansByPk?: Maybe<Plans>;
  /** update multiples rows of table: "plans" */
  updatePlansMany?: Maybe<Array<Maybe<PlansMutationResponse>>>;
  /** update data of the table: "auxiliary.play_execution" */
  updatePlayExecution?: Maybe<PlayExecutionMutationResponse>;
  /** update single row of the table: "auxiliary.play_execution" */
  updatePlayExecutionByPk?: Maybe<PlayExecution>;
  /** update data of the table: "program_actions_taken" */
  updateProgramActionsTaken?: Maybe<ProgramActionsTakenMutationResponse>;
  /** update multiples rows of table: "program_actions_taken" */
  updateProgramActionsTakenMany?: Maybe<Array<Maybe<ProgramActionsTakenMutationResponse>>>;
  /** update data of the table: "program_courses_shown" */
  updateProgramCoursesShown?: Maybe<ProgramCoursesShownMutationResponse>;
  /** update single row of the table: "program_courses_shown" */
  updateProgramCoursesShownByPk?: Maybe<ProgramCoursesShown>;
  /** update multiples rows of table: "program_courses_shown" */
  updateProgramCoursesShownMany?: Maybe<Array<Maybe<ProgramCoursesShownMutationResponse>>>;
  /** update data of the table: "program_tags" */
  updateProgramTags?: Maybe<ProgramTagsMutationResponse>;
  /** update single row of the table: "program_tags" */
  updateProgramTagsByPk?: Maybe<ProgramTags>;
  /** update multiples rows of table: "program_tags" */
  updateProgramTagsMany?: Maybe<Array<Maybe<ProgramTagsMutationResponse>>>;
  /** update data of the table: "program_users_selected" */
  updateProgramUsersSelected?: Maybe<ProgramUsersSelectedMutationResponse>;
  /** update single row of the table: "program_users_selected" */
  updateProgramUsersSelectedByPk?: Maybe<ProgramUsersSelected>;
  /** update multiples rows of table: "program_users_selected" */
  updateProgramUsersSelectedMany?: Maybe<Array<Maybe<ProgramUsersSelectedMutationResponse>>>;
  /** update data of the table: "programs" */
  updatePrograms?: Maybe<ProgramsMutationResponse>;
  /** update single row of the table: "programs" */
  updateProgramsByPk?: Maybe<Programs>;
  /** update multiples rows of table: "programs" */
  updateProgramsMany?: Maybe<Array<Maybe<ProgramsMutationResponse>>>;
  /** update data of the table: "question_type_enum" */
  updateQuestionType?: Maybe<QuestionTypeMutationResponse>;
  /** update single row of the table: "question_type_enum" */
  updateQuestionTypeByPk?: Maybe<QuestionType>;
  /** update multiples rows of table: "question_type_enum" */
  updateQuestionTypeMany?: Maybe<Array<Maybe<QuestionTypeMutationResponse>>>;
  /** update data of the table: "recording_activity" */
  updateRecordingActivity?: Maybe<RecordingActivityMutationResponse>;
  /** update single row of the table: "recording_activity" */
  updateRecordingActivityByPk?: Maybe<RecordingActivity>;
  /** update multiples rows of table: "recording_activity" */
  updateRecordingActivityMany?: Maybe<Array<Maybe<RecordingActivityMutationResponse>>>;
  /** update data of the table: "recordings" */
  updateRecordings?: Maybe<RecordingsMutationResponse>;
  /** update single row of the table: "recordings" */
  updateRecordingsByPk?: Maybe<Recordings>;
  /** update multiples rows of table: "recordings" */
  updateRecordingsMany?: Maybe<Array<Maybe<RecordingsMutationResponse>>>;
  /** update data of the table: "related_courses_rel" */
  updateRelatedCourses?: Maybe<RelatedCoursesMutationResponse>;
  /** update single row of the table: "related_courses_rel" */
  updateRelatedCoursesByPk?: Maybe<RelatedCourses>;
  /** update multiples rows of table: "related_courses_rel" */
  updateRelatedCoursesMany?: Maybe<Array<Maybe<RelatedCoursesMutationResponse>>>;
  /** update data of the table: "report_publish_status_enum" */
  updateReportPublishStatus?: Maybe<ReportPublishStatusMutationResponse>;
  /** update single row of the table: "report_publish_status_enum" */
  updateReportPublishStatusByPk?: Maybe<ReportPublishStatus>;
  /** update multiples rows of table: "report_publish_status_enum" */
  updateReportPublishStatusMany?: Maybe<Array<Maybe<ReportPublishStatusMutationResponse>>>;
  /** update data of the table: "report_type_enum" */
  updateReportType?: Maybe<ReportTypeMutationResponse>;
  /** update single row of the table: "report_type_enum" */
  updateReportTypeByPk?: Maybe<ReportType>;
  /** update multiples rows of table: "report_type_enum" */
  updateReportTypeMany?: Maybe<Array<Maybe<ReportTypeMutationResponse>>>;
  /** update data of the table: "reports" */
  updateReports?: Maybe<ReportsMutationResponse>;
  /** update single row of the table: "reports" */
  updateReportsByPk?: Maybe<Reports>;
  /** update multiples rows of table: "reports" */
  updateReportsMany?: Maybe<Array<Maybe<ReportsMutationResponse>>>;
  /** update data of the table: "resource_type_enum" */
  updateResourceType?: Maybe<ResourceTypeMutationResponse>;
  /** update single row of the table: "resource_type_enum" */
  updateResourceTypeByPk?: Maybe<ResourceType>;
  /** update multiples rows of table: "resource_type_enum" */
  updateResourceTypeMany?: Maybe<Array<Maybe<ResourceTypeMutationResponse>>>;
  /** update data of the table: "resources" */
  updateResources?: Maybe<ResourcesMutationResponse>;
  /** update single row of the table: "resources" */
  updateResourcesByPk?: Maybe<Resources>;
  /** update multiples rows of table: "resources" */
  updateResourcesMany?: Maybe<Array<Maybe<ResourcesMutationResponse>>>;
  /** update data of the table: "schools" */
  updateSchools?: Maybe<SchoolsMutationResponse>;
  /** update single row of the table: "schools" */
  updateSchoolsByPk?: Maybe<Schools>;
  /** update multiples rows of table: "schools" */
  updateSchoolsMany?: Maybe<Array<Maybe<SchoolsMutationResponse>>>;
  /** update data of the table: "seat_type_enum" */
  updateSeatType?: Maybe<SeatTypeMutationResponse>;
  /** update single row of the table: "seat_type_enum" */
  updateSeatTypeByPk?: Maybe<SeatType>;
  /** update multiples rows of table: "seat_type_enum" */
  updateSeatTypeMany?: Maybe<Array<Maybe<SeatTypeMutationResponse>>>;
  /** update data of the table: "seats" */
  updateSeats?: Maybe<SeatsMutationResponse>;
  /** update single row of the table: "seats" */
  updateSeatsByPk?: Maybe<Seats>;
  /** update multiples rows of table: "seats" */
  updateSeatsMany?: Maybe<Array<Maybe<SeatsMutationResponse>>>;
  /** update data of the table: "stream_messages" */
  updateStreamMessages?: Maybe<StreamMessagesMutationResponse>;
  /** update single row of the table: "stream_messages" */
  updateStreamMessagesByPk?: Maybe<StreamMessages>;
  /** update multiples rows of table: "stream_messages" */
  updateStreamMessagesMany?: Maybe<Array<Maybe<StreamMessagesMutationResponse>>>;
  /** update data of the table: "tag_type_scopes_enum" */
  updateTagTypeScope?: Maybe<TagTypeScopeMutationResponse>;
  /** update single row of the table: "tag_type_scopes_enum" */
  updateTagTypeScopeByPk?: Maybe<TagTypeScope>;
  /** update multiples rows of table: "tag_type_scopes_enum" */
  updateTagTypeScopeMany?: Maybe<Array<Maybe<TagTypeScopeMutationResponse>>>;
  /** update data of the table: "tag_types" */
  updateTagTypes?: Maybe<TagTypesMutationResponse>;
  /** update single row of the table: "tag_types" */
  updateTagTypesByPk?: Maybe<TagTypes>;
  /** update multiples rows of table: "tag_types" */
  updateTagTypesMany?: Maybe<Array<Maybe<TagTypesMutationResponse>>>;
  /** update data of the table: "tags" */
  updateTags?: Maybe<TagsMutationResponse>;
  /** update single row of the table: "tags" */
  updateTagsByPk?: Maybe<Tags>;
  /** update multiples rows of table: "tags" */
  updateTagsMany?: Maybe<Array<Maybe<TagsMutationResponse>>>;
  /** update data of the table: "task_definition" */
  updateTaskDefinition?: Maybe<TaskDefinitionMutationResponse>;
  /** update single row of the table: "task_definition" */
  updateTaskDefinitionByPk?: Maybe<TaskDefinition>;
  /** update multiples rows of table: "task_definition" */
  updateTaskDefinitionMany?: Maybe<Array<Maybe<TaskDefinitionMutationResponse>>>;
  /** update data of the table: "task_definition_type_enum" */
  updateTaskDefinitionType?: Maybe<TaskDefinitionTypeMutationResponse>;
  /** update single row of the table: "task_definition_type_enum" */
  updateTaskDefinitionTypeByPk?: Maybe<TaskDefinitionType>;
  /** update multiples rows of table: "task_definition_type_enum" */
  updateTaskDefinitionTypeMany?: Maybe<Array<Maybe<TaskDefinitionTypeMutationResponse>>>;
  /** update data of the table: "tasks" */
  updateTasks?: Maybe<TasksMutationResponse>;
  /** update single row of the table: "tasks" */
  updateTasksByPk?: Maybe<Tasks>;
  /** update multiples rows of table: "tasks" */
  updateTasksMany?: Maybe<Array<Maybe<TasksMutationResponse>>>;
  /** update data of the table: "teams" */
  updateTeams?: Maybe<TeamsMutationResponse>;
  /** update single row of the table: "teams" */
  updateTeamsByPk?: Maybe<Teams>;
  /** update multiples rows of table: "teams" */
  updateTeamsMany?: Maybe<Array<Maybe<TeamsMutationResponse>>>;
  /** update data of the table: "teams_users_rel" */
  updateTeamsUsers?: Maybe<TeamsUsersMutationResponse>;
  /** update single row of the table: "teams_users_rel" */
  updateTeamsUsersByPk?: Maybe<TeamsUsers>;
  /** update multiples rows of table: "teams_users_rel" */
  updateTeamsUsersMany?: Maybe<Array<Maybe<TeamsUsersMutationResponse>>>;
  /** update data of the table: "typeform_submissions" */
  updateTypeformSubmissions?: Maybe<TypeformSubmissionsMutationResponse>;
  /** update single row of the table: "typeform_submissions" */
  updateTypeformSubmissionsByPk?: Maybe<TypeformSubmissions>;
  /** update multiples rows of table: "typeform_submissions" */
  updateTypeformSubmissionsMany?: Maybe<Array<Maybe<TypeformSubmissionsMutationResponse>>>;
  /** update data of the table: "user_session_emails" */
  updateUserSessionEmails?: Maybe<UserSessionEmailsMutationResponse>;
  /** update single row of the table: "user_session_emails" */
  updateUserSessionEmailsByPk?: Maybe<UserSessionEmails>;
  /** update multiples rows of table: "user_session_emails" */
  updateUserSessionEmailsMany?: Maybe<Array<Maybe<UserSessionEmailsMutationResponse>>>;
  /** update data of the table: "fusionauth.users" */
  updateUsers?: Maybe<UsersMutationResponse>;
  /** update single row of the table: "fusionauth.users" */
  updateUsersByPk?: Maybe<Users>;
  /** update data of the table: "users_functions_enum" */
  updateUsersFunctions?: Maybe<UsersFunctionsMutationResponse>;
  /** update single row of the table: "users_functions_enum" */
  updateUsersFunctionsByPk?: Maybe<UsersFunctions>;
  /** update multiples rows of table: "users_functions_enum" */
  updateUsersFunctionsMany?: Maybe<Array<Maybe<UsersFunctionsMutationResponse>>>;
  /** update data of the table: "users_length_in_function_enum" */
  updateUsersLengthInFunction?: Maybe<UsersLengthInFunctionMutationResponse>;
  /** update single row of the table: "users_length_in_function_enum" */
  updateUsersLengthInFunctionByPk?: Maybe<UsersLengthInFunction>;
  /** update multiples rows of table: "users_length_in_function_enum" */
  updateUsersLengthInFunctionMany?: Maybe<Array<Maybe<UsersLengthInFunctionMutationResponse>>>;
  /** update multiples rows of table: "fusionauth.users" */
  updateUsersMany?: Maybe<Array<Maybe<UsersMutationResponse>>>;
  /** update data of the table: "users_meta" */
  updateUsersMeta?: Maybe<UsersMetaMutationResponse>;
  /** update single row of the table: "users_meta" */
  updateUsersMetaByPk?: Maybe<UsersMeta>;
  /** update multiples rows of table: "users_meta" */
  updateUsersMetaMany?: Maybe<Array<Maybe<UsersMetaMutationResponse>>>;
  /** update data of the table: "zoom_oauth_tokens" */
  updateZoomOauthTokens?: Maybe<ZoomOauthTokensMutationResponse>;
  /** update single row of the table: "zoom_oauth_tokens" */
  updateZoomOauthTokensByPk?: Maybe<ZoomOauthTokens>;
  /** update multiples rows of table: "zoom_oauth_tokens" */
  updateZoomOauthTokensMany?: Maybe<Array<Maybe<ZoomOauthTokensMutationResponse>>>;
  /** execute VOLATILE function "version_impact_assessment" which returns "impact_assessments" */
  versionImpactAssessment: Array<ImpactAssessments>;
};

/** mutation root */
export type Mutation_RootAddMembersToTeamArgs = {
  teamId: Scalars['uuid'];
  userIds: Array<Scalars['uuid']>;
};

/** mutation root */
export type Mutation_RootAddUserToCourseWaitlistArgs = {
  input: CourseWaitlistInput;
};

/** mutation root */
export type Mutation_RootAddUserToOrganizationArgs = {
  organizationId: Scalars['uuid'];
  role?: InputMaybe<Scalars['String']>;
  seatType?: InputMaybe<Scalars['String']>;
  teamId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootBeginClassArgs = {
  classEventId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootBulkImportUsersArgs = {
  organizationId?: InputMaybe<Scalars['uuid']>;
  organizationName?: InputMaybe<Scalars['String']>;
  planId?: InputMaybe<Scalars['uuid']>;
  salesforceAccountId?: InputMaybe<Scalars['String']>;
  subscriptionNotes?: InputMaybe<Scalars['String']>;
  users: Array<InputMaybe<ImportUser>>;
};

/** mutation root */
export type Mutation_RootChangeTeamMemberRoleArgs = {
  role: Scalars['String'];
  teamId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootCourseWaitlistSendAvailabilityEmailArgs = {
  args: CourseWaitlistSendAvailabilityEmailInput;
};

/** mutation root */
export type Mutation_RootCreateOrganizationArgs = {
  name: Scalars['String'];
  planId?: InputMaybe<Scalars['String']>;
  salesforceAccountId: Scalars['String'];
  skipSnowflakeCreation?: InputMaybe<Scalars['Boolean']>;
  subscriptionNotes?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootCreateRecordingArgs = {
  description?: InputMaybe<Scalars['String']>;
  hash: Scalars['String'];
  lightningSubmissionId?: InputMaybe<Scalars['String']>;
  public?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

/** mutation root */
export type Mutation_RootCreateUserArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  jobFunction?: InputMaybe<Scalars['String']>;
  jobFunctionLength?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  organizationId?: InputMaybe<Scalars['String']>;
  planId?: InputMaybe<Scalars['String']>;
  salesforceAccountId?: InputMaybe<Scalars['String']>;
  seatType?: InputMaybe<Scalars['String']>;
  subscriptionNotes?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootDeactivateUserArgs = {
  userId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteTeamArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootEndClassArgs = {
  classEventId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootEnrollLearnerArgs = {
  input: EnrollInput;
};

/** mutation root */
export type Mutation_RootGetCohortEnrollmentStatusArgs = {
  cohortId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootImpersonateUserArgs = {
  userId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootLaunchClassSurveyArgs = {
  classEventId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootLightningSessionArgs = {
  action: Scalars['String'];
  classEventId: Scalars['uuid'];
  countdownMinutes?: InputMaybe<Scalars['bigint']>;
  rooms?: InputMaybe<Array<InputMaybe<RoomInput>>>;
};

/** mutation root */
export type Mutation_RootMarkRecordingWatchedArgs = {
  hash: Scalars['String'];
};

/** mutation root */
export type Mutation_RootMigrateSingleUserArgs = {
  planId: Scalars['uuid'];
  salesforceAccountId: Scalars['String'];
  subscriptionNotes: Scalars['String'];
  userId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootMoveMemberToTeamArgs = {
  fromTeamId: Scalars['uuid'];
  memberUserId: Scalars['uuid'];
  toTeamId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootPurgePiiArgs = {
  organizationId: Scalars['uuid'];
  postHogApiKey: Scalars['String'];
  postHogProjectId: Scalars['String'];
};

/** mutation root */
export type Mutation_RootReactivateUserArgs = {
  userId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootRegisterAnonArgs = {
  classEventId: Scalars['uuid'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

/** mutation root */
export type Mutation_RootRegisterLearnerArgs = {
  classEventId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootRemoveTeamMemberArgs = {
  organizationId: Scalars['String'];
  teamId: Scalars['String'];
  userId: Scalars['String'];
};

/** mutation root */
export type Mutation_RootRemoveUserFromOrganizationArgs = {
  organizationId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootResumeAssessmentArgs = {
  input: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootRunPlayArgs = {
  data?: InputMaybe<Scalars['jsonb']>;
  playName: Scalars['String'];
};

/** mutation root */
export type Mutation_RootSaveAssessmentArgs = {
  attemptId: Scalars['uuid'];
  learnerAnswers?: InputMaybe<Scalars['json']>;
  status?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootSendEventInviteArgs = {
  cohortId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootSetClassStatusArgs = {
  classEventId: Scalars['uuid'];
  context: SetClassStatusContext;
};

/** mutation root */
export type Mutation_RootSignTokenArgs = {
  classEventId: Scalars['uuid'];
  roomName: Scalars['String'];
};

/** mutation root */
export type Mutation_RootSignUploadArgs = {
  s3path: Scalars['String'];
};

/** mutation root */
export type Mutation_RootStartAssessmentArgs = {
  assessmentId: Scalars['uuid'];
  cohortSlug: Scalars['String'];
};

/** mutation root */
export type Mutation_RootStartPlaybackArgs = {
  input?: InputMaybe<StartPlaybackInput>;
};

/** mutation root */
export type Mutation_RootSubmitAndGradeAssessmentArgs = {
  input?: InputMaybe<Scalars['uuid']>;
};

/** mutation root */
export type Mutation_RootSyncZoomMeetingArgs = {
  classEventId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootUnenrollLearnerArgs = {
  input: UnenrollInput;
};

/** mutation root */
export type Mutation_RootUpdatePlaybackArgs = {
  input: UpdatePlaybackInput;
};

/** mutation root */
export type Mutation_RootUpdateUserArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  jobFunction?: InputMaybe<Scalars['String']>;
  jobFunctionLength?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  organizationId: Scalars['String'];
  teamId?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootUpsertTeamArgs = {
  id?: InputMaybe<Scalars['uuid']>;
  name: Scalars['String'];
  organizationId: Scalars['uuid'];
  parentId: Scalars['uuid'];
  slug: Scalars['String'];
  teamJobFunction: Scalars['String'];
};

/** mutation root */
export type Mutation_RootUserProfileCaptureArgs = {
  acceptedMarketingCommunication?: InputMaybe<Scalars['Boolean']>;
  acceptedTermsOfUse: Scalars['Boolean'];
  firstName: Scalars['String'];
  jobFunction: Scalars['String'];
  jobFunctionLength: Scalars['String'];
  lastName: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteActivityTypeArgs = {
  where: ActivityTypeBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteActivityTypeByPkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteAttemptStatusArgs = {
  where: AttemptStatusBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteAttemptStatusByPkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteCatalogCoursesArgs = {
  where: CatalogCoursesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCatalogCoursesByPkArgs = {
  catalogId: Scalars['uuid'];
  courseId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteCatalogsArgs = {
  where: CatalogsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCatalogsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteCatalogsJourneysRelArgs = {
  where: CatalogsJourneysRelBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCatalogsJourneysRelByPkArgs = {
  catalogId: Scalars['uuid'];
  journeyId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteClassCoachesArgs = {
  where: ClassCoachesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteClassCoachesByPkArgs = {
  classId: Scalars['uuid'];
  coachId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteClassEventActivityArgs = {
  where: ClassEventActivityBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteClassEventActivityByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteClassEventAnonRegistrationsArgs = {
  where: ClassEventAnonRegistrationsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteClassEventAnonRegistrationsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteClassEventInvitationsArgs = {
  where: ClassEventInvitationsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteClassEventInvitationsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteClassEventMeetingParticipantsArgs = {
  where: ClassEventMeetingParticipantsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteClassEventMeetingParticipantsByPkArgs = {
  participantId: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteClassEventMeetingSegmentsArgs = {
  where: ClassEventMeetingSegmentsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteClassEventMeetingSegmentsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteClassEventMeetingsArgs = {
  where: ClassEventMeetingsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteClassEventMeetingsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteClassEventRegistrationsArgs = {
  where: ClassEventRegistrationsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteClassEventRegistrationsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteClassEventsArgs = {
  where: ClassEventsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteClassEventsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteClassPlaybackActivityArgs = {
  where: ClassPlaybackActivityBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteClassPlaybackActivityByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteClassPlaybackActivityTypeArgs = {
  where: ClassPlaybackActivityTypeBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteClassPlaybackActivityTypeByPkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteClassResourcesArgs = {
  where: ClassResourcesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteClassResourcesByPkArgs = {
  classId: Scalars['uuid'];
  resourceId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteClassesArgs = {
  where: ClassesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteClassesByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteClassesTagsRelArgs = {
  where: ClassesTagsRelBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteClassesTagsRelByPkArgs = {
  classId: Scalars['uuid'];
  tagId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteCoachesArgs = {
  where: CoachesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCoachesByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteCohortCoachesArgs = {
  where: CohortCoachesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCohortCoachesByPkArgs = {
  coachId: Scalars['uuid'];
  cohortId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteCohortTypeArgs = {
  where: CohortTypeBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCohortTypeByPkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteCohortVisibilityEnumArgs = {
  where: CohortVisibilityEnumBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCohortVisibilityEnumByPkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteCohortsArgs = {
  where: CohortsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCohortsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteCohortsResourcesRelArgs = {
  where: CohortsResourcesRelBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCohortsResourcesRelByPkArgs = {
  cohortId: Scalars['uuid'];
  resourceId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteConfigurationsArgs = {
  where: ConfigurationsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteConfigurationsByPkArgs = {
  key: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteCourseCertificationsArgs = {
  where: CourseCertificationsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCourseCertificationsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteCourseCoachesArgs = {
  where: CourseCoachesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCourseCoachesByPkArgs = {
  coachId: Scalars['uuid'];
  courseId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteCourseEnrollmentStatusArgs = {
  where: CourseEnrollmentStatusBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCourseEnrollmentStatusByPkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteCoursePublishStatusArgs = {
  where: CoursePublishStatusBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCoursePublishStatusByPkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteCourseTypeArgs = {
  where: CourseTypeBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCourseTypeByPkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteCourseWaitlistArgs = {
  where: CourseWaitlistBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCourseWaitlistByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteCourseWaitlistDesiredUrgencyTypeArgs = {
  where: CourseWaitlistDesiredUrgencyTypeBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCourseWaitlistDesiredUrgencyTypeByPkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteCoursesArgs = {
  where: CoursesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCoursesByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteCoursesResourcesRelArgs = {
  where: CoursesResourcesRelBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCoursesResourcesRelByPkArgs = {
  courseId: Scalars['uuid'];
  resourceId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteCoursesTagsRelArgs = {
  where: CoursesTagsRelBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCoursesTagsRelByPkArgs = {
  courseId: Scalars['uuid'];
  tagId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteEmailQueueArgs = {
  where: EmailQueueBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteEmailQueueByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteEnrollmentWaitlistsArgs = {
  where: EnrollmentWaitlistsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteEnrollmentWaitlistsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteEnrollmentsArgs = {
  where: EnrollmentsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteEnrollmentsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteFusionauthExternalIdentifiersArgs = {
  where: FusionauthExternalIdentifiersBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteFusionauthExternalIdentifiersByPkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteFusionauthUserRegistrationsArgs = {
  where: FusionauthUserRegistrationsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteFusionauthUserRegistrationsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteIdentitiesArgs = {
  where: IdentitiesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteIdentitiesByPkArgs = {
  id: Scalars['bigint'];
};

/** mutation root */
export type Mutation_RootDeleteIdentityProviderLinksArgs = {
  where: IdentityProviderLinksBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteIdentityProvidersArgs = {
  where: IdentityProvidersBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteIdentityProvidersByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteImpactAssessmentAttemptsArgs = {
  where: ImpactAssessmentAttemptsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteImpactAssessmentAttemptsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteImpactAssessmentQuestionsArgs = {
  where: ImpactAssessmentQuestionsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteImpactAssessmentsArgs = {
  where: ImpactAssessmentsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteImpactAssessmentsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteJourneyEnrollmentsArgs = {
  where: JourneyEnrollmentsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteJourneyEnrollmentsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteJourneysArgs = {
  where: JourneysBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteJourneysByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteLedgerArgs = {
  where: LedgerBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteLedgerByPkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteLedgerProductEnumArgs = {
  where: LedgerProductEnumBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteLedgerProductEnumByPkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteLedgerTransactionTypeEnumArgs = {
  where: LedgerTransactionTypeEnumBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteLedgerTransactionTypeEnumByPkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteLightningSessionArgs = {
  where: LightningSessionBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteLightningSessionByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteLightningSessionSubmissionsArgs = {
  where: LightningSessionSubmissionsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteLightningSessionSubmissionsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteLightningSessionSubmissionsStatusArgs = {
  where: LightningSessionSubmissionsStatusBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteLightningSessionSubmissionsStatusByPkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteLightningSessionSubmissionsUsersRelArgs = {
  where: LightningSessionSubmissionsUsersRelBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteLightningSessionSubmissionsUsersRelByPkArgs = {
  lightningSessionSubmissionId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteManagerSessionEmailsArgs = {
  where: ManagerSessionEmailsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteManagerSessionEmailsByPkArgs = {
  managerCohortId: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteNonceAccessTokenArgs = {
  where: NonceAccessTokenBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteNonceAccessTokenByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteOrganizationUsersArgs = {
  where: OrganizationUsersBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteOrganizationUsersByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteOrganizationsArgs = {
  where: OrganizationsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteOrganizationsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteOrganizationsMetaArgs = {
  where: OrganizationsMetaBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteOrganizationsMetaByPkArgs = {
  organizationId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeletePlanSubscriptionsArgs = {
  where: PlanSubscriptionsBoolExp;
};

/** mutation root */
export type Mutation_RootDeletePlanSubscriptionsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeletePlansArgs = {
  where: PlansBoolExp;
};

/** mutation root */
export type Mutation_RootDeletePlansByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeletePlayExecutionArgs = {
  where: PlayExecutionBoolExp;
};

/** mutation root */
export type Mutation_RootDeletePlayExecutionByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteProgramActionsTakenArgs = {
  where: ProgramActionsTakenBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteProgramCoursesShownArgs = {
  where: ProgramCoursesShownBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteProgramCoursesShownByPkArgs = {
  courseId: Scalars['uuid'];
  programId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteProgramTagsArgs = {
  where: ProgramTagsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteProgramTagsByPkArgs = {
  programId: Scalars['uuid'];
  tagId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteProgramUsersSelectedArgs = {
  where: ProgramUsersSelectedBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteProgramUsersSelectedByPkArgs = {
  programId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteProgramsArgs = {
  where: ProgramsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteProgramsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteQuestionTypeArgs = {
  where: QuestionTypeBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteQuestionTypeByPkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteRecordingActivityArgs = {
  where: RecordingActivityBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteRecordingActivityByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteRecordingsArgs = {
  where: RecordingsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteRecordingsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteRelatedCoursesArgs = {
  where: RelatedCoursesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteRelatedCoursesByPkArgs = {
  courseId: Scalars['uuid'];
  relatedCourseSlug: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteReportPublishStatusArgs = {
  where: ReportPublishStatusBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteReportPublishStatusByPkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteReportTypeArgs = {
  where: ReportTypeBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteReportTypeByPkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteReportsArgs = {
  where: ReportsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteReportsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteResourceTypeArgs = {
  where: ResourceTypeBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteResourceTypeByPkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteResourcesArgs = {
  where: ResourcesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteResourcesByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteSchoolsArgs = {
  where: SchoolsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteSchoolsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteSeatTypeArgs = {
  where: SeatTypeBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteSeatTypeByPkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteSeatsArgs = {
  where: SeatsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteSeatsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteStreamMessagesArgs = {
  where: StreamMessagesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteStreamMessagesByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteTagTypeScopeArgs = {
  where: TagTypeScopeBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTagTypeScopeByPkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteTagTypesArgs = {
  where: TagTypesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTagTypesByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteTagsArgs = {
  where: TagsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTagsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteTaskDefinitionArgs = {
  where: TaskDefinitionBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTaskDefinitionByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteTaskDefinitionTypeArgs = {
  where: TaskDefinitionTypeBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTaskDefinitionTypeByPkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteTasksArgs = {
  where: TasksBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTasksByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteTeamsArgs = {
  where: TeamsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTeamsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteTeamsUsersArgs = {
  where: TeamsUsersBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTeamsUsersByPkArgs = {
  teamId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteTypeformSubmissionsArgs = {
  where: TypeformSubmissionsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTypeformSubmissionsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteUserSessionEmailsArgs = {
  where: UserSessionEmailsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteUserSessionEmailsByPkArgs = {
  userClassEventId: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteUsersArgs = {
  where: UsersBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteUsersByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteUsersFunctionsArgs = {
  where: UsersFunctionsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteUsersFunctionsByPkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteUsersLengthInFunctionArgs = {
  where: UsersLengthInFunctionBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteUsersLengthInFunctionByPkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteUsersMetaArgs = {
  where: UsersMetaBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteUsersMetaByPkArgs = {
  userId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteZoomOauthTokensArgs = {
  where: ZoomOauthTokensBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteZoomOauthTokensByPkArgs = {
  bearerToken: Scalars['String'];
};

/** mutation root */
export type Mutation_RootInsertActivityTypeArgs = {
  objects: Array<ActivityTypeInsertInput>;
  onConflict?: InputMaybe<ActivityTypeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertActivityTypeOneArgs = {
  object: ActivityTypeInsertInput;
  onConflict?: InputMaybe<ActivityTypeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertAttemptStatusArgs = {
  objects: Array<AttemptStatusInsertInput>;
  onConflict?: InputMaybe<AttemptStatusOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertAttemptStatusOneArgs = {
  object: AttemptStatusInsertInput;
  onConflict?: InputMaybe<AttemptStatusOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCatalogCoursesArgs = {
  objects: Array<CatalogCoursesInsertInput>;
  onConflict?: InputMaybe<CatalogCoursesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCatalogCoursesOneArgs = {
  object: CatalogCoursesInsertInput;
  onConflict?: InputMaybe<CatalogCoursesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCatalogsArgs = {
  objects: Array<CatalogsInsertInput>;
  onConflict?: InputMaybe<CatalogsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCatalogsJourneysRelArgs = {
  objects: Array<CatalogsJourneysRelInsertInput>;
  onConflict?: InputMaybe<CatalogsJourneysRelOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCatalogsJourneysRelOneArgs = {
  object: CatalogsJourneysRelInsertInput;
  onConflict?: InputMaybe<CatalogsJourneysRelOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCatalogsOneArgs = {
  object: CatalogsInsertInput;
  onConflict?: InputMaybe<CatalogsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClassCoachesArgs = {
  objects: Array<ClassCoachesInsertInput>;
  onConflict?: InputMaybe<ClassCoachesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClassCoachesOneArgs = {
  object: ClassCoachesInsertInput;
  onConflict?: InputMaybe<ClassCoachesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClassEventActivityArgs = {
  objects: Array<ClassEventActivityInsertInput>;
  onConflict?: InputMaybe<ClassEventActivityOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClassEventActivityOneArgs = {
  object: ClassEventActivityInsertInput;
  onConflict?: InputMaybe<ClassEventActivityOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClassEventAnonRegistrationsArgs = {
  objects: Array<ClassEventAnonRegistrationsInsertInput>;
  onConflict?: InputMaybe<ClassEventAnonRegistrationsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClassEventAnonRegistrationsOneArgs = {
  object: ClassEventAnonRegistrationsInsertInput;
  onConflict?: InputMaybe<ClassEventAnonRegistrationsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClassEventInvitationsArgs = {
  objects: Array<ClassEventInvitationsInsertInput>;
  onConflict?: InputMaybe<ClassEventInvitationsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClassEventInvitationsOneArgs = {
  object: ClassEventInvitationsInsertInput;
  onConflict?: InputMaybe<ClassEventInvitationsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClassEventMeetingParticipantsArgs = {
  objects: Array<ClassEventMeetingParticipantsInsertInput>;
  onConflict?: InputMaybe<ClassEventMeetingParticipantsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClassEventMeetingParticipantsOneArgs = {
  object: ClassEventMeetingParticipantsInsertInput;
  onConflict?: InputMaybe<ClassEventMeetingParticipantsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClassEventMeetingSegmentsArgs = {
  objects: Array<ClassEventMeetingSegmentsInsertInput>;
  onConflict?: InputMaybe<ClassEventMeetingSegmentsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClassEventMeetingSegmentsOneArgs = {
  object: ClassEventMeetingSegmentsInsertInput;
  onConflict?: InputMaybe<ClassEventMeetingSegmentsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClassEventMeetingsArgs = {
  objects: Array<ClassEventMeetingsInsertInput>;
  onConflict?: InputMaybe<ClassEventMeetingsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClassEventMeetingsOneArgs = {
  object: ClassEventMeetingsInsertInput;
  onConflict?: InputMaybe<ClassEventMeetingsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClassEventRegistrationsArgs = {
  objects: Array<ClassEventRegistrationsInsertInput>;
  onConflict?: InputMaybe<ClassEventRegistrationsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClassEventRegistrationsOneArgs = {
  object: ClassEventRegistrationsInsertInput;
  onConflict?: InputMaybe<ClassEventRegistrationsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClassEventsArgs = {
  objects: Array<ClassEventsInsertInput>;
  onConflict?: InputMaybe<ClassEventsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClassEventsOneArgs = {
  object: ClassEventsInsertInput;
  onConflict?: InputMaybe<ClassEventsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClassPlaybackActivityArgs = {
  objects: Array<ClassPlaybackActivityInsertInput>;
  onConflict?: InputMaybe<ClassPlaybackActivityOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClassPlaybackActivityOneArgs = {
  object: ClassPlaybackActivityInsertInput;
  onConflict?: InputMaybe<ClassPlaybackActivityOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClassPlaybackActivityTypeArgs = {
  objects: Array<ClassPlaybackActivityTypeInsertInput>;
  onConflict?: InputMaybe<ClassPlaybackActivityTypeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClassPlaybackActivityTypeOneArgs = {
  object: ClassPlaybackActivityTypeInsertInput;
  onConflict?: InputMaybe<ClassPlaybackActivityTypeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClassResourcesArgs = {
  objects: Array<ClassResourcesInsertInput>;
  onConflict?: InputMaybe<ClassResourcesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClassResourcesOneArgs = {
  object: ClassResourcesInsertInput;
  onConflict?: InputMaybe<ClassResourcesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClassesArgs = {
  objects: Array<ClassesInsertInput>;
  onConflict?: InputMaybe<ClassesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClassesOneArgs = {
  object: ClassesInsertInput;
  onConflict?: InputMaybe<ClassesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClassesTagsRelArgs = {
  objects: Array<ClassesTagsRelInsertInput>;
  onConflict?: InputMaybe<ClassesTagsRelOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertClassesTagsRelOneArgs = {
  object: ClassesTagsRelInsertInput;
  onConflict?: InputMaybe<ClassesTagsRelOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCoachesArgs = {
  objects: Array<CoachesInsertInput>;
  onConflict?: InputMaybe<CoachesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCoachesOneArgs = {
  object: CoachesInsertInput;
  onConflict?: InputMaybe<CoachesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCohortCoachesArgs = {
  objects: Array<CohortCoachesInsertInput>;
  onConflict?: InputMaybe<CohortCoachesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCohortCoachesOneArgs = {
  object: CohortCoachesInsertInput;
  onConflict?: InputMaybe<CohortCoachesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCohortTypeArgs = {
  objects: Array<CohortTypeInsertInput>;
  onConflict?: InputMaybe<CohortTypeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCohortTypeOneArgs = {
  object: CohortTypeInsertInput;
  onConflict?: InputMaybe<CohortTypeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCohortVisibilityEnumArgs = {
  objects: Array<CohortVisibilityEnumInsertInput>;
  onConflict?: InputMaybe<CohortVisibilityEnumOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCohortVisibilityEnumOneArgs = {
  object: CohortVisibilityEnumInsertInput;
  onConflict?: InputMaybe<CohortVisibilityEnumOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCohortsArgs = {
  objects: Array<CohortsInsertInput>;
  onConflict?: InputMaybe<CohortsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCohortsOneArgs = {
  object: CohortsInsertInput;
  onConflict?: InputMaybe<CohortsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCohortsResourcesRelArgs = {
  objects: Array<CohortsResourcesRelInsertInput>;
  onConflict?: InputMaybe<CohortsResourcesRelOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCohortsResourcesRelOneArgs = {
  object: CohortsResourcesRelInsertInput;
  onConflict?: InputMaybe<CohortsResourcesRelOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertConfigurationsArgs = {
  objects: Array<ConfigurationsInsertInput>;
  onConflict?: InputMaybe<ConfigurationsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertConfigurationsOneArgs = {
  object: ConfigurationsInsertInput;
  onConflict?: InputMaybe<ConfigurationsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCourseCertificationsArgs = {
  objects: Array<CourseCertificationsInsertInput>;
  onConflict?: InputMaybe<CourseCertificationsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCourseCertificationsOneArgs = {
  object: CourseCertificationsInsertInput;
  onConflict?: InputMaybe<CourseCertificationsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCourseCoachesArgs = {
  objects: Array<CourseCoachesInsertInput>;
  onConflict?: InputMaybe<CourseCoachesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCourseCoachesOneArgs = {
  object: CourseCoachesInsertInput;
  onConflict?: InputMaybe<CourseCoachesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCourseEnrollmentStatusArgs = {
  objects: Array<CourseEnrollmentStatusInsertInput>;
  onConflict?: InputMaybe<CourseEnrollmentStatusOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCourseEnrollmentStatusOneArgs = {
  object: CourseEnrollmentStatusInsertInput;
  onConflict?: InputMaybe<CourseEnrollmentStatusOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCoursePublishStatusArgs = {
  objects: Array<CoursePublishStatusInsertInput>;
  onConflict?: InputMaybe<CoursePublishStatusOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCoursePublishStatusOneArgs = {
  object: CoursePublishStatusInsertInput;
  onConflict?: InputMaybe<CoursePublishStatusOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCourseTypeArgs = {
  objects: Array<CourseTypeInsertInput>;
  onConflict?: InputMaybe<CourseTypeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCourseTypeOneArgs = {
  object: CourseTypeInsertInput;
  onConflict?: InputMaybe<CourseTypeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCourseWaitlistArgs = {
  objects: Array<CourseWaitlistInsertInput>;
  onConflict?: InputMaybe<CourseWaitlistOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCourseWaitlistDesiredUrgencyTypeArgs = {
  objects: Array<CourseWaitlistDesiredUrgencyTypeInsertInput>;
  onConflict?: InputMaybe<CourseWaitlistDesiredUrgencyTypeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCourseWaitlistDesiredUrgencyTypeOneArgs = {
  object: CourseWaitlistDesiredUrgencyTypeInsertInput;
  onConflict?: InputMaybe<CourseWaitlistDesiredUrgencyTypeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCourseWaitlistOneArgs = {
  object: CourseWaitlistInsertInput;
  onConflict?: InputMaybe<CourseWaitlistOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCoursesArgs = {
  objects: Array<CoursesInsertInput>;
  onConflict?: InputMaybe<CoursesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCoursesOneArgs = {
  object: CoursesInsertInput;
  onConflict?: InputMaybe<CoursesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCoursesResourcesRelArgs = {
  objects: Array<CoursesResourcesRelInsertInput>;
  onConflict?: InputMaybe<CoursesResourcesRelOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCoursesResourcesRelOneArgs = {
  object: CoursesResourcesRelInsertInput;
  onConflict?: InputMaybe<CoursesResourcesRelOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCoursesTagsRelArgs = {
  objects: Array<CoursesTagsRelInsertInput>;
  onConflict?: InputMaybe<CoursesTagsRelOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCoursesTagsRelOneArgs = {
  object: CoursesTagsRelInsertInput;
  onConflict?: InputMaybe<CoursesTagsRelOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertEmailQueueArgs = {
  objects: Array<EmailQueueInsertInput>;
  onConflict?: InputMaybe<EmailQueueOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertEmailQueueOneArgs = {
  object: EmailQueueInsertInput;
  onConflict?: InputMaybe<EmailQueueOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertEnrollmentWaitlistsArgs = {
  objects: Array<EnrollmentWaitlistsInsertInput>;
  onConflict?: InputMaybe<EnrollmentWaitlistsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertEnrollmentWaitlistsOneArgs = {
  object: EnrollmentWaitlistsInsertInput;
  onConflict?: InputMaybe<EnrollmentWaitlistsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertEnrollmentsArgs = {
  objects: Array<EnrollmentsInsertInput>;
  onConflict?: InputMaybe<EnrollmentsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertEnrollmentsOneArgs = {
  object: EnrollmentsInsertInput;
  onConflict?: InputMaybe<EnrollmentsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertFusionauthExternalIdentifiersArgs = {
  objects: Array<FusionauthExternalIdentifiersInsertInput>;
  onConflict?: InputMaybe<FusionauthExternalIdentifiersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertFusionauthExternalIdentifiersOneArgs = {
  object: FusionauthExternalIdentifiersInsertInput;
  onConflict?: InputMaybe<FusionauthExternalIdentifiersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertFusionauthUserRegistrationsArgs = {
  objects: Array<FusionauthUserRegistrationsInsertInput>;
  onConflict?: InputMaybe<FusionauthUserRegistrationsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertFusionauthUserRegistrationsOneArgs = {
  object: FusionauthUserRegistrationsInsertInput;
  onConflict?: InputMaybe<FusionauthUserRegistrationsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertIdentitiesArgs = {
  objects: Array<IdentitiesInsertInput>;
  onConflict?: InputMaybe<IdentitiesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertIdentitiesOneArgs = {
  object: IdentitiesInsertInput;
  onConflict?: InputMaybe<IdentitiesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertIdentityProviderLinksArgs = {
  objects: Array<IdentityProviderLinksInsertInput>;
  onConflict?: InputMaybe<IdentityProviderLinksOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertIdentityProviderLinksOneArgs = {
  object: IdentityProviderLinksInsertInput;
  onConflict?: InputMaybe<IdentityProviderLinksOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertIdentityProvidersArgs = {
  objects: Array<IdentityProvidersInsertInput>;
  onConflict?: InputMaybe<IdentityProvidersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertIdentityProvidersOneArgs = {
  object: IdentityProvidersInsertInput;
  onConflict?: InputMaybe<IdentityProvidersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertImpactAssessmentAttemptsArgs = {
  objects: Array<ImpactAssessmentAttemptsInsertInput>;
  onConflict?: InputMaybe<ImpactAssessmentAttemptsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertImpactAssessmentAttemptsOneArgs = {
  object: ImpactAssessmentAttemptsInsertInput;
  onConflict?: InputMaybe<ImpactAssessmentAttemptsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertImpactAssessmentQuestionsArgs = {
  objects: Array<ImpactAssessmentQuestionsInsertInput>;
};

/** mutation root */
export type Mutation_RootInsertImpactAssessmentQuestionsOneArgs = {
  object: ImpactAssessmentQuestionsInsertInput;
};

/** mutation root */
export type Mutation_RootInsertImpactAssessmentsArgs = {
  objects: Array<ImpactAssessmentsInsertInput>;
  onConflict?: InputMaybe<ImpactAssessmentsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertImpactAssessmentsOneArgs = {
  object: ImpactAssessmentsInsertInput;
  onConflict?: InputMaybe<ImpactAssessmentsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertJourneyEnrollmentsArgs = {
  objects: Array<JourneyEnrollmentsInsertInput>;
  onConflict?: InputMaybe<JourneyEnrollmentsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertJourneyEnrollmentsOneArgs = {
  object: JourneyEnrollmentsInsertInput;
  onConflict?: InputMaybe<JourneyEnrollmentsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertJourneysArgs = {
  objects: Array<JourneysInsertInput>;
  onConflict?: InputMaybe<JourneysOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertJourneysOneArgs = {
  object: JourneysInsertInput;
  onConflict?: InputMaybe<JourneysOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertLedgerArgs = {
  objects: Array<LedgerInsertInput>;
  onConflict?: InputMaybe<LedgerOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertLedgerOneArgs = {
  object: LedgerInsertInput;
  onConflict?: InputMaybe<LedgerOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertLedgerProductEnumArgs = {
  objects: Array<LedgerProductEnumInsertInput>;
  onConflict?: InputMaybe<LedgerProductEnumOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertLedgerProductEnumOneArgs = {
  object: LedgerProductEnumInsertInput;
  onConflict?: InputMaybe<LedgerProductEnumOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertLedgerTransactionTypeEnumArgs = {
  objects: Array<LedgerTransactionTypeEnumInsertInput>;
  onConflict?: InputMaybe<LedgerTransactionTypeEnumOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertLedgerTransactionTypeEnumOneArgs = {
  object: LedgerTransactionTypeEnumInsertInput;
  onConflict?: InputMaybe<LedgerTransactionTypeEnumOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertLightningSessionArgs = {
  objects: Array<LightningSessionInsertInput>;
  onConflict?: InputMaybe<LightningSessionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertLightningSessionOneArgs = {
  object: LightningSessionInsertInput;
  onConflict?: InputMaybe<LightningSessionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertLightningSessionSubmissionsArgs = {
  objects: Array<LightningSessionSubmissionsInsertInput>;
  onConflict?: InputMaybe<LightningSessionSubmissionsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertLightningSessionSubmissionsOneArgs = {
  object: LightningSessionSubmissionsInsertInput;
  onConflict?: InputMaybe<LightningSessionSubmissionsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertLightningSessionSubmissionsStatusArgs = {
  objects: Array<LightningSessionSubmissionsStatusInsertInput>;
  onConflict?: InputMaybe<LightningSessionSubmissionsStatusOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertLightningSessionSubmissionsStatusOneArgs = {
  object: LightningSessionSubmissionsStatusInsertInput;
  onConflict?: InputMaybe<LightningSessionSubmissionsStatusOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertLightningSessionSubmissionsUsersRelArgs = {
  objects: Array<LightningSessionSubmissionsUsersRelInsertInput>;
  onConflict?: InputMaybe<LightningSessionSubmissionsUsersRelOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertLightningSessionSubmissionsUsersRelOneArgs = {
  object: LightningSessionSubmissionsUsersRelInsertInput;
  onConflict?: InputMaybe<LightningSessionSubmissionsUsersRelOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertManagerSessionEmailsArgs = {
  objects: Array<ManagerSessionEmailsInsertInput>;
  onConflict?: InputMaybe<ManagerSessionEmailsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertManagerSessionEmailsOneArgs = {
  object: ManagerSessionEmailsInsertInput;
  onConflict?: InputMaybe<ManagerSessionEmailsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertNonceAccessTokenArgs = {
  objects: Array<NonceAccessTokenInsertInput>;
  onConflict?: InputMaybe<NonceAccessTokenOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertNonceAccessTokenOneArgs = {
  object: NonceAccessTokenInsertInput;
  onConflict?: InputMaybe<NonceAccessTokenOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertOrganizationUsersArgs = {
  objects: Array<OrganizationUsersInsertInput>;
  onConflict?: InputMaybe<OrganizationUsersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertOrganizationUsersOneArgs = {
  object: OrganizationUsersInsertInput;
  onConflict?: InputMaybe<OrganizationUsersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertOrganizationsArgs = {
  objects: Array<OrganizationsInsertInput>;
  onConflict?: InputMaybe<OrganizationsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertOrganizationsMetaArgs = {
  objects: Array<OrganizationsMetaInsertInput>;
  onConflict?: InputMaybe<OrganizationsMetaOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertOrganizationsMetaOneArgs = {
  object: OrganizationsMetaInsertInput;
  onConflict?: InputMaybe<OrganizationsMetaOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertOrganizationsOneArgs = {
  object: OrganizationsInsertInput;
  onConflict?: InputMaybe<OrganizationsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPlanSubscriptionsArgs = {
  objects: Array<PlanSubscriptionsInsertInput>;
  onConflict?: InputMaybe<PlanSubscriptionsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPlanSubscriptionsOneArgs = {
  object: PlanSubscriptionsInsertInput;
  onConflict?: InputMaybe<PlanSubscriptionsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPlansArgs = {
  objects: Array<PlansInsertInput>;
  onConflict?: InputMaybe<PlansOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPlansOneArgs = {
  object: PlansInsertInput;
  onConflict?: InputMaybe<PlansOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPlayExecutionArgs = {
  objects: Array<PlayExecutionInsertInput>;
  onConflict?: InputMaybe<PlayExecutionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPlayExecutionOneArgs = {
  object: PlayExecutionInsertInput;
  onConflict?: InputMaybe<PlayExecutionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertProgramActionsTakenArgs = {
  objects: Array<ProgramActionsTakenInsertInput>;
};

/** mutation root */
export type Mutation_RootInsertProgramActionsTakenOneArgs = {
  object: ProgramActionsTakenInsertInput;
};

/** mutation root */
export type Mutation_RootInsertProgramCoursesShownArgs = {
  objects: Array<ProgramCoursesShownInsertInput>;
  onConflict?: InputMaybe<ProgramCoursesShownOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertProgramCoursesShownOneArgs = {
  object: ProgramCoursesShownInsertInput;
  onConflict?: InputMaybe<ProgramCoursesShownOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertProgramTagsArgs = {
  objects: Array<ProgramTagsInsertInput>;
  onConflict?: InputMaybe<ProgramTagsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertProgramTagsOneArgs = {
  object: ProgramTagsInsertInput;
  onConflict?: InputMaybe<ProgramTagsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertProgramUsersSelectedArgs = {
  objects: Array<ProgramUsersSelectedInsertInput>;
  onConflict?: InputMaybe<ProgramUsersSelectedOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertProgramUsersSelectedOneArgs = {
  object: ProgramUsersSelectedInsertInput;
  onConflict?: InputMaybe<ProgramUsersSelectedOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertProgramsArgs = {
  objects: Array<ProgramsInsertInput>;
  onConflict?: InputMaybe<ProgramsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertProgramsOneArgs = {
  object: ProgramsInsertInput;
  onConflict?: InputMaybe<ProgramsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertQuestionTypeArgs = {
  objects: Array<QuestionTypeInsertInput>;
  onConflict?: InputMaybe<QuestionTypeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertQuestionTypeOneArgs = {
  object: QuestionTypeInsertInput;
  onConflict?: InputMaybe<QuestionTypeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertRecordingActivityArgs = {
  objects: Array<RecordingActivityInsertInput>;
  onConflict?: InputMaybe<RecordingActivityOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertRecordingActivityOneArgs = {
  object: RecordingActivityInsertInput;
  onConflict?: InputMaybe<RecordingActivityOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertRecordingsArgs = {
  objects: Array<RecordingsInsertInput>;
  onConflict?: InputMaybe<RecordingsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertRecordingsOneArgs = {
  object: RecordingsInsertInput;
  onConflict?: InputMaybe<RecordingsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertRelatedCoursesArgs = {
  objects: Array<RelatedCoursesInsertInput>;
  onConflict?: InputMaybe<RelatedCoursesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertRelatedCoursesOneArgs = {
  object: RelatedCoursesInsertInput;
  onConflict?: InputMaybe<RelatedCoursesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertReportPublishStatusArgs = {
  objects: Array<ReportPublishStatusInsertInput>;
  onConflict?: InputMaybe<ReportPublishStatusOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertReportPublishStatusOneArgs = {
  object: ReportPublishStatusInsertInput;
  onConflict?: InputMaybe<ReportPublishStatusOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertReportTypeArgs = {
  objects: Array<ReportTypeInsertInput>;
  onConflict?: InputMaybe<ReportTypeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertReportTypeOneArgs = {
  object: ReportTypeInsertInput;
  onConflict?: InputMaybe<ReportTypeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertReportsArgs = {
  objects: Array<ReportsInsertInput>;
  onConflict?: InputMaybe<ReportsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertReportsOneArgs = {
  object: ReportsInsertInput;
  onConflict?: InputMaybe<ReportsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertResourceTypeArgs = {
  objects: Array<ResourceTypeInsertInput>;
  onConflict?: InputMaybe<ResourceTypeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertResourceTypeOneArgs = {
  object: ResourceTypeInsertInput;
  onConflict?: InputMaybe<ResourceTypeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertResourcesArgs = {
  objects: Array<ResourcesInsertInput>;
  onConflict?: InputMaybe<ResourcesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertResourcesOneArgs = {
  object: ResourcesInsertInput;
  onConflict?: InputMaybe<ResourcesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSchoolsArgs = {
  objects: Array<SchoolsInsertInput>;
  onConflict?: InputMaybe<SchoolsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSchoolsOneArgs = {
  object: SchoolsInsertInput;
  onConflict?: InputMaybe<SchoolsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSeatTypeArgs = {
  objects: Array<SeatTypeInsertInput>;
  onConflict?: InputMaybe<SeatTypeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSeatTypeOneArgs = {
  object: SeatTypeInsertInput;
  onConflict?: InputMaybe<SeatTypeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSeatsArgs = {
  objects: Array<SeatsInsertInput>;
  onConflict?: InputMaybe<SeatsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSeatsOneArgs = {
  object: SeatsInsertInput;
  onConflict?: InputMaybe<SeatsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertStreamMessagesArgs = {
  objects: Array<StreamMessagesInsertInput>;
  onConflict?: InputMaybe<StreamMessagesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertStreamMessagesOneArgs = {
  object: StreamMessagesInsertInput;
  onConflict?: InputMaybe<StreamMessagesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTagTypeScopeArgs = {
  objects: Array<TagTypeScopeInsertInput>;
  onConflict?: InputMaybe<TagTypeScopeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTagTypeScopeOneArgs = {
  object: TagTypeScopeInsertInput;
  onConflict?: InputMaybe<TagTypeScopeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTagTypesArgs = {
  objects: Array<TagTypesInsertInput>;
  onConflict?: InputMaybe<TagTypesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTagTypesOneArgs = {
  object: TagTypesInsertInput;
  onConflict?: InputMaybe<TagTypesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTagsArgs = {
  objects: Array<TagsInsertInput>;
  onConflict?: InputMaybe<TagsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTagsOneArgs = {
  object: TagsInsertInput;
  onConflict?: InputMaybe<TagsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTaskDefinitionArgs = {
  objects: Array<TaskDefinitionInsertInput>;
  onConflict?: InputMaybe<TaskDefinitionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTaskDefinitionOneArgs = {
  object: TaskDefinitionInsertInput;
  onConflict?: InputMaybe<TaskDefinitionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTaskDefinitionTypeArgs = {
  objects: Array<TaskDefinitionTypeInsertInput>;
  onConflict?: InputMaybe<TaskDefinitionTypeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTaskDefinitionTypeOneArgs = {
  object: TaskDefinitionTypeInsertInput;
  onConflict?: InputMaybe<TaskDefinitionTypeOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTasksArgs = {
  objects: Array<TasksInsertInput>;
  onConflict?: InputMaybe<TasksOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTasksOneArgs = {
  object: TasksInsertInput;
  onConflict?: InputMaybe<TasksOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTeamsArgs = {
  objects: Array<TeamsInsertInput>;
  onConflict?: InputMaybe<TeamsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTeamsOneArgs = {
  object: TeamsInsertInput;
  onConflict?: InputMaybe<TeamsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTeamsUsersArgs = {
  objects: Array<TeamsUsersInsertInput>;
  onConflict?: InputMaybe<TeamsUsersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTeamsUsersOneArgs = {
  object: TeamsUsersInsertInput;
  onConflict?: InputMaybe<TeamsUsersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTypeformSubmissionsArgs = {
  objects: Array<TypeformSubmissionsInsertInput>;
  onConflict?: InputMaybe<TypeformSubmissionsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTypeformSubmissionsOneArgs = {
  object: TypeformSubmissionsInsertInput;
  onConflict?: InputMaybe<TypeformSubmissionsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertUserSessionEmailsArgs = {
  objects: Array<UserSessionEmailsInsertInput>;
  onConflict?: InputMaybe<UserSessionEmailsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertUserSessionEmailsOneArgs = {
  object: UserSessionEmailsInsertInput;
  onConflict?: InputMaybe<UserSessionEmailsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertUsersArgs = {
  objects: Array<UsersInsertInput>;
  onConflict?: InputMaybe<UsersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertUsersFunctionsArgs = {
  objects: Array<UsersFunctionsInsertInput>;
  onConflict?: InputMaybe<UsersFunctionsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertUsersFunctionsOneArgs = {
  object: UsersFunctionsInsertInput;
  onConflict?: InputMaybe<UsersFunctionsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertUsersLengthInFunctionArgs = {
  objects: Array<UsersLengthInFunctionInsertInput>;
  onConflict?: InputMaybe<UsersLengthInFunctionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertUsersLengthInFunctionOneArgs = {
  object: UsersLengthInFunctionInsertInput;
  onConflict?: InputMaybe<UsersLengthInFunctionOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertUsersMetaArgs = {
  objects: Array<UsersMetaInsertInput>;
  onConflict?: InputMaybe<UsersMetaOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertUsersMetaOneArgs = {
  object: UsersMetaInsertInput;
  onConflict?: InputMaybe<UsersMetaOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertUsersOneArgs = {
  object: UsersInsertInput;
  onConflict?: InputMaybe<UsersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertZoomOauthTokensArgs = {
  objects: Array<ZoomOauthTokensInsertInput>;
  onConflict?: InputMaybe<ZoomOauthTokensOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertZoomOauthTokensOneArgs = {
  object: ZoomOauthTokensInsertInput;
  onConflict?: InputMaybe<ZoomOauthTokensOnConflict>;
};

/** mutation root */
export type Mutation_RootPublishCourseArgs = {
  args: PublishCourseArgs;
  distinctOn?: InputMaybe<Array<CoursesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesOrderBy>>;
  where?: InputMaybe<CoursesBoolExp>;
};

/** mutation root */
export type Mutation_RootUpdateActivityTypeArgs = {
  _set?: InputMaybe<ActivityTypeSetInput>;
  where: ActivityTypeBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateActivityTypeByPkArgs = {
  _set?: InputMaybe<ActivityTypeSetInput>;
  pkColumns: ActivityTypePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateActivityTypeManyArgs = {
  updates: Array<ActivityTypeUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateAttemptStatusArgs = {
  _set?: InputMaybe<AttemptStatusSetInput>;
  where: AttemptStatusBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateAttemptStatusByPkArgs = {
  _set?: InputMaybe<AttemptStatusSetInput>;
  pkColumns: AttemptStatusPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateAttemptStatusManyArgs = {
  updates: Array<AttemptStatusUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCatalogCoursesArgs = {
  _set?: InputMaybe<CatalogCoursesSetInput>;
  where: CatalogCoursesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCatalogCoursesByPkArgs = {
  _set?: InputMaybe<CatalogCoursesSetInput>;
  pkColumns: CatalogCoursesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCatalogCoursesManyArgs = {
  updates: Array<CatalogCoursesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCatalogsArgs = {
  _set?: InputMaybe<CatalogsSetInput>;
  where: CatalogsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCatalogsByPkArgs = {
  _set?: InputMaybe<CatalogsSetInput>;
  pkColumns: CatalogsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCatalogsJourneysRelArgs = {
  _set?: InputMaybe<CatalogsJourneysRelSetInput>;
  where: CatalogsJourneysRelBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCatalogsJourneysRelByPkArgs = {
  _set?: InputMaybe<CatalogsJourneysRelSetInput>;
  pkColumns: CatalogsJourneysRelPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCatalogsJourneysRelManyArgs = {
  updates: Array<CatalogsJourneysRelUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCatalogsManyArgs = {
  updates: Array<CatalogsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateClassCoachesArgs = {
  _set?: InputMaybe<ClassCoachesSetInput>;
  where: ClassCoachesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateClassCoachesByPkArgs = {
  _set?: InputMaybe<ClassCoachesSetInput>;
  pkColumns: ClassCoachesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateClassCoachesManyArgs = {
  updates: Array<ClassCoachesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateClassEventActivityArgs = {
  _append?: InputMaybe<ClassEventActivityAppendInput>;
  _deleteAtPath?: InputMaybe<ClassEventActivityDeleteAtPathInput>;
  _deleteElem?: InputMaybe<ClassEventActivityDeleteElemInput>;
  _deleteKey?: InputMaybe<ClassEventActivityDeleteKeyInput>;
  _prepend?: InputMaybe<ClassEventActivityPrependInput>;
  _set?: InputMaybe<ClassEventActivitySetInput>;
  where: ClassEventActivityBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateClassEventActivityByPkArgs = {
  _append?: InputMaybe<ClassEventActivityAppendInput>;
  _deleteAtPath?: InputMaybe<ClassEventActivityDeleteAtPathInput>;
  _deleteElem?: InputMaybe<ClassEventActivityDeleteElemInput>;
  _deleteKey?: InputMaybe<ClassEventActivityDeleteKeyInput>;
  _prepend?: InputMaybe<ClassEventActivityPrependInput>;
  _set?: InputMaybe<ClassEventActivitySetInput>;
  pkColumns: ClassEventActivityPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateClassEventActivityManyArgs = {
  updates: Array<ClassEventActivityUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateClassEventAnonRegistrationsArgs = {
  _set?: InputMaybe<ClassEventAnonRegistrationsSetInput>;
  where: ClassEventAnonRegistrationsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateClassEventAnonRegistrationsByPkArgs = {
  _set?: InputMaybe<ClassEventAnonRegistrationsSetInput>;
  pkColumns: ClassEventAnonRegistrationsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateClassEventAnonRegistrationsManyArgs = {
  updates: Array<ClassEventAnonRegistrationsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateClassEventInvitationsArgs = {
  _inc?: InputMaybe<ClassEventInvitationsIncInput>;
  _set?: InputMaybe<ClassEventInvitationsSetInput>;
  where: ClassEventInvitationsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateClassEventInvitationsByPkArgs = {
  _inc?: InputMaybe<ClassEventInvitationsIncInput>;
  _set?: InputMaybe<ClassEventInvitationsSetInput>;
  pkColumns: ClassEventInvitationsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateClassEventInvitationsManyArgs = {
  updates: Array<ClassEventInvitationsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateClassEventMeetingParticipantsArgs = {
  _inc?: InputMaybe<ClassEventMeetingParticipantsIncInput>;
  _set?: InputMaybe<ClassEventMeetingParticipantsSetInput>;
  where: ClassEventMeetingParticipantsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateClassEventMeetingParticipantsByPkArgs = {
  _inc?: InputMaybe<ClassEventMeetingParticipantsIncInput>;
  _set?: InputMaybe<ClassEventMeetingParticipantsSetInput>;
  pkColumns: ClassEventMeetingParticipantsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateClassEventMeetingParticipantsManyArgs = {
  updates: Array<ClassEventMeetingParticipantsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateClassEventMeetingSegmentsArgs = {
  _append?: InputMaybe<ClassEventMeetingSegmentsAppendInput>;
  _deleteAtPath?: InputMaybe<ClassEventMeetingSegmentsDeleteAtPathInput>;
  _deleteElem?: InputMaybe<ClassEventMeetingSegmentsDeleteElemInput>;
  _deleteKey?: InputMaybe<ClassEventMeetingSegmentsDeleteKeyInput>;
  _inc?: InputMaybe<ClassEventMeetingSegmentsIncInput>;
  _prepend?: InputMaybe<ClassEventMeetingSegmentsPrependInput>;
  _set?: InputMaybe<ClassEventMeetingSegmentsSetInput>;
  where: ClassEventMeetingSegmentsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateClassEventMeetingSegmentsByPkArgs = {
  _append?: InputMaybe<ClassEventMeetingSegmentsAppendInput>;
  _deleteAtPath?: InputMaybe<ClassEventMeetingSegmentsDeleteAtPathInput>;
  _deleteElem?: InputMaybe<ClassEventMeetingSegmentsDeleteElemInput>;
  _deleteKey?: InputMaybe<ClassEventMeetingSegmentsDeleteKeyInput>;
  _inc?: InputMaybe<ClassEventMeetingSegmentsIncInput>;
  _prepend?: InputMaybe<ClassEventMeetingSegmentsPrependInput>;
  _set?: InputMaybe<ClassEventMeetingSegmentsSetInput>;
  pkColumns: ClassEventMeetingSegmentsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateClassEventMeetingSegmentsManyArgs = {
  updates: Array<ClassEventMeetingSegmentsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateClassEventMeetingsArgs = {
  _inc?: InputMaybe<ClassEventMeetingsIncInput>;
  _set?: InputMaybe<ClassEventMeetingsSetInput>;
  where: ClassEventMeetingsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateClassEventMeetingsByPkArgs = {
  _inc?: InputMaybe<ClassEventMeetingsIncInput>;
  _set?: InputMaybe<ClassEventMeetingsSetInput>;
  pkColumns: ClassEventMeetingsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateClassEventMeetingsManyArgs = {
  updates: Array<ClassEventMeetingsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateClassEventRegistrationsArgs = {
  _set?: InputMaybe<ClassEventRegistrationsSetInput>;
  where: ClassEventRegistrationsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateClassEventRegistrationsByPkArgs = {
  _set?: InputMaybe<ClassEventRegistrationsSetInput>;
  pkColumns: ClassEventRegistrationsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateClassEventRegistrationsManyArgs = {
  updates: Array<ClassEventRegistrationsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateClassEventsArgs = {
  _inc?: InputMaybe<ClassEventsIncInput>;
  _set?: InputMaybe<ClassEventsSetInput>;
  where: ClassEventsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateClassEventsByPkArgs = {
  _inc?: InputMaybe<ClassEventsIncInput>;
  _set?: InputMaybe<ClassEventsSetInput>;
  pkColumns: ClassEventsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateClassEventsManyArgs = {
  updates: Array<ClassEventsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateClassPlaybackActivityArgs = {
  _append?: InputMaybe<ClassPlaybackActivityAppendInput>;
  _deleteAtPath?: InputMaybe<ClassPlaybackActivityDeleteAtPathInput>;
  _deleteElem?: InputMaybe<ClassPlaybackActivityDeleteElemInput>;
  _deleteKey?: InputMaybe<ClassPlaybackActivityDeleteKeyInput>;
  _prepend?: InputMaybe<ClassPlaybackActivityPrependInput>;
  _set?: InputMaybe<ClassPlaybackActivitySetInput>;
  where: ClassPlaybackActivityBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateClassPlaybackActivityByPkArgs = {
  _append?: InputMaybe<ClassPlaybackActivityAppendInput>;
  _deleteAtPath?: InputMaybe<ClassPlaybackActivityDeleteAtPathInput>;
  _deleteElem?: InputMaybe<ClassPlaybackActivityDeleteElemInput>;
  _deleteKey?: InputMaybe<ClassPlaybackActivityDeleteKeyInput>;
  _prepend?: InputMaybe<ClassPlaybackActivityPrependInput>;
  _set?: InputMaybe<ClassPlaybackActivitySetInput>;
  pkColumns: ClassPlaybackActivityPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateClassPlaybackActivityManyArgs = {
  updates: Array<ClassPlaybackActivityUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateClassPlaybackActivityTypeArgs = {
  _set?: InputMaybe<ClassPlaybackActivityTypeSetInput>;
  where: ClassPlaybackActivityTypeBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateClassPlaybackActivityTypeByPkArgs = {
  _set?: InputMaybe<ClassPlaybackActivityTypeSetInput>;
  pkColumns: ClassPlaybackActivityTypePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateClassPlaybackActivityTypeManyArgs = {
  updates: Array<ClassPlaybackActivityTypeUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateClassResourcesArgs = {
  _set?: InputMaybe<ClassResourcesSetInput>;
  where: ClassResourcesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateClassResourcesByPkArgs = {
  _set?: InputMaybe<ClassResourcesSetInput>;
  pkColumns: ClassResourcesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateClassResourcesManyArgs = {
  updates: Array<ClassResourcesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateClassesArgs = {
  _inc?: InputMaybe<ClassesIncInput>;
  _set?: InputMaybe<ClassesSetInput>;
  where: ClassesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateClassesByPkArgs = {
  _inc?: InputMaybe<ClassesIncInput>;
  _set?: InputMaybe<ClassesSetInput>;
  pkColumns: ClassesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateClassesManyArgs = {
  updates: Array<ClassesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateClassesTagsRelArgs = {
  _set?: InputMaybe<ClassesTagsRelSetInput>;
  where: ClassesTagsRelBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateClassesTagsRelByPkArgs = {
  _set?: InputMaybe<ClassesTagsRelSetInput>;
  pkColumns: ClassesTagsRelPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateClassesTagsRelManyArgs = {
  updates: Array<ClassesTagsRelUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCoachesArgs = {
  _set?: InputMaybe<CoachesSetInput>;
  where: CoachesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCoachesByPkArgs = {
  _set?: InputMaybe<CoachesSetInput>;
  pkColumns: CoachesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCoachesManyArgs = {
  updates: Array<CoachesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCohortCoachesArgs = {
  _set?: InputMaybe<CohortCoachesSetInput>;
  where: CohortCoachesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCohortCoachesByPkArgs = {
  _set?: InputMaybe<CohortCoachesSetInput>;
  pkColumns: CohortCoachesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCohortCoachesManyArgs = {
  updates: Array<CohortCoachesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCohortTypeArgs = {
  _set?: InputMaybe<CohortTypeSetInput>;
  where: CohortTypeBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCohortTypeByPkArgs = {
  _set?: InputMaybe<CohortTypeSetInput>;
  pkColumns: CohortTypePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCohortTypeManyArgs = {
  updates: Array<CohortTypeUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCohortVisibilityEnumArgs = {
  _set?: InputMaybe<CohortVisibilityEnumSetInput>;
  where: CohortVisibilityEnumBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCohortVisibilityEnumByPkArgs = {
  _set?: InputMaybe<CohortVisibilityEnumSetInput>;
  pkColumns: CohortVisibilityEnumPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCohortVisibilityEnumManyArgs = {
  updates: Array<CohortVisibilityEnumUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCohortsArgs = {
  _inc?: InputMaybe<CohortsIncInput>;
  _set?: InputMaybe<CohortsSetInput>;
  where: CohortsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCohortsByPkArgs = {
  _inc?: InputMaybe<CohortsIncInput>;
  _set?: InputMaybe<CohortsSetInput>;
  pkColumns: CohortsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCohortsManyArgs = {
  updates: Array<CohortsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCohortsResourcesRelArgs = {
  _set?: InputMaybe<CohortsResourcesRelSetInput>;
  where: CohortsResourcesRelBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCohortsResourcesRelByPkArgs = {
  _set?: InputMaybe<CohortsResourcesRelSetInput>;
  pkColumns: CohortsResourcesRelPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCohortsResourcesRelManyArgs = {
  updates: Array<CohortsResourcesRelUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateConfigurationsArgs = {
  _append?: InputMaybe<ConfigurationsAppendInput>;
  _deleteAtPath?: InputMaybe<ConfigurationsDeleteAtPathInput>;
  _deleteElem?: InputMaybe<ConfigurationsDeleteElemInput>;
  _deleteKey?: InputMaybe<ConfigurationsDeleteKeyInput>;
  _prepend?: InputMaybe<ConfigurationsPrependInput>;
  _set?: InputMaybe<ConfigurationsSetInput>;
  where: ConfigurationsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateConfigurationsByPkArgs = {
  _append?: InputMaybe<ConfigurationsAppendInput>;
  _deleteAtPath?: InputMaybe<ConfigurationsDeleteAtPathInput>;
  _deleteElem?: InputMaybe<ConfigurationsDeleteElemInput>;
  _deleteKey?: InputMaybe<ConfigurationsDeleteKeyInput>;
  _prepend?: InputMaybe<ConfigurationsPrependInput>;
  _set?: InputMaybe<ConfigurationsSetInput>;
  pkColumns: ConfigurationsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateConfigurationsManyArgs = {
  updates: Array<ConfigurationsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCourseCertificationsArgs = {
  _set?: InputMaybe<CourseCertificationsSetInput>;
  where: CourseCertificationsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCourseCertificationsByPkArgs = {
  _set?: InputMaybe<CourseCertificationsSetInput>;
  pkColumns: CourseCertificationsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCourseCertificationsManyArgs = {
  updates: Array<CourseCertificationsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCourseCoachesArgs = {
  _set?: InputMaybe<CourseCoachesSetInput>;
  where: CourseCoachesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCourseCoachesByPkArgs = {
  _set?: InputMaybe<CourseCoachesSetInput>;
  pkColumns: CourseCoachesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCourseCoachesManyArgs = {
  updates: Array<CourseCoachesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCourseEnrollmentStatusArgs = {
  _set?: InputMaybe<CourseEnrollmentStatusSetInput>;
  where: CourseEnrollmentStatusBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCourseEnrollmentStatusByPkArgs = {
  _set?: InputMaybe<CourseEnrollmentStatusSetInput>;
  pkColumns: CourseEnrollmentStatusPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCourseEnrollmentStatusManyArgs = {
  updates: Array<CourseEnrollmentStatusUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCoursePublishStatusArgs = {
  _set?: InputMaybe<CoursePublishStatusSetInput>;
  where: CoursePublishStatusBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCoursePublishStatusByPkArgs = {
  _set?: InputMaybe<CoursePublishStatusSetInput>;
  pkColumns: CoursePublishStatusPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCoursePublishStatusManyArgs = {
  updates: Array<CoursePublishStatusUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCourseTypeArgs = {
  _set?: InputMaybe<CourseTypeSetInput>;
  where: CourseTypeBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCourseTypeByPkArgs = {
  _set?: InputMaybe<CourseTypeSetInput>;
  pkColumns: CourseTypePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCourseTypeManyArgs = {
  updates: Array<CourseTypeUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCourseWaitlistArgs = {
  _set?: InputMaybe<CourseWaitlistSetInput>;
  where: CourseWaitlistBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCourseWaitlistByPkArgs = {
  _set?: InputMaybe<CourseWaitlistSetInput>;
  pkColumns: CourseWaitlistPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCourseWaitlistDesiredUrgencyTypeArgs = {
  _set?: InputMaybe<CourseWaitlistDesiredUrgencyTypeSetInput>;
  where: CourseWaitlistDesiredUrgencyTypeBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCourseWaitlistDesiredUrgencyTypeByPkArgs = {
  _set?: InputMaybe<CourseWaitlistDesiredUrgencyTypeSetInput>;
  pkColumns: CourseWaitlistDesiredUrgencyTypePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCourseWaitlistDesiredUrgencyTypeManyArgs = {
  updates: Array<CourseWaitlistDesiredUrgencyTypeUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCourseWaitlistManyArgs = {
  updates: Array<CourseWaitlistUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCoursesArgs = {
  _inc?: InputMaybe<CoursesIncInput>;
  _set?: InputMaybe<CoursesSetInput>;
  where: CoursesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCoursesByPkArgs = {
  _inc?: InputMaybe<CoursesIncInput>;
  _set?: InputMaybe<CoursesSetInput>;
  pkColumns: CoursesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCoursesManyArgs = {
  updates: Array<CoursesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCoursesResourcesRelArgs = {
  _set?: InputMaybe<CoursesResourcesRelSetInput>;
  where: CoursesResourcesRelBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCoursesResourcesRelByPkArgs = {
  _set?: InputMaybe<CoursesResourcesRelSetInput>;
  pkColumns: CoursesResourcesRelPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCoursesResourcesRelManyArgs = {
  updates: Array<CoursesResourcesRelUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCoursesTagsRelArgs = {
  _set?: InputMaybe<CoursesTagsRelSetInput>;
  where: CoursesTagsRelBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCoursesTagsRelByPkArgs = {
  _set?: InputMaybe<CoursesTagsRelSetInput>;
  pkColumns: CoursesTagsRelPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCoursesTagsRelManyArgs = {
  updates: Array<CoursesTagsRelUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateEmailQueueArgs = {
  _append?: InputMaybe<EmailQueueAppendInput>;
  _deleteAtPath?: InputMaybe<EmailQueueDeleteAtPathInput>;
  _deleteElem?: InputMaybe<EmailQueueDeleteElemInput>;
  _deleteKey?: InputMaybe<EmailQueueDeleteKeyInput>;
  _prepend?: InputMaybe<EmailQueuePrependInput>;
  _set?: InputMaybe<EmailQueueSetInput>;
  where: EmailQueueBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateEmailQueueByPkArgs = {
  _append?: InputMaybe<EmailQueueAppendInput>;
  _deleteAtPath?: InputMaybe<EmailQueueDeleteAtPathInput>;
  _deleteElem?: InputMaybe<EmailQueueDeleteElemInput>;
  _deleteKey?: InputMaybe<EmailQueueDeleteKeyInput>;
  _prepend?: InputMaybe<EmailQueuePrependInput>;
  _set?: InputMaybe<EmailQueueSetInput>;
  pkColumns: EmailQueuePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateEmailQueueManyArgs = {
  updates: Array<EmailQueueUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateEnrollmentWaitlistsArgs = {
  _set?: InputMaybe<EnrollmentWaitlistsSetInput>;
  where: EnrollmentWaitlistsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateEnrollmentWaitlistsByPkArgs = {
  _set?: InputMaybe<EnrollmentWaitlistsSetInput>;
  pkColumns: EnrollmentWaitlistsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateEnrollmentWaitlistsManyArgs = {
  updates: Array<EnrollmentWaitlistsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateEnrollmentsArgs = {
  _set?: InputMaybe<EnrollmentsSetInput>;
  where: EnrollmentsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateEnrollmentsByPkArgs = {
  _set?: InputMaybe<EnrollmentsSetInput>;
  pkColumns: EnrollmentsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateEnrollmentsManyArgs = {
  updates: Array<EnrollmentsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateFusionauthExternalIdentifiersArgs = {
  _inc?: InputMaybe<FusionauthExternalIdentifiersIncInput>;
  _set?: InputMaybe<FusionauthExternalIdentifiersSetInput>;
  where: FusionauthExternalIdentifiersBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateFusionauthExternalIdentifiersByPkArgs = {
  _inc?: InputMaybe<FusionauthExternalIdentifiersIncInput>;
  _set?: InputMaybe<FusionauthExternalIdentifiersSetInput>;
  pkColumns: FusionauthExternalIdentifiersPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateFusionauthExternalIdentifiersManyArgs = {
  updates: Array<FusionauthExternalIdentifiersUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateFusionauthUserRegistrationsArgs = {
  _inc?: InputMaybe<FusionauthUserRegistrationsIncInput>;
  _set?: InputMaybe<FusionauthUserRegistrationsSetInput>;
  where: FusionauthUserRegistrationsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateFusionauthUserRegistrationsByPkArgs = {
  _inc?: InputMaybe<FusionauthUserRegistrationsIncInput>;
  _set?: InputMaybe<FusionauthUserRegistrationsSetInput>;
  pkColumns: FusionauthUserRegistrationsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateFusionauthUserRegistrationsManyArgs = {
  updates: Array<FusionauthUserRegistrationsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateIdentitiesArgs = {
  _inc?: InputMaybe<IdentitiesIncInput>;
  _set?: InputMaybe<IdentitiesSetInput>;
  where: IdentitiesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateIdentitiesByPkArgs = {
  _inc?: InputMaybe<IdentitiesIncInput>;
  _set?: InputMaybe<IdentitiesSetInput>;
  pkColumns: IdentitiesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateIdentitiesManyArgs = {
  updates: Array<IdentitiesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateIdentityProviderLinksArgs = {
  _inc?: InputMaybe<IdentityProviderLinksIncInput>;
  _set?: InputMaybe<IdentityProviderLinksSetInput>;
  where: IdentityProviderLinksBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateIdentityProviderLinksManyArgs = {
  updates: Array<IdentityProviderLinksUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateIdentityProvidersArgs = {
  _inc?: InputMaybe<IdentityProvidersIncInput>;
  _set?: InputMaybe<IdentityProvidersSetInput>;
  where: IdentityProvidersBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateIdentityProvidersByPkArgs = {
  _inc?: InputMaybe<IdentityProvidersIncInput>;
  _set?: InputMaybe<IdentityProvidersSetInput>;
  pkColumns: IdentityProvidersPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateIdentityProvidersManyArgs = {
  updates: Array<IdentityProvidersUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateImpactAssessmentAttemptsArgs = {
  _inc?: InputMaybe<ImpactAssessmentAttemptsIncInput>;
  _set?: InputMaybe<ImpactAssessmentAttemptsSetInput>;
  where: ImpactAssessmentAttemptsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateImpactAssessmentAttemptsByPkArgs = {
  _inc?: InputMaybe<ImpactAssessmentAttemptsIncInput>;
  _set?: InputMaybe<ImpactAssessmentAttemptsSetInput>;
  pkColumns: ImpactAssessmentAttemptsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateImpactAssessmentAttemptsManyArgs = {
  updates: Array<ImpactAssessmentAttemptsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateImpactAssessmentQuestionsArgs = {
  _inc?: InputMaybe<ImpactAssessmentQuestionsIncInput>;
  _set?: InputMaybe<ImpactAssessmentQuestionsSetInput>;
  where: ImpactAssessmentQuestionsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateImpactAssessmentQuestionsManyArgs = {
  updates: Array<ImpactAssessmentQuestionsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateImpactAssessmentsArgs = {
  _inc?: InputMaybe<ImpactAssessmentsIncInput>;
  _set?: InputMaybe<ImpactAssessmentsSetInput>;
  where: ImpactAssessmentsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateImpactAssessmentsByPkArgs = {
  _inc?: InputMaybe<ImpactAssessmentsIncInput>;
  _set?: InputMaybe<ImpactAssessmentsSetInput>;
  pkColumns: ImpactAssessmentsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateImpactAssessmentsManyArgs = {
  updates: Array<ImpactAssessmentsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateJourneyEnrollmentsArgs = {
  _append?: InputMaybe<JourneyEnrollmentsAppendInput>;
  _deleteAtPath?: InputMaybe<JourneyEnrollmentsDeleteAtPathInput>;
  _deleteElem?: InputMaybe<JourneyEnrollmentsDeleteElemInput>;
  _deleteKey?: InputMaybe<JourneyEnrollmentsDeleteKeyInput>;
  _prepend?: InputMaybe<JourneyEnrollmentsPrependInput>;
  _set?: InputMaybe<JourneyEnrollmentsSetInput>;
  where: JourneyEnrollmentsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateJourneyEnrollmentsByPkArgs = {
  _append?: InputMaybe<JourneyEnrollmentsAppendInput>;
  _deleteAtPath?: InputMaybe<JourneyEnrollmentsDeleteAtPathInput>;
  _deleteElem?: InputMaybe<JourneyEnrollmentsDeleteElemInput>;
  _deleteKey?: InputMaybe<JourneyEnrollmentsDeleteKeyInput>;
  _prepend?: InputMaybe<JourneyEnrollmentsPrependInput>;
  _set?: InputMaybe<JourneyEnrollmentsSetInput>;
  pkColumns: JourneyEnrollmentsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateJourneyEnrollmentsManyArgs = {
  updates: Array<JourneyEnrollmentsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateJourneysArgs = {
  _set?: InputMaybe<JourneysSetInput>;
  where: JourneysBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateJourneysByPkArgs = {
  _set?: InputMaybe<JourneysSetInput>;
  pkColumns: JourneysPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateJourneysManyArgs = {
  updates: Array<JourneysUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateLedgerArgs = {
  _inc?: InputMaybe<LedgerIncInput>;
  _set?: InputMaybe<LedgerSetInput>;
  where: LedgerBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateLedgerByPkArgs = {
  _inc?: InputMaybe<LedgerIncInput>;
  _set?: InputMaybe<LedgerSetInput>;
  pkColumns: LedgerPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateLedgerManyArgs = {
  updates: Array<LedgerUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateLedgerProductEnumArgs = {
  _set?: InputMaybe<LedgerProductEnumSetInput>;
  where: LedgerProductEnumBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateLedgerProductEnumByPkArgs = {
  _set?: InputMaybe<LedgerProductEnumSetInput>;
  pkColumns: LedgerProductEnumPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateLedgerProductEnumManyArgs = {
  updates: Array<LedgerProductEnumUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateLedgerTransactionTypeEnumArgs = {
  _set?: InputMaybe<LedgerTransactionTypeEnumSetInput>;
  where: LedgerTransactionTypeEnumBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateLedgerTransactionTypeEnumByPkArgs = {
  _set?: InputMaybe<LedgerTransactionTypeEnumSetInput>;
  pkColumns: LedgerTransactionTypeEnumPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateLedgerTransactionTypeEnumManyArgs = {
  updates: Array<LedgerTransactionTypeEnumUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateLightningSessionArgs = {
  _append?: InputMaybe<LightningSessionAppendInput>;
  _deleteAtPath?: InputMaybe<LightningSessionDeleteAtPathInput>;
  _deleteElem?: InputMaybe<LightningSessionDeleteElemInput>;
  _deleteKey?: InputMaybe<LightningSessionDeleteKeyInput>;
  _prepend?: InputMaybe<LightningSessionPrependInput>;
  _set?: InputMaybe<LightningSessionSetInput>;
  where: LightningSessionBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateLightningSessionByPkArgs = {
  _append?: InputMaybe<LightningSessionAppendInput>;
  _deleteAtPath?: InputMaybe<LightningSessionDeleteAtPathInput>;
  _deleteElem?: InputMaybe<LightningSessionDeleteElemInput>;
  _deleteKey?: InputMaybe<LightningSessionDeleteKeyInput>;
  _prepend?: InputMaybe<LightningSessionPrependInput>;
  _set?: InputMaybe<LightningSessionSetInput>;
  pkColumns: LightningSessionPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateLightningSessionManyArgs = {
  updates: Array<LightningSessionUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateLightningSessionSubmissionsArgs = {
  _inc?: InputMaybe<LightningSessionSubmissionsIncInput>;
  _set?: InputMaybe<LightningSessionSubmissionsSetInput>;
  where: LightningSessionSubmissionsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateLightningSessionSubmissionsByPkArgs = {
  _inc?: InputMaybe<LightningSessionSubmissionsIncInput>;
  _set?: InputMaybe<LightningSessionSubmissionsSetInput>;
  pkColumns: LightningSessionSubmissionsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateLightningSessionSubmissionsManyArgs = {
  updates: Array<LightningSessionSubmissionsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateLightningSessionSubmissionsStatusArgs = {
  _set?: InputMaybe<LightningSessionSubmissionsStatusSetInput>;
  where: LightningSessionSubmissionsStatusBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateLightningSessionSubmissionsStatusByPkArgs = {
  _set?: InputMaybe<LightningSessionSubmissionsStatusSetInput>;
  pkColumns: LightningSessionSubmissionsStatusPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateLightningSessionSubmissionsStatusManyArgs = {
  updates: Array<LightningSessionSubmissionsStatusUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateLightningSessionSubmissionsUsersRelArgs = {
  _set?: InputMaybe<LightningSessionSubmissionsUsersRelSetInput>;
  where: LightningSessionSubmissionsUsersRelBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateLightningSessionSubmissionsUsersRelByPkArgs = {
  _set?: InputMaybe<LightningSessionSubmissionsUsersRelSetInput>;
  pkColumns: LightningSessionSubmissionsUsersRelPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateLightningSessionSubmissionsUsersRelManyArgs = {
  updates: Array<LightningSessionSubmissionsUsersRelUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateManagerSessionEmailsArgs = {
  _inc?: InputMaybe<ManagerSessionEmailsIncInput>;
  _set?: InputMaybe<ManagerSessionEmailsSetInput>;
  where: ManagerSessionEmailsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateManagerSessionEmailsByPkArgs = {
  _inc?: InputMaybe<ManagerSessionEmailsIncInput>;
  _set?: InputMaybe<ManagerSessionEmailsSetInput>;
  pkColumns: ManagerSessionEmailsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateManagerSessionEmailsManyArgs = {
  updates: Array<ManagerSessionEmailsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateManyPlayExecutionArgs = {
  updates: Array<PlayExecutionUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateNonceAccessTokenArgs = {
  _set?: InputMaybe<NonceAccessTokenSetInput>;
  where: NonceAccessTokenBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateNonceAccessTokenByPkArgs = {
  _set?: InputMaybe<NonceAccessTokenSetInput>;
  pkColumns: NonceAccessTokenPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateNonceAccessTokenManyArgs = {
  updates: Array<NonceAccessTokenUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateOrganizationUsersArgs = {
  _inc?: InputMaybe<OrganizationUsersIncInput>;
  _set?: InputMaybe<OrganizationUsersSetInput>;
  where: OrganizationUsersBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateOrganizationUsersByPkArgs = {
  _inc?: InputMaybe<OrganizationUsersIncInput>;
  _set?: InputMaybe<OrganizationUsersSetInput>;
  pkColumns: OrganizationUsersPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateOrganizationUsersManyArgs = {
  updates: Array<OrganizationUsersUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateOrganizationsArgs = {
  _inc?: InputMaybe<OrganizationsIncInput>;
  _set?: InputMaybe<OrganizationsSetInput>;
  where: OrganizationsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateOrganizationsByPkArgs = {
  _inc?: InputMaybe<OrganizationsIncInput>;
  _set?: InputMaybe<OrganizationsSetInput>;
  pkColumns: OrganizationsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateOrganizationsManyArgs = {
  updates: Array<OrganizationsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateOrganizationsMetaArgs = {
  _set?: InputMaybe<OrganizationsMetaSetInput>;
  where: OrganizationsMetaBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateOrganizationsMetaByPkArgs = {
  _set?: InputMaybe<OrganizationsMetaSetInput>;
  pkColumns: OrganizationsMetaPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateOrganizationsMetaManyArgs = {
  updates: Array<OrganizationsMetaUpdates>;
};

/** mutation root */
export type Mutation_RootUpdatePlanSubscriptionsArgs = {
  _set?: InputMaybe<PlanSubscriptionsSetInput>;
  where: PlanSubscriptionsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdatePlanSubscriptionsByPkArgs = {
  _set?: InputMaybe<PlanSubscriptionsSetInput>;
  pkColumns: PlanSubscriptionsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdatePlanSubscriptionsManyArgs = {
  updates: Array<PlanSubscriptionsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdatePlansArgs = {
  _append?: InputMaybe<PlansAppendInput>;
  _deleteAtPath?: InputMaybe<PlansDeleteAtPathInput>;
  _deleteElem?: InputMaybe<PlansDeleteElemInput>;
  _deleteKey?: InputMaybe<PlansDeleteKeyInput>;
  _prepend?: InputMaybe<PlansPrependInput>;
  _set?: InputMaybe<PlansSetInput>;
  where: PlansBoolExp;
};

/** mutation root */
export type Mutation_RootUpdatePlansByPkArgs = {
  _append?: InputMaybe<PlansAppendInput>;
  _deleteAtPath?: InputMaybe<PlansDeleteAtPathInput>;
  _deleteElem?: InputMaybe<PlansDeleteElemInput>;
  _deleteKey?: InputMaybe<PlansDeleteKeyInput>;
  _prepend?: InputMaybe<PlansPrependInput>;
  _set?: InputMaybe<PlansSetInput>;
  pkColumns: PlansPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdatePlansManyArgs = {
  updates: Array<PlansUpdates>;
};

/** mutation root */
export type Mutation_RootUpdatePlayExecutionArgs = {
  _append?: InputMaybe<PlayExecutionAppendInput>;
  _deleteAtPath?: InputMaybe<PlayExecutionDeleteAtPathInput>;
  _deleteElem?: InputMaybe<PlayExecutionDeleteElemInput>;
  _deleteKey?: InputMaybe<PlayExecutionDeleteKeyInput>;
  _prepend?: InputMaybe<PlayExecutionPrependInput>;
  _set?: InputMaybe<PlayExecutionSetInput>;
  where: PlayExecutionBoolExp;
};

/** mutation root */
export type Mutation_RootUpdatePlayExecutionByPkArgs = {
  _append?: InputMaybe<PlayExecutionAppendInput>;
  _deleteAtPath?: InputMaybe<PlayExecutionDeleteAtPathInput>;
  _deleteElem?: InputMaybe<PlayExecutionDeleteElemInput>;
  _deleteKey?: InputMaybe<PlayExecutionDeleteKeyInput>;
  _prepend?: InputMaybe<PlayExecutionPrependInput>;
  _set?: InputMaybe<PlayExecutionSetInput>;
  pkColumns: PlayExecutionPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateProgramActionsTakenArgs = {
  _set?: InputMaybe<ProgramActionsTakenSetInput>;
  where: ProgramActionsTakenBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateProgramActionsTakenManyArgs = {
  updates: Array<ProgramActionsTakenUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateProgramCoursesShownArgs = {
  _set?: InputMaybe<ProgramCoursesShownSetInput>;
  where: ProgramCoursesShownBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateProgramCoursesShownByPkArgs = {
  _set?: InputMaybe<ProgramCoursesShownSetInput>;
  pkColumns: ProgramCoursesShownPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateProgramCoursesShownManyArgs = {
  updates: Array<ProgramCoursesShownUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateProgramTagsArgs = {
  _set?: InputMaybe<ProgramTagsSetInput>;
  where: ProgramTagsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateProgramTagsByPkArgs = {
  _set?: InputMaybe<ProgramTagsSetInput>;
  pkColumns: ProgramTagsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateProgramTagsManyArgs = {
  updates: Array<ProgramTagsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateProgramUsersSelectedArgs = {
  _set?: InputMaybe<ProgramUsersSelectedSetInput>;
  where: ProgramUsersSelectedBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateProgramUsersSelectedByPkArgs = {
  _set?: InputMaybe<ProgramUsersSelectedSetInput>;
  pkColumns: ProgramUsersSelectedPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateProgramUsersSelectedManyArgs = {
  updates: Array<ProgramUsersSelectedUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateProgramsArgs = {
  _set?: InputMaybe<ProgramsSetInput>;
  where: ProgramsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateProgramsByPkArgs = {
  _set?: InputMaybe<ProgramsSetInput>;
  pkColumns: ProgramsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateProgramsManyArgs = {
  updates: Array<ProgramsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateQuestionTypeArgs = {
  _set?: InputMaybe<QuestionTypeSetInput>;
  where: QuestionTypeBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateQuestionTypeByPkArgs = {
  _set?: InputMaybe<QuestionTypeSetInput>;
  pkColumns: QuestionTypePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateQuestionTypeManyArgs = {
  updates: Array<QuestionTypeUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateRecordingActivityArgs = {
  _append?: InputMaybe<RecordingActivityAppendInput>;
  _deleteAtPath?: InputMaybe<RecordingActivityDeleteAtPathInput>;
  _deleteElem?: InputMaybe<RecordingActivityDeleteElemInput>;
  _deleteKey?: InputMaybe<RecordingActivityDeleteKeyInput>;
  _prepend?: InputMaybe<RecordingActivityPrependInput>;
  _set?: InputMaybe<RecordingActivitySetInput>;
  where: RecordingActivityBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateRecordingActivityByPkArgs = {
  _append?: InputMaybe<RecordingActivityAppendInput>;
  _deleteAtPath?: InputMaybe<RecordingActivityDeleteAtPathInput>;
  _deleteElem?: InputMaybe<RecordingActivityDeleteElemInput>;
  _deleteKey?: InputMaybe<RecordingActivityDeleteKeyInput>;
  _prepend?: InputMaybe<RecordingActivityPrependInput>;
  _set?: InputMaybe<RecordingActivitySetInput>;
  pkColumns: RecordingActivityPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateRecordingActivityManyArgs = {
  updates: Array<RecordingActivityUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateRecordingsArgs = {
  _append?: InputMaybe<RecordingsAppendInput>;
  _deleteAtPath?: InputMaybe<RecordingsDeleteAtPathInput>;
  _deleteElem?: InputMaybe<RecordingsDeleteElemInput>;
  _deleteKey?: InputMaybe<RecordingsDeleteKeyInput>;
  _prepend?: InputMaybe<RecordingsPrependInput>;
  _set?: InputMaybe<RecordingsSetInput>;
  where: RecordingsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateRecordingsByPkArgs = {
  _append?: InputMaybe<RecordingsAppendInput>;
  _deleteAtPath?: InputMaybe<RecordingsDeleteAtPathInput>;
  _deleteElem?: InputMaybe<RecordingsDeleteElemInput>;
  _deleteKey?: InputMaybe<RecordingsDeleteKeyInput>;
  _prepend?: InputMaybe<RecordingsPrependInput>;
  _set?: InputMaybe<RecordingsSetInput>;
  pkColumns: RecordingsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateRecordingsManyArgs = {
  updates: Array<RecordingsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateRelatedCoursesArgs = {
  _set?: InputMaybe<RelatedCoursesSetInput>;
  where: RelatedCoursesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateRelatedCoursesByPkArgs = {
  _set?: InputMaybe<RelatedCoursesSetInput>;
  pkColumns: RelatedCoursesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateRelatedCoursesManyArgs = {
  updates: Array<RelatedCoursesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateReportPublishStatusArgs = {
  _set?: InputMaybe<ReportPublishStatusSetInput>;
  where: ReportPublishStatusBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateReportPublishStatusByPkArgs = {
  _set?: InputMaybe<ReportPublishStatusSetInput>;
  pkColumns: ReportPublishStatusPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateReportPublishStatusManyArgs = {
  updates: Array<ReportPublishStatusUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateReportTypeArgs = {
  _set?: InputMaybe<ReportTypeSetInput>;
  where: ReportTypeBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateReportTypeByPkArgs = {
  _set?: InputMaybe<ReportTypeSetInput>;
  pkColumns: ReportTypePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateReportTypeManyArgs = {
  updates: Array<ReportTypeUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateReportsArgs = {
  _inc?: InputMaybe<ReportsIncInput>;
  _set?: InputMaybe<ReportsSetInput>;
  where: ReportsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateReportsByPkArgs = {
  _inc?: InputMaybe<ReportsIncInput>;
  _set?: InputMaybe<ReportsSetInput>;
  pkColumns: ReportsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateReportsManyArgs = {
  updates: Array<ReportsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateResourceTypeArgs = {
  _set?: InputMaybe<ResourceTypeSetInput>;
  where: ResourceTypeBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateResourceTypeByPkArgs = {
  _set?: InputMaybe<ResourceTypeSetInput>;
  pkColumns: ResourceTypePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateResourceTypeManyArgs = {
  updates: Array<ResourceTypeUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateResourcesArgs = {
  _inc?: InputMaybe<ResourcesIncInput>;
  _set?: InputMaybe<ResourcesSetInput>;
  where: ResourcesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateResourcesByPkArgs = {
  _inc?: InputMaybe<ResourcesIncInput>;
  _set?: InputMaybe<ResourcesSetInput>;
  pkColumns: ResourcesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateResourcesManyArgs = {
  updates: Array<ResourcesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateSchoolsArgs = {
  _set?: InputMaybe<SchoolsSetInput>;
  where: SchoolsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateSchoolsByPkArgs = {
  _set?: InputMaybe<SchoolsSetInput>;
  pkColumns: SchoolsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateSchoolsManyArgs = {
  updates: Array<SchoolsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateSeatTypeArgs = {
  _set?: InputMaybe<SeatTypeSetInput>;
  where: SeatTypeBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateSeatTypeByPkArgs = {
  _set?: InputMaybe<SeatTypeSetInput>;
  pkColumns: SeatTypePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateSeatTypeManyArgs = {
  updates: Array<SeatTypeUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateSeatsArgs = {
  _set?: InputMaybe<SeatsSetInput>;
  where: SeatsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateSeatsByPkArgs = {
  _set?: InputMaybe<SeatsSetInput>;
  pkColumns: SeatsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateSeatsManyArgs = {
  updates: Array<SeatsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateStreamMessagesArgs = {
  _append?: InputMaybe<StreamMessagesAppendInput>;
  _deleteAtPath?: InputMaybe<StreamMessagesDeleteAtPathInput>;
  _deleteElem?: InputMaybe<StreamMessagesDeleteElemInput>;
  _deleteKey?: InputMaybe<StreamMessagesDeleteKeyInput>;
  _prepend?: InputMaybe<StreamMessagesPrependInput>;
  _set?: InputMaybe<StreamMessagesSetInput>;
  where: StreamMessagesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateStreamMessagesByPkArgs = {
  _append?: InputMaybe<StreamMessagesAppendInput>;
  _deleteAtPath?: InputMaybe<StreamMessagesDeleteAtPathInput>;
  _deleteElem?: InputMaybe<StreamMessagesDeleteElemInput>;
  _deleteKey?: InputMaybe<StreamMessagesDeleteKeyInput>;
  _prepend?: InputMaybe<StreamMessagesPrependInput>;
  _set?: InputMaybe<StreamMessagesSetInput>;
  pkColumns: StreamMessagesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateStreamMessagesManyArgs = {
  updates: Array<StreamMessagesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTagTypeScopeArgs = {
  _set?: InputMaybe<TagTypeScopeSetInput>;
  where: TagTypeScopeBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTagTypeScopeByPkArgs = {
  _set?: InputMaybe<TagTypeScopeSetInput>;
  pkColumns: TagTypeScopePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTagTypeScopeManyArgs = {
  updates: Array<TagTypeScopeUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTagTypesArgs = {
  _set?: InputMaybe<TagTypesSetInput>;
  where: TagTypesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTagTypesByPkArgs = {
  _set?: InputMaybe<TagTypesSetInput>;
  pkColumns: TagTypesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTagTypesManyArgs = {
  updates: Array<TagTypesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTagsArgs = {
  _set?: InputMaybe<TagsSetInput>;
  where: TagsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTagsByPkArgs = {
  _set?: InputMaybe<TagsSetInput>;
  pkColumns: TagsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTagsManyArgs = {
  updates: Array<TagsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTaskDefinitionArgs = {
  _set?: InputMaybe<TaskDefinitionSetInput>;
  where: TaskDefinitionBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTaskDefinitionByPkArgs = {
  _set?: InputMaybe<TaskDefinitionSetInput>;
  pkColumns: TaskDefinitionPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTaskDefinitionManyArgs = {
  updates: Array<TaskDefinitionUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTaskDefinitionTypeArgs = {
  _set?: InputMaybe<TaskDefinitionTypeSetInput>;
  where: TaskDefinitionTypeBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTaskDefinitionTypeByPkArgs = {
  _set?: InputMaybe<TaskDefinitionTypeSetInput>;
  pkColumns: TaskDefinitionTypePkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTaskDefinitionTypeManyArgs = {
  updates: Array<TaskDefinitionTypeUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTasksArgs = {
  _append?: InputMaybe<TasksAppendInput>;
  _deleteAtPath?: InputMaybe<TasksDeleteAtPathInput>;
  _deleteElem?: InputMaybe<TasksDeleteElemInput>;
  _deleteKey?: InputMaybe<TasksDeleteKeyInput>;
  _prepend?: InputMaybe<TasksPrependInput>;
  _set?: InputMaybe<TasksSetInput>;
  where: TasksBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTasksByPkArgs = {
  _append?: InputMaybe<TasksAppendInput>;
  _deleteAtPath?: InputMaybe<TasksDeleteAtPathInput>;
  _deleteElem?: InputMaybe<TasksDeleteElemInput>;
  _deleteKey?: InputMaybe<TasksDeleteKeyInput>;
  _prepend?: InputMaybe<TasksPrependInput>;
  _set?: InputMaybe<TasksSetInput>;
  pkColumns: TasksPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTasksManyArgs = {
  updates: Array<TasksUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTeamsArgs = {
  _set?: InputMaybe<TeamsSetInput>;
  where: TeamsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTeamsByPkArgs = {
  _set?: InputMaybe<TeamsSetInput>;
  pkColumns: TeamsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTeamsManyArgs = {
  updates: Array<TeamsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTeamsUsersArgs = {
  _set?: InputMaybe<TeamsUsersSetInput>;
  where: TeamsUsersBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTeamsUsersByPkArgs = {
  _set?: InputMaybe<TeamsUsersSetInput>;
  pkColumns: TeamsUsersPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTeamsUsersManyArgs = {
  updates: Array<TeamsUsersUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTypeformSubmissionsArgs = {
  _append?: InputMaybe<TypeformSubmissionsAppendInput>;
  _deleteAtPath?: InputMaybe<TypeformSubmissionsDeleteAtPathInput>;
  _deleteElem?: InputMaybe<TypeformSubmissionsDeleteElemInput>;
  _deleteKey?: InputMaybe<TypeformSubmissionsDeleteKeyInput>;
  _prepend?: InputMaybe<TypeformSubmissionsPrependInput>;
  _set?: InputMaybe<TypeformSubmissionsSetInput>;
  where: TypeformSubmissionsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTypeformSubmissionsByPkArgs = {
  _append?: InputMaybe<TypeformSubmissionsAppendInput>;
  _deleteAtPath?: InputMaybe<TypeformSubmissionsDeleteAtPathInput>;
  _deleteElem?: InputMaybe<TypeformSubmissionsDeleteElemInput>;
  _deleteKey?: InputMaybe<TypeformSubmissionsDeleteKeyInput>;
  _prepend?: InputMaybe<TypeformSubmissionsPrependInput>;
  _set?: InputMaybe<TypeformSubmissionsSetInput>;
  pkColumns: TypeformSubmissionsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTypeformSubmissionsManyArgs = {
  updates: Array<TypeformSubmissionsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateUserSessionEmailsArgs = {
  _set?: InputMaybe<UserSessionEmailsSetInput>;
  where: UserSessionEmailsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateUserSessionEmailsByPkArgs = {
  _set?: InputMaybe<UserSessionEmailsSetInput>;
  pkColumns: UserSessionEmailsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateUserSessionEmailsManyArgs = {
  updates: Array<UserSessionEmailsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateUsersArgs = {
  _inc?: InputMaybe<UsersIncInput>;
  _set?: InputMaybe<UsersSetInput>;
  where: UsersBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateUsersByPkArgs = {
  _inc?: InputMaybe<UsersIncInput>;
  _set?: InputMaybe<UsersSetInput>;
  pkColumns: UsersPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateUsersFunctionsArgs = {
  _set?: InputMaybe<UsersFunctionsSetInput>;
  where: UsersFunctionsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateUsersFunctionsByPkArgs = {
  _set?: InputMaybe<UsersFunctionsSetInput>;
  pkColumns: UsersFunctionsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateUsersFunctionsManyArgs = {
  updates: Array<UsersFunctionsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateUsersLengthInFunctionArgs = {
  _set?: InputMaybe<UsersLengthInFunctionSetInput>;
  where: UsersLengthInFunctionBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateUsersLengthInFunctionByPkArgs = {
  _set?: InputMaybe<UsersLengthInFunctionSetInput>;
  pkColumns: UsersLengthInFunctionPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateUsersLengthInFunctionManyArgs = {
  updates: Array<UsersLengthInFunctionUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateUsersManyArgs = {
  updates: Array<UsersUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateUsersMetaArgs = {
  _append?: InputMaybe<UsersMetaAppendInput>;
  _deleteAtPath?: InputMaybe<UsersMetaDeleteAtPathInput>;
  _deleteElem?: InputMaybe<UsersMetaDeleteElemInput>;
  _deleteKey?: InputMaybe<UsersMetaDeleteKeyInput>;
  _prepend?: InputMaybe<UsersMetaPrependInput>;
  _set?: InputMaybe<UsersMetaSetInput>;
  where: UsersMetaBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateUsersMetaByPkArgs = {
  _append?: InputMaybe<UsersMetaAppendInput>;
  _deleteAtPath?: InputMaybe<UsersMetaDeleteAtPathInput>;
  _deleteElem?: InputMaybe<UsersMetaDeleteElemInput>;
  _deleteKey?: InputMaybe<UsersMetaDeleteKeyInput>;
  _prepend?: InputMaybe<UsersMetaPrependInput>;
  _set?: InputMaybe<UsersMetaSetInput>;
  pkColumns: UsersMetaPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateUsersMetaManyArgs = {
  updates: Array<UsersMetaUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateZoomOauthTokensArgs = {
  _inc?: InputMaybe<ZoomOauthTokensIncInput>;
  _set?: InputMaybe<ZoomOauthTokensSetInput>;
  where: ZoomOauthTokensBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateZoomOauthTokensByPkArgs = {
  _inc?: InputMaybe<ZoomOauthTokensIncInput>;
  _set?: InputMaybe<ZoomOauthTokensSetInput>;
  pkColumns: ZoomOauthTokensPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateZoomOauthTokensManyArgs = {
  updates: Array<ZoomOauthTokensUpdates>;
};

/** mutation root */
export type Mutation_RootVersionImpactAssessmentArgs = {
  args: VersionImpactAssessmentArgs;
  distinctOn?: InputMaybe<Array<ImpactAssessmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImpactAssessmentsOrderBy>>;
  where?: InputMaybe<ImpactAssessmentsBoolExp>;
};

/** myTeamFlatTreeNative Query Arguments */
export type MyTeamFlatTree_Arguments = {
  /** User's Organization ID */
  organizationId: Scalars['uuid'];
  /** User's ID */
  userId: Scalars['uuid'];
};

/** my_learner_task_native_queryNative Query Arguments */
export type My_Learner_Task_Native_Query_Arguments = {
  /** User's ID */
  userId: Scalars['uuid'];
};

export type PlanSubscriptionsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<PlanSubscriptionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PlanSubscriptionsBoolExp>;
  predicate: IntComparisonExp;
};

export type ProgramActionsTakenAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ProgramActionsTakenSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ProgramActionsTakenBoolExp>;
  predicate: IntComparisonExp;
};

export type ProgramCoursesShownAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ProgramCoursesShownSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ProgramCoursesShownBoolExp>;
  predicate: IntComparisonExp;
};

export type ProgramTagsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ProgramTagsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ProgramTagsBoolExp>;
  predicate: IntComparisonExp;
};

export type ProgramUsersSelectedAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ProgramUsersSelectedSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ProgramUsersSelectedBoolExp>;
  predicate: IntComparisonExp;
};

export type PublishCourseArgs = {
  course_slug?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** CheckMyTeamCourseWaitlist */
  CheckMyTeamCourseWaitlist?: Maybe<CheckMyTeamCourseWaitlistOutput>;
  CountTeamSessions?: Maybe<CountTeamSessionsOutput>;
  GetMySessionsAttendedCount?: Maybe<GetMySessionsAttendedCountOutput>;
  GetPastSessionAsyncMetrics?: Maybe<GetPastSessionAsyncMetricsOutput>;
  GetPastSessionsAsyncAverageMetrics?: Maybe<GetPastSessionAsyncAverageMetricsOutput>;
  MyTeamMembers?: Maybe<MyTeamMembersOutput>;
  /** fetch data from the table: "activity_type_enum" */
  activityType: Array<ActivityType>;
  /** fetch aggregated fields from the table: "activity_type_enum" */
  activityTypeAggregate: ActivityTypeAggregate;
  /** fetch data from the table: "activity_type_enum" using primary key columns */
  activityTypeByPk?: Maybe<ActivityType>;
  /** fetch data from the table: "attempt_status_enum" */
  attemptStatus: Array<AttemptStatus>;
  /** fetch aggregated fields from the table: "attempt_status_enum" */
  attemptStatusAggregate: AttemptStatusAggregate;
  /** fetch data from the table: "attempt_status_enum" using primary key columns */
  attemptStatusByPk?: Maybe<AttemptStatus>;
  /** fetch data from the table: "catalogs_courses_rel" */
  catalogCourses: Array<CatalogCourses>;
  /** fetch aggregated fields from the table: "catalogs_courses_rel" */
  catalogCoursesAggregate: CatalogCoursesAggregate;
  /** fetch data from the table: "catalogs_courses_rel" using primary key columns */
  catalogCoursesByPk?: Maybe<CatalogCourses>;
  /** fetch data from the table: "catalogs" */
  catalogs: Array<Catalogs>;
  /** fetch aggregated fields from the table: "catalogs" */
  catalogsAggregate: CatalogsAggregate;
  /** fetch data from the table: "catalogs" using primary key columns */
  catalogsByPk?: Maybe<Catalogs>;
  /** fetch data from the table: "catalogs_journeys_rel" */
  catalogsJourneysRel: Array<CatalogsJourneysRel>;
  /** fetch aggregated fields from the table: "catalogs_journeys_rel" */
  catalogsJourneysRelAggregate: CatalogsJourneysRelAggregate;
  /** fetch data from the table: "catalogs_journeys_rel" using primary key columns */
  catalogsJourneysRelByPk?: Maybe<CatalogsJourneysRel>;
  /** fetch data from the table: "classes_coaches_rel" */
  classCoaches: Array<ClassCoaches>;
  /** fetch aggregated fields from the table: "classes_coaches_rel" */
  classCoachesAggregate: ClassCoachesAggregate;
  /** fetch data from the table: "classes_coaches_rel" using primary key columns */
  classCoachesByPk?: Maybe<ClassCoaches>;
  /** fetch data from the table: "class_event_activity" */
  classEventActivity: Array<ClassEventActivity>;
  /** fetch aggregated fields from the table: "class_event_activity" */
  classEventActivityAggregate: ClassEventActivityAggregate;
  /** fetch data from the table: "class_event_activity" using primary key columns */
  classEventActivityByPk?: Maybe<ClassEventActivity>;
  /** fetch data from the table: "class_event_anon_registrations" */
  classEventAnonRegistrations: Array<ClassEventAnonRegistrations>;
  /** fetch aggregated fields from the table: "class_event_anon_registrations" */
  classEventAnonRegistrationsAggregate: ClassEventAnonRegistrationsAggregate;
  /** fetch data from the table: "class_event_anon_registrations" using primary key columns */
  classEventAnonRegistrationsByPk?: Maybe<ClassEventAnonRegistrations>;
  /** fetch data from the table: "class_event_invitations" */
  classEventInvitations: Array<ClassEventInvitations>;
  /** fetch aggregated fields from the table: "class_event_invitations" */
  classEventInvitationsAggregate: ClassEventInvitationsAggregate;
  /** fetch data from the table: "class_event_invitations" using primary key columns */
  classEventInvitationsByPk?: Maybe<ClassEventInvitations>;
  /** An array relationship */
  classEventMeetingParticipants: Array<ClassEventMeetingParticipants>;
  /** An aggregate relationship */
  classEventMeetingParticipantsAggregate: ClassEventMeetingParticipantsAggregate;
  /** fetch data from the table: "class_event_meeting_participants" using primary key columns */
  classEventMeetingParticipantsByPk?: Maybe<ClassEventMeetingParticipants>;
  /** fetch data from the table: "class_event_meeting_segments" */
  classEventMeetingSegments: Array<ClassEventMeetingSegments>;
  /** fetch aggregated fields from the table: "class_event_meeting_segments" */
  classEventMeetingSegmentsAggregate: ClassEventMeetingSegmentsAggregate;
  /** fetch data from the table: "class_event_meeting_segments" using primary key columns */
  classEventMeetingSegmentsByPk?: Maybe<ClassEventMeetingSegments>;
  /** fetch data from the table: "class_event_meetings" */
  classEventMeetings: Array<ClassEventMeetings>;
  /** fetch aggregated fields from the table: "class_event_meetings" */
  classEventMeetingsAggregate: ClassEventMeetingsAggregate;
  /** fetch data from the table: "class_event_meetings" using primary key columns */
  classEventMeetingsByPk?: Maybe<ClassEventMeetings>;
  /** fetch data from the table: "class_event_registrations" */
  classEventRegistrations: Array<ClassEventRegistrations>;
  /** fetch aggregated fields from the table: "class_event_registrations" */
  classEventRegistrationsAggregate: ClassEventRegistrationsAggregate;
  /** fetch data from the table: "class_event_registrations" using primary key columns */
  classEventRegistrationsByPk?: Maybe<ClassEventRegistrations>;
  /** An array relationship */
  classEvents: Array<ClassEvents>;
  /** An aggregate relationship */
  classEventsAggregate: ClassEventsAggregate;
  /** fetch data from the table: "class_events" using primary key columns */
  classEventsByPk?: Maybe<ClassEvents>;
  /** fetch data from the table: "class_playback_activity" */
  classPlaybackActivity: Array<ClassPlaybackActivity>;
  /** fetch aggregated fields from the table: "class_playback_activity" */
  classPlaybackActivityAggregate: ClassPlaybackActivityAggregate;
  /** fetch data from the table: "class_playback_activity" using primary key columns */
  classPlaybackActivityByPk?: Maybe<ClassPlaybackActivity>;
  /** fetch data from the table: "class_playback_activity_type_enum" */
  classPlaybackActivityType: Array<ClassPlaybackActivityType>;
  /** fetch aggregated fields from the table: "class_playback_activity_type_enum" */
  classPlaybackActivityTypeAggregate: ClassPlaybackActivityTypeAggregate;
  /** fetch data from the table: "class_playback_activity_type_enum" using primary key columns */
  classPlaybackActivityTypeByPk?: Maybe<ClassPlaybackActivityType>;
  /** fetch data from the table: "classes_resources_rel" */
  classResources: Array<ClassResources>;
  /** fetch aggregated fields from the table: "classes_resources_rel" */
  classResourcesAggregate: ClassResourcesAggregate;
  /** fetch data from the table: "classes_resources_rel" using primary key columns */
  classResourcesByPk?: Maybe<ClassResources>;
  /** An array relationship */
  classes: Array<Classes>;
  /** An aggregate relationship */
  classesAggregate: ClassesAggregate;
  /** fetch data from the table: "classes" using primary key columns */
  classesByPk?: Maybe<Classes>;
  /** fetch data from the table: "classes_tags_rel" */
  classesTagsRel: Array<ClassesTagsRel>;
  /** fetch aggregated fields from the table: "classes_tags_rel" */
  classesTagsRelAggregate: ClassesTagsRelAggregate;
  /** fetch data from the table: "classes_tags_rel" using primary key columns */
  classesTagsRelByPk?: Maybe<ClassesTagsRel>;
  /** An array relationship */
  coaches: Array<Coaches>;
  /** An aggregate relationship */
  coachesAggregate: CoachesAggregate;
  /** fetch data from the table: "coaches" using primary key columns */
  coachesByPk?: Maybe<Coaches>;
  /** fetch data from the table: "cohorts_coaches_rel" */
  cohortCoaches: Array<CohortCoaches>;
  /** fetch aggregated fields from the table: "cohorts_coaches_rel" */
  cohortCoachesAggregate: CohortCoachesAggregate;
  /** fetch data from the table: "cohorts_coaches_rel" using primary key columns */
  cohortCoachesByPk?: Maybe<CohortCoaches>;
  /** fetch data from the table: "cohort_progress_view" */
  cohortProgress: Array<CohortProgress>;
  /** fetch aggregated fields from the table: "cohort_progress_view" */
  cohortProgressAggregate: CohortProgressAggregate;
  /** fetch data from the table: "cohort_type_enum" */
  cohortType: Array<CohortType>;
  /** fetch aggregated fields from the table: "cohort_type_enum" */
  cohortTypeAggregate: CohortTypeAggregate;
  /** fetch data from the table: "cohort_type_enum" using primary key columns */
  cohortTypeByPk?: Maybe<CohortType>;
  /** fetch data from the table: "cohort_visibility_enum" */
  cohortVisibilityEnum: Array<CohortVisibilityEnum>;
  /** fetch aggregated fields from the table: "cohort_visibility_enum" */
  cohortVisibilityEnumAggregate: CohortVisibilityEnumAggregate;
  /** fetch data from the table: "cohort_visibility_enum" using primary key columns */
  cohortVisibilityEnumByPk?: Maybe<CohortVisibilityEnum>;
  /** An array relationship */
  cohorts: Array<Cohorts>;
  /** An aggregate relationship */
  cohortsAggregate: CohortsAggregate;
  /** fetch data from the table: "cohorts" using primary key columns */
  cohortsByPk?: Maybe<Cohorts>;
  /** fetch data from the table: "cohorts_resources_rel" */
  cohortsResourcesRel: Array<CohortsResourcesRel>;
  /** fetch aggregated fields from the table: "cohorts_resources_rel" */
  cohortsResourcesRelAggregate: CohortsResourcesRelAggregate;
  /** fetch data from the table: "cohorts_resources_rel" using primary key columns */
  cohortsResourcesRelByPk?: Maybe<CohortsResourcesRel>;
  /** fetch data from the table: "configurations" */
  configurations: Array<Configurations>;
  /** fetch aggregated fields from the table: "configurations" */
  configurationsAggregate: ConfigurationsAggregate;
  /** fetch data from the table: "configurations" using primary key columns */
  configurationsByPk?: Maybe<Configurations>;
  /** fetch data from the table: "course_certifications" */
  courseCertifications: Array<CourseCertifications>;
  /** fetch aggregated fields from the table: "course_certifications" */
  courseCertificationsAggregate: CourseCertificationsAggregate;
  /** fetch data from the table: "course_certifications" using primary key columns */
  courseCertificationsByPk?: Maybe<CourseCertifications>;
  /** fetch data from the table: "courses_coaches_rel" */
  courseCoaches: Array<CourseCoaches>;
  /** fetch aggregated fields from the table: "courses_coaches_rel" */
  courseCoachesAggregate: CourseCoachesAggregate;
  /** fetch data from the table: "courses_coaches_rel" using primary key columns */
  courseCoachesByPk?: Maybe<CourseCoaches>;
  /** fetch data from the table: "course_enrollment_status_enum" */
  courseEnrollmentStatus: Array<CourseEnrollmentStatus>;
  /** fetch aggregated fields from the table: "course_enrollment_status_enum" */
  courseEnrollmentStatusAggregate: CourseEnrollmentStatusAggregate;
  /** fetch data from the table: "course_enrollment_status_enum" using primary key columns */
  courseEnrollmentStatusByPk?: Maybe<CourseEnrollmentStatus>;
  /** fetch data from the table: "course_publish_status_enum" */
  coursePublishStatus: Array<CoursePublishStatus>;
  /** fetch aggregated fields from the table: "course_publish_status_enum" */
  coursePublishStatusAggregate: CoursePublishStatusAggregate;
  /** fetch data from the table: "course_publish_status_enum" using primary key columns */
  coursePublishStatusByPk?: Maybe<CoursePublishStatus>;
  /** fetch data from the table: "course_type_enum" */
  courseType: Array<CourseType>;
  /** fetch aggregated fields from the table: "course_type_enum" */
  courseTypeAggregate: CourseTypeAggregate;
  /** fetch data from the table: "course_type_enum" using primary key columns */
  courseTypeByPk?: Maybe<CourseType>;
  /** fetch data from the table: "course_waitlist" */
  courseWaitlist: Array<CourseWaitlist>;
  /** fetch aggregated fields from the table: "course_waitlist" */
  courseWaitlistAggregate: CourseWaitlistAggregate;
  /** fetch data from the table: "course_waitlist" using primary key columns */
  courseWaitlistByPk?: Maybe<CourseWaitlist>;
  /** fetch data from the table: "course_waitlist_desired_urgency_type" */
  courseWaitlistDesiredUrgencyType: Array<CourseWaitlistDesiredUrgencyType>;
  /** fetch aggregated fields from the table: "course_waitlist_desired_urgency_type" */
  courseWaitlistDesiredUrgencyTypeAggregate: CourseWaitlistDesiredUrgencyTypeAggregate;
  /** fetch data from the table: "course_waitlist_desired_urgency_type" using primary key columns */
  courseWaitlistDesiredUrgencyTypeByPk?: Maybe<CourseWaitlistDesiredUrgencyType>;
  /** An array relationship */
  courses: Array<Courses>;
  /** An aggregate relationship */
  coursesAggregate: CoursesAggregate;
  /** fetch data from the table: "courses" using primary key columns */
  coursesByPk?: Maybe<Courses>;
  /** fetch data from the table: "courses_by_plan" */
  coursesByPlan: Array<CoursesByPlan>;
  /** fetch aggregated fields from the table: "courses_by_plan" */
  coursesByPlanAggregate: CoursesByPlanAggregate;
  /** fetch data from the table: "courses_resources_rel" */
  coursesResourcesRel: Array<CoursesResourcesRel>;
  /** fetch aggregated fields from the table: "courses_resources_rel" */
  coursesResourcesRelAggregate: CoursesResourcesRelAggregate;
  /** fetch data from the table: "courses_resources_rel" using primary key columns */
  coursesResourcesRelByPk?: Maybe<CoursesResourcesRel>;
  /** fetch data from the table: "courses_tags_rel" */
  coursesTagsRel: Array<CoursesTagsRel>;
  /** fetch aggregated fields from the table: "courses_tags_rel" */
  coursesTagsRelAggregate: CoursesTagsRelAggregate;
  /** fetch data from the table: "courses_tags_rel" using primary key columns */
  coursesTagsRelByPk?: Maybe<CoursesTagsRel>;
  creditUsage: Array<CreditUsage>;
  creditUsageByUser: Array<CreditUsageByUser>;
  /** fetch data from the table: "email_queue" */
  emailQueue: Array<EmailQueue>;
  /** fetch aggregated fields from the table: "email_queue" */
  emailQueueAggregate: EmailQueueAggregate;
  /** fetch data from the table: "email_queue" using primary key columns */
  emailQueueByPk?: Maybe<EmailQueue>;
  /** fetch data from the table: "enrollment_waitlists" */
  enrollmentWaitlists: Array<EnrollmentWaitlists>;
  /** fetch aggregated fields from the table: "enrollment_waitlists" */
  enrollmentWaitlistsAggregate: EnrollmentWaitlistsAggregate;
  /** fetch data from the table: "enrollment_waitlists" using primary key columns */
  enrollmentWaitlistsByPk?: Maybe<EnrollmentWaitlists>;
  /** An array relationship */
  enrollments: Array<Enrollments>;
  /** An aggregate relationship */
  enrollmentsAggregate: EnrollmentsAggregate;
  /** fetch data from the table: "enrollments" using primary key columns */
  enrollmentsByPk?: Maybe<Enrollments>;
  /** fetch data from the table: "fusionauth.external_identifiers" */
  fusionauthExternalIdentifiers: Array<FusionauthExternalIdentifiers>;
  /** fetch aggregated fields from the table: "fusionauth.external_identifiers" */
  fusionauthExternalIdentifiersAggregate: FusionauthExternalIdentifiersAggregate;
  /** fetch data from the table: "fusionauth.external_identifiers" using primary key columns */
  fusionauthExternalIdentifiersByPk?: Maybe<FusionauthExternalIdentifiers>;
  /** fetch data from the table: "fusionauth.user_registrations" */
  fusionauthUserRegistrations: Array<FusionauthUserRegistrations>;
  /** fetch aggregated fields from the table: "fusionauth.user_registrations" */
  fusionauthUserRegistrationsAggregate: FusionauthUserRegistrationsAggregate;
  /** fetch data from the table: "fusionauth.user_registrations" using primary key columns */
  fusionauthUserRegistrationsByPk?: Maybe<FusionauthUserRegistrations>;
  /** execute function "get_assessment_questions" which returns "impact_assessment_questions" */
  getAssessmentQuestions: Array<ImpactAssessmentQuestions>;
  /** execute function "get_assessment_questions" and query aggregates on result of table type "impact_assessment_questions" */
  getAssessmentQuestionsAggregate: ImpactAssessmentQuestionsAggregate;
  getTeamMembers: Array<TeamMember>;
  getTeamMembersNew: Array<GetTeamMembersNew>;
  /** fetch data from the table: "fusionauth.identities" */
  identities: Array<Identities>;
  /** fetch aggregated fields from the table: "fusionauth.identities" */
  identitiesAggregate: IdentitiesAggregate;
  /** fetch data from the table: "fusionauth.identities" using primary key columns */
  identitiesByPk?: Maybe<Identities>;
  /** An array relationship */
  identityProviderLinks: Array<IdentityProviderLinks>;
  /** An aggregate relationship */
  identityProviderLinksAggregate: IdentityProviderLinksAggregate;
  /** fetch data from the table: "fusionauth.identity_providers" */
  identityProviders: Array<IdentityProviders>;
  /** fetch aggregated fields from the table: "fusionauth.identity_providers" */
  identityProvidersAggregate: IdentityProvidersAggregate;
  /** fetch data from the table: "fusionauth.identity_providers" using primary key columns */
  identityProvidersByPk?: Maybe<IdentityProviders>;
  /** An array relationship */
  impactAssessmentAttempts: Array<ImpactAssessmentAttempts>;
  /** An aggregate relationship */
  impactAssessmentAttemptsAggregate: ImpactAssessmentAttemptsAggregate;
  /** fetch data from the table: "impact_assessment_attempts" using primary key columns */
  impactAssessmentAttemptsByPk?: Maybe<ImpactAssessmentAttempts>;
  /** fetch data from the table: "impact_assessment_questions" */
  impactAssessmentQuestions: Array<ImpactAssessmentQuestions>;
  /** fetch aggregated fields from the table: "impact_assessment_questions" */
  impactAssessmentQuestionsAggregate: ImpactAssessmentQuestionsAggregate;
  /** fetch data from the table: "impact_assessments" */
  impactAssessments: Array<ImpactAssessments>;
  /** fetch aggregated fields from the table: "impact_assessments" */
  impactAssessmentsAggregate: ImpactAssessmentsAggregate;
  /** fetch data from the table: "impact_assessments" using primary key columns */
  impactAssessmentsByPk?: Maybe<ImpactAssessments>;
  /** fetch data from the table: "journey_enrollments" */
  journeyEnrollments: Array<JourneyEnrollments>;
  /** fetch aggregated fields from the table: "journey_enrollments" */
  journeyEnrollmentsAggregate: JourneyEnrollmentsAggregate;
  /** fetch data from the table: "journey_enrollments" using primary key columns */
  journeyEnrollmentsByPk?: Maybe<JourneyEnrollments>;
  /** An array relationship */
  journeys: Array<Journeys>;
  /** An aggregate relationship */
  journeysAggregate: JourneysAggregate;
  /** fetch data from the table: "journeys" using primary key columns */
  journeysByPk?: Maybe<Journeys>;
  /** fetch data from the table: "ledger" */
  ledger: Array<Ledger>;
  /** fetch aggregated fields from the table: "ledger" */
  ledgerAggregate: LedgerAggregate;
  /** fetch data from the table: "ledger" using primary key columns */
  ledgerByPk?: Maybe<Ledger>;
  /** fetch data from the table: "ledger_product_enum" */
  ledgerProductEnum: Array<LedgerProductEnum>;
  /** fetch aggregated fields from the table: "ledger_product_enum" */
  ledgerProductEnumAggregate: LedgerProductEnumAggregate;
  /** fetch data from the table: "ledger_product_enum" using primary key columns */
  ledgerProductEnumByPk?: Maybe<LedgerProductEnum>;
  /** fetch data from the table: "ledger_transaction_type_enum" */
  ledgerTransactionTypeEnum: Array<LedgerTransactionTypeEnum>;
  /** fetch aggregated fields from the table: "ledger_transaction_type_enum" */
  ledgerTransactionTypeEnumAggregate: LedgerTransactionTypeEnumAggregate;
  /** fetch data from the table: "ledger_transaction_type_enum" using primary key columns */
  ledgerTransactionTypeEnumByPk?: Maybe<LedgerTransactionTypeEnum>;
  /** fetch data from the table: "lightning_session" */
  lightningSession: Array<LightningSession>;
  /** fetch aggregated fields from the table: "lightning_session" */
  lightningSessionAggregate: LightningSessionAggregate;
  /** fetch data from the table: "lightning_session" using primary key columns */
  lightningSessionByPk?: Maybe<LightningSession>;
  /** fetch data from the table: "lightning_session_submissions" */
  lightningSessionSubmissions: Array<LightningSessionSubmissions>;
  /** fetch aggregated fields from the table: "lightning_session_submissions" */
  lightningSessionSubmissionsAggregate: LightningSessionSubmissionsAggregate;
  /** fetch data from the table: "lightning_session_submissions" using primary key columns */
  lightningSessionSubmissionsByPk?: Maybe<LightningSessionSubmissions>;
  /** fetch data from the table: "lightning_session_submissions_status_enum" */
  lightningSessionSubmissionsStatus: Array<LightningSessionSubmissionsStatus>;
  /** fetch aggregated fields from the table: "lightning_session_submissions_status_enum" */
  lightningSessionSubmissionsStatusAggregate: LightningSessionSubmissionsStatusAggregate;
  /** fetch data from the table: "lightning_session_submissions_status_enum" using primary key columns */
  lightningSessionSubmissionsStatusByPk?: Maybe<LightningSessionSubmissionsStatus>;
  /** fetch data from the table: "lightning_session_submissions_users_rel" */
  lightningSessionSubmissionsUsersRel: Array<LightningSessionSubmissionsUsersRel>;
  /** fetch aggregated fields from the table: "lightning_session_submissions_users_rel" */
  lightningSessionSubmissionsUsersRelAggregate: LightningSessionSubmissionsUsersRelAggregate;
  /** fetch data from the table: "lightning_session_submissions_users_rel" using primary key columns */
  lightningSessionSubmissionsUsersRelByPk?: Maybe<LightningSessionSubmissionsUsersRel>;
  /** fetch data from the table: "live_classes" */
  liveClasses: Array<LiveClasses>;
  /** fetch aggregated fields from the table: "live_classes" */
  liveClassesAggregate: LiveClassesAggregate;
  /** fetch data from the table: "manager_session_emails" */
  managerSessionEmails: Array<ManagerSessionEmails>;
  /** fetch aggregated fields from the table: "manager_session_emails" */
  managerSessionEmailsAggregate: ManagerSessionEmailsAggregate;
  /** fetch data from the table: "manager_session_emails" using primary key columns */
  managerSessionEmailsByPk?: Maybe<ManagerSessionEmails>;
  /** fetch data from the table: "my_cohort_learning_status" */
  myCohortLearningStatus: Array<MyCohortLearningStatus>;
  /** fetch aggregated fields from the table: "my_cohort_learning_status" */
  myCohortLearningStatusAggregate: MyCohortLearningStatusAggregate;
  /** fetch data from the table: "my_learner_home_courses" */
  myLearnerHomeCourses: Array<MyLearnerHomeCourses>;
  /** fetch aggregated fields from the table: "my_learner_home_courses" */
  myLearnerHomeCoursesAggregate: MyLearnerHomeCoursesAggregate;
  /** fetch data from the table: "my_learner_home_live_coaching" */
  myLearnerHomeLiveCoaching: Array<MyLearnerHomeLiveCoaching>;
  /** fetch aggregated fields from the table: "my_learner_home_live_coaching" */
  myLearnerHomeLiveCoachingAggregate: MyLearnerHomeLiveCoachingAggregate;
  /** fetch data from the table: "my_lightning_session_cohorts_learning_status" */
  myLightningSessionCohortsLearningStatus: Array<MyLightningSessionCohortsLearningStatus>;
  /** fetch aggregated fields from the table: "my_lightning_session_cohorts_learning_status" */
  myLightningSessionCohortsLearningStatusAggregate: MyLightningSessionCohortsLearningStatusAggregate;
  myTeamFlatTree: Array<Team_Flat_Tree_Node>;
  /** fetch data from the table: "my_traditional_cohorts_learning_status" */
  myTraditionalCohortsLearningStatus: Array<MyTraditionalCohortsLearningStatus>;
  /** fetch aggregated fields from the table: "my_traditional_cohorts_learning_status" */
  myTraditionalCohortsLearningStatusAggregate: MyTraditionalCohortsLearningStatusAggregate;
  my_learner_task_native_query: Array<Learner_Task>;
  /** fetch data from the table: "nonce_access_token" */
  nonceAccessToken: Array<NonceAccessToken>;
  /** fetch aggregated fields from the table: "nonce_access_token" */
  nonceAccessTokenAggregate: NonceAccessTokenAggregate;
  /** fetch data from the table: "nonce_access_token" using primary key columns */
  nonceAccessTokenByPk?: Maybe<NonceAccessToken>;
  /** An array relationship */
  organizationUsers: Array<OrganizationUsers>;
  /** An aggregate relationship */
  organizationUsersAggregate: OrganizationUsersAggregate;
  /** fetch data from the table: "fusionauth.group_members" using primary key columns */
  organizationUsersByPk?: Maybe<OrganizationUsers>;
  /** fetch data from the table: "fusionauth.groups" */
  organizations: Array<Organizations>;
  /** fetch aggregated fields from the table: "fusionauth.groups" */
  organizationsAggregate: OrganizationsAggregate;
  /** fetch data from the table: "fusionauth.groups" using primary key columns */
  organizationsByPk?: Maybe<Organizations>;
  /** fetch data from the table: "organizations_meta" */
  organizationsMeta: Array<OrganizationsMeta>;
  /** fetch aggregated fields from the table: "organizations_meta" */
  organizationsMetaAggregate: OrganizationsMetaAggregate;
  /** fetch data from the table: "organizations_meta" using primary key columns */
  organizationsMetaByPk?: Maybe<OrganizationsMeta>;
  /** An array relationship */
  planSubscriptions: Array<PlanSubscriptions>;
  /** An aggregate relationship */
  planSubscriptionsAggregate: PlanSubscriptionsAggregate;
  /** fetch data from the table: "plan_subscriptions" using primary key columns */
  planSubscriptionsByPk?: Maybe<PlanSubscriptions>;
  /** fetch data from the table: "plans" */
  plans: Array<Plans>;
  /** fetch aggregated fields from the table: "plans" */
  plansAggregate: PlansAggregate;
  /** fetch data from the table: "plans" using primary key columns */
  plansByPk?: Maybe<Plans>;
  /** fetch data from the table: "auxiliary.play_execution" */
  playExecution: Array<PlayExecution>;
  /** fetch aggregated fields from the table: "auxiliary.play_execution" */
  playExecutionAggregate: PlayExecutionAggregate;
  /** fetch data from the table: "auxiliary.play_execution" using primary key columns */
  playExecutionByPk?: Maybe<PlayExecution>;
  /** fetch data from the table: "program_actions_taken" */
  programActionsTaken: Array<ProgramActionsTaken>;
  /** fetch aggregated fields from the table: "program_actions_taken" */
  programActionsTakenAggregate: ProgramActionsTakenAggregate;
  /** fetch data from the table: "program_courses_shown" */
  programCoursesShown: Array<ProgramCoursesShown>;
  /** fetch aggregated fields from the table: "program_courses_shown" */
  programCoursesShownAggregate: ProgramCoursesShownAggregate;
  /** fetch data from the table: "program_courses_shown" using primary key columns */
  programCoursesShownByPk?: Maybe<ProgramCoursesShown>;
  /** fetch data from the table: "program_tags" */
  programTags: Array<ProgramTags>;
  /** fetch aggregated fields from the table: "program_tags" */
  programTagsAggregate: ProgramTagsAggregate;
  /** fetch data from the table: "program_tags" using primary key columns */
  programTagsByPk?: Maybe<ProgramTags>;
  /** fetch data from the table: "program_users_selected" */
  programUsersSelected: Array<ProgramUsersSelected>;
  /** fetch aggregated fields from the table: "program_users_selected" */
  programUsersSelectedAggregate: ProgramUsersSelectedAggregate;
  /** fetch data from the table: "program_users_selected" using primary key columns */
  programUsersSelectedByPk?: Maybe<ProgramUsersSelected>;
  /** fetch data from the table: "programs" */
  programs: Array<Programs>;
  /** fetch aggregated fields from the table: "programs" */
  programsAggregate: ProgramsAggregate;
  /** fetch data from the table: "programs" using primary key columns */
  programsByPk?: Maybe<Programs>;
  /** fetch data from the table: "question_type_enum" */
  questionType: Array<QuestionType>;
  /** fetch aggregated fields from the table: "question_type_enum" */
  questionTypeAggregate: QuestionTypeAggregate;
  /** fetch data from the table: "question_type_enum" using primary key columns */
  questionTypeByPk?: Maybe<QuestionType>;
  /** fetch data from the table: "recording_activity" */
  recordingActivity: Array<RecordingActivity>;
  /** fetch aggregated fields from the table: "recording_activity" */
  recordingActivityAggregate: RecordingActivityAggregate;
  /** fetch data from the table: "recording_activity" using primary key columns */
  recordingActivityByPk?: Maybe<RecordingActivity>;
  /** fetch data from the table: "recordings" */
  recordings: Array<Recordings>;
  /** fetch aggregated fields from the table: "recordings" */
  recordingsAggregate: RecordingsAggregate;
  /** fetch data from the table: "recordings" using primary key columns */
  recordingsByPk?: Maybe<Recordings>;
  /** An array relationship */
  relatedCourses: Array<RelatedCourses>;
  /** An aggregate relationship */
  relatedCoursesAggregate: RelatedCoursesAggregate;
  /** fetch data from the table: "related_courses_rel" using primary key columns */
  relatedCoursesByPk?: Maybe<RelatedCourses>;
  /** fetch data from the table: "report_publish_status_enum" */
  reportPublishStatus: Array<ReportPublishStatus>;
  /** fetch aggregated fields from the table: "report_publish_status_enum" */
  reportPublishStatusAggregate: ReportPublishStatusAggregate;
  /** fetch data from the table: "report_publish_status_enum" using primary key columns */
  reportPublishStatusByPk?: Maybe<ReportPublishStatus>;
  /** fetch data from the table: "report_type_enum" */
  reportType: Array<ReportType>;
  /** fetch aggregated fields from the table: "report_type_enum" */
  reportTypeAggregate: ReportTypeAggregate;
  /** fetch data from the table: "report_type_enum" using primary key columns */
  reportTypeByPk?: Maybe<ReportType>;
  /** fetch data from the table: "reports" */
  reports: Array<Reports>;
  /** fetch aggregated fields from the table: "reports" */
  reportsAggregate: ReportsAggregate;
  /** fetch data from the table: "reports" using primary key columns */
  reportsByPk?: Maybe<Reports>;
  /** fetch data from the table: "resource_type_enum" */
  resourceType: Array<ResourceType>;
  /** fetch aggregated fields from the table: "resource_type_enum" */
  resourceTypeAggregate: ResourceTypeAggregate;
  /** fetch data from the table: "resource_type_enum" using primary key columns */
  resourceTypeByPk?: Maybe<ResourceType>;
  /** fetch data from the table: "resources" */
  resources: Array<Resources>;
  /** fetch aggregated fields from the table: "resources" */
  resourcesAggregate: ResourcesAggregate;
  /** fetch data from the table: "resources" using primary key columns */
  resourcesByPk?: Maybe<Resources>;
  /** fetch data from the table: "schools" */
  schools: Array<Schools>;
  /** fetch aggregated fields from the table: "schools" */
  schoolsAggregate: SchoolsAggregate;
  /** fetch data from the table: "schools" using primary key columns */
  schoolsByPk?: Maybe<Schools>;
  /** fetch data from the table: "seat_type_enum" */
  seatType: Array<SeatType>;
  /** fetch aggregated fields from the table: "seat_type_enum" */
  seatTypeAggregate: SeatTypeAggregate;
  /** fetch data from the table: "seat_type_enum" using primary key columns */
  seatTypeByPk?: Maybe<SeatType>;
  /** fetch data from the table: "seats" */
  seats: Array<Seats>;
  /** fetch aggregated fields from the table: "seats" */
  seatsAggregate: SeatsAggregate;
  /** fetch data from the table: "seats" using primary key columns */
  seatsByPk?: Maybe<Seats>;
  /** An array relationship */
  streamMessages: Array<StreamMessages>;
  /** An aggregate relationship */
  streamMessagesAggregate: StreamMessagesAggregate;
  /** fetch data from the table: "stream_messages" using primary key columns */
  streamMessagesByPk?: Maybe<StreamMessages>;
  /** fetch data from the table: "subordinates" */
  subordinates: Array<Subordinates>;
  /** fetch aggregated fields from the table: "subordinates" */
  subordinatesAggregate: SubordinatesAggregate;
  /** fetch data from the table: "tag_type_scopes_enum" */
  tagTypeScope: Array<TagTypeScope>;
  /** fetch aggregated fields from the table: "tag_type_scopes_enum" */
  tagTypeScopeAggregate: TagTypeScopeAggregate;
  /** fetch data from the table: "tag_type_scopes_enum" using primary key columns */
  tagTypeScopeByPk?: Maybe<TagTypeScope>;
  /** fetch data from the table: "tag_types" */
  tagTypes: Array<TagTypes>;
  /** fetch aggregated fields from the table: "tag_types" */
  tagTypesAggregate: TagTypesAggregate;
  /** fetch data from the table: "tag_types" using primary key columns */
  tagTypesByPk?: Maybe<TagTypes>;
  /** An array relationship */
  tags: Array<Tags>;
  /** An aggregate relationship */
  tagsAggregate: TagsAggregate;
  /** fetch data from the table: "tags" using primary key columns */
  tagsByPk?: Maybe<Tags>;
  /** fetch data from the table: "task_definition" */
  taskDefinition: Array<TaskDefinition>;
  /** fetch aggregated fields from the table: "task_definition" */
  taskDefinitionAggregate: TaskDefinitionAggregate;
  /** fetch data from the table: "task_definition" using primary key columns */
  taskDefinitionByPk?: Maybe<TaskDefinition>;
  /** fetch data from the table: "task_definition_type_enum" */
  taskDefinitionType: Array<TaskDefinitionType>;
  /** fetch aggregated fields from the table: "task_definition_type_enum" */
  taskDefinitionTypeAggregate: TaskDefinitionTypeAggregate;
  /** fetch data from the table: "task_definition_type_enum" using primary key columns */
  taskDefinitionTypeByPk?: Maybe<TaskDefinitionType>;
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasksAggregate: TasksAggregate;
  /** fetch data from the table: "tasks" using primary key columns */
  tasksByPk?: Maybe<Tasks>;
  teamMemberPollSubmissions: Array<TeamMemberPollSubmissions>;
  /** fetch data from the table: "teams" */
  teams: Array<Teams>;
  /** fetch aggregated fields from the table: "teams" */
  teamsAggregate: TeamsAggregate;
  /** fetch data from the table: "teams" using primary key columns */
  teamsByPk?: Maybe<Teams>;
  /** fetch data from the table: "teams_users_rel" */
  teamsUsers: Array<TeamsUsers>;
  /** fetch aggregated fields from the table: "teams_users_rel" */
  teamsUsersAggregate: TeamsUsersAggregate;
  /** fetch data from the table: "teams_users_rel" using primary key columns */
  teamsUsersByPk?: Maybe<TeamsUsers>;
  /** fetch data from the table: "time_elapsed_live_classes" */
  timeElapsedLiveClasses: Array<TimeElapsedLiveClasses>;
  /** fetch aggregated fields from the table: "time_elapsed_live_classes" */
  timeElapsedLiveClassesAggregate: TimeElapsedLiveClassesAggregate;
  /** fetch data from the table: "typeform_submissions" */
  typeformSubmissions: Array<TypeformSubmissions>;
  /** fetch aggregated fields from the table: "typeform_submissions" */
  typeformSubmissionsAggregate: TypeformSubmissionsAggregate;
  /** fetch data from the table: "typeform_submissions" using primary key columns */
  typeformSubmissionsByPk?: Maybe<TypeformSubmissions>;
  /** fetch data from the table: "user_completed_surveys" */
  userCompletedSurveys: Array<UserCompletedSurveys>;
  /** fetch aggregated fields from the table: "user_completed_surveys" */
  userCompletedSurveysAggregate: UserCompletedSurveysAggregate;
  userManagedTeamsFlattenedTree: Array<UserManagedTeamsFlattenedTree>;
  /** fetch data from the table: "user_num_completed_classes" */
  userNumCompletedClasses: Array<UserNumCompletedClasses>;
  /** fetch aggregated fields from the table: "user_num_completed_classes" */
  userNumCompletedClassesAggregate: UserNumCompletedClassesAggregate;
  /** fetch data from the table: "user_session_emails" */
  userSessionEmails: Array<UserSessionEmails>;
  /** fetch aggregated fields from the table: "user_session_emails" */
  userSessionEmailsAggregate: UserSessionEmailsAggregate;
  /** fetch data from the table: "user_session_emails" using primary key columns */
  userSessionEmailsByPk?: Maybe<UserSessionEmails>;
  /** fetch data from the table: "fusionauth.users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "fusionauth.users" */
  usersAggregate: UsersAggregate;
  /** fetch data from the table: "fusionauth.users" using primary key columns */
  usersByPk?: Maybe<Users>;
  /** fetch data from the table: "users_functions_enum" */
  usersFunctions: Array<UsersFunctions>;
  /** fetch aggregated fields from the table: "users_functions_enum" */
  usersFunctionsAggregate: UsersFunctionsAggregate;
  /** fetch data from the table: "users_functions_enum" using primary key columns */
  usersFunctionsByPk?: Maybe<UsersFunctions>;
  /** fetch data from the table: "users_length_in_function_enum" */
  usersLengthInFunction: Array<UsersLengthInFunction>;
  /** fetch aggregated fields from the table: "users_length_in_function_enum" */
  usersLengthInFunctionAggregate: UsersLengthInFunctionAggregate;
  /** fetch data from the table: "users_length_in_function_enum" using primary key columns */
  usersLengthInFunctionByPk?: Maybe<UsersLengthInFunction>;
  /** fetch data from the table: "users_meta" */
  usersMeta: Array<UsersMeta>;
  /** fetch aggregated fields from the table: "users_meta" */
  usersMetaAggregate: UsersMetaAggregate;
  /** fetch data from the table: "users_meta" using primary key columns */
  usersMetaByPk?: Maybe<UsersMeta>;
  /** fetch data from the table: "zoom_oauth_tokens" */
  zoomOauthTokens: Array<ZoomOauthTokens>;
  /** fetch aggregated fields from the table: "zoom_oauth_tokens" */
  zoomOauthTokensAggregate: ZoomOauthTokensAggregate;
  /** fetch data from the table: "zoom_oauth_tokens" using primary key columns */
  zoomOauthTokensByPk?: Maybe<ZoomOauthTokens>;
};

export type Query_RootCheckMyTeamCourseWaitlistArgs = {
  courseSlug: Scalars['String'];
};

export type Query_RootCountTeamSessionsArgs = {
  courseSlug: Scalars['String'];
};

export type Query_RootGetPastSessionAsyncMetricsArgs = {
  cohortId: Scalars['uuid'];
};

export type Query_RootMyTeamMembersArgs = {
  activeOnly?: InputMaybe<Scalars['Boolean']>;
};

export type Query_RootActivityTypeArgs = {
  distinctOn?: InputMaybe<Array<ActivityTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ActivityTypeOrderBy>>;
  where?: InputMaybe<ActivityTypeBoolExp>;
};

export type Query_RootActivityTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<ActivityTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ActivityTypeOrderBy>>;
  where?: InputMaybe<ActivityTypeBoolExp>;
};

export type Query_RootActivityTypeByPkArgs = {
  value: Scalars['String'];
};

export type Query_RootAttemptStatusArgs = {
  distinctOn?: InputMaybe<Array<AttemptStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AttemptStatusOrderBy>>;
  where?: InputMaybe<AttemptStatusBoolExp>;
};

export type Query_RootAttemptStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<AttemptStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AttemptStatusOrderBy>>;
  where?: InputMaybe<AttemptStatusBoolExp>;
};

export type Query_RootAttemptStatusByPkArgs = {
  value: Scalars['String'];
};

export type Query_RootCatalogCoursesArgs = {
  distinctOn?: InputMaybe<Array<CatalogCoursesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CatalogCoursesOrderBy>>;
  where?: InputMaybe<CatalogCoursesBoolExp>;
};

export type Query_RootCatalogCoursesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CatalogCoursesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CatalogCoursesOrderBy>>;
  where?: InputMaybe<CatalogCoursesBoolExp>;
};

export type Query_RootCatalogCoursesByPkArgs = {
  catalogId: Scalars['uuid'];
  courseId: Scalars['uuid'];
};

export type Query_RootCatalogsArgs = {
  distinctOn?: InputMaybe<Array<CatalogsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CatalogsOrderBy>>;
  where?: InputMaybe<CatalogsBoolExp>;
};

export type Query_RootCatalogsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CatalogsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CatalogsOrderBy>>;
  where?: InputMaybe<CatalogsBoolExp>;
};

export type Query_RootCatalogsByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCatalogsJourneysRelArgs = {
  distinctOn?: InputMaybe<Array<CatalogsJourneysRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CatalogsJourneysRelOrderBy>>;
  where?: InputMaybe<CatalogsJourneysRelBoolExp>;
};

export type Query_RootCatalogsJourneysRelAggregateArgs = {
  distinctOn?: InputMaybe<Array<CatalogsJourneysRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CatalogsJourneysRelOrderBy>>;
  where?: InputMaybe<CatalogsJourneysRelBoolExp>;
};

export type Query_RootCatalogsJourneysRelByPkArgs = {
  catalogId: Scalars['uuid'];
  journeyId: Scalars['uuid'];
};

export type Query_RootClassCoachesArgs = {
  distinctOn?: InputMaybe<Array<ClassCoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassCoachesOrderBy>>;
  where?: InputMaybe<ClassCoachesBoolExp>;
};

export type Query_RootClassCoachesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassCoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassCoachesOrderBy>>;
  where?: InputMaybe<ClassCoachesBoolExp>;
};

export type Query_RootClassCoachesByPkArgs = {
  classId: Scalars['uuid'];
  coachId: Scalars['uuid'];
};

export type Query_RootClassEventActivityArgs = {
  distinctOn?: InputMaybe<Array<ClassEventActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventActivityOrderBy>>;
  where?: InputMaybe<ClassEventActivityBoolExp>;
};

export type Query_RootClassEventActivityAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassEventActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventActivityOrderBy>>;
  where?: InputMaybe<ClassEventActivityBoolExp>;
};

export type Query_RootClassEventActivityByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootClassEventAnonRegistrationsArgs = {
  distinctOn?: InputMaybe<Array<ClassEventAnonRegistrationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventAnonRegistrationsOrderBy>>;
  where?: InputMaybe<ClassEventAnonRegistrationsBoolExp>;
};

export type Query_RootClassEventAnonRegistrationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassEventAnonRegistrationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventAnonRegistrationsOrderBy>>;
  where?: InputMaybe<ClassEventAnonRegistrationsBoolExp>;
};

export type Query_RootClassEventAnonRegistrationsByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootClassEventInvitationsArgs = {
  distinctOn?: InputMaybe<Array<ClassEventInvitationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventInvitationsOrderBy>>;
  where?: InputMaybe<ClassEventInvitationsBoolExp>;
};

export type Query_RootClassEventInvitationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassEventInvitationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventInvitationsOrderBy>>;
  where?: InputMaybe<ClassEventInvitationsBoolExp>;
};

export type Query_RootClassEventInvitationsByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootClassEventMeetingParticipantsArgs = {
  distinctOn?: InputMaybe<Array<ClassEventMeetingParticipantsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventMeetingParticipantsOrderBy>>;
  where?: InputMaybe<ClassEventMeetingParticipantsBoolExp>;
};

export type Query_RootClassEventMeetingParticipantsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassEventMeetingParticipantsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventMeetingParticipantsOrderBy>>;
  where?: InputMaybe<ClassEventMeetingParticipantsBoolExp>;
};

export type Query_RootClassEventMeetingParticipantsByPkArgs = {
  participantId: Scalars['String'];
};

export type Query_RootClassEventMeetingSegmentsArgs = {
  distinctOn?: InputMaybe<Array<ClassEventMeetingSegmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventMeetingSegmentsOrderBy>>;
  where?: InputMaybe<ClassEventMeetingSegmentsBoolExp>;
};

export type Query_RootClassEventMeetingSegmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassEventMeetingSegmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventMeetingSegmentsOrderBy>>;
  where?: InputMaybe<ClassEventMeetingSegmentsBoolExp>;
};

export type Query_RootClassEventMeetingSegmentsByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootClassEventMeetingsArgs = {
  distinctOn?: InputMaybe<Array<ClassEventMeetingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventMeetingsOrderBy>>;
  where?: InputMaybe<ClassEventMeetingsBoolExp>;
};

export type Query_RootClassEventMeetingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassEventMeetingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventMeetingsOrderBy>>;
  where?: InputMaybe<ClassEventMeetingsBoolExp>;
};

export type Query_RootClassEventMeetingsByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootClassEventRegistrationsArgs = {
  distinctOn?: InputMaybe<Array<ClassEventRegistrationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventRegistrationsOrderBy>>;
  where?: InputMaybe<ClassEventRegistrationsBoolExp>;
};

export type Query_RootClassEventRegistrationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassEventRegistrationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventRegistrationsOrderBy>>;
  where?: InputMaybe<ClassEventRegistrationsBoolExp>;
};

export type Query_RootClassEventRegistrationsByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootClassEventsArgs = {
  distinctOn?: InputMaybe<Array<ClassEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventsOrderBy>>;
  where?: InputMaybe<ClassEventsBoolExp>;
};

export type Query_RootClassEventsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventsOrderBy>>;
  where?: InputMaybe<ClassEventsBoolExp>;
};

export type Query_RootClassEventsByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootClassPlaybackActivityArgs = {
  distinctOn?: InputMaybe<Array<ClassPlaybackActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassPlaybackActivityOrderBy>>;
  where?: InputMaybe<ClassPlaybackActivityBoolExp>;
};

export type Query_RootClassPlaybackActivityAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassPlaybackActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassPlaybackActivityOrderBy>>;
  where?: InputMaybe<ClassPlaybackActivityBoolExp>;
};

export type Query_RootClassPlaybackActivityByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootClassPlaybackActivityTypeArgs = {
  distinctOn?: InputMaybe<Array<ClassPlaybackActivityTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassPlaybackActivityTypeOrderBy>>;
  where?: InputMaybe<ClassPlaybackActivityTypeBoolExp>;
};

export type Query_RootClassPlaybackActivityTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassPlaybackActivityTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassPlaybackActivityTypeOrderBy>>;
  where?: InputMaybe<ClassPlaybackActivityTypeBoolExp>;
};

export type Query_RootClassPlaybackActivityTypeByPkArgs = {
  value: Scalars['String'];
};

export type Query_RootClassResourcesArgs = {
  distinctOn?: InputMaybe<Array<ClassResourcesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassResourcesOrderBy>>;
  where?: InputMaybe<ClassResourcesBoolExp>;
};

export type Query_RootClassResourcesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassResourcesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassResourcesOrderBy>>;
  where?: InputMaybe<ClassResourcesBoolExp>;
};

export type Query_RootClassResourcesByPkArgs = {
  classId: Scalars['uuid'];
  resourceId: Scalars['uuid'];
};

export type Query_RootClassesArgs = {
  distinctOn?: InputMaybe<Array<ClassesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassesOrderBy>>;
  where?: InputMaybe<ClassesBoolExp>;
};

export type Query_RootClassesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassesOrderBy>>;
  where?: InputMaybe<ClassesBoolExp>;
};

export type Query_RootClassesByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootClassesTagsRelArgs = {
  distinctOn?: InputMaybe<Array<ClassesTagsRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassesTagsRelOrderBy>>;
  where?: InputMaybe<ClassesTagsRelBoolExp>;
};

export type Query_RootClassesTagsRelAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassesTagsRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassesTagsRelOrderBy>>;
  where?: InputMaybe<ClassesTagsRelBoolExp>;
};

export type Query_RootClassesTagsRelByPkArgs = {
  classId: Scalars['uuid'];
  tagId: Scalars['uuid'];
};

export type Query_RootCoachesArgs = {
  distinctOn?: InputMaybe<Array<CoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoachesOrderBy>>;
  where?: InputMaybe<CoachesBoolExp>;
};

export type Query_RootCoachesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoachesOrderBy>>;
  where?: InputMaybe<CoachesBoolExp>;
};

export type Query_RootCoachesByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCohortCoachesArgs = {
  distinctOn?: InputMaybe<Array<CohortCoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortCoachesOrderBy>>;
  where?: InputMaybe<CohortCoachesBoolExp>;
};

export type Query_RootCohortCoachesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CohortCoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortCoachesOrderBy>>;
  where?: InputMaybe<CohortCoachesBoolExp>;
};

export type Query_RootCohortCoachesByPkArgs = {
  coachId: Scalars['uuid'];
  cohortId: Scalars['uuid'];
};

export type Query_RootCohortProgressArgs = {
  distinctOn?: InputMaybe<Array<CohortProgressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortProgressOrderBy>>;
  where?: InputMaybe<CohortProgressBoolExp>;
};

export type Query_RootCohortProgressAggregateArgs = {
  distinctOn?: InputMaybe<Array<CohortProgressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortProgressOrderBy>>;
  where?: InputMaybe<CohortProgressBoolExp>;
};

export type Query_RootCohortTypeArgs = {
  distinctOn?: InputMaybe<Array<CohortTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortTypeOrderBy>>;
  where?: InputMaybe<CohortTypeBoolExp>;
};

export type Query_RootCohortTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<CohortTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortTypeOrderBy>>;
  where?: InputMaybe<CohortTypeBoolExp>;
};

export type Query_RootCohortTypeByPkArgs = {
  value: Scalars['String'];
};

export type Query_RootCohortVisibilityEnumArgs = {
  distinctOn?: InputMaybe<Array<CohortVisibilityEnumSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortVisibilityEnumOrderBy>>;
  where?: InputMaybe<CohortVisibilityEnumBoolExp>;
};

export type Query_RootCohortVisibilityEnumAggregateArgs = {
  distinctOn?: InputMaybe<Array<CohortVisibilityEnumSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortVisibilityEnumOrderBy>>;
  where?: InputMaybe<CohortVisibilityEnumBoolExp>;
};

export type Query_RootCohortVisibilityEnumByPkArgs = {
  value: Scalars['String'];
};

export type Query_RootCohortsArgs = {
  distinctOn?: InputMaybe<Array<CohortsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortsOrderBy>>;
  where?: InputMaybe<CohortsBoolExp>;
};

export type Query_RootCohortsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CohortsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortsOrderBy>>;
  where?: InputMaybe<CohortsBoolExp>;
};

export type Query_RootCohortsByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCohortsResourcesRelArgs = {
  distinctOn?: InputMaybe<Array<CohortsResourcesRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortsResourcesRelOrderBy>>;
  where?: InputMaybe<CohortsResourcesRelBoolExp>;
};

export type Query_RootCohortsResourcesRelAggregateArgs = {
  distinctOn?: InputMaybe<Array<CohortsResourcesRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortsResourcesRelOrderBy>>;
  where?: InputMaybe<CohortsResourcesRelBoolExp>;
};

export type Query_RootCohortsResourcesRelByPkArgs = {
  cohortId: Scalars['uuid'];
  resourceId: Scalars['uuid'];
};

export type Query_RootConfigurationsArgs = {
  distinctOn?: InputMaybe<Array<ConfigurationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConfigurationsOrderBy>>;
  where?: InputMaybe<ConfigurationsBoolExp>;
};

export type Query_RootConfigurationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ConfigurationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConfigurationsOrderBy>>;
  where?: InputMaybe<ConfigurationsBoolExp>;
};

export type Query_RootConfigurationsByPkArgs = {
  key: Scalars['String'];
};

export type Query_RootCourseCertificationsArgs = {
  distinctOn?: InputMaybe<Array<CourseCertificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourseCertificationsOrderBy>>;
  where?: InputMaybe<CourseCertificationsBoolExp>;
};

export type Query_RootCourseCertificationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CourseCertificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourseCertificationsOrderBy>>;
  where?: InputMaybe<CourseCertificationsBoolExp>;
};

export type Query_RootCourseCertificationsByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCourseCoachesArgs = {
  distinctOn?: InputMaybe<Array<CourseCoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourseCoachesOrderBy>>;
  where?: InputMaybe<CourseCoachesBoolExp>;
};

export type Query_RootCourseCoachesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CourseCoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourseCoachesOrderBy>>;
  where?: InputMaybe<CourseCoachesBoolExp>;
};

export type Query_RootCourseCoachesByPkArgs = {
  coachId: Scalars['uuid'];
  courseId: Scalars['uuid'];
};

export type Query_RootCourseEnrollmentStatusArgs = {
  distinctOn?: InputMaybe<Array<CourseEnrollmentStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourseEnrollmentStatusOrderBy>>;
  where?: InputMaybe<CourseEnrollmentStatusBoolExp>;
};

export type Query_RootCourseEnrollmentStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<CourseEnrollmentStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourseEnrollmentStatusOrderBy>>;
  where?: InputMaybe<CourseEnrollmentStatusBoolExp>;
};

export type Query_RootCourseEnrollmentStatusByPkArgs = {
  value: Scalars['String'];
};

export type Query_RootCoursePublishStatusArgs = {
  distinctOn?: InputMaybe<Array<CoursePublishStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursePublishStatusOrderBy>>;
  where?: InputMaybe<CoursePublishStatusBoolExp>;
};

export type Query_RootCoursePublishStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<CoursePublishStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursePublishStatusOrderBy>>;
  where?: InputMaybe<CoursePublishStatusBoolExp>;
};

export type Query_RootCoursePublishStatusByPkArgs = {
  value: Scalars['String'];
};

export type Query_RootCourseTypeArgs = {
  distinctOn?: InputMaybe<Array<CourseTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourseTypeOrderBy>>;
  where?: InputMaybe<CourseTypeBoolExp>;
};

export type Query_RootCourseTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<CourseTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourseTypeOrderBy>>;
  where?: InputMaybe<CourseTypeBoolExp>;
};

export type Query_RootCourseTypeByPkArgs = {
  value: Scalars['String'];
};

export type Query_RootCourseWaitlistArgs = {
  distinctOn?: InputMaybe<Array<CourseWaitlistSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourseWaitlistOrderBy>>;
  where?: InputMaybe<CourseWaitlistBoolExp>;
};

export type Query_RootCourseWaitlistAggregateArgs = {
  distinctOn?: InputMaybe<Array<CourseWaitlistSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourseWaitlistOrderBy>>;
  where?: InputMaybe<CourseWaitlistBoolExp>;
};

export type Query_RootCourseWaitlistByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCourseWaitlistDesiredUrgencyTypeArgs = {
  distinctOn?: InputMaybe<Array<CourseWaitlistDesiredUrgencyTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourseWaitlistDesiredUrgencyTypeOrderBy>>;
  where?: InputMaybe<CourseWaitlistDesiredUrgencyTypeBoolExp>;
};

export type Query_RootCourseWaitlistDesiredUrgencyTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<CourseWaitlistDesiredUrgencyTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourseWaitlistDesiredUrgencyTypeOrderBy>>;
  where?: InputMaybe<CourseWaitlistDesiredUrgencyTypeBoolExp>;
};

export type Query_RootCourseWaitlistDesiredUrgencyTypeByPkArgs = {
  value: Scalars['String'];
};

export type Query_RootCoursesArgs = {
  distinctOn?: InputMaybe<Array<CoursesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesOrderBy>>;
  where?: InputMaybe<CoursesBoolExp>;
};

export type Query_RootCoursesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CoursesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesOrderBy>>;
  where?: InputMaybe<CoursesBoolExp>;
};

export type Query_RootCoursesByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCoursesByPlanArgs = {
  distinctOn?: InputMaybe<Array<CoursesByPlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesByPlanOrderBy>>;
  where?: InputMaybe<CoursesByPlanBoolExp>;
};

export type Query_RootCoursesByPlanAggregateArgs = {
  distinctOn?: InputMaybe<Array<CoursesByPlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesByPlanOrderBy>>;
  where?: InputMaybe<CoursesByPlanBoolExp>;
};

export type Query_RootCoursesResourcesRelArgs = {
  distinctOn?: InputMaybe<Array<CoursesResourcesRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesResourcesRelOrderBy>>;
  where?: InputMaybe<CoursesResourcesRelBoolExp>;
};

export type Query_RootCoursesResourcesRelAggregateArgs = {
  distinctOn?: InputMaybe<Array<CoursesResourcesRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesResourcesRelOrderBy>>;
  where?: InputMaybe<CoursesResourcesRelBoolExp>;
};

export type Query_RootCoursesResourcesRelByPkArgs = {
  courseId: Scalars['uuid'];
  resourceId: Scalars['uuid'];
};

export type Query_RootCoursesTagsRelArgs = {
  distinctOn?: InputMaybe<Array<CoursesTagsRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesTagsRelOrderBy>>;
  where?: InputMaybe<CoursesTagsRelBoolExp>;
};

export type Query_RootCoursesTagsRelAggregateArgs = {
  distinctOn?: InputMaybe<Array<CoursesTagsRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesTagsRelOrderBy>>;
  where?: InputMaybe<CoursesTagsRelBoolExp>;
};

export type Query_RootCoursesTagsRelByPkArgs = {
  courseId: Scalars['uuid'];
  tagId: Scalars['uuid'];
};

export type Query_RootCreditUsageArgs = {
  distinctOn?: InputMaybe<Array<CreditUsage_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CreditUsageOrderBy>>;
  where?: InputMaybe<CreditUsageBoolExpBoolExp>;
};

export type Query_RootCreditUsageByUserArgs = {
  distinctOn?: InputMaybe<Array<CreditUsageByUser_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CreditUsageByUserOrderBy>>;
  where?: InputMaybe<CreditUsageByUserBoolExpBoolExp>;
};

export type Query_RootEmailQueueArgs = {
  distinctOn?: InputMaybe<Array<EmailQueueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EmailQueueOrderBy>>;
  where?: InputMaybe<EmailQueueBoolExp>;
};

export type Query_RootEmailQueueAggregateArgs = {
  distinctOn?: InputMaybe<Array<EmailQueueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EmailQueueOrderBy>>;
  where?: InputMaybe<EmailQueueBoolExp>;
};

export type Query_RootEmailQueueByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootEnrollmentWaitlistsArgs = {
  distinctOn?: InputMaybe<Array<EnrollmentWaitlistsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnrollmentWaitlistsOrderBy>>;
  where?: InputMaybe<EnrollmentWaitlistsBoolExp>;
};

export type Query_RootEnrollmentWaitlistsAggregateArgs = {
  distinctOn?: InputMaybe<Array<EnrollmentWaitlistsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnrollmentWaitlistsOrderBy>>;
  where?: InputMaybe<EnrollmentWaitlistsBoolExp>;
};

export type Query_RootEnrollmentWaitlistsByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootEnrollmentsArgs = {
  distinctOn?: InputMaybe<Array<EnrollmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnrollmentsOrderBy>>;
  where?: InputMaybe<EnrollmentsBoolExp>;
};

export type Query_RootEnrollmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<EnrollmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnrollmentsOrderBy>>;
  where?: InputMaybe<EnrollmentsBoolExp>;
};

export type Query_RootEnrollmentsByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootFusionauthExternalIdentifiersArgs = {
  distinctOn?: InputMaybe<Array<FusionauthExternalIdentifiersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FusionauthExternalIdentifiersOrderBy>>;
  where?: InputMaybe<FusionauthExternalIdentifiersBoolExp>;
};

export type Query_RootFusionauthExternalIdentifiersAggregateArgs = {
  distinctOn?: InputMaybe<Array<FusionauthExternalIdentifiersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FusionauthExternalIdentifiersOrderBy>>;
  where?: InputMaybe<FusionauthExternalIdentifiersBoolExp>;
};

export type Query_RootFusionauthExternalIdentifiersByPkArgs = {
  id: Scalars['String'];
};

export type Query_RootFusionauthUserRegistrationsArgs = {
  distinctOn?: InputMaybe<Array<FusionauthUserRegistrationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FusionauthUserRegistrationsOrderBy>>;
  where?: InputMaybe<FusionauthUserRegistrationsBoolExp>;
};

export type Query_RootFusionauthUserRegistrationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<FusionauthUserRegistrationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FusionauthUserRegistrationsOrderBy>>;
  where?: InputMaybe<FusionauthUserRegistrationsBoolExp>;
};

export type Query_RootFusionauthUserRegistrationsByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootGetAssessmentQuestionsArgs = {
  args: GetAssessmentQuestionsArgs;
  distinctOn?: InputMaybe<Array<ImpactAssessmentQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImpactAssessmentQuestionsOrderBy>>;
  where?: InputMaybe<ImpactAssessmentQuestionsBoolExp>;
};

export type Query_RootGetAssessmentQuestionsAggregateArgs = {
  args: GetAssessmentQuestionsArgs;
  distinctOn?: InputMaybe<Array<ImpactAssessmentQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImpactAssessmentQuestionsOrderBy>>;
  where?: InputMaybe<ImpactAssessmentQuestionsBoolExp>;
};

export type Query_RootGetTeamMembersArgs = {
  args: GetTeamMembers_Arguments;
  distinctOn?: InputMaybe<Array<TeamMember_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamMemberOrderBy>>;
  where?: InputMaybe<TeamMemberBoolExpBoolExp>;
};

export type Query_RootGetTeamMembersNewArgs = {
  distinctOn?: InputMaybe<Array<TeamMemberNew_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamMemberNewOrderBy>>;
  where?: InputMaybe<TeamMemberNewBoolExpBoolExp>;
};

export type Query_RootIdentitiesArgs = {
  distinctOn?: InputMaybe<Array<IdentitiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IdentitiesOrderBy>>;
  where?: InputMaybe<IdentitiesBoolExp>;
};

export type Query_RootIdentitiesAggregateArgs = {
  distinctOn?: InputMaybe<Array<IdentitiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IdentitiesOrderBy>>;
  where?: InputMaybe<IdentitiesBoolExp>;
};

export type Query_RootIdentitiesByPkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootIdentityProviderLinksArgs = {
  distinctOn?: InputMaybe<Array<IdentityProviderLinksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IdentityProviderLinksOrderBy>>;
  where?: InputMaybe<IdentityProviderLinksBoolExp>;
};

export type Query_RootIdentityProviderLinksAggregateArgs = {
  distinctOn?: InputMaybe<Array<IdentityProviderLinksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IdentityProviderLinksOrderBy>>;
  where?: InputMaybe<IdentityProviderLinksBoolExp>;
};

export type Query_RootIdentityProvidersArgs = {
  distinctOn?: InputMaybe<Array<IdentityProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IdentityProvidersOrderBy>>;
  where?: InputMaybe<IdentityProvidersBoolExp>;
};

export type Query_RootIdentityProvidersAggregateArgs = {
  distinctOn?: InputMaybe<Array<IdentityProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IdentityProvidersOrderBy>>;
  where?: InputMaybe<IdentityProvidersBoolExp>;
};

export type Query_RootIdentityProvidersByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootImpactAssessmentAttemptsArgs = {
  distinctOn?: InputMaybe<Array<ImpactAssessmentAttemptsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImpactAssessmentAttemptsOrderBy>>;
  where?: InputMaybe<ImpactAssessmentAttemptsBoolExp>;
};

export type Query_RootImpactAssessmentAttemptsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ImpactAssessmentAttemptsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImpactAssessmentAttemptsOrderBy>>;
  where?: InputMaybe<ImpactAssessmentAttemptsBoolExp>;
};

export type Query_RootImpactAssessmentAttemptsByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootImpactAssessmentQuestionsArgs = {
  distinctOn?: InputMaybe<Array<ImpactAssessmentQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImpactAssessmentQuestionsOrderBy>>;
  where?: InputMaybe<ImpactAssessmentQuestionsBoolExp>;
};

export type Query_RootImpactAssessmentQuestionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ImpactAssessmentQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImpactAssessmentQuestionsOrderBy>>;
  where?: InputMaybe<ImpactAssessmentQuestionsBoolExp>;
};

export type Query_RootImpactAssessmentsArgs = {
  distinctOn?: InputMaybe<Array<ImpactAssessmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImpactAssessmentsOrderBy>>;
  where?: InputMaybe<ImpactAssessmentsBoolExp>;
};

export type Query_RootImpactAssessmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ImpactAssessmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImpactAssessmentsOrderBy>>;
  where?: InputMaybe<ImpactAssessmentsBoolExp>;
};

export type Query_RootImpactAssessmentsByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootJourneyEnrollmentsArgs = {
  distinctOn?: InputMaybe<Array<JourneyEnrollmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<JourneyEnrollmentsOrderBy>>;
  where?: InputMaybe<JourneyEnrollmentsBoolExp>;
};

export type Query_RootJourneyEnrollmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<JourneyEnrollmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<JourneyEnrollmentsOrderBy>>;
  where?: InputMaybe<JourneyEnrollmentsBoolExp>;
};

export type Query_RootJourneyEnrollmentsByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootJourneysArgs = {
  distinctOn?: InputMaybe<Array<JourneysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<JourneysOrderBy>>;
  where?: InputMaybe<JourneysBoolExp>;
};

export type Query_RootJourneysAggregateArgs = {
  distinctOn?: InputMaybe<Array<JourneysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<JourneysOrderBy>>;
  where?: InputMaybe<JourneysBoolExp>;
};

export type Query_RootJourneysByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootLedgerArgs = {
  distinctOn?: InputMaybe<Array<LedgerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LedgerOrderBy>>;
  where?: InputMaybe<LedgerBoolExp>;
};

export type Query_RootLedgerAggregateArgs = {
  distinctOn?: InputMaybe<Array<LedgerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LedgerOrderBy>>;
  where?: InputMaybe<LedgerBoolExp>;
};

export type Query_RootLedgerByPkArgs = {
  id: Scalars['String'];
};

export type Query_RootLedgerProductEnumArgs = {
  distinctOn?: InputMaybe<Array<LedgerProductEnumSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LedgerProductEnumOrderBy>>;
  where?: InputMaybe<LedgerProductEnumBoolExp>;
};

export type Query_RootLedgerProductEnumAggregateArgs = {
  distinctOn?: InputMaybe<Array<LedgerProductEnumSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LedgerProductEnumOrderBy>>;
  where?: InputMaybe<LedgerProductEnumBoolExp>;
};

export type Query_RootLedgerProductEnumByPkArgs = {
  value: Scalars['String'];
};

export type Query_RootLedgerTransactionTypeEnumArgs = {
  distinctOn?: InputMaybe<Array<LedgerTransactionTypeEnumSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LedgerTransactionTypeEnumOrderBy>>;
  where?: InputMaybe<LedgerTransactionTypeEnumBoolExp>;
};

export type Query_RootLedgerTransactionTypeEnumAggregateArgs = {
  distinctOn?: InputMaybe<Array<LedgerTransactionTypeEnumSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LedgerTransactionTypeEnumOrderBy>>;
  where?: InputMaybe<LedgerTransactionTypeEnumBoolExp>;
};

export type Query_RootLedgerTransactionTypeEnumByPkArgs = {
  value: Scalars['String'];
};

export type Query_RootLightningSessionArgs = {
  distinctOn?: InputMaybe<Array<LightningSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LightningSessionOrderBy>>;
  where?: InputMaybe<LightningSessionBoolExp>;
};

export type Query_RootLightningSessionAggregateArgs = {
  distinctOn?: InputMaybe<Array<LightningSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LightningSessionOrderBy>>;
  where?: InputMaybe<LightningSessionBoolExp>;
};

export type Query_RootLightningSessionByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootLightningSessionSubmissionsArgs = {
  distinctOn?: InputMaybe<Array<LightningSessionSubmissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LightningSessionSubmissionsOrderBy>>;
  where?: InputMaybe<LightningSessionSubmissionsBoolExp>;
};

export type Query_RootLightningSessionSubmissionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<LightningSessionSubmissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LightningSessionSubmissionsOrderBy>>;
  where?: InputMaybe<LightningSessionSubmissionsBoolExp>;
};

export type Query_RootLightningSessionSubmissionsByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootLightningSessionSubmissionsStatusArgs = {
  distinctOn?: InputMaybe<Array<LightningSessionSubmissionsStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LightningSessionSubmissionsStatusOrderBy>>;
  where?: InputMaybe<LightningSessionSubmissionsStatusBoolExp>;
};

export type Query_RootLightningSessionSubmissionsStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<LightningSessionSubmissionsStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LightningSessionSubmissionsStatusOrderBy>>;
  where?: InputMaybe<LightningSessionSubmissionsStatusBoolExp>;
};

export type Query_RootLightningSessionSubmissionsStatusByPkArgs = {
  value: Scalars['String'];
};

export type Query_RootLightningSessionSubmissionsUsersRelArgs = {
  distinctOn?: InputMaybe<Array<LightningSessionSubmissionsUsersRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LightningSessionSubmissionsUsersRelOrderBy>>;
  where?: InputMaybe<LightningSessionSubmissionsUsersRelBoolExp>;
};

export type Query_RootLightningSessionSubmissionsUsersRelAggregateArgs = {
  distinctOn?: InputMaybe<Array<LightningSessionSubmissionsUsersRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LightningSessionSubmissionsUsersRelOrderBy>>;
  where?: InputMaybe<LightningSessionSubmissionsUsersRelBoolExp>;
};

export type Query_RootLightningSessionSubmissionsUsersRelByPkArgs = {
  lightningSessionSubmissionId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

export type Query_RootLiveClassesArgs = {
  distinctOn?: InputMaybe<Array<LiveClassesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiveClassesOrderBy>>;
  where?: InputMaybe<LiveClassesBoolExp>;
};

export type Query_RootLiveClassesAggregateArgs = {
  distinctOn?: InputMaybe<Array<LiveClassesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiveClassesOrderBy>>;
  where?: InputMaybe<LiveClassesBoolExp>;
};

export type Query_RootManagerSessionEmailsArgs = {
  distinctOn?: InputMaybe<Array<ManagerSessionEmailsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ManagerSessionEmailsOrderBy>>;
  where?: InputMaybe<ManagerSessionEmailsBoolExp>;
};

export type Query_RootManagerSessionEmailsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ManagerSessionEmailsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ManagerSessionEmailsOrderBy>>;
  where?: InputMaybe<ManagerSessionEmailsBoolExp>;
};

export type Query_RootManagerSessionEmailsByPkArgs = {
  managerCohortId: Scalars['String'];
};

export type Query_RootMyCohortLearningStatusArgs = {
  distinctOn?: InputMaybe<Array<MyCohortLearningStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MyCohortLearningStatusOrderBy>>;
  where?: InputMaybe<MyCohortLearningStatusBoolExp>;
};

export type Query_RootMyCohortLearningStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<MyCohortLearningStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MyCohortLearningStatusOrderBy>>;
  where?: InputMaybe<MyCohortLearningStatusBoolExp>;
};

export type Query_RootMyLearnerHomeCoursesArgs = {
  distinctOn?: InputMaybe<Array<MyLearnerHomeCoursesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MyLearnerHomeCoursesOrderBy>>;
  where?: InputMaybe<MyLearnerHomeCoursesBoolExp>;
};

export type Query_RootMyLearnerHomeCoursesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MyLearnerHomeCoursesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MyLearnerHomeCoursesOrderBy>>;
  where?: InputMaybe<MyLearnerHomeCoursesBoolExp>;
};

export type Query_RootMyLearnerHomeLiveCoachingArgs = {
  distinctOn?: InputMaybe<Array<MyLearnerHomeLiveCoachingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MyLearnerHomeLiveCoachingOrderBy>>;
  where?: InputMaybe<MyLearnerHomeLiveCoachingBoolExp>;
};

export type Query_RootMyLearnerHomeLiveCoachingAggregateArgs = {
  distinctOn?: InputMaybe<Array<MyLearnerHomeLiveCoachingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MyLearnerHomeLiveCoachingOrderBy>>;
  where?: InputMaybe<MyLearnerHomeLiveCoachingBoolExp>;
};

export type Query_RootMyLightningSessionCohortsLearningStatusArgs = {
  distinctOn?: InputMaybe<Array<MyLightningSessionCohortsLearningStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MyLightningSessionCohortsLearningStatusOrderBy>>;
  where?: InputMaybe<MyLightningSessionCohortsLearningStatusBoolExp>;
};

export type Query_RootMyLightningSessionCohortsLearningStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<MyLightningSessionCohortsLearningStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MyLightningSessionCohortsLearningStatusOrderBy>>;
  where?: InputMaybe<MyLightningSessionCohortsLearningStatusBoolExp>;
};

export type Query_RootMyTeamFlatTreeArgs = {
  args: MyTeamFlatTree_Arguments;
  distinctOn?: InputMaybe<Array<Team_Flat_Tree_Node_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Team_Flat_Tree_NodeOrderBy>>;
  where?: InputMaybe<Team_Flat_Tree_NodeBoolExpBoolExp>;
};

export type Query_RootMyTraditionalCohortsLearningStatusArgs = {
  distinctOn?: InputMaybe<Array<MyTraditionalCohortsLearningStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MyTraditionalCohortsLearningStatusOrderBy>>;
  where?: InputMaybe<MyTraditionalCohortsLearningStatusBoolExp>;
};

export type Query_RootMyTraditionalCohortsLearningStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<MyTraditionalCohortsLearningStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MyTraditionalCohortsLearningStatusOrderBy>>;
  where?: InputMaybe<MyTraditionalCohortsLearningStatusBoolExp>;
};

export type Query_RootMy_Learner_Task_Native_QueryArgs = {
  args: My_Learner_Task_Native_Query_Arguments;
  distinctOn?: InputMaybe<Array<Learner_Task_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Learner_TaskOrderBy>>;
  where?: InputMaybe<Learner_TaskBoolExpBoolExp>;
};

export type Query_RootNonceAccessTokenArgs = {
  distinctOn?: InputMaybe<Array<NonceAccessTokenSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NonceAccessTokenOrderBy>>;
  where?: InputMaybe<NonceAccessTokenBoolExp>;
};

export type Query_RootNonceAccessTokenAggregateArgs = {
  distinctOn?: InputMaybe<Array<NonceAccessTokenSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NonceAccessTokenOrderBy>>;
  where?: InputMaybe<NonceAccessTokenBoolExp>;
};

export type Query_RootNonceAccessTokenByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootOrganizationUsersArgs = {
  distinctOn?: InputMaybe<Array<OrganizationUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationUsersOrderBy>>;
  where?: InputMaybe<OrganizationUsersBoolExp>;
};

export type Query_RootOrganizationUsersAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationUsersOrderBy>>;
  where?: InputMaybe<OrganizationUsersBoolExp>;
};

export type Query_RootOrganizationUsersByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootOrganizationsArgs = {
  distinctOn?: InputMaybe<Array<OrganizationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationsOrderBy>>;
  where?: InputMaybe<OrganizationsBoolExp>;
};

export type Query_RootOrganizationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationsOrderBy>>;
  where?: InputMaybe<OrganizationsBoolExp>;
};

export type Query_RootOrganizationsByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootOrganizationsMetaArgs = {
  distinctOn?: InputMaybe<Array<OrganizationsMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationsMetaOrderBy>>;
  where?: InputMaybe<OrganizationsMetaBoolExp>;
};

export type Query_RootOrganizationsMetaAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationsMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationsMetaOrderBy>>;
  where?: InputMaybe<OrganizationsMetaBoolExp>;
};

export type Query_RootOrganizationsMetaByPkArgs = {
  organizationId: Scalars['uuid'];
};

export type Query_RootPlanSubscriptionsArgs = {
  distinctOn?: InputMaybe<Array<PlanSubscriptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlanSubscriptionsOrderBy>>;
  where?: InputMaybe<PlanSubscriptionsBoolExp>;
};

export type Query_RootPlanSubscriptionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<PlanSubscriptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlanSubscriptionsOrderBy>>;
  where?: InputMaybe<PlanSubscriptionsBoolExp>;
};

export type Query_RootPlanSubscriptionsByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPlansArgs = {
  distinctOn?: InputMaybe<Array<PlansSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlansOrderBy>>;
  where?: InputMaybe<PlansBoolExp>;
};

export type Query_RootPlansAggregateArgs = {
  distinctOn?: InputMaybe<Array<PlansSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlansOrderBy>>;
  where?: InputMaybe<PlansBoolExp>;
};

export type Query_RootPlansByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPlayExecutionArgs = {
  distinctOn?: InputMaybe<Array<PlayExecutionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlayExecutionOrderBy>>;
  where?: InputMaybe<PlayExecutionBoolExp>;
};

export type Query_RootPlayExecutionAggregateArgs = {
  distinctOn?: InputMaybe<Array<PlayExecutionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlayExecutionOrderBy>>;
  where?: InputMaybe<PlayExecutionBoolExp>;
};

export type Query_RootPlayExecutionByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProgramActionsTakenArgs = {
  distinctOn?: InputMaybe<Array<ProgramActionsTakenSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramActionsTakenOrderBy>>;
  where?: InputMaybe<ProgramActionsTakenBoolExp>;
};

export type Query_RootProgramActionsTakenAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProgramActionsTakenSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramActionsTakenOrderBy>>;
  where?: InputMaybe<ProgramActionsTakenBoolExp>;
};

export type Query_RootProgramCoursesShownArgs = {
  distinctOn?: InputMaybe<Array<ProgramCoursesShownSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramCoursesShownOrderBy>>;
  where?: InputMaybe<ProgramCoursesShownBoolExp>;
};

export type Query_RootProgramCoursesShownAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProgramCoursesShownSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramCoursesShownOrderBy>>;
  where?: InputMaybe<ProgramCoursesShownBoolExp>;
};

export type Query_RootProgramCoursesShownByPkArgs = {
  courseId: Scalars['uuid'];
  programId: Scalars['uuid'];
};

export type Query_RootProgramTagsArgs = {
  distinctOn?: InputMaybe<Array<ProgramTagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramTagsOrderBy>>;
  where?: InputMaybe<ProgramTagsBoolExp>;
};

export type Query_RootProgramTagsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProgramTagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramTagsOrderBy>>;
  where?: InputMaybe<ProgramTagsBoolExp>;
};

export type Query_RootProgramTagsByPkArgs = {
  programId: Scalars['uuid'];
  tagId: Scalars['uuid'];
};

export type Query_RootProgramUsersSelectedArgs = {
  distinctOn?: InputMaybe<Array<ProgramUsersSelectedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramUsersSelectedOrderBy>>;
  where?: InputMaybe<ProgramUsersSelectedBoolExp>;
};

export type Query_RootProgramUsersSelectedAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProgramUsersSelectedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramUsersSelectedOrderBy>>;
  where?: InputMaybe<ProgramUsersSelectedBoolExp>;
};

export type Query_RootProgramUsersSelectedByPkArgs = {
  programId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

export type Query_RootProgramsArgs = {
  distinctOn?: InputMaybe<Array<ProgramsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramsOrderBy>>;
  where?: InputMaybe<ProgramsBoolExp>;
};

export type Query_RootProgramsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProgramsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramsOrderBy>>;
  where?: InputMaybe<ProgramsBoolExp>;
};

export type Query_RootProgramsByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootQuestionTypeArgs = {
  distinctOn?: InputMaybe<Array<QuestionTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<QuestionTypeOrderBy>>;
  where?: InputMaybe<QuestionTypeBoolExp>;
};

export type Query_RootQuestionTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<QuestionTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<QuestionTypeOrderBy>>;
  where?: InputMaybe<QuestionTypeBoolExp>;
};

export type Query_RootQuestionTypeByPkArgs = {
  value: Scalars['String'];
};

export type Query_RootRecordingActivityArgs = {
  distinctOn?: InputMaybe<Array<RecordingActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecordingActivityOrderBy>>;
  where?: InputMaybe<RecordingActivityBoolExp>;
};

export type Query_RootRecordingActivityAggregateArgs = {
  distinctOn?: InputMaybe<Array<RecordingActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecordingActivityOrderBy>>;
  where?: InputMaybe<RecordingActivityBoolExp>;
};

export type Query_RootRecordingActivityByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootRecordingsArgs = {
  distinctOn?: InputMaybe<Array<RecordingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecordingsOrderBy>>;
  where?: InputMaybe<RecordingsBoolExp>;
};

export type Query_RootRecordingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<RecordingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecordingsOrderBy>>;
  where?: InputMaybe<RecordingsBoolExp>;
};

export type Query_RootRecordingsByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootRelatedCoursesArgs = {
  distinctOn?: InputMaybe<Array<RelatedCoursesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RelatedCoursesOrderBy>>;
  where?: InputMaybe<RelatedCoursesBoolExp>;
};

export type Query_RootRelatedCoursesAggregateArgs = {
  distinctOn?: InputMaybe<Array<RelatedCoursesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RelatedCoursesOrderBy>>;
  where?: InputMaybe<RelatedCoursesBoolExp>;
};

export type Query_RootRelatedCoursesByPkArgs = {
  courseId: Scalars['uuid'];
  relatedCourseSlug: Scalars['String'];
};

export type Query_RootReportPublishStatusArgs = {
  distinctOn?: InputMaybe<Array<ReportPublishStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReportPublishStatusOrderBy>>;
  where?: InputMaybe<ReportPublishStatusBoolExp>;
};

export type Query_RootReportPublishStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<ReportPublishStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReportPublishStatusOrderBy>>;
  where?: InputMaybe<ReportPublishStatusBoolExp>;
};

export type Query_RootReportPublishStatusByPkArgs = {
  value: Scalars['String'];
};

export type Query_RootReportTypeArgs = {
  distinctOn?: InputMaybe<Array<ReportTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReportTypeOrderBy>>;
  where?: InputMaybe<ReportTypeBoolExp>;
};

export type Query_RootReportTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<ReportTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReportTypeOrderBy>>;
  where?: InputMaybe<ReportTypeBoolExp>;
};

export type Query_RootReportTypeByPkArgs = {
  value: Scalars['String'];
};

export type Query_RootReportsArgs = {
  distinctOn?: InputMaybe<Array<ReportsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReportsOrderBy>>;
  where?: InputMaybe<ReportsBoolExp>;
};

export type Query_RootReportsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ReportsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReportsOrderBy>>;
  where?: InputMaybe<ReportsBoolExp>;
};

export type Query_RootReportsByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootResourceTypeArgs = {
  distinctOn?: InputMaybe<Array<ResourceTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ResourceTypeOrderBy>>;
  where?: InputMaybe<ResourceTypeBoolExp>;
};

export type Query_RootResourceTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<ResourceTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ResourceTypeOrderBy>>;
  where?: InputMaybe<ResourceTypeBoolExp>;
};

export type Query_RootResourceTypeByPkArgs = {
  value: Scalars['String'];
};

export type Query_RootResourcesArgs = {
  distinctOn?: InputMaybe<Array<ResourcesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ResourcesOrderBy>>;
  where?: InputMaybe<ResourcesBoolExp>;
};

export type Query_RootResourcesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ResourcesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ResourcesOrderBy>>;
  where?: InputMaybe<ResourcesBoolExp>;
};

export type Query_RootResourcesByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSchoolsArgs = {
  distinctOn?: InputMaybe<Array<SchoolsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SchoolsOrderBy>>;
  where?: InputMaybe<SchoolsBoolExp>;
};

export type Query_RootSchoolsAggregateArgs = {
  distinctOn?: InputMaybe<Array<SchoolsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SchoolsOrderBy>>;
  where?: InputMaybe<SchoolsBoolExp>;
};

export type Query_RootSchoolsByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSeatTypeArgs = {
  distinctOn?: InputMaybe<Array<SeatTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SeatTypeOrderBy>>;
  where?: InputMaybe<SeatTypeBoolExp>;
};

export type Query_RootSeatTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<SeatTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SeatTypeOrderBy>>;
  where?: InputMaybe<SeatTypeBoolExp>;
};

export type Query_RootSeatTypeByPkArgs = {
  value: Scalars['String'];
};

export type Query_RootSeatsArgs = {
  distinctOn?: InputMaybe<Array<SeatsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SeatsOrderBy>>;
  where?: InputMaybe<SeatsBoolExp>;
};

export type Query_RootSeatsAggregateArgs = {
  distinctOn?: InputMaybe<Array<SeatsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SeatsOrderBy>>;
  where?: InputMaybe<SeatsBoolExp>;
};

export type Query_RootSeatsByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootStreamMessagesArgs = {
  distinctOn?: InputMaybe<Array<StreamMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StreamMessagesOrderBy>>;
  where?: InputMaybe<StreamMessagesBoolExp>;
};

export type Query_RootStreamMessagesAggregateArgs = {
  distinctOn?: InputMaybe<Array<StreamMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StreamMessagesOrderBy>>;
  where?: InputMaybe<StreamMessagesBoolExp>;
};

export type Query_RootStreamMessagesByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSubordinatesArgs = {
  distinctOn?: InputMaybe<Array<SubordinatesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SubordinatesOrderBy>>;
  where?: InputMaybe<SubordinatesBoolExp>;
};

export type Query_RootSubordinatesAggregateArgs = {
  distinctOn?: InputMaybe<Array<SubordinatesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SubordinatesOrderBy>>;
  where?: InputMaybe<SubordinatesBoolExp>;
};

export type Query_RootTagTypeScopeArgs = {
  distinctOn?: InputMaybe<Array<TagTypeScopeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TagTypeScopeOrderBy>>;
  where?: InputMaybe<TagTypeScopeBoolExp>;
};

export type Query_RootTagTypeScopeAggregateArgs = {
  distinctOn?: InputMaybe<Array<TagTypeScopeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TagTypeScopeOrderBy>>;
  where?: InputMaybe<TagTypeScopeBoolExp>;
};

export type Query_RootTagTypeScopeByPkArgs = {
  value: Scalars['String'];
};

export type Query_RootTagTypesArgs = {
  distinctOn?: InputMaybe<Array<TagTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TagTypesOrderBy>>;
  where?: InputMaybe<TagTypesBoolExp>;
};

export type Query_RootTagTypesAggregateArgs = {
  distinctOn?: InputMaybe<Array<TagTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TagTypesOrderBy>>;
  where?: InputMaybe<TagTypesBoolExp>;
};

export type Query_RootTagTypesByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTagsArgs = {
  distinctOn?: InputMaybe<Array<TagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TagsOrderBy>>;
  where?: InputMaybe<TagsBoolExp>;
};

export type Query_RootTagsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TagsOrderBy>>;
  where?: InputMaybe<TagsBoolExp>;
};

export type Query_RootTagsByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTaskDefinitionArgs = {
  distinctOn?: InputMaybe<Array<TaskDefinitionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TaskDefinitionOrderBy>>;
  where?: InputMaybe<TaskDefinitionBoolExp>;
};

export type Query_RootTaskDefinitionAggregateArgs = {
  distinctOn?: InputMaybe<Array<TaskDefinitionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TaskDefinitionOrderBy>>;
  where?: InputMaybe<TaskDefinitionBoolExp>;
};

export type Query_RootTaskDefinitionByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTaskDefinitionTypeArgs = {
  distinctOn?: InputMaybe<Array<TaskDefinitionTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TaskDefinitionTypeOrderBy>>;
  where?: InputMaybe<TaskDefinitionTypeBoolExp>;
};

export type Query_RootTaskDefinitionTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<TaskDefinitionTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TaskDefinitionTypeOrderBy>>;
  where?: InputMaybe<TaskDefinitionTypeBoolExp>;
};

export type Query_RootTaskDefinitionTypeByPkArgs = {
  value: Scalars['String'];
};

export type Query_RootTasksArgs = {
  distinctOn?: InputMaybe<Array<TasksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
  where?: InputMaybe<TasksBoolExp>;
};

export type Query_RootTasksAggregateArgs = {
  distinctOn?: InputMaybe<Array<TasksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
  where?: InputMaybe<TasksBoolExp>;
};

export type Query_RootTasksByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTeamMemberPollSubmissionsArgs = {
  distinctOn?: InputMaybe<Array<TeamMemberPollSubmissions_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamMemberPollSubmissionsOrderBy>>;
  where?: InputMaybe<TeamMemberPollSubmissionsBoolExpBoolExp>;
};

export type Query_RootTeamsArgs = {
  distinctOn?: InputMaybe<Array<TeamsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
  where?: InputMaybe<TeamsBoolExp>;
};

export type Query_RootTeamsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
  where?: InputMaybe<TeamsBoolExp>;
};

export type Query_RootTeamsByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTeamsUsersArgs = {
  distinctOn?: InputMaybe<Array<TeamsUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamsUsersOrderBy>>;
  where?: InputMaybe<TeamsUsersBoolExp>;
};

export type Query_RootTeamsUsersAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamsUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamsUsersOrderBy>>;
  where?: InputMaybe<TeamsUsersBoolExp>;
};

export type Query_RootTeamsUsersByPkArgs = {
  teamId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

export type Query_RootTimeElapsedLiveClassesArgs = {
  distinctOn?: InputMaybe<Array<TimeElapsedLiveClassesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TimeElapsedLiveClassesOrderBy>>;
  where?: InputMaybe<TimeElapsedLiveClassesBoolExp>;
};

export type Query_RootTimeElapsedLiveClassesAggregateArgs = {
  distinctOn?: InputMaybe<Array<TimeElapsedLiveClassesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TimeElapsedLiveClassesOrderBy>>;
  where?: InputMaybe<TimeElapsedLiveClassesBoolExp>;
};

export type Query_RootTypeformSubmissionsArgs = {
  distinctOn?: InputMaybe<Array<TypeformSubmissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TypeformSubmissionsOrderBy>>;
  where?: InputMaybe<TypeformSubmissionsBoolExp>;
};

export type Query_RootTypeformSubmissionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TypeformSubmissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TypeformSubmissionsOrderBy>>;
  where?: InputMaybe<TypeformSubmissionsBoolExp>;
};

export type Query_RootTypeformSubmissionsByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUserCompletedSurveysArgs = {
  distinctOn?: InputMaybe<Array<UserCompletedSurveysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserCompletedSurveysOrderBy>>;
  where?: InputMaybe<UserCompletedSurveysBoolExp>;
};

export type Query_RootUserCompletedSurveysAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserCompletedSurveysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserCompletedSurveysOrderBy>>;
  where?: InputMaybe<UserCompletedSurveysBoolExp>;
};

export type Query_RootUserManagedTeamsFlattenedTreeArgs = {
  args: UserManagedTeamsFlattenedTree_Arguments;
  distinctOn?: InputMaybe<Array<UserManagedTeamsFlattenedTree_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserManagedTeamsFlattenedTreeOrderBy>>;
  where?: InputMaybe<UserManagedTeamsFlattenedTreeBoolExpBoolExp>;
};

export type Query_RootUserNumCompletedClassesArgs = {
  distinctOn?: InputMaybe<Array<UserNumCompletedClassesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserNumCompletedClassesOrderBy>>;
  where?: InputMaybe<UserNumCompletedClassesBoolExp>;
};

export type Query_RootUserNumCompletedClassesAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserNumCompletedClassesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserNumCompletedClassesOrderBy>>;
  where?: InputMaybe<UserNumCompletedClassesBoolExp>;
};

export type Query_RootUserSessionEmailsArgs = {
  distinctOn?: InputMaybe<Array<UserSessionEmailsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserSessionEmailsOrderBy>>;
  where?: InputMaybe<UserSessionEmailsBoolExp>;
};

export type Query_RootUserSessionEmailsAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserSessionEmailsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserSessionEmailsOrderBy>>;
  where?: InputMaybe<UserSessionEmailsBoolExp>;
};

export type Query_RootUserSessionEmailsByPkArgs = {
  userClassEventId: Scalars['String'];
};

export type Query_RootUsersArgs = {
  distinctOn?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};

export type Query_RootUsersAggregateArgs = {
  distinctOn?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};

export type Query_RootUsersByPkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUsersFunctionsArgs = {
  distinctOn?: InputMaybe<Array<UsersFunctionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersFunctionsOrderBy>>;
  where?: InputMaybe<UsersFunctionsBoolExp>;
};

export type Query_RootUsersFunctionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<UsersFunctionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersFunctionsOrderBy>>;
  where?: InputMaybe<UsersFunctionsBoolExp>;
};

export type Query_RootUsersFunctionsByPkArgs = {
  value: Scalars['String'];
};

export type Query_RootUsersLengthInFunctionArgs = {
  distinctOn?: InputMaybe<Array<UsersLengthInFunctionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersLengthInFunctionOrderBy>>;
  where?: InputMaybe<UsersLengthInFunctionBoolExp>;
};

export type Query_RootUsersLengthInFunctionAggregateArgs = {
  distinctOn?: InputMaybe<Array<UsersLengthInFunctionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersLengthInFunctionOrderBy>>;
  where?: InputMaybe<UsersLengthInFunctionBoolExp>;
};

export type Query_RootUsersLengthInFunctionByPkArgs = {
  value: Scalars['String'];
};

export type Query_RootUsersMetaArgs = {
  distinctOn?: InputMaybe<Array<UsersMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersMetaOrderBy>>;
  where?: InputMaybe<UsersMetaBoolExp>;
};

export type Query_RootUsersMetaAggregateArgs = {
  distinctOn?: InputMaybe<Array<UsersMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersMetaOrderBy>>;
  where?: InputMaybe<UsersMetaBoolExp>;
};

export type Query_RootUsersMetaByPkArgs = {
  userId: Scalars['uuid'];
};

export type Query_RootZoomOauthTokensArgs = {
  distinctOn?: InputMaybe<Array<ZoomOauthTokensSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ZoomOauthTokensOrderBy>>;
  where?: InputMaybe<ZoomOauthTokensBoolExp>;
};

export type Query_RootZoomOauthTokensAggregateArgs = {
  distinctOn?: InputMaybe<Array<ZoomOauthTokensSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ZoomOauthTokensOrderBy>>;
  where?: InputMaybe<ZoomOauthTokensBoolExp>;
};

export type Query_RootZoomOauthTokensByPkArgs = {
  bearerToken: Scalars['String'];
};

export type StreamMessagesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<StreamMessagesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<StreamMessagesBoolExp>;
  predicate: IntComparisonExp;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table in a streaming manner: "activity_type_enum" */
  ActivityTypeStream: Array<ActivityType>;
  /** fetch data from the table in a streaming manner: "attempt_status_enum" */
  AttemptStatusStream: Array<AttemptStatus>;
  /** fetch data from the table in a streaming manner: "catalogs_courses_rel" */
  CatalogCoursesStream: Array<CatalogCourses>;
  /** fetch data from the table in a streaming manner: "classes_coaches_rel" */
  ClassCoachesStream: Array<ClassCoaches>;
  /** fetch data from the table in a streaming manner: "class_playback_activity_type_enum" */
  ClassPlaybackActivityTypeStream: Array<ClassPlaybackActivityType>;
  /** fetch data from the table in a streaming manner: "classes_resources_rel" */
  ClassResourcesStream: Array<ClassResources>;
  /** fetch data from the table in a streaming manner: "cohorts_coaches_rel" */
  CohortCoachesStream: Array<CohortCoaches>;
  /** fetch data from the table in a streaming manner: "cohort_progress_view" */
  CohortProgressStream: Array<CohortProgress>;
  /** fetch data from the table in a streaming manner: "cohort_type_enum" */
  CohortTypeStream: Array<CohortType>;
  /** fetch data from the table in a streaming manner: "courses_coaches_rel" */
  CourseCoachesStream: Array<CourseCoaches>;
  /** fetch data from the table in a streaming manner: "course_enrollment_status_enum" */
  CourseEnrollmentStatusStream: Array<CourseEnrollmentStatus>;
  /** fetch data from the table in a streaming manner: "course_publish_status_enum" */
  CoursePublishStatusStream: Array<CoursePublishStatus>;
  /** fetch data from the table in a streaming manner: "course_type_enum" */
  CourseTypeStream: Array<CourseType>;
  /** fetch data from the table in a streaming manner: "courses_resources_rel" */
  CoursesResourcesRelStream: Array<CoursesResourcesRel>;
  /** fetch data from the table in a streaming manner: "fusionauth.identities" */
  IdentitiesStream: Array<Identities>;
  /** fetch data from the table in a streaming manner: "fusionauth.identity_provider_links" */
  IdentityProviderLinksStream: Array<IdentityProviderLinks>;
  /** fetch data from the table in a streaming manner: "fusionauth.identity_providers" */
  IdentityProvidersStream: Array<IdentityProviders>;
  /** fetch data from the table in a streaming manner: "fusionauth.group_members" */
  OrganizationUsersStream: Array<OrganizationUsers>;
  /** fetch data from the table in a streaming manner: "fusionauth.groups" */
  OrganizationsStream: Array<Organizations>;
  /** fetch data from the table in a streaming manner: "auxiliary.play_execution" */
  PlayExecutionStream: Array<PlayExecution>;
  /** fetch data from the table in a streaming manner: "question_type_enum" */
  QuestionTypeStream: Array<QuestionType>;
  /** fetch data from the table in a streaming manner: "related_courses_rel" */
  RelatedCoursesStream: Array<RelatedCourses>;
  /** fetch data from the table in a streaming manner: "report_publish_status_enum" */
  ReportPublishStatusStream: Array<ReportPublishStatus>;
  /** fetch data from the table in a streaming manner: "report_type_enum" */
  ReportTypeStream: Array<ReportType>;
  /** fetch data from the table in a streaming manner: "resource_type_enum" */
  ResourceTypeStream: Array<ResourceType>;
  /** fetch data from the table in a streaming manner: "seat_type_enum" */
  SeatTypeStream: Array<SeatType>;
  /** fetch data from the table in a streaming manner: "tag_type_scopes_enum" */
  TagTypeScopeStream: Array<TagTypeScope>;
  /** fetch data from the table in a streaming manner: "task_definition_type_enum" */
  TaskDefinitionTypeStream: Array<TaskDefinitionType>;
  /** fetch data from the table in a streaming manner: "teams_users_rel" */
  TeamsUsersStream: Array<TeamsUsers>;
  /** fetch data from the table in a streaming manner: "users_functions_enum" */
  UsersFunctionsStream: Array<UsersFunctions>;
  /** fetch data from the table in a streaming manner: "users_length_in_function_enum" */
  UsersLengthInFunctionStream: Array<UsersLengthInFunction>;
  /** fetch data from the table in a streaming manner: "fusionauth.users" */
  UsersStream: Array<Users>;
  /** fetch data from the table: "activity_type_enum" */
  activityType: Array<ActivityType>;
  /** fetch aggregated fields from the table: "activity_type_enum" */
  activityTypeAggregate: ActivityTypeAggregate;
  /** fetch data from the table: "activity_type_enum" using primary key columns */
  activityTypeByPk?: Maybe<ActivityType>;
  /** fetch data from the table: "attempt_status_enum" */
  attemptStatus: Array<AttemptStatus>;
  /** fetch aggregated fields from the table: "attempt_status_enum" */
  attemptStatusAggregate: AttemptStatusAggregate;
  /** fetch data from the table: "attempt_status_enum" using primary key columns */
  attemptStatusByPk?: Maybe<AttemptStatus>;
  /** fetch data from the table: "catalogs_courses_rel" */
  catalogCourses: Array<CatalogCourses>;
  /** fetch aggregated fields from the table: "catalogs_courses_rel" */
  catalogCoursesAggregate: CatalogCoursesAggregate;
  /** fetch data from the table: "catalogs_courses_rel" using primary key columns */
  catalogCoursesByPk?: Maybe<CatalogCourses>;
  /** fetch data from the table: "catalogs" */
  catalogs: Array<Catalogs>;
  /** fetch aggregated fields from the table: "catalogs" */
  catalogsAggregate: CatalogsAggregate;
  /** fetch data from the table: "catalogs" using primary key columns */
  catalogsByPk?: Maybe<Catalogs>;
  /** fetch data from the table: "catalogs_journeys_rel" */
  catalogsJourneysRel: Array<CatalogsJourneysRel>;
  /** fetch aggregated fields from the table: "catalogs_journeys_rel" */
  catalogsJourneysRelAggregate: CatalogsJourneysRelAggregate;
  /** fetch data from the table: "catalogs_journeys_rel" using primary key columns */
  catalogsJourneysRelByPk?: Maybe<CatalogsJourneysRel>;
  /** fetch data from the table in a streaming manner: "catalogs_journeys_rel" */
  catalogsJourneysRelStream: Array<CatalogsJourneysRel>;
  /** fetch data from the table in a streaming manner: "catalogs" */
  catalogsStream: Array<Catalogs>;
  /** fetch data from the table: "classes_coaches_rel" */
  classCoaches: Array<ClassCoaches>;
  /** fetch aggregated fields from the table: "classes_coaches_rel" */
  classCoachesAggregate: ClassCoachesAggregate;
  /** fetch data from the table: "classes_coaches_rel" using primary key columns */
  classCoachesByPk?: Maybe<ClassCoaches>;
  /** fetch data from the table: "class_event_activity" */
  classEventActivity: Array<ClassEventActivity>;
  /** fetch aggregated fields from the table: "class_event_activity" */
  classEventActivityAggregate: ClassEventActivityAggregate;
  /** fetch data from the table: "class_event_activity" using primary key columns */
  classEventActivityByPk?: Maybe<ClassEventActivity>;
  /** fetch data from the table in a streaming manner: "class_event_activity" */
  classEventActivityStream: Array<ClassEventActivity>;
  /** fetch data from the table: "class_event_anon_registrations" */
  classEventAnonRegistrations: Array<ClassEventAnonRegistrations>;
  /** fetch aggregated fields from the table: "class_event_anon_registrations" */
  classEventAnonRegistrationsAggregate: ClassEventAnonRegistrationsAggregate;
  /** fetch data from the table: "class_event_anon_registrations" using primary key columns */
  classEventAnonRegistrationsByPk?: Maybe<ClassEventAnonRegistrations>;
  /** fetch data from the table in a streaming manner: "class_event_anon_registrations" */
  classEventAnonRegistrationsStream: Array<ClassEventAnonRegistrations>;
  /** fetch data from the table: "class_event_invitations" */
  classEventInvitations: Array<ClassEventInvitations>;
  /** fetch aggregated fields from the table: "class_event_invitations" */
  classEventInvitationsAggregate: ClassEventInvitationsAggregate;
  /** fetch data from the table: "class_event_invitations" using primary key columns */
  classEventInvitationsByPk?: Maybe<ClassEventInvitations>;
  /** fetch data from the table in a streaming manner: "class_event_invitations" */
  classEventInvitationsStream: Array<ClassEventInvitations>;
  /** An array relationship */
  classEventMeetingParticipants: Array<ClassEventMeetingParticipants>;
  /** An aggregate relationship */
  classEventMeetingParticipantsAggregate: ClassEventMeetingParticipantsAggregate;
  /** fetch data from the table: "class_event_meeting_participants" using primary key columns */
  classEventMeetingParticipantsByPk?: Maybe<ClassEventMeetingParticipants>;
  /** fetch data from the table in a streaming manner: "class_event_meeting_participants" */
  classEventMeetingParticipantsStream: Array<ClassEventMeetingParticipants>;
  /** fetch data from the table: "class_event_meeting_segments" */
  classEventMeetingSegments: Array<ClassEventMeetingSegments>;
  /** fetch aggregated fields from the table: "class_event_meeting_segments" */
  classEventMeetingSegmentsAggregate: ClassEventMeetingSegmentsAggregate;
  /** fetch data from the table: "class_event_meeting_segments" using primary key columns */
  classEventMeetingSegmentsByPk?: Maybe<ClassEventMeetingSegments>;
  /** fetch data from the table in a streaming manner: "class_event_meeting_segments" */
  classEventMeetingSegmentsStream: Array<ClassEventMeetingSegments>;
  /** fetch data from the table: "class_event_meetings" */
  classEventMeetings: Array<ClassEventMeetings>;
  /** fetch aggregated fields from the table: "class_event_meetings" */
  classEventMeetingsAggregate: ClassEventMeetingsAggregate;
  /** fetch data from the table: "class_event_meetings" using primary key columns */
  classEventMeetingsByPk?: Maybe<ClassEventMeetings>;
  /** fetch data from the table in a streaming manner: "class_event_meetings" */
  classEventMeetingsStream: Array<ClassEventMeetings>;
  /** fetch data from the table: "class_event_registrations" */
  classEventRegistrations: Array<ClassEventRegistrations>;
  /** fetch aggregated fields from the table: "class_event_registrations" */
  classEventRegistrationsAggregate: ClassEventRegistrationsAggregate;
  /** fetch data from the table: "class_event_registrations" using primary key columns */
  classEventRegistrationsByPk?: Maybe<ClassEventRegistrations>;
  /** fetch data from the table in a streaming manner: "class_event_registrations" */
  classEventRegistrationsStream: Array<ClassEventRegistrations>;
  /** An array relationship */
  classEvents: Array<ClassEvents>;
  /** An aggregate relationship */
  classEventsAggregate: ClassEventsAggregate;
  /** fetch data from the table: "class_events" using primary key columns */
  classEventsByPk?: Maybe<ClassEvents>;
  /** fetch data from the table in a streaming manner: "class_events" */
  classEventsStream: Array<ClassEvents>;
  /** fetch data from the table: "class_playback_activity" */
  classPlaybackActivity: Array<ClassPlaybackActivity>;
  /** fetch aggregated fields from the table: "class_playback_activity" */
  classPlaybackActivityAggregate: ClassPlaybackActivityAggregate;
  /** fetch data from the table: "class_playback_activity" using primary key columns */
  classPlaybackActivityByPk?: Maybe<ClassPlaybackActivity>;
  /** fetch data from the table in a streaming manner: "class_playback_activity" */
  classPlaybackActivityStream: Array<ClassPlaybackActivity>;
  /** fetch data from the table: "class_playback_activity_type_enum" */
  classPlaybackActivityType: Array<ClassPlaybackActivityType>;
  /** fetch aggregated fields from the table: "class_playback_activity_type_enum" */
  classPlaybackActivityTypeAggregate: ClassPlaybackActivityTypeAggregate;
  /** fetch data from the table: "class_playback_activity_type_enum" using primary key columns */
  classPlaybackActivityTypeByPk?: Maybe<ClassPlaybackActivityType>;
  /** fetch data from the table: "classes_resources_rel" */
  classResources: Array<ClassResources>;
  /** fetch aggregated fields from the table: "classes_resources_rel" */
  classResourcesAggregate: ClassResourcesAggregate;
  /** fetch data from the table: "classes_resources_rel" using primary key columns */
  classResourcesByPk?: Maybe<ClassResources>;
  /** An array relationship */
  classes: Array<Classes>;
  /** An aggregate relationship */
  classesAggregate: ClassesAggregate;
  /** fetch data from the table: "classes" using primary key columns */
  classesByPk?: Maybe<Classes>;
  /** fetch data from the table in a streaming manner: "classes" */
  classesStream: Array<Classes>;
  /** fetch data from the table: "classes_tags_rel" */
  classesTagsRel: Array<ClassesTagsRel>;
  /** fetch aggregated fields from the table: "classes_tags_rel" */
  classesTagsRelAggregate: ClassesTagsRelAggregate;
  /** fetch data from the table: "classes_tags_rel" using primary key columns */
  classesTagsRelByPk?: Maybe<ClassesTagsRel>;
  /** fetch data from the table in a streaming manner: "classes_tags_rel" */
  classesTagsRelStream: Array<ClassesTagsRel>;
  /** An array relationship */
  coaches: Array<Coaches>;
  /** An aggregate relationship */
  coachesAggregate: CoachesAggregate;
  /** fetch data from the table: "coaches" using primary key columns */
  coachesByPk?: Maybe<Coaches>;
  /** fetch data from the table in a streaming manner: "coaches" */
  coachesStream: Array<Coaches>;
  /** fetch data from the table: "cohorts_coaches_rel" */
  cohortCoaches: Array<CohortCoaches>;
  /** fetch aggregated fields from the table: "cohorts_coaches_rel" */
  cohortCoachesAggregate: CohortCoachesAggregate;
  /** fetch data from the table: "cohorts_coaches_rel" using primary key columns */
  cohortCoachesByPk?: Maybe<CohortCoaches>;
  /** fetch data from the table: "cohort_progress_view" */
  cohortProgress: Array<CohortProgress>;
  /** fetch aggregated fields from the table: "cohort_progress_view" */
  cohortProgressAggregate: CohortProgressAggregate;
  /** fetch data from the table: "cohort_type_enum" */
  cohortType: Array<CohortType>;
  /** fetch aggregated fields from the table: "cohort_type_enum" */
  cohortTypeAggregate: CohortTypeAggregate;
  /** fetch data from the table: "cohort_type_enum" using primary key columns */
  cohortTypeByPk?: Maybe<CohortType>;
  /** fetch data from the table: "cohort_visibility_enum" */
  cohortVisibilityEnum: Array<CohortVisibilityEnum>;
  /** fetch aggregated fields from the table: "cohort_visibility_enum" */
  cohortVisibilityEnumAggregate: CohortVisibilityEnumAggregate;
  /** fetch data from the table: "cohort_visibility_enum" using primary key columns */
  cohortVisibilityEnumByPk?: Maybe<CohortVisibilityEnum>;
  /** fetch data from the table in a streaming manner: "cohort_visibility_enum" */
  cohortVisibilityEnumStream: Array<CohortVisibilityEnum>;
  /** An array relationship */
  cohorts: Array<Cohorts>;
  /** An aggregate relationship */
  cohortsAggregate: CohortsAggregate;
  /** fetch data from the table: "cohorts" using primary key columns */
  cohortsByPk?: Maybe<Cohorts>;
  /** fetch data from the table: "cohorts_resources_rel" */
  cohortsResourcesRel: Array<CohortsResourcesRel>;
  /** fetch aggregated fields from the table: "cohorts_resources_rel" */
  cohortsResourcesRelAggregate: CohortsResourcesRelAggregate;
  /** fetch data from the table: "cohorts_resources_rel" using primary key columns */
  cohortsResourcesRelByPk?: Maybe<CohortsResourcesRel>;
  /** fetch data from the table in a streaming manner: "cohorts_resources_rel" */
  cohortsResourcesRelStream: Array<CohortsResourcesRel>;
  /** fetch data from the table in a streaming manner: "cohorts" */
  cohortsStream: Array<Cohorts>;
  /** fetch data from the table: "configurations" */
  configurations: Array<Configurations>;
  /** fetch aggregated fields from the table: "configurations" */
  configurationsAggregate: ConfigurationsAggregate;
  /** fetch data from the table: "configurations" using primary key columns */
  configurationsByPk?: Maybe<Configurations>;
  /** fetch data from the table in a streaming manner: "configurations" */
  configurationsStream: Array<Configurations>;
  /** fetch data from the table: "course_certifications" */
  courseCertifications: Array<CourseCertifications>;
  /** fetch aggregated fields from the table: "course_certifications" */
  courseCertificationsAggregate: CourseCertificationsAggregate;
  /** fetch data from the table: "course_certifications" using primary key columns */
  courseCertificationsByPk?: Maybe<CourseCertifications>;
  /** fetch data from the table in a streaming manner: "course_certifications" */
  courseCertificationsStream: Array<CourseCertifications>;
  /** fetch data from the table: "courses_coaches_rel" */
  courseCoaches: Array<CourseCoaches>;
  /** fetch aggregated fields from the table: "courses_coaches_rel" */
  courseCoachesAggregate: CourseCoachesAggregate;
  /** fetch data from the table: "courses_coaches_rel" using primary key columns */
  courseCoachesByPk?: Maybe<CourseCoaches>;
  /** fetch data from the table: "course_enrollment_status_enum" */
  courseEnrollmentStatus: Array<CourseEnrollmentStatus>;
  /** fetch aggregated fields from the table: "course_enrollment_status_enum" */
  courseEnrollmentStatusAggregate: CourseEnrollmentStatusAggregate;
  /** fetch data from the table: "course_enrollment_status_enum" using primary key columns */
  courseEnrollmentStatusByPk?: Maybe<CourseEnrollmentStatus>;
  /** fetch data from the table: "course_publish_status_enum" */
  coursePublishStatus: Array<CoursePublishStatus>;
  /** fetch aggregated fields from the table: "course_publish_status_enum" */
  coursePublishStatusAggregate: CoursePublishStatusAggregate;
  /** fetch data from the table: "course_publish_status_enum" using primary key columns */
  coursePublishStatusByPk?: Maybe<CoursePublishStatus>;
  /** fetch data from the table: "course_type_enum" */
  courseType: Array<CourseType>;
  /** fetch aggregated fields from the table: "course_type_enum" */
  courseTypeAggregate: CourseTypeAggregate;
  /** fetch data from the table: "course_type_enum" using primary key columns */
  courseTypeByPk?: Maybe<CourseType>;
  /** fetch data from the table: "course_waitlist" */
  courseWaitlist: Array<CourseWaitlist>;
  /** fetch aggregated fields from the table: "course_waitlist" */
  courseWaitlistAggregate: CourseWaitlistAggregate;
  /** fetch data from the table: "course_waitlist" using primary key columns */
  courseWaitlistByPk?: Maybe<CourseWaitlist>;
  /** fetch data from the table: "course_waitlist_desired_urgency_type" */
  courseWaitlistDesiredUrgencyType: Array<CourseWaitlistDesiredUrgencyType>;
  /** fetch aggregated fields from the table: "course_waitlist_desired_urgency_type" */
  courseWaitlistDesiredUrgencyTypeAggregate: CourseWaitlistDesiredUrgencyTypeAggregate;
  /** fetch data from the table: "course_waitlist_desired_urgency_type" using primary key columns */
  courseWaitlistDesiredUrgencyTypeByPk?: Maybe<CourseWaitlistDesiredUrgencyType>;
  /** fetch data from the table in a streaming manner: "course_waitlist_desired_urgency_type" */
  courseWaitlistDesiredUrgencyTypeStream: Array<CourseWaitlistDesiredUrgencyType>;
  /** fetch data from the table in a streaming manner: "course_waitlist" */
  courseWaitlistStream: Array<CourseWaitlist>;
  /** An array relationship */
  courses: Array<Courses>;
  /** An aggregate relationship */
  coursesAggregate: CoursesAggregate;
  /** fetch data from the table: "courses" using primary key columns */
  coursesByPk?: Maybe<Courses>;
  /** fetch data from the table: "courses_by_plan" */
  coursesByPlan: Array<CoursesByPlan>;
  /** fetch aggregated fields from the table: "courses_by_plan" */
  coursesByPlanAggregate: CoursesByPlanAggregate;
  /** fetch data from the table in a streaming manner: "courses_by_plan" */
  coursesByPlanStream: Array<CoursesByPlan>;
  /** fetch data from the table: "courses_resources_rel" */
  coursesResourcesRel: Array<CoursesResourcesRel>;
  /** fetch aggregated fields from the table: "courses_resources_rel" */
  coursesResourcesRelAggregate: CoursesResourcesRelAggregate;
  /** fetch data from the table: "courses_resources_rel" using primary key columns */
  coursesResourcesRelByPk?: Maybe<CoursesResourcesRel>;
  /** fetch data from the table in a streaming manner: "courses" */
  coursesStream: Array<Courses>;
  /** fetch data from the table: "courses_tags_rel" */
  coursesTagsRel: Array<CoursesTagsRel>;
  /** fetch aggregated fields from the table: "courses_tags_rel" */
  coursesTagsRelAggregate: CoursesTagsRelAggregate;
  /** fetch data from the table: "courses_tags_rel" using primary key columns */
  coursesTagsRelByPk?: Maybe<CoursesTagsRel>;
  /** fetch data from the table in a streaming manner: "courses_tags_rel" */
  coursesTagsRelStream: Array<CoursesTagsRel>;
  creditUsage: Array<CreditUsage>;
  creditUsageByUser: Array<CreditUsageByUser>;
  /** fetch data from the table: "email_queue" */
  emailQueue: Array<EmailQueue>;
  /** fetch aggregated fields from the table: "email_queue" */
  emailQueueAggregate: EmailQueueAggregate;
  /** fetch data from the table: "email_queue" using primary key columns */
  emailQueueByPk?: Maybe<EmailQueue>;
  /** fetch data from the table in a streaming manner: "email_queue" */
  emailQueueStream: Array<EmailQueue>;
  /** fetch data from the table: "enrollment_waitlists" */
  enrollmentWaitlists: Array<EnrollmentWaitlists>;
  /** fetch aggregated fields from the table: "enrollment_waitlists" */
  enrollmentWaitlistsAggregate: EnrollmentWaitlistsAggregate;
  /** fetch data from the table: "enrollment_waitlists" using primary key columns */
  enrollmentWaitlistsByPk?: Maybe<EnrollmentWaitlists>;
  /** fetch data from the table in a streaming manner: "enrollment_waitlists" */
  enrollmentWaitlistsStream: Array<EnrollmentWaitlists>;
  /** An array relationship */
  enrollments: Array<Enrollments>;
  /** An aggregate relationship */
  enrollmentsAggregate: EnrollmentsAggregate;
  /** fetch data from the table: "enrollments" using primary key columns */
  enrollmentsByPk?: Maybe<Enrollments>;
  /** fetch data from the table in a streaming manner: "enrollments" */
  enrollmentsStream: Array<Enrollments>;
  /** fetch data from the table: "fusionauth.external_identifiers" */
  fusionauthExternalIdentifiers: Array<FusionauthExternalIdentifiers>;
  /** fetch aggregated fields from the table: "fusionauth.external_identifiers" */
  fusionauthExternalIdentifiersAggregate: FusionauthExternalIdentifiersAggregate;
  /** fetch data from the table: "fusionauth.external_identifiers" using primary key columns */
  fusionauthExternalIdentifiersByPk?: Maybe<FusionauthExternalIdentifiers>;
  /** fetch data from the table in a streaming manner: "fusionauth.external_identifiers" */
  fusionauthExternalIdentifiersStream: Array<FusionauthExternalIdentifiers>;
  /** fetch data from the table: "fusionauth.user_registrations" */
  fusionauthUserRegistrations: Array<FusionauthUserRegistrations>;
  /** fetch aggregated fields from the table: "fusionauth.user_registrations" */
  fusionauthUserRegistrationsAggregate: FusionauthUserRegistrationsAggregate;
  /** fetch data from the table: "fusionauth.user_registrations" using primary key columns */
  fusionauthUserRegistrationsByPk?: Maybe<FusionauthUserRegistrations>;
  /** fetch data from the table in a streaming manner: "fusionauth.user_registrations" */
  fusionauthUserRegistrationsStream: Array<FusionauthUserRegistrations>;
  /** execute function "get_assessment_questions" which returns "impact_assessment_questions" */
  getAssessmentQuestions: Array<ImpactAssessmentQuestions>;
  /** execute function "get_assessment_questions" and query aggregates on result of table type "impact_assessment_questions" */
  getAssessmentQuestionsAggregate: ImpactAssessmentQuestionsAggregate;
  getTeamMembers: Array<TeamMember>;
  getTeamMembersNew: Array<GetTeamMembersNew>;
  /** fetch data from the table: "fusionauth.identities" */
  identities: Array<Identities>;
  /** fetch aggregated fields from the table: "fusionauth.identities" */
  identitiesAggregate: IdentitiesAggregate;
  /** fetch data from the table: "fusionauth.identities" using primary key columns */
  identitiesByPk?: Maybe<Identities>;
  /** An array relationship */
  identityProviderLinks: Array<IdentityProviderLinks>;
  /** An aggregate relationship */
  identityProviderLinksAggregate: IdentityProviderLinksAggregate;
  /** fetch data from the table: "fusionauth.identity_providers" */
  identityProviders: Array<IdentityProviders>;
  /** fetch aggregated fields from the table: "fusionauth.identity_providers" */
  identityProvidersAggregate: IdentityProvidersAggregate;
  /** fetch data from the table: "fusionauth.identity_providers" using primary key columns */
  identityProvidersByPk?: Maybe<IdentityProviders>;
  /** An array relationship */
  impactAssessmentAttempts: Array<ImpactAssessmentAttempts>;
  /** An aggregate relationship */
  impactAssessmentAttemptsAggregate: ImpactAssessmentAttemptsAggregate;
  /** fetch data from the table: "impact_assessment_attempts" using primary key columns */
  impactAssessmentAttemptsByPk?: Maybe<ImpactAssessmentAttempts>;
  /** fetch data from the table in a streaming manner: "impact_assessment_attempts" */
  impactAssessmentAttemptsStream: Array<ImpactAssessmentAttempts>;
  /** fetch data from the table: "impact_assessment_questions" */
  impactAssessmentQuestions: Array<ImpactAssessmentQuestions>;
  /** fetch aggregated fields from the table: "impact_assessment_questions" */
  impactAssessmentQuestionsAggregate: ImpactAssessmentQuestionsAggregate;
  /** fetch data from the table in a streaming manner: "impact_assessment_questions" */
  impactAssessmentQuestionsStream: Array<ImpactAssessmentQuestions>;
  /** fetch data from the table: "impact_assessments" */
  impactAssessments: Array<ImpactAssessments>;
  /** fetch aggregated fields from the table: "impact_assessments" */
  impactAssessmentsAggregate: ImpactAssessmentsAggregate;
  /** fetch data from the table: "impact_assessments" using primary key columns */
  impactAssessmentsByPk?: Maybe<ImpactAssessments>;
  /** fetch data from the table in a streaming manner: "impact_assessments" */
  impactAssessmentsStream: Array<ImpactAssessments>;
  /** fetch data from the table: "journey_enrollments" */
  journeyEnrollments: Array<JourneyEnrollments>;
  /** fetch aggregated fields from the table: "journey_enrollments" */
  journeyEnrollmentsAggregate: JourneyEnrollmentsAggregate;
  /** fetch data from the table: "journey_enrollments" using primary key columns */
  journeyEnrollmentsByPk?: Maybe<JourneyEnrollments>;
  /** fetch data from the table in a streaming manner: "journey_enrollments" */
  journeyEnrollmentsStream: Array<JourneyEnrollments>;
  /** An array relationship */
  journeys: Array<Journeys>;
  /** An aggregate relationship */
  journeysAggregate: JourneysAggregate;
  /** fetch data from the table: "journeys" using primary key columns */
  journeysByPk?: Maybe<Journeys>;
  /** fetch data from the table in a streaming manner: "journeys" */
  journeysStream: Array<Journeys>;
  /** fetch data from the table: "ledger" */
  ledger: Array<Ledger>;
  /** fetch aggregated fields from the table: "ledger" */
  ledgerAggregate: LedgerAggregate;
  /** fetch data from the table: "ledger" using primary key columns */
  ledgerByPk?: Maybe<Ledger>;
  /** fetch data from the table: "ledger_product_enum" */
  ledgerProductEnum: Array<LedgerProductEnum>;
  /** fetch aggregated fields from the table: "ledger_product_enum" */
  ledgerProductEnumAggregate: LedgerProductEnumAggregate;
  /** fetch data from the table: "ledger_product_enum" using primary key columns */
  ledgerProductEnumByPk?: Maybe<LedgerProductEnum>;
  /** fetch data from the table in a streaming manner: "ledger_product_enum" */
  ledgerProductEnumStream: Array<LedgerProductEnum>;
  /** fetch data from the table in a streaming manner: "ledger" */
  ledgerStream: Array<Ledger>;
  /** fetch data from the table: "ledger_transaction_type_enum" */
  ledgerTransactionTypeEnum: Array<LedgerTransactionTypeEnum>;
  /** fetch aggregated fields from the table: "ledger_transaction_type_enum" */
  ledgerTransactionTypeEnumAggregate: LedgerTransactionTypeEnumAggregate;
  /** fetch data from the table: "ledger_transaction_type_enum" using primary key columns */
  ledgerTransactionTypeEnumByPk?: Maybe<LedgerTransactionTypeEnum>;
  /** fetch data from the table in a streaming manner: "ledger_transaction_type_enum" */
  ledgerTransactionTypeEnumStream: Array<LedgerTransactionTypeEnum>;
  /** fetch data from the table: "lightning_session" */
  lightningSession: Array<LightningSession>;
  /** fetch aggregated fields from the table: "lightning_session" */
  lightningSessionAggregate: LightningSessionAggregate;
  /** fetch data from the table: "lightning_session" using primary key columns */
  lightningSessionByPk?: Maybe<LightningSession>;
  /** fetch data from the table in a streaming manner: "lightning_session" */
  lightningSessionStream: Array<LightningSession>;
  /** fetch data from the table: "lightning_session_submissions" */
  lightningSessionSubmissions: Array<LightningSessionSubmissions>;
  /** fetch aggregated fields from the table: "lightning_session_submissions" */
  lightningSessionSubmissionsAggregate: LightningSessionSubmissionsAggregate;
  /** fetch data from the table: "lightning_session_submissions" using primary key columns */
  lightningSessionSubmissionsByPk?: Maybe<LightningSessionSubmissions>;
  /** fetch data from the table: "lightning_session_submissions_status_enum" */
  lightningSessionSubmissionsStatus: Array<LightningSessionSubmissionsStatus>;
  /** fetch aggregated fields from the table: "lightning_session_submissions_status_enum" */
  lightningSessionSubmissionsStatusAggregate: LightningSessionSubmissionsStatusAggregate;
  /** fetch data from the table: "lightning_session_submissions_status_enum" using primary key columns */
  lightningSessionSubmissionsStatusByPk?: Maybe<LightningSessionSubmissionsStatus>;
  /** fetch data from the table in a streaming manner: "lightning_session_submissions_status_enum" */
  lightningSessionSubmissionsStatusStream: Array<LightningSessionSubmissionsStatus>;
  /** fetch data from the table in a streaming manner: "lightning_session_submissions" */
  lightningSessionSubmissionsStream: Array<LightningSessionSubmissions>;
  /** fetch data from the table: "lightning_session_submissions_users_rel" */
  lightningSessionSubmissionsUsersRel: Array<LightningSessionSubmissionsUsersRel>;
  /** fetch aggregated fields from the table: "lightning_session_submissions_users_rel" */
  lightningSessionSubmissionsUsersRelAggregate: LightningSessionSubmissionsUsersRelAggregate;
  /** fetch data from the table: "lightning_session_submissions_users_rel" using primary key columns */
  lightningSessionSubmissionsUsersRelByPk?: Maybe<LightningSessionSubmissionsUsersRel>;
  /** fetch data from the table in a streaming manner: "lightning_session_submissions_users_rel" */
  lightningSessionSubmissionsUsersRelStream: Array<LightningSessionSubmissionsUsersRel>;
  /** fetch data from the table: "live_classes" */
  liveClasses: Array<LiveClasses>;
  /** fetch aggregated fields from the table: "live_classes" */
  liveClassesAggregate: LiveClassesAggregate;
  /** fetch data from the table in a streaming manner: "live_classes" */
  liveClassesStream: Array<LiveClasses>;
  /** fetch data from the table: "manager_session_emails" */
  managerSessionEmails: Array<ManagerSessionEmails>;
  /** fetch aggregated fields from the table: "manager_session_emails" */
  managerSessionEmailsAggregate: ManagerSessionEmailsAggregate;
  /** fetch data from the table: "manager_session_emails" using primary key columns */
  managerSessionEmailsByPk?: Maybe<ManagerSessionEmails>;
  /** fetch data from the table in a streaming manner: "manager_session_emails" */
  managerSessionEmailsStream: Array<ManagerSessionEmails>;
  /** fetch data from the table: "my_cohort_learning_status" */
  myCohortLearningStatus: Array<MyCohortLearningStatus>;
  /** fetch aggregated fields from the table: "my_cohort_learning_status" */
  myCohortLearningStatusAggregate: MyCohortLearningStatusAggregate;
  /** fetch data from the table in a streaming manner: "my_cohort_learning_status" */
  myCohortLearningStatusStream: Array<MyCohortLearningStatus>;
  /** fetch data from the table: "my_learner_home_courses" */
  myLearnerHomeCourses: Array<MyLearnerHomeCourses>;
  /** fetch aggregated fields from the table: "my_learner_home_courses" */
  myLearnerHomeCoursesAggregate: MyLearnerHomeCoursesAggregate;
  /** fetch data from the table in a streaming manner: "my_learner_home_courses" */
  myLearnerHomeCoursesStream: Array<MyLearnerHomeCourses>;
  /** fetch data from the table: "my_learner_home_live_coaching" */
  myLearnerHomeLiveCoaching: Array<MyLearnerHomeLiveCoaching>;
  /** fetch aggregated fields from the table: "my_learner_home_live_coaching" */
  myLearnerHomeLiveCoachingAggregate: MyLearnerHomeLiveCoachingAggregate;
  /** fetch data from the table in a streaming manner: "my_learner_home_live_coaching" */
  myLearnerHomeLiveCoachingStream: Array<MyLearnerHomeLiveCoaching>;
  /** fetch data from the table: "my_lightning_session_cohorts_learning_status" */
  myLightningSessionCohortsLearningStatus: Array<MyLightningSessionCohortsLearningStatus>;
  /** fetch aggregated fields from the table: "my_lightning_session_cohorts_learning_status" */
  myLightningSessionCohortsLearningStatusAggregate: MyLightningSessionCohortsLearningStatusAggregate;
  /** fetch data from the table in a streaming manner: "my_lightning_session_cohorts_learning_status" */
  myLightningSessionCohortsLearningStatusStream: Array<MyLightningSessionCohortsLearningStatus>;
  myTeamFlatTree: Array<Team_Flat_Tree_Node>;
  /** fetch data from the table: "my_traditional_cohorts_learning_status" */
  myTraditionalCohortsLearningStatus: Array<MyTraditionalCohortsLearningStatus>;
  /** fetch aggregated fields from the table: "my_traditional_cohorts_learning_status" */
  myTraditionalCohortsLearningStatusAggregate: MyTraditionalCohortsLearningStatusAggregate;
  /** fetch data from the table in a streaming manner: "my_traditional_cohorts_learning_status" */
  myTraditionalCohortsLearningStatusStream: Array<MyTraditionalCohortsLearningStatus>;
  my_learner_task_native_query: Array<Learner_Task>;
  /** fetch data from the table: "nonce_access_token" */
  nonceAccessToken: Array<NonceAccessToken>;
  /** fetch aggregated fields from the table: "nonce_access_token" */
  nonceAccessTokenAggregate: NonceAccessTokenAggregate;
  /** fetch data from the table: "nonce_access_token" using primary key columns */
  nonceAccessTokenByPk?: Maybe<NonceAccessToken>;
  /** fetch data from the table in a streaming manner: "nonce_access_token" */
  nonceAccessTokenStream: Array<NonceAccessToken>;
  /** An array relationship */
  organizationUsers: Array<OrganizationUsers>;
  /** An aggregate relationship */
  organizationUsersAggregate: OrganizationUsersAggregate;
  /** fetch data from the table: "fusionauth.group_members" using primary key columns */
  organizationUsersByPk?: Maybe<OrganizationUsers>;
  /** fetch data from the table: "fusionauth.groups" */
  organizations: Array<Organizations>;
  /** fetch aggregated fields from the table: "fusionauth.groups" */
  organizationsAggregate: OrganizationsAggregate;
  /** fetch data from the table: "fusionauth.groups" using primary key columns */
  organizationsByPk?: Maybe<Organizations>;
  /** fetch data from the table: "organizations_meta" */
  organizationsMeta: Array<OrganizationsMeta>;
  /** fetch aggregated fields from the table: "organizations_meta" */
  organizationsMetaAggregate: OrganizationsMetaAggregate;
  /** fetch data from the table: "organizations_meta" using primary key columns */
  organizationsMetaByPk?: Maybe<OrganizationsMeta>;
  /** fetch data from the table in a streaming manner: "organizations_meta" */
  organizationsMetaStream: Array<OrganizationsMeta>;
  /** An array relationship */
  planSubscriptions: Array<PlanSubscriptions>;
  /** An aggregate relationship */
  planSubscriptionsAggregate: PlanSubscriptionsAggregate;
  /** fetch data from the table: "plan_subscriptions" using primary key columns */
  planSubscriptionsByPk?: Maybe<PlanSubscriptions>;
  /** fetch data from the table in a streaming manner: "plan_subscriptions" */
  planSubscriptionsStream: Array<PlanSubscriptions>;
  /** fetch data from the table: "plans" */
  plans: Array<Plans>;
  /** fetch aggregated fields from the table: "plans" */
  plansAggregate: PlansAggregate;
  /** fetch data from the table: "plans" using primary key columns */
  plansByPk?: Maybe<Plans>;
  /** fetch data from the table in a streaming manner: "plans" */
  plansStream: Array<Plans>;
  /** fetch data from the table: "auxiliary.play_execution" */
  playExecution: Array<PlayExecution>;
  /** fetch aggregated fields from the table: "auxiliary.play_execution" */
  playExecutionAggregate: PlayExecutionAggregate;
  /** fetch data from the table: "auxiliary.play_execution" using primary key columns */
  playExecutionByPk?: Maybe<PlayExecution>;
  /** fetch data from the table: "program_actions_taken" */
  programActionsTaken: Array<ProgramActionsTaken>;
  /** fetch aggregated fields from the table: "program_actions_taken" */
  programActionsTakenAggregate: ProgramActionsTakenAggregate;
  /** fetch data from the table in a streaming manner: "program_actions_taken" */
  programActionsTakenStream: Array<ProgramActionsTaken>;
  /** fetch data from the table: "program_courses_shown" */
  programCoursesShown: Array<ProgramCoursesShown>;
  /** fetch aggregated fields from the table: "program_courses_shown" */
  programCoursesShownAggregate: ProgramCoursesShownAggregate;
  /** fetch data from the table: "program_courses_shown" using primary key columns */
  programCoursesShownByPk?: Maybe<ProgramCoursesShown>;
  /** fetch data from the table in a streaming manner: "program_courses_shown" */
  programCoursesShownStream: Array<ProgramCoursesShown>;
  /** fetch data from the table: "program_tags" */
  programTags: Array<ProgramTags>;
  /** fetch aggregated fields from the table: "program_tags" */
  programTagsAggregate: ProgramTagsAggregate;
  /** fetch data from the table: "program_tags" using primary key columns */
  programTagsByPk?: Maybe<ProgramTags>;
  /** fetch data from the table in a streaming manner: "program_tags" */
  programTagsStream: Array<ProgramTags>;
  /** fetch data from the table: "program_users_selected" */
  programUsersSelected: Array<ProgramUsersSelected>;
  /** fetch aggregated fields from the table: "program_users_selected" */
  programUsersSelectedAggregate: ProgramUsersSelectedAggregate;
  /** fetch data from the table: "program_users_selected" using primary key columns */
  programUsersSelectedByPk?: Maybe<ProgramUsersSelected>;
  /** fetch data from the table in a streaming manner: "program_users_selected" */
  programUsersSelectedStream: Array<ProgramUsersSelected>;
  /** fetch data from the table: "programs" */
  programs: Array<Programs>;
  /** fetch aggregated fields from the table: "programs" */
  programsAggregate: ProgramsAggregate;
  /** fetch data from the table: "programs" using primary key columns */
  programsByPk?: Maybe<Programs>;
  /** fetch data from the table in a streaming manner: "programs" */
  programsStream: Array<Programs>;
  /** fetch data from the table: "question_type_enum" */
  questionType: Array<QuestionType>;
  /** fetch aggregated fields from the table: "question_type_enum" */
  questionTypeAggregate: QuestionTypeAggregate;
  /** fetch data from the table: "question_type_enum" using primary key columns */
  questionTypeByPk?: Maybe<QuestionType>;
  /** fetch data from the table: "recording_activity" */
  recordingActivity: Array<RecordingActivity>;
  /** fetch aggregated fields from the table: "recording_activity" */
  recordingActivityAggregate: RecordingActivityAggregate;
  /** fetch data from the table: "recording_activity" using primary key columns */
  recordingActivityByPk?: Maybe<RecordingActivity>;
  /** fetch data from the table in a streaming manner: "recording_activity" */
  recordingActivityStream: Array<RecordingActivity>;
  /** fetch data from the table: "recordings" */
  recordings: Array<Recordings>;
  /** fetch aggregated fields from the table: "recordings" */
  recordingsAggregate: RecordingsAggregate;
  /** fetch data from the table: "recordings" using primary key columns */
  recordingsByPk?: Maybe<Recordings>;
  /** fetch data from the table in a streaming manner: "recordings" */
  recordingsStream: Array<Recordings>;
  /** An array relationship */
  relatedCourses: Array<RelatedCourses>;
  /** An aggregate relationship */
  relatedCoursesAggregate: RelatedCoursesAggregate;
  /** fetch data from the table: "related_courses_rel" using primary key columns */
  relatedCoursesByPk?: Maybe<RelatedCourses>;
  /** fetch data from the table: "report_publish_status_enum" */
  reportPublishStatus: Array<ReportPublishStatus>;
  /** fetch aggregated fields from the table: "report_publish_status_enum" */
  reportPublishStatusAggregate: ReportPublishStatusAggregate;
  /** fetch data from the table: "report_publish_status_enum" using primary key columns */
  reportPublishStatusByPk?: Maybe<ReportPublishStatus>;
  /** fetch data from the table: "report_type_enum" */
  reportType: Array<ReportType>;
  /** fetch aggregated fields from the table: "report_type_enum" */
  reportTypeAggregate: ReportTypeAggregate;
  /** fetch data from the table: "report_type_enum" using primary key columns */
  reportTypeByPk?: Maybe<ReportType>;
  /** fetch data from the table: "reports" */
  reports: Array<Reports>;
  /** fetch aggregated fields from the table: "reports" */
  reportsAggregate: ReportsAggregate;
  /** fetch data from the table: "reports" using primary key columns */
  reportsByPk?: Maybe<Reports>;
  /** fetch data from the table in a streaming manner: "reports" */
  reportsStream: Array<Reports>;
  /** fetch data from the table: "resource_type_enum" */
  resourceType: Array<ResourceType>;
  /** fetch aggregated fields from the table: "resource_type_enum" */
  resourceTypeAggregate: ResourceTypeAggregate;
  /** fetch data from the table: "resource_type_enum" using primary key columns */
  resourceTypeByPk?: Maybe<ResourceType>;
  /** fetch data from the table: "resources" */
  resources: Array<Resources>;
  /** fetch aggregated fields from the table: "resources" */
  resourcesAggregate: ResourcesAggregate;
  /** fetch data from the table: "resources" using primary key columns */
  resourcesByPk?: Maybe<Resources>;
  /** fetch data from the table in a streaming manner: "resources" */
  resourcesStream: Array<Resources>;
  /** fetch data from the table: "schools" */
  schools: Array<Schools>;
  /** fetch aggregated fields from the table: "schools" */
  schoolsAggregate: SchoolsAggregate;
  /** fetch data from the table: "schools" using primary key columns */
  schoolsByPk?: Maybe<Schools>;
  /** fetch data from the table in a streaming manner: "schools" */
  schoolsStream: Array<Schools>;
  /** fetch data from the table: "seat_type_enum" */
  seatType: Array<SeatType>;
  /** fetch aggregated fields from the table: "seat_type_enum" */
  seatTypeAggregate: SeatTypeAggregate;
  /** fetch data from the table: "seat_type_enum" using primary key columns */
  seatTypeByPk?: Maybe<SeatType>;
  /** fetch data from the table: "seats" */
  seats: Array<Seats>;
  /** fetch aggregated fields from the table: "seats" */
  seatsAggregate: SeatsAggregate;
  /** fetch data from the table: "seats" using primary key columns */
  seatsByPk?: Maybe<Seats>;
  /** fetch data from the table in a streaming manner: "seats" */
  seatsStream: Array<Seats>;
  /** An array relationship */
  streamMessages: Array<StreamMessages>;
  /** An aggregate relationship */
  streamMessagesAggregate: StreamMessagesAggregate;
  /** fetch data from the table: "stream_messages" using primary key columns */
  streamMessagesByPk?: Maybe<StreamMessages>;
  /** fetch data from the table in a streaming manner: "stream_messages" */
  streamMessagesStream: Array<StreamMessages>;
  /** fetch data from the table: "subordinates" */
  subordinates: Array<Subordinates>;
  /** fetch aggregated fields from the table: "subordinates" */
  subordinatesAggregate: SubordinatesAggregate;
  /** fetch data from the table in a streaming manner: "subordinates" */
  subordinatesStream: Array<Subordinates>;
  /** fetch data from the table: "tag_type_scopes_enum" */
  tagTypeScope: Array<TagTypeScope>;
  /** fetch aggregated fields from the table: "tag_type_scopes_enum" */
  tagTypeScopeAggregate: TagTypeScopeAggregate;
  /** fetch data from the table: "tag_type_scopes_enum" using primary key columns */
  tagTypeScopeByPk?: Maybe<TagTypeScope>;
  /** fetch data from the table: "tag_types" */
  tagTypes: Array<TagTypes>;
  /** fetch aggregated fields from the table: "tag_types" */
  tagTypesAggregate: TagTypesAggregate;
  /** fetch data from the table: "tag_types" using primary key columns */
  tagTypesByPk?: Maybe<TagTypes>;
  /** fetch data from the table in a streaming manner: "tag_types" */
  tagTypesStream: Array<TagTypes>;
  /** An array relationship */
  tags: Array<Tags>;
  /** An aggregate relationship */
  tagsAggregate: TagsAggregate;
  /** fetch data from the table: "tags" using primary key columns */
  tagsByPk?: Maybe<Tags>;
  /** fetch data from the table in a streaming manner: "tags" */
  tagsStream: Array<Tags>;
  /** fetch data from the table: "task_definition" */
  taskDefinition: Array<TaskDefinition>;
  /** fetch aggregated fields from the table: "task_definition" */
  taskDefinitionAggregate: TaskDefinitionAggregate;
  /** fetch data from the table: "task_definition" using primary key columns */
  taskDefinitionByPk?: Maybe<TaskDefinition>;
  /** fetch data from the table in a streaming manner: "task_definition" */
  taskDefinitionStream: Array<TaskDefinition>;
  /** fetch data from the table: "task_definition_type_enum" */
  taskDefinitionType: Array<TaskDefinitionType>;
  /** fetch aggregated fields from the table: "task_definition_type_enum" */
  taskDefinitionTypeAggregate: TaskDefinitionTypeAggregate;
  /** fetch data from the table: "task_definition_type_enum" using primary key columns */
  taskDefinitionTypeByPk?: Maybe<TaskDefinitionType>;
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasksAggregate: TasksAggregate;
  /** fetch data from the table: "tasks" using primary key columns */
  tasksByPk?: Maybe<Tasks>;
  /** fetch data from the table in a streaming manner: "tasks" */
  tasksStream: Array<Tasks>;
  teamMemberPollSubmissions: Array<TeamMemberPollSubmissions>;
  /** fetch data from the table: "teams" */
  teams: Array<Teams>;
  /** fetch aggregated fields from the table: "teams" */
  teamsAggregate: TeamsAggregate;
  /** fetch data from the table: "teams" using primary key columns */
  teamsByPk?: Maybe<Teams>;
  /** fetch data from the table in a streaming manner: "teams" */
  teamsStream: Array<Teams>;
  /** fetch data from the table: "teams_users_rel" */
  teamsUsers: Array<TeamsUsers>;
  /** fetch aggregated fields from the table: "teams_users_rel" */
  teamsUsersAggregate: TeamsUsersAggregate;
  /** fetch data from the table: "teams_users_rel" using primary key columns */
  teamsUsersByPk?: Maybe<TeamsUsers>;
  /** fetch data from the table: "time_elapsed_live_classes" */
  timeElapsedLiveClasses: Array<TimeElapsedLiveClasses>;
  /** fetch aggregated fields from the table: "time_elapsed_live_classes" */
  timeElapsedLiveClassesAggregate: TimeElapsedLiveClassesAggregate;
  /** fetch data from the table in a streaming manner: "time_elapsed_live_classes" */
  timeElapsedLiveClassesStream: Array<TimeElapsedLiveClasses>;
  /** fetch data from the table: "typeform_submissions" */
  typeformSubmissions: Array<TypeformSubmissions>;
  /** fetch aggregated fields from the table: "typeform_submissions" */
  typeformSubmissionsAggregate: TypeformSubmissionsAggregate;
  /** fetch data from the table: "typeform_submissions" using primary key columns */
  typeformSubmissionsByPk?: Maybe<TypeformSubmissions>;
  /** fetch data from the table in a streaming manner: "typeform_submissions" */
  typeformSubmissionsStream: Array<TypeformSubmissions>;
  /** fetch data from the table: "user_completed_surveys" */
  userCompletedSurveys: Array<UserCompletedSurveys>;
  /** fetch aggregated fields from the table: "user_completed_surveys" */
  userCompletedSurveysAggregate: UserCompletedSurveysAggregate;
  /** fetch data from the table in a streaming manner: "user_completed_surveys" */
  userCompletedSurveysStream: Array<UserCompletedSurveys>;
  userManagedTeamsFlattenedTree: Array<UserManagedTeamsFlattenedTree>;
  /** fetch data from the table: "user_num_completed_classes" */
  userNumCompletedClasses: Array<UserNumCompletedClasses>;
  /** fetch aggregated fields from the table: "user_num_completed_classes" */
  userNumCompletedClassesAggregate: UserNumCompletedClassesAggregate;
  /** fetch data from the table in a streaming manner: "user_num_completed_classes" */
  userNumCompletedClassesStream: Array<UserNumCompletedClasses>;
  /** fetch data from the table: "user_session_emails" */
  userSessionEmails: Array<UserSessionEmails>;
  /** fetch aggregated fields from the table: "user_session_emails" */
  userSessionEmailsAggregate: UserSessionEmailsAggregate;
  /** fetch data from the table: "user_session_emails" using primary key columns */
  userSessionEmailsByPk?: Maybe<UserSessionEmails>;
  /** fetch data from the table in a streaming manner: "user_session_emails" */
  userSessionEmailsStream: Array<UserSessionEmails>;
  /** fetch data from the table: "fusionauth.users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "fusionauth.users" */
  usersAggregate: UsersAggregate;
  /** fetch data from the table: "fusionauth.users" using primary key columns */
  usersByPk?: Maybe<Users>;
  /** fetch data from the table: "users_functions_enum" */
  usersFunctions: Array<UsersFunctions>;
  /** fetch aggregated fields from the table: "users_functions_enum" */
  usersFunctionsAggregate: UsersFunctionsAggregate;
  /** fetch data from the table: "users_functions_enum" using primary key columns */
  usersFunctionsByPk?: Maybe<UsersFunctions>;
  /** fetch data from the table: "users_length_in_function_enum" */
  usersLengthInFunction: Array<UsersLengthInFunction>;
  /** fetch aggregated fields from the table: "users_length_in_function_enum" */
  usersLengthInFunctionAggregate: UsersLengthInFunctionAggregate;
  /** fetch data from the table: "users_length_in_function_enum" using primary key columns */
  usersLengthInFunctionByPk?: Maybe<UsersLengthInFunction>;
  /** fetch data from the table: "users_meta" */
  usersMeta: Array<UsersMeta>;
  /** fetch aggregated fields from the table: "users_meta" */
  usersMetaAggregate: UsersMetaAggregate;
  /** fetch data from the table: "users_meta" using primary key columns */
  usersMetaByPk?: Maybe<UsersMeta>;
  /** fetch data from the table in a streaming manner: "users_meta" */
  usersMetaStream: Array<UsersMeta>;
  /** fetch data from the table: "zoom_oauth_tokens" */
  zoomOauthTokens: Array<ZoomOauthTokens>;
  /** fetch aggregated fields from the table: "zoom_oauth_tokens" */
  zoomOauthTokensAggregate: ZoomOauthTokensAggregate;
  /** fetch data from the table: "zoom_oauth_tokens" using primary key columns */
  zoomOauthTokensByPk?: Maybe<ZoomOauthTokens>;
  /** fetch data from the table in a streaming manner: "zoom_oauth_tokens" */
  zoomOauthTokensStream: Array<ZoomOauthTokens>;
};

export type Subscription_RootActivityTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ActivityTypeStreamCursorInput>>;
  where?: InputMaybe<ActivityTypeBoolExp>;
};

export type Subscription_RootAttemptStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<AttemptStatusStreamCursorInput>>;
  where?: InputMaybe<AttemptStatusBoolExp>;
};

export type Subscription_RootCatalogCoursesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CatalogCoursesStreamCursorInput>>;
  where?: InputMaybe<CatalogCoursesBoolExp>;
};

export type Subscription_RootClassCoachesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ClassCoachesStreamCursorInput>>;
  where?: InputMaybe<ClassCoachesBoolExp>;
};

export type Subscription_RootClassPlaybackActivityTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ClassPlaybackActivityTypeStreamCursorInput>>;
  where?: InputMaybe<ClassPlaybackActivityTypeBoolExp>;
};

export type Subscription_RootClassResourcesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ClassResourcesStreamCursorInput>>;
  where?: InputMaybe<ClassResourcesBoolExp>;
};

export type Subscription_RootCohortCoachesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CohortCoachesStreamCursorInput>>;
  where?: InputMaybe<CohortCoachesBoolExp>;
};

export type Subscription_RootCohortProgressStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CohortProgressStreamCursorInput>>;
  where?: InputMaybe<CohortProgressBoolExp>;
};

export type Subscription_RootCohortTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CohortTypeStreamCursorInput>>;
  where?: InputMaybe<CohortTypeBoolExp>;
};

export type Subscription_RootCourseCoachesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CourseCoachesStreamCursorInput>>;
  where?: InputMaybe<CourseCoachesBoolExp>;
};

export type Subscription_RootCourseEnrollmentStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CourseEnrollmentStatusStreamCursorInput>>;
  where?: InputMaybe<CourseEnrollmentStatusBoolExp>;
};

export type Subscription_RootCoursePublishStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CoursePublishStatusStreamCursorInput>>;
  where?: InputMaybe<CoursePublishStatusBoolExp>;
};

export type Subscription_RootCourseTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CourseTypeStreamCursorInput>>;
  where?: InputMaybe<CourseTypeBoolExp>;
};

export type Subscription_RootCoursesResourcesRelStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CoursesResourcesRelStreamCursorInput>>;
  where?: InputMaybe<CoursesResourcesRelBoolExp>;
};

export type Subscription_RootIdentitiesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<IdentitiesStreamCursorInput>>;
  where?: InputMaybe<IdentitiesBoolExp>;
};

export type Subscription_RootIdentityProviderLinksStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<IdentityProviderLinksStreamCursorInput>>;
  where?: InputMaybe<IdentityProviderLinksBoolExp>;
};

export type Subscription_RootIdentityProvidersStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<IdentityProvidersStreamCursorInput>>;
  where?: InputMaybe<IdentityProvidersBoolExp>;
};

export type Subscription_RootOrganizationUsersStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<OrganizationUsersStreamCursorInput>>;
  where?: InputMaybe<OrganizationUsersBoolExp>;
};

export type Subscription_RootOrganizationsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<OrganizationsStreamCursorInput>>;
  where?: InputMaybe<OrganizationsBoolExp>;
};

export type Subscription_RootPlayExecutionStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<PlayExecutionStreamCursorInput>>;
  where?: InputMaybe<PlayExecutionBoolExp>;
};

export type Subscription_RootQuestionTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<QuestionTypeStreamCursorInput>>;
  where?: InputMaybe<QuestionTypeBoolExp>;
};

export type Subscription_RootRelatedCoursesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<RelatedCoursesStreamCursorInput>>;
  where?: InputMaybe<RelatedCoursesBoolExp>;
};

export type Subscription_RootReportPublishStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ReportPublishStatusStreamCursorInput>>;
  where?: InputMaybe<ReportPublishStatusBoolExp>;
};

export type Subscription_RootReportTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ReportTypeStreamCursorInput>>;
  where?: InputMaybe<ReportTypeBoolExp>;
};

export type Subscription_RootResourceTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ResourceTypeStreamCursorInput>>;
  where?: InputMaybe<ResourceTypeBoolExp>;
};

export type Subscription_RootSeatTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<SeatTypeStreamCursorInput>>;
  where?: InputMaybe<SeatTypeBoolExp>;
};

export type Subscription_RootTagTypeScopeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<TagTypeScopeStreamCursorInput>>;
  where?: InputMaybe<TagTypeScopeBoolExp>;
};

export type Subscription_RootTaskDefinitionTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<TaskDefinitionTypeStreamCursorInput>>;
  where?: InputMaybe<TaskDefinitionTypeBoolExp>;
};

export type Subscription_RootTeamsUsersStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<TeamsUsersStreamCursorInput>>;
  where?: InputMaybe<TeamsUsersBoolExp>;
};

export type Subscription_RootUsersFunctionsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<UsersFunctionsStreamCursorInput>>;
  where?: InputMaybe<UsersFunctionsBoolExp>;
};

export type Subscription_RootUsersLengthInFunctionStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<UsersLengthInFunctionStreamCursorInput>>;
  where?: InputMaybe<UsersLengthInFunctionBoolExp>;
};

export type Subscription_RootUsersStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<UsersStreamCursorInput>>;
  where?: InputMaybe<UsersBoolExp>;
};

export type Subscription_RootActivityTypeArgs = {
  distinctOn?: InputMaybe<Array<ActivityTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ActivityTypeOrderBy>>;
  where?: InputMaybe<ActivityTypeBoolExp>;
};

export type Subscription_RootActivityTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<ActivityTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ActivityTypeOrderBy>>;
  where?: InputMaybe<ActivityTypeBoolExp>;
};

export type Subscription_RootActivityTypeByPkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootAttemptStatusArgs = {
  distinctOn?: InputMaybe<Array<AttemptStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AttemptStatusOrderBy>>;
  where?: InputMaybe<AttemptStatusBoolExp>;
};

export type Subscription_RootAttemptStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<AttemptStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AttemptStatusOrderBy>>;
  where?: InputMaybe<AttemptStatusBoolExp>;
};

export type Subscription_RootAttemptStatusByPkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCatalogCoursesArgs = {
  distinctOn?: InputMaybe<Array<CatalogCoursesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CatalogCoursesOrderBy>>;
  where?: InputMaybe<CatalogCoursesBoolExp>;
};

export type Subscription_RootCatalogCoursesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CatalogCoursesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CatalogCoursesOrderBy>>;
  where?: InputMaybe<CatalogCoursesBoolExp>;
};

export type Subscription_RootCatalogCoursesByPkArgs = {
  catalogId: Scalars['uuid'];
  courseId: Scalars['uuid'];
};

export type Subscription_RootCatalogsArgs = {
  distinctOn?: InputMaybe<Array<CatalogsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CatalogsOrderBy>>;
  where?: InputMaybe<CatalogsBoolExp>;
};

export type Subscription_RootCatalogsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CatalogsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CatalogsOrderBy>>;
  where?: InputMaybe<CatalogsBoolExp>;
};

export type Subscription_RootCatalogsByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCatalogsJourneysRelArgs = {
  distinctOn?: InputMaybe<Array<CatalogsJourneysRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CatalogsJourneysRelOrderBy>>;
  where?: InputMaybe<CatalogsJourneysRelBoolExp>;
};

export type Subscription_RootCatalogsJourneysRelAggregateArgs = {
  distinctOn?: InputMaybe<Array<CatalogsJourneysRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CatalogsJourneysRelOrderBy>>;
  where?: InputMaybe<CatalogsJourneysRelBoolExp>;
};

export type Subscription_RootCatalogsJourneysRelByPkArgs = {
  catalogId: Scalars['uuid'];
  journeyId: Scalars['uuid'];
};

export type Subscription_RootCatalogsJourneysRelStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CatalogsJourneysRelStreamCursorInput>>;
  where?: InputMaybe<CatalogsJourneysRelBoolExp>;
};

export type Subscription_RootCatalogsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CatalogsStreamCursorInput>>;
  where?: InputMaybe<CatalogsBoolExp>;
};

export type Subscription_RootClassCoachesArgs = {
  distinctOn?: InputMaybe<Array<ClassCoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassCoachesOrderBy>>;
  where?: InputMaybe<ClassCoachesBoolExp>;
};

export type Subscription_RootClassCoachesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassCoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassCoachesOrderBy>>;
  where?: InputMaybe<ClassCoachesBoolExp>;
};

export type Subscription_RootClassCoachesByPkArgs = {
  classId: Scalars['uuid'];
  coachId: Scalars['uuid'];
};

export type Subscription_RootClassEventActivityArgs = {
  distinctOn?: InputMaybe<Array<ClassEventActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventActivityOrderBy>>;
  where?: InputMaybe<ClassEventActivityBoolExp>;
};

export type Subscription_RootClassEventActivityAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassEventActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventActivityOrderBy>>;
  where?: InputMaybe<ClassEventActivityBoolExp>;
};

export type Subscription_RootClassEventActivityByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootClassEventActivityStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ClassEventActivityStreamCursorInput>>;
  where?: InputMaybe<ClassEventActivityBoolExp>;
};

export type Subscription_RootClassEventAnonRegistrationsArgs = {
  distinctOn?: InputMaybe<Array<ClassEventAnonRegistrationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventAnonRegistrationsOrderBy>>;
  where?: InputMaybe<ClassEventAnonRegistrationsBoolExp>;
};

export type Subscription_RootClassEventAnonRegistrationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassEventAnonRegistrationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventAnonRegistrationsOrderBy>>;
  where?: InputMaybe<ClassEventAnonRegistrationsBoolExp>;
};

export type Subscription_RootClassEventAnonRegistrationsByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootClassEventAnonRegistrationsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ClassEventAnonRegistrationsStreamCursorInput>>;
  where?: InputMaybe<ClassEventAnonRegistrationsBoolExp>;
};

export type Subscription_RootClassEventInvitationsArgs = {
  distinctOn?: InputMaybe<Array<ClassEventInvitationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventInvitationsOrderBy>>;
  where?: InputMaybe<ClassEventInvitationsBoolExp>;
};

export type Subscription_RootClassEventInvitationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassEventInvitationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventInvitationsOrderBy>>;
  where?: InputMaybe<ClassEventInvitationsBoolExp>;
};

export type Subscription_RootClassEventInvitationsByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootClassEventInvitationsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ClassEventInvitationsStreamCursorInput>>;
  where?: InputMaybe<ClassEventInvitationsBoolExp>;
};

export type Subscription_RootClassEventMeetingParticipantsArgs = {
  distinctOn?: InputMaybe<Array<ClassEventMeetingParticipantsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventMeetingParticipantsOrderBy>>;
  where?: InputMaybe<ClassEventMeetingParticipantsBoolExp>;
};

export type Subscription_RootClassEventMeetingParticipantsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassEventMeetingParticipantsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventMeetingParticipantsOrderBy>>;
  where?: InputMaybe<ClassEventMeetingParticipantsBoolExp>;
};

export type Subscription_RootClassEventMeetingParticipantsByPkArgs = {
  participantId: Scalars['String'];
};

export type Subscription_RootClassEventMeetingParticipantsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ClassEventMeetingParticipantsStreamCursorInput>>;
  where?: InputMaybe<ClassEventMeetingParticipantsBoolExp>;
};

export type Subscription_RootClassEventMeetingSegmentsArgs = {
  distinctOn?: InputMaybe<Array<ClassEventMeetingSegmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventMeetingSegmentsOrderBy>>;
  where?: InputMaybe<ClassEventMeetingSegmentsBoolExp>;
};

export type Subscription_RootClassEventMeetingSegmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassEventMeetingSegmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventMeetingSegmentsOrderBy>>;
  where?: InputMaybe<ClassEventMeetingSegmentsBoolExp>;
};

export type Subscription_RootClassEventMeetingSegmentsByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootClassEventMeetingSegmentsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ClassEventMeetingSegmentsStreamCursorInput>>;
  where?: InputMaybe<ClassEventMeetingSegmentsBoolExp>;
};

export type Subscription_RootClassEventMeetingsArgs = {
  distinctOn?: InputMaybe<Array<ClassEventMeetingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventMeetingsOrderBy>>;
  where?: InputMaybe<ClassEventMeetingsBoolExp>;
};

export type Subscription_RootClassEventMeetingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassEventMeetingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventMeetingsOrderBy>>;
  where?: InputMaybe<ClassEventMeetingsBoolExp>;
};

export type Subscription_RootClassEventMeetingsByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootClassEventMeetingsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ClassEventMeetingsStreamCursorInput>>;
  where?: InputMaybe<ClassEventMeetingsBoolExp>;
};

export type Subscription_RootClassEventRegistrationsArgs = {
  distinctOn?: InputMaybe<Array<ClassEventRegistrationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventRegistrationsOrderBy>>;
  where?: InputMaybe<ClassEventRegistrationsBoolExp>;
};

export type Subscription_RootClassEventRegistrationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassEventRegistrationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventRegistrationsOrderBy>>;
  where?: InputMaybe<ClassEventRegistrationsBoolExp>;
};

export type Subscription_RootClassEventRegistrationsByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootClassEventRegistrationsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ClassEventRegistrationsStreamCursorInput>>;
  where?: InputMaybe<ClassEventRegistrationsBoolExp>;
};

export type Subscription_RootClassEventsArgs = {
  distinctOn?: InputMaybe<Array<ClassEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventsOrderBy>>;
  where?: InputMaybe<ClassEventsBoolExp>;
};

export type Subscription_RootClassEventsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassEventsOrderBy>>;
  where?: InputMaybe<ClassEventsBoolExp>;
};

export type Subscription_RootClassEventsByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootClassEventsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ClassEventsStreamCursorInput>>;
  where?: InputMaybe<ClassEventsBoolExp>;
};

export type Subscription_RootClassPlaybackActivityArgs = {
  distinctOn?: InputMaybe<Array<ClassPlaybackActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassPlaybackActivityOrderBy>>;
  where?: InputMaybe<ClassPlaybackActivityBoolExp>;
};

export type Subscription_RootClassPlaybackActivityAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassPlaybackActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassPlaybackActivityOrderBy>>;
  where?: InputMaybe<ClassPlaybackActivityBoolExp>;
};

export type Subscription_RootClassPlaybackActivityByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootClassPlaybackActivityStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ClassPlaybackActivityStreamCursorInput>>;
  where?: InputMaybe<ClassPlaybackActivityBoolExp>;
};

export type Subscription_RootClassPlaybackActivityTypeArgs = {
  distinctOn?: InputMaybe<Array<ClassPlaybackActivityTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassPlaybackActivityTypeOrderBy>>;
  where?: InputMaybe<ClassPlaybackActivityTypeBoolExp>;
};

export type Subscription_RootClassPlaybackActivityTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassPlaybackActivityTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassPlaybackActivityTypeOrderBy>>;
  where?: InputMaybe<ClassPlaybackActivityTypeBoolExp>;
};

export type Subscription_RootClassPlaybackActivityTypeByPkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootClassResourcesArgs = {
  distinctOn?: InputMaybe<Array<ClassResourcesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassResourcesOrderBy>>;
  where?: InputMaybe<ClassResourcesBoolExp>;
};

export type Subscription_RootClassResourcesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassResourcesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassResourcesOrderBy>>;
  where?: InputMaybe<ClassResourcesBoolExp>;
};

export type Subscription_RootClassResourcesByPkArgs = {
  classId: Scalars['uuid'];
  resourceId: Scalars['uuid'];
};

export type Subscription_RootClassesArgs = {
  distinctOn?: InputMaybe<Array<ClassesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassesOrderBy>>;
  where?: InputMaybe<ClassesBoolExp>;
};

export type Subscription_RootClassesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassesOrderBy>>;
  where?: InputMaybe<ClassesBoolExp>;
};

export type Subscription_RootClassesByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootClassesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ClassesStreamCursorInput>>;
  where?: InputMaybe<ClassesBoolExp>;
};

export type Subscription_RootClassesTagsRelArgs = {
  distinctOn?: InputMaybe<Array<ClassesTagsRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassesTagsRelOrderBy>>;
  where?: InputMaybe<ClassesTagsRelBoolExp>;
};

export type Subscription_RootClassesTagsRelAggregateArgs = {
  distinctOn?: InputMaybe<Array<ClassesTagsRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassesTagsRelOrderBy>>;
  where?: InputMaybe<ClassesTagsRelBoolExp>;
};

export type Subscription_RootClassesTagsRelByPkArgs = {
  classId: Scalars['uuid'];
  tagId: Scalars['uuid'];
};

export type Subscription_RootClassesTagsRelStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ClassesTagsRelStreamCursorInput>>;
  where?: InputMaybe<ClassesTagsRelBoolExp>;
};

export type Subscription_RootCoachesArgs = {
  distinctOn?: InputMaybe<Array<CoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoachesOrderBy>>;
  where?: InputMaybe<CoachesBoolExp>;
};

export type Subscription_RootCoachesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoachesOrderBy>>;
  where?: InputMaybe<CoachesBoolExp>;
};

export type Subscription_RootCoachesByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCoachesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CoachesStreamCursorInput>>;
  where?: InputMaybe<CoachesBoolExp>;
};

export type Subscription_RootCohortCoachesArgs = {
  distinctOn?: InputMaybe<Array<CohortCoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortCoachesOrderBy>>;
  where?: InputMaybe<CohortCoachesBoolExp>;
};

export type Subscription_RootCohortCoachesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CohortCoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortCoachesOrderBy>>;
  where?: InputMaybe<CohortCoachesBoolExp>;
};

export type Subscription_RootCohortCoachesByPkArgs = {
  coachId: Scalars['uuid'];
  cohortId: Scalars['uuid'];
};

export type Subscription_RootCohortProgressArgs = {
  distinctOn?: InputMaybe<Array<CohortProgressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortProgressOrderBy>>;
  where?: InputMaybe<CohortProgressBoolExp>;
};

export type Subscription_RootCohortProgressAggregateArgs = {
  distinctOn?: InputMaybe<Array<CohortProgressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortProgressOrderBy>>;
  where?: InputMaybe<CohortProgressBoolExp>;
};

export type Subscription_RootCohortTypeArgs = {
  distinctOn?: InputMaybe<Array<CohortTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortTypeOrderBy>>;
  where?: InputMaybe<CohortTypeBoolExp>;
};

export type Subscription_RootCohortTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<CohortTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortTypeOrderBy>>;
  where?: InputMaybe<CohortTypeBoolExp>;
};

export type Subscription_RootCohortTypeByPkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCohortVisibilityEnumArgs = {
  distinctOn?: InputMaybe<Array<CohortVisibilityEnumSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortVisibilityEnumOrderBy>>;
  where?: InputMaybe<CohortVisibilityEnumBoolExp>;
};

export type Subscription_RootCohortVisibilityEnumAggregateArgs = {
  distinctOn?: InputMaybe<Array<CohortVisibilityEnumSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortVisibilityEnumOrderBy>>;
  where?: InputMaybe<CohortVisibilityEnumBoolExp>;
};

export type Subscription_RootCohortVisibilityEnumByPkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCohortVisibilityEnumStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CohortVisibilityEnumStreamCursorInput>>;
  where?: InputMaybe<CohortVisibilityEnumBoolExp>;
};

export type Subscription_RootCohortsArgs = {
  distinctOn?: InputMaybe<Array<CohortsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortsOrderBy>>;
  where?: InputMaybe<CohortsBoolExp>;
};

export type Subscription_RootCohortsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CohortsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortsOrderBy>>;
  where?: InputMaybe<CohortsBoolExp>;
};

export type Subscription_RootCohortsByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCohortsResourcesRelArgs = {
  distinctOn?: InputMaybe<Array<CohortsResourcesRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortsResourcesRelOrderBy>>;
  where?: InputMaybe<CohortsResourcesRelBoolExp>;
};

export type Subscription_RootCohortsResourcesRelAggregateArgs = {
  distinctOn?: InputMaybe<Array<CohortsResourcesRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CohortsResourcesRelOrderBy>>;
  where?: InputMaybe<CohortsResourcesRelBoolExp>;
};

export type Subscription_RootCohortsResourcesRelByPkArgs = {
  cohortId: Scalars['uuid'];
  resourceId: Scalars['uuid'];
};

export type Subscription_RootCohortsResourcesRelStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CohortsResourcesRelStreamCursorInput>>;
  where?: InputMaybe<CohortsResourcesRelBoolExp>;
};

export type Subscription_RootCohortsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CohortsStreamCursorInput>>;
  where?: InputMaybe<CohortsBoolExp>;
};

export type Subscription_RootConfigurationsArgs = {
  distinctOn?: InputMaybe<Array<ConfigurationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConfigurationsOrderBy>>;
  where?: InputMaybe<ConfigurationsBoolExp>;
};

export type Subscription_RootConfigurationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ConfigurationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConfigurationsOrderBy>>;
  where?: InputMaybe<ConfigurationsBoolExp>;
};

export type Subscription_RootConfigurationsByPkArgs = {
  key: Scalars['String'];
};

export type Subscription_RootConfigurationsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ConfigurationsStreamCursorInput>>;
  where?: InputMaybe<ConfigurationsBoolExp>;
};

export type Subscription_RootCourseCertificationsArgs = {
  distinctOn?: InputMaybe<Array<CourseCertificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourseCertificationsOrderBy>>;
  where?: InputMaybe<CourseCertificationsBoolExp>;
};

export type Subscription_RootCourseCertificationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CourseCertificationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourseCertificationsOrderBy>>;
  where?: InputMaybe<CourseCertificationsBoolExp>;
};

export type Subscription_RootCourseCertificationsByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCourseCertificationsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CourseCertificationsStreamCursorInput>>;
  where?: InputMaybe<CourseCertificationsBoolExp>;
};

export type Subscription_RootCourseCoachesArgs = {
  distinctOn?: InputMaybe<Array<CourseCoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourseCoachesOrderBy>>;
  where?: InputMaybe<CourseCoachesBoolExp>;
};

export type Subscription_RootCourseCoachesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CourseCoachesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourseCoachesOrderBy>>;
  where?: InputMaybe<CourseCoachesBoolExp>;
};

export type Subscription_RootCourseCoachesByPkArgs = {
  coachId: Scalars['uuid'];
  courseId: Scalars['uuid'];
};

export type Subscription_RootCourseEnrollmentStatusArgs = {
  distinctOn?: InputMaybe<Array<CourseEnrollmentStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourseEnrollmentStatusOrderBy>>;
  where?: InputMaybe<CourseEnrollmentStatusBoolExp>;
};

export type Subscription_RootCourseEnrollmentStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<CourseEnrollmentStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourseEnrollmentStatusOrderBy>>;
  where?: InputMaybe<CourseEnrollmentStatusBoolExp>;
};

export type Subscription_RootCourseEnrollmentStatusByPkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCoursePublishStatusArgs = {
  distinctOn?: InputMaybe<Array<CoursePublishStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursePublishStatusOrderBy>>;
  where?: InputMaybe<CoursePublishStatusBoolExp>;
};

export type Subscription_RootCoursePublishStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<CoursePublishStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursePublishStatusOrderBy>>;
  where?: InputMaybe<CoursePublishStatusBoolExp>;
};

export type Subscription_RootCoursePublishStatusByPkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCourseTypeArgs = {
  distinctOn?: InputMaybe<Array<CourseTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourseTypeOrderBy>>;
  where?: InputMaybe<CourseTypeBoolExp>;
};

export type Subscription_RootCourseTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<CourseTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourseTypeOrderBy>>;
  where?: InputMaybe<CourseTypeBoolExp>;
};

export type Subscription_RootCourseTypeByPkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCourseWaitlistArgs = {
  distinctOn?: InputMaybe<Array<CourseWaitlistSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourseWaitlistOrderBy>>;
  where?: InputMaybe<CourseWaitlistBoolExp>;
};

export type Subscription_RootCourseWaitlistAggregateArgs = {
  distinctOn?: InputMaybe<Array<CourseWaitlistSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourseWaitlistOrderBy>>;
  where?: InputMaybe<CourseWaitlistBoolExp>;
};

export type Subscription_RootCourseWaitlistByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCourseWaitlistDesiredUrgencyTypeArgs = {
  distinctOn?: InputMaybe<Array<CourseWaitlistDesiredUrgencyTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourseWaitlistDesiredUrgencyTypeOrderBy>>;
  where?: InputMaybe<CourseWaitlistDesiredUrgencyTypeBoolExp>;
};

export type Subscription_RootCourseWaitlistDesiredUrgencyTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<CourseWaitlistDesiredUrgencyTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourseWaitlistDesiredUrgencyTypeOrderBy>>;
  where?: InputMaybe<CourseWaitlistDesiredUrgencyTypeBoolExp>;
};

export type Subscription_RootCourseWaitlistDesiredUrgencyTypeByPkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCourseWaitlistDesiredUrgencyTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CourseWaitlistDesiredUrgencyTypeStreamCursorInput>>;
  where?: InputMaybe<CourseWaitlistDesiredUrgencyTypeBoolExp>;
};

export type Subscription_RootCourseWaitlistStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CourseWaitlistStreamCursorInput>>;
  where?: InputMaybe<CourseWaitlistBoolExp>;
};

export type Subscription_RootCoursesArgs = {
  distinctOn?: InputMaybe<Array<CoursesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesOrderBy>>;
  where?: InputMaybe<CoursesBoolExp>;
};

export type Subscription_RootCoursesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CoursesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesOrderBy>>;
  where?: InputMaybe<CoursesBoolExp>;
};

export type Subscription_RootCoursesByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCoursesByPlanArgs = {
  distinctOn?: InputMaybe<Array<CoursesByPlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesByPlanOrderBy>>;
  where?: InputMaybe<CoursesByPlanBoolExp>;
};

export type Subscription_RootCoursesByPlanAggregateArgs = {
  distinctOn?: InputMaybe<Array<CoursesByPlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesByPlanOrderBy>>;
  where?: InputMaybe<CoursesByPlanBoolExp>;
};

export type Subscription_RootCoursesByPlanStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CoursesByPlanStreamCursorInput>>;
  where?: InputMaybe<CoursesByPlanBoolExp>;
};

export type Subscription_RootCoursesResourcesRelArgs = {
  distinctOn?: InputMaybe<Array<CoursesResourcesRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesResourcesRelOrderBy>>;
  where?: InputMaybe<CoursesResourcesRelBoolExp>;
};

export type Subscription_RootCoursesResourcesRelAggregateArgs = {
  distinctOn?: InputMaybe<Array<CoursesResourcesRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesResourcesRelOrderBy>>;
  where?: InputMaybe<CoursesResourcesRelBoolExp>;
};

export type Subscription_RootCoursesResourcesRelByPkArgs = {
  courseId: Scalars['uuid'];
  resourceId: Scalars['uuid'];
};

export type Subscription_RootCoursesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CoursesStreamCursorInput>>;
  where?: InputMaybe<CoursesBoolExp>;
};

export type Subscription_RootCoursesTagsRelArgs = {
  distinctOn?: InputMaybe<Array<CoursesTagsRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesTagsRelOrderBy>>;
  where?: InputMaybe<CoursesTagsRelBoolExp>;
};

export type Subscription_RootCoursesTagsRelAggregateArgs = {
  distinctOn?: InputMaybe<Array<CoursesTagsRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CoursesTagsRelOrderBy>>;
  where?: InputMaybe<CoursesTagsRelBoolExp>;
};

export type Subscription_RootCoursesTagsRelByPkArgs = {
  courseId: Scalars['uuid'];
  tagId: Scalars['uuid'];
};

export type Subscription_RootCoursesTagsRelStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<CoursesTagsRelStreamCursorInput>>;
  where?: InputMaybe<CoursesTagsRelBoolExp>;
};

export type Subscription_RootCreditUsageArgs = {
  distinctOn?: InputMaybe<Array<CreditUsage_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CreditUsageOrderBy>>;
  where?: InputMaybe<CreditUsageBoolExpBoolExp>;
};

export type Subscription_RootCreditUsageByUserArgs = {
  distinctOn?: InputMaybe<Array<CreditUsageByUser_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CreditUsageByUserOrderBy>>;
  where?: InputMaybe<CreditUsageByUserBoolExpBoolExp>;
};

export type Subscription_RootEmailQueueArgs = {
  distinctOn?: InputMaybe<Array<EmailQueueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EmailQueueOrderBy>>;
  where?: InputMaybe<EmailQueueBoolExp>;
};

export type Subscription_RootEmailQueueAggregateArgs = {
  distinctOn?: InputMaybe<Array<EmailQueueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EmailQueueOrderBy>>;
  where?: InputMaybe<EmailQueueBoolExp>;
};

export type Subscription_RootEmailQueueByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootEmailQueueStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EmailQueueStreamCursorInput>>;
  where?: InputMaybe<EmailQueueBoolExp>;
};

export type Subscription_RootEnrollmentWaitlistsArgs = {
  distinctOn?: InputMaybe<Array<EnrollmentWaitlistsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnrollmentWaitlistsOrderBy>>;
  where?: InputMaybe<EnrollmentWaitlistsBoolExp>;
};

export type Subscription_RootEnrollmentWaitlistsAggregateArgs = {
  distinctOn?: InputMaybe<Array<EnrollmentWaitlistsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnrollmentWaitlistsOrderBy>>;
  where?: InputMaybe<EnrollmentWaitlistsBoolExp>;
};

export type Subscription_RootEnrollmentWaitlistsByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootEnrollmentWaitlistsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnrollmentWaitlistsStreamCursorInput>>;
  where?: InputMaybe<EnrollmentWaitlistsBoolExp>;
};

export type Subscription_RootEnrollmentsArgs = {
  distinctOn?: InputMaybe<Array<EnrollmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnrollmentsOrderBy>>;
  where?: InputMaybe<EnrollmentsBoolExp>;
};

export type Subscription_RootEnrollmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<EnrollmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnrollmentsOrderBy>>;
  where?: InputMaybe<EnrollmentsBoolExp>;
};

export type Subscription_RootEnrollmentsByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootEnrollmentsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnrollmentsStreamCursorInput>>;
  where?: InputMaybe<EnrollmentsBoolExp>;
};

export type Subscription_RootFusionauthExternalIdentifiersArgs = {
  distinctOn?: InputMaybe<Array<FusionauthExternalIdentifiersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FusionauthExternalIdentifiersOrderBy>>;
  where?: InputMaybe<FusionauthExternalIdentifiersBoolExp>;
};

export type Subscription_RootFusionauthExternalIdentifiersAggregateArgs = {
  distinctOn?: InputMaybe<Array<FusionauthExternalIdentifiersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FusionauthExternalIdentifiersOrderBy>>;
  where?: InputMaybe<FusionauthExternalIdentifiersBoolExp>;
};

export type Subscription_RootFusionauthExternalIdentifiersByPkArgs = {
  id: Scalars['String'];
};

export type Subscription_RootFusionauthExternalIdentifiersStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<FusionauthExternalIdentifiersStreamCursorInput>>;
  where?: InputMaybe<FusionauthExternalIdentifiersBoolExp>;
};

export type Subscription_RootFusionauthUserRegistrationsArgs = {
  distinctOn?: InputMaybe<Array<FusionauthUserRegistrationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FusionauthUserRegistrationsOrderBy>>;
  where?: InputMaybe<FusionauthUserRegistrationsBoolExp>;
};

export type Subscription_RootFusionauthUserRegistrationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<FusionauthUserRegistrationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FusionauthUserRegistrationsOrderBy>>;
  where?: InputMaybe<FusionauthUserRegistrationsBoolExp>;
};

export type Subscription_RootFusionauthUserRegistrationsByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootFusionauthUserRegistrationsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<FusionauthUserRegistrationsStreamCursorInput>>;
  where?: InputMaybe<FusionauthUserRegistrationsBoolExp>;
};

export type Subscription_RootGetAssessmentQuestionsArgs = {
  args: GetAssessmentQuestionsArgs;
  distinctOn?: InputMaybe<Array<ImpactAssessmentQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImpactAssessmentQuestionsOrderBy>>;
  where?: InputMaybe<ImpactAssessmentQuestionsBoolExp>;
};

export type Subscription_RootGetAssessmentQuestionsAggregateArgs = {
  args: GetAssessmentQuestionsArgs;
  distinctOn?: InputMaybe<Array<ImpactAssessmentQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImpactAssessmentQuestionsOrderBy>>;
  where?: InputMaybe<ImpactAssessmentQuestionsBoolExp>;
};

export type Subscription_RootGetTeamMembersArgs = {
  args: GetTeamMembers_Arguments;
  distinctOn?: InputMaybe<Array<TeamMember_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamMemberOrderBy>>;
  where?: InputMaybe<TeamMemberBoolExpBoolExp>;
};

export type Subscription_RootGetTeamMembersNewArgs = {
  distinctOn?: InputMaybe<Array<TeamMemberNew_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamMemberNewOrderBy>>;
  where?: InputMaybe<TeamMemberNewBoolExpBoolExp>;
};

export type Subscription_RootIdentitiesArgs = {
  distinctOn?: InputMaybe<Array<IdentitiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IdentitiesOrderBy>>;
  where?: InputMaybe<IdentitiesBoolExp>;
};

export type Subscription_RootIdentitiesAggregateArgs = {
  distinctOn?: InputMaybe<Array<IdentitiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IdentitiesOrderBy>>;
  where?: InputMaybe<IdentitiesBoolExp>;
};

export type Subscription_RootIdentitiesByPkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootIdentityProviderLinksArgs = {
  distinctOn?: InputMaybe<Array<IdentityProviderLinksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IdentityProviderLinksOrderBy>>;
  where?: InputMaybe<IdentityProviderLinksBoolExp>;
};

export type Subscription_RootIdentityProviderLinksAggregateArgs = {
  distinctOn?: InputMaybe<Array<IdentityProviderLinksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IdentityProviderLinksOrderBy>>;
  where?: InputMaybe<IdentityProviderLinksBoolExp>;
};

export type Subscription_RootIdentityProvidersArgs = {
  distinctOn?: InputMaybe<Array<IdentityProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IdentityProvidersOrderBy>>;
  where?: InputMaybe<IdentityProvidersBoolExp>;
};

export type Subscription_RootIdentityProvidersAggregateArgs = {
  distinctOn?: InputMaybe<Array<IdentityProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IdentityProvidersOrderBy>>;
  where?: InputMaybe<IdentityProvidersBoolExp>;
};

export type Subscription_RootIdentityProvidersByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootImpactAssessmentAttemptsArgs = {
  distinctOn?: InputMaybe<Array<ImpactAssessmentAttemptsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImpactAssessmentAttemptsOrderBy>>;
  where?: InputMaybe<ImpactAssessmentAttemptsBoolExp>;
};

export type Subscription_RootImpactAssessmentAttemptsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ImpactAssessmentAttemptsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImpactAssessmentAttemptsOrderBy>>;
  where?: InputMaybe<ImpactAssessmentAttemptsBoolExp>;
};

export type Subscription_RootImpactAssessmentAttemptsByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootImpactAssessmentAttemptsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ImpactAssessmentAttemptsStreamCursorInput>>;
  where?: InputMaybe<ImpactAssessmentAttemptsBoolExp>;
};

export type Subscription_RootImpactAssessmentQuestionsArgs = {
  distinctOn?: InputMaybe<Array<ImpactAssessmentQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImpactAssessmentQuestionsOrderBy>>;
  where?: InputMaybe<ImpactAssessmentQuestionsBoolExp>;
};

export type Subscription_RootImpactAssessmentQuestionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ImpactAssessmentQuestionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImpactAssessmentQuestionsOrderBy>>;
  where?: InputMaybe<ImpactAssessmentQuestionsBoolExp>;
};

export type Subscription_RootImpactAssessmentQuestionsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ImpactAssessmentQuestionsStreamCursorInput>>;
  where?: InputMaybe<ImpactAssessmentQuestionsBoolExp>;
};

export type Subscription_RootImpactAssessmentsArgs = {
  distinctOn?: InputMaybe<Array<ImpactAssessmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImpactAssessmentsOrderBy>>;
  where?: InputMaybe<ImpactAssessmentsBoolExp>;
};

export type Subscription_RootImpactAssessmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ImpactAssessmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImpactAssessmentsOrderBy>>;
  where?: InputMaybe<ImpactAssessmentsBoolExp>;
};

export type Subscription_RootImpactAssessmentsByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootImpactAssessmentsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ImpactAssessmentsStreamCursorInput>>;
  where?: InputMaybe<ImpactAssessmentsBoolExp>;
};

export type Subscription_RootJourneyEnrollmentsArgs = {
  distinctOn?: InputMaybe<Array<JourneyEnrollmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<JourneyEnrollmentsOrderBy>>;
  where?: InputMaybe<JourneyEnrollmentsBoolExp>;
};

export type Subscription_RootJourneyEnrollmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<JourneyEnrollmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<JourneyEnrollmentsOrderBy>>;
  where?: InputMaybe<JourneyEnrollmentsBoolExp>;
};

export type Subscription_RootJourneyEnrollmentsByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootJourneyEnrollmentsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<JourneyEnrollmentsStreamCursorInput>>;
  where?: InputMaybe<JourneyEnrollmentsBoolExp>;
};

export type Subscription_RootJourneysArgs = {
  distinctOn?: InputMaybe<Array<JourneysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<JourneysOrderBy>>;
  where?: InputMaybe<JourneysBoolExp>;
};

export type Subscription_RootJourneysAggregateArgs = {
  distinctOn?: InputMaybe<Array<JourneysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<JourneysOrderBy>>;
  where?: InputMaybe<JourneysBoolExp>;
};

export type Subscription_RootJourneysByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootJourneysStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<JourneysStreamCursorInput>>;
  where?: InputMaybe<JourneysBoolExp>;
};

export type Subscription_RootLedgerArgs = {
  distinctOn?: InputMaybe<Array<LedgerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LedgerOrderBy>>;
  where?: InputMaybe<LedgerBoolExp>;
};

export type Subscription_RootLedgerAggregateArgs = {
  distinctOn?: InputMaybe<Array<LedgerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LedgerOrderBy>>;
  where?: InputMaybe<LedgerBoolExp>;
};

export type Subscription_RootLedgerByPkArgs = {
  id: Scalars['String'];
};

export type Subscription_RootLedgerProductEnumArgs = {
  distinctOn?: InputMaybe<Array<LedgerProductEnumSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LedgerProductEnumOrderBy>>;
  where?: InputMaybe<LedgerProductEnumBoolExp>;
};

export type Subscription_RootLedgerProductEnumAggregateArgs = {
  distinctOn?: InputMaybe<Array<LedgerProductEnumSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LedgerProductEnumOrderBy>>;
  where?: InputMaybe<LedgerProductEnumBoolExp>;
};

export type Subscription_RootLedgerProductEnumByPkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootLedgerProductEnumStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<LedgerProductEnumStreamCursorInput>>;
  where?: InputMaybe<LedgerProductEnumBoolExp>;
};

export type Subscription_RootLedgerStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<LedgerStreamCursorInput>>;
  where?: InputMaybe<LedgerBoolExp>;
};

export type Subscription_RootLedgerTransactionTypeEnumArgs = {
  distinctOn?: InputMaybe<Array<LedgerTransactionTypeEnumSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LedgerTransactionTypeEnumOrderBy>>;
  where?: InputMaybe<LedgerTransactionTypeEnumBoolExp>;
};

export type Subscription_RootLedgerTransactionTypeEnumAggregateArgs = {
  distinctOn?: InputMaybe<Array<LedgerTransactionTypeEnumSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LedgerTransactionTypeEnumOrderBy>>;
  where?: InputMaybe<LedgerTransactionTypeEnumBoolExp>;
};

export type Subscription_RootLedgerTransactionTypeEnumByPkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootLedgerTransactionTypeEnumStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<LedgerTransactionTypeEnumStreamCursorInput>>;
  where?: InputMaybe<LedgerTransactionTypeEnumBoolExp>;
};

export type Subscription_RootLightningSessionArgs = {
  distinctOn?: InputMaybe<Array<LightningSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LightningSessionOrderBy>>;
  where?: InputMaybe<LightningSessionBoolExp>;
};

export type Subscription_RootLightningSessionAggregateArgs = {
  distinctOn?: InputMaybe<Array<LightningSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LightningSessionOrderBy>>;
  where?: InputMaybe<LightningSessionBoolExp>;
};

export type Subscription_RootLightningSessionByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootLightningSessionStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<LightningSessionStreamCursorInput>>;
  where?: InputMaybe<LightningSessionBoolExp>;
};

export type Subscription_RootLightningSessionSubmissionsArgs = {
  distinctOn?: InputMaybe<Array<LightningSessionSubmissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LightningSessionSubmissionsOrderBy>>;
  where?: InputMaybe<LightningSessionSubmissionsBoolExp>;
};

export type Subscription_RootLightningSessionSubmissionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<LightningSessionSubmissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LightningSessionSubmissionsOrderBy>>;
  where?: InputMaybe<LightningSessionSubmissionsBoolExp>;
};

export type Subscription_RootLightningSessionSubmissionsByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootLightningSessionSubmissionsStatusArgs = {
  distinctOn?: InputMaybe<Array<LightningSessionSubmissionsStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LightningSessionSubmissionsStatusOrderBy>>;
  where?: InputMaybe<LightningSessionSubmissionsStatusBoolExp>;
};

export type Subscription_RootLightningSessionSubmissionsStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<LightningSessionSubmissionsStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LightningSessionSubmissionsStatusOrderBy>>;
  where?: InputMaybe<LightningSessionSubmissionsStatusBoolExp>;
};

export type Subscription_RootLightningSessionSubmissionsStatusByPkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootLightningSessionSubmissionsStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<LightningSessionSubmissionsStatusStreamCursorInput>>;
  where?: InputMaybe<LightningSessionSubmissionsStatusBoolExp>;
};

export type Subscription_RootLightningSessionSubmissionsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<LightningSessionSubmissionsStreamCursorInput>>;
  where?: InputMaybe<LightningSessionSubmissionsBoolExp>;
};

export type Subscription_RootLightningSessionSubmissionsUsersRelArgs = {
  distinctOn?: InputMaybe<Array<LightningSessionSubmissionsUsersRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LightningSessionSubmissionsUsersRelOrderBy>>;
  where?: InputMaybe<LightningSessionSubmissionsUsersRelBoolExp>;
};

export type Subscription_RootLightningSessionSubmissionsUsersRelAggregateArgs = {
  distinctOn?: InputMaybe<Array<LightningSessionSubmissionsUsersRelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LightningSessionSubmissionsUsersRelOrderBy>>;
  where?: InputMaybe<LightningSessionSubmissionsUsersRelBoolExp>;
};

export type Subscription_RootLightningSessionSubmissionsUsersRelByPkArgs = {
  lightningSessionSubmissionId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

export type Subscription_RootLightningSessionSubmissionsUsersRelStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<LightningSessionSubmissionsUsersRelStreamCursorInput>>;
  where?: InputMaybe<LightningSessionSubmissionsUsersRelBoolExp>;
};

export type Subscription_RootLiveClassesArgs = {
  distinctOn?: InputMaybe<Array<LiveClassesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiveClassesOrderBy>>;
  where?: InputMaybe<LiveClassesBoolExp>;
};

export type Subscription_RootLiveClassesAggregateArgs = {
  distinctOn?: InputMaybe<Array<LiveClassesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiveClassesOrderBy>>;
  where?: InputMaybe<LiveClassesBoolExp>;
};

export type Subscription_RootLiveClassesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<LiveClassesStreamCursorInput>>;
  where?: InputMaybe<LiveClassesBoolExp>;
};

export type Subscription_RootManagerSessionEmailsArgs = {
  distinctOn?: InputMaybe<Array<ManagerSessionEmailsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ManagerSessionEmailsOrderBy>>;
  where?: InputMaybe<ManagerSessionEmailsBoolExp>;
};

export type Subscription_RootManagerSessionEmailsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ManagerSessionEmailsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ManagerSessionEmailsOrderBy>>;
  where?: InputMaybe<ManagerSessionEmailsBoolExp>;
};

export type Subscription_RootManagerSessionEmailsByPkArgs = {
  managerCohortId: Scalars['String'];
};

export type Subscription_RootManagerSessionEmailsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ManagerSessionEmailsStreamCursorInput>>;
  where?: InputMaybe<ManagerSessionEmailsBoolExp>;
};

export type Subscription_RootMyCohortLearningStatusArgs = {
  distinctOn?: InputMaybe<Array<MyCohortLearningStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MyCohortLearningStatusOrderBy>>;
  where?: InputMaybe<MyCohortLearningStatusBoolExp>;
};

export type Subscription_RootMyCohortLearningStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<MyCohortLearningStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MyCohortLearningStatusOrderBy>>;
  where?: InputMaybe<MyCohortLearningStatusBoolExp>;
};

export type Subscription_RootMyCohortLearningStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<MyCohortLearningStatusStreamCursorInput>>;
  where?: InputMaybe<MyCohortLearningStatusBoolExp>;
};

export type Subscription_RootMyLearnerHomeCoursesArgs = {
  distinctOn?: InputMaybe<Array<MyLearnerHomeCoursesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MyLearnerHomeCoursesOrderBy>>;
  where?: InputMaybe<MyLearnerHomeCoursesBoolExp>;
};

export type Subscription_RootMyLearnerHomeCoursesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MyLearnerHomeCoursesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MyLearnerHomeCoursesOrderBy>>;
  where?: InputMaybe<MyLearnerHomeCoursesBoolExp>;
};

export type Subscription_RootMyLearnerHomeCoursesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<MyLearnerHomeCoursesStreamCursorInput>>;
  where?: InputMaybe<MyLearnerHomeCoursesBoolExp>;
};

export type Subscription_RootMyLearnerHomeLiveCoachingArgs = {
  distinctOn?: InputMaybe<Array<MyLearnerHomeLiveCoachingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MyLearnerHomeLiveCoachingOrderBy>>;
  where?: InputMaybe<MyLearnerHomeLiveCoachingBoolExp>;
};

export type Subscription_RootMyLearnerHomeLiveCoachingAggregateArgs = {
  distinctOn?: InputMaybe<Array<MyLearnerHomeLiveCoachingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MyLearnerHomeLiveCoachingOrderBy>>;
  where?: InputMaybe<MyLearnerHomeLiveCoachingBoolExp>;
};

export type Subscription_RootMyLearnerHomeLiveCoachingStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<MyLearnerHomeLiveCoachingStreamCursorInput>>;
  where?: InputMaybe<MyLearnerHomeLiveCoachingBoolExp>;
};

export type Subscription_RootMyLightningSessionCohortsLearningStatusArgs = {
  distinctOn?: InputMaybe<Array<MyLightningSessionCohortsLearningStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MyLightningSessionCohortsLearningStatusOrderBy>>;
  where?: InputMaybe<MyLightningSessionCohortsLearningStatusBoolExp>;
};

export type Subscription_RootMyLightningSessionCohortsLearningStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<MyLightningSessionCohortsLearningStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MyLightningSessionCohortsLearningStatusOrderBy>>;
  where?: InputMaybe<MyLightningSessionCohortsLearningStatusBoolExp>;
};

export type Subscription_RootMyLightningSessionCohortsLearningStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<MyLightningSessionCohortsLearningStatusStreamCursorInput>>;
  where?: InputMaybe<MyLightningSessionCohortsLearningStatusBoolExp>;
};

export type Subscription_RootMyTeamFlatTreeArgs = {
  args: MyTeamFlatTree_Arguments;
  distinctOn?: InputMaybe<Array<Team_Flat_Tree_Node_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Team_Flat_Tree_NodeOrderBy>>;
  where?: InputMaybe<Team_Flat_Tree_NodeBoolExpBoolExp>;
};

export type Subscription_RootMyTraditionalCohortsLearningStatusArgs = {
  distinctOn?: InputMaybe<Array<MyTraditionalCohortsLearningStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MyTraditionalCohortsLearningStatusOrderBy>>;
  where?: InputMaybe<MyTraditionalCohortsLearningStatusBoolExp>;
};

export type Subscription_RootMyTraditionalCohortsLearningStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<MyTraditionalCohortsLearningStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MyTraditionalCohortsLearningStatusOrderBy>>;
  where?: InputMaybe<MyTraditionalCohortsLearningStatusBoolExp>;
};

export type Subscription_RootMyTraditionalCohortsLearningStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<MyTraditionalCohortsLearningStatusStreamCursorInput>>;
  where?: InputMaybe<MyTraditionalCohortsLearningStatusBoolExp>;
};

export type Subscription_RootMy_Learner_Task_Native_QueryArgs = {
  args: My_Learner_Task_Native_Query_Arguments;
  distinctOn?: InputMaybe<Array<Learner_Task_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Learner_TaskOrderBy>>;
  where?: InputMaybe<Learner_TaskBoolExpBoolExp>;
};

export type Subscription_RootNonceAccessTokenArgs = {
  distinctOn?: InputMaybe<Array<NonceAccessTokenSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NonceAccessTokenOrderBy>>;
  where?: InputMaybe<NonceAccessTokenBoolExp>;
};

export type Subscription_RootNonceAccessTokenAggregateArgs = {
  distinctOn?: InputMaybe<Array<NonceAccessTokenSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NonceAccessTokenOrderBy>>;
  where?: InputMaybe<NonceAccessTokenBoolExp>;
};

export type Subscription_RootNonceAccessTokenByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootNonceAccessTokenStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<NonceAccessTokenStreamCursorInput>>;
  where?: InputMaybe<NonceAccessTokenBoolExp>;
};

export type Subscription_RootOrganizationUsersArgs = {
  distinctOn?: InputMaybe<Array<OrganizationUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationUsersOrderBy>>;
  where?: InputMaybe<OrganizationUsersBoolExp>;
};

export type Subscription_RootOrganizationUsersAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationUsersOrderBy>>;
  where?: InputMaybe<OrganizationUsersBoolExp>;
};

export type Subscription_RootOrganizationUsersByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootOrganizationsArgs = {
  distinctOn?: InputMaybe<Array<OrganizationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationsOrderBy>>;
  where?: InputMaybe<OrganizationsBoolExp>;
};

export type Subscription_RootOrganizationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationsOrderBy>>;
  where?: InputMaybe<OrganizationsBoolExp>;
};

export type Subscription_RootOrganizationsByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootOrganizationsMetaArgs = {
  distinctOn?: InputMaybe<Array<OrganizationsMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationsMetaOrderBy>>;
  where?: InputMaybe<OrganizationsMetaBoolExp>;
};

export type Subscription_RootOrganizationsMetaAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationsMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationsMetaOrderBy>>;
  where?: InputMaybe<OrganizationsMetaBoolExp>;
};

export type Subscription_RootOrganizationsMetaByPkArgs = {
  organizationId: Scalars['uuid'];
};

export type Subscription_RootOrganizationsMetaStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<OrganizationsMetaStreamCursorInput>>;
  where?: InputMaybe<OrganizationsMetaBoolExp>;
};

export type Subscription_RootPlanSubscriptionsArgs = {
  distinctOn?: InputMaybe<Array<PlanSubscriptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlanSubscriptionsOrderBy>>;
  where?: InputMaybe<PlanSubscriptionsBoolExp>;
};

export type Subscription_RootPlanSubscriptionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<PlanSubscriptionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlanSubscriptionsOrderBy>>;
  where?: InputMaybe<PlanSubscriptionsBoolExp>;
};

export type Subscription_RootPlanSubscriptionsByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPlanSubscriptionsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<PlanSubscriptionsStreamCursorInput>>;
  where?: InputMaybe<PlanSubscriptionsBoolExp>;
};

export type Subscription_RootPlansArgs = {
  distinctOn?: InputMaybe<Array<PlansSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlansOrderBy>>;
  where?: InputMaybe<PlansBoolExp>;
};

export type Subscription_RootPlansAggregateArgs = {
  distinctOn?: InputMaybe<Array<PlansSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlansOrderBy>>;
  where?: InputMaybe<PlansBoolExp>;
};

export type Subscription_RootPlansByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPlansStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<PlansStreamCursorInput>>;
  where?: InputMaybe<PlansBoolExp>;
};

export type Subscription_RootPlayExecutionArgs = {
  distinctOn?: InputMaybe<Array<PlayExecutionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlayExecutionOrderBy>>;
  where?: InputMaybe<PlayExecutionBoolExp>;
};

export type Subscription_RootPlayExecutionAggregateArgs = {
  distinctOn?: InputMaybe<Array<PlayExecutionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlayExecutionOrderBy>>;
  where?: InputMaybe<PlayExecutionBoolExp>;
};

export type Subscription_RootPlayExecutionByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProgramActionsTakenArgs = {
  distinctOn?: InputMaybe<Array<ProgramActionsTakenSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramActionsTakenOrderBy>>;
  where?: InputMaybe<ProgramActionsTakenBoolExp>;
};

export type Subscription_RootProgramActionsTakenAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProgramActionsTakenSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramActionsTakenOrderBy>>;
  where?: InputMaybe<ProgramActionsTakenBoolExp>;
};

export type Subscription_RootProgramActionsTakenStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ProgramActionsTakenStreamCursorInput>>;
  where?: InputMaybe<ProgramActionsTakenBoolExp>;
};

export type Subscription_RootProgramCoursesShownArgs = {
  distinctOn?: InputMaybe<Array<ProgramCoursesShownSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramCoursesShownOrderBy>>;
  where?: InputMaybe<ProgramCoursesShownBoolExp>;
};

export type Subscription_RootProgramCoursesShownAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProgramCoursesShownSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramCoursesShownOrderBy>>;
  where?: InputMaybe<ProgramCoursesShownBoolExp>;
};

export type Subscription_RootProgramCoursesShownByPkArgs = {
  courseId: Scalars['uuid'];
  programId: Scalars['uuid'];
};

export type Subscription_RootProgramCoursesShownStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ProgramCoursesShownStreamCursorInput>>;
  where?: InputMaybe<ProgramCoursesShownBoolExp>;
};

export type Subscription_RootProgramTagsArgs = {
  distinctOn?: InputMaybe<Array<ProgramTagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramTagsOrderBy>>;
  where?: InputMaybe<ProgramTagsBoolExp>;
};

export type Subscription_RootProgramTagsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProgramTagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramTagsOrderBy>>;
  where?: InputMaybe<ProgramTagsBoolExp>;
};

export type Subscription_RootProgramTagsByPkArgs = {
  programId: Scalars['uuid'];
  tagId: Scalars['uuid'];
};

export type Subscription_RootProgramTagsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ProgramTagsStreamCursorInput>>;
  where?: InputMaybe<ProgramTagsBoolExp>;
};

export type Subscription_RootProgramUsersSelectedArgs = {
  distinctOn?: InputMaybe<Array<ProgramUsersSelectedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramUsersSelectedOrderBy>>;
  where?: InputMaybe<ProgramUsersSelectedBoolExp>;
};

export type Subscription_RootProgramUsersSelectedAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProgramUsersSelectedSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramUsersSelectedOrderBy>>;
  where?: InputMaybe<ProgramUsersSelectedBoolExp>;
};

export type Subscription_RootProgramUsersSelectedByPkArgs = {
  programId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

export type Subscription_RootProgramUsersSelectedStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ProgramUsersSelectedStreamCursorInput>>;
  where?: InputMaybe<ProgramUsersSelectedBoolExp>;
};

export type Subscription_RootProgramsArgs = {
  distinctOn?: InputMaybe<Array<ProgramsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramsOrderBy>>;
  where?: InputMaybe<ProgramsBoolExp>;
};

export type Subscription_RootProgramsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProgramsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramsOrderBy>>;
  where?: InputMaybe<ProgramsBoolExp>;
};

export type Subscription_RootProgramsByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProgramsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ProgramsStreamCursorInput>>;
  where?: InputMaybe<ProgramsBoolExp>;
};

export type Subscription_RootQuestionTypeArgs = {
  distinctOn?: InputMaybe<Array<QuestionTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<QuestionTypeOrderBy>>;
  where?: InputMaybe<QuestionTypeBoolExp>;
};

export type Subscription_RootQuestionTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<QuestionTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<QuestionTypeOrderBy>>;
  where?: InputMaybe<QuestionTypeBoolExp>;
};

export type Subscription_RootQuestionTypeByPkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootRecordingActivityArgs = {
  distinctOn?: InputMaybe<Array<RecordingActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecordingActivityOrderBy>>;
  where?: InputMaybe<RecordingActivityBoolExp>;
};

export type Subscription_RootRecordingActivityAggregateArgs = {
  distinctOn?: InputMaybe<Array<RecordingActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecordingActivityOrderBy>>;
  where?: InputMaybe<RecordingActivityBoolExp>;
};

export type Subscription_RootRecordingActivityByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootRecordingActivityStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<RecordingActivityStreamCursorInput>>;
  where?: InputMaybe<RecordingActivityBoolExp>;
};

export type Subscription_RootRecordingsArgs = {
  distinctOn?: InputMaybe<Array<RecordingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecordingsOrderBy>>;
  where?: InputMaybe<RecordingsBoolExp>;
};

export type Subscription_RootRecordingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<RecordingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecordingsOrderBy>>;
  where?: InputMaybe<RecordingsBoolExp>;
};

export type Subscription_RootRecordingsByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootRecordingsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<RecordingsStreamCursorInput>>;
  where?: InputMaybe<RecordingsBoolExp>;
};

export type Subscription_RootRelatedCoursesArgs = {
  distinctOn?: InputMaybe<Array<RelatedCoursesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RelatedCoursesOrderBy>>;
  where?: InputMaybe<RelatedCoursesBoolExp>;
};

export type Subscription_RootRelatedCoursesAggregateArgs = {
  distinctOn?: InputMaybe<Array<RelatedCoursesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RelatedCoursesOrderBy>>;
  where?: InputMaybe<RelatedCoursesBoolExp>;
};

export type Subscription_RootRelatedCoursesByPkArgs = {
  courseId: Scalars['uuid'];
  relatedCourseSlug: Scalars['String'];
};

export type Subscription_RootReportPublishStatusArgs = {
  distinctOn?: InputMaybe<Array<ReportPublishStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReportPublishStatusOrderBy>>;
  where?: InputMaybe<ReportPublishStatusBoolExp>;
};

export type Subscription_RootReportPublishStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<ReportPublishStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReportPublishStatusOrderBy>>;
  where?: InputMaybe<ReportPublishStatusBoolExp>;
};

export type Subscription_RootReportPublishStatusByPkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootReportTypeArgs = {
  distinctOn?: InputMaybe<Array<ReportTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReportTypeOrderBy>>;
  where?: InputMaybe<ReportTypeBoolExp>;
};

export type Subscription_RootReportTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<ReportTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReportTypeOrderBy>>;
  where?: InputMaybe<ReportTypeBoolExp>;
};

export type Subscription_RootReportTypeByPkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootReportsArgs = {
  distinctOn?: InputMaybe<Array<ReportsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReportsOrderBy>>;
  where?: InputMaybe<ReportsBoolExp>;
};

export type Subscription_RootReportsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ReportsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReportsOrderBy>>;
  where?: InputMaybe<ReportsBoolExp>;
};

export type Subscription_RootReportsByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootReportsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ReportsStreamCursorInput>>;
  where?: InputMaybe<ReportsBoolExp>;
};

export type Subscription_RootResourceTypeArgs = {
  distinctOn?: InputMaybe<Array<ResourceTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ResourceTypeOrderBy>>;
  where?: InputMaybe<ResourceTypeBoolExp>;
};

export type Subscription_RootResourceTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<ResourceTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ResourceTypeOrderBy>>;
  where?: InputMaybe<ResourceTypeBoolExp>;
};

export type Subscription_RootResourceTypeByPkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootResourcesArgs = {
  distinctOn?: InputMaybe<Array<ResourcesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ResourcesOrderBy>>;
  where?: InputMaybe<ResourcesBoolExp>;
};

export type Subscription_RootResourcesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ResourcesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ResourcesOrderBy>>;
  where?: InputMaybe<ResourcesBoolExp>;
};

export type Subscription_RootResourcesByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootResourcesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ResourcesStreamCursorInput>>;
  where?: InputMaybe<ResourcesBoolExp>;
};

export type Subscription_RootSchoolsArgs = {
  distinctOn?: InputMaybe<Array<SchoolsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SchoolsOrderBy>>;
  where?: InputMaybe<SchoolsBoolExp>;
};

export type Subscription_RootSchoolsAggregateArgs = {
  distinctOn?: InputMaybe<Array<SchoolsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SchoolsOrderBy>>;
  where?: InputMaybe<SchoolsBoolExp>;
};

export type Subscription_RootSchoolsByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSchoolsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<SchoolsStreamCursorInput>>;
  where?: InputMaybe<SchoolsBoolExp>;
};

export type Subscription_RootSeatTypeArgs = {
  distinctOn?: InputMaybe<Array<SeatTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SeatTypeOrderBy>>;
  where?: InputMaybe<SeatTypeBoolExp>;
};

export type Subscription_RootSeatTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<SeatTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SeatTypeOrderBy>>;
  where?: InputMaybe<SeatTypeBoolExp>;
};

export type Subscription_RootSeatTypeByPkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootSeatsArgs = {
  distinctOn?: InputMaybe<Array<SeatsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SeatsOrderBy>>;
  where?: InputMaybe<SeatsBoolExp>;
};

export type Subscription_RootSeatsAggregateArgs = {
  distinctOn?: InputMaybe<Array<SeatsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SeatsOrderBy>>;
  where?: InputMaybe<SeatsBoolExp>;
};

export type Subscription_RootSeatsByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSeatsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<SeatsStreamCursorInput>>;
  where?: InputMaybe<SeatsBoolExp>;
};

export type Subscription_RootStreamMessagesArgs = {
  distinctOn?: InputMaybe<Array<StreamMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StreamMessagesOrderBy>>;
  where?: InputMaybe<StreamMessagesBoolExp>;
};

export type Subscription_RootStreamMessagesAggregateArgs = {
  distinctOn?: InputMaybe<Array<StreamMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StreamMessagesOrderBy>>;
  where?: InputMaybe<StreamMessagesBoolExp>;
};

export type Subscription_RootStreamMessagesByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootStreamMessagesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<StreamMessagesStreamCursorInput>>;
  where?: InputMaybe<StreamMessagesBoolExp>;
};

export type Subscription_RootSubordinatesArgs = {
  distinctOn?: InputMaybe<Array<SubordinatesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SubordinatesOrderBy>>;
  where?: InputMaybe<SubordinatesBoolExp>;
};

export type Subscription_RootSubordinatesAggregateArgs = {
  distinctOn?: InputMaybe<Array<SubordinatesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SubordinatesOrderBy>>;
  where?: InputMaybe<SubordinatesBoolExp>;
};

export type Subscription_RootSubordinatesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<SubordinatesStreamCursorInput>>;
  where?: InputMaybe<SubordinatesBoolExp>;
};

export type Subscription_RootTagTypeScopeArgs = {
  distinctOn?: InputMaybe<Array<TagTypeScopeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TagTypeScopeOrderBy>>;
  where?: InputMaybe<TagTypeScopeBoolExp>;
};

export type Subscription_RootTagTypeScopeAggregateArgs = {
  distinctOn?: InputMaybe<Array<TagTypeScopeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TagTypeScopeOrderBy>>;
  where?: InputMaybe<TagTypeScopeBoolExp>;
};

export type Subscription_RootTagTypeScopeByPkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootTagTypesArgs = {
  distinctOn?: InputMaybe<Array<TagTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TagTypesOrderBy>>;
  where?: InputMaybe<TagTypesBoolExp>;
};

export type Subscription_RootTagTypesAggregateArgs = {
  distinctOn?: InputMaybe<Array<TagTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TagTypesOrderBy>>;
  where?: InputMaybe<TagTypesBoolExp>;
};

export type Subscription_RootTagTypesByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTagTypesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<TagTypesStreamCursorInput>>;
  where?: InputMaybe<TagTypesBoolExp>;
};

export type Subscription_RootTagsArgs = {
  distinctOn?: InputMaybe<Array<TagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TagsOrderBy>>;
  where?: InputMaybe<TagsBoolExp>;
};

export type Subscription_RootTagsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TagsOrderBy>>;
  where?: InputMaybe<TagsBoolExp>;
};

export type Subscription_RootTagsByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTagsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<TagsStreamCursorInput>>;
  where?: InputMaybe<TagsBoolExp>;
};

export type Subscription_RootTaskDefinitionArgs = {
  distinctOn?: InputMaybe<Array<TaskDefinitionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TaskDefinitionOrderBy>>;
  where?: InputMaybe<TaskDefinitionBoolExp>;
};

export type Subscription_RootTaskDefinitionAggregateArgs = {
  distinctOn?: InputMaybe<Array<TaskDefinitionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TaskDefinitionOrderBy>>;
  where?: InputMaybe<TaskDefinitionBoolExp>;
};

export type Subscription_RootTaskDefinitionByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTaskDefinitionStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<TaskDefinitionStreamCursorInput>>;
  where?: InputMaybe<TaskDefinitionBoolExp>;
};

export type Subscription_RootTaskDefinitionTypeArgs = {
  distinctOn?: InputMaybe<Array<TaskDefinitionTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TaskDefinitionTypeOrderBy>>;
  where?: InputMaybe<TaskDefinitionTypeBoolExp>;
};

export type Subscription_RootTaskDefinitionTypeAggregateArgs = {
  distinctOn?: InputMaybe<Array<TaskDefinitionTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TaskDefinitionTypeOrderBy>>;
  where?: InputMaybe<TaskDefinitionTypeBoolExp>;
};

export type Subscription_RootTaskDefinitionTypeByPkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootTasksArgs = {
  distinctOn?: InputMaybe<Array<TasksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
  where?: InputMaybe<TasksBoolExp>;
};

export type Subscription_RootTasksAggregateArgs = {
  distinctOn?: InputMaybe<Array<TasksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
  where?: InputMaybe<TasksBoolExp>;
};

export type Subscription_RootTasksByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTasksStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<TasksStreamCursorInput>>;
  where?: InputMaybe<TasksBoolExp>;
};

export type Subscription_RootTeamMemberPollSubmissionsArgs = {
  distinctOn?: InputMaybe<Array<TeamMemberPollSubmissions_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamMemberPollSubmissionsOrderBy>>;
  where?: InputMaybe<TeamMemberPollSubmissionsBoolExpBoolExp>;
};

export type Subscription_RootTeamsArgs = {
  distinctOn?: InputMaybe<Array<TeamsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
  where?: InputMaybe<TeamsBoolExp>;
};

export type Subscription_RootTeamsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
  where?: InputMaybe<TeamsBoolExp>;
};

export type Subscription_RootTeamsByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTeamsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<TeamsStreamCursorInput>>;
  where?: InputMaybe<TeamsBoolExp>;
};

export type Subscription_RootTeamsUsersArgs = {
  distinctOn?: InputMaybe<Array<TeamsUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamsUsersOrderBy>>;
  where?: InputMaybe<TeamsUsersBoolExp>;
};

export type Subscription_RootTeamsUsersAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamsUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamsUsersOrderBy>>;
  where?: InputMaybe<TeamsUsersBoolExp>;
};

export type Subscription_RootTeamsUsersByPkArgs = {
  teamId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

export type Subscription_RootTimeElapsedLiveClassesArgs = {
  distinctOn?: InputMaybe<Array<TimeElapsedLiveClassesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TimeElapsedLiveClassesOrderBy>>;
  where?: InputMaybe<TimeElapsedLiveClassesBoolExp>;
};

export type Subscription_RootTimeElapsedLiveClassesAggregateArgs = {
  distinctOn?: InputMaybe<Array<TimeElapsedLiveClassesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TimeElapsedLiveClassesOrderBy>>;
  where?: InputMaybe<TimeElapsedLiveClassesBoolExp>;
};

export type Subscription_RootTimeElapsedLiveClassesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<TimeElapsedLiveClassesStreamCursorInput>>;
  where?: InputMaybe<TimeElapsedLiveClassesBoolExp>;
};

export type Subscription_RootTypeformSubmissionsArgs = {
  distinctOn?: InputMaybe<Array<TypeformSubmissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TypeformSubmissionsOrderBy>>;
  where?: InputMaybe<TypeformSubmissionsBoolExp>;
};

export type Subscription_RootTypeformSubmissionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TypeformSubmissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TypeformSubmissionsOrderBy>>;
  where?: InputMaybe<TypeformSubmissionsBoolExp>;
};

export type Subscription_RootTypeformSubmissionsByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTypeformSubmissionsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<TypeformSubmissionsStreamCursorInput>>;
  where?: InputMaybe<TypeformSubmissionsBoolExp>;
};

export type Subscription_RootUserCompletedSurveysArgs = {
  distinctOn?: InputMaybe<Array<UserCompletedSurveysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserCompletedSurveysOrderBy>>;
  where?: InputMaybe<UserCompletedSurveysBoolExp>;
};

export type Subscription_RootUserCompletedSurveysAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserCompletedSurveysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserCompletedSurveysOrderBy>>;
  where?: InputMaybe<UserCompletedSurveysBoolExp>;
};

export type Subscription_RootUserCompletedSurveysStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<UserCompletedSurveysStreamCursorInput>>;
  where?: InputMaybe<UserCompletedSurveysBoolExp>;
};

export type Subscription_RootUserManagedTeamsFlattenedTreeArgs = {
  args: UserManagedTeamsFlattenedTree_Arguments;
  distinctOn?: InputMaybe<Array<UserManagedTeamsFlattenedTree_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserManagedTeamsFlattenedTreeOrderBy>>;
  where?: InputMaybe<UserManagedTeamsFlattenedTreeBoolExpBoolExp>;
};

export type Subscription_RootUserNumCompletedClassesArgs = {
  distinctOn?: InputMaybe<Array<UserNumCompletedClassesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserNumCompletedClassesOrderBy>>;
  where?: InputMaybe<UserNumCompletedClassesBoolExp>;
};

export type Subscription_RootUserNumCompletedClassesAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserNumCompletedClassesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserNumCompletedClassesOrderBy>>;
  where?: InputMaybe<UserNumCompletedClassesBoolExp>;
};

export type Subscription_RootUserNumCompletedClassesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<UserNumCompletedClassesStreamCursorInput>>;
  where?: InputMaybe<UserNumCompletedClassesBoolExp>;
};

export type Subscription_RootUserSessionEmailsArgs = {
  distinctOn?: InputMaybe<Array<UserSessionEmailsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserSessionEmailsOrderBy>>;
  where?: InputMaybe<UserSessionEmailsBoolExp>;
};

export type Subscription_RootUserSessionEmailsAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserSessionEmailsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserSessionEmailsOrderBy>>;
  where?: InputMaybe<UserSessionEmailsBoolExp>;
};

export type Subscription_RootUserSessionEmailsByPkArgs = {
  userClassEventId: Scalars['String'];
};

export type Subscription_RootUserSessionEmailsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<UserSessionEmailsStreamCursorInput>>;
  where?: InputMaybe<UserSessionEmailsBoolExp>;
};

export type Subscription_RootUsersArgs = {
  distinctOn?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};

export type Subscription_RootUsersAggregateArgs = {
  distinctOn?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};

export type Subscription_RootUsersByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUsersFunctionsArgs = {
  distinctOn?: InputMaybe<Array<UsersFunctionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersFunctionsOrderBy>>;
  where?: InputMaybe<UsersFunctionsBoolExp>;
};

export type Subscription_RootUsersFunctionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<UsersFunctionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersFunctionsOrderBy>>;
  where?: InputMaybe<UsersFunctionsBoolExp>;
};

export type Subscription_RootUsersFunctionsByPkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootUsersLengthInFunctionArgs = {
  distinctOn?: InputMaybe<Array<UsersLengthInFunctionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersLengthInFunctionOrderBy>>;
  where?: InputMaybe<UsersLengthInFunctionBoolExp>;
};

export type Subscription_RootUsersLengthInFunctionAggregateArgs = {
  distinctOn?: InputMaybe<Array<UsersLengthInFunctionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersLengthInFunctionOrderBy>>;
  where?: InputMaybe<UsersLengthInFunctionBoolExp>;
};

export type Subscription_RootUsersLengthInFunctionByPkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootUsersMetaArgs = {
  distinctOn?: InputMaybe<Array<UsersMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersMetaOrderBy>>;
  where?: InputMaybe<UsersMetaBoolExp>;
};

export type Subscription_RootUsersMetaAggregateArgs = {
  distinctOn?: InputMaybe<Array<UsersMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersMetaOrderBy>>;
  where?: InputMaybe<UsersMetaBoolExp>;
};

export type Subscription_RootUsersMetaByPkArgs = {
  userId: Scalars['uuid'];
};

export type Subscription_RootUsersMetaStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<UsersMetaStreamCursorInput>>;
  where?: InputMaybe<UsersMetaBoolExp>;
};

export type Subscription_RootZoomOauthTokensArgs = {
  distinctOn?: InputMaybe<Array<ZoomOauthTokensSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ZoomOauthTokensOrderBy>>;
  where?: InputMaybe<ZoomOauthTokensBoolExp>;
};

export type Subscription_RootZoomOauthTokensAggregateArgs = {
  distinctOn?: InputMaybe<Array<ZoomOauthTokensSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ZoomOauthTokensOrderBy>>;
  where?: InputMaybe<ZoomOauthTokensBoolExp>;
};

export type Subscription_RootZoomOauthTokensByPkArgs = {
  bearerToken: Scalars['String'];
};

export type Subscription_RootZoomOauthTokensStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomOauthTokensStreamCursorInput>>;
  where?: InputMaybe<ZoomOauthTokensBoolExp>;
};

export type TagsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TagsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TagsBoolExp>;
  predicate: IntComparisonExp;
};

export type TasksAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TasksSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TasksBoolExp>;
  predicate: IntComparisonExp;
};

export type TeamMember = {
  __typename?: 'teamMember';
  email: Scalars['String'];
  firstName: Scalars['String'];
  isActive: Scalars['Boolean'];
  jobFunction?: Maybe<Scalars['String']>;
  jobFunctionLength?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  role: Scalars['String'];
  teamId: Scalars['uuid'];
  teamIds: Scalars['jsonb'];
  teamName: Scalars['String'];
  teamNames: Scalars['jsonb'];
  userId: Scalars['uuid'];
};

export type TeamMemberTeamIdsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type TeamMemberTeamNamesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the logical model for "teamMember". All fields are combined with a logical 'AND'. */
export type TeamMemberBoolExpBoolExp = {
  _and?: InputMaybe<Array<TeamMemberBoolExpBoolExp>>;
  _not?: InputMaybe<TeamMemberBoolExpBoolExp>;
  _or?: InputMaybe<Array<TeamMemberBoolExpBoolExp>>;
  email?: InputMaybe<StringComparisonExp>;
  firstName?: InputMaybe<StringComparisonExp>;
  isActive?: InputMaybe<BooleanComparisonExp>;
  jobFunction?: InputMaybe<StringComparisonExp>;
  jobFunctionLength?: InputMaybe<StringComparisonExp>;
  lastName?: InputMaybe<StringComparisonExp>;
  role?: InputMaybe<StringComparisonExp>;
  teamId?: InputMaybe<UuidComparisonExp>;
  teamIds?: InputMaybe<JsonbComparisonExp>;
  teamName?: InputMaybe<StringComparisonExp>;
  teamNames?: InputMaybe<JsonbComparisonExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** Boolean expression to filter rows from the logical model for "teamMemberNew". All fields are combined with a logical 'AND'. */
export type TeamMemberNewBoolExpBoolExp = {
  _and?: InputMaybe<Array<TeamMemberNewBoolExpBoolExp>>;
  _not?: InputMaybe<TeamMemberNewBoolExpBoolExp>;
  _or?: InputMaybe<Array<TeamMemberNewBoolExpBoolExp>>;
  avatarUrl?: InputMaybe<StringComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  fullName?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isActive?: InputMaybe<BooleanComparisonExp>;
  role?: InputMaybe<StringComparisonExp>;
  teamId?: InputMaybe<UuidComparisonExp>;
};

/** Ordering options when selecting data from "teamMemberNew". */
export type TeamMemberNewOrderBy = {
  avatarUrl?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  fullName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isActive?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
};

export enum TeamMemberNew_Enum_Name {
  /** column name */
  avatarUrl = 'avatarUrl',
  /** column name */
  email = 'email',
  /** column name */
  fullName = 'fullName',
  /** column name */
  id = 'id',
  /** column name */
  isActive = 'isActive',
  /** column name */
  role = 'role',
  /** column name */
  teamId = 'teamId'
}

/** Ordering options when selecting data from "teamMember". */
export type TeamMemberOrderBy = {
  email?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  isActive?: InputMaybe<OrderBy>;
  jobFunction?: InputMaybe<OrderBy>;
  jobFunctionLength?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  teamIds?: InputMaybe<OrderBy>;
  teamName?: InputMaybe<OrderBy>;
  teamNames?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

export type TeamMemberPollSubmissions = {
  __typename?: 'teamMemberPollSubmissions';
  classEventId: Scalars['uuid'];
  cohortId: Scalars['uuid'];
  confidenceScore: Scalars['String'];
  courseId: Scalars['uuid'];
  engagementScore: Scalars['String'];
  formId: Scalars['String'];
  formName?: Maybe<Scalars['String']>;
  jobImpactScore: Scalars['String'];
  learnerUserId: Scalars['uuid'];
  npsScore: Scalars['String'];
  numDealsWhereApplied?: Maybe<Scalars['String']>;
  pollSubmissionId: Scalars['uuid'];
  submittedAt: Scalars['timestamp'];
  takeawayQuote: Scalars['String'];
  whichDeals?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the logical model for "teamMemberPollSubmissions". All fields are combined with a logical 'AND'. */
export type TeamMemberPollSubmissionsBoolExpBoolExp = {
  _and?: InputMaybe<Array<TeamMemberPollSubmissionsBoolExpBoolExp>>;
  _not?: InputMaybe<TeamMemberPollSubmissionsBoolExpBoolExp>;
  _or?: InputMaybe<Array<TeamMemberPollSubmissionsBoolExpBoolExp>>;
  classEventId?: InputMaybe<UuidComparisonExp>;
  cohortId?: InputMaybe<UuidComparisonExp>;
  confidenceScore?: InputMaybe<StringComparisonExp>;
  courseId?: InputMaybe<UuidComparisonExp>;
  engagementScore?: InputMaybe<StringComparisonExp>;
  formId?: InputMaybe<StringComparisonExp>;
  formName?: InputMaybe<StringComparisonExp>;
  jobImpactScore?: InputMaybe<StringComparisonExp>;
  learnerUserId?: InputMaybe<UuidComparisonExp>;
  npsScore?: InputMaybe<StringComparisonExp>;
  numDealsWhereApplied?: InputMaybe<StringComparisonExp>;
  pollSubmissionId?: InputMaybe<UuidComparisonExp>;
  submittedAt?: InputMaybe<TimestampComparisonExp>;
  takeawayQuote?: InputMaybe<StringComparisonExp>;
  whichDeals?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "teamMemberPollSubmissions". */
export type TeamMemberPollSubmissionsOrderBy = {
  classEventId?: InputMaybe<OrderBy>;
  cohortId?: InputMaybe<OrderBy>;
  confidenceScore?: InputMaybe<OrderBy>;
  courseId?: InputMaybe<OrderBy>;
  engagementScore?: InputMaybe<OrderBy>;
  formId?: InputMaybe<OrderBy>;
  formName?: InputMaybe<OrderBy>;
  jobImpactScore?: InputMaybe<OrderBy>;
  learnerUserId?: InputMaybe<OrderBy>;
  npsScore?: InputMaybe<OrderBy>;
  numDealsWhereApplied?: InputMaybe<OrderBy>;
  pollSubmissionId?: InputMaybe<OrderBy>;
  submittedAt?: InputMaybe<OrderBy>;
  takeawayQuote?: InputMaybe<OrderBy>;
  whichDeals?: InputMaybe<OrderBy>;
};

export enum TeamMemberPollSubmissions_Enum_Name {
  /** column name */
  classEventId = 'classEventId',
  /** column name */
  cohortId = 'cohortId',
  /** column name */
  confidenceScore = 'confidenceScore',
  /** column name */
  courseId = 'courseId',
  /** column name */
  engagementScore = 'engagementScore',
  /** column name */
  formId = 'formId',
  /** column name */
  formName = 'formName',
  /** column name */
  jobImpactScore = 'jobImpactScore',
  /** column name */
  learnerUserId = 'learnerUserId',
  /** column name */
  npsScore = 'npsScore',
  /** column name */
  numDealsWhereApplied = 'numDealsWhereApplied',
  /** column name */
  pollSubmissionId = 'pollSubmissionId',
  /** column name */
  submittedAt = 'submittedAt',
  /** column name */
  takeawayQuote = 'takeawayQuote',
  /** column name */
  whichDeals = 'whichDeals'
}

export enum TeamMember_Enum_Name {
  /** column name */
  email = 'email',
  /** column name */
  firstName = 'firstName',
  /** column name */
  isActive = 'isActive',
  /** column name */
  jobFunction = 'jobFunction',
  /** column name */
  jobFunctionLength = 'jobFunctionLength',
  /** column name */
  lastName = 'lastName',
  /** column name */
  role = 'role',
  /** column name */
  teamId = 'teamId',
  /** column name */
  teamIds = 'teamIds',
  /** column name */
  teamName = 'teamName',
  /** column name */
  teamNames = 'teamNames',
  /** column name */
  userId = 'userId'
}

export type Team_Flat_Tree_Node = {
  __typename?: 'team_flat_tree_node';
  branch: Scalars['String'];
  hasChildrenTeams: Scalars['Boolean'];
  jobFunction?: Maybe<Scalars['String']>;
  managerNames: Scalars['jsonb'];
  memberNames: Scalars['jsonb'];
  name: Scalars['String'];
  nodeHeight: Scalars['Int'];
  organizationId: Scalars['uuid'];
  parentId?: Maybe<Scalars['uuid']>;
  slug: Scalars['String'];
  teamId: Scalars['uuid'];
  treeId: Scalars['bigint'];
};

export type Team_Flat_Tree_NodeManagerNamesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type Team_Flat_Tree_NodeMemberNamesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the logical model for "team_flat_tree_node". All fields are combined with a logical 'AND'. */
export type Team_Flat_Tree_NodeBoolExpBoolExp = {
  _and?: InputMaybe<Array<Team_Flat_Tree_NodeBoolExpBoolExp>>;
  _not?: InputMaybe<Team_Flat_Tree_NodeBoolExpBoolExp>;
  _or?: InputMaybe<Array<Team_Flat_Tree_NodeBoolExpBoolExp>>;
  branch?: InputMaybe<StringComparisonExp>;
  hasChildrenTeams?: InputMaybe<BooleanComparisonExp>;
  jobFunction?: InputMaybe<StringComparisonExp>;
  managerNames?: InputMaybe<JsonbComparisonExp>;
  memberNames?: InputMaybe<JsonbComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  nodeHeight?: InputMaybe<IntComparisonExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  parentId?: InputMaybe<UuidComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  teamId?: InputMaybe<UuidComparisonExp>;
  treeId?: InputMaybe<BigintComparisonExp>;
};

/** Ordering options when selecting data from "team_flat_tree_node". */
export type Team_Flat_Tree_NodeOrderBy = {
  branch?: InputMaybe<OrderBy>;
  hasChildrenTeams?: InputMaybe<OrderBy>;
  jobFunction?: InputMaybe<OrderBy>;
  managerNames?: InputMaybe<OrderBy>;
  memberNames?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nodeHeight?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  parentId?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  treeId?: InputMaybe<OrderBy>;
};

export enum Team_Flat_Tree_Node_Enum_Name {
  /** column name */
  branch = 'branch',
  /** column name */
  hasChildrenTeams = 'hasChildrenTeams',
  /** column name */
  jobFunction = 'jobFunction',
  /** column name */
  managerNames = 'managerNames',
  /** column name */
  memberNames = 'memberNames',
  /** column name */
  name = 'name',
  /** column name */
  nodeHeight = 'nodeHeight',
  /** column name */
  organizationId = 'organizationId',
  /** column name */
  parentId = 'parentId',
  /** column name */
  slug = 'slug',
  /** column name */
  teamId = 'teamId',
  /** column name */
  treeId = 'treeId'
}

export type UserManagedTeamsFlattenedTree = {
  __typename?: 'userManagedTeamsFlattenedTree';
  branch: Scalars['String'];
  id: Scalars['uuid'];
  /** An array relationship */
  members?: Maybe<Array<GetTeamMembersNew>>;
  name: Scalars['String'];
  nodeHeight: Scalars['Int'];
  organizationId: Scalars['uuid'];
  parentId?: Maybe<Scalars['uuid']>;
  slug: Scalars['String'];
  teamJobFunction?: Maybe<Scalars['String']>;
  treeId: Scalars['bigint'];
};

export type UserManagedTeamsFlattenedTreeMembersArgs = {
  distinctOn?: InputMaybe<Array<TeamMemberNew_Enum_Name>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamMemberNewOrderBy>>;
  where?: InputMaybe<TeamMemberNewBoolExpBoolExp>;
};

/** Boolean expression to filter rows from the logical model for "userManagedTeamsFlattenedTree". All fields are combined with a logical 'AND'. */
export type UserManagedTeamsFlattenedTreeBoolExpBoolExp = {
  _and?: InputMaybe<Array<UserManagedTeamsFlattenedTreeBoolExpBoolExp>>;
  _not?: InputMaybe<UserManagedTeamsFlattenedTreeBoolExpBoolExp>;
  _or?: InputMaybe<Array<UserManagedTeamsFlattenedTreeBoolExpBoolExp>>;
  branch?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  nodeHeight?: InputMaybe<IntComparisonExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  parentId?: InputMaybe<UuidComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  teamJobFunction?: InputMaybe<StringComparisonExp>;
  treeId?: InputMaybe<BigintComparisonExp>;
};

/** Ordering options when selecting data from "userManagedTeamsFlattenedTree". */
export type UserManagedTeamsFlattenedTreeOrderBy = {
  branch?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nodeHeight?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  parentId?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  teamJobFunction?: InputMaybe<OrderBy>;
  treeId?: InputMaybe<OrderBy>;
};

/** userManagedTeamsFlattenedTreeNative Query Arguments */
export type UserManagedTeamsFlattenedTree_Arguments = {
  organizationId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

export enum UserManagedTeamsFlattenedTree_Enum_Name {
  /** column name */
  branch = 'branch',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  nodeHeight = 'nodeHeight',
  /** column name */
  organizationId = 'organizationId',
  /** column name */
  parentId = 'parentId',
  /** column name */
  slug = 'slug',
  /** column name */
  teamJobFunction = 'teamJobFunction',
  /** column name */
  treeId = 'treeId'
}

export type VersionImpactAssessmentArgs = {
  impact_assessment_slug?: InputMaybe<Scalars['String']>;
};

export type CoreEnrollmentCohortFragmentFragment = {
  __typename?: 'Cohorts';
  id: any;
  name: string;
  code: string;
  startDate?: any | undefined;
  classTimeOfDay?: any | undefined;
  classEvents: Array<{__typename?: 'ClassEvents'; id: any; startTime?: any | undefined}>;
  coaches: Array<{__typename?: 'CohortCoaches'; coach: {__typename?: 'Coaches'; name: string}}>;
  course?:
    | {
        __typename?: 'Courses';
        id: any;
        slug: string;
        name: string;
        type: CourseTypeEnum;
        school?: {__typename?: 'Schools'; name: string} | undefined;
      }
    | undefined;
};

export type CoreEnrollmentLearnerFragmentFragment = {
  __typename?: 'Users';
  id: any;
  firstName?: string | undefined;
  lastName?: string | undefined;
  identity: Array<{__typename?: 'Identities'; email?: string | undefined}>;
};

export type ComplexEnrollmentCohortFragmentFragment = {
  __typename?: 'Cohorts';
  id: any;
  name: string;
  code: string;
  startDate?: any | undefined;
  endDate?: any | undefined;
  dayOfTheWeek?: string | undefined;
  classTimeOfDay?: any | undefined;
  slug: string;
  type: CohortTypeEnum;
  coaches: Array<{__typename?: 'CohortCoaches'; coach: {__typename?: 'Coaches'; name: string}}>;
  course?:
    | {
        __typename?: 'Courses';
        name: string;
        backgroundImageUrl?: string | undefined;
        thumbnailCardUrl?: string | undefined;
        school?: {__typename?: 'Schools'; name: string} | undefined;
      }
    | undefined;
};

export type ImpactAssessmentAttemptFragment = {
  __typename?: 'ImpactAssessmentAttempts';
  id: any;
  learnerAnswers?: any | undefined;
  remainingTime?: any | undefined;
  status?: AttemptStatusEnum | undefined;
  userId?: any | undefined;
  cohortId: any;
  questions?:
    | Array<{
        __typename?: 'ImpactAssessmentQuestions';
        questionRich?: string | undefined;
        id?: any | undefined;
        impactAssessmentId?: any | undefined;
        answers?: any | undefined;
        type?: QuestionTypeEnum | undefined;
        points: number;
      }>
    | undefined;
};

export type ImpactAssessmentQuestionsFragment = {
  __typename?: 'ImpactAssessmentQuestions';
  questionRich?: string | undefined;
  id?: any | undefined;
  impactAssessmentId?: any | undefined;
  answers?: any | undefined;
  type?: QuestionTypeEnum | undefined;
  points: number;
};

export type CohortAssessmentDatesFragment = {
  __typename?: 'Cohorts';
  assessmentDueDate?: any | undefined;
  assessmentLiveDate?: any | undefined;
  isAssessmentLive?: boolean | undefined;
};

export type CohortSimpleFragmentFragment = {
  __typename?: 'Cohorts';
  id: any;
  name: string;
  slug: string;
  course?: {__typename?: 'Courses'; id: any; name: string; slug: string} | undefined;
};

export type LearnerHomeCohortsFragment = {
  __typename?: 'Cohorts';
  id: any;
  name: string;
  slug: string;
  startDate?: any | undefined;
  course?:
    | {
        __typename?: 'Courses';
        name: string;
        backgroundImageUrl?: string | undefined;
        tags: Array<{
          __typename?: 'CoursesTagsRel';
          tag?: {__typename?: 'Tags'; name: string; slug: string} | undefined;
        }>;
      }
    | undefined;
};

export type CourseRoleTagsFragment = {
  __typename?: 'Courses';
  tags: Array<{
    __typename?: 'CoursesTagsRel';
    tag?:
      | {
          __typename?: 'Tags';
          name: string;
          slug: string;
          tagType?: {__typename?: 'TagTypes'; slug: string} | undefined;
        }
      | undefined;
  }>;
};

export type ResourcesMetadataFragment = {
  __typename?: 'Resources';
  id: any;
  filename: string;
  url: string;
  name: string;
  descriptionRich?: string | undefined;
  type: ResourceTypeEnum;
  contentType: string;
  slug: string;
};

export type AddAssessmentAttemptMutationVariables = Exact<{
  input: ImpactAssessmentAttemptsInsertInput;
}>;

export type AddAssessmentAttemptMutation = {
  __typename?: 'mutation_root';
  assessmentAttempt?: {__typename?: 'ImpactAssessmentAttempts'; id: any} | undefined;
};

export type AddCourseCertificationMutationVariables = Exact<{
  input: CourseCertificationsInsertInput;
}>;

export type AddCourseCertificationMutation = {
  __typename?: 'mutation_root';
  courseCertification?: {__typename?: 'CourseCertifications'; id: any} | undefined;
};

export type ResumeAssessmentMutationVariables = Exact<{
  input: Scalars['uuid'];
}>;

export type ResumeAssessmentMutation = {
  __typename?: 'mutation_root';
  attempt?:
    | {__typename?: 'ResumeAssessmentOutput'; id: any; remainingTime?: any | undefined}
    | undefined;
};

export type SaveAssessmentMutationVariables = Exact<{
  attemptId: Scalars['uuid'];
  learnerAnswers: Scalars['json'];
  status?: InputMaybe<Scalars['String']>;
}>;

export type SaveAssessmentMutation = {
  __typename?: 'mutation_root';
  attempt?:
    | {
        __typename?: 'SaveAssessmentOutput';
        id: any;
        remainingTime?: any | undefined;
        startedAt?: any | undefined;
        status?: string | undefined;
      }
    | undefined;
};

export type StartAssessmentMutationVariables = Exact<{
  assessmentId: Scalars['uuid'];
  cohortSlug: Scalars['String'];
}>;

export type StartAssessmentMutation = {
  __typename?: 'mutation_root';
  attempt?:
    | {__typename?: 'StartAssessmentOutput'; impactAssessmentId: any; cohortSlug: string; id: any}
    | undefined;
};

export type SubmitAndGradeAssessmentMutationVariables = Exact<{
  input?: InputMaybe<Scalars['uuid']>;
}>;

export type SubmitAndGradeAssessmentMutation = {
  __typename?: 'mutation_root';
  SubmitAndGradeAssessment?:
    | {
        __typename?: 'SubmitAndGradeAssessmentOutput';
        impactAssessmentAttemptId: any;
        impactAssessmentId?: any | undefined;
        learnerScoreIsPassing?: boolean | undefined;
      }
    | undefined;
};

export type UpdateAssessmentAttemptMutationVariables = Exact<{
  id: ImpactAssessmentAttemptsPkColumnsInput;
  input: ImpactAssessmentAttemptsSetInput;
}>;

export type UpdateAssessmentAttemptMutation = {
  __typename?: 'mutation_root';
  assessmentAttempt?:
    | {
        __typename?: 'ImpactAssessmentAttempts';
        id: any;
        remainingTime?: any | undefined;
        startedAt?: any | undefined;
        status?: AttemptStatusEnum | undefined;
      }
    | undefined;
};

export type LogClassEventActivityMutationVariables = Exact<{
  input: ClassEventActivityInsertInput;
}>;

export type LogClassEventActivityMutation = {
  __typename?: 'mutation_root';
  insertClassEventActivityOne?: {__typename?: 'ClassEventActivity'; id: any} | undefined;
};

export type LogClassPlaybackActivityMutationVariables = Exact<{
  input: ClassPlaybackActivityInsertInput;
}>;

export type LogClassPlaybackActivityMutation = {
  __typename?: 'mutation_root';
  insertClassPlaybackActivityOne?: {__typename?: 'ClassPlaybackActivity'; id: any} | undefined;
};

export type RemoveClassEventActivityMutationVariables = Exact<{
  userId: Scalars['uuid'];
  classEventId: Scalars['uuid'];
  type?: InputMaybe<Array<ActivityTypeEnum> | ActivityTypeEnum>;
}>;

export type RemoveClassEventActivityMutation = {
  __typename?: 'mutation_root';
  deleteClassEventActivity?:
    | {__typename?: 'ClassEventActivityMutationResponse'; affectedRows: number}
    | undefined;
};

export type SetClassStatusMutationVariables = Exact<{
  classEventId: Scalars['uuid'];
  context: SetClassStatusContext;
}>;

export type SetClassStatusMutation = {
  __typename?: 'mutation_root';
  SetClassStatus?: {__typename?: 'SetClassStatusOutput'; done: boolean} | undefined;
};

export type UpdateClassEventActivityMutationVariables = Exact<{
  id: Scalars['uuid'];
  updateObject?: InputMaybe<ClassEventActivitySetInput>;
}>;

export type UpdateClassEventActivityMutation = {
  __typename?: 'mutation_root';
  updateClassEventActivityByPk?: {__typename?: 'ClassEventActivity'; id: any} | undefined;
};

export type UpdateClassPlaybackActivityMutationVariables = Exact<{
  id: Scalars['uuid'];
  updateObject?: InputMaybe<ClassPlaybackActivitySetInput>;
}>;

export type UpdateClassPlaybackActivityMutation = {
  __typename?: 'mutation_root';
  updateClassPlaybackActivityByPk?: {__typename?: 'ClassPlaybackActivity'; id: any} | undefined;
};

export type AddClassEventInvitationsMutationVariables = Exact<{
  classEventInvites: Array<ClassEventInvitationsInsertInput> | ClassEventInvitationsInsertInput;
}>;

export type AddClassEventInvitationsMutation = {
  __typename?: 'mutation_root';
  insertClassEventInvitations?:
    | {
        __typename?: 'ClassEventInvitationsMutationResponse';
        returning: Array<{__typename?: 'ClassEventInvitations'; id: any}>;
      }
    | undefined;
};

export type BeginClassFromClientMutationVariables = Exact<{
  classEventId: Scalars['uuid'];
}>;

export type BeginClassFromClientMutation = {
  __typename?: 'mutation_root';
  BeginClass?:
    | {
        __typename?: 'SuccessOutput';
        success: boolean;
        details?: Array<string | undefined> | undefined;
      }
    | undefined;
};

export type CreateAnonRegistrationMutationVariables = Exact<{
  classEventId: Scalars['uuid'];
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  zoomJoinLinkUrl?: InputMaybe<Scalars['String']>;
}>;

export type CreateAnonRegistrationMutation = {
  __typename?: 'mutation_root';
  insertClassEventAnonRegistrationsOne?:
    | {__typename?: 'ClassEventAnonRegistrations'; token: string}
    | undefined;
};

export type CreateClassEventMeetingMutationVariables = Exact<{
  meeting: ClassEventMeetingsInsertInput;
}>;

export type CreateClassEventMeetingMutation = {
  __typename?: 'mutation_root';
  insertClassEventMeetingsOne?: {__typename?: 'ClassEventMeetings'; id: any} | undefined;
};

export type CreateLightingSessionMutationVariables = Exact<{
  meeting: ClassEventMeetingsInsertInput;
  ls: LightningSessionInsertInput;
}>;

export type CreateLightingSessionMutation = {
  __typename?: 'mutation_root';
  insertClassEventMeetingsOne?: {__typename?: 'ClassEventMeetings'; id: any} | undefined;
  insertLightningSessionOne?: {__typename?: 'LightningSession'; id: any} | undefined;
};

export type DeleteZoomMeetingMutationVariables = Exact<{
  classEventId: Scalars['uuid'];
}>;

export type DeleteZoomMeetingMutation = {
  __typename?: 'mutation_root';
  updateClassEventAnonRegistrations?:
    | {__typename?: 'ClassEventAnonRegistrationsMutationResponse'; affectedRows: number}
    | undefined;
  deleteClassEventRegistrations?:
    | {__typename?: 'ClassEventRegistrationsMutationResponse'; affectedRows: number}
    | undefined;
  updateClassEventsByPk?:
    | {
        __typename?: 'ClassEvents';
        id: any;
        zoomMeetingId?: string | undefined;
        zoomLinkUrl?: string | undefined;
      }
    | undefined;
};

export type EndClassFromClientMutationVariables = Exact<{
  classEventId: Scalars['uuid'];
}>;

export type EndClassFromClientMutation = {
  __typename?: 'mutation_root';
  EndClass?:
    | {
        __typename?: 'SuccessOutput';
        success: boolean;
        details?: Array<string | undefined> | undefined;
      }
    | undefined;
};

export type EndLightingSessionFromClientMutationVariables = Exact<{
  classEventId: Scalars['uuid'];
  countdownMinutes?: InputMaybe<Scalars['bigint']>;
}>;

export type EndLightingSessionFromClientMutation = {
  __typename?: 'mutation_root';
  LightningSession?:
    | {
        __typename?: 'SuccessOutput';
        success: boolean;
        details?: Array<string | undefined> | undefined;
      }
    | undefined;
};

export type LaunchClassSurveyFromClientMutationVariables = Exact<{
  classEventId: Scalars['uuid'];
}>;

export type LaunchClassSurveyFromClientMutation = {
  __typename?: 'mutation_root';
  LaunchClassSurvey?:
    | {
        __typename?: 'SuccessOutput';
        success: boolean;
        details?: Array<string | undefined> | undefined;
      }
    | undefined;
};

export type RegisterAnonMutationVariables = Exact<{
  classEventId: Scalars['uuid'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
}>;

export type RegisterAnonMutation = {
  __typename?: 'mutation_root';
  RegisterAnon?:
    | {
        __typename?: 'RegisterAnonOutput';
        success: boolean;
        code?: string | undefined;
        joinLinkUrl?: string | undefined;
      }
    | undefined;
};

export type SetLightningSessionEndTimeMutationVariables = Exact<{
  classEventId: Scalars['uuid'];
  endTime: Scalars['timestamp'];
}>;

export type SetLightningSessionEndTimeMutation = {
  __typename?: 'mutation_root';
  updateLightningSession?:
    | {__typename?: 'LightningSessionMutationResponse'; affectedRows: number}
    | undefined;
};

export type SignTokenMutationVariables = Exact<{
  classEventId: Scalars['uuid'];
  roomName: Scalars['String'];
}>;

export type SignTokenMutation = {
  __typename?: 'mutation_root';
  SignToken?:
    | {
        __typename?: 'SuccessOutput';
        success: boolean;
        details?: Array<string | undefined> | undefined;
      }
    | undefined;
};

export type StartLightingSessionFromClientMutationVariables = Exact<{
  classEventId: Scalars['uuid'];
  rooms: Array<InputMaybe<RoomInput>> | InputMaybe<RoomInput>;
}>;

export type StartLightingSessionFromClientMutation = {
  __typename?: 'mutation_root';
  LightningSession?:
    | {
        __typename?: 'SuccessOutput';
        success: boolean;
        details?: Array<string | undefined> | undefined;
      }
    | undefined;
};

export type UpdateClassEventMutationVariables = Exact<{
  id: Scalars['uuid'];
  data: ClassEventsSetInput;
}>;

export type UpdateClassEventMutation = {
  __typename?: 'mutation_root';
  updateClassEventsByPk?: {__typename?: 'ClassEvents'; id: any} | undefined;
};

export type UpdateClassEventMeetingAltRecordingMutationVariables = Exact<{
  meetingId: Scalars['uuid'];
  altRecordingUrl?: InputMaybe<Scalars['String']>;
}>;

export type UpdateClassEventMeetingAltRecordingMutation = {
  __typename?: 'mutation_root';
  updateClassEventMeetingsByPk?: {__typename?: 'ClassEventMeetings'; id: any} | undefined;
};

export type UpdateClassEventMeetingSegmentMutationVariables = Exact<{
  id: Scalars['uuid'];
  jobs?: InputMaybe<Scalars['jsonb']>;
  videoClipUrl?: InputMaybe<Scalars['String']>;
  transcription?: InputMaybe<Scalars['jsonb']>;
  synced?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdateClassEventMeetingSegmentMutation = {
  __typename?: 'mutation_root';
  updateClassEventMeetingSegmentsByPk?:
    | {
        __typename?: 'ClassEventMeetingSegments';
        id: any;
        jobs?: any | undefined;
        videoClipUrl?: string | undefined;
        transcription?: any | undefined;
        synced?: boolean | undefined;
      }
    | undefined;
};

export type UpdateClassEventMeetingTranscodeJobMutationVariables = Exact<{
  meetingId: Scalars['uuid'];
  awsMediaConvertJobId?: InputMaybe<Scalars['String']>;
}>;

export type UpdateClassEventMeetingTranscodeJobMutation = {
  __typename?: 'mutation_root';
  updateClassEventMeetingsByPk?: {__typename?: 'ClassEventMeetings'; id: any} | undefined;
};

export type UpdateClassEventMeetingMutationVariables = Exact<{
  meetingId: Scalars['uuid'];
  dailySessionId: Scalars['uuid'];
  recordingUrl?: InputMaybe<Scalars['String']>;
  posterFrameUrl?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  maxParticipants?: InputMaybe<Scalars['Int']>;
  startTime?: InputMaybe<Scalars['timestamp']>;
  participants:
    | Array<ClassEventMeetingParticipantsInsertInput>
    | ClassEventMeetingParticipantsInsertInput;
}>;

export type UpdateClassEventMeetingMutation = {
  __typename?: 'mutation_root';
  updateClassEventMeetings?:
    | {__typename?: 'ClassEventMeetingsMutationResponse'; affectedRows: number}
    | undefined;
  upsertMeetingParticipants?:
    | {__typename?: 'ClassEventMeetingParticipantsMutationResponse'; affectedRows: number}
    | undefined;
};

export type UpdateClassEventRecordingUrlMutationVariables = Exact<{
  classEventId: Scalars['uuid'];
  recordingUrl?: InputMaybe<Scalars['String']>;
}>;

export type UpdateClassEventRecordingUrlMutation = {
  __typename?: 'mutation_root';
  updateClassEventsByPk?: {__typename?: 'ClassEvents'; id: any} | undefined;
};

export type UpdateLightningSessionBorDetailsMutationVariables = Exact<{
  id: Scalars['uuid'];
  borDetail?: InputMaybe<Scalars['jsonb']>;
}>;

export type UpdateLightningSessionBorDetailsMutation = {
  __typename?: 'mutation_root';
  updateLightningSessionByPk?:
    | {__typename?: 'LightningSession'; borDetails?: any | undefined}
    | undefined;
};

export type AddUserToCourseWaitlistMutationVariables = Exact<{
  input: CourseWaitlistInput;
}>;

export type AddUserToCourseWaitlistMutation = {
  __typename?: 'mutation_root';
  AddUserToCourseWaitlist?:
    | {
        __typename?: 'AddUserToCourseWaitlistOutput';
        success: boolean;
        details?: Array<string | undefined> | undefined;
      }
    | undefined;
};

export type InsertCourseWaitlistMutationVariables = Exact<{
  objects: Array<CourseWaitlistInsertInput> | CourseWaitlistInsertInput;
}>;

export type InsertCourseWaitlistMutation = {
  __typename?: 'mutation_root';
  insertCourseWaitlist?:
    | {
        __typename?: 'CourseWaitlistMutationResponse';
        affectedRows: number;
        returning: Array<{__typename?: 'CourseWaitlist'; id: any; userId: any}>;
      }
    | undefined;
};

export type InsertCourseWaitlistOneMutationVariables = Exact<{
  courseSlug: Scalars['String'];
  userId: Scalars['uuid'];
  time?: InputMaybe<Scalars['String']>;
  urgency?: InputMaybe<CourseWaitlistDesiredUrgencyTypeEnum>;
  createdById: Scalars['uuid'];
  createdAt?: InputMaybe<Scalars['timestamp']>;
  orgId?: InputMaybe<Scalars['uuid']>;
}>;

export type InsertCourseWaitlistOneMutation = {
  __typename?: 'mutation_root';
  insertCourseWaitlistOne?: {__typename?: 'CourseWaitlist'; id: any} | undefined;
};

export type SoftDeleteCourseWaitlistManyMutationVariables = Exact<{
  updates: Array<CourseWaitlistUpdates> | CourseWaitlistUpdates;
}>;

export type SoftDeleteCourseWaitlistManyMutation = {
  __typename?: 'mutation_root';
  updateCourseWaitlistMany?:
    | Array<
        | {
            __typename?: 'CourseWaitlistMutationResponse';
            affectedRows: number;
            returning: Array<{
              __typename?: 'CourseWaitlist';
              id: any;
              deletedAt?: any | undefined;
              deletedById?: any | undefined;
              user?:
                | {
                    __typename?: 'Users';
                    id: any;
                    firstName?: string | undefined;
                    lastName?: string | undefined;
                  }
                | undefined;
            }>;
          }
        | undefined
      >
    | undefined;
};

export type UpdateBeforeClassReminderEmailMutationVariables = Exact<{
  userClassEventId?: InputMaybe<Scalars['String']>;
  sentHourReminderEmailAt?: InputMaybe<Scalars['timestamptz']>;
}>;

export type UpdateBeforeClassReminderEmailMutation = {
  __typename?: 'mutation_root';
  updateUserSessionEmailsByPk?:
    | {__typename?: 'UserSessionEmails'; userClassEventId: string}
    | undefined;
};

export type UpdateEmailsQueueProcessedStatusMutationVariables = Exact<{
  id: Scalars['uuid'];
  status?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdateEmailsQueueProcessedStatusMutation = {
  __typename?: 'mutation_root';
  updateEmailQueueByPk?:
    | {__typename?: 'EmailQueue'; id: any; processed: boolean; processedAt?: string | undefined}
    | undefined;
};

export type UpdateManagerPostSessionEmailMutationVariables = Exact<{
  managerCohortId?: InputMaybe<Scalars['String']>;
  sentPostSessionEmailAt?: InputMaybe<Scalars['timestamptz']>;
}>;

export type UpdateManagerPostSessionEmailMutation = {
  __typename?: 'mutation_root';
  updateManagerSessionEmailsByPk?:
    | {__typename?: 'ManagerSessionEmails'; managerCohortId: string}
    | undefined;
};

export type UpdateManagerPreSessionEmailMutationVariables = Exact<{
  managerCohortId?: InputMaybe<Scalars['String']>;
  sentPreSessionEmailAt?: InputMaybe<Scalars['timestamptz']>;
}>;

export type UpdateManagerPreSessionEmailMutation = {
  __typename?: 'mutation_root';
  updateManagerSessionEmailsByPk?:
    | {__typename?: 'ManagerSessionEmails'; managerCohortId: string}
    | undefined;
};

export type UpdateMondayReminderEmailMutationVariables = Exact<{
  userClassEventId?: InputMaybe<Scalars['String']>;
  sentMondayReminderEmailAt?: InputMaybe<Scalars['timestamptz']>;
}>;

export type UpdateMondayReminderEmailMutation = {
  __typename?: 'mutation_root';
  updateUserSessionEmailsByPk?:
    | {__typename?: 'UserSessionEmails'; userClassEventId: string}
    | undefined;
};

export type AddLearnerToEnrollmentsMutationVariables = Exact<{
  object: EnrollmentsInsertInput;
}>;

export type AddLearnerToEnrollmentsMutation = {
  __typename?: 'mutation_root';
  enrollment?: {__typename?: 'Enrollments'; enrollmentId: any} | undefined;
};

export type AddLearnerToWaitlistMutationVariables = Exact<{
  object: EnrollmentWaitlistsInsertInput;
}>;

export type AddLearnerToWaitlistMutation = {
  __typename?: 'mutation_root';
  insertEnrollmentWaitlistsOne?: {__typename?: 'EnrollmentWaitlists'; id: any} | undefined;
};

export type AddLearnersToWaitlistMutationVariables = Exact<{
  objects: Array<EnrollmentWaitlistsInsertInput> | EnrollmentWaitlistsInsertInput;
}>;

export type AddLearnersToWaitlistMutation = {
  __typename?: 'mutation_root';
  insertEnrollmentWaitlists?:
    | {__typename?: 'EnrollmentWaitlistsMutationResponse'; affectedRows: number}
    | undefined;
};

export type CreateClassEventRegistrationMutationVariables = Exact<{
  object: ClassEventRegistrationsInsertInput;
}>;

export type CreateClassEventRegistrationMutation = {
  __typename?: 'mutation_root';
  insertClassEventRegistrationsOne?: {__typename?: 'ClassEventRegistrations'; id: any} | undefined;
};

export type DeleteWaitlistEntryMutationVariables = Exact<{
  id: Scalars['uuid'];
  deletedBy: Scalars['uuid'];
}>;

export type DeleteWaitlistEntryMutation = {
  __typename?: 'mutation_root';
  updateEnrollmentWaitlistsByPk?: {__typename: 'EnrollmentWaitlists'} | undefined;
};

export type DequeueEnrollmentWaitlistMutationVariables = Exact<{
  cohortId: Scalars['uuid'];
  learnerUserIds: Array<Scalars['uuid']> | Scalars['uuid'];
  deletedBy: Scalars['uuid'];
  newEnrollments: Array<EnrollmentsInsertInput> | EnrollmentsInsertInput;
}>;

export type DequeueEnrollmentWaitlistMutation = {
  __typename?: 'mutation_root';
  updateEnrollmentWaitlists?:
    | {__typename?: 'EnrollmentWaitlistsMutationResponse'; affectedRows: number}
    | undefined;
  insertEnrollments?:
    | {__typename?: 'EnrollmentsMutationResponse'; affectedRows: number}
    | undefined;
};

export type EnrollLearnerMutationVariables = Exact<{
  cohortId: Scalars['uuid'];
  waitlistEnabled?: InputMaybe<Scalars['Boolean']>;
}>;

export type EnrollLearnerMutation = {
  __typename?: 'mutation_root';
  EnrollLearner?:
    | {__typename?: 'EnrollOutput'; enrollmentId?: any | undefined; waitlistId?: any | undefined}
    | undefined;
};

export type EnrollTeamMemberMutationVariables = Exact<{
  userId: Scalars['uuid'];
  cohortId: Scalars['uuid'];
  waitlistEnabled?: InputMaybe<Scalars['Boolean']>;
}>;

export type EnrollTeamMemberMutation = {
  __typename?: 'mutation_root';
  EnrollLearner?:
    | {__typename?: 'EnrollOutput'; enrollmentId?: any | undefined; waitlistId?: any | undefined}
    | undefined;
};

export type RemoveLearnerFromEnrollmentsMutationVariables = Exact<{
  enrollmentId: Scalars['uuid'];
  deletedById: Scalars['uuid'];
  reason?: InputMaybe<Scalars['String']>;
  onBehalfOfUserId?: InputMaybe<Scalars['uuid']>;
}>;

export type RemoveLearnerFromEnrollmentsMutation = {
  __typename?: 'mutation_root';
  enrollment?: {__typename?: 'Enrollments'; enrollmentId: any} | undefined;
};

export type UnenrollLearnerMutationVariables = Exact<{
  cohortId: Scalars['uuid'];
  userId?: InputMaybe<Scalars['uuid']>;
  onBehalfOfUserId?: InputMaybe<Scalars['uuid']>;
  reason?: InputMaybe<Scalars['String']>;
  waitlistEnabled?: InputMaybe<Scalars['Boolean']>;
}>;

export type UnenrollLearnerMutation = {
  __typename?: 'mutation_root';
  UnenrollLearner?:
    | {
        __typename?: 'UnenrollOutput';
        enrollmentId?: any | undefined;
        waitlistId?: any | undefined;
        unenrollmentStatus?: boolean | undefined;
      }
    | undefined;
};

export type UnenrollTeamMemberMutationVariables = Exact<{
  userId: Scalars['uuid'];
  cohortId: Scalars['uuid'];
  reason?: InputMaybe<Scalars['String']>;
  waitlistEnabled?: InputMaybe<Scalars['Boolean']>;
}>;

export type UnenrollTeamMemberMutation = {
  __typename?: 'mutation_root';
  UnenrollLearner?:
    | {
        __typename?: 'UnenrollOutput';
        unenrollmentStatus?: boolean | undefined;
        enrollmentId?: any | undefined;
        waitlistId?: any | undefined;
      }
    | undefined;
};

export type UpdateJourneyEnrollmentMutationVariables = Exact<{
  id: Scalars['uuid'];
  nextTaskId: Scalars['uuid'];
  nextTaskAt: Scalars['timestamp'];
  isComplete?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdateJourneyEnrollmentMutation = {
  __typename?: 'mutation_root';
  updateJourneyEnrollmentsByPk?: {__typename?: 'JourneyEnrollments'; id: any} | undefined;
};

export type AddLedgerLineItemMutationVariables = Exact<{
  input: LedgerInsertInput;
}>;

export type AddLedgerLineItemMutation = {
  __typename?: 'mutation_root';
  insertLedgerOne?: {__typename: 'Ledger'} | undefined;
};

export type AddCoachToLightningSessionSubmissionMutationVariables = Exact<{
  coachUserId: Scalars['uuid'];
  submissionId: Scalars['uuid'];
}>;

export type AddCoachToLightningSessionSubmissionMutation = {
  __typename?: 'mutation_root';
  updateLightningSessionSubmissionsByPk?:
    | {__typename?: 'LightningSessionSubmissions'; id: any}
    | undefined;
  insertLightningSessionSubmissionsUsersRelOne?:
    | {__typename?: 'LightningSessionSubmissionsUsersRel'; lightningSessionSubmissionId: any}
    | undefined;
};

export type AddUserToLightningSessionSubmissionUsersRelMutationVariables = Exact<{
  userId: Scalars['uuid'];
  submissionId: Scalars['uuid'];
}>;

export type AddUserToLightningSessionSubmissionUsersRelMutation = {
  __typename?: 'mutation_root';
  insertLightningSessionSubmissionsUsersRel?:
    | {__typename?: 'LightningSessionSubmissionsUsersRelMutationResponse'; affectedRows: number}
    | undefined;
};

export type ChangeLightningSessionSubmissionScoreDataMutationVariables = Exact<{
  submissionId: Scalars['uuid'];
  score?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
}>;

export type ChangeLightningSessionSubmissionScoreDataMutation = {
  __typename?: 'mutation_root';
  updateLightningSessionSubmissionsByPk?:
    | {__typename?: 'LightningSessionSubmissions'; id: any}
    | undefined;
};

export type ChangeLightningSessionSubmissionStatusMutationVariables = Exact<{
  submissionId: Scalars['uuid'];
  status: LightningSessionSubmissionsStatusEnum;
  interactionsCount?: InputMaybe<Scalars['Int']>;
}>;

export type ChangeLightningSessionSubmissionStatusMutation = {
  __typename?: 'mutation_root';
  updateLightningSessionSubmissionsByPk?:
    | {__typename?: 'LightningSessionSubmissions'; id: any}
    | undefined;
};

export type CompleteAndScoreLightningSessionSubmissionMutationVariables = Exact<{
  submissionId: Scalars['uuid'];
  score?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
}>;

export type CompleteAndScoreLightningSessionSubmissionMutation = {
  __typename?: 'mutation_root';
  updateLightningSessionSubmissionsByPk?:
    | {__typename?: 'LightningSessionSubmissions'; id: any}
    | undefined;
};

export type CompleteLightningSessionSubmissionStatusMutationVariables = Exact<{
  submissionId: Scalars['uuid'];
}>;

export type CompleteLightningSessionSubmissionStatusMutation = {
  __typename?: 'mutation_root';
  updateLightningSessionSubmissionsByPk?:
    | {__typename?: 'LightningSessionSubmissions'; id: any}
    | undefined;
};

export type DeleteLightningSessionSubmissionMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type DeleteLightningSessionSubmissionMutation = {
  __typename?: 'mutation_root';
  deleteLightningSessionSubmissionsUsersRel?:
    | {__typename?: 'LightningSessionSubmissionsUsersRelMutationResponse'; affectedRows: number}
    | undefined;
  deleteLightningSessionSubmissions?:
    | {__typename?: 'LightningSessionSubmissionsMutationResponse'; affectedRows: number}
    | undefined;
};

export type GetLightningSessionSubmissionByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetLightningSessionSubmissionByIdQuery = {
  __typename?: 'query_root';
  lightningSessionSubmissionsByPk?:
    | {
        __typename?: 'LightningSessionSubmissions';
        id: any;
        userId: any;
        status: LightningSessionSubmissionsStatusEnum;
        streamChannelId: string;
        cohortId: any;
        coachUserId?: any | undefined;
        user: {
          __typename?: 'Users';
          id: any;
          firstName?: string | undefined;
          lastName?: string | undefined;
          fullName?: string | undefined;
          identity: Array<{__typename?: 'Identities'; email?: string | undefined}>;
        };
        coach?:
          | {
              __typename?: 'Users';
              id: any;
              organizationUsers: Array<{
                __typename?: 'OrganizationUsers';
                id: any;
                user: {
                  __typename?: 'Users';
                  id: any;
                  firstName?: string | undefined;
                  lastName?: string | undefined;
                  fullName?: string | undefined;
                  identity: Array<{__typename?: 'Identities'; email?: string | undefined}>;
                };
              }>;
            }
          | undefined;
        cohort: {
          __typename?: 'Cohorts';
          id: any;
          slug: string;
          course?: {__typename?: 'Courses'; id: any; type: CourseTypeEnum} | undefined;
        };
      }
    | undefined;
};

export type GetLightningSessionSubmissionByUserAndCohortIdsQueryVariables = Exact<{
  userId: Scalars['uuid'];
  cohortId: Scalars['uuid'];
}>;

export type GetLightningSessionSubmissionByUserAndCohortIdsQuery = {
  __typename?: 'query_root';
  lightningSessionSubmissions: Array<{
    __typename?: 'LightningSessionSubmissions';
    id: any;
    userId: any;
    status: LightningSessionSubmissionsStatusEnum;
    streamChannelId: string;
    cohortId: any;
    coachUserId?: any | undefined;
    cohort: {
      __typename?: 'Cohorts';
      communitySlackChannelUrl?: string | undefined;
      communitySlackInviteUrl?: string | undefined;
      courseId?: any | undefined;
      id: any;
      name: string;
      slug: string;
      surveyLinkUrl?: string | undefined;
      type: CohortTypeEnum;
      isPostCourseSurveyAvailable?: boolean | undefined;
      preworkRichText?: string | undefined;
      preworkVideoUrl?: string | undefined;
      requiresPractice: boolean;
      startDate?: any | undefined;
      endDate?: any | undefined;
      classTimeOfDay?: any | undefined;
      course?:
        | {
            __typename?: 'Courses';
            id: any;
            type: CourseTypeEnum;
            name: string;
            slug: string;
            preCourseSurveyFormId?: string | undefined;
            postCourseSurveyFormId?: string | undefined;
            impactAssessment?: {__typename?: 'ImpactAssessments'; id: any} | undefined;
          }
        | undefined;
    };
    pitchSegment?:
      | {
          __typename?: 'ClassEventMeetingSegments';
          startTimecode: number;
          endTimecode: number;
          createdAt?: any | undefined;
          videoClipUrl?: string | undefined;
          classEventMeeting: {__typename?: 'ClassEventMeetings'; recordingUrl?: string | undefined};
        }
      | undefined;
    user: {
      __typename?: 'Users';
      id: any;
      firstName?: string | undefined;
      lastName?: string | undefined;
      imageUrl?: string | undefined;
    };
  }>;
};

export type SoftDeleteLightningSessionSubmissionMutationVariables = Exact<{
  id: Scalars['uuid'];
  deletedAt?: InputMaybe<Scalars['timestamp']>;
  deletedBy?: InputMaybe<Scalars['uuid']>;
}>;

export type SoftDeleteLightningSessionSubmissionMutation = {
  __typename?: 'mutation_root';
  updateLightningSessionSubmissionsByPk?:
    | {__typename?: 'LightningSessionSubmissions'; id: any}
    | undefined;
};

export type UpdateLightningSessionSubmissionStreamChannelIdMutationVariables = Exact<{
  id: Scalars['uuid'];
  channelId?: InputMaybe<Scalars['String']>;
}>;

export type UpdateLightningSessionSubmissionStreamChannelIdMutation = {
  __typename?: 'mutation_root';
  updateLightningSessionSubmissionsByPk?: {__typename: 'LightningSessionSubmissions'} | undefined;
};

export type UpdateLightningSubmissionEmailFlagsMutationVariables = Exact<{
  id: Scalars['uuid'];
  flags?: InputMaybe<LightningSessionSubmissionsSetInput>;
}>;

export type UpdateLightningSubmissionEmailFlagsMutation = {
  __typename?: 'mutation_root';
  updateLightningSessionSubmissions?:
    | {__typename?: 'LightningSessionSubmissionsMutationResponse'; affectedRows: number}
    | undefined;
};

export type UpsertLightningSessionSubmissionMutationVariables = Exact<{
  object: LightningSessionSubmissionsInsertInput;
}>;

export type UpsertLightningSessionSubmissionMutation = {
  __typename?: 'mutation_root';
  insertLightningSessionSubmissionsOne?:
    | {__typename?: 'LightningSessionSubmissions'; id: any}
    | undefined;
};

export type GetNonceAccessTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type GetNonceAccessTokenQuery = {
  __typename?: 'query_root';
  nonceAccessToken: Array<{
    __typename?: 'NonceAccessToken';
    id: any;
    token: string;
    createdAt: any;
    expirationDate: any;
    targetResource: string;
  }>;
};

export type InsertNonceAccessTokenMutationVariables = Exact<{
  object: NonceAccessTokenInsertInput;
}>;

export type InsertNonceAccessTokenMutation = {
  __typename?: 'mutation_root';
  insertNonceAccessTokenOne?: {__typename?: 'NonceAccessToken'; id: any; token: string} | undefined;
};

export type FindRootTeamQueryVariables = Exact<{
  organizationId?: InputMaybe<Scalars['uuid']>;
}>;

export type FindRootTeamQuery = {
  __typename?: 'query_root';
  teams: Array<{
    __typename?: 'Teams';
    id: any;
    organization?: {__typename?: 'Organizations'; id: any; name: string} | undefined;
  }>;
};

export type SetOrganizationMetaMutationVariables = Exact<{
  object: OrganizationsMetaInsertInput;
}>;

export type SetOrganizationMetaMutation = {
  __typename?: 'mutation_root';
  insertOrganizationsMetaOne?:
    | {__typename?: 'OrganizationsMeta'; salesforceAccountId: string}
    | undefined;
};

export type CreateSubscriptionMutationVariables = Exact<{
  organizationId: Scalars['uuid'];
  planId: Scalars['uuid'];
  notes?: InputMaybe<Scalars['String']>;
  createdById: Scalars['uuid'];
}>;

export type CreateSubscriptionMutation = {
  __typename?: 'mutation_root';
  insertPlanSubscriptionsOne?: {__typename?: 'PlanSubscriptions'; id: any} | undefined;
};

export type AddPlayExecutionMutationVariables = Exact<{
  object: PlayExecutionInsertInput;
}>;

export type AddPlayExecutionMutation = {
  __typename?: 'mutation_root';
  insertPlayExecutionOne?: {__typename?: 'PlayExecution'; id: any} | undefined;
};

export type RunPlayMutationVariables = Exact<{
  playName: Scalars['String'];
  data?: InputMaybe<Scalars['jsonb']>;
}>;

export type RunPlayMutation = {
  __typename?: 'mutation_root';
  RunPlay?: {__typename?: 'RunPlayOutput'; success: boolean} | undefined;
};

export type PlayAddClassEventToCohortMutationVariables = Exact<{
  object: ClassEventsInsertInput;
}>;

export type PlayAddClassEventToCohortMutation = {
  __typename?: 'mutation_root';
  insertClassEventsOne?: {__typename?: 'ClassEvents'; id: any} | undefined;
};

export type PlayAddCoachToCohortMutationVariables = Exact<{
  objects: Array<CohortCoachesInsertInput> | CohortCoachesInsertInput;
}>;

export type PlayAddCoachToCohortMutation = {
  __typename?: 'mutation_root';
  insertCohortCoaches?:
    | {__typename?: 'CohortCoachesMutationResponse'; affectedRows: number}
    | undefined;
};

export type PlayAddCourseToCatalogMutationVariables = Exact<{
  courseId: Scalars['uuid'];
  catalogId: Scalars['uuid'];
}>;

export type PlayAddCourseToCatalogMutation = {
  __typename?: 'mutation_root';
  insertCatalogCoursesOne?:
    | {__typename?: 'CatalogCourses'; courseId: any; catalogId: any}
    | undefined;
};

export type PlayAddCourseToTagMutationVariables = Exact<{
  courseId: Scalars['uuid'];
  tagId: Scalars['uuid'];
  courseSlug: Scalars['String'];
}>;

export type PlayAddCourseToTagMutation = {
  __typename?: 'mutation_root';
  insertCoursesTagsRelOne?: {__typename?: 'CoursesTagsRel'; courseId: any; tagId: any} | undefined;
};

export type PlayCreateClassMutationVariables = Exact<{
  name: Scalars['String'];
  slug: Scalars['String'];
  number: Scalars['Int'];
  duration: Scalars['Int'];
  courseId: Scalars['uuid'];
  desc: Scalars['String'];
  listDescription: Scalars['json'];
}>;

export type PlayCreateClassMutation = {
  __typename?: 'mutation_root';
  insertClassesOne?: {__typename?: 'Classes'; id: any; name: string} | undefined;
};

export type PlayCreateCoachMutationVariables = Exact<{
  coach: CoachesInsertInput;
}>;

export type PlayCreateCoachMutation = {
  __typename?: 'mutation_root';
  insertCoachesOne?: {__typename?: 'Coaches'; id: any} | undefined;
};

export type PlayCreateCohortMutationVariables = Exact<{
  cohort: CohortsInsertInput;
}>;

export type PlayCreateCohortMutation = {
  __typename?: 'mutation_root';
  insertCohortsOne?: {__typename?: 'Cohorts'; id: any; slug: string} | undefined;
};

export type PlayCreateCourseMutationVariables = Exact<{
  course: CoursesInsertInput;
}>;

export type PlayCreateCourseMutation = {
  __typename?: 'mutation_root';
  insertCoursesOne?: {__typename?: 'Courses'; id: any; slug: string} | undefined;
};

export type PlayCreateOrgMutationVariables = Exact<{
  name: Scalars['String'];
  planId: Scalars['String'];
  salesforceAccountId: Scalars['String'];
  subscriptionNotes: Scalars['String'];
}>;

export type PlayCreateOrgMutation = {
  __typename?: 'mutation_root';
  CreateOrganization?: {__typename?: 'CreateOrganizationOutput'; id: string} | undefined;
};

export type PlayCreatePlanMutationVariables = Exact<{
  plan: PlansInsertInput;
}>;

export type PlayCreatePlanMutation = {
  __typename?: 'mutation_root';
  insertPlansOne?:
    | {__typename?: 'Plans'; id: any; slug: string; name: string; policy: any}
    | undefined;
};

export type PlayCreateSchoolMutationVariables = Exact<{
  slug: Scalars['String'];
  name: Scalars['String'];
  createdById: Scalars['uuid'];
}>;

export type PlayCreateSchoolMutation = {
  __typename?: 'mutation_root';
  insertSchoolsOne?: {__typename?: 'Schools'; id: any} | undefined;
};

export type PlayCreateTagMutationVariables = Exact<{
  slug: Scalars['String'];
  name: Scalars['String'];
  createdById: Scalars['uuid'];
}>;

export type PlayCreateTagMutation = {
  __typename?: 'mutation_root';
  insertTagsOne?: {__typename?: 'Tags'; id: any} | undefined;
};

export type PlayCreateTeamMutationVariables = Exact<{
  team: TeamsInsertInput;
}>;

export type PlayCreateTeamMutation = {
  __typename?: 'mutation_root';
  insertTeamsOne?: {__typename?: 'Teams'; id: any} | undefined;
};

export type PlayCreateUserMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  organizationId?: InputMaybe<Scalars['String']>;
  salesforceAccountId?: InputMaybe<Scalars['String']>;
  planId?: InputMaybe<Scalars['String']>;
  subscriptionNotes?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['String']>;
  seatType?: InputMaybe<Scalars['String']>;
}>;

export type PlayCreateUserMutation = {
  __typename?: 'mutation_root';
  CreateUser?: {__typename?: 'CreateUserOutput'; id: string} | undefined;
};

export type PlayEnrollLearnersMutationVariables = Exact<{
  objects: Array<EnrollmentsInsertInput> | EnrollmentsInsertInput;
}>;

export type PlayEnrollLearnersMutation = {
  __typename?: 'mutation_root';
  insertEnrollments?:
    | {__typename?: 'EnrollmentsMutationResponse'; affectedRows: number}
    | undefined;
};

export type PlayInsertLsSubmissionUserRelMutationVariables = Exact<{
  object: LightningSessionSubmissionsUsersRelInsertInput;
}>;

export type PlayInsertLsSubmissionUserRelMutation = {
  __typename?: 'mutation_root';
  insertLightningSessionSubmissionsUsersRelOne?:
    | {
        __typename?: 'LightningSessionSubmissionsUsersRel';
        userId: any;
        lightningSessionSubmissionId: any;
      }
    | undefined;
};

export type PlayMakeUserManagerMutationVariables = Exact<{
  teamId: Scalars['uuid'];
  userId: Scalars['uuid'];
}>;

export type PlayMakeUserManagerMutation = {
  __typename?: 'mutation_root';
  updateTeamsUsersByPk?:
    | {__typename?: 'TeamsUsers'; userId: any; teamId: any; role: string}
    | undefined;
};

export type PlayPublishCourseMutationVariables = Exact<{
  slug: Scalars['String'];
  reason: Scalars['String'];
  userId: Scalars['uuid'];
}>;

export type PlayPublishCourseMutation = {
  __typename?: 'mutation_root';
  publishCourse: Array<{__typename?: 'Courses'; id: any; version?: number | undefined}>;
};

export type PlayUpsertLsSubmissionMutationVariables = Exact<{
  object: LightningSessionSubmissionsInsertInput;
}>;

export type PlayUpsertLsSubmissionMutation = {
  __typename?: 'mutation_root';
  insertLightningSessionSubmissionsOne?:
    | {__typename?: 'LightningSessionSubmissions'; id: any}
    | undefined;
};

export type CreateProgramMutationVariables = Exact<{
  input: ProgramsInsertInput;
}>;

export type CreateProgramMutation = {
  __typename?: 'mutation_root';
  insertProgramsOne?: {__typename?: 'Programs'; id: any} | undefined;
};

export type CreateRecordingMutationVariables = Exact<{
  input: RecordingsInsertInput;
}>;

export type CreateRecordingMutation = {
  __typename?: 'mutation_root';
  insertRecordingsOne?: {__typename?: 'Recordings'; id: any; hash: string} | undefined;
};

export type CreateRecordingFromClientMutationVariables = Exact<{
  hash: Scalars['String'];
  type: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  public?: InputMaybe<Scalars['Boolean']>;
  lightningSubmissionId?: InputMaybe<Scalars['String']>;
}>;

export type CreateRecordingFromClientMutation = {
  __typename?: 'mutation_root';
  CreateRecording?:
    | {
        __typename?: 'SuccessOutput';
        success: boolean;
        details?: Array<string | undefined> | undefined;
      }
    | undefined;
};

export type DeleteRecordingMutationVariables = Exact<{
  lightningSubmissionId: Scalars['uuid'];
  hash: Scalars['String'];
}>;

export type DeleteRecordingMutation = {
  __typename?: 'mutation_root';
  deleteRecordings?: {__typename?: 'RecordingsMutationResponse'; affectedRows: number} | undefined;
};

export type GetRecordingActivityQueryVariables = Exact<{
  userId: Scalars['uuid'];
  hash: Scalars['String'];
  type?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetRecordingActivityQuery = {
  __typename?: 'query_root';
  recordingActivity: Array<{
    __typename?: 'RecordingActivity';
    id: any;
    userId?: any | undefined;
    hash: string;
    context?: any | undefined;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    type?: string | undefined;
  }>;
};

export type MarkRecordingWatchedMutationVariables = Exact<{
  hash: Scalars['String'];
}>;

export type MarkRecordingWatchedMutation = {
  __typename?: 'mutation_root';
  MarkRecordingWatched?: {__typename?: 'MarkRecordingWatchedOutput'; done: boolean} | undefined;
};

export type SignUploadFromClientMutationVariables = Exact<{
  s3path: Scalars['String'];
}>;

export type SignUploadFromClientMutation = {
  __typename?: 'mutation_root';
  SignUpload?:
    | {
        __typename?: 'SuccessOutput';
        success: boolean;
        details?: Array<string | undefined> | undefined;
      }
    | undefined;
};

export type StartPlaybackMutationVariables = Exact<{
  input: StartPlaybackInput;
}>;

export type StartPlaybackMutation = {
  __typename?: 'mutation_root';
  StartPlayback?: {__typename?: 'StartPlaybackOutput'; success: boolean} | undefined;
};

export type UpdatePlaybackMutationVariables = Exact<{
  input: UpdatePlaybackInput;
}>;

export type UpdatePlaybackMutation = {
  __typename?: 'mutation_root';
  UpdatePlayback?: {__typename?: 'UpdatePlaybackOutput'; success?: boolean | undefined} | undefined;
};

export type UpdateRecordingMutationVariables = Exact<{
  id: Scalars['uuid'];
  publicUrl?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<Scalars['jsonb']>;
  transcription?: InputMaybe<Scalars['jsonb']>;
  summary?: InputMaybe<Scalars['jsonb']>;
  synced?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdateRecordingMutation = {
  __typename?: 'mutation_root';
  updateRecordingsByPk?:
    | {
        __typename?: 'Recordings';
        id: any;
        publicUrl?: string | undefined;
        meta?: any | undefined;
        transcription?: any | undefined;
        summary?: any | undefined;
        synced?: boolean | undefined;
      }
    | undefined;
};

export type UpdateRecordingSentStatusMutationVariables = Exact<{
  hash: Scalars['String'];
  lightningSubmissionId: Scalars['uuid'];
  status: Scalars['Boolean'];
}>;

export type UpdateRecordingSentStatusMutation = {
  __typename?: 'mutation_root';
  updateRecordings?: {__typename?: 'RecordingsMutationResponse'; affectedRows: number} | undefined;
};

export type UpsertRecordingActivityMutationVariables = Exact<{
  object: RecordingActivityInsertInput;
}>;

export type UpsertRecordingActivityMutation = {
  __typename?: 'mutation_root';
  insertRecordingActivityOne?: {__typename?: 'RecordingActivity'; id: any} | undefined;
};

export type DeleteStreamMessageMutationVariables = Exact<{
  messageId?: InputMaybe<Scalars['String']>;
}>;

export type DeleteStreamMessageMutation = {
  __typename?: 'mutation_root';
  deleteStreamMessages?:
    | {__typename?: 'StreamMessagesMutationResponse'; affectedRows: number}
    | undefined;
};

export type InsertStreamMessageMutationVariables = Exact<{
  object: StreamMessagesInsertInput;
}>;

export type InsertStreamMessageMutation = {
  __typename?: 'mutation_root';
  insertStreamMessagesOne?: {__typename?: 'StreamMessages'; id: any} | undefined;
};

export type UpdateStreamMessageMutationVariables = Exact<{
  messageId?: InputMaybe<Scalars['String']>;
  object?: InputMaybe<StreamMessagesSetInput>;
}>;

export type UpdateStreamMessageMutation = {
  __typename?: 'mutation_root';
  updateStreamMessages?:
    | {__typename?: 'StreamMessagesMutationResponse'; affectedRows: number}
    | undefined;
};

export type AddMembersToTeamActionMutationVariables = Exact<{
  teamId: Scalars['uuid'];
  userIds: Array<Scalars['uuid']> | Scalars['uuid'];
}>;

export type AddMembersToTeamActionMutation = {
  __typename?: 'mutation_root';
  AddMembersToTeam?: {__typename?: 'SuccessOutput'; success: boolean} | undefined;
};

export type AddUserToTeamMutationVariables = Exact<{
  rel: TeamsUsersInsertInput;
}>;

export type AddUserToTeamMutation = {
  __typename?: 'mutation_root';
  insertTeamsUsersOne?: {__typename: 'TeamsUsers'} | undefined;
};

export type AddUsersToTeamMutationVariables = Exact<{
  objects: Array<TeamsUsersInsertInput> | TeamsUsersInsertInput;
}>;

export type AddUsersToTeamMutation = {
  __typename?: 'mutation_root';
  insertTeamsUsers?: {__typename?: 'TeamsUsersMutationResponse'; affectedRows: number} | undefined;
};

export type ChangeTeamMemberRoleMutationVariables = Exact<{
  userId: Scalars['uuid'];
  teamId: Scalars['uuid'];
  role: Scalars['String'];
  updatedById: Scalars['uuid'];
  updatedAt: Scalars['timestamp'];
}>;

export type ChangeTeamMemberRoleMutation = {
  __typename?: 'mutation_root';
  updateTeamsUsersByPk?: {__typename?: 'TeamsUsers'; userId: any} | undefined;
};

export type ChangeTeamMemberRoleActionMutationVariables = Exact<{
  userId: Scalars['uuid'];
  teamId: Scalars['uuid'];
  role: Scalars['String'];
}>;

export type ChangeTeamMemberRoleActionMutation = {
  __typename?: 'mutation_root';
  ChangeTeamMemberRole?: {__typename?: 'SuccessOutput'; success: boolean} | undefined;
};

export type CreateTeamMutationVariables = Exact<{
  team: TeamsInsertInput;
}>;

export type CreateTeamMutation = {
  __typename?: 'mutation_root';
  insertTeamsOne?: {__typename?: 'Teams'; id: any} | undefined;
};

export type DeleteTeamMutationVariables = Exact<{
  teamId: Scalars['uuid'];
}>;

export type DeleteTeamMutation = {
  __typename?: 'mutation_root';
  deleteTeamsUsers?: {__typename?: 'TeamsUsersMutationResponse'; affectedRows: number} | undefined;
  deleteTeamsByPk?: {__typename?: 'Teams'; id: any} | undefined;
};

export type DeleteTeamActionMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type DeleteTeamActionMutation = {
  __typename?: 'mutation_root';
  DeleteTeam?:
    | {
        __typename?: 'SuccessOutput';
        details?: Array<string | undefined> | undefined;
        success: boolean;
      }
    | undefined;
};

export type DeleteUsersTeamsMutationVariables = Exact<{
  where: TeamsUsersBoolExp;
}>;

export type DeleteUsersTeamsMutation = {
  __typename?: 'mutation_root';
  deleteTeamsUsers?: {__typename?: 'TeamsUsersMutationResponse'; affectedRows: number} | undefined;
};

export type MoveMemberToTeamMutationVariables = Exact<{
  memberUserId: Scalars['uuid'];
  memberRole?: InputMaybe<Scalars['String']>;
  fromTeamId: Scalars['uuid'];
  toTeamId: Scalars['uuid'];
  createdById: Scalars['uuid'];
}>;

export type MoveMemberToTeamMutation = {
  __typename?: 'mutation_root';
  deleteTeamsUsersByPk?: {__typename: 'TeamsUsers'} | undefined;
  insertTeamsUsersOne?: {__typename: 'TeamsUsers'} | undefined;
};

export type MoveMemberToTeamActionMutationVariables = Exact<{
  memberUserId: Scalars['uuid'];
  fromTeamId: Scalars['uuid'];
  toTeamId: Scalars['uuid'];
}>;

export type MoveMemberToTeamActionMutation = {
  __typename?: 'mutation_root';
  MoveMemberToTeam?: {__typename?: 'SuccessOutput'; success: boolean} | undefined;
};

export type RemoveTeamMemberActionMutationVariables = Exact<{
  userId: Scalars['String'];
  teamId: Scalars['String'];
  organizationId: Scalars['String'];
}>;

export type RemoveTeamMemberActionMutation = {
  __typename?: 'mutation_root';
  RemoveTeamMember?: {__typename?: 'SuccessOutput'; success: boolean} | undefined;
};

export type UpsertTeamMutationVariables = Exact<{
  fields: TeamsInsertInput;
}>;

export type UpsertTeamMutation = {
  __typename?: 'mutation_root';
  insertTeams?:
    | {
        __typename?: 'TeamsMutationResponse';
        affectedRows: number;
        returning: Array<{__typename?: 'Teams'; id: any}>;
      }
    | undefined;
};

export type UpsertTeamActionMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  parentId: Scalars['uuid'];
  organizationId: Scalars['uuid'];
  name: Scalars['String'];
  teamJobFunction: Scalars['String'];
  slug: Scalars['String'];
}>;

export type UpsertTeamActionMutation = {
  __typename?: 'mutation_root';
  UpsertTeam?: {__typename?: 'TeamOutput'; id: any} | undefined;
};

export type AddTypeformResponseMutationVariables = Exact<{
  object: TypeformSubmissionsInsertInput;
  onConflict: TypeformSubmissionsOnConflict;
}>;

export type AddTypeformResponseMutation = {
  __typename?: 'mutation_root';
  insertTypeformSubmissionsOne?: {__typename?: 'TypeformSubmissions'; id: any} | undefined;
};

export type CreateUserActionMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  organizationId?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['String']>;
  jobFunction?: InputMaybe<Scalars['String']>;
  jobFunctionLength?: InputMaybe<Scalars['String']>;
}>;

export type CreateUserActionMutation = {
  __typename?: 'mutation_root';
  CreateUser?: {__typename?: 'CreateUserOutput'; id: string} | undefined;
};

export type DeactivateUserActionMutationVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type DeactivateUserActionMutation = {
  __typename?: 'mutation_root';
  DeactivateUser?: {__typename?: 'SuccessOutput'; success: boolean} | undefined;
};

export type ImpersonateUserActionMutationVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type ImpersonateUserActionMutation = {
  __typename?: 'mutation_root';
  ImpersonateUser?: {__typename?: 'SampleOutput'; assumeSessionUrl: string} | undefined;
};

export type ReactivateUserActionMutationVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type ReactivateUserActionMutation = {
  __typename?: 'mutation_root';
  ReactivateUser?: {__typename?: 'SuccessOutput'; success: boolean} | undefined;
};

export type RemoveSeatsMutationVariables = Exact<{
  userId: Scalars['uuid'];
  deletedAt: Scalars['timestamp'];
  deletedById: Scalars['uuid'];
}>;

export type RemoveSeatsMutation = {
  __typename?: 'mutation_root';
  updateSeats?: {__typename?: 'SeatsMutationResponse'; affectedRows: number} | undefined;
};

export type SetUserMetaMutationVariables = Exact<{
  object: UsersMetaInsertInput;
}>;

export type SetUserMetaMutation = {
  __typename?: 'mutation_root';
  insertUsersMetaOne?:
    | {__typename?: 'UsersMeta'; userId: any; joinDate?: any | undefined}
    | undefined;
};

export type SetUserMetaProfileDataMutationVariables = Exact<{
  object: UsersMetaInsertInput;
}>;

export type SetUserMetaProfileDataMutation = {
  __typename?: 'mutation_root';
  insertUsersMetaOne?:
    | {
        __typename?: 'UsersMeta';
        userId: any;
        jobFunction?: UsersFunctionsEnum | undefined;
        jobFunctionLength?: UsersLengthInFunctionEnum | undefined;
        acceptedTermsOfUse?: boolean | undefined;
        hasFilledUserProfileData?: boolean | undefined;
      }
    | undefined;
};

export type SetUserProfileCaptureMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  jobFunction: Scalars['String'];
  jobFunctionLength: Scalars['String'];
  acceptedTermsOfUse: Scalars['Boolean'];
  acceptedMarketingCommunication?: InputMaybe<Scalars['Boolean']>;
}>;

export type SetUserProfileCaptureMutation = {
  __typename?: 'mutation_root';
  UserProfileCapture?: {__typename?: 'UserProfileCaptureOutput'; id: string} | undefined;
};

export type SetUserSeatMutationVariables = Exact<{
  object: SeatsInsertInput;
}>;

export type SetUserSeatMutation = {
  __typename?: 'mutation_root';
  insertSeatsOne?: {__typename?: 'Seats'; id: any} | undefined;
};

export type UpdateUserActionMutationVariables = Exact<{
  id: Scalars['String'];
  teamId?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  jobFunction?: InputMaybe<Scalars['String']>;
  jobFunctionLength?: InputMaybe<Scalars['String']>;
}>;

export type UpdateUserActionMutation = {
  __typename?: 'mutation_root';
  UpdateUser?: {__typename?: 'SuccessOutput'; success: boolean} | undefined;
};

export type UpdateZoomTokensMutationVariables = Exact<{
  bearerToken: Scalars['String'];
  expiresAt: Scalars['timestamp'];
  index: Scalars['Int'];
  now: Scalars['timestamp'];
}>;

export type UpdateZoomTokensMutation = {
  __typename?: 'mutation_root';
  insertZoomOauthTokensOne?: {__typename: 'ZoomOauthTokens'} | undefined;
  deleteZoomOauthTokens?:
    | {__typename?: 'ZoomOauthTokensMutationResponse'; affectedRows: number}
    | undefined;
};

export type GetAssessmentAttemptQueryVariables = Exact<{
  where: ImpactAssessmentAttemptsBoolExp;
  orderBy?: InputMaybe<Array<ImpactAssessmentAttemptsOrderBy> | ImpactAssessmentAttemptsOrderBy>;
}>;

export type GetAssessmentAttemptQuery = {
  __typename?: 'query_root';
  impactAssessmentAttempts: Array<{
    __typename?: 'ImpactAssessmentAttempts';
    id: any;
    impactAssessmentId?: any | undefined;
    percentCorrect?: number | undefined;
    isPassing?: boolean | undefined;
  }>;
};

export type GetAssessmentAttemptByPkQueryVariables = Exact<{
  input: Scalars['uuid'];
}>;

export type GetAssessmentAttemptByPkQuery = {
  __typename?: 'query_root';
  attempt?:
    | {
        __typename?: 'ImpactAssessmentAttempts';
        id: any;
        userId?: any | undefined;
        status?: AttemptStatusEnum | undefined;
        remainingTime?: any | undefined;
      }
    | undefined;
};

export type GetCourseCertificationQueryVariables = Exact<{
  input: CourseCertificationsBoolExp;
}>;

export type GetCourseCertificationQuery = {
  __typename?: 'query_root';
  courseCertifications: Array<{
    __typename?: 'CourseCertifications';
    certificationFileUrl?: string | undefined;
    certificationImageUrl?: string | undefined;
    cohort?: {__typename?: 'Cohorts'; name: string} | undefined;
    impactAssessment?:
      | {
          __typename?: 'ImpactAssessments';
          course?: {__typename?: 'Courses'; name: string} | undefined;
        }
      | undefined;
  }>;
};

export type GetImpactAssessmentQueryVariables = Exact<{
  input: Scalars['uuid'];
}>;

export type GetImpactAssessmentQuery = {
  __typename?: 'query_root';
  assessment?:
    | {
        __typename?: 'ImpactAssessments';
        id: any;
        timeLimitInMinutes?: number | undefined;
        dueWithinDays?: number | undefined;
        percentToPass?: number | undefined;
        questionCount: number;
      }
    | undefined;
};

export type GetImpactAssessmentAttemptQueryVariables = Exact<{
  attemptId: Scalars['uuid'];
  onlyAssessmentId?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetImpactAssessmentAttemptQuery = {
  __typename?: 'query_root';
  assessmentAttempt?:
    | {
        __typename?: 'ImpactAssessmentAttempts';
        status?: AttemptStatusEnum | undefined;
        impactAssessmentId?: any | undefined;
        id: any;
        learnerAnswers?: any | undefined;
        remainingTime?: any | undefined;
        userId?: any | undefined;
        cohortId: any;
        impactAssessment?:
          | {
              __typename?: 'ImpactAssessments';
              course?: {__typename?: 'Courses'; name: string} | undefined;
            }
          | undefined;
        questions?:
          | Array<{
              __typename?: 'ImpactAssessmentQuestions';
              questionRich?: string | undefined;
              id?: any | undefined;
              impactAssessmentId?: any | undefined;
              answers?: any | undefined;
              type?: QuestionTypeEnum | undefined;
              points: number;
            }>
          | undefined;
      }
    | undefined;
};

export type GetImpactAssessmentQuestionsQueryVariables = Exact<{
  input: Scalars['uuid'];
  limit: Scalars['Int'];
  onlyIds?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetImpactAssessmentQuestionsQuery = {
  __typename?: 'query_root';
  questions: Array<{
    __typename?: 'ImpactAssessmentQuestions';
    id?: any | undefined;
    questionRich?: string | undefined;
    impactAssessmentId?: any | undefined;
    answers?: any | undefined;
    type?: QuestionTypeEnum | undefined;
    points: number;
  }>;
};

export type GetImpactAssessmentQuestionsByIdsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;

export type GetImpactAssessmentQuestionsByIdsQuery = {
  __typename?: 'query_root';
  assessmentQuestions: Array<{
    __typename?: 'ImpactAssessmentQuestions';
    id?: any | undefined;
    points: number;
    correctAnswers?: any | undefined;
    questionType?: {__typename?: 'QuestionType'; value: string} | undefined;
  }>;
};

export type GetUserAssessmentAttemptValidQueryVariables = Exact<{
  assessmentId: Scalars['uuid'];
  userId: Scalars['uuid'];
  cohortSlug: Scalars['String'];
}>;

export type GetUserAssessmentAttemptValidQuery = {
  __typename?: 'query_root';
  liveAttempts: {
    __typename?: 'ImpactAssessmentAttemptsAggregate';
    valid?: {__typename?: 'ImpactAssessmentAttemptsAggregateFields'; count: number} | undefined;
  };
  total: {
    __typename?: 'ImpactAssessmentAttemptsAggregate';
    attempts?: {__typename?: 'ImpactAssessmentAttemptsAggregateFields'; count: number} | undefined;
  };
  isPassing: {
    __typename?: 'ImpactAssessmentAttemptsAggregate';
    passed?: {__typename?: 'ImpactAssessmentAttemptsAggregateFields'; count: number} | undefined;
  };
};

export type GetUserCurrentImpactAssessmentsForCohortQueryVariables = Exact<{
  input: Scalars['uuid'];
}>;

export type GetUserCurrentImpactAssessmentsForCohortQuery = {
  __typename?: 'query_root';
  currentAssessment?:
    | {
        __typename?: 'ImpactAssessmentAttempts';
        status?: AttemptStatusEnum | undefined;
        startedAt?: any | undefined;
        remainingTime?: any | undefined;
        learnerAnswers?: any | undefined;
        impactAssessment?:
          | {
              __typename?: 'ImpactAssessments';
              id: any;
              timeLimitInMinutes?: number | undefined;
              questions: Array<{
                __typename?: 'ImpactAssessmentQuestions';
                answers?: any | undefined;
                id?: any | undefined;
                questionRich?: string | undefined;
                type?: QuestionTypeEnum | undefined;
              }>;
            }
          | undefined;
      }
    | undefined;
};

export type GetUserEnrolledForAssessmentQueryVariables = Exact<{
  assessmentId: Scalars['uuid'];
  userId: Scalars['uuid'];
  cohortSlug: Scalars['String'];
}>;

export type GetUserEnrolledForAssessmentQuery = {
  __typename?: 'query_root';
  enrolledForAssessment: {
    __typename?: 'EnrollmentsAggregate';
    enrolled?: {__typename?: 'EnrollmentsAggregateFields'; count: number} | undefined;
  };
  cohort: Array<{
    __typename?: 'Cohorts';
    assessmentLiveDate?: any | undefined;
    assessmentDueDate?: any | undefined;
    isAssessmentLive?: boolean | undefined;
    type: CohortTypeEnum;
  }>;
};

export type GetUserImpactAssessmentsForCohortQueryVariables = Exact<{
  input: Scalars['String'];
}>;

export type GetUserImpactAssessmentsForCohortQuery = {
  __typename?: 'query_root';
  courses: Array<{
    __typename?: 'Courses';
    impactDescriptionList?: any | undefined;
    cohorts: Array<{
      __typename?: 'Cohorts';
      type: CohortTypeEnum;
      assessmentDueDate?: any | undefined;
      assessmentLiveDate?: any | undefined;
      isAssessmentLive?: boolean | undefined;
    }>;
    impactAssessment?:
      | {
          __typename?: 'ImpactAssessments';
          id: any;
          percentToPass?: number | undefined;
          timeLimitInMinutes?: number | undefined;
          dueWithinDays?: number | undefined;
          questionCount: number;
          descriptionRich?: string | undefined;
        }
      | undefined;
  }>;
  attempts: Array<{
    __typename?: 'ImpactAssessmentAttempts';
    id: any;
    status?: AttemptStatusEnum | undefined;
    startedAt?: any | undefined;
    remainingTime?: any | undefined;
    isPassing?: boolean | undefined;
    percentCorrect?: number | undefined;
    impactAssessmentId?: any | undefined;
  }>;
};

export type GetCoachesForCatalogQueryVariables = Exact<{[key: string]: never}>;

export type GetCoachesForCatalogQuery = {
  __typename?: 'query_root';
  courses: Array<{
    __typename?: 'CoursesByPlan';
    coaches: Array<{
      __typename?: 'CourseCoaches';
      coach: {__typename?: 'Coaches'; id: any; name: string; slug: string};
    }>;
  }>;
};

export type GetCoursesForCatalogQueryVariables = Exact<{
  filter: CoursesByPlanBoolExp;
}>;

export type GetCoursesForCatalogQuery = {
  __typename?: 'query_root';
  courses: Array<{
    __typename?: 'CoursesByPlan';
    version?: number | undefined;
    id?: any | undefined;
    slug?: string | undefined;
    name?: string | undefined;
    cohortType?: string | undefined;
    courseType?: string | undefined;
    enrollmentStatus?: string | undefined;
    shortDescription?: string | undefined;
    nextCohortStartDate?: any | undefined;
    nextCohortStartTimeOfDay?: any | undefined;
    firstClassStartTime?: any | undefined;
    endDate?: any | undefined;
    backgroundImageUrl?: string | undefined;
    thumbnailCardUrl?: string | undefined;
    timeLengthInWeeks?: number | undefined;
    timeLengthInClasses?: number | undefined;
    tags: Array<{
      __typename?: 'CoursesTagsRel';
      tag?: {__typename?: 'Tags'; name: string; slug: string} | undefined;
    }>;
    school?: {__typename?: 'Schools'; name: string; slug: string} | undefined;
    coaches: Array<{
      __typename?: 'CourseCoaches';
      coach: {__typename?: 'Coaches'; id: any; name: string; slug: string};
    }>;
  }>;
};

export type GetCertificateMetadataQueryVariables = Exact<{
  impactAssessmentAttemptId: Scalars['uuid'];
}>;

export type GetCertificateMetadataQuery = {
  __typename?: 'query_root';
  certificateMetadata?:
    | {
        __typename?: 'ImpactAssessmentAttempts';
        percentCorrect?: number | undefined;
        updatedAt?: any | undefined;
        user?:
          | {
              __typename?: 'Users';
              id: any;
              firstName?: string | undefined;
              lastName?: string | undefined;
              fullName?: string | undefined;
              data?: string | undefined;
              timezone?: string | undefined;
            }
          | undefined;
        cohort: {
          __typename?: 'Cohorts';
          course?:
            | {
                __typename?: 'Courses';
                name: string;
                slug: string;
                certificateTemplate?: any | undefined;
                coaches: Array<{
                  __typename?: 'CourseCoaches';
                  coach: {__typename?: 'Coaches'; name: string; slug: string};
                }>;
              }
            | undefined;
        };
      }
    | undefined;
};

export type GetAnonRegistrationQueryVariables = Exact<{
  classEventId: Scalars['uuid'];
  email: Scalars['String'];
}>;

export type GetAnonRegistrationQuery = {
  __typename?: 'query_root';
  classEventAnonRegistrations: Array<{
    __typename?: 'ClassEventAnonRegistrations';
    token: string;
    zoomJoinLinkUrl: string;
  }>;
};

export type GetAnonRegistrationByTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type GetAnonRegistrationByTokenQuery = {
  __typename?: 'query_root';
  classEventAnonRegistrations: Array<{
    __typename?: 'ClassEventAnonRegistrations';
    classEventId: any;
    zoomJoinLinkUrl: string;
    email: string;
    firstName?: string | undefined;
    lastName?: string | undefined;
    classEvent?:
      | {
          __typename?: 'ClassEvents';
          startTime?: any | undefined;
          endTime?: any | undefined;
          cohort?:
            | {__typename?: 'Cohorts'; slug: string; inAppVideo?: boolean | undefined}
            | undefined;
        }
      | undefined;
  }>;
};

export type CalendarClassEventFragment = {
  __typename?: 'ClassEvents';
  id: any;
  createdAt?: any | undefined;
  updatedAt?: any | undefined;
  startTime?: any | undefined;
  endTime?: any | undefined;
  class?: {__typename?: 'Classes'; number?: number | undefined; name: string} | undefined;
  cohort?:
    | {
        __typename?: 'Cohorts';
        id: any;
        name: string;
        slug: string;
        startDate?: any | undefined;
        endDate?: any | undefined;
        coaches: Array<{
          __typename?: 'CohortCoaches';
          coach: {__typename?: 'Coaches'; name: string};
        }>;
        course?:
          | {
              __typename?: 'Courses';
              name: string;
              type: CourseTypeEnum;
              slug: string;
              audience?: string | undefined;
              shortDescription?: string | undefined;
            }
          | undefined;
      }
    | undefined;
};

export type GetPublicCalendarFeedQueryVariables = Exact<{[key: string]: never}>;

export type GetPublicCalendarFeedQuery = {
  __typename?: 'query_root';
  classEvents: Array<{
    __typename?: 'ClassEvents';
    id: any;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    startTime?: any | undefined;
    endTime?: any | undefined;
    class?: {__typename?: 'Classes'; number?: number | undefined; name: string} | undefined;
    cohort?:
      | {
          __typename?: 'Cohorts';
          id: any;
          name: string;
          slug: string;
          startDate?: any | undefined;
          endDate?: any | undefined;
          coaches: Array<{
            __typename?: 'CohortCoaches';
            coach: {__typename?: 'Coaches'; name: string};
          }>;
          course?:
            | {
                __typename?: 'Courses';
                name: string;
                type: CourseTypeEnum;
                slug: string;
                audience?: string | undefined;
                shortDescription?: string | undefined;
              }
            | undefined;
        }
      | undefined;
  }>;
};

export type GetStaffCalendarFeedQueryVariables = Exact<{[key: string]: never}>;

export type GetStaffCalendarFeedQuery = {
  __typename?: 'query_root';
  classEvents: Array<{
    __typename?: 'ClassEvents';
    id: any;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    startTime?: any | undefined;
    endTime?: any | undefined;
    class?: {__typename?: 'Classes'; number?: number | undefined; name: string} | undefined;
    cohort?:
      | {
          __typename?: 'Cohorts';
          id: any;
          name: string;
          slug: string;
          startDate?: any | undefined;
          endDate?: any | undefined;
          coaches: Array<{
            __typename?: 'CohortCoaches';
            coach: {__typename?: 'Coaches'; name: string};
          }>;
          course?:
            | {
                __typename?: 'Courses';
                name: string;
                type: CourseTypeEnum;
                slug: string;
                audience?: string | undefined;
                shortDescription?: string | undefined;
              }
            | undefined;
        }
      | undefined;
  }>;
};

export type GetManagerCalendarFeedQueryVariables = Exact<{
  managerId: Scalars['uuid'];
}>;

export type GetManagerCalendarFeedQuery = {
  __typename?: 'query_root';
  classEvents: Array<{
    __typename?: 'ClassEvents';
    id: any;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    startTime?: any | undefined;
    endTime?: any | undefined;
    class?: {__typename?: 'Classes'; number?: number | undefined; name: string} | undefined;
    cohort?:
      | {
          __typename?: 'Cohorts';
          id: any;
          name: string;
          slug: string;
          startDate?: any | undefined;
          endDate?: any | undefined;
          coaches: Array<{
            __typename?: 'CohortCoaches';
            coach: {__typename?: 'Coaches'; name: string};
          }>;
          course?:
            | {
                __typename?: 'Courses';
                name: string;
                type: CourseTypeEnum;
                slug: string;
                audience?: string | undefined;
                shortDescription?: string | undefined;
              }
            | undefined;
        }
      | undefined;
  }>;
};

export type GetClassAttendanceQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetClassAttendanceQuery = {
  __typename?: 'query_root';
  enrollments: Array<{
    __typename?: 'Enrollments';
    cohort?:
      | {
          __typename?: 'Cohorts';
          id: any;
          classEvents: Array<{
            __typename?: 'ClassEvents';
            id: any;
            classEventActivities: Array<{
              __typename?: 'ClassEventActivity';
              id: any;
              type?: ActivityTypeEnum | undefined;
            }>;
          }>;
        }
      | undefined;
  }>;
};

export type GetClassEventEndedQueryVariables = Exact<{
  classEventId: Scalars['uuid'];
}>;

export type GetClassEventEndedQuery = {
  __typename?: 'query_root';
  classEventActivity: Array<{
    __typename?: 'ClassEventActivity';
    id: any;
    createdAt?: any | undefined;
    createdBy?:
      | {
          __typename?: 'Users';
          identity: Array<{__typename?: 'Identities'; email?: string | undefined}>;
        }
      | undefined;
  }>;
};

export type GetClassEventForCoachOpsQueryVariables = Exact<{
  classEventId: Scalars['uuid'];
}>;

export type GetClassEventForCoachOpsQuery = {
  __typename?: 'query_root';
  classEventsByPk?:
    | {
        __typename?: 'ClassEvents';
        id: any;
        startTime?: any | undefined;
        cohort?: {__typename?: 'Cohorts'; slug: string} | undefined;
      }
    | undefined;
};

export type GetClassEventForDailyMeetingQueryVariables = Exact<{
  classEventId: Scalars['uuid'];
}>;

export type GetClassEventForDailyMeetingQuery = {
  __typename?: 'query_root';
  classEventsByPk?:
    | {
        __typename?: 'ClassEvents';
        id: any;
        startTime?: any | undefined;
        endTime?: any | undefined;
        class?:
          | {
              __typename?: 'Classes';
              name: string;
              coaches: Array<{
                __typename?: 'ClassCoaches';
                coach: {
                  __typename?: 'Coaches';
                  id: any;
                  name: string;
                  title?: string | undefined;
                  company?: string | undefined;
                  avatarUrl?: string | undefined;
                  user?:
                    | {
                        __typename?: 'Users';
                        id: any;
                        identity: Array<{__typename?: 'Identities'; email?: string | undefined}>;
                      }
                    | undefined;
                };
              }>;
            }
          | undefined;
        cohort?:
          | {
              __typename?: 'Cohorts';
              slug: string;
              coaches: Array<{
                __typename?: 'CohortCoaches';
                coach: {
                  __typename?: 'Coaches';
                  id: any;
                  name: string;
                  title?: string | undefined;
                  company?: string | undefined;
                  avatarUrl?: string | undefined;
                  user?:
                    | {
                        __typename?: 'Users';
                        id: any;
                        identity: Array<{__typename?: 'Identities'; email?: string | undefined}>;
                      }
                    | undefined;
                };
              }>;
              course?:
                | {
                    __typename?: 'Courses';
                    type: CourseTypeEnum;
                    coaches: Array<{
                      __typename?: 'CourseCoaches';
                      coach: {
                        __typename?: 'Coaches';
                        id: any;
                        name: string;
                        title?: string | undefined;
                        company?: string | undefined;
                        avatarUrl?: string | undefined;
                        user?:
                          | {
                              __typename?: 'Users';
                              id: any;
                              identity: Array<{
                                __typename?: 'Identities';
                                email?: string | undefined;
                              }>;
                            }
                          | undefined;
                      };
                    }>;
                  }
                | undefined;
            }
          | undefined;
      }
    | undefined;
};

export type GetClassEventForRegistrationQueryVariables = Exact<{
  classEventId: Scalars['uuid'];
}>;

export type GetClassEventForRegistrationQuery = {
  __typename?: 'query_root';
  classEventsByPk?:
    | {
        __typename?: 'ClassEvents';
        id: any;
        startTime?: any | undefined;
        endTime?: any | undefined;
        zoomMeetingId?: string | undefined;
        class?:
          | {
              __typename?: 'Classes';
              number?: number | undefined;
              name: string;
              isLive?: boolean | undefined;
            }
          | undefined;
        cohort?:
          | {
              __typename?: 'Cohorts';
              inAppVideo?: boolean | undefined;
              course?:
                | {
                    __typename?: 'Courses';
                    name: string;
                    publishStatus?: CoursePublishStatusEnum | undefined;
                  }
                | undefined;
            }
          | undefined;
      }
    | undefined;
};

export type GetClassEventForZoomMeetingQueryVariables = Exact<{
  classEventId: Scalars['uuid'];
}>;

export type GetClassEventForZoomMeetingQuery = {
  __typename?: 'query_root';
  classEventsByPk?:
    | {
        __typename?: 'ClassEvents';
        startTime?: any | undefined;
        endTime?: any | undefined;
        zoomMeetingId?: string | undefined;
        class?:
          | {
              __typename?: 'Classes';
              name: string;
              isLive?: boolean | undefined;
              coaches: Array<{
                __typename?: 'ClassCoaches';
                coach: {
                  __typename?: 'Coaches';
                  id: any;
                  user?:
                    | {
                        __typename?: 'Users';
                        id: any;
                        firstName?: string | undefined;
                        lastName?: string | undefined;
                        identity: Array<{__typename?: 'Identities'; email?: string | undefined}>;
                      }
                    | undefined;
                };
              }>;
            }
          | undefined;
        cohort?:
          | {
              __typename?: 'Cohorts';
              course?:
                | {
                    __typename?: 'Courses';
                    name: string;
                    publishStatus?: CoursePublishStatusEnum | undefined;
                    manager?:
                      | {
                          __typename?: 'Users';
                          identity: Array<{__typename?: 'Identities'; email?: string | undefined}>;
                        }
                      | undefined;
                    coaches: Array<{
                      __typename?: 'CourseCoaches';
                      coach: {
                        __typename?: 'Coaches';
                        id: any;
                        user?:
                          | {
                              __typename?: 'Users';
                              id: any;
                              firstName?: string | undefined;
                              lastName?: string | undefined;
                              identity: Array<{
                                __typename?: 'Identities';
                                email?: string | undefined;
                              }>;
                            }
                          | undefined;
                      };
                    }>;
                  }
                | undefined;
              coaches: Array<{
                __typename?: 'CohortCoaches';
                coach: {
                  __typename?: 'Coaches';
                  id: any;
                  user?:
                    | {
                        __typename?: 'Users';
                        id: any;
                        firstName?: string | undefined;
                        lastName?: string | undefined;
                        identity: Array<{__typename?: 'Identities'; email?: string | undefined}>;
                      }
                    | undefined;
                };
              }>;
            }
          | undefined;
      }
    | undefined;
};

export type GetClassEventMeetingSegmentsToSyncQueryVariables = Exact<{[key: string]: never}>;

export type GetClassEventMeetingSegmentsToSyncQuery = {
  __typename?: 'query_root';
  classEventMeetingSegments: Array<{
    __typename?: 'ClassEventMeetingSegments';
    id: any;
    videoClipUrl?: string | undefined;
    audioClipUrl?: string | undefined;
    transcription?: any | undefined;
    jobs?: any | undefined;
    startTimecode: number;
    endTimecode: number;
    duration: number;
    classEventMeeting: {
      __typename?: 'ClassEventMeetings';
      id: any;
      recordingUrl?: string | undefined;
    };
    lightningSubmissions: Array<{__typename?: 'LightningSessionSubmissions'; id: any}>;
  }>;
};

export type GetClassEventMeetingsQueryVariables = Exact<{
  where: ClassEventMeetingsBoolExp;
}>;

export type GetClassEventMeetingsQuery = {
  __typename?: 'query_root';
  classEventMeetings: Array<{
    __typename?: 'ClassEventMeetings';
    id: any;
    room: string;
    classEventId: any;
    parentMeetingId?: any | undefined;
    recordingUrl?: string | undefined;
    posterFrameUrl?: string | undefined;
    duration?: number | undefined;
    maxParticipants?: number | undefined;
    synced: boolean;
    altRecordingUrl?: string | undefined;
    participants: Array<{
      __typename?: 'ClassEventMeetingParticipants';
      user?:
        | {
            __typename?: 'Users';
            id: any;
            firstName?: string | undefined;
            lastName?: string | undefined;
            imageUrl?: string | undefined;
          }
        | undefined;
    }>;
  }>;
};

export type GetClassEventMeetingsForPosterBackfillQueryVariables = Exact<{[key: string]: never}>;

export type GetClassEventMeetingsForPosterBackfillQuery = {
  __typename?: 'query_root';
  classEventMeetings: Array<{
    __typename?: 'ClassEventMeetings';
    id: any;
    recordingUrl?: string | undefined;
    posterFrameUrl?: string | undefined;
  }>;
};

export type GetClassEventMeetingsToSyncQueryVariables = Exact<{[key: string]: never}>;

export type GetClassEventMeetingsToSyncQuery = {
  __typename?: 'query_root';
  classEventMeetings: Array<{
    __typename?: 'ClassEventMeetings';
    classEventId: any;
    id: any;
    maxParticipants?: number | undefined;
    parentMeetingId?: any | undefined;
    recordingUrl?: string | undefined;
    room: string;
    dailySessionId?: any | undefined;
    startTime?: any | undefined;
    awsMediaConvertJobId?: string | undefined;
    breakoutMeetings: Array<{
      __typename?: 'ClassEventMeetings';
      id: any;
      maxParticipants?: number | undefined;
      recordingUrl?: string | undefined;
      room: string;
      startTime?: any | undefined;
    }>;
    classEvent?:
      | {
          __typename?: 'ClassEvents';
          classId?: any | undefined;
          cohortId?: any | undefined;
          id: any;
          cohort?: {__typename?: 'Cohorts'; courseId?: any | undefined; slug: string} | undefined;
        }
      | undefined;
  }>;
};

export type GetClassEventsForParticipationSyncQueryVariables = Exact<{[key: string]: never}>;

export type GetClassEventsForParticipationSyncQuery = {
  __typename?: 'query_root';
  classEvents: Array<{
    __typename?: 'ClassEvents';
    id: any;
    startTime?: any | undefined;
    cohort?: {__typename?: 'Cohorts'; slug: string} | undefined;
  }>;
};

export type GetClassParticipantStatsQueryVariables = Exact<{
  userIds: Array<Scalars['uuid']> | Scalars['uuid'];
  classEventId: Scalars['uuid'];
}>;

export type GetClassParticipantStatsQuery = {
  __typename?: 'query_root';
  usersMeta: Array<{
    __typename?: 'UsersMeta';
    userId: any;
    hasParticipatedInLightningSession?: boolean | undefined;
    user: {
      __typename?: 'Users';
      id: any;
      firstName?: string | undefined;
      lastName?: string | undefined;
      imageUrl?: string | undefined;
    };
    organization?: {__typename?: 'Organizations'; name: string} | undefined;
    jobFunctionData?: {__typename?: 'UsersFunctions'; description: string} | undefined;
  }>;
  countNonLearners: {
    __typename?: 'UsersMetaAggregate';
    aggregate?: {__typename?: 'UsersMetaAggregateFields'; count: number} | undefined;
  };
  enrollemntsStats?:
    | {
        __typename?: 'ClassEvents';
        cohort?:
          | {
              __typename?: 'Cohorts';
              enrollmentsAggregate: {
                __typename?: 'EnrollmentsAggregate';
                total?: {__typename?: 'EnrollmentsAggregateFields'; count: number} | undefined;
              };
            }
          | undefined;
      }
    | undefined;
};

export type GetCoachParticipantsForClassEventMeetingQueryVariables = Exact<{
  classEventMeetingId: Scalars['uuid'];
  courseId: Scalars['uuid'];
  classId: Scalars['uuid'];
  cohortId: Scalars['uuid'];
}>;

export type GetCoachParticipantsForClassEventMeetingQuery = {
  __typename?: 'query_root';
  coachParticipants: {
    __typename?: 'ClassEventMeetingParticipantsAggregate';
    nodes: Array<{
      __typename?: 'ClassEventMeetingParticipants';
      userId?: any | undefined;
      userName?: string | undefined;
    }>;
  };
  learnerParticipants: {
    __typename?: 'ClassEventMeetingParticipantsAggregate';
    nodes: Array<{
      __typename?: 'ClassEventMeetingParticipants';
      userId?: any | undefined;
      userName?: string | undefined;
      user?:
        | {
            __typename?: 'Users';
            organizationUsers: Array<{
              __typename?: 'OrganizationUsers';
              organization: {__typename?: 'Organizations'; id: any};
            }>;
          }
        | undefined;
    }>;
  };
};

export type GetFutureClassEventsForCoachQueryVariables = Exact<{
  coachId: Scalars['uuid'];
  now?: InputMaybe<Scalars['timestamp']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetFutureClassEventsForCoachQuery = {
  __typename?: 'query_root';
  classEvents: Array<{
    __typename?: 'ClassEvents';
    startTime?: any | undefined;
    endTime?: any | undefined;
    id: any;
    cohort?:
      | {
          __typename?: 'Cohorts';
          name: string;
          course?: {__typename?: 'Courses'; name: string} | undefined;
        }
      | undefined;
    class?: {__typename?: 'Classes'; name: string; number?: number | undefined} | undefined;
  }>;
};

export type GetLightningSessionQueryVariables = Exact<{
  classEventId: Scalars['uuid'];
}>;

export type GetLightningSessionQuery = {
  __typename?: 'query_root';
  lightningSession: Array<{
    __typename?: 'LightningSession';
    id: any;
    rooms: any;
    borDetails?: any | undefined;
    startTime: any;
    endTime?: any | undefined;
  }>;
};

export type GetLightningSessionStatusQueryVariables = Exact<{
  classEventId: Scalars['uuid'];
}>;

export type GetLightningSessionStatusQuery = {
  __typename?: 'query_root';
  lightningSession: Array<{
    __typename?: 'LightningSession';
    id: any;
    startTime: any;
    endTime?: any | undefined;
  }>;
};

export type GetMeetingsForClassEventQueryVariables = Exact<{
  classEventId: Scalars['uuid'];
}>;

export type GetMeetingsForClassEventQuery = {
  __typename?: 'query_root';
  parentMeeting: Array<{
    __typename?: 'ClassEventMeetings';
    id: any;
    room: string;
    classEventId: any;
  }>;
  childMeetings: Array<{
    __typename?: 'ClassEventMeetings';
    id: any;
    room: string;
    classEventId: any;
  }>;
};

export type GetRegisteredUsersForClassEventQueryVariables = Exact<{
  classEventId: Scalars['uuid'];
}>;

export type GetRegisteredUsersForClassEventQuery = {
  __typename?: 'query_root';
  classEventRegistrations: Array<{
    __typename?: 'ClassEventRegistrations';
    userId?: any | undefined;
  }>;
  classEventAnonRegistrations: Array<{__typename?: 'ClassEventAnonRegistrations'; email: string}>;
};

export type GetTypeformResponseByClassEventQueryVariables = Exact<{
  formIds: Array<Scalars['String']> | Scalars['String'];
  userId: Scalars['uuid'];
  classEventId: Scalars['uuid'];
}>;

export type GetTypeformResponseByClassEventQuery = {
  __typename?: 'query_root';
  typeformSubmissions: Array<{
    __typename?: 'TypeformSubmissions';
    id: any;
    formId: string;
    userId?: any | undefined;
    data?: any | undefined;
    classEventId?: any | undefined;
  }>;
};

export type GetAllCoachesQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<CoachesOrderBy> | CoachesOrderBy>;
}>;

export type GetAllCoachesQuery = {
  __typename?: 'query_root';
  coaches: Array<{__typename?: 'Coaches'; id: any; name: string; slug: string}>;
};

export type GetCoachForUserQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetCoachForUserQuery = {
  __typename?: 'query_root';
  coaches: Array<{__typename?: 'Coaches'; id: any}>;
};

export type GetCoachesForClassEventQueryVariables = Exact<{
  classEventId: Scalars['uuid'];
}>;

export type GetCoachesForClassEventQuery = {
  __typename?: 'query_root';
  classEventsByPk?:
    | {
        __typename?: 'ClassEvents';
        class?:
          | {
              __typename?: 'Classes';
              coaches: Array<{
                __typename?: 'ClassCoaches';
                coach: {
                  __typename?: 'Coaches';
                  id: any;
                  name: string;
                  title?: string | undefined;
                  company?: string | undefined;
                  avatarUrl?: string | undefined;
                  user?:
                    | {
                        __typename?: 'Users';
                        id: any;
                        identity: Array<{__typename?: 'Identities'; email?: string | undefined}>;
                      }
                    | undefined;
                };
              }>;
            }
          | undefined;
        cohort?:
          | {
              __typename?: 'Cohorts';
              coaches: Array<{
                __typename?: 'CohortCoaches';
                coach: {
                  __typename?: 'Coaches';
                  id: any;
                  name: string;
                  title?: string | undefined;
                  company?: string | undefined;
                  avatarUrl?: string | undefined;
                  user?:
                    | {
                        __typename?: 'Users';
                        id: any;
                        identity: Array<{__typename?: 'Identities'; email?: string | undefined}>;
                      }
                    | undefined;
                };
              }>;
              course?:
                | {
                    __typename?: 'Courses';
                    coaches: Array<{
                      __typename?: 'CourseCoaches';
                      coach: {
                        __typename?: 'Coaches';
                        id: any;
                        name: string;
                        title?: string | undefined;
                        company?: string | undefined;
                        avatarUrl?: string | undefined;
                        user?:
                          | {
                              __typename?: 'Users';
                              id: any;
                              identity: Array<{
                                __typename?: 'Identities';
                                email?: string | undefined;
                              }>;
                            }
                          | undefined;
                      };
                    }>;
                  }
                | undefined;
            }
          | undefined;
      }
    | undefined;
};

export type GetPrepQueryVariables = Exact<{
  classEventId: Scalars['uuid'];
}>;

export type GetPrepQuery = {
  __typename?: 'query_root';
  cohorts: Array<{
    __typename?: 'Cohorts';
    id: any;
    slug: string;
    name: string;
    course?:
      | {
          __typename?: 'Courses';
          id: any;
          slug: string;
          name: string;
          shortDescription?: string | undefined;
          longDescriptionRich?: string | undefined;
        }
      | undefined;
    classEvents: Array<{
      __typename?: 'ClassEvents';
      id: any;
      startTime?: any | undefined;
      endTime?: any | undefined;
      class?: {__typename?: 'Classes'; id: any; name: string} | undefined;
    }>;
    enrollments: Array<{
      __typename?: 'Enrollments';
      id: any;
      organization?:
        | {
            __typename?: 'Organizations';
            id: any;
            name: string;
            insertInstant: any;
            meta?:
              | {
                  __typename?: 'OrganizationsMeta';
                  companySize?: string | undefined;
                  industry?: string | undefined;
                  productSold?: string | undefined;
                  targetCustomer?: string | undefined;
                  type?: string | undefined;
                  websiteLink?: string | undefined;
                  linkedInUrl?: string | undefined;
                  customerCreatedAt?: string | undefined;
                }
              | undefined;
          }
        | undefined;
      user?:
        | {
            __typename?: 'Users';
            id: any;
            firstName?: string | undefined;
            lastName?: string | undefined;
            imageUrl?: string | undefined;
            identity: Array<{__typename?: 'Identities'; email?: string | undefined}>;
            meta?:
              | {
                  __typename?: 'UsersMeta';
                  jobFunction?: UsersFunctionsEnum | undefined;
                  jobFunctionLength?: UsersLengthInFunctionEnum | undefined;
                  jobLevel?: string | undefined;
                  jobTitle?: string | undefined;
                  locale?: string | undefined;
                  managerId?: any | undefined;
                  hasParticipatedInLightningSession?: boolean | undefined;
                  jobFunctionData?:
                    | {__typename?: 'UsersFunctions'; description: string; value: string}
                    | undefined;
                }
              | undefined;
          }
        | undefined;
    }>;
  }>;
  usersFunctions: Array<{__typename?: 'UsersFunctions'; value: string; description: string}>;
  usersLengthInFunction: Array<{
    __typename?: 'UsersLengthInFunction';
    value: string;
    description: string;
  }>;
};

export type CountClassEventActivityForClassesQueryVariables = Exact<{
  userId: Scalars['uuid'];
  classEventIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  type?: InputMaybe<Array<ActivityTypeEnum> | ActivityTypeEnum>;
}>;

export type CountClassEventActivityForClassesQuery = {
  __typename?: 'query_root';
  classEventActivityAggregate: {
    __typename?: 'ClassEventActivityAggregate';
    aggregate?: {__typename?: 'ClassEventActivityAggregateFields'; count: number} | undefined;
  };
};

export type GetClassEventQueryVariables = Exact<{
  classEventId: Scalars['uuid'];
  userId: Scalars['uuid'];
}>;

export type GetClassEventQuery = {
  __typename?: 'query_root';
  registration: Array<{
    __typename?: 'ClassEventRegistrations';
    id: any;
    zoomJoinLinkUrl?: string | undefined;
  }>;
  classEvent?:
    | {
        __typename?: 'ClassEvents';
        id: any;
        slug: string;
        startTime?: any | undefined;
        endTime?: any | undefined;
        zoomMeetingId?: string | undefined;
        class?:
          | {
              __typename?: 'Classes';
              name: string;
              slug: string;
              course?:
                | {
                    __typename?: 'Courses';
                    enrollmentStatus?: CourseEnrollmentStatusEnum | undefined;
                    id: any;
                    name: string;
                    preCourseSurveyFormId?: string | undefined;
                  }
                | undefined;
            }
          | undefined;
        cohort?:
          | {
              __typename?: 'Cohorts';
              id: any;
              name: string;
              slug: string;
              inAppVideo?: boolean | undefined;
              course?: {__typename?: 'Courses'; slug: string; type: CourseTypeEnum} | undefined;
            }
          | undefined;
      }
    | undefined;
};

export type GetClassEventActivityQueryVariables = Exact<{
  userId: Scalars['uuid'];
  classEventId: Scalars['uuid'];
  type?: InputMaybe<Array<ActivityTypeEnum> | ActivityTypeEnum>;
}>;

export type GetClassEventActivityQuery = {
  __typename?: 'query_root';
  classEventActivity: Array<{
    __typename?: 'ClassEventActivity';
    id: any;
    userId?: any | undefined;
    classEventId?: any | undefined;
    context?: any | undefined;
    createdAt?: any | undefined;
    type?: ActivityTypeEnum | undefined;
  }>;
};

export type GetClassPlaybackActivityQueryVariables = Exact<{
  userId: Scalars['uuid'];
  classEventId: Scalars['uuid'];
  type?: InputMaybe<Array<ClassPlaybackActivityTypeEnum> | ClassPlaybackActivityTypeEnum>;
}>;

export type GetClassPlaybackActivityQuery = {
  __typename?: 'query_root';
  classPlaybackActivity: Array<{
    __typename?: 'ClassPlaybackActivity';
    id: any;
    userId: any;
    classEventId: any;
    context?: any | undefined;
    createdAt?: any | undefined;
    type: ClassPlaybackActivityTypeEnum;
  }>;
};

export type GetCohortBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
  onlyId?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetCohortBySlugQuery = {
  __typename?: 'query_root';
  cohorts: Array<{
    __typename?: 'Cohorts';
    id: any;
    name: string;
    slug: string;
    course?: {__typename?: 'Courses'; id: any; name: string; slug: string} | undefined;
  }>;
};

export type GetCohortDetailsAndCourseSlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetCohortDetailsAndCourseSlugQuery = {
  __typename?: 'query_root';
  cohorts: Array<{
    __typename?: 'Cohorts';
    endDate?: any | undefined;
    startDate?: any | undefined;
    classTimeOfDay?: any | undefined;
    type: CohortTypeEnum;
    communitySlackChannelUrl?: string | undefined;
    communitySlackInviteUrl?: string | undefined;
    preworkRichText?: string | undefined;
    preworkVideoUrl?: string | undefined;
    assessmentLiveDate?: any | undefined;
    isAssessmentLive?: boolean | undefined;
    course?:
      | {
          __typename?: 'Courses';
          slug: string;
          id: any;
          name: string;
          type: CourseTypeEnum;
          impactAssessment?: {__typename?: 'ImpactAssessments'; id: any} | undefined;
        }
      | undefined;
  }>;
};

export type GetCohortEnrollmentStatusQueryVariables = Exact<{
  cohortId: Scalars['uuid'];
}>;

export type GetCohortEnrollmentStatusQuery = {
  __typename?: 'query_root';
  cohort?: {__typename?: 'Cohorts'; maxEnrollment?: number | undefined} | undefined;
  enrolledLearners: {
    __typename?: 'EnrollmentsAggregate';
    total?: {__typename?: 'EnrollmentsAggregateFields'; count: number} | undefined;
  };
};

export type GetCohortEnrollmentStatusActionMutationVariables = Exact<{
  cohortId: Scalars['uuid'];
}>;

export type GetCohortEnrollmentStatusActionMutation = {
  __typename?: 'mutation_root';
  GetCohortEnrollmentStatus?:
    | {__typename?: 'GetCohortEnrollmentStatusOutput'; isFull: boolean; remainingSeats: number}
    | undefined;
};

export type GetCohortProgressQueryVariables = Exact<{[key: string]: never}>;

export type GetCohortProgressQuery = {
  __typename?: 'query_root';
  cohortProgress: Array<{
    __typename?: 'CohortProgress';
    numClasses?: any | undefined;
    numClassesCompleted?: any | undefined;
    id?: any | undefined;
  }>;
};

export type GetCohortTypeByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetCohortTypeByIdQuery = {
  __typename?: 'query_root';
  cohort?:
    | {
        __typename?: 'Cohorts';
        type: CohortTypeEnum;
        slug: string;
        course?:
          | {__typename?: 'Courses'; name: string; type: CourseTypeEnum; slug: string}
          | undefined;
      }
    | undefined;
};

export type GetCohortWithEnrollmentsForManagerQueryVariables = Exact<{
  userIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  cohortSlug: Scalars['String'];
}>;

export type GetCohortWithEnrollmentsForManagerQuery = {
  __typename?: 'query_root';
  cohorts: Array<{
    __typename?: 'Cohorts';
    id: any;
    name: string;
    slug: string;
    startDate?: any | undefined;
    classTimeOfDay?: any | undefined;
    type: CohortTypeEnum;
    classEvents: Array<{__typename?: 'ClassEvents'; startTime?: any | undefined}>;
    enrollments: Array<{
      __typename?: 'Enrollments';
      user?: {__typename?: 'Users'; id: any} | undefined;
    }>;
    course?:
      | {
          __typename?: 'Courses';
          id: any;
          name: string;
          slug: string;
          type: CourseTypeEnum;
          shortDescription?: string | undefined;
          enrollmentStatus?: CourseEnrollmentStatusEnum | undefined;
          tags: Array<{
            __typename?: 'CoursesTagsRel';
            tag?:
              | {
                  __typename?: 'Tags';
                  name: string;
                  slug: string;
                  tagType?: {__typename?: 'TagTypes'; slug: string} | undefined;
                }
              | undefined;
          }>;
        }
      | undefined;
  }>;
};

export type GetCohortsByStartDateQueryVariables = Exact<{
  startDate?: InputMaybe<Scalars['timestamp']>;
  startDateIsEmpty?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetCohortsByStartDateQuery = {
  __typename?: 'query_root';
  cohorts?: Array<{__typename?: 'Cohorts'; id: any; startDate?: any | undefined}>;
};

export type GetCohortsEnrollmentsForManagersQueryVariables = Exact<{
  cohortIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  userIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;

export type GetCohortsEnrollmentsForManagersQuery = {
  __typename?: 'query_root';
  enrollmentsPerCohort: Array<{
    __typename?: 'Cohorts';
    id: any;
    enrollmentsAggregate: {
      __typename?: 'EnrollmentsAggregate';
      aggregate?: {__typename?: 'EnrollmentsAggregateFields'; count: number} | undefined;
    };
  }>;
  usersEnrolled: Array<{__typename?: 'Users'; id: any}>;
};

export type GetLightningSessionCohortByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetLightningSessionCohortByIdQuery = {
  __typename?: 'query_root';
  cohorts: Array<{
    __typename?: 'Cohorts';
    preworkRichText?: string | undefined;
    preworkVideoUrl?: string | undefined;
    id: any;
    name: string;
    slug: string;
    course?:
      | {__typename?: 'Courses'; type: CourseTypeEnum; id: any; name: string; slug: string}
      | undefined;
    coaches: Array<{
      __typename?: 'CohortCoaches';
      coach: {__typename?: 'Coaches'; id: any; userId?: any | undefined};
    }>;
    classEvents: Array<{
      __typename?: 'ClassEvents';
      id: any;
      preworkCompleteActivityCount: {
        __typename?: 'ClassEventActivityAggregate';
        aggregate?: {__typename?: 'ClassEventActivityAggregateFields'; count: number} | undefined;
      };
    }>;
  }>;
};

export type GetLightningSessionCohortBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetLightningSessionCohortBySlugQuery = {
  __typename?: 'query_root';
  cohorts: Array<{
    __typename?: 'Cohorts';
    preworkRichText?: string | undefined;
    preworkVideoUrl?: string | undefined;
    id: any;
    name: string;
    slug: string;
    classEvents: Array<{
      __typename?: 'ClassEvents';
      id: any;
      preworkCompleteActivityCount: {
        __typename?: 'ClassEventActivityAggregate';
        aggregate?: {__typename?: 'ClassEventActivityAggregateFields'; count: number} | undefined;
      };
    }>;
    resources: Array<{
      __typename?: 'CohortsResourcesRel';
      resource?:
        | {
            __typename?: 'Resources';
            id: any;
            filename: string;
            contentType: string;
            name: string;
            url: string;
            type: ResourceTypeEnum;
          }
        | undefined;
    }>;
    course?:
      | {
          __typename?: 'Courses';
          id: any;
          name: string;
          slug: string;
          resources: Array<{
            __typename?: 'CoursesResourcesRel';
            resource: {
              __typename?: 'Resources';
              id: any;
              filename: string;
              contentType: string;
              name: string;
              url: string;
              type: ResourceTypeEnum;
            };
          }>;
        }
      | undefined;
  }>;
};

export type OneCohortDetailsFragment = {
  __typename?: 'Cohorts';
  communitySlackChannelUrl?: string | undefined;
  communitySlackInviteUrl?: string | undefined;
  courseId?: any | undefined;
  id: any;
  name: string;
  slug: string;
  surveyLinkUrl?: string | undefined;
  type: CohortTypeEnum;
  isPostCourseSurveyAvailable?: boolean | undefined;
  preworkRichText?: string | undefined;
  preworkVideoUrl?: string | undefined;
  requiresPractice: boolean;
  assessmentDueDate?: any | undefined;
  assessmentLiveDate?: any | undefined;
  isAssessmentLive?: boolean | undefined;
  classEvents: Array<{
    __typename?: 'ClassEvents';
    startTime?: any | undefined;
    endTime?: any | undefined;
    id: any;
    recordingUrl?: string | undefined;
    class?:
      | {
          __typename?: 'Classes';
          name: string;
          isLive?: boolean | undefined;
          descriptionRich?: string | undefined;
          listDescription?: any | undefined;
          number?: number | undefined;
          slug: string;
          id: any;
          resources: Array<{
            __typename?: 'ClassResources';
            resource: {
              __typename?: 'Resources';
              id: any;
              filename: string;
              url: string;
              name: string;
              descriptionRich?: string | undefined;
              type: ResourceTypeEnum;
              contentType: string;
              slug: string;
            };
          }>;
        }
      | undefined;
  }>;
  course?:
    | {
        __typename?: 'Courses';
        id: any;
        slug: string;
        type: CourseTypeEnum;
        name: string;
        preCourseSurveyFormId?: string | undefined;
        postCourseSurveyFormId?: string | undefined;
        impactAssessment?: {__typename?: 'ImpactAssessments'; id: any} | undefined;
        resources: Array<{
          __typename?: 'CoursesResourcesRel';
          resource: {
            __typename?: 'Resources';
            id: any;
            filename: string;
            contentType: string;
            name: string;
            url: string;
            type: ResourceTypeEnum;
          };
        }>;
      }
    | undefined;
  resources: Array<{
    __typename?: 'CohortsResourcesRel';
    resource?:
      | {
          __typename?: 'Resources';
          id: any;
          filename: string;
          contentType: string;
          name: string;
          url: string;
          type: ResourceTypeEnum;
        }
      | undefined;
  }>;
};

export type GetMyLearningCohortDetailsBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
  classEventsClassesOrderBy?: InputMaybe<ClassesOrderBy>;
}>;

export type GetMyLearningCohortDetailsBySlugQuery = {
  __typename?: 'query_root';
  cohorts: Array<{
    __typename?: 'Cohorts';
    communitySlackChannelUrl?: string | undefined;
    communitySlackInviteUrl?: string | undefined;
    courseId?: any | undefined;
    id: any;
    name: string;
    slug: string;
    surveyLinkUrl?: string | undefined;
    type: CohortTypeEnum;
    isPostCourseSurveyAvailable?: boolean | undefined;
    preworkRichText?: string | undefined;
    preworkVideoUrl?: string | undefined;
    requiresPractice: boolean;
    assessmentDueDate?: any | undefined;
    assessmentLiveDate?: any | undefined;
    isAssessmentLive?: boolean | undefined;
    preworkCompleteCount: Array<{
      __typename?: 'ClassEvents';
      classEventActivitiesAggregate: {
        __typename?: 'ClassEventActivityAggregate';
        aggregate?: {__typename?: 'ClassEventActivityAggregateFields'; count: number} | undefined;
      };
    }>;
    classEvents: Array<{
      __typename?: 'ClassEvents';
      startTime?: any | undefined;
      endTime?: any | undefined;
      id: any;
      recordingUrl?: string | undefined;
      class?:
        | {
            __typename?: 'Classes';
            name: string;
            isLive?: boolean | undefined;
            descriptionRich?: string | undefined;
            listDescription?: any | undefined;
            number?: number | undefined;
            slug: string;
            id: any;
            resources: Array<{
              __typename?: 'ClassResources';
              resource: {
                __typename?: 'Resources';
                id: any;
                filename: string;
                url: string;
                name: string;
                descriptionRich?: string | undefined;
                type: ResourceTypeEnum;
                contentType: string;
                slug: string;
              };
            }>;
          }
        | undefined;
    }>;
    course?:
      | {
          __typename?: 'Courses';
          id: any;
          slug: string;
          type: CourseTypeEnum;
          name: string;
          preCourseSurveyFormId?: string | undefined;
          postCourseSurveyFormId?: string | undefined;
          impactAssessment?: {__typename?: 'ImpactAssessments'; id: any} | undefined;
          resources: Array<{
            __typename?: 'CoursesResourcesRel';
            resource: {
              __typename?: 'Resources';
              id: any;
              filename: string;
              contentType: string;
              name: string;
              url: string;
              type: ResourceTypeEnum;
            };
          }>;
        }
      | undefined;
    resources: Array<{
      __typename?: 'CohortsResourcesRel';
      resource?:
        | {
            __typename?: 'Resources';
            id: any;
            filename: string;
            contentType: string;
            name: string;
            url: string;
            type: ResourceTypeEnum;
          }
        | undefined;
    }>;
  }>;
  courseCertifications: Array<{__typename?: 'CourseCertifications'; createdAt?: any | undefined}>;
  resources: Array<{
    __typename?: 'Resources';
    id: any;
    filename: string;
    url: string;
    name: string;
    descriptionRich?: string | undefined;
    type: ResourceTypeEnum;
    contentType: string;
    slug: string;
  }>;
};

export type GetOneCohortDetailByPkQueryVariables = Exact<{
  id: Scalars['uuid'];
  classEventsClassesOrderBy?: InputMaybe<ClassesOrderBy>;
}>;

export type GetOneCohortDetailByPkQuery = {
  __typename?: 'query_root';
  cohortsByPk?:
    | {
        __typename?: 'Cohorts';
        communitySlackChannelUrl?: string | undefined;
        communitySlackInviteUrl?: string | undefined;
        courseId?: any | undefined;
        id: any;
        name: string;
        slug: string;
        surveyLinkUrl?: string | undefined;
        type: CohortTypeEnum;
        isPostCourseSurveyAvailable?: boolean | undefined;
        preworkRichText?: string | undefined;
        preworkVideoUrl?: string | undefined;
        requiresPractice: boolean;
        assessmentDueDate?: any | undefined;
        assessmentLiveDate?: any | undefined;
        isAssessmentLive?: boolean | undefined;
        classEvents: Array<{
          __typename?: 'ClassEvents';
          startTime?: any | undefined;
          endTime?: any | undefined;
          id: any;
          recordingUrl?: string | undefined;
          class?:
            | {
                __typename?: 'Classes';
                name: string;
                isLive?: boolean | undefined;
                descriptionRich?: string | undefined;
                listDescription?: any | undefined;
                number?: number | undefined;
                slug: string;
                id: any;
                resources: Array<{
                  __typename?: 'ClassResources';
                  resource: {
                    __typename?: 'Resources';
                    id: any;
                    filename: string;
                    url: string;
                    name: string;
                    descriptionRich?: string | undefined;
                    type: ResourceTypeEnum;
                    contentType: string;
                    slug: string;
                  };
                }>;
              }
            | undefined;
        }>;
        course?:
          | {
              __typename?: 'Courses';
              id: any;
              slug: string;
              type: CourseTypeEnum;
              name: string;
              preCourseSurveyFormId?: string | undefined;
              postCourseSurveyFormId?: string | undefined;
              impactAssessment?: {__typename?: 'ImpactAssessments'; id: any} | undefined;
              resources: Array<{
                __typename?: 'CoursesResourcesRel';
                resource: {
                  __typename?: 'Resources';
                  id: any;
                  filename: string;
                  contentType: string;
                  name: string;
                  url: string;
                  type: ResourceTypeEnum;
                };
              }>;
            }
          | undefined;
        resources: Array<{
          __typename?: 'CohortsResourcesRel';
          resource?:
            | {
                __typename?: 'Resources';
                id: any;
                filename: string;
                contentType: string;
                name: string;
                url: string;
                type: ResourceTypeEnum;
              }
            | undefined;
        }>;
      }
    | undefined;
};

export type PastSessionMetricsFragment = {
  __typename?: 'Cohorts';
  classEvents: Array<{
    __typename?: 'ClassEvents';
    meetingsNotSyncedCount: {
      __typename?: 'ClassEventMeetingsAggregate';
      aggregate?: {__typename?: 'ClassEventMeetingsAggregateFields'; count: number} | undefined;
    };
    participationActivities: Array<{
      __typename?: 'ClassEventActivity';
      userId?: any | undefined;
      type?: ActivityTypeEnum | undefined;
    }>;
  }>;
  enrolled: Array<{__typename?: 'LightningSessionSubmissions'; userId: any}>;
  attended: Array<{
    __typename?: 'LightningSessionSubmissions';
    userId: any;
    status: LightningSessionSubmissionsStatusEnum;
  }>;
  missed: Array<{
    __typename?: 'LightningSessionSubmissions';
    userId: any;
    status: LightningSessionSubmissionsStatusEnum;
  }>;
};

export type GetPastSessionsForUsersQueryVariables = Exact<{
  userIds: Array<Scalars['uuid']> | Scalars['uuid'];
}>;

export type GetPastSessionsForUsersQuery = {
  __typename?: 'query_root';
  cohorts: Array<{
    __typename?: 'Cohorts';
    id: any;
    slug: string;
    startDate?: any | undefined;
    classTimeOfDay?: any | undefined;
    course?:
      | {
          __typename?: 'Courses';
          name: string;
          slug: string;
          tags: Array<{
            __typename?: 'CoursesTagsRel';
            tag?:
              | {
                  __typename?: 'Tags';
                  name: string;
                  slug: string;
                  tagType?: {__typename?: 'TagTypes'; slug: string} | undefined;
                }
              | undefined;
          }>;
        }
      | undefined;
    classEvents: Array<{
      __typename?: 'ClassEvents';
      meetingsNotSyncedCount: {
        __typename?: 'ClassEventMeetingsAggregate';
        aggregate?: {__typename?: 'ClassEventMeetingsAggregateFields'; count: number} | undefined;
      };
      participationActivities: Array<{
        __typename?: 'ClassEventActivity';
        userId?: any | undefined;
        type?: ActivityTypeEnum | undefined;
      }>;
    }>;
    enrolled: Array<{__typename?: 'LightningSessionSubmissions'; userId: any}>;
    attended: Array<{
      __typename?: 'LightningSessionSubmissions';
      userId: any;
      status: LightningSessionSubmissionsStatusEnum;
    }>;
    missed: Array<{
      __typename?: 'LightningSessionSubmissions';
      userId: any;
      status: LightningSessionSubmissionsStatusEnum;
    }>;
  }>;
};

export type GetPastSingleSessionForUsersQueryVariables = Exact<{
  cohortId: Scalars['uuid'];
  userIds: Array<Scalars['uuid']> | Scalars['uuid'];
}>;

export type GetPastSingleSessionForUsersQuery = {
  __typename?: 'query_root';
  cohortsByPk?:
    | {
        __typename?: 'Cohorts';
        id: any;
        slug: string;
        startDate?: any | undefined;
        classTimeOfDay?: any | undefined;
        resources: Array<{
          __typename?: 'CohortsResourcesRel';
          resource?:
            | {
                __typename?: 'Resources';
                id: any;
                filename: string;
                contentType: string;
                name: string;
                url: string;
                type: ResourceTypeEnum;
              }
            | undefined;
        }>;
        course?:
          | {
              __typename?: 'Courses';
              name: string;
              shortDescription?: string | undefined;
              frameworkHighlight?: string | undefined;
              frameworkDescription?: string | undefined;
              slug: string;
              resources: Array<{
                __typename?: 'CoursesResourcesRel';
                resource: {
                  __typename?: 'Resources';
                  id: any;
                  filename: string;
                  contentType: string;
                  name: string;
                  url: string;
                  type: ResourceTypeEnum;
                };
              }>;
              tags: Array<{
                __typename?: 'CoursesTagsRel';
                tag?:
                  | {
                      __typename?: 'Tags';
                      name: string;
                      slug: string;
                      tagType?: {__typename?: 'TagTypes'; slug: string} | undefined;
                    }
                  | undefined;
              }>;
            }
          | undefined;
        classEvents: Array<{
          __typename?: 'ClassEvents';
          recordingUrl?: string | undefined;
          lightningSession?:
            | {__typename?: 'LightningSession'; rooms: any; borDetails?: any | undefined}
            | undefined;
          meetingsNotSyncedCount: {
            __typename?: 'ClassEventMeetingsAggregate';
            aggregate?:
              | {__typename?: 'ClassEventMeetingsAggregateFields'; count: number}
              | undefined;
          };
          participationActivities: Array<{
            __typename?: 'ClassEventActivity';
            userId?: any | undefined;
            type?: ActivityTypeEnum | undefined;
          }>;
        }>;
        sessionDetails: Array<{
          __typename?: 'LightningSessionSubmissions';
          id: any;
          userId: any;
          status: LightningSessionSubmissionsStatusEnum;
          feedbackSummary?: string | undefined;
          nextEnrolledCohort: {
            __typename?: 'Users';
            enrollments: Array<{
              __typename?: 'Enrollments';
              cohort?:
                | {
                    __typename?: 'Cohorts';
                    slug: string;
                    startDate?: any | undefined;
                    course?: {__typename?: 'Courses'; name: string; slug: string} | undefined;
                  }
                | undefined;
            }>;
          };
          coach?:
            | {
                __typename?: 'Users';
                id: any;
                firstName?: string | undefined;
                lastName?: string | undefined;
                coaches: Array<{
                  __typename?: 'Coaches';
                  avatarUrl?: string | undefined;
                  title?: string | undefined;
                  company?: string | undefined;
                }>;
              }
            | undefined;
        }>;
        enrolled: Array<{__typename?: 'LightningSessionSubmissions'; userId: any}>;
        attended: Array<{
          __typename?: 'LightningSessionSubmissions';
          userId: any;
          status: LightningSessionSubmissionsStatusEnum;
        }>;
        missed: Array<{
          __typename?: 'LightningSessionSubmissions';
          userId: any;
          status: LightningSessionSubmissionsStatusEnum;
        }>;
      }
    | undefined;
};

export type GetProgramMatchesQueryVariables = Exact<{
  timeframe: Scalars['timestamp'];
  tagIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;

export type GetProgramMatchesQuery = {
  __typename?: 'query_root';
  courses: Array<{
    __typename?: 'CoursesByPlan';
    id?: any | undefined;
    name?: string | undefined;
    shortDescription?: string | undefined;
    version?: number | undefined;
    slug?: string | undefined;
    courseType?: string | undefined;
    enrollmentStatus?: string | undefined;
    cohorts: Array<{
      __typename?: 'Cohorts';
      id: any;
      name: string;
      slug: string;
      startDate?: any | undefined;
      classTimeOfDay?: any | undefined;
      type: CohortTypeEnum;
      classEvents: Array<{__typename?: 'ClassEvents'; startTime?: any | undefined}>;
    }>;
    tags: Array<{
      __typename?: 'CoursesTagsRel';
      tag?:
        | {
            __typename?: 'Tags';
            id: any;
            name: string;
            slug: string;
            tagTypeId: any;
            tagType?: {__typename?: 'TagTypes'; slug: string} | undefined;
          }
        | undefined;
    }>;
  }>;
};

export type GetUpcomingCohortsQueryVariables = Exact<{
  where?: InputMaybe<CohortsBoolExp>;
}>;

export type GetUpcomingCohortsQuery = {
  __typename?: 'query_root';
  cohorts: Array<{
    __typename?: 'Cohorts';
    id: any;
    name: string;
    slug: string;
    startDate?: any | undefined;
    classTimeOfDay?: any | undefined;
    type: CohortTypeEnum;
    maxEnrollment?: number | undefined;
    totalEnrollments?: any | undefined;
    classEvents: Array<{__typename?: 'ClassEvents'; startTime?: any | undefined}>;
    course?:
      | {
          __typename?: 'Courses';
          id: any;
          code?: string | undefined;
          name: string;
          slug: string;
          enrollmentStatus?: CourseEnrollmentStatusEnum | undefined;
          timeLengthInClasses?: number | undefined;
          type: CourseTypeEnum;
          tags: Array<{
            __typename?: 'CoursesTagsRel';
            tag?: {__typename?: 'Tags'; name: string; slug: string} | undefined;
          }>;
          classes: Array<{
            __typename?: 'Classes';
            id: any;
            isLive?: boolean | undefined;
            durationInMinutes?: number | undefined;
          }>;
          school?: {__typename?: 'Schools'; id: any; name: string; slug: string} | undefined;
        }
      | undefined;
    coaches: Array<{
      __typename?: 'CohortCoaches';
      coach: {__typename?: 'Coaches'; id: any; name: string; avatarUrl?: string | undefined};
    }>;
  }>;
};

export type GetUpcommingLighthningSessionsWithStatsForManagerQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetUpcommingLighthningSessionsWithStatsForManagerQuery = {
  __typename?: 'query_root';
  cohorts: Array<{
    __typename?: 'Cohorts';
    id: any;
    name: string;
    slug: string;
    startDate?: any | undefined;
    classTimeOfDay?: any | undefined;
    type: CohortTypeEnum;
    classEvents: Array<{__typename?: 'ClassEvents'; startTime?: any | undefined}>;
    course?:
      | {
          __typename?: 'Courses';
          id: any;
          name: string;
          slug: string;
          type: CourseTypeEnum;
          enrollmentStatus?: CourseEnrollmentStatusEnum | undefined;
          tags: Array<{
            __typename?: 'CoursesTagsRel';
            tag?:
              | {
                  __typename?: 'Tags';
                  name: string;
                  slug: string;
                  tagType?: {__typename?: 'TagTypes'; slug: string} | undefined;
                }
              | undefined;
          }>;
        }
      | undefined;
  }>;
};

export type IsLargeCohortQueryVariables = Exact<{
  classEventId: Scalars['uuid'];
}>;

export type IsLargeCohortQuery = {
  __typename?: 'query_root';
  classEventsByPk?:
    | {
        __typename?: 'ClassEvents';
        id: any;
        cohort?:
          | {
              __typename?: 'Cohorts';
              coachesAggregate: {
                __typename?: 'CohortCoachesAggregate';
                aggregate?:
                  | {__typename?: 'CohortCoachesAggregateFields'; count: number}
                  | undefined;
              };
              enrollmentsAggregate: {
                __typename?: 'EnrollmentsAggregate';
                aggregate?: {__typename?: 'EnrollmentsAggregateFields'; count: number} | undefined;
              };
            }
          | undefined;
      }
    | undefined;
};

export type GetConfigurationByKeyQueryVariables = Exact<{
  key: Scalars['String'];
}>;

export type GetConfigurationByKeyQuery = {
  __typename?: 'query_root';
  config?: {__typename?: 'Configurations'; value: any} | undefined;
};

export type GetConfigurationsQueryVariables = Exact<{[key: string]: never}>;

export type GetConfigurationsQuery = {
  __typename?: 'query_root';
  config: Array<{__typename?: 'Configurations'; key: string; value: any}>;
};

export type CheckMyTeamCourseWaitlistQueryVariables = Exact<{
  courseSlug: Scalars['String'];
}>;

export type CheckMyTeamCourseWaitlistQuery = {
  __typename?: 'query_root';
  CheckMyTeamCourseWaitlist?:
    | {
        __typename?: 'CheckMyTeamCourseWaitlistOutput';
        onWaitlist?: Array<any | undefined> | undefined;
      }
    | undefined;
};

export type GetActiveCourseWaitlistQueryVariables = Exact<{
  userIds: Array<Scalars['uuid']> | Scalars['uuid'];
  courseSlug: Scalars['String'];
}>;

export type GetActiveCourseWaitlistQuery = {
  __typename?: 'query_root';
  courseWaitlist: Array<{__typename?: 'CourseWaitlist'; id: any; userId: any; courseSlug: string}>;
};

export type GetCertificationsByUserIdQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetCertificationsByUserIdQuery = {
  __typename?: 'query_root';
  courseCertifications: Array<{
    __typename?: 'CourseCertifications';
    id: any;
    certificationFileUrl?: string | undefined;
    certificationImageUrl?: string | undefined;
    createdAt?: any | undefined;
    cohort?:
      | {
          __typename?: 'Cohorts';
          id: any;
          slug: string;
          course?:
            | {
                __typename?: 'Courses';
                name: string;
                school?: {__typename?: 'Schools'; name: string} | undefined;
              }
            | undefined;
        }
      | undefined;
  }>;
};

export type GetCourseByIdQueryVariables = Exact<{
  courseId: Scalars['uuid'];
}>;

export type GetCourseByIdQuery = {
  __typename?: 'query_root';
  coursesByPk?:
    | {
        __typename?: 'Courses';
        id: any;
        name: string;
        slug: string;
        type: CourseTypeEnum;
        tags: Array<{
          __typename?: 'CoursesTagsRel';
          tag?: {__typename?: 'Tags'; name: string; slug: string} | undefined;
        }>;
      }
    | undefined;
};

export type GetCourseDetailsBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
  classesOrderBy?: InputMaybe<Array<ClassesOrderBy> | ClassesOrderBy>;
  cohortsWhere?: InputMaybe<CohortsBoolExp>;
  skipManagerResources?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetCourseDetailsBySlugQuery = {
  __typename?: 'query_root';
  courses: Array<{
    __typename?: 'Courses';
    id: any;
    name: string;
    enrollmentStatus?: CourseEnrollmentStatusEnum | undefined;
    slug: string;
    bannerUrl?: string | undefined;
    shortDescription?: string | undefined;
    longDescriptionRich?: string | undefined;
    impactDescriptionList?: any | undefined;
    impactDescriptionRich?: string | undefined;
    thumbnailCardUrl?: string | undefined;
    timeLengthInWeeks?: number | undefined;
    timeLengthInClasses?: number | undefined;
    impactRich?: string | undefined;
    applicationRich?: string | undefined;
    practicedRich?: string | undefined;
    type: CourseTypeEnum;
    promoVideoUrl?: string | undefined;
    backgroundImageUrl?: string | undefined;
    audience?: string | undefined;
    tags: Array<{
      __typename?: 'CoursesTagsRel';
      tag?:
        | {
            __typename?: 'Tags';
            name: string;
            slug: string;
            tagType?: {__typename?: 'TagTypes'; name: string; slug: string; id: any} | undefined;
          }
        | undefined;
    }>;
    school?: {__typename?: 'Schools'; name: string; slug: string; id: any} | undefined;
    managerResources: Array<{
      __typename?: 'CoursesResourcesRel';
      resource: {
        __typename?: 'Resources';
        id: any;
        url: string;
        name: string;
        descriptionRich?: string | undefined;
        type: ResourceTypeEnum;
      };
    }>;
    cohorts?:
      | Array<{
          __typename?: 'Cohorts';
          id: any;
          slug: string;
          type: CohortTypeEnum;
          startDate?: any | undefined;
          endDate?: any | undefined;
          dayOfTheWeek?: string | undefined;
          classTimeOfDay?: any | undefined;
          name: string;
          maxEnrollment?: number | undefined;
          totalEnrollments?: any | undefined;
          classEvents: Array<{
            __typename?: 'ClassEvents';
            startTime?: any | undefined;
            durationInSeconds?: number | undefined;
          }>;
        }>
      | undefined;
    coaches: Array<{
      __typename?: 'CourseCoaches';
      coach: {
        __typename?: 'Coaches';
        id: any;
        slug: string;
        name: string;
        title?: string | undefined;
        company?: string | undefined;
        avatarUrl?: string | undefined;
        linkedinUrl?: string | undefined;
        headshotUrl?: string | undefined;
        shortBio?: string | undefined;
        longBioRich?: string | undefined;
      };
    }>;
    classes: Array<{
      __typename?: 'Classes';
      id: any;
      name: string;
      number?: number | undefined;
      descriptionRich?: string | undefined;
      listDescription?: any | undefined;
      isLive?: boolean | undefined;
      durationInMinutes?: number | undefined;
    }>;
    relatedCourses: Array<{
      __typename?: 'RelatedCourses';
      course?:
        | {
            __typename?: 'Courses';
            thumbnailCardUrl?: string | undefined;
            name: string;
            id: any;
            shortDescription?: string | undefined;
            timeLengthInClasses?: number | undefined;
            timeLengthInWeeks?: number | undefined;
            school?: {__typename?: 'Schools'; name: string} | undefined;
            cohorts: Array<{__typename?: 'Cohorts'; startDate?: any | undefined}>;
          }
        | undefined;
    }>;
  }>;
};

export type GetCourseResourcesByCohortQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetCourseResourcesByCohortQuery = {
  __typename?: 'query_root';
  resources: Array<{
    __typename?: 'Resources';
    id: any;
    filename: string;
    url: string;
    name: string;
    descriptionRich?: string | undefined;
    type: ResourceTypeEnum;
    contentType: string;
    slug: string;
  }>;
};

export type GetCourseSurveyResponseQueryVariables = Exact<{
  formIds: Array<Scalars['String']> | Scalars['String'];
  userId: Scalars['uuid'];
  cohortId: Scalars['uuid'];
}>;

export type GetCourseSurveyResponseQuery = {
  __typename?: 'query_root';
  typeformSubmissions: Array<{
    __typename?: 'TypeformSubmissions';
    id: any;
    formId: string;
    userId?: any | undefined;
    data?: any | undefined;
  }>;
};

export type GetCourseWaitlistWithIdsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;

export type GetCourseWaitlistWithIdsQuery = {
  __typename?: 'query_root';
  courseWaitlist: Array<{
    __typename?: 'CourseWaitlist';
    id: any;
    availabilityNotificationSent: boolean;
    course?: {__typename?: 'Courses'; slug: string} | undefined;
    user?:
      | {__typename?: 'Users'; firstName?: string | undefined; lastName?: string | undefined}
      | undefined;
    createdBy: {
      __typename?: 'Users';
      id: any;
      firstName?: string | undefined;
      lastName?: string | undefined;
      identity: Array<{__typename?: 'Identities'; email?: string | undefined}>;
    };
    createdOnBehalfOf?:
      | {
          __typename?: 'Users';
          id: any;
          firstName?: string | undefined;
          lastName?: string | undefined;
          identity: Array<{__typename?: 'Identities'; email?: string | undefined}>;
        }
      | undefined;
  }>;
};

export type GetCourseWaitlistUrgenciesQueryVariables = Exact<{[key: string]: never}>;

export type GetCourseWaitlistUrgenciesQuery = {
  __typename?: 'query_root';
  courseWaitlistDesiredUrgencyType: Array<{
    __typename?: 'CourseWaitlistDesiredUrgencyType';
    value: string;
    description: string;
  }>;
};

export type AddEmailsToQueueMutationVariables = Exact<{
  objects: Array<EmailQueueInsertInput> | EmailQueueInsertInput;
}>;

export type AddEmailsToQueueMutation = {
  __typename?: 'mutation_root';
  insertEmailQueue?:
    | {
        __typename?: 'EmailQueueMutationResponse';
        affectedRows: number;
        returning: Array<{__typename?: 'EmailQueue'; id: any; data?: any | undefined}>;
      }
    | undefined;
};

export type GetBeforeClassReminderEmailsQueryVariables = Exact<{[key: string]: never}>;

export type GetBeforeClassReminderEmailsQuery = {
  __typename?: 'query_root';
  userSessionEmails: Array<{
    __typename?: 'UserSessionEmails';
    userClassEventId: string;
    courseName: string;
    leadCoachName?: string | undefined;
    leadCoachCompany?: string | undefined;
    frameworkUrl?: string | undefined;
    user: {
      __typename?: 'Users';
      firstName?: string | undefined;
      identity: Array<{__typename?: 'Identities'; email?: string | undefined}>;
    };
  }>;
};

export type GetCohortEnrollmentForSubmissionNotificationQueryVariables = Exact<{
  cohortId: Scalars['uuid'];
  userId: Scalars['uuid'];
}>;

export type GetCohortEnrollmentForSubmissionNotificationQuery = {
  __typename?: 'query_root';
  enrollments: Array<{
    __typename?: 'Enrollments';
    cohort?:
      | {
          __typename?: 'Cohorts';
          slug: string;
          startDate?: any | undefined;
          course?: {__typename?: 'Courses'; name: string} | undefined;
        }
      | undefined;
    user?:
      | {
          __typename?: 'Users';
          firstName?: string | undefined;
          identity: Array<{__typename?: 'Identities'; email?: string | undefined}>;
        }
      | undefined;
  }>;
};

export type GetLightningSubmissionsByCoachAndCohortQueryVariables = Exact<{
  coachUserId: Scalars['uuid'];
  cohortId: Scalars['uuid'];
}>;

export type GetLightningSubmissionsByCoachAndCohortQuery = {
  __typename?: 'query_root';
  lightningSessionSubmissions: Array<{
    __typename?: 'LightningSessionSubmissions';
    id: any;
    status: LightningSessionSubmissionsStatusEnum;
    coachUserId?: any | undefined;
    coach?:
      | {
          __typename?: 'Users';
          id: any;
          fullName?: string | undefined;
          firstName?: string | undefined;
          lastName?: string | undefined;
          identity: Array<{__typename?: 'Identities'; email?: string | undefined}>;
        }
      | undefined;
    cohort: {
      __typename?: 'Cohorts';
      id: any;
      name: string;
      startDate?: any | undefined;
      course?: {__typename?: 'Courses'; name: string} | undefined;
    };
  }>;
};

export type GetManagerPostSessionEmailsQueryVariables = Exact<{[key: string]: never}>;

export type GetManagerPostSessionEmailsQuery = {
  __typename?: 'query_root';
  managerSessionEmails: Array<{
    __typename?: 'ManagerSessionEmails';
    courseName: string;
    coursePlatformUrl: string;
    creditModel?: string | undefined;
    csmEmail?: string | undefined;
    csmFullName?: string | undefined;
    csmSignature?: string | undefined;
    enrolleesCommaSeparatedList: string;
    enrolleesForPast: string;
    frameworkHighlight?: string | undefined;
    managerCohortId: string;
    managerPastSessionUrl: string;
    percentUsersAttended: number;
    posterFrameUrl?: string | undefined;
    recommendations?: string | undefined;
    startTimeFormatted: string;
    user: {
      __typename?: 'Users';
      firstName?: string | undefined;
      identity: Array<{__typename?: 'Identities'; email?: string | undefined}>;
    };
  }>;
};

export type GetManagerPreSessionEmailsQueryVariables = Exact<{[key: string]: never}>;

export type GetManagerPreSessionEmailsQuery = {
  __typename?: 'query_root';
  managerSessionEmails: Array<{
    __typename?: 'ManagerSessionEmails';
    courseName: string;
    coursePlatformUrl: string;
    csmEmail?: string | undefined;
    csmFullName?: string | undefined;
    csmSignature?: string | undefined;
    enrolleeEmails: string;
    enrolleesForUpcoming: string;
    firstClassDuration: number;
    managerCohortId: string;
    managerUpcomingSessionUrl: string;
    startTimeFormatted: string;
    user: {
      __typename?: 'Users';
      firstName?: string | undefined;
      identity: Array<{__typename?: 'Identities'; email?: string | undefined}>;
    };
  }>;
};

export type GetMondayReminderEmailsQueryVariables = Exact<{[key: string]: never}>;

export type GetMondayReminderEmailsQuery = {
  __typename?: 'query_root';
  userSessionEmails: Array<{
    __typename?: 'UserSessionEmails';
    userClassEventId: string;
    courseName: string;
    cohortSlug: string;
    formattedTimestamp: string;
    isReturningUser: boolean;
    creditModel?: string | undefined;
    leadCoachName?: string | undefined;
    leadCoachCompany?: string | undefined;
    frameworkUrl?: string | undefined;
    user: {
      __typename?: 'Users';
      firstName?: string | undefined;
      identity: Array<{__typename?: 'Identities'; email?: string | undefined}>;
    };
  }>;
};

export type CheckEnrollmentStatusForSubordinateQueryVariables = Exact<{
  cohortIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  userId: Scalars['uuid'];
}>;

export type CheckEnrollmentStatusForSubordinateQuery = {
  __typename?: 'query_root';
  enrollmentCount: {
    __typename?: 'SubordinatesAggregate';
    aggregate?: {__typename?: 'SubordinatesAggregateFields'; count: number} | undefined;
  };
  waitlistCount: {
    __typename?: 'SubordinatesAggregate';
    aggregate?: {__typename?: 'SubordinatesAggregateFields'; count: number} | undefined;
  };
};

export type GetClassEventByUserQueryVariables = Exact<{
  userId: Scalars['uuid'];
  classEventId: Scalars['uuid'];
}>;

export type GetClassEventByUserQuery = {
  __typename?: 'query_root';
  learner?:
    | {
        __typename?: 'Users';
        id: any;
        firstName?: string | undefined;
        lastName?: string | undefined;
        identity: Array<{__typename?: 'Identities'; email?: string | undefined}>;
      }
    | undefined;
  classEvent?:
    | {
        __typename?: 'ClassEvents';
        id: any;
        zoomMeetingId?: string | undefined;
        startTime?: any | undefined;
      }
    | undefined;
};

export type GetCohortByIdQueryVariables = Exact<{
  cohortId: Scalars['uuid'];
}>;

export type GetCohortByIdQuery = {
  __typename?: 'query_root';
  cohortsByPk?:
    | {
        __typename?: 'Cohorts';
        id: any;
        name: string;
        code: string;
        startDate?: any | undefined;
        classTimeOfDay?: any | undefined;
        classEvents: Array<{__typename?: 'ClassEvents'; id: any; startTime?: any | undefined}>;
        coaches: Array<{
          __typename?: 'CohortCoaches';
          coach: {__typename?: 'Coaches'; name: string};
        }>;
        course?:
          | {
              __typename?: 'Courses';
              id: any;
              slug: string;
              name: string;
              type: CourseTypeEnum;
              school?: {__typename?: 'Schools'; name: string} | undefined;
            }
          | undefined;
      }
    | undefined;
};

export type GetCohortsByIdQueryVariables = Exact<{
  cohortIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;

export type GetCohortsByIdQuery = {
  __typename?: 'query_root';
  cohorts: Array<{
    __typename?: 'Cohorts';
    id: any;
    name: string;
    code: string;
    startDate?: any | undefined;
    classTimeOfDay?: any | undefined;
    classEvents: Array<{__typename?: 'ClassEvents'; id: any; startTime?: any | undefined}>;
    coaches: Array<{__typename?: 'CohortCoaches'; coach: {__typename?: 'Coaches'; name: string}}>;
    course?:
      | {
          __typename?: 'Courses';
          id: any;
          slug: string;
          name: string;
          type: CourseTypeEnum;
          school?: {__typename?: 'Schools'; name: string} | undefined;
        }
      | undefined;
  }>;
};

export type GetEnrollLearnerCohortInfoQueryVariables = Exact<{
  userId: Scalars['uuid'];
  cohortId: Scalars['uuid'];
}>;

export type GetEnrollLearnerCohortInfoQuery = {
  __typename?: 'query_root';
  cohort?:
    | {
        __typename?: 'Cohorts';
        code: string;
        id: any;
        maxEnrollment?: number | undefined;
        type: CohortTypeEnum;
        visibility: CohortVisibilityEnumEnum;
        course?:
          | {
              __typename?: 'Courses';
              id: any;
              type: CourseTypeEnum;
              enrollmentStatus?: CourseEnrollmentStatusEnum | undefined;
            }
          | undefined;
      }
    | undefined;
  userEnrollments?:
    | {__typename?: 'Users'; allowEnrollmentsBySubscription?: boolean | undefined}
    | undefined;
  enrolledLearners: {
    __typename?: 'EnrollmentsAggregate';
    total?: {__typename?: 'EnrollmentsAggregateFields'; count: number} | undefined;
  };
  userEnrolled: {
    __typename?: 'EnrollmentsAggregate';
    enrolled?: {__typename?: 'EnrollmentsAggregateFields'; yes: number} | undefined;
    nodes: Array<{__typename?: 'Enrollments'; id: any}>;
  };
};

export type GetEnrollmentsByCohortQueryVariables = Exact<{
  cohortId: Scalars['uuid'];
}>;

export type GetEnrollmentsByCohortQuery = {
  __typename?: 'query_root';
  enrollments: Array<{
    __typename?: 'Enrollments';
    user?:
      | {
          __typename?: 'Users';
          id: any;
          firstName?: string | undefined;
          lastName?: string | undefined;
          enrollments: Array<{
            __typename?: 'Enrollments';
            cohort?:
              | {
                  __typename?: 'Cohorts';
                  id: any;
                  name: string;
                  code: string;
                  startDate?: any | undefined;
                  classTimeOfDay?: any | undefined;
                  classEvents: Array<{
                    __typename?: 'ClassEvents';
                    id: any;
                    startTime?: any | undefined;
                  }>;
                  coaches: Array<{
                    __typename?: 'CohortCoaches';
                    coach: {__typename?: 'Coaches'; name: string};
                  }>;
                  course?:
                    | {
                        __typename?: 'Courses';
                        id: any;
                        slug: string;
                        name: string;
                        type: CourseTypeEnum;
                        school?: {__typename?: 'Schools'; name: string} | undefined;
                      }
                    | undefined;
                }
              | undefined;
          }>;
          identity: Array<{__typename?: 'Identities'; email?: string | undefined}>;
        }
      | undefined;
  }>;
};

export type GetEnrollmentCapAndCountQueryVariables = Exact<{
  cohortId: Scalars['uuid'];
  now: Scalars['timestamp'];
}>;

export type GetEnrollmentCapAndCountQuery = {
  __typename?: 'query_root';
  cohortsByPk?:
    | {
        __typename?: 'Cohorts';
        enrollmentCap?: number | undefined;
        course?: {__typename?: 'Courses'; type: CourseTypeEnum} | undefined;
        nextClassEventStartTime: {
          __typename?: 'ClassEventsAggregate';
          aggregate?:
            | {
                __typename?: 'ClassEventsAggregateFields';
                min?:
                  | {__typename?: 'ClassEventsMinFields'; startTime?: any | undefined}
                  | undefined;
              }
            | undefined;
        };
        enrollmentCount: {
          __typename?: 'EnrollmentsAggregate';
          aggregate?: {__typename?: 'EnrollmentsAggregateFields'; count: number} | undefined;
        };
      }
    | undefined;
};

export type GetEnrollmentWaitlistByCohortQueryVariables = Exact<{
  cohortId: Scalars['uuid'];
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetEnrollmentWaitlistByCohortQuery = {
  __typename?: 'query_root';
  waitlistSize: {
    __typename?: 'EnrollmentWaitlistsAggregate';
    aggregate?: {__typename?: 'EnrollmentWaitlistsAggregateFields'; count: number} | undefined;
  };
  enrollmentWaitlists: Array<{
    __typename?: 'EnrollmentWaitlists';
    userId: any;
    organizationId: any;
    createdById: any;
    createdOnBehalfOfId?: any | undefined;
  }>;
};

export type GetEnrollmentsByUserCohortQueryVariables = Exact<{
  userId: Scalars['uuid'];
  cohortId: Scalars['uuid'];
  withRegistrations?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetEnrollmentsByUserCohortQuery = {
  __typename?: 'query_root';
  learner?:
    | {
        __typename?: 'Users';
        id: any;
        firstName?: string | undefined;
        lastName?: string | undefined;
        identity: Array<{__typename?: 'Identities'; email?: string | undefined}>;
        userRegistrations?: Array<{
          __typename?: 'FusionauthUserRegistrations';
          timezone?: string | undefined;
        }>;
      }
    | undefined;
  enrollments: Array<{__typename?: 'Enrollments'; id: any}>;
  waitlists: Array<{__typename?: 'EnrollmentWaitlists'; id: any}>;
  cohort?:
    | {
        __typename?: 'Cohorts';
        id: any;
        name: string;
        code: string;
        slug: string;
        startDate?: any | undefined;
        endDate?: any | undefined;
        type: CohortTypeEnum;
        course?:
          | {
              __typename?: 'Courses';
              id: any;
              name: string;
              type: CourseTypeEnum;
              coaches: Array<{
                __typename?: 'CourseCoaches';
                coach: {__typename?: 'Coaches'; name: string};
              }>;
            }
          | undefined;
        coaches: Array<{
          __typename?: 'CohortCoaches';
          coach: {__typename?: 'Coaches'; name: string};
        }>;
        classEvents: Array<{
          __typename?: 'ClassEvents';
          id: any;
          eventInviteVersion?: number | undefined;
          startTime?: any | undefined;
          endTime?: any | undefined;
          class?:
            | {
                __typename?: 'Classes';
                id: any;
                name: string;
                number?: number | undefined;
                isLive?: boolean | undefined;
                coaches: Array<{
                  __typename?: 'ClassCoaches';
                  coach: {__typename?: 'Coaches'; name: string};
                }>;
              }
            | undefined;
        }>;
      }
    | undefined;
};

export type GetSubordinateEnrollmentsQueryVariables = Exact<{
  cohortId: Scalars['uuid'];
  isActive?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetSubordinateEnrollmentsQuery = {
  __typename?: 'query_root';
  subordinates: Array<{
    __typename?: 'Subordinates';
    firstName?: string | undefined;
    lastName?: string | undefined;
    email?: string | undefined;
    teamNames?: string | undefined;
    userId?: any | undefined;
    imageUrl?: string | undefined;
    enrollments?: Array<any> | undefined;
    waitlists?: Array<any> | undefined;
  }>;
  cohortsByPk?:
    | {
        __typename?: 'Cohorts';
        maxEnrollment?: number | undefined;
        type: CohortTypeEnum;
        totalEnrollments?: any | undefined;
        course?: {__typename?: 'Courses'; name: string; type: CourseTypeEnum} | undefined;
      }
    | undefined;
};

export type FetchUserCohortEnrollmentStatusQueryVariables = Exact<{
  cohortId: Scalars['uuid'];
  userId: Scalars['uuid'];
}>;

export type FetchUserCohortEnrollmentStatusQuery = {
  __typename?: 'query_root';
  enrollments: Array<{
    __typename?: 'Enrollments';
    createdAt?: any | undefined;
    deletedAt?: any | undefined;
    cohort?:
      | {
          __typename?: 'Cohorts';
          id: any;
          name: string;
          code: string;
          startDate?: any | undefined;
          classTimeOfDay?: any | undefined;
          classEvents: Array<{__typename?: 'ClassEvents'; id: any; startTime?: any | undefined}>;
          coaches: Array<{
            __typename?: 'CohortCoaches';
            coach: {__typename?: 'Coaches'; name: string};
          }>;
          course?:
            | {
                __typename?: 'Courses';
                id: any;
                slug: string;
                name: string;
                type: CourseTypeEnum;
                school?: {__typename?: 'Schools'; name: string} | undefined;
              }
            | undefined;
        }
      | undefined;
  }>;
};

export type GetUserEnrolledInCohortQueryVariables = Exact<{
  userId: Scalars['uuid'];
  cohortId: Scalars['uuid'];
}>;

export type GetUserEnrolledInCohortQuery = {
  __typename?: 'query_root';
  userEnrolled: Array<{__typename?: 'Enrollments'; userId?: any | undefined}>;
};

export type GetUserEnrolledBySlugQueryVariables = Exact<{
  cohortSlug: Scalars['String'];
}>;

export type GetUserEnrolledBySlugQuery = {
  __typename?: 'query_root';
  is: {
    __typename?: 'EnrollmentsAggregate';
    user?: {__typename?: 'EnrollmentsAggregateFields'; enrolled: number} | undefined;
  };
};

export type GetUserEnrollmentsQueryVariables = Exact<{
  user_id: Scalars['uuid'];
  simple?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetUserEnrollmentsQuery = {
  __typename?: 'query_root';
  user?:
    | {
        __typename?: 'Users';
        activeSubscription?: Array<{__typename?: 'Plans'; name: string}> | undefined;
        enrollments: Array<{
          __typename?: 'Enrollments';
          cohortId?: any | undefined;
          cohort?:
            | {
                __typename?: 'Cohorts';
                id: any;
                name: string;
                code: string;
                startDate?: any | undefined;
                classTimeOfDay?: any | undefined;
                endDate?: any | undefined;
                dayOfTheWeek?: string | undefined;
                slug: string;
                type: CohortTypeEnum;
                classEvents: Array<{
                  __typename?: 'ClassEvents';
                  id: any;
                  startTime?: any | undefined;
                }>;
                coaches: Array<{
                  __typename?: 'CohortCoaches';
                  coach: {__typename?: 'Coaches'; name: string};
                }>;
                course?:
                  | {
                      __typename?: 'Courses';
                      id: any;
                      slug: string;
                      name: string;
                      type: CourseTypeEnum;
                      backgroundImageUrl?: string | undefined;
                      thumbnailCardUrl?: string | undefined;
                      school?: {__typename?: 'Schools'; name: string} | undefined;
                    }
                  | undefined;
              }
            | undefined;
        }>;
        waitlists: Array<{__typename?: 'EnrollmentWaitlists'; cohortId: any}>;
      }
    | undefined;
};

export type GetExternalIdentifiersByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;

export type GetExternalIdentifiersByEmailQuery = {
  __typename?: 'query_root';
  fusionauthExternalIdentifiers: Array<{
    __typename?: 'FusionauthExternalIdentifiers';
    id: string;
    insertInstant: any;
  }>;
};

export type GetExternalIdentifiersByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;

export type GetExternalIdentifiersByIdQuery = {
  __typename?: 'query_root';
  fusionauthExternalIdentifiers: Array<{
    __typename?: 'FusionauthExternalIdentifiers';
    id: string;
    insertInstant: any;
    user?:
      | {
          __typename?: 'Users';
          identity: Array<{__typename?: 'Identities'; email?: string | undefined}>;
        }
      | undefined;
  }>;
};

export type GetUserInvitesForCohortQueryVariables = Exact<{
  userId: Scalars['uuid'];
  cohortId: Scalars['uuid'];
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetUserInvitesForCohortQuery = {
  __typename?: 'query_root';
  invites: Array<{__typename?: 'ClassEventInvitations'; createdAt?: any | undefined}>;
};

export type GetCohortDetailsForLedgerQueryVariables = Exact<{
  cohortId: Scalars['uuid'];
}>;

export type GetCohortDetailsForLedgerQuery = {
  __typename?: 'query_root';
  cohortsByPk?:
    | {
        __typename?: 'Cohorts';
        slug: string;
        classEvents: Array<{__typename?: 'ClassEvents'; startTime?: any | undefined}>;
        course?: {__typename?: 'Courses'; type: CourseTypeEnum} | undefined;
      }
    | undefined;
};

export type GetLedgerTailQueryVariables = Exact<{
  organizationId: Scalars['uuid'];
}>;

export type GetLedgerTailQuery = {
  __typename?: 'query_root';
  ledger: Array<{
    __typename?: 'Ledger';
    id: string;
    creditLedgerId: number;
    availableCreditBalance: number;
    creditBalance: number;
    totalAllocatedCredits: number;
    isCurrentSubscription: boolean;
    subscriptionEndDate?: any | undefined;
    subscriptionStartDate?: any | undefined;
    subscriptionCreditsBought?: number | undefined;
    totalCreditsSpent?: number | undefined;
    creditModel: string;
  }>;
};

export type GetLightningSessionSubmissionByChannelIdQueryVariables = Exact<{
  streamChannelId: Scalars['String'];
}>;

export type GetLightningSessionSubmissionByChannelIdQuery = {
  __typename?: 'query_root';
  lightningSessionSubmissions: Array<{
    __typename?: 'LightningSessionSubmissions';
    id: any;
    userId: any;
    status: LightningSessionSubmissionsStatusEnum;
    streamChannelId: string;
    cohortId: any;
    coachUserId?: any | undefined;
    cohort: {
      __typename?: 'Cohorts';
      id: any;
      slug: string;
      course?: {__typename?: 'Courses'; id: any; slug: string; type: CourseTypeEnum} | undefined;
    };
    streamMessages: Array<{
      __typename?: 'StreamMessages';
      id: any;
      authorId: any;
      createdAt: string;
    }>;
  }>;
};

export type GetLightningSessionSubmissionByCohortAndCoachesQueryVariables = Exact<{
  cohortId: Scalars['uuid'];
  coachesUserIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;

export type GetLightningSessionSubmissionByCohortAndCoachesQuery = {
  __typename?: 'query_root';
  lightningSessionSubmissions: Array<{
    __typename?: 'LightningSessionSubmissions';
    id: any;
    userId: any;
    status: LightningSessionSubmissionsStatusEnum;
    streamChannelId: string;
    cohortId: any;
    coachUserId?: any | undefined;
    createdAt: any;
  }>;
};

export type GetLightningSubmissionQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetLightningSubmissionQuery = {
  __typename?: 'query_root';
  lightningSessionSubmission?:
    | {
        __typename?: 'LightningSessionSubmissions';
        id: any;
        userId: any;
        status: LightningSessionSubmissionsStatusEnum;
        streamChannelId: string;
        cohortId: any;
        coachUserId?: any | undefined;
        interactionsCount?: number | undefined;
        cohort: {
          __typename?: 'Cohorts';
          communitySlackChannelUrl?: string | undefined;
          communitySlackInviteUrl?: string | undefined;
          courseId?: any | undefined;
          id: any;
          name: string;
          slug: string;
          surveyLinkUrl?: string | undefined;
          type: CohortTypeEnum;
          isPostCourseSurveyAvailable?: boolean | undefined;
          preworkRichText?: string | undefined;
          preworkVideoUrl?: string | undefined;
          requiresPractice: boolean;
          startDate?: any | undefined;
          endDate?: any | undefined;
          classTimeOfDay?: any | undefined;
          classEvents: Array<{__typename?: 'ClassEvents'; id: any; classId?: any | undefined}>;
          course?:
            | {
                __typename?: 'Courses';
                id: any;
                type: CourseTypeEnum;
                name: string;
                slug: string;
                preCourseSurveyFormId?: string | undefined;
                postCourseSurveyFormId?: string | undefined;
                impactAssessment?: {__typename?: 'ImpactAssessments'; id: any} | undefined;
                resources: Array<{
                  __typename?: 'CoursesResourcesRel';
                  resource: {
                    __typename?: 'Resources';
                    id: any;
                    filename: string;
                    contentType: string;
                    name: string;
                    url: string;
                    type: ResourceTypeEnum;
                  };
                }>;
              }
            | undefined;
          resources: Array<{
            __typename?: 'CohortsResourcesRel';
            resource?:
              | {
                  __typename?: 'Resources';
                  id: any;
                  filename: string;
                  contentType: string;
                  name: string;
                  url: string;
                  type: ResourceTypeEnum;
                }
              | undefined;
          }>;
        };
        pitchSegment?:
          | {
              __typename?: 'ClassEventMeetingSegments';
              startTimecode: number;
              endTimecode: number;
              createdAt?: any | undefined;
              videoClipUrl?: string | undefined;
              classEventMeeting: {
                __typename?: 'ClassEventMeetings';
                recordingUrl?: string | undefined;
              };
            }
          | undefined;
        user: {
          __typename?: 'Users';
          id: any;
          firstName?: string | undefined;
          lastName?: string | undefined;
          imageUrl?: string | undefined;
        };
      }
    | undefined;
};

export type GetLightningSubmissionByCoachQueryVariables = Exact<{
  coachUserId: Scalars['uuid'];
  status?: InputMaybe<LightningSessionSubmissionsStatusEnum>;
}>;

export type GetLightningSubmissionByCoachQuery = {
  __typename?: 'query_root';
  lightningSessionSubmissions: Array<{
    __typename?: 'LightningSessionSubmissions';
    id: any;
    userId: any;
    status: LightningSessionSubmissionsStatusEnum;
    streamChannelId: string;
    cohortId: any;
    coachUserId?: any | undefined;
    coach?:
      | {
          __typename?: 'Users';
          id: any;
          fullName?: string | undefined;
          firstName?: string | undefined;
          lastName?: string | undefined;
        }
      | undefined;
    pitchSegment?:
      | {
          __typename?: 'ClassEventMeetingSegments';
          startTimecode: number;
          endTimecode: number;
          videoClipUrl?: string | undefined;
          classEventMeeting: {__typename?: 'ClassEventMeetings'; recordingUrl?: string | undefined};
        }
      | undefined;
    user: {
      __typename?: 'Users';
      id: any;
      firstName?: string | undefined;
      lastName?: string | undefined;
      imageUrl?: string | undefined;
    };
    cohort: {
      __typename?: 'Cohorts';
      id: any;
      name: string;
      classEvents: Array<{__typename?: 'ClassEvents'; endTime?: any | undefined}>;
      course?: {__typename?: 'Courses'; type: CourseTypeEnum; name: string} | undefined;
    };
  }>;
};

export type GetLightningSubmissionByUserAndCohortQueryVariables = Exact<{
  userId: Scalars['uuid'];
  cohortId: Scalars['uuid'];
}>;

export type GetLightningSubmissionByUserAndCohortQuery = {
  __typename?: 'query_root';
  lightningSessionSubmissions: Array<{
    __typename?: 'LightningSessionSubmissions';
    id: any;
    userId: any;
    status: LightningSessionSubmissionsStatusEnum;
    streamChannelId: string;
    cohortId: any;
    score?: string | undefined;
    comment?: string | undefined;
    coachUserId?: any | undefined;
    cohort: {
      __typename?: 'Cohorts';
      id: any;
      course?:
        | {
            __typename?: 'Courses';
            id: any;
            preCourseSurveyFormId?: string | undefined;
            postCourseSurveyFormId?: string | undefined;
          }
        | undefined;
      classEvents: Array<{__typename?: 'ClassEvents'; id: any}>;
    };
    coach?:
      | {
          __typename?: 'Users';
          id: any;
          fullName?: string | undefined;
          firstName?: string | undefined;
          lastName?: string | undefined;
        }
      | undefined;
    pitchSegment?:
      | {
          __typename?: 'ClassEventMeetingSegments';
          startTimecode: number;
          endTimecode: number;
          videoClipUrl?: string | undefined;
          classEventMeeting: {__typename?: 'ClassEventMeetings'; recordingUrl?: string | undefined};
        }
      | undefined;
    user: {
      __typename?: 'Users';
      id: any;
      firstName?: string | undefined;
      lastName?: string | undefined;
      imageUrl?: string | undefined;
    };
    relatedUsers: Array<{__typename?: 'LightningSessionSubmissionsUsersRel'; userId: any}>;
  }>;
};

export type GetLightningSubmissionByUserAndCohortAndDeleteStatusQueryVariables = Exact<{
  userId: Scalars['uuid'];
  cohortId: Scalars['uuid'];
  deletedAt?: InputMaybe<TimestampComparisonExp>;
}>;

export type GetLightningSubmissionByUserAndCohortAndDeleteStatusQuery = {
  __typename?: 'query_root';
  lightningSessionSubmissions: Array<{
    __typename?: 'LightningSessionSubmissions';
    id: any;
    userId: any;
    streamChannelId: string;
    cohortId: any;
  }>;
};

export type GetLightningSubmissionEmailFlagsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetLightningSubmissionEmailFlagsQuery = {
  __typename?: 'query_root';
  lightningSessionSubmissionsByPk?:
    | {
        __typename?: 'LightningSessionSubmissions';
        coachEmailSent?: boolean | undefined;
        learnerEmailSent?: boolean | undefined;
      }
    | undefined;
};

export type GetMissingRecordingForSubmissionQueryVariables = Exact<{
  lightningSubmissionId: Scalars['uuid'];
  userId: Scalars['uuid'];
}>;

export type GetMissingRecordingForSubmissionQuery = {
  __typename?: 'query_root';
  recordings: Array<{
    __typename?: 'Recordings';
    id: any;
    hash: string;
    sourceUrl: string;
    publicUrl?: string | undefined;
    lightningSubmissionId?: any | undefined;
    sentToChannel?: boolean | undefined;
    createdAt: any;
    createdBy?:
      | {
          __typename?: 'Users';
          firstName?: string | undefined;
          lastName?: string | undefined;
          imageUrl?: string | undefined;
        }
      | undefined;
  }>;
};

export type AsyncAverageMetricFragment = {
  __typename?: 'GetPastSessionAsyncMetricsMetric';
  async: boolean;
  benchmark?: string | undefined;
  count?: number | undefined;
  description?: string | undefined;
  percentage?: number | undefined;
  title: string;
  type: string;
};

export type GetPastSessionAsyncAverageMetricsActionQueryVariables = Exact<{[key: string]: never}>;

export type GetPastSessionAsyncAverageMetricsActionQuery = {
  __typename?: 'query_root';
  GetPastSessionsAsyncAverageMetrics?:
    | {
        __typename?: 'GetPastSessionAsyncAverageMetricsOutput';
        asyncMetrics?:
          | {
              __typename?: 'GetPastSessionAsyncAverageMetricsSnowflakeMetrics';
              averageAttendanceRateMetric?:
                | {
                    __typename?: 'GetPastSessionAsyncMetricsMetric';
                    async: boolean;
                    benchmark?: string | undefined;
                    count?: number | undefined;
                    description?: string | undefined;
                    percentage?: number | undefined;
                    title: string;
                    type: string;
                  }
                | undefined;
              averageHighConfidenceMetric?:
                | {
                    __typename?: 'GetPastSessionAsyncMetricsMetric';
                    async: boolean;
                    benchmark?: string | undefined;
                    count?: number | undefined;
                    description?: string | undefined;
                    percentage?: number | undefined;
                    title: string;
                    type: string;
                  }
                | undefined;
              averageHighEngagementMetric?:
                | {
                    __typename?: 'GetPastSessionAsyncMetricsMetric';
                    async: boolean;
                    benchmark?: string | undefined;
                    count?: number | undefined;
                    description?: string | undefined;
                    percentage?: number | undefined;
                    title: string;
                    type: string;
                  }
                | undefined;
              averageHighJobImpactMetric?:
                | {
                    __typename?: 'GetPastSessionAsyncMetricsMetric';
                    async: boolean;
                    benchmark?: string | undefined;
                    count?: number | undefined;
                    description?: string | undefined;
                    percentage?: number | undefined;
                    title: string;
                    type: string;
                  }
                | undefined;
            }
          | undefined;
        pastSessionsWithMetrics: Array<
          | {
              __typename?: 'GetPastSessionAsyncAverageMetricsPastSession';
              cohortId: any;
              metrics?:
                | {
                    __typename?: 'GetPastSessionAsyncMetricsSnowflakeMetrics';
                    attendanceRateMetric?:
                      | {
                          __typename?: 'GetPastSessionAsyncMetricsMetric';
                          async: boolean;
                          benchmark?: string | undefined;
                          count?: number | undefined;
                          description?: string | undefined;
                          percentage?: number | undefined;
                          title: string;
                          type: string;
                        }
                      | undefined;
                    highConfidenceMetric?:
                      | {
                          __typename?: 'GetPastSessionAsyncMetricsMetric';
                          async: boolean;
                          benchmark?: string | undefined;
                          count?: number | undefined;
                          description?: string | undefined;
                          percentage?: number | undefined;
                          title: string;
                          type: string;
                        }
                      | undefined;
                    highEngagementMetric?:
                      | {
                          __typename?: 'GetPastSessionAsyncMetricsMetric';
                          async: boolean;
                          benchmark?: string | undefined;
                          count?: number | undefined;
                          description?: string | undefined;
                          percentage?: number | undefined;
                          title: string;
                          type: string;
                        }
                      | undefined;
                    highJobImpactMetric?:
                      | {
                          __typename?: 'GetPastSessionAsyncMetricsMetric';
                          async: boolean;
                          benchmark?: string | undefined;
                          count?: number | undefined;
                          description?: string | undefined;
                          percentage?: number | undefined;
                          title: string;
                          type: string;
                        }
                      | undefined;
                  }
                | undefined;
            }
          | undefined
        >;
      }
    | undefined;
};

export type AsyncMetricFragment = {
  __typename?: 'GetPastSessionAsyncMetricsMetric';
  async: boolean;
  benchmark?: string | undefined;
  count?: number | undefined;
  description?: string | undefined;
  percentage?: number | undefined;
  title: string;
  type: string;
};

export type GetPastSessionAsyncMetricsActionQueryVariables = Exact<{
  cohortId: Scalars['uuid'];
}>;

export type GetPastSessionAsyncMetricsActionQuery = {
  __typename?: 'query_root';
  GetPastSessionAsyncMetrics?:
    | {
        __typename?: 'GetPastSessionAsyncMetricsOutput';
        sessionMembersWithNPS: Array<
          | {
              __typename?: 'GetPastSessionAsyncMetricsMemberWithSessionNps';
              id: any;
              pollSubmission?:
                | {
                    __typename?: 'GetPastSessionAsyncMetricsMemberSessionNps';
                    confidenceScore: string;
                    engagementScore: string;
                    jobImpactScore: string;
                    npsScore: string;
                    takeawayQuote: string;
                    whichDeals: string;
                    numDealsWhereApplied: string;
                  }
                | undefined;
            }
          | undefined
        >;
        asyncMetrics?:
          | {
              __typename?: 'GetPastSessionAsyncMetricsSnowflakeMetrics';
              attendanceRateMetric?:
                | {
                    __typename?: 'GetPastSessionAsyncMetricsMetric';
                    async: boolean;
                    benchmark?: string | undefined;
                    count?: number | undefined;
                    description?: string | undefined;
                    percentage?: number | undefined;
                    title: string;
                    type: string;
                  }
                | undefined;
              highCoachFeedbackMetric?:
                | {
                    __typename?: 'GetPastSessionAsyncMetricsMetric';
                    async: boolean;
                    benchmark?: string | undefined;
                    count?: number | undefined;
                    description?: string | undefined;
                    percentage?: number | undefined;
                    title: string;
                    type: string;
                  }
                | undefined;
              highConfidenceMetric?:
                | {
                    __typename?: 'GetPastSessionAsyncMetricsMetric';
                    async: boolean;
                    benchmark?: string | undefined;
                    count?: number | undefined;
                    description?: string | undefined;
                    percentage?: number | undefined;
                    title: string;
                    type: string;
                  }
                | undefined;
              highEngagementMetric?:
                | {
                    __typename?: 'GetPastSessionAsyncMetricsMetric';
                    async: boolean;
                    benchmark?: string | undefined;
                    count?: number | undefined;
                    description?: string | undefined;
                    percentage?: number | undefined;
                    title: string;
                    type: string;
                  }
                | undefined;
              highJobImpactMetric?:
                | {
                    __typename?: 'GetPastSessionAsyncMetricsMetric';
                    async: boolean;
                    benchmark?: string | undefined;
                    count?: number | undefined;
                    description?: string | undefined;
                    percentage?: number | undefined;
                    title: string;
                    type: string;
                  }
                | undefined;
            }
          | undefined;
      }
    | undefined;
};

export type FindOrganizationQueryVariables = Exact<{
  name: Scalars['String'];
}>;

export type FindOrganizationQuery = {
  __typename?: 'query_root';
  organizations: Array<{__typename?: 'Organizations'; id: any}>;
};

export type GetAllOrgUsersQueryVariables = Exact<{
  orgId: Scalars['uuid'];
}>;

export type GetAllOrgUsersQuery = {
  __typename?: 'query_root';
  organizationUsers: Array<{
    __typename?: 'OrganizationUsers';
    user: {
      __typename?: 'Users';
      id: any;
      firstName?: string | undefined;
      lastName?: string | undefined;
      active: boolean;
      identity: Array<{__typename?: 'Identities'; email?: string | undefined}>;
    };
  }>;
};

export type GetOrganizationByPkQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetOrganizationByPkQuery = {
  __typename?: 'query_root';
  organizationsByPk?: {__typename?: 'Organizations'; id: any; name: string} | undefined;
};

export type GetOrganizationPoliciesQueryVariables = Exact<{
  orgId: Scalars['uuid'];
}>;

export type GetOrganizationPoliciesQuery = {
  __typename?: 'query_root';
  organizations: Array<{
    __typename?: 'Organizations';
    id: any;
    name: string;
    planSubscriptions: Array<{
      __typename?: 'PlanSubscriptions';
      plan: {__typename?: 'Plans'; policy: any};
    }>;
  }>;
};

export type GetPlanBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetPlanBySlugQuery = {
  __typename?: 'query_root';
  plans: Array<{__typename?: 'Plans'; id: any}>;
};

export type GetAllPlayExecutionQueryVariables = Exact<{[key: string]: never}>;

export type GetAllPlayExecutionQuery = {
  __typename?: 'query_root';
  playExecution: Array<{
    __typename?: 'PlayExecution';
    id: any;
    success?: boolean | undefined;
    data?: any | undefined;
    users?: any | undefined;
    message?: string | undefined;
    playName?: string | undefined;
    createdAt: any;
    params?: any | undefined;
  }>;
};

export type PlayGetClassesForCourseQueryVariables = Exact<{
  courseId: Scalars['uuid'];
}>;

export type PlayGetClassesForCourseQuery = {
  __typename?: 'query_root';
  classes: Array<{
    __typename?: 'Classes';
    id: any;
    name: string;
    version?: number | undefined;
    slug: string;
  }>;
};

export type PlayGetLightningSessionSubmissionByUserAndCohortIdsQueryVariables = Exact<{
  userId: Scalars['uuid'];
  cohortId: Scalars['uuid'];
}>;

export type PlayGetLightningSessionSubmissionByUserAndCohortIdsQuery = {
  __typename?: 'query_root';
  lightningSessionSubmissions: Array<{
    __typename?: 'LightningSessionSubmissions';
    id: any;
    userId: any;
    status: LightningSessionSubmissionsStatusEnum;
    streamChannelId: string;
    cohortId: any;
    coachUserId?: any | undefined;
  }>;
};

export type PlayGetPublicCatalogQueryVariables = Exact<{[key: string]: never}>;

export type PlayGetPublicCatalogQuery = {
  __typename?: 'query_root';
  catalogs: Array<{__typename?: 'Catalogs'; id: any; name: string; slug: string}>;
};

export type PlayGetSchoolQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type PlayGetSchoolQuery = {
  __typename?: 'query_root';
  schools: Array<{__typename?: 'Schools'; id: any; name: string; slug: string}>;
};

export type PlayGetTagQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type PlayGetTagQuery = {
  __typename?: 'query_root';
  tags: Array<{__typename?: 'Tags'; id: any}>;
};

export type PlayGetUnlimitedPlanQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']>;
}>;

export type PlayGetUnlimitedPlanQuery = {
  __typename?: 'query_root';
  plans: Array<{__typename?: 'Plans'; id: any; name: string}>;
};

export type GetRecordingByHashQueryVariables = Exact<{
  hash: Scalars['String'];
}>;

export type GetRecordingByHashQuery = {
  __typename?: 'query_root';
  recordings: Array<{
    __typename?: 'Recordings';
    id: any;
    hash: string;
    title?: string | undefined;
    description?: string | undefined;
    type: string;
    sourceUrl: string;
    publicUrl?: string | undefined;
    synced?: boolean | undefined;
    public: boolean;
  }>;
};

export type GetRecordingsToSyncQueryVariables = Exact<{[key: string]: never}>;

export type GetRecordingsToSyncQuery = {
  __typename?: 'query_root';
  recordings: Array<{
    __typename?: 'Recordings';
    id: any;
    hash: string;
    type: string;
    sourceUrl: string;
    publicUrl?: string | undefined;
    meta?: any | undefined;
    transcription?: any | undefined;
    summary?: any | undefined;
    synced?: boolean | undefined;
  }>;
};

export type ClassEventsWithCompleteActivitiesFragment = {
  __typename?: 'Cohorts';
  classEventsWithCompleteActivities: Array<{
    __typename?: 'ClassEvents';
    id: any;
    class?: {__typename?: 'Classes'; number?: number | undefined} | undefined;
    completeActivities: Array<{
      __typename?: 'ClassEventActivity';
      id: any;
      context?: any | undefined;
      userId?: any | undefined;
      createdAt?: any | undefined;
    }>;
  }>;
};

export type ClassEventsWithFeedbacKViewedActivitiesFragment = {
  __typename?: 'Cohorts';
  classEventsWithFeedbackViewedActivities: Array<{
    __typename?: 'ClassEvents';
    feedbackViewedActivities: Array<{
      __typename?: 'ClassEventActivity';
      classEventId?: any | undefined;
      userId?: any | undefined;
    }>;
  }>;
};

export type ClassEventCompleteActivitiesFragment = {
  __typename?: 'ClassEvents';
  id: any;
  class?: {__typename?: 'Classes'; number?: number | undefined} | undefined;
  completeActivities: Array<{
    __typename?: 'ClassEventActivity';
    id: any;
    context?: any | undefined;
    userId?: any | undefined;
    createdAt?: any | undefined;
  }>;
};

export type ClassEventFeedbackViewedActivitiesFragment = {
  __typename?: 'ClassEvents';
  feedbackViewedActivities: Array<{
    __typename?: 'ClassEventActivity';
    classEventId?: any | undefined;
    userId?: any | undefined;
  }>;
};

export type GetEnrollmentsExportQueryVariables = Exact<{
  userIds: Array<Scalars['uuid']> | Scalars['uuid'];
}>;

export type GetEnrollmentsExportQuery = {
  __typename?: 'query_root';
  lightningSessionSubmissions: Array<{
    __typename?: 'LightningSessionSubmissions';
    userId: any;
    cohortId: any;
    status: LightningSessionSubmissionsStatusEnum;
  }>;
  cohorts: Array<{
    __typename?: 'Cohorts';
    id: any;
    startDate?: any | undefined;
    type: CohortTypeEnum;
    course?: {__typename?: 'Courses'; name: string; type: CourseTypeEnum} | undefined;
    userScores: Array<{
      __typename?: 'ImpactAssessmentAttempts';
      userId?: any | undefined;
      percentCorrect?: number | undefined;
    }>;
    classEvents: Array<{__typename?: 'ClassEvents'; startTime?: any | undefined}>;
    enrollments: Array<{
      __typename?: 'Enrollments';
      userId?: any | undefined;
      createdAt?: any | undefined;
      completedAt?: any | undefined;
      user?:
        | {
            __typename?: 'Users';
            active: boolean;
            firstName?: string | undefined;
            lastName?: string | undefined;
            insertInstant: any;
            identity: Array<{__typename?: 'Identities'; email?: string | undefined}>;
          }
        | undefined;
    }>;
    classEventsWithFeedbackViewedActivities: Array<{
      __typename?: 'ClassEvents';
      feedbackViewedActivities: Array<{
        __typename?: 'ClassEventActivity';
        classEventId?: any | undefined;
        userId?: any | undefined;
      }>;
    }>;
    classEventsWithCompleteActivities: Array<{
      __typename?: 'ClassEvents';
      id: any;
      class?: {__typename?: 'Classes'; number?: number | undefined} | undefined;
      completeActivities: Array<{
        __typename?: 'ClassEventActivity';
        id: any;
        context?: any | undefined;
        userId?: any | undefined;
        createdAt?: any | undefined;
      }>;
    }>;
  }>;
};

export type GetOnDemandCoursesExportQueryVariables = Exact<{
  userIds: Array<Scalars['uuid']> | Scalars['uuid'];
}>;

export type GetOnDemandCoursesExportQuery = {
  __typename?: 'query_root';
  cohorts: Array<{
    __typename?: 'Cohorts';
    course?: {__typename?: 'Courses'; name: string} | undefined;
    classEvents: Array<{__typename?: 'ClassEvents'; id: any}>;
    enrollments: Array<{
      __typename?: 'Enrollments';
      userId?: any | undefined;
      user?:
        | {__typename?: 'Users'; firstName?: string | undefined; lastName?: string | undefined}
        | undefined;
    }>;
    classEventsWithCompleteActivities: Array<{
      __typename?: 'ClassEvents';
      id: any;
      class?: {__typename?: 'Classes'; number?: number | undefined} | undefined;
      completeActivities: Array<{
        __typename?: 'ClassEventActivity';
        id: any;
        context?: any | undefined;
        userId?: any | undefined;
        createdAt?: any | undefined;
      }>;
    }>;
  }>;
};

export type GetReportsByTypeQueryVariables = Exact<{
  reportTypes: Array<ReportTypeEnum> | ReportTypeEnum;
}>;

export type GetReportsByTypeQuery = {
  __typename?: 'query_root';
  reports: Array<{
    __typename?: 'Reports';
    id: any;
    name: string;
    slug: string;
    embedPath: string;
    desc: string;
    height?: number | undefined;
    type: ReportTypeEnum;
  }>;
};

export type GetResourceQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetResourceQuery = {
  __typename?: 'query_root';
  resource?:
    | {
        __typename?: 'Resources';
        id: any;
        filename: string;
        url: string;
        name: string;
        descriptionRich?: string | undefined;
        type: ResourceTypeEnum;
        contentType: string;
        slug: string;
      }
    | undefined;
};

export type GetAllSchoolsQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<SchoolsOrderBy> | SchoolsOrderBy>;
  now: Scalars['timestamp'];
}>;

export type GetAllSchoolsQuery = {
  __typename?: 'query_root';
  schools: Array<{__typename?: 'Schools'; id: any; name: string; slug: string}>;
};

export type GetFunnelCategoryTagsQueryVariables = Exact<{[key: string]: never}>;

export type GetFunnelCategoryTagsQuery = {
  __typename?: 'query_root';
  tags: Array<{
    __typename?: 'Tags';
    id: any;
    name: string;
    slug: string;
    description?: string | undefined;
  }>;
};

export type GetRoleTagsQueryVariables = Exact<{[key: string]: never}>;

export type GetRoleTagsQuery = {
  __typename?: 'query_root';
  tags: Array<{__typename?: 'Tags'; id: any; name: string; slug: string}>;
};

export type GetSkillTagsQueryVariables = Exact<{[key: string]: never}>;

export type GetSkillTagsQuery = {
  __typename?: 'query_root';
  tags: Array<{
    __typename?: 'Tags';
    id: any;
    name: string;
    slug: string;
    description?: string | undefined;
  }>;
};

export type GetAllTeamsFromOrgQueryVariables = Exact<{
  organizationId: Scalars['uuid'];
}>;

export type GetAllTeamsFromOrgQuery = {
  __typename?: 'query_root';
  teams: Array<{__typename?: 'Teams'; id: any; name: string; parentId?: any | undefined}>;
};

export type CountTeamSessionsQueryVariables = Exact<{
  courseSlug: Scalars['String'];
}>;

export type CountTeamSessionsQuery = {
  __typename?: 'query_root';
  CountTeamSessions?:
    | {
        __typename?: 'CountTeamSessionsOutput';
        users?:
          | Array<
              | {
                  __typename?: 'CountTeamSessionsItem';
                  id: any;
                  firstName: string;
                  lastName?: string | undefined;
                  imageUrl: string;
                  count: number;
                }
              | undefined
            >
          | undefined;
      }
    | undefined;
};

export type GetManagersForMemberQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetManagersForMemberQuery = {
  __typename?: 'query_root';
  subordinates: Array<{
    __typename?: 'Subordinates';
    manager?:
      | {
          __typename?: 'Users';
          id: any;
          firstName?: string | undefined;
          lastName?: string | undefined;
        }
      | undefined;
  }>;
};

export type GetTeamByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetTeamByIdQuery = {
  __typename?: 'query_root';
  teamsByPk?: {__typename?: 'Teams'; id: any; name: string} | undefined;
};

export type GetTeamMemberForManagerQueryVariables = Exact<{
  userId: Scalars['uuid'];
  isActive?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetTeamMemberForManagerQuery = {
  __typename?: 'query_root';
  subordinates: Array<{
    __typename?: 'Subordinates';
    managerId?: any | undefined;
    userId?: any | undefined;
    enrollments?: Array<any> | undefined;
    waitlists?: Array<any> | undefined;
  }>;
};

export type GetTeamMembersQueryVariables = Exact<{
  teamFlatTree: Scalars['jsonb'];
  isActive: Scalars['Boolean'];
}>;

export type GetTeamMembersQuery = {
  __typename?: 'query_root';
  getTeamMembers: Array<{
    __typename?: 'teamMember';
    userId: any;
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    teamId: any;
    teamName: string;
    jobFunction?: string | undefined;
    jobFunctionLength?: string | undefined;
    teamIds: any;
    teamNames: any;
    isActive: boolean;
  }>;
};

export type GetTeamsForUserQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetTeamsForUserQuery = {
  __typename?: 'query_root';
  teamsUsers: Array<{__typename?: 'TeamsUsers'; teamId: any; role: string}>;
};

export type MySubordinatesQueryVariables = Exact<{[key: string]: never}>;

export type MySubordinatesQuery = {
  __typename?: 'query_root';
  subordinates: Array<{__typename?: 'Subordinates'; userId?: any | undefined}>;
};

export type MyTeamFlatTreeQueryVariables = Exact<{
  organizationId: Scalars['uuid'];
  userId: Scalars['uuid'];
}>;

export type MyTeamFlatTreeQuery = {
  __typename?: 'query_root';
  myTeamFlatTree: Array<{
    __typename?: 'team_flat_tree_node';
    teamId: any;
    parentId?: any | undefined;
    organizationId: any;
    name: string;
    jobFunction?: string | undefined;
    slug: string;
    branch: string;
    managerNames: any;
    memberNames: any;
    treeId: any;
    nodeHeight: number;
    hasChildrenTeams: boolean;
  }>;
};

export type MyTeamMembersQueryVariables = Exact<{
  onlyActive?: InputMaybe<Scalars['Boolean']>;
}>;

export type MyTeamMembersQuery = {
  __typename?: 'query_root';
  MyTeamMembers?:
    | {
        __typename?: 'MyTeamMembersOutput';
        members?:
          | Array<{
              __typename?: 'MyTeamMember';
              id: string;
              fullName: string;
              avatarUrl?: string | undefined;
              email: string;
              teamId?: string | undefined;
              teamName?: string | undefined;
            }>
          | undefined;
      }
    | undefined;
};

export type MyTeamSessionCountQueryVariables = Exact<{
  userIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  courseSlug: Scalars['String'];
}>;

export type MyTeamSessionCountQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'Users';
    id: any;
    firstName?: string | undefined;
    lastName?: string | undefined;
    imageUrl?: string | undefined;
    learnerLightningSessionSubmissionsAggregate: {
      __typename?: 'LightningSessionSubmissionsAggregate';
      aggregate?:
        | {__typename?: 'LightningSessionSubmissionsAggregateFields'; count: number}
        | undefined;
    };
  }>;
};

export type TeamMemberFragment = {
  __typename?: 'getTeamMembersNew';
  id: any;
  fullName: string;
  email: string;
  avatarUrl?: string | undefined;
};

export type PollSubmissionNpsFragment = {
  __typename?: 'teamMemberPollSubmissions';
  cohortId: any;
  confidenceScore: string;
  engagementScore: string;
  jobImpactScore: string;
  npsScore: string;
  takeawayQuote: string;
  numDealsWhereApplied?: string | undefined;
  whichDeals?: string | undefined;
};

export type TeamFlatTreeNodeFragment = {
  __typename?: 'userManagedTeamsFlattenedTree';
  id: any;
  parentId?: any | undefined;
  organizationId: any;
  name: string;
  slug: string;
  branch: string;
  teamJobFunction?: string | undefined;
  treeId: any;
  nodeHeight: number;
};

export type TeamFlatTreeNodeWithMembersFragment = {
  __typename?: 'userManagedTeamsFlattenedTree';
  id: any;
  parentId?: any | undefined;
  organizationId: any;
  name: string;
  slug: string;
  branch: string;
  teamJobFunction?: string | undefined;
  treeId: any;
  nodeHeight: number;
  memberUsers?:
    | Array<{
        __typename?: 'getTeamMembersNew';
        id: any;
        fullName: string;
        email: string;
        avatarUrl?: string | undefined;
      }>
    | undefined;
};

export type TeamFlatTreeNodeWithLearnersAndManagersFragment = {
  __typename?: 'userManagedTeamsFlattenedTree';
  id: any;
  parentId?: any | undefined;
  organizationId: any;
  name: string;
  slug: string;
  branch: string;
  teamJobFunction?: string | undefined;
  treeId: any;
  nodeHeight: number;
  managerUsers?:
    | Array<{
        __typename?: 'getTeamMembersNew';
        id: any;
        fullName: string;
        email: string;
        avatarUrl?: string | undefined;
      }>
    | undefined;
  learnerUsers?:
    | Array<{
        __typename?: 'getTeamMembersNew';
        id: any;
        fullName: string;
        email: string;
        avatarUrl?: string | undefined;
      }>
    | undefined;
};

export type GetTeamFlatTreeForManagerQueryVariables = Exact<{
  organizationId: Scalars['uuid'];
  userId: Scalars['uuid'];
  includeLearners?: InputMaybe<Scalars['Boolean']>;
  includeManagers?: InputMaybe<Scalars['Boolean']>;
  includePollSubmissions?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetTeamFlatTreeForManagerQuery = {
  __typename?: 'query_root';
  userManagedTeamsFlattenedTree: Array<{
    __typename?: 'userManagedTeamsFlattenedTree';
    id: any;
    parentId?: any | undefined;
    organizationId: any;
    name: string;
    slug: string;
    branch: string;
    teamJobFunction?: string | undefined;
    treeId: any;
    nodeHeight: number;
    managerUsers?:
      | Array<{
          __typename?: 'getTeamMembersNew';
          id: any;
          fullName: string;
          email: string;
          avatarUrl?: string | undefined;
          pollSubmissions?:
            | Array<{
                __typename?: 'teamMemberPollSubmissions';
                cohortId: any;
                confidenceScore: string;
                engagementScore: string;
                jobImpactScore: string;
                npsScore: string;
                takeawayQuote: string;
                numDealsWhereApplied?: string | undefined;
                whichDeals?: string | undefined;
              }>
            | undefined;
        }>
      | undefined;
    learnerUsers?:
      | Array<{
          __typename?: 'getTeamMembersNew';
          id: any;
          fullName: string;
          email: string;
          avatarUrl?: string | undefined;
          pollSubmissions?:
            | Array<{
                __typename?: 'teamMemberPollSubmissions';
                cohortId: any;
                confidenceScore: string;
                engagementScore: string;
                jobImpactScore: string;
                npsScore: string;
                takeawayQuote: string;
                numDealsWhereApplied?: string | undefined;
                whichDeals?: string | undefined;
              }>
            | undefined;
        }>
      | undefined;
  }>;
};

export type GetTeamFlatTreeWithMemberPollSubmissionsQueryVariables = Exact<{
  organizationId: Scalars['uuid'];
  userId: Scalars['uuid'];
  cohortId: Scalars['uuid'];
}>;

export type GetTeamFlatTreeWithMemberPollSubmissionsQuery = {
  __typename?: 'query_root';
  userManagedTeamsFlattenedTree: Array<{
    __typename?: 'userManagedTeamsFlattenedTree';
    id: any;
    parentId?: any | undefined;
    organizationId: any;
    name: string;
    slug: string;
    branch: string;
    teamJobFunction?: string | undefined;
    treeId: any;
    nodeHeight: number;
    memberUsers?:
      | Array<{
          __typename?: 'getTeamMembersNew';
          id: any;
          fullName: string;
          email: string;
          avatarUrl?: string | undefined;
          pollSubmissions?:
            | Array<{
                __typename?: 'teamMemberPollSubmissions';
                cohortId: any;
                confidenceScore: string;
                engagementScore: string;
                jobImpactScore: string;
                npsScore: string;
                takeawayQuote: string;
                numDealsWhereApplied?: string | undefined;
                whichDeals?: string | undefined;
              }>
            | undefined;
        }>
      | undefined;
  }>;
};

export type AllUserManagedTeamsFlattenedTreeQueryVariables = Exact<{
  organizationId: Scalars['uuid'];
  userId: Scalars['uuid'];
  includeCredits?: InputMaybe<Scalars['Boolean']>;
}>;

export type AllUserManagedTeamsFlattenedTreeQuery = {
  __typename?: 'query_root';
  userManagedTeamsFlattenedTree: Array<{
    __typename?: 'userManagedTeamsFlattenedTree';
    id: any;
    parentId?: any | undefined;
    organizationId: any;
    name: string;
    slug: string;
    branch: string;
    teamJobFunction?: string | undefined;
    treeId: any;
    nodeHeight: number;
    memberUsers?:
      | Array<{
          __typename?: 'getTeamMembersNew';
          id: any;
          fullName: string;
          email: string;
          avatarUrl?: string | undefined;
          creditUsage?:
            | Array<{
                __typename?: 'creditUsage';
                userId: any;
                enrollmentId: any;
                cohortName: string;
                cohortStartDate: any;
                courseName: string;
                spent: number;
                scheduled: number;
              }>
            | undefined;
          creditUsageAggregate?:
            | {__typename?: 'creditUsageByUser'; userId: any; spent: number; scheduled: number}
            | undefined;
        }>
      | undefined;
  }>;
};

export type FilteredUserManagedTeamsFlattenedTreeQueryVariables = Exact<{
  organizationId: Scalars['uuid'];
  userId: Scalars['uuid'];
  onlyActive: Scalars['Boolean'];
}>;

export type FilteredUserManagedTeamsFlattenedTreeQuery = {
  __typename?: 'query_root';
  userManagedTeamsFlattenedTree: Array<{
    __typename?: 'userManagedTeamsFlattenedTree';
    id: any;
    parentId?: any | undefined;
    organizationId: any;
    name: string;
    slug: string;
    branch: string;
    teamJobFunction?: string | undefined;
    treeId: any;
    nodeHeight: number;
    memberUsers?:
      | Array<{
          __typename?: 'getTeamMembersNew';
          id: any;
          fullName: string;
          email: string;
          avatarUrl?: string | undefined;
        }>
      | undefined;
  }>;
};

export type CountMySessionsAttentedQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type CountMySessionsAttentedQuery = {
  __typename?: 'query_root';
  lightningSessionSubmissionsAggregate: {
    __typename?: 'LightningSessionSubmissionsAggregate';
    aggregate?:
      | {__typename?: 'LightningSessionSubmissionsAggregateFields'; count: number}
      | undefined;
  };
};

export type FindUserQueryVariables = Exact<{
  email: Scalars['String'];
}>;

export type FindUserQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'Users';
    id: any;
    firstName?: string | undefined;
    lastName?: string | undefined;
    imageUrl?: string | undefined;
    insertInstant: any;
    identity: Array<{__typename?: 'Identities'; email?: string | undefined}>;
    meta?:
      | {
          __typename?: 'UsersMeta';
          joinDate?: any | undefined;
          locale?: string | undefined;
          selectedOrgId?: any | undefined;
        }
      | undefined;
    organizationUsers: Array<{
      __typename?: 'OrganizationUsers';
      organization: {__typename?: 'Organizations'; id: any; name: string};
    }>;
  }>;
};

export type GetManagerTeamsQueryVariables = Exact<{[key: string]: never}>;

export type GetManagerTeamsQuery = {
  __typename?: 'query_root';
  subordinates: Array<{
    __typename?: 'Subordinates';
    firstName?: string | undefined;
    lastName?: string | undefined;
    userId?: any | undefined;
    teamIds?: Array<string> | undefined;
  }>;
};

export type GetMySessionsAttendedCountQueryVariables = Exact<{[key: string]: never}>;

export type GetMySessionsAttendedCountQuery = {
  __typename?: 'query_root';
  GetMySessionsAttendedCount?:
    | {__typename?: 'GetMySessionsAttendedCountOutput'; count: number}
    | undefined;
};

export type GetUserQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetUserQuery = {
  __typename?: 'query_root';
  user?:
    | {
        __typename?: 'Users';
        id: any;
        firstName?: string | undefined;
        lastName?: string | undefined;
        imageUrl?: string | undefined;
        insertInstant: any;
        data?: string | undefined;
        identity: Array<{__typename?: 'Identities'; email?: string | undefined}>;
        meta?:
          | {
              __typename?: 'UsersMeta';
              joinDate?: any | undefined;
              locale?: string | undefined;
              selectedOrgId?: any | undefined;
              jobFunction?: UsersFunctionsEnum | undefined;
              jobFunctionLength?: UsersLengthInFunctionEnum | undefined;
              acceptedTermsOfUse?: boolean | undefined;
              hasFilledUserProfileData?: boolean | undefined;
              updatedAt?: any | undefined;
            }
          | undefined;
        organizationUsers: Array<{
          __typename?: 'OrganizationUsers';
          organization: {
            __typename?: 'Organizations';
            id: any;
            name: string;
            meta?: {__typename?: 'OrganizationsMeta'; creditModel?: string | undefined} | undefined;
          };
        }>;
      }
    | undefined;
};

export type GetUserCreditModelQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetUserCreditModelQuery = {
  __typename?: 'query_root';
  user?:
    | {
        __typename?: 'Users';
        organizationUsers: Array<{
          __typename?: 'OrganizationUsers';
          organization: {
            __typename?: 'Organizations';
            meta?: {__typename?: 'OrganizationsMeta'; creditModel?: string | undefined} | undefined;
          };
        }>;
      }
    | undefined;
};

export type GetUserManagedTeamsQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetUserManagedTeamsQuery = {
  __typename?: 'query_root';
  userManagedTeams: Array<{
    __typename?: 'TeamsUsers';
    role: string;
    teamId: any;
    team: {__typename?: 'Teams'; parentId?: any | undefined; organizationId?: any | undefined};
  }>;
};

export type GetUserOrganizationsQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetUserOrganizationsQuery = {
  __typename?: 'query_root';
  user?:
    | {
        __typename?: 'Users';
        id: any;
        organizationUsers: Array<{
          __typename?: 'OrganizationUsers';
          organization: {__typename?: 'Organizations'; id: any};
        }>;
      }
    | undefined;
};

export type ListJobFunctionOptionsQueryVariables = Exact<{[key: string]: never}>;

export type ListJobFunctionOptionsQuery = {
  __typename?: 'query_root';
  usersFunctions: Array<{__typename?: 'UsersFunctions'; value: string; description: string}>;
  usersLengthInFunction: Array<{
    __typename?: 'UsersLengthInFunction';
    value: string;
    description: string;
  }>;
};

export type MyLearnerHomeCoursesQueryVariables = Exact<{[key: string]: never}>;

export type MyLearnerHomeCoursesQuery = {
  __typename?: 'query_root';
  myLearnerHomeCourses: Array<{
    __typename?: 'MyLearnerHomeCourses';
    userId?: any | undefined;
    cohortId?: any | undefined;
    cohortName?: string | undefined;
    cohortType?: string | undefined;
    cohortSlug?: string | undefined;
    cohortStart?: any | undefined;
    cohortEnd?: any | undefined;
    cohortTimeOfDay?: any | undefined;
    cohortDayOfWeek?: string | undefined;
    courseId?: any | undefined;
    courseSlug?: string | undefined;
    courseName?: string | undefined;
    courseType?: string | undefined;
    courseBackgroundImageUrl?: string | undefined;
    classCount?: any | undefined;
    schoolName?: string | undefined;
    schoolSlug?: string | undefined;
    certDate?: any | undefined;
    assessmentPassed?: boolean | undefined;
    progress?: any | undefined;
    isOnTrack?: boolean | undefined;
    cohortCompleted?: boolean | undefined;
    totalAssessments?: number | undefined;
    lastCompletedClassDate?: any | undefined;
    nextClassDate?: any | undefined;
    enrolledAt?: any | undefined;
    hasUserCompletedAllClasses?: boolean | undefined;
    status?: string | undefined;
    tags: Array<{
      __typename?: 'CoursesTagsRel';
      tag?: {__typename?: 'Tags'; name: string; slug: string} | undefined;
    }>;
  }>;
};

export type MyLearnerHomeLiveCoachingQueryVariables = Exact<{[key: string]: never}>;

export type MyLearnerHomeLiveCoachingQuery = {
  __typename?: 'query_root';
  myLearnerHomeLiveCoaching: Array<{
    __typename?: 'MyLearnerHomeLiveCoaching';
    userId?: any | undefined;
    cohortId?: any | undefined;
    cohortName?: string | undefined;
    cohortType?: string | undefined;
    cohortSlug?: string | undefined;
    cohortStart?: any | undefined;
    cohortEnd?: any | undefined;
    cohortTimeOfDay?: any | undefined;
    cohortDayOfWeek?: string | undefined;
    courseId?: any | undefined;
    courseSlug?: string | undefined;
    courseName?: string | undefined;
    courseType?: string | undefined;
    courseBackgroundImageUrl?: string | undefined;
    classCount?: any | undefined;
    schoolName?: string | undefined;
    schoolSlug?: string | undefined;
    certDate?: string | undefined;
    assessmentPassed?: boolean | undefined;
    progress?: any | undefined;
    isOnTrack?: boolean | undefined;
    cohortCompleted?: boolean | undefined;
    totalAssessments?: number | undefined;
    lastCompletedClassDate?: any | undefined;
    isWaitlisted?: boolean | undefined;
    enrolledAt?: any | undefined;
    status?: string | undefined;
    tags: Array<{
      __typename?: 'CoursesTagsRel';
      tag?: {__typename?: 'Tags'; name: string; slug: string} | undefined;
    }>;
  }>;
};

export type MyLearnerTasksQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type MyLearnerTasksQuery = {
  __typename?: 'query_root';
  my_learner_task_native_query: Array<{
    __typename?: 'learner_task';
    task_name: string;
    cohort_id: any;
    cohort_slug: string;
    course_name: string;
    course_type: string;
    join_id_or_link?: string | undefined;
    date?: any | undefined;
    is_done: boolean;
  }>;
};

export type LearnerMyLearningStatusQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type LearnerMyLearningStatusQuery = {
  __typename?: 'query_root';
  user?:
    | {
        __typename?: 'Users';
        activeSubscription?: Array<{__typename?: 'Plans'; name: string}> | undefined;
      }
    | undefined;
  myCohortLearningStatus: Array<{
    __typename?: 'MyCohortLearningStatus';
    userId?: any | undefined;
    cohortId?: any | undefined;
    cohortName?: string | undefined;
    cohortType?: string | undefined;
    cohortSlug?: string | undefined;
    cohortStart?: any | undefined;
    cohortEnd?: any | undefined;
    cohortTimeOfDay?: any | undefined;
    cohortDayOfWeek?: string | undefined;
    courseId?: any | undefined;
    courseSlug?: string | undefined;
    courseName?: string | undefined;
    courseType?: string | undefined;
    courseBackgroundImageUrl?: string | undefined;
    schoolName?: string | undefined;
    schoolSlug?: string | undefined;
    certDate?: string | undefined;
    assessmentPassed?: boolean | undefined;
    progress?: any | undefined;
    isOnTrack?: boolean | undefined;
    cohortCompleted?: boolean | undefined;
    totalAssessments?: number | undefined;
    lastCompletedClassDate?: any | undefined;
    enrolledAt?: any | undefined;
  }>;
};

export type GetUserOrganizationsByPlanPolicyFeaturesQueryVariables = Exact<{
  userId: Scalars['uuid'];
  features: Scalars['jsonb'];
}>;

export type GetUserOrganizationsByPlanPolicyFeaturesQuery = {
  __typename?: 'query_root';
  userOrganizations?:
    | {
        __typename?: 'Users';
        id: any;
        organizationUsers: Array<{
          __typename?: 'OrganizationUsers';
          id: any;
          organization: {__typename?: 'Organizations'; id: any};
        }>;
      }
    | undefined;
};

export type GetZoomOauthTokenQueryVariables = Exact<{[key: string]: never}>;

export type GetZoomOauthTokenQuery = {
  __typename?: 'query_root';
  zoomOauthTokens: Array<{
    __typename?: 'ZoomOauthTokens';
    bearerToken: string;
    createdAt: any;
    expiresAt: any;
    index: number;
  }>;
};

export const CoreEnrollmentCohortFragmentFragmentDoc = gql`
  fragment CoreEnrollmentCohortFragment on Cohorts {
    id
    name
    code
    startDate
    classTimeOfDay
    classEvents {
      id
      startTime
    }
    coaches {
      coach {
        name
      }
    }
    course {
      id
      slug
      name
      type
      school {
        name
      }
    }
  }
`;
export const CoreEnrollmentLearnerFragmentFragmentDoc = gql`
  fragment CoreEnrollmentLearnerFragment on Users {
    id
    firstName
    lastName
    identity {
      email
    }
  }
`;
export const ComplexEnrollmentCohortFragmentFragmentDoc = gql`
  fragment ComplexEnrollmentCohortFragment on Cohorts {
    id
    name
    code
    startDate
    endDate
    dayOfTheWeek
    classTimeOfDay
    slug
    type
    coaches {
      coach {
        name
      }
    }
    course {
      name
      backgroundImageUrl
      thumbnailCardUrl
      school {
        name
      }
    }
  }
`;
export const ImpactAssessmentQuestionsFragmentDoc = gql`
  fragment ImpactAssessmentQuestions on ImpactAssessmentQuestions {
    questionRich
    id
    impactAssessmentId
    answers
    type
    points
  }
`;
export const ImpactAssessmentAttemptFragmentDoc = gql`
  fragment ImpactAssessmentAttempt on ImpactAssessmentAttempts {
    id
    learnerAnswers
    remainingTime
    status
    userId
    cohortId
    questions {
      ...ImpactAssessmentQuestions
    }
  }
  ${ImpactAssessmentQuestionsFragmentDoc}
`;
export const CohortSimpleFragmentFragmentDoc = gql`
  fragment CohortSimpleFragment on Cohorts {
    id
    name
    slug
    course {
      id
      name
      slug
    }
  }
`;
export const LearnerHomeCohortsFragmentDoc = gql`
  fragment LearnerHomeCohorts on Cohorts {
    id
    name
    slug
    startDate
    course {
      name
      backgroundImageUrl
      tags(orderBy: {createdAt: ASC}, where: {tag: {tagType: {slug: {_eq: "role"}}}}) {
        tag {
          name
          slug
        }
      }
    }
  }
`;
export const CourseRoleTagsFragmentDoc = gql`
  fragment CourseRoleTags on Courses {
    tags(orderBy: {tag: {name: ASC}}) {
      tag {
        name
        slug
        tagType {
          slug
        }
      }
    }
  }
`;
export const CalendarClassEventFragmentDoc = gql`
  fragment CalendarClassEvent on ClassEvents {
    id
    createdAt
    updatedAt
    startTime
    endTime
    class {
      number
      name
    }
    cohort {
      id
      name
      slug
      startDate
      endDate
      coaches {
        coach {
          name
        }
      }
      course {
        name
        type
        slug
        audience
        shortDescription
      }
    }
  }
`;
export const ResourcesMetadataFragmentDoc = gql`
  fragment ResourcesMetadata on Resources {
    id
    filename
    url
    name
    descriptionRich
    type
    contentType
    slug
  }
`;
export const CohortAssessmentDatesFragmentDoc = gql`
  fragment CohortAssessmentDates on Cohorts {
    assessmentDueDate
    assessmentLiveDate
    isAssessmentLive
  }
`;
export const OneCohortDetailsFragmentDoc = gql`
  fragment OneCohortDetails on Cohorts {
    classEvents(orderBy: {class: $classEventsClassesOrderBy}) {
      startTime
      endTime
      id
      recordingUrl
      class {
        name
        isLive
        descriptionRich
        listDescription
        number
        slug
        id
        resources {
          resource {
            ...ResourcesMetadata
          }
        }
      }
    }
    communitySlackChannelUrl
    communitySlackInviteUrl
    courseId
    id
    name
    slug
    surveyLinkUrl
    type
    isPostCourseSurveyAvailable
    preworkRichText
    preworkVideoUrl
    requiresPractice
    ...CohortAssessmentDates
    course {
      id
      slug
      type
      name
      preCourseSurveyFormId
      postCourseSurveyFormId
      impactAssessment {
        id
      }
      resources {
        resource {
          id
          filename
          contentType
          name
          url
          type
        }
      }
    }
    resources {
      resource {
        id
        filename
        contentType
        name
        url
        type
      }
    }
  }
  ${ResourcesMetadataFragmentDoc}
  ${CohortAssessmentDatesFragmentDoc}
`;
export const PastSessionMetricsFragmentDoc = gql`
  fragment PastSessionMetrics on Cohorts {
    classEvents {
      meetingsNotSyncedCount: meetingsAggregate(where: {synced: {_eq: false}}) {
        aggregate {
          count
        }
      }
      participationActivities: classEventActivities(
        where: {userId: {_in: $userIds}, type: {_in: [JOIN_ZOOM, CLASS_COMPLETE]}}
      ) {
        userId
        type
      }
    }
    enrolled: submissions(where: {deletedAt: {_isNull: true}, userId: {_in: $userIds}}) {
      userId
    }
    attended: submissions(
      where: {userId: {_in: $userIds}, status: {_neq: SKIPPED_PRACTICE}, deletedAt: {_isNull: true}}
    ) {
      userId
      status
    }
    missed: submissions(
      where: {userId: {_in: $userIds}, status: {_eq: SKIPPED_PRACTICE}, deletedAt: {_isNull: true}}
    ) {
      userId
      status
    }
  }
`;
export const AsyncAverageMetricFragmentDoc = gql`
  fragment AsyncAverageMetric on GetPastSessionAsyncMetricsMetric {
    async
    benchmark
    count
    description
    percentage
    title
    type
  }
`;
export const AsyncMetricFragmentDoc = gql`
  fragment AsyncMetric on GetPastSessionAsyncMetricsMetric {
    async
    benchmark
    count
    description
    percentage
    title
    type
  }
`;
export const ClassEventCompleteActivitiesFragmentDoc = gql`
  fragment ClassEventCompleteActivities on ClassEvents {
    id
    class {
      number
    }
    completeActivities: classEventActivities(
      where: {type: {_eq: CLASS_COMPLETE}, userId: {_in: $userIds}}
    ) {
      id
      context
      userId
      createdAt
    }
  }
`;
export const ClassEventsWithCompleteActivitiesFragmentDoc = gql`
  fragment ClassEventsWithCompleteActivities on Cohorts {
    classEventsWithCompleteActivities: classEvents {
      ...ClassEventCompleteActivities
    }
  }
  ${ClassEventCompleteActivitiesFragmentDoc}
`;
export const ClassEventFeedbackViewedActivitiesFragmentDoc = gql`
  fragment ClassEventFeedbackViewedActivities on ClassEvents {
    feedbackViewedActivities: classEventActivities(
      where: {type: {_eq: VIEWED_FEEDBACK}, userId: {_in: $userIds}}
    ) {
      classEventId
      userId
    }
  }
`;
export const ClassEventsWithFeedbacKViewedActivitiesFragmentDoc = gql`
  fragment ClassEventsWithFeedbacKViewedActivities on Cohorts {
    classEventsWithFeedbackViewedActivities: classEvents {
      ...ClassEventFeedbackViewedActivities
    }
  }
  ${ClassEventFeedbackViewedActivitiesFragmentDoc}
`;
export const PollSubmissionNpsFragmentDoc = gql`
  fragment PollSubmissionNPS on teamMemberPollSubmissions {
    cohortId
    confidenceScore
    engagementScore
    jobImpactScore
    npsScore
    takeawayQuote
    numDealsWhereApplied
    whichDeals
  }
`;
export const TeamFlatTreeNodeFragmentDoc = gql`
  fragment TeamFlatTreeNode on userManagedTeamsFlattenedTree {
    id
    parentId
    organizationId
    name
    slug
    branch
    teamJobFunction
    treeId
    nodeHeight
  }
`;
export const TeamMemberFragmentDoc = gql`
  fragment TeamMember on getTeamMembersNew {
    id
    fullName
    email
    avatarUrl
  }
`;
export const TeamFlatTreeNodeWithMembersFragmentDoc = gql`
  fragment TeamFlatTreeNodeWithMembers on userManagedTeamsFlattenedTree {
    ...TeamFlatTreeNode
    memberUsers: members {
      ...TeamMember
    }
  }
  ${TeamFlatTreeNodeFragmentDoc}
  ${TeamMemberFragmentDoc}
`;
export const TeamFlatTreeNodeWithLearnersAndManagersFragmentDoc = gql`
  fragment TeamFlatTreeNodeWithLearnersAndManagers on userManagedTeamsFlattenedTree {
    ...TeamFlatTreeNode
    managerUsers: members(where: {role: {_eq: "manager"}}) {
      ...TeamMember
    }
    learnerUsers: members(where: {role: {_eq: "member"}}) {
      ...TeamMember
    }
  }
  ${TeamFlatTreeNodeFragmentDoc}
  ${TeamMemberFragmentDoc}
`;
export const AddAssessmentAttemptDocument = gql`
  mutation AddAssessmentAttempt($input: ImpactAssessmentAttemptsInsertInput!) {
    assessmentAttempt: insertImpactAssessmentAttemptsOne(object: $input) {
      id
    }
  }
`;
export type AddAssessmentAttemptMutationFn = Apollo.MutationFunction<
  AddAssessmentAttemptMutation,
  AddAssessmentAttemptMutationVariables
>;

/**
 * __useAddAssessmentAttemptMutation__
 *
 * To run a mutation, you first call `useAddAssessmentAttemptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAssessmentAttemptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAssessmentAttemptMutation, { data, loading, error }] = useAddAssessmentAttemptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAssessmentAttemptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAssessmentAttemptMutation,
    AddAssessmentAttemptMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<AddAssessmentAttemptMutation, AddAssessmentAttemptMutationVariables>(
    AddAssessmentAttemptDocument,
    options
  );
}
export type AddAssessmentAttemptMutationHookResult = ReturnType<
  typeof useAddAssessmentAttemptMutation
>;
export type AddAssessmentAttemptMutationResult =
  Apollo.MutationResult<AddAssessmentAttemptMutation>;
export type AddAssessmentAttemptMutationOptions = Apollo.BaseMutationOptions<
  AddAssessmentAttemptMutation,
  AddAssessmentAttemptMutationVariables
>;
export const AddCourseCertificationDocument = gql`
  mutation AddCourseCertification($input: CourseCertificationsInsertInput!) {
    courseCertification: insertCourseCertificationsOne(object: $input) {
      id
    }
  }
`;
export type AddCourseCertificationMutationFn = Apollo.MutationFunction<
  AddCourseCertificationMutation,
  AddCourseCertificationMutationVariables
>;

/**
 * __useAddCourseCertificationMutation__
 *
 * To run a mutation, you first call `useAddCourseCertificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCourseCertificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCourseCertificationMutation, { data, loading, error }] = useAddCourseCertificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCourseCertificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCourseCertificationMutation,
    AddCourseCertificationMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    AddCourseCertificationMutation,
    AddCourseCertificationMutationVariables
  >(AddCourseCertificationDocument, options);
}
export type AddCourseCertificationMutationHookResult = ReturnType<
  typeof useAddCourseCertificationMutation
>;
export type AddCourseCertificationMutationResult =
  Apollo.MutationResult<AddCourseCertificationMutation>;
export type AddCourseCertificationMutationOptions = Apollo.BaseMutationOptions<
  AddCourseCertificationMutation,
  AddCourseCertificationMutationVariables
>;
export const ResumeAssessmentDocument = gql`
  mutation ResumeAssessment($input: uuid!) {
    attempt: ResumeAssessment(input: $input) {
      id
      remainingTime
    }
  }
`;
export type ResumeAssessmentMutationFn = Apollo.MutationFunction<
  ResumeAssessmentMutation,
  ResumeAssessmentMutationVariables
>;

/**
 * __useResumeAssessmentMutation__
 *
 * To run a mutation, you first call `useResumeAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumeAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumeAssessmentMutation, { data, loading, error }] = useResumeAssessmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResumeAssessmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResumeAssessmentMutation,
    ResumeAssessmentMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<ResumeAssessmentMutation, ResumeAssessmentMutationVariables>(
    ResumeAssessmentDocument,
    options
  );
}
export type ResumeAssessmentMutationHookResult = ReturnType<typeof useResumeAssessmentMutation>;
export type ResumeAssessmentMutationResult = Apollo.MutationResult<ResumeAssessmentMutation>;
export type ResumeAssessmentMutationOptions = Apollo.BaseMutationOptions<
  ResumeAssessmentMutation,
  ResumeAssessmentMutationVariables
>;
export const SaveAssessmentDocument = gql`
  mutation SaveAssessment($attemptId: uuid!, $learnerAnswers: json!, $status: String) {
    attempt: SaveAssessment(
      attemptId: $attemptId
      learnerAnswers: $learnerAnswers
      status: $status
    ) {
      id
      remainingTime
      startedAt
      status
    }
  }
`;
export type SaveAssessmentMutationFn = Apollo.MutationFunction<
  SaveAssessmentMutation,
  SaveAssessmentMutationVariables
>;

/**
 * __useSaveAssessmentMutation__
 *
 * To run a mutation, you first call `useSaveAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAssessmentMutation, { data, loading, error }] = useSaveAssessmentMutation({
 *   variables: {
 *      attemptId: // value for 'attemptId'
 *      learnerAnswers: // value for 'learnerAnswers'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSaveAssessmentMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveAssessmentMutation, SaveAssessmentMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<SaveAssessmentMutation, SaveAssessmentMutationVariables>(
    SaveAssessmentDocument,
    options
  );
}
export type SaveAssessmentMutationHookResult = ReturnType<typeof useSaveAssessmentMutation>;
export type SaveAssessmentMutationResult = Apollo.MutationResult<SaveAssessmentMutation>;
export type SaveAssessmentMutationOptions = Apollo.BaseMutationOptions<
  SaveAssessmentMutation,
  SaveAssessmentMutationVariables
>;
export const StartAssessmentDocument = gql`
  mutation StartAssessment($assessmentId: uuid!, $cohortSlug: String!) {
    attempt: StartAssessment(assessmentId: $assessmentId, cohortSlug: $cohortSlug) {
      impactAssessmentId
      cohortSlug
      id
    }
  }
`;
export type StartAssessmentMutationFn = Apollo.MutationFunction<
  StartAssessmentMutation,
  StartAssessmentMutationVariables
>;

/**
 * __useStartAssessmentMutation__
 *
 * To run a mutation, you first call `useStartAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startAssessmentMutation, { data, loading, error }] = useStartAssessmentMutation({
 *   variables: {
 *      assessmentId: // value for 'assessmentId'
 *      cohortSlug: // value for 'cohortSlug'
 *   },
 * });
 */
export function useStartAssessmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartAssessmentMutation,
    StartAssessmentMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<StartAssessmentMutation, StartAssessmentMutationVariables>(
    StartAssessmentDocument,
    options
  );
}
export type StartAssessmentMutationHookResult = ReturnType<typeof useStartAssessmentMutation>;
export type StartAssessmentMutationResult = Apollo.MutationResult<StartAssessmentMutation>;
export type StartAssessmentMutationOptions = Apollo.BaseMutationOptions<
  StartAssessmentMutation,
  StartAssessmentMutationVariables
>;
export const SubmitAndGradeAssessmentDocument = gql`
  mutation SubmitAndGradeAssessment($input: uuid) {
    SubmitAndGradeAssessment(input: $input) {
      impactAssessmentAttemptId
      impactAssessmentId
      learnerScoreIsPassing
    }
  }
`;
export type SubmitAndGradeAssessmentMutationFn = Apollo.MutationFunction<
  SubmitAndGradeAssessmentMutation,
  SubmitAndGradeAssessmentMutationVariables
>;

/**
 * __useSubmitAndGradeAssessmentMutation__
 *
 * To run a mutation, you first call `useSubmitAndGradeAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitAndGradeAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitAndGradeAssessmentMutation, { data, loading, error }] = useSubmitAndGradeAssessmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitAndGradeAssessmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitAndGradeAssessmentMutation,
    SubmitAndGradeAssessmentMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    SubmitAndGradeAssessmentMutation,
    SubmitAndGradeAssessmentMutationVariables
  >(SubmitAndGradeAssessmentDocument, options);
}
export type SubmitAndGradeAssessmentMutationHookResult = ReturnType<
  typeof useSubmitAndGradeAssessmentMutation
>;
export type SubmitAndGradeAssessmentMutationResult =
  Apollo.MutationResult<SubmitAndGradeAssessmentMutation>;
export type SubmitAndGradeAssessmentMutationOptions = Apollo.BaseMutationOptions<
  SubmitAndGradeAssessmentMutation,
  SubmitAndGradeAssessmentMutationVariables
>;
export const UpdateAssessmentAttemptDocument = gql`
  mutation UpdateAssessmentAttempt(
    $id: ImpactAssessmentAttemptsPkColumnsInput!
    $input: ImpactAssessmentAttemptsSetInput!
  ) {
    assessmentAttempt: updateImpactAssessmentAttemptsByPk(pkColumns: $id, _set: $input) {
      id
      remainingTime
      startedAt
      status
    }
  }
`;
export type UpdateAssessmentAttemptMutationFn = Apollo.MutationFunction<
  UpdateAssessmentAttemptMutation,
  UpdateAssessmentAttemptMutationVariables
>;

/**
 * __useUpdateAssessmentAttemptMutation__
 *
 * To run a mutation, you first call `useUpdateAssessmentAttemptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssessmentAttemptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssessmentAttemptMutation, { data, loading, error }] = useUpdateAssessmentAttemptMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssessmentAttemptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAssessmentAttemptMutation,
    UpdateAssessmentAttemptMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateAssessmentAttemptMutation,
    UpdateAssessmentAttemptMutationVariables
  >(UpdateAssessmentAttemptDocument, options);
}
export type UpdateAssessmentAttemptMutationHookResult = ReturnType<
  typeof useUpdateAssessmentAttemptMutation
>;
export type UpdateAssessmentAttemptMutationResult =
  Apollo.MutationResult<UpdateAssessmentAttemptMutation>;
export type UpdateAssessmentAttemptMutationOptions = Apollo.BaseMutationOptions<
  UpdateAssessmentAttemptMutation,
  UpdateAssessmentAttemptMutationVariables
>;
export const LogClassEventActivityDocument = gql`
  mutation LogClassEventActivity($input: ClassEventActivityInsertInput!) {
    insertClassEventActivityOne(object: $input) {
      id
    }
  }
`;
export type LogClassEventActivityMutationFn = Apollo.MutationFunction<
  LogClassEventActivityMutation,
  LogClassEventActivityMutationVariables
>;

/**
 * __useLogClassEventActivityMutation__
 *
 * To run a mutation, you first call `useLogClassEventActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogClassEventActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logClassEventActivityMutation, { data, loading, error }] = useLogClassEventActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLogClassEventActivityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogClassEventActivityMutation,
    LogClassEventActivityMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<LogClassEventActivityMutation, LogClassEventActivityMutationVariables>(
    LogClassEventActivityDocument,
    options
  );
}
export type LogClassEventActivityMutationHookResult = ReturnType<
  typeof useLogClassEventActivityMutation
>;
export type LogClassEventActivityMutationResult =
  Apollo.MutationResult<LogClassEventActivityMutation>;
export type LogClassEventActivityMutationOptions = Apollo.BaseMutationOptions<
  LogClassEventActivityMutation,
  LogClassEventActivityMutationVariables
>;
export const LogClassPlaybackActivityDocument = gql`
  mutation LogClassPlaybackActivity($input: ClassPlaybackActivityInsertInput!) {
    insertClassPlaybackActivityOne(object: $input) {
      id
    }
  }
`;
export type LogClassPlaybackActivityMutationFn = Apollo.MutationFunction<
  LogClassPlaybackActivityMutation,
  LogClassPlaybackActivityMutationVariables
>;

/**
 * __useLogClassPlaybackActivityMutation__
 *
 * To run a mutation, you first call `useLogClassPlaybackActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogClassPlaybackActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logClassPlaybackActivityMutation, { data, loading, error }] = useLogClassPlaybackActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLogClassPlaybackActivityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogClassPlaybackActivityMutation,
    LogClassPlaybackActivityMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    LogClassPlaybackActivityMutation,
    LogClassPlaybackActivityMutationVariables
  >(LogClassPlaybackActivityDocument, options);
}
export type LogClassPlaybackActivityMutationHookResult = ReturnType<
  typeof useLogClassPlaybackActivityMutation
>;
export type LogClassPlaybackActivityMutationResult =
  Apollo.MutationResult<LogClassPlaybackActivityMutation>;
export type LogClassPlaybackActivityMutationOptions = Apollo.BaseMutationOptions<
  LogClassPlaybackActivityMutation,
  LogClassPlaybackActivityMutationVariables
>;
export const RemoveClassEventActivityDocument = gql`
  mutation RemoveClassEventActivity(
    $userId: uuid!
    $classEventId: uuid!
    $type: [ActivityTypeEnum!]
  ) {
    deleteClassEventActivity(
      where: {userId: {_eq: $userId}, classEventId: {_eq: $classEventId}, type: {_in: $type}}
    ) {
      affectedRows
    }
  }
`;
export type RemoveClassEventActivityMutationFn = Apollo.MutationFunction<
  RemoveClassEventActivityMutation,
  RemoveClassEventActivityMutationVariables
>;

/**
 * __useRemoveClassEventActivityMutation__
 *
 * To run a mutation, you first call `useRemoveClassEventActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClassEventActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClassEventActivityMutation, { data, loading, error }] = useRemoveClassEventActivityMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      classEventId: // value for 'classEventId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useRemoveClassEventActivityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveClassEventActivityMutation,
    RemoveClassEventActivityMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    RemoveClassEventActivityMutation,
    RemoveClassEventActivityMutationVariables
  >(RemoveClassEventActivityDocument, options);
}
export type RemoveClassEventActivityMutationHookResult = ReturnType<
  typeof useRemoveClassEventActivityMutation
>;
export type RemoveClassEventActivityMutationResult =
  Apollo.MutationResult<RemoveClassEventActivityMutation>;
export type RemoveClassEventActivityMutationOptions = Apollo.BaseMutationOptions<
  RemoveClassEventActivityMutation,
  RemoveClassEventActivityMutationVariables
>;
export const SetClassStatusDocument = gql`
  mutation SetClassStatus($classEventId: uuid!, $context: SetClassStatusContext!) {
    SetClassStatus(classEventId: $classEventId, context: $context) {
      done
    }
  }
`;
export type SetClassStatusMutationFn = Apollo.MutationFunction<
  SetClassStatusMutation,
  SetClassStatusMutationVariables
>;

/**
 * __useSetClassStatusMutation__
 *
 * To run a mutation, you first call `useSetClassStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetClassStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setClassStatusMutation, { data, loading, error }] = useSetClassStatusMutation({
 *   variables: {
 *      classEventId: // value for 'classEventId'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useSetClassStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<SetClassStatusMutation, SetClassStatusMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<SetClassStatusMutation, SetClassStatusMutationVariables>(
    SetClassStatusDocument,
    options
  );
}
export type SetClassStatusMutationHookResult = ReturnType<typeof useSetClassStatusMutation>;
export type SetClassStatusMutationResult = Apollo.MutationResult<SetClassStatusMutation>;
export type SetClassStatusMutationOptions = Apollo.BaseMutationOptions<
  SetClassStatusMutation,
  SetClassStatusMutationVariables
>;
export const UpdateClassEventActivityDocument = gql`
  mutation UpdateClassEventActivity($id: uuid!, $updateObject: ClassEventActivitySetInput) {
    updateClassEventActivityByPk(_set: $updateObject, pkColumns: {id: $id}) {
      id
    }
  }
`;
export type UpdateClassEventActivityMutationFn = Apollo.MutationFunction<
  UpdateClassEventActivityMutation,
  UpdateClassEventActivityMutationVariables
>;

/**
 * __useUpdateClassEventActivityMutation__
 *
 * To run a mutation, you first call `useUpdateClassEventActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassEventActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassEventActivityMutation, { data, loading, error }] = useUpdateClassEventActivityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updateObject: // value for 'updateObject'
 *   },
 * });
 */
export function useUpdateClassEventActivityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClassEventActivityMutation,
    UpdateClassEventActivityMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateClassEventActivityMutation,
    UpdateClassEventActivityMutationVariables
  >(UpdateClassEventActivityDocument, options);
}
export type UpdateClassEventActivityMutationHookResult = ReturnType<
  typeof useUpdateClassEventActivityMutation
>;
export type UpdateClassEventActivityMutationResult =
  Apollo.MutationResult<UpdateClassEventActivityMutation>;
export type UpdateClassEventActivityMutationOptions = Apollo.BaseMutationOptions<
  UpdateClassEventActivityMutation,
  UpdateClassEventActivityMutationVariables
>;
export const UpdateClassPlaybackActivityDocument = gql`
  mutation UpdateClassPlaybackActivity($id: uuid!, $updateObject: ClassPlaybackActivitySetInput) {
    updateClassPlaybackActivityByPk(_set: $updateObject, pkColumns: {id: $id}) {
      id
    }
  }
`;
export type UpdateClassPlaybackActivityMutationFn = Apollo.MutationFunction<
  UpdateClassPlaybackActivityMutation,
  UpdateClassPlaybackActivityMutationVariables
>;

/**
 * __useUpdateClassPlaybackActivityMutation__
 *
 * To run a mutation, you first call `useUpdateClassPlaybackActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassPlaybackActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassPlaybackActivityMutation, { data, loading, error }] = useUpdateClassPlaybackActivityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updateObject: // value for 'updateObject'
 *   },
 * });
 */
export function useUpdateClassPlaybackActivityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClassPlaybackActivityMutation,
    UpdateClassPlaybackActivityMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateClassPlaybackActivityMutation,
    UpdateClassPlaybackActivityMutationVariables
  >(UpdateClassPlaybackActivityDocument, options);
}
export type UpdateClassPlaybackActivityMutationHookResult = ReturnType<
  typeof useUpdateClassPlaybackActivityMutation
>;
export type UpdateClassPlaybackActivityMutationResult =
  Apollo.MutationResult<UpdateClassPlaybackActivityMutation>;
export type UpdateClassPlaybackActivityMutationOptions = Apollo.BaseMutationOptions<
  UpdateClassPlaybackActivityMutation,
  UpdateClassPlaybackActivityMutationVariables
>;
export const AddClassEventInvitationsDocument = gql`
  mutation AddClassEventInvitations($classEventInvites: [ClassEventInvitationsInsertInput!]!) {
    insertClassEventInvitations(objects: $classEventInvites) {
      returning {
        id
      }
    }
  }
`;
export type AddClassEventInvitationsMutationFn = Apollo.MutationFunction<
  AddClassEventInvitationsMutation,
  AddClassEventInvitationsMutationVariables
>;

/**
 * __useAddClassEventInvitationsMutation__
 *
 * To run a mutation, you first call `useAddClassEventInvitationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClassEventInvitationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClassEventInvitationsMutation, { data, loading, error }] = useAddClassEventInvitationsMutation({
 *   variables: {
 *      classEventInvites: // value for 'classEventInvites'
 *   },
 * });
 */
export function useAddClassEventInvitationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddClassEventInvitationsMutation,
    AddClassEventInvitationsMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    AddClassEventInvitationsMutation,
    AddClassEventInvitationsMutationVariables
  >(AddClassEventInvitationsDocument, options);
}
export type AddClassEventInvitationsMutationHookResult = ReturnType<
  typeof useAddClassEventInvitationsMutation
>;
export type AddClassEventInvitationsMutationResult =
  Apollo.MutationResult<AddClassEventInvitationsMutation>;
export type AddClassEventInvitationsMutationOptions = Apollo.BaseMutationOptions<
  AddClassEventInvitationsMutation,
  AddClassEventInvitationsMutationVariables
>;
export const BeginClassFromClientDocument = gql`
  mutation BeginClassFromClient($classEventId: uuid!) {
    BeginClass(classEventId: $classEventId) {
      success
      details
    }
  }
`;
export type BeginClassFromClientMutationFn = Apollo.MutationFunction<
  BeginClassFromClientMutation,
  BeginClassFromClientMutationVariables
>;

/**
 * __useBeginClassFromClientMutation__
 *
 * To run a mutation, you first call `useBeginClassFromClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBeginClassFromClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [beginClassFromClientMutation, { data, loading, error }] = useBeginClassFromClientMutation({
 *   variables: {
 *      classEventId: // value for 'classEventId'
 *   },
 * });
 */
export function useBeginClassFromClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BeginClassFromClientMutation,
    BeginClassFromClientMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<BeginClassFromClientMutation, BeginClassFromClientMutationVariables>(
    BeginClassFromClientDocument,
    options
  );
}
export type BeginClassFromClientMutationHookResult = ReturnType<
  typeof useBeginClassFromClientMutation
>;
export type BeginClassFromClientMutationResult =
  Apollo.MutationResult<BeginClassFromClientMutation>;
export type BeginClassFromClientMutationOptions = Apollo.BaseMutationOptions<
  BeginClassFromClientMutation,
  BeginClassFromClientMutationVariables
>;
export const CreateAnonRegistrationDocument = gql`
  mutation CreateAnonRegistration(
    $classEventId: uuid!
    $email: String
    $firstName: String
    $lastName: String
    $zoomJoinLinkUrl: String
  ) {
    insertClassEventAnonRegistrationsOne(
      object: {
        classEventId: $classEventId
        email: $email
        firstName: $firstName
        lastName: $lastName
        zoomJoinLinkUrl: $zoomJoinLinkUrl
      }
    ) {
      token
    }
  }
`;
export type CreateAnonRegistrationMutationFn = Apollo.MutationFunction<
  CreateAnonRegistrationMutation,
  CreateAnonRegistrationMutationVariables
>;

/**
 * __useCreateAnonRegistrationMutation__
 *
 * To run a mutation, you first call `useCreateAnonRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnonRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnonRegistrationMutation, { data, loading, error }] = useCreateAnonRegistrationMutation({
 *   variables: {
 *      classEventId: // value for 'classEventId'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      zoomJoinLinkUrl: // value for 'zoomJoinLinkUrl'
 *   },
 * });
 */
export function useCreateAnonRegistrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAnonRegistrationMutation,
    CreateAnonRegistrationMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    CreateAnonRegistrationMutation,
    CreateAnonRegistrationMutationVariables
  >(CreateAnonRegistrationDocument, options);
}
export type CreateAnonRegistrationMutationHookResult = ReturnType<
  typeof useCreateAnonRegistrationMutation
>;
export type CreateAnonRegistrationMutationResult =
  Apollo.MutationResult<CreateAnonRegistrationMutation>;
export type CreateAnonRegistrationMutationOptions = Apollo.BaseMutationOptions<
  CreateAnonRegistrationMutation,
  CreateAnonRegistrationMutationVariables
>;
export const CreateClassEventMeetingDocument = gql`
  mutation CreateClassEventMeeting($meeting: ClassEventMeetingsInsertInput!) {
    insertClassEventMeetingsOne(
      object: $meeting
      onConflict: {constraint: class_event_meetings_pk2, updateColumns: []}
    ) {
      id
    }
  }
`;
export type CreateClassEventMeetingMutationFn = Apollo.MutationFunction<
  CreateClassEventMeetingMutation,
  CreateClassEventMeetingMutationVariables
>;

/**
 * __useCreateClassEventMeetingMutation__
 *
 * To run a mutation, you first call `useCreateClassEventMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClassEventMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClassEventMeetingMutation, { data, loading, error }] = useCreateClassEventMeetingMutation({
 *   variables: {
 *      meeting: // value for 'meeting'
 *   },
 * });
 */
export function useCreateClassEventMeetingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClassEventMeetingMutation,
    CreateClassEventMeetingMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    CreateClassEventMeetingMutation,
    CreateClassEventMeetingMutationVariables
  >(CreateClassEventMeetingDocument, options);
}
export type CreateClassEventMeetingMutationHookResult = ReturnType<
  typeof useCreateClassEventMeetingMutation
>;
export type CreateClassEventMeetingMutationResult =
  Apollo.MutationResult<CreateClassEventMeetingMutation>;
export type CreateClassEventMeetingMutationOptions = Apollo.BaseMutationOptions<
  CreateClassEventMeetingMutation,
  CreateClassEventMeetingMutationVariables
>;
export const CreateLightingSessionDocument = gql`
  mutation CreateLightingSession(
    $meeting: ClassEventMeetingsInsertInput!
    $ls: LightningSessionInsertInput!
  ) {
    insertClassEventMeetingsOne(
      object: $meeting
      onConflict: {constraint: class_event_meetings_pk2, updateColumns: []}
    ) {
      id
    }
    insertLightningSessionOne(
      object: $ls
      onConflict: {constraint: lightning_session_class_event_id_key, updateColumns: []}
    ) {
      id
    }
  }
`;
export type CreateLightingSessionMutationFn = Apollo.MutationFunction<
  CreateLightingSessionMutation,
  CreateLightingSessionMutationVariables
>;

/**
 * __useCreateLightingSessionMutation__
 *
 * To run a mutation, you first call `useCreateLightingSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLightingSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLightingSessionMutation, { data, loading, error }] = useCreateLightingSessionMutation({
 *   variables: {
 *      meeting: // value for 'meeting'
 *      ls: // value for 'ls'
 *   },
 * });
 */
export function useCreateLightingSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLightingSessionMutation,
    CreateLightingSessionMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<CreateLightingSessionMutation, CreateLightingSessionMutationVariables>(
    CreateLightingSessionDocument,
    options
  );
}
export type CreateLightingSessionMutationHookResult = ReturnType<
  typeof useCreateLightingSessionMutation
>;
export type CreateLightingSessionMutationResult =
  Apollo.MutationResult<CreateLightingSessionMutation>;
export type CreateLightingSessionMutationOptions = Apollo.BaseMutationOptions<
  CreateLightingSessionMutation,
  CreateLightingSessionMutationVariables
>;
export const DeleteZoomMeetingDocument = gql`
  mutation DeleteZoomMeeting($classEventId: uuid!) {
    updateClassEventAnonRegistrations(
      where: {classEventId: {_eq: $classEventId}}
      _set: {zoomJoinLinkUrl: null}
    ) {
      affectedRows
    }
    deleteClassEventRegistrations(where: {classEventId: {_eq: $classEventId}}) {
      affectedRows
    }
    updateClassEventsByPk(
      pkColumns: {id: $classEventId}
      _set: {zoomMeetingId: null, zoomLinkUrl: null}
    ) {
      id
      zoomMeetingId
      zoomLinkUrl
    }
  }
`;
export type DeleteZoomMeetingMutationFn = Apollo.MutationFunction<
  DeleteZoomMeetingMutation,
  DeleteZoomMeetingMutationVariables
>;

/**
 * __useDeleteZoomMeetingMutation__
 *
 * To run a mutation, you first call `useDeleteZoomMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteZoomMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteZoomMeetingMutation, { data, loading, error }] = useDeleteZoomMeetingMutation({
 *   variables: {
 *      classEventId: // value for 'classEventId'
 *   },
 * });
 */
export function useDeleteZoomMeetingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteZoomMeetingMutation,
    DeleteZoomMeetingMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<DeleteZoomMeetingMutation, DeleteZoomMeetingMutationVariables>(
    DeleteZoomMeetingDocument,
    options
  );
}
export type DeleteZoomMeetingMutationHookResult = ReturnType<typeof useDeleteZoomMeetingMutation>;
export type DeleteZoomMeetingMutationResult = Apollo.MutationResult<DeleteZoomMeetingMutation>;
export type DeleteZoomMeetingMutationOptions = Apollo.BaseMutationOptions<
  DeleteZoomMeetingMutation,
  DeleteZoomMeetingMutationVariables
>;
export const EndClassFromClientDocument = gql`
  mutation EndClassFromClient($classEventId: uuid!) {
    EndClass(classEventId: $classEventId) {
      success
      details
    }
  }
`;
export type EndClassFromClientMutationFn = Apollo.MutationFunction<
  EndClassFromClientMutation,
  EndClassFromClientMutationVariables
>;

/**
 * __useEndClassFromClientMutation__
 *
 * To run a mutation, you first call `useEndClassFromClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndClassFromClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endClassFromClientMutation, { data, loading, error }] = useEndClassFromClientMutation({
 *   variables: {
 *      classEventId: // value for 'classEventId'
 *   },
 * });
 */
export function useEndClassFromClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EndClassFromClientMutation,
    EndClassFromClientMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<EndClassFromClientMutation, EndClassFromClientMutationVariables>(
    EndClassFromClientDocument,
    options
  );
}
export type EndClassFromClientMutationHookResult = ReturnType<typeof useEndClassFromClientMutation>;
export type EndClassFromClientMutationResult = Apollo.MutationResult<EndClassFromClientMutation>;
export type EndClassFromClientMutationOptions = Apollo.BaseMutationOptions<
  EndClassFromClientMutation,
  EndClassFromClientMutationVariables
>;
export const EndLightingSessionFromClientDocument = gql`
  mutation EndLightingSessionFromClient($classEventId: uuid!, $countdownMinutes: bigint) {
    LightningSession(
      classEventId: $classEventId
      action: "end"
      countdownMinutes: $countdownMinutes
    ) {
      success
      details
    }
  }
`;
export type EndLightingSessionFromClientMutationFn = Apollo.MutationFunction<
  EndLightingSessionFromClientMutation,
  EndLightingSessionFromClientMutationVariables
>;

/**
 * __useEndLightingSessionFromClientMutation__
 *
 * To run a mutation, you first call `useEndLightingSessionFromClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndLightingSessionFromClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endLightingSessionFromClientMutation, { data, loading, error }] = useEndLightingSessionFromClientMutation({
 *   variables: {
 *      classEventId: // value for 'classEventId'
 *      countdownMinutes: // value for 'countdownMinutes'
 *   },
 * });
 */
export function useEndLightingSessionFromClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EndLightingSessionFromClientMutation,
    EndLightingSessionFromClientMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    EndLightingSessionFromClientMutation,
    EndLightingSessionFromClientMutationVariables
  >(EndLightingSessionFromClientDocument, options);
}
export type EndLightingSessionFromClientMutationHookResult = ReturnType<
  typeof useEndLightingSessionFromClientMutation
>;
export type EndLightingSessionFromClientMutationResult =
  Apollo.MutationResult<EndLightingSessionFromClientMutation>;
export type EndLightingSessionFromClientMutationOptions = Apollo.BaseMutationOptions<
  EndLightingSessionFromClientMutation,
  EndLightingSessionFromClientMutationVariables
>;
export const LaunchClassSurveyFromClientDocument = gql`
  mutation LaunchClassSurveyFromClient($classEventId: uuid!) {
    LaunchClassSurvey(classEventId: $classEventId) {
      success
      details
    }
  }
`;
export type LaunchClassSurveyFromClientMutationFn = Apollo.MutationFunction<
  LaunchClassSurveyFromClientMutation,
  LaunchClassSurveyFromClientMutationVariables
>;

/**
 * __useLaunchClassSurveyFromClientMutation__
 *
 * To run a mutation, you first call `useLaunchClassSurveyFromClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLaunchClassSurveyFromClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [launchClassSurveyFromClientMutation, { data, loading, error }] = useLaunchClassSurveyFromClientMutation({
 *   variables: {
 *      classEventId: // value for 'classEventId'
 *   },
 * });
 */
export function useLaunchClassSurveyFromClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LaunchClassSurveyFromClientMutation,
    LaunchClassSurveyFromClientMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    LaunchClassSurveyFromClientMutation,
    LaunchClassSurveyFromClientMutationVariables
  >(LaunchClassSurveyFromClientDocument, options);
}
export type LaunchClassSurveyFromClientMutationHookResult = ReturnType<
  typeof useLaunchClassSurveyFromClientMutation
>;
export type LaunchClassSurveyFromClientMutationResult =
  Apollo.MutationResult<LaunchClassSurveyFromClientMutation>;
export type LaunchClassSurveyFromClientMutationOptions = Apollo.BaseMutationOptions<
  LaunchClassSurveyFromClientMutation,
  LaunchClassSurveyFromClientMutationVariables
>;
export const RegisterAnonDocument = gql`
  mutation RegisterAnon(
    $classEventId: uuid!
    $email: String!
    $firstName: String!
    $lastName: String!
  ) {
    RegisterAnon(
      classEventId: $classEventId
      email: $email
      firstName: $firstName
      lastName: $lastName
    ) {
      success
      code
      joinLinkUrl
    }
  }
`;
export type RegisterAnonMutationFn = Apollo.MutationFunction<
  RegisterAnonMutation,
  RegisterAnonMutationVariables
>;

/**
 * __useRegisterAnonMutation__
 *
 * To run a mutation, you first call `useRegisterAnonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterAnonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerAnonMutation, { data, loading, error }] = useRegisterAnonMutation({
 *   variables: {
 *      classEventId: // value for 'classEventId'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useRegisterAnonMutation(
  baseOptions?: Apollo.MutationHookOptions<RegisterAnonMutation, RegisterAnonMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<RegisterAnonMutation, RegisterAnonMutationVariables>(
    RegisterAnonDocument,
    options
  );
}
export type RegisterAnonMutationHookResult = ReturnType<typeof useRegisterAnonMutation>;
export type RegisterAnonMutationResult = Apollo.MutationResult<RegisterAnonMutation>;
export type RegisterAnonMutationOptions = Apollo.BaseMutationOptions<
  RegisterAnonMutation,
  RegisterAnonMutationVariables
>;
export const SetLightningSessionEndTimeDocument = gql`
  mutation SetLightningSessionEndTime($classEventId: uuid!, $endTime: timestamp!) {
    updateLightningSession(where: {classEventId: {_eq: $classEventId}}, _set: {endTime: $endTime}) {
      affectedRows
    }
  }
`;
export type SetLightningSessionEndTimeMutationFn = Apollo.MutationFunction<
  SetLightningSessionEndTimeMutation,
  SetLightningSessionEndTimeMutationVariables
>;

/**
 * __useSetLightningSessionEndTimeMutation__
 *
 * To run a mutation, you first call `useSetLightningSessionEndTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLightningSessionEndTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLightningSessionEndTimeMutation, { data, loading, error }] = useSetLightningSessionEndTimeMutation({
 *   variables: {
 *      classEventId: // value for 'classEventId'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useSetLightningSessionEndTimeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetLightningSessionEndTimeMutation,
    SetLightningSessionEndTimeMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    SetLightningSessionEndTimeMutation,
    SetLightningSessionEndTimeMutationVariables
  >(SetLightningSessionEndTimeDocument, options);
}
export type SetLightningSessionEndTimeMutationHookResult = ReturnType<
  typeof useSetLightningSessionEndTimeMutation
>;
export type SetLightningSessionEndTimeMutationResult =
  Apollo.MutationResult<SetLightningSessionEndTimeMutation>;
export type SetLightningSessionEndTimeMutationOptions = Apollo.BaseMutationOptions<
  SetLightningSessionEndTimeMutation,
  SetLightningSessionEndTimeMutationVariables
>;
export const SignTokenDocument = gql`
  mutation SignToken($classEventId: uuid!, $roomName: String!) {
    SignToken(classEventId: $classEventId, roomName: $roomName) {
      success
      details
    }
  }
`;
export type SignTokenMutationFn = Apollo.MutationFunction<
  SignTokenMutation,
  SignTokenMutationVariables
>;

/**
 * __useSignTokenMutation__
 *
 * To run a mutation, you first call `useSignTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signTokenMutation, { data, loading, error }] = useSignTokenMutation({
 *   variables: {
 *      classEventId: // value for 'classEventId'
 *      roomName: // value for 'roomName'
 *   },
 * });
 */
export function useSignTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<SignTokenMutation, SignTokenMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<SignTokenMutation, SignTokenMutationVariables>(
    SignTokenDocument,
    options
  );
}
export type SignTokenMutationHookResult = ReturnType<typeof useSignTokenMutation>;
export type SignTokenMutationResult = Apollo.MutationResult<SignTokenMutation>;
export type SignTokenMutationOptions = Apollo.BaseMutationOptions<
  SignTokenMutation,
  SignTokenMutationVariables
>;
export const StartLightingSessionFromClientDocument = gql`
  mutation StartLightingSessionFromClient($classEventId: uuid!, $rooms: [RoomInput]!) {
    LightningSession(classEventId: $classEventId, action: "start", rooms: $rooms) {
      success
      details
    }
  }
`;
export type StartLightingSessionFromClientMutationFn = Apollo.MutationFunction<
  StartLightingSessionFromClientMutation,
  StartLightingSessionFromClientMutationVariables
>;

/**
 * __useStartLightingSessionFromClientMutation__
 *
 * To run a mutation, you first call `useStartLightingSessionFromClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartLightingSessionFromClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startLightingSessionFromClientMutation, { data, loading, error }] = useStartLightingSessionFromClientMutation({
 *   variables: {
 *      classEventId: // value for 'classEventId'
 *      rooms: // value for 'rooms'
 *   },
 * });
 */
export function useStartLightingSessionFromClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartLightingSessionFromClientMutation,
    StartLightingSessionFromClientMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    StartLightingSessionFromClientMutation,
    StartLightingSessionFromClientMutationVariables
  >(StartLightingSessionFromClientDocument, options);
}
export type StartLightingSessionFromClientMutationHookResult = ReturnType<
  typeof useStartLightingSessionFromClientMutation
>;
export type StartLightingSessionFromClientMutationResult =
  Apollo.MutationResult<StartLightingSessionFromClientMutation>;
export type StartLightingSessionFromClientMutationOptions = Apollo.BaseMutationOptions<
  StartLightingSessionFromClientMutation,
  StartLightingSessionFromClientMutationVariables
>;
export const UpdateClassEventDocument = gql`
  mutation UpdateClassEvent($id: uuid!, $data: ClassEventsSetInput!) {
    updateClassEventsByPk(pkColumns: {id: $id}, _set: $data) {
      id
    }
  }
`;
export type UpdateClassEventMutationFn = Apollo.MutationFunction<
  UpdateClassEventMutation,
  UpdateClassEventMutationVariables
>;

/**
 * __useUpdateClassEventMutation__
 *
 * To run a mutation, you first call `useUpdateClassEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassEventMutation, { data, loading, error }] = useUpdateClassEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateClassEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClassEventMutation,
    UpdateClassEventMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<UpdateClassEventMutation, UpdateClassEventMutationVariables>(
    UpdateClassEventDocument,
    options
  );
}
export type UpdateClassEventMutationHookResult = ReturnType<typeof useUpdateClassEventMutation>;
export type UpdateClassEventMutationResult = Apollo.MutationResult<UpdateClassEventMutation>;
export type UpdateClassEventMutationOptions = Apollo.BaseMutationOptions<
  UpdateClassEventMutation,
  UpdateClassEventMutationVariables
>;
export const UpdateClassEventMeetingAltRecordingDocument = gql`
  mutation UpdateClassEventMeetingAltRecording($meetingId: uuid!, $altRecordingUrl: String) {
    updateClassEventMeetingsByPk(
      pkColumns: {id: $meetingId}
      _set: {altRecordingUrl: $altRecordingUrl}
    ) {
      id
    }
  }
`;
export type UpdateClassEventMeetingAltRecordingMutationFn = Apollo.MutationFunction<
  UpdateClassEventMeetingAltRecordingMutation,
  UpdateClassEventMeetingAltRecordingMutationVariables
>;

/**
 * __useUpdateClassEventMeetingAltRecordingMutation__
 *
 * To run a mutation, you first call `useUpdateClassEventMeetingAltRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassEventMeetingAltRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassEventMeetingAltRecordingMutation, { data, loading, error }] = useUpdateClassEventMeetingAltRecordingMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      altRecordingUrl: // value for 'altRecordingUrl'
 *   },
 * });
 */
export function useUpdateClassEventMeetingAltRecordingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClassEventMeetingAltRecordingMutation,
    UpdateClassEventMeetingAltRecordingMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateClassEventMeetingAltRecordingMutation,
    UpdateClassEventMeetingAltRecordingMutationVariables
  >(UpdateClassEventMeetingAltRecordingDocument, options);
}
export type UpdateClassEventMeetingAltRecordingMutationHookResult = ReturnType<
  typeof useUpdateClassEventMeetingAltRecordingMutation
>;
export type UpdateClassEventMeetingAltRecordingMutationResult =
  Apollo.MutationResult<UpdateClassEventMeetingAltRecordingMutation>;
export type UpdateClassEventMeetingAltRecordingMutationOptions = Apollo.BaseMutationOptions<
  UpdateClassEventMeetingAltRecordingMutation,
  UpdateClassEventMeetingAltRecordingMutationVariables
>;
export const UpdateClassEventMeetingSegmentDocument = gql`
  mutation UpdateClassEventMeetingSegment(
    $id: uuid!
    $jobs: jsonb
    $videoClipUrl: String
    $transcription: jsonb
    $synced: Boolean
  ) {
    updateClassEventMeetingSegmentsByPk(
      pkColumns: {id: $id}
      _set: {
        jobs: $jobs
        videoClipUrl: $videoClipUrl
        transcription: $transcription
        synced: $synced
      }
    ) {
      id
      jobs
      videoClipUrl
      transcription
      synced
    }
  }
`;
export type UpdateClassEventMeetingSegmentMutationFn = Apollo.MutationFunction<
  UpdateClassEventMeetingSegmentMutation,
  UpdateClassEventMeetingSegmentMutationVariables
>;

/**
 * __useUpdateClassEventMeetingSegmentMutation__
 *
 * To run a mutation, you first call `useUpdateClassEventMeetingSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassEventMeetingSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassEventMeetingSegmentMutation, { data, loading, error }] = useUpdateClassEventMeetingSegmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      jobs: // value for 'jobs'
 *      videoClipUrl: // value for 'videoClipUrl'
 *      transcription: // value for 'transcription'
 *      synced: // value for 'synced'
 *   },
 * });
 */
export function useUpdateClassEventMeetingSegmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClassEventMeetingSegmentMutation,
    UpdateClassEventMeetingSegmentMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateClassEventMeetingSegmentMutation,
    UpdateClassEventMeetingSegmentMutationVariables
  >(UpdateClassEventMeetingSegmentDocument, options);
}
export type UpdateClassEventMeetingSegmentMutationHookResult = ReturnType<
  typeof useUpdateClassEventMeetingSegmentMutation
>;
export type UpdateClassEventMeetingSegmentMutationResult =
  Apollo.MutationResult<UpdateClassEventMeetingSegmentMutation>;
export type UpdateClassEventMeetingSegmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateClassEventMeetingSegmentMutation,
  UpdateClassEventMeetingSegmentMutationVariables
>;
export const UpdateClassEventMeetingTranscodeJobDocument = gql`
  mutation UpdateClassEventMeetingTranscodeJob($meetingId: uuid!, $awsMediaConvertJobId: String) {
    updateClassEventMeetingsByPk(
      pkColumns: {id: $meetingId}
      _set: {awsMediaConvertJobId: $awsMediaConvertJobId}
    ) {
      id
    }
  }
`;
export type UpdateClassEventMeetingTranscodeJobMutationFn = Apollo.MutationFunction<
  UpdateClassEventMeetingTranscodeJobMutation,
  UpdateClassEventMeetingTranscodeJobMutationVariables
>;

/**
 * __useUpdateClassEventMeetingTranscodeJobMutation__
 *
 * To run a mutation, you first call `useUpdateClassEventMeetingTranscodeJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassEventMeetingTranscodeJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassEventMeetingTranscodeJobMutation, { data, loading, error }] = useUpdateClassEventMeetingTranscodeJobMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      awsMediaConvertJobId: // value for 'awsMediaConvertJobId'
 *   },
 * });
 */
export function useUpdateClassEventMeetingTranscodeJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClassEventMeetingTranscodeJobMutation,
    UpdateClassEventMeetingTranscodeJobMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateClassEventMeetingTranscodeJobMutation,
    UpdateClassEventMeetingTranscodeJobMutationVariables
  >(UpdateClassEventMeetingTranscodeJobDocument, options);
}
export type UpdateClassEventMeetingTranscodeJobMutationHookResult = ReturnType<
  typeof useUpdateClassEventMeetingTranscodeJobMutation
>;
export type UpdateClassEventMeetingTranscodeJobMutationResult =
  Apollo.MutationResult<UpdateClassEventMeetingTranscodeJobMutation>;
export type UpdateClassEventMeetingTranscodeJobMutationOptions = Apollo.BaseMutationOptions<
  UpdateClassEventMeetingTranscodeJobMutation,
  UpdateClassEventMeetingTranscodeJobMutationVariables
>;
export const UpdateClassEventMeetingDocument = gql`
  mutation UpdateClassEventMeeting(
    $meetingId: uuid!
    $dailySessionId: uuid!
    $recordingUrl: String
    $posterFrameUrl: String
    $duration: Int
    $maxParticipants: Int
    $startTime: timestamp
    $participants: [ClassEventMeetingParticipantsInsertInput!]!
  ) {
    updateClassEventMeetings(
      where: {id: {_eq: $meetingId}}
      _set: {
        duration: $duration
        maxParticipants: $maxParticipants
        recordingUrl: $recordingUrl
        posterFrameUrl: $posterFrameUrl
        startTime: $startTime
        dailySessionId: $dailySessionId
      }
    ) {
      affectedRows
    }
    upsertMeetingParticipants: insertClassEventMeetingParticipants(
      objects: $participants
      onConflict: {
        constraint: class_event_meeting_participants_pkey
        updateColumns: [duration, joinTime]
      }
    ) {
      affectedRows
    }
  }
`;
export type UpdateClassEventMeetingMutationFn = Apollo.MutationFunction<
  UpdateClassEventMeetingMutation,
  UpdateClassEventMeetingMutationVariables
>;

/**
 * __useUpdateClassEventMeetingMutation__
 *
 * To run a mutation, you first call `useUpdateClassEventMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassEventMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassEventMeetingMutation, { data, loading, error }] = useUpdateClassEventMeetingMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      dailySessionId: // value for 'dailySessionId'
 *      recordingUrl: // value for 'recordingUrl'
 *      posterFrameUrl: // value for 'posterFrameUrl'
 *      duration: // value for 'duration'
 *      maxParticipants: // value for 'maxParticipants'
 *      startTime: // value for 'startTime'
 *      participants: // value for 'participants'
 *   },
 * });
 */
export function useUpdateClassEventMeetingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClassEventMeetingMutation,
    UpdateClassEventMeetingMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateClassEventMeetingMutation,
    UpdateClassEventMeetingMutationVariables
  >(UpdateClassEventMeetingDocument, options);
}
export type UpdateClassEventMeetingMutationHookResult = ReturnType<
  typeof useUpdateClassEventMeetingMutation
>;
export type UpdateClassEventMeetingMutationResult =
  Apollo.MutationResult<UpdateClassEventMeetingMutation>;
export type UpdateClassEventMeetingMutationOptions = Apollo.BaseMutationOptions<
  UpdateClassEventMeetingMutation,
  UpdateClassEventMeetingMutationVariables
>;
export const UpdateClassEventRecordingUrlDocument = gql`
  mutation UpdateClassEventRecordingUrl($classEventId: uuid!, $recordingUrl: String) {
    updateClassEventsByPk(pkColumns: {id: $classEventId}, _set: {recordingUrl: $recordingUrl}) {
      id
    }
  }
`;
export type UpdateClassEventRecordingUrlMutationFn = Apollo.MutationFunction<
  UpdateClassEventRecordingUrlMutation,
  UpdateClassEventRecordingUrlMutationVariables
>;

/**
 * __useUpdateClassEventRecordingUrlMutation__
 *
 * To run a mutation, you first call `useUpdateClassEventRecordingUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassEventRecordingUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassEventRecordingUrlMutation, { data, loading, error }] = useUpdateClassEventRecordingUrlMutation({
 *   variables: {
 *      classEventId: // value for 'classEventId'
 *      recordingUrl: // value for 'recordingUrl'
 *   },
 * });
 */
export function useUpdateClassEventRecordingUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClassEventRecordingUrlMutation,
    UpdateClassEventRecordingUrlMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateClassEventRecordingUrlMutation,
    UpdateClassEventRecordingUrlMutationVariables
  >(UpdateClassEventRecordingUrlDocument, options);
}
export type UpdateClassEventRecordingUrlMutationHookResult = ReturnType<
  typeof useUpdateClassEventRecordingUrlMutation
>;
export type UpdateClassEventRecordingUrlMutationResult =
  Apollo.MutationResult<UpdateClassEventRecordingUrlMutation>;
export type UpdateClassEventRecordingUrlMutationOptions = Apollo.BaseMutationOptions<
  UpdateClassEventRecordingUrlMutation,
  UpdateClassEventRecordingUrlMutationVariables
>;
export const UpdateLightningSessionBorDetailsDocument = gql`
  mutation UpdateLightningSessionBorDetails($id: uuid!, $borDetail: jsonb) {
    updateLightningSessionByPk(pkColumns: {id: $id}, _append: {borDetails: $borDetail}) {
      borDetails
    }
  }
`;
export type UpdateLightningSessionBorDetailsMutationFn = Apollo.MutationFunction<
  UpdateLightningSessionBorDetailsMutation,
  UpdateLightningSessionBorDetailsMutationVariables
>;

/**
 * __useUpdateLightningSessionBorDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateLightningSessionBorDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLightningSessionBorDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLightningSessionBorDetailsMutation, { data, loading, error }] = useUpdateLightningSessionBorDetailsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      borDetail: // value for 'borDetail'
 *   },
 * });
 */
export function useUpdateLightningSessionBorDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLightningSessionBorDetailsMutation,
    UpdateLightningSessionBorDetailsMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateLightningSessionBorDetailsMutation,
    UpdateLightningSessionBorDetailsMutationVariables
  >(UpdateLightningSessionBorDetailsDocument, options);
}
export type UpdateLightningSessionBorDetailsMutationHookResult = ReturnType<
  typeof useUpdateLightningSessionBorDetailsMutation
>;
export type UpdateLightningSessionBorDetailsMutationResult =
  Apollo.MutationResult<UpdateLightningSessionBorDetailsMutation>;
export type UpdateLightningSessionBorDetailsMutationOptions = Apollo.BaseMutationOptions<
  UpdateLightningSessionBorDetailsMutation,
  UpdateLightningSessionBorDetailsMutationVariables
>;
export const AddUserToCourseWaitlistDocument = gql`
  mutation AddUserToCourseWaitlist($input: CourseWaitlistInput!) {
    AddUserToCourseWaitlist(input: $input) {
      success
      details
    }
  }
`;
export type AddUserToCourseWaitlistMutationFn = Apollo.MutationFunction<
  AddUserToCourseWaitlistMutation,
  AddUserToCourseWaitlistMutationVariables
>;

/**
 * __useAddUserToCourseWaitlistMutation__
 *
 * To run a mutation, you first call `useAddUserToCourseWaitlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToCourseWaitlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToCourseWaitlistMutation, { data, loading, error }] = useAddUserToCourseWaitlistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserToCourseWaitlistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserToCourseWaitlistMutation,
    AddUserToCourseWaitlistMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    AddUserToCourseWaitlistMutation,
    AddUserToCourseWaitlistMutationVariables
  >(AddUserToCourseWaitlistDocument, options);
}
export type AddUserToCourseWaitlistMutationHookResult = ReturnType<
  typeof useAddUserToCourseWaitlistMutation
>;
export type AddUserToCourseWaitlistMutationResult =
  Apollo.MutationResult<AddUserToCourseWaitlistMutation>;
export type AddUserToCourseWaitlistMutationOptions = Apollo.BaseMutationOptions<
  AddUserToCourseWaitlistMutation,
  AddUserToCourseWaitlistMutationVariables
>;
export const InsertCourseWaitlistDocument = gql`
  mutation InsertCourseWaitlist($objects: [CourseWaitlistInsertInput!]!) {
    insertCourseWaitlist(
      objects: $objects
      onConflict: {
        constraint: course_waitlist_is_not_deleted_course_slug_user_id_key
        updateColumns: [desiredUrgency, desiredTimezone, createdAt]
      }
    ) {
      affectedRows
      returning {
        id
        userId
      }
    }
  }
`;
export type InsertCourseWaitlistMutationFn = Apollo.MutationFunction<
  InsertCourseWaitlistMutation,
  InsertCourseWaitlistMutationVariables
>;

/**
 * __useInsertCourseWaitlistMutation__
 *
 * To run a mutation, you first call `useInsertCourseWaitlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCourseWaitlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCourseWaitlistMutation, { data, loading, error }] = useInsertCourseWaitlistMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertCourseWaitlistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertCourseWaitlistMutation,
    InsertCourseWaitlistMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<InsertCourseWaitlistMutation, InsertCourseWaitlistMutationVariables>(
    InsertCourseWaitlistDocument,
    options
  );
}
export type InsertCourseWaitlistMutationHookResult = ReturnType<
  typeof useInsertCourseWaitlistMutation
>;
export type InsertCourseWaitlistMutationResult =
  Apollo.MutationResult<InsertCourseWaitlistMutation>;
export type InsertCourseWaitlistMutationOptions = Apollo.BaseMutationOptions<
  InsertCourseWaitlistMutation,
  InsertCourseWaitlistMutationVariables
>;
export const InsertCourseWaitlistOneDocument = gql`
  mutation InsertCourseWaitlistOne(
    $courseSlug: String!
    $userId: uuid!
    $time: String
    $urgency: CourseWaitlistDesiredUrgencyTypeEnum
    $createdById: uuid!
    $createdAt: timestamp
    $orgId: uuid
  ) {
    insertCourseWaitlistOne(
      object: {
        courseSlug: $courseSlug
        userId: $userId
        desiredTimezone: $time
        desiredUrgency: $urgency
        createdById: $createdById
        createdAt: $createdAt
        organizationId: $orgId
      }
      onConflict: {
        constraint: course_waitlist_is_not_deleted_course_slug_user_id_key
        updateColumns: [desiredUrgency, desiredTimezone, createdAt]
      }
    ) {
      id
    }
  }
`;
export type InsertCourseWaitlistOneMutationFn = Apollo.MutationFunction<
  InsertCourseWaitlistOneMutation,
  InsertCourseWaitlistOneMutationVariables
>;

/**
 * __useInsertCourseWaitlistOneMutation__
 *
 * To run a mutation, you first call `useInsertCourseWaitlistOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCourseWaitlistOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCourseWaitlistOneMutation, { data, loading, error }] = useInsertCourseWaitlistOneMutation({
 *   variables: {
 *      courseSlug: // value for 'courseSlug'
 *      userId: // value for 'userId'
 *      time: // value for 'time'
 *      urgency: // value for 'urgency'
 *      createdById: // value for 'createdById'
 *      createdAt: // value for 'createdAt'
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useInsertCourseWaitlistOneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertCourseWaitlistOneMutation,
    InsertCourseWaitlistOneMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    InsertCourseWaitlistOneMutation,
    InsertCourseWaitlistOneMutationVariables
  >(InsertCourseWaitlistOneDocument, options);
}
export type InsertCourseWaitlistOneMutationHookResult = ReturnType<
  typeof useInsertCourseWaitlistOneMutation
>;
export type InsertCourseWaitlistOneMutationResult =
  Apollo.MutationResult<InsertCourseWaitlistOneMutation>;
export type InsertCourseWaitlistOneMutationOptions = Apollo.BaseMutationOptions<
  InsertCourseWaitlistOneMutation,
  InsertCourseWaitlistOneMutationVariables
>;
export const SoftDeleteCourseWaitlistManyDocument = gql`
  mutation SoftDeleteCourseWaitlistMany($updates: [CourseWaitlistUpdates!]!) {
    updateCourseWaitlistMany(updates: $updates) {
      affectedRows
      returning {
        id
        user {
          id
          firstName
          lastName
        }
        deletedAt
        deletedById
      }
    }
  }
`;
export type SoftDeleteCourseWaitlistManyMutationFn = Apollo.MutationFunction<
  SoftDeleteCourseWaitlistManyMutation,
  SoftDeleteCourseWaitlistManyMutationVariables
>;

/**
 * __useSoftDeleteCourseWaitlistManyMutation__
 *
 * To run a mutation, you first call `useSoftDeleteCourseWaitlistManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftDeleteCourseWaitlistManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softDeleteCourseWaitlistManyMutation, { data, loading, error }] = useSoftDeleteCourseWaitlistManyMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useSoftDeleteCourseWaitlistManyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SoftDeleteCourseWaitlistManyMutation,
    SoftDeleteCourseWaitlistManyMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    SoftDeleteCourseWaitlistManyMutation,
    SoftDeleteCourseWaitlistManyMutationVariables
  >(SoftDeleteCourseWaitlistManyDocument, options);
}
export type SoftDeleteCourseWaitlistManyMutationHookResult = ReturnType<
  typeof useSoftDeleteCourseWaitlistManyMutation
>;
export type SoftDeleteCourseWaitlistManyMutationResult =
  Apollo.MutationResult<SoftDeleteCourseWaitlistManyMutation>;
export type SoftDeleteCourseWaitlistManyMutationOptions = Apollo.BaseMutationOptions<
  SoftDeleteCourseWaitlistManyMutation,
  SoftDeleteCourseWaitlistManyMutationVariables
>;
export const UpdateBeforeClassReminderEmailDocument = gql`
  mutation UpdateBeforeClassReminderEmail(
    $userClassEventId: String = ""
    $sentHourReminderEmailAt: timestamptz = ""
  ) {
    updateUserSessionEmailsByPk(
      pkColumns: {userClassEventId: $userClassEventId}
      _set: {sentHourReminderEmailAt: $sentHourReminderEmailAt, hasSentHourReminderEmail: true}
    ) {
      userClassEventId
    }
  }
`;
export type UpdateBeforeClassReminderEmailMutationFn = Apollo.MutationFunction<
  UpdateBeforeClassReminderEmailMutation,
  UpdateBeforeClassReminderEmailMutationVariables
>;

/**
 * __useUpdateBeforeClassReminderEmailMutation__
 *
 * To run a mutation, you first call `useUpdateBeforeClassReminderEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBeforeClassReminderEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBeforeClassReminderEmailMutation, { data, loading, error }] = useUpdateBeforeClassReminderEmailMutation({
 *   variables: {
 *      userClassEventId: // value for 'userClassEventId'
 *      sentHourReminderEmailAt: // value for 'sentHourReminderEmailAt'
 *   },
 * });
 */
export function useUpdateBeforeClassReminderEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBeforeClassReminderEmailMutation,
    UpdateBeforeClassReminderEmailMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateBeforeClassReminderEmailMutation,
    UpdateBeforeClassReminderEmailMutationVariables
  >(UpdateBeforeClassReminderEmailDocument, options);
}
export type UpdateBeforeClassReminderEmailMutationHookResult = ReturnType<
  typeof useUpdateBeforeClassReminderEmailMutation
>;
export type UpdateBeforeClassReminderEmailMutationResult =
  Apollo.MutationResult<UpdateBeforeClassReminderEmailMutation>;
export type UpdateBeforeClassReminderEmailMutationOptions = Apollo.BaseMutationOptions<
  UpdateBeforeClassReminderEmailMutation,
  UpdateBeforeClassReminderEmailMutationVariables
>;
export const UpdateEmailsQueueProcessedStatusDocument = gql`
  mutation UpdateEmailsQueueProcessedStatus($id: uuid!, $status: Boolean) {
    updateEmailQueueByPk(pkColumns: {id: $id}, _set: {processed: $status, processedAt: now}) {
      id
      processed
      processedAt
    }
  }
`;
export type UpdateEmailsQueueProcessedStatusMutationFn = Apollo.MutationFunction<
  UpdateEmailsQueueProcessedStatusMutation,
  UpdateEmailsQueueProcessedStatusMutationVariables
>;

/**
 * __useUpdateEmailsQueueProcessedStatusMutation__
 *
 * To run a mutation, you first call `useUpdateEmailsQueueProcessedStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailsQueueProcessedStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailsQueueProcessedStatusMutation, { data, loading, error }] = useUpdateEmailsQueueProcessedStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateEmailsQueueProcessedStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEmailsQueueProcessedStatusMutation,
    UpdateEmailsQueueProcessedStatusMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateEmailsQueueProcessedStatusMutation,
    UpdateEmailsQueueProcessedStatusMutationVariables
  >(UpdateEmailsQueueProcessedStatusDocument, options);
}
export type UpdateEmailsQueueProcessedStatusMutationHookResult = ReturnType<
  typeof useUpdateEmailsQueueProcessedStatusMutation
>;
export type UpdateEmailsQueueProcessedStatusMutationResult =
  Apollo.MutationResult<UpdateEmailsQueueProcessedStatusMutation>;
export type UpdateEmailsQueueProcessedStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateEmailsQueueProcessedStatusMutation,
  UpdateEmailsQueueProcessedStatusMutationVariables
>;
export const UpdateManagerPostSessionEmailDocument = gql`
  mutation UpdateManagerPostSessionEmail(
    $managerCohortId: String = ""
    $sentPostSessionEmailAt: timestamptz = ""
  ) {
    updateManagerSessionEmailsByPk(
      pkColumns: {managerCohortId: $managerCohortId}
      _set: {sentPostSessionEmailAt: $sentPostSessionEmailAt, hasSentPostSessionEmail: true}
    ) {
      managerCohortId
    }
  }
`;
export type UpdateManagerPostSessionEmailMutationFn = Apollo.MutationFunction<
  UpdateManagerPostSessionEmailMutation,
  UpdateManagerPostSessionEmailMutationVariables
>;

/**
 * __useUpdateManagerPostSessionEmailMutation__
 *
 * To run a mutation, you first call `useUpdateManagerPostSessionEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManagerPostSessionEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManagerPostSessionEmailMutation, { data, loading, error }] = useUpdateManagerPostSessionEmailMutation({
 *   variables: {
 *      managerCohortId: // value for 'managerCohortId'
 *      sentPostSessionEmailAt: // value for 'sentPostSessionEmailAt'
 *   },
 * });
 */
export function useUpdateManagerPostSessionEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateManagerPostSessionEmailMutation,
    UpdateManagerPostSessionEmailMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateManagerPostSessionEmailMutation,
    UpdateManagerPostSessionEmailMutationVariables
  >(UpdateManagerPostSessionEmailDocument, options);
}
export type UpdateManagerPostSessionEmailMutationHookResult = ReturnType<
  typeof useUpdateManagerPostSessionEmailMutation
>;
export type UpdateManagerPostSessionEmailMutationResult =
  Apollo.MutationResult<UpdateManagerPostSessionEmailMutation>;
export type UpdateManagerPostSessionEmailMutationOptions = Apollo.BaseMutationOptions<
  UpdateManagerPostSessionEmailMutation,
  UpdateManagerPostSessionEmailMutationVariables
>;
export const UpdateManagerPreSessionEmailDocument = gql`
  mutation UpdateManagerPreSessionEmail(
    $managerCohortId: String = ""
    $sentPreSessionEmailAt: timestamptz = ""
  ) {
    updateManagerSessionEmailsByPk(
      pkColumns: {managerCohortId: $managerCohortId}
      _set: {sentPreSessionEmailAt: $sentPreSessionEmailAt, hasSentPreSessionEmail: true}
    ) {
      managerCohortId
    }
  }
`;
export type UpdateManagerPreSessionEmailMutationFn = Apollo.MutationFunction<
  UpdateManagerPreSessionEmailMutation,
  UpdateManagerPreSessionEmailMutationVariables
>;

/**
 * __useUpdateManagerPreSessionEmailMutation__
 *
 * To run a mutation, you first call `useUpdateManagerPreSessionEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManagerPreSessionEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManagerPreSessionEmailMutation, { data, loading, error }] = useUpdateManagerPreSessionEmailMutation({
 *   variables: {
 *      managerCohortId: // value for 'managerCohortId'
 *      sentPreSessionEmailAt: // value for 'sentPreSessionEmailAt'
 *   },
 * });
 */
export function useUpdateManagerPreSessionEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateManagerPreSessionEmailMutation,
    UpdateManagerPreSessionEmailMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateManagerPreSessionEmailMutation,
    UpdateManagerPreSessionEmailMutationVariables
  >(UpdateManagerPreSessionEmailDocument, options);
}
export type UpdateManagerPreSessionEmailMutationHookResult = ReturnType<
  typeof useUpdateManagerPreSessionEmailMutation
>;
export type UpdateManagerPreSessionEmailMutationResult =
  Apollo.MutationResult<UpdateManagerPreSessionEmailMutation>;
export type UpdateManagerPreSessionEmailMutationOptions = Apollo.BaseMutationOptions<
  UpdateManagerPreSessionEmailMutation,
  UpdateManagerPreSessionEmailMutationVariables
>;
export const UpdateMondayReminderEmailDocument = gql`
  mutation UpdateMondayReminderEmail(
    $userClassEventId: String = ""
    $sentMondayReminderEmailAt: timestamptz = ""
  ) {
    updateUserSessionEmailsByPk(
      pkColumns: {userClassEventId: $userClassEventId}
      _set: {
        sentMondayReminderEmailAt: $sentMondayReminderEmailAt
        hasSentMondayReminderEmail: true
      }
    ) {
      userClassEventId
    }
  }
`;
export type UpdateMondayReminderEmailMutationFn = Apollo.MutationFunction<
  UpdateMondayReminderEmailMutation,
  UpdateMondayReminderEmailMutationVariables
>;

/**
 * __useUpdateMondayReminderEmailMutation__
 *
 * To run a mutation, you first call `useUpdateMondayReminderEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMondayReminderEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMondayReminderEmailMutation, { data, loading, error }] = useUpdateMondayReminderEmailMutation({
 *   variables: {
 *      userClassEventId: // value for 'userClassEventId'
 *      sentMondayReminderEmailAt: // value for 'sentMondayReminderEmailAt'
 *   },
 * });
 */
export function useUpdateMondayReminderEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMondayReminderEmailMutation,
    UpdateMondayReminderEmailMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateMondayReminderEmailMutation,
    UpdateMondayReminderEmailMutationVariables
  >(UpdateMondayReminderEmailDocument, options);
}
export type UpdateMondayReminderEmailMutationHookResult = ReturnType<
  typeof useUpdateMondayReminderEmailMutation
>;
export type UpdateMondayReminderEmailMutationResult =
  Apollo.MutationResult<UpdateMondayReminderEmailMutation>;
export type UpdateMondayReminderEmailMutationOptions = Apollo.BaseMutationOptions<
  UpdateMondayReminderEmailMutation,
  UpdateMondayReminderEmailMutationVariables
>;
export const AddLearnerToEnrollmentsDocument = gql`
  mutation AddLearnerToEnrollments($object: EnrollmentsInsertInput!) {
    enrollment: insertEnrollmentsOne(object: $object) {
      enrollmentId: id
    }
  }
`;
export type AddLearnerToEnrollmentsMutationFn = Apollo.MutationFunction<
  AddLearnerToEnrollmentsMutation,
  AddLearnerToEnrollmentsMutationVariables
>;

/**
 * __useAddLearnerToEnrollmentsMutation__
 *
 * To run a mutation, you first call `useAddLearnerToEnrollmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLearnerToEnrollmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLearnerToEnrollmentsMutation, { data, loading, error }] = useAddLearnerToEnrollmentsMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useAddLearnerToEnrollmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddLearnerToEnrollmentsMutation,
    AddLearnerToEnrollmentsMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    AddLearnerToEnrollmentsMutation,
    AddLearnerToEnrollmentsMutationVariables
  >(AddLearnerToEnrollmentsDocument, options);
}
export type AddLearnerToEnrollmentsMutationHookResult = ReturnType<
  typeof useAddLearnerToEnrollmentsMutation
>;
export type AddLearnerToEnrollmentsMutationResult =
  Apollo.MutationResult<AddLearnerToEnrollmentsMutation>;
export type AddLearnerToEnrollmentsMutationOptions = Apollo.BaseMutationOptions<
  AddLearnerToEnrollmentsMutation,
  AddLearnerToEnrollmentsMutationVariables
>;
export const AddLearnerToWaitlistDocument = gql`
  mutation AddLearnerToWaitlist($object: EnrollmentWaitlistsInsertInput!) {
    insertEnrollmentWaitlistsOne(
      object: $object
      onConflict: {
        constraint: enrollment_waitlists_cohort_id_user_id_deleted_at_key
        updateColumns: []
      }
    ) {
      id
    }
  }
`;
export type AddLearnerToWaitlistMutationFn = Apollo.MutationFunction<
  AddLearnerToWaitlistMutation,
  AddLearnerToWaitlistMutationVariables
>;

/**
 * __useAddLearnerToWaitlistMutation__
 *
 * To run a mutation, you first call `useAddLearnerToWaitlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLearnerToWaitlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLearnerToWaitlistMutation, { data, loading, error }] = useAddLearnerToWaitlistMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useAddLearnerToWaitlistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddLearnerToWaitlistMutation,
    AddLearnerToWaitlistMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<AddLearnerToWaitlistMutation, AddLearnerToWaitlistMutationVariables>(
    AddLearnerToWaitlistDocument,
    options
  );
}
export type AddLearnerToWaitlistMutationHookResult = ReturnType<
  typeof useAddLearnerToWaitlistMutation
>;
export type AddLearnerToWaitlistMutationResult =
  Apollo.MutationResult<AddLearnerToWaitlistMutation>;
export type AddLearnerToWaitlistMutationOptions = Apollo.BaseMutationOptions<
  AddLearnerToWaitlistMutation,
  AddLearnerToWaitlistMutationVariables
>;
export const AddLearnersToWaitlistDocument = gql`
  mutation AddLearnersToWaitlist($objects: [EnrollmentWaitlistsInsertInput!]!) {
    insertEnrollmentWaitlists(
      objects: $objects
      onConflict: {
        constraint: enrollment_waitlists_cohort_id_user_id_deleted_at_key
        updateColumns: []
      }
    ) {
      affectedRows
    }
  }
`;
export type AddLearnersToWaitlistMutationFn = Apollo.MutationFunction<
  AddLearnersToWaitlistMutation,
  AddLearnersToWaitlistMutationVariables
>;

/**
 * __useAddLearnersToWaitlistMutation__
 *
 * To run a mutation, you first call `useAddLearnersToWaitlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLearnersToWaitlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLearnersToWaitlistMutation, { data, loading, error }] = useAddLearnersToWaitlistMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddLearnersToWaitlistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddLearnersToWaitlistMutation,
    AddLearnersToWaitlistMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<AddLearnersToWaitlistMutation, AddLearnersToWaitlistMutationVariables>(
    AddLearnersToWaitlistDocument,
    options
  );
}
export type AddLearnersToWaitlistMutationHookResult = ReturnType<
  typeof useAddLearnersToWaitlistMutation
>;
export type AddLearnersToWaitlistMutationResult =
  Apollo.MutationResult<AddLearnersToWaitlistMutation>;
export type AddLearnersToWaitlistMutationOptions = Apollo.BaseMutationOptions<
  AddLearnersToWaitlistMutation,
  AddLearnersToWaitlistMutationVariables
>;
export const CreateClassEventRegistrationDocument = gql`
  mutation CreateClassEventRegistration($object: ClassEventRegistrationsInsertInput!) {
    insertClassEventRegistrationsOne(
      object: $object
      onConflict: {
        updateColumns: [updatedAt, zoomJoinLinkUrl]
        constraint: class_event_registrations_user_id_class_event_id_key
      }
    ) {
      id
    }
  }
`;
export type CreateClassEventRegistrationMutationFn = Apollo.MutationFunction<
  CreateClassEventRegistrationMutation,
  CreateClassEventRegistrationMutationVariables
>;

/**
 * __useCreateClassEventRegistrationMutation__
 *
 * To run a mutation, you first call `useCreateClassEventRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClassEventRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClassEventRegistrationMutation, { data, loading, error }] = useCreateClassEventRegistrationMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreateClassEventRegistrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClassEventRegistrationMutation,
    CreateClassEventRegistrationMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    CreateClassEventRegistrationMutation,
    CreateClassEventRegistrationMutationVariables
  >(CreateClassEventRegistrationDocument, options);
}
export type CreateClassEventRegistrationMutationHookResult = ReturnType<
  typeof useCreateClassEventRegistrationMutation
>;
export type CreateClassEventRegistrationMutationResult =
  Apollo.MutationResult<CreateClassEventRegistrationMutation>;
export type CreateClassEventRegistrationMutationOptions = Apollo.BaseMutationOptions<
  CreateClassEventRegistrationMutation,
  CreateClassEventRegistrationMutationVariables
>;
export const DeleteWaitlistEntryDocument = gql`
  mutation DeleteWaitlistEntry($id: uuid!, $deletedBy: uuid!) {
    updateEnrollmentWaitlistsByPk(
      pkColumns: {id: $id}
      _set: {deletedAt: "now()", deletedBy: $deletedBy}
    ) {
      __typename
    }
  }
`;
export type DeleteWaitlistEntryMutationFn = Apollo.MutationFunction<
  DeleteWaitlistEntryMutation,
  DeleteWaitlistEntryMutationVariables
>;

/**
 * __useDeleteWaitlistEntryMutation__
 *
 * To run a mutation, you first call `useDeleteWaitlistEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWaitlistEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWaitlistEntryMutation, { data, loading, error }] = useDeleteWaitlistEntryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deletedBy: // value for 'deletedBy'
 *   },
 * });
 */
export function useDeleteWaitlistEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteWaitlistEntryMutation,
    DeleteWaitlistEntryMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<DeleteWaitlistEntryMutation, DeleteWaitlistEntryMutationVariables>(
    DeleteWaitlistEntryDocument,
    options
  );
}
export type DeleteWaitlistEntryMutationHookResult = ReturnType<
  typeof useDeleteWaitlistEntryMutation
>;
export type DeleteWaitlistEntryMutationResult = Apollo.MutationResult<DeleteWaitlistEntryMutation>;
export type DeleteWaitlistEntryMutationOptions = Apollo.BaseMutationOptions<
  DeleteWaitlistEntryMutation,
  DeleteWaitlistEntryMutationVariables
>;
export const DequeueEnrollmentWaitlistDocument = gql`
  mutation DequeueEnrollmentWaitlist(
    $cohortId: uuid!
    $learnerUserIds: [uuid!]!
    $deletedBy: uuid!
    $newEnrollments: [EnrollmentsInsertInput!]!
  ) {
    updateEnrollmentWaitlists(
      where: {
        cohortId: {_eq: $cohortId}
        userId: {_in: $learnerUserIds}
        deletedAt: {_isNull: true}
      }
      _set: {deletedAt: "now()", deletedBy: $deletedBy}
    ) {
      affectedRows
    }
    insertEnrollments(objects: $newEnrollments) {
      affectedRows
    }
  }
`;
export type DequeueEnrollmentWaitlistMutationFn = Apollo.MutationFunction<
  DequeueEnrollmentWaitlistMutation,
  DequeueEnrollmentWaitlistMutationVariables
>;

/**
 * __useDequeueEnrollmentWaitlistMutation__
 *
 * To run a mutation, you first call `useDequeueEnrollmentWaitlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDequeueEnrollmentWaitlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dequeueEnrollmentWaitlistMutation, { data, loading, error }] = useDequeueEnrollmentWaitlistMutation({
 *   variables: {
 *      cohortId: // value for 'cohortId'
 *      learnerUserIds: // value for 'learnerUserIds'
 *      deletedBy: // value for 'deletedBy'
 *      newEnrollments: // value for 'newEnrollments'
 *   },
 * });
 */
export function useDequeueEnrollmentWaitlistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DequeueEnrollmentWaitlistMutation,
    DequeueEnrollmentWaitlistMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    DequeueEnrollmentWaitlistMutation,
    DequeueEnrollmentWaitlistMutationVariables
  >(DequeueEnrollmentWaitlistDocument, options);
}
export type DequeueEnrollmentWaitlistMutationHookResult = ReturnType<
  typeof useDequeueEnrollmentWaitlistMutation
>;
export type DequeueEnrollmentWaitlistMutationResult =
  Apollo.MutationResult<DequeueEnrollmentWaitlistMutation>;
export type DequeueEnrollmentWaitlistMutationOptions = Apollo.BaseMutationOptions<
  DequeueEnrollmentWaitlistMutation,
  DequeueEnrollmentWaitlistMutationVariables
>;
export const EnrollLearnerDocument = gql`
  mutation EnrollLearner($cohortId: uuid!, $waitlistEnabled: Boolean = false) {
    EnrollLearner(input: {cohortId: $cohortId, waitlistEnabled: $waitlistEnabled}) {
      enrollmentId
      waitlistId
    }
  }
`;
export type EnrollLearnerMutationFn = Apollo.MutationFunction<
  EnrollLearnerMutation,
  EnrollLearnerMutationVariables
>;

/**
 * __useEnrollLearnerMutation__
 *
 * To run a mutation, you first call `useEnrollLearnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollLearnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollLearnerMutation, { data, loading, error }] = useEnrollLearnerMutation({
 *   variables: {
 *      cohortId: // value for 'cohortId'
 *      waitlistEnabled: // value for 'waitlistEnabled'
 *   },
 * });
 */
export function useEnrollLearnerMutation(
  baseOptions?: Apollo.MutationHookOptions<EnrollLearnerMutation, EnrollLearnerMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<EnrollLearnerMutation, EnrollLearnerMutationVariables>(
    EnrollLearnerDocument,
    options
  );
}
export type EnrollLearnerMutationHookResult = ReturnType<typeof useEnrollLearnerMutation>;
export type EnrollLearnerMutationResult = Apollo.MutationResult<EnrollLearnerMutation>;
export type EnrollLearnerMutationOptions = Apollo.BaseMutationOptions<
  EnrollLearnerMutation,
  EnrollLearnerMutationVariables
>;
export const EnrollTeamMemberDocument = gql`
  mutation EnrollTeamMember($userId: uuid!, $cohortId: uuid!, $waitlistEnabled: Boolean = false) {
    EnrollLearner(
      input: {userId: $userId, cohortId: $cohortId, waitlistEnabled: $waitlistEnabled}
    ) {
      enrollmentId
      waitlistId
    }
  }
`;
export type EnrollTeamMemberMutationFn = Apollo.MutationFunction<
  EnrollTeamMemberMutation,
  EnrollTeamMemberMutationVariables
>;

/**
 * __useEnrollTeamMemberMutation__
 *
 * To run a mutation, you first call `useEnrollTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollTeamMemberMutation, { data, loading, error }] = useEnrollTeamMemberMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      cohortId: // value for 'cohortId'
 *      waitlistEnabled: // value for 'waitlistEnabled'
 *   },
 * });
 */
export function useEnrollTeamMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EnrollTeamMemberMutation,
    EnrollTeamMemberMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<EnrollTeamMemberMutation, EnrollTeamMemberMutationVariables>(
    EnrollTeamMemberDocument,
    options
  );
}
export type EnrollTeamMemberMutationHookResult = ReturnType<typeof useEnrollTeamMemberMutation>;
export type EnrollTeamMemberMutationResult = Apollo.MutationResult<EnrollTeamMemberMutation>;
export type EnrollTeamMemberMutationOptions = Apollo.BaseMutationOptions<
  EnrollTeamMemberMutation,
  EnrollTeamMemberMutationVariables
>;
export const RemoveLearnerFromEnrollmentsDocument = gql`
  mutation RemoveLearnerFromEnrollments(
    $enrollmentId: uuid!
    $deletedById: uuid!
    $reason: String
    $onBehalfOfUserId: uuid
  ) {
    enrollment: updateEnrollmentsByPk(
      _set: {
        deletedById: $deletedById
        deletedAt: now
        deletedOnBehalfOfId: $onBehalfOfUserId
        deletedReason: $reason
      }
      pkColumns: {id: $enrollmentId}
    ) {
      enrollmentId: id
    }
  }
`;
export type RemoveLearnerFromEnrollmentsMutationFn = Apollo.MutationFunction<
  RemoveLearnerFromEnrollmentsMutation,
  RemoveLearnerFromEnrollmentsMutationVariables
>;

/**
 * __useRemoveLearnerFromEnrollmentsMutation__
 *
 * To run a mutation, you first call `useRemoveLearnerFromEnrollmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLearnerFromEnrollmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLearnerFromEnrollmentsMutation, { data, loading, error }] = useRemoveLearnerFromEnrollmentsMutation({
 *   variables: {
 *      enrollmentId: // value for 'enrollmentId'
 *      deletedById: // value for 'deletedById'
 *      reason: // value for 'reason'
 *      onBehalfOfUserId: // value for 'onBehalfOfUserId'
 *   },
 * });
 */
export function useRemoveLearnerFromEnrollmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveLearnerFromEnrollmentsMutation,
    RemoveLearnerFromEnrollmentsMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    RemoveLearnerFromEnrollmentsMutation,
    RemoveLearnerFromEnrollmentsMutationVariables
  >(RemoveLearnerFromEnrollmentsDocument, options);
}
export type RemoveLearnerFromEnrollmentsMutationHookResult = ReturnType<
  typeof useRemoveLearnerFromEnrollmentsMutation
>;
export type RemoveLearnerFromEnrollmentsMutationResult =
  Apollo.MutationResult<RemoveLearnerFromEnrollmentsMutation>;
export type RemoveLearnerFromEnrollmentsMutationOptions = Apollo.BaseMutationOptions<
  RemoveLearnerFromEnrollmentsMutation,
  RemoveLearnerFromEnrollmentsMutationVariables
>;
export const UnenrollLearnerDocument = gql`
  mutation UnenrollLearner(
    $cohortId: uuid!
    $userId: uuid
    $onBehalfOfUserId: uuid
    $reason: String
    $waitlistEnabled: Boolean = false
  ) {
    UnenrollLearner(
      input: {
        cohortId: $cohortId
        userId: $userId
        onBehalfOfUserId: $onBehalfOfUserId
        waitlistEnabled: $waitlistEnabled
        reason: $reason
      }
    ) {
      enrollmentId
      waitlistId
      unenrollmentStatus
    }
  }
`;
export type UnenrollLearnerMutationFn = Apollo.MutationFunction<
  UnenrollLearnerMutation,
  UnenrollLearnerMutationVariables
>;

/**
 * __useUnenrollLearnerMutation__
 *
 * To run a mutation, you first call `useUnenrollLearnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnenrollLearnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unenrollLearnerMutation, { data, loading, error }] = useUnenrollLearnerMutation({
 *   variables: {
 *      cohortId: // value for 'cohortId'
 *      userId: // value for 'userId'
 *      onBehalfOfUserId: // value for 'onBehalfOfUserId'
 *      reason: // value for 'reason'
 *      waitlistEnabled: // value for 'waitlistEnabled'
 *   },
 * });
 */
export function useUnenrollLearnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnenrollLearnerMutation,
    UnenrollLearnerMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<UnenrollLearnerMutation, UnenrollLearnerMutationVariables>(
    UnenrollLearnerDocument,
    options
  );
}
export type UnenrollLearnerMutationHookResult = ReturnType<typeof useUnenrollLearnerMutation>;
export type UnenrollLearnerMutationResult = Apollo.MutationResult<UnenrollLearnerMutation>;
export type UnenrollLearnerMutationOptions = Apollo.BaseMutationOptions<
  UnenrollLearnerMutation,
  UnenrollLearnerMutationVariables
>;
export const UnenrollTeamMemberDocument = gql`
  mutation UnenrollTeamMember(
    $userId: uuid!
    $cohortId: uuid!
    $reason: String
    $waitlistEnabled: Boolean = false
  ) {
    UnenrollLearner(
      input: {
        userId: $userId
        cohortId: $cohortId
        waitlistEnabled: $waitlistEnabled
        reason: $reason
      }
    ) {
      unenrollmentStatus
      enrollmentId
      waitlistId
    }
  }
`;
export type UnenrollTeamMemberMutationFn = Apollo.MutationFunction<
  UnenrollTeamMemberMutation,
  UnenrollTeamMemberMutationVariables
>;

/**
 * __useUnenrollTeamMemberMutation__
 *
 * To run a mutation, you first call `useUnenrollTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnenrollTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unenrollTeamMemberMutation, { data, loading, error }] = useUnenrollTeamMemberMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      cohortId: // value for 'cohortId'
 *      reason: // value for 'reason'
 *      waitlistEnabled: // value for 'waitlistEnabled'
 *   },
 * });
 */
export function useUnenrollTeamMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnenrollTeamMemberMutation,
    UnenrollTeamMemberMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<UnenrollTeamMemberMutation, UnenrollTeamMemberMutationVariables>(
    UnenrollTeamMemberDocument,
    options
  );
}
export type UnenrollTeamMemberMutationHookResult = ReturnType<typeof useUnenrollTeamMemberMutation>;
export type UnenrollTeamMemberMutationResult = Apollo.MutationResult<UnenrollTeamMemberMutation>;
export type UnenrollTeamMemberMutationOptions = Apollo.BaseMutationOptions<
  UnenrollTeamMemberMutation,
  UnenrollTeamMemberMutationVariables
>;
export const UpdateJourneyEnrollmentDocument = gql`
  mutation UpdateJourneyEnrollment(
    $id: uuid!
    $nextTaskId: uuid!
    $nextTaskAt: timestamp!
    $isComplete: Boolean
  ) {
    updateJourneyEnrollmentsByPk(
      pkColumns: {id: $id}
      _set: {nextTaskId: $nextTaskId, nextTaskAt: $nextTaskAt, isComplete: $isComplete}
    ) {
      id
    }
  }
`;
export type UpdateJourneyEnrollmentMutationFn = Apollo.MutationFunction<
  UpdateJourneyEnrollmentMutation,
  UpdateJourneyEnrollmentMutationVariables
>;

/**
 * __useUpdateJourneyEnrollmentMutation__
 *
 * To run a mutation, you first call `useUpdateJourneyEnrollmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJourneyEnrollmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJourneyEnrollmentMutation, { data, loading, error }] = useUpdateJourneyEnrollmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      nextTaskId: // value for 'nextTaskId'
 *      nextTaskAt: // value for 'nextTaskAt'
 *      isComplete: // value for 'isComplete'
 *   },
 * });
 */
export function useUpdateJourneyEnrollmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateJourneyEnrollmentMutation,
    UpdateJourneyEnrollmentMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateJourneyEnrollmentMutation,
    UpdateJourneyEnrollmentMutationVariables
  >(UpdateJourneyEnrollmentDocument, options);
}
export type UpdateJourneyEnrollmentMutationHookResult = ReturnType<
  typeof useUpdateJourneyEnrollmentMutation
>;
export type UpdateJourneyEnrollmentMutationResult =
  Apollo.MutationResult<UpdateJourneyEnrollmentMutation>;
export type UpdateJourneyEnrollmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateJourneyEnrollmentMutation,
  UpdateJourneyEnrollmentMutationVariables
>;
export const AddLedgerLineItemDocument = gql`
  mutation AddLedgerLineItem($input: LedgerInsertInput!) {
    insertLedgerOne(object: $input) {
      __typename
    }
  }
`;
export type AddLedgerLineItemMutationFn = Apollo.MutationFunction<
  AddLedgerLineItemMutation,
  AddLedgerLineItemMutationVariables
>;

/**
 * __useAddLedgerLineItemMutation__
 *
 * To run a mutation, you first call `useAddLedgerLineItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLedgerLineItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLedgerLineItemMutation, { data, loading, error }] = useAddLedgerLineItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddLedgerLineItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddLedgerLineItemMutation,
    AddLedgerLineItemMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<AddLedgerLineItemMutation, AddLedgerLineItemMutationVariables>(
    AddLedgerLineItemDocument,
    options
  );
}
export type AddLedgerLineItemMutationHookResult = ReturnType<typeof useAddLedgerLineItemMutation>;
export type AddLedgerLineItemMutationResult = Apollo.MutationResult<AddLedgerLineItemMutation>;
export type AddLedgerLineItemMutationOptions = Apollo.BaseMutationOptions<
  AddLedgerLineItemMutation,
  AddLedgerLineItemMutationVariables
>;
export const AddCoachToLightningSessionSubmissionDocument = gql`
  mutation AddCoachToLightningSessionSubmission($coachUserId: uuid!, $submissionId: uuid!) {
    updateLightningSessionSubmissionsByPk(
      pkColumns: {id: $submissionId}
      _set: {coachUserId: $coachUserId}
    ) {
      id
    }
    insertLightningSessionSubmissionsUsersRelOne(
      object: {lightningSessionSubmissionId: $submissionId, userId: $coachUserId}
      onConflict: {constraint: lightning_session_submissions_users_rel_pkey, updateColumns: []}
    ) {
      lightningSessionSubmissionId
    }
  }
`;
export type AddCoachToLightningSessionSubmissionMutationFn = Apollo.MutationFunction<
  AddCoachToLightningSessionSubmissionMutation,
  AddCoachToLightningSessionSubmissionMutationVariables
>;

/**
 * __useAddCoachToLightningSessionSubmissionMutation__
 *
 * To run a mutation, you first call `useAddCoachToLightningSessionSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCoachToLightningSessionSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCoachToLightningSessionSubmissionMutation, { data, loading, error }] = useAddCoachToLightningSessionSubmissionMutation({
 *   variables: {
 *      coachUserId: // value for 'coachUserId'
 *      submissionId: // value for 'submissionId'
 *   },
 * });
 */
export function useAddCoachToLightningSessionSubmissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCoachToLightningSessionSubmissionMutation,
    AddCoachToLightningSessionSubmissionMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    AddCoachToLightningSessionSubmissionMutation,
    AddCoachToLightningSessionSubmissionMutationVariables
  >(AddCoachToLightningSessionSubmissionDocument, options);
}
export type AddCoachToLightningSessionSubmissionMutationHookResult = ReturnType<
  typeof useAddCoachToLightningSessionSubmissionMutation
>;
export type AddCoachToLightningSessionSubmissionMutationResult =
  Apollo.MutationResult<AddCoachToLightningSessionSubmissionMutation>;
export type AddCoachToLightningSessionSubmissionMutationOptions = Apollo.BaseMutationOptions<
  AddCoachToLightningSessionSubmissionMutation,
  AddCoachToLightningSessionSubmissionMutationVariables
>;
export const AddUserToLightningSessionSubmissionUsersRelDocument = gql`
  mutation AddUserToLightningSessionSubmissionUsersRel($userId: uuid!, $submissionId: uuid!) {
    insertLightningSessionSubmissionsUsersRel(
      objects: [{lightningSessionSubmissionId: $submissionId, userId: $userId}]
      onConflict: {constraint: lightning_session_submissions_users_rel_pkey, updateColumns: []}
    ) {
      affectedRows
    }
  }
`;
export type AddUserToLightningSessionSubmissionUsersRelMutationFn = Apollo.MutationFunction<
  AddUserToLightningSessionSubmissionUsersRelMutation,
  AddUserToLightningSessionSubmissionUsersRelMutationVariables
>;

/**
 * __useAddUserToLightningSessionSubmissionUsersRelMutation__
 *
 * To run a mutation, you first call `useAddUserToLightningSessionSubmissionUsersRelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToLightningSessionSubmissionUsersRelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToLightningSessionSubmissionUsersRelMutation, { data, loading, error }] = useAddUserToLightningSessionSubmissionUsersRelMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      submissionId: // value for 'submissionId'
 *   },
 * });
 */
export function useAddUserToLightningSessionSubmissionUsersRelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserToLightningSessionSubmissionUsersRelMutation,
    AddUserToLightningSessionSubmissionUsersRelMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    AddUserToLightningSessionSubmissionUsersRelMutation,
    AddUserToLightningSessionSubmissionUsersRelMutationVariables
  >(AddUserToLightningSessionSubmissionUsersRelDocument, options);
}
export type AddUserToLightningSessionSubmissionUsersRelMutationHookResult = ReturnType<
  typeof useAddUserToLightningSessionSubmissionUsersRelMutation
>;
export type AddUserToLightningSessionSubmissionUsersRelMutationResult =
  Apollo.MutationResult<AddUserToLightningSessionSubmissionUsersRelMutation>;
export type AddUserToLightningSessionSubmissionUsersRelMutationOptions = Apollo.BaseMutationOptions<
  AddUserToLightningSessionSubmissionUsersRelMutation,
  AddUserToLightningSessionSubmissionUsersRelMutationVariables
>;
export const ChangeLightningSessionSubmissionScoreDataDocument = gql`
  mutation ChangeLightningSessionSubmissionScoreData(
    $submissionId: uuid!
    $score: String
    $comment: String
  ) {
    updateLightningSessionSubmissionsByPk(
      _set: {completedAt: now, updatedAt: now, score: $score, comment: $comment}
      pkColumns: {id: $submissionId}
    ) {
      id
    }
  }
`;
export type ChangeLightningSessionSubmissionScoreDataMutationFn = Apollo.MutationFunction<
  ChangeLightningSessionSubmissionScoreDataMutation,
  ChangeLightningSessionSubmissionScoreDataMutationVariables
>;

/**
 * __useChangeLightningSessionSubmissionScoreDataMutation__
 *
 * To run a mutation, you first call `useChangeLightningSessionSubmissionScoreDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeLightningSessionSubmissionScoreDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeLightningSessionSubmissionScoreDataMutation, { data, loading, error }] = useChangeLightningSessionSubmissionScoreDataMutation({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *      score: // value for 'score'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useChangeLightningSessionSubmissionScoreDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeLightningSessionSubmissionScoreDataMutation,
    ChangeLightningSessionSubmissionScoreDataMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    ChangeLightningSessionSubmissionScoreDataMutation,
    ChangeLightningSessionSubmissionScoreDataMutationVariables
  >(ChangeLightningSessionSubmissionScoreDataDocument, options);
}
export type ChangeLightningSessionSubmissionScoreDataMutationHookResult = ReturnType<
  typeof useChangeLightningSessionSubmissionScoreDataMutation
>;
export type ChangeLightningSessionSubmissionScoreDataMutationResult =
  Apollo.MutationResult<ChangeLightningSessionSubmissionScoreDataMutation>;
export type ChangeLightningSessionSubmissionScoreDataMutationOptions = Apollo.BaseMutationOptions<
  ChangeLightningSessionSubmissionScoreDataMutation,
  ChangeLightningSessionSubmissionScoreDataMutationVariables
>;
export const ChangeLightningSessionSubmissionStatusDocument = gql`
  mutation ChangeLightningSessionSubmissionStatus(
    $submissionId: uuid!
    $status: lightningSessionSubmissionsStatusEnum!
    $interactionsCount: Int = 0
  ) {
    updateLightningSessionSubmissionsByPk(
      _set: {status: $status, interactionsCount: $interactionsCount}
      pkColumns: {id: $submissionId}
    ) {
      id
    }
  }
`;
export type ChangeLightningSessionSubmissionStatusMutationFn = Apollo.MutationFunction<
  ChangeLightningSessionSubmissionStatusMutation,
  ChangeLightningSessionSubmissionStatusMutationVariables
>;

/**
 * __useChangeLightningSessionSubmissionStatusMutation__
 *
 * To run a mutation, you first call `useChangeLightningSessionSubmissionStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeLightningSessionSubmissionStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeLightningSessionSubmissionStatusMutation, { data, loading, error }] = useChangeLightningSessionSubmissionStatusMutation({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *      status: // value for 'status'
 *      interactionsCount: // value for 'interactionsCount'
 *   },
 * });
 */
export function useChangeLightningSessionSubmissionStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeLightningSessionSubmissionStatusMutation,
    ChangeLightningSessionSubmissionStatusMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    ChangeLightningSessionSubmissionStatusMutation,
    ChangeLightningSessionSubmissionStatusMutationVariables
  >(ChangeLightningSessionSubmissionStatusDocument, options);
}
export type ChangeLightningSessionSubmissionStatusMutationHookResult = ReturnType<
  typeof useChangeLightningSessionSubmissionStatusMutation
>;
export type ChangeLightningSessionSubmissionStatusMutationResult =
  Apollo.MutationResult<ChangeLightningSessionSubmissionStatusMutation>;
export type ChangeLightningSessionSubmissionStatusMutationOptions = Apollo.BaseMutationOptions<
  ChangeLightningSessionSubmissionStatusMutation,
  ChangeLightningSessionSubmissionStatusMutationVariables
>;
export const CompleteAndScoreLightningSessionSubmissionDocument = gql`
  mutation CompleteAndScoreLightningSessionSubmission(
    $submissionId: uuid!
    $score: String
    $comment: String
  ) {
    updateLightningSessionSubmissionsByPk(
      _set: {status: COMPLETE, completedAt: now, updatedAt: now, score: $score, comment: $comment}
      pkColumns: {id: $submissionId}
    ) {
      id
    }
  }
`;
export type CompleteAndScoreLightningSessionSubmissionMutationFn = Apollo.MutationFunction<
  CompleteAndScoreLightningSessionSubmissionMutation,
  CompleteAndScoreLightningSessionSubmissionMutationVariables
>;

/**
 * __useCompleteAndScoreLightningSessionSubmissionMutation__
 *
 * To run a mutation, you first call `useCompleteAndScoreLightningSessionSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteAndScoreLightningSessionSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeAndScoreLightningSessionSubmissionMutation, { data, loading, error }] = useCompleteAndScoreLightningSessionSubmissionMutation({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *      score: // value for 'score'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCompleteAndScoreLightningSessionSubmissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteAndScoreLightningSessionSubmissionMutation,
    CompleteAndScoreLightningSessionSubmissionMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    CompleteAndScoreLightningSessionSubmissionMutation,
    CompleteAndScoreLightningSessionSubmissionMutationVariables
  >(CompleteAndScoreLightningSessionSubmissionDocument, options);
}
export type CompleteAndScoreLightningSessionSubmissionMutationHookResult = ReturnType<
  typeof useCompleteAndScoreLightningSessionSubmissionMutation
>;
export type CompleteAndScoreLightningSessionSubmissionMutationResult =
  Apollo.MutationResult<CompleteAndScoreLightningSessionSubmissionMutation>;
export type CompleteAndScoreLightningSessionSubmissionMutationOptions = Apollo.BaseMutationOptions<
  CompleteAndScoreLightningSessionSubmissionMutation,
  CompleteAndScoreLightningSessionSubmissionMutationVariables
>;
export const CompleteLightningSessionSubmissionStatusDocument = gql`
  mutation CompleteLightningSessionSubmissionStatus($submissionId: uuid!) {
    updateLightningSessionSubmissionsByPk(
      _set: {status: COMPLETE, completedAt: now, updatedAt: now}
      pkColumns: {id: $submissionId}
    ) {
      id
    }
  }
`;
export type CompleteLightningSessionSubmissionStatusMutationFn = Apollo.MutationFunction<
  CompleteLightningSessionSubmissionStatusMutation,
  CompleteLightningSessionSubmissionStatusMutationVariables
>;

/**
 * __useCompleteLightningSessionSubmissionStatusMutation__
 *
 * To run a mutation, you first call `useCompleteLightningSessionSubmissionStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteLightningSessionSubmissionStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeLightningSessionSubmissionStatusMutation, { data, loading, error }] = useCompleteLightningSessionSubmissionStatusMutation({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *   },
 * });
 */
export function useCompleteLightningSessionSubmissionStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteLightningSessionSubmissionStatusMutation,
    CompleteLightningSessionSubmissionStatusMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    CompleteLightningSessionSubmissionStatusMutation,
    CompleteLightningSessionSubmissionStatusMutationVariables
  >(CompleteLightningSessionSubmissionStatusDocument, options);
}
export type CompleteLightningSessionSubmissionStatusMutationHookResult = ReturnType<
  typeof useCompleteLightningSessionSubmissionStatusMutation
>;
export type CompleteLightningSessionSubmissionStatusMutationResult =
  Apollo.MutationResult<CompleteLightningSessionSubmissionStatusMutation>;
export type CompleteLightningSessionSubmissionStatusMutationOptions = Apollo.BaseMutationOptions<
  CompleteLightningSessionSubmissionStatusMutation,
  CompleteLightningSessionSubmissionStatusMutationVariables
>;
export const DeleteLightningSessionSubmissionDocument = gql`
  mutation DeleteLightningSessionSubmission($id: uuid!) {
    deleteLightningSessionSubmissionsUsersRel(where: {lightningSessionSubmissionId: {_eq: $id}}) {
      affectedRows
    }
    deleteLightningSessionSubmissions(where: {id: {_eq: $id}}) {
      affectedRows
    }
  }
`;
export type DeleteLightningSessionSubmissionMutationFn = Apollo.MutationFunction<
  DeleteLightningSessionSubmissionMutation,
  DeleteLightningSessionSubmissionMutationVariables
>;

/**
 * __useDeleteLightningSessionSubmissionMutation__
 *
 * To run a mutation, you first call `useDeleteLightningSessionSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLightningSessionSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLightningSessionSubmissionMutation, { data, loading, error }] = useDeleteLightningSessionSubmissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLightningSessionSubmissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteLightningSessionSubmissionMutation,
    DeleteLightningSessionSubmissionMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    DeleteLightningSessionSubmissionMutation,
    DeleteLightningSessionSubmissionMutationVariables
  >(DeleteLightningSessionSubmissionDocument, options);
}
export type DeleteLightningSessionSubmissionMutationHookResult = ReturnType<
  typeof useDeleteLightningSessionSubmissionMutation
>;
export type DeleteLightningSessionSubmissionMutationResult =
  Apollo.MutationResult<DeleteLightningSessionSubmissionMutation>;
export type DeleteLightningSessionSubmissionMutationOptions = Apollo.BaseMutationOptions<
  DeleteLightningSessionSubmissionMutation,
  DeleteLightningSessionSubmissionMutationVariables
>;
export const GetLightningSessionSubmissionByIdDocument = gql`
  query GetLightningSessionSubmissionById($id: uuid!) {
    lightningSessionSubmissionsByPk(id: $id) {
      id
      userId
      status
      streamChannelId
      cohortId
      coachUserId
      user {
        id
        firstName
        lastName
        fullName
        identity {
          email
        }
      }
      coach {
        id
        organizationUsers {
          id
          user {
            id
            firstName
            lastName
            fullName
            identity {
              email
            }
          }
        }
      }
      cohort {
        id
        slug
        course {
          id
          type
        }
      }
    }
  }
`;

/**
 * __useGetLightningSessionSubmissionByIdQuery__
 *
 * To run a query within a React component, call `useGetLightningSessionSubmissionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLightningSessionSubmissionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLightningSessionSubmissionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLightningSessionSubmissionByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLightningSessionSubmissionByIdQuery,
    GetLightningSessionSubmissionByIdQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetLightningSessionSubmissionByIdQuery,
    GetLightningSessionSubmissionByIdQueryVariables
  >(GetLightningSessionSubmissionByIdDocument, options);
}
export function useGetLightningSessionSubmissionByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLightningSessionSubmissionByIdQuery,
    GetLightningSessionSubmissionByIdQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetLightningSessionSubmissionByIdQuery,
    GetLightningSessionSubmissionByIdQueryVariables
  >(GetLightningSessionSubmissionByIdDocument, options);
}
export type GetLightningSessionSubmissionByIdQueryHookResult = ReturnType<
  typeof useGetLightningSessionSubmissionByIdQuery
>;
export type GetLightningSessionSubmissionByIdLazyQueryHookResult = ReturnType<
  typeof useGetLightningSessionSubmissionByIdLazyQuery
>;
export type GetLightningSessionSubmissionByIdQueryResult = Apollo.QueryResult<
  GetLightningSessionSubmissionByIdQuery,
  GetLightningSessionSubmissionByIdQueryVariables
>;
export const GetLightningSessionSubmissionByUserAndCohortIdsDocument = gql`
  query GetLightningSessionSubmissionByUserAndCohortIds($userId: uuid!, $cohortId: uuid!) {
    lightningSessionSubmissions(
      where: {userId: {_eq: $userId}, cohortId: {_eq: $cohortId}, deletedAt: {_isNull: true}}
    ) {
      id
      userId
      status
      streamChannelId
      cohortId
      coachUserId
      cohort {
        communitySlackChannelUrl
        communitySlackInviteUrl
        courseId
        id
        name
        slug
        surveyLinkUrl
        type
        isPostCourseSurveyAvailable
        preworkRichText
        preworkVideoUrl
        requiresPractice
        startDate
        endDate
        classTimeOfDay
        course {
          id
          type
          name
          slug
          preCourseSurveyFormId
          postCourseSurveyFormId
          impactAssessment {
            id
          }
        }
      }
      pitchSegment {
        startTimecode
        endTimecode
        createdAt
        classEventMeeting {
          recordingUrl
        }
        videoClipUrl
      }
      user {
        id
        firstName
        lastName
        imageUrl
      }
    }
  }
`;

/**
 * __useGetLightningSessionSubmissionByUserAndCohortIdsQuery__
 *
 * To run a query within a React component, call `useGetLightningSessionSubmissionByUserAndCohortIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLightningSessionSubmissionByUserAndCohortIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLightningSessionSubmissionByUserAndCohortIdsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      cohortId: // value for 'cohortId'
 *   },
 * });
 */
export function useGetLightningSessionSubmissionByUserAndCohortIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLightningSessionSubmissionByUserAndCohortIdsQuery,
    GetLightningSessionSubmissionByUserAndCohortIdsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetLightningSessionSubmissionByUserAndCohortIdsQuery,
    GetLightningSessionSubmissionByUserAndCohortIdsQueryVariables
  >(GetLightningSessionSubmissionByUserAndCohortIdsDocument, options);
}
export function useGetLightningSessionSubmissionByUserAndCohortIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLightningSessionSubmissionByUserAndCohortIdsQuery,
    GetLightningSessionSubmissionByUserAndCohortIdsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetLightningSessionSubmissionByUserAndCohortIdsQuery,
    GetLightningSessionSubmissionByUserAndCohortIdsQueryVariables
  >(GetLightningSessionSubmissionByUserAndCohortIdsDocument, options);
}
export type GetLightningSessionSubmissionByUserAndCohortIdsQueryHookResult = ReturnType<
  typeof useGetLightningSessionSubmissionByUserAndCohortIdsQuery
>;
export type GetLightningSessionSubmissionByUserAndCohortIdsLazyQueryHookResult = ReturnType<
  typeof useGetLightningSessionSubmissionByUserAndCohortIdsLazyQuery
>;
export type GetLightningSessionSubmissionByUserAndCohortIdsQueryResult = Apollo.QueryResult<
  GetLightningSessionSubmissionByUserAndCohortIdsQuery,
  GetLightningSessionSubmissionByUserAndCohortIdsQueryVariables
>;
export const SoftDeleteLightningSessionSubmissionDocument = gql`
  mutation SoftDeleteLightningSessionSubmission(
    $id: uuid!
    $deletedAt: timestamp
    $deletedBy: uuid
  ) {
    updateLightningSessionSubmissionsByPk(
      _set: {deletedAt: $deletedAt, deletedById: $deletedBy}
      pkColumns: {id: $id}
    ) {
      id
    }
  }
`;
export type SoftDeleteLightningSessionSubmissionMutationFn = Apollo.MutationFunction<
  SoftDeleteLightningSessionSubmissionMutation,
  SoftDeleteLightningSessionSubmissionMutationVariables
>;

/**
 * __useSoftDeleteLightningSessionSubmissionMutation__
 *
 * To run a mutation, you first call `useSoftDeleteLightningSessionSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftDeleteLightningSessionSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softDeleteLightningSessionSubmissionMutation, { data, loading, error }] = useSoftDeleteLightningSessionSubmissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deletedAt: // value for 'deletedAt'
 *      deletedBy: // value for 'deletedBy'
 *   },
 * });
 */
export function useSoftDeleteLightningSessionSubmissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SoftDeleteLightningSessionSubmissionMutation,
    SoftDeleteLightningSessionSubmissionMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    SoftDeleteLightningSessionSubmissionMutation,
    SoftDeleteLightningSessionSubmissionMutationVariables
  >(SoftDeleteLightningSessionSubmissionDocument, options);
}
export type SoftDeleteLightningSessionSubmissionMutationHookResult = ReturnType<
  typeof useSoftDeleteLightningSessionSubmissionMutation
>;
export type SoftDeleteLightningSessionSubmissionMutationResult =
  Apollo.MutationResult<SoftDeleteLightningSessionSubmissionMutation>;
export type SoftDeleteLightningSessionSubmissionMutationOptions = Apollo.BaseMutationOptions<
  SoftDeleteLightningSessionSubmissionMutation,
  SoftDeleteLightningSessionSubmissionMutationVariables
>;
export const UpdateLightningSessionSubmissionStreamChannelIdDocument = gql`
  mutation UpdateLightningSessionSubmissionStreamChannelId($id: uuid!, $channelId: String) {
    updateLightningSessionSubmissionsByPk(
      _set: {streamChannelId: $channelId}
      pkColumns: {id: $id}
    ) {
      __typename
    }
  }
`;
export type UpdateLightningSessionSubmissionStreamChannelIdMutationFn = Apollo.MutationFunction<
  UpdateLightningSessionSubmissionStreamChannelIdMutation,
  UpdateLightningSessionSubmissionStreamChannelIdMutationVariables
>;

/**
 * __useUpdateLightningSessionSubmissionStreamChannelIdMutation__
 *
 * To run a mutation, you first call `useUpdateLightningSessionSubmissionStreamChannelIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLightningSessionSubmissionStreamChannelIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLightningSessionSubmissionStreamChannelIdMutation, { data, loading, error }] = useUpdateLightningSessionSubmissionStreamChannelIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useUpdateLightningSessionSubmissionStreamChannelIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLightningSessionSubmissionStreamChannelIdMutation,
    UpdateLightningSessionSubmissionStreamChannelIdMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateLightningSessionSubmissionStreamChannelIdMutation,
    UpdateLightningSessionSubmissionStreamChannelIdMutationVariables
  >(UpdateLightningSessionSubmissionStreamChannelIdDocument, options);
}
export type UpdateLightningSessionSubmissionStreamChannelIdMutationHookResult = ReturnType<
  typeof useUpdateLightningSessionSubmissionStreamChannelIdMutation
>;
export type UpdateLightningSessionSubmissionStreamChannelIdMutationResult =
  Apollo.MutationResult<UpdateLightningSessionSubmissionStreamChannelIdMutation>;
export type UpdateLightningSessionSubmissionStreamChannelIdMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateLightningSessionSubmissionStreamChannelIdMutation,
    UpdateLightningSessionSubmissionStreamChannelIdMutationVariables
  >;
export const UpdateLightningSubmissionEmailFlagsDocument = gql`
  mutation UpdateLightningSubmissionEmailFlags(
    $id: uuid!
    $flags: LightningSessionSubmissionsSetInput
  ) {
    updateLightningSessionSubmissions(where: {id: {_eq: $id}}, _set: $flags) {
      affectedRows
    }
  }
`;
export type UpdateLightningSubmissionEmailFlagsMutationFn = Apollo.MutationFunction<
  UpdateLightningSubmissionEmailFlagsMutation,
  UpdateLightningSubmissionEmailFlagsMutationVariables
>;

/**
 * __useUpdateLightningSubmissionEmailFlagsMutation__
 *
 * To run a mutation, you first call `useUpdateLightningSubmissionEmailFlagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLightningSubmissionEmailFlagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLightningSubmissionEmailFlagsMutation, { data, loading, error }] = useUpdateLightningSubmissionEmailFlagsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      flags: // value for 'flags'
 *   },
 * });
 */
export function useUpdateLightningSubmissionEmailFlagsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLightningSubmissionEmailFlagsMutation,
    UpdateLightningSubmissionEmailFlagsMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateLightningSubmissionEmailFlagsMutation,
    UpdateLightningSubmissionEmailFlagsMutationVariables
  >(UpdateLightningSubmissionEmailFlagsDocument, options);
}
export type UpdateLightningSubmissionEmailFlagsMutationHookResult = ReturnType<
  typeof useUpdateLightningSubmissionEmailFlagsMutation
>;
export type UpdateLightningSubmissionEmailFlagsMutationResult =
  Apollo.MutationResult<UpdateLightningSubmissionEmailFlagsMutation>;
export type UpdateLightningSubmissionEmailFlagsMutationOptions = Apollo.BaseMutationOptions<
  UpdateLightningSubmissionEmailFlagsMutation,
  UpdateLightningSubmissionEmailFlagsMutationVariables
>;
export const UpsertLightningSessionSubmissionDocument = gql`
  mutation UpsertLightningSessionSubmission($object: LightningSessionSubmissionsInsertInput!) {
    insertLightningSessionSubmissionsOne(
      object: $object
      onConflict: {
        constraint: lightning_session_submissions_cohort_id_user_id_deleted_at_key
        updateColumns: []
      }
    ) {
      id
    }
  }
`;
export type UpsertLightningSessionSubmissionMutationFn = Apollo.MutationFunction<
  UpsertLightningSessionSubmissionMutation,
  UpsertLightningSessionSubmissionMutationVariables
>;

/**
 * __useUpsertLightningSessionSubmissionMutation__
 *
 * To run a mutation, you first call `useUpsertLightningSessionSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertLightningSessionSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertLightningSessionSubmissionMutation, { data, loading, error }] = useUpsertLightningSessionSubmissionMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpsertLightningSessionSubmissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertLightningSessionSubmissionMutation,
    UpsertLightningSessionSubmissionMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpsertLightningSessionSubmissionMutation,
    UpsertLightningSessionSubmissionMutationVariables
  >(UpsertLightningSessionSubmissionDocument, options);
}
export type UpsertLightningSessionSubmissionMutationHookResult = ReturnType<
  typeof useUpsertLightningSessionSubmissionMutation
>;
export type UpsertLightningSessionSubmissionMutationResult =
  Apollo.MutationResult<UpsertLightningSessionSubmissionMutation>;
export type UpsertLightningSessionSubmissionMutationOptions = Apollo.BaseMutationOptions<
  UpsertLightningSessionSubmissionMutation,
  UpsertLightningSessionSubmissionMutationVariables
>;
export const GetNonceAccessTokenDocument = gql`
  query GetNonceAccessToken($token: String!) {
    nonceAccessToken(where: {token: {_eq: $token}}) {
      id
      token
      createdAt
      expirationDate
      targetResource
    }
  }
`;

/**
 * __useGetNonceAccessTokenQuery__
 *
 * To run a query within a React component, call `useGetNonceAccessTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNonceAccessTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNonceAccessTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetNonceAccessTokenQuery(
  baseOptions: Apollo.QueryHookOptions<GetNonceAccessTokenQuery, GetNonceAccessTokenQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetNonceAccessTokenQuery, GetNonceAccessTokenQueryVariables>(
    GetNonceAccessTokenDocument,
    options
  );
}
export function useGetNonceAccessTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNonceAccessTokenQuery,
    GetNonceAccessTokenQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetNonceAccessTokenQuery, GetNonceAccessTokenQueryVariables>(
    GetNonceAccessTokenDocument,
    options
  );
}
export type GetNonceAccessTokenQueryHookResult = ReturnType<typeof useGetNonceAccessTokenQuery>;
export type GetNonceAccessTokenLazyQueryHookResult = ReturnType<
  typeof useGetNonceAccessTokenLazyQuery
>;
export type GetNonceAccessTokenQueryResult = Apollo.QueryResult<
  GetNonceAccessTokenQuery,
  GetNonceAccessTokenQueryVariables
>;
export const InsertNonceAccessTokenDocument = gql`
  mutation InsertNonceAccessToken($object: NonceAccessTokenInsertInput!) {
    insertNonceAccessTokenOne(object: $object) {
      id
      token
    }
  }
`;
export type InsertNonceAccessTokenMutationFn = Apollo.MutationFunction<
  InsertNonceAccessTokenMutation,
  InsertNonceAccessTokenMutationVariables
>;

/**
 * __useInsertNonceAccessTokenMutation__
 *
 * To run a mutation, you first call `useInsertNonceAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertNonceAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertNonceAccessTokenMutation, { data, loading, error }] = useInsertNonceAccessTokenMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertNonceAccessTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertNonceAccessTokenMutation,
    InsertNonceAccessTokenMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    InsertNonceAccessTokenMutation,
    InsertNonceAccessTokenMutationVariables
  >(InsertNonceAccessTokenDocument, options);
}
export type InsertNonceAccessTokenMutationHookResult = ReturnType<
  typeof useInsertNonceAccessTokenMutation
>;
export type InsertNonceAccessTokenMutationResult =
  Apollo.MutationResult<InsertNonceAccessTokenMutation>;
export type InsertNonceAccessTokenMutationOptions = Apollo.BaseMutationOptions<
  InsertNonceAccessTokenMutation,
  InsertNonceAccessTokenMutationVariables
>;
export const FindRootTeamDocument = gql`
  query FindRootTeam($organizationId: uuid) {
    teams(
      where: {_and: [{name: {_ilike: "all members"}}, {organizationId: {_eq: $organizationId}}]}
      limit: 1
    ) {
      id
      organization {
        id
        name
      }
    }
  }
`;

/**
 * __useFindRootTeamQuery__
 *
 * To run a query within a React component, call `useFindRootTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindRootTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindRootTeamQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useFindRootTeamQuery(
  baseOptions?: Apollo.QueryHookOptions<FindRootTeamQuery, FindRootTeamQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<FindRootTeamQuery, FindRootTeamQueryVariables>(
    FindRootTeamDocument,
    options
  );
}
export function useFindRootTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindRootTeamQuery, FindRootTeamQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<FindRootTeamQuery, FindRootTeamQueryVariables>(
    FindRootTeamDocument,
    options
  );
}
export type FindRootTeamQueryHookResult = ReturnType<typeof useFindRootTeamQuery>;
export type FindRootTeamLazyQueryHookResult = ReturnType<typeof useFindRootTeamLazyQuery>;
export type FindRootTeamQueryResult = Apollo.QueryResult<
  FindRootTeamQuery,
  FindRootTeamQueryVariables
>;
export const SetOrganizationMetaDocument = gql`
  mutation SetOrganizationMeta($object: OrganizationsMetaInsertInput!) {
    insertOrganizationsMetaOne(
      object: $object
      onConflict: {constraint: organizations_meta_pk, updateColumns: [salesforceAccountId]}
    ) {
      salesforceAccountId
    }
  }
`;
export type SetOrganizationMetaMutationFn = Apollo.MutationFunction<
  SetOrganizationMetaMutation,
  SetOrganizationMetaMutationVariables
>;

/**
 * __useSetOrganizationMetaMutation__
 *
 * To run a mutation, you first call `useSetOrganizationMetaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOrganizationMetaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOrganizationMetaMutation, { data, loading, error }] = useSetOrganizationMetaMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useSetOrganizationMetaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetOrganizationMetaMutation,
    SetOrganizationMetaMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<SetOrganizationMetaMutation, SetOrganizationMetaMutationVariables>(
    SetOrganizationMetaDocument,
    options
  );
}
export type SetOrganizationMetaMutationHookResult = ReturnType<
  typeof useSetOrganizationMetaMutation
>;
export type SetOrganizationMetaMutationResult = Apollo.MutationResult<SetOrganizationMetaMutation>;
export type SetOrganizationMetaMutationOptions = Apollo.BaseMutationOptions<
  SetOrganizationMetaMutation,
  SetOrganizationMetaMutationVariables
>;
export const CreateSubscriptionDocument = gql`
  mutation CreateSubscription(
    $organizationId: uuid!
    $planId: uuid!
    $notes: String
    $createdById: uuid!
  ) {
    insertPlanSubscriptionsOne(
      object: {
        organizationId: $organizationId
        planId: $planId
        notes: $notes
        createdById: $createdById
      }
    ) {
      id
    }
  }
`;
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<
  CreateSubscriptionMutation,
  CreateSubscriptionMutationVariables
>;

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      planId: // value for 'planId'
 *      notes: // value for 'notes'
 *      createdById: // value for 'createdById'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSubscriptionMutation,
    CreateSubscriptionMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(
    CreateSubscriptionDocument,
    options
  );
}
export type CreateSubscriptionMutationHookResult = ReturnType<typeof useCreateSubscriptionMutation>;
export type CreateSubscriptionMutationResult = Apollo.MutationResult<CreateSubscriptionMutation>;
export type CreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CreateSubscriptionMutation,
  CreateSubscriptionMutationVariables
>;
export const AddPlayExecutionDocument = gql`
  mutation AddPlayExecution($object: PlayExecutionInsertInput!) {
    insertPlayExecutionOne(object: $object) {
      id
    }
  }
`;
export type AddPlayExecutionMutationFn = Apollo.MutationFunction<
  AddPlayExecutionMutation,
  AddPlayExecutionMutationVariables
>;

/**
 * __useAddPlayExecutionMutation__
 *
 * To run a mutation, you first call `useAddPlayExecutionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlayExecutionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlayExecutionMutation, { data, loading, error }] = useAddPlayExecutionMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useAddPlayExecutionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPlayExecutionMutation,
    AddPlayExecutionMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<AddPlayExecutionMutation, AddPlayExecutionMutationVariables>(
    AddPlayExecutionDocument,
    options
  );
}
export type AddPlayExecutionMutationHookResult = ReturnType<typeof useAddPlayExecutionMutation>;
export type AddPlayExecutionMutationResult = Apollo.MutationResult<AddPlayExecutionMutation>;
export type AddPlayExecutionMutationOptions = Apollo.BaseMutationOptions<
  AddPlayExecutionMutation,
  AddPlayExecutionMutationVariables
>;
export const RunPlayDocument = gql`
  mutation RunPlay($playName: String!, $data: jsonb) {
    RunPlay(playName: $playName, data: $data) {
      success
    }
  }
`;
export type RunPlayMutationFn = Apollo.MutationFunction<RunPlayMutation, RunPlayMutationVariables>;

/**
 * __useRunPlayMutation__
 *
 * To run a mutation, you first call `useRunPlayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunPlayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runPlayMutation, { data, loading, error }] = useRunPlayMutation({
 *   variables: {
 *      playName: // value for 'playName'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRunPlayMutation(
  baseOptions?: Apollo.MutationHookOptions<RunPlayMutation, RunPlayMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<RunPlayMutation, RunPlayMutationVariables>(RunPlayDocument, options);
}
export type RunPlayMutationHookResult = ReturnType<typeof useRunPlayMutation>;
export type RunPlayMutationResult = Apollo.MutationResult<RunPlayMutation>;
export type RunPlayMutationOptions = Apollo.BaseMutationOptions<
  RunPlayMutation,
  RunPlayMutationVariables
>;
export const PlayAddClassEventToCohortDocument = gql`
  mutation PlayAddClassEventToCohort($object: ClassEventsInsertInput!) {
    insertClassEventsOne(object: $object) {
      id
    }
  }
`;
export type PlayAddClassEventToCohortMutationFn = Apollo.MutationFunction<
  PlayAddClassEventToCohortMutation,
  PlayAddClassEventToCohortMutationVariables
>;

/**
 * __usePlayAddClassEventToCohortMutation__
 *
 * To run a mutation, you first call `usePlayAddClassEventToCohortMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlayAddClassEventToCohortMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playAddClassEventToCohortMutation, { data, loading, error }] = usePlayAddClassEventToCohortMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function usePlayAddClassEventToCohortMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PlayAddClassEventToCohortMutation,
    PlayAddClassEventToCohortMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    PlayAddClassEventToCohortMutation,
    PlayAddClassEventToCohortMutationVariables
  >(PlayAddClassEventToCohortDocument, options);
}
export type PlayAddClassEventToCohortMutationHookResult = ReturnType<
  typeof usePlayAddClassEventToCohortMutation
>;
export type PlayAddClassEventToCohortMutationResult =
  Apollo.MutationResult<PlayAddClassEventToCohortMutation>;
export type PlayAddClassEventToCohortMutationOptions = Apollo.BaseMutationOptions<
  PlayAddClassEventToCohortMutation,
  PlayAddClassEventToCohortMutationVariables
>;
export const PlayAddCoachToCohortDocument = gql`
  mutation PlayAddCoachToCohort($objects: [CohortCoachesInsertInput!]!) {
    insertCohortCoaches(objects: $objects) {
      affectedRows
    }
  }
`;
export type PlayAddCoachToCohortMutationFn = Apollo.MutationFunction<
  PlayAddCoachToCohortMutation,
  PlayAddCoachToCohortMutationVariables
>;

/**
 * __usePlayAddCoachToCohortMutation__
 *
 * To run a mutation, you first call `usePlayAddCoachToCohortMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlayAddCoachToCohortMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playAddCoachToCohortMutation, { data, loading, error }] = usePlayAddCoachToCohortMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function usePlayAddCoachToCohortMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PlayAddCoachToCohortMutation,
    PlayAddCoachToCohortMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<PlayAddCoachToCohortMutation, PlayAddCoachToCohortMutationVariables>(
    PlayAddCoachToCohortDocument,
    options
  );
}
export type PlayAddCoachToCohortMutationHookResult = ReturnType<
  typeof usePlayAddCoachToCohortMutation
>;
export type PlayAddCoachToCohortMutationResult =
  Apollo.MutationResult<PlayAddCoachToCohortMutation>;
export type PlayAddCoachToCohortMutationOptions = Apollo.BaseMutationOptions<
  PlayAddCoachToCohortMutation,
  PlayAddCoachToCohortMutationVariables
>;
export const PlayAddCourseToCatalogDocument = gql`
  mutation PlayAddCourseToCatalog($courseId: uuid!, $catalogId: uuid!) {
    insertCatalogCoursesOne(object: {catalogId: $catalogId, courseId: $courseId}) {
      courseId
      catalogId
    }
  }
`;
export type PlayAddCourseToCatalogMutationFn = Apollo.MutationFunction<
  PlayAddCourseToCatalogMutation,
  PlayAddCourseToCatalogMutationVariables
>;

/**
 * __usePlayAddCourseToCatalogMutation__
 *
 * To run a mutation, you first call `usePlayAddCourseToCatalogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlayAddCourseToCatalogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playAddCourseToCatalogMutation, { data, loading, error }] = usePlayAddCourseToCatalogMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      catalogId: // value for 'catalogId'
 *   },
 * });
 */
export function usePlayAddCourseToCatalogMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PlayAddCourseToCatalogMutation,
    PlayAddCourseToCatalogMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    PlayAddCourseToCatalogMutation,
    PlayAddCourseToCatalogMutationVariables
  >(PlayAddCourseToCatalogDocument, options);
}
export type PlayAddCourseToCatalogMutationHookResult = ReturnType<
  typeof usePlayAddCourseToCatalogMutation
>;
export type PlayAddCourseToCatalogMutationResult =
  Apollo.MutationResult<PlayAddCourseToCatalogMutation>;
export type PlayAddCourseToCatalogMutationOptions = Apollo.BaseMutationOptions<
  PlayAddCourseToCatalogMutation,
  PlayAddCourseToCatalogMutationVariables
>;
export const PlayAddCourseToTagDocument = gql`
  mutation PlayAddCourseToTag($courseId: uuid!, $tagId: uuid!, $courseSlug: String!) {
    insertCoursesTagsRelOne(object: {tagId: $tagId, courseId: $courseId, courseSlug: $courseSlug}) {
      courseId
      tagId
    }
  }
`;
export type PlayAddCourseToTagMutationFn = Apollo.MutationFunction<
  PlayAddCourseToTagMutation,
  PlayAddCourseToTagMutationVariables
>;

/**
 * __usePlayAddCourseToTagMutation__
 *
 * To run a mutation, you first call `usePlayAddCourseToTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlayAddCourseToTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playAddCourseToTagMutation, { data, loading, error }] = usePlayAddCourseToTagMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      tagId: // value for 'tagId'
 *      courseSlug: // value for 'courseSlug'
 *   },
 * });
 */
export function usePlayAddCourseToTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PlayAddCourseToTagMutation,
    PlayAddCourseToTagMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<PlayAddCourseToTagMutation, PlayAddCourseToTagMutationVariables>(
    PlayAddCourseToTagDocument,
    options
  );
}
export type PlayAddCourseToTagMutationHookResult = ReturnType<typeof usePlayAddCourseToTagMutation>;
export type PlayAddCourseToTagMutationResult = Apollo.MutationResult<PlayAddCourseToTagMutation>;
export type PlayAddCourseToTagMutationOptions = Apollo.BaseMutationOptions<
  PlayAddCourseToTagMutation,
  PlayAddCourseToTagMutationVariables
>;
export const PlayCreateClassDocument = gql`
  mutation PlayCreateClass(
    $name: String!
    $slug: String!
    $number: Int!
    $duration: Int!
    $courseId: uuid!
    $desc: String!
    $listDescription: json!
  ) {
    insertClassesOne(
      object: {
        name: $name
        slug: $slug
        number: $number
        durationInMinutes: $duration
        courseId: $courseId
        isLive: true
        descriptionRich: $desc
        listDescription: $listDescription
      }
    ) {
      id
      name
    }
  }
`;
export type PlayCreateClassMutationFn = Apollo.MutationFunction<
  PlayCreateClassMutation,
  PlayCreateClassMutationVariables
>;

/**
 * __usePlayCreateClassMutation__
 *
 * To run a mutation, you first call `usePlayCreateClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlayCreateClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playCreateClassMutation, { data, loading, error }] = usePlayCreateClassMutation({
 *   variables: {
 *      name: // value for 'name'
 *      slug: // value for 'slug'
 *      number: // value for 'number'
 *      duration: // value for 'duration'
 *      courseId: // value for 'courseId'
 *      desc: // value for 'desc'
 *      listDescription: // value for 'listDescription'
 *   },
 * });
 */
export function usePlayCreateClassMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PlayCreateClassMutation,
    PlayCreateClassMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<PlayCreateClassMutation, PlayCreateClassMutationVariables>(
    PlayCreateClassDocument,
    options
  );
}
export type PlayCreateClassMutationHookResult = ReturnType<typeof usePlayCreateClassMutation>;
export type PlayCreateClassMutationResult = Apollo.MutationResult<PlayCreateClassMutation>;
export type PlayCreateClassMutationOptions = Apollo.BaseMutationOptions<
  PlayCreateClassMutation,
  PlayCreateClassMutationVariables
>;
export const PlayCreateCoachDocument = gql`
  mutation PlayCreateCoach($coach: CoachesInsertInput!) {
    insertCoachesOne(object: $coach) {
      id
    }
  }
`;
export type PlayCreateCoachMutationFn = Apollo.MutationFunction<
  PlayCreateCoachMutation,
  PlayCreateCoachMutationVariables
>;

/**
 * __usePlayCreateCoachMutation__
 *
 * To run a mutation, you first call `usePlayCreateCoachMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlayCreateCoachMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playCreateCoachMutation, { data, loading, error }] = usePlayCreateCoachMutation({
 *   variables: {
 *      coach: // value for 'coach'
 *   },
 * });
 */
export function usePlayCreateCoachMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PlayCreateCoachMutation,
    PlayCreateCoachMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<PlayCreateCoachMutation, PlayCreateCoachMutationVariables>(
    PlayCreateCoachDocument,
    options
  );
}
export type PlayCreateCoachMutationHookResult = ReturnType<typeof usePlayCreateCoachMutation>;
export type PlayCreateCoachMutationResult = Apollo.MutationResult<PlayCreateCoachMutation>;
export type PlayCreateCoachMutationOptions = Apollo.BaseMutationOptions<
  PlayCreateCoachMutation,
  PlayCreateCoachMutationVariables
>;
export const PlayCreateCohortDocument = gql`
  mutation PlayCreateCohort($cohort: CohortsInsertInput!) {
    insertCohortsOne(object: $cohort) {
      id
      slug
    }
  }
`;
export type PlayCreateCohortMutationFn = Apollo.MutationFunction<
  PlayCreateCohortMutation,
  PlayCreateCohortMutationVariables
>;

/**
 * __usePlayCreateCohortMutation__
 *
 * To run a mutation, you first call `usePlayCreateCohortMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlayCreateCohortMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playCreateCohortMutation, { data, loading, error }] = usePlayCreateCohortMutation({
 *   variables: {
 *      cohort: // value for 'cohort'
 *   },
 * });
 */
export function usePlayCreateCohortMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PlayCreateCohortMutation,
    PlayCreateCohortMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<PlayCreateCohortMutation, PlayCreateCohortMutationVariables>(
    PlayCreateCohortDocument,
    options
  );
}
export type PlayCreateCohortMutationHookResult = ReturnType<typeof usePlayCreateCohortMutation>;
export type PlayCreateCohortMutationResult = Apollo.MutationResult<PlayCreateCohortMutation>;
export type PlayCreateCohortMutationOptions = Apollo.BaseMutationOptions<
  PlayCreateCohortMutation,
  PlayCreateCohortMutationVariables
>;
export const PlayCreateCourseDocument = gql`
  mutation PlayCreateCourse($course: CoursesInsertInput!) {
    insertCoursesOne(object: $course) {
      id
      slug
    }
  }
`;
export type PlayCreateCourseMutationFn = Apollo.MutationFunction<
  PlayCreateCourseMutation,
  PlayCreateCourseMutationVariables
>;

/**
 * __usePlayCreateCourseMutation__
 *
 * To run a mutation, you first call `usePlayCreateCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlayCreateCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playCreateCourseMutation, { data, loading, error }] = usePlayCreateCourseMutation({
 *   variables: {
 *      course: // value for 'course'
 *   },
 * });
 */
export function usePlayCreateCourseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PlayCreateCourseMutation,
    PlayCreateCourseMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<PlayCreateCourseMutation, PlayCreateCourseMutationVariables>(
    PlayCreateCourseDocument,
    options
  );
}
export type PlayCreateCourseMutationHookResult = ReturnType<typeof usePlayCreateCourseMutation>;
export type PlayCreateCourseMutationResult = Apollo.MutationResult<PlayCreateCourseMutation>;
export type PlayCreateCourseMutationOptions = Apollo.BaseMutationOptions<
  PlayCreateCourseMutation,
  PlayCreateCourseMutationVariables
>;
export const PlayCreateOrgDocument = gql`
  mutation PlayCreateOrg(
    $name: String!
    $planId: String!
    $salesforceAccountId: String!
    $subscriptionNotes: String!
  ) {
    CreateOrganization(
      name: $name
      planId: $planId
      salesforceAccountId: $salesforceAccountId
      subscriptionNotes: $subscriptionNotes
      skipSnowflakeCreation: true
    ) {
      id
    }
  }
`;
export type PlayCreateOrgMutationFn = Apollo.MutationFunction<
  PlayCreateOrgMutation,
  PlayCreateOrgMutationVariables
>;

/**
 * __usePlayCreateOrgMutation__
 *
 * To run a mutation, you first call `usePlayCreateOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlayCreateOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playCreateOrgMutation, { data, loading, error }] = usePlayCreateOrgMutation({
 *   variables: {
 *      name: // value for 'name'
 *      planId: // value for 'planId'
 *      salesforceAccountId: // value for 'salesforceAccountId'
 *      subscriptionNotes: // value for 'subscriptionNotes'
 *   },
 * });
 */
export function usePlayCreateOrgMutation(
  baseOptions?: Apollo.MutationHookOptions<PlayCreateOrgMutation, PlayCreateOrgMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<PlayCreateOrgMutation, PlayCreateOrgMutationVariables>(
    PlayCreateOrgDocument,
    options
  );
}
export type PlayCreateOrgMutationHookResult = ReturnType<typeof usePlayCreateOrgMutation>;
export type PlayCreateOrgMutationResult = Apollo.MutationResult<PlayCreateOrgMutation>;
export type PlayCreateOrgMutationOptions = Apollo.BaseMutationOptions<
  PlayCreateOrgMutation,
  PlayCreateOrgMutationVariables
>;
export const PlayCreatePlanDocument = gql`
  mutation PlayCreatePlan($plan: PlansInsertInput!) {
    insertPlansOne(object: $plan) {
      id
      slug
      name
      policy
    }
  }
`;
export type PlayCreatePlanMutationFn = Apollo.MutationFunction<
  PlayCreatePlanMutation,
  PlayCreatePlanMutationVariables
>;

/**
 * __usePlayCreatePlanMutation__
 *
 * To run a mutation, you first call `usePlayCreatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlayCreatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playCreatePlanMutation, { data, loading, error }] = usePlayCreatePlanMutation({
 *   variables: {
 *      plan: // value for 'plan'
 *   },
 * });
 */
export function usePlayCreatePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<PlayCreatePlanMutation, PlayCreatePlanMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<PlayCreatePlanMutation, PlayCreatePlanMutationVariables>(
    PlayCreatePlanDocument,
    options
  );
}
export type PlayCreatePlanMutationHookResult = ReturnType<typeof usePlayCreatePlanMutation>;
export type PlayCreatePlanMutationResult = Apollo.MutationResult<PlayCreatePlanMutation>;
export type PlayCreatePlanMutationOptions = Apollo.BaseMutationOptions<
  PlayCreatePlanMutation,
  PlayCreatePlanMutationVariables
>;
export const PlayCreateSchoolDocument = gql`
  mutation PlayCreateSchool($slug: String!, $name: String!, $createdById: uuid!) {
    insertSchoolsOne(
      object: {slug: $slug, name: $name, createdById: $createdById}
      onConflict: {constraint: schools_slug_key, updateColumns: []}
    ) {
      id
    }
  }
`;
export type PlayCreateSchoolMutationFn = Apollo.MutationFunction<
  PlayCreateSchoolMutation,
  PlayCreateSchoolMutationVariables
>;

/**
 * __usePlayCreateSchoolMutation__
 *
 * To run a mutation, you first call `usePlayCreateSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlayCreateSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playCreateSchoolMutation, { data, loading, error }] = usePlayCreateSchoolMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      name: // value for 'name'
 *      createdById: // value for 'createdById'
 *   },
 * });
 */
export function usePlayCreateSchoolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PlayCreateSchoolMutation,
    PlayCreateSchoolMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<PlayCreateSchoolMutation, PlayCreateSchoolMutationVariables>(
    PlayCreateSchoolDocument,
    options
  );
}
export type PlayCreateSchoolMutationHookResult = ReturnType<typeof usePlayCreateSchoolMutation>;
export type PlayCreateSchoolMutationResult = Apollo.MutationResult<PlayCreateSchoolMutation>;
export type PlayCreateSchoolMutationOptions = Apollo.BaseMutationOptions<
  PlayCreateSchoolMutation,
  PlayCreateSchoolMutationVariables
>;
export const PlayCreateTagDocument = gql`
  mutation PlayCreateTag($slug: String!, $name: String!, $createdById: uuid!) {
    insertTagsOne(object: {slug: $slug, name: $name, createdById: $createdById}) {
      id
    }
  }
`;
export type PlayCreateTagMutationFn = Apollo.MutationFunction<
  PlayCreateTagMutation,
  PlayCreateTagMutationVariables
>;

/**
 * __usePlayCreateTagMutation__
 *
 * To run a mutation, you first call `usePlayCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlayCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playCreateTagMutation, { data, loading, error }] = usePlayCreateTagMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      name: // value for 'name'
 *      createdById: // value for 'createdById'
 *   },
 * });
 */
export function usePlayCreateTagMutation(
  baseOptions?: Apollo.MutationHookOptions<PlayCreateTagMutation, PlayCreateTagMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<PlayCreateTagMutation, PlayCreateTagMutationVariables>(
    PlayCreateTagDocument,
    options
  );
}
export type PlayCreateTagMutationHookResult = ReturnType<typeof usePlayCreateTagMutation>;
export type PlayCreateTagMutationResult = Apollo.MutationResult<PlayCreateTagMutation>;
export type PlayCreateTagMutationOptions = Apollo.BaseMutationOptions<
  PlayCreateTagMutation,
  PlayCreateTagMutationVariables
>;
export const PlayCreateTeamDocument = gql`
  mutation PlayCreateTeam($team: TeamsInsertInput!) {
    insertTeamsOne(object: $team) {
      id
    }
  }
`;
export type PlayCreateTeamMutationFn = Apollo.MutationFunction<
  PlayCreateTeamMutation,
  PlayCreateTeamMutationVariables
>;

/**
 * __usePlayCreateTeamMutation__
 *
 * To run a mutation, you first call `usePlayCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlayCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playCreateTeamMutation, { data, loading, error }] = usePlayCreateTeamMutation({
 *   variables: {
 *      team: // value for 'team'
 *   },
 * });
 */
export function usePlayCreateTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<PlayCreateTeamMutation, PlayCreateTeamMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<PlayCreateTeamMutation, PlayCreateTeamMutationVariables>(
    PlayCreateTeamDocument,
    options
  );
}
export type PlayCreateTeamMutationHookResult = ReturnType<typeof usePlayCreateTeamMutation>;
export type PlayCreateTeamMutationResult = Apollo.MutationResult<PlayCreateTeamMutation>;
export type PlayCreateTeamMutationOptions = Apollo.BaseMutationOptions<
  PlayCreateTeamMutation,
  PlayCreateTeamMutationVariables
>;
export const PlayCreateUserDocument = gql`
  mutation PlayCreateUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $organizationId: String
    $salesforceAccountId: String
    $planId: String
    $subscriptionNotes: String
    $teamId: String
    $seatType: String
  ) {
    CreateUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      organizationId: $organizationId
      salesforceAccountId: $salesforceAccountId
      planId: $planId
      subscriptionNotes: $subscriptionNotes
      teamId: $teamId
      seatType: $seatType
    ) {
      id
    }
  }
`;
export type PlayCreateUserMutationFn = Apollo.MutationFunction<
  PlayCreateUserMutation,
  PlayCreateUserMutationVariables
>;

/**
 * __usePlayCreateUserMutation__
 *
 * To run a mutation, you first call `usePlayCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlayCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playCreateUserMutation, { data, loading, error }] = usePlayCreateUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      organizationId: // value for 'organizationId'
 *      salesforceAccountId: // value for 'salesforceAccountId'
 *      planId: // value for 'planId'
 *      subscriptionNotes: // value for 'subscriptionNotes'
 *      teamId: // value for 'teamId'
 *      seatType: // value for 'seatType'
 *   },
 * });
 */
export function usePlayCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<PlayCreateUserMutation, PlayCreateUserMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<PlayCreateUserMutation, PlayCreateUserMutationVariables>(
    PlayCreateUserDocument,
    options
  );
}
export type PlayCreateUserMutationHookResult = ReturnType<typeof usePlayCreateUserMutation>;
export type PlayCreateUserMutationResult = Apollo.MutationResult<PlayCreateUserMutation>;
export type PlayCreateUserMutationOptions = Apollo.BaseMutationOptions<
  PlayCreateUserMutation,
  PlayCreateUserMutationVariables
>;
export const PlayEnrollLearnersDocument = gql`
  mutation PlayEnrollLearners($objects: [EnrollmentsInsertInput!]!) {
    insertEnrollments(objects: $objects) {
      affectedRows
    }
  }
`;
export type PlayEnrollLearnersMutationFn = Apollo.MutationFunction<
  PlayEnrollLearnersMutation,
  PlayEnrollLearnersMutationVariables
>;

/**
 * __usePlayEnrollLearnersMutation__
 *
 * To run a mutation, you first call `usePlayEnrollLearnersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlayEnrollLearnersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playEnrollLearnersMutation, { data, loading, error }] = usePlayEnrollLearnersMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function usePlayEnrollLearnersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PlayEnrollLearnersMutation,
    PlayEnrollLearnersMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<PlayEnrollLearnersMutation, PlayEnrollLearnersMutationVariables>(
    PlayEnrollLearnersDocument,
    options
  );
}
export type PlayEnrollLearnersMutationHookResult = ReturnType<typeof usePlayEnrollLearnersMutation>;
export type PlayEnrollLearnersMutationResult = Apollo.MutationResult<PlayEnrollLearnersMutation>;
export type PlayEnrollLearnersMutationOptions = Apollo.BaseMutationOptions<
  PlayEnrollLearnersMutation,
  PlayEnrollLearnersMutationVariables
>;
export const PlayInsertLsSubmissionUserRelDocument = gql`
  mutation PlayInsertLSSubmissionUserRel($object: LightningSessionSubmissionsUsersRelInsertInput!) {
    insertLightningSessionSubmissionsUsersRelOne(object: $object) {
      userId
      lightningSessionSubmissionId
    }
  }
`;
export type PlayInsertLsSubmissionUserRelMutationFn = Apollo.MutationFunction<
  PlayInsertLsSubmissionUserRelMutation,
  PlayInsertLsSubmissionUserRelMutationVariables
>;

/**
 * __usePlayInsertLsSubmissionUserRelMutation__
 *
 * To run a mutation, you first call `usePlayInsertLsSubmissionUserRelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlayInsertLsSubmissionUserRelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playInsertLsSubmissionUserRelMutation, { data, loading, error }] = usePlayInsertLsSubmissionUserRelMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function usePlayInsertLsSubmissionUserRelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PlayInsertLsSubmissionUserRelMutation,
    PlayInsertLsSubmissionUserRelMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    PlayInsertLsSubmissionUserRelMutation,
    PlayInsertLsSubmissionUserRelMutationVariables
  >(PlayInsertLsSubmissionUserRelDocument, options);
}
export type PlayInsertLsSubmissionUserRelMutationHookResult = ReturnType<
  typeof usePlayInsertLsSubmissionUserRelMutation
>;
export type PlayInsertLsSubmissionUserRelMutationResult =
  Apollo.MutationResult<PlayInsertLsSubmissionUserRelMutation>;
export type PlayInsertLsSubmissionUserRelMutationOptions = Apollo.BaseMutationOptions<
  PlayInsertLsSubmissionUserRelMutation,
  PlayInsertLsSubmissionUserRelMutationVariables
>;
export const PlayMakeUserManagerDocument = gql`
  mutation PlayMakeUserManager($teamId: uuid!, $userId: uuid!) {
    updateTeamsUsersByPk(pkColumns: {teamId: $teamId, userId: $userId}, _set: {role: "manager"}) {
      userId
      teamId
      role
    }
  }
`;
export type PlayMakeUserManagerMutationFn = Apollo.MutationFunction<
  PlayMakeUserManagerMutation,
  PlayMakeUserManagerMutationVariables
>;

/**
 * __usePlayMakeUserManagerMutation__
 *
 * To run a mutation, you first call `usePlayMakeUserManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlayMakeUserManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playMakeUserManagerMutation, { data, loading, error }] = usePlayMakeUserManagerMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function usePlayMakeUserManagerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PlayMakeUserManagerMutation,
    PlayMakeUserManagerMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<PlayMakeUserManagerMutation, PlayMakeUserManagerMutationVariables>(
    PlayMakeUserManagerDocument,
    options
  );
}
export type PlayMakeUserManagerMutationHookResult = ReturnType<
  typeof usePlayMakeUserManagerMutation
>;
export type PlayMakeUserManagerMutationResult = Apollo.MutationResult<PlayMakeUserManagerMutation>;
export type PlayMakeUserManagerMutationOptions = Apollo.BaseMutationOptions<
  PlayMakeUserManagerMutation,
  PlayMakeUserManagerMutationVariables
>;
export const PlayPublishCourseDocument = gql`
  mutation PlayPublishCourse($slug: String!, $reason: String!, $userId: uuid!) {
    publishCourse(args: {course_slug: $slug, reason: $reason, user_id: $userId}) {
      id
      version
    }
  }
`;
export type PlayPublishCourseMutationFn = Apollo.MutationFunction<
  PlayPublishCourseMutation,
  PlayPublishCourseMutationVariables
>;

/**
 * __usePlayPublishCourseMutation__
 *
 * To run a mutation, you first call `usePlayPublishCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlayPublishCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playPublishCourseMutation, { data, loading, error }] = usePlayPublishCourseMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      reason: // value for 'reason'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function usePlayPublishCourseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PlayPublishCourseMutation,
    PlayPublishCourseMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<PlayPublishCourseMutation, PlayPublishCourseMutationVariables>(
    PlayPublishCourseDocument,
    options
  );
}
export type PlayPublishCourseMutationHookResult = ReturnType<typeof usePlayPublishCourseMutation>;
export type PlayPublishCourseMutationResult = Apollo.MutationResult<PlayPublishCourseMutation>;
export type PlayPublishCourseMutationOptions = Apollo.BaseMutationOptions<
  PlayPublishCourseMutation,
  PlayPublishCourseMutationVariables
>;
export const PlayUpsertLsSubmissionDocument = gql`
  mutation PlayUpsertLSSubmission($object: LightningSessionSubmissionsInsertInput!) {
    insertLightningSessionSubmissionsOne(
      object: $object
      onConflict: {
        constraint: lightning_session_submissions_cohort_id_user_id_deleted_at_key
        updateColumns: [userId, coachUserId, status, streamChannelId, cohortId]
      }
    ) {
      id
    }
  }
`;
export type PlayUpsertLsSubmissionMutationFn = Apollo.MutationFunction<
  PlayUpsertLsSubmissionMutation,
  PlayUpsertLsSubmissionMutationVariables
>;

/**
 * __usePlayUpsertLsSubmissionMutation__
 *
 * To run a mutation, you first call `usePlayUpsertLsSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlayUpsertLsSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playUpsertLsSubmissionMutation, { data, loading, error }] = usePlayUpsertLsSubmissionMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function usePlayUpsertLsSubmissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PlayUpsertLsSubmissionMutation,
    PlayUpsertLsSubmissionMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    PlayUpsertLsSubmissionMutation,
    PlayUpsertLsSubmissionMutationVariables
  >(PlayUpsertLsSubmissionDocument, options);
}
export type PlayUpsertLsSubmissionMutationHookResult = ReturnType<
  typeof usePlayUpsertLsSubmissionMutation
>;
export type PlayUpsertLsSubmissionMutationResult =
  Apollo.MutationResult<PlayUpsertLsSubmissionMutation>;
export type PlayUpsertLsSubmissionMutationOptions = Apollo.BaseMutationOptions<
  PlayUpsertLsSubmissionMutation,
  PlayUpsertLsSubmissionMutationVariables
>;
export const CreateProgramDocument = gql`
  mutation CreateProgram($input: ProgramsInsertInput!) {
    insertProgramsOne(object: $input) {
      id
    }
  }
`;
export type CreateProgramMutationFn = Apollo.MutationFunction<
  CreateProgramMutation,
  CreateProgramMutationVariables
>;

/**
 * __useCreateProgramMutation__
 *
 * To run a mutation, you first call `useCreateProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgramMutation, { data, loading, error }] = useCreateProgramMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProgramMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProgramMutation, CreateProgramMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<CreateProgramMutation, CreateProgramMutationVariables>(
    CreateProgramDocument,
    options
  );
}
export type CreateProgramMutationHookResult = ReturnType<typeof useCreateProgramMutation>;
export type CreateProgramMutationResult = Apollo.MutationResult<CreateProgramMutation>;
export type CreateProgramMutationOptions = Apollo.BaseMutationOptions<
  CreateProgramMutation,
  CreateProgramMutationVariables
>;
export const CreateRecordingDocument = gql`
  mutation CreateRecording($input: RecordingsInsertInput!) {
    insertRecordingsOne(object: $input) {
      id
      hash
    }
  }
`;
export type CreateRecordingMutationFn = Apollo.MutationFunction<
  CreateRecordingMutation,
  CreateRecordingMutationVariables
>;

/**
 * __useCreateRecordingMutation__
 *
 * To run a mutation, you first call `useCreateRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecordingMutation, { data, loading, error }] = useCreateRecordingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRecordingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRecordingMutation,
    CreateRecordingMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<CreateRecordingMutation, CreateRecordingMutationVariables>(
    CreateRecordingDocument,
    options
  );
}
export type CreateRecordingMutationHookResult = ReturnType<typeof useCreateRecordingMutation>;
export type CreateRecordingMutationResult = Apollo.MutationResult<CreateRecordingMutation>;
export type CreateRecordingMutationOptions = Apollo.BaseMutationOptions<
  CreateRecordingMutation,
  CreateRecordingMutationVariables
>;
export const CreateRecordingFromClientDocument = gql`
  mutation CreateRecordingFromClient(
    $hash: String!
    $type: String!
    $title: String
    $description: String
    $public: Boolean
    $lightningSubmissionId: String
  ) {
    CreateRecording(
      hash: $hash
      type: $type
      title: $title
      description: $description
      public: $public
      lightningSubmissionId: $lightningSubmissionId
    ) {
      success
      details
    }
  }
`;
export type CreateRecordingFromClientMutationFn = Apollo.MutationFunction<
  CreateRecordingFromClientMutation,
  CreateRecordingFromClientMutationVariables
>;

/**
 * __useCreateRecordingFromClientMutation__
 *
 * To run a mutation, you first call `useCreateRecordingFromClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecordingFromClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecordingFromClientMutation, { data, loading, error }] = useCreateRecordingFromClientMutation({
 *   variables: {
 *      hash: // value for 'hash'
 *      type: // value for 'type'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      public: // value for 'public'
 *      lightningSubmissionId: // value for 'lightningSubmissionId'
 *   },
 * });
 */
export function useCreateRecordingFromClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRecordingFromClientMutation,
    CreateRecordingFromClientMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    CreateRecordingFromClientMutation,
    CreateRecordingFromClientMutationVariables
  >(CreateRecordingFromClientDocument, options);
}
export type CreateRecordingFromClientMutationHookResult = ReturnType<
  typeof useCreateRecordingFromClientMutation
>;
export type CreateRecordingFromClientMutationResult =
  Apollo.MutationResult<CreateRecordingFromClientMutation>;
export type CreateRecordingFromClientMutationOptions = Apollo.BaseMutationOptions<
  CreateRecordingFromClientMutation,
  CreateRecordingFromClientMutationVariables
>;
export const DeleteRecordingDocument = gql`
  mutation DeleteRecording($lightningSubmissionId: uuid!, $hash: String!) {
    deleteRecordings(
      where: {lightningSubmissionId: {_eq: $lightningSubmissionId}, hash: {_eq: $hash}}
    ) {
      affectedRows
    }
  }
`;
export type DeleteRecordingMutationFn = Apollo.MutationFunction<
  DeleteRecordingMutation,
  DeleteRecordingMutationVariables
>;

/**
 * __useDeleteRecordingMutation__
 *
 * To run a mutation, you first call `useDeleteRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRecordingMutation, { data, loading, error }] = useDeleteRecordingMutation({
 *   variables: {
 *      lightningSubmissionId: // value for 'lightningSubmissionId'
 *      hash: // value for 'hash'
 *   },
 * });
 */
export function useDeleteRecordingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRecordingMutation,
    DeleteRecordingMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<DeleteRecordingMutation, DeleteRecordingMutationVariables>(
    DeleteRecordingDocument,
    options
  );
}
export type DeleteRecordingMutationHookResult = ReturnType<typeof useDeleteRecordingMutation>;
export type DeleteRecordingMutationResult = Apollo.MutationResult<DeleteRecordingMutation>;
export type DeleteRecordingMutationOptions = Apollo.BaseMutationOptions<
  DeleteRecordingMutation,
  DeleteRecordingMutationVariables
>;
export const GetRecordingActivityDocument = gql`
  query GetRecordingActivity($userId: uuid!, $hash: String!, $type: [String!]) {
    recordingActivity(
      where: {userId: {_eq: $userId}, hash: {_eq: $hash}, type: {_in: $type}}
      orderBy: {createdAt: DESC}
    ) {
      id
      userId
      hash
      context
      createdAt
      updatedAt
      type
    }
  }
`;

/**
 * __useGetRecordingActivityQuery__
 *
 * To run a query within a React component, call `useGetRecordingActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecordingActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecordingActivityQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      hash: // value for 'hash'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetRecordingActivityQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRecordingActivityQuery,
    GetRecordingActivityQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetRecordingActivityQuery, GetRecordingActivityQueryVariables>(
    GetRecordingActivityDocument,
    options
  );
}
export function useGetRecordingActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecordingActivityQuery,
    GetRecordingActivityQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetRecordingActivityQuery, GetRecordingActivityQueryVariables>(
    GetRecordingActivityDocument,
    options
  );
}
export type GetRecordingActivityQueryHookResult = ReturnType<typeof useGetRecordingActivityQuery>;
export type GetRecordingActivityLazyQueryHookResult = ReturnType<
  typeof useGetRecordingActivityLazyQuery
>;
export type GetRecordingActivityQueryResult = Apollo.QueryResult<
  GetRecordingActivityQuery,
  GetRecordingActivityQueryVariables
>;
export const MarkRecordingWatchedDocument = gql`
  mutation MarkRecordingWatched($hash: String!) {
    MarkRecordingWatched(hash: $hash) {
      done
    }
  }
`;
export type MarkRecordingWatchedMutationFn = Apollo.MutationFunction<
  MarkRecordingWatchedMutation,
  MarkRecordingWatchedMutationVariables
>;

/**
 * __useMarkRecordingWatchedMutation__
 *
 * To run a mutation, you first call `useMarkRecordingWatchedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkRecordingWatchedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markRecordingWatchedMutation, { data, loading, error }] = useMarkRecordingWatchedMutation({
 *   variables: {
 *      hash: // value for 'hash'
 *   },
 * });
 */
export function useMarkRecordingWatchedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkRecordingWatchedMutation,
    MarkRecordingWatchedMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<MarkRecordingWatchedMutation, MarkRecordingWatchedMutationVariables>(
    MarkRecordingWatchedDocument,
    options
  );
}
export type MarkRecordingWatchedMutationHookResult = ReturnType<
  typeof useMarkRecordingWatchedMutation
>;
export type MarkRecordingWatchedMutationResult =
  Apollo.MutationResult<MarkRecordingWatchedMutation>;
export type MarkRecordingWatchedMutationOptions = Apollo.BaseMutationOptions<
  MarkRecordingWatchedMutation,
  MarkRecordingWatchedMutationVariables
>;
export const SignUploadFromClientDocument = gql`
  mutation SignUploadFromClient($s3path: String!) {
    SignUpload(s3path: $s3path) {
      success
      details
    }
  }
`;
export type SignUploadFromClientMutationFn = Apollo.MutationFunction<
  SignUploadFromClientMutation,
  SignUploadFromClientMutationVariables
>;

/**
 * __useSignUploadFromClientMutation__
 *
 * To run a mutation, you first call `useSignUploadFromClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUploadFromClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUploadFromClientMutation, { data, loading, error }] = useSignUploadFromClientMutation({
 *   variables: {
 *      s3path: // value for 's3path'
 *   },
 * });
 */
export function useSignUploadFromClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUploadFromClientMutation,
    SignUploadFromClientMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<SignUploadFromClientMutation, SignUploadFromClientMutationVariables>(
    SignUploadFromClientDocument,
    options
  );
}
export type SignUploadFromClientMutationHookResult = ReturnType<
  typeof useSignUploadFromClientMutation
>;
export type SignUploadFromClientMutationResult =
  Apollo.MutationResult<SignUploadFromClientMutation>;
export type SignUploadFromClientMutationOptions = Apollo.BaseMutationOptions<
  SignUploadFromClientMutation,
  SignUploadFromClientMutationVariables
>;
export const StartPlaybackDocument = gql`
  mutation StartPlayback($input: StartPlaybackInput!) {
    StartPlayback(input: $input) {
      success
    }
  }
`;
export type StartPlaybackMutationFn = Apollo.MutationFunction<
  StartPlaybackMutation,
  StartPlaybackMutationVariables
>;

/**
 * __useStartPlaybackMutation__
 *
 * To run a mutation, you first call `useStartPlaybackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartPlaybackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startPlaybackMutation, { data, loading, error }] = useStartPlaybackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartPlaybackMutation(
  baseOptions?: Apollo.MutationHookOptions<StartPlaybackMutation, StartPlaybackMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<StartPlaybackMutation, StartPlaybackMutationVariables>(
    StartPlaybackDocument,
    options
  );
}
export type StartPlaybackMutationHookResult = ReturnType<typeof useStartPlaybackMutation>;
export type StartPlaybackMutationResult = Apollo.MutationResult<StartPlaybackMutation>;
export type StartPlaybackMutationOptions = Apollo.BaseMutationOptions<
  StartPlaybackMutation,
  StartPlaybackMutationVariables
>;
export const UpdatePlaybackDocument = gql`
  mutation UpdatePlayback($input: UpdatePlaybackInput!) {
    UpdatePlayback(input: $input) {
      success
    }
  }
`;
export type UpdatePlaybackMutationFn = Apollo.MutationFunction<
  UpdatePlaybackMutation,
  UpdatePlaybackMutationVariables
>;

/**
 * __useUpdatePlaybackMutation__
 *
 * To run a mutation, you first call `useUpdatePlaybackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlaybackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlaybackMutation, { data, loading, error }] = useUpdatePlaybackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlaybackMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePlaybackMutation, UpdatePlaybackMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<UpdatePlaybackMutation, UpdatePlaybackMutationVariables>(
    UpdatePlaybackDocument,
    options
  );
}
export type UpdatePlaybackMutationHookResult = ReturnType<typeof useUpdatePlaybackMutation>;
export type UpdatePlaybackMutationResult = Apollo.MutationResult<UpdatePlaybackMutation>;
export type UpdatePlaybackMutationOptions = Apollo.BaseMutationOptions<
  UpdatePlaybackMutation,
  UpdatePlaybackMutationVariables
>;
export const UpdateRecordingDocument = gql`
  mutation UpdateRecording(
    $id: uuid!
    $publicUrl: String
    $meta: jsonb
    $transcription: jsonb
    $summary: jsonb
    $synced: Boolean
  ) {
    updateRecordingsByPk(
      pkColumns: {id: $id}
      _set: {
        publicUrl: $publicUrl
        meta: $meta
        transcription: $transcription
        synced: $synced
        summary: $summary
      }
    ) {
      id
      publicUrl
      meta
      transcription
      summary
      synced
    }
  }
`;
export type UpdateRecordingMutationFn = Apollo.MutationFunction<
  UpdateRecordingMutation,
  UpdateRecordingMutationVariables
>;

/**
 * __useUpdateRecordingMutation__
 *
 * To run a mutation, you first call `useUpdateRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecordingMutation, { data, loading, error }] = useUpdateRecordingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      publicUrl: // value for 'publicUrl'
 *      meta: // value for 'meta'
 *      transcription: // value for 'transcription'
 *      summary: // value for 'summary'
 *      synced: // value for 'synced'
 *   },
 * });
 */
export function useUpdateRecordingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRecordingMutation,
    UpdateRecordingMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<UpdateRecordingMutation, UpdateRecordingMutationVariables>(
    UpdateRecordingDocument,
    options
  );
}
export type UpdateRecordingMutationHookResult = ReturnType<typeof useUpdateRecordingMutation>;
export type UpdateRecordingMutationResult = Apollo.MutationResult<UpdateRecordingMutation>;
export type UpdateRecordingMutationOptions = Apollo.BaseMutationOptions<
  UpdateRecordingMutation,
  UpdateRecordingMutationVariables
>;
export const UpdateRecordingSentStatusDocument = gql`
  mutation UpdateRecordingSentStatus(
    $hash: String!
    $lightningSubmissionId: uuid!
    $status: Boolean!
  ) {
    updateRecordings(
      where: {hash: {_eq: $hash}, lightningSubmissionId: {_eq: $lightningSubmissionId}}
      _set: {sentToChannel: $status}
    ) {
      affectedRows
    }
  }
`;
export type UpdateRecordingSentStatusMutationFn = Apollo.MutationFunction<
  UpdateRecordingSentStatusMutation,
  UpdateRecordingSentStatusMutationVariables
>;

/**
 * __useUpdateRecordingSentStatusMutation__
 *
 * To run a mutation, you first call `useUpdateRecordingSentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecordingSentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecordingSentStatusMutation, { data, loading, error }] = useUpdateRecordingSentStatusMutation({
 *   variables: {
 *      hash: // value for 'hash'
 *      lightningSubmissionId: // value for 'lightningSubmissionId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateRecordingSentStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRecordingSentStatusMutation,
    UpdateRecordingSentStatusMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateRecordingSentStatusMutation,
    UpdateRecordingSentStatusMutationVariables
  >(UpdateRecordingSentStatusDocument, options);
}
export type UpdateRecordingSentStatusMutationHookResult = ReturnType<
  typeof useUpdateRecordingSentStatusMutation
>;
export type UpdateRecordingSentStatusMutationResult =
  Apollo.MutationResult<UpdateRecordingSentStatusMutation>;
export type UpdateRecordingSentStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateRecordingSentStatusMutation,
  UpdateRecordingSentStatusMutationVariables
>;
export const UpsertRecordingActivityDocument = gql`
  mutation UpsertRecordingActivity($object: RecordingActivityInsertInput!) {
    insertRecordingActivityOne(
      object: $object
      onConflict: {
        constraint: recording_activity_hash_user_id_type_key
        updateColumns: [context, updatedAt]
      }
    ) {
      id
    }
  }
`;
export type UpsertRecordingActivityMutationFn = Apollo.MutationFunction<
  UpsertRecordingActivityMutation,
  UpsertRecordingActivityMutationVariables
>;

/**
 * __useUpsertRecordingActivityMutation__
 *
 * To run a mutation, you first call `useUpsertRecordingActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertRecordingActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertRecordingActivityMutation, { data, loading, error }] = useUpsertRecordingActivityMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpsertRecordingActivityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertRecordingActivityMutation,
    UpsertRecordingActivityMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpsertRecordingActivityMutation,
    UpsertRecordingActivityMutationVariables
  >(UpsertRecordingActivityDocument, options);
}
export type UpsertRecordingActivityMutationHookResult = ReturnType<
  typeof useUpsertRecordingActivityMutation
>;
export type UpsertRecordingActivityMutationResult =
  Apollo.MutationResult<UpsertRecordingActivityMutation>;
export type UpsertRecordingActivityMutationOptions = Apollo.BaseMutationOptions<
  UpsertRecordingActivityMutation,
  UpsertRecordingActivityMutationVariables
>;
export const DeleteStreamMessageDocument = gql`
  mutation deleteStreamMessage($messageId: String) {
    deleteStreamMessages(where: {messageId: {_eq: $messageId}}) {
      affectedRows
    }
  }
`;
export type DeleteStreamMessageMutationFn = Apollo.MutationFunction<
  DeleteStreamMessageMutation,
  DeleteStreamMessageMutationVariables
>;

/**
 * __useDeleteStreamMessageMutation__
 *
 * To run a mutation, you first call `useDeleteStreamMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStreamMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStreamMessageMutation, { data, loading, error }] = useDeleteStreamMessageMutation({
 *   variables: {
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useDeleteStreamMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteStreamMessageMutation,
    DeleteStreamMessageMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<DeleteStreamMessageMutation, DeleteStreamMessageMutationVariables>(
    DeleteStreamMessageDocument,
    options
  );
}
export type DeleteStreamMessageMutationHookResult = ReturnType<
  typeof useDeleteStreamMessageMutation
>;
export type DeleteStreamMessageMutationResult = Apollo.MutationResult<DeleteStreamMessageMutation>;
export type DeleteStreamMessageMutationOptions = Apollo.BaseMutationOptions<
  DeleteStreamMessageMutation,
  DeleteStreamMessageMutationVariables
>;
export const InsertStreamMessageDocument = gql`
  mutation InsertStreamMessage($object: StreamMessagesInsertInput!) {
    insertStreamMessagesOne(object: $object) {
      id: id
    }
  }
`;
export type InsertStreamMessageMutationFn = Apollo.MutationFunction<
  InsertStreamMessageMutation,
  InsertStreamMessageMutationVariables
>;

/**
 * __useInsertStreamMessageMutation__
 *
 * To run a mutation, you first call `useInsertStreamMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertStreamMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertStreamMessageMutation, { data, loading, error }] = useInsertStreamMessageMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertStreamMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertStreamMessageMutation,
    InsertStreamMessageMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<InsertStreamMessageMutation, InsertStreamMessageMutationVariables>(
    InsertStreamMessageDocument,
    options
  );
}
export type InsertStreamMessageMutationHookResult = ReturnType<
  typeof useInsertStreamMessageMutation
>;
export type InsertStreamMessageMutationResult = Apollo.MutationResult<InsertStreamMessageMutation>;
export type InsertStreamMessageMutationOptions = Apollo.BaseMutationOptions<
  InsertStreamMessageMutation,
  InsertStreamMessageMutationVariables
>;
export const UpdateStreamMessageDocument = gql`
  mutation updateStreamMessage($messageId: String, $object: StreamMessagesSetInput) {
    updateStreamMessages(_set: $object, where: {messageId: {_eq: $messageId}}) {
      affectedRows
    }
  }
`;
export type UpdateStreamMessageMutationFn = Apollo.MutationFunction<
  UpdateStreamMessageMutation,
  UpdateStreamMessageMutationVariables
>;

/**
 * __useUpdateStreamMessageMutation__
 *
 * To run a mutation, you first call `useUpdateStreamMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStreamMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStreamMessageMutation, { data, loading, error }] = useUpdateStreamMessageMutation({
 *   variables: {
 *      messageId: // value for 'messageId'
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpdateStreamMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateStreamMessageMutation,
    UpdateStreamMessageMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<UpdateStreamMessageMutation, UpdateStreamMessageMutationVariables>(
    UpdateStreamMessageDocument,
    options
  );
}
export type UpdateStreamMessageMutationHookResult = ReturnType<
  typeof useUpdateStreamMessageMutation
>;
export type UpdateStreamMessageMutationResult = Apollo.MutationResult<UpdateStreamMessageMutation>;
export type UpdateStreamMessageMutationOptions = Apollo.BaseMutationOptions<
  UpdateStreamMessageMutation,
  UpdateStreamMessageMutationVariables
>;
export const AddMembersToTeamActionDocument = gql`
  mutation AddMembersToTeamAction($teamId: uuid!, $userIds: [uuid!]!) {
    AddMembersToTeam(teamId: $teamId, userIds: $userIds) {
      success
    }
  }
`;
export type AddMembersToTeamActionMutationFn = Apollo.MutationFunction<
  AddMembersToTeamActionMutation,
  AddMembersToTeamActionMutationVariables
>;

/**
 * __useAddMembersToTeamActionMutation__
 *
 * To run a mutation, you first call `useAddMembersToTeamActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMembersToTeamActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMembersToTeamActionMutation, { data, loading, error }] = useAddMembersToTeamActionMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useAddMembersToTeamActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddMembersToTeamActionMutation,
    AddMembersToTeamActionMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    AddMembersToTeamActionMutation,
    AddMembersToTeamActionMutationVariables
  >(AddMembersToTeamActionDocument, options);
}
export type AddMembersToTeamActionMutationHookResult = ReturnType<
  typeof useAddMembersToTeamActionMutation
>;
export type AddMembersToTeamActionMutationResult =
  Apollo.MutationResult<AddMembersToTeamActionMutation>;
export type AddMembersToTeamActionMutationOptions = Apollo.BaseMutationOptions<
  AddMembersToTeamActionMutation,
  AddMembersToTeamActionMutationVariables
>;
export const AddUserToTeamDocument = gql`
  mutation AddUserToTeam($rel: TeamsUsersInsertInput!) {
    insertTeamsUsersOne(object: $rel) {
      __typename
    }
  }
`;
export type AddUserToTeamMutationFn = Apollo.MutationFunction<
  AddUserToTeamMutation,
  AddUserToTeamMutationVariables
>;

/**
 * __useAddUserToTeamMutation__
 *
 * To run a mutation, you first call `useAddUserToTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToTeamMutation, { data, loading, error }] = useAddUserToTeamMutation({
 *   variables: {
 *      rel: // value for 'rel'
 *   },
 * });
 */
export function useAddUserToTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<AddUserToTeamMutation, AddUserToTeamMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<AddUserToTeamMutation, AddUserToTeamMutationVariables>(
    AddUserToTeamDocument,
    options
  );
}
export type AddUserToTeamMutationHookResult = ReturnType<typeof useAddUserToTeamMutation>;
export type AddUserToTeamMutationResult = Apollo.MutationResult<AddUserToTeamMutation>;
export type AddUserToTeamMutationOptions = Apollo.BaseMutationOptions<
  AddUserToTeamMutation,
  AddUserToTeamMutationVariables
>;
export const AddUsersToTeamDocument = gql`
  mutation AddUsersToTeam($objects: [TeamsUsersInsertInput!]!) {
    insertTeamsUsers(objects: $objects) {
      affectedRows
    }
  }
`;
export type AddUsersToTeamMutationFn = Apollo.MutationFunction<
  AddUsersToTeamMutation,
  AddUsersToTeamMutationVariables
>;

/**
 * __useAddUsersToTeamMutation__
 *
 * To run a mutation, you first call `useAddUsersToTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUsersToTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUsersToTeamMutation, { data, loading, error }] = useAddUsersToTeamMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddUsersToTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<AddUsersToTeamMutation, AddUsersToTeamMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<AddUsersToTeamMutation, AddUsersToTeamMutationVariables>(
    AddUsersToTeamDocument,
    options
  );
}
export type AddUsersToTeamMutationHookResult = ReturnType<typeof useAddUsersToTeamMutation>;
export type AddUsersToTeamMutationResult = Apollo.MutationResult<AddUsersToTeamMutation>;
export type AddUsersToTeamMutationOptions = Apollo.BaseMutationOptions<
  AddUsersToTeamMutation,
  AddUsersToTeamMutationVariables
>;
export const ChangeTeamMemberRoleDocument = gql`
  mutation ChangeTeamMemberRole(
    $userId: uuid!
    $teamId: uuid!
    $role: String!
    $updatedById: uuid!
    $updatedAt: timestamp!
  ) {
    updateTeamsUsersByPk(
      pkColumns: {userId: $userId, teamId: $teamId}
      _set: {role: $role, updatedById: $updatedById, updatedAt: $updatedAt}
    ) {
      userId
    }
  }
`;
export type ChangeTeamMemberRoleMutationFn = Apollo.MutationFunction<
  ChangeTeamMemberRoleMutation,
  ChangeTeamMemberRoleMutationVariables
>;

/**
 * __useChangeTeamMemberRoleMutation__
 *
 * To run a mutation, you first call `useChangeTeamMemberRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTeamMemberRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTeamMemberRoleMutation, { data, loading, error }] = useChangeTeamMemberRoleMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      teamId: // value for 'teamId'
 *      role: // value for 'role'
 *      updatedById: // value for 'updatedById'
 *      updatedAt: // value for 'updatedAt'
 *   },
 * });
 */
export function useChangeTeamMemberRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeTeamMemberRoleMutation,
    ChangeTeamMemberRoleMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<ChangeTeamMemberRoleMutation, ChangeTeamMemberRoleMutationVariables>(
    ChangeTeamMemberRoleDocument,
    options
  );
}
export type ChangeTeamMemberRoleMutationHookResult = ReturnType<
  typeof useChangeTeamMemberRoleMutation
>;
export type ChangeTeamMemberRoleMutationResult =
  Apollo.MutationResult<ChangeTeamMemberRoleMutation>;
export type ChangeTeamMemberRoleMutationOptions = Apollo.BaseMutationOptions<
  ChangeTeamMemberRoleMutation,
  ChangeTeamMemberRoleMutationVariables
>;
export const ChangeTeamMemberRoleActionDocument = gql`
  mutation ChangeTeamMemberRoleAction($userId: uuid!, $teamId: uuid!, $role: String!) {
    ChangeTeamMemberRole(userId: $userId, teamId: $teamId, role: $role) {
      success
    }
  }
`;
export type ChangeTeamMemberRoleActionMutationFn = Apollo.MutationFunction<
  ChangeTeamMemberRoleActionMutation,
  ChangeTeamMemberRoleActionMutationVariables
>;

/**
 * __useChangeTeamMemberRoleActionMutation__
 *
 * To run a mutation, you first call `useChangeTeamMemberRoleActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTeamMemberRoleActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTeamMemberRoleActionMutation, { data, loading, error }] = useChangeTeamMemberRoleActionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      teamId: // value for 'teamId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useChangeTeamMemberRoleActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeTeamMemberRoleActionMutation,
    ChangeTeamMemberRoleActionMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    ChangeTeamMemberRoleActionMutation,
    ChangeTeamMemberRoleActionMutationVariables
  >(ChangeTeamMemberRoleActionDocument, options);
}
export type ChangeTeamMemberRoleActionMutationHookResult = ReturnType<
  typeof useChangeTeamMemberRoleActionMutation
>;
export type ChangeTeamMemberRoleActionMutationResult =
  Apollo.MutationResult<ChangeTeamMemberRoleActionMutation>;
export type ChangeTeamMemberRoleActionMutationOptions = Apollo.BaseMutationOptions<
  ChangeTeamMemberRoleActionMutation,
  ChangeTeamMemberRoleActionMutationVariables
>;
export const CreateTeamDocument = gql`
  mutation CreateTeam($team: TeamsInsertInput!) {
    insertTeamsOne(object: $team) {
      id
    }
  }
`;
export type CreateTeamMutationFn = Apollo.MutationFunction<
  CreateTeamMutation,
  CreateTeamMutationVariables
>;

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      team: // value for 'team'
 *   },
 * });
 */
export function useCreateTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTeamMutation, CreateTeamMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(
    CreateTeamDocument,
    options
  );
}
export type CreateTeamMutationHookResult = ReturnType<typeof useCreateTeamMutation>;
export type CreateTeamMutationResult = Apollo.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = Apollo.BaseMutationOptions<
  CreateTeamMutation,
  CreateTeamMutationVariables
>;
export const DeleteTeamDocument = gql`
  mutation DeleteTeam($teamId: uuid!) {
    deleteTeamsUsers(where: {teamId: {_eq: $teamId}}) {
      affectedRows
    }
    deleteTeamsByPk(id: $teamId) {
      id
    }
  }
`;
export type DeleteTeamMutationFn = Apollo.MutationFunction<
  DeleteTeamMutation,
  DeleteTeamMutationVariables
>;

/**
 * __useDeleteTeamMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMutation, { data, loading, error }] = useDeleteTeamMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useDeleteTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTeamMutation, DeleteTeamMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<DeleteTeamMutation, DeleteTeamMutationVariables>(
    DeleteTeamDocument,
    options
  );
}
export type DeleteTeamMutationHookResult = ReturnType<typeof useDeleteTeamMutation>;
export type DeleteTeamMutationResult = Apollo.MutationResult<DeleteTeamMutation>;
export type DeleteTeamMutationOptions = Apollo.BaseMutationOptions<
  DeleteTeamMutation,
  DeleteTeamMutationVariables
>;
export const DeleteTeamActionDocument = gql`
  mutation DeleteTeamAction($id: uuid!) {
    DeleteTeam(id: $id) {
      details
      success
    }
  }
`;
export type DeleteTeamActionMutationFn = Apollo.MutationFunction<
  DeleteTeamActionMutation,
  DeleteTeamActionMutationVariables
>;

/**
 * __useDeleteTeamActionMutation__
 *
 * To run a mutation, you first call `useDeleteTeamActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamActionMutation, { data, loading, error }] = useDeleteTeamActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTeamActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTeamActionMutation,
    DeleteTeamActionMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<DeleteTeamActionMutation, DeleteTeamActionMutationVariables>(
    DeleteTeamActionDocument,
    options
  );
}
export type DeleteTeamActionMutationHookResult = ReturnType<typeof useDeleteTeamActionMutation>;
export type DeleteTeamActionMutationResult = Apollo.MutationResult<DeleteTeamActionMutation>;
export type DeleteTeamActionMutationOptions = Apollo.BaseMutationOptions<
  DeleteTeamActionMutation,
  DeleteTeamActionMutationVariables
>;
export const DeleteUsersTeamsDocument = gql`
  mutation DeleteUsersTeams($where: TeamsUsersBoolExp!) {
    deleteTeamsUsers(where: $where) {
      affectedRows
    }
  }
`;
export type DeleteUsersTeamsMutationFn = Apollo.MutationFunction<
  DeleteUsersTeamsMutation,
  DeleteUsersTeamsMutationVariables
>;

/**
 * __useDeleteUsersTeamsMutation__
 *
 * To run a mutation, you first call `useDeleteUsersTeamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUsersTeamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUsersTeamsMutation, { data, loading, error }] = useDeleteUsersTeamsMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteUsersTeamsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUsersTeamsMutation,
    DeleteUsersTeamsMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<DeleteUsersTeamsMutation, DeleteUsersTeamsMutationVariables>(
    DeleteUsersTeamsDocument,
    options
  );
}
export type DeleteUsersTeamsMutationHookResult = ReturnType<typeof useDeleteUsersTeamsMutation>;
export type DeleteUsersTeamsMutationResult = Apollo.MutationResult<DeleteUsersTeamsMutation>;
export type DeleteUsersTeamsMutationOptions = Apollo.BaseMutationOptions<
  DeleteUsersTeamsMutation,
  DeleteUsersTeamsMutationVariables
>;
export const MoveMemberToTeamDocument = gql`
  mutation MoveMemberToTeam(
    $memberUserId: uuid!
    $memberRole: String = "member"
    $fromTeamId: uuid!
    $toTeamId: uuid!
    $createdById: uuid!
  ) {
    deleteTeamsUsersByPk(teamId: $fromTeamId, userId: $memberUserId) {
      __typename
    }
    insertTeamsUsersOne(
      object: {
        createdById: $createdById
        role: $memberRole
        teamId: $toTeamId
        userId: $memberUserId
      }
    ) {
      __typename
    }
  }
`;
export type MoveMemberToTeamMutationFn = Apollo.MutationFunction<
  MoveMemberToTeamMutation,
  MoveMemberToTeamMutationVariables
>;

/**
 * __useMoveMemberToTeamMutation__
 *
 * To run a mutation, you first call `useMoveMemberToTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveMemberToTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveMemberToTeamMutation, { data, loading, error }] = useMoveMemberToTeamMutation({
 *   variables: {
 *      memberUserId: // value for 'memberUserId'
 *      memberRole: // value for 'memberRole'
 *      fromTeamId: // value for 'fromTeamId'
 *      toTeamId: // value for 'toTeamId'
 *      createdById: // value for 'createdById'
 *   },
 * });
 */
export function useMoveMemberToTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveMemberToTeamMutation,
    MoveMemberToTeamMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<MoveMemberToTeamMutation, MoveMemberToTeamMutationVariables>(
    MoveMemberToTeamDocument,
    options
  );
}
export type MoveMemberToTeamMutationHookResult = ReturnType<typeof useMoveMemberToTeamMutation>;
export type MoveMemberToTeamMutationResult = Apollo.MutationResult<MoveMemberToTeamMutation>;
export type MoveMemberToTeamMutationOptions = Apollo.BaseMutationOptions<
  MoveMemberToTeamMutation,
  MoveMemberToTeamMutationVariables
>;
export const MoveMemberToTeamActionDocument = gql`
  mutation MoveMemberToTeamAction($memberUserId: uuid!, $fromTeamId: uuid!, $toTeamId: uuid!) {
    MoveMemberToTeam(memberUserId: $memberUserId, fromTeamId: $fromTeamId, toTeamId: $toTeamId) {
      success
    }
  }
`;
export type MoveMemberToTeamActionMutationFn = Apollo.MutationFunction<
  MoveMemberToTeamActionMutation,
  MoveMemberToTeamActionMutationVariables
>;

/**
 * __useMoveMemberToTeamActionMutation__
 *
 * To run a mutation, you first call `useMoveMemberToTeamActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveMemberToTeamActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveMemberToTeamActionMutation, { data, loading, error }] = useMoveMemberToTeamActionMutation({
 *   variables: {
 *      memberUserId: // value for 'memberUserId'
 *      fromTeamId: // value for 'fromTeamId'
 *      toTeamId: // value for 'toTeamId'
 *   },
 * });
 */
export function useMoveMemberToTeamActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveMemberToTeamActionMutation,
    MoveMemberToTeamActionMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    MoveMemberToTeamActionMutation,
    MoveMemberToTeamActionMutationVariables
  >(MoveMemberToTeamActionDocument, options);
}
export type MoveMemberToTeamActionMutationHookResult = ReturnType<
  typeof useMoveMemberToTeamActionMutation
>;
export type MoveMemberToTeamActionMutationResult =
  Apollo.MutationResult<MoveMemberToTeamActionMutation>;
export type MoveMemberToTeamActionMutationOptions = Apollo.BaseMutationOptions<
  MoveMemberToTeamActionMutation,
  MoveMemberToTeamActionMutationVariables
>;
export const RemoveTeamMemberActionDocument = gql`
  mutation RemoveTeamMemberAction($userId: String!, $teamId: String!, $organizationId: String!) {
    RemoveTeamMember(teamId: $teamId, userId: $userId, organizationId: $organizationId) {
      success
    }
  }
`;
export type RemoveTeamMemberActionMutationFn = Apollo.MutationFunction<
  RemoveTeamMemberActionMutation,
  RemoveTeamMemberActionMutationVariables
>;

/**
 * __useRemoveTeamMemberActionMutation__
 *
 * To run a mutation, you first call `useRemoveTeamMemberActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTeamMemberActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTeamMemberActionMutation, { data, loading, error }] = useRemoveTeamMemberActionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      teamId: // value for 'teamId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useRemoveTeamMemberActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveTeamMemberActionMutation,
    RemoveTeamMemberActionMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    RemoveTeamMemberActionMutation,
    RemoveTeamMemberActionMutationVariables
  >(RemoveTeamMemberActionDocument, options);
}
export type RemoveTeamMemberActionMutationHookResult = ReturnType<
  typeof useRemoveTeamMemberActionMutation
>;
export type RemoveTeamMemberActionMutationResult =
  Apollo.MutationResult<RemoveTeamMemberActionMutation>;
export type RemoveTeamMemberActionMutationOptions = Apollo.BaseMutationOptions<
  RemoveTeamMemberActionMutation,
  RemoveTeamMemberActionMutationVariables
>;
export const UpsertTeamDocument = gql`
  mutation UpsertTeam($fields: TeamsInsertInput!) {
    insertTeams(
      objects: [$fields]
      onConflict: {
        constraint: teams_pk
        updateColumns: [parentId, name, slug, teamJobFunction, updatedAt, updatedById]
      }
    ) {
      affectedRows
      returning {
        id
      }
    }
  }
`;
export type UpsertTeamMutationFn = Apollo.MutationFunction<
  UpsertTeamMutation,
  UpsertTeamMutationVariables
>;

/**
 * __useUpsertTeamMutation__
 *
 * To run a mutation, you first call `useUpsertTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTeamMutation, { data, loading, error }] = useUpsertTeamMutation({
 *   variables: {
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useUpsertTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertTeamMutation, UpsertTeamMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<UpsertTeamMutation, UpsertTeamMutationVariables>(
    UpsertTeamDocument,
    options
  );
}
export type UpsertTeamMutationHookResult = ReturnType<typeof useUpsertTeamMutation>;
export type UpsertTeamMutationResult = Apollo.MutationResult<UpsertTeamMutation>;
export type UpsertTeamMutationOptions = Apollo.BaseMutationOptions<
  UpsertTeamMutation,
  UpsertTeamMutationVariables
>;
export const UpsertTeamActionDocument = gql`
  mutation UpsertTeamAction(
    $id: uuid
    $parentId: uuid!
    $organizationId: uuid!
    $name: String!
    $teamJobFunction: String!
    $slug: String!
  ) {
    UpsertTeam(
      id: $id
      parentId: $parentId
      organizationId: $organizationId
      name: $name
      teamJobFunction: $teamJobFunction
      slug: $slug
    ) {
      id
    }
  }
`;
export type UpsertTeamActionMutationFn = Apollo.MutationFunction<
  UpsertTeamActionMutation,
  UpsertTeamActionMutationVariables
>;

/**
 * __useUpsertTeamActionMutation__
 *
 * To run a mutation, you first call `useUpsertTeamActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertTeamActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTeamActionMutation, { data, loading, error }] = useUpsertTeamActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      parentId: // value for 'parentId'
 *      organizationId: // value for 'organizationId'
 *      name: // value for 'name'
 *      teamJobFunction: // value for 'teamJobFunction'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useUpsertTeamActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertTeamActionMutation,
    UpsertTeamActionMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<UpsertTeamActionMutation, UpsertTeamActionMutationVariables>(
    UpsertTeamActionDocument,
    options
  );
}
export type UpsertTeamActionMutationHookResult = ReturnType<typeof useUpsertTeamActionMutation>;
export type UpsertTeamActionMutationResult = Apollo.MutationResult<UpsertTeamActionMutation>;
export type UpsertTeamActionMutationOptions = Apollo.BaseMutationOptions<
  UpsertTeamActionMutation,
  UpsertTeamActionMutationVariables
>;
export const AddTypeformResponseDocument = gql`
  mutation AddTypeformResponse(
    $object: TypeformSubmissionsInsertInput!
    $onConflict: TypeformSubmissionsOnConflict!
  ) {
    insertTypeformSubmissionsOne(object: $object, onConflict: $onConflict) {
      id
    }
  }
`;
export type AddTypeformResponseMutationFn = Apollo.MutationFunction<
  AddTypeformResponseMutation,
  AddTypeformResponseMutationVariables
>;

/**
 * __useAddTypeformResponseMutation__
 *
 * To run a mutation, you first call `useAddTypeformResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTypeformResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTypeformResponseMutation, { data, loading, error }] = useAddTypeformResponseMutation({
 *   variables: {
 *      object: // value for 'object'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useAddTypeformResponseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddTypeformResponseMutation,
    AddTypeformResponseMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<AddTypeformResponseMutation, AddTypeformResponseMutationVariables>(
    AddTypeformResponseDocument,
    options
  );
}
export type AddTypeformResponseMutationHookResult = ReturnType<
  typeof useAddTypeformResponseMutation
>;
export type AddTypeformResponseMutationResult = Apollo.MutationResult<AddTypeformResponseMutation>;
export type AddTypeformResponseMutationOptions = Apollo.BaseMutationOptions<
  AddTypeformResponseMutation,
  AddTypeformResponseMutationVariables
>;
export const CreateUserActionDocument = gql`
  mutation CreateUserAction(
    $firstName: String!
    $lastName: String!
    $email: String!
    $organizationId: String
    $teamId: String
    $jobFunction: String
    $jobFunctionLength: String
  ) {
    CreateUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      organizationId: $organizationId
      teamId: $teamId
      jobFunction: $jobFunction
      jobFunctionLength: $jobFunctionLength
    ) {
      id
    }
  }
`;
export type CreateUserActionMutationFn = Apollo.MutationFunction<
  CreateUserActionMutation,
  CreateUserActionMutationVariables
>;

/**
 * __useCreateUserActionMutation__
 *
 * To run a mutation, you first call `useCreateUserActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserActionMutation, { data, loading, error }] = useCreateUserActionMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      organizationId: // value for 'organizationId'
 *      teamId: // value for 'teamId'
 *      jobFunction: // value for 'jobFunction'
 *      jobFunctionLength: // value for 'jobFunctionLength'
 *   },
 * });
 */
export function useCreateUserActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserActionMutation,
    CreateUserActionMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<CreateUserActionMutation, CreateUserActionMutationVariables>(
    CreateUserActionDocument,
    options
  );
}
export type CreateUserActionMutationHookResult = ReturnType<typeof useCreateUserActionMutation>;
export type CreateUserActionMutationResult = Apollo.MutationResult<CreateUserActionMutation>;
export type CreateUserActionMutationOptions = Apollo.BaseMutationOptions<
  CreateUserActionMutation,
  CreateUserActionMutationVariables
>;
export const DeactivateUserActionDocument = gql`
  mutation DeactivateUserAction($userId: uuid!) {
    DeactivateUser(userId: $userId) {
      success
    }
  }
`;
export type DeactivateUserActionMutationFn = Apollo.MutationFunction<
  DeactivateUserActionMutation,
  DeactivateUserActionMutationVariables
>;

/**
 * __useDeactivateUserActionMutation__
 *
 * To run a mutation, you first call `useDeactivateUserActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateUserActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateUserActionMutation, { data, loading, error }] = useDeactivateUserActionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeactivateUserActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeactivateUserActionMutation,
    DeactivateUserActionMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<DeactivateUserActionMutation, DeactivateUserActionMutationVariables>(
    DeactivateUserActionDocument,
    options
  );
}
export type DeactivateUserActionMutationHookResult = ReturnType<
  typeof useDeactivateUserActionMutation
>;
export type DeactivateUserActionMutationResult =
  Apollo.MutationResult<DeactivateUserActionMutation>;
export type DeactivateUserActionMutationOptions = Apollo.BaseMutationOptions<
  DeactivateUserActionMutation,
  DeactivateUserActionMutationVariables
>;
export const ImpersonateUserActionDocument = gql`
  mutation ImpersonateUserAction($userId: uuid!) {
    ImpersonateUser(userId: $userId) {
      assumeSessionUrl
    }
  }
`;
export type ImpersonateUserActionMutationFn = Apollo.MutationFunction<
  ImpersonateUserActionMutation,
  ImpersonateUserActionMutationVariables
>;

/**
 * __useImpersonateUserActionMutation__
 *
 * To run a mutation, you first call `useImpersonateUserActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImpersonateUserActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [impersonateUserActionMutation, { data, loading, error }] = useImpersonateUserActionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useImpersonateUserActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImpersonateUserActionMutation,
    ImpersonateUserActionMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<ImpersonateUserActionMutation, ImpersonateUserActionMutationVariables>(
    ImpersonateUserActionDocument,
    options
  );
}
export type ImpersonateUserActionMutationHookResult = ReturnType<
  typeof useImpersonateUserActionMutation
>;
export type ImpersonateUserActionMutationResult =
  Apollo.MutationResult<ImpersonateUserActionMutation>;
export type ImpersonateUserActionMutationOptions = Apollo.BaseMutationOptions<
  ImpersonateUserActionMutation,
  ImpersonateUserActionMutationVariables
>;
export const ReactivateUserActionDocument = gql`
  mutation ReactivateUserAction($userId: uuid!) {
    ReactivateUser(userId: $userId) {
      success
    }
  }
`;
export type ReactivateUserActionMutationFn = Apollo.MutationFunction<
  ReactivateUserActionMutation,
  ReactivateUserActionMutationVariables
>;

/**
 * __useReactivateUserActionMutation__
 *
 * To run a mutation, you first call `useReactivateUserActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateUserActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateUserActionMutation, { data, loading, error }] = useReactivateUserActionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useReactivateUserActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReactivateUserActionMutation,
    ReactivateUserActionMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<ReactivateUserActionMutation, ReactivateUserActionMutationVariables>(
    ReactivateUserActionDocument,
    options
  );
}
export type ReactivateUserActionMutationHookResult = ReturnType<
  typeof useReactivateUserActionMutation
>;
export type ReactivateUserActionMutationResult =
  Apollo.MutationResult<ReactivateUserActionMutation>;
export type ReactivateUserActionMutationOptions = Apollo.BaseMutationOptions<
  ReactivateUserActionMutation,
  ReactivateUserActionMutationVariables
>;
export const RemoveSeatsDocument = gql`
  mutation RemoveSeats($userId: uuid!, $deletedAt: timestamp!, $deletedById: uuid!) {
    updateSeats(
      _set: {deletedAt: $deletedAt, deletedById: $deletedById}
      where: {userId: {_eq: $userId}}
    ) {
      affectedRows
    }
  }
`;
export type RemoveSeatsMutationFn = Apollo.MutationFunction<
  RemoveSeatsMutation,
  RemoveSeatsMutationVariables
>;

/**
 * __useRemoveSeatsMutation__
 *
 * To run a mutation, you first call `useRemoveSeatsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSeatsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSeatsMutation, { data, loading, error }] = useRemoveSeatsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      deletedAt: // value for 'deletedAt'
 *      deletedById: // value for 'deletedById'
 *   },
 * });
 */
export function useRemoveSeatsMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveSeatsMutation, RemoveSeatsMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<RemoveSeatsMutation, RemoveSeatsMutationVariables>(
    RemoveSeatsDocument,
    options
  );
}
export type RemoveSeatsMutationHookResult = ReturnType<typeof useRemoveSeatsMutation>;
export type RemoveSeatsMutationResult = Apollo.MutationResult<RemoveSeatsMutation>;
export type RemoveSeatsMutationOptions = Apollo.BaseMutationOptions<
  RemoveSeatsMutation,
  RemoveSeatsMutationVariables
>;
export const SetUserMetaDocument = gql`
  mutation SetUserMeta($object: UsersMetaInsertInput!) {
    insertUsersMetaOne(
      object: $object
      onConflict: {constraint: users_meta_pk, updateColumns: [locale, selectedOrgId, joinDate]}
    ) {
      userId
      joinDate
    }
  }
`;
export type SetUserMetaMutationFn = Apollo.MutationFunction<
  SetUserMetaMutation,
  SetUserMetaMutationVariables
>;

/**
 * __useSetUserMetaMutation__
 *
 * To run a mutation, you first call `useSetUserMetaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserMetaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserMetaMutation, { data, loading, error }] = useSetUserMetaMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useSetUserMetaMutation(
  baseOptions?: Apollo.MutationHookOptions<SetUserMetaMutation, SetUserMetaMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<SetUserMetaMutation, SetUserMetaMutationVariables>(
    SetUserMetaDocument,
    options
  );
}
export type SetUserMetaMutationHookResult = ReturnType<typeof useSetUserMetaMutation>;
export type SetUserMetaMutationResult = Apollo.MutationResult<SetUserMetaMutation>;
export type SetUserMetaMutationOptions = Apollo.BaseMutationOptions<
  SetUserMetaMutation,
  SetUserMetaMutationVariables
>;
export const SetUserMetaProfileDataDocument = gql`
  mutation SetUserMetaProfileData($object: UsersMetaInsertInput!) {
    insertUsersMetaOne(
      object: $object
      onConflict: {
        constraint: users_meta_pk
        updateColumns: [
          jobFunction
          jobFunctionLength
          acceptedTermsOfUse
          hasFilledUserProfileData
          updatedAt
        ]
      }
    ) {
      userId
      jobFunction
      jobFunctionLength
      acceptedTermsOfUse
      hasFilledUserProfileData
    }
  }
`;
export type SetUserMetaProfileDataMutationFn = Apollo.MutationFunction<
  SetUserMetaProfileDataMutation,
  SetUserMetaProfileDataMutationVariables
>;

/**
 * __useSetUserMetaProfileDataMutation__
 *
 * To run a mutation, you first call `useSetUserMetaProfileDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserMetaProfileDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserMetaProfileDataMutation, { data, loading, error }] = useSetUserMetaProfileDataMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useSetUserMetaProfileDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetUserMetaProfileDataMutation,
    SetUserMetaProfileDataMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    SetUserMetaProfileDataMutation,
    SetUserMetaProfileDataMutationVariables
  >(SetUserMetaProfileDataDocument, options);
}
export type SetUserMetaProfileDataMutationHookResult = ReturnType<
  typeof useSetUserMetaProfileDataMutation
>;
export type SetUserMetaProfileDataMutationResult =
  Apollo.MutationResult<SetUserMetaProfileDataMutation>;
export type SetUserMetaProfileDataMutationOptions = Apollo.BaseMutationOptions<
  SetUserMetaProfileDataMutation,
  SetUserMetaProfileDataMutationVariables
>;
export const SetUserProfileCaptureDocument = gql`
  mutation SetUserProfileCapture(
    $firstName: String!
    $lastName: String!
    $jobFunction: String!
    $jobFunctionLength: String!
    $acceptedTermsOfUse: Boolean!
    $acceptedMarketingCommunication: Boolean
  ) {
    UserProfileCapture(
      firstName: $firstName
      lastName: $lastName
      jobFunction: $jobFunction
      jobFunctionLength: $jobFunctionLength
      acceptedTermsOfUse: $acceptedTermsOfUse
      acceptedMarketingCommunication: $acceptedMarketingCommunication
    ) {
      id
    }
  }
`;
export type SetUserProfileCaptureMutationFn = Apollo.MutationFunction<
  SetUserProfileCaptureMutation,
  SetUserProfileCaptureMutationVariables
>;

/**
 * __useSetUserProfileCaptureMutation__
 *
 * To run a mutation, you first call `useSetUserProfileCaptureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserProfileCaptureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserProfileCaptureMutation, { data, loading, error }] = useSetUserProfileCaptureMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      jobFunction: // value for 'jobFunction'
 *      jobFunctionLength: // value for 'jobFunctionLength'
 *      acceptedTermsOfUse: // value for 'acceptedTermsOfUse'
 *      acceptedMarketingCommunication: // value for 'acceptedMarketingCommunication'
 *   },
 * });
 */
export function useSetUserProfileCaptureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetUserProfileCaptureMutation,
    SetUserProfileCaptureMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<SetUserProfileCaptureMutation, SetUserProfileCaptureMutationVariables>(
    SetUserProfileCaptureDocument,
    options
  );
}
export type SetUserProfileCaptureMutationHookResult = ReturnType<
  typeof useSetUserProfileCaptureMutation
>;
export type SetUserProfileCaptureMutationResult =
  Apollo.MutationResult<SetUserProfileCaptureMutation>;
export type SetUserProfileCaptureMutationOptions = Apollo.BaseMutationOptions<
  SetUserProfileCaptureMutation,
  SetUserProfileCaptureMutationVariables
>;
export const SetUserSeatDocument = gql`
  mutation SetUserSeat($object: SeatsInsertInput!) {
    insertSeatsOne(object: $object) {
      id
    }
  }
`;
export type SetUserSeatMutationFn = Apollo.MutationFunction<
  SetUserSeatMutation,
  SetUserSeatMutationVariables
>;

/**
 * __useSetUserSeatMutation__
 *
 * To run a mutation, you first call `useSetUserSeatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserSeatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserSeatMutation, { data, loading, error }] = useSetUserSeatMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useSetUserSeatMutation(
  baseOptions?: Apollo.MutationHookOptions<SetUserSeatMutation, SetUserSeatMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<SetUserSeatMutation, SetUserSeatMutationVariables>(
    SetUserSeatDocument,
    options
  );
}
export type SetUserSeatMutationHookResult = ReturnType<typeof useSetUserSeatMutation>;
export type SetUserSeatMutationResult = Apollo.MutationResult<SetUserSeatMutation>;
export type SetUserSeatMutationOptions = Apollo.BaseMutationOptions<
  SetUserSeatMutation,
  SetUserSeatMutationVariables
>;
export const UpdateUserActionDocument = gql`
  mutation UpdateUserAction(
    $id: String!
    $teamId: String
    $organizationId: String!
    $email: String!
    $firstName: String!
    $lastName: String!
    $jobFunction: String
    $jobFunctionLength: String
  ) {
    UpdateUser(
      id: $id
      teamId: $teamId
      organizationId: $organizationId
      email: $email
      firstName: $firstName
      lastName: $lastName
      jobFunction: $jobFunction
      jobFunctionLength: $jobFunctionLength
    ) {
      success
    }
  }
`;
export type UpdateUserActionMutationFn = Apollo.MutationFunction<
  UpdateUserActionMutation,
  UpdateUserActionMutationVariables
>;

/**
 * __useUpdateUserActionMutation__
 *
 * To run a mutation, you first call `useUpdateUserActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserActionMutation, { data, loading, error }] = useUpdateUserActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      teamId: // value for 'teamId'
 *      organizationId: // value for 'organizationId'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      jobFunction: // value for 'jobFunction'
 *      jobFunctionLength: // value for 'jobFunctionLength'
 *   },
 * });
 */
export function useUpdateUserActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserActionMutation,
    UpdateUserActionMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<UpdateUserActionMutation, UpdateUserActionMutationVariables>(
    UpdateUserActionDocument,
    options
  );
}
export type UpdateUserActionMutationHookResult = ReturnType<typeof useUpdateUserActionMutation>;
export type UpdateUserActionMutationResult = Apollo.MutationResult<UpdateUserActionMutation>;
export type UpdateUserActionMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserActionMutation,
  UpdateUserActionMutationVariables
>;
export const UpdateZoomTokensDocument = gql`
  mutation UpdateZoomTokens(
    $bearerToken: String!
    $expiresAt: timestamp!
    $index: Int!
    $now: timestamp!
  ) {
    insertZoomOauthTokensOne(
      object: {bearerToken: $bearerToken, expiresAt: $expiresAt, index: $index}
    ) {
      __typename
    }
    deleteZoomOauthTokens(where: {expiresAt: {_lte: $now}}) {
      affectedRows
    }
  }
`;
export type UpdateZoomTokensMutationFn = Apollo.MutationFunction<
  UpdateZoomTokensMutation,
  UpdateZoomTokensMutationVariables
>;

/**
 * __useUpdateZoomTokensMutation__
 *
 * To run a mutation, you first call `useUpdateZoomTokensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomTokensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomTokensMutation, { data, loading, error }] = useUpdateZoomTokensMutation({
 *   variables: {
 *      bearerToken: // value for 'bearerToken'
 *      expiresAt: // value for 'expiresAt'
 *      index: // value for 'index'
 *      now: // value for 'now'
 *   },
 * });
 */
export function useUpdateZoomTokensMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateZoomTokensMutation,
    UpdateZoomTokensMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<UpdateZoomTokensMutation, UpdateZoomTokensMutationVariables>(
    UpdateZoomTokensDocument,
    options
  );
}
export type UpdateZoomTokensMutationHookResult = ReturnType<typeof useUpdateZoomTokensMutation>;
export type UpdateZoomTokensMutationResult = Apollo.MutationResult<UpdateZoomTokensMutation>;
export type UpdateZoomTokensMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomTokensMutation,
  UpdateZoomTokensMutationVariables
>;
export const GetAssessmentAttemptDocument = gql`
  query GetAssessmentAttempt(
    $where: ImpactAssessmentAttemptsBoolExp!
    $orderBy: [ImpactAssessmentAttemptsOrderBy!]
  ) {
    impactAssessmentAttempts(where: $where, orderBy: $orderBy) {
      id
      impactAssessmentId
      percentCorrect
      isPassing
    }
  }
`;

/**
 * __useGetAssessmentAttemptQuery__
 *
 * To run a query within a React component, call `useGetAssessmentAttemptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentAttemptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentAttemptQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAssessmentAttemptQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssessmentAttemptQuery,
    GetAssessmentAttemptQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetAssessmentAttemptQuery, GetAssessmentAttemptQueryVariables>(
    GetAssessmentAttemptDocument,
    options
  );
}
export function useGetAssessmentAttemptLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssessmentAttemptQuery,
    GetAssessmentAttemptQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetAssessmentAttemptQuery, GetAssessmentAttemptQueryVariables>(
    GetAssessmentAttemptDocument,
    options
  );
}
export type GetAssessmentAttemptQueryHookResult = ReturnType<typeof useGetAssessmentAttemptQuery>;
export type GetAssessmentAttemptLazyQueryHookResult = ReturnType<
  typeof useGetAssessmentAttemptLazyQuery
>;
export type GetAssessmentAttemptQueryResult = Apollo.QueryResult<
  GetAssessmentAttemptQuery,
  GetAssessmentAttemptQueryVariables
>;
export const GetAssessmentAttemptByPkDocument = gql`
  query GetAssessmentAttemptByPk($input: uuid!) {
    attempt: impactAssessmentAttemptsByPk(id: $input) {
      id
      userId
      status
      remainingTime
    }
  }
`;

/**
 * __useGetAssessmentAttemptByPkQuery__
 *
 * To run a query within a React component, call `useGetAssessmentAttemptByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentAttemptByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentAttemptByPkQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAssessmentAttemptByPkQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssessmentAttemptByPkQuery,
    GetAssessmentAttemptByPkQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetAssessmentAttemptByPkQuery, GetAssessmentAttemptByPkQueryVariables>(
    GetAssessmentAttemptByPkDocument,
    options
  );
}
export function useGetAssessmentAttemptByPkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssessmentAttemptByPkQuery,
    GetAssessmentAttemptByPkQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetAssessmentAttemptByPkQuery, GetAssessmentAttemptByPkQueryVariables>(
    GetAssessmentAttemptByPkDocument,
    options
  );
}
export type GetAssessmentAttemptByPkQueryHookResult = ReturnType<
  typeof useGetAssessmentAttemptByPkQuery
>;
export type GetAssessmentAttemptByPkLazyQueryHookResult = ReturnType<
  typeof useGetAssessmentAttemptByPkLazyQuery
>;
export type GetAssessmentAttemptByPkQueryResult = Apollo.QueryResult<
  GetAssessmentAttemptByPkQuery,
  GetAssessmentAttemptByPkQueryVariables
>;
export const GetCourseCertificationDocument = gql`
  query GetCourseCertification($input: CourseCertificationsBoolExp!) {
    courseCertifications(where: $input) {
      certificationFileUrl
      certificationImageUrl
      cohort {
        name
      }
      impactAssessment {
        course {
          name
        }
      }
    }
  }
`;

/**
 * __useGetCourseCertificationQuery__
 *
 * To run a query within a React component, call `useGetCourseCertificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseCertificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseCertificationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCourseCertificationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCourseCertificationQuery,
    GetCourseCertificationQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetCourseCertificationQuery, GetCourseCertificationQueryVariables>(
    GetCourseCertificationDocument,
    options
  );
}
export function useGetCourseCertificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCourseCertificationQuery,
    GetCourseCertificationQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetCourseCertificationQuery, GetCourseCertificationQueryVariables>(
    GetCourseCertificationDocument,
    options
  );
}
export type GetCourseCertificationQueryHookResult = ReturnType<
  typeof useGetCourseCertificationQuery
>;
export type GetCourseCertificationLazyQueryHookResult = ReturnType<
  typeof useGetCourseCertificationLazyQuery
>;
export type GetCourseCertificationQueryResult = Apollo.QueryResult<
  GetCourseCertificationQuery,
  GetCourseCertificationQueryVariables
>;
export const GetImpactAssessmentDocument = gql`
  query GetImpactAssessment($input: uuid!) {
    assessment: impactAssessmentsByPk(id: $input) {
      id
      timeLimitInMinutes
      dueWithinDays
      percentToPass
      questionCount
    }
  }
`;

/**
 * __useGetImpactAssessmentQuery__
 *
 * To run a query within a React component, call `useGetImpactAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImpactAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImpactAssessmentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetImpactAssessmentQuery(
  baseOptions: Apollo.QueryHookOptions<GetImpactAssessmentQuery, GetImpactAssessmentQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetImpactAssessmentQuery, GetImpactAssessmentQueryVariables>(
    GetImpactAssessmentDocument,
    options
  );
}
export function useGetImpactAssessmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetImpactAssessmentQuery,
    GetImpactAssessmentQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetImpactAssessmentQuery, GetImpactAssessmentQueryVariables>(
    GetImpactAssessmentDocument,
    options
  );
}
export type GetImpactAssessmentQueryHookResult = ReturnType<typeof useGetImpactAssessmentQuery>;
export type GetImpactAssessmentLazyQueryHookResult = ReturnType<
  typeof useGetImpactAssessmentLazyQuery
>;
export type GetImpactAssessmentQueryResult = Apollo.QueryResult<
  GetImpactAssessmentQuery,
  GetImpactAssessmentQueryVariables
>;
export const GetImpactAssessmentAttemptDocument = gql`
  query GetImpactAssessmentAttempt($attemptId: uuid!, $onlyAssessmentId: Boolean = false) {
    assessmentAttempt: impactAssessmentAttemptsByPk(id: $attemptId) {
      status
      impactAssessmentId
      impactAssessment @skip(if: $onlyAssessmentId) {
        course {
          name
        }
      }
      ...ImpactAssessmentAttempt @skip(if: $onlyAssessmentId)
    }
  }
  ${ImpactAssessmentAttemptFragmentDoc}
`;

/**
 * __useGetImpactAssessmentAttemptQuery__
 *
 * To run a query within a React component, call `useGetImpactAssessmentAttemptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImpactAssessmentAttemptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImpactAssessmentAttemptQuery({
 *   variables: {
 *      attemptId: // value for 'attemptId'
 *      onlyAssessmentId: // value for 'onlyAssessmentId'
 *   },
 * });
 */
export function useGetImpactAssessmentAttemptQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetImpactAssessmentAttemptQuery,
    GetImpactAssessmentAttemptQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetImpactAssessmentAttemptQuery, GetImpactAssessmentAttemptQueryVariables>(
    GetImpactAssessmentAttemptDocument,
    options
  );
}
export function useGetImpactAssessmentAttemptLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetImpactAssessmentAttemptQuery,
    GetImpactAssessmentAttemptQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetImpactAssessmentAttemptQuery,
    GetImpactAssessmentAttemptQueryVariables
  >(GetImpactAssessmentAttemptDocument, options);
}
export type GetImpactAssessmentAttemptQueryHookResult = ReturnType<
  typeof useGetImpactAssessmentAttemptQuery
>;
export type GetImpactAssessmentAttemptLazyQueryHookResult = ReturnType<
  typeof useGetImpactAssessmentAttemptLazyQuery
>;
export type GetImpactAssessmentAttemptQueryResult = Apollo.QueryResult<
  GetImpactAssessmentAttemptQuery,
  GetImpactAssessmentAttemptQueryVariables
>;
export const GetImpactAssessmentQuestionsDocument = gql`
  query GetImpactAssessmentQuestions($input: uuid!, $limit: Int!, $onlyIds: Boolean = true) {
    questions: getAssessmentQuestions(args: {assessment_id: $input}, limit: $limit) {
      id
      ...ImpactAssessmentQuestions @skip(if: $onlyIds)
    }
  }
  ${ImpactAssessmentQuestionsFragmentDoc}
`;

/**
 * __useGetImpactAssessmentQuestionsQuery__
 *
 * To run a query within a React component, call `useGetImpactAssessmentQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImpactAssessmentQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImpactAssessmentQuestionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      limit: // value for 'limit'
 *      onlyIds: // value for 'onlyIds'
 *   },
 * });
 */
export function useGetImpactAssessmentQuestionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetImpactAssessmentQuestionsQuery,
    GetImpactAssessmentQuestionsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetImpactAssessmentQuestionsQuery,
    GetImpactAssessmentQuestionsQueryVariables
  >(GetImpactAssessmentQuestionsDocument, options);
}
export function useGetImpactAssessmentQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetImpactAssessmentQuestionsQuery,
    GetImpactAssessmentQuestionsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetImpactAssessmentQuestionsQuery,
    GetImpactAssessmentQuestionsQueryVariables
  >(GetImpactAssessmentQuestionsDocument, options);
}
export type GetImpactAssessmentQuestionsQueryHookResult = ReturnType<
  typeof useGetImpactAssessmentQuestionsQuery
>;
export type GetImpactAssessmentQuestionsLazyQueryHookResult = ReturnType<
  typeof useGetImpactAssessmentQuestionsLazyQuery
>;
export type GetImpactAssessmentQuestionsQueryResult = Apollo.QueryResult<
  GetImpactAssessmentQuestionsQuery,
  GetImpactAssessmentQuestionsQueryVariables
>;
export const GetImpactAssessmentQuestionsByIdsDocument = gql`
  query GetImpactAssessmentQuestionsByIds($ids: [uuid!]) {
    assessmentQuestions: impactAssessmentQuestions(where: {id: {_in: $ids}}) {
      id
      points
      correctAnswers
      questionType {
        value
      }
    }
  }
`;

/**
 * __useGetImpactAssessmentQuestionsByIdsQuery__
 *
 * To run a query within a React component, call `useGetImpactAssessmentQuestionsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImpactAssessmentQuestionsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImpactAssessmentQuestionsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetImpactAssessmentQuestionsByIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetImpactAssessmentQuestionsByIdsQuery,
    GetImpactAssessmentQuestionsByIdsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetImpactAssessmentQuestionsByIdsQuery,
    GetImpactAssessmentQuestionsByIdsQueryVariables
  >(GetImpactAssessmentQuestionsByIdsDocument, options);
}
export function useGetImpactAssessmentQuestionsByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetImpactAssessmentQuestionsByIdsQuery,
    GetImpactAssessmentQuestionsByIdsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetImpactAssessmentQuestionsByIdsQuery,
    GetImpactAssessmentQuestionsByIdsQueryVariables
  >(GetImpactAssessmentQuestionsByIdsDocument, options);
}
export type GetImpactAssessmentQuestionsByIdsQueryHookResult = ReturnType<
  typeof useGetImpactAssessmentQuestionsByIdsQuery
>;
export type GetImpactAssessmentQuestionsByIdsLazyQueryHookResult = ReturnType<
  typeof useGetImpactAssessmentQuestionsByIdsLazyQuery
>;
export type GetImpactAssessmentQuestionsByIdsQueryResult = Apollo.QueryResult<
  GetImpactAssessmentQuestionsByIdsQuery,
  GetImpactAssessmentQuestionsByIdsQueryVariables
>;
export const GetUserAssessmentAttemptValidDocument = gql`
  query GetUserAssessmentAttemptValid($assessmentId: uuid!, $userId: uuid!, $cohortSlug: String!) {
    liveAttempts: impactAssessmentAttemptsAggregate(
      where: {
        cohort: {slug: {_eq: $cohortSlug}}
        impactAssessmentId: {_eq: $assessmentId}
        userId: {_eq: $userId}
        status: {_in: [IN_PROGRESS, PAUSED, REVIEW]}
      }
    ) {
      valid: aggregate {
        count
      }
    }
    total: impactAssessmentAttemptsAggregate(
      where: {
        cohort: {slug: {_eq: $cohortSlug}}
        impactAssessmentId: {_eq: $assessmentId}
        userId: {_eq: $userId}
      }
    ) {
      attempts: aggregate {
        count
      }
    }
    isPassing: impactAssessmentAttemptsAggregate(
      where: {
        cohort: {slug: {_eq: $cohortSlug}}
        impactAssessmentId: {_eq: $assessmentId}
        userId: {_eq: $userId}
        isPassing: {_eq: true}
      }
    ) {
      passed: aggregate {
        count
      }
    }
  }
`;

/**
 * __useGetUserAssessmentAttemptValidQuery__
 *
 * To run a query within a React component, call `useGetUserAssessmentAttemptValidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAssessmentAttemptValidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAssessmentAttemptValidQuery({
 *   variables: {
 *      assessmentId: // value for 'assessmentId'
 *      userId: // value for 'userId'
 *      cohortSlug: // value for 'cohortSlug'
 *   },
 * });
 */
export function useGetUserAssessmentAttemptValidQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserAssessmentAttemptValidQuery,
    GetUserAssessmentAttemptValidQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetUserAssessmentAttemptValidQuery,
    GetUserAssessmentAttemptValidQueryVariables
  >(GetUserAssessmentAttemptValidDocument, options);
}
export function useGetUserAssessmentAttemptValidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserAssessmentAttemptValidQuery,
    GetUserAssessmentAttemptValidQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetUserAssessmentAttemptValidQuery,
    GetUserAssessmentAttemptValidQueryVariables
  >(GetUserAssessmentAttemptValidDocument, options);
}
export type GetUserAssessmentAttemptValidQueryHookResult = ReturnType<
  typeof useGetUserAssessmentAttemptValidQuery
>;
export type GetUserAssessmentAttemptValidLazyQueryHookResult = ReturnType<
  typeof useGetUserAssessmentAttemptValidLazyQuery
>;
export type GetUserAssessmentAttemptValidQueryResult = Apollo.QueryResult<
  GetUserAssessmentAttemptValidQuery,
  GetUserAssessmentAttemptValidQueryVariables
>;
export const GetUserCurrentImpactAssessmentsForCohortDocument = gql`
  query GetUserCurrentImpactAssessmentsForCohort($input: uuid!) {
    currentAssessment: impactAssessmentAttemptsByPk(id: $input) {
      status
      startedAt
      remainingTime
      learnerAnswers
      impactAssessment {
        id
        timeLimitInMinutes
        questions {
          answers
          id
          questionRich
          type
        }
      }
    }
  }
`;

/**
 * __useGetUserCurrentImpactAssessmentsForCohortQuery__
 *
 * To run a query within a React component, call `useGetUserCurrentImpactAssessmentsForCohortQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCurrentImpactAssessmentsForCohortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCurrentImpactAssessmentsForCohortQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserCurrentImpactAssessmentsForCohortQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserCurrentImpactAssessmentsForCohortQuery,
    GetUserCurrentImpactAssessmentsForCohortQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetUserCurrentImpactAssessmentsForCohortQuery,
    GetUserCurrentImpactAssessmentsForCohortQueryVariables
  >(GetUserCurrentImpactAssessmentsForCohortDocument, options);
}
export function useGetUserCurrentImpactAssessmentsForCohortLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserCurrentImpactAssessmentsForCohortQuery,
    GetUserCurrentImpactAssessmentsForCohortQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetUserCurrentImpactAssessmentsForCohortQuery,
    GetUserCurrentImpactAssessmentsForCohortQueryVariables
  >(GetUserCurrentImpactAssessmentsForCohortDocument, options);
}
export type GetUserCurrentImpactAssessmentsForCohortQueryHookResult = ReturnType<
  typeof useGetUserCurrentImpactAssessmentsForCohortQuery
>;
export type GetUserCurrentImpactAssessmentsForCohortLazyQueryHookResult = ReturnType<
  typeof useGetUserCurrentImpactAssessmentsForCohortLazyQuery
>;
export type GetUserCurrentImpactAssessmentsForCohortQueryResult = Apollo.QueryResult<
  GetUserCurrentImpactAssessmentsForCohortQuery,
  GetUserCurrentImpactAssessmentsForCohortQueryVariables
>;
export const GetUserEnrolledForAssessmentDocument = gql`
  query GetUserEnrolledForAssessment($assessmentId: uuid!, $userId: uuid!, $cohortSlug: String!) {
    enrolledForAssessment: enrollmentsAggregate(
      where: {
        cohort: {slug: {_eq: $cohortSlug}, course: {impactAssessmentId: {_eq: $assessmentId}}}
        userId: {_eq: $userId}
        deletedAt: {_isNull: true}
      }
    ) {
      enrolled: aggregate {
        count
      }
    }
    cohort: cohorts(where: {slug: {_eq: $cohortSlug}}, limit: 1) {
      assessmentLiveDate
      assessmentDueDate
      isAssessmentLive
      type
    }
  }
`;

/**
 * __useGetUserEnrolledForAssessmentQuery__
 *
 * To run a query within a React component, call `useGetUserEnrolledForAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEnrolledForAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEnrolledForAssessmentQuery({
 *   variables: {
 *      assessmentId: // value for 'assessmentId'
 *      userId: // value for 'userId'
 *      cohortSlug: // value for 'cohortSlug'
 *   },
 * });
 */
export function useGetUserEnrolledForAssessmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserEnrolledForAssessmentQuery,
    GetUserEnrolledForAssessmentQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetUserEnrolledForAssessmentQuery,
    GetUserEnrolledForAssessmentQueryVariables
  >(GetUserEnrolledForAssessmentDocument, options);
}
export function useGetUserEnrolledForAssessmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserEnrolledForAssessmentQuery,
    GetUserEnrolledForAssessmentQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetUserEnrolledForAssessmentQuery,
    GetUserEnrolledForAssessmentQueryVariables
  >(GetUserEnrolledForAssessmentDocument, options);
}
export type GetUserEnrolledForAssessmentQueryHookResult = ReturnType<
  typeof useGetUserEnrolledForAssessmentQuery
>;
export type GetUserEnrolledForAssessmentLazyQueryHookResult = ReturnType<
  typeof useGetUserEnrolledForAssessmentLazyQuery
>;
export type GetUserEnrolledForAssessmentQueryResult = Apollo.QueryResult<
  GetUserEnrolledForAssessmentQuery,
  GetUserEnrolledForAssessmentQueryVariables
>;
export const GetUserImpactAssessmentsForCohortDocument = gql`
  query GetUserImpactAssessmentsForCohort($input: String!) {
    courses(where: {cohorts: {slug: {_eq: $input}}}) {
      impactDescriptionList
      cohorts(where: {slug: {_eq: $input}}) {
        type
        ...CohortAssessmentDates
      }
      impactAssessment {
        id
        percentToPass
        timeLimitInMinutes
        dueWithinDays
        questionCount
        descriptionRich
      }
    }
    attempts: impactAssessmentAttempts(
      where: {cohort: {slug: {_eq: $input}}}
      orderBy: {startedAt: DESC_NULLS_LAST}
    ) {
      id
      status
      startedAt
      remainingTime
      isPassing
      percentCorrect
      impactAssessmentId
    }
  }
  ${CohortAssessmentDatesFragmentDoc}
`;

/**
 * __useGetUserImpactAssessmentsForCohortQuery__
 *
 * To run a query within a React component, call `useGetUserImpactAssessmentsForCohortQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserImpactAssessmentsForCohortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserImpactAssessmentsForCohortQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserImpactAssessmentsForCohortQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserImpactAssessmentsForCohortQuery,
    GetUserImpactAssessmentsForCohortQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetUserImpactAssessmentsForCohortQuery,
    GetUserImpactAssessmentsForCohortQueryVariables
  >(GetUserImpactAssessmentsForCohortDocument, options);
}
export function useGetUserImpactAssessmentsForCohortLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserImpactAssessmentsForCohortQuery,
    GetUserImpactAssessmentsForCohortQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetUserImpactAssessmentsForCohortQuery,
    GetUserImpactAssessmentsForCohortQueryVariables
  >(GetUserImpactAssessmentsForCohortDocument, options);
}
export type GetUserImpactAssessmentsForCohortQueryHookResult = ReturnType<
  typeof useGetUserImpactAssessmentsForCohortQuery
>;
export type GetUserImpactAssessmentsForCohortLazyQueryHookResult = ReturnType<
  typeof useGetUserImpactAssessmentsForCohortLazyQuery
>;
export type GetUserImpactAssessmentsForCohortQueryResult = Apollo.QueryResult<
  GetUserImpactAssessmentsForCohortQuery,
  GetUserImpactAssessmentsForCohortQueryVariables
>;
export const GetCoachesForCatalogDocument = gql`
  query GetCoachesForCatalog {
    courses: coursesByPlan {
      coaches(orderBy: [{coach: {name: ASC}}]) {
        coach {
          id
          name
          slug
        }
      }
    }
  }
`;

/**
 * __useGetCoachesForCatalogQuery__
 *
 * To run a query within a React component, call `useGetCoachesForCatalogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoachesForCatalogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoachesForCatalogQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCoachesForCatalogQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCoachesForCatalogQuery,
    GetCoachesForCatalogQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetCoachesForCatalogQuery, GetCoachesForCatalogQueryVariables>(
    GetCoachesForCatalogDocument,
    options
  );
}
export function useGetCoachesForCatalogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCoachesForCatalogQuery,
    GetCoachesForCatalogQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetCoachesForCatalogQuery, GetCoachesForCatalogQueryVariables>(
    GetCoachesForCatalogDocument,
    options
  );
}
export type GetCoachesForCatalogQueryHookResult = ReturnType<typeof useGetCoachesForCatalogQuery>;
export type GetCoachesForCatalogLazyQueryHookResult = ReturnType<
  typeof useGetCoachesForCatalogLazyQuery
>;
export type GetCoachesForCatalogQueryResult = Apollo.QueryResult<
  GetCoachesForCatalogQuery,
  GetCoachesForCatalogQueryVariables
>;
export const GetCoursesForCatalogDocument = gql`
  query GetCoursesForCatalog($filter: CoursesByPlanBoolExp!) {
    courses: coursesByPlan(where: $filter) {
      version
      id
      slug
      name
      cohortType
      courseType
      enrollmentStatus
      shortDescription
      nextCohortStartDate
      nextCohortStartTimeOfDay
      firstClassStartTime
      endDate
      backgroundImageUrl
      thumbnailCardUrl
      timeLengthInWeeks
      timeLengthInClasses
      tags(where: {tag: {tagType: {slug: {_eq: "role"}}}}) {
        tag {
          name
          slug
        }
      }
      school {
        name
        slug
      }
      coaches {
        coach {
          id
          name
          slug
        }
      }
    }
  }
`;

/**
 * __useGetCoursesForCatalogQuery__
 *
 * To run a query within a React component, call `useGetCoursesForCatalogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursesForCatalogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursesForCatalogQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCoursesForCatalogQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCoursesForCatalogQuery,
    GetCoursesForCatalogQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetCoursesForCatalogQuery, GetCoursesForCatalogQueryVariables>(
    GetCoursesForCatalogDocument,
    options
  );
}
export function useGetCoursesForCatalogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCoursesForCatalogQuery,
    GetCoursesForCatalogQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetCoursesForCatalogQuery, GetCoursesForCatalogQueryVariables>(
    GetCoursesForCatalogDocument,
    options
  );
}
export type GetCoursesForCatalogQueryHookResult = ReturnType<typeof useGetCoursesForCatalogQuery>;
export type GetCoursesForCatalogLazyQueryHookResult = ReturnType<
  typeof useGetCoursesForCatalogLazyQuery
>;
export type GetCoursesForCatalogQueryResult = Apollo.QueryResult<
  GetCoursesForCatalogQuery,
  GetCoursesForCatalogQueryVariables
>;
export const GetCertificateMetadataDocument = gql`
  query GetCertificateMetadata($impactAssessmentAttemptId: uuid!) {
    certificateMetadata: impactAssessmentAttemptsByPk(id: $impactAssessmentAttemptId) {
      percentCorrect
      updatedAt
      user {
        id
        firstName
        lastName
        fullName
        data
        timezone
      }
      cohort {
        course {
          name
          slug
          certificateTemplate
          coaches {
            coach {
              name
              slug
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetCertificateMetadataQuery__
 *
 * To run a query within a React component, call `useGetCertificateMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCertificateMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCertificateMetadataQuery({
 *   variables: {
 *      impactAssessmentAttemptId: // value for 'impactAssessmentAttemptId'
 *   },
 * });
 */
export function useGetCertificateMetadataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCertificateMetadataQuery,
    GetCertificateMetadataQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetCertificateMetadataQuery, GetCertificateMetadataQueryVariables>(
    GetCertificateMetadataDocument,
    options
  );
}
export function useGetCertificateMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCertificateMetadataQuery,
    GetCertificateMetadataQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetCertificateMetadataQuery, GetCertificateMetadataQueryVariables>(
    GetCertificateMetadataDocument,
    options
  );
}
export type GetCertificateMetadataQueryHookResult = ReturnType<
  typeof useGetCertificateMetadataQuery
>;
export type GetCertificateMetadataLazyQueryHookResult = ReturnType<
  typeof useGetCertificateMetadataLazyQuery
>;
export type GetCertificateMetadataQueryResult = Apollo.QueryResult<
  GetCertificateMetadataQuery,
  GetCertificateMetadataQueryVariables
>;
export const GetAnonRegistrationDocument = gql`
  query GetAnonRegistration($classEventId: uuid!, $email: String!) {
    classEventAnonRegistrations(
      where: {_and: [{classEventId: {_eq: $classEventId}}, {email: {_eq: $email}}]}
    ) {
      token
      zoomJoinLinkUrl
    }
  }
`;

/**
 * __useGetAnonRegistrationQuery__
 *
 * To run a query within a React component, call `useGetAnonRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnonRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnonRegistrationQuery({
 *   variables: {
 *      classEventId: // value for 'classEventId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetAnonRegistrationQuery(
  baseOptions: Apollo.QueryHookOptions<GetAnonRegistrationQuery, GetAnonRegistrationQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetAnonRegistrationQuery, GetAnonRegistrationQueryVariables>(
    GetAnonRegistrationDocument,
    options
  );
}
export function useGetAnonRegistrationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAnonRegistrationQuery,
    GetAnonRegistrationQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetAnonRegistrationQuery, GetAnonRegistrationQueryVariables>(
    GetAnonRegistrationDocument,
    options
  );
}
export type GetAnonRegistrationQueryHookResult = ReturnType<typeof useGetAnonRegistrationQuery>;
export type GetAnonRegistrationLazyQueryHookResult = ReturnType<
  typeof useGetAnonRegistrationLazyQuery
>;
export type GetAnonRegistrationQueryResult = Apollo.QueryResult<
  GetAnonRegistrationQuery,
  GetAnonRegistrationQueryVariables
>;
export const GetAnonRegistrationByTokenDocument = gql`
  query GetAnonRegistrationByToken($token: String!) {
    classEventAnonRegistrations(where: {token: {_eq: $token}}) {
      classEventId
      zoomJoinLinkUrl
      email
      firstName
      lastName
      classEvent {
        startTime
        endTime
        cohort {
          slug
          inAppVideo
        }
      }
    }
  }
`;

/**
 * __useGetAnonRegistrationByTokenQuery__
 *
 * To run a query within a React component, call `useGetAnonRegistrationByTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnonRegistrationByTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnonRegistrationByTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetAnonRegistrationByTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAnonRegistrationByTokenQuery,
    GetAnonRegistrationByTokenQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetAnonRegistrationByTokenQuery, GetAnonRegistrationByTokenQueryVariables>(
    GetAnonRegistrationByTokenDocument,
    options
  );
}
export function useGetAnonRegistrationByTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAnonRegistrationByTokenQuery,
    GetAnonRegistrationByTokenQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetAnonRegistrationByTokenQuery,
    GetAnonRegistrationByTokenQueryVariables
  >(GetAnonRegistrationByTokenDocument, options);
}
export type GetAnonRegistrationByTokenQueryHookResult = ReturnType<
  typeof useGetAnonRegistrationByTokenQuery
>;
export type GetAnonRegistrationByTokenLazyQueryHookResult = ReturnType<
  typeof useGetAnonRegistrationByTokenLazyQuery
>;
export type GetAnonRegistrationByTokenQueryResult = Apollo.QueryResult<
  GetAnonRegistrationByTokenQuery,
  GetAnonRegistrationByTokenQueryVariables
>;
export const GetPublicCalendarFeedDocument = gql`
  query GetPublicCalendarFeed {
    classEvents(
      where: {
        _and: [
          {startTime: {_gt: "now()"}}
          {cohort: {course: {catalogs: {catalog: {slug: {_eq: "public"}}}}}}
        ]
      }
    ) {
      ...CalendarClassEvent
    }
  }
  ${CalendarClassEventFragmentDoc}
`;

/**
 * __useGetPublicCalendarFeedQuery__
 *
 * To run a query within a React component, call `useGetPublicCalendarFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicCalendarFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicCalendarFeedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPublicCalendarFeedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPublicCalendarFeedQuery,
    GetPublicCalendarFeedQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetPublicCalendarFeedQuery, GetPublicCalendarFeedQueryVariables>(
    GetPublicCalendarFeedDocument,
    options
  );
}
export function useGetPublicCalendarFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPublicCalendarFeedQuery,
    GetPublicCalendarFeedQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetPublicCalendarFeedQuery, GetPublicCalendarFeedQueryVariables>(
    GetPublicCalendarFeedDocument,
    options
  );
}
export type GetPublicCalendarFeedQueryHookResult = ReturnType<typeof useGetPublicCalendarFeedQuery>;
export type GetPublicCalendarFeedLazyQueryHookResult = ReturnType<
  typeof useGetPublicCalendarFeedLazyQuery
>;
export type GetPublicCalendarFeedQueryResult = Apollo.QueryResult<
  GetPublicCalendarFeedQuery,
  GetPublicCalendarFeedQueryVariables
>;
export const GetStaffCalendarFeedDocument = gql`
  query GetStaffCalendarFeed {
    classEvents(where: {startTime: {_gt: "now()"}}) {
      ...CalendarClassEvent
    }
  }
  ${CalendarClassEventFragmentDoc}
`;

/**
 * __useGetStaffCalendarFeedQuery__
 *
 * To run a query within a React component, call `useGetStaffCalendarFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStaffCalendarFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStaffCalendarFeedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStaffCalendarFeedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStaffCalendarFeedQuery,
    GetStaffCalendarFeedQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetStaffCalendarFeedQuery, GetStaffCalendarFeedQueryVariables>(
    GetStaffCalendarFeedDocument,
    options
  );
}
export function useGetStaffCalendarFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStaffCalendarFeedQuery,
    GetStaffCalendarFeedQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetStaffCalendarFeedQuery, GetStaffCalendarFeedQueryVariables>(
    GetStaffCalendarFeedDocument,
    options
  );
}
export type GetStaffCalendarFeedQueryHookResult = ReturnType<typeof useGetStaffCalendarFeedQuery>;
export type GetStaffCalendarFeedLazyQueryHookResult = ReturnType<
  typeof useGetStaffCalendarFeedLazyQuery
>;
export type GetStaffCalendarFeedQueryResult = Apollo.QueryResult<
  GetStaffCalendarFeedQuery,
  GetStaffCalendarFeedQueryVariables
>;
export const GetManagerCalendarFeedDocument = gql`
  query GetManagerCalendarFeed($managerId: uuid!) {
    classEvents(
      where: {
        _and: [
          {createdById: {_eq: $managerId}}
          {startTime: {_gt: "now()"}}
          {cohort: {course: {catalogs: {catalog: {slug: {_eq: "public"}}}}}}
        ]
      }
    ) {
      ...CalendarClassEvent
    }
  }
  ${CalendarClassEventFragmentDoc}
`;

/**
 * __useGetManagerCalendarFeedQuery__
 *
 * To run a query within a React component, call `useGetManagerCalendarFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagerCalendarFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagerCalendarFeedQuery({
 *   variables: {
 *      managerId: // value for 'managerId'
 *   },
 * });
 */
export function useGetManagerCalendarFeedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagerCalendarFeedQuery,
    GetManagerCalendarFeedQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetManagerCalendarFeedQuery, GetManagerCalendarFeedQueryVariables>(
    GetManagerCalendarFeedDocument,
    options
  );
}
export function useGetManagerCalendarFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagerCalendarFeedQuery,
    GetManagerCalendarFeedQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetManagerCalendarFeedQuery, GetManagerCalendarFeedQueryVariables>(
    GetManagerCalendarFeedDocument,
    options
  );
}
export type GetManagerCalendarFeedQueryHookResult = ReturnType<
  typeof useGetManagerCalendarFeedQuery
>;
export type GetManagerCalendarFeedLazyQueryHookResult = ReturnType<
  typeof useGetManagerCalendarFeedLazyQuery
>;
export type GetManagerCalendarFeedQueryResult = Apollo.QueryResult<
  GetManagerCalendarFeedQuery,
  GetManagerCalendarFeedQueryVariables
>;
export const GetClassAttendanceDocument = gql`
  query GetClassAttendance($userId: uuid!) {
    enrollments(where: {userId: {_eq: $userId}}) {
      cohort {
        id
        classEvents {
          id
          classEventActivities(where: {type: {_eq: JOIN_ZOOM}}, limit: 1) {
            id
            type
          }
        }
      }
    }
  }
`;

/**
 * __useGetClassAttendanceQuery__
 *
 * To run a query within a React component, call `useGetClassAttendanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassAttendanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassAttendanceQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetClassAttendanceQuery(
  baseOptions: Apollo.QueryHookOptions<GetClassAttendanceQuery, GetClassAttendanceQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetClassAttendanceQuery, GetClassAttendanceQueryVariables>(
    GetClassAttendanceDocument,
    options
  );
}
export function useGetClassAttendanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassAttendanceQuery,
    GetClassAttendanceQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetClassAttendanceQuery, GetClassAttendanceQueryVariables>(
    GetClassAttendanceDocument,
    options
  );
}
export type GetClassAttendanceQueryHookResult = ReturnType<typeof useGetClassAttendanceQuery>;
export type GetClassAttendanceLazyQueryHookResult = ReturnType<
  typeof useGetClassAttendanceLazyQuery
>;
export type GetClassAttendanceQueryResult = Apollo.QueryResult<
  GetClassAttendanceQuery,
  GetClassAttendanceQueryVariables
>;
export const GetClassEventEndedDocument = gql`
  query GetClassEventEnded($classEventId: uuid!) {
    classEventActivity(
      where: {_and: [{classEventId: {_eq: $classEventId}}, {type: {_eq: END_CLASS}}]}
    ) {
      id
      createdAt
      createdBy {
        identity {
          email
        }
      }
    }
  }
`;

/**
 * __useGetClassEventEndedQuery__
 *
 * To run a query within a React component, call `useGetClassEventEndedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassEventEndedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassEventEndedQuery({
 *   variables: {
 *      classEventId: // value for 'classEventId'
 *   },
 * });
 */
export function useGetClassEventEndedQuery(
  baseOptions: Apollo.QueryHookOptions<GetClassEventEndedQuery, GetClassEventEndedQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetClassEventEndedQuery, GetClassEventEndedQueryVariables>(
    GetClassEventEndedDocument,
    options
  );
}
export function useGetClassEventEndedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassEventEndedQuery,
    GetClassEventEndedQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetClassEventEndedQuery, GetClassEventEndedQueryVariables>(
    GetClassEventEndedDocument,
    options
  );
}
export type GetClassEventEndedQueryHookResult = ReturnType<typeof useGetClassEventEndedQuery>;
export type GetClassEventEndedLazyQueryHookResult = ReturnType<
  typeof useGetClassEventEndedLazyQuery
>;
export type GetClassEventEndedQueryResult = Apollo.QueryResult<
  GetClassEventEndedQuery,
  GetClassEventEndedQueryVariables
>;
export const GetClassEventForCoachOpsDocument = gql`
  query GetClassEventForCoachOps($classEventId: uuid!) {
    classEventsByPk(id: $classEventId) {
      id
      startTime
      cohort {
        slug
      }
    }
  }
`;

/**
 * __useGetClassEventForCoachOpsQuery__
 *
 * To run a query within a React component, call `useGetClassEventForCoachOpsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassEventForCoachOpsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassEventForCoachOpsQuery({
 *   variables: {
 *      classEventId: // value for 'classEventId'
 *   },
 * });
 */
export function useGetClassEventForCoachOpsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClassEventForCoachOpsQuery,
    GetClassEventForCoachOpsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetClassEventForCoachOpsQuery, GetClassEventForCoachOpsQueryVariables>(
    GetClassEventForCoachOpsDocument,
    options
  );
}
export function useGetClassEventForCoachOpsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassEventForCoachOpsQuery,
    GetClassEventForCoachOpsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetClassEventForCoachOpsQuery, GetClassEventForCoachOpsQueryVariables>(
    GetClassEventForCoachOpsDocument,
    options
  );
}
export type GetClassEventForCoachOpsQueryHookResult = ReturnType<
  typeof useGetClassEventForCoachOpsQuery
>;
export type GetClassEventForCoachOpsLazyQueryHookResult = ReturnType<
  typeof useGetClassEventForCoachOpsLazyQuery
>;
export type GetClassEventForCoachOpsQueryResult = Apollo.QueryResult<
  GetClassEventForCoachOpsQuery,
  GetClassEventForCoachOpsQueryVariables
>;
export const GetClassEventForDailyMeetingDocument = gql`
  query GetClassEventForDailyMeeting($classEventId: uuid!) {
    classEventsByPk(id: $classEventId) {
      id
      startTime
      endTime
      class {
        name
        coaches {
          coach {
            id
            name
            title
            company
            avatarUrl
            user {
              id
              identity {
                email
              }
            }
          }
        }
      }
      cohort {
        slug
        coaches {
          coach {
            id
            name
            title
            company
            avatarUrl
            user {
              id
              identity {
                email
              }
            }
          }
        }
        course {
          type
          coaches {
            coach {
              id
              name
              title
              company
              avatarUrl
              user {
                id
                identity {
                  email
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetClassEventForDailyMeetingQuery__
 *
 * To run a query within a React component, call `useGetClassEventForDailyMeetingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassEventForDailyMeetingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassEventForDailyMeetingQuery({
 *   variables: {
 *      classEventId: // value for 'classEventId'
 *   },
 * });
 */
export function useGetClassEventForDailyMeetingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClassEventForDailyMeetingQuery,
    GetClassEventForDailyMeetingQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetClassEventForDailyMeetingQuery,
    GetClassEventForDailyMeetingQueryVariables
  >(GetClassEventForDailyMeetingDocument, options);
}
export function useGetClassEventForDailyMeetingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassEventForDailyMeetingQuery,
    GetClassEventForDailyMeetingQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetClassEventForDailyMeetingQuery,
    GetClassEventForDailyMeetingQueryVariables
  >(GetClassEventForDailyMeetingDocument, options);
}
export type GetClassEventForDailyMeetingQueryHookResult = ReturnType<
  typeof useGetClassEventForDailyMeetingQuery
>;
export type GetClassEventForDailyMeetingLazyQueryHookResult = ReturnType<
  typeof useGetClassEventForDailyMeetingLazyQuery
>;
export type GetClassEventForDailyMeetingQueryResult = Apollo.QueryResult<
  GetClassEventForDailyMeetingQuery,
  GetClassEventForDailyMeetingQueryVariables
>;
export const GetClassEventForRegistrationDocument = gql`
  query GetClassEventForRegistration($classEventId: uuid!) {
    classEventsByPk(id: $classEventId) {
      id
      startTime
      endTime
      zoomMeetingId
      class {
        number
        name
        isLive
      }
      cohort {
        inAppVideo
        course {
          name
          publishStatus
        }
      }
    }
  }
`;

/**
 * __useGetClassEventForRegistrationQuery__
 *
 * To run a query within a React component, call `useGetClassEventForRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassEventForRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassEventForRegistrationQuery({
 *   variables: {
 *      classEventId: // value for 'classEventId'
 *   },
 * });
 */
export function useGetClassEventForRegistrationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClassEventForRegistrationQuery,
    GetClassEventForRegistrationQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetClassEventForRegistrationQuery,
    GetClassEventForRegistrationQueryVariables
  >(GetClassEventForRegistrationDocument, options);
}
export function useGetClassEventForRegistrationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassEventForRegistrationQuery,
    GetClassEventForRegistrationQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetClassEventForRegistrationQuery,
    GetClassEventForRegistrationQueryVariables
  >(GetClassEventForRegistrationDocument, options);
}
export type GetClassEventForRegistrationQueryHookResult = ReturnType<
  typeof useGetClassEventForRegistrationQuery
>;
export type GetClassEventForRegistrationLazyQueryHookResult = ReturnType<
  typeof useGetClassEventForRegistrationLazyQuery
>;
export type GetClassEventForRegistrationQueryResult = Apollo.QueryResult<
  GetClassEventForRegistrationQuery,
  GetClassEventForRegistrationQueryVariables
>;
export const GetClassEventForZoomMeetingDocument = gql`
  query GetClassEventForZoomMeeting($classEventId: uuid!) {
    classEventsByPk(id: $classEventId) {
      startTime
      endTime
      zoomMeetingId
      class {
        name
        isLive
        coaches(orderBy: {coachId: ASC}) {
          coach {
            id
            user {
              id
              firstName
              lastName
              identity {
                email
              }
            }
          }
        }
      }
      cohort {
        course {
          name
          publishStatus
          manager {
            identity {
              email
            }
          }
          coaches(orderBy: {coachId: ASC}) {
            coach {
              id
              user {
                id
                firstName
                lastName
                identity {
                  email
                }
              }
            }
          }
        }
        coaches(orderBy: {coachId: ASC}) {
          coach {
            id
            user {
              id
              firstName
              lastName
              identity {
                email
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetClassEventForZoomMeetingQuery__
 *
 * To run a query within a React component, call `useGetClassEventForZoomMeetingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassEventForZoomMeetingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassEventForZoomMeetingQuery({
 *   variables: {
 *      classEventId: // value for 'classEventId'
 *   },
 * });
 */
export function useGetClassEventForZoomMeetingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClassEventForZoomMeetingQuery,
    GetClassEventForZoomMeetingQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetClassEventForZoomMeetingQuery,
    GetClassEventForZoomMeetingQueryVariables
  >(GetClassEventForZoomMeetingDocument, options);
}
export function useGetClassEventForZoomMeetingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassEventForZoomMeetingQuery,
    GetClassEventForZoomMeetingQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetClassEventForZoomMeetingQuery,
    GetClassEventForZoomMeetingQueryVariables
  >(GetClassEventForZoomMeetingDocument, options);
}
export type GetClassEventForZoomMeetingQueryHookResult = ReturnType<
  typeof useGetClassEventForZoomMeetingQuery
>;
export type GetClassEventForZoomMeetingLazyQueryHookResult = ReturnType<
  typeof useGetClassEventForZoomMeetingLazyQuery
>;
export type GetClassEventForZoomMeetingQueryResult = Apollo.QueryResult<
  GetClassEventForZoomMeetingQuery,
  GetClassEventForZoomMeetingQueryVariables
>;
export const GetClassEventMeetingSegmentsToSyncDocument = gql`
  query GetClassEventMeetingSegmentsToSync {
    classEventMeetingSegments(where: {_or: [{synced: {_eq: false}}, {synced: {_isNull: true}}]}) {
      id
      classEventMeeting {
        id
        recordingUrl
      }
      lightningSubmissions {
        id
      }
      videoClipUrl
      audioClipUrl
      transcription
      jobs
      startTimecode
      endTimecode
      duration
    }
  }
`;

/**
 * __useGetClassEventMeetingSegmentsToSyncQuery__
 *
 * To run a query within a React component, call `useGetClassEventMeetingSegmentsToSyncQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassEventMeetingSegmentsToSyncQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassEventMeetingSegmentsToSyncQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClassEventMeetingSegmentsToSyncQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClassEventMeetingSegmentsToSyncQuery,
    GetClassEventMeetingSegmentsToSyncQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetClassEventMeetingSegmentsToSyncQuery,
    GetClassEventMeetingSegmentsToSyncQueryVariables
  >(GetClassEventMeetingSegmentsToSyncDocument, options);
}
export function useGetClassEventMeetingSegmentsToSyncLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassEventMeetingSegmentsToSyncQuery,
    GetClassEventMeetingSegmentsToSyncQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetClassEventMeetingSegmentsToSyncQuery,
    GetClassEventMeetingSegmentsToSyncQueryVariables
  >(GetClassEventMeetingSegmentsToSyncDocument, options);
}
export type GetClassEventMeetingSegmentsToSyncQueryHookResult = ReturnType<
  typeof useGetClassEventMeetingSegmentsToSyncQuery
>;
export type GetClassEventMeetingSegmentsToSyncLazyQueryHookResult = ReturnType<
  typeof useGetClassEventMeetingSegmentsToSyncLazyQuery
>;
export type GetClassEventMeetingSegmentsToSyncQueryResult = Apollo.QueryResult<
  GetClassEventMeetingSegmentsToSyncQuery,
  GetClassEventMeetingSegmentsToSyncQueryVariables
>;
export const GetClassEventMeetingsDocument = gql`
  query GetClassEventMeetings($where: ClassEventMeetingsBoolExp!) {
    classEventMeetings(where: $where) {
      id
      room
      classEventId
      parentMeetingId
      recordingUrl
      posterFrameUrl
      duration
      maxParticipants
      synced
      altRecordingUrl
      participants {
        user {
          id
          firstName
          lastName
          imageUrl
        }
      }
    }
  }
`;

/**
 * __useGetClassEventMeetingsQuery__
 *
 * To run a query within a React component, call `useGetClassEventMeetingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassEventMeetingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassEventMeetingsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetClassEventMeetingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClassEventMeetingsQuery,
    GetClassEventMeetingsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetClassEventMeetingsQuery, GetClassEventMeetingsQueryVariables>(
    GetClassEventMeetingsDocument,
    options
  );
}
export function useGetClassEventMeetingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassEventMeetingsQuery,
    GetClassEventMeetingsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetClassEventMeetingsQuery, GetClassEventMeetingsQueryVariables>(
    GetClassEventMeetingsDocument,
    options
  );
}
export type GetClassEventMeetingsQueryHookResult = ReturnType<typeof useGetClassEventMeetingsQuery>;
export type GetClassEventMeetingsLazyQueryHookResult = ReturnType<
  typeof useGetClassEventMeetingsLazyQuery
>;
export type GetClassEventMeetingsQueryResult = Apollo.QueryResult<
  GetClassEventMeetingsQuery,
  GetClassEventMeetingsQueryVariables
>;
export const GetClassEventMeetingsForPosterBackfillDocument = gql`
  query GetClassEventMeetingsForPosterBackfill {
    classEventMeetings(
      where: {
        _and: [
          {synced: {_eq: true}}
          {posterFrameUrl: {_isNull: true}}
          {recordingUrl: {_ilike: "%meeting%"}}
        ]
      }
      orderBy: {startTime: ASC}
      limit: 50
    ) {
      id
      recordingUrl
      posterFrameUrl
    }
  }
`;

/**
 * __useGetClassEventMeetingsForPosterBackfillQuery__
 *
 * To run a query within a React component, call `useGetClassEventMeetingsForPosterBackfillQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassEventMeetingsForPosterBackfillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassEventMeetingsForPosterBackfillQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClassEventMeetingsForPosterBackfillQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClassEventMeetingsForPosterBackfillQuery,
    GetClassEventMeetingsForPosterBackfillQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetClassEventMeetingsForPosterBackfillQuery,
    GetClassEventMeetingsForPosterBackfillQueryVariables
  >(GetClassEventMeetingsForPosterBackfillDocument, options);
}
export function useGetClassEventMeetingsForPosterBackfillLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassEventMeetingsForPosterBackfillQuery,
    GetClassEventMeetingsForPosterBackfillQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetClassEventMeetingsForPosterBackfillQuery,
    GetClassEventMeetingsForPosterBackfillQueryVariables
  >(GetClassEventMeetingsForPosterBackfillDocument, options);
}
export type GetClassEventMeetingsForPosterBackfillQueryHookResult = ReturnType<
  typeof useGetClassEventMeetingsForPosterBackfillQuery
>;
export type GetClassEventMeetingsForPosterBackfillLazyQueryHookResult = ReturnType<
  typeof useGetClassEventMeetingsForPosterBackfillLazyQuery
>;
export type GetClassEventMeetingsForPosterBackfillQueryResult = Apollo.QueryResult<
  GetClassEventMeetingsForPosterBackfillQuery,
  GetClassEventMeetingsForPosterBackfillQueryVariables
>;
export const GetClassEventMeetingsToSyncDocument = gql`
  query GetClassEventMeetingsToSync {
    classEventMeetings(
      where: {
        _and: [
          {synced: {_eq: false}}
          {
            classEvent: {
              _and: [
                {cohort: {type: {_eq: LIVE}}}
                {cohort: {visibility: {_eq: VISIBLE}}}
                {cohort: {inAppVideo: {_eq: true}}}
                {
                  _or: [
                    {endTime: {_lte: "now()"}}
                    {
                      classEventActivitiesAggregate: {
                        count: {filter: {type: {_eq: END_CLASS}}, predicate: {_gte: 1}}
                      }
                    }
                  ]
                }
              ]
            }
          }
        ]
      }
    ) {
      breakoutMeetings {
        id
        maxParticipants
        recordingUrl
        room
        startTime
      }
      classEventId
      classEvent {
        classId
        cohortId
        cohort {
          courseId
          slug
        }
        id
      }
      id
      maxParticipants
      parentMeetingId
      recordingUrl
      room
      dailySessionId
      startTime
      awsMediaConvertJobId
    }
  }
`;

/**
 * __useGetClassEventMeetingsToSyncQuery__
 *
 * To run a query within a React component, call `useGetClassEventMeetingsToSyncQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassEventMeetingsToSyncQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassEventMeetingsToSyncQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClassEventMeetingsToSyncQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClassEventMeetingsToSyncQuery,
    GetClassEventMeetingsToSyncQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetClassEventMeetingsToSyncQuery,
    GetClassEventMeetingsToSyncQueryVariables
  >(GetClassEventMeetingsToSyncDocument, options);
}
export function useGetClassEventMeetingsToSyncLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassEventMeetingsToSyncQuery,
    GetClassEventMeetingsToSyncQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetClassEventMeetingsToSyncQuery,
    GetClassEventMeetingsToSyncQueryVariables
  >(GetClassEventMeetingsToSyncDocument, options);
}
export type GetClassEventMeetingsToSyncQueryHookResult = ReturnType<
  typeof useGetClassEventMeetingsToSyncQuery
>;
export type GetClassEventMeetingsToSyncLazyQueryHookResult = ReturnType<
  typeof useGetClassEventMeetingsToSyncLazyQuery
>;
export type GetClassEventMeetingsToSyncQueryResult = Apollo.QueryResult<
  GetClassEventMeetingsToSyncQuery,
  GetClassEventMeetingsToSyncQueryVariables
>;
export const GetClassEventsForParticipationSyncDocument = gql`
  query GetClassEventsForParticipationSync {
    classEvents(
      where: {
        _and: [
          {numMeetings: {_eq: 0}}
          {class: {isLive: {_eq: true}}}
          {cohort: {type: {_eq: LIVE}}}
          {cohort: {visibility: {_eq: VISIBLE}}}
          {cohort: {inAppVideo: {_eq: true}}}
          {
            _or: [
              {endTime: {_lte: "now()"}}
              {
                classEventActivitiesAggregate: {
                  count: {filter: {type: {_eq: END_CLASS}}, predicate: {_gte: 1}}
                }
              }
            ]
          }
        ]
      }
    ) {
      id
      startTime
      cohort {
        slug
      }
    }
  }
`;

/**
 * __useGetClassEventsForParticipationSyncQuery__
 *
 * To run a query within a React component, call `useGetClassEventsForParticipationSyncQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassEventsForParticipationSyncQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassEventsForParticipationSyncQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClassEventsForParticipationSyncQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClassEventsForParticipationSyncQuery,
    GetClassEventsForParticipationSyncQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetClassEventsForParticipationSyncQuery,
    GetClassEventsForParticipationSyncQueryVariables
  >(GetClassEventsForParticipationSyncDocument, options);
}
export function useGetClassEventsForParticipationSyncLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassEventsForParticipationSyncQuery,
    GetClassEventsForParticipationSyncQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetClassEventsForParticipationSyncQuery,
    GetClassEventsForParticipationSyncQueryVariables
  >(GetClassEventsForParticipationSyncDocument, options);
}
export type GetClassEventsForParticipationSyncQueryHookResult = ReturnType<
  typeof useGetClassEventsForParticipationSyncQuery
>;
export type GetClassEventsForParticipationSyncLazyQueryHookResult = ReturnType<
  typeof useGetClassEventsForParticipationSyncLazyQuery
>;
export type GetClassEventsForParticipationSyncQueryResult = Apollo.QueryResult<
  GetClassEventsForParticipationSyncQuery,
  GetClassEventsForParticipationSyncQueryVariables
>;
export const GetClassParticipantStatsDocument = gql`
  query GetClassParticipantStats($userIds: [uuid!]!, $classEventId: uuid!) {
    usersMeta(
      where: {
        userId: {_in: $userIds}
        user: {
          identity: {email: {_nlike: "%@salesimpact.io%"}}
          coachesAggregate: {count: {predicate: {_eq: 0}}}
        }
      }
      orderBy: {user: {firstName: ASC, lastName: ASC}}
    ) {
      user {
        id
        firstName
        lastName
        imageUrl
      }
      organization {
        name
      }
      userId
      jobFunctionData {
        description
      }
      hasParticipatedInLightningSession
    }
    countNonLearners: usersMetaAggregate(
      where: {
        _and: {
          userId: {_in: $userIds}
          _or: [
            {user: {identity: {email: {_like: "%@salesimpact.io%"}}}}
            {user: {coachesAggregate: {count: {predicate: {_gte: 1}}}}}
          ]
        }
      }
    ) {
      aggregate {
        count
      }
    }
    enrollemntsStats: classEventsByPk(id: $classEventId) {
      cohort {
        enrollmentsAggregate {
          total: aggregate {
            count
          }
        }
      }
    }
  }
`;

/**
 * __useGetClassParticipantStatsQuery__
 *
 * To run a query within a React component, call `useGetClassParticipantStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassParticipantStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassParticipantStatsQuery({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      classEventId: // value for 'classEventId'
 *   },
 * });
 */
export function useGetClassParticipantStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClassParticipantStatsQuery,
    GetClassParticipantStatsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetClassParticipantStatsQuery, GetClassParticipantStatsQueryVariables>(
    GetClassParticipantStatsDocument,
    options
  );
}
export function useGetClassParticipantStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassParticipantStatsQuery,
    GetClassParticipantStatsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetClassParticipantStatsQuery, GetClassParticipantStatsQueryVariables>(
    GetClassParticipantStatsDocument,
    options
  );
}
export type GetClassParticipantStatsQueryHookResult = ReturnType<
  typeof useGetClassParticipantStatsQuery
>;
export type GetClassParticipantStatsLazyQueryHookResult = ReturnType<
  typeof useGetClassParticipantStatsLazyQuery
>;
export type GetClassParticipantStatsQueryResult = Apollo.QueryResult<
  GetClassParticipantStatsQuery,
  GetClassParticipantStatsQueryVariables
>;
export const GetCoachParticipantsForClassEventMeetingDocument = gql`
  query GetCoachParticipantsForClassEventMeeting(
    $classEventMeetingId: uuid!
    $courseId: uuid!
    $classId: uuid!
    $cohortId: uuid!
  ) {
    coachParticipants: classEventMeetingParticipantsAggregate(
      where: {
        classEventMeetingId: {_eq: $classEventMeetingId}
        user: {
          coachesAggregate: {
            count: {
              filter: {
                _or: [
                  {
                    classesAggregate: {
                      count: {filter: {classId: {_eq: $classId}}, predicate: {_gte: 1}}
                    }
                  }
                  {
                    cohortsAggregate: {
                      count: {filter: {cohortId: {_eq: $cohortId}}, predicate: {_gte: 1}}
                    }
                  }
                  {
                    coursesAggregate: {
                      count: {filter: {courseId: {_eq: $courseId}}, predicate: {_gte: 1}}
                    }
                  }
                ]
              }
              predicate: {_gte: 1}
            }
          }
        }
      }
    ) {
      nodes {
        userId
        userName
      }
    }
    learnerParticipants: classEventMeetingParticipantsAggregate(
      where: {
        classEventMeetingId: {_eq: $classEventMeetingId}
        user: {coachesAggregate: {count: {predicate: {_eq: 0}}}}
      }
    ) {
      nodes {
        userId
        userName
        user {
          organizationUsers {
            organization {
              id
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetCoachParticipantsForClassEventMeetingQuery__
 *
 * To run a query within a React component, call `useGetCoachParticipantsForClassEventMeetingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoachParticipantsForClassEventMeetingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoachParticipantsForClassEventMeetingQuery({
 *   variables: {
 *      classEventMeetingId: // value for 'classEventMeetingId'
 *      courseId: // value for 'courseId'
 *      classId: // value for 'classId'
 *      cohortId: // value for 'cohortId'
 *   },
 * });
 */
export function useGetCoachParticipantsForClassEventMeetingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCoachParticipantsForClassEventMeetingQuery,
    GetCoachParticipantsForClassEventMeetingQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetCoachParticipantsForClassEventMeetingQuery,
    GetCoachParticipantsForClassEventMeetingQueryVariables
  >(GetCoachParticipantsForClassEventMeetingDocument, options);
}
export function useGetCoachParticipantsForClassEventMeetingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCoachParticipantsForClassEventMeetingQuery,
    GetCoachParticipantsForClassEventMeetingQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetCoachParticipantsForClassEventMeetingQuery,
    GetCoachParticipantsForClassEventMeetingQueryVariables
  >(GetCoachParticipantsForClassEventMeetingDocument, options);
}
export type GetCoachParticipantsForClassEventMeetingQueryHookResult = ReturnType<
  typeof useGetCoachParticipantsForClassEventMeetingQuery
>;
export type GetCoachParticipantsForClassEventMeetingLazyQueryHookResult = ReturnType<
  typeof useGetCoachParticipantsForClassEventMeetingLazyQuery
>;
export type GetCoachParticipantsForClassEventMeetingQueryResult = Apollo.QueryResult<
  GetCoachParticipantsForClassEventMeetingQuery,
  GetCoachParticipantsForClassEventMeetingQueryVariables
>;
export const GetFutureClassEventsForCoachDocument = gql`
  query GetFutureClassEventsForCoach($coachId: uuid!, $now: timestamp, $limit: Int) {
    classEvents(
      where: {
        endTime: {_gte: $now}
        class: {number: {_gt: 0}}
        cohort: {type: {_eq: LIVE}, coaches: {coachId: {_eq: $coachId}}}
      }
      orderBy: {startTime: ASC}
      limit: $limit
    ) {
      cohort {
        name
        course {
          name
        }
      }
      startTime
      endTime
      id
      class {
        name
        number
      }
    }
  }
`;

/**
 * __useGetFutureClassEventsForCoachQuery__
 *
 * To run a query within a React component, call `useGetFutureClassEventsForCoachQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFutureClassEventsForCoachQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFutureClassEventsForCoachQuery({
 *   variables: {
 *      coachId: // value for 'coachId'
 *      now: // value for 'now'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetFutureClassEventsForCoachQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFutureClassEventsForCoachQuery,
    GetFutureClassEventsForCoachQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetFutureClassEventsForCoachQuery,
    GetFutureClassEventsForCoachQueryVariables
  >(GetFutureClassEventsForCoachDocument, options);
}
export function useGetFutureClassEventsForCoachLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFutureClassEventsForCoachQuery,
    GetFutureClassEventsForCoachQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetFutureClassEventsForCoachQuery,
    GetFutureClassEventsForCoachQueryVariables
  >(GetFutureClassEventsForCoachDocument, options);
}
export type GetFutureClassEventsForCoachQueryHookResult = ReturnType<
  typeof useGetFutureClassEventsForCoachQuery
>;
export type GetFutureClassEventsForCoachLazyQueryHookResult = ReturnType<
  typeof useGetFutureClassEventsForCoachLazyQuery
>;
export type GetFutureClassEventsForCoachQueryResult = Apollo.QueryResult<
  GetFutureClassEventsForCoachQuery,
  GetFutureClassEventsForCoachQueryVariables
>;
export const GetLightningSessionDocument = gql`
  query GetLightningSession($classEventId: uuid!) {
    lightningSession(where: {classEventId: {_eq: $classEventId}}) {
      id
      rooms
      borDetails
      startTime
      endTime
    }
  }
`;

/**
 * __useGetLightningSessionQuery__
 *
 * To run a query within a React component, call `useGetLightningSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLightningSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLightningSessionQuery({
 *   variables: {
 *      classEventId: // value for 'classEventId'
 *   },
 * });
 */
export function useGetLightningSessionQuery(
  baseOptions: Apollo.QueryHookOptions<GetLightningSessionQuery, GetLightningSessionQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetLightningSessionQuery, GetLightningSessionQueryVariables>(
    GetLightningSessionDocument,
    options
  );
}
export function useGetLightningSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLightningSessionQuery,
    GetLightningSessionQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetLightningSessionQuery, GetLightningSessionQueryVariables>(
    GetLightningSessionDocument,
    options
  );
}
export type GetLightningSessionQueryHookResult = ReturnType<typeof useGetLightningSessionQuery>;
export type GetLightningSessionLazyQueryHookResult = ReturnType<
  typeof useGetLightningSessionLazyQuery
>;
export type GetLightningSessionQueryResult = Apollo.QueryResult<
  GetLightningSessionQuery,
  GetLightningSessionQueryVariables
>;
export const GetLightningSessionStatusDocument = gql`
  query GetLightningSessionStatus($classEventId: uuid!) {
    lightningSession(where: {classEventId: {_eq: $classEventId}}) {
      id
      startTime
      endTime
    }
  }
`;

/**
 * __useGetLightningSessionStatusQuery__
 *
 * To run a query within a React component, call `useGetLightningSessionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLightningSessionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLightningSessionStatusQuery({
 *   variables: {
 *      classEventId: // value for 'classEventId'
 *   },
 * });
 */
export function useGetLightningSessionStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLightningSessionStatusQuery,
    GetLightningSessionStatusQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetLightningSessionStatusQuery, GetLightningSessionStatusQueryVariables>(
    GetLightningSessionStatusDocument,
    options
  );
}
export function useGetLightningSessionStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLightningSessionStatusQuery,
    GetLightningSessionStatusQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetLightningSessionStatusQuery,
    GetLightningSessionStatusQueryVariables
  >(GetLightningSessionStatusDocument, options);
}
export type GetLightningSessionStatusQueryHookResult = ReturnType<
  typeof useGetLightningSessionStatusQuery
>;
export type GetLightningSessionStatusLazyQueryHookResult = ReturnType<
  typeof useGetLightningSessionStatusLazyQuery
>;
export type GetLightningSessionStatusQueryResult = Apollo.QueryResult<
  GetLightningSessionStatusQuery,
  GetLightningSessionStatusQueryVariables
>;
export const GetMeetingsForClassEventDocument = gql`
  query GetMeetingsForClassEvent($classEventId: uuid!) {
    parentMeeting: classEventMeetings(
      where: {_and: [{classEventId: {_eq: $classEventId}}, {parentMeetingId: {_isNull: true}}]}
    ) {
      id
      room
      classEventId
    }
    childMeetings: classEventMeetings(
      where: {_and: [{classEventId: {_eq: $classEventId}}, {parentMeetingId: {_isNull: false}}]}
    ) {
      id
      room
      classEventId
    }
  }
`;

/**
 * __useGetMeetingsForClassEventQuery__
 *
 * To run a query within a React component, call `useGetMeetingsForClassEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeetingsForClassEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeetingsForClassEventQuery({
 *   variables: {
 *      classEventId: // value for 'classEventId'
 *   },
 * });
 */
export function useGetMeetingsForClassEventQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMeetingsForClassEventQuery,
    GetMeetingsForClassEventQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetMeetingsForClassEventQuery, GetMeetingsForClassEventQueryVariables>(
    GetMeetingsForClassEventDocument,
    options
  );
}
export function useGetMeetingsForClassEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMeetingsForClassEventQuery,
    GetMeetingsForClassEventQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetMeetingsForClassEventQuery, GetMeetingsForClassEventQueryVariables>(
    GetMeetingsForClassEventDocument,
    options
  );
}
export type GetMeetingsForClassEventQueryHookResult = ReturnType<
  typeof useGetMeetingsForClassEventQuery
>;
export type GetMeetingsForClassEventLazyQueryHookResult = ReturnType<
  typeof useGetMeetingsForClassEventLazyQuery
>;
export type GetMeetingsForClassEventQueryResult = Apollo.QueryResult<
  GetMeetingsForClassEventQuery,
  GetMeetingsForClassEventQueryVariables
>;
export const GetRegisteredUsersForClassEventDocument = gql`
  query GetRegisteredUsersForClassEvent($classEventId: uuid!) {
    classEventRegistrations(
      where: {_and: [{classEventId: {_eq: $classEventId}}, {zoomJoinLinkUrl: {_isNull: false}}]}
    ) {
      userId
    }
    classEventAnonRegistrations(where: {classEventId: {_eq: $classEventId}}) {
      email
    }
  }
`;

/**
 * __useGetRegisteredUsersForClassEventQuery__
 *
 * To run a query within a React component, call `useGetRegisteredUsersForClassEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegisteredUsersForClassEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegisteredUsersForClassEventQuery({
 *   variables: {
 *      classEventId: // value for 'classEventId'
 *   },
 * });
 */
export function useGetRegisteredUsersForClassEventQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRegisteredUsersForClassEventQuery,
    GetRegisteredUsersForClassEventQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetRegisteredUsersForClassEventQuery,
    GetRegisteredUsersForClassEventQueryVariables
  >(GetRegisteredUsersForClassEventDocument, options);
}
export function useGetRegisteredUsersForClassEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRegisteredUsersForClassEventQuery,
    GetRegisteredUsersForClassEventQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetRegisteredUsersForClassEventQuery,
    GetRegisteredUsersForClassEventQueryVariables
  >(GetRegisteredUsersForClassEventDocument, options);
}
export type GetRegisteredUsersForClassEventQueryHookResult = ReturnType<
  typeof useGetRegisteredUsersForClassEventQuery
>;
export type GetRegisteredUsersForClassEventLazyQueryHookResult = ReturnType<
  typeof useGetRegisteredUsersForClassEventLazyQuery
>;
export type GetRegisteredUsersForClassEventQueryResult = Apollo.QueryResult<
  GetRegisteredUsersForClassEventQuery,
  GetRegisteredUsersForClassEventQueryVariables
>;
export const GetTypeformResponseByClassEventDocument = gql`
  query GetTypeformResponseByClassEvent(
    $formIds: [String!]!
    $userId: uuid!
    $classEventId: uuid!
  ) {
    typeformSubmissions(
      where: {formId: {_in: $formIds}, userId: {_eq: $userId}, classEventId: {_eq: $classEventId}}
    ) {
      id
      formId
      userId
      data
      classEventId
    }
  }
`;

/**
 * __useGetTypeformResponseByClassEventQuery__
 *
 * To run a query within a React component, call `useGetTypeformResponseByClassEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTypeformResponseByClassEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTypeformResponseByClassEventQuery({
 *   variables: {
 *      formIds: // value for 'formIds'
 *      userId: // value for 'userId'
 *      classEventId: // value for 'classEventId'
 *   },
 * });
 */
export function useGetTypeformResponseByClassEventQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTypeformResponseByClassEventQuery,
    GetTypeformResponseByClassEventQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetTypeformResponseByClassEventQuery,
    GetTypeformResponseByClassEventQueryVariables
  >(GetTypeformResponseByClassEventDocument, options);
}
export function useGetTypeformResponseByClassEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTypeformResponseByClassEventQuery,
    GetTypeformResponseByClassEventQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetTypeformResponseByClassEventQuery,
    GetTypeformResponseByClassEventQueryVariables
  >(GetTypeformResponseByClassEventDocument, options);
}
export type GetTypeformResponseByClassEventQueryHookResult = ReturnType<
  typeof useGetTypeformResponseByClassEventQuery
>;
export type GetTypeformResponseByClassEventLazyQueryHookResult = ReturnType<
  typeof useGetTypeformResponseByClassEventLazyQuery
>;
export type GetTypeformResponseByClassEventQueryResult = Apollo.QueryResult<
  GetTypeformResponseByClassEventQuery,
  GetTypeformResponseByClassEventQueryVariables
>;
export const GetAllCoachesDocument = gql`
  query GetAllCoaches($orderBy: [CoachesOrderBy!]) {
    coaches(orderBy: $orderBy) {
      id
      name
      slug
    }
  }
`;

/**
 * __useGetAllCoachesQuery__
 *
 * To run a query within a React component, call `useGetAllCoachesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCoachesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCoachesQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllCoachesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllCoachesQuery, GetAllCoachesQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetAllCoachesQuery, GetAllCoachesQueryVariables>(
    GetAllCoachesDocument,
    options
  );
}
export function useGetAllCoachesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllCoachesQuery, GetAllCoachesQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetAllCoachesQuery, GetAllCoachesQueryVariables>(
    GetAllCoachesDocument,
    options
  );
}
export type GetAllCoachesQueryHookResult = ReturnType<typeof useGetAllCoachesQuery>;
export type GetAllCoachesLazyQueryHookResult = ReturnType<typeof useGetAllCoachesLazyQuery>;
export type GetAllCoachesQueryResult = Apollo.QueryResult<
  GetAllCoachesQuery,
  GetAllCoachesQueryVariables
>;
export const GetCoachForUserDocument = gql`
  query GetCoachForUser($userId: uuid!) {
    coaches(where: {userId: {_eq: $userId}}) {
      id
    }
  }
`;

/**
 * __useGetCoachForUserQuery__
 *
 * To run a query within a React component, call `useGetCoachForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoachForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoachForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetCoachForUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetCoachForUserQuery, GetCoachForUserQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetCoachForUserQuery, GetCoachForUserQueryVariables>(
    GetCoachForUserDocument,
    options
  );
}
export function useGetCoachForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCoachForUserQuery, GetCoachForUserQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetCoachForUserQuery, GetCoachForUserQueryVariables>(
    GetCoachForUserDocument,
    options
  );
}
export type GetCoachForUserQueryHookResult = ReturnType<typeof useGetCoachForUserQuery>;
export type GetCoachForUserLazyQueryHookResult = ReturnType<typeof useGetCoachForUserLazyQuery>;
export type GetCoachForUserQueryResult = Apollo.QueryResult<
  GetCoachForUserQuery,
  GetCoachForUserQueryVariables
>;
export const GetCoachesForClassEventDocument = gql`
  query GetCoachesForClassEvent($classEventId: uuid!) {
    classEventsByPk(id: $classEventId) {
      class {
        coaches {
          coach {
            id
            name
            title
            company
            avatarUrl
            user {
              id
              identity {
                email
              }
            }
          }
        }
      }
      cohort {
        coaches {
          coach {
            id
            name
            title
            company
            avatarUrl
            user {
              id
              identity {
                email
              }
            }
          }
        }
        course {
          coaches {
            coach {
              id
              name
              title
              company
              avatarUrl
              user {
                id
                identity {
                  email
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetCoachesForClassEventQuery__
 *
 * To run a query within a React component, call `useGetCoachesForClassEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoachesForClassEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoachesForClassEventQuery({
 *   variables: {
 *      classEventId: // value for 'classEventId'
 *   },
 * });
 */
export function useGetCoachesForClassEventQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCoachesForClassEventQuery,
    GetCoachesForClassEventQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetCoachesForClassEventQuery, GetCoachesForClassEventQueryVariables>(
    GetCoachesForClassEventDocument,
    options
  );
}
export function useGetCoachesForClassEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCoachesForClassEventQuery,
    GetCoachesForClassEventQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetCoachesForClassEventQuery, GetCoachesForClassEventQueryVariables>(
    GetCoachesForClassEventDocument,
    options
  );
}
export type GetCoachesForClassEventQueryHookResult = ReturnType<
  typeof useGetCoachesForClassEventQuery
>;
export type GetCoachesForClassEventLazyQueryHookResult = ReturnType<
  typeof useGetCoachesForClassEventLazyQuery
>;
export type GetCoachesForClassEventQueryResult = Apollo.QueryResult<
  GetCoachesForClassEventQuery,
  GetCoachesForClassEventQueryVariables
>;
export const GetPrepDocument = gql`
  query GetPrep($classEventId: uuid!) {
    cohorts(where: {classEvents: {id: {_eq: $classEventId}}}) {
      id
      slug
      name
      course {
        id
        slug
        name
        shortDescription
        longDescriptionRich
      }
      classEvents(where: {id: {_eq: $classEventId}}) {
        id
        startTime
        endTime
        class {
          id
          name
        }
      }
      enrollments(where: {_and: [{deletedAt: {_isNull: true}}, {user: {active: {_eq: true}}}]}) {
        id
        organization {
          id
          name
          insertInstant
          meta {
            companySize
            industry
            productSold
            targetCustomer
            type
            websiteLink
            linkedInUrl
            customerCreatedAt
          }
        }
        user {
          id
          firstName
          lastName
          identity {
            email
          }
          imageUrl
          meta {
            jobFunction
            jobFunctionData {
              description
              value
            }
            jobFunctionLength
            jobLevel
            jobTitle
            locale
            managerId
            hasParticipatedInLightningSession
          }
        }
      }
    }
    usersFunctions {
      value
      description
    }
    usersLengthInFunction {
      value
      description
    }
  }
`;

/**
 * __useGetPrepQuery__
 *
 * To run a query within a React component, call `useGetPrepQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrepQuery({
 *   variables: {
 *      classEventId: // value for 'classEventId'
 *   },
 * });
 */
export function useGetPrepQuery(
  baseOptions: Apollo.QueryHookOptions<GetPrepQuery, GetPrepQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetPrepQuery, GetPrepQueryVariables>(GetPrepDocument, options);
}
export function useGetPrepLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPrepQuery, GetPrepQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetPrepQuery, GetPrepQueryVariables>(GetPrepDocument, options);
}
export type GetPrepQueryHookResult = ReturnType<typeof useGetPrepQuery>;
export type GetPrepLazyQueryHookResult = ReturnType<typeof useGetPrepLazyQuery>;
export type GetPrepQueryResult = Apollo.QueryResult<GetPrepQuery, GetPrepQueryVariables>;
export const CountClassEventActivityForClassesDocument = gql`
  query CountClassEventActivityForClasses(
    $userId: uuid!
    $classEventIds: [uuid!]
    $type: [ActivityTypeEnum!]
  ) {
    classEventActivityAggregate(
      where: {userId: {_eq: $userId}, classEventId: {_in: $classEventIds}, type: {_in: $type}}
    ) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useCountClassEventActivityForClassesQuery__
 *
 * To run a query within a React component, call `useCountClassEventActivityForClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountClassEventActivityForClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountClassEventActivityForClassesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      classEventIds: // value for 'classEventIds'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCountClassEventActivityForClassesQuery(
  baseOptions: Apollo.QueryHookOptions<
    CountClassEventActivityForClassesQuery,
    CountClassEventActivityForClassesQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    CountClassEventActivityForClassesQuery,
    CountClassEventActivityForClassesQueryVariables
  >(CountClassEventActivityForClassesDocument, options);
}
export function useCountClassEventActivityForClassesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CountClassEventActivityForClassesQuery,
    CountClassEventActivityForClassesQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    CountClassEventActivityForClassesQuery,
    CountClassEventActivityForClassesQueryVariables
  >(CountClassEventActivityForClassesDocument, options);
}
export type CountClassEventActivityForClassesQueryHookResult = ReturnType<
  typeof useCountClassEventActivityForClassesQuery
>;
export type CountClassEventActivityForClassesLazyQueryHookResult = ReturnType<
  typeof useCountClassEventActivityForClassesLazyQuery
>;
export type CountClassEventActivityForClassesQueryResult = Apollo.QueryResult<
  CountClassEventActivityForClassesQuery,
  CountClassEventActivityForClassesQueryVariables
>;
export const GetClassEventDocument = gql`
  query GetClassEvent($classEventId: uuid!, $userId: uuid!) {
    registration: classEventRegistrations(
      where: {_and: [{classEventId: {_eq: $classEventId}}, {userId: {_eq: $userId}}]}
      limit: 1
    ) {
      id
      zoomJoinLinkUrl
    }
    classEvent: classEventsByPk(id: $classEventId) {
      id
      slug
      startTime
      endTime
      zoomMeetingId
      class {
        name
        slug
        course {
          enrollmentStatus
          id
          name
          preCourseSurveyFormId
        }
      }
      cohort {
        id
        name
        slug
        inAppVideo
        course {
          slug
          type
        }
      }
    }
  }
`;

/**
 * __useGetClassEventQuery__
 *
 * To run a query within a React component, call `useGetClassEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassEventQuery({
 *   variables: {
 *      classEventId: // value for 'classEventId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetClassEventQuery(
  baseOptions: Apollo.QueryHookOptions<GetClassEventQuery, GetClassEventQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetClassEventQuery, GetClassEventQueryVariables>(
    GetClassEventDocument,
    options
  );
}
export function useGetClassEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetClassEventQuery, GetClassEventQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetClassEventQuery, GetClassEventQueryVariables>(
    GetClassEventDocument,
    options
  );
}
export type GetClassEventQueryHookResult = ReturnType<typeof useGetClassEventQuery>;
export type GetClassEventLazyQueryHookResult = ReturnType<typeof useGetClassEventLazyQuery>;
export type GetClassEventQueryResult = Apollo.QueryResult<
  GetClassEventQuery,
  GetClassEventQueryVariables
>;
export const GetClassEventActivityDocument = gql`
  query GetClassEventActivity($userId: uuid!, $classEventId: uuid!, $type: [ActivityTypeEnum!]) {
    classEventActivity(
      where: {userId: {_eq: $userId}, classEventId: {_eq: $classEventId}, type: {_in: $type}}
      orderBy: {createdAt: DESC}
    ) {
      id
      userId
      classEventId
      context
      createdAt
      type
    }
  }
`;

/**
 * __useGetClassEventActivityQuery__
 *
 * To run a query within a React component, call `useGetClassEventActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassEventActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassEventActivityQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      classEventId: // value for 'classEventId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetClassEventActivityQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClassEventActivityQuery,
    GetClassEventActivityQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetClassEventActivityQuery, GetClassEventActivityQueryVariables>(
    GetClassEventActivityDocument,
    options
  );
}
export function useGetClassEventActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassEventActivityQuery,
    GetClassEventActivityQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetClassEventActivityQuery, GetClassEventActivityQueryVariables>(
    GetClassEventActivityDocument,
    options
  );
}
export type GetClassEventActivityQueryHookResult = ReturnType<typeof useGetClassEventActivityQuery>;
export type GetClassEventActivityLazyQueryHookResult = ReturnType<
  typeof useGetClassEventActivityLazyQuery
>;
export type GetClassEventActivityQueryResult = Apollo.QueryResult<
  GetClassEventActivityQuery,
  GetClassEventActivityQueryVariables
>;
export const GetClassPlaybackActivityDocument = gql`
  query GetClassPlaybackActivity(
    $userId: uuid!
    $classEventId: uuid!
    $type: [ClassPlaybackActivityTypeEnum!]
  ) {
    classPlaybackActivity(
      where: {userId: {_eq: $userId}, classEventId: {_eq: $classEventId}, type: {_in: $type}}
      orderBy: {createdAt: DESC}
    ) {
      id
      userId
      classEventId
      context
      createdAt
      type
    }
  }
`;

/**
 * __useGetClassPlaybackActivityQuery__
 *
 * To run a query within a React component, call `useGetClassPlaybackActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassPlaybackActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassPlaybackActivityQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      classEventId: // value for 'classEventId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetClassPlaybackActivityQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClassPlaybackActivityQuery,
    GetClassPlaybackActivityQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetClassPlaybackActivityQuery, GetClassPlaybackActivityQueryVariables>(
    GetClassPlaybackActivityDocument,
    options
  );
}
export function useGetClassPlaybackActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassPlaybackActivityQuery,
    GetClassPlaybackActivityQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetClassPlaybackActivityQuery, GetClassPlaybackActivityQueryVariables>(
    GetClassPlaybackActivityDocument,
    options
  );
}
export type GetClassPlaybackActivityQueryHookResult = ReturnType<
  typeof useGetClassPlaybackActivityQuery
>;
export type GetClassPlaybackActivityLazyQueryHookResult = ReturnType<
  typeof useGetClassPlaybackActivityLazyQuery
>;
export type GetClassPlaybackActivityQueryResult = Apollo.QueryResult<
  GetClassPlaybackActivityQuery,
  GetClassPlaybackActivityQueryVariables
>;
export const GetCohortBySlugDocument = gql`
  query GetCohortBySlug($slug: String!, $onlyId: Boolean = false) {
    cohorts(where: {slug: {_eq: $slug}}) {
      id
      ...CohortSimpleFragment @skip(if: $onlyId)
    }
  }
  ${CohortSimpleFragmentFragmentDoc}
`;

/**
 * __useGetCohortBySlugQuery__
 *
 * To run a query within a React component, call `useGetCohortBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCohortBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCohortBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      onlyId: // value for 'onlyId'
 *   },
 * });
 */
export function useGetCohortBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<GetCohortBySlugQuery, GetCohortBySlugQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetCohortBySlugQuery, GetCohortBySlugQueryVariables>(
    GetCohortBySlugDocument,
    options
  );
}
export function useGetCohortBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCohortBySlugQuery, GetCohortBySlugQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetCohortBySlugQuery, GetCohortBySlugQueryVariables>(
    GetCohortBySlugDocument,
    options
  );
}
export type GetCohortBySlugQueryHookResult = ReturnType<typeof useGetCohortBySlugQuery>;
export type GetCohortBySlugLazyQueryHookResult = ReturnType<typeof useGetCohortBySlugLazyQuery>;
export type GetCohortBySlugQueryResult = Apollo.QueryResult<
  GetCohortBySlugQuery,
  GetCohortBySlugQueryVariables
>;
export const GetCohortDetailsAndCourseSlugDocument = gql`
  query GetCohortDetailsAndCourseSlug($slug: String!) {
    cohorts(where: {slug: {_eq: $slug}}) {
      endDate
      startDate
      classTimeOfDay
      type
      communitySlackChannelUrl
      communitySlackInviteUrl
      preworkRichText
      preworkVideoUrl
      assessmentLiveDate
      isAssessmentLive
      course {
        slug
        id
        name
        type
        impactAssessment {
          id
        }
      }
    }
  }
`;

/**
 * __useGetCohortDetailsAndCourseSlugQuery__
 *
 * To run a query within a React component, call `useGetCohortDetailsAndCourseSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCohortDetailsAndCourseSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCohortDetailsAndCourseSlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetCohortDetailsAndCourseSlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCohortDetailsAndCourseSlugQuery,
    GetCohortDetailsAndCourseSlugQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetCohortDetailsAndCourseSlugQuery,
    GetCohortDetailsAndCourseSlugQueryVariables
  >(GetCohortDetailsAndCourseSlugDocument, options);
}
export function useGetCohortDetailsAndCourseSlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCohortDetailsAndCourseSlugQuery,
    GetCohortDetailsAndCourseSlugQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetCohortDetailsAndCourseSlugQuery,
    GetCohortDetailsAndCourseSlugQueryVariables
  >(GetCohortDetailsAndCourseSlugDocument, options);
}
export type GetCohortDetailsAndCourseSlugQueryHookResult = ReturnType<
  typeof useGetCohortDetailsAndCourseSlugQuery
>;
export type GetCohortDetailsAndCourseSlugLazyQueryHookResult = ReturnType<
  typeof useGetCohortDetailsAndCourseSlugLazyQuery
>;
export type GetCohortDetailsAndCourseSlugQueryResult = Apollo.QueryResult<
  GetCohortDetailsAndCourseSlugQuery,
  GetCohortDetailsAndCourseSlugQueryVariables
>;
export const GetCohortEnrollmentStatusDocument = gql`
  query GetCohortEnrollmentStatus($cohortId: uuid!) {
    cohort: cohortsByPk(id: $cohortId) {
      maxEnrollment
    }
    enrolledLearners: enrollmentsAggregate(
      where: {deletedAt: {_isNull: true}, cohortId: {_eq: $cohortId}, user: {active: {_eq: true}}}
    ) {
      total: aggregate {
        count(columns: userId, distinct: true)
      }
    }
  }
`;

/**
 * __useGetCohortEnrollmentStatusQuery__
 *
 * To run a query within a React component, call `useGetCohortEnrollmentStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCohortEnrollmentStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCohortEnrollmentStatusQuery({
 *   variables: {
 *      cohortId: // value for 'cohortId'
 *   },
 * });
 */
export function useGetCohortEnrollmentStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCohortEnrollmentStatusQuery,
    GetCohortEnrollmentStatusQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetCohortEnrollmentStatusQuery, GetCohortEnrollmentStatusQueryVariables>(
    GetCohortEnrollmentStatusDocument,
    options
  );
}
export function useGetCohortEnrollmentStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCohortEnrollmentStatusQuery,
    GetCohortEnrollmentStatusQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetCohortEnrollmentStatusQuery,
    GetCohortEnrollmentStatusQueryVariables
  >(GetCohortEnrollmentStatusDocument, options);
}
export type GetCohortEnrollmentStatusQueryHookResult = ReturnType<
  typeof useGetCohortEnrollmentStatusQuery
>;
export type GetCohortEnrollmentStatusLazyQueryHookResult = ReturnType<
  typeof useGetCohortEnrollmentStatusLazyQuery
>;
export type GetCohortEnrollmentStatusQueryResult = Apollo.QueryResult<
  GetCohortEnrollmentStatusQuery,
  GetCohortEnrollmentStatusQueryVariables
>;
export const GetCohortEnrollmentStatusActionDocument = gql`
  mutation GetCohortEnrollmentStatusAction($cohortId: uuid!) {
    GetCohortEnrollmentStatus(cohortId: $cohortId) {
      isFull
      remainingSeats
    }
  }
`;
export type GetCohortEnrollmentStatusActionMutationFn = Apollo.MutationFunction<
  GetCohortEnrollmentStatusActionMutation,
  GetCohortEnrollmentStatusActionMutationVariables
>;

/**
 * __useGetCohortEnrollmentStatusActionMutation__
 *
 * To run a mutation, you first call `useGetCohortEnrollmentStatusActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetCohortEnrollmentStatusActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getCohortEnrollmentStatusActionMutation, { data, loading, error }] = useGetCohortEnrollmentStatusActionMutation({
 *   variables: {
 *      cohortId: // value for 'cohortId'
 *   },
 * });
 */
export function useGetCohortEnrollmentStatusActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetCohortEnrollmentStatusActionMutation,
    GetCohortEnrollmentStatusActionMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    GetCohortEnrollmentStatusActionMutation,
    GetCohortEnrollmentStatusActionMutationVariables
  >(GetCohortEnrollmentStatusActionDocument, options);
}
export type GetCohortEnrollmentStatusActionMutationHookResult = ReturnType<
  typeof useGetCohortEnrollmentStatusActionMutation
>;
export type GetCohortEnrollmentStatusActionMutationResult =
  Apollo.MutationResult<GetCohortEnrollmentStatusActionMutation>;
export type GetCohortEnrollmentStatusActionMutationOptions = Apollo.BaseMutationOptions<
  GetCohortEnrollmentStatusActionMutation,
  GetCohortEnrollmentStatusActionMutationVariables
>;
export const GetCohortProgressDocument = gql`
  query GetCohortProgress {
    cohortProgress {
      numClasses
      numClassesCompleted
      id
    }
  }
`;

/**
 * __useGetCohortProgressQuery__
 *
 * To run a query within a React component, call `useGetCohortProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCohortProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCohortProgressQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCohortProgressQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCohortProgressQuery, GetCohortProgressQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetCohortProgressQuery, GetCohortProgressQueryVariables>(
    GetCohortProgressDocument,
    options
  );
}
export function useGetCohortProgressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCohortProgressQuery, GetCohortProgressQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetCohortProgressQuery, GetCohortProgressQueryVariables>(
    GetCohortProgressDocument,
    options
  );
}
export type GetCohortProgressQueryHookResult = ReturnType<typeof useGetCohortProgressQuery>;
export type GetCohortProgressLazyQueryHookResult = ReturnType<typeof useGetCohortProgressLazyQuery>;
export type GetCohortProgressQueryResult = Apollo.QueryResult<
  GetCohortProgressQuery,
  GetCohortProgressQueryVariables
>;
export const GetCohortTypeByIdDocument = gql`
  query GetCohortTypeById($id: uuid!) {
    cohort: cohortsByPk(id: $id) {
      type
      slug
      course {
        name
        type
        slug
      }
    }
  }
`;

/**
 * __useGetCohortTypeByIdQuery__
 *
 * To run a query within a React component, call `useGetCohortTypeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCohortTypeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCohortTypeByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCohortTypeByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetCohortTypeByIdQuery, GetCohortTypeByIdQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetCohortTypeByIdQuery, GetCohortTypeByIdQueryVariables>(
    GetCohortTypeByIdDocument,
    options
  );
}
export function useGetCohortTypeByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCohortTypeByIdQuery, GetCohortTypeByIdQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetCohortTypeByIdQuery, GetCohortTypeByIdQueryVariables>(
    GetCohortTypeByIdDocument,
    options
  );
}
export type GetCohortTypeByIdQueryHookResult = ReturnType<typeof useGetCohortTypeByIdQuery>;
export type GetCohortTypeByIdLazyQueryHookResult = ReturnType<typeof useGetCohortTypeByIdLazyQuery>;
export type GetCohortTypeByIdQueryResult = Apollo.QueryResult<
  GetCohortTypeByIdQuery,
  GetCohortTypeByIdQueryVariables
>;
export const GetCohortWithEnrollmentsForManagerDocument = gql`
  query GetCohortWithEnrollmentsForManager($userIds: [uuid!], $cohortSlug: String!) {
    cohorts(where: {slug: {_eq: $cohortSlug}}) {
      id
      name
      slug
      startDate
      classTimeOfDay
      type
      classEvents {
        startTime
      }
      enrollments(where: {userId: {_in: $userIds}, deletedAt: {_isNull: true}}) {
        user {
          id
        }
      }
      course {
        id
        name
        slug
        type
        shortDescription
        enrollmentStatus
        tags(orderBy: {tag: {name: ASC}}) {
          tag {
            name
            slug
            tagType {
              slug
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetCohortWithEnrollmentsForManagerQuery__
 *
 * To run a query within a React component, call `useGetCohortWithEnrollmentsForManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCohortWithEnrollmentsForManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCohortWithEnrollmentsForManagerQuery({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      cohortSlug: // value for 'cohortSlug'
 *   },
 * });
 */
export function useGetCohortWithEnrollmentsForManagerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCohortWithEnrollmentsForManagerQuery,
    GetCohortWithEnrollmentsForManagerQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetCohortWithEnrollmentsForManagerQuery,
    GetCohortWithEnrollmentsForManagerQueryVariables
  >(GetCohortWithEnrollmentsForManagerDocument, options);
}
export function useGetCohortWithEnrollmentsForManagerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCohortWithEnrollmentsForManagerQuery,
    GetCohortWithEnrollmentsForManagerQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetCohortWithEnrollmentsForManagerQuery,
    GetCohortWithEnrollmentsForManagerQueryVariables
  >(GetCohortWithEnrollmentsForManagerDocument, options);
}
export type GetCohortWithEnrollmentsForManagerQueryHookResult = ReturnType<
  typeof useGetCohortWithEnrollmentsForManagerQuery
>;
export type GetCohortWithEnrollmentsForManagerLazyQueryHookResult = ReturnType<
  typeof useGetCohortWithEnrollmentsForManagerLazyQuery
>;
export type GetCohortWithEnrollmentsForManagerQueryResult = Apollo.QueryResult<
  GetCohortWithEnrollmentsForManagerQuery,
  GetCohortWithEnrollmentsForManagerQueryVariables
>;
export const GetCohortsByStartDateDocument = gql`
  query GetCohortsByStartDate($startDate: timestamp, $startDateIsEmpty: Boolean = true) {
    cohorts(where: {startDate: {_eq: $startDate}}) @skip(if: $startDateIsEmpty) {
      id
      startDate
    }
  }
`;

/**
 * __useGetCohortsByStartDateQuery__
 *
 * To run a query within a React component, call `useGetCohortsByStartDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCohortsByStartDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCohortsByStartDateQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      startDateIsEmpty: // value for 'startDateIsEmpty'
 *   },
 * });
 */
export function useGetCohortsByStartDateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCohortsByStartDateQuery,
    GetCohortsByStartDateQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetCohortsByStartDateQuery, GetCohortsByStartDateQueryVariables>(
    GetCohortsByStartDateDocument,
    options
  );
}
export function useGetCohortsByStartDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCohortsByStartDateQuery,
    GetCohortsByStartDateQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetCohortsByStartDateQuery, GetCohortsByStartDateQueryVariables>(
    GetCohortsByStartDateDocument,
    options
  );
}
export type GetCohortsByStartDateQueryHookResult = ReturnType<typeof useGetCohortsByStartDateQuery>;
export type GetCohortsByStartDateLazyQueryHookResult = ReturnType<
  typeof useGetCohortsByStartDateLazyQuery
>;
export type GetCohortsByStartDateQueryResult = Apollo.QueryResult<
  GetCohortsByStartDateQuery,
  GetCohortsByStartDateQueryVariables
>;
export const GetCohortsEnrollmentsForManagersDocument = gql`
  query GetCohortsEnrollmentsForManagers($cohortIds: [uuid!], $userIds: [uuid!]) {
    enrollmentsPerCohort: cohorts(where: {id: {_in: $cohortIds}}) {
      id
      enrollmentsAggregate(where: {userId: {_in: $userIds}, deletedAt: {_isNull: true}}) {
        aggregate {
          count
        }
      }
    }
    usersEnrolled: users(
      where: {
        id: {_in: $userIds}
        enrollments: {cohortId: {_in: $cohortIds}, deletedAt: {_isNull: true}}
      }
    ) {
      id
    }
  }
`;

/**
 * __useGetCohortsEnrollmentsForManagersQuery__
 *
 * To run a query within a React component, call `useGetCohortsEnrollmentsForManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCohortsEnrollmentsForManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCohortsEnrollmentsForManagersQuery({
 *   variables: {
 *      cohortIds: // value for 'cohortIds'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useGetCohortsEnrollmentsForManagersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCohortsEnrollmentsForManagersQuery,
    GetCohortsEnrollmentsForManagersQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetCohortsEnrollmentsForManagersQuery,
    GetCohortsEnrollmentsForManagersQueryVariables
  >(GetCohortsEnrollmentsForManagersDocument, options);
}
export function useGetCohortsEnrollmentsForManagersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCohortsEnrollmentsForManagersQuery,
    GetCohortsEnrollmentsForManagersQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetCohortsEnrollmentsForManagersQuery,
    GetCohortsEnrollmentsForManagersQueryVariables
  >(GetCohortsEnrollmentsForManagersDocument, options);
}
export type GetCohortsEnrollmentsForManagersQueryHookResult = ReturnType<
  typeof useGetCohortsEnrollmentsForManagersQuery
>;
export type GetCohortsEnrollmentsForManagersLazyQueryHookResult = ReturnType<
  typeof useGetCohortsEnrollmentsForManagersLazyQuery
>;
export type GetCohortsEnrollmentsForManagersQueryResult = Apollo.QueryResult<
  GetCohortsEnrollmentsForManagersQuery,
  GetCohortsEnrollmentsForManagersQueryVariables
>;
export const GetLightningSessionCohortByIdDocument = gql`
  query GetLightningSessionCohortById($id: uuid!) {
    cohorts(where: {id: {_eq: $id}}) {
      ...CohortSimpleFragment
      course {
        type
      }
      coaches(orderBy: {coach: {name: ASC}}) {
        coach {
          id
          userId
        }
      }
      classEvents {
        id
        preworkCompleteActivityCount: classEventActivitiesAggregate(
          where: {type: {_eq: PREWORK_COMPLETE}}
        ) {
          aggregate {
            count
          }
        }
      }
      preworkRichText
      preworkVideoUrl
    }
  }
  ${CohortSimpleFragmentFragmentDoc}
`;

/**
 * __useGetLightningSessionCohortByIdQuery__
 *
 * To run a query within a React component, call `useGetLightningSessionCohortByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLightningSessionCohortByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLightningSessionCohortByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLightningSessionCohortByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLightningSessionCohortByIdQuery,
    GetLightningSessionCohortByIdQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetLightningSessionCohortByIdQuery,
    GetLightningSessionCohortByIdQueryVariables
  >(GetLightningSessionCohortByIdDocument, options);
}
export function useGetLightningSessionCohortByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLightningSessionCohortByIdQuery,
    GetLightningSessionCohortByIdQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetLightningSessionCohortByIdQuery,
    GetLightningSessionCohortByIdQueryVariables
  >(GetLightningSessionCohortByIdDocument, options);
}
export type GetLightningSessionCohortByIdQueryHookResult = ReturnType<
  typeof useGetLightningSessionCohortByIdQuery
>;
export type GetLightningSessionCohortByIdLazyQueryHookResult = ReturnType<
  typeof useGetLightningSessionCohortByIdLazyQuery
>;
export type GetLightningSessionCohortByIdQueryResult = Apollo.QueryResult<
  GetLightningSessionCohortByIdQuery,
  GetLightningSessionCohortByIdQueryVariables
>;
export const GetLightningSessionCohortBySlugDocument = gql`
  query GetLightningSessionCohortBySlug($slug: String!) {
    cohorts(where: {slug: {_eq: $slug}}) {
      ...CohortSimpleFragment
      classEvents {
        id
        preworkCompleteActivityCount: classEventActivitiesAggregate(
          where: {type: {_eq: PREWORK_COMPLETE}}
        ) {
          aggregate {
            count
          }
        }
      }
      preworkRichText
      preworkVideoUrl
      resources {
        resource {
          id
          filename
          contentType
          name
          url
          type
        }
      }
      course {
        id
        resources {
          resource {
            id
            filename
            contentType
            name
            url
            type
          }
        }
      }
    }
  }
  ${CohortSimpleFragmentFragmentDoc}
`;

/**
 * __useGetLightningSessionCohortBySlugQuery__
 *
 * To run a query within a React component, call `useGetLightningSessionCohortBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLightningSessionCohortBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLightningSessionCohortBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetLightningSessionCohortBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLightningSessionCohortBySlugQuery,
    GetLightningSessionCohortBySlugQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetLightningSessionCohortBySlugQuery,
    GetLightningSessionCohortBySlugQueryVariables
  >(GetLightningSessionCohortBySlugDocument, options);
}
export function useGetLightningSessionCohortBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLightningSessionCohortBySlugQuery,
    GetLightningSessionCohortBySlugQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetLightningSessionCohortBySlugQuery,
    GetLightningSessionCohortBySlugQueryVariables
  >(GetLightningSessionCohortBySlugDocument, options);
}
export type GetLightningSessionCohortBySlugQueryHookResult = ReturnType<
  typeof useGetLightningSessionCohortBySlugQuery
>;
export type GetLightningSessionCohortBySlugLazyQueryHookResult = ReturnType<
  typeof useGetLightningSessionCohortBySlugLazyQuery
>;
export type GetLightningSessionCohortBySlugQueryResult = Apollo.QueryResult<
  GetLightningSessionCohortBySlugQuery,
  GetLightningSessionCohortBySlugQueryVariables
>;
export const GetMyLearningCohortDetailsBySlugDocument = gql`
  query GetMyLearningCohortDetailsBySlug(
    $slug: String!
    $classEventsClassesOrderBy: ClassesOrderBy
  ) {
    cohorts(where: {slug: {_eq: $slug}}, limit: 1) {
      ...OneCohortDetails
      preworkCompleteCount: classEvents {
        classEventActivitiesAggregate(where: {type: {_eq: PREWORK_COMPLETE}}) {
          aggregate {
            count
          }
        }
      }
    }
    courseCertifications(where: {cohort: {slug: {_eq: $slug}}}, limit: 1) {
      createdAt
    }
    resources(where: {resourceCourse: {course: {cohorts: {slug: {_eq: $slug}}}}}) {
      ...ResourcesMetadata
    }
  }
  ${OneCohortDetailsFragmentDoc}
  ${ResourcesMetadataFragmentDoc}
`;

/**
 * __useGetMyLearningCohortDetailsBySlugQuery__
 *
 * To run a query within a React component, call `useGetMyLearningCohortDetailsBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyLearningCohortDetailsBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyLearningCohortDetailsBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      classEventsClassesOrderBy: // value for 'classEventsClassesOrderBy'
 *   },
 * });
 */
export function useGetMyLearningCohortDetailsBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyLearningCohortDetailsBySlugQuery,
    GetMyLearningCohortDetailsBySlugQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetMyLearningCohortDetailsBySlugQuery,
    GetMyLearningCohortDetailsBySlugQueryVariables
  >(GetMyLearningCohortDetailsBySlugDocument, options);
}
export function useGetMyLearningCohortDetailsBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyLearningCohortDetailsBySlugQuery,
    GetMyLearningCohortDetailsBySlugQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetMyLearningCohortDetailsBySlugQuery,
    GetMyLearningCohortDetailsBySlugQueryVariables
  >(GetMyLearningCohortDetailsBySlugDocument, options);
}
export type GetMyLearningCohortDetailsBySlugQueryHookResult = ReturnType<
  typeof useGetMyLearningCohortDetailsBySlugQuery
>;
export type GetMyLearningCohortDetailsBySlugLazyQueryHookResult = ReturnType<
  typeof useGetMyLearningCohortDetailsBySlugLazyQuery
>;
export type GetMyLearningCohortDetailsBySlugQueryResult = Apollo.QueryResult<
  GetMyLearningCohortDetailsBySlugQuery,
  GetMyLearningCohortDetailsBySlugQueryVariables
>;
export const GetOneCohortDetailByPkDocument = gql`
  query GetOneCohortDetailByPk($id: uuid!, $classEventsClassesOrderBy: ClassesOrderBy) {
    cohortsByPk(id: $id) {
      ...OneCohortDetails
    }
  }
  ${OneCohortDetailsFragmentDoc}
`;

/**
 * __useGetOneCohortDetailByPkQuery__
 *
 * To run a query within a React component, call `useGetOneCohortDetailByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneCohortDetailByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneCohortDetailByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *      classEventsClassesOrderBy: // value for 'classEventsClassesOrderBy'
 *   },
 * });
 */
export function useGetOneCohortDetailByPkQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOneCohortDetailByPkQuery,
    GetOneCohortDetailByPkQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetOneCohortDetailByPkQuery, GetOneCohortDetailByPkQueryVariables>(
    GetOneCohortDetailByPkDocument,
    options
  );
}
export function useGetOneCohortDetailByPkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOneCohortDetailByPkQuery,
    GetOneCohortDetailByPkQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetOneCohortDetailByPkQuery, GetOneCohortDetailByPkQueryVariables>(
    GetOneCohortDetailByPkDocument,
    options
  );
}
export type GetOneCohortDetailByPkQueryHookResult = ReturnType<
  typeof useGetOneCohortDetailByPkQuery
>;
export type GetOneCohortDetailByPkLazyQueryHookResult = ReturnType<
  typeof useGetOneCohortDetailByPkLazyQuery
>;
export type GetOneCohortDetailByPkQueryResult = Apollo.QueryResult<
  GetOneCohortDetailByPkQuery,
  GetOneCohortDetailByPkQueryVariables
>;
export const GetPastSessionsForUsersDocument = gql`
  query GetPastSessionsForUsers($userIds: [uuid!]!) {
    cohorts(
      orderBy: {startDate: DESC}
      where: {
        endDate: {_lte: "now()"}
        visibility: {_eq: VISIBLE}
        course: {type: {_eq: LIGHTNING_SESSION}, publishStatus: {_eq: PUBLISHED}}
        submissionsAggregate: {
          count: {
            filter: {userId: {_in: $userIds}, deletedAt: {_isNull: true}}
            predicate: {_gte: 1}
          }
        }
        enrollmentsAggregate: {
          count: {
            filter: {userId: {_in: $userIds}, deletedAt: {_isNull: true}}
            predicate: {_gte: 1}
          }
        }
      }
    ) {
      id
      course {
        name
        slug
        ...CourseRoleTags
      }
      slug
      startDate
      classTimeOfDay
      ...PastSessionMetrics
    }
  }
  ${CourseRoleTagsFragmentDoc}
  ${PastSessionMetricsFragmentDoc}
`;

/**
 * __useGetPastSessionsForUsersQuery__
 *
 * To run a query within a React component, call `useGetPastSessionsForUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPastSessionsForUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPastSessionsForUsersQuery({
 *   variables: {
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useGetPastSessionsForUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPastSessionsForUsersQuery,
    GetPastSessionsForUsersQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetPastSessionsForUsersQuery, GetPastSessionsForUsersQueryVariables>(
    GetPastSessionsForUsersDocument,
    options
  );
}
export function useGetPastSessionsForUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPastSessionsForUsersQuery,
    GetPastSessionsForUsersQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetPastSessionsForUsersQuery, GetPastSessionsForUsersQueryVariables>(
    GetPastSessionsForUsersDocument,
    options
  );
}
export type GetPastSessionsForUsersQueryHookResult = ReturnType<
  typeof useGetPastSessionsForUsersQuery
>;
export type GetPastSessionsForUsersLazyQueryHookResult = ReturnType<
  typeof useGetPastSessionsForUsersLazyQuery
>;
export type GetPastSessionsForUsersQueryResult = Apollo.QueryResult<
  GetPastSessionsForUsersQuery,
  GetPastSessionsForUsersQueryVariables
>;
export const GetPastSingleSessionForUsersDocument = gql`
  query GetPastSingleSessionForUsers($cohortId: uuid!, $userIds: [uuid!]!) {
    cohortsByPk(id: $cohortId) {
      id
      slug
      resources {
        resource {
          id
          filename
          contentType
          name
          url
          type
        }
      }
      course {
        name
        shortDescription
        frameworkHighlight
        frameworkDescription
        slug
        resources {
          resource {
            id
            filename
            contentType
            name
            url
            type
          }
        }
        ...CourseRoleTags
      }
      classEvents {
        recordingUrl
        lightningSession {
          rooms
          borDetails
        }
      }
      startDate
      classTimeOfDay
      ...PastSessionMetrics
      sessionDetails: submissions(
        where: {userId: {_in: $userIds}, deletedAt: {_isNull: true}}
        orderBy: [{user: {firstName: ASC}}, {user: {lastName: ASC}}]
      ) {
        id
        userId
        nextEnrolledCohort: user {
          enrollments(
            limit: 1
            orderBy: {cohort: {startDate: ASC}}
            where: {
              cohort: {
                startDate: {_gt: "now()"}
                visibility: {_eq: VISIBLE}
                course: {publishStatus: {_eq: PUBLISHED}, type: {_eq: LIGHTNING_SESSION}}
              }
            }
          ) {
            cohort {
              slug
              startDate
              course {
                name
                slug
              }
            }
          }
        }
        status
        feedbackSummary: comment
        coach {
          id
          firstName
          lastName
          coaches {
            avatarUrl
            title
            company
          }
        }
      }
    }
  }
  ${CourseRoleTagsFragmentDoc}
  ${PastSessionMetricsFragmentDoc}
`;

/**
 * __useGetPastSingleSessionForUsersQuery__
 *
 * To run a query within a React component, call `useGetPastSingleSessionForUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPastSingleSessionForUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPastSingleSessionForUsersQuery({
 *   variables: {
 *      cohortId: // value for 'cohortId'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useGetPastSingleSessionForUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPastSingleSessionForUsersQuery,
    GetPastSingleSessionForUsersQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetPastSingleSessionForUsersQuery,
    GetPastSingleSessionForUsersQueryVariables
  >(GetPastSingleSessionForUsersDocument, options);
}
export function useGetPastSingleSessionForUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPastSingleSessionForUsersQuery,
    GetPastSingleSessionForUsersQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetPastSingleSessionForUsersQuery,
    GetPastSingleSessionForUsersQueryVariables
  >(GetPastSingleSessionForUsersDocument, options);
}
export type GetPastSingleSessionForUsersQueryHookResult = ReturnType<
  typeof useGetPastSingleSessionForUsersQuery
>;
export type GetPastSingleSessionForUsersLazyQueryHookResult = ReturnType<
  typeof useGetPastSingleSessionForUsersLazyQuery
>;
export type GetPastSingleSessionForUsersQueryResult = Apollo.QueryResult<
  GetPastSingleSessionForUsersQuery,
  GetPastSingleSessionForUsersQueryVariables
>;
export const GetProgramMatchesDocument = gql`
  query GetProgramMatches($timeframe: timestamp!, $tagIds: [uuid!]) {
    courses: coursesByPlan(
      where: {_and: [{tags: {tagId: {_in: $tagIds}}}, {courseType: {_eq: "LIGHTNING_SESSION"}}]}
    ) {
      cohorts(
        where: {
          _and: [{endDate: {_gt: "NOW()"}}, {startDate: {_lte: $timeframe}}, {type: {_eq: LIVE}}]
        }
      ) {
        id
        name
        slug
        startDate
        classTimeOfDay
        type
        classEvents {
          startTime
        }
      }
      id
      name
      shortDescription
      version
      slug
      courseType
      enrollmentStatus
      tags {
        tag {
          id
          name
          slug
          tagTypeId
          tagType {
            slug
          }
        }
      }
    }
  }
`;

/**
 * __useGetProgramMatchesQuery__
 *
 * To run a query within a React component, call `useGetProgramMatchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgramMatchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgramMatchesQuery({
 *   variables: {
 *      timeframe: // value for 'timeframe'
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useGetProgramMatchesQuery(
  baseOptions: Apollo.QueryHookOptions<GetProgramMatchesQuery, GetProgramMatchesQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetProgramMatchesQuery, GetProgramMatchesQueryVariables>(
    GetProgramMatchesDocument,
    options
  );
}
export function useGetProgramMatchesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProgramMatchesQuery, GetProgramMatchesQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetProgramMatchesQuery, GetProgramMatchesQueryVariables>(
    GetProgramMatchesDocument,
    options
  );
}
export type GetProgramMatchesQueryHookResult = ReturnType<typeof useGetProgramMatchesQuery>;
export type GetProgramMatchesLazyQueryHookResult = ReturnType<typeof useGetProgramMatchesLazyQuery>;
export type GetProgramMatchesQueryResult = Apollo.QueryResult<
  GetProgramMatchesQuery,
  GetProgramMatchesQueryVariables
>;
export const GetUpcomingCohortsDocument = gql`
  query GetUpcomingCohorts($where: CohortsBoolExp) {
    cohorts(where: $where, orderBy: [{startDate: ASC}, {classTimeOfDay: ASC}]) {
      id
      name
      slug
      startDate
      classTimeOfDay
      type
      maxEnrollment
      totalEnrollments
      classEvents {
        startTime
      }
      course {
        id
        code
        name
        slug
        enrollmentStatus
        timeLengthInClasses
        type
        tags(where: {tag: {tagType: {slug: {_eq: "role"}}}}) {
          tag {
            name
            slug
          }
        }
        classes {
          id
          isLive
          durationInMinutes
        }
        school {
          id
          name
          slug
        }
      }
      coaches {
        coach {
          id
          name
          avatarUrl
        }
      }
    }
  }
`;

/**
 * __useGetUpcomingCohortsQuery__
 *
 * To run a query within a React component, call `useGetUpcomingCohortsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpcomingCohortsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpcomingCohortsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUpcomingCohortsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUpcomingCohortsQuery, GetUpcomingCohortsQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetUpcomingCohortsQuery, GetUpcomingCohortsQueryVariables>(
    GetUpcomingCohortsDocument,
    options
  );
}
export function useGetUpcomingCohortsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUpcomingCohortsQuery,
    GetUpcomingCohortsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetUpcomingCohortsQuery, GetUpcomingCohortsQueryVariables>(
    GetUpcomingCohortsDocument,
    options
  );
}
export type GetUpcomingCohortsQueryHookResult = ReturnType<typeof useGetUpcomingCohortsQuery>;
export type GetUpcomingCohortsLazyQueryHookResult = ReturnType<
  typeof useGetUpcomingCohortsLazyQuery
>;
export type GetUpcomingCohortsQueryResult = Apollo.QueryResult<
  GetUpcomingCohortsQuery,
  GetUpcomingCohortsQueryVariables
>;
export const GetUpcommingLighthningSessionsWithStatsForManagerDocument = gql`
  query GetUpcommingLighthningSessionsWithStatsForManager {
    cohorts(
      where: {
        course: {type: {_eq: LIGHTNING_SESSION}, is_permitted: {_eq: true}}
        type: {_eq: LIVE}
        endDate: {_gt: now}
      }
      orderBy: [{startDate: ASC}, {classTimeOfDay: ASC}]
    ) {
      id
      name
      slug
      startDate
      classTimeOfDay
      type
      classEvents {
        startTime
      }
      course {
        id
        name
        slug
        type
        enrollmentStatus
        tags(orderBy: {tag: {name: ASC}}) {
          tag {
            name
            slug
            tagType {
              slug
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetUpcommingLighthningSessionsWithStatsForManagerQuery__
 *
 * To run a query within a React component, call `useGetUpcommingLighthningSessionsWithStatsForManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpcommingLighthningSessionsWithStatsForManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpcommingLighthningSessionsWithStatsForManagerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUpcommingLighthningSessionsWithStatsForManagerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUpcommingLighthningSessionsWithStatsForManagerQuery,
    GetUpcommingLighthningSessionsWithStatsForManagerQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetUpcommingLighthningSessionsWithStatsForManagerQuery,
    GetUpcommingLighthningSessionsWithStatsForManagerQueryVariables
  >(GetUpcommingLighthningSessionsWithStatsForManagerDocument, options);
}
export function useGetUpcommingLighthningSessionsWithStatsForManagerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUpcommingLighthningSessionsWithStatsForManagerQuery,
    GetUpcommingLighthningSessionsWithStatsForManagerQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetUpcommingLighthningSessionsWithStatsForManagerQuery,
    GetUpcommingLighthningSessionsWithStatsForManagerQueryVariables
  >(GetUpcommingLighthningSessionsWithStatsForManagerDocument, options);
}
export type GetUpcommingLighthningSessionsWithStatsForManagerQueryHookResult = ReturnType<
  typeof useGetUpcommingLighthningSessionsWithStatsForManagerQuery
>;
export type GetUpcommingLighthningSessionsWithStatsForManagerLazyQueryHookResult = ReturnType<
  typeof useGetUpcommingLighthningSessionsWithStatsForManagerLazyQuery
>;
export type GetUpcommingLighthningSessionsWithStatsForManagerQueryResult = Apollo.QueryResult<
  GetUpcommingLighthningSessionsWithStatsForManagerQuery,
  GetUpcommingLighthningSessionsWithStatsForManagerQueryVariables
>;
export const IsLargeCohortDocument = gql`
  query IsLargeCohort($classEventId: uuid!) {
    classEventsByPk(id: $classEventId) {
      id
      cohort {
        coachesAggregate {
          aggregate {
            count
          }
        }
        enrollmentsAggregate(where: {deletedAt: {_isNull: true}, user: {active: {_eq: true}}}) {
          aggregate {
            count(columns: userId, distinct: true)
          }
        }
      }
    }
  }
`;

/**
 * __useIsLargeCohortQuery__
 *
 * To run a query within a React component, call `useIsLargeCohortQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsLargeCohortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsLargeCohortQuery({
 *   variables: {
 *      classEventId: // value for 'classEventId'
 *   },
 * });
 */
export function useIsLargeCohortQuery(
  baseOptions: Apollo.QueryHookOptions<IsLargeCohortQuery, IsLargeCohortQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<IsLargeCohortQuery, IsLargeCohortQueryVariables>(
    IsLargeCohortDocument,
    options
  );
}
export function useIsLargeCohortLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IsLargeCohortQuery, IsLargeCohortQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<IsLargeCohortQuery, IsLargeCohortQueryVariables>(
    IsLargeCohortDocument,
    options
  );
}
export type IsLargeCohortQueryHookResult = ReturnType<typeof useIsLargeCohortQuery>;
export type IsLargeCohortLazyQueryHookResult = ReturnType<typeof useIsLargeCohortLazyQuery>;
export type IsLargeCohortQueryResult = Apollo.QueryResult<
  IsLargeCohortQuery,
  IsLargeCohortQueryVariables
>;
export const GetConfigurationByKeyDocument = gql`
  query GetConfigurationByKey($key: String!) {
    config: configurationsByPk(key: $key) {
      value
    }
  }
`;

/**
 * __useGetConfigurationByKeyQuery__
 *
 * To run a query within a React component, call `useGetConfigurationByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConfigurationByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfigurationByKeyQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetConfigurationByKeyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetConfigurationByKeyQuery,
    GetConfigurationByKeyQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetConfigurationByKeyQuery, GetConfigurationByKeyQueryVariables>(
    GetConfigurationByKeyDocument,
    options
  );
}
export function useGetConfigurationByKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConfigurationByKeyQuery,
    GetConfigurationByKeyQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetConfigurationByKeyQuery, GetConfigurationByKeyQueryVariables>(
    GetConfigurationByKeyDocument,
    options
  );
}
export type GetConfigurationByKeyQueryHookResult = ReturnType<typeof useGetConfigurationByKeyQuery>;
export type GetConfigurationByKeyLazyQueryHookResult = ReturnType<
  typeof useGetConfigurationByKeyLazyQuery
>;
export type GetConfigurationByKeyQueryResult = Apollo.QueryResult<
  GetConfigurationByKeyQuery,
  GetConfigurationByKeyQueryVariables
>;
export const GetConfigurationsDocument = gql`
  query GetConfigurations {
    config: configurations {
      key
      value
    }
  }
`;

/**
 * __useGetConfigurationsQuery__
 *
 * To run a query within a React component, call `useGetConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfigurationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetConfigurationsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetConfigurationsQuery, GetConfigurationsQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetConfigurationsQuery, GetConfigurationsQueryVariables>(
    GetConfigurationsDocument,
    options
  );
}
export function useGetConfigurationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetConfigurationsQuery, GetConfigurationsQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetConfigurationsQuery, GetConfigurationsQueryVariables>(
    GetConfigurationsDocument,
    options
  );
}
export type GetConfigurationsQueryHookResult = ReturnType<typeof useGetConfigurationsQuery>;
export type GetConfigurationsLazyQueryHookResult = ReturnType<typeof useGetConfigurationsLazyQuery>;
export type GetConfigurationsQueryResult = Apollo.QueryResult<
  GetConfigurationsQuery,
  GetConfigurationsQueryVariables
>;
export const CheckMyTeamCourseWaitlistDocument = gql`
  query CheckMyTeamCourseWaitlist($courseSlug: String!) {
    CheckMyTeamCourseWaitlist(courseSlug: $courseSlug) {
      onWaitlist
    }
  }
`;

/**
 * __useCheckMyTeamCourseWaitlistQuery__
 *
 * To run a query within a React component, call `useCheckMyTeamCourseWaitlistQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckMyTeamCourseWaitlistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckMyTeamCourseWaitlistQuery({
 *   variables: {
 *      courseSlug: // value for 'courseSlug'
 *   },
 * });
 */
export function useCheckMyTeamCourseWaitlistQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckMyTeamCourseWaitlistQuery,
    CheckMyTeamCourseWaitlistQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<CheckMyTeamCourseWaitlistQuery, CheckMyTeamCourseWaitlistQueryVariables>(
    CheckMyTeamCourseWaitlistDocument,
    options
  );
}
export function useCheckMyTeamCourseWaitlistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckMyTeamCourseWaitlistQuery,
    CheckMyTeamCourseWaitlistQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    CheckMyTeamCourseWaitlistQuery,
    CheckMyTeamCourseWaitlistQueryVariables
  >(CheckMyTeamCourseWaitlistDocument, options);
}
export type CheckMyTeamCourseWaitlistQueryHookResult = ReturnType<
  typeof useCheckMyTeamCourseWaitlistQuery
>;
export type CheckMyTeamCourseWaitlistLazyQueryHookResult = ReturnType<
  typeof useCheckMyTeamCourseWaitlistLazyQuery
>;
export type CheckMyTeamCourseWaitlistQueryResult = Apollo.QueryResult<
  CheckMyTeamCourseWaitlistQuery,
  CheckMyTeamCourseWaitlistQueryVariables
>;
export const GetActiveCourseWaitlistDocument = gql`
  query GetActiveCourseWaitlist($userIds: [uuid!]!, $courseSlug: String!) {
    courseWaitlist(
      where: {userId: {_in: $userIds}, courseSlug: {_eq: $courseSlug}, isNotDeleted: {_eq: true}}
    ) {
      id
      userId
      courseSlug
    }
  }
`;

/**
 * __useGetActiveCourseWaitlistQuery__
 *
 * To run a query within a React component, call `useGetActiveCourseWaitlistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveCourseWaitlistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveCourseWaitlistQuery({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      courseSlug: // value for 'courseSlug'
 *   },
 * });
 */
export function useGetActiveCourseWaitlistQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActiveCourseWaitlistQuery,
    GetActiveCourseWaitlistQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetActiveCourseWaitlistQuery, GetActiveCourseWaitlistQueryVariables>(
    GetActiveCourseWaitlistDocument,
    options
  );
}
export function useGetActiveCourseWaitlistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActiveCourseWaitlistQuery,
    GetActiveCourseWaitlistQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetActiveCourseWaitlistQuery, GetActiveCourseWaitlistQueryVariables>(
    GetActiveCourseWaitlistDocument,
    options
  );
}
export type GetActiveCourseWaitlistQueryHookResult = ReturnType<
  typeof useGetActiveCourseWaitlistQuery
>;
export type GetActiveCourseWaitlistLazyQueryHookResult = ReturnType<
  typeof useGetActiveCourseWaitlistLazyQuery
>;
export type GetActiveCourseWaitlistQueryResult = Apollo.QueryResult<
  GetActiveCourseWaitlistQuery,
  GetActiveCourseWaitlistQueryVariables
>;
export const GetCertificationsByUserIdDocument = gql`
  query GetCertificationsByUserId($userId: uuid!) {
    courseCertifications(where: {userId: {_eq: $userId}}) {
      id
      certificationFileUrl
      certificationImageUrl
      createdAt
      cohort {
        id
        slug
        course {
          name
          school {
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetCertificationsByUserIdQuery__
 *
 * To run a query within a React component, call `useGetCertificationsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCertificationsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCertificationsByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetCertificationsByUserIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCertificationsByUserIdQuery,
    GetCertificationsByUserIdQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetCertificationsByUserIdQuery, GetCertificationsByUserIdQueryVariables>(
    GetCertificationsByUserIdDocument,
    options
  );
}
export function useGetCertificationsByUserIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCertificationsByUserIdQuery,
    GetCertificationsByUserIdQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetCertificationsByUserIdQuery,
    GetCertificationsByUserIdQueryVariables
  >(GetCertificationsByUserIdDocument, options);
}
export type GetCertificationsByUserIdQueryHookResult = ReturnType<
  typeof useGetCertificationsByUserIdQuery
>;
export type GetCertificationsByUserIdLazyQueryHookResult = ReturnType<
  typeof useGetCertificationsByUserIdLazyQuery
>;
export type GetCertificationsByUserIdQueryResult = Apollo.QueryResult<
  GetCertificationsByUserIdQuery,
  GetCertificationsByUserIdQueryVariables
>;
export const GetCourseByIdDocument = gql`
  query GetCourseById($courseId: uuid!) {
    coursesByPk(id: $courseId) {
      id
      name
      slug
      type
      tags {
        tag {
          name
          slug
        }
      }
    }
  }
`;

/**
 * __useGetCourseByIdQuery__
 *
 * To run a query within a React component, call `useGetCourseByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseByIdQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetCourseByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetCourseByIdQuery, GetCourseByIdQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetCourseByIdQuery, GetCourseByIdQueryVariables>(
    GetCourseByIdDocument,
    options
  );
}
export function useGetCourseByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCourseByIdQuery, GetCourseByIdQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetCourseByIdQuery, GetCourseByIdQueryVariables>(
    GetCourseByIdDocument,
    options
  );
}
export type GetCourseByIdQueryHookResult = ReturnType<typeof useGetCourseByIdQuery>;
export type GetCourseByIdLazyQueryHookResult = ReturnType<typeof useGetCourseByIdLazyQuery>;
export type GetCourseByIdQueryResult = Apollo.QueryResult<
  GetCourseByIdQuery,
  GetCourseByIdQueryVariables
>;
export const GetCourseDetailsBySlugDocument = gql`
  query GetCourseDetailsBySlug(
    $slug: String!
    $classesOrderBy: [ClassesOrderBy!]
    $cohortsWhere: CohortsBoolExp
    $skipManagerResources: Boolean = true
  ) {
    courses(
      where: {slug: {_eq: $slug}}
      orderBy: {version: DESC_NULLS_LAST, slug: ASC_NULLS_LAST}
      distinctOn: slug
    ) {
      id
      name
      enrollmentStatus
      slug
      bannerUrl
      shortDescription
      longDescriptionRich
      impactDescriptionList
      impactDescriptionRich
      thumbnailCardUrl
      timeLengthInWeeks
      timeLengthInClasses
      impactRich
      applicationRich
      practicedRich
      type
      tags(orderBy: {tag: {name: ASC}}) {
        tag {
          name
          slug
          tagType {
            name
            slug
            id
          }
        }
      }
      school {
        name
        slug
        id
      }
      managerResources: resources(where: {resource: {type: {_eq: MANAGER_RESOURCE}}})
        @skip(if: $skipManagerResources) {
        resource {
          id
          url
          name
          descriptionRich
          type
        }
      }
      cohorts: availableCohorts(
        where: $cohortsWhere
        orderBy: [{type: ASC}, {startDate: ASC}, {classTimeOfDay: ASC}]
      ) {
        id
        slug
        type
        startDate
        endDate
        dayOfTheWeek
        classTimeOfDay
        name
        maxEnrollment
        totalEnrollments
        classEvents(
          where: {class: {isLive: {_eq: true}}}
          orderBy: {durationInSeconds: DESC_NULLS_LAST}
          limit: 6
        ) {
          startTime
          durationInSeconds
        }
      }
      coaches(orderBy: {coach: {name: ASC}}) {
        coach {
          id
          slug
          name
          title
          company
          avatarUrl
          linkedinUrl
          headshotUrl
          shortBio
          longBioRich
        }
      }
      promoVideoUrl
      backgroundImageUrl
      audience
      classes(orderBy: $classesOrderBy) {
        id
        name
        number
        descriptionRich
        listDescription
        isLive
        durationInMinutes
      }
      relatedCourses {
        course {
          thumbnailCardUrl
          name
          id
          school {
            name
          }
          shortDescription
          cohorts {
            startDate
          }
          timeLengthInClasses
          timeLengthInWeeks
        }
      }
    }
  }
`;

/**
 * __useGetCourseDetailsBySlugQuery__
 *
 * To run a query within a React component, call `useGetCourseDetailsBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseDetailsBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseDetailsBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      classesOrderBy: // value for 'classesOrderBy'
 *      cohortsWhere: // value for 'cohortsWhere'
 *      skipManagerResources: // value for 'skipManagerResources'
 *   },
 * });
 */
export function useGetCourseDetailsBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCourseDetailsBySlugQuery,
    GetCourseDetailsBySlugQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetCourseDetailsBySlugQuery, GetCourseDetailsBySlugQueryVariables>(
    GetCourseDetailsBySlugDocument,
    options
  );
}
export function useGetCourseDetailsBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCourseDetailsBySlugQuery,
    GetCourseDetailsBySlugQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetCourseDetailsBySlugQuery, GetCourseDetailsBySlugQueryVariables>(
    GetCourseDetailsBySlugDocument,
    options
  );
}
export type GetCourseDetailsBySlugQueryHookResult = ReturnType<
  typeof useGetCourseDetailsBySlugQuery
>;
export type GetCourseDetailsBySlugLazyQueryHookResult = ReturnType<
  typeof useGetCourseDetailsBySlugLazyQuery
>;
export type GetCourseDetailsBySlugQueryResult = Apollo.QueryResult<
  GetCourseDetailsBySlugQuery,
  GetCourseDetailsBySlugQueryVariables
>;
export const GetCourseResourcesByCohortDocument = gql`
  query GetCourseResourcesByCohort($slug: String!) {
    resources(where: {resourceCourse: {course: {cohorts: {slug: {_eq: $slug}}}}}) {
      ...ResourcesMetadata
    }
  }
  ${ResourcesMetadataFragmentDoc}
`;

/**
 * __useGetCourseResourcesByCohortQuery__
 *
 * To run a query within a React component, call `useGetCourseResourcesByCohortQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseResourcesByCohortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseResourcesByCohortQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetCourseResourcesByCohortQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCourseResourcesByCohortQuery,
    GetCourseResourcesByCohortQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetCourseResourcesByCohortQuery, GetCourseResourcesByCohortQueryVariables>(
    GetCourseResourcesByCohortDocument,
    options
  );
}
export function useGetCourseResourcesByCohortLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCourseResourcesByCohortQuery,
    GetCourseResourcesByCohortQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetCourseResourcesByCohortQuery,
    GetCourseResourcesByCohortQueryVariables
  >(GetCourseResourcesByCohortDocument, options);
}
export type GetCourseResourcesByCohortQueryHookResult = ReturnType<
  typeof useGetCourseResourcesByCohortQuery
>;
export type GetCourseResourcesByCohortLazyQueryHookResult = ReturnType<
  typeof useGetCourseResourcesByCohortLazyQuery
>;
export type GetCourseResourcesByCohortQueryResult = Apollo.QueryResult<
  GetCourseResourcesByCohortQuery,
  GetCourseResourcesByCohortQueryVariables
>;
export const GetCourseSurveyResponseDocument = gql`
  query GetCourseSurveyResponse($formIds: [String!]!, $userId: uuid!, $cohortId: uuid!) {
    typeformSubmissions(
      where: {formId: {_in: $formIds}, userId: {_eq: $userId}, cohortId: {_eq: $cohortId}}
    ) {
      id
      formId
      userId
      data
    }
  }
`;

/**
 * __useGetCourseSurveyResponseQuery__
 *
 * To run a query within a React component, call `useGetCourseSurveyResponseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseSurveyResponseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseSurveyResponseQuery({
 *   variables: {
 *      formIds: // value for 'formIds'
 *      userId: // value for 'userId'
 *      cohortId: // value for 'cohortId'
 *   },
 * });
 */
export function useGetCourseSurveyResponseQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCourseSurveyResponseQuery,
    GetCourseSurveyResponseQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetCourseSurveyResponseQuery, GetCourseSurveyResponseQueryVariables>(
    GetCourseSurveyResponseDocument,
    options
  );
}
export function useGetCourseSurveyResponseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCourseSurveyResponseQuery,
    GetCourseSurveyResponseQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetCourseSurveyResponseQuery, GetCourseSurveyResponseQueryVariables>(
    GetCourseSurveyResponseDocument,
    options
  );
}
export type GetCourseSurveyResponseQueryHookResult = ReturnType<
  typeof useGetCourseSurveyResponseQuery
>;
export type GetCourseSurveyResponseLazyQueryHookResult = ReturnType<
  typeof useGetCourseSurveyResponseLazyQuery
>;
export type GetCourseSurveyResponseQueryResult = Apollo.QueryResult<
  GetCourseSurveyResponseQuery,
  GetCourseSurveyResponseQueryVariables
>;
export const GetCourseWaitlistWithIdsDocument = gql`
  query GetCourseWaitlistWithIds($ids: [uuid!]) {
    courseWaitlist(where: {id: {_in: $ids}}) {
      id
      availabilityNotificationSent
      course {
        slug
      }
      user {
        firstName
        lastName
      }
      createdBy {
        id
        firstName
        lastName
        identity {
          email
        }
      }
      createdOnBehalfOf {
        id
        firstName
        lastName
        identity {
          email
        }
      }
    }
  }
`;

/**
 * __useGetCourseWaitlistWithIdsQuery__
 *
 * To run a query within a React component, call `useGetCourseWaitlistWithIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseWaitlistWithIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseWaitlistWithIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetCourseWaitlistWithIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCourseWaitlistWithIdsQuery,
    GetCourseWaitlistWithIdsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetCourseWaitlistWithIdsQuery, GetCourseWaitlistWithIdsQueryVariables>(
    GetCourseWaitlistWithIdsDocument,
    options
  );
}
export function useGetCourseWaitlistWithIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCourseWaitlistWithIdsQuery,
    GetCourseWaitlistWithIdsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetCourseWaitlistWithIdsQuery, GetCourseWaitlistWithIdsQueryVariables>(
    GetCourseWaitlistWithIdsDocument,
    options
  );
}
export type GetCourseWaitlistWithIdsQueryHookResult = ReturnType<
  typeof useGetCourseWaitlistWithIdsQuery
>;
export type GetCourseWaitlistWithIdsLazyQueryHookResult = ReturnType<
  typeof useGetCourseWaitlistWithIdsLazyQuery
>;
export type GetCourseWaitlistWithIdsQueryResult = Apollo.QueryResult<
  GetCourseWaitlistWithIdsQuery,
  GetCourseWaitlistWithIdsQueryVariables
>;
export const GetCourseWaitlistUrgenciesDocument = gql`
  query GetCourseWaitlistUrgencies {
    courseWaitlistDesiredUrgencyType(orderBy: {description: ASC}) {
      value
      description
    }
  }
`;

/**
 * __useGetCourseWaitlistUrgenciesQuery__
 *
 * To run a query within a React component, call `useGetCourseWaitlistUrgenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseWaitlistUrgenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseWaitlistUrgenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCourseWaitlistUrgenciesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCourseWaitlistUrgenciesQuery,
    GetCourseWaitlistUrgenciesQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetCourseWaitlistUrgenciesQuery, GetCourseWaitlistUrgenciesQueryVariables>(
    GetCourseWaitlistUrgenciesDocument,
    options
  );
}
export function useGetCourseWaitlistUrgenciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCourseWaitlistUrgenciesQuery,
    GetCourseWaitlistUrgenciesQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetCourseWaitlistUrgenciesQuery,
    GetCourseWaitlistUrgenciesQueryVariables
  >(GetCourseWaitlistUrgenciesDocument, options);
}
export type GetCourseWaitlistUrgenciesQueryHookResult = ReturnType<
  typeof useGetCourseWaitlistUrgenciesQuery
>;
export type GetCourseWaitlistUrgenciesLazyQueryHookResult = ReturnType<
  typeof useGetCourseWaitlistUrgenciesLazyQuery
>;
export type GetCourseWaitlistUrgenciesQueryResult = Apollo.QueryResult<
  GetCourseWaitlistUrgenciesQuery,
  GetCourseWaitlistUrgenciesQueryVariables
>;
export const AddEmailsToQueueDocument = gql`
  mutation AddEmailsToQueue($objects: [EmailQueueInsertInput!]!) {
    insertEmailQueue(objects: $objects) {
      affectedRows
      returning {
        id
        data
      }
    }
  }
`;
export type AddEmailsToQueueMutationFn = Apollo.MutationFunction<
  AddEmailsToQueueMutation,
  AddEmailsToQueueMutationVariables
>;

/**
 * __useAddEmailsToQueueMutation__
 *
 * To run a mutation, you first call `useAddEmailsToQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEmailsToQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEmailsToQueueMutation, { data, loading, error }] = useAddEmailsToQueueMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddEmailsToQueueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddEmailsToQueueMutation,
    AddEmailsToQueueMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<AddEmailsToQueueMutation, AddEmailsToQueueMutationVariables>(
    AddEmailsToQueueDocument,
    options
  );
}
export type AddEmailsToQueueMutationHookResult = ReturnType<typeof useAddEmailsToQueueMutation>;
export type AddEmailsToQueueMutationResult = Apollo.MutationResult<AddEmailsToQueueMutation>;
export type AddEmailsToQueueMutationOptions = Apollo.BaseMutationOptions<
  AddEmailsToQueueMutation,
  AddEmailsToQueueMutationVariables
>;
export const GetBeforeClassReminderEmailsDocument = gql`
  query GetBeforeClassReminderEmails {
    userSessionEmails(
      where: {
        cohortType: {_eq: "LIVE"}
        isStartingSoon: {_eq: true}
        user: {meta: {isSubscribedToReminderEmails: {_eq: true}}, active: {_eq: true}}
        enrollment: {deletedAt: {_isNull: true}}
        hasSentHourReminderEmail: {_eq: false}
        courseType: {_eq: "LIGHTNING_SESSION"}
      }
    ) {
      userClassEventId
      courseName
      leadCoachName
      leadCoachCompany
      frameworkUrl
      user {
        firstName
        identity {
          email
        }
      }
    }
  }
`;

/**
 * __useGetBeforeClassReminderEmailsQuery__
 *
 * To run a query within a React component, call `useGetBeforeClassReminderEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBeforeClassReminderEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBeforeClassReminderEmailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBeforeClassReminderEmailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBeforeClassReminderEmailsQuery,
    GetBeforeClassReminderEmailsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetBeforeClassReminderEmailsQuery,
    GetBeforeClassReminderEmailsQueryVariables
  >(GetBeforeClassReminderEmailsDocument, options);
}
export function useGetBeforeClassReminderEmailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBeforeClassReminderEmailsQuery,
    GetBeforeClassReminderEmailsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetBeforeClassReminderEmailsQuery,
    GetBeforeClassReminderEmailsQueryVariables
  >(GetBeforeClassReminderEmailsDocument, options);
}
export type GetBeforeClassReminderEmailsQueryHookResult = ReturnType<
  typeof useGetBeforeClassReminderEmailsQuery
>;
export type GetBeforeClassReminderEmailsLazyQueryHookResult = ReturnType<
  typeof useGetBeforeClassReminderEmailsLazyQuery
>;
export type GetBeforeClassReminderEmailsQueryResult = Apollo.QueryResult<
  GetBeforeClassReminderEmailsQuery,
  GetBeforeClassReminderEmailsQueryVariables
>;
export const GetCohortEnrollmentForSubmissionNotificationDocument = gql`
  query GetCohortEnrollmentForSubmissionNotification($cohortId: uuid!, $userId: uuid!) {
    enrollments(
      where: {
        _and: [{userId: {_eq: $userId}}, {cohortId: {_eq: $cohortId}}, {deletedAt: {_isNull: true}}]
      }
    ) {
      cohort {
        slug
        startDate
        course {
          name
        }
      }
      user {
        firstName
        identity {
          email
        }
      }
    }
  }
`;

/**
 * __useGetCohortEnrollmentForSubmissionNotificationQuery__
 *
 * To run a query within a React component, call `useGetCohortEnrollmentForSubmissionNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCohortEnrollmentForSubmissionNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCohortEnrollmentForSubmissionNotificationQuery({
 *   variables: {
 *      cohortId: // value for 'cohortId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetCohortEnrollmentForSubmissionNotificationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCohortEnrollmentForSubmissionNotificationQuery,
    GetCohortEnrollmentForSubmissionNotificationQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetCohortEnrollmentForSubmissionNotificationQuery,
    GetCohortEnrollmentForSubmissionNotificationQueryVariables
  >(GetCohortEnrollmentForSubmissionNotificationDocument, options);
}
export function useGetCohortEnrollmentForSubmissionNotificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCohortEnrollmentForSubmissionNotificationQuery,
    GetCohortEnrollmentForSubmissionNotificationQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetCohortEnrollmentForSubmissionNotificationQuery,
    GetCohortEnrollmentForSubmissionNotificationQueryVariables
  >(GetCohortEnrollmentForSubmissionNotificationDocument, options);
}
export type GetCohortEnrollmentForSubmissionNotificationQueryHookResult = ReturnType<
  typeof useGetCohortEnrollmentForSubmissionNotificationQuery
>;
export type GetCohortEnrollmentForSubmissionNotificationLazyQueryHookResult = ReturnType<
  typeof useGetCohortEnrollmentForSubmissionNotificationLazyQuery
>;
export type GetCohortEnrollmentForSubmissionNotificationQueryResult = Apollo.QueryResult<
  GetCohortEnrollmentForSubmissionNotificationQuery,
  GetCohortEnrollmentForSubmissionNotificationQueryVariables
>;
export const GetLightningSubmissionsByCoachAndCohortDocument = gql`
  query GetLightningSubmissionsByCoachAndCohort($coachUserId: uuid!, $cohortId: uuid!) {
    lightningSessionSubmissions(
      where: {
        coachUserId: {_eq: $coachUserId}
        cohortId: {_eq: $cohortId}
        deletedAt: {_isNull: true}
      }
    ) {
      id
      status
      coachUserId
      coach {
        id
        fullName
        firstName
        lastName
        identity {
          email
        }
      }
      cohort {
        id
        name
        startDate
        course {
          name
        }
      }
    }
  }
`;

/**
 * __useGetLightningSubmissionsByCoachAndCohortQuery__
 *
 * To run a query within a React component, call `useGetLightningSubmissionsByCoachAndCohortQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLightningSubmissionsByCoachAndCohortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLightningSubmissionsByCoachAndCohortQuery({
 *   variables: {
 *      coachUserId: // value for 'coachUserId'
 *      cohortId: // value for 'cohortId'
 *   },
 * });
 */
export function useGetLightningSubmissionsByCoachAndCohortQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLightningSubmissionsByCoachAndCohortQuery,
    GetLightningSubmissionsByCoachAndCohortQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetLightningSubmissionsByCoachAndCohortQuery,
    GetLightningSubmissionsByCoachAndCohortQueryVariables
  >(GetLightningSubmissionsByCoachAndCohortDocument, options);
}
export function useGetLightningSubmissionsByCoachAndCohortLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLightningSubmissionsByCoachAndCohortQuery,
    GetLightningSubmissionsByCoachAndCohortQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetLightningSubmissionsByCoachAndCohortQuery,
    GetLightningSubmissionsByCoachAndCohortQueryVariables
  >(GetLightningSubmissionsByCoachAndCohortDocument, options);
}
export type GetLightningSubmissionsByCoachAndCohortQueryHookResult = ReturnType<
  typeof useGetLightningSubmissionsByCoachAndCohortQuery
>;
export type GetLightningSubmissionsByCoachAndCohortLazyQueryHookResult = ReturnType<
  typeof useGetLightningSubmissionsByCoachAndCohortLazyQuery
>;
export type GetLightningSubmissionsByCoachAndCohortQueryResult = Apollo.QueryResult<
  GetLightningSubmissionsByCoachAndCohortQuery,
  GetLightningSubmissionsByCoachAndCohortQueryVariables
>;
export const GetManagerPostSessionEmailsDocument = gql`
  query GetManagerPostSessionEmails {
    managerSessionEmails(
      where: {
        isWithinLast7Days: {_eq: true}
        isAllFeedbackSkippedOrReady: {_eq: true}
        user: {meta: {isSubscribedToReminderEmails: {_eq: true}}, active: {_eq: true}}
        hasSentPostSessionEmail: {_eq: false}
      }
    ) {
      courseName
      coursePlatformUrl
      creditModel
      csmEmail
      csmFullName
      csmSignature
      enrolleesCommaSeparatedList
      enrolleesForPast
      frameworkHighlight
      managerCohortId
      managerPastSessionUrl
      percentUsersAttended
      posterFrameUrl
      recommendations
      startTimeFormatted
      user {
        firstName
        identity {
          email
        }
      }
    }
  }
`;

/**
 * __useGetManagerPostSessionEmailsQuery__
 *
 * To run a query within a React component, call `useGetManagerPostSessionEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagerPostSessionEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagerPostSessionEmailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManagerPostSessionEmailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManagerPostSessionEmailsQuery,
    GetManagerPostSessionEmailsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetManagerPostSessionEmailsQuery,
    GetManagerPostSessionEmailsQueryVariables
  >(GetManagerPostSessionEmailsDocument, options);
}
export function useGetManagerPostSessionEmailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagerPostSessionEmailsQuery,
    GetManagerPostSessionEmailsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetManagerPostSessionEmailsQuery,
    GetManagerPostSessionEmailsQueryVariables
  >(GetManagerPostSessionEmailsDocument, options);
}
export type GetManagerPostSessionEmailsQueryHookResult = ReturnType<
  typeof useGetManagerPostSessionEmailsQuery
>;
export type GetManagerPostSessionEmailsLazyQueryHookResult = ReturnType<
  typeof useGetManagerPostSessionEmailsLazyQuery
>;
export type GetManagerPostSessionEmailsQueryResult = Apollo.QueryResult<
  GetManagerPostSessionEmailsQuery,
  GetManagerPostSessionEmailsQueryVariables
>;
export const GetManagerPreSessionEmailsDocument = gql`
  query GetManagerPreSessionEmails {
    managerSessionEmails(
      where: {
        isStartingSoon: {_eq: true}
        user: {meta: {isSubscribedToReminderEmails: {_eq: true}}, active: {_eq: true}}
        hasSentPreSessionEmail: {_eq: false}
      }
    ) {
      courseName
      coursePlatformUrl
      csmEmail
      csmFullName
      csmSignature
      enrolleeEmails
      enrolleesForUpcoming
      firstClassDuration
      managerCohortId
      managerUpcomingSessionUrl
      startTimeFormatted
      user {
        firstName
        identity {
          email
        }
      }
    }
  }
`;

/**
 * __useGetManagerPreSessionEmailsQuery__
 *
 * To run a query within a React component, call `useGetManagerPreSessionEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagerPreSessionEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagerPreSessionEmailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManagerPreSessionEmailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManagerPreSessionEmailsQuery,
    GetManagerPreSessionEmailsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetManagerPreSessionEmailsQuery, GetManagerPreSessionEmailsQueryVariables>(
    GetManagerPreSessionEmailsDocument,
    options
  );
}
export function useGetManagerPreSessionEmailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagerPreSessionEmailsQuery,
    GetManagerPreSessionEmailsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetManagerPreSessionEmailsQuery,
    GetManagerPreSessionEmailsQueryVariables
  >(GetManagerPreSessionEmailsDocument, options);
}
export type GetManagerPreSessionEmailsQueryHookResult = ReturnType<
  typeof useGetManagerPreSessionEmailsQuery
>;
export type GetManagerPreSessionEmailsLazyQueryHookResult = ReturnType<
  typeof useGetManagerPreSessionEmailsLazyQuery
>;
export type GetManagerPreSessionEmailsQueryResult = Apollo.QueryResult<
  GetManagerPreSessionEmailsQuery,
  GetManagerPreSessionEmailsQueryVariables
>;
export const GetMondayReminderEmailsDocument = gql`
  query GetMondayReminderEmails {
    userSessionEmails(
      where: {
        cohortType: {_eq: "LIVE"}
        isThisWeek: {_eq: true}
        user: {meta: {isSubscribedToReminderEmails: {_eq: true}}, active: {_eq: true}}
        enrollment: {deletedAt: {_isNull: true}}
        hasSentMondayReminderEmail: {_eq: false}
        courseType: {_eq: "LIGHTNING_SESSION"}
      }
    ) {
      userClassEventId
      courseName
      cohortSlug
      formattedTimestamp
      isReturningUser
      creditModel
      leadCoachName
      leadCoachCompany
      frameworkUrl
      user {
        firstName
        identity {
          email
        }
      }
    }
  }
`;

/**
 * __useGetMondayReminderEmailsQuery__
 *
 * To run a query within a React component, call `useGetMondayReminderEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMondayReminderEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMondayReminderEmailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMondayReminderEmailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMondayReminderEmailsQuery,
    GetMondayReminderEmailsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetMondayReminderEmailsQuery, GetMondayReminderEmailsQueryVariables>(
    GetMondayReminderEmailsDocument,
    options
  );
}
export function useGetMondayReminderEmailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMondayReminderEmailsQuery,
    GetMondayReminderEmailsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetMondayReminderEmailsQuery, GetMondayReminderEmailsQueryVariables>(
    GetMondayReminderEmailsDocument,
    options
  );
}
export type GetMondayReminderEmailsQueryHookResult = ReturnType<
  typeof useGetMondayReminderEmailsQuery
>;
export type GetMondayReminderEmailsLazyQueryHookResult = ReturnType<
  typeof useGetMondayReminderEmailsLazyQuery
>;
export type GetMondayReminderEmailsQueryResult = Apollo.QueryResult<
  GetMondayReminderEmailsQuery,
  GetMondayReminderEmailsQueryVariables
>;
export const CheckEnrollmentStatusForSubordinateDocument = gql`
  query CheckEnrollmentStatusForSubordinate($cohortIds: [uuid!], $userId: uuid!) {
    enrollmentCount: subordinatesAggregate(
      where: {userId: {_eq: $userId}, isActive: {_eq: true}, enrollments: {_contains: $cohortIds}}
    ) {
      aggregate {
        count
      }
    }
    waitlistCount: subordinatesAggregate(
      where: {userId: {_eq: $userId}, isActive: {_eq: true}, waitlists: {_contains: $cohortIds}}
    ) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useCheckEnrollmentStatusForSubordinateQuery__
 *
 * To run a query within a React component, call `useCheckEnrollmentStatusForSubordinateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckEnrollmentStatusForSubordinateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckEnrollmentStatusForSubordinateQuery({
 *   variables: {
 *      cohortIds: // value for 'cohortIds'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCheckEnrollmentStatusForSubordinateQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckEnrollmentStatusForSubordinateQuery,
    CheckEnrollmentStatusForSubordinateQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    CheckEnrollmentStatusForSubordinateQuery,
    CheckEnrollmentStatusForSubordinateQueryVariables
  >(CheckEnrollmentStatusForSubordinateDocument, options);
}
export function useCheckEnrollmentStatusForSubordinateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckEnrollmentStatusForSubordinateQuery,
    CheckEnrollmentStatusForSubordinateQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    CheckEnrollmentStatusForSubordinateQuery,
    CheckEnrollmentStatusForSubordinateQueryVariables
  >(CheckEnrollmentStatusForSubordinateDocument, options);
}
export type CheckEnrollmentStatusForSubordinateQueryHookResult = ReturnType<
  typeof useCheckEnrollmentStatusForSubordinateQuery
>;
export type CheckEnrollmentStatusForSubordinateLazyQueryHookResult = ReturnType<
  typeof useCheckEnrollmentStatusForSubordinateLazyQuery
>;
export type CheckEnrollmentStatusForSubordinateQueryResult = Apollo.QueryResult<
  CheckEnrollmentStatusForSubordinateQuery,
  CheckEnrollmentStatusForSubordinateQueryVariables
>;
export const GetClassEventByUserDocument = gql`
  query GetClassEventByUser($userId: uuid!, $classEventId: uuid!) {
    learner: usersByPk(id: $userId) {
      id
      firstName
      lastName
      identity(limit: 1) {
        email
      }
    }
    classEvent: classEventsByPk(id: $classEventId) {
      id
      zoomMeetingId
      startTime
    }
  }
`;

/**
 * __useGetClassEventByUserQuery__
 *
 * To run a query within a React component, call `useGetClassEventByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassEventByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassEventByUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      classEventId: // value for 'classEventId'
 *   },
 * });
 */
export function useGetClassEventByUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetClassEventByUserQuery, GetClassEventByUserQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetClassEventByUserQuery, GetClassEventByUserQueryVariables>(
    GetClassEventByUserDocument,
    options
  );
}
export function useGetClassEventByUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassEventByUserQuery,
    GetClassEventByUserQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetClassEventByUserQuery, GetClassEventByUserQueryVariables>(
    GetClassEventByUserDocument,
    options
  );
}
export type GetClassEventByUserQueryHookResult = ReturnType<typeof useGetClassEventByUserQuery>;
export type GetClassEventByUserLazyQueryHookResult = ReturnType<
  typeof useGetClassEventByUserLazyQuery
>;
export type GetClassEventByUserQueryResult = Apollo.QueryResult<
  GetClassEventByUserQuery,
  GetClassEventByUserQueryVariables
>;
export const GetCohortByIdDocument = gql`
  query GetCohortById($cohortId: uuid!) {
    cohortsByPk(id: $cohortId) {
      ...CoreEnrollmentCohortFragment
    }
  }
  ${CoreEnrollmentCohortFragmentFragmentDoc}
`;

/**
 * __useGetCohortByIdQuery__
 *
 * To run a query within a React component, call `useGetCohortByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCohortByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCohortByIdQuery({
 *   variables: {
 *      cohortId: // value for 'cohortId'
 *   },
 * });
 */
export function useGetCohortByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetCohortByIdQuery, GetCohortByIdQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetCohortByIdQuery, GetCohortByIdQueryVariables>(
    GetCohortByIdDocument,
    options
  );
}
export function useGetCohortByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCohortByIdQuery, GetCohortByIdQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetCohortByIdQuery, GetCohortByIdQueryVariables>(
    GetCohortByIdDocument,
    options
  );
}
export type GetCohortByIdQueryHookResult = ReturnType<typeof useGetCohortByIdQuery>;
export type GetCohortByIdLazyQueryHookResult = ReturnType<typeof useGetCohortByIdLazyQuery>;
export type GetCohortByIdQueryResult = Apollo.QueryResult<
  GetCohortByIdQuery,
  GetCohortByIdQueryVariables
>;
export const GetCohortsByIdDocument = gql`
  query GetCohortsById($cohortIds: [uuid!]) {
    cohorts(where: {id: {_in: $cohortIds}}) {
      ...CoreEnrollmentCohortFragment
    }
  }
  ${CoreEnrollmentCohortFragmentFragmentDoc}
`;

/**
 * __useGetCohortsByIdQuery__
 *
 * To run a query within a React component, call `useGetCohortsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCohortsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCohortsByIdQuery({
 *   variables: {
 *      cohortIds: // value for 'cohortIds'
 *   },
 * });
 */
export function useGetCohortsByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCohortsByIdQuery, GetCohortsByIdQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetCohortsByIdQuery, GetCohortsByIdQueryVariables>(
    GetCohortsByIdDocument,
    options
  );
}
export function useGetCohortsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCohortsByIdQuery, GetCohortsByIdQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetCohortsByIdQuery, GetCohortsByIdQueryVariables>(
    GetCohortsByIdDocument,
    options
  );
}
export type GetCohortsByIdQueryHookResult = ReturnType<typeof useGetCohortsByIdQuery>;
export type GetCohortsByIdLazyQueryHookResult = ReturnType<typeof useGetCohortsByIdLazyQuery>;
export type GetCohortsByIdQueryResult = Apollo.QueryResult<
  GetCohortsByIdQuery,
  GetCohortsByIdQueryVariables
>;
export const GetEnrollLearnerCohortInfoDocument = gql`
  query GetEnrollLearnerCohortInfo($userId: uuid!, $cohortId: uuid!) {
    cohort: cohortsByPk(id: $cohortId) {
      code
      id
      maxEnrollment
      type
      visibility
      course {
        id
        type
        enrollmentStatus
      }
    }
    userEnrollments: usersByPk(id: $userId) {
      allowEnrollmentsBySubscription
    }
    enrolledLearners: enrollmentsAggregate(
      where: {deletedAt: {_isNull: true}, cohortId: {_eq: $cohortId}, user: {active: {_eq: true}}}
    ) {
      total: aggregate {
        count(columns: userId, distinct: true)
      }
    }
    userEnrolled: enrollmentsAggregate(
      where: {cohortId: {_eq: $cohortId}, userId: {_eq: $userId}, deletedAt: {_isNull: true}}
    ) {
      enrolled: aggregate {
        yes: count(columns: userId, distinct: true)
      }
      nodes {
        id
      }
    }
  }
`;

/**
 * __useGetEnrollLearnerCohortInfoQuery__
 *
 * To run a query within a React component, call `useGetEnrollLearnerCohortInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnrollLearnerCohortInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnrollLearnerCohortInfoQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      cohortId: // value for 'cohortId'
 *   },
 * });
 */
export function useGetEnrollLearnerCohortInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEnrollLearnerCohortInfoQuery,
    GetEnrollLearnerCohortInfoQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetEnrollLearnerCohortInfoQuery, GetEnrollLearnerCohortInfoQueryVariables>(
    GetEnrollLearnerCohortInfoDocument,
    options
  );
}
export function useGetEnrollLearnerCohortInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEnrollLearnerCohortInfoQuery,
    GetEnrollLearnerCohortInfoQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetEnrollLearnerCohortInfoQuery,
    GetEnrollLearnerCohortInfoQueryVariables
  >(GetEnrollLearnerCohortInfoDocument, options);
}
export type GetEnrollLearnerCohortInfoQueryHookResult = ReturnType<
  typeof useGetEnrollLearnerCohortInfoQuery
>;
export type GetEnrollLearnerCohortInfoLazyQueryHookResult = ReturnType<
  typeof useGetEnrollLearnerCohortInfoLazyQuery
>;
export type GetEnrollLearnerCohortInfoQueryResult = Apollo.QueryResult<
  GetEnrollLearnerCohortInfoQuery,
  GetEnrollLearnerCohortInfoQueryVariables
>;
export const GetEnrollmentsByCohortDocument = gql`
  query GetEnrollmentsByCohort($cohortId: uuid!) {
    enrollments(where: {cohortId: {_eq: $cohortId}}, orderBy: {user: {lastName: ASC}}) {
      user {
        ...CoreEnrollmentLearnerFragment
        enrollments {
          cohort {
            ...CoreEnrollmentCohortFragment
          }
        }
      }
    }
  }
  ${CoreEnrollmentLearnerFragmentFragmentDoc}
  ${CoreEnrollmentCohortFragmentFragmentDoc}
`;

/**
 * __useGetEnrollmentsByCohortQuery__
 *
 * To run a query within a React component, call `useGetEnrollmentsByCohortQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnrollmentsByCohortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnrollmentsByCohortQuery({
 *   variables: {
 *      cohortId: // value for 'cohortId'
 *   },
 * });
 */
export function useGetEnrollmentsByCohortQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEnrollmentsByCohortQuery,
    GetEnrollmentsByCohortQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetEnrollmentsByCohortQuery, GetEnrollmentsByCohortQueryVariables>(
    GetEnrollmentsByCohortDocument,
    options
  );
}
export function useGetEnrollmentsByCohortLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEnrollmentsByCohortQuery,
    GetEnrollmentsByCohortQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetEnrollmentsByCohortQuery, GetEnrollmentsByCohortQueryVariables>(
    GetEnrollmentsByCohortDocument,
    options
  );
}
export type GetEnrollmentsByCohortQueryHookResult = ReturnType<
  typeof useGetEnrollmentsByCohortQuery
>;
export type GetEnrollmentsByCohortLazyQueryHookResult = ReturnType<
  typeof useGetEnrollmentsByCohortLazyQuery
>;
export type GetEnrollmentsByCohortQueryResult = Apollo.QueryResult<
  GetEnrollmentsByCohortQuery,
  GetEnrollmentsByCohortQueryVariables
>;
export const GetEnrollmentCapAndCountDocument = gql`
  query GetEnrollmentCapAndCount($cohortId: uuid!, $now: timestamp!) {
    cohortsByPk(id: $cohortId) {
      course {
        type
      }
      nextClassEventStartTime: classEventsAggregate(where: {startTime: {_gt: $now}}) {
        aggregate {
          min {
            startTime
          }
        }
      }
      enrollmentCap: maxEnrollment
      enrollmentCount: enrollmentsAggregate(
        where: {deletedAt: {_isNull: true}, user: {active: {_eq: true}}}
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

/**
 * __useGetEnrollmentCapAndCountQuery__
 *
 * To run a query within a React component, call `useGetEnrollmentCapAndCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnrollmentCapAndCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnrollmentCapAndCountQuery({
 *   variables: {
 *      cohortId: // value for 'cohortId'
 *      now: // value for 'now'
 *   },
 * });
 */
export function useGetEnrollmentCapAndCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEnrollmentCapAndCountQuery,
    GetEnrollmentCapAndCountQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetEnrollmentCapAndCountQuery, GetEnrollmentCapAndCountQueryVariables>(
    GetEnrollmentCapAndCountDocument,
    options
  );
}
export function useGetEnrollmentCapAndCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEnrollmentCapAndCountQuery,
    GetEnrollmentCapAndCountQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetEnrollmentCapAndCountQuery, GetEnrollmentCapAndCountQueryVariables>(
    GetEnrollmentCapAndCountDocument,
    options
  );
}
export type GetEnrollmentCapAndCountQueryHookResult = ReturnType<
  typeof useGetEnrollmentCapAndCountQuery
>;
export type GetEnrollmentCapAndCountLazyQueryHookResult = ReturnType<
  typeof useGetEnrollmentCapAndCountLazyQuery
>;
export type GetEnrollmentCapAndCountQueryResult = Apollo.QueryResult<
  GetEnrollmentCapAndCountQuery,
  GetEnrollmentCapAndCountQueryVariables
>;
export const GetEnrollmentWaitlistByCohortDocument = gql`
  query GetEnrollmentWaitlistByCohort($cohortId: uuid!, $limit: Int) {
    waitlistSize: enrollmentWaitlistsAggregate(
      where: {cohortId: {_eq: $cohortId}, deletedAt: {_isNull: true}}
    ) {
      aggregate {
        count
      }
    }
    enrollmentWaitlists(
      where: {cohortId: {_eq: $cohortId}, deletedAt: {_isNull: true}}
      limit: $limit
      orderBy: {createdAt: ASC_NULLS_LAST}
    ) {
      userId
      organizationId
      createdById
      createdOnBehalfOfId
    }
  }
`;

/**
 * __useGetEnrollmentWaitlistByCohortQuery__
 *
 * To run a query within a React component, call `useGetEnrollmentWaitlistByCohortQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnrollmentWaitlistByCohortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnrollmentWaitlistByCohortQuery({
 *   variables: {
 *      cohortId: // value for 'cohortId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetEnrollmentWaitlistByCohortQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEnrollmentWaitlistByCohortQuery,
    GetEnrollmentWaitlistByCohortQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetEnrollmentWaitlistByCohortQuery,
    GetEnrollmentWaitlistByCohortQueryVariables
  >(GetEnrollmentWaitlistByCohortDocument, options);
}
export function useGetEnrollmentWaitlistByCohortLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEnrollmentWaitlistByCohortQuery,
    GetEnrollmentWaitlistByCohortQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetEnrollmentWaitlistByCohortQuery,
    GetEnrollmentWaitlistByCohortQueryVariables
  >(GetEnrollmentWaitlistByCohortDocument, options);
}
export type GetEnrollmentWaitlistByCohortQueryHookResult = ReturnType<
  typeof useGetEnrollmentWaitlistByCohortQuery
>;
export type GetEnrollmentWaitlistByCohortLazyQueryHookResult = ReturnType<
  typeof useGetEnrollmentWaitlistByCohortLazyQuery
>;
export type GetEnrollmentWaitlistByCohortQueryResult = Apollo.QueryResult<
  GetEnrollmentWaitlistByCohortQuery,
  GetEnrollmentWaitlistByCohortQueryVariables
>;
export const GetEnrollmentsByUserCohortDocument = gql`
  query GetEnrollmentsByUserCohort(
    $userId: uuid!
    $cohortId: uuid!
    $withRegistrations: Boolean = false
  ) {
    learner: usersByPk(id: $userId) {
      id
      firstName
      lastName
      identity(limit: 1) {
        email
      }
      userRegistrations @include(if: $withRegistrations) {
        timezone
      }
    }
    enrollments(
      where: {cohortId: {_eq: $cohortId}, userId: {_eq: $userId}, deletedAt: {_isNull: true}}
    ) {
      id
    }
    waitlists: enrollmentWaitlists(
      where: {cohortId: {_eq: $cohortId}, userId: {_eq: $userId}, deletedAt: {_isNull: true}}
    ) {
      id
    }
    cohort: cohortsByPk(id: $cohortId) {
      id
      name
      code
      slug
      startDate
      endDate
      type
      course {
        id
        name
        type
        coaches {
          coach {
            name
          }
        }
      }
      coaches {
        coach {
          name
        }
      }
      classEvents {
        id
        eventInviteVersion
        startTime
        class {
          id
          name
          number
          isLive
          coaches {
            coach {
              name
            }
          }
        }
        startTime
        endTime
      }
    }
  }
`;

/**
 * __useGetEnrollmentsByUserCohortQuery__
 *
 * To run a query within a React component, call `useGetEnrollmentsByUserCohortQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnrollmentsByUserCohortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnrollmentsByUserCohortQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      cohortId: // value for 'cohortId'
 *      withRegistrations: // value for 'withRegistrations'
 *   },
 * });
 */
export function useGetEnrollmentsByUserCohortQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEnrollmentsByUserCohortQuery,
    GetEnrollmentsByUserCohortQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetEnrollmentsByUserCohortQuery, GetEnrollmentsByUserCohortQueryVariables>(
    GetEnrollmentsByUserCohortDocument,
    options
  );
}
export function useGetEnrollmentsByUserCohortLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEnrollmentsByUserCohortQuery,
    GetEnrollmentsByUserCohortQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetEnrollmentsByUserCohortQuery,
    GetEnrollmentsByUserCohortQueryVariables
  >(GetEnrollmentsByUserCohortDocument, options);
}
export type GetEnrollmentsByUserCohortQueryHookResult = ReturnType<
  typeof useGetEnrollmentsByUserCohortQuery
>;
export type GetEnrollmentsByUserCohortLazyQueryHookResult = ReturnType<
  typeof useGetEnrollmentsByUserCohortLazyQuery
>;
export type GetEnrollmentsByUserCohortQueryResult = Apollo.QueryResult<
  GetEnrollmentsByUserCohortQuery,
  GetEnrollmentsByUserCohortQueryVariables
>;
export const GetSubordinateEnrollmentsDocument = gql`
  query GetSubordinateEnrollments($cohortId: uuid!, $isActive: Boolean = true) {
    subordinates(where: {isActive: {_eq: $isActive}}, orderBy: {teamNames: ASC}) {
      firstName
      lastName
      email
      teamNames
      userId
      imageUrl
      enrollments
      waitlists
    }
    cohortsByPk(id: $cohortId) {
      maxEnrollment
      type
      totalEnrollments
      course {
        name
        type
      }
    }
  }
`;

/**
 * __useGetSubordinateEnrollmentsQuery__
 *
 * To run a query within a React component, call `useGetSubordinateEnrollmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubordinateEnrollmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubordinateEnrollmentsQuery({
 *   variables: {
 *      cohortId: // value for 'cohortId'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useGetSubordinateEnrollmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSubordinateEnrollmentsQuery,
    GetSubordinateEnrollmentsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetSubordinateEnrollmentsQuery, GetSubordinateEnrollmentsQueryVariables>(
    GetSubordinateEnrollmentsDocument,
    options
  );
}
export function useGetSubordinateEnrollmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubordinateEnrollmentsQuery,
    GetSubordinateEnrollmentsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetSubordinateEnrollmentsQuery,
    GetSubordinateEnrollmentsQueryVariables
  >(GetSubordinateEnrollmentsDocument, options);
}
export type GetSubordinateEnrollmentsQueryHookResult = ReturnType<
  typeof useGetSubordinateEnrollmentsQuery
>;
export type GetSubordinateEnrollmentsLazyQueryHookResult = ReturnType<
  typeof useGetSubordinateEnrollmentsLazyQuery
>;
export type GetSubordinateEnrollmentsQueryResult = Apollo.QueryResult<
  GetSubordinateEnrollmentsQuery,
  GetSubordinateEnrollmentsQueryVariables
>;
export const FetchUserCohortEnrollmentStatusDocument = gql`
  query FetchUserCohortEnrollmentStatus($cohortId: uuid!, $userId: uuid!) {
    enrollments(
      where: {cohortId: {_eq: $cohortId}, userId: {_eq: $userId}}
      orderBy: {createdAt: DESC}
      limit: 1
    ) {
      cohort {
        ...CoreEnrollmentCohortFragment
      }
      createdAt
      deletedAt
    }
  }
  ${CoreEnrollmentCohortFragmentFragmentDoc}
`;

/**
 * __useFetchUserCohortEnrollmentStatusQuery__
 *
 * To run a query within a React component, call `useFetchUserCohortEnrollmentStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserCohortEnrollmentStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserCohortEnrollmentStatusQuery({
 *   variables: {
 *      cohortId: // value for 'cohortId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useFetchUserCohortEnrollmentStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchUserCohortEnrollmentStatusQuery,
    FetchUserCohortEnrollmentStatusQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    FetchUserCohortEnrollmentStatusQuery,
    FetchUserCohortEnrollmentStatusQueryVariables
  >(FetchUserCohortEnrollmentStatusDocument, options);
}
export function useFetchUserCohortEnrollmentStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchUserCohortEnrollmentStatusQuery,
    FetchUserCohortEnrollmentStatusQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    FetchUserCohortEnrollmentStatusQuery,
    FetchUserCohortEnrollmentStatusQueryVariables
  >(FetchUserCohortEnrollmentStatusDocument, options);
}
export type FetchUserCohortEnrollmentStatusQueryHookResult = ReturnType<
  typeof useFetchUserCohortEnrollmentStatusQuery
>;
export type FetchUserCohortEnrollmentStatusLazyQueryHookResult = ReturnType<
  typeof useFetchUserCohortEnrollmentStatusLazyQuery
>;
export type FetchUserCohortEnrollmentStatusQueryResult = Apollo.QueryResult<
  FetchUserCohortEnrollmentStatusQuery,
  FetchUserCohortEnrollmentStatusQueryVariables
>;
export const GetUserEnrolledInCohortDocument = gql`
  query GetUserEnrolledInCohort($userId: uuid!, $cohortId: uuid!) {
    userEnrolled: enrollments(
      where: {userId: {_eq: $userId}, cohortId: {_eq: $cohortId}, deletedAt: {_isNull: true}}
    ) {
      userId
    }
  }
`;

/**
 * __useGetUserEnrolledInCohortQuery__
 *
 * To run a query within a React component, call `useGetUserEnrolledInCohortQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEnrolledInCohortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEnrolledInCohortQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      cohortId: // value for 'cohortId'
 *   },
 * });
 */
export function useGetUserEnrolledInCohortQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserEnrolledInCohortQuery,
    GetUserEnrolledInCohortQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetUserEnrolledInCohortQuery, GetUserEnrolledInCohortQueryVariables>(
    GetUserEnrolledInCohortDocument,
    options
  );
}
export function useGetUserEnrolledInCohortLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserEnrolledInCohortQuery,
    GetUserEnrolledInCohortQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetUserEnrolledInCohortQuery, GetUserEnrolledInCohortQueryVariables>(
    GetUserEnrolledInCohortDocument,
    options
  );
}
export type GetUserEnrolledInCohortQueryHookResult = ReturnType<
  typeof useGetUserEnrolledInCohortQuery
>;
export type GetUserEnrolledInCohortLazyQueryHookResult = ReturnType<
  typeof useGetUserEnrolledInCohortLazyQuery
>;
export type GetUserEnrolledInCohortQueryResult = Apollo.QueryResult<
  GetUserEnrolledInCohortQuery,
  GetUserEnrolledInCohortQueryVariables
>;
export const GetUserEnrolledBySlugDocument = gql`
  query GetUserEnrolledBySlug($cohortSlug: String!) {
    is: enrollmentsAggregate(
      where: {cohort: {slug: {_eq: $cohortSlug}}, deletedAt: {_isNull: true}}
    ) {
      user: aggregate {
        enrolled: count(distinct: true)
      }
    }
  }
`;

/**
 * __useGetUserEnrolledBySlugQuery__
 *
 * To run a query within a React component, call `useGetUserEnrolledBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEnrolledBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEnrolledBySlugQuery({
 *   variables: {
 *      cohortSlug: // value for 'cohortSlug'
 *   },
 * });
 */
export function useGetUserEnrolledBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserEnrolledBySlugQuery,
    GetUserEnrolledBySlugQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetUserEnrolledBySlugQuery, GetUserEnrolledBySlugQueryVariables>(
    GetUserEnrolledBySlugDocument,
    options
  );
}
export function useGetUserEnrolledBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserEnrolledBySlugQuery,
    GetUserEnrolledBySlugQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetUserEnrolledBySlugQuery, GetUserEnrolledBySlugQueryVariables>(
    GetUserEnrolledBySlugDocument,
    options
  );
}
export type GetUserEnrolledBySlugQueryHookResult = ReturnType<typeof useGetUserEnrolledBySlugQuery>;
export type GetUserEnrolledBySlugLazyQueryHookResult = ReturnType<
  typeof useGetUserEnrolledBySlugLazyQuery
>;
export type GetUserEnrolledBySlugQueryResult = Apollo.QueryResult<
  GetUserEnrolledBySlugQuery,
  GetUserEnrolledBySlugQueryVariables
>;
export const GetUserEnrollmentsDocument = gql`
  query GetUserEnrollments($user_id: uuid!, $simple: Boolean = true) {
    user: usersByPk(id: $user_id) {
      activeSubscription {
        name
      }
      enrollments {
        cohort {
          ...CoreEnrollmentCohortFragment @include(if: $simple)
          ...ComplexEnrollmentCohortFragment @skip(if: $simple)
        }
        cohortId
      }
      waitlists(where: {deletedAt: {_isNull: true}}) {
        cohortId
      }
    }
  }
  ${CoreEnrollmentCohortFragmentFragmentDoc}
  ${ComplexEnrollmentCohortFragmentFragmentDoc}
`;

/**
 * __useGetUserEnrollmentsQuery__
 *
 * To run a query within a React component, call `useGetUserEnrollmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEnrollmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEnrollmentsQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      simple: // value for 'simple'
 *   },
 * });
 */
export function useGetUserEnrollmentsQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserEnrollmentsQuery, GetUserEnrollmentsQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetUserEnrollmentsQuery, GetUserEnrollmentsQueryVariables>(
    GetUserEnrollmentsDocument,
    options
  );
}
export function useGetUserEnrollmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserEnrollmentsQuery,
    GetUserEnrollmentsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetUserEnrollmentsQuery, GetUserEnrollmentsQueryVariables>(
    GetUserEnrollmentsDocument,
    options
  );
}
export type GetUserEnrollmentsQueryHookResult = ReturnType<typeof useGetUserEnrollmentsQuery>;
export type GetUserEnrollmentsLazyQueryHookResult = ReturnType<
  typeof useGetUserEnrollmentsLazyQuery
>;
export type GetUserEnrollmentsQueryResult = Apollo.QueryResult<
  GetUserEnrollmentsQuery,
  GetUserEnrollmentsQueryVariables
>;
export const GetExternalIdentifiersByEmailDocument = gql`
  query GetExternalIdentifiersByEmail($email: String!) {
    fusionauthExternalIdentifiers(where: {user: {identity: {email: {_eq: $email}}}}) {
      id
      insertInstant
    }
  }
`;

/**
 * __useGetExternalIdentifiersByEmailQuery__
 *
 * To run a query within a React component, call `useGetExternalIdentifiersByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalIdentifiersByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalIdentifiersByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetExternalIdentifiersByEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetExternalIdentifiersByEmailQuery,
    GetExternalIdentifiersByEmailQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetExternalIdentifiersByEmailQuery,
    GetExternalIdentifiersByEmailQueryVariables
  >(GetExternalIdentifiersByEmailDocument, options);
}
export function useGetExternalIdentifiersByEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExternalIdentifiersByEmailQuery,
    GetExternalIdentifiersByEmailQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetExternalIdentifiersByEmailQuery,
    GetExternalIdentifiersByEmailQueryVariables
  >(GetExternalIdentifiersByEmailDocument, options);
}
export type GetExternalIdentifiersByEmailQueryHookResult = ReturnType<
  typeof useGetExternalIdentifiersByEmailQuery
>;
export type GetExternalIdentifiersByEmailLazyQueryHookResult = ReturnType<
  typeof useGetExternalIdentifiersByEmailLazyQuery
>;
export type GetExternalIdentifiersByEmailQueryResult = Apollo.QueryResult<
  GetExternalIdentifiersByEmailQuery,
  GetExternalIdentifiersByEmailQueryVariables
>;
export const GetExternalIdentifiersByIdDocument = gql`
  query GetExternalIdentifiersById($id: String) {
    fusionauthExternalIdentifiers(where: {id: {_eq: $id}}) {
      id
      insertInstant
      user {
        identity {
          email
        }
      }
    }
  }
`;

/**
 * __useGetExternalIdentifiersByIdQuery__
 *
 * To run a query within a React component, call `useGetExternalIdentifiersByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalIdentifiersByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalIdentifiersByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExternalIdentifiersByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetExternalIdentifiersByIdQuery,
    GetExternalIdentifiersByIdQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetExternalIdentifiersByIdQuery, GetExternalIdentifiersByIdQueryVariables>(
    GetExternalIdentifiersByIdDocument,
    options
  );
}
export function useGetExternalIdentifiersByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExternalIdentifiersByIdQuery,
    GetExternalIdentifiersByIdQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetExternalIdentifiersByIdQuery,
    GetExternalIdentifiersByIdQueryVariables
  >(GetExternalIdentifiersByIdDocument, options);
}
export type GetExternalIdentifiersByIdQueryHookResult = ReturnType<
  typeof useGetExternalIdentifiersByIdQuery
>;
export type GetExternalIdentifiersByIdLazyQueryHookResult = ReturnType<
  typeof useGetExternalIdentifiersByIdLazyQuery
>;
export type GetExternalIdentifiersByIdQueryResult = Apollo.QueryResult<
  GetExternalIdentifiersByIdQuery,
  GetExternalIdentifiersByIdQueryVariables
>;
export const GetUserInvitesForCohortDocument = gql`
  query GetUserInvitesForCohort($userId: uuid!, $cohortId: uuid!, $limit: Int = 1) {
    invites: classEventInvitations(
      where: {userId: {_eq: $userId}, classEvent: {cohort: {id: {_eq: $cohortId}}}}
      orderBy: {createdAt: ASC}
      limit: $limit
    ) {
      createdAt
    }
  }
`;

/**
 * __useGetUserInvitesForCohortQuery__
 *
 * To run a query within a React component, call `useGetUserInvitesForCohortQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInvitesForCohortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInvitesForCohortQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      cohortId: // value for 'cohortId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetUserInvitesForCohortQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserInvitesForCohortQuery,
    GetUserInvitesForCohortQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetUserInvitesForCohortQuery, GetUserInvitesForCohortQueryVariables>(
    GetUserInvitesForCohortDocument,
    options
  );
}
export function useGetUserInvitesForCohortLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserInvitesForCohortQuery,
    GetUserInvitesForCohortQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetUserInvitesForCohortQuery, GetUserInvitesForCohortQueryVariables>(
    GetUserInvitesForCohortDocument,
    options
  );
}
export type GetUserInvitesForCohortQueryHookResult = ReturnType<
  typeof useGetUserInvitesForCohortQuery
>;
export type GetUserInvitesForCohortLazyQueryHookResult = ReturnType<
  typeof useGetUserInvitesForCohortLazyQuery
>;
export type GetUserInvitesForCohortQueryResult = Apollo.QueryResult<
  GetUserInvitesForCohortQuery,
  GetUserInvitesForCohortQueryVariables
>;
export const GetCohortDetailsForLedgerDocument = gql`
  query GetCohortDetailsForLedger($cohortId: uuid!) {
    cohortsByPk(id: $cohortId) {
      classEvents {
        startTime
      }
      course {
        type
      }
      slug
    }
  }
`;

/**
 * __useGetCohortDetailsForLedgerQuery__
 *
 * To run a query within a React component, call `useGetCohortDetailsForLedgerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCohortDetailsForLedgerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCohortDetailsForLedgerQuery({
 *   variables: {
 *      cohortId: // value for 'cohortId'
 *   },
 * });
 */
export function useGetCohortDetailsForLedgerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCohortDetailsForLedgerQuery,
    GetCohortDetailsForLedgerQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetCohortDetailsForLedgerQuery, GetCohortDetailsForLedgerQueryVariables>(
    GetCohortDetailsForLedgerDocument,
    options
  );
}
export function useGetCohortDetailsForLedgerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCohortDetailsForLedgerQuery,
    GetCohortDetailsForLedgerQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetCohortDetailsForLedgerQuery,
    GetCohortDetailsForLedgerQueryVariables
  >(GetCohortDetailsForLedgerDocument, options);
}
export type GetCohortDetailsForLedgerQueryHookResult = ReturnType<
  typeof useGetCohortDetailsForLedgerQuery
>;
export type GetCohortDetailsForLedgerLazyQueryHookResult = ReturnType<
  typeof useGetCohortDetailsForLedgerLazyQuery
>;
export type GetCohortDetailsForLedgerQueryResult = Apollo.QueryResult<
  GetCohortDetailsForLedgerQuery,
  GetCohortDetailsForLedgerQueryVariables
>;
export const GetLedgerTailDocument = gql`
  query GetLedgerTail($organizationId: uuid!) {
    ledger(
      where: {
        isCurrentSubscription: {_eq: true}
        organizationId: {_eq: $organizationId}
        transactionDate: {_lte: "now()"}
      }
      orderBy: {creditLedgerId: DESC}
      limit: 1
    ) {
      id
      creditLedgerId
      availableCreditBalance
      creditBalance
      totalAllocatedCredits
      isCurrentSubscription
      subscriptionEndDate
      subscriptionStartDate
      subscriptionCreditsBought
      totalCreditsSpent
      creditModel
    }
  }
`;

/**
 * __useGetLedgerTailQuery__
 *
 * To run a query within a React component, call `useGetLedgerTailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLedgerTailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLedgerTailQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetLedgerTailQuery(
  baseOptions: Apollo.QueryHookOptions<GetLedgerTailQuery, GetLedgerTailQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetLedgerTailQuery, GetLedgerTailQueryVariables>(
    GetLedgerTailDocument,
    options
  );
}
export function useGetLedgerTailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLedgerTailQuery, GetLedgerTailQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetLedgerTailQuery, GetLedgerTailQueryVariables>(
    GetLedgerTailDocument,
    options
  );
}
export type GetLedgerTailQueryHookResult = ReturnType<typeof useGetLedgerTailQuery>;
export type GetLedgerTailLazyQueryHookResult = ReturnType<typeof useGetLedgerTailLazyQuery>;
export type GetLedgerTailQueryResult = Apollo.QueryResult<
  GetLedgerTailQuery,
  GetLedgerTailQueryVariables
>;
export const GetLightningSessionSubmissionByChannelIdDocument = gql`
  query GetLightningSessionSubmissionByChannelId($streamChannelId: String!) {
    lightningSessionSubmissions(
      where: {streamChannelId: {_eq: $streamChannelId}, deletedAt: {_isNull: true}}
    ) {
      id
      userId
      status
      streamChannelId
      cohortId
      coachUserId
      cohort {
        id
        slug
        course {
          id
          slug
          type
        }
      }
      streamMessages(where: {deletedAt: {_isNull: true}}) {
        id
        authorId
        createdAt
      }
    }
  }
`;

/**
 * __useGetLightningSessionSubmissionByChannelIdQuery__
 *
 * To run a query within a React component, call `useGetLightningSessionSubmissionByChannelIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLightningSessionSubmissionByChannelIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLightningSessionSubmissionByChannelIdQuery({
 *   variables: {
 *      streamChannelId: // value for 'streamChannelId'
 *   },
 * });
 */
export function useGetLightningSessionSubmissionByChannelIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLightningSessionSubmissionByChannelIdQuery,
    GetLightningSessionSubmissionByChannelIdQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetLightningSessionSubmissionByChannelIdQuery,
    GetLightningSessionSubmissionByChannelIdQueryVariables
  >(GetLightningSessionSubmissionByChannelIdDocument, options);
}
export function useGetLightningSessionSubmissionByChannelIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLightningSessionSubmissionByChannelIdQuery,
    GetLightningSessionSubmissionByChannelIdQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetLightningSessionSubmissionByChannelIdQuery,
    GetLightningSessionSubmissionByChannelIdQueryVariables
  >(GetLightningSessionSubmissionByChannelIdDocument, options);
}
export type GetLightningSessionSubmissionByChannelIdQueryHookResult = ReturnType<
  typeof useGetLightningSessionSubmissionByChannelIdQuery
>;
export type GetLightningSessionSubmissionByChannelIdLazyQueryHookResult = ReturnType<
  typeof useGetLightningSessionSubmissionByChannelIdLazyQuery
>;
export type GetLightningSessionSubmissionByChannelIdQueryResult = Apollo.QueryResult<
  GetLightningSessionSubmissionByChannelIdQuery,
  GetLightningSessionSubmissionByChannelIdQueryVariables
>;
export const GetLightningSessionSubmissionByCohortAndCoachesDocument = gql`
  query GetLightningSessionSubmissionByCohortAndCoaches(
    $cohortId: uuid!
    $coachesUserIds: [uuid!]
  ) {
    lightningSessionSubmissions(
      where: {
        cohortId: {_eq: $cohortId}
        deletedAt: {_isNull: true}
        coachUserId: {_in: $coachesUserIds}
      }
      orderBy: {createdAt: DESC}
      limit: 1
    ) {
      id
      userId
      status
      streamChannelId
      cohortId
      coachUserId
      createdAt
    }
  }
`;

/**
 * __useGetLightningSessionSubmissionByCohortAndCoachesQuery__
 *
 * To run a query within a React component, call `useGetLightningSessionSubmissionByCohortAndCoachesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLightningSessionSubmissionByCohortAndCoachesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLightningSessionSubmissionByCohortAndCoachesQuery({
 *   variables: {
 *      cohortId: // value for 'cohortId'
 *      coachesUserIds: // value for 'coachesUserIds'
 *   },
 * });
 */
export function useGetLightningSessionSubmissionByCohortAndCoachesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLightningSessionSubmissionByCohortAndCoachesQuery,
    GetLightningSessionSubmissionByCohortAndCoachesQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetLightningSessionSubmissionByCohortAndCoachesQuery,
    GetLightningSessionSubmissionByCohortAndCoachesQueryVariables
  >(GetLightningSessionSubmissionByCohortAndCoachesDocument, options);
}
export function useGetLightningSessionSubmissionByCohortAndCoachesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLightningSessionSubmissionByCohortAndCoachesQuery,
    GetLightningSessionSubmissionByCohortAndCoachesQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetLightningSessionSubmissionByCohortAndCoachesQuery,
    GetLightningSessionSubmissionByCohortAndCoachesQueryVariables
  >(GetLightningSessionSubmissionByCohortAndCoachesDocument, options);
}
export type GetLightningSessionSubmissionByCohortAndCoachesQueryHookResult = ReturnType<
  typeof useGetLightningSessionSubmissionByCohortAndCoachesQuery
>;
export type GetLightningSessionSubmissionByCohortAndCoachesLazyQueryHookResult = ReturnType<
  typeof useGetLightningSessionSubmissionByCohortAndCoachesLazyQuery
>;
export type GetLightningSessionSubmissionByCohortAndCoachesQueryResult = Apollo.QueryResult<
  GetLightningSessionSubmissionByCohortAndCoachesQuery,
  GetLightningSessionSubmissionByCohortAndCoachesQueryVariables
>;
export const GetLightningSubmissionDocument = gql`
  query GetLightningSubmission($id: uuid!) {
    lightningSessionSubmission: lightningSessionSubmissionsByPk(id: $id) {
      id
      userId
      status
      streamChannelId
      cohortId
      coachUserId
      interactionsCount
      cohort {
        communitySlackChannelUrl
        communitySlackInviteUrl
        courseId
        id
        name
        slug
        surveyLinkUrl
        type
        isPostCourseSurveyAvailable
        preworkRichText
        preworkVideoUrl
        requiresPractice
        startDate
        endDate
        classTimeOfDay
        classEvents {
          id
          classId
        }
        course {
          id
          type
          name
          slug
          preCourseSurveyFormId
          postCourseSurveyFormId
          impactAssessment {
            id
          }
          resources {
            resource {
              id
              filename
              contentType
              name
              url
              type
            }
          }
        }
        resources {
          resource {
            id
            filename
            contentType
            name
            url
            type
          }
        }
      }
      pitchSegment {
        startTimecode
        endTimecode
        createdAt
        classEventMeeting {
          recordingUrl
        }
        videoClipUrl
      }
      user {
        id
        firstName
        lastName
        imageUrl
      }
    }
  }
`;

/**
 * __useGetLightningSubmissionQuery__
 *
 * To run a query within a React component, call `useGetLightningSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLightningSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLightningSubmissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLightningSubmissionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLightningSubmissionQuery,
    GetLightningSubmissionQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetLightningSubmissionQuery, GetLightningSubmissionQueryVariables>(
    GetLightningSubmissionDocument,
    options
  );
}
export function useGetLightningSubmissionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLightningSubmissionQuery,
    GetLightningSubmissionQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetLightningSubmissionQuery, GetLightningSubmissionQueryVariables>(
    GetLightningSubmissionDocument,
    options
  );
}
export type GetLightningSubmissionQueryHookResult = ReturnType<
  typeof useGetLightningSubmissionQuery
>;
export type GetLightningSubmissionLazyQueryHookResult = ReturnType<
  typeof useGetLightningSubmissionLazyQuery
>;
export type GetLightningSubmissionQueryResult = Apollo.QueryResult<
  GetLightningSubmissionQuery,
  GetLightningSubmissionQueryVariables
>;
export const GetLightningSubmissionByCoachDocument = gql`
  query GetLightningSubmissionByCoach(
    $coachUserId: uuid!
    $status: lightningSessionSubmissionsStatusEnum
  ) {
    lightningSessionSubmissions(
      where: {coachUserId: {_eq: $coachUserId}, status: {_eq: $status}}
      orderBy: {cohort: {classEventsAggregate: {min: {endTime: ASC}}}}
    ) {
      id
      userId
      status
      streamChannelId
      cohortId
      coachUserId
      coach {
        id
        fullName
        firstName
        lastName
      }
      pitchSegment {
        startTimecode
        endTimecode
        classEventMeeting {
          recordingUrl
        }
        videoClipUrl
      }
      user {
        id
        firstName
        lastName
        imageUrl
      }
      cohort {
        id
        name
        classEvents {
          endTime
        }
        course {
          type
          name
        }
      }
    }
  }
`;

/**
 * __useGetLightningSubmissionByCoachQuery__
 *
 * To run a query within a React component, call `useGetLightningSubmissionByCoachQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLightningSubmissionByCoachQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLightningSubmissionByCoachQuery({
 *   variables: {
 *      coachUserId: // value for 'coachUserId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetLightningSubmissionByCoachQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLightningSubmissionByCoachQuery,
    GetLightningSubmissionByCoachQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetLightningSubmissionByCoachQuery,
    GetLightningSubmissionByCoachQueryVariables
  >(GetLightningSubmissionByCoachDocument, options);
}
export function useGetLightningSubmissionByCoachLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLightningSubmissionByCoachQuery,
    GetLightningSubmissionByCoachQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetLightningSubmissionByCoachQuery,
    GetLightningSubmissionByCoachQueryVariables
  >(GetLightningSubmissionByCoachDocument, options);
}
export type GetLightningSubmissionByCoachQueryHookResult = ReturnType<
  typeof useGetLightningSubmissionByCoachQuery
>;
export type GetLightningSubmissionByCoachLazyQueryHookResult = ReturnType<
  typeof useGetLightningSubmissionByCoachLazyQuery
>;
export type GetLightningSubmissionByCoachQueryResult = Apollo.QueryResult<
  GetLightningSubmissionByCoachQuery,
  GetLightningSubmissionByCoachQueryVariables
>;
export const GetLightningSubmissionByUserAndCohortDocument = gql`
  query GetLightningSubmissionByUserAndCohort($userId: uuid!, $cohortId: uuid!) {
    lightningSessionSubmissions(
      limit: 1
      where: {userId: {_eq: $userId}, cohortId: {_eq: $cohortId}, deletedAt: {_isNull: true}}
    ) {
      id
      userId
      status
      streamChannelId
      cohortId
      score
      comment
      cohort {
        id
        course {
          id
          preCourseSurveyFormId
          postCourseSurveyFormId
        }
        classEvents {
          id
        }
      }
      coachUserId
      coach {
        id
        fullName
        firstName
        lastName
      }
      pitchSegment {
        startTimecode
        endTimecode
        classEventMeeting {
          recordingUrl
        }
        videoClipUrl
      }
      user {
        id
        firstName
        lastName
        imageUrl
      }
      relatedUsers {
        userId
      }
    }
  }
`;

/**
 * __useGetLightningSubmissionByUserAndCohortQuery__
 *
 * To run a query within a React component, call `useGetLightningSubmissionByUserAndCohortQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLightningSubmissionByUserAndCohortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLightningSubmissionByUserAndCohortQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      cohortId: // value for 'cohortId'
 *   },
 * });
 */
export function useGetLightningSubmissionByUserAndCohortQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLightningSubmissionByUserAndCohortQuery,
    GetLightningSubmissionByUserAndCohortQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetLightningSubmissionByUserAndCohortQuery,
    GetLightningSubmissionByUserAndCohortQueryVariables
  >(GetLightningSubmissionByUserAndCohortDocument, options);
}
export function useGetLightningSubmissionByUserAndCohortLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLightningSubmissionByUserAndCohortQuery,
    GetLightningSubmissionByUserAndCohortQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetLightningSubmissionByUserAndCohortQuery,
    GetLightningSubmissionByUserAndCohortQueryVariables
  >(GetLightningSubmissionByUserAndCohortDocument, options);
}
export type GetLightningSubmissionByUserAndCohortQueryHookResult = ReturnType<
  typeof useGetLightningSubmissionByUserAndCohortQuery
>;
export type GetLightningSubmissionByUserAndCohortLazyQueryHookResult = ReturnType<
  typeof useGetLightningSubmissionByUserAndCohortLazyQuery
>;
export type GetLightningSubmissionByUserAndCohortQueryResult = Apollo.QueryResult<
  GetLightningSubmissionByUserAndCohortQuery,
  GetLightningSubmissionByUserAndCohortQueryVariables
>;
export const GetLightningSubmissionByUserAndCohortAndDeleteStatusDocument = gql`
  query GetLightningSubmissionByUserAndCohortAndDeleteStatus(
    $userId: uuid!
    $cohortId: uuid!
    $deletedAt: TimestampComparisonExp
  ) {
    lightningSessionSubmissions(
      limit: 1
      where: {userId: {_eq: $userId}, cohortId: {_eq: $cohortId}, deletedAt: $deletedAt}
    ) {
      id
      userId
      streamChannelId
      cohortId
    }
  }
`;

/**
 * __useGetLightningSubmissionByUserAndCohortAndDeleteStatusQuery__
 *
 * To run a query within a React component, call `useGetLightningSubmissionByUserAndCohortAndDeleteStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLightningSubmissionByUserAndCohortAndDeleteStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLightningSubmissionByUserAndCohortAndDeleteStatusQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      cohortId: // value for 'cohortId'
 *      deletedAt: // value for 'deletedAt'
 *   },
 * });
 */
export function useGetLightningSubmissionByUserAndCohortAndDeleteStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLightningSubmissionByUserAndCohortAndDeleteStatusQuery,
    GetLightningSubmissionByUserAndCohortAndDeleteStatusQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetLightningSubmissionByUserAndCohortAndDeleteStatusQuery,
    GetLightningSubmissionByUserAndCohortAndDeleteStatusQueryVariables
  >(GetLightningSubmissionByUserAndCohortAndDeleteStatusDocument, options);
}
export function useGetLightningSubmissionByUserAndCohortAndDeleteStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLightningSubmissionByUserAndCohortAndDeleteStatusQuery,
    GetLightningSubmissionByUserAndCohortAndDeleteStatusQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetLightningSubmissionByUserAndCohortAndDeleteStatusQuery,
    GetLightningSubmissionByUserAndCohortAndDeleteStatusQueryVariables
  >(GetLightningSubmissionByUserAndCohortAndDeleteStatusDocument, options);
}
export type GetLightningSubmissionByUserAndCohortAndDeleteStatusQueryHookResult = ReturnType<
  typeof useGetLightningSubmissionByUserAndCohortAndDeleteStatusQuery
>;
export type GetLightningSubmissionByUserAndCohortAndDeleteStatusLazyQueryHookResult = ReturnType<
  typeof useGetLightningSubmissionByUserAndCohortAndDeleteStatusLazyQuery
>;
export type GetLightningSubmissionByUserAndCohortAndDeleteStatusQueryResult = Apollo.QueryResult<
  GetLightningSubmissionByUserAndCohortAndDeleteStatusQuery,
  GetLightningSubmissionByUserAndCohortAndDeleteStatusQueryVariables
>;
export const GetLightningSubmissionEmailFlagsDocument = gql`
  query GetLightningSubmissionEmailFlags($id: uuid!) {
    lightningSessionSubmissionsByPk(id: $id) {
      coachEmailSent
      learnerEmailSent
    }
  }
`;

/**
 * __useGetLightningSubmissionEmailFlagsQuery__
 *
 * To run a query within a React component, call `useGetLightningSubmissionEmailFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLightningSubmissionEmailFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLightningSubmissionEmailFlagsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLightningSubmissionEmailFlagsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLightningSubmissionEmailFlagsQuery,
    GetLightningSubmissionEmailFlagsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetLightningSubmissionEmailFlagsQuery,
    GetLightningSubmissionEmailFlagsQueryVariables
  >(GetLightningSubmissionEmailFlagsDocument, options);
}
export function useGetLightningSubmissionEmailFlagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLightningSubmissionEmailFlagsQuery,
    GetLightningSubmissionEmailFlagsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetLightningSubmissionEmailFlagsQuery,
    GetLightningSubmissionEmailFlagsQueryVariables
  >(GetLightningSubmissionEmailFlagsDocument, options);
}
export type GetLightningSubmissionEmailFlagsQueryHookResult = ReturnType<
  typeof useGetLightningSubmissionEmailFlagsQuery
>;
export type GetLightningSubmissionEmailFlagsLazyQueryHookResult = ReturnType<
  typeof useGetLightningSubmissionEmailFlagsLazyQuery
>;
export type GetLightningSubmissionEmailFlagsQueryResult = Apollo.QueryResult<
  GetLightningSubmissionEmailFlagsQuery,
  GetLightningSubmissionEmailFlagsQueryVariables
>;
export const GetMissingRecordingForSubmissionDocument = gql`
  query GetMissingRecordingForSubmission($lightningSubmissionId: uuid!, $userId: uuid!) {
    recordings(
      where: {
        lightningSubmissionId: {_eq: $lightningSubmissionId}
        sentToChannel: {_eq: false}
        createdBy: {id: {_eq: $userId}}
      }
      orderBy: {createdAt: ASC}
    ) {
      id
      hash
      sourceUrl
      publicUrl
      lightningSubmissionId
      sentToChannel
      createdAt
      createdBy {
        firstName
        lastName
        imageUrl
      }
    }
  }
`;

/**
 * __useGetMissingRecordingForSubmissionQuery__
 *
 * To run a query within a React component, call `useGetMissingRecordingForSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMissingRecordingForSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMissingRecordingForSubmissionQuery({
 *   variables: {
 *      lightningSubmissionId: // value for 'lightningSubmissionId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetMissingRecordingForSubmissionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMissingRecordingForSubmissionQuery,
    GetMissingRecordingForSubmissionQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetMissingRecordingForSubmissionQuery,
    GetMissingRecordingForSubmissionQueryVariables
  >(GetMissingRecordingForSubmissionDocument, options);
}
export function useGetMissingRecordingForSubmissionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMissingRecordingForSubmissionQuery,
    GetMissingRecordingForSubmissionQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetMissingRecordingForSubmissionQuery,
    GetMissingRecordingForSubmissionQueryVariables
  >(GetMissingRecordingForSubmissionDocument, options);
}
export type GetMissingRecordingForSubmissionQueryHookResult = ReturnType<
  typeof useGetMissingRecordingForSubmissionQuery
>;
export type GetMissingRecordingForSubmissionLazyQueryHookResult = ReturnType<
  typeof useGetMissingRecordingForSubmissionLazyQuery
>;
export type GetMissingRecordingForSubmissionQueryResult = Apollo.QueryResult<
  GetMissingRecordingForSubmissionQuery,
  GetMissingRecordingForSubmissionQueryVariables
>;
export const GetPastSessionAsyncAverageMetricsActionDocument = gql`
  query GetPastSessionAsyncAverageMetricsAction {
    GetPastSessionsAsyncAverageMetrics {
      asyncMetrics {
        averageAttendanceRateMetric {
          ...AsyncAverageMetric
        }
        averageHighConfidenceMetric {
          ...AsyncAverageMetric
        }
        averageHighEngagementMetric {
          ...AsyncAverageMetric
        }
        averageHighJobImpactMetric {
          ...AsyncAverageMetric
        }
      }
      pastSessionsWithMetrics {
        cohortId
        metrics {
          attendanceRateMetric {
            ...AsyncMetric
          }
          highConfidenceMetric {
            ...AsyncMetric
          }
          highEngagementMetric {
            ...AsyncMetric
          }
          highJobImpactMetric {
            ...AsyncMetric
          }
        }
      }
    }
  }
  ${AsyncAverageMetricFragmentDoc}
  ${AsyncMetricFragmentDoc}
`;

/**
 * __useGetPastSessionAsyncAverageMetricsActionQuery__
 *
 * To run a query within a React component, call `useGetPastSessionAsyncAverageMetricsActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPastSessionAsyncAverageMetricsActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPastSessionAsyncAverageMetricsActionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPastSessionAsyncAverageMetricsActionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPastSessionAsyncAverageMetricsActionQuery,
    GetPastSessionAsyncAverageMetricsActionQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetPastSessionAsyncAverageMetricsActionQuery,
    GetPastSessionAsyncAverageMetricsActionQueryVariables
  >(GetPastSessionAsyncAverageMetricsActionDocument, options);
}
export function useGetPastSessionAsyncAverageMetricsActionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPastSessionAsyncAverageMetricsActionQuery,
    GetPastSessionAsyncAverageMetricsActionQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetPastSessionAsyncAverageMetricsActionQuery,
    GetPastSessionAsyncAverageMetricsActionQueryVariables
  >(GetPastSessionAsyncAverageMetricsActionDocument, options);
}
export type GetPastSessionAsyncAverageMetricsActionQueryHookResult = ReturnType<
  typeof useGetPastSessionAsyncAverageMetricsActionQuery
>;
export type GetPastSessionAsyncAverageMetricsActionLazyQueryHookResult = ReturnType<
  typeof useGetPastSessionAsyncAverageMetricsActionLazyQuery
>;
export type GetPastSessionAsyncAverageMetricsActionQueryResult = Apollo.QueryResult<
  GetPastSessionAsyncAverageMetricsActionQuery,
  GetPastSessionAsyncAverageMetricsActionQueryVariables
>;
export const GetPastSessionAsyncMetricsActionDocument = gql`
  query GetPastSessionAsyncMetricsAction($cohortId: uuid!) {
    GetPastSessionAsyncMetrics(cohortId: $cohortId) {
      sessionMembersWithNPS {
        id
        pollSubmission {
          confidenceScore
          engagementScore
          jobImpactScore
          npsScore
          takeawayQuote
          whichDeals
          numDealsWhereApplied
        }
      }
      asyncMetrics {
        attendanceRateMetric {
          ...AsyncMetric
        }
        highCoachFeedbackMetric {
          ...AsyncMetric
        }
        highConfidenceMetric {
          ...AsyncMetric
        }
        highEngagementMetric {
          ...AsyncMetric
        }
        highJobImpactMetric {
          ...AsyncMetric
        }
      }
    }
  }
  ${AsyncMetricFragmentDoc}
`;

/**
 * __useGetPastSessionAsyncMetricsActionQuery__
 *
 * To run a query within a React component, call `useGetPastSessionAsyncMetricsActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPastSessionAsyncMetricsActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPastSessionAsyncMetricsActionQuery({
 *   variables: {
 *      cohortId: // value for 'cohortId'
 *   },
 * });
 */
export function useGetPastSessionAsyncMetricsActionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPastSessionAsyncMetricsActionQuery,
    GetPastSessionAsyncMetricsActionQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetPastSessionAsyncMetricsActionQuery,
    GetPastSessionAsyncMetricsActionQueryVariables
  >(GetPastSessionAsyncMetricsActionDocument, options);
}
export function useGetPastSessionAsyncMetricsActionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPastSessionAsyncMetricsActionQuery,
    GetPastSessionAsyncMetricsActionQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetPastSessionAsyncMetricsActionQuery,
    GetPastSessionAsyncMetricsActionQueryVariables
  >(GetPastSessionAsyncMetricsActionDocument, options);
}
export type GetPastSessionAsyncMetricsActionQueryHookResult = ReturnType<
  typeof useGetPastSessionAsyncMetricsActionQuery
>;
export type GetPastSessionAsyncMetricsActionLazyQueryHookResult = ReturnType<
  typeof useGetPastSessionAsyncMetricsActionLazyQuery
>;
export type GetPastSessionAsyncMetricsActionQueryResult = Apollo.QueryResult<
  GetPastSessionAsyncMetricsActionQuery,
  GetPastSessionAsyncMetricsActionQueryVariables
>;
export const FindOrganizationDocument = gql`
  query FindOrganization($name: String!) {
    organizations(where: {name: {_eq: $name}}) {
      id
    }
  }
`;

/**
 * __useFindOrganizationQuery__
 *
 * To run a query within a React component, call `useFindOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOrganizationQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useFindOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<FindOrganizationQuery, FindOrganizationQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<FindOrganizationQuery, FindOrganizationQueryVariables>(
    FindOrganizationDocument,
    options
  );
}
export function useFindOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindOrganizationQuery, FindOrganizationQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<FindOrganizationQuery, FindOrganizationQueryVariables>(
    FindOrganizationDocument,
    options
  );
}
export type FindOrganizationQueryHookResult = ReturnType<typeof useFindOrganizationQuery>;
export type FindOrganizationLazyQueryHookResult = ReturnType<typeof useFindOrganizationLazyQuery>;
export type FindOrganizationQueryResult = Apollo.QueryResult<
  FindOrganizationQuery,
  FindOrganizationQueryVariables
>;
export const GetAllOrgUsersDocument = gql`
  query GetAllOrgUsers($orgId: uuid!) {
    organizationUsers(where: {organization: {id: {_eq: $orgId}}}) {
      user {
        id
        firstName
        lastName
        identity {
          email
        }
        active
      }
    }
  }
`;

/**
 * __useGetAllOrgUsersQuery__
 *
 * To run a query within a React component, call `useGetAllOrgUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOrgUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOrgUsersQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useGetAllOrgUsersQuery(
  baseOptions: Apollo.QueryHookOptions<GetAllOrgUsersQuery, GetAllOrgUsersQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetAllOrgUsersQuery, GetAllOrgUsersQueryVariables>(
    GetAllOrgUsersDocument,
    options
  );
}
export function useGetAllOrgUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllOrgUsersQuery, GetAllOrgUsersQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetAllOrgUsersQuery, GetAllOrgUsersQueryVariables>(
    GetAllOrgUsersDocument,
    options
  );
}
export type GetAllOrgUsersQueryHookResult = ReturnType<typeof useGetAllOrgUsersQuery>;
export type GetAllOrgUsersLazyQueryHookResult = ReturnType<typeof useGetAllOrgUsersLazyQuery>;
export type GetAllOrgUsersQueryResult = Apollo.QueryResult<
  GetAllOrgUsersQuery,
  GetAllOrgUsersQueryVariables
>;
export const GetOrganizationByPkDocument = gql`
  query GetOrganizationByPk($id: uuid!) {
    organizationsByPk(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useGetOrganizationByPkQuery__
 *
 * To run a query within a React component, call `useGetOrganizationByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationByPkQuery(
  baseOptions: Apollo.QueryHookOptions<GetOrganizationByPkQuery, GetOrganizationByPkQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetOrganizationByPkQuery, GetOrganizationByPkQueryVariables>(
    GetOrganizationByPkDocument,
    options
  );
}
export function useGetOrganizationByPkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationByPkQuery,
    GetOrganizationByPkQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetOrganizationByPkQuery, GetOrganizationByPkQueryVariables>(
    GetOrganizationByPkDocument,
    options
  );
}
export type GetOrganizationByPkQueryHookResult = ReturnType<typeof useGetOrganizationByPkQuery>;
export type GetOrganizationByPkLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationByPkLazyQuery
>;
export type GetOrganizationByPkQueryResult = Apollo.QueryResult<
  GetOrganizationByPkQuery,
  GetOrganizationByPkQueryVariables
>;
export const GetOrganizationPoliciesDocument = gql`
  query GetOrganizationPolicies($orgId: uuid!) {
    organizations(where: {id: {_eq: $orgId}}) {
      id
      name
      planSubscriptions(where: {deletedAt: {_isNull: true}}) {
        plan {
          policy
        }
      }
    }
  }
`;

/**
 * __useGetOrganizationPoliciesQuery__
 *
 * To run a query within a React component, call `useGetOrganizationPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationPoliciesQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useGetOrganizationPoliciesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrganizationPoliciesQuery,
    GetOrganizationPoliciesQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetOrganizationPoliciesQuery, GetOrganizationPoliciesQueryVariables>(
    GetOrganizationPoliciesDocument,
    options
  );
}
export function useGetOrganizationPoliciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationPoliciesQuery,
    GetOrganizationPoliciesQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetOrganizationPoliciesQuery, GetOrganizationPoliciesQueryVariables>(
    GetOrganizationPoliciesDocument,
    options
  );
}
export type GetOrganizationPoliciesQueryHookResult = ReturnType<
  typeof useGetOrganizationPoliciesQuery
>;
export type GetOrganizationPoliciesLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationPoliciesLazyQuery
>;
export type GetOrganizationPoliciesQueryResult = Apollo.QueryResult<
  GetOrganizationPoliciesQuery,
  GetOrganizationPoliciesQueryVariables
>;
export const GetPlanBySlugDocument = gql`
  query GetPlanBySlug($slug: String!) {
    plans(where: {slug: {_eq: $slug}}) {
      id
    }
  }
`;

/**
 * __useGetPlanBySlugQuery__
 *
 * To run a query within a React component, call `useGetPlanBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetPlanBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<GetPlanBySlugQuery, GetPlanBySlugQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetPlanBySlugQuery, GetPlanBySlugQueryVariables>(
    GetPlanBySlugDocument,
    options
  );
}
export function useGetPlanBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPlanBySlugQuery, GetPlanBySlugQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetPlanBySlugQuery, GetPlanBySlugQueryVariables>(
    GetPlanBySlugDocument,
    options
  );
}
export type GetPlanBySlugQueryHookResult = ReturnType<typeof useGetPlanBySlugQuery>;
export type GetPlanBySlugLazyQueryHookResult = ReturnType<typeof useGetPlanBySlugLazyQuery>;
export type GetPlanBySlugQueryResult = Apollo.QueryResult<
  GetPlanBySlugQuery,
  GetPlanBySlugQueryVariables
>;
export const GetAllPlayExecutionDocument = gql`
  query GetAllPlayExecution {
    playExecution(orderBy: {createdAt: DESC}) {
      id
      success
      data
      users
      message
      playName
      createdAt
      params
    }
  }
`;

/**
 * __useGetAllPlayExecutionQuery__
 *
 * To run a query within a React component, call `useGetAllPlayExecutionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPlayExecutionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPlayExecutionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPlayExecutionQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllPlayExecutionQuery, GetAllPlayExecutionQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetAllPlayExecutionQuery, GetAllPlayExecutionQueryVariables>(
    GetAllPlayExecutionDocument,
    options
  );
}
export function useGetAllPlayExecutionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllPlayExecutionQuery,
    GetAllPlayExecutionQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetAllPlayExecutionQuery, GetAllPlayExecutionQueryVariables>(
    GetAllPlayExecutionDocument,
    options
  );
}
export type GetAllPlayExecutionQueryHookResult = ReturnType<typeof useGetAllPlayExecutionQuery>;
export type GetAllPlayExecutionLazyQueryHookResult = ReturnType<
  typeof useGetAllPlayExecutionLazyQuery
>;
export type GetAllPlayExecutionQueryResult = Apollo.QueryResult<
  GetAllPlayExecutionQuery,
  GetAllPlayExecutionQueryVariables
>;
export const PlayGetClassesForCourseDocument = gql`
  query PlayGetClassesForCourse($courseId: uuid!) {
    classes(where: {courseId: {_eq: $courseId}}) {
      id
      name
      version
      slug
    }
  }
`;

/**
 * __usePlayGetClassesForCourseQuery__
 *
 * To run a query within a React component, call `usePlayGetClassesForCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayGetClassesForCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayGetClassesForCourseQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function usePlayGetClassesForCourseQuery(
  baseOptions: Apollo.QueryHookOptions<
    PlayGetClassesForCourseQuery,
    PlayGetClassesForCourseQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<PlayGetClassesForCourseQuery, PlayGetClassesForCourseQueryVariables>(
    PlayGetClassesForCourseDocument,
    options
  );
}
export function usePlayGetClassesForCourseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlayGetClassesForCourseQuery,
    PlayGetClassesForCourseQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<PlayGetClassesForCourseQuery, PlayGetClassesForCourseQueryVariables>(
    PlayGetClassesForCourseDocument,
    options
  );
}
export type PlayGetClassesForCourseQueryHookResult = ReturnType<
  typeof usePlayGetClassesForCourseQuery
>;
export type PlayGetClassesForCourseLazyQueryHookResult = ReturnType<
  typeof usePlayGetClassesForCourseLazyQuery
>;
export type PlayGetClassesForCourseQueryResult = Apollo.QueryResult<
  PlayGetClassesForCourseQuery,
  PlayGetClassesForCourseQueryVariables
>;
export const PlayGetLightningSessionSubmissionByUserAndCohortIdsDocument = gql`
  query PlayGetLightningSessionSubmissionByUserAndCohortIds($userId: uuid!, $cohortId: uuid!) {
    lightningSessionSubmissions(
      where: {userId: {_eq: $userId}, cohortId: {_eq: $cohortId}, deletedAt: {_isNull: true}}
    ) {
      id
      userId
      status
      streamChannelId
      cohortId
      coachUserId
    }
  }
`;

/**
 * __usePlayGetLightningSessionSubmissionByUserAndCohortIdsQuery__
 *
 * To run a query within a React component, call `usePlayGetLightningSessionSubmissionByUserAndCohortIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayGetLightningSessionSubmissionByUserAndCohortIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayGetLightningSessionSubmissionByUserAndCohortIdsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      cohortId: // value for 'cohortId'
 *   },
 * });
 */
export function usePlayGetLightningSessionSubmissionByUserAndCohortIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PlayGetLightningSessionSubmissionByUserAndCohortIdsQuery,
    PlayGetLightningSessionSubmissionByUserAndCohortIdsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    PlayGetLightningSessionSubmissionByUserAndCohortIdsQuery,
    PlayGetLightningSessionSubmissionByUserAndCohortIdsQueryVariables
  >(PlayGetLightningSessionSubmissionByUserAndCohortIdsDocument, options);
}
export function usePlayGetLightningSessionSubmissionByUserAndCohortIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlayGetLightningSessionSubmissionByUserAndCohortIdsQuery,
    PlayGetLightningSessionSubmissionByUserAndCohortIdsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    PlayGetLightningSessionSubmissionByUserAndCohortIdsQuery,
    PlayGetLightningSessionSubmissionByUserAndCohortIdsQueryVariables
  >(PlayGetLightningSessionSubmissionByUserAndCohortIdsDocument, options);
}
export type PlayGetLightningSessionSubmissionByUserAndCohortIdsQueryHookResult = ReturnType<
  typeof usePlayGetLightningSessionSubmissionByUserAndCohortIdsQuery
>;
export type PlayGetLightningSessionSubmissionByUserAndCohortIdsLazyQueryHookResult = ReturnType<
  typeof usePlayGetLightningSessionSubmissionByUserAndCohortIdsLazyQuery
>;
export type PlayGetLightningSessionSubmissionByUserAndCohortIdsQueryResult = Apollo.QueryResult<
  PlayGetLightningSessionSubmissionByUserAndCohortIdsQuery,
  PlayGetLightningSessionSubmissionByUserAndCohortIdsQueryVariables
>;
export const PlayGetPublicCatalogDocument = gql`
  query PlayGetPublicCatalog {
    catalogs(where: {slug: {_eq: "public"}}) {
      id
      name
      slug
    }
  }
`;

/**
 * __usePlayGetPublicCatalogQuery__
 *
 * To run a query within a React component, call `usePlayGetPublicCatalogQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayGetPublicCatalogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayGetPublicCatalogQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlayGetPublicCatalogQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PlayGetPublicCatalogQuery,
    PlayGetPublicCatalogQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<PlayGetPublicCatalogQuery, PlayGetPublicCatalogQueryVariables>(
    PlayGetPublicCatalogDocument,
    options
  );
}
export function usePlayGetPublicCatalogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlayGetPublicCatalogQuery,
    PlayGetPublicCatalogQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<PlayGetPublicCatalogQuery, PlayGetPublicCatalogQueryVariables>(
    PlayGetPublicCatalogDocument,
    options
  );
}
export type PlayGetPublicCatalogQueryHookResult = ReturnType<typeof usePlayGetPublicCatalogQuery>;
export type PlayGetPublicCatalogLazyQueryHookResult = ReturnType<
  typeof usePlayGetPublicCatalogLazyQuery
>;
export type PlayGetPublicCatalogQueryResult = Apollo.QueryResult<
  PlayGetPublicCatalogQuery,
  PlayGetPublicCatalogQueryVariables
>;
export const PlayGetSchoolDocument = gql`
  query PlayGetSchool($slug: String!) {
    schools(where: {slug: {_eq: $slug}}) {
      id
      name
      slug
    }
  }
`;

/**
 * __usePlayGetSchoolQuery__
 *
 * To run a query within a React component, call `usePlayGetSchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayGetSchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayGetSchoolQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePlayGetSchoolQuery(
  baseOptions: Apollo.QueryHookOptions<PlayGetSchoolQuery, PlayGetSchoolQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<PlayGetSchoolQuery, PlayGetSchoolQueryVariables>(
    PlayGetSchoolDocument,
    options
  );
}
export function usePlayGetSchoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PlayGetSchoolQuery, PlayGetSchoolQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<PlayGetSchoolQuery, PlayGetSchoolQueryVariables>(
    PlayGetSchoolDocument,
    options
  );
}
export type PlayGetSchoolQueryHookResult = ReturnType<typeof usePlayGetSchoolQuery>;
export type PlayGetSchoolLazyQueryHookResult = ReturnType<typeof usePlayGetSchoolLazyQuery>;
export type PlayGetSchoolQueryResult = Apollo.QueryResult<
  PlayGetSchoolQuery,
  PlayGetSchoolQueryVariables
>;
export const PlayGetTagDocument = gql`
  query PlayGetTag($slug: String!) {
    tags(where: {slug: {_eq: $slug}}) {
      id
    }
  }
`;

/**
 * __usePlayGetTagQuery__
 *
 * To run a query within a React component, call `usePlayGetTagQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayGetTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayGetTagQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePlayGetTagQuery(
  baseOptions: Apollo.QueryHookOptions<PlayGetTagQuery, PlayGetTagQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<PlayGetTagQuery, PlayGetTagQueryVariables>(PlayGetTagDocument, options);
}
export function usePlayGetTagLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PlayGetTagQuery, PlayGetTagQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<PlayGetTagQuery, PlayGetTagQueryVariables>(
    PlayGetTagDocument,
    options
  );
}
export type PlayGetTagQueryHookResult = ReturnType<typeof usePlayGetTagQuery>;
export type PlayGetTagLazyQueryHookResult = ReturnType<typeof usePlayGetTagLazyQuery>;
export type PlayGetTagQueryResult = Apollo.QueryResult<PlayGetTagQuery, PlayGetTagQueryVariables>;
export const PlayGetUnlimitedPlanDocument = gql`
  query PlayGetUnlimitedPlan($slug: String) {
    plans(where: {slug: {_eq: $slug}}) {
      id
      name
    }
  }
`;

/**
 * __usePlayGetUnlimitedPlanQuery__
 *
 * To run a query within a React component, call `usePlayGetUnlimitedPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayGetUnlimitedPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayGetUnlimitedPlanQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePlayGetUnlimitedPlanQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PlayGetUnlimitedPlanQuery,
    PlayGetUnlimitedPlanQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<PlayGetUnlimitedPlanQuery, PlayGetUnlimitedPlanQueryVariables>(
    PlayGetUnlimitedPlanDocument,
    options
  );
}
export function usePlayGetUnlimitedPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlayGetUnlimitedPlanQuery,
    PlayGetUnlimitedPlanQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<PlayGetUnlimitedPlanQuery, PlayGetUnlimitedPlanQueryVariables>(
    PlayGetUnlimitedPlanDocument,
    options
  );
}
export type PlayGetUnlimitedPlanQueryHookResult = ReturnType<typeof usePlayGetUnlimitedPlanQuery>;
export type PlayGetUnlimitedPlanLazyQueryHookResult = ReturnType<
  typeof usePlayGetUnlimitedPlanLazyQuery
>;
export type PlayGetUnlimitedPlanQueryResult = Apollo.QueryResult<
  PlayGetUnlimitedPlanQuery,
  PlayGetUnlimitedPlanQueryVariables
>;
export const GetRecordingByHashDocument = gql`
  query GetRecordingByHash($hash: String!) {
    recordings(where: {hash: {_eq: $hash}}) {
      id
      hash
      title
      description
      type
      sourceUrl
      publicUrl
      synced
      public
    }
  }
`;

/**
 * __useGetRecordingByHashQuery__
 *
 * To run a query within a React component, call `useGetRecordingByHashQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecordingByHashQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecordingByHashQuery({
 *   variables: {
 *      hash: // value for 'hash'
 *   },
 * });
 */
export function useGetRecordingByHashQuery(
  baseOptions: Apollo.QueryHookOptions<GetRecordingByHashQuery, GetRecordingByHashQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetRecordingByHashQuery, GetRecordingByHashQueryVariables>(
    GetRecordingByHashDocument,
    options
  );
}
export function useGetRecordingByHashLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecordingByHashQuery,
    GetRecordingByHashQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetRecordingByHashQuery, GetRecordingByHashQueryVariables>(
    GetRecordingByHashDocument,
    options
  );
}
export type GetRecordingByHashQueryHookResult = ReturnType<typeof useGetRecordingByHashQuery>;
export type GetRecordingByHashLazyQueryHookResult = ReturnType<
  typeof useGetRecordingByHashLazyQuery
>;
export type GetRecordingByHashQueryResult = Apollo.QueryResult<
  GetRecordingByHashQuery,
  GetRecordingByHashQueryVariables
>;
export const GetRecordingsToSyncDocument = gql`
  query GetRecordingsToSync {
    recordings(where: {synced: {_neq: true}}) {
      id
      hash
      type
      sourceUrl
      publicUrl
      meta
      transcription
      summary
      synced
    }
  }
`;

/**
 * __useGetRecordingsToSyncQuery__
 *
 * To run a query within a React component, call `useGetRecordingsToSyncQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecordingsToSyncQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecordingsToSyncQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecordingsToSyncQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRecordingsToSyncQuery, GetRecordingsToSyncQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetRecordingsToSyncQuery, GetRecordingsToSyncQueryVariables>(
    GetRecordingsToSyncDocument,
    options
  );
}
export function useGetRecordingsToSyncLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecordingsToSyncQuery,
    GetRecordingsToSyncQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetRecordingsToSyncQuery, GetRecordingsToSyncQueryVariables>(
    GetRecordingsToSyncDocument,
    options
  );
}
export type GetRecordingsToSyncQueryHookResult = ReturnType<typeof useGetRecordingsToSyncQuery>;
export type GetRecordingsToSyncLazyQueryHookResult = ReturnType<
  typeof useGetRecordingsToSyncLazyQuery
>;
export type GetRecordingsToSyncQueryResult = Apollo.QueryResult<
  GetRecordingsToSyncQuery,
  GetRecordingsToSyncQueryVariables
>;
export const GetEnrollmentsExportDocument = gql`
  query GetEnrollmentsExport($userIds: [uuid!]!) {
    lightningSessionSubmissions(where: {deletedAt: {_isNull: true}, userId: {_in: $userIds}}) {
      userId
      cohortId
      status
    }
    cohorts(where: {enrollments: {deletedAt: {_isNull: true}, userId: {_in: $userIds}}}) {
      id
      course {
        name
      }
      startDate
      type
      course {
        type
      }
      userScores: impactAssessmentAttempts(where: {userId: {_in: $userIds}}) {
        userId
        percentCorrect
      }
      classEvents {
        startTime
      }
      ...ClassEventsWithFeedbacKViewedActivities
      ...ClassEventsWithCompleteActivities
      enrollments(where: {deletedAt: {_isNull: true}, userId: {_in: $userIds}}) {
        userId
        user {
          active
          firstName
          lastName
          insertInstant
          identity {
            email
          }
        }
        createdAt
        completedAt
      }
    }
  }
  ${ClassEventsWithFeedbacKViewedActivitiesFragmentDoc}
  ${ClassEventsWithCompleteActivitiesFragmentDoc}
`;

/**
 * __useGetEnrollmentsExportQuery__
 *
 * To run a query within a React component, call `useGetEnrollmentsExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnrollmentsExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnrollmentsExportQuery({
 *   variables: {
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useGetEnrollmentsExportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEnrollmentsExportQuery,
    GetEnrollmentsExportQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetEnrollmentsExportQuery, GetEnrollmentsExportQueryVariables>(
    GetEnrollmentsExportDocument,
    options
  );
}
export function useGetEnrollmentsExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEnrollmentsExportQuery,
    GetEnrollmentsExportQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetEnrollmentsExportQuery, GetEnrollmentsExportQueryVariables>(
    GetEnrollmentsExportDocument,
    options
  );
}
export type GetEnrollmentsExportQueryHookResult = ReturnType<typeof useGetEnrollmentsExportQuery>;
export type GetEnrollmentsExportLazyQueryHookResult = ReturnType<
  typeof useGetEnrollmentsExportLazyQuery
>;
export type GetEnrollmentsExportQueryResult = Apollo.QueryResult<
  GetEnrollmentsExportQuery,
  GetEnrollmentsExportQueryVariables
>;
export const GetOnDemandCoursesExportDocument = gql`
  query GetOnDemandCoursesExport($userIds: [uuid!]!) {
    cohorts(
      where: {
        course: {type: {_eq: TRADITIONAL}}
        enrollments: {deletedAt: {_isNull: true}, userId: {_in: $userIds}}
      }
      orderBy: {name: ASC}
    ) {
      course {
        name
      }
      classEvents {
        id
      }
      ...ClassEventsWithCompleteActivities
      enrollments(
        where: {deletedAt: {_isNull: true}, userId: {_in: $userIds}}
        orderBy: [{user: {firstName: ASC}}, {user: {lastName: ASC}}]
      ) {
        userId
        user {
          firstName
          lastName
        }
      }
    }
  }
  ${ClassEventsWithCompleteActivitiesFragmentDoc}
`;

/**
 * __useGetOnDemandCoursesExportQuery__
 *
 * To run a query within a React component, call `useGetOnDemandCoursesExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnDemandCoursesExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnDemandCoursesExportQuery({
 *   variables: {
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useGetOnDemandCoursesExportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOnDemandCoursesExportQuery,
    GetOnDemandCoursesExportQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetOnDemandCoursesExportQuery, GetOnDemandCoursesExportQueryVariables>(
    GetOnDemandCoursesExportDocument,
    options
  );
}
export function useGetOnDemandCoursesExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOnDemandCoursesExportQuery,
    GetOnDemandCoursesExportQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetOnDemandCoursesExportQuery, GetOnDemandCoursesExportQueryVariables>(
    GetOnDemandCoursesExportDocument,
    options
  );
}
export type GetOnDemandCoursesExportQueryHookResult = ReturnType<
  typeof useGetOnDemandCoursesExportQuery
>;
export type GetOnDemandCoursesExportLazyQueryHookResult = ReturnType<
  typeof useGetOnDemandCoursesExportLazyQuery
>;
export type GetOnDemandCoursesExportQueryResult = Apollo.QueryResult<
  GetOnDemandCoursesExportQuery,
  GetOnDemandCoursesExportQueryVariables
>;
export const GetReportsByTypeDocument = gql`
  query GetReportsByType($reportTypes: [ReportTypeEnum!]!) {
    reports(where: {type: {_in: $reportTypes}}) {
      id
      name
      slug
      embedPath
      desc
      height
      type
    }
  }
`;

/**
 * __useGetReportsByTypeQuery__
 *
 * To run a query within a React component, call `useGetReportsByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportsByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportsByTypeQuery({
 *   variables: {
 *      reportTypes: // value for 'reportTypes'
 *   },
 * });
 */
export function useGetReportsByTypeQuery(
  baseOptions: Apollo.QueryHookOptions<GetReportsByTypeQuery, GetReportsByTypeQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetReportsByTypeQuery, GetReportsByTypeQueryVariables>(
    GetReportsByTypeDocument,
    options
  );
}
export function useGetReportsByTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReportsByTypeQuery, GetReportsByTypeQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetReportsByTypeQuery, GetReportsByTypeQueryVariables>(
    GetReportsByTypeDocument,
    options
  );
}
export type GetReportsByTypeQueryHookResult = ReturnType<typeof useGetReportsByTypeQuery>;
export type GetReportsByTypeLazyQueryHookResult = ReturnType<typeof useGetReportsByTypeLazyQuery>;
export type GetReportsByTypeQueryResult = Apollo.QueryResult<
  GetReportsByTypeQuery,
  GetReportsByTypeQueryVariables
>;
export const GetResourceDocument = gql`
  query GetResource($id: uuid!) {
    resource: resourcesByPk(id: $id) {
      ...ResourcesMetadata
    }
  }
  ${ResourcesMetadataFragmentDoc}
`;

/**
 * __useGetResourceQuery__
 *
 * To run a query within a React component, call `useGetResourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetResourceQuery(
  baseOptions: Apollo.QueryHookOptions<GetResourceQuery, GetResourceQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetResourceQuery, GetResourceQueryVariables>(GetResourceDocument, options);
}
export function useGetResourceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetResourceQuery, GetResourceQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetResourceQuery, GetResourceQueryVariables>(
    GetResourceDocument,
    options
  );
}
export type GetResourceQueryHookResult = ReturnType<typeof useGetResourceQuery>;
export type GetResourceLazyQueryHookResult = ReturnType<typeof useGetResourceLazyQuery>;
export type GetResourceQueryResult = Apollo.QueryResult<
  GetResourceQuery,
  GetResourceQueryVariables
>;
export const GetAllSchoolsDocument = gql`
  query GetAllSchools($orderBy: [SchoolsOrderBy!], $now: timestamp!) {
    schools(
      where: {_or: [{deletedAt: {_gt: $now}}, {deletedAt: {_isNull: true}}]}
      orderBy: $orderBy
    ) {
      id
      name
      slug
    }
  }
`;

/**
 * __useGetAllSchoolsQuery__
 *
 * To run a query within a React component, call `useGetAllSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSchoolsQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      now: // value for 'now'
 *   },
 * });
 */
export function useGetAllSchoolsQuery(
  baseOptions: Apollo.QueryHookOptions<GetAllSchoolsQuery, GetAllSchoolsQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetAllSchoolsQuery, GetAllSchoolsQueryVariables>(
    GetAllSchoolsDocument,
    options
  );
}
export function useGetAllSchoolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllSchoolsQuery, GetAllSchoolsQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetAllSchoolsQuery, GetAllSchoolsQueryVariables>(
    GetAllSchoolsDocument,
    options
  );
}
export type GetAllSchoolsQueryHookResult = ReturnType<typeof useGetAllSchoolsQuery>;
export type GetAllSchoolsLazyQueryHookResult = ReturnType<typeof useGetAllSchoolsLazyQuery>;
export type GetAllSchoolsQueryResult = Apollo.QueryResult<
  GetAllSchoolsQuery,
  GetAllSchoolsQueryVariables
>;
export const GetFunnelCategoryTagsDocument = gql`
  query GetFunnelCategoryTags {
    tags(where: {tagType: {slug: {_eq: "funnel-category"}}}, orderBy: {name: ASC}) {
      id
      name
      slug
      description
    }
  }
`;

/**
 * __useGetFunnelCategoryTagsQuery__
 *
 * To run a query within a React component, call `useGetFunnelCategoryTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFunnelCategoryTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFunnelCategoryTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFunnelCategoryTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFunnelCategoryTagsQuery,
    GetFunnelCategoryTagsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetFunnelCategoryTagsQuery, GetFunnelCategoryTagsQueryVariables>(
    GetFunnelCategoryTagsDocument,
    options
  );
}
export function useGetFunnelCategoryTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFunnelCategoryTagsQuery,
    GetFunnelCategoryTagsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetFunnelCategoryTagsQuery, GetFunnelCategoryTagsQueryVariables>(
    GetFunnelCategoryTagsDocument,
    options
  );
}
export type GetFunnelCategoryTagsQueryHookResult = ReturnType<typeof useGetFunnelCategoryTagsQuery>;
export type GetFunnelCategoryTagsLazyQueryHookResult = ReturnType<
  typeof useGetFunnelCategoryTagsLazyQuery
>;
export type GetFunnelCategoryTagsQueryResult = Apollo.QueryResult<
  GetFunnelCategoryTagsQuery,
  GetFunnelCategoryTagsQueryVariables
>;
export const GetRoleTagsDocument = gql`
  query GetRoleTags {
    tags(where: {tagType: {slug: {_eq: "role"}}}, orderBy: {name: ASC}) {
      id
      name
      slug
    }
  }
`;

/**
 * __useGetRoleTagsQuery__
 *
 * To run a query within a React component, call `useGetRoleTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRoleTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRoleTagsQuery, GetRoleTagsQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetRoleTagsQuery, GetRoleTagsQueryVariables>(GetRoleTagsDocument, options);
}
export function useGetRoleTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRoleTagsQuery, GetRoleTagsQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetRoleTagsQuery, GetRoleTagsQueryVariables>(
    GetRoleTagsDocument,
    options
  );
}
export type GetRoleTagsQueryHookResult = ReturnType<typeof useGetRoleTagsQuery>;
export type GetRoleTagsLazyQueryHookResult = ReturnType<typeof useGetRoleTagsLazyQuery>;
export type GetRoleTagsQueryResult = Apollo.QueryResult<
  GetRoleTagsQuery,
  GetRoleTagsQueryVariables
>;
export const GetSkillTagsDocument = gql`
  query GetSkillTags {
    tags(where: {tagType: {slug: {_eq: "skill"}}}, orderBy: {name: ASC}) {
      id
      name
      slug
      description
    }
  }
`;

/**
 * __useGetSkillTagsQuery__
 *
 * To run a query within a React component, call `useGetSkillTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSkillTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSkillTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSkillTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSkillTagsQuery, GetSkillTagsQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetSkillTagsQuery, GetSkillTagsQueryVariables>(
    GetSkillTagsDocument,
    options
  );
}
export function useGetSkillTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSkillTagsQuery, GetSkillTagsQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetSkillTagsQuery, GetSkillTagsQueryVariables>(
    GetSkillTagsDocument,
    options
  );
}
export type GetSkillTagsQueryHookResult = ReturnType<typeof useGetSkillTagsQuery>;
export type GetSkillTagsLazyQueryHookResult = ReturnType<typeof useGetSkillTagsLazyQuery>;
export type GetSkillTagsQueryResult = Apollo.QueryResult<
  GetSkillTagsQuery,
  GetSkillTagsQueryVariables
>;
export const GetAllTeamsFromOrgDocument = gql`
  query GetAllTeamsFromOrg($organizationId: uuid!) {
    teams(where: {organizationId: {_eq: $organizationId}}) {
      id
      name
      parentId
    }
  }
`;

/**
 * __useGetAllTeamsFromOrgQuery__
 *
 * To run a query within a React component, call `useGetAllTeamsFromOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTeamsFromOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTeamsFromOrgQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetAllTeamsFromOrgQuery(
  baseOptions: Apollo.QueryHookOptions<GetAllTeamsFromOrgQuery, GetAllTeamsFromOrgQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetAllTeamsFromOrgQuery, GetAllTeamsFromOrgQueryVariables>(
    GetAllTeamsFromOrgDocument,
    options
  );
}
export function useGetAllTeamsFromOrgLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllTeamsFromOrgQuery,
    GetAllTeamsFromOrgQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetAllTeamsFromOrgQuery, GetAllTeamsFromOrgQueryVariables>(
    GetAllTeamsFromOrgDocument,
    options
  );
}
export type GetAllTeamsFromOrgQueryHookResult = ReturnType<typeof useGetAllTeamsFromOrgQuery>;
export type GetAllTeamsFromOrgLazyQueryHookResult = ReturnType<
  typeof useGetAllTeamsFromOrgLazyQuery
>;
export type GetAllTeamsFromOrgQueryResult = Apollo.QueryResult<
  GetAllTeamsFromOrgQuery,
  GetAllTeamsFromOrgQueryVariables
>;
export const CountTeamSessionsDocument = gql`
  query CountTeamSessions($courseSlug: String!) {
    CountTeamSessions(courseSlug: $courseSlug) {
      users {
        id
        firstName
        lastName
        imageUrl
        count
      }
    }
  }
`;

/**
 * __useCountTeamSessionsQuery__
 *
 * To run a query within a React component, call `useCountTeamSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountTeamSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountTeamSessionsQuery({
 *   variables: {
 *      courseSlug: // value for 'courseSlug'
 *   },
 * });
 */
export function useCountTeamSessionsQuery(
  baseOptions: Apollo.QueryHookOptions<CountTeamSessionsQuery, CountTeamSessionsQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<CountTeamSessionsQuery, CountTeamSessionsQueryVariables>(
    CountTeamSessionsDocument,
    options
  );
}
export function useCountTeamSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CountTeamSessionsQuery, CountTeamSessionsQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<CountTeamSessionsQuery, CountTeamSessionsQueryVariables>(
    CountTeamSessionsDocument,
    options
  );
}
export type CountTeamSessionsQueryHookResult = ReturnType<typeof useCountTeamSessionsQuery>;
export type CountTeamSessionsLazyQueryHookResult = ReturnType<typeof useCountTeamSessionsLazyQuery>;
export type CountTeamSessionsQueryResult = Apollo.QueryResult<
  CountTeamSessionsQuery,
  CountTeamSessionsQueryVariables
>;
export const GetManagersForMemberDocument = gql`
  query GetManagersForMember($userId: uuid!) {
    subordinates(where: {userId: {_eq: $userId}}) {
      manager {
        id
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useGetManagersForMemberQuery__
 *
 * To run a query within a React component, call `useGetManagersForMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagersForMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagersForMemberQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetManagersForMemberQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagersForMemberQuery,
    GetManagersForMemberQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetManagersForMemberQuery, GetManagersForMemberQueryVariables>(
    GetManagersForMemberDocument,
    options
  );
}
export function useGetManagersForMemberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagersForMemberQuery,
    GetManagersForMemberQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetManagersForMemberQuery, GetManagersForMemberQueryVariables>(
    GetManagersForMemberDocument,
    options
  );
}
export type GetManagersForMemberQueryHookResult = ReturnType<typeof useGetManagersForMemberQuery>;
export type GetManagersForMemberLazyQueryHookResult = ReturnType<
  typeof useGetManagersForMemberLazyQuery
>;
export type GetManagersForMemberQueryResult = Apollo.QueryResult<
  GetManagersForMemberQuery,
  GetManagersForMemberQueryVariables
>;
export const GetTeamByIdDocument = gql`
  query GetTeamById($id: uuid!) {
    teamsByPk(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useGetTeamByIdQuery__
 *
 * To run a query within a React component, call `useGetTeamByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTeamByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetTeamByIdQuery, GetTeamByIdQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetTeamByIdQuery, GetTeamByIdQueryVariables>(GetTeamByIdDocument, options);
}
export function useGetTeamByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTeamByIdQuery, GetTeamByIdQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetTeamByIdQuery, GetTeamByIdQueryVariables>(
    GetTeamByIdDocument,
    options
  );
}
export type GetTeamByIdQueryHookResult = ReturnType<typeof useGetTeamByIdQuery>;
export type GetTeamByIdLazyQueryHookResult = ReturnType<typeof useGetTeamByIdLazyQuery>;
export type GetTeamByIdQueryResult = Apollo.QueryResult<
  GetTeamByIdQuery,
  GetTeamByIdQueryVariables
>;
export const GetTeamMemberForManagerDocument = gql`
  query GetTeamMemberForManager($userId: uuid!, $isActive: Boolean = true) {
    subordinates(where: {isActive: {_eq: $isActive}, userId: {_eq: $userId}}) {
      managerId
      userId
      enrollments
      waitlists
    }
  }
`;

/**
 * __useGetTeamMemberForManagerQuery__
 *
 * To run a query within a React component, call `useGetTeamMemberForManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamMemberForManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamMemberForManagerQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useGetTeamMemberForManagerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTeamMemberForManagerQuery,
    GetTeamMemberForManagerQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetTeamMemberForManagerQuery, GetTeamMemberForManagerQueryVariables>(
    GetTeamMemberForManagerDocument,
    options
  );
}
export function useGetTeamMemberForManagerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTeamMemberForManagerQuery,
    GetTeamMemberForManagerQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetTeamMemberForManagerQuery, GetTeamMemberForManagerQueryVariables>(
    GetTeamMemberForManagerDocument,
    options
  );
}
export type GetTeamMemberForManagerQueryHookResult = ReturnType<
  typeof useGetTeamMemberForManagerQuery
>;
export type GetTeamMemberForManagerLazyQueryHookResult = ReturnType<
  typeof useGetTeamMemberForManagerLazyQuery
>;
export type GetTeamMemberForManagerQueryResult = Apollo.QueryResult<
  GetTeamMemberForManagerQuery,
  GetTeamMemberForManagerQueryVariables
>;
export const GetTeamMembersDocument = gql`
  query GetTeamMembers($teamFlatTree: jsonb!, $isActive: Boolean!) {
    getTeamMembers(args: {teamFlatTree: $teamFlatTree, isActive: $isActive}) {
      userId
      firstName
      lastName
      email
      role
      teamId
      teamName
      jobFunction
      jobFunctionLength
      teamIds
      teamNames
      isActive
    }
  }
`;

/**
 * __useGetTeamMembersQuery__
 *
 * To run a query within a React component, call `useGetTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamMembersQuery({
 *   variables: {
 *      teamFlatTree: // value for 'teamFlatTree'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useGetTeamMembersQuery(
  baseOptions: Apollo.QueryHookOptions<GetTeamMembersQuery, GetTeamMembersQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetTeamMembersQuery, GetTeamMembersQueryVariables>(
    GetTeamMembersDocument,
    options
  );
}
export function useGetTeamMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTeamMembersQuery, GetTeamMembersQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetTeamMembersQuery, GetTeamMembersQueryVariables>(
    GetTeamMembersDocument,
    options
  );
}
export type GetTeamMembersQueryHookResult = ReturnType<typeof useGetTeamMembersQuery>;
export type GetTeamMembersLazyQueryHookResult = ReturnType<typeof useGetTeamMembersLazyQuery>;
export type GetTeamMembersQueryResult = Apollo.QueryResult<
  GetTeamMembersQuery,
  GetTeamMembersQueryVariables
>;
export const GetTeamsForUserDocument = gql`
  query GetTeamsForUser($userId: uuid!) {
    teamsUsers(where: {userId: {_eq: $userId}}) {
      teamId
      role
    }
  }
`;

/**
 * __useGetTeamsForUserQuery__
 *
 * To run a query within a React component, call `useGetTeamsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamsForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetTeamsForUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetTeamsForUserQuery, GetTeamsForUserQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetTeamsForUserQuery, GetTeamsForUserQueryVariables>(
    GetTeamsForUserDocument,
    options
  );
}
export function useGetTeamsForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTeamsForUserQuery, GetTeamsForUserQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetTeamsForUserQuery, GetTeamsForUserQueryVariables>(
    GetTeamsForUserDocument,
    options
  );
}
export type GetTeamsForUserQueryHookResult = ReturnType<typeof useGetTeamsForUserQuery>;
export type GetTeamsForUserLazyQueryHookResult = ReturnType<typeof useGetTeamsForUserLazyQuery>;
export type GetTeamsForUserQueryResult = Apollo.QueryResult<
  GetTeamsForUserQuery,
  GetTeamsForUserQueryVariables
>;
export const MySubordinatesDocument = gql`
  query MySubordinates {
    subordinates {
      userId
    }
  }
`;

/**
 * __useMySubordinatesQuery__
 *
 * To run a query within a React component, call `useMySubordinatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMySubordinatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMySubordinatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMySubordinatesQuery(
  baseOptions?: Apollo.QueryHookOptions<MySubordinatesQuery, MySubordinatesQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<MySubordinatesQuery, MySubordinatesQueryVariables>(
    MySubordinatesDocument,
    options
  );
}
export function useMySubordinatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MySubordinatesQuery, MySubordinatesQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<MySubordinatesQuery, MySubordinatesQueryVariables>(
    MySubordinatesDocument,
    options
  );
}
export type MySubordinatesQueryHookResult = ReturnType<typeof useMySubordinatesQuery>;
export type MySubordinatesLazyQueryHookResult = ReturnType<typeof useMySubordinatesLazyQuery>;
export type MySubordinatesQueryResult = Apollo.QueryResult<
  MySubordinatesQuery,
  MySubordinatesQueryVariables
>;
export const MyTeamFlatTreeDocument = gql`
  query MyTeamFlatTree($organizationId: uuid!, $userId: uuid!) {
    myTeamFlatTree(args: {organizationId: $organizationId, userId: $userId}) {
      teamId
      parentId
      organizationId
      name
      jobFunction
      slug
      branch
      managerNames
      memberNames
      treeId
      nodeHeight
      hasChildrenTeams
    }
  }
`;

/**
 * __useMyTeamFlatTreeQuery__
 *
 * To run a query within a React component, call `useMyTeamFlatTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyTeamFlatTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyTeamFlatTreeQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMyTeamFlatTreeQuery(
  baseOptions: Apollo.QueryHookOptions<MyTeamFlatTreeQuery, MyTeamFlatTreeQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<MyTeamFlatTreeQuery, MyTeamFlatTreeQueryVariables>(
    MyTeamFlatTreeDocument,
    options
  );
}
export function useMyTeamFlatTreeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyTeamFlatTreeQuery, MyTeamFlatTreeQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<MyTeamFlatTreeQuery, MyTeamFlatTreeQueryVariables>(
    MyTeamFlatTreeDocument,
    options
  );
}
export type MyTeamFlatTreeQueryHookResult = ReturnType<typeof useMyTeamFlatTreeQuery>;
export type MyTeamFlatTreeLazyQueryHookResult = ReturnType<typeof useMyTeamFlatTreeLazyQuery>;
export type MyTeamFlatTreeQueryResult = Apollo.QueryResult<
  MyTeamFlatTreeQuery,
  MyTeamFlatTreeQueryVariables
>;
export const MyTeamMembersDocument = gql`
  query MyTeamMembers($onlyActive: Boolean) {
    MyTeamMembers(activeOnly: $onlyActive) {
      members {
        id
        fullName
        avatarUrl
        email
        teamId
        teamName
      }
    }
  }
`;

/**
 * __useMyTeamMembersQuery__
 *
 * To run a query within a React component, call `useMyTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyTeamMembersQuery({
 *   variables: {
 *      onlyActive: // value for 'onlyActive'
 *   },
 * });
 */
export function useMyTeamMembersQuery(
  baseOptions?: Apollo.QueryHookOptions<MyTeamMembersQuery, MyTeamMembersQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<MyTeamMembersQuery, MyTeamMembersQueryVariables>(
    MyTeamMembersDocument,
    options
  );
}
export function useMyTeamMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyTeamMembersQuery, MyTeamMembersQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<MyTeamMembersQuery, MyTeamMembersQueryVariables>(
    MyTeamMembersDocument,
    options
  );
}
export type MyTeamMembersQueryHookResult = ReturnType<typeof useMyTeamMembersQuery>;
export type MyTeamMembersLazyQueryHookResult = ReturnType<typeof useMyTeamMembersLazyQuery>;
export type MyTeamMembersQueryResult = Apollo.QueryResult<
  MyTeamMembersQuery,
  MyTeamMembersQueryVariables
>;
export const MyTeamSessionCountDocument = gql`
  query MyTeamSessionCount($userIds: [uuid!], $courseSlug: String!) {
    users(where: {id: {_in: $userIds}, active: {_eq: true}}) {
      id
      firstName
      lastName
      imageUrl
      learnerLightningSessionSubmissionsAggregate(
        where: {
          status: {
            _in: [COMPLETE, WAITING_FOR_COMPLETION, WAITING_FOR_COACH_REVIEW, SKIPPED_PRACTICE]
          }
          isNotDeleted: {_eq: true}
          cohort: {course: {slug: {_eq: $courseSlug}}}
        }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

/**
 * __useMyTeamSessionCountQuery__
 *
 * To run a query within a React component, call `useMyTeamSessionCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyTeamSessionCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyTeamSessionCountQuery({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      courseSlug: // value for 'courseSlug'
 *   },
 * });
 */
export function useMyTeamSessionCountQuery(
  baseOptions: Apollo.QueryHookOptions<MyTeamSessionCountQuery, MyTeamSessionCountQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<MyTeamSessionCountQuery, MyTeamSessionCountQueryVariables>(
    MyTeamSessionCountDocument,
    options
  );
}
export function useMyTeamSessionCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyTeamSessionCountQuery,
    MyTeamSessionCountQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<MyTeamSessionCountQuery, MyTeamSessionCountQueryVariables>(
    MyTeamSessionCountDocument,
    options
  );
}
export type MyTeamSessionCountQueryHookResult = ReturnType<typeof useMyTeamSessionCountQuery>;
export type MyTeamSessionCountLazyQueryHookResult = ReturnType<
  typeof useMyTeamSessionCountLazyQuery
>;
export type MyTeamSessionCountQueryResult = Apollo.QueryResult<
  MyTeamSessionCountQuery,
  MyTeamSessionCountQueryVariables
>;
export const GetTeamFlatTreeForManagerDocument = gql`
  query GetTeamFlatTreeForManager(
    $organizationId: uuid!
    $userId: uuid!
    $includeLearners: Boolean = false
    $includeManagers: Boolean = false
    $includePollSubmissions: Boolean = false
  ) {
    userManagedTeamsFlattenedTree(args: {organizationId: $organizationId, userId: $userId}) {
      ...TeamFlatTreeNode
      managerUsers: members(where: {role: {_eq: "manager"}}) @include(if: $includeManagers) {
        ...TeamMember
        pollSubmissions @include(if: $includePollSubmissions) {
          ...PollSubmissionNPS
        }
      }
      learnerUsers: members(where: {role: {_eq: "member"}}) @include(if: $includeLearners) {
        ...TeamMember
        pollSubmissions @include(if: $includePollSubmissions) {
          ...PollSubmissionNPS
        }
      }
    }
  }
  ${TeamFlatTreeNodeFragmentDoc}
  ${TeamMemberFragmentDoc}
  ${PollSubmissionNpsFragmentDoc}
`;

/**
 * __useGetTeamFlatTreeForManagerQuery__
 *
 * To run a query within a React component, call `useGetTeamFlatTreeForManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamFlatTreeForManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamFlatTreeForManagerQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *      includeLearners: // value for 'includeLearners'
 *      includeManagers: // value for 'includeManagers'
 *      includePollSubmissions: // value for 'includePollSubmissions'
 *   },
 * });
 */
export function useGetTeamFlatTreeForManagerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTeamFlatTreeForManagerQuery,
    GetTeamFlatTreeForManagerQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetTeamFlatTreeForManagerQuery, GetTeamFlatTreeForManagerQueryVariables>(
    GetTeamFlatTreeForManagerDocument,
    options
  );
}
export function useGetTeamFlatTreeForManagerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTeamFlatTreeForManagerQuery,
    GetTeamFlatTreeForManagerQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetTeamFlatTreeForManagerQuery,
    GetTeamFlatTreeForManagerQueryVariables
  >(GetTeamFlatTreeForManagerDocument, options);
}
export type GetTeamFlatTreeForManagerQueryHookResult = ReturnType<
  typeof useGetTeamFlatTreeForManagerQuery
>;
export type GetTeamFlatTreeForManagerLazyQueryHookResult = ReturnType<
  typeof useGetTeamFlatTreeForManagerLazyQuery
>;
export type GetTeamFlatTreeForManagerQueryResult = Apollo.QueryResult<
  GetTeamFlatTreeForManagerQuery,
  GetTeamFlatTreeForManagerQueryVariables
>;
export const GetTeamFlatTreeWithMemberPollSubmissionsDocument = gql`
  query GetTeamFlatTreeWithMemberPollSubmissions(
    $organizationId: uuid!
    $userId: uuid!
    $cohortId: uuid!
  ) {
    userManagedTeamsFlattenedTree(args: {organizationId: $organizationId, userId: $userId}) {
      ...TeamFlatTreeNode
      memberUsers: members {
        ...TeamMember
        pollSubmissions(where: {cohortId: {_eq: $cohortId}}) {
          ...PollSubmissionNPS
        }
      }
    }
  }
  ${TeamFlatTreeNodeFragmentDoc}
  ${TeamMemberFragmentDoc}
  ${PollSubmissionNpsFragmentDoc}
`;

/**
 * __useGetTeamFlatTreeWithMemberPollSubmissionsQuery__
 *
 * To run a query within a React component, call `useGetTeamFlatTreeWithMemberPollSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamFlatTreeWithMemberPollSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamFlatTreeWithMemberPollSubmissionsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *      cohortId: // value for 'cohortId'
 *   },
 * });
 */
export function useGetTeamFlatTreeWithMemberPollSubmissionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTeamFlatTreeWithMemberPollSubmissionsQuery,
    GetTeamFlatTreeWithMemberPollSubmissionsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetTeamFlatTreeWithMemberPollSubmissionsQuery,
    GetTeamFlatTreeWithMemberPollSubmissionsQueryVariables
  >(GetTeamFlatTreeWithMemberPollSubmissionsDocument, options);
}
export function useGetTeamFlatTreeWithMemberPollSubmissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTeamFlatTreeWithMemberPollSubmissionsQuery,
    GetTeamFlatTreeWithMemberPollSubmissionsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetTeamFlatTreeWithMemberPollSubmissionsQuery,
    GetTeamFlatTreeWithMemberPollSubmissionsQueryVariables
  >(GetTeamFlatTreeWithMemberPollSubmissionsDocument, options);
}
export type GetTeamFlatTreeWithMemberPollSubmissionsQueryHookResult = ReturnType<
  typeof useGetTeamFlatTreeWithMemberPollSubmissionsQuery
>;
export type GetTeamFlatTreeWithMemberPollSubmissionsLazyQueryHookResult = ReturnType<
  typeof useGetTeamFlatTreeWithMemberPollSubmissionsLazyQuery
>;
export type GetTeamFlatTreeWithMemberPollSubmissionsQueryResult = Apollo.QueryResult<
  GetTeamFlatTreeWithMemberPollSubmissionsQuery,
  GetTeamFlatTreeWithMemberPollSubmissionsQueryVariables
>;
export const AllUserManagedTeamsFlattenedTreeDocument = gql`
  query AllUserManagedTeamsFlattenedTree(
    $organizationId: uuid!
    $userId: uuid!
    $includeCredits: Boolean = false
  ) {
    userManagedTeamsFlattenedTree(
      args: {organizationId: $organizationId, userId: $userId}
      orderBy: [{name: ASC}, {nodeHeight: ASC}]
    ) {
      ...TeamFlatTreeNode
      memberUsers: members(orderBy: {fullName: ASC}) {
        ...TeamMember
        creditUsage @include(if: $includeCredits) {
          userId
          enrollmentId
          cohortName
          cohortStartDate
          courseName
          spent
          scheduled
        }
        creditUsageAggregate {
          userId
          spent
          scheduled
        }
      }
    }
  }
  ${TeamFlatTreeNodeFragmentDoc}
  ${TeamMemberFragmentDoc}
`;

/**
 * __useAllUserManagedTeamsFlattenedTreeQuery__
 *
 * To run a query within a React component, call `useAllUserManagedTeamsFlattenedTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserManagedTeamsFlattenedTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserManagedTeamsFlattenedTreeQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *      includeCredits: // value for 'includeCredits'
 *   },
 * });
 */
export function useAllUserManagedTeamsFlattenedTreeQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllUserManagedTeamsFlattenedTreeQuery,
    AllUserManagedTeamsFlattenedTreeQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    AllUserManagedTeamsFlattenedTreeQuery,
    AllUserManagedTeamsFlattenedTreeQueryVariables
  >(AllUserManagedTeamsFlattenedTreeDocument, options);
}
export function useAllUserManagedTeamsFlattenedTreeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllUserManagedTeamsFlattenedTreeQuery,
    AllUserManagedTeamsFlattenedTreeQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    AllUserManagedTeamsFlattenedTreeQuery,
    AllUserManagedTeamsFlattenedTreeQueryVariables
  >(AllUserManagedTeamsFlattenedTreeDocument, options);
}
export type AllUserManagedTeamsFlattenedTreeQueryHookResult = ReturnType<
  typeof useAllUserManagedTeamsFlattenedTreeQuery
>;
export type AllUserManagedTeamsFlattenedTreeLazyQueryHookResult = ReturnType<
  typeof useAllUserManagedTeamsFlattenedTreeLazyQuery
>;
export type AllUserManagedTeamsFlattenedTreeQueryResult = Apollo.QueryResult<
  AllUserManagedTeamsFlattenedTreeQuery,
  AllUserManagedTeamsFlattenedTreeQueryVariables
>;
export const FilteredUserManagedTeamsFlattenedTreeDocument = gql`
  query FilteredUserManagedTeamsFlattenedTree(
    $organizationId: uuid!
    $userId: uuid!
    $onlyActive: Boolean!
  ) {
    userManagedTeamsFlattenedTree(args: {organizationId: $organizationId, userId: $userId}) {
      ...TeamFlatTreeNode
      memberUsers: members(where: {isActive: {_eq: $onlyActive}}) {
        ...TeamMember
      }
    }
  }
  ${TeamFlatTreeNodeFragmentDoc}
  ${TeamMemberFragmentDoc}
`;

/**
 * __useFilteredUserManagedTeamsFlattenedTreeQuery__
 *
 * To run a query within a React component, call `useFilteredUserManagedTeamsFlattenedTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilteredUserManagedTeamsFlattenedTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilteredUserManagedTeamsFlattenedTreeQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *      onlyActive: // value for 'onlyActive'
 *   },
 * });
 */
export function useFilteredUserManagedTeamsFlattenedTreeQuery(
  baseOptions: Apollo.QueryHookOptions<
    FilteredUserManagedTeamsFlattenedTreeQuery,
    FilteredUserManagedTeamsFlattenedTreeQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    FilteredUserManagedTeamsFlattenedTreeQuery,
    FilteredUserManagedTeamsFlattenedTreeQueryVariables
  >(FilteredUserManagedTeamsFlattenedTreeDocument, options);
}
export function useFilteredUserManagedTeamsFlattenedTreeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilteredUserManagedTeamsFlattenedTreeQuery,
    FilteredUserManagedTeamsFlattenedTreeQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    FilteredUserManagedTeamsFlattenedTreeQuery,
    FilteredUserManagedTeamsFlattenedTreeQueryVariables
  >(FilteredUserManagedTeamsFlattenedTreeDocument, options);
}
export type FilteredUserManagedTeamsFlattenedTreeQueryHookResult = ReturnType<
  typeof useFilteredUserManagedTeamsFlattenedTreeQuery
>;
export type FilteredUserManagedTeamsFlattenedTreeLazyQueryHookResult = ReturnType<
  typeof useFilteredUserManagedTeamsFlattenedTreeLazyQuery
>;
export type FilteredUserManagedTeamsFlattenedTreeQueryResult = Apollo.QueryResult<
  FilteredUserManagedTeamsFlattenedTreeQuery,
  FilteredUserManagedTeamsFlattenedTreeQueryVariables
>;
export const CountMySessionsAttentedDocument = gql`
  query CountMySessionsAttented($userId: uuid!) {
    lightningSessionSubmissionsAggregate(
      where: {
        status: {
          _in: [COMPLETE, WAITING_FOR_COMPLETION, WAITING_FOR_COACH_REVIEW, SKIPPED_PRACTICE]
        }
        isNotDeleted: {_eq: true}
        userId: {_eq: $userId}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useCountMySessionsAttentedQuery__
 *
 * To run a query within a React component, call `useCountMySessionsAttentedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountMySessionsAttentedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountMySessionsAttentedQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCountMySessionsAttentedQuery(
  baseOptions: Apollo.QueryHookOptions<
    CountMySessionsAttentedQuery,
    CountMySessionsAttentedQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<CountMySessionsAttentedQuery, CountMySessionsAttentedQueryVariables>(
    CountMySessionsAttentedDocument,
    options
  );
}
export function useCountMySessionsAttentedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CountMySessionsAttentedQuery,
    CountMySessionsAttentedQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<CountMySessionsAttentedQuery, CountMySessionsAttentedQueryVariables>(
    CountMySessionsAttentedDocument,
    options
  );
}
export type CountMySessionsAttentedQueryHookResult = ReturnType<
  typeof useCountMySessionsAttentedQuery
>;
export type CountMySessionsAttentedLazyQueryHookResult = ReturnType<
  typeof useCountMySessionsAttentedLazyQuery
>;
export type CountMySessionsAttentedQueryResult = Apollo.QueryResult<
  CountMySessionsAttentedQuery,
  CountMySessionsAttentedQueryVariables
>;
export const FindUserDocument = gql`
  query FindUser($email: String!) {
    users(where: {identity: {email: {_eq: $email}}}) {
      id
      firstName
      lastName
      imageUrl
      insertInstant
      identity {
        email
      }
      meta {
        joinDate
        locale
        selectedOrgId
      }
      organizationUsers {
        organization {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useFindUserQuery__
 *
 * To run a query within a React component, call `useFindUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useFindUserQuery(
  baseOptions: Apollo.QueryHookOptions<FindUserQuery, FindUserQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<FindUserQuery, FindUserQueryVariables>(FindUserDocument, options);
}
export function useFindUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindUserQuery, FindUserQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<FindUserQuery, FindUserQueryVariables>(FindUserDocument, options);
}
export type FindUserQueryHookResult = ReturnType<typeof useFindUserQuery>;
export type FindUserLazyQueryHookResult = ReturnType<typeof useFindUserLazyQuery>;
export type FindUserQueryResult = Apollo.QueryResult<FindUserQuery, FindUserQueryVariables>;
export const GetManagerTeamsDocument = gql`
  query GetManagerTeams {
    subordinates(where: {isActive: {_eq: true}}) {
      firstName
      lastName
      userId
      teamIds
    }
  }
`;

/**
 * __useGetManagerTeamsQuery__
 *
 * To run a query within a React component, call `useGetManagerTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagerTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagerTeamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManagerTeamsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetManagerTeamsQuery, GetManagerTeamsQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetManagerTeamsQuery, GetManagerTeamsQueryVariables>(
    GetManagerTeamsDocument,
    options
  );
}
export function useGetManagerTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetManagerTeamsQuery, GetManagerTeamsQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetManagerTeamsQuery, GetManagerTeamsQueryVariables>(
    GetManagerTeamsDocument,
    options
  );
}
export type GetManagerTeamsQueryHookResult = ReturnType<typeof useGetManagerTeamsQuery>;
export type GetManagerTeamsLazyQueryHookResult = ReturnType<typeof useGetManagerTeamsLazyQuery>;
export type GetManagerTeamsQueryResult = Apollo.QueryResult<
  GetManagerTeamsQuery,
  GetManagerTeamsQueryVariables
>;
export const GetMySessionsAttendedCountDocument = gql`
  query GetMySessionsAttendedCount {
    GetMySessionsAttendedCount {
      count
    }
  }
`;

/**
 * __useGetMySessionsAttendedCountQuery__
 *
 * To run a query within a React component, call `useGetMySessionsAttendedCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMySessionsAttendedCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMySessionsAttendedCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMySessionsAttendedCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMySessionsAttendedCountQuery,
    GetMySessionsAttendedCountQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetMySessionsAttendedCountQuery, GetMySessionsAttendedCountQueryVariables>(
    GetMySessionsAttendedCountDocument,
    options
  );
}
export function useGetMySessionsAttendedCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMySessionsAttendedCountQuery,
    GetMySessionsAttendedCountQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetMySessionsAttendedCountQuery,
    GetMySessionsAttendedCountQueryVariables
  >(GetMySessionsAttendedCountDocument, options);
}
export type GetMySessionsAttendedCountQueryHookResult = ReturnType<
  typeof useGetMySessionsAttendedCountQuery
>;
export type GetMySessionsAttendedCountLazyQueryHookResult = ReturnType<
  typeof useGetMySessionsAttendedCountLazyQuery
>;
export type GetMySessionsAttendedCountQueryResult = Apollo.QueryResult<
  GetMySessionsAttendedCountQuery,
  GetMySessionsAttendedCountQueryVariables
>;
export const GetUserDocument = gql`
  query GetUser($userId: uuid!) {
    user: usersByPk(id: $userId) {
      id
      firstName
      lastName
      imageUrl
      insertInstant
      identity {
        email
      }
      data
      meta {
        joinDate
        locale
        selectedOrgId
        jobFunction
        jobFunctionLength
        acceptedTermsOfUse
        hasFilledUserProfileData
        updatedAt
      }
      organizationUsers {
        organization {
          id
          name
          meta {
            creditModel
          }
        }
      }
    }
  }
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUserCreditModelDocument = gql`
  query GetUserCreditModel($userId: uuid!) {
    user: usersByPk(id: $userId) {
      organizationUsers {
        organization {
          meta {
            creditModel
          }
        }
      }
    }
  }
`;

/**
 * __useGetUserCreditModelQuery__
 *
 * To run a query within a React component, call `useGetUserCreditModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCreditModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCreditModelQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserCreditModelQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserCreditModelQuery, GetUserCreditModelQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetUserCreditModelQuery, GetUserCreditModelQueryVariables>(
    GetUserCreditModelDocument,
    options
  );
}
export function useGetUserCreditModelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserCreditModelQuery,
    GetUserCreditModelQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetUserCreditModelQuery, GetUserCreditModelQueryVariables>(
    GetUserCreditModelDocument,
    options
  );
}
export type GetUserCreditModelQueryHookResult = ReturnType<typeof useGetUserCreditModelQuery>;
export type GetUserCreditModelLazyQueryHookResult = ReturnType<
  typeof useGetUserCreditModelLazyQuery
>;
export type GetUserCreditModelQueryResult = Apollo.QueryResult<
  GetUserCreditModelQuery,
  GetUserCreditModelQueryVariables
>;
export const GetUserManagedTeamsDocument = gql`
  query GetUserManagedTeams($userId: uuid!) {
    userManagedTeams: teamsUsers(where: {role: {_eq: "manager"}, userId: {_eq: $userId}}) {
      role
      teamId
      team {
        parentId
        organizationId
      }
    }
  }
`;

/**
 * __useGetUserManagedTeamsQuery__
 *
 * To run a query within a React component, call `useGetUserManagedTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserManagedTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserManagedTeamsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserManagedTeamsQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserManagedTeamsQuery, GetUserManagedTeamsQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetUserManagedTeamsQuery, GetUserManagedTeamsQueryVariables>(
    GetUserManagedTeamsDocument,
    options
  );
}
export function useGetUserManagedTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserManagedTeamsQuery,
    GetUserManagedTeamsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetUserManagedTeamsQuery, GetUserManagedTeamsQueryVariables>(
    GetUserManagedTeamsDocument,
    options
  );
}
export type GetUserManagedTeamsQueryHookResult = ReturnType<typeof useGetUserManagedTeamsQuery>;
export type GetUserManagedTeamsLazyQueryHookResult = ReturnType<
  typeof useGetUserManagedTeamsLazyQuery
>;
export type GetUserManagedTeamsQueryResult = Apollo.QueryResult<
  GetUserManagedTeamsQuery,
  GetUserManagedTeamsQueryVariables
>;
export const GetUserOrganizationsDocument = gql`
  query GetUserOrganizations($userId: uuid!) {
    user: usersByPk(id: $userId) {
      id
      organizationUsers {
        organization {
          id
        }
      }
    }
  }
`;

/**
 * __useGetUserOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetUserOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOrganizationsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserOrganizationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserOrganizationsQuery,
    GetUserOrganizationsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetUserOrganizationsQuery, GetUserOrganizationsQueryVariables>(
    GetUserOrganizationsDocument,
    options
  );
}
export function useGetUserOrganizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserOrganizationsQuery,
    GetUserOrganizationsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetUserOrganizationsQuery, GetUserOrganizationsQueryVariables>(
    GetUserOrganizationsDocument,
    options
  );
}
export type GetUserOrganizationsQueryHookResult = ReturnType<typeof useGetUserOrganizationsQuery>;
export type GetUserOrganizationsLazyQueryHookResult = ReturnType<
  typeof useGetUserOrganizationsLazyQuery
>;
export type GetUserOrganizationsQueryResult = Apollo.QueryResult<
  GetUserOrganizationsQuery,
  GetUserOrganizationsQueryVariables
>;
export const ListJobFunctionOptionsDocument = gql`
  query ListJobFunctionOptions {
    usersFunctions {
      value
      description
    }
    usersLengthInFunction {
      value
      description
    }
  }
`;

/**
 * __useListJobFunctionOptionsQuery__
 *
 * To run a query within a React component, call `useListJobFunctionOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListJobFunctionOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListJobFunctionOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListJobFunctionOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListJobFunctionOptionsQuery,
    ListJobFunctionOptionsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<ListJobFunctionOptionsQuery, ListJobFunctionOptionsQueryVariables>(
    ListJobFunctionOptionsDocument,
    options
  );
}
export function useListJobFunctionOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListJobFunctionOptionsQuery,
    ListJobFunctionOptionsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<ListJobFunctionOptionsQuery, ListJobFunctionOptionsQueryVariables>(
    ListJobFunctionOptionsDocument,
    options
  );
}
export type ListJobFunctionOptionsQueryHookResult = ReturnType<
  typeof useListJobFunctionOptionsQuery
>;
export type ListJobFunctionOptionsLazyQueryHookResult = ReturnType<
  typeof useListJobFunctionOptionsLazyQuery
>;
export type ListJobFunctionOptionsQueryResult = Apollo.QueryResult<
  ListJobFunctionOptionsQuery,
  ListJobFunctionOptionsQueryVariables
>;
export const MyLearnerHomeCoursesDocument = gql`
  query MyLearnerHomeCourses {
    myLearnerHomeCourses {
      userId
      cohortId
      cohortName
      cohortType
      cohortSlug
      cohortStart
      cohortEnd
      cohortTimeOfDay
      cohortDayOfWeek
      courseId
      courseSlug
      courseName
      courseType
      courseBackgroundImageUrl
      classCount
      schoolName
      schoolSlug
      certDate
      assessmentPassed
      progress
      isOnTrack
      cohortCompleted
      totalAssessments
      lastCompletedClassDate
      nextClassDate
      enrolledAt
      hasUserCompletedAllClasses
      status
      tags(orderBy: {createdAt: ASC}, where: {tag: {tagType: {slug: {_eq: "role"}}}}) {
        tag {
          name
          slug
        }
      }
    }
  }
`;

/**
 * __useMyLearnerHomeCoursesQuery__
 *
 * To run a query within a React component, call `useMyLearnerHomeCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyLearnerHomeCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyLearnerHomeCoursesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyLearnerHomeCoursesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyLearnerHomeCoursesQuery,
    MyLearnerHomeCoursesQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<MyLearnerHomeCoursesQuery, MyLearnerHomeCoursesQueryVariables>(
    MyLearnerHomeCoursesDocument,
    options
  );
}
export function useMyLearnerHomeCoursesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyLearnerHomeCoursesQuery,
    MyLearnerHomeCoursesQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<MyLearnerHomeCoursesQuery, MyLearnerHomeCoursesQueryVariables>(
    MyLearnerHomeCoursesDocument,
    options
  );
}
export type MyLearnerHomeCoursesQueryHookResult = ReturnType<typeof useMyLearnerHomeCoursesQuery>;
export type MyLearnerHomeCoursesLazyQueryHookResult = ReturnType<
  typeof useMyLearnerHomeCoursesLazyQuery
>;
export type MyLearnerHomeCoursesQueryResult = Apollo.QueryResult<
  MyLearnerHomeCoursesQuery,
  MyLearnerHomeCoursesQueryVariables
>;
export const MyLearnerHomeLiveCoachingDocument = gql`
  query MyLearnerHomeLiveCoaching {
    myLearnerHomeLiveCoaching {
      userId
      cohortId
      cohortName
      cohortType
      cohortSlug
      cohortStart
      cohortEnd
      cohortTimeOfDay
      cohortDayOfWeek
      courseId
      courseSlug
      courseName
      courseType
      courseBackgroundImageUrl
      classCount
      schoolName
      schoolSlug
      certDate
      assessmentPassed
      progress
      isOnTrack
      cohortCompleted
      totalAssessments
      lastCompletedClassDate
      isWaitlisted
      enrolledAt
      status
      tags(orderBy: {createdAt: ASC}, where: {tag: {tagType: {slug: {_eq: "role"}}}}) {
        tag {
          name
          slug
        }
      }
    }
  }
`;

/**
 * __useMyLearnerHomeLiveCoachingQuery__
 *
 * To run a query within a React component, call `useMyLearnerHomeLiveCoachingQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyLearnerHomeLiveCoachingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyLearnerHomeLiveCoachingQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyLearnerHomeLiveCoachingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyLearnerHomeLiveCoachingQuery,
    MyLearnerHomeLiveCoachingQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<MyLearnerHomeLiveCoachingQuery, MyLearnerHomeLiveCoachingQueryVariables>(
    MyLearnerHomeLiveCoachingDocument,
    options
  );
}
export function useMyLearnerHomeLiveCoachingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyLearnerHomeLiveCoachingQuery,
    MyLearnerHomeLiveCoachingQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    MyLearnerHomeLiveCoachingQuery,
    MyLearnerHomeLiveCoachingQueryVariables
  >(MyLearnerHomeLiveCoachingDocument, options);
}
export type MyLearnerHomeLiveCoachingQueryHookResult = ReturnType<
  typeof useMyLearnerHomeLiveCoachingQuery
>;
export type MyLearnerHomeLiveCoachingLazyQueryHookResult = ReturnType<
  typeof useMyLearnerHomeLiveCoachingLazyQuery
>;
export type MyLearnerHomeLiveCoachingQueryResult = Apollo.QueryResult<
  MyLearnerHomeLiveCoachingQuery,
  MyLearnerHomeLiveCoachingQueryVariables
>;
export const MyLearnerTasksDocument = gql`
  query MyLearnerTasks($userId: uuid!) {
    my_learner_task_native_query(args: {userId: $userId}) {
      task_name
      cohort_id
      cohort_slug
      course_name
      course_type
      join_id_or_link
      date
      is_done
    }
  }
`;

/**
 * __useMyLearnerTasksQuery__
 *
 * To run a query within a React component, call `useMyLearnerTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyLearnerTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyLearnerTasksQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMyLearnerTasksQuery(
  baseOptions: Apollo.QueryHookOptions<MyLearnerTasksQuery, MyLearnerTasksQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<MyLearnerTasksQuery, MyLearnerTasksQueryVariables>(
    MyLearnerTasksDocument,
    options
  );
}
export function useMyLearnerTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyLearnerTasksQuery, MyLearnerTasksQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<MyLearnerTasksQuery, MyLearnerTasksQueryVariables>(
    MyLearnerTasksDocument,
    options
  );
}
export type MyLearnerTasksQueryHookResult = ReturnType<typeof useMyLearnerTasksQuery>;
export type MyLearnerTasksLazyQueryHookResult = ReturnType<typeof useMyLearnerTasksLazyQuery>;
export type MyLearnerTasksQueryResult = Apollo.QueryResult<
  MyLearnerTasksQuery,
  MyLearnerTasksQueryVariables
>;
export const LearnerMyLearningStatusDocument = gql`
  query LearnerMyLearningStatus($userId: uuid!) {
    user: usersByPk(id: $userId) {
      activeSubscription {
        name
      }
    }
    myCohortLearningStatus(orderBy: {enrolledAt: DESC}) {
      userId
      cohortId
      cohortName
      cohortType
      cohortSlug
      cohortStart
      cohortEnd
      cohortTimeOfDay
      cohortDayOfWeek
      courseId
      courseSlug
      courseName
      courseType
      courseBackgroundImageUrl
      schoolName
      schoolSlug
      certDate
      assessmentPassed
      progress
      isOnTrack
      cohortCompleted
      totalAssessments
      lastCompletedClassDate
      enrolledAt
    }
  }
`;

/**
 * __useLearnerMyLearningStatusQuery__
 *
 * To run a query within a React component, call `useLearnerMyLearningStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearnerMyLearningStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearnerMyLearningStatusQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useLearnerMyLearningStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    LearnerMyLearningStatusQuery,
    LearnerMyLearningStatusQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<LearnerMyLearningStatusQuery, LearnerMyLearningStatusQueryVariables>(
    LearnerMyLearningStatusDocument,
    options
  );
}
export function useLearnerMyLearningStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LearnerMyLearningStatusQuery,
    LearnerMyLearningStatusQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<LearnerMyLearningStatusQuery, LearnerMyLearningStatusQueryVariables>(
    LearnerMyLearningStatusDocument,
    options
  );
}
export type LearnerMyLearningStatusQueryHookResult = ReturnType<
  typeof useLearnerMyLearningStatusQuery
>;
export type LearnerMyLearningStatusLazyQueryHookResult = ReturnType<
  typeof useLearnerMyLearningStatusLazyQuery
>;
export type LearnerMyLearningStatusQueryResult = Apollo.QueryResult<
  LearnerMyLearningStatusQuery,
  LearnerMyLearningStatusQueryVariables
>;
export const GetUserOrganizationsByPlanPolicyFeaturesDocument = gql`
  query GetUserOrganizationsByPlanPolicyFeatures($userId: uuid!, $features: jsonb!) {
    userOrganizations: usersByPk(id: $userId) {
      organizationUsers(
        where: {organization: {planSubscriptions: {plan: {policy: {_contains: $features}}}}}
      ) {
        id
        organization {
          id
        }
      }
      id
    }
  }
`;

/**
 * __useGetUserOrganizationsByPlanPolicyFeaturesQuery__
 *
 * To run a query within a React component, call `useGetUserOrganizationsByPlanPolicyFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOrganizationsByPlanPolicyFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOrganizationsByPlanPolicyFeaturesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      features: // value for 'features'
 *   },
 * });
 */
export function useGetUserOrganizationsByPlanPolicyFeaturesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserOrganizationsByPlanPolicyFeaturesQuery,
    GetUserOrganizationsByPlanPolicyFeaturesQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetUserOrganizationsByPlanPolicyFeaturesQuery,
    GetUserOrganizationsByPlanPolicyFeaturesQueryVariables
  >(GetUserOrganizationsByPlanPolicyFeaturesDocument, options);
}
export function useGetUserOrganizationsByPlanPolicyFeaturesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserOrganizationsByPlanPolicyFeaturesQuery,
    GetUserOrganizationsByPlanPolicyFeaturesQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetUserOrganizationsByPlanPolicyFeaturesQuery,
    GetUserOrganizationsByPlanPolicyFeaturesQueryVariables
  >(GetUserOrganizationsByPlanPolicyFeaturesDocument, options);
}
export type GetUserOrganizationsByPlanPolicyFeaturesQueryHookResult = ReturnType<
  typeof useGetUserOrganizationsByPlanPolicyFeaturesQuery
>;
export type GetUserOrganizationsByPlanPolicyFeaturesLazyQueryHookResult = ReturnType<
  typeof useGetUserOrganizationsByPlanPolicyFeaturesLazyQuery
>;
export type GetUserOrganizationsByPlanPolicyFeaturesQueryResult = Apollo.QueryResult<
  GetUserOrganizationsByPlanPolicyFeaturesQuery,
  GetUserOrganizationsByPlanPolicyFeaturesQueryVariables
>;
export const GetZoomOauthTokenDocument = gql`
  query GetZoomOauthToken {
    zoomOauthTokens(orderBy: {createdAt: DESC}, limit: 1) {
      bearerToken
      createdAt
      expiresAt
      index
    }
  }
`;

/**
 * __useGetZoomOauthTokenQuery__
 *
 * To run a query within a React component, call `useGetZoomOauthTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetZoomOauthTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetZoomOauthTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetZoomOauthTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<GetZoomOauthTokenQuery, GetZoomOauthTokenQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetZoomOauthTokenQuery, GetZoomOauthTokenQueryVariables>(
    GetZoomOauthTokenDocument,
    options
  );
}
export function useGetZoomOauthTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetZoomOauthTokenQuery, GetZoomOauthTokenQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetZoomOauthTokenQuery, GetZoomOauthTokenQueryVariables>(
    GetZoomOauthTokenDocument,
    options
  );
}
export type GetZoomOauthTokenQueryHookResult = ReturnType<typeof useGetZoomOauthTokenQuery>;
export type GetZoomOauthTokenLazyQueryHookResult = ReturnType<typeof useGetZoomOauthTokenLazyQuery>;
export type GetZoomOauthTokenQueryResult = Apollo.QueryResult<
  GetZoomOauthTokenQuery,
  GetZoomOauthTokenQueryVariables
>;

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> {
  subscribe: SubscriptionSubscribeFn<{[key in TKey]: TResult}, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, {[key in TKey]: TResult}, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {}
> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  ActivityType: ResolverTypeWrapper<ActivityType>;
  ActivityTypeAggregate: ResolverTypeWrapper<ActivityTypeAggregate>;
  ActivityTypeAggregateFields: ResolverTypeWrapper<ActivityTypeAggregateFields>;
  ActivityTypeBoolExp: ActivityTypeBoolExp;
  ActivityTypeConstraint: ActivityTypeConstraint;
  ActivityTypeEnum: ActivityTypeEnum;
  ActivityTypeEnumComparisonExp: ActivityTypeEnumComparisonExp;
  ActivityTypeInsertInput: ActivityTypeInsertInput;
  ActivityTypeMaxFields: ResolverTypeWrapper<ActivityTypeMaxFields>;
  ActivityTypeMinFields: ResolverTypeWrapper<ActivityTypeMinFields>;
  ActivityTypeMutationResponse: ResolverTypeWrapper<ActivityTypeMutationResponse>;
  ActivityTypeOnConflict: ActivityTypeOnConflict;
  ActivityTypeOrderBy: ActivityTypeOrderBy;
  ActivityTypePkColumnsInput: ActivityTypePkColumnsInput;
  ActivityTypeSelectColumn: ActivityTypeSelectColumn;
  ActivityTypeSetInput: ActivityTypeSetInput;
  ActivityTypeStreamCursorInput: ActivityTypeStreamCursorInput;
  ActivityTypeStreamCursorValueInput: ActivityTypeStreamCursorValueInput;
  ActivityTypeUpdateColumn: ActivityTypeUpdateColumn;
  ActivityTypeUpdates: ActivityTypeUpdates;
  AddUserToCourseWaitlistOutput: ResolverTypeWrapper<AddUserToCourseWaitlistOutput>;
  AttemptStatus: ResolverTypeWrapper<AttemptStatus>;
  AttemptStatusAggregate: ResolverTypeWrapper<AttemptStatusAggregate>;
  AttemptStatusAggregateFields: ResolverTypeWrapper<AttemptStatusAggregateFields>;
  AttemptStatusBoolExp: AttemptStatusBoolExp;
  AttemptStatusConstraint: AttemptStatusConstraint;
  AttemptStatusEnum: AttemptStatusEnum;
  AttemptStatusEnumComparisonExp: AttemptStatusEnumComparisonExp;
  AttemptStatusInsertInput: AttemptStatusInsertInput;
  AttemptStatusMaxFields: ResolverTypeWrapper<AttemptStatusMaxFields>;
  AttemptStatusMinFields: ResolverTypeWrapper<AttemptStatusMinFields>;
  AttemptStatusMutationResponse: ResolverTypeWrapper<AttemptStatusMutationResponse>;
  AttemptStatusOnConflict: AttemptStatusOnConflict;
  AttemptStatusOrderBy: AttemptStatusOrderBy;
  AttemptStatusPkColumnsInput: AttemptStatusPkColumnsInput;
  AttemptStatusSelectColumn: AttemptStatusSelectColumn;
  AttemptStatusSetInput: AttemptStatusSetInput;
  AttemptStatusStreamCursorInput: AttemptStatusStreamCursorInput;
  AttemptStatusStreamCursorValueInput: AttemptStatusStreamCursorValueInput;
  AttemptStatusUpdateColumn: AttemptStatusUpdateColumn;
  AttemptStatusUpdates: AttemptStatusUpdates;
  BigintComparisonExp: BigintComparisonExp;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  BooleanComparisonExp: BooleanComparisonExp;
  BpcharComparisonExp: BpcharComparisonExp;
  CatalogCourses: ResolverTypeWrapper<CatalogCourses>;
  CatalogCoursesAggregate: ResolverTypeWrapper<CatalogCoursesAggregate>;
  CatalogCoursesAggregateBoolExp: CatalogCoursesAggregateBoolExp;
  CatalogCoursesAggregateBoolExpBool_and: CatalogCoursesAggregateBoolExpBool_And;
  CatalogCoursesAggregateBoolExpBool_or: CatalogCoursesAggregateBoolExpBool_Or;
  CatalogCoursesAggregateBoolExpCount: CatalogCoursesAggregateBoolExpCount;
  CatalogCoursesAggregateFields: ResolverTypeWrapper<CatalogCoursesAggregateFields>;
  CatalogCoursesAggregateOrderBy: CatalogCoursesAggregateOrderBy;
  CatalogCoursesArrRelInsertInput: CatalogCoursesArrRelInsertInput;
  CatalogCoursesBoolExp: CatalogCoursesBoolExp;
  CatalogCoursesConstraint: CatalogCoursesConstraint;
  CatalogCoursesInsertInput: CatalogCoursesInsertInput;
  CatalogCoursesMaxFields: ResolverTypeWrapper<CatalogCoursesMaxFields>;
  CatalogCoursesMaxOrderBy: CatalogCoursesMaxOrderBy;
  CatalogCoursesMinFields: ResolverTypeWrapper<CatalogCoursesMinFields>;
  CatalogCoursesMinOrderBy: CatalogCoursesMinOrderBy;
  CatalogCoursesMutationResponse: ResolverTypeWrapper<CatalogCoursesMutationResponse>;
  CatalogCoursesOnConflict: CatalogCoursesOnConflict;
  CatalogCoursesOrderBy: CatalogCoursesOrderBy;
  CatalogCoursesPkColumnsInput: CatalogCoursesPkColumnsInput;
  CatalogCoursesSelectColumn: CatalogCoursesSelectColumn;
  CatalogCoursesSelectColumnCatalogCoursesAggregateBoolExpBool_andArgumentsColumns: CatalogCoursesSelectColumnCatalogCoursesAggregateBoolExpBool_AndArgumentsColumns;
  CatalogCoursesSelectColumnCatalogCoursesAggregateBoolExpBool_orArgumentsColumns: CatalogCoursesSelectColumnCatalogCoursesAggregateBoolExpBool_OrArgumentsColumns;
  CatalogCoursesSetInput: CatalogCoursesSetInput;
  CatalogCoursesStreamCursorInput: CatalogCoursesStreamCursorInput;
  CatalogCoursesStreamCursorValueInput: CatalogCoursesStreamCursorValueInput;
  CatalogCoursesUpdateColumn: CatalogCoursesUpdateColumn;
  CatalogCoursesUpdates: CatalogCoursesUpdates;
  Catalogs: ResolverTypeWrapper<Catalogs>;
  CatalogsAggregate: ResolverTypeWrapper<CatalogsAggregate>;
  CatalogsAggregateFields: ResolverTypeWrapper<CatalogsAggregateFields>;
  CatalogsBoolExp: CatalogsBoolExp;
  CatalogsConstraint: CatalogsConstraint;
  CatalogsInsertInput: CatalogsInsertInput;
  CatalogsJourneysRel: ResolverTypeWrapper<CatalogsJourneysRel>;
  CatalogsJourneysRelAggregate: ResolverTypeWrapper<CatalogsJourneysRelAggregate>;
  CatalogsJourneysRelAggregateBoolExp: CatalogsJourneysRelAggregateBoolExp;
  CatalogsJourneysRelAggregateFields: ResolverTypeWrapper<CatalogsJourneysRelAggregateFields>;
  CatalogsJourneysRelAggregateOrderBy: CatalogsJourneysRelAggregateOrderBy;
  CatalogsJourneysRelArrRelInsertInput: CatalogsJourneysRelArrRelInsertInput;
  CatalogsJourneysRelBoolExp: CatalogsJourneysRelBoolExp;
  CatalogsJourneysRelConstraint: CatalogsJourneysRelConstraint;
  CatalogsJourneysRelInsertInput: CatalogsJourneysRelInsertInput;
  CatalogsJourneysRelMaxFields: ResolverTypeWrapper<CatalogsJourneysRelMaxFields>;
  CatalogsJourneysRelMaxOrderBy: CatalogsJourneysRelMaxOrderBy;
  CatalogsJourneysRelMinFields: ResolverTypeWrapper<CatalogsJourneysRelMinFields>;
  CatalogsJourneysRelMinOrderBy: CatalogsJourneysRelMinOrderBy;
  CatalogsJourneysRelMutationResponse: ResolverTypeWrapper<CatalogsJourneysRelMutationResponse>;
  CatalogsJourneysRelOnConflict: CatalogsJourneysRelOnConflict;
  CatalogsJourneysRelOrderBy: CatalogsJourneysRelOrderBy;
  CatalogsJourneysRelPkColumnsInput: CatalogsJourneysRelPkColumnsInput;
  CatalogsJourneysRelSelectColumn: CatalogsJourneysRelSelectColumn;
  CatalogsJourneysRelSetInput: CatalogsJourneysRelSetInput;
  CatalogsJourneysRelStreamCursorInput: CatalogsJourneysRelStreamCursorInput;
  CatalogsJourneysRelStreamCursorValueInput: CatalogsJourneysRelStreamCursorValueInput;
  CatalogsJourneysRelUpdateColumn: CatalogsJourneysRelUpdateColumn;
  CatalogsJourneysRelUpdates: CatalogsJourneysRelUpdates;
  CatalogsMaxFields: ResolverTypeWrapper<CatalogsMaxFields>;
  CatalogsMinFields: ResolverTypeWrapper<CatalogsMinFields>;
  CatalogsMutationResponse: ResolverTypeWrapper<CatalogsMutationResponse>;
  CatalogsObjRelInsertInput: CatalogsObjRelInsertInput;
  CatalogsOnConflict: CatalogsOnConflict;
  CatalogsOrderBy: CatalogsOrderBy;
  CatalogsPkColumnsInput: CatalogsPkColumnsInput;
  CatalogsSelectColumn: CatalogsSelectColumn;
  CatalogsSetInput: CatalogsSetInput;
  CatalogsStreamCursorInput: CatalogsStreamCursorInput;
  CatalogsStreamCursorValueInput: CatalogsStreamCursorValueInput;
  CatalogsUpdateColumn: CatalogsUpdateColumn;
  CatalogsUpdates: CatalogsUpdates;
  CheckMyTeamCourseWaitlistOutput: ResolverTypeWrapper<CheckMyTeamCourseWaitlistOutput>;
  ClassCoaches: ResolverTypeWrapper<ClassCoaches>;
  ClassCoachesAggregate: ResolverTypeWrapper<ClassCoachesAggregate>;
  ClassCoachesAggregateBoolExp: ClassCoachesAggregateBoolExp;
  ClassCoachesAggregateBoolExpCount: ClassCoachesAggregateBoolExpCount;
  ClassCoachesAggregateFields: ResolverTypeWrapper<ClassCoachesAggregateFields>;
  ClassCoachesAggregateOrderBy: ClassCoachesAggregateOrderBy;
  ClassCoachesArrRelInsertInput: ClassCoachesArrRelInsertInput;
  ClassCoachesBoolExp: ClassCoachesBoolExp;
  ClassCoachesConstraint: ClassCoachesConstraint;
  ClassCoachesInsertInput: ClassCoachesInsertInput;
  ClassCoachesMaxFields: ResolverTypeWrapper<ClassCoachesMaxFields>;
  ClassCoachesMaxOrderBy: ClassCoachesMaxOrderBy;
  ClassCoachesMinFields: ResolverTypeWrapper<ClassCoachesMinFields>;
  ClassCoachesMinOrderBy: ClassCoachesMinOrderBy;
  ClassCoachesMutationResponse: ResolverTypeWrapper<ClassCoachesMutationResponse>;
  ClassCoachesOnConflict: ClassCoachesOnConflict;
  ClassCoachesOrderBy: ClassCoachesOrderBy;
  ClassCoachesPkColumnsInput: ClassCoachesPkColumnsInput;
  ClassCoachesSelectColumn: ClassCoachesSelectColumn;
  ClassCoachesSetInput: ClassCoachesSetInput;
  ClassCoachesStreamCursorInput: ClassCoachesStreamCursorInput;
  ClassCoachesStreamCursorValueInput: ClassCoachesStreamCursorValueInput;
  ClassCoachesUpdateColumn: ClassCoachesUpdateColumn;
  ClassCoachesUpdates: ClassCoachesUpdates;
  ClassEventActivity: ResolverTypeWrapper<ClassEventActivity>;
  ClassEventActivityAggregate: ResolverTypeWrapper<ClassEventActivityAggregate>;
  ClassEventActivityAggregateBoolExp: ClassEventActivityAggregateBoolExp;
  ClassEventActivityAggregateFields: ResolverTypeWrapper<ClassEventActivityAggregateFields>;
  ClassEventActivityAggregateOrderBy: ClassEventActivityAggregateOrderBy;
  ClassEventActivityAppendInput: ClassEventActivityAppendInput;
  ClassEventActivityArrRelInsertInput: ClassEventActivityArrRelInsertInput;
  ClassEventActivityBoolExp: ClassEventActivityBoolExp;
  ClassEventActivityConstraint: ClassEventActivityConstraint;
  ClassEventActivityDeleteAtPathInput: ClassEventActivityDeleteAtPathInput;
  ClassEventActivityDeleteElemInput: ClassEventActivityDeleteElemInput;
  ClassEventActivityDeleteKeyInput: ClassEventActivityDeleteKeyInput;
  ClassEventActivityInsertInput: ClassEventActivityInsertInput;
  ClassEventActivityMaxFields: ResolverTypeWrapper<ClassEventActivityMaxFields>;
  ClassEventActivityMaxOrderBy: ClassEventActivityMaxOrderBy;
  ClassEventActivityMinFields: ResolverTypeWrapper<ClassEventActivityMinFields>;
  ClassEventActivityMinOrderBy: ClassEventActivityMinOrderBy;
  ClassEventActivityMutationResponse: ResolverTypeWrapper<ClassEventActivityMutationResponse>;
  ClassEventActivityOnConflict: ClassEventActivityOnConflict;
  ClassEventActivityOrderBy: ClassEventActivityOrderBy;
  ClassEventActivityPkColumnsInput: ClassEventActivityPkColumnsInput;
  ClassEventActivityPrependInput: ClassEventActivityPrependInput;
  ClassEventActivitySelectColumn: ClassEventActivitySelectColumn;
  ClassEventActivitySetInput: ClassEventActivitySetInput;
  ClassEventActivityStreamCursorInput: ClassEventActivityStreamCursorInput;
  ClassEventActivityStreamCursorValueInput: ClassEventActivityStreamCursorValueInput;
  ClassEventActivityUpdateColumn: ClassEventActivityUpdateColumn;
  ClassEventActivityUpdates: ClassEventActivityUpdates;
  ClassEventAnonRegistrations: ResolverTypeWrapper<ClassEventAnonRegistrations>;
  ClassEventAnonRegistrationsAggregate: ResolverTypeWrapper<ClassEventAnonRegistrationsAggregate>;
  ClassEventAnonRegistrationsAggregateFields: ResolverTypeWrapper<ClassEventAnonRegistrationsAggregateFields>;
  ClassEventAnonRegistrationsBoolExp: ClassEventAnonRegistrationsBoolExp;
  ClassEventAnonRegistrationsConstraint: ClassEventAnonRegistrationsConstraint;
  ClassEventAnonRegistrationsInsertInput: ClassEventAnonRegistrationsInsertInput;
  ClassEventAnonRegistrationsMaxFields: ResolverTypeWrapper<ClassEventAnonRegistrationsMaxFields>;
  ClassEventAnonRegistrationsMinFields: ResolverTypeWrapper<ClassEventAnonRegistrationsMinFields>;
  ClassEventAnonRegistrationsMutationResponse: ResolverTypeWrapper<ClassEventAnonRegistrationsMutationResponse>;
  ClassEventAnonRegistrationsOnConflict: ClassEventAnonRegistrationsOnConflict;
  ClassEventAnonRegistrationsOrderBy: ClassEventAnonRegistrationsOrderBy;
  ClassEventAnonRegistrationsPkColumnsInput: ClassEventAnonRegistrationsPkColumnsInput;
  ClassEventAnonRegistrationsSelectColumn: ClassEventAnonRegistrationsSelectColumn;
  ClassEventAnonRegistrationsSetInput: ClassEventAnonRegistrationsSetInput;
  ClassEventAnonRegistrationsStreamCursorInput: ClassEventAnonRegistrationsStreamCursorInput;
  ClassEventAnonRegistrationsStreamCursorValueInput: ClassEventAnonRegistrationsStreamCursorValueInput;
  ClassEventAnonRegistrationsUpdateColumn: ClassEventAnonRegistrationsUpdateColumn;
  ClassEventAnonRegistrationsUpdates: ClassEventAnonRegistrationsUpdates;
  ClassEventInvitations: ResolverTypeWrapper<ClassEventInvitations>;
  ClassEventInvitationsAggregate: ResolverTypeWrapper<ClassEventInvitationsAggregate>;
  ClassEventInvitationsAggregateFields: ResolverTypeWrapper<ClassEventInvitationsAggregateFields>;
  ClassEventInvitationsAvgFields: ResolverTypeWrapper<ClassEventInvitationsAvgFields>;
  ClassEventInvitationsBoolExp: ClassEventInvitationsBoolExp;
  ClassEventInvitationsConstraint: ClassEventInvitationsConstraint;
  ClassEventInvitationsIncInput: ClassEventInvitationsIncInput;
  ClassEventInvitationsInsertInput: ClassEventInvitationsInsertInput;
  ClassEventInvitationsMaxFields: ResolverTypeWrapper<ClassEventInvitationsMaxFields>;
  ClassEventInvitationsMinFields: ResolverTypeWrapper<ClassEventInvitationsMinFields>;
  ClassEventInvitationsMutationResponse: ResolverTypeWrapper<ClassEventInvitationsMutationResponse>;
  ClassEventInvitationsOnConflict: ClassEventInvitationsOnConflict;
  ClassEventInvitationsOrderBy: ClassEventInvitationsOrderBy;
  ClassEventInvitationsPkColumnsInput: ClassEventInvitationsPkColumnsInput;
  ClassEventInvitationsSelectColumn: ClassEventInvitationsSelectColumn;
  ClassEventInvitationsSetInput: ClassEventInvitationsSetInput;
  ClassEventInvitationsStddevFields: ResolverTypeWrapper<ClassEventInvitationsStddevFields>;
  ClassEventInvitationsStddevPopFields: ResolverTypeWrapper<ClassEventInvitationsStddevPopFields>;
  ClassEventInvitationsStddevSampFields: ResolverTypeWrapper<ClassEventInvitationsStddevSampFields>;
  ClassEventInvitationsStreamCursorInput: ClassEventInvitationsStreamCursorInput;
  ClassEventInvitationsStreamCursorValueInput: ClassEventInvitationsStreamCursorValueInput;
  ClassEventInvitationsSumFields: ResolverTypeWrapper<ClassEventInvitationsSumFields>;
  ClassEventInvitationsUpdateColumn: ClassEventInvitationsUpdateColumn;
  ClassEventInvitationsUpdates: ClassEventInvitationsUpdates;
  ClassEventInvitationsVarPopFields: ResolverTypeWrapper<ClassEventInvitationsVarPopFields>;
  ClassEventInvitationsVarSampFields: ResolverTypeWrapper<ClassEventInvitationsVarSampFields>;
  ClassEventInvitationsVarianceFields: ResolverTypeWrapper<ClassEventInvitationsVarianceFields>;
  ClassEventMeetingParticipants: ResolverTypeWrapper<ClassEventMeetingParticipants>;
  ClassEventMeetingParticipantsAggregate: ResolverTypeWrapper<ClassEventMeetingParticipantsAggregate>;
  ClassEventMeetingParticipantsAggregateBoolExp: ClassEventMeetingParticipantsAggregateBoolExp;
  ClassEventMeetingParticipantsAggregateFields: ResolverTypeWrapper<ClassEventMeetingParticipantsAggregateFields>;
  ClassEventMeetingParticipantsAggregateOrderBy: ClassEventMeetingParticipantsAggregateOrderBy;
  ClassEventMeetingParticipantsArrRelInsertInput: ClassEventMeetingParticipantsArrRelInsertInput;
  ClassEventMeetingParticipantsAvgFields: ResolverTypeWrapper<ClassEventMeetingParticipantsAvgFields>;
  ClassEventMeetingParticipantsAvgOrderBy: ClassEventMeetingParticipantsAvgOrderBy;
  ClassEventMeetingParticipantsBoolExp: ClassEventMeetingParticipantsBoolExp;
  ClassEventMeetingParticipantsConstraint: ClassEventMeetingParticipantsConstraint;
  ClassEventMeetingParticipantsIncInput: ClassEventMeetingParticipantsIncInput;
  ClassEventMeetingParticipantsInsertInput: ClassEventMeetingParticipantsInsertInput;
  ClassEventMeetingParticipantsMaxFields: ResolverTypeWrapper<ClassEventMeetingParticipantsMaxFields>;
  ClassEventMeetingParticipantsMaxOrderBy: ClassEventMeetingParticipantsMaxOrderBy;
  ClassEventMeetingParticipantsMinFields: ResolverTypeWrapper<ClassEventMeetingParticipantsMinFields>;
  ClassEventMeetingParticipantsMinOrderBy: ClassEventMeetingParticipantsMinOrderBy;
  ClassEventMeetingParticipantsMutationResponse: ResolverTypeWrapper<ClassEventMeetingParticipantsMutationResponse>;
  ClassEventMeetingParticipantsObjRelInsertInput: ClassEventMeetingParticipantsObjRelInsertInput;
  ClassEventMeetingParticipantsOnConflict: ClassEventMeetingParticipantsOnConflict;
  ClassEventMeetingParticipantsOrderBy: ClassEventMeetingParticipantsOrderBy;
  ClassEventMeetingParticipantsPkColumnsInput: ClassEventMeetingParticipantsPkColumnsInput;
  ClassEventMeetingParticipantsSelectColumn: ClassEventMeetingParticipantsSelectColumn;
  ClassEventMeetingParticipantsSetInput: ClassEventMeetingParticipantsSetInput;
  ClassEventMeetingParticipantsStddevFields: ResolverTypeWrapper<ClassEventMeetingParticipantsStddevFields>;
  ClassEventMeetingParticipantsStddevOrderBy: ClassEventMeetingParticipantsStddevOrderBy;
  ClassEventMeetingParticipantsStddevPopFields: ResolverTypeWrapper<ClassEventMeetingParticipantsStddevPopFields>;
  ClassEventMeetingParticipantsStddevPopOrderBy: ClassEventMeetingParticipantsStddevPopOrderBy;
  ClassEventMeetingParticipantsStddevSampFields: ResolverTypeWrapper<ClassEventMeetingParticipantsStddevSampFields>;
  ClassEventMeetingParticipantsStddevSampOrderBy: ClassEventMeetingParticipantsStddevSampOrderBy;
  ClassEventMeetingParticipantsStreamCursorInput: ClassEventMeetingParticipantsStreamCursorInput;
  ClassEventMeetingParticipantsStreamCursorValueInput: ClassEventMeetingParticipantsStreamCursorValueInput;
  ClassEventMeetingParticipantsSumFields: ResolverTypeWrapper<ClassEventMeetingParticipantsSumFields>;
  ClassEventMeetingParticipantsSumOrderBy: ClassEventMeetingParticipantsSumOrderBy;
  ClassEventMeetingParticipantsUpdateColumn: ClassEventMeetingParticipantsUpdateColumn;
  ClassEventMeetingParticipantsUpdates: ClassEventMeetingParticipantsUpdates;
  ClassEventMeetingParticipantsVarPopFields: ResolverTypeWrapper<ClassEventMeetingParticipantsVarPopFields>;
  ClassEventMeetingParticipantsVarPopOrderBy: ClassEventMeetingParticipantsVarPopOrderBy;
  ClassEventMeetingParticipantsVarSampFields: ResolverTypeWrapper<ClassEventMeetingParticipantsVarSampFields>;
  ClassEventMeetingParticipantsVarSampOrderBy: ClassEventMeetingParticipantsVarSampOrderBy;
  ClassEventMeetingParticipantsVarianceFields: ResolverTypeWrapper<ClassEventMeetingParticipantsVarianceFields>;
  ClassEventMeetingParticipantsVarianceOrderBy: ClassEventMeetingParticipantsVarianceOrderBy;
  ClassEventMeetingSegments: ResolverTypeWrapper<ClassEventMeetingSegments>;
  ClassEventMeetingSegmentsAggregate: ResolverTypeWrapper<ClassEventMeetingSegmentsAggregate>;
  ClassEventMeetingSegmentsAggregateBoolExp: ClassEventMeetingSegmentsAggregateBoolExp;
  ClassEventMeetingSegmentsAggregateFields: ResolverTypeWrapper<ClassEventMeetingSegmentsAggregateFields>;
  ClassEventMeetingSegmentsAggregateOrderBy: ClassEventMeetingSegmentsAggregateOrderBy;
  ClassEventMeetingSegmentsAppendInput: ClassEventMeetingSegmentsAppendInput;
  ClassEventMeetingSegmentsArrRelInsertInput: ClassEventMeetingSegmentsArrRelInsertInput;
  ClassEventMeetingSegmentsAvgFields: ResolverTypeWrapper<ClassEventMeetingSegmentsAvgFields>;
  ClassEventMeetingSegmentsAvgOrderBy: ClassEventMeetingSegmentsAvgOrderBy;
  ClassEventMeetingSegmentsBoolExp: ClassEventMeetingSegmentsBoolExp;
  ClassEventMeetingSegmentsConstraint: ClassEventMeetingSegmentsConstraint;
  ClassEventMeetingSegmentsDeleteAtPathInput: ClassEventMeetingSegmentsDeleteAtPathInput;
  ClassEventMeetingSegmentsDeleteElemInput: ClassEventMeetingSegmentsDeleteElemInput;
  ClassEventMeetingSegmentsDeleteKeyInput: ClassEventMeetingSegmentsDeleteKeyInput;
  ClassEventMeetingSegmentsIncInput: ClassEventMeetingSegmentsIncInput;
  ClassEventMeetingSegmentsInsertInput: ClassEventMeetingSegmentsInsertInput;
  ClassEventMeetingSegmentsMaxFields: ResolverTypeWrapper<ClassEventMeetingSegmentsMaxFields>;
  ClassEventMeetingSegmentsMaxOrderBy: ClassEventMeetingSegmentsMaxOrderBy;
  ClassEventMeetingSegmentsMinFields: ResolverTypeWrapper<ClassEventMeetingSegmentsMinFields>;
  ClassEventMeetingSegmentsMinOrderBy: ClassEventMeetingSegmentsMinOrderBy;
  ClassEventMeetingSegmentsMutationResponse: ResolverTypeWrapper<ClassEventMeetingSegmentsMutationResponse>;
  ClassEventMeetingSegmentsObjRelInsertInput: ClassEventMeetingSegmentsObjRelInsertInput;
  ClassEventMeetingSegmentsOnConflict: ClassEventMeetingSegmentsOnConflict;
  ClassEventMeetingSegmentsOrderBy: ClassEventMeetingSegmentsOrderBy;
  ClassEventMeetingSegmentsPkColumnsInput: ClassEventMeetingSegmentsPkColumnsInput;
  ClassEventMeetingSegmentsPrependInput: ClassEventMeetingSegmentsPrependInput;
  ClassEventMeetingSegmentsSelectColumn: ClassEventMeetingSegmentsSelectColumn;
  ClassEventMeetingSegmentsSelectColumnClassEventMeetingSegmentsAggregateBoolExpBool_andArgumentsColumns: ClassEventMeetingSegmentsSelectColumnClassEventMeetingSegmentsAggregateBoolExpBool_AndArgumentsColumns;
  ClassEventMeetingSegmentsSelectColumnClassEventMeetingSegmentsAggregateBoolExpBool_orArgumentsColumns: ClassEventMeetingSegmentsSelectColumnClassEventMeetingSegmentsAggregateBoolExpBool_OrArgumentsColumns;
  ClassEventMeetingSegmentsSetInput: ClassEventMeetingSegmentsSetInput;
  ClassEventMeetingSegmentsStddevFields: ResolverTypeWrapper<ClassEventMeetingSegmentsStddevFields>;
  ClassEventMeetingSegmentsStddevOrderBy: ClassEventMeetingSegmentsStddevOrderBy;
  ClassEventMeetingSegmentsStddevPopFields: ResolverTypeWrapper<ClassEventMeetingSegmentsStddevPopFields>;
  ClassEventMeetingSegmentsStddevPopOrderBy: ClassEventMeetingSegmentsStddevPopOrderBy;
  ClassEventMeetingSegmentsStddevSampFields: ResolverTypeWrapper<ClassEventMeetingSegmentsStddevSampFields>;
  ClassEventMeetingSegmentsStddevSampOrderBy: ClassEventMeetingSegmentsStddevSampOrderBy;
  ClassEventMeetingSegmentsStreamCursorInput: ClassEventMeetingSegmentsStreamCursorInput;
  ClassEventMeetingSegmentsStreamCursorValueInput: ClassEventMeetingSegmentsStreamCursorValueInput;
  ClassEventMeetingSegmentsSumFields: ResolverTypeWrapper<ClassEventMeetingSegmentsSumFields>;
  ClassEventMeetingSegmentsSumOrderBy: ClassEventMeetingSegmentsSumOrderBy;
  ClassEventMeetingSegmentsUpdateColumn: ClassEventMeetingSegmentsUpdateColumn;
  ClassEventMeetingSegmentsUpdates: ClassEventMeetingSegmentsUpdates;
  ClassEventMeetingSegmentsVarPopFields: ResolverTypeWrapper<ClassEventMeetingSegmentsVarPopFields>;
  ClassEventMeetingSegmentsVarPopOrderBy: ClassEventMeetingSegmentsVarPopOrderBy;
  ClassEventMeetingSegmentsVarSampFields: ResolverTypeWrapper<ClassEventMeetingSegmentsVarSampFields>;
  ClassEventMeetingSegmentsVarSampOrderBy: ClassEventMeetingSegmentsVarSampOrderBy;
  ClassEventMeetingSegmentsVarianceFields: ResolverTypeWrapper<ClassEventMeetingSegmentsVarianceFields>;
  ClassEventMeetingSegmentsVarianceOrderBy: ClassEventMeetingSegmentsVarianceOrderBy;
  ClassEventMeetings: ResolverTypeWrapper<ClassEventMeetings>;
  ClassEventMeetingsAggregate: ResolverTypeWrapper<ClassEventMeetingsAggregate>;
  ClassEventMeetingsAggregateBoolExp: ClassEventMeetingsAggregateBoolExp;
  ClassEventMeetingsAggregateFields: ResolverTypeWrapper<ClassEventMeetingsAggregateFields>;
  ClassEventMeetingsAggregateOrderBy: ClassEventMeetingsAggregateOrderBy;
  ClassEventMeetingsArrRelInsertInput: ClassEventMeetingsArrRelInsertInput;
  ClassEventMeetingsAvgFields: ResolverTypeWrapper<ClassEventMeetingsAvgFields>;
  ClassEventMeetingsAvgOrderBy: ClassEventMeetingsAvgOrderBy;
  ClassEventMeetingsBoolExp: ClassEventMeetingsBoolExp;
  ClassEventMeetingsConstraint: ClassEventMeetingsConstraint;
  ClassEventMeetingsIncInput: ClassEventMeetingsIncInput;
  ClassEventMeetingsInsertInput: ClassEventMeetingsInsertInput;
  ClassEventMeetingsMaxFields: ResolverTypeWrapper<ClassEventMeetingsMaxFields>;
  ClassEventMeetingsMaxOrderBy: ClassEventMeetingsMaxOrderBy;
  ClassEventMeetingsMinFields: ResolverTypeWrapper<ClassEventMeetingsMinFields>;
  ClassEventMeetingsMinOrderBy: ClassEventMeetingsMinOrderBy;
  ClassEventMeetingsMutationResponse: ResolverTypeWrapper<ClassEventMeetingsMutationResponse>;
  ClassEventMeetingsObjRelInsertInput: ClassEventMeetingsObjRelInsertInput;
  ClassEventMeetingsOnConflict: ClassEventMeetingsOnConflict;
  ClassEventMeetingsOrderBy: ClassEventMeetingsOrderBy;
  ClassEventMeetingsPkColumnsInput: ClassEventMeetingsPkColumnsInput;
  ClassEventMeetingsSelectColumn: ClassEventMeetingsSelectColumn;
  ClassEventMeetingsSelectColumnClassEventMeetingsAggregateBoolExpBool_andArgumentsColumns: ClassEventMeetingsSelectColumnClassEventMeetingsAggregateBoolExpBool_AndArgumentsColumns;
  ClassEventMeetingsSelectColumnClassEventMeetingsAggregateBoolExpBool_orArgumentsColumns: ClassEventMeetingsSelectColumnClassEventMeetingsAggregateBoolExpBool_OrArgumentsColumns;
  ClassEventMeetingsSetInput: ClassEventMeetingsSetInput;
  ClassEventMeetingsStddevFields: ResolverTypeWrapper<ClassEventMeetingsStddevFields>;
  ClassEventMeetingsStddevOrderBy: ClassEventMeetingsStddevOrderBy;
  ClassEventMeetingsStddevPopFields: ResolverTypeWrapper<ClassEventMeetingsStddevPopFields>;
  ClassEventMeetingsStddevPopOrderBy: ClassEventMeetingsStddevPopOrderBy;
  ClassEventMeetingsStddevSampFields: ResolverTypeWrapper<ClassEventMeetingsStddevSampFields>;
  ClassEventMeetingsStddevSampOrderBy: ClassEventMeetingsStddevSampOrderBy;
  ClassEventMeetingsStreamCursorInput: ClassEventMeetingsStreamCursorInput;
  ClassEventMeetingsStreamCursorValueInput: ClassEventMeetingsStreamCursorValueInput;
  ClassEventMeetingsSumFields: ResolverTypeWrapper<ClassEventMeetingsSumFields>;
  ClassEventMeetingsSumOrderBy: ClassEventMeetingsSumOrderBy;
  ClassEventMeetingsUpdateColumn: ClassEventMeetingsUpdateColumn;
  ClassEventMeetingsUpdates: ClassEventMeetingsUpdates;
  ClassEventMeetingsVarPopFields: ResolverTypeWrapper<ClassEventMeetingsVarPopFields>;
  ClassEventMeetingsVarPopOrderBy: ClassEventMeetingsVarPopOrderBy;
  ClassEventMeetingsVarSampFields: ResolverTypeWrapper<ClassEventMeetingsVarSampFields>;
  ClassEventMeetingsVarSampOrderBy: ClassEventMeetingsVarSampOrderBy;
  ClassEventMeetingsVarianceFields: ResolverTypeWrapper<ClassEventMeetingsVarianceFields>;
  ClassEventMeetingsVarianceOrderBy: ClassEventMeetingsVarianceOrderBy;
  ClassEventRegistrations: ResolverTypeWrapper<ClassEventRegistrations>;
  ClassEventRegistrationsAggregate: ResolverTypeWrapper<ClassEventRegistrationsAggregate>;
  ClassEventRegistrationsAggregateFields: ResolverTypeWrapper<ClassEventRegistrationsAggregateFields>;
  ClassEventRegistrationsBoolExp: ClassEventRegistrationsBoolExp;
  ClassEventRegistrationsConstraint: ClassEventRegistrationsConstraint;
  ClassEventRegistrationsInsertInput: ClassEventRegistrationsInsertInput;
  ClassEventRegistrationsMaxFields: ResolverTypeWrapper<ClassEventRegistrationsMaxFields>;
  ClassEventRegistrationsMinFields: ResolverTypeWrapper<ClassEventRegistrationsMinFields>;
  ClassEventRegistrationsMutationResponse: ResolverTypeWrapper<ClassEventRegistrationsMutationResponse>;
  ClassEventRegistrationsOnConflict: ClassEventRegistrationsOnConflict;
  ClassEventRegistrationsOrderBy: ClassEventRegistrationsOrderBy;
  ClassEventRegistrationsPkColumnsInput: ClassEventRegistrationsPkColumnsInput;
  ClassEventRegistrationsSelectColumn: ClassEventRegistrationsSelectColumn;
  ClassEventRegistrationsSetInput: ClassEventRegistrationsSetInput;
  ClassEventRegistrationsStreamCursorInput: ClassEventRegistrationsStreamCursorInput;
  ClassEventRegistrationsStreamCursorValueInput: ClassEventRegistrationsStreamCursorValueInput;
  ClassEventRegistrationsUpdateColumn: ClassEventRegistrationsUpdateColumn;
  ClassEventRegistrationsUpdates: ClassEventRegistrationsUpdates;
  ClassEvents: ResolverTypeWrapper<ClassEvents>;
  ClassEventsAggregate: ResolverTypeWrapper<ClassEventsAggregate>;
  ClassEventsAggregateBoolExp: ClassEventsAggregateBoolExp;
  ClassEventsAggregateFields: ResolverTypeWrapper<ClassEventsAggregateFields>;
  ClassEventsAggregateOrderBy: ClassEventsAggregateOrderBy;
  ClassEventsArrRelInsertInput: ClassEventsArrRelInsertInput;
  ClassEventsAvgFields: ResolverTypeWrapper<ClassEventsAvgFields>;
  ClassEventsAvgOrderBy: ClassEventsAvgOrderBy;
  ClassEventsBoolExp: ClassEventsBoolExp;
  ClassEventsConstraint: ClassEventsConstraint;
  ClassEventsIncInput: ClassEventsIncInput;
  ClassEventsInsertInput: ClassEventsInsertInput;
  ClassEventsMaxFields: ResolverTypeWrapper<ClassEventsMaxFields>;
  ClassEventsMaxOrderBy: ClassEventsMaxOrderBy;
  ClassEventsMinFields: ResolverTypeWrapper<ClassEventsMinFields>;
  ClassEventsMinOrderBy: ClassEventsMinOrderBy;
  ClassEventsMutationResponse: ResolverTypeWrapper<ClassEventsMutationResponse>;
  ClassEventsObjRelInsertInput: ClassEventsObjRelInsertInput;
  ClassEventsOnConflict: ClassEventsOnConflict;
  ClassEventsOrderBy: ClassEventsOrderBy;
  ClassEventsPkColumnsInput: ClassEventsPkColumnsInput;
  ClassEventsSelectColumn: ClassEventsSelectColumn;
  ClassEventsSetInput: ClassEventsSetInput;
  ClassEventsStddevFields: ResolverTypeWrapper<ClassEventsStddevFields>;
  ClassEventsStddevOrderBy: ClassEventsStddevOrderBy;
  ClassEventsStddevPopFields: ResolverTypeWrapper<ClassEventsStddevPopFields>;
  ClassEventsStddevPopOrderBy: ClassEventsStddevPopOrderBy;
  ClassEventsStddevSampFields: ResolverTypeWrapper<ClassEventsStddevSampFields>;
  ClassEventsStddevSampOrderBy: ClassEventsStddevSampOrderBy;
  ClassEventsStreamCursorInput: ClassEventsStreamCursorInput;
  ClassEventsStreamCursorValueInput: ClassEventsStreamCursorValueInput;
  ClassEventsSumFields: ResolverTypeWrapper<ClassEventsSumFields>;
  ClassEventsSumOrderBy: ClassEventsSumOrderBy;
  ClassEventsUpdateColumn: ClassEventsUpdateColumn;
  ClassEventsUpdates: ClassEventsUpdates;
  ClassEventsVarPopFields: ResolverTypeWrapper<ClassEventsVarPopFields>;
  ClassEventsVarPopOrderBy: ClassEventsVarPopOrderBy;
  ClassEventsVarSampFields: ResolverTypeWrapper<ClassEventsVarSampFields>;
  ClassEventsVarSampOrderBy: ClassEventsVarSampOrderBy;
  ClassEventsVarianceFields: ResolverTypeWrapper<ClassEventsVarianceFields>;
  ClassEventsVarianceOrderBy: ClassEventsVarianceOrderBy;
  ClassPlaybackActivity: ResolverTypeWrapper<ClassPlaybackActivity>;
  ClassPlaybackActivityAggregate: ResolverTypeWrapper<ClassPlaybackActivityAggregate>;
  ClassPlaybackActivityAggregateFields: ResolverTypeWrapper<ClassPlaybackActivityAggregateFields>;
  ClassPlaybackActivityAppendInput: ClassPlaybackActivityAppendInput;
  ClassPlaybackActivityBoolExp: ClassPlaybackActivityBoolExp;
  ClassPlaybackActivityConstraint: ClassPlaybackActivityConstraint;
  ClassPlaybackActivityDeleteAtPathInput: ClassPlaybackActivityDeleteAtPathInput;
  ClassPlaybackActivityDeleteElemInput: ClassPlaybackActivityDeleteElemInput;
  ClassPlaybackActivityDeleteKeyInput: ClassPlaybackActivityDeleteKeyInput;
  ClassPlaybackActivityInsertInput: ClassPlaybackActivityInsertInput;
  ClassPlaybackActivityMaxFields: ResolverTypeWrapper<ClassPlaybackActivityMaxFields>;
  ClassPlaybackActivityMinFields: ResolverTypeWrapper<ClassPlaybackActivityMinFields>;
  ClassPlaybackActivityMutationResponse: ResolverTypeWrapper<ClassPlaybackActivityMutationResponse>;
  ClassPlaybackActivityOnConflict: ClassPlaybackActivityOnConflict;
  ClassPlaybackActivityOrderBy: ClassPlaybackActivityOrderBy;
  ClassPlaybackActivityPkColumnsInput: ClassPlaybackActivityPkColumnsInput;
  ClassPlaybackActivityPrependInput: ClassPlaybackActivityPrependInput;
  ClassPlaybackActivitySelectColumn: ClassPlaybackActivitySelectColumn;
  ClassPlaybackActivitySetInput: ClassPlaybackActivitySetInput;
  ClassPlaybackActivityStreamCursorInput: ClassPlaybackActivityStreamCursorInput;
  ClassPlaybackActivityStreamCursorValueInput: ClassPlaybackActivityStreamCursorValueInput;
  ClassPlaybackActivityType: ResolverTypeWrapper<ClassPlaybackActivityType>;
  ClassPlaybackActivityTypeAggregate: ResolverTypeWrapper<ClassPlaybackActivityTypeAggregate>;
  ClassPlaybackActivityTypeAggregateFields: ResolverTypeWrapper<ClassPlaybackActivityTypeAggregateFields>;
  ClassPlaybackActivityTypeBoolExp: ClassPlaybackActivityTypeBoolExp;
  ClassPlaybackActivityTypeConstraint: ClassPlaybackActivityTypeConstraint;
  ClassPlaybackActivityTypeEnum: ClassPlaybackActivityTypeEnum;
  ClassPlaybackActivityTypeEnumComparisonExp: ClassPlaybackActivityTypeEnumComparisonExp;
  ClassPlaybackActivityTypeInsertInput: ClassPlaybackActivityTypeInsertInput;
  ClassPlaybackActivityTypeMaxFields: ResolverTypeWrapper<ClassPlaybackActivityTypeMaxFields>;
  ClassPlaybackActivityTypeMinFields: ResolverTypeWrapper<ClassPlaybackActivityTypeMinFields>;
  ClassPlaybackActivityTypeMutationResponse: ResolverTypeWrapper<ClassPlaybackActivityTypeMutationResponse>;
  ClassPlaybackActivityTypeOnConflict: ClassPlaybackActivityTypeOnConflict;
  ClassPlaybackActivityTypeOrderBy: ClassPlaybackActivityTypeOrderBy;
  ClassPlaybackActivityTypePkColumnsInput: ClassPlaybackActivityTypePkColumnsInput;
  ClassPlaybackActivityTypeSelectColumn: ClassPlaybackActivityTypeSelectColumn;
  ClassPlaybackActivityTypeSetInput: ClassPlaybackActivityTypeSetInput;
  ClassPlaybackActivityTypeStreamCursorInput: ClassPlaybackActivityTypeStreamCursorInput;
  ClassPlaybackActivityTypeStreamCursorValueInput: ClassPlaybackActivityTypeStreamCursorValueInput;
  ClassPlaybackActivityTypeUpdateColumn: ClassPlaybackActivityTypeUpdateColumn;
  ClassPlaybackActivityTypeUpdates: ClassPlaybackActivityTypeUpdates;
  ClassPlaybackActivityUpdateColumn: ClassPlaybackActivityUpdateColumn;
  ClassPlaybackActivityUpdates: ClassPlaybackActivityUpdates;
  ClassResources: ResolverTypeWrapper<ClassResources>;
  ClassResourcesAggregate: ResolverTypeWrapper<ClassResourcesAggregate>;
  ClassResourcesAggregateBoolExp: ClassResourcesAggregateBoolExp;
  ClassResourcesAggregateBoolExpCount: ClassResourcesAggregateBoolExpCount;
  ClassResourcesAggregateFields: ResolverTypeWrapper<ClassResourcesAggregateFields>;
  ClassResourcesAggregateOrderBy: ClassResourcesAggregateOrderBy;
  ClassResourcesArrRelInsertInput: ClassResourcesArrRelInsertInput;
  ClassResourcesBoolExp: ClassResourcesBoolExp;
  ClassResourcesConstraint: ClassResourcesConstraint;
  ClassResourcesInsertInput: ClassResourcesInsertInput;
  ClassResourcesMaxFields: ResolverTypeWrapper<ClassResourcesMaxFields>;
  ClassResourcesMaxOrderBy: ClassResourcesMaxOrderBy;
  ClassResourcesMinFields: ResolverTypeWrapper<ClassResourcesMinFields>;
  ClassResourcesMinOrderBy: ClassResourcesMinOrderBy;
  ClassResourcesMutationResponse: ResolverTypeWrapper<ClassResourcesMutationResponse>;
  ClassResourcesObjRelInsertInput: ClassResourcesObjRelInsertInput;
  ClassResourcesOnConflict: ClassResourcesOnConflict;
  ClassResourcesOrderBy: ClassResourcesOrderBy;
  ClassResourcesPkColumnsInput: ClassResourcesPkColumnsInput;
  ClassResourcesSelectColumn: ClassResourcesSelectColumn;
  ClassResourcesSetInput: ClassResourcesSetInput;
  ClassResourcesStreamCursorInput: ClassResourcesStreamCursorInput;
  ClassResourcesStreamCursorValueInput: ClassResourcesStreamCursorValueInput;
  ClassResourcesUpdateColumn: ClassResourcesUpdateColumn;
  ClassResourcesUpdates: ClassResourcesUpdates;
  Classes: ResolverTypeWrapper<Classes>;
  ClassesAggregate: ResolverTypeWrapper<ClassesAggregate>;
  ClassesAggregateBoolExp: ClassesAggregateBoolExp;
  ClassesAggregateFields: ResolverTypeWrapper<ClassesAggregateFields>;
  ClassesAggregateOrderBy: ClassesAggregateOrderBy;
  ClassesArrRelInsertInput: ClassesArrRelInsertInput;
  ClassesAvgFields: ResolverTypeWrapper<ClassesAvgFields>;
  ClassesAvgOrderBy: ClassesAvgOrderBy;
  ClassesBoolExp: ClassesBoolExp;
  ClassesConstraint: ClassesConstraint;
  ClassesIncInput: ClassesIncInput;
  ClassesInsertInput: ClassesInsertInput;
  ClassesMaxFields: ResolverTypeWrapper<ClassesMaxFields>;
  ClassesMaxOrderBy: ClassesMaxOrderBy;
  ClassesMinFields: ResolverTypeWrapper<ClassesMinFields>;
  ClassesMinOrderBy: ClassesMinOrderBy;
  ClassesMutationResponse: ResolverTypeWrapper<ClassesMutationResponse>;
  ClassesObjRelInsertInput: ClassesObjRelInsertInput;
  ClassesOnConflict: ClassesOnConflict;
  ClassesOrderBy: ClassesOrderBy;
  ClassesPkColumnsInput: ClassesPkColumnsInput;
  ClassesSelectColumn: ClassesSelectColumn;
  ClassesSelectColumnClassesAggregateBoolExpBool_andArgumentsColumns: ClassesSelectColumnClassesAggregateBoolExpBool_AndArgumentsColumns;
  ClassesSelectColumnClassesAggregateBoolExpBool_orArgumentsColumns: ClassesSelectColumnClassesAggregateBoolExpBool_OrArgumentsColumns;
  ClassesSetInput: ClassesSetInput;
  ClassesStddevFields: ResolverTypeWrapper<ClassesStddevFields>;
  ClassesStddevOrderBy: ClassesStddevOrderBy;
  ClassesStddevPopFields: ResolverTypeWrapper<ClassesStddevPopFields>;
  ClassesStddevPopOrderBy: ClassesStddevPopOrderBy;
  ClassesStddevSampFields: ResolverTypeWrapper<ClassesStddevSampFields>;
  ClassesStddevSampOrderBy: ClassesStddevSampOrderBy;
  ClassesStreamCursorInput: ClassesStreamCursorInput;
  ClassesStreamCursorValueInput: ClassesStreamCursorValueInput;
  ClassesSumFields: ResolverTypeWrapper<ClassesSumFields>;
  ClassesSumOrderBy: ClassesSumOrderBy;
  ClassesTagsRel: ResolverTypeWrapper<ClassesTagsRel>;
  ClassesTagsRelAggregate: ResolverTypeWrapper<ClassesTagsRelAggregate>;
  ClassesTagsRelAggregateBoolExp: ClassesTagsRelAggregateBoolExp;
  ClassesTagsRelAggregateFields: ResolverTypeWrapper<ClassesTagsRelAggregateFields>;
  ClassesTagsRelAggregateOrderBy: ClassesTagsRelAggregateOrderBy;
  ClassesTagsRelArrRelInsertInput: ClassesTagsRelArrRelInsertInput;
  ClassesTagsRelBoolExp: ClassesTagsRelBoolExp;
  ClassesTagsRelConstraint: ClassesTagsRelConstraint;
  ClassesTagsRelInsertInput: ClassesTagsRelInsertInput;
  ClassesTagsRelMaxFields: ResolverTypeWrapper<ClassesTagsRelMaxFields>;
  ClassesTagsRelMaxOrderBy: ClassesTagsRelMaxOrderBy;
  ClassesTagsRelMinFields: ResolverTypeWrapper<ClassesTagsRelMinFields>;
  ClassesTagsRelMinOrderBy: ClassesTagsRelMinOrderBy;
  ClassesTagsRelMutationResponse: ResolverTypeWrapper<ClassesTagsRelMutationResponse>;
  ClassesTagsRelOnConflict: ClassesTagsRelOnConflict;
  ClassesTagsRelOrderBy: ClassesTagsRelOrderBy;
  ClassesTagsRelPkColumnsInput: ClassesTagsRelPkColumnsInput;
  ClassesTagsRelSelectColumn: ClassesTagsRelSelectColumn;
  ClassesTagsRelSetInput: ClassesTagsRelSetInput;
  ClassesTagsRelStreamCursorInput: ClassesTagsRelStreamCursorInput;
  ClassesTagsRelStreamCursorValueInput: ClassesTagsRelStreamCursorValueInput;
  ClassesTagsRelUpdateColumn: ClassesTagsRelUpdateColumn;
  ClassesTagsRelUpdates: ClassesTagsRelUpdates;
  ClassesUpdateColumn: ClassesUpdateColumn;
  ClassesUpdates: ClassesUpdates;
  ClassesVarPopFields: ResolverTypeWrapper<ClassesVarPopFields>;
  ClassesVarPopOrderBy: ClassesVarPopOrderBy;
  ClassesVarSampFields: ResolverTypeWrapper<ClassesVarSampFields>;
  ClassesVarSampOrderBy: ClassesVarSampOrderBy;
  ClassesVarianceFields: ResolverTypeWrapper<ClassesVarianceFields>;
  ClassesVarianceOrderBy: ClassesVarianceOrderBy;
  Coaches: ResolverTypeWrapper<Coaches>;
  CoachesAggregate: ResolverTypeWrapper<CoachesAggregate>;
  CoachesAggregateBoolExp: CoachesAggregateBoolExp;
  CoachesAggregateFields: ResolverTypeWrapper<CoachesAggregateFields>;
  CoachesAggregateOrderBy: CoachesAggregateOrderBy;
  CoachesArrRelInsertInput: CoachesArrRelInsertInput;
  CoachesBoolExp: CoachesBoolExp;
  CoachesConstraint: CoachesConstraint;
  CoachesInsertInput: CoachesInsertInput;
  CoachesMaxFields: ResolverTypeWrapper<CoachesMaxFields>;
  CoachesMaxOrderBy: CoachesMaxOrderBy;
  CoachesMinFields: ResolverTypeWrapper<CoachesMinFields>;
  CoachesMinOrderBy: CoachesMinOrderBy;
  CoachesMutationResponse: ResolverTypeWrapper<CoachesMutationResponse>;
  CoachesObjRelInsertInput: CoachesObjRelInsertInput;
  CoachesOnConflict: CoachesOnConflict;
  CoachesOrderBy: CoachesOrderBy;
  CoachesPkColumnsInput: CoachesPkColumnsInput;
  CoachesSelectColumn: CoachesSelectColumn;
  CoachesSetInput: CoachesSetInput;
  CoachesStreamCursorInput: CoachesStreamCursorInput;
  CoachesStreamCursorValueInput: CoachesStreamCursorValueInput;
  CoachesUpdateColumn: CoachesUpdateColumn;
  CoachesUpdates: CoachesUpdates;
  CohortCoaches: ResolverTypeWrapper<CohortCoaches>;
  CohortCoachesAggregate: ResolverTypeWrapper<CohortCoachesAggregate>;
  CohortCoachesAggregateBoolExp: CohortCoachesAggregateBoolExp;
  CohortCoachesAggregateBoolExpBool_and: CohortCoachesAggregateBoolExpBool_And;
  CohortCoachesAggregateBoolExpBool_or: CohortCoachesAggregateBoolExpBool_Or;
  CohortCoachesAggregateBoolExpCount: CohortCoachesAggregateBoolExpCount;
  CohortCoachesAggregateFields: ResolverTypeWrapper<CohortCoachesAggregateFields>;
  CohortCoachesAggregateOrderBy: CohortCoachesAggregateOrderBy;
  CohortCoachesArrRelInsertInput: CohortCoachesArrRelInsertInput;
  CohortCoachesBoolExp: CohortCoachesBoolExp;
  CohortCoachesConstraint: CohortCoachesConstraint;
  CohortCoachesInsertInput: CohortCoachesInsertInput;
  CohortCoachesMaxFields: ResolverTypeWrapper<CohortCoachesMaxFields>;
  CohortCoachesMaxOrderBy: CohortCoachesMaxOrderBy;
  CohortCoachesMinFields: ResolverTypeWrapper<CohortCoachesMinFields>;
  CohortCoachesMinOrderBy: CohortCoachesMinOrderBy;
  CohortCoachesMutationResponse: ResolverTypeWrapper<CohortCoachesMutationResponse>;
  CohortCoachesOnConflict: CohortCoachesOnConflict;
  CohortCoachesOrderBy: CohortCoachesOrderBy;
  CohortCoachesPkColumnsInput: CohortCoachesPkColumnsInput;
  CohortCoachesSelectColumn: CohortCoachesSelectColumn;
  CohortCoachesSelectColumnCohortCoachesAggregateBoolExpBool_andArgumentsColumns: CohortCoachesSelectColumnCohortCoachesAggregateBoolExpBool_AndArgumentsColumns;
  CohortCoachesSelectColumnCohortCoachesAggregateBoolExpBool_orArgumentsColumns: CohortCoachesSelectColumnCohortCoachesAggregateBoolExpBool_OrArgumentsColumns;
  CohortCoachesSetInput: CohortCoachesSetInput;
  CohortCoachesStreamCursorInput: CohortCoachesStreamCursorInput;
  CohortCoachesStreamCursorValueInput: CohortCoachesStreamCursorValueInput;
  CohortCoachesUpdateColumn: CohortCoachesUpdateColumn;
  CohortCoachesUpdates: CohortCoachesUpdates;
  CohortProgress: ResolverTypeWrapper<CohortProgress>;
  CohortProgressAggregate: ResolverTypeWrapper<CohortProgressAggregate>;
  CohortProgressAggregateFields: ResolverTypeWrapper<CohortProgressAggregateFields>;
  CohortProgressAvgFields: ResolverTypeWrapper<CohortProgressAvgFields>;
  CohortProgressBoolExp: CohortProgressBoolExp;
  CohortProgressMaxFields: ResolverTypeWrapper<CohortProgressMaxFields>;
  CohortProgressMinFields: ResolverTypeWrapper<CohortProgressMinFields>;
  CohortProgressOrderBy: CohortProgressOrderBy;
  CohortProgressSelectColumn: CohortProgressSelectColumn;
  CohortProgressStddevFields: ResolverTypeWrapper<CohortProgressStddevFields>;
  CohortProgressStddevPopFields: ResolverTypeWrapper<CohortProgressStddevPopFields>;
  CohortProgressStddevSampFields: ResolverTypeWrapper<CohortProgressStddevSampFields>;
  CohortProgressStreamCursorInput: CohortProgressStreamCursorInput;
  CohortProgressStreamCursorValueInput: CohortProgressStreamCursorValueInput;
  CohortProgressSumFields: ResolverTypeWrapper<CohortProgressSumFields>;
  CohortProgressVarPopFields: ResolverTypeWrapper<CohortProgressVarPopFields>;
  CohortProgressVarSampFields: ResolverTypeWrapper<CohortProgressVarSampFields>;
  CohortProgressVarianceFields: ResolverTypeWrapper<CohortProgressVarianceFields>;
  CohortType: ResolverTypeWrapper<CohortType>;
  CohortTypeAggregate: ResolverTypeWrapper<CohortTypeAggregate>;
  CohortTypeAggregateFields: ResolverTypeWrapper<CohortTypeAggregateFields>;
  CohortTypeBoolExp: CohortTypeBoolExp;
  CohortTypeConstraint: CohortTypeConstraint;
  CohortTypeEnum: CohortTypeEnum;
  CohortTypeEnumComparisonExp: CohortTypeEnumComparisonExp;
  CohortTypeInsertInput: CohortTypeInsertInput;
  CohortTypeMaxFields: ResolverTypeWrapper<CohortTypeMaxFields>;
  CohortTypeMinFields: ResolverTypeWrapper<CohortTypeMinFields>;
  CohortTypeMutationResponse: ResolverTypeWrapper<CohortTypeMutationResponse>;
  CohortTypeOnConflict: CohortTypeOnConflict;
  CohortTypeOrderBy: CohortTypeOrderBy;
  CohortTypePkColumnsInput: CohortTypePkColumnsInput;
  CohortTypeSelectColumn: CohortTypeSelectColumn;
  CohortTypeSetInput: CohortTypeSetInput;
  CohortTypeStreamCursorInput: CohortTypeStreamCursorInput;
  CohortTypeStreamCursorValueInput: CohortTypeStreamCursorValueInput;
  CohortTypeUpdateColumn: CohortTypeUpdateColumn;
  CohortTypeUpdates: CohortTypeUpdates;
  CohortVisibilityEnum: ResolverTypeWrapper<CohortVisibilityEnum>;
  CohortVisibilityEnumAggregate: ResolverTypeWrapper<CohortVisibilityEnumAggregate>;
  CohortVisibilityEnumAggregateFields: ResolverTypeWrapper<CohortVisibilityEnumAggregateFields>;
  CohortVisibilityEnumBoolExp: CohortVisibilityEnumBoolExp;
  CohortVisibilityEnumConstraint: CohortVisibilityEnumConstraint;
  CohortVisibilityEnumEnum: CohortVisibilityEnumEnum;
  CohortVisibilityEnumEnumComparisonExp: CohortVisibilityEnumEnumComparisonExp;
  CohortVisibilityEnumInsertInput: CohortVisibilityEnumInsertInput;
  CohortVisibilityEnumMaxFields: ResolverTypeWrapper<CohortVisibilityEnumMaxFields>;
  CohortVisibilityEnumMinFields: ResolverTypeWrapper<CohortVisibilityEnumMinFields>;
  CohortVisibilityEnumMutationResponse: ResolverTypeWrapper<CohortVisibilityEnumMutationResponse>;
  CohortVisibilityEnumOnConflict: CohortVisibilityEnumOnConflict;
  CohortVisibilityEnumOrderBy: CohortVisibilityEnumOrderBy;
  CohortVisibilityEnumPkColumnsInput: CohortVisibilityEnumPkColumnsInput;
  CohortVisibilityEnumSelectColumn: CohortVisibilityEnumSelectColumn;
  CohortVisibilityEnumSetInput: CohortVisibilityEnumSetInput;
  CohortVisibilityEnumStreamCursorInput: CohortVisibilityEnumStreamCursorInput;
  CohortVisibilityEnumStreamCursorValueInput: CohortVisibilityEnumStreamCursorValueInput;
  CohortVisibilityEnumUpdateColumn: CohortVisibilityEnumUpdateColumn;
  CohortVisibilityEnumUpdates: CohortVisibilityEnumUpdates;
  Cohorts: ResolverTypeWrapper<Cohorts>;
  CohortsAggregate: ResolverTypeWrapper<CohortsAggregate>;
  CohortsAggregateBoolExp: CohortsAggregateBoolExp;
  CohortsAggregateFields: ResolverTypeWrapper<CohortsAggregateFields>;
  CohortsAggregateOrderBy: CohortsAggregateOrderBy;
  CohortsArrRelInsertInput: CohortsArrRelInsertInput;
  CohortsAvgFields: ResolverTypeWrapper<CohortsAvgFields>;
  CohortsAvgOrderBy: CohortsAvgOrderBy;
  CohortsBoolExp: CohortsBoolExp;
  CohortsConstraint: CohortsConstraint;
  CohortsIncInput: CohortsIncInput;
  CohortsInsertInput: CohortsInsertInput;
  CohortsMaxFields: ResolverTypeWrapper<CohortsMaxFields>;
  CohortsMaxOrderBy: CohortsMaxOrderBy;
  CohortsMinFields: ResolverTypeWrapper<CohortsMinFields>;
  CohortsMinOrderBy: CohortsMinOrderBy;
  CohortsMutationResponse: ResolverTypeWrapper<CohortsMutationResponse>;
  CohortsObjRelInsertInput: CohortsObjRelInsertInput;
  CohortsOnConflict: CohortsOnConflict;
  CohortsOrderBy: CohortsOrderBy;
  CohortsPkColumnsInput: CohortsPkColumnsInput;
  CohortsResourcesRel: ResolverTypeWrapper<CohortsResourcesRel>;
  CohortsResourcesRelAggregate: ResolverTypeWrapper<CohortsResourcesRelAggregate>;
  CohortsResourcesRelAggregateBoolExp: CohortsResourcesRelAggregateBoolExp;
  CohortsResourcesRelAggregateFields: ResolverTypeWrapper<CohortsResourcesRelAggregateFields>;
  CohortsResourcesRelAggregateOrderBy: CohortsResourcesRelAggregateOrderBy;
  CohortsResourcesRelArrRelInsertInput: CohortsResourcesRelArrRelInsertInput;
  CohortsResourcesRelBoolExp: CohortsResourcesRelBoolExp;
  CohortsResourcesRelConstraint: CohortsResourcesRelConstraint;
  CohortsResourcesRelInsertInput: CohortsResourcesRelInsertInput;
  CohortsResourcesRelMaxFields: ResolverTypeWrapper<CohortsResourcesRelMaxFields>;
  CohortsResourcesRelMaxOrderBy: CohortsResourcesRelMaxOrderBy;
  CohortsResourcesRelMinFields: ResolverTypeWrapper<CohortsResourcesRelMinFields>;
  CohortsResourcesRelMinOrderBy: CohortsResourcesRelMinOrderBy;
  CohortsResourcesRelMutationResponse: ResolverTypeWrapper<CohortsResourcesRelMutationResponse>;
  CohortsResourcesRelObjRelInsertInput: CohortsResourcesRelObjRelInsertInput;
  CohortsResourcesRelOnConflict: CohortsResourcesRelOnConflict;
  CohortsResourcesRelOrderBy: CohortsResourcesRelOrderBy;
  CohortsResourcesRelPkColumnsInput: CohortsResourcesRelPkColumnsInput;
  CohortsResourcesRelSelectColumn: CohortsResourcesRelSelectColumn;
  CohortsResourcesRelSetInput: CohortsResourcesRelSetInput;
  CohortsResourcesRelStreamCursorInput: CohortsResourcesRelStreamCursorInput;
  CohortsResourcesRelStreamCursorValueInput: CohortsResourcesRelStreamCursorValueInput;
  CohortsResourcesRelUpdateColumn: CohortsResourcesRelUpdateColumn;
  CohortsResourcesRelUpdates: CohortsResourcesRelUpdates;
  CohortsSelectColumn: CohortsSelectColumn;
  CohortsSelectColumnCohortsAggregateBoolExpBool_andArgumentsColumns: CohortsSelectColumnCohortsAggregateBoolExpBool_AndArgumentsColumns;
  CohortsSelectColumnCohortsAggregateBoolExpBool_orArgumentsColumns: CohortsSelectColumnCohortsAggregateBoolExpBool_OrArgumentsColumns;
  CohortsSetInput: CohortsSetInput;
  CohortsStddevFields: ResolverTypeWrapper<CohortsStddevFields>;
  CohortsStddevOrderBy: CohortsStddevOrderBy;
  CohortsStddevPopFields: ResolverTypeWrapper<CohortsStddevPopFields>;
  CohortsStddevPopOrderBy: CohortsStddevPopOrderBy;
  CohortsStddevSampFields: ResolverTypeWrapper<CohortsStddevSampFields>;
  CohortsStddevSampOrderBy: CohortsStddevSampOrderBy;
  CohortsStreamCursorInput: CohortsStreamCursorInput;
  CohortsStreamCursorValueInput: CohortsStreamCursorValueInput;
  CohortsSumFields: ResolverTypeWrapper<CohortsSumFields>;
  CohortsSumOrderBy: CohortsSumOrderBy;
  CohortsUpdateColumn: CohortsUpdateColumn;
  CohortsUpdates: CohortsUpdates;
  CohortsVarPopFields: ResolverTypeWrapper<CohortsVarPopFields>;
  CohortsVarPopOrderBy: CohortsVarPopOrderBy;
  CohortsVarSampFields: ResolverTypeWrapper<CohortsVarSampFields>;
  CohortsVarSampOrderBy: CohortsVarSampOrderBy;
  CohortsVarianceFields: ResolverTypeWrapper<CohortsVarianceFields>;
  CohortsVarianceOrderBy: CohortsVarianceOrderBy;
  Configurations: ResolverTypeWrapper<Configurations>;
  ConfigurationsAggregate: ResolverTypeWrapper<ConfigurationsAggregate>;
  ConfigurationsAggregateFields: ResolverTypeWrapper<ConfigurationsAggregateFields>;
  ConfigurationsAppendInput: ConfigurationsAppendInput;
  ConfigurationsBoolExp: ConfigurationsBoolExp;
  ConfigurationsConstraint: ConfigurationsConstraint;
  ConfigurationsDeleteAtPathInput: ConfigurationsDeleteAtPathInput;
  ConfigurationsDeleteElemInput: ConfigurationsDeleteElemInput;
  ConfigurationsDeleteKeyInput: ConfigurationsDeleteKeyInput;
  ConfigurationsInsertInput: ConfigurationsInsertInput;
  ConfigurationsMaxFields: ResolverTypeWrapper<ConfigurationsMaxFields>;
  ConfigurationsMinFields: ResolverTypeWrapper<ConfigurationsMinFields>;
  ConfigurationsMutationResponse: ResolverTypeWrapper<ConfigurationsMutationResponse>;
  ConfigurationsOnConflict: ConfigurationsOnConflict;
  ConfigurationsOrderBy: ConfigurationsOrderBy;
  ConfigurationsPkColumnsInput: ConfigurationsPkColumnsInput;
  ConfigurationsPrependInput: ConfigurationsPrependInput;
  ConfigurationsSelectColumn: ConfigurationsSelectColumn;
  ConfigurationsSetInput: ConfigurationsSetInput;
  ConfigurationsStreamCursorInput: ConfigurationsStreamCursorInput;
  ConfigurationsStreamCursorValueInput: ConfigurationsStreamCursorValueInput;
  ConfigurationsUpdateColumn: ConfigurationsUpdateColumn;
  ConfigurationsUpdates: ConfigurationsUpdates;
  CountTeamSessionsItem: ResolverTypeWrapper<CountTeamSessionsItem>;
  CountTeamSessionsOutput: ResolverTypeWrapper<CountTeamSessionsOutput>;
  CourseCertifications: ResolverTypeWrapper<CourseCertifications>;
  CourseCertificationsAggregate: ResolverTypeWrapper<CourseCertificationsAggregate>;
  CourseCertificationsAggregateFields: ResolverTypeWrapper<CourseCertificationsAggregateFields>;
  CourseCertificationsBoolExp: CourseCertificationsBoolExp;
  CourseCertificationsConstraint: CourseCertificationsConstraint;
  CourseCertificationsInsertInput: CourseCertificationsInsertInput;
  CourseCertificationsMaxFields: ResolverTypeWrapper<CourseCertificationsMaxFields>;
  CourseCertificationsMinFields: ResolverTypeWrapper<CourseCertificationsMinFields>;
  CourseCertificationsMutationResponse: ResolverTypeWrapper<CourseCertificationsMutationResponse>;
  CourseCertificationsOnConflict: CourseCertificationsOnConflict;
  CourseCertificationsOrderBy: CourseCertificationsOrderBy;
  CourseCertificationsPkColumnsInput: CourseCertificationsPkColumnsInput;
  CourseCertificationsSelectColumn: CourseCertificationsSelectColumn;
  CourseCertificationsSetInput: CourseCertificationsSetInput;
  CourseCertificationsStreamCursorInput: CourseCertificationsStreamCursorInput;
  CourseCertificationsStreamCursorValueInput: CourseCertificationsStreamCursorValueInput;
  CourseCertificationsUpdateColumn: CourseCertificationsUpdateColumn;
  CourseCertificationsUpdates: CourseCertificationsUpdates;
  CourseCoaches: ResolverTypeWrapper<CourseCoaches>;
  CourseCoachesAggregate: ResolverTypeWrapper<CourseCoachesAggregate>;
  CourseCoachesAggregateBoolExp: CourseCoachesAggregateBoolExp;
  CourseCoachesAggregateBoolExpCount: CourseCoachesAggregateBoolExpCount;
  CourseCoachesAggregateFields: ResolverTypeWrapper<CourseCoachesAggregateFields>;
  CourseCoachesAggregateOrderBy: CourseCoachesAggregateOrderBy;
  CourseCoachesArrRelInsertInput: CourseCoachesArrRelInsertInput;
  CourseCoachesBoolExp: CourseCoachesBoolExp;
  CourseCoachesConstraint: CourseCoachesConstraint;
  CourseCoachesInsertInput: CourseCoachesInsertInput;
  CourseCoachesMaxFields: ResolverTypeWrapper<CourseCoachesMaxFields>;
  CourseCoachesMaxOrderBy: CourseCoachesMaxOrderBy;
  CourseCoachesMinFields: ResolverTypeWrapper<CourseCoachesMinFields>;
  CourseCoachesMinOrderBy: CourseCoachesMinOrderBy;
  CourseCoachesMutationResponse: ResolverTypeWrapper<CourseCoachesMutationResponse>;
  CourseCoachesOnConflict: CourseCoachesOnConflict;
  CourseCoachesOrderBy: CourseCoachesOrderBy;
  CourseCoachesPkColumnsInput: CourseCoachesPkColumnsInput;
  CourseCoachesSelectColumn: CourseCoachesSelectColumn;
  CourseCoachesSetInput: CourseCoachesSetInput;
  CourseCoachesStreamCursorInput: CourseCoachesStreamCursorInput;
  CourseCoachesStreamCursorValueInput: CourseCoachesStreamCursorValueInput;
  CourseCoachesUpdateColumn: CourseCoachesUpdateColumn;
  CourseCoachesUpdates: CourseCoachesUpdates;
  CourseEnrollmentStatus: ResolverTypeWrapper<CourseEnrollmentStatus>;
  CourseEnrollmentStatusAggregate: ResolverTypeWrapper<CourseEnrollmentStatusAggregate>;
  CourseEnrollmentStatusAggregateFields: ResolverTypeWrapper<CourseEnrollmentStatusAggregateFields>;
  CourseEnrollmentStatusBoolExp: CourseEnrollmentStatusBoolExp;
  CourseEnrollmentStatusConstraint: CourseEnrollmentStatusConstraint;
  CourseEnrollmentStatusEnum: CourseEnrollmentStatusEnum;
  CourseEnrollmentStatusEnumComparisonExp: CourseEnrollmentStatusEnumComparisonExp;
  CourseEnrollmentStatusInsertInput: CourseEnrollmentStatusInsertInput;
  CourseEnrollmentStatusMaxFields: ResolverTypeWrapper<CourseEnrollmentStatusMaxFields>;
  CourseEnrollmentStatusMinFields: ResolverTypeWrapper<CourseEnrollmentStatusMinFields>;
  CourseEnrollmentStatusMutationResponse: ResolverTypeWrapper<CourseEnrollmentStatusMutationResponse>;
  CourseEnrollmentStatusOnConflict: CourseEnrollmentStatusOnConflict;
  CourseEnrollmentStatusOrderBy: CourseEnrollmentStatusOrderBy;
  CourseEnrollmentStatusPkColumnsInput: CourseEnrollmentStatusPkColumnsInput;
  CourseEnrollmentStatusSelectColumn: CourseEnrollmentStatusSelectColumn;
  CourseEnrollmentStatusSetInput: CourseEnrollmentStatusSetInput;
  CourseEnrollmentStatusStreamCursorInput: CourseEnrollmentStatusStreamCursorInput;
  CourseEnrollmentStatusStreamCursorValueInput: CourseEnrollmentStatusStreamCursorValueInput;
  CourseEnrollmentStatusUpdateColumn: CourseEnrollmentStatusUpdateColumn;
  CourseEnrollmentStatusUpdates: CourseEnrollmentStatusUpdates;
  CoursePublishStatus: ResolverTypeWrapper<CoursePublishStatus>;
  CoursePublishStatusAggregate: ResolverTypeWrapper<CoursePublishStatusAggregate>;
  CoursePublishStatusAggregateFields: ResolverTypeWrapper<CoursePublishStatusAggregateFields>;
  CoursePublishStatusBoolExp: CoursePublishStatusBoolExp;
  CoursePublishStatusConstraint: CoursePublishStatusConstraint;
  CoursePublishStatusEnum: CoursePublishStatusEnum;
  CoursePublishStatusEnumComparisonExp: CoursePublishStatusEnumComparisonExp;
  CoursePublishStatusInsertInput: CoursePublishStatusInsertInput;
  CoursePublishStatusMaxFields: ResolverTypeWrapper<CoursePublishStatusMaxFields>;
  CoursePublishStatusMinFields: ResolverTypeWrapper<CoursePublishStatusMinFields>;
  CoursePublishStatusMutationResponse: ResolverTypeWrapper<CoursePublishStatusMutationResponse>;
  CoursePublishStatusObjRelInsertInput: CoursePublishStatusObjRelInsertInput;
  CoursePublishStatusOnConflict: CoursePublishStatusOnConflict;
  CoursePublishStatusOrderBy: CoursePublishStatusOrderBy;
  CoursePublishStatusPkColumnsInput: CoursePublishStatusPkColumnsInput;
  CoursePublishStatusSelectColumn: CoursePublishStatusSelectColumn;
  CoursePublishStatusSetInput: CoursePublishStatusSetInput;
  CoursePublishStatusStreamCursorInput: CoursePublishStatusStreamCursorInput;
  CoursePublishStatusStreamCursorValueInput: CoursePublishStatusStreamCursorValueInput;
  CoursePublishStatusUpdateColumn: CoursePublishStatusUpdateColumn;
  CoursePublishStatusUpdates: CoursePublishStatusUpdates;
  CourseType: ResolverTypeWrapper<CourseType>;
  CourseTypeAggregate: ResolverTypeWrapper<CourseTypeAggregate>;
  CourseTypeAggregateFields: ResolverTypeWrapper<CourseTypeAggregateFields>;
  CourseTypeBoolExp: CourseTypeBoolExp;
  CourseTypeConstraint: CourseTypeConstraint;
  CourseTypeEnum: CourseTypeEnum;
  CourseTypeEnumComparisonExp: CourseTypeEnumComparisonExp;
  CourseTypeInsertInput: CourseTypeInsertInput;
  CourseTypeMaxFields: ResolverTypeWrapper<CourseTypeMaxFields>;
  CourseTypeMinFields: ResolverTypeWrapper<CourseTypeMinFields>;
  CourseTypeMutationResponse: ResolverTypeWrapper<CourseTypeMutationResponse>;
  CourseTypeOnConflict: CourseTypeOnConflict;
  CourseTypeOrderBy: CourseTypeOrderBy;
  CourseTypePkColumnsInput: CourseTypePkColumnsInput;
  CourseTypeSelectColumn: CourseTypeSelectColumn;
  CourseTypeSetInput: CourseTypeSetInput;
  CourseTypeStreamCursorInput: CourseTypeStreamCursorInput;
  CourseTypeStreamCursorValueInput: CourseTypeStreamCursorValueInput;
  CourseTypeUpdateColumn: CourseTypeUpdateColumn;
  CourseTypeUpdates: CourseTypeUpdates;
  CourseWaitlist: ResolverTypeWrapper<CourseWaitlist>;
  CourseWaitlistAggregate: ResolverTypeWrapper<CourseWaitlistAggregate>;
  CourseWaitlistAggregateFields: ResolverTypeWrapper<CourseWaitlistAggregateFields>;
  CourseWaitlistBoolExp: CourseWaitlistBoolExp;
  CourseWaitlistConstraint: CourseWaitlistConstraint;
  CourseWaitlistDesiredUrgencyType: ResolverTypeWrapper<CourseWaitlistDesiredUrgencyType>;
  CourseWaitlistDesiredUrgencyTypeAggregate: ResolverTypeWrapper<CourseWaitlistDesiredUrgencyTypeAggregate>;
  CourseWaitlistDesiredUrgencyTypeAggregateFields: ResolverTypeWrapper<CourseWaitlistDesiredUrgencyTypeAggregateFields>;
  CourseWaitlistDesiredUrgencyTypeBoolExp: CourseWaitlistDesiredUrgencyTypeBoolExp;
  CourseWaitlistDesiredUrgencyTypeConstraint: CourseWaitlistDesiredUrgencyTypeConstraint;
  CourseWaitlistDesiredUrgencyTypeEnum: CourseWaitlistDesiredUrgencyTypeEnum;
  CourseWaitlistDesiredUrgencyTypeEnumComparisonExp: CourseWaitlistDesiredUrgencyTypeEnumComparisonExp;
  CourseWaitlistDesiredUrgencyTypeInsertInput: CourseWaitlistDesiredUrgencyTypeInsertInput;
  CourseWaitlistDesiredUrgencyTypeMaxFields: ResolverTypeWrapper<CourseWaitlistDesiredUrgencyTypeMaxFields>;
  CourseWaitlistDesiredUrgencyTypeMinFields: ResolverTypeWrapper<CourseWaitlistDesiredUrgencyTypeMinFields>;
  CourseWaitlistDesiredUrgencyTypeMutationResponse: ResolverTypeWrapper<CourseWaitlistDesiredUrgencyTypeMutationResponse>;
  CourseWaitlistDesiredUrgencyTypeOnConflict: CourseWaitlistDesiredUrgencyTypeOnConflict;
  CourseWaitlistDesiredUrgencyTypeOrderBy: CourseWaitlistDesiredUrgencyTypeOrderBy;
  CourseWaitlistDesiredUrgencyTypePkColumnsInput: CourseWaitlistDesiredUrgencyTypePkColumnsInput;
  CourseWaitlistDesiredUrgencyTypeSelectColumn: CourseWaitlistDesiredUrgencyTypeSelectColumn;
  CourseWaitlistDesiredUrgencyTypeSetInput: CourseWaitlistDesiredUrgencyTypeSetInput;
  CourseWaitlistDesiredUrgencyTypeStreamCursorInput: CourseWaitlistDesiredUrgencyTypeStreamCursorInput;
  CourseWaitlistDesiredUrgencyTypeStreamCursorValueInput: CourseWaitlistDesiredUrgencyTypeStreamCursorValueInput;
  CourseWaitlistDesiredUrgencyTypeUpdateColumn: CourseWaitlistDesiredUrgencyTypeUpdateColumn;
  CourseWaitlistDesiredUrgencyTypeUpdates: CourseWaitlistDesiredUrgencyTypeUpdates;
  CourseWaitlistInput: CourseWaitlistInput;
  CourseWaitlistInsertInput: CourseWaitlistInsertInput;
  CourseWaitlistMaxFields: ResolverTypeWrapper<CourseWaitlistMaxFields>;
  CourseWaitlistMinFields: ResolverTypeWrapper<CourseWaitlistMinFields>;
  CourseWaitlistMutationResponse: ResolverTypeWrapper<CourseWaitlistMutationResponse>;
  CourseWaitlistOnConflict: CourseWaitlistOnConflict;
  CourseWaitlistOrderBy: CourseWaitlistOrderBy;
  CourseWaitlistPkColumnsInput: CourseWaitlistPkColumnsInput;
  CourseWaitlistSelectColumn: CourseWaitlistSelectColumn;
  CourseWaitlistSendAvailabilityEmailInput: CourseWaitlistSendAvailabilityEmailInput;
  CourseWaitlistSendAvailabilityEmailOutput: ResolverTypeWrapper<CourseWaitlistSendAvailabilityEmailOutput>;
  CourseWaitlistSendAvailabilityEmailUserOutPut: ResolverTypeWrapper<CourseWaitlistSendAvailabilityEmailUserOutPut>;
  CourseWaitlistSetInput: CourseWaitlistSetInput;
  CourseWaitlistStreamCursorInput: CourseWaitlistStreamCursorInput;
  CourseWaitlistStreamCursorValueInput: CourseWaitlistStreamCursorValueInput;
  CourseWaitlistUpdateColumn: CourseWaitlistUpdateColumn;
  CourseWaitlistUpdates: CourseWaitlistUpdates;
  Courses: ResolverTypeWrapper<Courses>;
  CoursesAggregate: ResolverTypeWrapper<CoursesAggregate>;
  CoursesAggregateBoolExp: CoursesAggregateBoolExp;
  CoursesAggregateFields: ResolverTypeWrapper<CoursesAggregateFields>;
  CoursesAggregateOrderBy: CoursesAggregateOrderBy;
  CoursesArrRelInsertInput: CoursesArrRelInsertInput;
  CoursesAvgFields: ResolverTypeWrapper<CoursesAvgFields>;
  CoursesAvgOrderBy: CoursesAvgOrderBy;
  CoursesBoolExp: CoursesBoolExp;
  CoursesByPlan: ResolverTypeWrapper<CoursesByPlan>;
  CoursesByPlanAggregate: ResolverTypeWrapper<CoursesByPlanAggregate>;
  CoursesByPlanAggregateFields: ResolverTypeWrapper<CoursesByPlanAggregateFields>;
  CoursesByPlanAvgFields: ResolverTypeWrapper<CoursesByPlanAvgFields>;
  CoursesByPlanBoolExp: CoursesByPlanBoolExp;
  CoursesByPlanMaxFields: ResolverTypeWrapper<CoursesByPlanMaxFields>;
  CoursesByPlanMinFields: ResolverTypeWrapper<CoursesByPlanMinFields>;
  CoursesByPlanOrderBy: CoursesByPlanOrderBy;
  CoursesByPlanSelectColumn: CoursesByPlanSelectColumn;
  CoursesByPlanStddevFields: ResolverTypeWrapper<CoursesByPlanStddevFields>;
  CoursesByPlanStddevPopFields: ResolverTypeWrapper<CoursesByPlanStddevPopFields>;
  CoursesByPlanStddevSampFields: ResolverTypeWrapper<CoursesByPlanStddevSampFields>;
  CoursesByPlanStreamCursorInput: CoursesByPlanStreamCursorInput;
  CoursesByPlanStreamCursorValueInput: CoursesByPlanStreamCursorValueInput;
  CoursesByPlanSumFields: ResolverTypeWrapper<CoursesByPlanSumFields>;
  CoursesByPlanVarPopFields: ResolverTypeWrapper<CoursesByPlanVarPopFields>;
  CoursesByPlanVarSampFields: ResolverTypeWrapper<CoursesByPlanVarSampFields>;
  CoursesByPlanVarianceFields: ResolverTypeWrapper<CoursesByPlanVarianceFields>;
  CoursesConstraint: CoursesConstraint;
  CoursesIncInput: CoursesIncInput;
  CoursesInsertInput: CoursesInsertInput;
  CoursesMaxFields: ResolverTypeWrapper<CoursesMaxFields>;
  CoursesMaxOrderBy: CoursesMaxOrderBy;
  CoursesMinFields: ResolverTypeWrapper<CoursesMinFields>;
  CoursesMinOrderBy: CoursesMinOrderBy;
  CoursesMutationResponse: ResolverTypeWrapper<CoursesMutationResponse>;
  CoursesObjRelInsertInput: CoursesObjRelInsertInput;
  CoursesOnConflict: CoursesOnConflict;
  CoursesOrderBy: CoursesOrderBy;
  CoursesPkColumnsInput: CoursesPkColumnsInput;
  CoursesResourcesRel: ResolverTypeWrapper<CoursesResourcesRel>;
  CoursesResourcesRelAggregate: ResolverTypeWrapper<CoursesResourcesRelAggregate>;
  CoursesResourcesRelAggregateBoolExp: CoursesResourcesRelAggregateBoolExp;
  CoursesResourcesRelAggregateBoolExpCount: CoursesResourcesRelAggregateBoolExpCount;
  CoursesResourcesRelAggregateFields: ResolverTypeWrapper<CoursesResourcesRelAggregateFields>;
  CoursesResourcesRelAggregateOrderBy: CoursesResourcesRelAggregateOrderBy;
  CoursesResourcesRelArrRelInsertInput: CoursesResourcesRelArrRelInsertInput;
  CoursesResourcesRelBoolExp: CoursesResourcesRelBoolExp;
  CoursesResourcesRelConstraint: CoursesResourcesRelConstraint;
  CoursesResourcesRelInsertInput: CoursesResourcesRelInsertInput;
  CoursesResourcesRelMaxFields: ResolverTypeWrapper<CoursesResourcesRelMaxFields>;
  CoursesResourcesRelMaxOrderBy: CoursesResourcesRelMaxOrderBy;
  CoursesResourcesRelMinFields: ResolverTypeWrapper<CoursesResourcesRelMinFields>;
  CoursesResourcesRelMinOrderBy: CoursesResourcesRelMinOrderBy;
  CoursesResourcesRelMutationResponse: ResolverTypeWrapper<CoursesResourcesRelMutationResponse>;
  CoursesResourcesRelObjRelInsertInput: CoursesResourcesRelObjRelInsertInput;
  CoursesResourcesRelOnConflict: CoursesResourcesRelOnConflict;
  CoursesResourcesRelOrderBy: CoursesResourcesRelOrderBy;
  CoursesResourcesRelPkColumnsInput: CoursesResourcesRelPkColumnsInput;
  CoursesResourcesRelSelectColumn: CoursesResourcesRelSelectColumn;
  CoursesResourcesRelSetInput: CoursesResourcesRelSetInput;
  CoursesResourcesRelStreamCursorInput: CoursesResourcesRelStreamCursorInput;
  CoursesResourcesRelStreamCursorValueInput: CoursesResourcesRelStreamCursorValueInput;
  CoursesResourcesRelUpdateColumn: CoursesResourcesRelUpdateColumn;
  CoursesResourcesRelUpdates: CoursesResourcesRelUpdates;
  CoursesSelectColumn: CoursesSelectColumn;
  CoursesSetInput: CoursesSetInput;
  CoursesStddevFields: ResolverTypeWrapper<CoursesStddevFields>;
  CoursesStddevOrderBy: CoursesStddevOrderBy;
  CoursesStddevPopFields: ResolverTypeWrapper<CoursesStddevPopFields>;
  CoursesStddevPopOrderBy: CoursesStddevPopOrderBy;
  CoursesStddevSampFields: ResolverTypeWrapper<CoursesStddevSampFields>;
  CoursesStddevSampOrderBy: CoursesStddevSampOrderBy;
  CoursesStreamCursorInput: CoursesStreamCursorInput;
  CoursesStreamCursorValueInput: CoursesStreamCursorValueInput;
  CoursesSumFields: ResolverTypeWrapper<CoursesSumFields>;
  CoursesSumOrderBy: CoursesSumOrderBy;
  CoursesTagsRel: ResolverTypeWrapper<CoursesTagsRel>;
  CoursesTagsRelAggregate: ResolverTypeWrapper<CoursesTagsRelAggregate>;
  CoursesTagsRelAggregateBoolExp: CoursesTagsRelAggregateBoolExp;
  CoursesTagsRelAggregateFields: ResolverTypeWrapper<CoursesTagsRelAggregateFields>;
  CoursesTagsRelAggregateOrderBy: CoursesTagsRelAggregateOrderBy;
  CoursesTagsRelArrRelInsertInput: CoursesTagsRelArrRelInsertInput;
  CoursesTagsRelBoolExp: CoursesTagsRelBoolExp;
  CoursesTagsRelConstraint: CoursesTagsRelConstraint;
  CoursesTagsRelInsertInput: CoursesTagsRelInsertInput;
  CoursesTagsRelMaxFields: ResolverTypeWrapper<CoursesTagsRelMaxFields>;
  CoursesTagsRelMaxOrderBy: CoursesTagsRelMaxOrderBy;
  CoursesTagsRelMinFields: ResolverTypeWrapper<CoursesTagsRelMinFields>;
  CoursesTagsRelMinOrderBy: CoursesTagsRelMinOrderBy;
  CoursesTagsRelMutationResponse: ResolverTypeWrapper<CoursesTagsRelMutationResponse>;
  CoursesTagsRelOnConflict: CoursesTagsRelOnConflict;
  CoursesTagsRelOrderBy: CoursesTagsRelOrderBy;
  CoursesTagsRelPkColumnsInput: CoursesTagsRelPkColumnsInput;
  CoursesTagsRelSelectColumn: CoursesTagsRelSelectColumn;
  CoursesTagsRelSetInput: CoursesTagsRelSetInput;
  CoursesTagsRelStreamCursorInput: CoursesTagsRelStreamCursorInput;
  CoursesTagsRelStreamCursorValueInput: CoursesTagsRelStreamCursorValueInput;
  CoursesTagsRelUpdateColumn: CoursesTagsRelUpdateColumn;
  CoursesTagsRelUpdates: CoursesTagsRelUpdates;
  CoursesUpdateColumn: CoursesUpdateColumn;
  CoursesUpdates: CoursesUpdates;
  CoursesVarPopFields: ResolverTypeWrapper<CoursesVarPopFields>;
  CoursesVarPopOrderBy: CoursesVarPopOrderBy;
  CoursesVarSampFields: ResolverTypeWrapper<CoursesVarSampFields>;
  CoursesVarSampOrderBy: CoursesVarSampOrderBy;
  CoursesVarianceFields: ResolverTypeWrapper<CoursesVarianceFields>;
  CoursesVarianceOrderBy: CoursesVarianceOrderBy;
  CreateOrganizationOutput: ResolverTypeWrapper<CreateOrganizationOutput>;
  CreateUserOutput: ResolverTypeWrapper<CreateUserOutput>;
  CursorOrdering: CursorOrdering;
  DateComparisonExp: DateComparisonExp;
  EmailQueue: ResolverTypeWrapper<EmailQueue>;
  EmailQueueAggregate: ResolverTypeWrapper<EmailQueueAggregate>;
  EmailQueueAggregateFields: ResolverTypeWrapper<EmailQueueAggregateFields>;
  EmailQueueAppendInput: EmailQueueAppendInput;
  EmailQueueBoolExp: EmailQueueBoolExp;
  EmailQueueConstraint: EmailQueueConstraint;
  EmailQueueDeleteAtPathInput: EmailQueueDeleteAtPathInput;
  EmailQueueDeleteElemInput: EmailQueueDeleteElemInput;
  EmailQueueDeleteKeyInput: EmailQueueDeleteKeyInput;
  EmailQueueInsertInput: EmailQueueInsertInput;
  EmailQueueMaxFields: ResolverTypeWrapper<EmailQueueMaxFields>;
  EmailQueueMinFields: ResolverTypeWrapper<EmailQueueMinFields>;
  EmailQueueMutationResponse: ResolverTypeWrapper<EmailQueueMutationResponse>;
  EmailQueueOnConflict: EmailQueueOnConflict;
  EmailQueueOrderBy: EmailQueueOrderBy;
  EmailQueuePkColumnsInput: EmailQueuePkColumnsInput;
  EmailQueuePrependInput: EmailQueuePrependInput;
  EmailQueueSelectColumn: EmailQueueSelectColumn;
  EmailQueueSetInput: EmailQueueSetInput;
  EmailQueueStreamCursorInput: EmailQueueStreamCursorInput;
  EmailQueueStreamCursorValueInput: EmailQueueStreamCursorValueInput;
  EmailQueueUpdateColumn: EmailQueueUpdateColumn;
  EmailQueueUpdates: EmailQueueUpdates;
  EnrollInput: EnrollInput;
  EnrollOutput: ResolverTypeWrapper<EnrollOutput>;
  EnrollmentWaitlists: ResolverTypeWrapper<EnrollmentWaitlists>;
  EnrollmentWaitlistsAggregate: ResolverTypeWrapper<EnrollmentWaitlistsAggregate>;
  EnrollmentWaitlistsAggregateBoolExp: EnrollmentWaitlistsAggregateBoolExp;
  EnrollmentWaitlistsAggregateFields: ResolverTypeWrapper<EnrollmentWaitlistsAggregateFields>;
  EnrollmentWaitlistsAggregateOrderBy: EnrollmentWaitlistsAggregateOrderBy;
  EnrollmentWaitlistsArrRelInsertInput: EnrollmentWaitlistsArrRelInsertInput;
  EnrollmentWaitlistsBoolExp: EnrollmentWaitlistsBoolExp;
  EnrollmentWaitlistsConstraint: EnrollmentWaitlistsConstraint;
  EnrollmentWaitlistsInsertInput: EnrollmentWaitlistsInsertInput;
  EnrollmentWaitlistsMaxFields: ResolverTypeWrapper<EnrollmentWaitlistsMaxFields>;
  EnrollmentWaitlistsMaxOrderBy: EnrollmentWaitlistsMaxOrderBy;
  EnrollmentWaitlistsMinFields: ResolverTypeWrapper<EnrollmentWaitlistsMinFields>;
  EnrollmentWaitlistsMinOrderBy: EnrollmentWaitlistsMinOrderBy;
  EnrollmentWaitlistsMutationResponse: ResolverTypeWrapper<EnrollmentWaitlistsMutationResponse>;
  EnrollmentWaitlistsOnConflict: EnrollmentWaitlistsOnConflict;
  EnrollmentWaitlistsOrderBy: EnrollmentWaitlistsOrderBy;
  EnrollmentWaitlistsPkColumnsInput: EnrollmentWaitlistsPkColumnsInput;
  EnrollmentWaitlistsSelectColumn: EnrollmentWaitlistsSelectColumn;
  EnrollmentWaitlistsSelectColumnEnrollmentWaitlistsAggregateBoolExpBool_andArgumentsColumns: EnrollmentWaitlistsSelectColumnEnrollmentWaitlistsAggregateBoolExpBool_AndArgumentsColumns;
  EnrollmentWaitlistsSelectColumnEnrollmentWaitlistsAggregateBoolExpBool_orArgumentsColumns: EnrollmentWaitlistsSelectColumnEnrollmentWaitlistsAggregateBoolExpBool_OrArgumentsColumns;
  EnrollmentWaitlistsSetInput: EnrollmentWaitlistsSetInput;
  EnrollmentWaitlistsStreamCursorInput: EnrollmentWaitlistsStreamCursorInput;
  EnrollmentWaitlistsStreamCursorValueInput: EnrollmentWaitlistsStreamCursorValueInput;
  EnrollmentWaitlistsUpdateColumn: EnrollmentWaitlistsUpdateColumn;
  EnrollmentWaitlistsUpdates: EnrollmentWaitlistsUpdates;
  Enrollments: ResolverTypeWrapper<Enrollments>;
  EnrollmentsAggregate: ResolverTypeWrapper<EnrollmentsAggregate>;
  EnrollmentsAggregateBoolExp: EnrollmentsAggregateBoolExp;
  EnrollmentsAggregateFields: ResolverTypeWrapper<EnrollmentsAggregateFields>;
  EnrollmentsAggregateOrderBy: EnrollmentsAggregateOrderBy;
  EnrollmentsArrRelInsertInput: EnrollmentsArrRelInsertInput;
  EnrollmentsBoolExp: EnrollmentsBoolExp;
  EnrollmentsConstraint: EnrollmentsConstraint;
  EnrollmentsInsertInput: EnrollmentsInsertInput;
  EnrollmentsMaxFields: ResolverTypeWrapper<EnrollmentsMaxFields>;
  EnrollmentsMaxOrderBy: EnrollmentsMaxOrderBy;
  EnrollmentsMinFields: ResolverTypeWrapper<EnrollmentsMinFields>;
  EnrollmentsMinOrderBy: EnrollmentsMinOrderBy;
  EnrollmentsMutationResponse: ResolverTypeWrapper<EnrollmentsMutationResponse>;
  EnrollmentsObjRelInsertInput: EnrollmentsObjRelInsertInput;
  EnrollmentsOnConflict: EnrollmentsOnConflict;
  EnrollmentsOrderBy: EnrollmentsOrderBy;
  EnrollmentsPkColumnsInput: EnrollmentsPkColumnsInput;
  EnrollmentsSelectColumn: EnrollmentsSelectColumn;
  EnrollmentsSetInput: EnrollmentsSetInput;
  EnrollmentsStreamCursorInput: EnrollmentsStreamCursorInput;
  EnrollmentsStreamCursorValueInput: EnrollmentsStreamCursorValueInput;
  EnrollmentsUpdateColumn: EnrollmentsUpdateColumn;
  EnrollmentsUpdates: EnrollmentsUpdates;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  Float8ComparisonExp: Float8ComparisonExp;
  FusionauthExternalIdentifiers: ResolverTypeWrapper<FusionauthExternalIdentifiers>;
  FusionauthExternalIdentifiersAggregate: ResolverTypeWrapper<FusionauthExternalIdentifiersAggregate>;
  FusionauthExternalIdentifiersAggregateFields: ResolverTypeWrapper<FusionauthExternalIdentifiersAggregateFields>;
  FusionauthExternalIdentifiersAvgFields: ResolverTypeWrapper<FusionauthExternalIdentifiersAvgFields>;
  FusionauthExternalIdentifiersBoolExp: FusionauthExternalIdentifiersBoolExp;
  FusionauthExternalIdentifiersConstraint: FusionauthExternalIdentifiersConstraint;
  FusionauthExternalIdentifiersIncInput: FusionauthExternalIdentifiersIncInput;
  FusionauthExternalIdentifiersInsertInput: FusionauthExternalIdentifiersInsertInput;
  FusionauthExternalIdentifiersMaxFields: ResolverTypeWrapper<FusionauthExternalIdentifiersMaxFields>;
  FusionauthExternalIdentifiersMinFields: ResolverTypeWrapper<FusionauthExternalIdentifiersMinFields>;
  FusionauthExternalIdentifiersMutationResponse: ResolverTypeWrapper<FusionauthExternalIdentifiersMutationResponse>;
  FusionauthExternalIdentifiersOnConflict: FusionauthExternalIdentifiersOnConflict;
  FusionauthExternalIdentifiersOrderBy: FusionauthExternalIdentifiersOrderBy;
  FusionauthExternalIdentifiersPkColumnsInput: FusionauthExternalIdentifiersPkColumnsInput;
  FusionauthExternalIdentifiersSelectColumn: FusionauthExternalIdentifiersSelectColumn;
  FusionauthExternalIdentifiersSetInput: FusionauthExternalIdentifiersSetInput;
  FusionauthExternalIdentifiersStddevFields: ResolverTypeWrapper<FusionauthExternalIdentifiersStddevFields>;
  FusionauthExternalIdentifiersStddevPopFields: ResolverTypeWrapper<FusionauthExternalIdentifiersStddevPopFields>;
  FusionauthExternalIdentifiersStddevSampFields: ResolverTypeWrapper<FusionauthExternalIdentifiersStddevSampFields>;
  FusionauthExternalIdentifiersStreamCursorInput: FusionauthExternalIdentifiersStreamCursorInput;
  FusionauthExternalIdentifiersStreamCursorValueInput: FusionauthExternalIdentifiersStreamCursorValueInput;
  FusionauthExternalIdentifiersSumFields: ResolverTypeWrapper<FusionauthExternalIdentifiersSumFields>;
  FusionauthExternalIdentifiersUpdateColumn: FusionauthExternalIdentifiersUpdateColumn;
  FusionauthExternalIdentifiersUpdates: FusionauthExternalIdentifiersUpdates;
  FusionauthExternalIdentifiersVarPopFields: ResolverTypeWrapper<FusionauthExternalIdentifiersVarPopFields>;
  FusionauthExternalIdentifiersVarSampFields: ResolverTypeWrapper<FusionauthExternalIdentifiersVarSampFields>;
  FusionauthExternalIdentifiersVarianceFields: ResolverTypeWrapper<FusionauthExternalIdentifiersVarianceFields>;
  FusionauthUserRegistrations: ResolverTypeWrapper<FusionauthUserRegistrations>;
  FusionauthUserRegistrationsAggregate: ResolverTypeWrapper<FusionauthUserRegistrationsAggregate>;
  FusionauthUserRegistrationsAggregateBoolExp: FusionauthUserRegistrationsAggregateBoolExp;
  FusionauthUserRegistrationsAggregateFields: ResolverTypeWrapper<FusionauthUserRegistrationsAggregateFields>;
  FusionauthUserRegistrationsAggregateOrderBy: FusionauthUserRegistrationsAggregateOrderBy;
  FusionauthUserRegistrationsArrRelInsertInput: FusionauthUserRegistrationsArrRelInsertInput;
  FusionauthUserRegistrationsAvgFields: ResolverTypeWrapper<FusionauthUserRegistrationsAvgFields>;
  FusionauthUserRegistrationsAvgOrderBy: FusionauthUserRegistrationsAvgOrderBy;
  FusionauthUserRegistrationsBoolExp: FusionauthUserRegistrationsBoolExp;
  FusionauthUserRegistrationsConstraint: FusionauthUserRegistrationsConstraint;
  FusionauthUserRegistrationsIncInput: FusionauthUserRegistrationsIncInput;
  FusionauthUserRegistrationsInsertInput: FusionauthUserRegistrationsInsertInput;
  FusionauthUserRegistrationsMaxFields: ResolverTypeWrapper<FusionauthUserRegistrationsMaxFields>;
  FusionauthUserRegistrationsMaxOrderBy: FusionauthUserRegistrationsMaxOrderBy;
  FusionauthUserRegistrationsMinFields: ResolverTypeWrapper<FusionauthUserRegistrationsMinFields>;
  FusionauthUserRegistrationsMinOrderBy: FusionauthUserRegistrationsMinOrderBy;
  FusionauthUserRegistrationsMutationResponse: ResolverTypeWrapper<FusionauthUserRegistrationsMutationResponse>;
  FusionauthUserRegistrationsOnConflict: FusionauthUserRegistrationsOnConflict;
  FusionauthUserRegistrationsOrderBy: FusionauthUserRegistrationsOrderBy;
  FusionauthUserRegistrationsPkColumnsInput: FusionauthUserRegistrationsPkColumnsInput;
  FusionauthUserRegistrationsSelectColumn: FusionauthUserRegistrationsSelectColumn;
  FusionauthUserRegistrationsSelectColumnFusionauthUserRegistrationsAggregateBoolExpBool_andArgumentsColumns: FusionauthUserRegistrationsSelectColumnFusionauthUserRegistrationsAggregateBoolExpBool_AndArgumentsColumns;
  FusionauthUserRegistrationsSelectColumnFusionauthUserRegistrationsAggregateBoolExpBool_orArgumentsColumns: FusionauthUserRegistrationsSelectColumnFusionauthUserRegistrationsAggregateBoolExpBool_OrArgumentsColumns;
  FusionauthUserRegistrationsSetInput: FusionauthUserRegistrationsSetInput;
  FusionauthUserRegistrationsStddevFields: ResolverTypeWrapper<FusionauthUserRegistrationsStddevFields>;
  FusionauthUserRegistrationsStddevOrderBy: FusionauthUserRegistrationsStddevOrderBy;
  FusionauthUserRegistrationsStddevPopFields: ResolverTypeWrapper<FusionauthUserRegistrationsStddevPopFields>;
  FusionauthUserRegistrationsStddevPopOrderBy: FusionauthUserRegistrationsStddevPopOrderBy;
  FusionauthUserRegistrationsStddevSampFields: ResolverTypeWrapper<FusionauthUserRegistrationsStddevSampFields>;
  FusionauthUserRegistrationsStddevSampOrderBy: FusionauthUserRegistrationsStddevSampOrderBy;
  FusionauthUserRegistrationsStreamCursorInput: FusionauthUserRegistrationsStreamCursorInput;
  FusionauthUserRegistrationsStreamCursorValueInput: FusionauthUserRegistrationsStreamCursorValueInput;
  FusionauthUserRegistrationsSumFields: ResolverTypeWrapper<FusionauthUserRegistrationsSumFields>;
  FusionauthUserRegistrationsSumOrderBy: FusionauthUserRegistrationsSumOrderBy;
  FusionauthUserRegistrationsUpdateColumn: FusionauthUserRegistrationsUpdateColumn;
  FusionauthUserRegistrationsUpdates: FusionauthUserRegistrationsUpdates;
  FusionauthUserRegistrationsVarPopFields: ResolverTypeWrapper<FusionauthUserRegistrationsVarPopFields>;
  FusionauthUserRegistrationsVarPopOrderBy: FusionauthUserRegistrationsVarPopOrderBy;
  FusionauthUserRegistrationsVarSampFields: ResolverTypeWrapper<FusionauthUserRegistrationsVarSampFields>;
  FusionauthUserRegistrationsVarSampOrderBy: FusionauthUserRegistrationsVarSampOrderBy;
  FusionauthUserRegistrationsVarianceFields: ResolverTypeWrapper<FusionauthUserRegistrationsVarianceFields>;
  FusionauthUserRegistrationsVarianceOrderBy: FusionauthUserRegistrationsVarianceOrderBy;
  GetCohortEnrollmentStatusOutput: ResolverTypeWrapper<GetCohortEnrollmentStatusOutput>;
  GetMySessionsAttendedCountOutput: ResolverTypeWrapper<GetMySessionsAttendedCountOutput>;
  GetPastSessionAsyncAverageMetricsOutput: ResolverTypeWrapper<GetPastSessionAsyncAverageMetricsOutput>;
  GetPastSessionAsyncAverageMetricsPastSession: ResolverTypeWrapper<GetPastSessionAsyncAverageMetricsPastSession>;
  GetPastSessionAsyncAverageMetricsSnowflakeMetrics: ResolverTypeWrapper<GetPastSessionAsyncAverageMetricsSnowflakeMetrics>;
  GetPastSessionAsyncMetricsMemberSessionNps: ResolverTypeWrapper<GetPastSessionAsyncMetricsMemberSessionNps>;
  GetPastSessionAsyncMetricsMemberWithSessionNps: ResolverTypeWrapper<GetPastSessionAsyncMetricsMemberWithSessionNps>;
  GetPastSessionAsyncMetricsMetric: ResolverTypeWrapper<GetPastSessionAsyncMetricsMetric>;
  GetPastSessionAsyncMetricsOutput: ResolverTypeWrapper<GetPastSessionAsyncMetricsOutput>;
  GetPastSessionAsyncMetricsSnowflakeMetrics: ResolverTypeWrapper<GetPastSessionAsyncMetricsSnowflakeMetrics>;
  Identities: ResolverTypeWrapper<Identities>;
  IdentitiesAggregate: ResolverTypeWrapper<IdentitiesAggregate>;
  IdentitiesAggregateBoolExp: IdentitiesAggregateBoolExp;
  IdentitiesAggregateBoolExpBool_and: IdentitiesAggregateBoolExpBool_And;
  IdentitiesAggregateBoolExpBool_or: IdentitiesAggregateBoolExpBool_Or;
  IdentitiesAggregateBoolExpCount: IdentitiesAggregateBoolExpCount;
  IdentitiesAggregateFields: ResolverTypeWrapper<IdentitiesAggregateFields>;
  IdentitiesAggregateOrderBy: IdentitiesAggregateOrderBy;
  IdentitiesArrRelInsertInput: IdentitiesArrRelInsertInput;
  IdentitiesAvgFields: ResolverTypeWrapper<IdentitiesAvgFields>;
  IdentitiesAvgOrderBy: IdentitiesAvgOrderBy;
  IdentitiesBoolExp: IdentitiesBoolExp;
  IdentitiesConstraint: IdentitiesConstraint;
  IdentitiesIncInput: IdentitiesIncInput;
  IdentitiesInsertInput: IdentitiesInsertInput;
  IdentitiesMaxFields: ResolverTypeWrapper<IdentitiesMaxFields>;
  IdentitiesMaxOrderBy: IdentitiesMaxOrderBy;
  IdentitiesMinFields: ResolverTypeWrapper<IdentitiesMinFields>;
  IdentitiesMinOrderBy: IdentitiesMinOrderBy;
  IdentitiesMutationResponse: ResolverTypeWrapper<IdentitiesMutationResponse>;
  IdentitiesOnConflict: IdentitiesOnConflict;
  IdentitiesOrderBy: IdentitiesOrderBy;
  IdentitiesPkColumnsInput: IdentitiesPkColumnsInput;
  IdentitiesSelectColumn: IdentitiesSelectColumn;
  IdentitiesSelectColumnIdentitiesAggregateBoolExpBool_andArgumentsColumns: IdentitiesSelectColumnIdentitiesAggregateBoolExpBool_AndArgumentsColumns;
  IdentitiesSelectColumnIdentitiesAggregateBoolExpBool_orArgumentsColumns: IdentitiesSelectColumnIdentitiesAggregateBoolExpBool_OrArgumentsColumns;
  IdentitiesSetInput: IdentitiesSetInput;
  IdentitiesStddevFields: ResolverTypeWrapper<IdentitiesStddevFields>;
  IdentitiesStddevOrderBy: IdentitiesStddevOrderBy;
  IdentitiesStddevPopFields: ResolverTypeWrapper<IdentitiesStddevPopFields>;
  IdentitiesStddevPopOrderBy: IdentitiesStddevPopOrderBy;
  IdentitiesStddevSampFields: ResolverTypeWrapper<IdentitiesStddevSampFields>;
  IdentitiesStddevSampOrderBy: IdentitiesStddevSampOrderBy;
  IdentitiesStreamCursorInput: IdentitiesStreamCursorInput;
  IdentitiesStreamCursorValueInput: IdentitiesStreamCursorValueInput;
  IdentitiesSumFields: ResolverTypeWrapper<IdentitiesSumFields>;
  IdentitiesSumOrderBy: IdentitiesSumOrderBy;
  IdentitiesUpdateColumn: IdentitiesUpdateColumn;
  IdentitiesUpdates: IdentitiesUpdates;
  IdentitiesVarPopFields: ResolverTypeWrapper<IdentitiesVarPopFields>;
  IdentitiesVarPopOrderBy: IdentitiesVarPopOrderBy;
  IdentitiesVarSampFields: ResolverTypeWrapper<IdentitiesVarSampFields>;
  IdentitiesVarSampOrderBy: IdentitiesVarSampOrderBy;
  IdentitiesVarianceFields: ResolverTypeWrapper<IdentitiesVarianceFields>;
  IdentitiesVarianceOrderBy: IdentitiesVarianceOrderBy;
  IdentityProviderLinks: ResolverTypeWrapper<IdentityProviderLinks>;
  IdentityProviderLinksAggregate: ResolverTypeWrapper<IdentityProviderLinksAggregate>;
  IdentityProviderLinksAggregateBoolExp: IdentityProviderLinksAggregateBoolExp;
  IdentityProviderLinksAggregateBoolExpCount: IdentityProviderLinksAggregateBoolExpCount;
  IdentityProviderLinksAggregateFields: ResolverTypeWrapper<IdentityProviderLinksAggregateFields>;
  IdentityProviderLinksAggregateOrderBy: IdentityProviderLinksAggregateOrderBy;
  IdentityProviderLinksArrRelInsertInput: IdentityProviderLinksArrRelInsertInput;
  IdentityProviderLinksAvgFields: ResolverTypeWrapper<IdentityProviderLinksAvgFields>;
  IdentityProviderLinksAvgOrderBy: IdentityProviderLinksAvgOrderBy;
  IdentityProviderLinksBoolExp: IdentityProviderLinksBoolExp;
  IdentityProviderLinksConstraint: IdentityProviderLinksConstraint;
  IdentityProviderLinksIncInput: IdentityProviderLinksIncInput;
  IdentityProviderLinksInsertInput: IdentityProviderLinksInsertInput;
  IdentityProviderLinksMaxFields: ResolverTypeWrapper<IdentityProviderLinksMaxFields>;
  IdentityProviderLinksMaxOrderBy: IdentityProviderLinksMaxOrderBy;
  IdentityProviderLinksMinFields: ResolverTypeWrapper<IdentityProviderLinksMinFields>;
  IdentityProviderLinksMinOrderBy: IdentityProviderLinksMinOrderBy;
  IdentityProviderLinksMutationResponse: ResolverTypeWrapper<IdentityProviderLinksMutationResponse>;
  IdentityProviderLinksOnConflict: IdentityProviderLinksOnConflict;
  IdentityProviderLinksOrderBy: IdentityProviderLinksOrderBy;
  IdentityProviderLinksSelectColumn: IdentityProviderLinksSelectColumn;
  IdentityProviderLinksSetInput: IdentityProviderLinksSetInput;
  IdentityProviderLinksStddevFields: ResolverTypeWrapper<IdentityProviderLinksStddevFields>;
  IdentityProviderLinksStddevOrderBy: IdentityProviderLinksStddevOrderBy;
  IdentityProviderLinksStddevPopFields: ResolverTypeWrapper<IdentityProviderLinksStddevPopFields>;
  IdentityProviderLinksStddevPopOrderBy: IdentityProviderLinksStddevPopOrderBy;
  IdentityProviderLinksStddevSampFields: ResolverTypeWrapper<IdentityProviderLinksStddevSampFields>;
  IdentityProviderLinksStddevSampOrderBy: IdentityProviderLinksStddevSampOrderBy;
  IdentityProviderLinksStreamCursorInput: IdentityProviderLinksStreamCursorInput;
  IdentityProviderLinksStreamCursorValueInput: IdentityProviderLinksStreamCursorValueInput;
  IdentityProviderLinksSumFields: ResolverTypeWrapper<IdentityProviderLinksSumFields>;
  IdentityProviderLinksSumOrderBy: IdentityProviderLinksSumOrderBy;
  IdentityProviderLinksUpdateColumn: IdentityProviderLinksUpdateColumn;
  IdentityProviderLinksUpdates: IdentityProviderLinksUpdates;
  IdentityProviderLinksVarPopFields: ResolverTypeWrapper<IdentityProviderLinksVarPopFields>;
  IdentityProviderLinksVarPopOrderBy: IdentityProviderLinksVarPopOrderBy;
  IdentityProviderLinksVarSampFields: ResolverTypeWrapper<IdentityProviderLinksVarSampFields>;
  IdentityProviderLinksVarSampOrderBy: IdentityProviderLinksVarSampOrderBy;
  IdentityProviderLinksVarianceFields: ResolverTypeWrapper<IdentityProviderLinksVarianceFields>;
  IdentityProviderLinksVarianceOrderBy: IdentityProviderLinksVarianceOrderBy;
  IdentityProviders: ResolverTypeWrapper<IdentityProviders>;
  IdentityProvidersAggregate: ResolverTypeWrapper<IdentityProvidersAggregate>;
  IdentityProvidersAggregateFields: ResolverTypeWrapper<IdentityProvidersAggregateFields>;
  IdentityProvidersAvgFields: ResolverTypeWrapper<IdentityProvidersAvgFields>;
  IdentityProvidersBoolExp: IdentityProvidersBoolExp;
  IdentityProvidersConstraint: IdentityProvidersConstraint;
  IdentityProvidersIncInput: IdentityProvidersIncInput;
  IdentityProvidersInsertInput: IdentityProvidersInsertInput;
  IdentityProvidersMaxFields: ResolverTypeWrapper<IdentityProvidersMaxFields>;
  IdentityProvidersMinFields: ResolverTypeWrapper<IdentityProvidersMinFields>;
  IdentityProvidersMutationResponse: ResolverTypeWrapper<IdentityProvidersMutationResponse>;
  IdentityProvidersObjRelInsertInput: IdentityProvidersObjRelInsertInput;
  IdentityProvidersOnConflict: IdentityProvidersOnConflict;
  IdentityProvidersOrderBy: IdentityProvidersOrderBy;
  IdentityProvidersPkColumnsInput: IdentityProvidersPkColumnsInput;
  IdentityProvidersSelectColumn: IdentityProvidersSelectColumn;
  IdentityProvidersSetInput: IdentityProvidersSetInput;
  IdentityProvidersStddevFields: ResolverTypeWrapper<IdentityProvidersStddevFields>;
  IdentityProvidersStddevPopFields: ResolverTypeWrapper<IdentityProvidersStddevPopFields>;
  IdentityProvidersStddevSampFields: ResolverTypeWrapper<IdentityProvidersStddevSampFields>;
  IdentityProvidersStreamCursorInput: IdentityProvidersStreamCursorInput;
  IdentityProvidersStreamCursorValueInput: IdentityProvidersStreamCursorValueInput;
  IdentityProvidersSumFields: ResolverTypeWrapper<IdentityProvidersSumFields>;
  IdentityProvidersUpdateColumn: IdentityProvidersUpdateColumn;
  IdentityProvidersUpdates: IdentityProvidersUpdates;
  IdentityProvidersVarPopFields: ResolverTypeWrapper<IdentityProvidersVarPopFields>;
  IdentityProvidersVarSampFields: ResolverTypeWrapper<IdentityProvidersVarSampFields>;
  IdentityProvidersVarianceFields: ResolverTypeWrapper<IdentityProvidersVarianceFields>;
  ImpactAssessmentAttempts: ResolverTypeWrapper<ImpactAssessmentAttempts>;
  ImpactAssessmentAttemptsAggregate: ResolverTypeWrapper<ImpactAssessmentAttemptsAggregate>;
  ImpactAssessmentAttemptsAggregateBoolExp: ImpactAssessmentAttemptsAggregateBoolExp;
  ImpactAssessmentAttemptsAggregateFields: ResolverTypeWrapper<ImpactAssessmentAttemptsAggregateFields>;
  ImpactAssessmentAttemptsAggregateOrderBy: ImpactAssessmentAttemptsAggregateOrderBy;
  ImpactAssessmentAttemptsArrRelInsertInput: ImpactAssessmentAttemptsArrRelInsertInput;
  ImpactAssessmentAttemptsAvgFields: ResolverTypeWrapper<ImpactAssessmentAttemptsAvgFields>;
  ImpactAssessmentAttemptsAvgOrderBy: ImpactAssessmentAttemptsAvgOrderBy;
  ImpactAssessmentAttemptsBoolExp: ImpactAssessmentAttemptsBoolExp;
  ImpactAssessmentAttemptsConstraint: ImpactAssessmentAttemptsConstraint;
  ImpactAssessmentAttemptsIncInput: ImpactAssessmentAttemptsIncInput;
  ImpactAssessmentAttemptsInsertInput: ImpactAssessmentAttemptsInsertInput;
  ImpactAssessmentAttemptsMaxFields: ResolverTypeWrapper<ImpactAssessmentAttemptsMaxFields>;
  ImpactAssessmentAttemptsMaxOrderBy: ImpactAssessmentAttemptsMaxOrderBy;
  ImpactAssessmentAttemptsMinFields: ResolverTypeWrapper<ImpactAssessmentAttemptsMinFields>;
  ImpactAssessmentAttemptsMinOrderBy: ImpactAssessmentAttemptsMinOrderBy;
  ImpactAssessmentAttemptsMutationResponse: ResolverTypeWrapper<ImpactAssessmentAttemptsMutationResponse>;
  ImpactAssessmentAttemptsOnConflict: ImpactAssessmentAttemptsOnConflict;
  ImpactAssessmentAttemptsOrderBy: ImpactAssessmentAttemptsOrderBy;
  ImpactAssessmentAttemptsPkColumnsInput: ImpactAssessmentAttemptsPkColumnsInput;
  ImpactAssessmentAttemptsSelectColumn: ImpactAssessmentAttemptsSelectColumn;
  ImpactAssessmentAttemptsSelectColumnImpactAssessmentAttemptsAggregateBoolExpBool_andArgumentsColumns: ImpactAssessmentAttemptsSelectColumnImpactAssessmentAttemptsAggregateBoolExpBool_AndArgumentsColumns;
  ImpactAssessmentAttemptsSelectColumnImpactAssessmentAttemptsAggregateBoolExpBool_orArgumentsColumns: ImpactAssessmentAttemptsSelectColumnImpactAssessmentAttemptsAggregateBoolExpBool_OrArgumentsColumns;
  ImpactAssessmentAttemptsSetInput: ImpactAssessmentAttemptsSetInput;
  ImpactAssessmentAttemptsStddevFields: ResolverTypeWrapper<ImpactAssessmentAttemptsStddevFields>;
  ImpactAssessmentAttemptsStddevOrderBy: ImpactAssessmentAttemptsStddevOrderBy;
  ImpactAssessmentAttemptsStddevPopFields: ResolverTypeWrapper<ImpactAssessmentAttemptsStddevPopFields>;
  ImpactAssessmentAttemptsStddevPopOrderBy: ImpactAssessmentAttemptsStddevPopOrderBy;
  ImpactAssessmentAttemptsStddevSampFields: ResolverTypeWrapper<ImpactAssessmentAttemptsStddevSampFields>;
  ImpactAssessmentAttemptsStddevSampOrderBy: ImpactAssessmentAttemptsStddevSampOrderBy;
  ImpactAssessmentAttemptsStreamCursorInput: ImpactAssessmentAttemptsStreamCursorInput;
  ImpactAssessmentAttemptsStreamCursorValueInput: ImpactAssessmentAttemptsStreamCursorValueInput;
  ImpactAssessmentAttemptsSumFields: ResolverTypeWrapper<ImpactAssessmentAttemptsSumFields>;
  ImpactAssessmentAttemptsSumOrderBy: ImpactAssessmentAttemptsSumOrderBy;
  ImpactAssessmentAttemptsUpdateColumn: ImpactAssessmentAttemptsUpdateColumn;
  ImpactAssessmentAttemptsUpdates: ImpactAssessmentAttemptsUpdates;
  ImpactAssessmentAttemptsVarPopFields: ResolverTypeWrapper<ImpactAssessmentAttemptsVarPopFields>;
  ImpactAssessmentAttemptsVarPopOrderBy: ImpactAssessmentAttemptsVarPopOrderBy;
  ImpactAssessmentAttemptsVarSampFields: ResolverTypeWrapper<ImpactAssessmentAttemptsVarSampFields>;
  ImpactAssessmentAttemptsVarSampOrderBy: ImpactAssessmentAttemptsVarSampOrderBy;
  ImpactAssessmentAttemptsVarianceFields: ResolverTypeWrapper<ImpactAssessmentAttemptsVarianceFields>;
  ImpactAssessmentAttemptsVarianceOrderBy: ImpactAssessmentAttemptsVarianceOrderBy;
  ImpactAssessmentQuestions: ResolverTypeWrapper<ImpactAssessmentQuestions>;
  ImpactAssessmentQuestionsAggregate: ResolverTypeWrapper<ImpactAssessmentQuestionsAggregate>;
  ImpactAssessmentQuestionsAggregateBoolExp: ImpactAssessmentQuestionsAggregateBoolExp;
  ImpactAssessmentQuestionsAggregateFields: ResolverTypeWrapper<ImpactAssessmentQuestionsAggregateFields>;
  ImpactAssessmentQuestionsAggregateOrderBy: ImpactAssessmentQuestionsAggregateOrderBy;
  ImpactAssessmentQuestionsArrRelInsertInput: ImpactAssessmentQuestionsArrRelInsertInput;
  ImpactAssessmentQuestionsAvgFields: ResolverTypeWrapper<ImpactAssessmentQuestionsAvgFields>;
  ImpactAssessmentQuestionsAvgOrderBy: ImpactAssessmentQuestionsAvgOrderBy;
  ImpactAssessmentQuestionsBoolExp: ImpactAssessmentQuestionsBoolExp;
  ImpactAssessmentQuestionsIncInput: ImpactAssessmentQuestionsIncInput;
  ImpactAssessmentQuestionsInsertInput: ImpactAssessmentQuestionsInsertInput;
  ImpactAssessmentQuestionsMaxFields: ResolverTypeWrapper<ImpactAssessmentQuestionsMaxFields>;
  ImpactAssessmentQuestionsMaxOrderBy: ImpactAssessmentQuestionsMaxOrderBy;
  ImpactAssessmentQuestionsMinFields: ResolverTypeWrapper<ImpactAssessmentQuestionsMinFields>;
  ImpactAssessmentQuestionsMinOrderBy: ImpactAssessmentQuestionsMinOrderBy;
  ImpactAssessmentQuestionsMutationResponse: ResolverTypeWrapper<ImpactAssessmentQuestionsMutationResponse>;
  ImpactAssessmentQuestionsOrderBy: ImpactAssessmentQuestionsOrderBy;
  ImpactAssessmentQuestionsSelectColumn: ImpactAssessmentQuestionsSelectColumn;
  ImpactAssessmentQuestionsSetInput: ImpactAssessmentQuestionsSetInput;
  ImpactAssessmentQuestionsStddevFields: ResolverTypeWrapper<ImpactAssessmentQuestionsStddevFields>;
  ImpactAssessmentQuestionsStddevOrderBy: ImpactAssessmentQuestionsStddevOrderBy;
  ImpactAssessmentQuestionsStddevPopFields: ResolverTypeWrapper<ImpactAssessmentQuestionsStddevPopFields>;
  ImpactAssessmentQuestionsStddevPopOrderBy: ImpactAssessmentQuestionsStddevPopOrderBy;
  ImpactAssessmentQuestionsStddevSampFields: ResolverTypeWrapper<ImpactAssessmentQuestionsStddevSampFields>;
  ImpactAssessmentQuestionsStddevSampOrderBy: ImpactAssessmentQuestionsStddevSampOrderBy;
  ImpactAssessmentQuestionsStreamCursorInput: ImpactAssessmentQuestionsStreamCursorInput;
  ImpactAssessmentQuestionsStreamCursorValueInput: ImpactAssessmentQuestionsStreamCursorValueInput;
  ImpactAssessmentQuestionsSumFields: ResolverTypeWrapper<ImpactAssessmentQuestionsSumFields>;
  ImpactAssessmentQuestionsSumOrderBy: ImpactAssessmentQuestionsSumOrderBy;
  ImpactAssessmentQuestionsUpdates: ImpactAssessmentQuestionsUpdates;
  ImpactAssessmentQuestionsVarPopFields: ResolverTypeWrapper<ImpactAssessmentQuestionsVarPopFields>;
  ImpactAssessmentQuestionsVarPopOrderBy: ImpactAssessmentQuestionsVarPopOrderBy;
  ImpactAssessmentQuestionsVarSampFields: ResolverTypeWrapper<ImpactAssessmentQuestionsVarSampFields>;
  ImpactAssessmentQuestionsVarSampOrderBy: ImpactAssessmentQuestionsVarSampOrderBy;
  ImpactAssessmentQuestionsVarianceFields: ResolverTypeWrapper<ImpactAssessmentQuestionsVarianceFields>;
  ImpactAssessmentQuestionsVarianceOrderBy: ImpactAssessmentQuestionsVarianceOrderBy;
  ImpactAssessments: ResolverTypeWrapper<ImpactAssessments>;
  ImpactAssessmentsAggregate: ResolverTypeWrapper<ImpactAssessmentsAggregate>;
  ImpactAssessmentsAggregateFields: ResolverTypeWrapper<ImpactAssessmentsAggregateFields>;
  ImpactAssessmentsAvgFields: ResolverTypeWrapper<ImpactAssessmentsAvgFields>;
  ImpactAssessmentsBoolExp: ImpactAssessmentsBoolExp;
  ImpactAssessmentsConstraint: ImpactAssessmentsConstraint;
  ImpactAssessmentsIncInput: ImpactAssessmentsIncInput;
  ImpactAssessmentsInsertInput: ImpactAssessmentsInsertInput;
  ImpactAssessmentsMaxFields: ResolverTypeWrapper<ImpactAssessmentsMaxFields>;
  ImpactAssessmentsMinFields: ResolverTypeWrapper<ImpactAssessmentsMinFields>;
  ImpactAssessmentsMutationResponse: ResolverTypeWrapper<ImpactAssessmentsMutationResponse>;
  ImpactAssessmentsObjRelInsertInput: ImpactAssessmentsObjRelInsertInput;
  ImpactAssessmentsOnConflict: ImpactAssessmentsOnConflict;
  ImpactAssessmentsOrderBy: ImpactAssessmentsOrderBy;
  ImpactAssessmentsPkColumnsInput: ImpactAssessmentsPkColumnsInput;
  ImpactAssessmentsSelectColumn: ImpactAssessmentsSelectColumn;
  ImpactAssessmentsSetInput: ImpactAssessmentsSetInput;
  ImpactAssessmentsStddevFields: ResolverTypeWrapper<ImpactAssessmentsStddevFields>;
  ImpactAssessmentsStddevPopFields: ResolverTypeWrapper<ImpactAssessmentsStddevPopFields>;
  ImpactAssessmentsStddevSampFields: ResolverTypeWrapper<ImpactAssessmentsStddevSampFields>;
  ImpactAssessmentsStreamCursorInput: ImpactAssessmentsStreamCursorInput;
  ImpactAssessmentsStreamCursorValueInput: ImpactAssessmentsStreamCursorValueInput;
  ImpactAssessmentsSumFields: ResolverTypeWrapper<ImpactAssessmentsSumFields>;
  ImpactAssessmentsUpdateColumn: ImpactAssessmentsUpdateColumn;
  ImpactAssessmentsUpdates: ImpactAssessmentsUpdates;
  ImpactAssessmentsVarPopFields: ResolverTypeWrapper<ImpactAssessmentsVarPopFields>;
  ImpactAssessmentsVarSampFields: ResolverTypeWrapper<ImpactAssessmentsVarSampFields>;
  ImpactAssessmentsVarianceFields: ResolverTypeWrapper<ImpactAssessmentsVarianceFields>;
  ImportUser: ImportUser;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  IntComparisonExp: IntComparisonExp;
  JourneyEnrollments: ResolverTypeWrapper<JourneyEnrollments>;
  JourneyEnrollmentsAggregate: ResolverTypeWrapper<JourneyEnrollmentsAggregate>;
  JourneyEnrollmentsAggregateBoolExp: JourneyEnrollmentsAggregateBoolExp;
  JourneyEnrollmentsAggregateFields: ResolverTypeWrapper<JourneyEnrollmentsAggregateFields>;
  JourneyEnrollmentsAggregateOrderBy: JourneyEnrollmentsAggregateOrderBy;
  JourneyEnrollmentsAppendInput: JourneyEnrollmentsAppendInput;
  JourneyEnrollmentsArrRelInsertInput: JourneyEnrollmentsArrRelInsertInput;
  JourneyEnrollmentsBoolExp: JourneyEnrollmentsBoolExp;
  JourneyEnrollmentsConstraint: JourneyEnrollmentsConstraint;
  JourneyEnrollmentsDeleteAtPathInput: JourneyEnrollmentsDeleteAtPathInput;
  JourneyEnrollmentsDeleteElemInput: JourneyEnrollmentsDeleteElemInput;
  JourneyEnrollmentsDeleteKeyInput: JourneyEnrollmentsDeleteKeyInput;
  JourneyEnrollmentsInsertInput: JourneyEnrollmentsInsertInput;
  JourneyEnrollmentsMaxFields: ResolverTypeWrapper<JourneyEnrollmentsMaxFields>;
  JourneyEnrollmentsMaxOrderBy: JourneyEnrollmentsMaxOrderBy;
  JourneyEnrollmentsMinFields: ResolverTypeWrapper<JourneyEnrollmentsMinFields>;
  JourneyEnrollmentsMinOrderBy: JourneyEnrollmentsMinOrderBy;
  JourneyEnrollmentsMutationResponse: ResolverTypeWrapper<JourneyEnrollmentsMutationResponse>;
  JourneyEnrollmentsOnConflict: JourneyEnrollmentsOnConflict;
  JourneyEnrollmentsOrderBy: JourneyEnrollmentsOrderBy;
  JourneyEnrollmentsPkColumnsInput: JourneyEnrollmentsPkColumnsInput;
  JourneyEnrollmentsPrependInput: JourneyEnrollmentsPrependInput;
  JourneyEnrollmentsSelectColumn: JourneyEnrollmentsSelectColumn;
  JourneyEnrollmentsSelectColumnJourneyEnrollmentsAggregateBoolExpBool_andArgumentsColumns: JourneyEnrollmentsSelectColumnJourneyEnrollmentsAggregateBoolExpBool_AndArgumentsColumns;
  JourneyEnrollmentsSelectColumnJourneyEnrollmentsAggregateBoolExpBool_orArgumentsColumns: JourneyEnrollmentsSelectColumnJourneyEnrollmentsAggregateBoolExpBool_OrArgumentsColumns;
  JourneyEnrollmentsSetInput: JourneyEnrollmentsSetInput;
  JourneyEnrollmentsStreamCursorInput: JourneyEnrollmentsStreamCursorInput;
  JourneyEnrollmentsStreamCursorValueInput: JourneyEnrollmentsStreamCursorValueInput;
  JourneyEnrollmentsUpdateColumn: JourneyEnrollmentsUpdateColumn;
  JourneyEnrollmentsUpdates: JourneyEnrollmentsUpdates;
  Journeys: ResolverTypeWrapper<Journeys>;
  JourneysAggregate: ResolverTypeWrapper<JourneysAggregate>;
  JourneysAggregateBoolExp: JourneysAggregateBoolExp;
  JourneysAggregateFields: ResolverTypeWrapper<JourneysAggregateFields>;
  JourneysAggregateOrderBy: JourneysAggregateOrderBy;
  JourneysArrRelInsertInput: JourneysArrRelInsertInput;
  JourneysBoolExp: JourneysBoolExp;
  JourneysConstraint: JourneysConstraint;
  JourneysInsertInput: JourneysInsertInput;
  JourneysMaxFields: ResolverTypeWrapper<JourneysMaxFields>;
  JourneysMaxOrderBy: JourneysMaxOrderBy;
  JourneysMinFields: ResolverTypeWrapper<JourneysMinFields>;
  JourneysMinOrderBy: JourneysMinOrderBy;
  JourneysMutationResponse: ResolverTypeWrapper<JourneysMutationResponse>;
  JourneysObjRelInsertInput: JourneysObjRelInsertInput;
  JourneysOnConflict: JourneysOnConflict;
  JourneysOrderBy: JourneysOrderBy;
  JourneysPkColumnsInput: JourneysPkColumnsInput;
  JourneysSelectColumn: JourneysSelectColumn;
  JourneysSelectColumnJourneysAggregateBoolExpBool_andArgumentsColumns: JourneysSelectColumnJourneysAggregateBoolExpBool_AndArgumentsColumns;
  JourneysSelectColumnJourneysAggregateBoolExpBool_orArgumentsColumns: JourneysSelectColumnJourneysAggregateBoolExpBool_OrArgumentsColumns;
  JourneysSetInput: JourneysSetInput;
  JourneysStreamCursorInput: JourneysStreamCursorInput;
  JourneysStreamCursorValueInput: JourneysStreamCursorValueInput;
  JourneysUpdateColumn: JourneysUpdateColumn;
  JourneysUpdates: JourneysUpdates;
  JsonComparisonExp: JsonComparisonExp;
  JsonbCastExp: JsonbCastExp;
  JsonbComparisonExp: JsonbComparisonExp;
  Ledger: ResolverTypeWrapper<Ledger>;
  LedgerAggregate: ResolverTypeWrapper<LedgerAggregate>;
  LedgerAggregateFields: ResolverTypeWrapper<LedgerAggregateFields>;
  LedgerAvgFields: ResolverTypeWrapper<LedgerAvgFields>;
  LedgerBoolExp: LedgerBoolExp;
  LedgerConstraint: LedgerConstraint;
  LedgerIncInput: LedgerIncInput;
  LedgerInsertInput: LedgerInsertInput;
  LedgerMaxFields: ResolverTypeWrapper<LedgerMaxFields>;
  LedgerMinFields: ResolverTypeWrapper<LedgerMinFields>;
  LedgerMutationResponse: ResolverTypeWrapper<LedgerMutationResponse>;
  LedgerOnConflict: LedgerOnConflict;
  LedgerOrderBy: LedgerOrderBy;
  LedgerPkColumnsInput: LedgerPkColumnsInput;
  LedgerProductEnum: ResolverTypeWrapper<LedgerProductEnum>;
  LedgerProductEnumAggregate: ResolverTypeWrapper<LedgerProductEnumAggregate>;
  LedgerProductEnumAggregateFields: ResolverTypeWrapper<LedgerProductEnumAggregateFields>;
  LedgerProductEnumBoolExp: LedgerProductEnumBoolExp;
  LedgerProductEnumConstraint: LedgerProductEnumConstraint;
  LedgerProductEnumEnum: LedgerProductEnumEnum;
  LedgerProductEnumEnumComparisonExp: LedgerProductEnumEnumComparisonExp;
  LedgerProductEnumInsertInput: LedgerProductEnumInsertInput;
  LedgerProductEnumMaxFields: ResolverTypeWrapper<LedgerProductEnumMaxFields>;
  LedgerProductEnumMinFields: ResolverTypeWrapper<LedgerProductEnumMinFields>;
  LedgerProductEnumMutationResponse: ResolverTypeWrapper<LedgerProductEnumMutationResponse>;
  LedgerProductEnumOnConflict: LedgerProductEnumOnConflict;
  LedgerProductEnumOrderBy: LedgerProductEnumOrderBy;
  LedgerProductEnumPkColumnsInput: LedgerProductEnumPkColumnsInput;
  LedgerProductEnumSelectColumn: LedgerProductEnumSelectColumn;
  LedgerProductEnumSetInput: LedgerProductEnumSetInput;
  LedgerProductEnumStreamCursorInput: LedgerProductEnumStreamCursorInput;
  LedgerProductEnumStreamCursorValueInput: LedgerProductEnumStreamCursorValueInput;
  LedgerProductEnumUpdateColumn: LedgerProductEnumUpdateColumn;
  LedgerProductEnumUpdates: LedgerProductEnumUpdates;
  LedgerSelectColumn: LedgerSelectColumn;
  LedgerSetInput: LedgerSetInput;
  LedgerStddevFields: ResolverTypeWrapper<LedgerStddevFields>;
  LedgerStddevPopFields: ResolverTypeWrapper<LedgerStddevPopFields>;
  LedgerStddevSampFields: ResolverTypeWrapper<LedgerStddevSampFields>;
  LedgerStreamCursorInput: LedgerStreamCursorInput;
  LedgerStreamCursorValueInput: LedgerStreamCursorValueInput;
  LedgerSumFields: ResolverTypeWrapper<LedgerSumFields>;
  LedgerTransactionTypeEnum: ResolverTypeWrapper<LedgerTransactionTypeEnum>;
  LedgerTransactionTypeEnumAggregate: ResolverTypeWrapper<LedgerTransactionTypeEnumAggregate>;
  LedgerTransactionTypeEnumAggregateFields: ResolverTypeWrapper<LedgerTransactionTypeEnumAggregateFields>;
  LedgerTransactionTypeEnumBoolExp: LedgerTransactionTypeEnumBoolExp;
  LedgerTransactionTypeEnumConstraint: LedgerTransactionTypeEnumConstraint;
  LedgerTransactionTypeEnumEnum: LedgerTransactionTypeEnumEnum;
  LedgerTransactionTypeEnumEnumComparisonExp: LedgerTransactionTypeEnumEnumComparisonExp;
  LedgerTransactionTypeEnumInsertInput: LedgerTransactionTypeEnumInsertInput;
  LedgerTransactionTypeEnumMaxFields: ResolverTypeWrapper<LedgerTransactionTypeEnumMaxFields>;
  LedgerTransactionTypeEnumMinFields: ResolverTypeWrapper<LedgerTransactionTypeEnumMinFields>;
  LedgerTransactionTypeEnumMutationResponse: ResolverTypeWrapper<LedgerTransactionTypeEnumMutationResponse>;
  LedgerTransactionTypeEnumOnConflict: LedgerTransactionTypeEnumOnConflict;
  LedgerTransactionTypeEnumOrderBy: LedgerTransactionTypeEnumOrderBy;
  LedgerTransactionTypeEnumPkColumnsInput: LedgerTransactionTypeEnumPkColumnsInput;
  LedgerTransactionTypeEnumSelectColumn: LedgerTransactionTypeEnumSelectColumn;
  LedgerTransactionTypeEnumSetInput: LedgerTransactionTypeEnumSetInput;
  LedgerTransactionTypeEnumStreamCursorInput: LedgerTransactionTypeEnumStreamCursorInput;
  LedgerTransactionTypeEnumStreamCursorValueInput: LedgerTransactionTypeEnumStreamCursorValueInput;
  LedgerTransactionTypeEnumUpdateColumn: LedgerTransactionTypeEnumUpdateColumn;
  LedgerTransactionTypeEnumUpdates: LedgerTransactionTypeEnumUpdates;
  LedgerUpdateColumn: LedgerUpdateColumn;
  LedgerUpdates: LedgerUpdates;
  LedgerVarPopFields: ResolverTypeWrapper<LedgerVarPopFields>;
  LedgerVarSampFields: ResolverTypeWrapper<LedgerVarSampFields>;
  LedgerVarianceFields: ResolverTypeWrapper<LedgerVarianceFields>;
  LightningSession: ResolverTypeWrapper<LightningSession>;
  LightningSessionAggregate: ResolverTypeWrapper<LightningSessionAggregate>;
  LightningSessionAggregateFields: ResolverTypeWrapper<LightningSessionAggregateFields>;
  LightningSessionAppendInput: LightningSessionAppendInput;
  LightningSessionBoolExp: LightningSessionBoolExp;
  LightningSessionConstraint: LightningSessionConstraint;
  LightningSessionDeleteAtPathInput: LightningSessionDeleteAtPathInput;
  LightningSessionDeleteElemInput: LightningSessionDeleteElemInput;
  LightningSessionDeleteKeyInput: LightningSessionDeleteKeyInput;
  LightningSessionInsertInput: LightningSessionInsertInput;
  LightningSessionMaxFields: ResolverTypeWrapper<LightningSessionMaxFields>;
  LightningSessionMinFields: ResolverTypeWrapper<LightningSessionMinFields>;
  LightningSessionMutationResponse: ResolverTypeWrapper<LightningSessionMutationResponse>;
  LightningSessionObjRelInsertInput: LightningSessionObjRelInsertInput;
  LightningSessionOnConflict: LightningSessionOnConflict;
  LightningSessionOrderBy: LightningSessionOrderBy;
  LightningSessionPkColumnsInput: LightningSessionPkColumnsInput;
  LightningSessionPrependInput: LightningSessionPrependInput;
  LightningSessionSelectColumn: LightningSessionSelectColumn;
  LightningSessionSetInput: LightningSessionSetInput;
  LightningSessionStreamCursorInput: LightningSessionStreamCursorInput;
  LightningSessionStreamCursorValueInput: LightningSessionStreamCursorValueInput;
  LightningSessionSubmissions: ResolverTypeWrapper<LightningSessionSubmissions>;
  LightningSessionSubmissionsAggregate: ResolverTypeWrapper<LightningSessionSubmissionsAggregate>;
  LightningSessionSubmissionsAggregateBoolExp: LightningSessionSubmissionsAggregateBoolExp;
  LightningSessionSubmissionsAggregateFields: ResolverTypeWrapper<LightningSessionSubmissionsAggregateFields>;
  LightningSessionSubmissionsAggregateOrderBy: LightningSessionSubmissionsAggregateOrderBy;
  LightningSessionSubmissionsArrRelInsertInput: LightningSessionSubmissionsArrRelInsertInput;
  LightningSessionSubmissionsAvgFields: ResolverTypeWrapper<LightningSessionSubmissionsAvgFields>;
  LightningSessionSubmissionsAvgOrderBy: LightningSessionSubmissionsAvgOrderBy;
  LightningSessionSubmissionsBoolExp: LightningSessionSubmissionsBoolExp;
  LightningSessionSubmissionsConstraint: LightningSessionSubmissionsConstraint;
  LightningSessionSubmissionsIncInput: LightningSessionSubmissionsIncInput;
  LightningSessionSubmissionsInsertInput: LightningSessionSubmissionsInsertInput;
  LightningSessionSubmissionsMaxFields: ResolverTypeWrapper<LightningSessionSubmissionsMaxFields>;
  LightningSessionSubmissionsMaxOrderBy: LightningSessionSubmissionsMaxOrderBy;
  LightningSessionSubmissionsMinFields: ResolverTypeWrapper<LightningSessionSubmissionsMinFields>;
  LightningSessionSubmissionsMinOrderBy: LightningSessionSubmissionsMinOrderBy;
  LightningSessionSubmissionsMutationResponse: ResolverTypeWrapper<LightningSessionSubmissionsMutationResponse>;
  LightningSessionSubmissionsObjRelInsertInput: LightningSessionSubmissionsObjRelInsertInput;
  LightningSessionSubmissionsOnConflict: LightningSessionSubmissionsOnConflict;
  LightningSessionSubmissionsOrderBy: LightningSessionSubmissionsOrderBy;
  LightningSessionSubmissionsPkColumnsInput: LightningSessionSubmissionsPkColumnsInput;
  LightningSessionSubmissionsSelectColumn: LightningSessionSubmissionsSelectColumn;
  LightningSessionSubmissionsSelectColumnLightningSessionSubmissionsAggregateBoolExpBool_andArgumentsColumns: LightningSessionSubmissionsSelectColumnLightningSessionSubmissionsAggregateBoolExpBool_AndArgumentsColumns;
  LightningSessionSubmissionsSelectColumnLightningSessionSubmissionsAggregateBoolExpBool_orArgumentsColumns: LightningSessionSubmissionsSelectColumnLightningSessionSubmissionsAggregateBoolExpBool_OrArgumentsColumns;
  LightningSessionSubmissionsSetInput: LightningSessionSubmissionsSetInput;
  LightningSessionSubmissionsStatusEnumComparisonExp: LightningSessionSubmissionsStatusEnumComparisonExp;
  LightningSessionSubmissionsStddevFields: ResolverTypeWrapper<LightningSessionSubmissionsStddevFields>;
  LightningSessionSubmissionsStddevOrderBy: LightningSessionSubmissionsStddevOrderBy;
  LightningSessionSubmissionsStddevPopFields: ResolverTypeWrapper<LightningSessionSubmissionsStddevPopFields>;
  LightningSessionSubmissionsStddevPopOrderBy: LightningSessionSubmissionsStddevPopOrderBy;
  LightningSessionSubmissionsStddevSampFields: ResolverTypeWrapper<LightningSessionSubmissionsStddevSampFields>;
  LightningSessionSubmissionsStddevSampOrderBy: LightningSessionSubmissionsStddevSampOrderBy;
  LightningSessionSubmissionsStreamCursorInput: LightningSessionSubmissionsStreamCursorInput;
  LightningSessionSubmissionsStreamCursorValueInput: LightningSessionSubmissionsStreamCursorValueInput;
  LightningSessionSubmissionsSumFields: ResolverTypeWrapper<LightningSessionSubmissionsSumFields>;
  LightningSessionSubmissionsSumOrderBy: LightningSessionSubmissionsSumOrderBy;
  LightningSessionSubmissionsUpdateColumn: LightningSessionSubmissionsUpdateColumn;
  LightningSessionSubmissionsUpdates: LightningSessionSubmissionsUpdates;
  LightningSessionSubmissionsUsersRel: ResolverTypeWrapper<LightningSessionSubmissionsUsersRel>;
  LightningSessionSubmissionsUsersRelAggregate: ResolverTypeWrapper<LightningSessionSubmissionsUsersRelAggregate>;
  LightningSessionSubmissionsUsersRelAggregateBoolExp: LightningSessionSubmissionsUsersRelAggregateBoolExp;
  LightningSessionSubmissionsUsersRelAggregateFields: ResolverTypeWrapper<LightningSessionSubmissionsUsersRelAggregateFields>;
  LightningSessionSubmissionsUsersRelAggregateOrderBy: LightningSessionSubmissionsUsersRelAggregateOrderBy;
  LightningSessionSubmissionsUsersRelArrRelInsertInput: LightningSessionSubmissionsUsersRelArrRelInsertInput;
  LightningSessionSubmissionsUsersRelBoolExp: LightningSessionSubmissionsUsersRelBoolExp;
  LightningSessionSubmissionsUsersRelConstraint: LightningSessionSubmissionsUsersRelConstraint;
  LightningSessionSubmissionsUsersRelInsertInput: LightningSessionSubmissionsUsersRelInsertInput;
  LightningSessionSubmissionsUsersRelMaxFields: ResolverTypeWrapper<LightningSessionSubmissionsUsersRelMaxFields>;
  LightningSessionSubmissionsUsersRelMaxOrderBy: LightningSessionSubmissionsUsersRelMaxOrderBy;
  LightningSessionSubmissionsUsersRelMinFields: ResolverTypeWrapper<LightningSessionSubmissionsUsersRelMinFields>;
  LightningSessionSubmissionsUsersRelMinOrderBy: LightningSessionSubmissionsUsersRelMinOrderBy;
  LightningSessionSubmissionsUsersRelMutationResponse: ResolverTypeWrapper<LightningSessionSubmissionsUsersRelMutationResponse>;
  LightningSessionSubmissionsUsersRelOnConflict: LightningSessionSubmissionsUsersRelOnConflict;
  LightningSessionSubmissionsUsersRelOrderBy: LightningSessionSubmissionsUsersRelOrderBy;
  LightningSessionSubmissionsUsersRelPkColumnsInput: LightningSessionSubmissionsUsersRelPkColumnsInput;
  LightningSessionSubmissionsUsersRelSelectColumn: LightningSessionSubmissionsUsersRelSelectColumn;
  LightningSessionSubmissionsUsersRelSetInput: LightningSessionSubmissionsUsersRelSetInput;
  LightningSessionSubmissionsUsersRelStreamCursorInput: LightningSessionSubmissionsUsersRelStreamCursorInput;
  LightningSessionSubmissionsUsersRelStreamCursorValueInput: LightningSessionSubmissionsUsersRelStreamCursorValueInput;
  LightningSessionSubmissionsUsersRelUpdateColumn: LightningSessionSubmissionsUsersRelUpdateColumn;
  LightningSessionSubmissionsUsersRelUpdates: LightningSessionSubmissionsUsersRelUpdates;
  LightningSessionSubmissionsVarPopFields: ResolverTypeWrapper<LightningSessionSubmissionsVarPopFields>;
  LightningSessionSubmissionsVarPopOrderBy: LightningSessionSubmissionsVarPopOrderBy;
  LightningSessionSubmissionsVarSampFields: ResolverTypeWrapper<LightningSessionSubmissionsVarSampFields>;
  LightningSessionSubmissionsVarSampOrderBy: LightningSessionSubmissionsVarSampOrderBy;
  LightningSessionSubmissionsVarianceFields: ResolverTypeWrapper<LightningSessionSubmissionsVarianceFields>;
  LightningSessionSubmissionsVarianceOrderBy: LightningSessionSubmissionsVarianceOrderBy;
  LightningSessionUpdateColumn: LightningSessionUpdateColumn;
  LightningSessionUpdates: LightningSessionUpdates;
  LiveClasses: ResolverTypeWrapper<LiveClasses>;
  LiveClassesAggregate: ResolverTypeWrapper<LiveClassesAggregate>;
  LiveClassesAggregateFields: ResolverTypeWrapper<LiveClassesAggregateFields>;
  LiveClassesAvgFields: ResolverTypeWrapper<LiveClassesAvgFields>;
  LiveClassesBoolExp: LiveClassesBoolExp;
  LiveClassesMaxFields: ResolverTypeWrapper<LiveClassesMaxFields>;
  LiveClassesMinFields: ResolverTypeWrapper<LiveClassesMinFields>;
  LiveClassesOrderBy: LiveClassesOrderBy;
  LiveClassesSelectColumn: LiveClassesSelectColumn;
  LiveClassesStddevFields: ResolverTypeWrapper<LiveClassesStddevFields>;
  LiveClassesStddevPopFields: ResolverTypeWrapper<LiveClassesStddevPopFields>;
  LiveClassesStddevSampFields: ResolverTypeWrapper<LiveClassesStddevSampFields>;
  LiveClassesStreamCursorInput: LiveClassesStreamCursorInput;
  LiveClassesStreamCursorValueInput: LiveClassesStreamCursorValueInput;
  LiveClassesSumFields: ResolverTypeWrapper<LiveClassesSumFields>;
  LiveClassesVarPopFields: ResolverTypeWrapper<LiveClassesVarPopFields>;
  LiveClassesVarSampFields: ResolverTypeWrapper<LiveClassesVarSampFields>;
  LiveClassesVarianceFields: ResolverTypeWrapper<LiveClassesVarianceFields>;
  ManagerSessionEmails: ResolverTypeWrapper<ManagerSessionEmails>;
  ManagerSessionEmailsAggregate: ResolverTypeWrapper<ManagerSessionEmailsAggregate>;
  ManagerSessionEmailsAggregateFields: ResolverTypeWrapper<ManagerSessionEmailsAggregateFields>;
  ManagerSessionEmailsAvgFields: ResolverTypeWrapper<ManagerSessionEmailsAvgFields>;
  ManagerSessionEmailsBoolExp: ManagerSessionEmailsBoolExp;
  ManagerSessionEmailsConstraint: ManagerSessionEmailsConstraint;
  ManagerSessionEmailsIncInput: ManagerSessionEmailsIncInput;
  ManagerSessionEmailsInsertInput: ManagerSessionEmailsInsertInput;
  ManagerSessionEmailsMaxFields: ResolverTypeWrapper<ManagerSessionEmailsMaxFields>;
  ManagerSessionEmailsMinFields: ResolverTypeWrapper<ManagerSessionEmailsMinFields>;
  ManagerSessionEmailsMutationResponse: ResolverTypeWrapper<ManagerSessionEmailsMutationResponse>;
  ManagerSessionEmailsOnConflict: ManagerSessionEmailsOnConflict;
  ManagerSessionEmailsOrderBy: ManagerSessionEmailsOrderBy;
  ManagerSessionEmailsPkColumnsInput: ManagerSessionEmailsPkColumnsInput;
  ManagerSessionEmailsSelectColumn: ManagerSessionEmailsSelectColumn;
  ManagerSessionEmailsSetInput: ManagerSessionEmailsSetInput;
  ManagerSessionEmailsStddevFields: ResolverTypeWrapper<ManagerSessionEmailsStddevFields>;
  ManagerSessionEmailsStddevPopFields: ResolverTypeWrapper<ManagerSessionEmailsStddevPopFields>;
  ManagerSessionEmailsStddevSampFields: ResolverTypeWrapper<ManagerSessionEmailsStddevSampFields>;
  ManagerSessionEmailsStreamCursorInput: ManagerSessionEmailsStreamCursorInput;
  ManagerSessionEmailsStreamCursorValueInput: ManagerSessionEmailsStreamCursorValueInput;
  ManagerSessionEmailsSumFields: ResolverTypeWrapper<ManagerSessionEmailsSumFields>;
  ManagerSessionEmailsUpdateColumn: ManagerSessionEmailsUpdateColumn;
  ManagerSessionEmailsUpdates: ManagerSessionEmailsUpdates;
  ManagerSessionEmailsVarPopFields: ResolverTypeWrapper<ManagerSessionEmailsVarPopFields>;
  ManagerSessionEmailsVarSampFields: ResolverTypeWrapper<ManagerSessionEmailsVarSampFields>;
  ManagerSessionEmailsVarianceFields: ResolverTypeWrapper<ManagerSessionEmailsVarianceFields>;
  MarkRecordingWatchedOutput: ResolverTypeWrapper<MarkRecordingWatchedOutput>;
  MyCohortLearningStatus: ResolverTypeWrapper<MyCohortLearningStatus>;
  MyCohortLearningStatusAggregate: ResolverTypeWrapper<MyCohortLearningStatusAggregate>;
  MyCohortLearningStatusAggregateFields: ResolverTypeWrapper<MyCohortLearningStatusAggregateFields>;
  MyCohortLearningStatusAvgFields: ResolverTypeWrapper<MyCohortLearningStatusAvgFields>;
  MyCohortLearningStatusBoolExp: MyCohortLearningStatusBoolExp;
  MyCohortLearningStatusMaxFields: ResolverTypeWrapper<MyCohortLearningStatusMaxFields>;
  MyCohortLearningStatusMinFields: ResolverTypeWrapper<MyCohortLearningStatusMinFields>;
  MyCohortLearningStatusOrderBy: MyCohortLearningStatusOrderBy;
  MyCohortLearningStatusSelectColumn: MyCohortLearningStatusSelectColumn;
  MyCohortLearningStatusStddevFields: ResolverTypeWrapper<MyCohortLearningStatusStddevFields>;
  MyCohortLearningStatusStddevPopFields: ResolverTypeWrapper<MyCohortLearningStatusStddevPopFields>;
  MyCohortLearningStatusStddevSampFields: ResolverTypeWrapper<MyCohortLearningStatusStddevSampFields>;
  MyCohortLearningStatusStreamCursorInput: MyCohortLearningStatusStreamCursorInput;
  MyCohortLearningStatusStreamCursorValueInput: MyCohortLearningStatusStreamCursorValueInput;
  MyCohortLearningStatusSumFields: ResolverTypeWrapper<MyCohortLearningStatusSumFields>;
  MyCohortLearningStatusVarPopFields: ResolverTypeWrapper<MyCohortLearningStatusVarPopFields>;
  MyCohortLearningStatusVarSampFields: ResolverTypeWrapper<MyCohortLearningStatusVarSampFields>;
  MyCohortLearningStatusVarianceFields: ResolverTypeWrapper<MyCohortLearningStatusVarianceFields>;
  MyLearnerHomeCourses: ResolverTypeWrapper<MyLearnerHomeCourses>;
  MyLearnerHomeCoursesAggregate: ResolverTypeWrapper<MyLearnerHomeCoursesAggregate>;
  MyLearnerHomeCoursesAggregateFields: ResolverTypeWrapper<MyLearnerHomeCoursesAggregateFields>;
  MyLearnerHomeCoursesAvgFields: ResolverTypeWrapper<MyLearnerHomeCoursesAvgFields>;
  MyLearnerHomeCoursesBoolExp: MyLearnerHomeCoursesBoolExp;
  MyLearnerHomeCoursesMaxFields: ResolverTypeWrapper<MyLearnerHomeCoursesMaxFields>;
  MyLearnerHomeCoursesMinFields: ResolverTypeWrapper<MyLearnerHomeCoursesMinFields>;
  MyLearnerHomeCoursesOrderBy: MyLearnerHomeCoursesOrderBy;
  MyLearnerHomeCoursesSelectColumn: MyLearnerHomeCoursesSelectColumn;
  MyLearnerHomeCoursesStddevFields: ResolverTypeWrapper<MyLearnerHomeCoursesStddevFields>;
  MyLearnerHomeCoursesStddevPopFields: ResolverTypeWrapper<MyLearnerHomeCoursesStddevPopFields>;
  MyLearnerHomeCoursesStddevSampFields: ResolverTypeWrapper<MyLearnerHomeCoursesStddevSampFields>;
  MyLearnerHomeCoursesStreamCursorInput: MyLearnerHomeCoursesStreamCursorInput;
  MyLearnerHomeCoursesStreamCursorValueInput: MyLearnerHomeCoursesStreamCursorValueInput;
  MyLearnerHomeCoursesSumFields: ResolverTypeWrapper<MyLearnerHomeCoursesSumFields>;
  MyLearnerHomeCoursesVarPopFields: ResolverTypeWrapper<MyLearnerHomeCoursesVarPopFields>;
  MyLearnerHomeCoursesVarSampFields: ResolverTypeWrapper<MyLearnerHomeCoursesVarSampFields>;
  MyLearnerHomeCoursesVarianceFields: ResolverTypeWrapper<MyLearnerHomeCoursesVarianceFields>;
  MyLearnerHomeLiveCoaching: ResolverTypeWrapper<MyLearnerHomeLiveCoaching>;
  MyLearnerHomeLiveCoachingAggregate: ResolverTypeWrapper<MyLearnerHomeLiveCoachingAggregate>;
  MyLearnerHomeLiveCoachingAggregateFields: ResolverTypeWrapper<MyLearnerHomeLiveCoachingAggregateFields>;
  MyLearnerHomeLiveCoachingAvgFields: ResolverTypeWrapper<MyLearnerHomeLiveCoachingAvgFields>;
  MyLearnerHomeLiveCoachingBoolExp: MyLearnerHomeLiveCoachingBoolExp;
  MyLearnerHomeLiveCoachingMaxFields: ResolverTypeWrapper<MyLearnerHomeLiveCoachingMaxFields>;
  MyLearnerHomeLiveCoachingMinFields: ResolverTypeWrapper<MyLearnerHomeLiveCoachingMinFields>;
  MyLearnerHomeLiveCoachingOrderBy: MyLearnerHomeLiveCoachingOrderBy;
  MyLearnerHomeLiveCoachingSelectColumn: MyLearnerHomeLiveCoachingSelectColumn;
  MyLearnerHomeLiveCoachingStddevFields: ResolverTypeWrapper<MyLearnerHomeLiveCoachingStddevFields>;
  MyLearnerHomeLiveCoachingStddevPopFields: ResolverTypeWrapper<MyLearnerHomeLiveCoachingStddevPopFields>;
  MyLearnerHomeLiveCoachingStddevSampFields: ResolverTypeWrapper<MyLearnerHomeLiveCoachingStddevSampFields>;
  MyLearnerHomeLiveCoachingStreamCursorInput: MyLearnerHomeLiveCoachingStreamCursorInput;
  MyLearnerHomeLiveCoachingStreamCursorValueInput: MyLearnerHomeLiveCoachingStreamCursorValueInput;
  MyLearnerHomeLiveCoachingSumFields: ResolverTypeWrapper<MyLearnerHomeLiveCoachingSumFields>;
  MyLearnerHomeLiveCoachingVarPopFields: ResolverTypeWrapper<MyLearnerHomeLiveCoachingVarPopFields>;
  MyLearnerHomeLiveCoachingVarSampFields: ResolverTypeWrapper<MyLearnerHomeLiveCoachingVarSampFields>;
  MyLearnerHomeLiveCoachingVarianceFields: ResolverTypeWrapper<MyLearnerHomeLiveCoachingVarianceFields>;
  MyLightningSessionCohortsLearningStatus: ResolverTypeWrapper<MyLightningSessionCohortsLearningStatus>;
  MyLightningSessionCohortsLearningStatusAggregate: ResolverTypeWrapper<MyLightningSessionCohortsLearningStatusAggregate>;
  MyLightningSessionCohortsLearningStatusAggregateFields: ResolverTypeWrapper<MyLightningSessionCohortsLearningStatusAggregateFields>;
  MyLightningSessionCohortsLearningStatusAvgFields: ResolverTypeWrapper<MyLightningSessionCohortsLearningStatusAvgFields>;
  MyLightningSessionCohortsLearningStatusBoolExp: MyLightningSessionCohortsLearningStatusBoolExp;
  MyLightningSessionCohortsLearningStatusMaxFields: ResolverTypeWrapper<MyLightningSessionCohortsLearningStatusMaxFields>;
  MyLightningSessionCohortsLearningStatusMinFields: ResolverTypeWrapper<MyLightningSessionCohortsLearningStatusMinFields>;
  MyLightningSessionCohortsLearningStatusOrderBy: MyLightningSessionCohortsLearningStatusOrderBy;
  MyLightningSessionCohortsLearningStatusSelectColumn: MyLightningSessionCohortsLearningStatusSelectColumn;
  MyLightningSessionCohortsLearningStatusStddevFields: ResolverTypeWrapper<MyLightningSessionCohortsLearningStatusStddevFields>;
  MyLightningSessionCohortsLearningStatusStddevPopFields: ResolverTypeWrapper<MyLightningSessionCohortsLearningStatusStddevPopFields>;
  MyLightningSessionCohortsLearningStatusStddevSampFields: ResolverTypeWrapper<MyLightningSessionCohortsLearningStatusStddevSampFields>;
  MyLightningSessionCohortsLearningStatusStreamCursorInput: MyLightningSessionCohortsLearningStatusStreamCursorInput;
  MyLightningSessionCohortsLearningStatusStreamCursorValueInput: MyLightningSessionCohortsLearningStatusStreamCursorValueInput;
  MyLightningSessionCohortsLearningStatusSumFields: ResolverTypeWrapper<MyLightningSessionCohortsLearningStatusSumFields>;
  MyLightningSessionCohortsLearningStatusVarPopFields: ResolverTypeWrapper<MyLightningSessionCohortsLearningStatusVarPopFields>;
  MyLightningSessionCohortsLearningStatusVarSampFields: ResolverTypeWrapper<MyLightningSessionCohortsLearningStatusVarSampFields>;
  MyLightningSessionCohortsLearningStatusVarianceFields: ResolverTypeWrapper<MyLightningSessionCohortsLearningStatusVarianceFields>;
  MyTeamMember: ResolverTypeWrapper<MyTeamMember>;
  MyTeamMembersOutput: ResolverTypeWrapper<MyTeamMembersOutput>;
  MyTraditionalCohortsLearningStatus: ResolverTypeWrapper<MyTraditionalCohortsLearningStatus>;
  MyTraditionalCohortsLearningStatusAggregate: ResolverTypeWrapper<MyTraditionalCohortsLearningStatusAggregate>;
  MyTraditionalCohortsLearningStatusAggregateFields: ResolverTypeWrapper<MyTraditionalCohortsLearningStatusAggregateFields>;
  MyTraditionalCohortsLearningStatusAvgFields: ResolverTypeWrapper<MyTraditionalCohortsLearningStatusAvgFields>;
  MyTraditionalCohortsLearningStatusBoolExp: MyTraditionalCohortsLearningStatusBoolExp;
  MyTraditionalCohortsLearningStatusMaxFields: ResolverTypeWrapper<MyTraditionalCohortsLearningStatusMaxFields>;
  MyTraditionalCohortsLearningStatusMinFields: ResolverTypeWrapper<MyTraditionalCohortsLearningStatusMinFields>;
  MyTraditionalCohortsLearningStatusOrderBy: MyTraditionalCohortsLearningStatusOrderBy;
  MyTraditionalCohortsLearningStatusSelectColumn: MyTraditionalCohortsLearningStatusSelectColumn;
  MyTraditionalCohortsLearningStatusStddevFields: ResolverTypeWrapper<MyTraditionalCohortsLearningStatusStddevFields>;
  MyTraditionalCohortsLearningStatusStddevPopFields: ResolverTypeWrapper<MyTraditionalCohortsLearningStatusStddevPopFields>;
  MyTraditionalCohortsLearningStatusStddevSampFields: ResolverTypeWrapper<MyTraditionalCohortsLearningStatusStddevSampFields>;
  MyTraditionalCohortsLearningStatusStreamCursorInput: MyTraditionalCohortsLearningStatusStreamCursorInput;
  MyTraditionalCohortsLearningStatusStreamCursorValueInput: MyTraditionalCohortsLearningStatusStreamCursorValueInput;
  MyTraditionalCohortsLearningStatusSumFields: ResolverTypeWrapper<MyTraditionalCohortsLearningStatusSumFields>;
  MyTraditionalCohortsLearningStatusVarPopFields: ResolverTypeWrapper<MyTraditionalCohortsLearningStatusVarPopFields>;
  MyTraditionalCohortsLearningStatusVarSampFields: ResolverTypeWrapper<MyTraditionalCohortsLearningStatusVarSampFields>;
  MyTraditionalCohortsLearningStatusVarianceFields: ResolverTypeWrapper<MyTraditionalCohortsLearningStatusVarianceFields>;
  NonceAccessToken: ResolverTypeWrapper<NonceAccessToken>;
  NonceAccessTokenAggregate: ResolverTypeWrapper<NonceAccessTokenAggregate>;
  NonceAccessTokenAggregateFields: ResolverTypeWrapper<NonceAccessTokenAggregateFields>;
  NonceAccessTokenBoolExp: NonceAccessTokenBoolExp;
  NonceAccessTokenConstraint: NonceAccessTokenConstraint;
  NonceAccessTokenInsertInput: NonceAccessTokenInsertInput;
  NonceAccessTokenMaxFields: ResolverTypeWrapper<NonceAccessTokenMaxFields>;
  NonceAccessTokenMinFields: ResolverTypeWrapper<NonceAccessTokenMinFields>;
  NonceAccessTokenMutationResponse: ResolverTypeWrapper<NonceAccessTokenMutationResponse>;
  NonceAccessTokenOnConflict: NonceAccessTokenOnConflict;
  NonceAccessTokenOrderBy: NonceAccessTokenOrderBy;
  NonceAccessTokenPkColumnsInput: NonceAccessTokenPkColumnsInput;
  NonceAccessTokenSelectColumn: NonceAccessTokenSelectColumn;
  NonceAccessTokenSetInput: NonceAccessTokenSetInput;
  NonceAccessTokenStreamCursorInput: NonceAccessTokenStreamCursorInput;
  NonceAccessTokenStreamCursorValueInput: NonceAccessTokenStreamCursorValueInput;
  NonceAccessTokenUpdateColumn: NonceAccessTokenUpdateColumn;
  NonceAccessTokenUpdates: NonceAccessTokenUpdates;
  NumericComparisonExp: NumericComparisonExp;
  OrderBy: OrderBy;
  OrganizationUsers: ResolverTypeWrapper<OrganizationUsers>;
  OrganizationUsersAggregate: ResolverTypeWrapper<OrganizationUsersAggregate>;
  OrganizationUsersAggregateBoolExp: OrganizationUsersAggregateBoolExp;
  OrganizationUsersAggregateBoolExpCount: OrganizationUsersAggregateBoolExpCount;
  OrganizationUsersAggregateFields: ResolverTypeWrapper<OrganizationUsersAggregateFields>;
  OrganizationUsersAggregateOrderBy: OrganizationUsersAggregateOrderBy;
  OrganizationUsersArrRelInsertInput: OrganizationUsersArrRelInsertInput;
  OrganizationUsersAvgFields: ResolverTypeWrapper<OrganizationUsersAvgFields>;
  OrganizationUsersAvgOrderBy: OrganizationUsersAvgOrderBy;
  OrganizationUsersBoolExp: OrganizationUsersBoolExp;
  OrganizationUsersConstraint: OrganizationUsersConstraint;
  OrganizationUsersIncInput: OrganizationUsersIncInput;
  OrganizationUsersInsertInput: OrganizationUsersInsertInput;
  OrganizationUsersMaxFields: ResolverTypeWrapper<OrganizationUsersMaxFields>;
  OrganizationUsersMaxOrderBy: OrganizationUsersMaxOrderBy;
  OrganizationUsersMinFields: ResolverTypeWrapper<OrganizationUsersMinFields>;
  OrganizationUsersMinOrderBy: OrganizationUsersMinOrderBy;
  OrganizationUsersMutationResponse: ResolverTypeWrapper<OrganizationUsersMutationResponse>;
  OrganizationUsersOnConflict: OrganizationUsersOnConflict;
  OrganizationUsersOrderBy: OrganizationUsersOrderBy;
  OrganizationUsersPkColumnsInput: OrganizationUsersPkColumnsInput;
  OrganizationUsersSelectColumn: OrganizationUsersSelectColumn;
  OrganizationUsersSetInput: OrganizationUsersSetInput;
  OrganizationUsersStddevFields: ResolverTypeWrapper<OrganizationUsersStddevFields>;
  OrganizationUsersStddevOrderBy: OrganizationUsersStddevOrderBy;
  OrganizationUsersStddevPopFields: ResolverTypeWrapper<OrganizationUsersStddevPopFields>;
  OrganizationUsersStddevPopOrderBy: OrganizationUsersStddevPopOrderBy;
  OrganizationUsersStddevSampFields: ResolverTypeWrapper<OrganizationUsersStddevSampFields>;
  OrganizationUsersStddevSampOrderBy: OrganizationUsersStddevSampOrderBy;
  OrganizationUsersStreamCursorInput: OrganizationUsersStreamCursorInput;
  OrganizationUsersStreamCursorValueInput: OrganizationUsersStreamCursorValueInput;
  OrganizationUsersSumFields: ResolverTypeWrapper<OrganizationUsersSumFields>;
  OrganizationUsersSumOrderBy: OrganizationUsersSumOrderBy;
  OrganizationUsersUpdateColumn: OrganizationUsersUpdateColumn;
  OrganizationUsersUpdates: OrganizationUsersUpdates;
  OrganizationUsersVarPopFields: ResolverTypeWrapper<OrganizationUsersVarPopFields>;
  OrganizationUsersVarPopOrderBy: OrganizationUsersVarPopOrderBy;
  OrganizationUsersVarSampFields: ResolverTypeWrapper<OrganizationUsersVarSampFields>;
  OrganizationUsersVarSampOrderBy: OrganizationUsersVarSampOrderBy;
  OrganizationUsersVarianceFields: ResolverTypeWrapper<OrganizationUsersVarianceFields>;
  OrganizationUsersVarianceOrderBy: OrganizationUsersVarianceOrderBy;
  Organizations: ResolverTypeWrapper<Organizations>;
  OrganizationsAggregate: ResolverTypeWrapper<OrganizationsAggregate>;
  OrganizationsAggregateFields: ResolverTypeWrapper<OrganizationsAggregateFields>;
  OrganizationsAvgFields: ResolverTypeWrapper<OrganizationsAvgFields>;
  OrganizationsBoolExp: OrganizationsBoolExp;
  OrganizationsConstraint: OrganizationsConstraint;
  OrganizationsIncInput: OrganizationsIncInput;
  OrganizationsInsertInput: OrganizationsInsertInput;
  OrganizationsMaxFields: ResolverTypeWrapper<OrganizationsMaxFields>;
  OrganizationsMeta: ResolverTypeWrapper<OrganizationsMeta>;
  OrganizationsMetaAggregate: ResolverTypeWrapper<OrganizationsMetaAggregate>;
  OrganizationsMetaAggregateFields: ResolverTypeWrapper<OrganizationsMetaAggregateFields>;
  OrganizationsMetaBoolExp: OrganizationsMetaBoolExp;
  OrganizationsMetaConstraint: OrganizationsMetaConstraint;
  OrganizationsMetaInsertInput: OrganizationsMetaInsertInput;
  OrganizationsMetaMaxFields: ResolverTypeWrapper<OrganizationsMetaMaxFields>;
  OrganizationsMetaMinFields: ResolverTypeWrapper<OrganizationsMetaMinFields>;
  OrganizationsMetaMutationResponse: ResolverTypeWrapper<OrganizationsMetaMutationResponse>;
  OrganizationsMetaObjRelInsertInput: OrganizationsMetaObjRelInsertInput;
  OrganizationsMetaOnConflict: OrganizationsMetaOnConflict;
  OrganizationsMetaOrderBy: OrganizationsMetaOrderBy;
  OrganizationsMetaPkColumnsInput: OrganizationsMetaPkColumnsInput;
  OrganizationsMetaSelectColumn: OrganizationsMetaSelectColumn;
  OrganizationsMetaSetInput: OrganizationsMetaSetInput;
  OrganizationsMetaStreamCursorInput: OrganizationsMetaStreamCursorInput;
  OrganizationsMetaStreamCursorValueInput: OrganizationsMetaStreamCursorValueInput;
  OrganizationsMetaUpdateColumn: OrganizationsMetaUpdateColumn;
  OrganizationsMetaUpdates: OrganizationsMetaUpdates;
  OrganizationsMinFields: ResolverTypeWrapper<OrganizationsMinFields>;
  OrganizationsMutationResponse: ResolverTypeWrapper<OrganizationsMutationResponse>;
  OrganizationsObjRelInsertInput: OrganizationsObjRelInsertInput;
  OrganizationsOnConflict: OrganizationsOnConflict;
  OrganizationsOrderBy: OrganizationsOrderBy;
  OrganizationsPkColumnsInput: OrganizationsPkColumnsInput;
  OrganizationsSelectColumn: OrganizationsSelectColumn;
  OrganizationsSetInput: OrganizationsSetInput;
  OrganizationsStddevFields: ResolverTypeWrapper<OrganizationsStddevFields>;
  OrganizationsStddevPopFields: ResolverTypeWrapper<OrganizationsStddevPopFields>;
  OrganizationsStddevSampFields: ResolverTypeWrapper<OrganizationsStddevSampFields>;
  OrganizationsStreamCursorInput: OrganizationsStreamCursorInput;
  OrganizationsStreamCursorValueInput: OrganizationsStreamCursorValueInput;
  OrganizationsSumFields: ResolverTypeWrapper<OrganizationsSumFields>;
  OrganizationsUpdateColumn: OrganizationsUpdateColumn;
  OrganizationsUpdates: OrganizationsUpdates;
  OrganizationsVarPopFields: ResolverTypeWrapper<OrganizationsVarPopFields>;
  OrganizationsVarSampFields: ResolverTypeWrapper<OrganizationsVarSampFields>;
  OrganizationsVarianceFields: ResolverTypeWrapper<OrganizationsVarianceFields>;
  ParticipantInput: ParticipantInput;
  PlanSubscriptions: ResolverTypeWrapper<PlanSubscriptions>;
  PlanSubscriptionsAggregate: ResolverTypeWrapper<PlanSubscriptionsAggregate>;
  PlanSubscriptionsAggregateBoolExp: PlanSubscriptionsAggregateBoolExp;
  PlanSubscriptionsAggregateFields: ResolverTypeWrapper<PlanSubscriptionsAggregateFields>;
  PlanSubscriptionsAggregateOrderBy: PlanSubscriptionsAggregateOrderBy;
  PlanSubscriptionsArrRelInsertInput: PlanSubscriptionsArrRelInsertInput;
  PlanSubscriptionsBoolExp: PlanSubscriptionsBoolExp;
  PlanSubscriptionsConstraint: PlanSubscriptionsConstraint;
  PlanSubscriptionsInsertInput: PlanSubscriptionsInsertInput;
  PlanSubscriptionsMaxFields: ResolverTypeWrapper<PlanSubscriptionsMaxFields>;
  PlanSubscriptionsMaxOrderBy: PlanSubscriptionsMaxOrderBy;
  PlanSubscriptionsMinFields: ResolverTypeWrapper<PlanSubscriptionsMinFields>;
  PlanSubscriptionsMinOrderBy: PlanSubscriptionsMinOrderBy;
  PlanSubscriptionsMutationResponse: ResolverTypeWrapper<PlanSubscriptionsMutationResponse>;
  PlanSubscriptionsOnConflict: PlanSubscriptionsOnConflict;
  PlanSubscriptionsOrderBy: PlanSubscriptionsOrderBy;
  PlanSubscriptionsPkColumnsInput: PlanSubscriptionsPkColumnsInput;
  PlanSubscriptionsSelectColumn: PlanSubscriptionsSelectColumn;
  PlanSubscriptionsSetInput: PlanSubscriptionsSetInput;
  PlanSubscriptionsStreamCursorInput: PlanSubscriptionsStreamCursorInput;
  PlanSubscriptionsStreamCursorValueInput: PlanSubscriptionsStreamCursorValueInput;
  PlanSubscriptionsUpdateColumn: PlanSubscriptionsUpdateColumn;
  PlanSubscriptionsUpdates: PlanSubscriptionsUpdates;
  Plans: ResolverTypeWrapper<Plans>;
  PlansAggregate: ResolverTypeWrapper<PlansAggregate>;
  PlansAggregateFields: ResolverTypeWrapper<PlansAggregateFields>;
  PlansAggregateOrderBy: PlansAggregateOrderBy;
  PlansAppendInput: PlansAppendInput;
  PlansBoolExp: PlansBoolExp;
  PlansConstraint: PlansConstraint;
  PlansDeleteAtPathInput: PlansDeleteAtPathInput;
  PlansDeleteElemInput: PlansDeleteElemInput;
  PlansDeleteKeyInput: PlansDeleteKeyInput;
  PlansInsertInput: PlansInsertInput;
  PlansMaxFields: ResolverTypeWrapper<PlansMaxFields>;
  PlansMaxOrderBy: PlansMaxOrderBy;
  PlansMinFields: ResolverTypeWrapper<PlansMinFields>;
  PlansMinOrderBy: PlansMinOrderBy;
  PlansMutationResponse: ResolverTypeWrapper<PlansMutationResponse>;
  PlansObjRelInsertInput: PlansObjRelInsertInput;
  PlansOnConflict: PlansOnConflict;
  PlansOrderBy: PlansOrderBy;
  PlansPkColumnsInput: PlansPkColumnsInput;
  PlansPrependInput: PlansPrependInput;
  PlansSelectColumn: PlansSelectColumn;
  PlansSetInput: PlansSetInput;
  PlansStreamCursorInput: PlansStreamCursorInput;
  PlansStreamCursorValueInput: PlansStreamCursorValueInput;
  PlansUpdateColumn: PlansUpdateColumn;
  PlansUpdates: PlansUpdates;
  PlayExecution: ResolverTypeWrapper<PlayExecution>;
  PlayExecutionAggregate: ResolverTypeWrapper<PlayExecutionAggregate>;
  PlayExecutionAggregateFields: ResolverTypeWrapper<PlayExecutionAggregateFields>;
  PlayExecutionAppendInput: PlayExecutionAppendInput;
  PlayExecutionBoolExp: PlayExecutionBoolExp;
  PlayExecutionConstraint: PlayExecutionConstraint;
  PlayExecutionDeleteAtPathInput: PlayExecutionDeleteAtPathInput;
  PlayExecutionDeleteElemInput: PlayExecutionDeleteElemInput;
  PlayExecutionDeleteKeyInput: PlayExecutionDeleteKeyInput;
  PlayExecutionInsertInput: PlayExecutionInsertInput;
  PlayExecutionMaxFields: ResolverTypeWrapper<PlayExecutionMaxFields>;
  PlayExecutionMinFields: ResolverTypeWrapper<PlayExecutionMinFields>;
  PlayExecutionMutationResponse: ResolverTypeWrapper<PlayExecutionMutationResponse>;
  PlayExecutionOnConflict: PlayExecutionOnConflict;
  PlayExecutionOrderBy: PlayExecutionOrderBy;
  PlayExecutionPkColumnsInput: PlayExecutionPkColumnsInput;
  PlayExecutionPrependInput: PlayExecutionPrependInput;
  PlayExecutionSelectColumn: PlayExecutionSelectColumn;
  PlayExecutionSetInput: PlayExecutionSetInput;
  PlayExecutionStreamCursorInput: PlayExecutionStreamCursorInput;
  PlayExecutionStreamCursorValueInput: PlayExecutionStreamCursorValueInput;
  PlayExecutionUpdateColumn: PlayExecutionUpdateColumn;
  PlayExecutionUpdates: PlayExecutionUpdates;
  PlaybackTimes: PlaybackTimes;
  ProgramActionsTaken: ResolverTypeWrapper<ProgramActionsTaken>;
  ProgramActionsTakenAggregate: ResolverTypeWrapper<ProgramActionsTakenAggregate>;
  ProgramActionsTakenAggregateBoolExp: ProgramActionsTakenAggregateBoolExp;
  ProgramActionsTakenAggregateFields: ResolverTypeWrapper<ProgramActionsTakenAggregateFields>;
  ProgramActionsTakenAggregateOrderBy: ProgramActionsTakenAggregateOrderBy;
  ProgramActionsTakenArrRelInsertInput: ProgramActionsTakenArrRelInsertInput;
  ProgramActionsTakenBoolExp: ProgramActionsTakenBoolExp;
  ProgramActionsTakenInsertInput: ProgramActionsTakenInsertInput;
  ProgramActionsTakenMaxFields: ResolverTypeWrapper<ProgramActionsTakenMaxFields>;
  ProgramActionsTakenMaxOrderBy: ProgramActionsTakenMaxOrderBy;
  ProgramActionsTakenMinFields: ResolverTypeWrapper<ProgramActionsTakenMinFields>;
  ProgramActionsTakenMinOrderBy: ProgramActionsTakenMinOrderBy;
  ProgramActionsTakenMutationResponse: ResolverTypeWrapper<ProgramActionsTakenMutationResponse>;
  ProgramActionsTakenOrderBy: ProgramActionsTakenOrderBy;
  ProgramActionsTakenSelectColumn: ProgramActionsTakenSelectColumn;
  ProgramActionsTakenSetInput: ProgramActionsTakenSetInput;
  ProgramActionsTakenStreamCursorInput: ProgramActionsTakenStreamCursorInput;
  ProgramActionsTakenStreamCursorValueInput: ProgramActionsTakenStreamCursorValueInput;
  ProgramActionsTakenUpdates: ProgramActionsTakenUpdates;
  ProgramCoursesShown: ResolverTypeWrapper<ProgramCoursesShown>;
  ProgramCoursesShownAggregate: ResolverTypeWrapper<ProgramCoursesShownAggregate>;
  ProgramCoursesShownAggregateBoolExp: ProgramCoursesShownAggregateBoolExp;
  ProgramCoursesShownAggregateFields: ResolverTypeWrapper<ProgramCoursesShownAggregateFields>;
  ProgramCoursesShownAggregateOrderBy: ProgramCoursesShownAggregateOrderBy;
  ProgramCoursesShownArrRelInsertInput: ProgramCoursesShownArrRelInsertInput;
  ProgramCoursesShownBoolExp: ProgramCoursesShownBoolExp;
  ProgramCoursesShownConstraint: ProgramCoursesShownConstraint;
  ProgramCoursesShownInsertInput: ProgramCoursesShownInsertInput;
  ProgramCoursesShownMaxFields: ResolverTypeWrapper<ProgramCoursesShownMaxFields>;
  ProgramCoursesShownMaxOrderBy: ProgramCoursesShownMaxOrderBy;
  ProgramCoursesShownMinFields: ResolverTypeWrapper<ProgramCoursesShownMinFields>;
  ProgramCoursesShownMinOrderBy: ProgramCoursesShownMinOrderBy;
  ProgramCoursesShownMutationResponse: ResolverTypeWrapper<ProgramCoursesShownMutationResponse>;
  ProgramCoursesShownOnConflict: ProgramCoursesShownOnConflict;
  ProgramCoursesShownOrderBy: ProgramCoursesShownOrderBy;
  ProgramCoursesShownPkColumnsInput: ProgramCoursesShownPkColumnsInput;
  ProgramCoursesShownSelectColumn: ProgramCoursesShownSelectColumn;
  ProgramCoursesShownSetInput: ProgramCoursesShownSetInput;
  ProgramCoursesShownStreamCursorInput: ProgramCoursesShownStreamCursorInput;
  ProgramCoursesShownStreamCursorValueInput: ProgramCoursesShownStreamCursorValueInput;
  ProgramCoursesShownUpdateColumn: ProgramCoursesShownUpdateColumn;
  ProgramCoursesShownUpdates: ProgramCoursesShownUpdates;
  ProgramTags: ResolverTypeWrapper<ProgramTags>;
  ProgramTagsAggregate: ResolverTypeWrapper<ProgramTagsAggregate>;
  ProgramTagsAggregateBoolExp: ProgramTagsAggregateBoolExp;
  ProgramTagsAggregateFields: ResolverTypeWrapper<ProgramTagsAggregateFields>;
  ProgramTagsAggregateOrderBy: ProgramTagsAggregateOrderBy;
  ProgramTagsArrRelInsertInput: ProgramTagsArrRelInsertInput;
  ProgramTagsBoolExp: ProgramTagsBoolExp;
  ProgramTagsConstraint: ProgramTagsConstraint;
  ProgramTagsInsertInput: ProgramTagsInsertInput;
  ProgramTagsMaxFields: ResolverTypeWrapper<ProgramTagsMaxFields>;
  ProgramTagsMaxOrderBy: ProgramTagsMaxOrderBy;
  ProgramTagsMinFields: ResolverTypeWrapper<ProgramTagsMinFields>;
  ProgramTagsMinOrderBy: ProgramTagsMinOrderBy;
  ProgramTagsMutationResponse: ResolverTypeWrapper<ProgramTagsMutationResponse>;
  ProgramTagsOnConflict: ProgramTagsOnConflict;
  ProgramTagsOrderBy: ProgramTagsOrderBy;
  ProgramTagsPkColumnsInput: ProgramTagsPkColumnsInput;
  ProgramTagsSelectColumn: ProgramTagsSelectColumn;
  ProgramTagsSetInput: ProgramTagsSetInput;
  ProgramTagsStreamCursorInput: ProgramTagsStreamCursorInput;
  ProgramTagsStreamCursorValueInput: ProgramTagsStreamCursorValueInput;
  ProgramTagsUpdateColumn: ProgramTagsUpdateColumn;
  ProgramTagsUpdates: ProgramTagsUpdates;
  ProgramUsersSelected: ResolverTypeWrapper<ProgramUsersSelected>;
  ProgramUsersSelectedAggregate: ResolverTypeWrapper<ProgramUsersSelectedAggregate>;
  ProgramUsersSelectedAggregateBoolExp: ProgramUsersSelectedAggregateBoolExp;
  ProgramUsersSelectedAggregateFields: ResolverTypeWrapper<ProgramUsersSelectedAggregateFields>;
  ProgramUsersSelectedAggregateOrderBy: ProgramUsersSelectedAggregateOrderBy;
  ProgramUsersSelectedArrRelInsertInput: ProgramUsersSelectedArrRelInsertInput;
  ProgramUsersSelectedBoolExp: ProgramUsersSelectedBoolExp;
  ProgramUsersSelectedConstraint: ProgramUsersSelectedConstraint;
  ProgramUsersSelectedInsertInput: ProgramUsersSelectedInsertInput;
  ProgramUsersSelectedMaxFields: ResolverTypeWrapper<ProgramUsersSelectedMaxFields>;
  ProgramUsersSelectedMaxOrderBy: ProgramUsersSelectedMaxOrderBy;
  ProgramUsersSelectedMinFields: ResolverTypeWrapper<ProgramUsersSelectedMinFields>;
  ProgramUsersSelectedMinOrderBy: ProgramUsersSelectedMinOrderBy;
  ProgramUsersSelectedMutationResponse: ResolverTypeWrapper<ProgramUsersSelectedMutationResponse>;
  ProgramUsersSelectedOnConflict: ProgramUsersSelectedOnConflict;
  ProgramUsersSelectedOrderBy: ProgramUsersSelectedOrderBy;
  ProgramUsersSelectedPkColumnsInput: ProgramUsersSelectedPkColumnsInput;
  ProgramUsersSelectedSelectColumn: ProgramUsersSelectedSelectColumn;
  ProgramUsersSelectedSetInput: ProgramUsersSelectedSetInput;
  ProgramUsersSelectedStreamCursorInput: ProgramUsersSelectedStreamCursorInput;
  ProgramUsersSelectedStreamCursorValueInput: ProgramUsersSelectedStreamCursorValueInput;
  ProgramUsersSelectedUpdateColumn: ProgramUsersSelectedUpdateColumn;
  ProgramUsersSelectedUpdates: ProgramUsersSelectedUpdates;
  Programs: ResolverTypeWrapper<Programs>;
  ProgramsAggregate: ResolverTypeWrapper<ProgramsAggregate>;
  ProgramsAggregateFields: ResolverTypeWrapper<ProgramsAggregateFields>;
  ProgramsBoolExp: ProgramsBoolExp;
  ProgramsConstraint: ProgramsConstraint;
  ProgramsInsertInput: ProgramsInsertInput;
  ProgramsMaxFields: ResolverTypeWrapper<ProgramsMaxFields>;
  ProgramsMinFields: ResolverTypeWrapper<ProgramsMinFields>;
  ProgramsMutationResponse: ResolverTypeWrapper<ProgramsMutationResponse>;
  ProgramsOnConflict: ProgramsOnConflict;
  ProgramsOrderBy: ProgramsOrderBy;
  ProgramsPkColumnsInput: ProgramsPkColumnsInput;
  ProgramsSelectColumn: ProgramsSelectColumn;
  ProgramsSetInput: ProgramsSetInput;
  ProgramsStreamCursorInput: ProgramsStreamCursorInput;
  ProgramsStreamCursorValueInput: ProgramsStreamCursorValueInput;
  ProgramsUpdateColumn: ProgramsUpdateColumn;
  ProgramsUpdates: ProgramsUpdates;
  QuestionType: ResolverTypeWrapper<QuestionType>;
  QuestionTypeAggregate: ResolverTypeWrapper<QuestionTypeAggregate>;
  QuestionTypeAggregateFields: ResolverTypeWrapper<QuestionTypeAggregateFields>;
  QuestionTypeBoolExp: QuestionTypeBoolExp;
  QuestionTypeConstraint: QuestionTypeConstraint;
  QuestionTypeEnum: QuestionTypeEnum;
  QuestionTypeEnumComparisonExp: QuestionTypeEnumComparisonExp;
  QuestionTypeInsertInput: QuestionTypeInsertInput;
  QuestionTypeMaxFields: ResolverTypeWrapper<QuestionTypeMaxFields>;
  QuestionTypeMinFields: ResolverTypeWrapper<QuestionTypeMinFields>;
  QuestionTypeMutationResponse: ResolverTypeWrapper<QuestionTypeMutationResponse>;
  QuestionTypeObjRelInsertInput: QuestionTypeObjRelInsertInput;
  QuestionTypeOnConflict: QuestionTypeOnConflict;
  QuestionTypeOrderBy: QuestionTypeOrderBy;
  QuestionTypePkColumnsInput: QuestionTypePkColumnsInput;
  QuestionTypeSelectColumn: QuestionTypeSelectColumn;
  QuestionTypeSetInput: QuestionTypeSetInput;
  QuestionTypeStreamCursorInput: QuestionTypeStreamCursorInput;
  QuestionTypeStreamCursorValueInput: QuestionTypeStreamCursorValueInput;
  QuestionTypeUpdateColumn: QuestionTypeUpdateColumn;
  QuestionTypeUpdates: QuestionTypeUpdates;
  RecordingActivity: ResolverTypeWrapper<RecordingActivity>;
  RecordingActivityAggregate: ResolverTypeWrapper<RecordingActivityAggregate>;
  RecordingActivityAggregateFields: ResolverTypeWrapper<RecordingActivityAggregateFields>;
  RecordingActivityAppendInput: RecordingActivityAppendInput;
  RecordingActivityBoolExp: RecordingActivityBoolExp;
  RecordingActivityConstraint: RecordingActivityConstraint;
  RecordingActivityDeleteAtPathInput: RecordingActivityDeleteAtPathInput;
  RecordingActivityDeleteElemInput: RecordingActivityDeleteElemInput;
  RecordingActivityDeleteKeyInput: RecordingActivityDeleteKeyInput;
  RecordingActivityInsertInput: RecordingActivityInsertInput;
  RecordingActivityMaxFields: ResolverTypeWrapper<RecordingActivityMaxFields>;
  RecordingActivityMinFields: ResolverTypeWrapper<RecordingActivityMinFields>;
  RecordingActivityMutationResponse: ResolverTypeWrapper<RecordingActivityMutationResponse>;
  RecordingActivityOnConflict: RecordingActivityOnConflict;
  RecordingActivityOrderBy: RecordingActivityOrderBy;
  RecordingActivityPkColumnsInput: RecordingActivityPkColumnsInput;
  RecordingActivityPrependInput: RecordingActivityPrependInput;
  RecordingActivitySelectColumn: RecordingActivitySelectColumn;
  RecordingActivitySetInput: RecordingActivitySetInput;
  RecordingActivityStreamCursorInput: RecordingActivityStreamCursorInput;
  RecordingActivityStreamCursorValueInput: RecordingActivityStreamCursorValueInput;
  RecordingActivityUpdateColumn: RecordingActivityUpdateColumn;
  RecordingActivityUpdates: RecordingActivityUpdates;
  Recordings: ResolverTypeWrapper<Recordings>;
  RecordingsAggregate: ResolverTypeWrapper<RecordingsAggregate>;
  RecordingsAggregateFields: ResolverTypeWrapper<RecordingsAggregateFields>;
  RecordingsAppendInput: RecordingsAppendInput;
  RecordingsBoolExp: RecordingsBoolExp;
  RecordingsConstraint: RecordingsConstraint;
  RecordingsDeleteAtPathInput: RecordingsDeleteAtPathInput;
  RecordingsDeleteElemInput: RecordingsDeleteElemInput;
  RecordingsDeleteKeyInput: RecordingsDeleteKeyInput;
  RecordingsInsertInput: RecordingsInsertInput;
  RecordingsMaxFields: ResolverTypeWrapper<RecordingsMaxFields>;
  RecordingsMinFields: ResolverTypeWrapper<RecordingsMinFields>;
  RecordingsMutationResponse: ResolverTypeWrapper<RecordingsMutationResponse>;
  RecordingsOnConflict: RecordingsOnConflict;
  RecordingsOrderBy: RecordingsOrderBy;
  RecordingsPkColumnsInput: RecordingsPkColumnsInput;
  RecordingsPrependInput: RecordingsPrependInput;
  RecordingsSelectColumn: RecordingsSelectColumn;
  RecordingsSetInput: RecordingsSetInput;
  RecordingsStreamCursorInput: RecordingsStreamCursorInput;
  RecordingsStreamCursorValueInput: RecordingsStreamCursorValueInput;
  RecordingsUpdateColumn: RecordingsUpdateColumn;
  RecordingsUpdates: RecordingsUpdates;
  RegisterAnonOutput: ResolverTypeWrapper<RegisterAnonOutput>;
  RelatedCourses: ResolverTypeWrapper<RelatedCourses>;
  RelatedCoursesAggregate: ResolverTypeWrapper<RelatedCoursesAggregate>;
  RelatedCoursesAggregateBoolExp: RelatedCoursesAggregateBoolExp;
  RelatedCoursesAggregateBoolExpCount: RelatedCoursesAggregateBoolExpCount;
  RelatedCoursesAggregateFields: ResolverTypeWrapper<RelatedCoursesAggregateFields>;
  RelatedCoursesAggregateOrderBy: RelatedCoursesAggregateOrderBy;
  RelatedCoursesArrRelInsertInput: RelatedCoursesArrRelInsertInput;
  RelatedCoursesBoolExp: RelatedCoursesBoolExp;
  RelatedCoursesConstraint: RelatedCoursesConstraint;
  RelatedCoursesInsertInput: RelatedCoursesInsertInput;
  RelatedCoursesMaxFields: ResolverTypeWrapper<RelatedCoursesMaxFields>;
  RelatedCoursesMaxOrderBy: RelatedCoursesMaxOrderBy;
  RelatedCoursesMinFields: ResolverTypeWrapper<RelatedCoursesMinFields>;
  RelatedCoursesMinOrderBy: RelatedCoursesMinOrderBy;
  RelatedCoursesMutationResponse: ResolverTypeWrapper<RelatedCoursesMutationResponse>;
  RelatedCoursesOnConflict: RelatedCoursesOnConflict;
  RelatedCoursesOrderBy: RelatedCoursesOrderBy;
  RelatedCoursesPkColumnsInput: RelatedCoursesPkColumnsInput;
  RelatedCoursesSelectColumn: RelatedCoursesSelectColumn;
  RelatedCoursesSetInput: RelatedCoursesSetInput;
  RelatedCoursesStreamCursorInput: RelatedCoursesStreamCursorInput;
  RelatedCoursesStreamCursorValueInput: RelatedCoursesStreamCursorValueInput;
  RelatedCoursesUpdateColumn: RelatedCoursesUpdateColumn;
  RelatedCoursesUpdates: RelatedCoursesUpdates;
  ReportPublishStatus: ResolverTypeWrapper<ReportPublishStatus>;
  ReportPublishStatusAggregate: ResolverTypeWrapper<ReportPublishStatusAggregate>;
  ReportPublishStatusAggregateFields: ResolverTypeWrapper<ReportPublishStatusAggregateFields>;
  ReportPublishStatusBoolExp: ReportPublishStatusBoolExp;
  ReportPublishStatusConstraint: ReportPublishStatusConstraint;
  ReportPublishStatusEnum: ReportPublishStatusEnum;
  ReportPublishStatusEnumComparisonExp: ReportPublishStatusEnumComparisonExp;
  ReportPublishStatusInsertInput: ReportPublishStatusInsertInput;
  ReportPublishStatusMaxFields: ResolverTypeWrapper<ReportPublishStatusMaxFields>;
  ReportPublishStatusMinFields: ResolverTypeWrapper<ReportPublishStatusMinFields>;
  ReportPublishStatusMutationResponse: ResolverTypeWrapper<ReportPublishStatusMutationResponse>;
  ReportPublishStatusOnConflict: ReportPublishStatusOnConflict;
  ReportPublishStatusOrderBy: ReportPublishStatusOrderBy;
  ReportPublishStatusPkColumnsInput: ReportPublishStatusPkColumnsInput;
  ReportPublishStatusSelectColumn: ReportPublishStatusSelectColumn;
  ReportPublishStatusSetInput: ReportPublishStatusSetInput;
  ReportPublishStatusStreamCursorInput: ReportPublishStatusStreamCursorInput;
  ReportPublishStatusStreamCursorValueInput: ReportPublishStatusStreamCursorValueInput;
  ReportPublishStatusUpdateColumn: ReportPublishStatusUpdateColumn;
  ReportPublishStatusUpdates: ReportPublishStatusUpdates;
  ReportType: ResolverTypeWrapper<ReportType>;
  ReportTypeAggregate: ResolverTypeWrapper<ReportTypeAggregate>;
  ReportTypeAggregateFields: ResolverTypeWrapper<ReportTypeAggregateFields>;
  ReportTypeBoolExp: ReportTypeBoolExp;
  ReportTypeConstraint: ReportTypeConstraint;
  ReportTypeEnum: ReportTypeEnum;
  ReportTypeEnumComparisonExp: ReportTypeEnumComparisonExp;
  ReportTypeInsertInput: ReportTypeInsertInput;
  ReportTypeMaxFields: ResolverTypeWrapper<ReportTypeMaxFields>;
  ReportTypeMinFields: ResolverTypeWrapper<ReportTypeMinFields>;
  ReportTypeMutationResponse: ResolverTypeWrapper<ReportTypeMutationResponse>;
  ReportTypeOnConflict: ReportTypeOnConflict;
  ReportTypeOrderBy: ReportTypeOrderBy;
  ReportTypePkColumnsInput: ReportTypePkColumnsInput;
  ReportTypeSelectColumn: ReportTypeSelectColumn;
  ReportTypeSetInput: ReportTypeSetInput;
  ReportTypeStreamCursorInput: ReportTypeStreamCursorInput;
  ReportTypeStreamCursorValueInput: ReportTypeStreamCursorValueInput;
  ReportTypeUpdateColumn: ReportTypeUpdateColumn;
  ReportTypeUpdates: ReportTypeUpdates;
  Reports: ResolverTypeWrapper<Reports>;
  ReportsAggregate: ResolverTypeWrapper<ReportsAggregate>;
  ReportsAggregateFields: ResolverTypeWrapper<ReportsAggregateFields>;
  ReportsAvgFields: ResolverTypeWrapper<ReportsAvgFields>;
  ReportsBoolExp: ReportsBoolExp;
  ReportsConstraint: ReportsConstraint;
  ReportsIncInput: ReportsIncInput;
  ReportsInsertInput: ReportsInsertInput;
  ReportsMaxFields: ResolverTypeWrapper<ReportsMaxFields>;
  ReportsMinFields: ResolverTypeWrapper<ReportsMinFields>;
  ReportsMutationResponse: ResolverTypeWrapper<ReportsMutationResponse>;
  ReportsOnConflict: ReportsOnConflict;
  ReportsOrderBy: ReportsOrderBy;
  ReportsPkColumnsInput: ReportsPkColumnsInput;
  ReportsSelectColumn: ReportsSelectColumn;
  ReportsSetInput: ReportsSetInput;
  ReportsStddevFields: ResolverTypeWrapper<ReportsStddevFields>;
  ReportsStddevPopFields: ResolverTypeWrapper<ReportsStddevPopFields>;
  ReportsStddevSampFields: ResolverTypeWrapper<ReportsStddevSampFields>;
  ReportsStreamCursorInput: ReportsStreamCursorInput;
  ReportsStreamCursorValueInput: ReportsStreamCursorValueInput;
  ReportsSumFields: ResolverTypeWrapper<ReportsSumFields>;
  ReportsUpdateColumn: ReportsUpdateColumn;
  ReportsUpdates: ReportsUpdates;
  ReportsVarPopFields: ResolverTypeWrapper<ReportsVarPopFields>;
  ReportsVarSampFields: ResolverTypeWrapper<ReportsVarSampFields>;
  ReportsVarianceFields: ResolverTypeWrapper<ReportsVarianceFields>;
  ResourceType: ResolverTypeWrapper<ResourceType>;
  ResourceTypeAggregate: ResolverTypeWrapper<ResourceTypeAggregate>;
  ResourceTypeAggregateFields: ResolverTypeWrapper<ResourceTypeAggregateFields>;
  ResourceTypeBoolExp: ResourceTypeBoolExp;
  ResourceTypeConstraint: ResourceTypeConstraint;
  ResourceTypeEnum: ResourceTypeEnum;
  ResourceTypeEnumComparisonExp: ResourceTypeEnumComparisonExp;
  ResourceTypeInsertInput: ResourceTypeInsertInput;
  ResourceTypeMaxFields: ResolverTypeWrapper<ResourceTypeMaxFields>;
  ResourceTypeMinFields: ResolverTypeWrapper<ResourceTypeMinFields>;
  ResourceTypeMutationResponse: ResolverTypeWrapper<ResourceTypeMutationResponse>;
  ResourceTypeOnConflict: ResourceTypeOnConflict;
  ResourceTypeOrderBy: ResourceTypeOrderBy;
  ResourceTypePkColumnsInput: ResourceTypePkColumnsInput;
  ResourceTypeSelectColumn: ResourceTypeSelectColumn;
  ResourceTypeSetInput: ResourceTypeSetInput;
  ResourceTypeStreamCursorInput: ResourceTypeStreamCursorInput;
  ResourceTypeStreamCursorValueInput: ResourceTypeStreamCursorValueInput;
  ResourceTypeUpdateColumn: ResourceTypeUpdateColumn;
  ResourceTypeUpdates: ResourceTypeUpdates;
  Resources: ResolverTypeWrapper<Resources>;
  ResourcesAggregate: ResolverTypeWrapper<ResourcesAggregate>;
  ResourcesAggregateFields: ResolverTypeWrapper<ResourcesAggregateFields>;
  ResourcesAvgFields: ResolverTypeWrapper<ResourcesAvgFields>;
  ResourcesBoolExp: ResourcesBoolExp;
  ResourcesConstraint: ResourcesConstraint;
  ResourcesIncInput: ResourcesIncInput;
  ResourcesInsertInput: ResourcesInsertInput;
  ResourcesMaxFields: ResolverTypeWrapper<ResourcesMaxFields>;
  ResourcesMinFields: ResolverTypeWrapper<ResourcesMinFields>;
  ResourcesMutationResponse: ResolverTypeWrapper<ResourcesMutationResponse>;
  ResourcesObjRelInsertInput: ResourcesObjRelInsertInput;
  ResourcesOnConflict: ResourcesOnConflict;
  ResourcesOrderBy: ResourcesOrderBy;
  ResourcesPkColumnsInput: ResourcesPkColumnsInput;
  ResourcesSelectColumn: ResourcesSelectColumn;
  ResourcesSetInput: ResourcesSetInput;
  ResourcesStddevFields: ResolverTypeWrapper<ResourcesStddevFields>;
  ResourcesStddevPopFields: ResolverTypeWrapper<ResourcesStddevPopFields>;
  ResourcesStddevSampFields: ResolverTypeWrapper<ResourcesStddevSampFields>;
  ResourcesStreamCursorInput: ResourcesStreamCursorInput;
  ResourcesStreamCursorValueInput: ResourcesStreamCursorValueInput;
  ResourcesSumFields: ResolverTypeWrapper<ResourcesSumFields>;
  ResourcesUpdateColumn: ResourcesUpdateColumn;
  ResourcesUpdates: ResourcesUpdates;
  ResourcesVarPopFields: ResolverTypeWrapper<ResourcesVarPopFields>;
  ResourcesVarSampFields: ResolverTypeWrapper<ResourcesVarSampFields>;
  ResourcesVarianceFields: ResolverTypeWrapper<ResourcesVarianceFields>;
  ResumeAssessmentOutput: ResolverTypeWrapper<ResumeAssessmentOutput>;
  RoomInput: RoomInput;
  RunPlayOutput: ResolverTypeWrapper<RunPlayOutput>;
  SampleOutput: ResolverTypeWrapper<SampleOutput>;
  SaveAssessmentOutput: ResolverTypeWrapper<SaveAssessmentOutput>;
  Schools: ResolverTypeWrapper<Schools>;
  SchoolsAggregate: ResolverTypeWrapper<SchoolsAggregate>;
  SchoolsAggregateFields: ResolverTypeWrapper<SchoolsAggregateFields>;
  SchoolsBoolExp: SchoolsBoolExp;
  SchoolsConstraint: SchoolsConstraint;
  SchoolsInsertInput: SchoolsInsertInput;
  SchoolsMaxFields: ResolverTypeWrapper<SchoolsMaxFields>;
  SchoolsMinFields: ResolverTypeWrapper<SchoolsMinFields>;
  SchoolsMutationResponse: ResolverTypeWrapper<SchoolsMutationResponse>;
  SchoolsObjRelInsertInput: SchoolsObjRelInsertInput;
  SchoolsOnConflict: SchoolsOnConflict;
  SchoolsOrderBy: SchoolsOrderBy;
  SchoolsPkColumnsInput: SchoolsPkColumnsInput;
  SchoolsSelectColumn: SchoolsSelectColumn;
  SchoolsSetInput: SchoolsSetInput;
  SchoolsStreamCursorInput: SchoolsStreamCursorInput;
  SchoolsStreamCursorValueInput: SchoolsStreamCursorValueInput;
  SchoolsUpdateColumn: SchoolsUpdateColumn;
  SchoolsUpdates: SchoolsUpdates;
  SeatType: ResolverTypeWrapper<SeatType>;
  SeatTypeAggregate: ResolverTypeWrapper<SeatTypeAggregate>;
  SeatTypeAggregateFields: ResolverTypeWrapper<SeatTypeAggregateFields>;
  SeatTypeBoolExp: SeatTypeBoolExp;
  SeatTypeConstraint: SeatTypeConstraint;
  SeatTypeEnum: SeatTypeEnum;
  SeatTypeEnumComparisonExp: SeatTypeEnumComparisonExp;
  SeatTypeInsertInput: SeatTypeInsertInput;
  SeatTypeMaxFields: ResolverTypeWrapper<SeatTypeMaxFields>;
  SeatTypeMinFields: ResolverTypeWrapper<SeatTypeMinFields>;
  SeatTypeMutationResponse: ResolverTypeWrapper<SeatTypeMutationResponse>;
  SeatTypeOnConflict: SeatTypeOnConflict;
  SeatTypeOrderBy: SeatTypeOrderBy;
  SeatTypePkColumnsInput: SeatTypePkColumnsInput;
  SeatTypeSelectColumn: SeatTypeSelectColumn;
  SeatTypeSetInput: SeatTypeSetInput;
  SeatTypeStreamCursorInput: SeatTypeStreamCursorInput;
  SeatTypeStreamCursorValueInput: SeatTypeStreamCursorValueInput;
  SeatTypeUpdateColumn: SeatTypeUpdateColumn;
  SeatTypeUpdates: SeatTypeUpdates;
  Seats: ResolverTypeWrapper<Seats>;
  SeatsAggregate: ResolverTypeWrapper<SeatsAggregate>;
  SeatsAggregateFields: ResolverTypeWrapper<SeatsAggregateFields>;
  SeatsBoolExp: SeatsBoolExp;
  SeatsConstraint: SeatsConstraint;
  SeatsInsertInput: SeatsInsertInput;
  SeatsMaxFields: ResolverTypeWrapper<SeatsMaxFields>;
  SeatsMinFields: ResolverTypeWrapper<SeatsMinFields>;
  SeatsMutationResponse: ResolverTypeWrapper<SeatsMutationResponse>;
  SeatsOnConflict: SeatsOnConflict;
  SeatsOrderBy: SeatsOrderBy;
  SeatsPkColumnsInput: SeatsPkColumnsInput;
  SeatsSelectColumn: SeatsSelectColumn;
  SeatsSetInput: SeatsSetInput;
  SeatsStreamCursorInput: SeatsStreamCursorInput;
  SeatsStreamCursorValueInput: SeatsStreamCursorValueInput;
  SeatsUpdateColumn: SeatsUpdateColumn;
  SeatsUpdates: SeatsUpdates;
  SetClassStatusContext: SetClassStatusContext;
  SetClassStatusOutput: ResolverTypeWrapper<SetClassStatusOutput>;
  SmallintComparisonExp: SmallintComparisonExp;
  StartAssessmentOutput: ResolverTypeWrapper<StartAssessmentOutput>;
  StartPlaybackInput: StartPlaybackInput;
  StartPlaybackOutput: ResolverTypeWrapper<StartPlaybackOutput>;
  StreamMessages: ResolverTypeWrapper<StreamMessages>;
  StreamMessagesAggregate: ResolverTypeWrapper<StreamMessagesAggregate>;
  StreamMessagesAggregateBoolExp: StreamMessagesAggregateBoolExp;
  StreamMessagesAggregateFields: ResolverTypeWrapper<StreamMessagesAggregateFields>;
  StreamMessagesAggregateOrderBy: StreamMessagesAggregateOrderBy;
  StreamMessagesAppendInput: StreamMessagesAppendInput;
  StreamMessagesArrRelInsertInput: StreamMessagesArrRelInsertInput;
  StreamMessagesBoolExp: StreamMessagesBoolExp;
  StreamMessagesConstraint: StreamMessagesConstraint;
  StreamMessagesDeleteAtPathInput: StreamMessagesDeleteAtPathInput;
  StreamMessagesDeleteElemInput: StreamMessagesDeleteElemInput;
  StreamMessagesDeleteKeyInput: StreamMessagesDeleteKeyInput;
  StreamMessagesInsertInput: StreamMessagesInsertInput;
  StreamMessagesMaxFields: ResolverTypeWrapper<StreamMessagesMaxFields>;
  StreamMessagesMaxOrderBy: StreamMessagesMaxOrderBy;
  StreamMessagesMinFields: ResolverTypeWrapper<StreamMessagesMinFields>;
  StreamMessagesMinOrderBy: StreamMessagesMinOrderBy;
  StreamMessagesMutationResponse: ResolverTypeWrapper<StreamMessagesMutationResponse>;
  StreamMessagesOnConflict: StreamMessagesOnConflict;
  StreamMessagesOrderBy: StreamMessagesOrderBy;
  StreamMessagesPkColumnsInput: StreamMessagesPkColumnsInput;
  StreamMessagesPrependInput: StreamMessagesPrependInput;
  StreamMessagesSelectColumn: StreamMessagesSelectColumn;
  StreamMessagesSetInput: StreamMessagesSetInput;
  StreamMessagesStreamCursorInput: StreamMessagesStreamCursorInput;
  StreamMessagesStreamCursorValueInput: StreamMessagesStreamCursorValueInput;
  StreamMessagesUpdateColumn: StreamMessagesUpdateColumn;
  StreamMessagesUpdates: StreamMessagesUpdates;
  String: ResolverTypeWrapper<Scalars['String']>;
  StringArrayComparisonExp: StringArrayComparisonExp;
  StringComparisonExp: StringComparisonExp;
  SubmitAndGradeAssessmentOutput: ResolverTypeWrapper<SubmitAndGradeAssessmentOutput>;
  Subordinates: ResolverTypeWrapper<Subordinates>;
  SubordinatesAggregate: ResolverTypeWrapper<SubordinatesAggregate>;
  SubordinatesAggregateFields: ResolverTypeWrapper<SubordinatesAggregateFields>;
  SubordinatesBoolExp: SubordinatesBoolExp;
  SubordinatesMaxFields: ResolverTypeWrapper<SubordinatesMaxFields>;
  SubordinatesMinFields: ResolverTypeWrapper<SubordinatesMinFields>;
  SubordinatesOrderBy: SubordinatesOrderBy;
  SubordinatesSelectColumn: SubordinatesSelectColumn;
  SubordinatesStreamCursorInput: SubordinatesStreamCursorInput;
  SubordinatesStreamCursorValueInput: SubordinatesStreamCursorValueInput;
  SuccessOutput: ResolverTypeWrapper<SuccessOutput>;
  TagTypeScope: ResolverTypeWrapper<TagTypeScope>;
  TagTypeScopeAggregate: ResolverTypeWrapper<TagTypeScopeAggregate>;
  TagTypeScopeAggregateFields: ResolverTypeWrapper<TagTypeScopeAggregateFields>;
  TagTypeScopeBoolExp: TagTypeScopeBoolExp;
  TagTypeScopeConstraint: TagTypeScopeConstraint;
  TagTypeScopeEnum: TagTypeScopeEnum;
  TagTypeScopeEnumComparisonExp: TagTypeScopeEnumComparisonExp;
  TagTypeScopeInsertInput: TagTypeScopeInsertInput;
  TagTypeScopeMaxFields: ResolverTypeWrapper<TagTypeScopeMaxFields>;
  TagTypeScopeMinFields: ResolverTypeWrapper<TagTypeScopeMinFields>;
  TagTypeScopeMutationResponse: ResolverTypeWrapper<TagTypeScopeMutationResponse>;
  TagTypeScopeOnConflict: TagTypeScopeOnConflict;
  TagTypeScopeOrderBy: TagTypeScopeOrderBy;
  TagTypeScopePkColumnsInput: TagTypeScopePkColumnsInput;
  TagTypeScopeSelectColumn: TagTypeScopeSelectColumn;
  TagTypeScopeSetInput: TagTypeScopeSetInput;
  TagTypeScopeStreamCursorInput: TagTypeScopeStreamCursorInput;
  TagTypeScopeStreamCursorValueInput: TagTypeScopeStreamCursorValueInput;
  TagTypeScopeUpdateColumn: TagTypeScopeUpdateColumn;
  TagTypeScopeUpdates: TagTypeScopeUpdates;
  TagTypes: ResolverTypeWrapper<TagTypes>;
  TagTypesAggregate: ResolverTypeWrapper<TagTypesAggregate>;
  TagTypesAggregateFields: ResolverTypeWrapper<TagTypesAggregateFields>;
  TagTypesBoolExp: TagTypesBoolExp;
  TagTypesConstraint: TagTypesConstraint;
  TagTypesInsertInput: TagTypesInsertInput;
  TagTypesMaxFields: ResolverTypeWrapper<TagTypesMaxFields>;
  TagTypesMinFields: ResolverTypeWrapper<TagTypesMinFields>;
  TagTypesMutationResponse: ResolverTypeWrapper<TagTypesMutationResponse>;
  TagTypesObjRelInsertInput: TagTypesObjRelInsertInput;
  TagTypesOnConflict: TagTypesOnConflict;
  TagTypesOrderBy: TagTypesOrderBy;
  TagTypesPkColumnsInput: TagTypesPkColumnsInput;
  TagTypesSelectColumn: TagTypesSelectColumn;
  TagTypesSetInput: TagTypesSetInput;
  TagTypesStreamCursorInput: TagTypesStreamCursorInput;
  TagTypesStreamCursorValueInput: TagTypesStreamCursorValueInput;
  TagTypesUpdateColumn: TagTypesUpdateColumn;
  TagTypesUpdates: TagTypesUpdates;
  Tags: ResolverTypeWrapper<Tags>;
  TagsAggregate: ResolverTypeWrapper<TagsAggregate>;
  TagsAggregateBoolExp: TagsAggregateBoolExp;
  TagsAggregateFields: ResolverTypeWrapper<TagsAggregateFields>;
  TagsAggregateOrderBy: TagsAggregateOrderBy;
  TagsArrRelInsertInput: TagsArrRelInsertInput;
  TagsBoolExp: TagsBoolExp;
  TagsConstraint: TagsConstraint;
  TagsInsertInput: TagsInsertInput;
  TagsMaxFields: ResolverTypeWrapper<TagsMaxFields>;
  TagsMaxOrderBy: TagsMaxOrderBy;
  TagsMinFields: ResolverTypeWrapper<TagsMinFields>;
  TagsMinOrderBy: TagsMinOrderBy;
  TagsMutationResponse: ResolverTypeWrapper<TagsMutationResponse>;
  TagsObjRelInsertInput: TagsObjRelInsertInput;
  TagsOnConflict: TagsOnConflict;
  TagsOrderBy: TagsOrderBy;
  TagsPkColumnsInput: TagsPkColumnsInput;
  TagsSelectColumn: TagsSelectColumn;
  TagsSetInput: TagsSetInput;
  TagsStreamCursorInput: TagsStreamCursorInput;
  TagsStreamCursorValueInput: TagsStreamCursorValueInput;
  TagsUpdateColumn: TagsUpdateColumn;
  TagsUpdates: TagsUpdates;
  TaskDefinition: ResolverTypeWrapper<TaskDefinition>;
  TaskDefinitionAggregate: ResolverTypeWrapper<TaskDefinitionAggregate>;
  TaskDefinitionAggregateFields: ResolverTypeWrapper<TaskDefinitionAggregateFields>;
  TaskDefinitionBoolExp: TaskDefinitionBoolExp;
  TaskDefinitionConstraint: TaskDefinitionConstraint;
  TaskDefinitionInsertInput: TaskDefinitionInsertInput;
  TaskDefinitionMaxFields: ResolverTypeWrapper<TaskDefinitionMaxFields>;
  TaskDefinitionMinFields: ResolverTypeWrapper<TaskDefinitionMinFields>;
  TaskDefinitionMutationResponse: ResolverTypeWrapper<TaskDefinitionMutationResponse>;
  TaskDefinitionObjRelInsertInput: TaskDefinitionObjRelInsertInput;
  TaskDefinitionOnConflict: TaskDefinitionOnConflict;
  TaskDefinitionOrderBy: TaskDefinitionOrderBy;
  TaskDefinitionPkColumnsInput: TaskDefinitionPkColumnsInput;
  TaskDefinitionSelectColumn: TaskDefinitionSelectColumn;
  TaskDefinitionSetInput: TaskDefinitionSetInput;
  TaskDefinitionStreamCursorInput: TaskDefinitionStreamCursorInput;
  TaskDefinitionStreamCursorValueInput: TaskDefinitionStreamCursorValueInput;
  TaskDefinitionType: ResolverTypeWrapper<TaskDefinitionType>;
  TaskDefinitionTypeAggregate: ResolverTypeWrapper<TaskDefinitionTypeAggregate>;
  TaskDefinitionTypeAggregateFields: ResolverTypeWrapper<TaskDefinitionTypeAggregateFields>;
  TaskDefinitionTypeBoolExp: TaskDefinitionTypeBoolExp;
  TaskDefinitionTypeConstraint: TaskDefinitionTypeConstraint;
  TaskDefinitionTypeEnum: TaskDefinitionTypeEnum;
  TaskDefinitionTypeEnumComparisonExp: TaskDefinitionTypeEnumComparisonExp;
  TaskDefinitionTypeInsertInput: TaskDefinitionTypeInsertInput;
  TaskDefinitionTypeMaxFields: ResolverTypeWrapper<TaskDefinitionTypeMaxFields>;
  TaskDefinitionTypeMinFields: ResolverTypeWrapper<TaskDefinitionTypeMinFields>;
  TaskDefinitionTypeMutationResponse: ResolverTypeWrapper<TaskDefinitionTypeMutationResponse>;
  TaskDefinitionTypeOnConflict: TaskDefinitionTypeOnConflict;
  TaskDefinitionTypeOrderBy: TaskDefinitionTypeOrderBy;
  TaskDefinitionTypePkColumnsInput: TaskDefinitionTypePkColumnsInput;
  TaskDefinitionTypeSelectColumn: TaskDefinitionTypeSelectColumn;
  TaskDefinitionTypeSetInput: TaskDefinitionTypeSetInput;
  TaskDefinitionTypeStreamCursorInput: TaskDefinitionTypeStreamCursorInput;
  TaskDefinitionTypeStreamCursorValueInput: TaskDefinitionTypeStreamCursorValueInput;
  TaskDefinitionTypeUpdateColumn: TaskDefinitionTypeUpdateColumn;
  TaskDefinitionTypeUpdates: TaskDefinitionTypeUpdates;
  TaskDefinitionUpdateColumn: TaskDefinitionUpdateColumn;
  TaskDefinitionUpdates: TaskDefinitionUpdates;
  Tasks: ResolverTypeWrapper<Tasks>;
  TasksAggregate: ResolverTypeWrapper<TasksAggregate>;
  TasksAggregateBoolExp: TasksAggregateBoolExp;
  TasksAggregateFields: ResolverTypeWrapper<TasksAggregateFields>;
  TasksAggregateOrderBy: TasksAggregateOrderBy;
  TasksAppendInput: TasksAppendInput;
  TasksArrRelInsertInput: TasksArrRelInsertInput;
  TasksBoolExp: TasksBoolExp;
  TasksConstraint: TasksConstraint;
  TasksDeleteAtPathInput: TasksDeleteAtPathInput;
  TasksDeleteElemInput: TasksDeleteElemInput;
  TasksDeleteKeyInput: TasksDeleteKeyInput;
  TasksInsertInput: TasksInsertInput;
  TasksMaxFields: ResolverTypeWrapper<TasksMaxFields>;
  TasksMaxOrderBy: TasksMaxOrderBy;
  TasksMinFields: ResolverTypeWrapper<TasksMinFields>;
  TasksMinOrderBy: TasksMinOrderBy;
  TasksMutationResponse: ResolverTypeWrapper<TasksMutationResponse>;
  TasksObjRelInsertInput: TasksObjRelInsertInput;
  TasksOnConflict: TasksOnConflict;
  TasksOrderBy: TasksOrderBy;
  TasksPkColumnsInput: TasksPkColumnsInput;
  TasksPrependInput: TasksPrependInput;
  TasksSelectColumn: TasksSelectColumn;
  TasksSetInput: TasksSetInput;
  TasksStreamCursorInput: TasksStreamCursorInput;
  TasksStreamCursorValueInput: TasksStreamCursorValueInput;
  TasksUpdateColumn: TasksUpdateColumn;
  TasksUpdates: TasksUpdates;
  TeamOutput: ResolverTypeWrapper<TeamOutput>;
  Teams: ResolverTypeWrapper<Teams>;
  TeamsAggregate: ResolverTypeWrapper<TeamsAggregate>;
  TeamsAggregateFields: ResolverTypeWrapper<TeamsAggregateFields>;
  TeamsBoolExp: TeamsBoolExp;
  TeamsConstraint: TeamsConstraint;
  TeamsInsertInput: TeamsInsertInput;
  TeamsMaxFields: ResolverTypeWrapper<TeamsMaxFields>;
  TeamsMinFields: ResolverTypeWrapper<TeamsMinFields>;
  TeamsMutationResponse: ResolverTypeWrapper<TeamsMutationResponse>;
  TeamsObjRelInsertInput: TeamsObjRelInsertInput;
  TeamsOnConflict: TeamsOnConflict;
  TeamsOrderBy: TeamsOrderBy;
  TeamsPkColumnsInput: TeamsPkColumnsInput;
  TeamsSelectColumn: TeamsSelectColumn;
  TeamsSetInput: TeamsSetInput;
  TeamsStreamCursorInput: TeamsStreamCursorInput;
  TeamsStreamCursorValueInput: TeamsStreamCursorValueInput;
  TeamsUpdateColumn: TeamsUpdateColumn;
  TeamsUpdates: TeamsUpdates;
  TeamsUsers: ResolverTypeWrapper<TeamsUsers>;
  TeamsUsersAggregate: ResolverTypeWrapper<TeamsUsersAggregate>;
  TeamsUsersAggregateBoolExp: TeamsUsersAggregateBoolExp;
  TeamsUsersAggregateBoolExpCount: TeamsUsersAggregateBoolExpCount;
  TeamsUsersAggregateFields: ResolverTypeWrapper<TeamsUsersAggregateFields>;
  TeamsUsersAggregateOrderBy: TeamsUsersAggregateOrderBy;
  TeamsUsersArrRelInsertInput: TeamsUsersArrRelInsertInput;
  TeamsUsersBoolExp: TeamsUsersBoolExp;
  TeamsUsersConstraint: TeamsUsersConstraint;
  TeamsUsersInsertInput: TeamsUsersInsertInput;
  TeamsUsersMaxFields: ResolverTypeWrapper<TeamsUsersMaxFields>;
  TeamsUsersMaxOrderBy: TeamsUsersMaxOrderBy;
  TeamsUsersMinFields: ResolverTypeWrapper<TeamsUsersMinFields>;
  TeamsUsersMinOrderBy: TeamsUsersMinOrderBy;
  TeamsUsersMutationResponse: ResolverTypeWrapper<TeamsUsersMutationResponse>;
  TeamsUsersOnConflict: TeamsUsersOnConflict;
  TeamsUsersOrderBy: TeamsUsersOrderBy;
  TeamsUsersPkColumnsInput: TeamsUsersPkColumnsInput;
  TeamsUsersSelectColumn: TeamsUsersSelectColumn;
  TeamsUsersSetInput: TeamsUsersSetInput;
  TeamsUsersStreamCursorInput: TeamsUsersStreamCursorInput;
  TeamsUsersStreamCursorValueInput: TeamsUsersStreamCursorValueInput;
  TeamsUsersUpdateColumn: TeamsUsersUpdateColumn;
  TeamsUsersUpdates: TeamsUsersUpdates;
  TimeElapsedLiveClasses: ResolverTypeWrapper<TimeElapsedLiveClasses>;
  TimeElapsedLiveClassesAggregate: ResolverTypeWrapper<TimeElapsedLiveClassesAggregate>;
  TimeElapsedLiveClassesAggregateFields: ResolverTypeWrapper<TimeElapsedLiveClassesAggregateFields>;
  TimeElapsedLiveClassesAvgFields: ResolverTypeWrapper<TimeElapsedLiveClassesAvgFields>;
  TimeElapsedLiveClassesBoolExp: TimeElapsedLiveClassesBoolExp;
  TimeElapsedLiveClassesMaxFields: ResolverTypeWrapper<TimeElapsedLiveClassesMaxFields>;
  TimeElapsedLiveClassesMinFields: ResolverTypeWrapper<TimeElapsedLiveClassesMinFields>;
  TimeElapsedLiveClassesOrderBy: TimeElapsedLiveClassesOrderBy;
  TimeElapsedLiveClassesSelectColumn: TimeElapsedLiveClassesSelectColumn;
  TimeElapsedLiveClassesStddevFields: ResolverTypeWrapper<TimeElapsedLiveClassesStddevFields>;
  TimeElapsedLiveClassesStddevPopFields: ResolverTypeWrapper<TimeElapsedLiveClassesStddevPopFields>;
  TimeElapsedLiveClassesStddevSampFields: ResolverTypeWrapper<TimeElapsedLiveClassesStddevSampFields>;
  TimeElapsedLiveClassesStreamCursorInput: TimeElapsedLiveClassesStreamCursorInput;
  TimeElapsedLiveClassesStreamCursorValueInput: TimeElapsedLiveClassesStreamCursorValueInput;
  TimeElapsedLiveClassesSumFields: ResolverTypeWrapper<TimeElapsedLiveClassesSumFields>;
  TimeElapsedLiveClassesVarPopFields: ResolverTypeWrapper<TimeElapsedLiveClassesVarPopFields>;
  TimeElapsedLiveClassesVarSampFields: ResolverTypeWrapper<TimeElapsedLiveClassesVarSampFields>;
  TimeElapsedLiveClassesVarianceFields: ResolverTypeWrapper<TimeElapsedLiveClassesVarianceFields>;
  TimestampComparisonExp: TimestampComparisonExp;
  TimestamptzComparisonExp: TimestamptzComparisonExp;
  TypeformSubmissions: ResolverTypeWrapper<TypeformSubmissions>;
  TypeformSubmissionsAggregate: ResolverTypeWrapper<TypeformSubmissionsAggregate>;
  TypeformSubmissionsAggregateFields: ResolverTypeWrapper<TypeformSubmissionsAggregateFields>;
  TypeformSubmissionsAppendInput: TypeformSubmissionsAppendInput;
  TypeformSubmissionsBoolExp: TypeformSubmissionsBoolExp;
  TypeformSubmissionsConstraint: TypeformSubmissionsConstraint;
  TypeformSubmissionsDeleteAtPathInput: TypeformSubmissionsDeleteAtPathInput;
  TypeformSubmissionsDeleteElemInput: TypeformSubmissionsDeleteElemInput;
  TypeformSubmissionsDeleteKeyInput: TypeformSubmissionsDeleteKeyInput;
  TypeformSubmissionsInsertInput: TypeformSubmissionsInsertInput;
  TypeformSubmissionsMaxFields: ResolverTypeWrapper<TypeformSubmissionsMaxFields>;
  TypeformSubmissionsMinFields: ResolverTypeWrapper<TypeformSubmissionsMinFields>;
  TypeformSubmissionsMutationResponse: ResolverTypeWrapper<TypeformSubmissionsMutationResponse>;
  TypeformSubmissionsOnConflict: TypeformSubmissionsOnConflict;
  TypeformSubmissionsOrderBy: TypeformSubmissionsOrderBy;
  TypeformSubmissionsPkColumnsInput: TypeformSubmissionsPkColumnsInput;
  TypeformSubmissionsPrependInput: TypeformSubmissionsPrependInput;
  TypeformSubmissionsSelectColumn: TypeformSubmissionsSelectColumn;
  TypeformSubmissionsSetInput: TypeformSubmissionsSetInput;
  TypeformSubmissionsStreamCursorInput: TypeformSubmissionsStreamCursorInput;
  TypeformSubmissionsStreamCursorValueInput: TypeformSubmissionsStreamCursorValueInput;
  TypeformSubmissionsUpdateColumn: TypeformSubmissionsUpdateColumn;
  TypeformSubmissionsUpdates: TypeformSubmissionsUpdates;
  UnenrollInput: UnenrollInput;
  UnenrollOutput: ResolverTypeWrapper<UnenrollOutput>;
  UpdatePlaybackInput: UpdatePlaybackInput;
  UpdatePlaybackOutput: ResolverTypeWrapper<UpdatePlaybackOutput>;
  UserCompletedSurveys: ResolverTypeWrapper<UserCompletedSurveys>;
  UserCompletedSurveysAggregate: ResolverTypeWrapper<UserCompletedSurveysAggregate>;
  UserCompletedSurveysAggregateFields: ResolverTypeWrapper<UserCompletedSurveysAggregateFields>;
  UserCompletedSurveysAvgFields: ResolverTypeWrapper<UserCompletedSurveysAvgFields>;
  UserCompletedSurveysBoolExp: UserCompletedSurveysBoolExp;
  UserCompletedSurveysMaxFields: ResolverTypeWrapper<UserCompletedSurveysMaxFields>;
  UserCompletedSurveysMinFields: ResolverTypeWrapper<UserCompletedSurveysMinFields>;
  UserCompletedSurveysOrderBy: UserCompletedSurveysOrderBy;
  UserCompletedSurveysSelectColumn: UserCompletedSurveysSelectColumn;
  UserCompletedSurveysStddevFields: ResolverTypeWrapper<UserCompletedSurveysStddevFields>;
  UserCompletedSurveysStddevPopFields: ResolverTypeWrapper<UserCompletedSurveysStddevPopFields>;
  UserCompletedSurveysStddevSampFields: ResolverTypeWrapper<UserCompletedSurveysStddevSampFields>;
  UserCompletedSurveysStreamCursorInput: UserCompletedSurveysStreamCursorInput;
  UserCompletedSurveysStreamCursorValueInput: UserCompletedSurveysStreamCursorValueInput;
  UserCompletedSurveysSumFields: ResolverTypeWrapper<UserCompletedSurveysSumFields>;
  UserCompletedSurveysVarPopFields: ResolverTypeWrapper<UserCompletedSurveysVarPopFields>;
  UserCompletedSurveysVarSampFields: ResolverTypeWrapper<UserCompletedSurveysVarSampFields>;
  UserCompletedSurveysVarianceFields: ResolverTypeWrapper<UserCompletedSurveysVarianceFields>;
  UserNumCompletedClasses: ResolverTypeWrapper<UserNumCompletedClasses>;
  UserNumCompletedClassesAggregate: ResolverTypeWrapper<UserNumCompletedClassesAggregate>;
  UserNumCompletedClassesAggregateFields: ResolverTypeWrapper<UserNumCompletedClassesAggregateFields>;
  UserNumCompletedClassesAvgFields: ResolverTypeWrapper<UserNumCompletedClassesAvgFields>;
  UserNumCompletedClassesBoolExp: UserNumCompletedClassesBoolExp;
  UserNumCompletedClassesMaxFields: ResolverTypeWrapper<UserNumCompletedClassesMaxFields>;
  UserNumCompletedClassesMinFields: ResolverTypeWrapper<UserNumCompletedClassesMinFields>;
  UserNumCompletedClassesOrderBy: UserNumCompletedClassesOrderBy;
  UserNumCompletedClassesSelectColumn: UserNumCompletedClassesSelectColumn;
  UserNumCompletedClassesStddevFields: ResolverTypeWrapper<UserNumCompletedClassesStddevFields>;
  UserNumCompletedClassesStddevPopFields: ResolverTypeWrapper<UserNumCompletedClassesStddevPopFields>;
  UserNumCompletedClassesStddevSampFields: ResolverTypeWrapper<UserNumCompletedClassesStddevSampFields>;
  UserNumCompletedClassesStreamCursorInput: UserNumCompletedClassesStreamCursorInput;
  UserNumCompletedClassesStreamCursorValueInput: UserNumCompletedClassesStreamCursorValueInput;
  UserNumCompletedClassesSumFields: ResolverTypeWrapper<UserNumCompletedClassesSumFields>;
  UserNumCompletedClassesVarPopFields: ResolverTypeWrapper<UserNumCompletedClassesVarPopFields>;
  UserNumCompletedClassesVarSampFields: ResolverTypeWrapper<UserNumCompletedClassesVarSampFields>;
  UserNumCompletedClassesVarianceFields: ResolverTypeWrapper<UserNumCompletedClassesVarianceFields>;
  UserProfileCaptureOutput: ResolverTypeWrapper<UserProfileCaptureOutput>;
  UserSessionEmails: ResolverTypeWrapper<UserSessionEmails>;
  UserSessionEmailsAggregate: ResolverTypeWrapper<UserSessionEmailsAggregate>;
  UserSessionEmailsAggregateFields: ResolverTypeWrapper<UserSessionEmailsAggregateFields>;
  UserSessionEmailsBoolExp: UserSessionEmailsBoolExp;
  UserSessionEmailsConstraint: UserSessionEmailsConstraint;
  UserSessionEmailsInsertInput: UserSessionEmailsInsertInput;
  UserSessionEmailsMaxFields: ResolverTypeWrapper<UserSessionEmailsMaxFields>;
  UserSessionEmailsMinFields: ResolverTypeWrapper<UserSessionEmailsMinFields>;
  UserSessionEmailsMutationResponse: ResolverTypeWrapper<UserSessionEmailsMutationResponse>;
  UserSessionEmailsOnConflict: UserSessionEmailsOnConflict;
  UserSessionEmailsOrderBy: UserSessionEmailsOrderBy;
  UserSessionEmailsPkColumnsInput: UserSessionEmailsPkColumnsInput;
  UserSessionEmailsSelectColumn: UserSessionEmailsSelectColumn;
  UserSessionEmailsSetInput: UserSessionEmailsSetInput;
  UserSessionEmailsStreamCursorInput: UserSessionEmailsStreamCursorInput;
  UserSessionEmailsStreamCursorValueInput: UserSessionEmailsStreamCursorValueInput;
  UserSessionEmailsUpdateColumn: UserSessionEmailsUpdateColumn;
  UserSessionEmailsUpdates: UserSessionEmailsUpdates;
  Users: ResolverTypeWrapper<Users>;
  UsersAggregate: ResolverTypeWrapper<UsersAggregate>;
  UsersAggregateFields: ResolverTypeWrapper<UsersAggregateFields>;
  UsersAvgFields: ResolverTypeWrapper<UsersAvgFields>;
  UsersBoolExp: UsersBoolExp;
  UsersConstraint: UsersConstraint;
  UsersFunctions: ResolverTypeWrapper<UsersFunctions>;
  UsersFunctionsAggregate: ResolverTypeWrapper<UsersFunctionsAggregate>;
  UsersFunctionsAggregateFields: ResolverTypeWrapper<UsersFunctionsAggregateFields>;
  UsersFunctionsBoolExp: UsersFunctionsBoolExp;
  UsersFunctionsConstraint: UsersFunctionsConstraint;
  UsersFunctionsEnum: UsersFunctionsEnum;
  UsersFunctionsEnumComparisonExp: UsersFunctionsEnumComparisonExp;
  UsersFunctionsInsertInput: UsersFunctionsInsertInput;
  UsersFunctionsMaxFields: ResolverTypeWrapper<UsersFunctionsMaxFields>;
  UsersFunctionsMinFields: ResolverTypeWrapper<UsersFunctionsMinFields>;
  UsersFunctionsMutationResponse: ResolverTypeWrapper<UsersFunctionsMutationResponse>;
  UsersFunctionsObjRelInsertInput: UsersFunctionsObjRelInsertInput;
  UsersFunctionsOnConflict: UsersFunctionsOnConflict;
  UsersFunctionsOrderBy: UsersFunctionsOrderBy;
  UsersFunctionsPkColumnsInput: UsersFunctionsPkColumnsInput;
  UsersFunctionsSelectColumn: UsersFunctionsSelectColumn;
  UsersFunctionsSetInput: UsersFunctionsSetInput;
  UsersFunctionsStreamCursorInput: UsersFunctionsStreamCursorInput;
  UsersFunctionsStreamCursorValueInput: UsersFunctionsStreamCursorValueInput;
  UsersFunctionsUpdateColumn: UsersFunctionsUpdateColumn;
  UsersFunctionsUpdates: UsersFunctionsUpdates;
  UsersIncInput: UsersIncInput;
  UsersInsertInput: UsersInsertInput;
  UsersLengthInFunction: ResolverTypeWrapper<UsersLengthInFunction>;
  UsersLengthInFunctionAggregate: ResolverTypeWrapper<UsersLengthInFunctionAggregate>;
  UsersLengthInFunctionAggregateFields: ResolverTypeWrapper<UsersLengthInFunctionAggregateFields>;
  UsersLengthInFunctionBoolExp: UsersLengthInFunctionBoolExp;
  UsersLengthInFunctionConstraint: UsersLengthInFunctionConstraint;
  UsersLengthInFunctionEnum: UsersLengthInFunctionEnum;
  UsersLengthInFunctionEnumComparisonExp: UsersLengthInFunctionEnumComparisonExp;
  UsersLengthInFunctionInsertInput: UsersLengthInFunctionInsertInput;
  UsersLengthInFunctionMaxFields: ResolverTypeWrapper<UsersLengthInFunctionMaxFields>;
  UsersLengthInFunctionMinFields: ResolverTypeWrapper<UsersLengthInFunctionMinFields>;
  UsersLengthInFunctionMutationResponse: ResolverTypeWrapper<UsersLengthInFunctionMutationResponse>;
  UsersLengthInFunctionOnConflict: UsersLengthInFunctionOnConflict;
  UsersLengthInFunctionOrderBy: UsersLengthInFunctionOrderBy;
  UsersLengthInFunctionPkColumnsInput: UsersLengthInFunctionPkColumnsInput;
  UsersLengthInFunctionSelectColumn: UsersLengthInFunctionSelectColumn;
  UsersLengthInFunctionSetInput: UsersLengthInFunctionSetInput;
  UsersLengthInFunctionStreamCursorInput: UsersLengthInFunctionStreamCursorInput;
  UsersLengthInFunctionStreamCursorValueInput: UsersLengthInFunctionStreamCursorValueInput;
  UsersLengthInFunctionUpdateColumn: UsersLengthInFunctionUpdateColumn;
  UsersLengthInFunctionUpdates: UsersLengthInFunctionUpdates;
  UsersMaxFields: ResolverTypeWrapper<UsersMaxFields>;
  UsersMeta: ResolverTypeWrapper<UsersMeta>;
  UsersMetaAggregate: ResolverTypeWrapper<UsersMetaAggregate>;
  UsersMetaAggregateFields: ResolverTypeWrapper<UsersMetaAggregateFields>;
  UsersMetaAppendInput: UsersMetaAppendInput;
  UsersMetaBoolExp: UsersMetaBoolExp;
  UsersMetaConstraint: UsersMetaConstraint;
  UsersMetaDeleteAtPathInput: UsersMetaDeleteAtPathInput;
  UsersMetaDeleteElemInput: UsersMetaDeleteElemInput;
  UsersMetaDeleteKeyInput: UsersMetaDeleteKeyInput;
  UsersMetaInsertInput: UsersMetaInsertInput;
  UsersMetaMaxFields: ResolverTypeWrapper<UsersMetaMaxFields>;
  UsersMetaMinFields: ResolverTypeWrapper<UsersMetaMinFields>;
  UsersMetaMutationResponse: ResolverTypeWrapper<UsersMetaMutationResponse>;
  UsersMetaObjRelInsertInput: UsersMetaObjRelInsertInput;
  UsersMetaOnConflict: UsersMetaOnConflict;
  UsersMetaOrderBy: UsersMetaOrderBy;
  UsersMetaPkColumnsInput: UsersMetaPkColumnsInput;
  UsersMetaPrependInput: UsersMetaPrependInput;
  UsersMetaSelectColumn: UsersMetaSelectColumn;
  UsersMetaSetInput: UsersMetaSetInput;
  UsersMetaStreamCursorInput: UsersMetaStreamCursorInput;
  UsersMetaStreamCursorValueInput: UsersMetaStreamCursorValueInput;
  UsersMetaUpdateColumn: UsersMetaUpdateColumn;
  UsersMetaUpdates: UsersMetaUpdates;
  UsersMinFields: ResolverTypeWrapper<UsersMinFields>;
  UsersMutationResponse: ResolverTypeWrapper<UsersMutationResponse>;
  UsersObjRelInsertInput: UsersObjRelInsertInput;
  UsersOnConflict: UsersOnConflict;
  UsersOrderBy: UsersOrderBy;
  UsersPkColumnsInput: UsersPkColumnsInput;
  UsersSelectColumn: UsersSelectColumn;
  UsersSetInput: UsersSetInput;
  UsersStddevFields: ResolverTypeWrapper<UsersStddevFields>;
  UsersStddevPopFields: ResolverTypeWrapper<UsersStddevPopFields>;
  UsersStddevSampFields: ResolverTypeWrapper<UsersStddevSampFields>;
  UsersStreamCursorInput: UsersStreamCursorInput;
  UsersStreamCursorValueInput: UsersStreamCursorValueInput;
  UsersSumFields: ResolverTypeWrapper<UsersSumFields>;
  UsersUpdateColumn: UsersUpdateColumn;
  UsersUpdates: UsersUpdates;
  UsersVarPopFields: ResolverTypeWrapper<UsersVarPopFields>;
  UsersVarSampFields: ResolverTypeWrapper<UsersVarSampFields>;
  UsersVarianceFields: ResolverTypeWrapper<UsersVarianceFields>;
  UuidArrayComparisonExp: UuidArrayComparisonExp;
  UuidComparisonExp: UuidComparisonExp;
  ZoomOauthTokens: ResolverTypeWrapper<ZoomOauthTokens>;
  ZoomOauthTokensAggregate: ResolverTypeWrapper<ZoomOauthTokensAggregate>;
  ZoomOauthTokensAggregateFields: ResolverTypeWrapper<ZoomOauthTokensAggregateFields>;
  ZoomOauthTokensAvgFields: ResolverTypeWrapper<ZoomOauthTokensAvgFields>;
  ZoomOauthTokensBoolExp: ZoomOauthTokensBoolExp;
  ZoomOauthTokensConstraint: ZoomOauthTokensConstraint;
  ZoomOauthTokensIncInput: ZoomOauthTokensIncInput;
  ZoomOauthTokensInsertInput: ZoomOauthTokensInsertInput;
  ZoomOauthTokensMaxFields: ResolverTypeWrapper<ZoomOauthTokensMaxFields>;
  ZoomOauthTokensMinFields: ResolverTypeWrapper<ZoomOauthTokensMinFields>;
  ZoomOauthTokensMutationResponse: ResolverTypeWrapper<ZoomOauthTokensMutationResponse>;
  ZoomOauthTokensOnConflict: ZoomOauthTokensOnConflict;
  ZoomOauthTokensOrderBy: ZoomOauthTokensOrderBy;
  ZoomOauthTokensPkColumnsInput: ZoomOauthTokensPkColumnsInput;
  ZoomOauthTokensSelectColumn: ZoomOauthTokensSelectColumn;
  ZoomOauthTokensSetInput: ZoomOauthTokensSetInput;
  ZoomOauthTokensStddevFields: ResolverTypeWrapper<ZoomOauthTokensStddevFields>;
  ZoomOauthTokensStddevPopFields: ResolverTypeWrapper<ZoomOauthTokensStddevPopFields>;
  ZoomOauthTokensStddevSampFields: ResolverTypeWrapper<ZoomOauthTokensStddevSampFields>;
  ZoomOauthTokensStreamCursorInput: ZoomOauthTokensStreamCursorInput;
  ZoomOauthTokensStreamCursorValueInput: ZoomOauthTokensStreamCursorValueInput;
  ZoomOauthTokensSumFields: ResolverTypeWrapper<ZoomOauthTokensSumFields>;
  ZoomOauthTokensUpdateColumn: ZoomOauthTokensUpdateColumn;
  ZoomOauthTokensUpdates: ZoomOauthTokensUpdates;
  ZoomOauthTokensVarPopFields: ResolverTypeWrapper<ZoomOauthTokensVarPopFields>;
  ZoomOauthTokensVarSampFields: ResolverTypeWrapper<ZoomOauthTokensVarSampFields>;
  ZoomOauthTokensVarianceFields: ResolverTypeWrapper<ZoomOauthTokensVarianceFields>;
  bigint: ResolverTypeWrapper<Scalars['bigint']>;
  bpchar: ResolverTypeWrapper<Scalars['bpchar']>;
  catalogsJourneysRelAggregateBoolExpCount: CatalogsJourneysRelAggregateBoolExpCount;
  classEventActivityAggregateBoolExpCount: ClassEventActivityAggregateBoolExpCount;
  classEventMeetingParticipantsAggregateBoolExpCount: ClassEventMeetingParticipantsAggregateBoolExpCount;
  classEventMeetingSegmentsAggregateBoolExpBool_and: ClassEventMeetingSegmentsAggregateBoolExpBool_And;
  classEventMeetingSegmentsAggregateBoolExpBool_or: ClassEventMeetingSegmentsAggregateBoolExpBool_Or;
  classEventMeetingSegmentsAggregateBoolExpCount: ClassEventMeetingSegmentsAggregateBoolExpCount;
  classEventMeetingsAggregateBoolExpBool_and: ClassEventMeetingsAggregateBoolExpBool_And;
  classEventMeetingsAggregateBoolExpBool_or: ClassEventMeetingsAggregateBoolExpBool_Or;
  classEventMeetingsAggregateBoolExpCount: ClassEventMeetingsAggregateBoolExpCount;
  classEventsAggregateBoolExpCount: ClassEventsAggregateBoolExpCount;
  classesAggregateBoolExpBool_and: ClassesAggregateBoolExpBool_And;
  classesAggregateBoolExpBool_or: ClassesAggregateBoolExpBool_Or;
  classesAggregateBoolExpCount: ClassesAggregateBoolExpCount;
  classesTagsRelAggregateBoolExpCount: ClassesTagsRelAggregateBoolExpCount;
  coachesAggregateBoolExpCount: CoachesAggregateBoolExpCount;
  cohortsAggregateBoolExpBool_and: CohortsAggregateBoolExpBool_And;
  cohortsAggregateBoolExpBool_or: CohortsAggregateBoolExpBool_Or;
  cohortsAggregateBoolExpCount: CohortsAggregateBoolExpCount;
  cohortsResourcesRelAggregateBoolExpCount: CohortsResourcesRelAggregateBoolExpCount;
  coursesAggregateBoolExpCount: CoursesAggregateBoolExpCount;
  coursesTagsRelAggregateBoolExpCount: CoursesTagsRelAggregateBoolExpCount;
  creditUsage: ResolverTypeWrapper<CreditUsage>;
  creditUsageBoolExpBoolExp: CreditUsageBoolExpBoolExp;
  creditUsageByUser: ResolverTypeWrapper<CreditUsageByUser>;
  creditUsageByUserBoolExpBoolExp: CreditUsageByUserBoolExpBoolExp;
  creditUsageByUserOrderBy: CreditUsageByUserOrderBy;
  creditUsageByUser_enum_name: CreditUsageByUser_Enum_Name;
  creditUsageOrderBy: CreditUsageOrderBy;
  creditUsage_enum_name: CreditUsage_Enum_Name;
  date: ResolverTypeWrapper<Scalars['date']>;
  enrollmentWaitlistsAggregateBoolExpBool_and: EnrollmentWaitlistsAggregateBoolExpBool_And;
  enrollmentWaitlistsAggregateBoolExpBool_or: EnrollmentWaitlistsAggregateBoolExpBool_Or;
  enrollmentWaitlistsAggregateBoolExpCount: EnrollmentWaitlistsAggregateBoolExpCount;
  enrollmentsAggregateBoolExpCount: EnrollmentsAggregateBoolExpCount;
  float8: ResolverTypeWrapper<Scalars['float8']>;
  fusionauthUserRegistrationsAggregateBoolExpBool_and: FusionauthUserRegistrationsAggregateBoolExpBool_And;
  fusionauthUserRegistrationsAggregateBoolExpBool_or: FusionauthUserRegistrationsAggregateBoolExpBool_Or;
  fusionauthUserRegistrationsAggregateBoolExpCount: FusionauthUserRegistrationsAggregateBoolExpCount;
  getAssessmentQuestionsArgs: GetAssessmentQuestionsArgs;
  getTeamMembersNew: ResolverTypeWrapper<GetTeamMembersNew>;
  getTeamMembers_arguments: GetTeamMembers_Arguments;
  impactAssessmentAttemptsAggregateBoolExpBool_and: ImpactAssessmentAttemptsAggregateBoolExpBool_And;
  impactAssessmentAttemptsAggregateBoolExpBool_or: ImpactAssessmentAttemptsAggregateBoolExpBool_Or;
  impactAssessmentAttemptsAggregateBoolExpCount: ImpactAssessmentAttemptsAggregateBoolExpCount;
  impactAssessmentQuestionsAggregateBoolExpCount: ImpactAssessmentQuestionsAggregateBoolExpCount;
  journeyEnrollmentsAggregateBoolExpBool_and: JourneyEnrollmentsAggregateBoolExpBool_And;
  journeyEnrollmentsAggregateBoolExpBool_or: JourneyEnrollmentsAggregateBoolExpBool_Or;
  journeyEnrollmentsAggregateBoolExpCount: JourneyEnrollmentsAggregateBoolExpCount;
  journeysAggregateBoolExpBool_and: JourneysAggregateBoolExpBool_And;
  journeysAggregateBoolExpBool_or: JourneysAggregateBoolExpBool_Or;
  journeysAggregateBoolExpCount: JourneysAggregateBoolExpCount;
  json: ResolverTypeWrapper<Scalars['json']>;
  jsonb: ResolverTypeWrapper<Scalars['jsonb']>;
  learner_task: ResolverTypeWrapper<Learner_Task>;
  learner_taskBoolExpBoolExp: Learner_TaskBoolExpBoolExp;
  learner_taskOrderBy: Learner_TaskOrderBy;
  learner_task_enum_name: Learner_Task_Enum_Name;
  lightningSessionSubmissionsAggregateBoolExpBool_and: LightningSessionSubmissionsAggregateBoolExpBool_And;
  lightningSessionSubmissionsAggregateBoolExpBool_or: LightningSessionSubmissionsAggregateBoolExpBool_Or;
  lightningSessionSubmissionsAggregateBoolExpCount: LightningSessionSubmissionsAggregateBoolExpCount;
  lightningSessionSubmissionsStatus: ResolverTypeWrapper<LightningSessionSubmissionsStatus>;
  lightningSessionSubmissionsStatusAggregate: ResolverTypeWrapper<LightningSessionSubmissionsStatusAggregate>;
  lightningSessionSubmissionsStatusAggregateFields: ResolverTypeWrapper<LightningSessionSubmissionsStatusAggregateFields>;
  lightningSessionSubmissionsStatusBoolExp: LightningSessionSubmissionsStatusBoolExp;
  lightningSessionSubmissionsStatusConstraint: LightningSessionSubmissionsStatusConstraint;
  lightningSessionSubmissionsStatusEnum: LightningSessionSubmissionsStatusEnum;
  lightningSessionSubmissionsStatusInsertInput: LightningSessionSubmissionsStatusInsertInput;
  lightningSessionSubmissionsStatusMaxFields: ResolverTypeWrapper<LightningSessionSubmissionsStatusMaxFields>;
  lightningSessionSubmissionsStatusMinFields: ResolverTypeWrapper<LightningSessionSubmissionsStatusMinFields>;
  lightningSessionSubmissionsStatusMutationResponse: ResolverTypeWrapper<LightningSessionSubmissionsStatusMutationResponse>;
  lightningSessionSubmissionsStatusOnConflict: LightningSessionSubmissionsStatusOnConflict;
  lightningSessionSubmissionsStatusOrderBy: LightningSessionSubmissionsStatusOrderBy;
  lightningSessionSubmissionsStatusPkColumnsInput: LightningSessionSubmissionsStatusPkColumnsInput;
  lightningSessionSubmissionsStatusSelectColumn: LightningSessionSubmissionsStatusSelectColumn;
  lightningSessionSubmissionsStatusSetInput: LightningSessionSubmissionsStatusSetInput;
  lightningSessionSubmissionsStatusStreamCursorInput: LightningSessionSubmissionsStatusStreamCursorInput;
  lightningSessionSubmissionsStatusStreamCursorValueInput: LightningSessionSubmissionsStatusStreamCursorValueInput;
  lightningSessionSubmissionsStatusUpdateColumn: LightningSessionSubmissionsStatusUpdateColumn;
  lightningSessionSubmissionsStatusUpdates: LightningSessionSubmissionsStatusUpdates;
  lightningSessionSubmissionsUsersRelAggregateBoolExpCount: LightningSessionSubmissionsUsersRelAggregateBoolExpCount;
  mutation_root: ResolverTypeWrapper<{}>;
  myTeamFlatTree_arguments: MyTeamFlatTree_Arguments;
  my_learner_task_native_query_arguments: My_Learner_Task_Native_Query_Arguments;
  numeric: ResolverTypeWrapper<Scalars['numeric']>;
  planSubscriptionsAggregateBoolExpCount: PlanSubscriptionsAggregateBoolExpCount;
  programActionsTakenAggregateBoolExpCount: ProgramActionsTakenAggregateBoolExpCount;
  programCoursesShownAggregateBoolExpCount: ProgramCoursesShownAggregateBoolExpCount;
  programTagsAggregateBoolExpCount: ProgramTagsAggregateBoolExpCount;
  programUsersSelectedAggregateBoolExpCount: ProgramUsersSelectedAggregateBoolExpCount;
  publishCourseArgs: PublishCourseArgs;
  query_root: ResolverTypeWrapper<{}>;
  smallint: ResolverTypeWrapper<Scalars['smallint']>;
  streamMessagesAggregateBoolExpCount: StreamMessagesAggregateBoolExpCount;
  subscription_root: ResolverTypeWrapper<{}>;
  tagsAggregateBoolExpCount: TagsAggregateBoolExpCount;
  tasksAggregateBoolExpCount: TasksAggregateBoolExpCount;
  teamMember: ResolverTypeWrapper<TeamMember>;
  teamMemberBoolExpBoolExp: TeamMemberBoolExpBoolExp;
  teamMemberNewBoolExpBoolExp: TeamMemberNewBoolExpBoolExp;
  teamMemberNewOrderBy: TeamMemberNewOrderBy;
  teamMemberNew_enum_name: TeamMemberNew_Enum_Name;
  teamMemberOrderBy: TeamMemberOrderBy;
  teamMemberPollSubmissions: ResolverTypeWrapper<TeamMemberPollSubmissions>;
  teamMemberPollSubmissionsBoolExpBoolExp: TeamMemberPollSubmissionsBoolExpBoolExp;
  teamMemberPollSubmissionsOrderBy: TeamMemberPollSubmissionsOrderBy;
  teamMemberPollSubmissions_enum_name: TeamMemberPollSubmissions_Enum_Name;
  teamMember_enum_name: TeamMember_Enum_Name;
  team_flat_tree_node: ResolverTypeWrapper<Team_Flat_Tree_Node>;
  team_flat_tree_nodeBoolExpBoolExp: Team_Flat_Tree_NodeBoolExpBoolExp;
  team_flat_tree_nodeOrderBy: Team_Flat_Tree_NodeOrderBy;
  team_flat_tree_node_enum_name: Team_Flat_Tree_Node_Enum_Name;
  timestamp: ResolverTypeWrapper<Scalars['timestamp']>;
  timestamptz: ResolverTypeWrapper<Scalars['timestamptz']>;
  userManagedTeamsFlattenedTree: ResolverTypeWrapper<UserManagedTeamsFlattenedTree>;
  userManagedTeamsFlattenedTreeBoolExpBoolExp: UserManagedTeamsFlattenedTreeBoolExpBoolExp;
  userManagedTeamsFlattenedTreeOrderBy: UserManagedTeamsFlattenedTreeOrderBy;
  userManagedTeamsFlattenedTree_arguments: UserManagedTeamsFlattenedTree_Arguments;
  userManagedTeamsFlattenedTree_enum_name: UserManagedTeamsFlattenedTree_Enum_Name;
  uuid: ResolverTypeWrapper<Scalars['uuid']>;
  versionImpactAssessmentArgs: VersionImpactAssessmentArgs;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  ActivityType: ActivityType;
  ActivityTypeAggregate: ActivityTypeAggregate;
  ActivityTypeAggregateFields: ActivityTypeAggregateFields;
  ActivityTypeBoolExp: ActivityTypeBoolExp;
  ActivityTypeEnumComparisonExp: ActivityTypeEnumComparisonExp;
  ActivityTypeInsertInput: ActivityTypeInsertInput;
  ActivityTypeMaxFields: ActivityTypeMaxFields;
  ActivityTypeMinFields: ActivityTypeMinFields;
  ActivityTypeMutationResponse: ActivityTypeMutationResponse;
  ActivityTypeOnConflict: ActivityTypeOnConflict;
  ActivityTypeOrderBy: ActivityTypeOrderBy;
  ActivityTypePkColumnsInput: ActivityTypePkColumnsInput;
  ActivityTypeSetInput: ActivityTypeSetInput;
  ActivityTypeStreamCursorInput: ActivityTypeStreamCursorInput;
  ActivityTypeStreamCursorValueInput: ActivityTypeStreamCursorValueInput;
  ActivityTypeUpdates: ActivityTypeUpdates;
  AddUserToCourseWaitlistOutput: AddUserToCourseWaitlistOutput;
  AttemptStatus: AttemptStatus;
  AttemptStatusAggregate: AttemptStatusAggregate;
  AttemptStatusAggregateFields: AttemptStatusAggregateFields;
  AttemptStatusBoolExp: AttemptStatusBoolExp;
  AttemptStatusEnumComparisonExp: AttemptStatusEnumComparisonExp;
  AttemptStatusInsertInput: AttemptStatusInsertInput;
  AttemptStatusMaxFields: AttemptStatusMaxFields;
  AttemptStatusMinFields: AttemptStatusMinFields;
  AttemptStatusMutationResponse: AttemptStatusMutationResponse;
  AttemptStatusOnConflict: AttemptStatusOnConflict;
  AttemptStatusOrderBy: AttemptStatusOrderBy;
  AttemptStatusPkColumnsInput: AttemptStatusPkColumnsInput;
  AttemptStatusSetInput: AttemptStatusSetInput;
  AttemptStatusStreamCursorInput: AttemptStatusStreamCursorInput;
  AttemptStatusStreamCursorValueInput: AttemptStatusStreamCursorValueInput;
  AttemptStatusUpdates: AttemptStatusUpdates;
  BigintComparisonExp: BigintComparisonExp;
  Boolean: Scalars['Boolean'];
  BooleanComparisonExp: BooleanComparisonExp;
  BpcharComparisonExp: BpcharComparisonExp;
  CatalogCourses: CatalogCourses;
  CatalogCoursesAggregate: CatalogCoursesAggregate;
  CatalogCoursesAggregateBoolExp: CatalogCoursesAggregateBoolExp;
  CatalogCoursesAggregateBoolExpBool_and: CatalogCoursesAggregateBoolExpBool_And;
  CatalogCoursesAggregateBoolExpBool_or: CatalogCoursesAggregateBoolExpBool_Or;
  CatalogCoursesAggregateBoolExpCount: CatalogCoursesAggregateBoolExpCount;
  CatalogCoursesAggregateFields: CatalogCoursesAggregateFields;
  CatalogCoursesAggregateOrderBy: CatalogCoursesAggregateOrderBy;
  CatalogCoursesArrRelInsertInput: CatalogCoursesArrRelInsertInput;
  CatalogCoursesBoolExp: CatalogCoursesBoolExp;
  CatalogCoursesInsertInput: CatalogCoursesInsertInput;
  CatalogCoursesMaxFields: CatalogCoursesMaxFields;
  CatalogCoursesMaxOrderBy: CatalogCoursesMaxOrderBy;
  CatalogCoursesMinFields: CatalogCoursesMinFields;
  CatalogCoursesMinOrderBy: CatalogCoursesMinOrderBy;
  CatalogCoursesMutationResponse: CatalogCoursesMutationResponse;
  CatalogCoursesOnConflict: CatalogCoursesOnConflict;
  CatalogCoursesOrderBy: CatalogCoursesOrderBy;
  CatalogCoursesPkColumnsInput: CatalogCoursesPkColumnsInput;
  CatalogCoursesSetInput: CatalogCoursesSetInput;
  CatalogCoursesStreamCursorInput: CatalogCoursesStreamCursorInput;
  CatalogCoursesStreamCursorValueInput: CatalogCoursesStreamCursorValueInput;
  CatalogCoursesUpdates: CatalogCoursesUpdates;
  Catalogs: Catalogs;
  CatalogsAggregate: CatalogsAggregate;
  CatalogsAggregateFields: CatalogsAggregateFields;
  CatalogsBoolExp: CatalogsBoolExp;
  CatalogsInsertInput: CatalogsInsertInput;
  CatalogsJourneysRel: CatalogsJourneysRel;
  CatalogsJourneysRelAggregate: CatalogsJourneysRelAggregate;
  CatalogsJourneysRelAggregateBoolExp: CatalogsJourneysRelAggregateBoolExp;
  CatalogsJourneysRelAggregateFields: CatalogsJourneysRelAggregateFields;
  CatalogsJourneysRelAggregateOrderBy: CatalogsJourneysRelAggregateOrderBy;
  CatalogsJourneysRelArrRelInsertInput: CatalogsJourneysRelArrRelInsertInput;
  CatalogsJourneysRelBoolExp: CatalogsJourneysRelBoolExp;
  CatalogsJourneysRelInsertInput: CatalogsJourneysRelInsertInput;
  CatalogsJourneysRelMaxFields: CatalogsJourneysRelMaxFields;
  CatalogsJourneysRelMaxOrderBy: CatalogsJourneysRelMaxOrderBy;
  CatalogsJourneysRelMinFields: CatalogsJourneysRelMinFields;
  CatalogsJourneysRelMinOrderBy: CatalogsJourneysRelMinOrderBy;
  CatalogsJourneysRelMutationResponse: CatalogsJourneysRelMutationResponse;
  CatalogsJourneysRelOnConflict: CatalogsJourneysRelOnConflict;
  CatalogsJourneysRelOrderBy: CatalogsJourneysRelOrderBy;
  CatalogsJourneysRelPkColumnsInput: CatalogsJourneysRelPkColumnsInput;
  CatalogsJourneysRelSetInput: CatalogsJourneysRelSetInput;
  CatalogsJourneysRelStreamCursorInput: CatalogsJourneysRelStreamCursorInput;
  CatalogsJourneysRelStreamCursorValueInput: CatalogsJourneysRelStreamCursorValueInput;
  CatalogsJourneysRelUpdates: CatalogsJourneysRelUpdates;
  CatalogsMaxFields: CatalogsMaxFields;
  CatalogsMinFields: CatalogsMinFields;
  CatalogsMutationResponse: CatalogsMutationResponse;
  CatalogsObjRelInsertInput: CatalogsObjRelInsertInput;
  CatalogsOnConflict: CatalogsOnConflict;
  CatalogsOrderBy: CatalogsOrderBy;
  CatalogsPkColumnsInput: CatalogsPkColumnsInput;
  CatalogsSetInput: CatalogsSetInput;
  CatalogsStreamCursorInput: CatalogsStreamCursorInput;
  CatalogsStreamCursorValueInput: CatalogsStreamCursorValueInput;
  CatalogsUpdates: CatalogsUpdates;
  CheckMyTeamCourseWaitlistOutput: CheckMyTeamCourseWaitlistOutput;
  ClassCoaches: ClassCoaches;
  ClassCoachesAggregate: ClassCoachesAggregate;
  ClassCoachesAggregateBoolExp: ClassCoachesAggregateBoolExp;
  ClassCoachesAggregateBoolExpCount: ClassCoachesAggregateBoolExpCount;
  ClassCoachesAggregateFields: ClassCoachesAggregateFields;
  ClassCoachesAggregateOrderBy: ClassCoachesAggregateOrderBy;
  ClassCoachesArrRelInsertInput: ClassCoachesArrRelInsertInput;
  ClassCoachesBoolExp: ClassCoachesBoolExp;
  ClassCoachesInsertInput: ClassCoachesInsertInput;
  ClassCoachesMaxFields: ClassCoachesMaxFields;
  ClassCoachesMaxOrderBy: ClassCoachesMaxOrderBy;
  ClassCoachesMinFields: ClassCoachesMinFields;
  ClassCoachesMinOrderBy: ClassCoachesMinOrderBy;
  ClassCoachesMutationResponse: ClassCoachesMutationResponse;
  ClassCoachesOnConflict: ClassCoachesOnConflict;
  ClassCoachesOrderBy: ClassCoachesOrderBy;
  ClassCoachesPkColumnsInput: ClassCoachesPkColumnsInput;
  ClassCoachesSetInput: ClassCoachesSetInput;
  ClassCoachesStreamCursorInput: ClassCoachesStreamCursorInput;
  ClassCoachesStreamCursorValueInput: ClassCoachesStreamCursorValueInput;
  ClassCoachesUpdates: ClassCoachesUpdates;
  ClassEventActivity: ClassEventActivity;
  ClassEventActivityAggregate: ClassEventActivityAggregate;
  ClassEventActivityAggregateBoolExp: ClassEventActivityAggregateBoolExp;
  ClassEventActivityAggregateFields: ClassEventActivityAggregateFields;
  ClassEventActivityAggregateOrderBy: ClassEventActivityAggregateOrderBy;
  ClassEventActivityAppendInput: ClassEventActivityAppendInput;
  ClassEventActivityArrRelInsertInput: ClassEventActivityArrRelInsertInput;
  ClassEventActivityBoolExp: ClassEventActivityBoolExp;
  ClassEventActivityDeleteAtPathInput: ClassEventActivityDeleteAtPathInput;
  ClassEventActivityDeleteElemInput: ClassEventActivityDeleteElemInput;
  ClassEventActivityDeleteKeyInput: ClassEventActivityDeleteKeyInput;
  ClassEventActivityInsertInput: ClassEventActivityInsertInput;
  ClassEventActivityMaxFields: ClassEventActivityMaxFields;
  ClassEventActivityMaxOrderBy: ClassEventActivityMaxOrderBy;
  ClassEventActivityMinFields: ClassEventActivityMinFields;
  ClassEventActivityMinOrderBy: ClassEventActivityMinOrderBy;
  ClassEventActivityMutationResponse: ClassEventActivityMutationResponse;
  ClassEventActivityOnConflict: ClassEventActivityOnConflict;
  ClassEventActivityOrderBy: ClassEventActivityOrderBy;
  ClassEventActivityPkColumnsInput: ClassEventActivityPkColumnsInput;
  ClassEventActivityPrependInput: ClassEventActivityPrependInput;
  ClassEventActivitySetInput: ClassEventActivitySetInput;
  ClassEventActivityStreamCursorInput: ClassEventActivityStreamCursorInput;
  ClassEventActivityStreamCursorValueInput: ClassEventActivityStreamCursorValueInput;
  ClassEventActivityUpdates: ClassEventActivityUpdates;
  ClassEventAnonRegistrations: ClassEventAnonRegistrations;
  ClassEventAnonRegistrationsAggregate: ClassEventAnonRegistrationsAggregate;
  ClassEventAnonRegistrationsAggregateFields: ClassEventAnonRegistrationsAggregateFields;
  ClassEventAnonRegistrationsBoolExp: ClassEventAnonRegistrationsBoolExp;
  ClassEventAnonRegistrationsInsertInput: ClassEventAnonRegistrationsInsertInput;
  ClassEventAnonRegistrationsMaxFields: ClassEventAnonRegistrationsMaxFields;
  ClassEventAnonRegistrationsMinFields: ClassEventAnonRegistrationsMinFields;
  ClassEventAnonRegistrationsMutationResponse: ClassEventAnonRegistrationsMutationResponse;
  ClassEventAnonRegistrationsOnConflict: ClassEventAnonRegistrationsOnConflict;
  ClassEventAnonRegistrationsOrderBy: ClassEventAnonRegistrationsOrderBy;
  ClassEventAnonRegistrationsPkColumnsInput: ClassEventAnonRegistrationsPkColumnsInput;
  ClassEventAnonRegistrationsSetInput: ClassEventAnonRegistrationsSetInput;
  ClassEventAnonRegistrationsStreamCursorInput: ClassEventAnonRegistrationsStreamCursorInput;
  ClassEventAnonRegistrationsStreamCursorValueInput: ClassEventAnonRegistrationsStreamCursorValueInput;
  ClassEventAnonRegistrationsUpdates: ClassEventAnonRegistrationsUpdates;
  ClassEventInvitations: ClassEventInvitations;
  ClassEventInvitationsAggregate: ClassEventInvitationsAggregate;
  ClassEventInvitationsAggregateFields: ClassEventInvitationsAggregateFields;
  ClassEventInvitationsAvgFields: ClassEventInvitationsAvgFields;
  ClassEventInvitationsBoolExp: ClassEventInvitationsBoolExp;
  ClassEventInvitationsIncInput: ClassEventInvitationsIncInput;
  ClassEventInvitationsInsertInput: ClassEventInvitationsInsertInput;
  ClassEventInvitationsMaxFields: ClassEventInvitationsMaxFields;
  ClassEventInvitationsMinFields: ClassEventInvitationsMinFields;
  ClassEventInvitationsMutationResponse: ClassEventInvitationsMutationResponse;
  ClassEventInvitationsOnConflict: ClassEventInvitationsOnConflict;
  ClassEventInvitationsOrderBy: ClassEventInvitationsOrderBy;
  ClassEventInvitationsPkColumnsInput: ClassEventInvitationsPkColumnsInput;
  ClassEventInvitationsSetInput: ClassEventInvitationsSetInput;
  ClassEventInvitationsStddevFields: ClassEventInvitationsStddevFields;
  ClassEventInvitationsStddevPopFields: ClassEventInvitationsStddevPopFields;
  ClassEventInvitationsStddevSampFields: ClassEventInvitationsStddevSampFields;
  ClassEventInvitationsStreamCursorInput: ClassEventInvitationsStreamCursorInput;
  ClassEventInvitationsStreamCursorValueInput: ClassEventInvitationsStreamCursorValueInput;
  ClassEventInvitationsSumFields: ClassEventInvitationsSumFields;
  ClassEventInvitationsUpdates: ClassEventInvitationsUpdates;
  ClassEventInvitationsVarPopFields: ClassEventInvitationsVarPopFields;
  ClassEventInvitationsVarSampFields: ClassEventInvitationsVarSampFields;
  ClassEventInvitationsVarianceFields: ClassEventInvitationsVarianceFields;
  ClassEventMeetingParticipants: ClassEventMeetingParticipants;
  ClassEventMeetingParticipantsAggregate: ClassEventMeetingParticipantsAggregate;
  ClassEventMeetingParticipantsAggregateBoolExp: ClassEventMeetingParticipantsAggregateBoolExp;
  ClassEventMeetingParticipantsAggregateFields: ClassEventMeetingParticipantsAggregateFields;
  ClassEventMeetingParticipantsAggregateOrderBy: ClassEventMeetingParticipantsAggregateOrderBy;
  ClassEventMeetingParticipantsArrRelInsertInput: ClassEventMeetingParticipantsArrRelInsertInput;
  ClassEventMeetingParticipantsAvgFields: ClassEventMeetingParticipantsAvgFields;
  ClassEventMeetingParticipantsAvgOrderBy: ClassEventMeetingParticipantsAvgOrderBy;
  ClassEventMeetingParticipantsBoolExp: ClassEventMeetingParticipantsBoolExp;
  ClassEventMeetingParticipantsIncInput: ClassEventMeetingParticipantsIncInput;
  ClassEventMeetingParticipantsInsertInput: ClassEventMeetingParticipantsInsertInput;
  ClassEventMeetingParticipantsMaxFields: ClassEventMeetingParticipantsMaxFields;
  ClassEventMeetingParticipantsMaxOrderBy: ClassEventMeetingParticipantsMaxOrderBy;
  ClassEventMeetingParticipantsMinFields: ClassEventMeetingParticipantsMinFields;
  ClassEventMeetingParticipantsMinOrderBy: ClassEventMeetingParticipantsMinOrderBy;
  ClassEventMeetingParticipantsMutationResponse: ClassEventMeetingParticipantsMutationResponse;
  ClassEventMeetingParticipantsObjRelInsertInput: ClassEventMeetingParticipantsObjRelInsertInput;
  ClassEventMeetingParticipantsOnConflict: ClassEventMeetingParticipantsOnConflict;
  ClassEventMeetingParticipantsOrderBy: ClassEventMeetingParticipantsOrderBy;
  ClassEventMeetingParticipantsPkColumnsInput: ClassEventMeetingParticipantsPkColumnsInput;
  ClassEventMeetingParticipantsSetInput: ClassEventMeetingParticipantsSetInput;
  ClassEventMeetingParticipantsStddevFields: ClassEventMeetingParticipantsStddevFields;
  ClassEventMeetingParticipantsStddevOrderBy: ClassEventMeetingParticipantsStddevOrderBy;
  ClassEventMeetingParticipantsStddevPopFields: ClassEventMeetingParticipantsStddevPopFields;
  ClassEventMeetingParticipantsStddevPopOrderBy: ClassEventMeetingParticipantsStddevPopOrderBy;
  ClassEventMeetingParticipantsStddevSampFields: ClassEventMeetingParticipantsStddevSampFields;
  ClassEventMeetingParticipantsStddevSampOrderBy: ClassEventMeetingParticipantsStddevSampOrderBy;
  ClassEventMeetingParticipantsStreamCursorInput: ClassEventMeetingParticipantsStreamCursorInput;
  ClassEventMeetingParticipantsStreamCursorValueInput: ClassEventMeetingParticipantsStreamCursorValueInput;
  ClassEventMeetingParticipantsSumFields: ClassEventMeetingParticipantsSumFields;
  ClassEventMeetingParticipantsSumOrderBy: ClassEventMeetingParticipantsSumOrderBy;
  ClassEventMeetingParticipantsUpdates: ClassEventMeetingParticipantsUpdates;
  ClassEventMeetingParticipantsVarPopFields: ClassEventMeetingParticipantsVarPopFields;
  ClassEventMeetingParticipantsVarPopOrderBy: ClassEventMeetingParticipantsVarPopOrderBy;
  ClassEventMeetingParticipantsVarSampFields: ClassEventMeetingParticipantsVarSampFields;
  ClassEventMeetingParticipantsVarSampOrderBy: ClassEventMeetingParticipantsVarSampOrderBy;
  ClassEventMeetingParticipantsVarianceFields: ClassEventMeetingParticipantsVarianceFields;
  ClassEventMeetingParticipantsVarianceOrderBy: ClassEventMeetingParticipantsVarianceOrderBy;
  ClassEventMeetingSegments: ClassEventMeetingSegments;
  ClassEventMeetingSegmentsAggregate: ClassEventMeetingSegmentsAggregate;
  ClassEventMeetingSegmentsAggregateBoolExp: ClassEventMeetingSegmentsAggregateBoolExp;
  ClassEventMeetingSegmentsAggregateFields: ClassEventMeetingSegmentsAggregateFields;
  ClassEventMeetingSegmentsAggregateOrderBy: ClassEventMeetingSegmentsAggregateOrderBy;
  ClassEventMeetingSegmentsAppendInput: ClassEventMeetingSegmentsAppendInput;
  ClassEventMeetingSegmentsArrRelInsertInput: ClassEventMeetingSegmentsArrRelInsertInput;
  ClassEventMeetingSegmentsAvgFields: ClassEventMeetingSegmentsAvgFields;
  ClassEventMeetingSegmentsAvgOrderBy: ClassEventMeetingSegmentsAvgOrderBy;
  ClassEventMeetingSegmentsBoolExp: ClassEventMeetingSegmentsBoolExp;
  ClassEventMeetingSegmentsDeleteAtPathInput: ClassEventMeetingSegmentsDeleteAtPathInput;
  ClassEventMeetingSegmentsDeleteElemInput: ClassEventMeetingSegmentsDeleteElemInput;
  ClassEventMeetingSegmentsDeleteKeyInput: ClassEventMeetingSegmentsDeleteKeyInput;
  ClassEventMeetingSegmentsIncInput: ClassEventMeetingSegmentsIncInput;
  ClassEventMeetingSegmentsInsertInput: ClassEventMeetingSegmentsInsertInput;
  ClassEventMeetingSegmentsMaxFields: ClassEventMeetingSegmentsMaxFields;
  ClassEventMeetingSegmentsMaxOrderBy: ClassEventMeetingSegmentsMaxOrderBy;
  ClassEventMeetingSegmentsMinFields: ClassEventMeetingSegmentsMinFields;
  ClassEventMeetingSegmentsMinOrderBy: ClassEventMeetingSegmentsMinOrderBy;
  ClassEventMeetingSegmentsMutationResponse: ClassEventMeetingSegmentsMutationResponse;
  ClassEventMeetingSegmentsObjRelInsertInput: ClassEventMeetingSegmentsObjRelInsertInput;
  ClassEventMeetingSegmentsOnConflict: ClassEventMeetingSegmentsOnConflict;
  ClassEventMeetingSegmentsOrderBy: ClassEventMeetingSegmentsOrderBy;
  ClassEventMeetingSegmentsPkColumnsInput: ClassEventMeetingSegmentsPkColumnsInput;
  ClassEventMeetingSegmentsPrependInput: ClassEventMeetingSegmentsPrependInput;
  ClassEventMeetingSegmentsSetInput: ClassEventMeetingSegmentsSetInput;
  ClassEventMeetingSegmentsStddevFields: ClassEventMeetingSegmentsStddevFields;
  ClassEventMeetingSegmentsStddevOrderBy: ClassEventMeetingSegmentsStddevOrderBy;
  ClassEventMeetingSegmentsStddevPopFields: ClassEventMeetingSegmentsStddevPopFields;
  ClassEventMeetingSegmentsStddevPopOrderBy: ClassEventMeetingSegmentsStddevPopOrderBy;
  ClassEventMeetingSegmentsStddevSampFields: ClassEventMeetingSegmentsStddevSampFields;
  ClassEventMeetingSegmentsStddevSampOrderBy: ClassEventMeetingSegmentsStddevSampOrderBy;
  ClassEventMeetingSegmentsStreamCursorInput: ClassEventMeetingSegmentsStreamCursorInput;
  ClassEventMeetingSegmentsStreamCursorValueInput: ClassEventMeetingSegmentsStreamCursorValueInput;
  ClassEventMeetingSegmentsSumFields: ClassEventMeetingSegmentsSumFields;
  ClassEventMeetingSegmentsSumOrderBy: ClassEventMeetingSegmentsSumOrderBy;
  ClassEventMeetingSegmentsUpdates: ClassEventMeetingSegmentsUpdates;
  ClassEventMeetingSegmentsVarPopFields: ClassEventMeetingSegmentsVarPopFields;
  ClassEventMeetingSegmentsVarPopOrderBy: ClassEventMeetingSegmentsVarPopOrderBy;
  ClassEventMeetingSegmentsVarSampFields: ClassEventMeetingSegmentsVarSampFields;
  ClassEventMeetingSegmentsVarSampOrderBy: ClassEventMeetingSegmentsVarSampOrderBy;
  ClassEventMeetingSegmentsVarianceFields: ClassEventMeetingSegmentsVarianceFields;
  ClassEventMeetingSegmentsVarianceOrderBy: ClassEventMeetingSegmentsVarianceOrderBy;
  ClassEventMeetings: ClassEventMeetings;
  ClassEventMeetingsAggregate: ClassEventMeetingsAggregate;
  ClassEventMeetingsAggregateBoolExp: ClassEventMeetingsAggregateBoolExp;
  ClassEventMeetingsAggregateFields: ClassEventMeetingsAggregateFields;
  ClassEventMeetingsAggregateOrderBy: ClassEventMeetingsAggregateOrderBy;
  ClassEventMeetingsArrRelInsertInput: ClassEventMeetingsArrRelInsertInput;
  ClassEventMeetingsAvgFields: ClassEventMeetingsAvgFields;
  ClassEventMeetingsAvgOrderBy: ClassEventMeetingsAvgOrderBy;
  ClassEventMeetingsBoolExp: ClassEventMeetingsBoolExp;
  ClassEventMeetingsIncInput: ClassEventMeetingsIncInput;
  ClassEventMeetingsInsertInput: ClassEventMeetingsInsertInput;
  ClassEventMeetingsMaxFields: ClassEventMeetingsMaxFields;
  ClassEventMeetingsMaxOrderBy: ClassEventMeetingsMaxOrderBy;
  ClassEventMeetingsMinFields: ClassEventMeetingsMinFields;
  ClassEventMeetingsMinOrderBy: ClassEventMeetingsMinOrderBy;
  ClassEventMeetingsMutationResponse: ClassEventMeetingsMutationResponse;
  ClassEventMeetingsObjRelInsertInput: ClassEventMeetingsObjRelInsertInput;
  ClassEventMeetingsOnConflict: ClassEventMeetingsOnConflict;
  ClassEventMeetingsOrderBy: ClassEventMeetingsOrderBy;
  ClassEventMeetingsPkColumnsInput: ClassEventMeetingsPkColumnsInput;
  ClassEventMeetingsSetInput: ClassEventMeetingsSetInput;
  ClassEventMeetingsStddevFields: ClassEventMeetingsStddevFields;
  ClassEventMeetingsStddevOrderBy: ClassEventMeetingsStddevOrderBy;
  ClassEventMeetingsStddevPopFields: ClassEventMeetingsStddevPopFields;
  ClassEventMeetingsStddevPopOrderBy: ClassEventMeetingsStddevPopOrderBy;
  ClassEventMeetingsStddevSampFields: ClassEventMeetingsStddevSampFields;
  ClassEventMeetingsStddevSampOrderBy: ClassEventMeetingsStddevSampOrderBy;
  ClassEventMeetingsStreamCursorInput: ClassEventMeetingsStreamCursorInput;
  ClassEventMeetingsStreamCursorValueInput: ClassEventMeetingsStreamCursorValueInput;
  ClassEventMeetingsSumFields: ClassEventMeetingsSumFields;
  ClassEventMeetingsSumOrderBy: ClassEventMeetingsSumOrderBy;
  ClassEventMeetingsUpdates: ClassEventMeetingsUpdates;
  ClassEventMeetingsVarPopFields: ClassEventMeetingsVarPopFields;
  ClassEventMeetingsVarPopOrderBy: ClassEventMeetingsVarPopOrderBy;
  ClassEventMeetingsVarSampFields: ClassEventMeetingsVarSampFields;
  ClassEventMeetingsVarSampOrderBy: ClassEventMeetingsVarSampOrderBy;
  ClassEventMeetingsVarianceFields: ClassEventMeetingsVarianceFields;
  ClassEventMeetingsVarianceOrderBy: ClassEventMeetingsVarianceOrderBy;
  ClassEventRegistrations: ClassEventRegistrations;
  ClassEventRegistrationsAggregate: ClassEventRegistrationsAggregate;
  ClassEventRegistrationsAggregateFields: ClassEventRegistrationsAggregateFields;
  ClassEventRegistrationsBoolExp: ClassEventRegistrationsBoolExp;
  ClassEventRegistrationsInsertInput: ClassEventRegistrationsInsertInput;
  ClassEventRegistrationsMaxFields: ClassEventRegistrationsMaxFields;
  ClassEventRegistrationsMinFields: ClassEventRegistrationsMinFields;
  ClassEventRegistrationsMutationResponse: ClassEventRegistrationsMutationResponse;
  ClassEventRegistrationsOnConflict: ClassEventRegistrationsOnConflict;
  ClassEventRegistrationsOrderBy: ClassEventRegistrationsOrderBy;
  ClassEventRegistrationsPkColumnsInput: ClassEventRegistrationsPkColumnsInput;
  ClassEventRegistrationsSetInput: ClassEventRegistrationsSetInput;
  ClassEventRegistrationsStreamCursorInput: ClassEventRegistrationsStreamCursorInput;
  ClassEventRegistrationsStreamCursorValueInput: ClassEventRegistrationsStreamCursorValueInput;
  ClassEventRegistrationsUpdates: ClassEventRegistrationsUpdates;
  ClassEvents: ClassEvents;
  ClassEventsAggregate: ClassEventsAggregate;
  ClassEventsAggregateBoolExp: ClassEventsAggregateBoolExp;
  ClassEventsAggregateFields: ClassEventsAggregateFields;
  ClassEventsAggregateOrderBy: ClassEventsAggregateOrderBy;
  ClassEventsArrRelInsertInput: ClassEventsArrRelInsertInput;
  ClassEventsAvgFields: ClassEventsAvgFields;
  ClassEventsAvgOrderBy: ClassEventsAvgOrderBy;
  ClassEventsBoolExp: ClassEventsBoolExp;
  ClassEventsIncInput: ClassEventsIncInput;
  ClassEventsInsertInput: ClassEventsInsertInput;
  ClassEventsMaxFields: ClassEventsMaxFields;
  ClassEventsMaxOrderBy: ClassEventsMaxOrderBy;
  ClassEventsMinFields: ClassEventsMinFields;
  ClassEventsMinOrderBy: ClassEventsMinOrderBy;
  ClassEventsMutationResponse: ClassEventsMutationResponse;
  ClassEventsObjRelInsertInput: ClassEventsObjRelInsertInput;
  ClassEventsOnConflict: ClassEventsOnConflict;
  ClassEventsOrderBy: ClassEventsOrderBy;
  ClassEventsPkColumnsInput: ClassEventsPkColumnsInput;
  ClassEventsSetInput: ClassEventsSetInput;
  ClassEventsStddevFields: ClassEventsStddevFields;
  ClassEventsStddevOrderBy: ClassEventsStddevOrderBy;
  ClassEventsStddevPopFields: ClassEventsStddevPopFields;
  ClassEventsStddevPopOrderBy: ClassEventsStddevPopOrderBy;
  ClassEventsStddevSampFields: ClassEventsStddevSampFields;
  ClassEventsStddevSampOrderBy: ClassEventsStddevSampOrderBy;
  ClassEventsStreamCursorInput: ClassEventsStreamCursorInput;
  ClassEventsStreamCursorValueInput: ClassEventsStreamCursorValueInput;
  ClassEventsSumFields: ClassEventsSumFields;
  ClassEventsSumOrderBy: ClassEventsSumOrderBy;
  ClassEventsUpdates: ClassEventsUpdates;
  ClassEventsVarPopFields: ClassEventsVarPopFields;
  ClassEventsVarPopOrderBy: ClassEventsVarPopOrderBy;
  ClassEventsVarSampFields: ClassEventsVarSampFields;
  ClassEventsVarSampOrderBy: ClassEventsVarSampOrderBy;
  ClassEventsVarianceFields: ClassEventsVarianceFields;
  ClassEventsVarianceOrderBy: ClassEventsVarianceOrderBy;
  ClassPlaybackActivity: ClassPlaybackActivity;
  ClassPlaybackActivityAggregate: ClassPlaybackActivityAggregate;
  ClassPlaybackActivityAggregateFields: ClassPlaybackActivityAggregateFields;
  ClassPlaybackActivityAppendInput: ClassPlaybackActivityAppendInput;
  ClassPlaybackActivityBoolExp: ClassPlaybackActivityBoolExp;
  ClassPlaybackActivityDeleteAtPathInput: ClassPlaybackActivityDeleteAtPathInput;
  ClassPlaybackActivityDeleteElemInput: ClassPlaybackActivityDeleteElemInput;
  ClassPlaybackActivityDeleteKeyInput: ClassPlaybackActivityDeleteKeyInput;
  ClassPlaybackActivityInsertInput: ClassPlaybackActivityInsertInput;
  ClassPlaybackActivityMaxFields: ClassPlaybackActivityMaxFields;
  ClassPlaybackActivityMinFields: ClassPlaybackActivityMinFields;
  ClassPlaybackActivityMutationResponse: ClassPlaybackActivityMutationResponse;
  ClassPlaybackActivityOnConflict: ClassPlaybackActivityOnConflict;
  ClassPlaybackActivityOrderBy: ClassPlaybackActivityOrderBy;
  ClassPlaybackActivityPkColumnsInput: ClassPlaybackActivityPkColumnsInput;
  ClassPlaybackActivityPrependInput: ClassPlaybackActivityPrependInput;
  ClassPlaybackActivitySetInput: ClassPlaybackActivitySetInput;
  ClassPlaybackActivityStreamCursorInput: ClassPlaybackActivityStreamCursorInput;
  ClassPlaybackActivityStreamCursorValueInput: ClassPlaybackActivityStreamCursorValueInput;
  ClassPlaybackActivityType: ClassPlaybackActivityType;
  ClassPlaybackActivityTypeAggregate: ClassPlaybackActivityTypeAggregate;
  ClassPlaybackActivityTypeAggregateFields: ClassPlaybackActivityTypeAggregateFields;
  ClassPlaybackActivityTypeBoolExp: ClassPlaybackActivityTypeBoolExp;
  ClassPlaybackActivityTypeEnumComparisonExp: ClassPlaybackActivityTypeEnumComparisonExp;
  ClassPlaybackActivityTypeInsertInput: ClassPlaybackActivityTypeInsertInput;
  ClassPlaybackActivityTypeMaxFields: ClassPlaybackActivityTypeMaxFields;
  ClassPlaybackActivityTypeMinFields: ClassPlaybackActivityTypeMinFields;
  ClassPlaybackActivityTypeMutationResponse: ClassPlaybackActivityTypeMutationResponse;
  ClassPlaybackActivityTypeOnConflict: ClassPlaybackActivityTypeOnConflict;
  ClassPlaybackActivityTypeOrderBy: ClassPlaybackActivityTypeOrderBy;
  ClassPlaybackActivityTypePkColumnsInput: ClassPlaybackActivityTypePkColumnsInput;
  ClassPlaybackActivityTypeSetInput: ClassPlaybackActivityTypeSetInput;
  ClassPlaybackActivityTypeStreamCursorInput: ClassPlaybackActivityTypeStreamCursorInput;
  ClassPlaybackActivityTypeStreamCursorValueInput: ClassPlaybackActivityTypeStreamCursorValueInput;
  ClassPlaybackActivityTypeUpdates: ClassPlaybackActivityTypeUpdates;
  ClassPlaybackActivityUpdates: ClassPlaybackActivityUpdates;
  ClassResources: ClassResources;
  ClassResourcesAggregate: ClassResourcesAggregate;
  ClassResourcesAggregateBoolExp: ClassResourcesAggregateBoolExp;
  ClassResourcesAggregateBoolExpCount: ClassResourcesAggregateBoolExpCount;
  ClassResourcesAggregateFields: ClassResourcesAggregateFields;
  ClassResourcesAggregateOrderBy: ClassResourcesAggregateOrderBy;
  ClassResourcesArrRelInsertInput: ClassResourcesArrRelInsertInput;
  ClassResourcesBoolExp: ClassResourcesBoolExp;
  ClassResourcesInsertInput: ClassResourcesInsertInput;
  ClassResourcesMaxFields: ClassResourcesMaxFields;
  ClassResourcesMaxOrderBy: ClassResourcesMaxOrderBy;
  ClassResourcesMinFields: ClassResourcesMinFields;
  ClassResourcesMinOrderBy: ClassResourcesMinOrderBy;
  ClassResourcesMutationResponse: ClassResourcesMutationResponse;
  ClassResourcesObjRelInsertInput: ClassResourcesObjRelInsertInput;
  ClassResourcesOnConflict: ClassResourcesOnConflict;
  ClassResourcesOrderBy: ClassResourcesOrderBy;
  ClassResourcesPkColumnsInput: ClassResourcesPkColumnsInput;
  ClassResourcesSetInput: ClassResourcesSetInput;
  ClassResourcesStreamCursorInput: ClassResourcesStreamCursorInput;
  ClassResourcesStreamCursorValueInput: ClassResourcesStreamCursorValueInput;
  ClassResourcesUpdates: ClassResourcesUpdates;
  Classes: Classes;
  ClassesAggregate: ClassesAggregate;
  ClassesAggregateBoolExp: ClassesAggregateBoolExp;
  ClassesAggregateFields: ClassesAggregateFields;
  ClassesAggregateOrderBy: ClassesAggregateOrderBy;
  ClassesArrRelInsertInput: ClassesArrRelInsertInput;
  ClassesAvgFields: ClassesAvgFields;
  ClassesAvgOrderBy: ClassesAvgOrderBy;
  ClassesBoolExp: ClassesBoolExp;
  ClassesIncInput: ClassesIncInput;
  ClassesInsertInput: ClassesInsertInput;
  ClassesMaxFields: ClassesMaxFields;
  ClassesMaxOrderBy: ClassesMaxOrderBy;
  ClassesMinFields: ClassesMinFields;
  ClassesMinOrderBy: ClassesMinOrderBy;
  ClassesMutationResponse: ClassesMutationResponse;
  ClassesObjRelInsertInput: ClassesObjRelInsertInput;
  ClassesOnConflict: ClassesOnConflict;
  ClassesOrderBy: ClassesOrderBy;
  ClassesPkColumnsInput: ClassesPkColumnsInput;
  ClassesSetInput: ClassesSetInput;
  ClassesStddevFields: ClassesStddevFields;
  ClassesStddevOrderBy: ClassesStddevOrderBy;
  ClassesStddevPopFields: ClassesStddevPopFields;
  ClassesStddevPopOrderBy: ClassesStddevPopOrderBy;
  ClassesStddevSampFields: ClassesStddevSampFields;
  ClassesStddevSampOrderBy: ClassesStddevSampOrderBy;
  ClassesStreamCursorInput: ClassesStreamCursorInput;
  ClassesStreamCursorValueInput: ClassesStreamCursorValueInput;
  ClassesSumFields: ClassesSumFields;
  ClassesSumOrderBy: ClassesSumOrderBy;
  ClassesTagsRel: ClassesTagsRel;
  ClassesTagsRelAggregate: ClassesTagsRelAggregate;
  ClassesTagsRelAggregateBoolExp: ClassesTagsRelAggregateBoolExp;
  ClassesTagsRelAggregateFields: ClassesTagsRelAggregateFields;
  ClassesTagsRelAggregateOrderBy: ClassesTagsRelAggregateOrderBy;
  ClassesTagsRelArrRelInsertInput: ClassesTagsRelArrRelInsertInput;
  ClassesTagsRelBoolExp: ClassesTagsRelBoolExp;
  ClassesTagsRelInsertInput: ClassesTagsRelInsertInput;
  ClassesTagsRelMaxFields: ClassesTagsRelMaxFields;
  ClassesTagsRelMaxOrderBy: ClassesTagsRelMaxOrderBy;
  ClassesTagsRelMinFields: ClassesTagsRelMinFields;
  ClassesTagsRelMinOrderBy: ClassesTagsRelMinOrderBy;
  ClassesTagsRelMutationResponse: ClassesTagsRelMutationResponse;
  ClassesTagsRelOnConflict: ClassesTagsRelOnConflict;
  ClassesTagsRelOrderBy: ClassesTagsRelOrderBy;
  ClassesTagsRelPkColumnsInput: ClassesTagsRelPkColumnsInput;
  ClassesTagsRelSetInput: ClassesTagsRelSetInput;
  ClassesTagsRelStreamCursorInput: ClassesTagsRelStreamCursorInput;
  ClassesTagsRelStreamCursorValueInput: ClassesTagsRelStreamCursorValueInput;
  ClassesTagsRelUpdates: ClassesTagsRelUpdates;
  ClassesUpdates: ClassesUpdates;
  ClassesVarPopFields: ClassesVarPopFields;
  ClassesVarPopOrderBy: ClassesVarPopOrderBy;
  ClassesVarSampFields: ClassesVarSampFields;
  ClassesVarSampOrderBy: ClassesVarSampOrderBy;
  ClassesVarianceFields: ClassesVarianceFields;
  ClassesVarianceOrderBy: ClassesVarianceOrderBy;
  Coaches: Coaches;
  CoachesAggregate: CoachesAggregate;
  CoachesAggregateBoolExp: CoachesAggregateBoolExp;
  CoachesAggregateFields: CoachesAggregateFields;
  CoachesAggregateOrderBy: CoachesAggregateOrderBy;
  CoachesArrRelInsertInput: CoachesArrRelInsertInput;
  CoachesBoolExp: CoachesBoolExp;
  CoachesInsertInput: CoachesInsertInput;
  CoachesMaxFields: CoachesMaxFields;
  CoachesMaxOrderBy: CoachesMaxOrderBy;
  CoachesMinFields: CoachesMinFields;
  CoachesMinOrderBy: CoachesMinOrderBy;
  CoachesMutationResponse: CoachesMutationResponse;
  CoachesObjRelInsertInput: CoachesObjRelInsertInput;
  CoachesOnConflict: CoachesOnConflict;
  CoachesOrderBy: CoachesOrderBy;
  CoachesPkColumnsInput: CoachesPkColumnsInput;
  CoachesSetInput: CoachesSetInput;
  CoachesStreamCursorInput: CoachesStreamCursorInput;
  CoachesStreamCursorValueInput: CoachesStreamCursorValueInput;
  CoachesUpdates: CoachesUpdates;
  CohortCoaches: CohortCoaches;
  CohortCoachesAggregate: CohortCoachesAggregate;
  CohortCoachesAggregateBoolExp: CohortCoachesAggregateBoolExp;
  CohortCoachesAggregateBoolExpBool_and: CohortCoachesAggregateBoolExpBool_And;
  CohortCoachesAggregateBoolExpBool_or: CohortCoachesAggregateBoolExpBool_Or;
  CohortCoachesAggregateBoolExpCount: CohortCoachesAggregateBoolExpCount;
  CohortCoachesAggregateFields: CohortCoachesAggregateFields;
  CohortCoachesAggregateOrderBy: CohortCoachesAggregateOrderBy;
  CohortCoachesArrRelInsertInput: CohortCoachesArrRelInsertInput;
  CohortCoachesBoolExp: CohortCoachesBoolExp;
  CohortCoachesInsertInput: CohortCoachesInsertInput;
  CohortCoachesMaxFields: CohortCoachesMaxFields;
  CohortCoachesMaxOrderBy: CohortCoachesMaxOrderBy;
  CohortCoachesMinFields: CohortCoachesMinFields;
  CohortCoachesMinOrderBy: CohortCoachesMinOrderBy;
  CohortCoachesMutationResponse: CohortCoachesMutationResponse;
  CohortCoachesOnConflict: CohortCoachesOnConflict;
  CohortCoachesOrderBy: CohortCoachesOrderBy;
  CohortCoachesPkColumnsInput: CohortCoachesPkColumnsInput;
  CohortCoachesSetInput: CohortCoachesSetInput;
  CohortCoachesStreamCursorInput: CohortCoachesStreamCursorInput;
  CohortCoachesStreamCursorValueInput: CohortCoachesStreamCursorValueInput;
  CohortCoachesUpdates: CohortCoachesUpdates;
  CohortProgress: CohortProgress;
  CohortProgressAggregate: CohortProgressAggregate;
  CohortProgressAggregateFields: CohortProgressAggregateFields;
  CohortProgressAvgFields: CohortProgressAvgFields;
  CohortProgressBoolExp: CohortProgressBoolExp;
  CohortProgressMaxFields: CohortProgressMaxFields;
  CohortProgressMinFields: CohortProgressMinFields;
  CohortProgressOrderBy: CohortProgressOrderBy;
  CohortProgressStddevFields: CohortProgressStddevFields;
  CohortProgressStddevPopFields: CohortProgressStddevPopFields;
  CohortProgressStddevSampFields: CohortProgressStddevSampFields;
  CohortProgressStreamCursorInput: CohortProgressStreamCursorInput;
  CohortProgressStreamCursorValueInput: CohortProgressStreamCursorValueInput;
  CohortProgressSumFields: CohortProgressSumFields;
  CohortProgressVarPopFields: CohortProgressVarPopFields;
  CohortProgressVarSampFields: CohortProgressVarSampFields;
  CohortProgressVarianceFields: CohortProgressVarianceFields;
  CohortType: CohortType;
  CohortTypeAggregate: CohortTypeAggregate;
  CohortTypeAggregateFields: CohortTypeAggregateFields;
  CohortTypeBoolExp: CohortTypeBoolExp;
  CohortTypeEnumComparisonExp: CohortTypeEnumComparisonExp;
  CohortTypeInsertInput: CohortTypeInsertInput;
  CohortTypeMaxFields: CohortTypeMaxFields;
  CohortTypeMinFields: CohortTypeMinFields;
  CohortTypeMutationResponse: CohortTypeMutationResponse;
  CohortTypeOnConflict: CohortTypeOnConflict;
  CohortTypeOrderBy: CohortTypeOrderBy;
  CohortTypePkColumnsInput: CohortTypePkColumnsInput;
  CohortTypeSetInput: CohortTypeSetInput;
  CohortTypeStreamCursorInput: CohortTypeStreamCursorInput;
  CohortTypeStreamCursorValueInput: CohortTypeStreamCursorValueInput;
  CohortTypeUpdates: CohortTypeUpdates;
  CohortVisibilityEnum: CohortVisibilityEnum;
  CohortVisibilityEnumAggregate: CohortVisibilityEnumAggregate;
  CohortVisibilityEnumAggregateFields: CohortVisibilityEnumAggregateFields;
  CohortVisibilityEnumBoolExp: CohortVisibilityEnumBoolExp;
  CohortVisibilityEnumEnumComparisonExp: CohortVisibilityEnumEnumComparisonExp;
  CohortVisibilityEnumInsertInput: CohortVisibilityEnumInsertInput;
  CohortVisibilityEnumMaxFields: CohortVisibilityEnumMaxFields;
  CohortVisibilityEnumMinFields: CohortVisibilityEnumMinFields;
  CohortVisibilityEnumMutationResponse: CohortVisibilityEnumMutationResponse;
  CohortVisibilityEnumOnConflict: CohortVisibilityEnumOnConflict;
  CohortVisibilityEnumOrderBy: CohortVisibilityEnumOrderBy;
  CohortVisibilityEnumPkColumnsInput: CohortVisibilityEnumPkColumnsInput;
  CohortVisibilityEnumSetInput: CohortVisibilityEnumSetInput;
  CohortVisibilityEnumStreamCursorInput: CohortVisibilityEnumStreamCursorInput;
  CohortVisibilityEnumStreamCursorValueInput: CohortVisibilityEnumStreamCursorValueInput;
  CohortVisibilityEnumUpdates: CohortVisibilityEnumUpdates;
  Cohorts: Cohorts;
  CohortsAggregate: CohortsAggregate;
  CohortsAggregateBoolExp: CohortsAggregateBoolExp;
  CohortsAggregateFields: CohortsAggregateFields;
  CohortsAggregateOrderBy: CohortsAggregateOrderBy;
  CohortsArrRelInsertInput: CohortsArrRelInsertInput;
  CohortsAvgFields: CohortsAvgFields;
  CohortsAvgOrderBy: CohortsAvgOrderBy;
  CohortsBoolExp: CohortsBoolExp;
  CohortsIncInput: CohortsIncInput;
  CohortsInsertInput: CohortsInsertInput;
  CohortsMaxFields: CohortsMaxFields;
  CohortsMaxOrderBy: CohortsMaxOrderBy;
  CohortsMinFields: CohortsMinFields;
  CohortsMinOrderBy: CohortsMinOrderBy;
  CohortsMutationResponse: CohortsMutationResponse;
  CohortsObjRelInsertInput: CohortsObjRelInsertInput;
  CohortsOnConflict: CohortsOnConflict;
  CohortsOrderBy: CohortsOrderBy;
  CohortsPkColumnsInput: CohortsPkColumnsInput;
  CohortsResourcesRel: CohortsResourcesRel;
  CohortsResourcesRelAggregate: CohortsResourcesRelAggregate;
  CohortsResourcesRelAggregateBoolExp: CohortsResourcesRelAggregateBoolExp;
  CohortsResourcesRelAggregateFields: CohortsResourcesRelAggregateFields;
  CohortsResourcesRelAggregateOrderBy: CohortsResourcesRelAggregateOrderBy;
  CohortsResourcesRelArrRelInsertInput: CohortsResourcesRelArrRelInsertInput;
  CohortsResourcesRelBoolExp: CohortsResourcesRelBoolExp;
  CohortsResourcesRelInsertInput: CohortsResourcesRelInsertInput;
  CohortsResourcesRelMaxFields: CohortsResourcesRelMaxFields;
  CohortsResourcesRelMaxOrderBy: CohortsResourcesRelMaxOrderBy;
  CohortsResourcesRelMinFields: CohortsResourcesRelMinFields;
  CohortsResourcesRelMinOrderBy: CohortsResourcesRelMinOrderBy;
  CohortsResourcesRelMutationResponse: CohortsResourcesRelMutationResponse;
  CohortsResourcesRelObjRelInsertInput: CohortsResourcesRelObjRelInsertInput;
  CohortsResourcesRelOnConflict: CohortsResourcesRelOnConflict;
  CohortsResourcesRelOrderBy: CohortsResourcesRelOrderBy;
  CohortsResourcesRelPkColumnsInput: CohortsResourcesRelPkColumnsInput;
  CohortsResourcesRelSetInput: CohortsResourcesRelSetInput;
  CohortsResourcesRelStreamCursorInput: CohortsResourcesRelStreamCursorInput;
  CohortsResourcesRelStreamCursorValueInput: CohortsResourcesRelStreamCursorValueInput;
  CohortsResourcesRelUpdates: CohortsResourcesRelUpdates;
  CohortsSetInput: CohortsSetInput;
  CohortsStddevFields: CohortsStddevFields;
  CohortsStddevOrderBy: CohortsStddevOrderBy;
  CohortsStddevPopFields: CohortsStddevPopFields;
  CohortsStddevPopOrderBy: CohortsStddevPopOrderBy;
  CohortsStddevSampFields: CohortsStddevSampFields;
  CohortsStddevSampOrderBy: CohortsStddevSampOrderBy;
  CohortsStreamCursorInput: CohortsStreamCursorInput;
  CohortsStreamCursorValueInput: CohortsStreamCursorValueInput;
  CohortsSumFields: CohortsSumFields;
  CohortsSumOrderBy: CohortsSumOrderBy;
  CohortsUpdates: CohortsUpdates;
  CohortsVarPopFields: CohortsVarPopFields;
  CohortsVarPopOrderBy: CohortsVarPopOrderBy;
  CohortsVarSampFields: CohortsVarSampFields;
  CohortsVarSampOrderBy: CohortsVarSampOrderBy;
  CohortsVarianceFields: CohortsVarianceFields;
  CohortsVarianceOrderBy: CohortsVarianceOrderBy;
  Configurations: Configurations;
  ConfigurationsAggregate: ConfigurationsAggregate;
  ConfigurationsAggregateFields: ConfigurationsAggregateFields;
  ConfigurationsAppendInput: ConfigurationsAppendInput;
  ConfigurationsBoolExp: ConfigurationsBoolExp;
  ConfigurationsDeleteAtPathInput: ConfigurationsDeleteAtPathInput;
  ConfigurationsDeleteElemInput: ConfigurationsDeleteElemInput;
  ConfigurationsDeleteKeyInput: ConfigurationsDeleteKeyInput;
  ConfigurationsInsertInput: ConfigurationsInsertInput;
  ConfigurationsMaxFields: ConfigurationsMaxFields;
  ConfigurationsMinFields: ConfigurationsMinFields;
  ConfigurationsMutationResponse: ConfigurationsMutationResponse;
  ConfigurationsOnConflict: ConfigurationsOnConflict;
  ConfigurationsOrderBy: ConfigurationsOrderBy;
  ConfigurationsPkColumnsInput: ConfigurationsPkColumnsInput;
  ConfigurationsPrependInput: ConfigurationsPrependInput;
  ConfigurationsSetInput: ConfigurationsSetInput;
  ConfigurationsStreamCursorInput: ConfigurationsStreamCursorInput;
  ConfigurationsStreamCursorValueInput: ConfigurationsStreamCursorValueInput;
  ConfigurationsUpdates: ConfigurationsUpdates;
  CountTeamSessionsItem: CountTeamSessionsItem;
  CountTeamSessionsOutput: CountTeamSessionsOutput;
  CourseCertifications: CourseCertifications;
  CourseCertificationsAggregate: CourseCertificationsAggregate;
  CourseCertificationsAggregateFields: CourseCertificationsAggregateFields;
  CourseCertificationsBoolExp: CourseCertificationsBoolExp;
  CourseCertificationsInsertInput: CourseCertificationsInsertInput;
  CourseCertificationsMaxFields: CourseCertificationsMaxFields;
  CourseCertificationsMinFields: CourseCertificationsMinFields;
  CourseCertificationsMutationResponse: CourseCertificationsMutationResponse;
  CourseCertificationsOnConflict: CourseCertificationsOnConflict;
  CourseCertificationsOrderBy: CourseCertificationsOrderBy;
  CourseCertificationsPkColumnsInput: CourseCertificationsPkColumnsInput;
  CourseCertificationsSetInput: CourseCertificationsSetInput;
  CourseCertificationsStreamCursorInput: CourseCertificationsStreamCursorInput;
  CourseCertificationsStreamCursorValueInput: CourseCertificationsStreamCursorValueInput;
  CourseCertificationsUpdates: CourseCertificationsUpdates;
  CourseCoaches: CourseCoaches;
  CourseCoachesAggregate: CourseCoachesAggregate;
  CourseCoachesAggregateBoolExp: CourseCoachesAggregateBoolExp;
  CourseCoachesAggregateBoolExpCount: CourseCoachesAggregateBoolExpCount;
  CourseCoachesAggregateFields: CourseCoachesAggregateFields;
  CourseCoachesAggregateOrderBy: CourseCoachesAggregateOrderBy;
  CourseCoachesArrRelInsertInput: CourseCoachesArrRelInsertInput;
  CourseCoachesBoolExp: CourseCoachesBoolExp;
  CourseCoachesInsertInput: CourseCoachesInsertInput;
  CourseCoachesMaxFields: CourseCoachesMaxFields;
  CourseCoachesMaxOrderBy: CourseCoachesMaxOrderBy;
  CourseCoachesMinFields: CourseCoachesMinFields;
  CourseCoachesMinOrderBy: CourseCoachesMinOrderBy;
  CourseCoachesMutationResponse: CourseCoachesMutationResponse;
  CourseCoachesOnConflict: CourseCoachesOnConflict;
  CourseCoachesOrderBy: CourseCoachesOrderBy;
  CourseCoachesPkColumnsInput: CourseCoachesPkColumnsInput;
  CourseCoachesSetInput: CourseCoachesSetInput;
  CourseCoachesStreamCursorInput: CourseCoachesStreamCursorInput;
  CourseCoachesStreamCursorValueInput: CourseCoachesStreamCursorValueInput;
  CourseCoachesUpdates: CourseCoachesUpdates;
  CourseEnrollmentStatus: CourseEnrollmentStatus;
  CourseEnrollmentStatusAggregate: CourseEnrollmentStatusAggregate;
  CourseEnrollmentStatusAggregateFields: CourseEnrollmentStatusAggregateFields;
  CourseEnrollmentStatusBoolExp: CourseEnrollmentStatusBoolExp;
  CourseEnrollmentStatusEnumComparisonExp: CourseEnrollmentStatusEnumComparisonExp;
  CourseEnrollmentStatusInsertInput: CourseEnrollmentStatusInsertInput;
  CourseEnrollmentStatusMaxFields: CourseEnrollmentStatusMaxFields;
  CourseEnrollmentStatusMinFields: CourseEnrollmentStatusMinFields;
  CourseEnrollmentStatusMutationResponse: CourseEnrollmentStatusMutationResponse;
  CourseEnrollmentStatusOnConflict: CourseEnrollmentStatusOnConflict;
  CourseEnrollmentStatusOrderBy: CourseEnrollmentStatusOrderBy;
  CourseEnrollmentStatusPkColumnsInput: CourseEnrollmentStatusPkColumnsInput;
  CourseEnrollmentStatusSetInput: CourseEnrollmentStatusSetInput;
  CourseEnrollmentStatusStreamCursorInput: CourseEnrollmentStatusStreamCursorInput;
  CourseEnrollmentStatusStreamCursorValueInput: CourseEnrollmentStatusStreamCursorValueInput;
  CourseEnrollmentStatusUpdates: CourseEnrollmentStatusUpdates;
  CoursePublishStatus: CoursePublishStatus;
  CoursePublishStatusAggregate: CoursePublishStatusAggregate;
  CoursePublishStatusAggregateFields: CoursePublishStatusAggregateFields;
  CoursePublishStatusBoolExp: CoursePublishStatusBoolExp;
  CoursePublishStatusEnumComparisonExp: CoursePublishStatusEnumComparisonExp;
  CoursePublishStatusInsertInput: CoursePublishStatusInsertInput;
  CoursePublishStatusMaxFields: CoursePublishStatusMaxFields;
  CoursePublishStatusMinFields: CoursePublishStatusMinFields;
  CoursePublishStatusMutationResponse: CoursePublishStatusMutationResponse;
  CoursePublishStatusObjRelInsertInput: CoursePublishStatusObjRelInsertInput;
  CoursePublishStatusOnConflict: CoursePublishStatusOnConflict;
  CoursePublishStatusOrderBy: CoursePublishStatusOrderBy;
  CoursePublishStatusPkColumnsInput: CoursePublishStatusPkColumnsInput;
  CoursePublishStatusSetInput: CoursePublishStatusSetInput;
  CoursePublishStatusStreamCursorInput: CoursePublishStatusStreamCursorInput;
  CoursePublishStatusStreamCursorValueInput: CoursePublishStatusStreamCursorValueInput;
  CoursePublishStatusUpdates: CoursePublishStatusUpdates;
  CourseType: CourseType;
  CourseTypeAggregate: CourseTypeAggregate;
  CourseTypeAggregateFields: CourseTypeAggregateFields;
  CourseTypeBoolExp: CourseTypeBoolExp;
  CourseTypeEnumComparisonExp: CourseTypeEnumComparisonExp;
  CourseTypeInsertInput: CourseTypeInsertInput;
  CourseTypeMaxFields: CourseTypeMaxFields;
  CourseTypeMinFields: CourseTypeMinFields;
  CourseTypeMutationResponse: CourseTypeMutationResponse;
  CourseTypeOnConflict: CourseTypeOnConflict;
  CourseTypeOrderBy: CourseTypeOrderBy;
  CourseTypePkColumnsInput: CourseTypePkColumnsInput;
  CourseTypeSetInput: CourseTypeSetInput;
  CourseTypeStreamCursorInput: CourseTypeStreamCursorInput;
  CourseTypeStreamCursorValueInput: CourseTypeStreamCursorValueInput;
  CourseTypeUpdates: CourseTypeUpdates;
  CourseWaitlist: CourseWaitlist;
  CourseWaitlistAggregate: CourseWaitlistAggregate;
  CourseWaitlistAggregateFields: CourseWaitlistAggregateFields;
  CourseWaitlistBoolExp: CourseWaitlistBoolExp;
  CourseWaitlistDesiredUrgencyType: CourseWaitlistDesiredUrgencyType;
  CourseWaitlistDesiredUrgencyTypeAggregate: CourseWaitlistDesiredUrgencyTypeAggregate;
  CourseWaitlistDesiredUrgencyTypeAggregateFields: CourseWaitlistDesiredUrgencyTypeAggregateFields;
  CourseWaitlistDesiredUrgencyTypeBoolExp: CourseWaitlistDesiredUrgencyTypeBoolExp;
  CourseWaitlistDesiredUrgencyTypeEnumComparisonExp: CourseWaitlistDesiredUrgencyTypeEnumComparisonExp;
  CourseWaitlistDesiredUrgencyTypeInsertInput: CourseWaitlistDesiredUrgencyTypeInsertInput;
  CourseWaitlistDesiredUrgencyTypeMaxFields: CourseWaitlistDesiredUrgencyTypeMaxFields;
  CourseWaitlistDesiredUrgencyTypeMinFields: CourseWaitlistDesiredUrgencyTypeMinFields;
  CourseWaitlistDesiredUrgencyTypeMutationResponse: CourseWaitlistDesiredUrgencyTypeMutationResponse;
  CourseWaitlistDesiredUrgencyTypeOnConflict: CourseWaitlistDesiredUrgencyTypeOnConflict;
  CourseWaitlistDesiredUrgencyTypeOrderBy: CourseWaitlistDesiredUrgencyTypeOrderBy;
  CourseWaitlistDesiredUrgencyTypePkColumnsInput: CourseWaitlistDesiredUrgencyTypePkColumnsInput;
  CourseWaitlistDesiredUrgencyTypeSetInput: CourseWaitlistDesiredUrgencyTypeSetInput;
  CourseWaitlistDesiredUrgencyTypeStreamCursorInput: CourseWaitlistDesiredUrgencyTypeStreamCursorInput;
  CourseWaitlistDesiredUrgencyTypeStreamCursorValueInput: CourseWaitlistDesiredUrgencyTypeStreamCursorValueInput;
  CourseWaitlistDesiredUrgencyTypeUpdates: CourseWaitlistDesiredUrgencyTypeUpdates;
  CourseWaitlistInput: CourseWaitlistInput;
  CourseWaitlistInsertInput: CourseWaitlistInsertInput;
  CourseWaitlistMaxFields: CourseWaitlistMaxFields;
  CourseWaitlistMinFields: CourseWaitlistMinFields;
  CourseWaitlistMutationResponse: CourseWaitlistMutationResponse;
  CourseWaitlistOnConflict: CourseWaitlistOnConflict;
  CourseWaitlistOrderBy: CourseWaitlistOrderBy;
  CourseWaitlistPkColumnsInput: CourseWaitlistPkColumnsInput;
  CourseWaitlistSendAvailabilityEmailInput: CourseWaitlistSendAvailabilityEmailInput;
  CourseWaitlistSendAvailabilityEmailOutput: CourseWaitlistSendAvailabilityEmailOutput;
  CourseWaitlistSendAvailabilityEmailUserOutPut: CourseWaitlistSendAvailabilityEmailUserOutPut;
  CourseWaitlistSetInput: CourseWaitlistSetInput;
  CourseWaitlistStreamCursorInput: CourseWaitlistStreamCursorInput;
  CourseWaitlistStreamCursorValueInput: CourseWaitlistStreamCursorValueInput;
  CourseWaitlistUpdates: CourseWaitlistUpdates;
  Courses: Courses;
  CoursesAggregate: CoursesAggregate;
  CoursesAggregateBoolExp: CoursesAggregateBoolExp;
  CoursesAggregateFields: CoursesAggregateFields;
  CoursesAggregateOrderBy: CoursesAggregateOrderBy;
  CoursesArrRelInsertInput: CoursesArrRelInsertInput;
  CoursesAvgFields: CoursesAvgFields;
  CoursesAvgOrderBy: CoursesAvgOrderBy;
  CoursesBoolExp: CoursesBoolExp;
  CoursesByPlan: CoursesByPlan;
  CoursesByPlanAggregate: CoursesByPlanAggregate;
  CoursesByPlanAggregateFields: CoursesByPlanAggregateFields;
  CoursesByPlanAvgFields: CoursesByPlanAvgFields;
  CoursesByPlanBoolExp: CoursesByPlanBoolExp;
  CoursesByPlanMaxFields: CoursesByPlanMaxFields;
  CoursesByPlanMinFields: CoursesByPlanMinFields;
  CoursesByPlanOrderBy: CoursesByPlanOrderBy;
  CoursesByPlanStddevFields: CoursesByPlanStddevFields;
  CoursesByPlanStddevPopFields: CoursesByPlanStddevPopFields;
  CoursesByPlanStddevSampFields: CoursesByPlanStddevSampFields;
  CoursesByPlanStreamCursorInput: CoursesByPlanStreamCursorInput;
  CoursesByPlanStreamCursorValueInput: CoursesByPlanStreamCursorValueInput;
  CoursesByPlanSumFields: CoursesByPlanSumFields;
  CoursesByPlanVarPopFields: CoursesByPlanVarPopFields;
  CoursesByPlanVarSampFields: CoursesByPlanVarSampFields;
  CoursesByPlanVarianceFields: CoursesByPlanVarianceFields;
  CoursesIncInput: CoursesIncInput;
  CoursesInsertInput: CoursesInsertInput;
  CoursesMaxFields: CoursesMaxFields;
  CoursesMaxOrderBy: CoursesMaxOrderBy;
  CoursesMinFields: CoursesMinFields;
  CoursesMinOrderBy: CoursesMinOrderBy;
  CoursesMutationResponse: CoursesMutationResponse;
  CoursesObjRelInsertInput: CoursesObjRelInsertInput;
  CoursesOnConflict: CoursesOnConflict;
  CoursesOrderBy: CoursesOrderBy;
  CoursesPkColumnsInput: CoursesPkColumnsInput;
  CoursesResourcesRel: CoursesResourcesRel;
  CoursesResourcesRelAggregate: CoursesResourcesRelAggregate;
  CoursesResourcesRelAggregateBoolExp: CoursesResourcesRelAggregateBoolExp;
  CoursesResourcesRelAggregateBoolExpCount: CoursesResourcesRelAggregateBoolExpCount;
  CoursesResourcesRelAggregateFields: CoursesResourcesRelAggregateFields;
  CoursesResourcesRelAggregateOrderBy: CoursesResourcesRelAggregateOrderBy;
  CoursesResourcesRelArrRelInsertInput: CoursesResourcesRelArrRelInsertInput;
  CoursesResourcesRelBoolExp: CoursesResourcesRelBoolExp;
  CoursesResourcesRelInsertInput: CoursesResourcesRelInsertInput;
  CoursesResourcesRelMaxFields: CoursesResourcesRelMaxFields;
  CoursesResourcesRelMaxOrderBy: CoursesResourcesRelMaxOrderBy;
  CoursesResourcesRelMinFields: CoursesResourcesRelMinFields;
  CoursesResourcesRelMinOrderBy: CoursesResourcesRelMinOrderBy;
  CoursesResourcesRelMutationResponse: CoursesResourcesRelMutationResponse;
  CoursesResourcesRelObjRelInsertInput: CoursesResourcesRelObjRelInsertInput;
  CoursesResourcesRelOnConflict: CoursesResourcesRelOnConflict;
  CoursesResourcesRelOrderBy: CoursesResourcesRelOrderBy;
  CoursesResourcesRelPkColumnsInput: CoursesResourcesRelPkColumnsInput;
  CoursesResourcesRelSetInput: CoursesResourcesRelSetInput;
  CoursesResourcesRelStreamCursorInput: CoursesResourcesRelStreamCursorInput;
  CoursesResourcesRelStreamCursorValueInput: CoursesResourcesRelStreamCursorValueInput;
  CoursesResourcesRelUpdates: CoursesResourcesRelUpdates;
  CoursesSetInput: CoursesSetInput;
  CoursesStddevFields: CoursesStddevFields;
  CoursesStddevOrderBy: CoursesStddevOrderBy;
  CoursesStddevPopFields: CoursesStddevPopFields;
  CoursesStddevPopOrderBy: CoursesStddevPopOrderBy;
  CoursesStddevSampFields: CoursesStddevSampFields;
  CoursesStddevSampOrderBy: CoursesStddevSampOrderBy;
  CoursesStreamCursorInput: CoursesStreamCursorInput;
  CoursesStreamCursorValueInput: CoursesStreamCursorValueInput;
  CoursesSumFields: CoursesSumFields;
  CoursesSumOrderBy: CoursesSumOrderBy;
  CoursesTagsRel: CoursesTagsRel;
  CoursesTagsRelAggregate: CoursesTagsRelAggregate;
  CoursesTagsRelAggregateBoolExp: CoursesTagsRelAggregateBoolExp;
  CoursesTagsRelAggregateFields: CoursesTagsRelAggregateFields;
  CoursesTagsRelAggregateOrderBy: CoursesTagsRelAggregateOrderBy;
  CoursesTagsRelArrRelInsertInput: CoursesTagsRelArrRelInsertInput;
  CoursesTagsRelBoolExp: CoursesTagsRelBoolExp;
  CoursesTagsRelInsertInput: CoursesTagsRelInsertInput;
  CoursesTagsRelMaxFields: CoursesTagsRelMaxFields;
  CoursesTagsRelMaxOrderBy: CoursesTagsRelMaxOrderBy;
  CoursesTagsRelMinFields: CoursesTagsRelMinFields;
  CoursesTagsRelMinOrderBy: CoursesTagsRelMinOrderBy;
  CoursesTagsRelMutationResponse: CoursesTagsRelMutationResponse;
  CoursesTagsRelOnConflict: CoursesTagsRelOnConflict;
  CoursesTagsRelOrderBy: CoursesTagsRelOrderBy;
  CoursesTagsRelPkColumnsInput: CoursesTagsRelPkColumnsInput;
  CoursesTagsRelSetInput: CoursesTagsRelSetInput;
  CoursesTagsRelStreamCursorInput: CoursesTagsRelStreamCursorInput;
  CoursesTagsRelStreamCursorValueInput: CoursesTagsRelStreamCursorValueInput;
  CoursesTagsRelUpdates: CoursesTagsRelUpdates;
  CoursesUpdates: CoursesUpdates;
  CoursesVarPopFields: CoursesVarPopFields;
  CoursesVarPopOrderBy: CoursesVarPopOrderBy;
  CoursesVarSampFields: CoursesVarSampFields;
  CoursesVarSampOrderBy: CoursesVarSampOrderBy;
  CoursesVarianceFields: CoursesVarianceFields;
  CoursesVarianceOrderBy: CoursesVarianceOrderBy;
  CreateOrganizationOutput: CreateOrganizationOutput;
  CreateUserOutput: CreateUserOutput;
  DateComparisonExp: DateComparisonExp;
  EmailQueue: EmailQueue;
  EmailQueueAggregate: EmailQueueAggregate;
  EmailQueueAggregateFields: EmailQueueAggregateFields;
  EmailQueueAppendInput: EmailQueueAppendInput;
  EmailQueueBoolExp: EmailQueueBoolExp;
  EmailQueueDeleteAtPathInput: EmailQueueDeleteAtPathInput;
  EmailQueueDeleteElemInput: EmailQueueDeleteElemInput;
  EmailQueueDeleteKeyInput: EmailQueueDeleteKeyInput;
  EmailQueueInsertInput: EmailQueueInsertInput;
  EmailQueueMaxFields: EmailQueueMaxFields;
  EmailQueueMinFields: EmailQueueMinFields;
  EmailQueueMutationResponse: EmailQueueMutationResponse;
  EmailQueueOnConflict: EmailQueueOnConflict;
  EmailQueueOrderBy: EmailQueueOrderBy;
  EmailQueuePkColumnsInput: EmailQueuePkColumnsInput;
  EmailQueuePrependInput: EmailQueuePrependInput;
  EmailQueueSetInput: EmailQueueSetInput;
  EmailQueueStreamCursorInput: EmailQueueStreamCursorInput;
  EmailQueueStreamCursorValueInput: EmailQueueStreamCursorValueInput;
  EmailQueueUpdates: EmailQueueUpdates;
  EnrollInput: EnrollInput;
  EnrollOutput: EnrollOutput;
  EnrollmentWaitlists: EnrollmentWaitlists;
  EnrollmentWaitlistsAggregate: EnrollmentWaitlistsAggregate;
  EnrollmentWaitlistsAggregateBoolExp: EnrollmentWaitlistsAggregateBoolExp;
  EnrollmentWaitlistsAggregateFields: EnrollmentWaitlistsAggregateFields;
  EnrollmentWaitlistsAggregateOrderBy: EnrollmentWaitlistsAggregateOrderBy;
  EnrollmentWaitlistsArrRelInsertInput: EnrollmentWaitlistsArrRelInsertInput;
  EnrollmentWaitlistsBoolExp: EnrollmentWaitlistsBoolExp;
  EnrollmentWaitlistsInsertInput: EnrollmentWaitlistsInsertInput;
  EnrollmentWaitlistsMaxFields: EnrollmentWaitlistsMaxFields;
  EnrollmentWaitlistsMaxOrderBy: EnrollmentWaitlistsMaxOrderBy;
  EnrollmentWaitlistsMinFields: EnrollmentWaitlistsMinFields;
  EnrollmentWaitlistsMinOrderBy: EnrollmentWaitlistsMinOrderBy;
  EnrollmentWaitlistsMutationResponse: EnrollmentWaitlistsMutationResponse;
  EnrollmentWaitlistsOnConflict: EnrollmentWaitlistsOnConflict;
  EnrollmentWaitlistsOrderBy: EnrollmentWaitlistsOrderBy;
  EnrollmentWaitlistsPkColumnsInput: EnrollmentWaitlistsPkColumnsInput;
  EnrollmentWaitlistsSetInput: EnrollmentWaitlistsSetInput;
  EnrollmentWaitlistsStreamCursorInput: EnrollmentWaitlistsStreamCursorInput;
  EnrollmentWaitlistsStreamCursorValueInput: EnrollmentWaitlistsStreamCursorValueInput;
  EnrollmentWaitlistsUpdates: EnrollmentWaitlistsUpdates;
  Enrollments: Enrollments;
  EnrollmentsAggregate: EnrollmentsAggregate;
  EnrollmentsAggregateBoolExp: EnrollmentsAggregateBoolExp;
  EnrollmentsAggregateFields: EnrollmentsAggregateFields;
  EnrollmentsAggregateOrderBy: EnrollmentsAggregateOrderBy;
  EnrollmentsArrRelInsertInput: EnrollmentsArrRelInsertInput;
  EnrollmentsBoolExp: EnrollmentsBoolExp;
  EnrollmentsInsertInput: EnrollmentsInsertInput;
  EnrollmentsMaxFields: EnrollmentsMaxFields;
  EnrollmentsMaxOrderBy: EnrollmentsMaxOrderBy;
  EnrollmentsMinFields: EnrollmentsMinFields;
  EnrollmentsMinOrderBy: EnrollmentsMinOrderBy;
  EnrollmentsMutationResponse: EnrollmentsMutationResponse;
  EnrollmentsObjRelInsertInput: EnrollmentsObjRelInsertInput;
  EnrollmentsOnConflict: EnrollmentsOnConflict;
  EnrollmentsOrderBy: EnrollmentsOrderBy;
  EnrollmentsPkColumnsInput: EnrollmentsPkColumnsInput;
  EnrollmentsSetInput: EnrollmentsSetInput;
  EnrollmentsStreamCursorInput: EnrollmentsStreamCursorInput;
  EnrollmentsStreamCursorValueInput: EnrollmentsStreamCursorValueInput;
  EnrollmentsUpdates: EnrollmentsUpdates;
  Float: Scalars['Float'];
  Float8ComparisonExp: Float8ComparisonExp;
  FusionauthExternalIdentifiers: FusionauthExternalIdentifiers;
  FusionauthExternalIdentifiersAggregate: FusionauthExternalIdentifiersAggregate;
  FusionauthExternalIdentifiersAggregateFields: FusionauthExternalIdentifiersAggregateFields;
  FusionauthExternalIdentifiersAvgFields: FusionauthExternalIdentifiersAvgFields;
  FusionauthExternalIdentifiersBoolExp: FusionauthExternalIdentifiersBoolExp;
  FusionauthExternalIdentifiersIncInput: FusionauthExternalIdentifiersIncInput;
  FusionauthExternalIdentifiersInsertInput: FusionauthExternalIdentifiersInsertInput;
  FusionauthExternalIdentifiersMaxFields: FusionauthExternalIdentifiersMaxFields;
  FusionauthExternalIdentifiersMinFields: FusionauthExternalIdentifiersMinFields;
  FusionauthExternalIdentifiersMutationResponse: FusionauthExternalIdentifiersMutationResponse;
  FusionauthExternalIdentifiersOnConflict: FusionauthExternalIdentifiersOnConflict;
  FusionauthExternalIdentifiersOrderBy: FusionauthExternalIdentifiersOrderBy;
  FusionauthExternalIdentifiersPkColumnsInput: FusionauthExternalIdentifiersPkColumnsInput;
  FusionauthExternalIdentifiersSetInput: FusionauthExternalIdentifiersSetInput;
  FusionauthExternalIdentifiersStddevFields: FusionauthExternalIdentifiersStddevFields;
  FusionauthExternalIdentifiersStddevPopFields: FusionauthExternalIdentifiersStddevPopFields;
  FusionauthExternalIdentifiersStddevSampFields: FusionauthExternalIdentifiersStddevSampFields;
  FusionauthExternalIdentifiersStreamCursorInput: FusionauthExternalIdentifiersStreamCursorInput;
  FusionauthExternalIdentifiersStreamCursorValueInput: FusionauthExternalIdentifiersStreamCursorValueInput;
  FusionauthExternalIdentifiersSumFields: FusionauthExternalIdentifiersSumFields;
  FusionauthExternalIdentifiersUpdates: FusionauthExternalIdentifiersUpdates;
  FusionauthExternalIdentifiersVarPopFields: FusionauthExternalIdentifiersVarPopFields;
  FusionauthExternalIdentifiersVarSampFields: FusionauthExternalIdentifiersVarSampFields;
  FusionauthExternalIdentifiersVarianceFields: FusionauthExternalIdentifiersVarianceFields;
  FusionauthUserRegistrations: FusionauthUserRegistrations;
  FusionauthUserRegistrationsAggregate: FusionauthUserRegistrationsAggregate;
  FusionauthUserRegistrationsAggregateBoolExp: FusionauthUserRegistrationsAggregateBoolExp;
  FusionauthUserRegistrationsAggregateFields: FusionauthUserRegistrationsAggregateFields;
  FusionauthUserRegistrationsAggregateOrderBy: FusionauthUserRegistrationsAggregateOrderBy;
  FusionauthUserRegistrationsArrRelInsertInput: FusionauthUserRegistrationsArrRelInsertInput;
  FusionauthUserRegistrationsAvgFields: FusionauthUserRegistrationsAvgFields;
  FusionauthUserRegistrationsAvgOrderBy: FusionauthUserRegistrationsAvgOrderBy;
  FusionauthUserRegistrationsBoolExp: FusionauthUserRegistrationsBoolExp;
  FusionauthUserRegistrationsIncInput: FusionauthUserRegistrationsIncInput;
  FusionauthUserRegistrationsInsertInput: FusionauthUserRegistrationsInsertInput;
  FusionauthUserRegistrationsMaxFields: FusionauthUserRegistrationsMaxFields;
  FusionauthUserRegistrationsMaxOrderBy: FusionauthUserRegistrationsMaxOrderBy;
  FusionauthUserRegistrationsMinFields: FusionauthUserRegistrationsMinFields;
  FusionauthUserRegistrationsMinOrderBy: FusionauthUserRegistrationsMinOrderBy;
  FusionauthUserRegistrationsMutationResponse: FusionauthUserRegistrationsMutationResponse;
  FusionauthUserRegistrationsOnConflict: FusionauthUserRegistrationsOnConflict;
  FusionauthUserRegistrationsOrderBy: FusionauthUserRegistrationsOrderBy;
  FusionauthUserRegistrationsPkColumnsInput: FusionauthUserRegistrationsPkColumnsInput;
  FusionauthUserRegistrationsSetInput: FusionauthUserRegistrationsSetInput;
  FusionauthUserRegistrationsStddevFields: FusionauthUserRegistrationsStddevFields;
  FusionauthUserRegistrationsStddevOrderBy: FusionauthUserRegistrationsStddevOrderBy;
  FusionauthUserRegistrationsStddevPopFields: FusionauthUserRegistrationsStddevPopFields;
  FusionauthUserRegistrationsStddevPopOrderBy: FusionauthUserRegistrationsStddevPopOrderBy;
  FusionauthUserRegistrationsStddevSampFields: FusionauthUserRegistrationsStddevSampFields;
  FusionauthUserRegistrationsStddevSampOrderBy: FusionauthUserRegistrationsStddevSampOrderBy;
  FusionauthUserRegistrationsStreamCursorInput: FusionauthUserRegistrationsStreamCursorInput;
  FusionauthUserRegistrationsStreamCursorValueInput: FusionauthUserRegistrationsStreamCursorValueInput;
  FusionauthUserRegistrationsSumFields: FusionauthUserRegistrationsSumFields;
  FusionauthUserRegistrationsSumOrderBy: FusionauthUserRegistrationsSumOrderBy;
  FusionauthUserRegistrationsUpdates: FusionauthUserRegistrationsUpdates;
  FusionauthUserRegistrationsVarPopFields: FusionauthUserRegistrationsVarPopFields;
  FusionauthUserRegistrationsVarPopOrderBy: FusionauthUserRegistrationsVarPopOrderBy;
  FusionauthUserRegistrationsVarSampFields: FusionauthUserRegistrationsVarSampFields;
  FusionauthUserRegistrationsVarSampOrderBy: FusionauthUserRegistrationsVarSampOrderBy;
  FusionauthUserRegistrationsVarianceFields: FusionauthUserRegistrationsVarianceFields;
  FusionauthUserRegistrationsVarianceOrderBy: FusionauthUserRegistrationsVarianceOrderBy;
  GetCohortEnrollmentStatusOutput: GetCohortEnrollmentStatusOutput;
  GetMySessionsAttendedCountOutput: GetMySessionsAttendedCountOutput;
  GetPastSessionAsyncAverageMetricsOutput: GetPastSessionAsyncAverageMetricsOutput;
  GetPastSessionAsyncAverageMetricsPastSession: GetPastSessionAsyncAverageMetricsPastSession;
  GetPastSessionAsyncAverageMetricsSnowflakeMetrics: GetPastSessionAsyncAverageMetricsSnowflakeMetrics;
  GetPastSessionAsyncMetricsMemberSessionNps: GetPastSessionAsyncMetricsMemberSessionNps;
  GetPastSessionAsyncMetricsMemberWithSessionNps: GetPastSessionAsyncMetricsMemberWithSessionNps;
  GetPastSessionAsyncMetricsMetric: GetPastSessionAsyncMetricsMetric;
  GetPastSessionAsyncMetricsOutput: GetPastSessionAsyncMetricsOutput;
  GetPastSessionAsyncMetricsSnowflakeMetrics: GetPastSessionAsyncMetricsSnowflakeMetrics;
  Identities: Identities;
  IdentitiesAggregate: IdentitiesAggregate;
  IdentitiesAggregateBoolExp: IdentitiesAggregateBoolExp;
  IdentitiesAggregateBoolExpBool_and: IdentitiesAggregateBoolExpBool_And;
  IdentitiesAggregateBoolExpBool_or: IdentitiesAggregateBoolExpBool_Or;
  IdentitiesAggregateBoolExpCount: IdentitiesAggregateBoolExpCount;
  IdentitiesAggregateFields: IdentitiesAggregateFields;
  IdentitiesAggregateOrderBy: IdentitiesAggregateOrderBy;
  IdentitiesArrRelInsertInput: IdentitiesArrRelInsertInput;
  IdentitiesAvgFields: IdentitiesAvgFields;
  IdentitiesAvgOrderBy: IdentitiesAvgOrderBy;
  IdentitiesBoolExp: IdentitiesBoolExp;
  IdentitiesIncInput: IdentitiesIncInput;
  IdentitiesInsertInput: IdentitiesInsertInput;
  IdentitiesMaxFields: IdentitiesMaxFields;
  IdentitiesMaxOrderBy: IdentitiesMaxOrderBy;
  IdentitiesMinFields: IdentitiesMinFields;
  IdentitiesMinOrderBy: IdentitiesMinOrderBy;
  IdentitiesMutationResponse: IdentitiesMutationResponse;
  IdentitiesOnConflict: IdentitiesOnConflict;
  IdentitiesOrderBy: IdentitiesOrderBy;
  IdentitiesPkColumnsInput: IdentitiesPkColumnsInput;
  IdentitiesSetInput: IdentitiesSetInput;
  IdentitiesStddevFields: IdentitiesStddevFields;
  IdentitiesStddevOrderBy: IdentitiesStddevOrderBy;
  IdentitiesStddevPopFields: IdentitiesStddevPopFields;
  IdentitiesStddevPopOrderBy: IdentitiesStddevPopOrderBy;
  IdentitiesStddevSampFields: IdentitiesStddevSampFields;
  IdentitiesStddevSampOrderBy: IdentitiesStddevSampOrderBy;
  IdentitiesStreamCursorInput: IdentitiesStreamCursorInput;
  IdentitiesStreamCursorValueInput: IdentitiesStreamCursorValueInput;
  IdentitiesSumFields: IdentitiesSumFields;
  IdentitiesSumOrderBy: IdentitiesSumOrderBy;
  IdentitiesUpdates: IdentitiesUpdates;
  IdentitiesVarPopFields: IdentitiesVarPopFields;
  IdentitiesVarPopOrderBy: IdentitiesVarPopOrderBy;
  IdentitiesVarSampFields: IdentitiesVarSampFields;
  IdentitiesVarSampOrderBy: IdentitiesVarSampOrderBy;
  IdentitiesVarianceFields: IdentitiesVarianceFields;
  IdentitiesVarianceOrderBy: IdentitiesVarianceOrderBy;
  IdentityProviderLinks: IdentityProviderLinks;
  IdentityProviderLinksAggregate: IdentityProviderLinksAggregate;
  IdentityProviderLinksAggregateBoolExp: IdentityProviderLinksAggregateBoolExp;
  IdentityProviderLinksAggregateBoolExpCount: IdentityProviderLinksAggregateBoolExpCount;
  IdentityProviderLinksAggregateFields: IdentityProviderLinksAggregateFields;
  IdentityProviderLinksAggregateOrderBy: IdentityProviderLinksAggregateOrderBy;
  IdentityProviderLinksArrRelInsertInput: IdentityProviderLinksArrRelInsertInput;
  IdentityProviderLinksAvgFields: IdentityProviderLinksAvgFields;
  IdentityProviderLinksAvgOrderBy: IdentityProviderLinksAvgOrderBy;
  IdentityProviderLinksBoolExp: IdentityProviderLinksBoolExp;
  IdentityProviderLinksIncInput: IdentityProviderLinksIncInput;
  IdentityProviderLinksInsertInput: IdentityProviderLinksInsertInput;
  IdentityProviderLinksMaxFields: IdentityProviderLinksMaxFields;
  IdentityProviderLinksMaxOrderBy: IdentityProviderLinksMaxOrderBy;
  IdentityProviderLinksMinFields: IdentityProviderLinksMinFields;
  IdentityProviderLinksMinOrderBy: IdentityProviderLinksMinOrderBy;
  IdentityProviderLinksMutationResponse: IdentityProviderLinksMutationResponse;
  IdentityProviderLinksOnConflict: IdentityProviderLinksOnConflict;
  IdentityProviderLinksOrderBy: IdentityProviderLinksOrderBy;
  IdentityProviderLinksSetInput: IdentityProviderLinksSetInput;
  IdentityProviderLinksStddevFields: IdentityProviderLinksStddevFields;
  IdentityProviderLinksStddevOrderBy: IdentityProviderLinksStddevOrderBy;
  IdentityProviderLinksStddevPopFields: IdentityProviderLinksStddevPopFields;
  IdentityProviderLinksStddevPopOrderBy: IdentityProviderLinksStddevPopOrderBy;
  IdentityProviderLinksStddevSampFields: IdentityProviderLinksStddevSampFields;
  IdentityProviderLinksStddevSampOrderBy: IdentityProviderLinksStddevSampOrderBy;
  IdentityProviderLinksStreamCursorInput: IdentityProviderLinksStreamCursorInput;
  IdentityProviderLinksStreamCursorValueInput: IdentityProviderLinksStreamCursorValueInput;
  IdentityProviderLinksSumFields: IdentityProviderLinksSumFields;
  IdentityProviderLinksSumOrderBy: IdentityProviderLinksSumOrderBy;
  IdentityProviderLinksUpdates: IdentityProviderLinksUpdates;
  IdentityProviderLinksVarPopFields: IdentityProviderLinksVarPopFields;
  IdentityProviderLinksVarPopOrderBy: IdentityProviderLinksVarPopOrderBy;
  IdentityProviderLinksVarSampFields: IdentityProviderLinksVarSampFields;
  IdentityProviderLinksVarSampOrderBy: IdentityProviderLinksVarSampOrderBy;
  IdentityProviderLinksVarianceFields: IdentityProviderLinksVarianceFields;
  IdentityProviderLinksVarianceOrderBy: IdentityProviderLinksVarianceOrderBy;
  IdentityProviders: IdentityProviders;
  IdentityProvidersAggregate: IdentityProvidersAggregate;
  IdentityProvidersAggregateFields: IdentityProvidersAggregateFields;
  IdentityProvidersAvgFields: IdentityProvidersAvgFields;
  IdentityProvidersBoolExp: IdentityProvidersBoolExp;
  IdentityProvidersIncInput: IdentityProvidersIncInput;
  IdentityProvidersInsertInput: IdentityProvidersInsertInput;
  IdentityProvidersMaxFields: IdentityProvidersMaxFields;
  IdentityProvidersMinFields: IdentityProvidersMinFields;
  IdentityProvidersMutationResponse: IdentityProvidersMutationResponse;
  IdentityProvidersObjRelInsertInput: IdentityProvidersObjRelInsertInput;
  IdentityProvidersOnConflict: IdentityProvidersOnConflict;
  IdentityProvidersOrderBy: IdentityProvidersOrderBy;
  IdentityProvidersPkColumnsInput: IdentityProvidersPkColumnsInput;
  IdentityProvidersSetInput: IdentityProvidersSetInput;
  IdentityProvidersStddevFields: IdentityProvidersStddevFields;
  IdentityProvidersStddevPopFields: IdentityProvidersStddevPopFields;
  IdentityProvidersStddevSampFields: IdentityProvidersStddevSampFields;
  IdentityProvidersStreamCursorInput: IdentityProvidersStreamCursorInput;
  IdentityProvidersStreamCursorValueInput: IdentityProvidersStreamCursorValueInput;
  IdentityProvidersSumFields: IdentityProvidersSumFields;
  IdentityProvidersUpdates: IdentityProvidersUpdates;
  IdentityProvidersVarPopFields: IdentityProvidersVarPopFields;
  IdentityProvidersVarSampFields: IdentityProvidersVarSampFields;
  IdentityProvidersVarianceFields: IdentityProvidersVarianceFields;
  ImpactAssessmentAttempts: ImpactAssessmentAttempts;
  ImpactAssessmentAttemptsAggregate: ImpactAssessmentAttemptsAggregate;
  ImpactAssessmentAttemptsAggregateBoolExp: ImpactAssessmentAttemptsAggregateBoolExp;
  ImpactAssessmentAttemptsAggregateFields: ImpactAssessmentAttemptsAggregateFields;
  ImpactAssessmentAttemptsAggregateOrderBy: ImpactAssessmentAttemptsAggregateOrderBy;
  ImpactAssessmentAttemptsArrRelInsertInput: ImpactAssessmentAttemptsArrRelInsertInput;
  ImpactAssessmentAttemptsAvgFields: ImpactAssessmentAttemptsAvgFields;
  ImpactAssessmentAttemptsAvgOrderBy: ImpactAssessmentAttemptsAvgOrderBy;
  ImpactAssessmentAttemptsBoolExp: ImpactAssessmentAttemptsBoolExp;
  ImpactAssessmentAttemptsIncInput: ImpactAssessmentAttemptsIncInput;
  ImpactAssessmentAttemptsInsertInput: ImpactAssessmentAttemptsInsertInput;
  ImpactAssessmentAttemptsMaxFields: ImpactAssessmentAttemptsMaxFields;
  ImpactAssessmentAttemptsMaxOrderBy: ImpactAssessmentAttemptsMaxOrderBy;
  ImpactAssessmentAttemptsMinFields: ImpactAssessmentAttemptsMinFields;
  ImpactAssessmentAttemptsMinOrderBy: ImpactAssessmentAttemptsMinOrderBy;
  ImpactAssessmentAttemptsMutationResponse: ImpactAssessmentAttemptsMutationResponse;
  ImpactAssessmentAttemptsOnConflict: ImpactAssessmentAttemptsOnConflict;
  ImpactAssessmentAttemptsOrderBy: ImpactAssessmentAttemptsOrderBy;
  ImpactAssessmentAttemptsPkColumnsInput: ImpactAssessmentAttemptsPkColumnsInput;
  ImpactAssessmentAttemptsSetInput: ImpactAssessmentAttemptsSetInput;
  ImpactAssessmentAttemptsStddevFields: ImpactAssessmentAttemptsStddevFields;
  ImpactAssessmentAttemptsStddevOrderBy: ImpactAssessmentAttemptsStddevOrderBy;
  ImpactAssessmentAttemptsStddevPopFields: ImpactAssessmentAttemptsStddevPopFields;
  ImpactAssessmentAttemptsStddevPopOrderBy: ImpactAssessmentAttemptsStddevPopOrderBy;
  ImpactAssessmentAttemptsStddevSampFields: ImpactAssessmentAttemptsStddevSampFields;
  ImpactAssessmentAttemptsStddevSampOrderBy: ImpactAssessmentAttemptsStddevSampOrderBy;
  ImpactAssessmentAttemptsStreamCursorInput: ImpactAssessmentAttemptsStreamCursorInput;
  ImpactAssessmentAttemptsStreamCursorValueInput: ImpactAssessmentAttemptsStreamCursorValueInput;
  ImpactAssessmentAttemptsSumFields: ImpactAssessmentAttemptsSumFields;
  ImpactAssessmentAttemptsSumOrderBy: ImpactAssessmentAttemptsSumOrderBy;
  ImpactAssessmentAttemptsUpdates: ImpactAssessmentAttemptsUpdates;
  ImpactAssessmentAttemptsVarPopFields: ImpactAssessmentAttemptsVarPopFields;
  ImpactAssessmentAttemptsVarPopOrderBy: ImpactAssessmentAttemptsVarPopOrderBy;
  ImpactAssessmentAttemptsVarSampFields: ImpactAssessmentAttemptsVarSampFields;
  ImpactAssessmentAttemptsVarSampOrderBy: ImpactAssessmentAttemptsVarSampOrderBy;
  ImpactAssessmentAttemptsVarianceFields: ImpactAssessmentAttemptsVarianceFields;
  ImpactAssessmentAttemptsVarianceOrderBy: ImpactAssessmentAttemptsVarianceOrderBy;
  ImpactAssessmentQuestions: ImpactAssessmentQuestions;
  ImpactAssessmentQuestionsAggregate: ImpactAssessmentQuestionsAggregate;
  ImpactAssessmentQuestionsAggregateBoolExp: ImpactAssessmentQuestionsAggregateBoolExp;
  ImpactAssessmentQuestionsAggregateFields: ImpactAssessmentQuestionsAggregateFields;
  ImpactAssessmentQuestionsAggregateOrderBy: ImpactAssessmentQuestionsAggregateOrderBy;
  ImpactAssessmentQuestionsArrRelInsertInput: ImpactAssessmentQuestionsArrRelInsertInput;
  ImpactAssessmentQuestionsAvgFields: ImpactAssessmentQuestionsAvgFields;
  ImpactAssessmentQuestionsAvgOrderBy: ImpactAssessmentQuestionsAvgOrderBy;
  ImpactAssessmentQuestionsBoolExp: ImpactAssessmentQuestionsBoolExp;
  ImpactAssessmentQuestionsIncInput: ImpactAssessmentQuestionsIncInput;
  ImpactAssessmentQuestionsInsertInput: ImpactAssessmentQuestionsInsertInput;
  ImpactAssessmentQuestionsMaxFields: ImpactAssessmentQuestionsMaxFields;
  ImpactAssessmentQuestionsMaxOrderBy: ImpactAssessmentQuestionsMaxOrderBy;
  ImpactAssessmentQuestionsMinFields: ImpactAssessmentQuestionsMinFields;
  ImpactAssessmentQuestionsMinOrderBy: ImpactAssessmentQuestionsMinOrderBy;
  ImpactAssessmentQuestionsMutationResponse: ImpactAssessmentQuestionsMutationResponse;
  ImpactAssessmentQuestionsOrderBy: ImpactAssessmentQuestionsOrderBy;
  ImpactAssessmentQuestionsSetInput: ImpactAssessmentQuestionsSetInput;
  ImpactAssessmentQuestionsStddevFields: ImpactAssessmentQuestionsStddevFields;
  ImpactAssessmentQuestionsStddevOrderBy: ImpactAssessmentQuestionsStddevOrderBy;
  ImpactAssessmentQuestionsStddevPopFields: ImpactAssessmentQuestionsStddevPopFields;
  ImpactAssessmentQuestionsStddevPopOrderBy: ImpactAssessmentQuestionsStddevPopOrderBy;
  ImpactAssessmentQuestionsStddevSampFields: ImpactAssessmentQuestionsStddevSampFields;
  ImpactAssessmentQuestionsStddevSampOrderBy: ImpactAssessmentQuestionsStddevSampOrderBy;
  ImpactAssessmentQuestionsStreamCursorInput: ImpactAssessmentQuestionsStreamCursorInput;
  ImpactAssessmentQuestionsStreamCursorValueInput: ImpactAssessmentQuestionsStreamCursorValueInput;
  ImpactAssessmentQuestionsSumFields: ImpactAssessmentQuestionsSumFields;
  ImpactAssessmentQuestionsSumOrderBy: ImpactAssessmentQuestionsSumOrderBy;
  ImpactAssessmentQuestionsUpdates: ImpactAssessmentQuestionsUpdates;
  ImpactAssessmentQuestionsVarPopFields: ImpactAssessmentQuestionsVarPopFields;
  ImpactAssessmentQuestionsVarPopOrderBy: ImpactAssessmentQuestionsVarPopOrderBy;
  ImpactAssessmentQuestionsVarSampFields: ImpactAssessmentQuestionsVarSampFields;
  ImpactAssessmentQuestionsVarSampOrderBy: ImpactAssessmentQuestionsVarSampOrderBy;
  ImpactAssessmentQuestionsVarianceFields: ImpactAssessmentQuestionsVarianceFields;
  ImpactAssessmentQuestionsVarianceOrderBy: ImpactAssessmentQuestionsVarianceOrderBy;
  ImpactAssessments: ImpactAssessments;
  ImpactAssessmentsAggregate: ImpactAssessmentsAggregate;
  ImpactAssessmentsAggregateFields: ImpactAssessmentsAggregateFields;
  ImpactAssessmentsAvgFields: ImpactAssessmentsAvgFields;
  ImpactAssessmentsBoolExp: ImpactAssessmentsBoolExp;
  ImpactAssessmentsIncInput: ImpactAssessmentsIncInput;
  ImpactAssessmentsInsertInput: ImpactAssessmentsInsertInput;
  ImpactAssessmentsMaxFields: ImpactAssessmentsMaxFields;
  ImpactAssessmentsMinFields: ImpactAssessmentsMinFields;
  ImpactAssessmentsMutationResponse: ImpactAssessmentsMutationResponse;
  ImpactAssessmentsObjRelInsertInput: ImpactAssessmentsObjRelInsertInput;
  ImpactAssessmentsOnConflict: ImpactAssessmentsOnConflict;
  ImpactAssessmentsOrderBy: ImpactAssessmentsOrderBy;
  ImpactAssessmentsPkColumnsInput: ImpactAssessmentsPkColumnsInput;
  ImpactAssessmentsSetInput: ImpactAssessmentsSetInput;
  ImpactAssessmentsStddevFields: ImpactAssessmentsStddevFields;
  ImpactAssessmentsStddevPopFields: ImpactAssessmentsStddevPopFields;
  ImpactAssessmentsStddevSampFields: ImpactAssessmentsStddevSampFields;
  ImpactAssessmentsStreamCursorInput: ImpactAssessmentsStreamCursorInput;
  ImpactAssessmentsStreamCursorValueInput: ImpactAssessmentsStreamCursorValueInput;
  ImpactAssessmentsSumFields: ImpactAssessmentsSumFields;
  ImpactAssessmentsUpdates: ImpactAssessmentsUpdates;
  ImpactAssessmentsVarPopFields: ImpactAssessmentsVarPopFields;
  ImpactAssessmentsVarSampFields: ImpactAssessmentsVarSampFields;
  ImpactAssessmentsVarianceFields: ImpactAssessmentsVarianceFields;
  ImportUser: ImportUser;
  Int: Scalars['Int'];
  IntComparisonExp: IntComparisonExp;
  JourneyEnrollments: JourneyEnrollments;
  JourneyEnrollmentsAggregate: JourneyEnrollmentsAggregate;
  JourneyEnrollmentsAggregateBoolExp: JourneyEnrollmentsAggregateBoolExp;
  JourneyEnrollmentsAggregateFields: JourneyEnrollmentsAggregateFields;
  JourneyEnrollmentsAggregateOrderBy: JourneyEnrollmentsAggregateOrderBy;
  JourneyEnrollmentsAppendInput: JourneyEnrollmentsAppendInput;
  JourneyEnrollmentsArrRelInsertInput: JourneyEnrollmentsArrRelInsertInput;
  JourneyEnrollmentsBoolExp: JourneyEnrollmentsBoolExp;
  JourneyEnrollmentsDeleteAtPathInput: JourneyEnrollmentsDeleteAtPathInput;
  JourneyEnrollmentsDeleteElemInput: JourneyEnrollmentsDeleteElemInput;
  JourneyEnrollmentsDeleteKeyInput: JourneyEnrollmentsDeleteKeyInput;
  JourneyEnrollmentsInsertInput: JourneyEnrollmentsInsertInput;
  JourneyEnrollmentsMaxFields: JourneyEnrollmentsMaxFields;
  JourneyEnrollmentsMaxOrderBy: JourneyEnrollmentsMaxOrderBy;
  JourneyEnrollmentsMinFields: JourneyEnrollmentsMinFields;
  JourneyEnrollmentsMinOrderBy: JourneyEnrollmentsMinOrderBy;
  JourneyEnrollmentsMutationResponse: JourneyEnrollmentsMutationResponse;
  JourneyEnrollmentsOnConflict: JourneyEnrollmentsOnConflict;
  JourneyEnrollmentsOrderBy: JourneyEnrollmentsOrderBy;
  JourneyEnrollmentsPkColumnsInput: JourneyEnrollmentsPkColumnsInput;
  JourneyEnrollmentsPrependInput: JourneyEnrollmentsPrependInput;
  JourneyEnrollmentsSetInput: JourneyEnrollmentsSetInput;
  JourneyEnrollmentsStreamCursorInput: JourneyEnrollmentsStreamCursorInput;
  JourneyEnrollmentsStreamCursorValueInput: JourneyEnrollmentsStreamCursorValueInput;
  JourneyEnrollmentsUpdates: JourneyEnrollmentsUpdates;
  Journeys: Journeys;
  JourneysAggregate: JourneysAggregate;
  JourneysAggregateBoolExp: JourneysAggregateBoolExp;
  JourneysAggregateFields: JourneysAggregateFields;
  JourneysAggregateOrderBy: JourneysAggregateOrderBy;
  JourneysArrRelInsertInput: JourneysArrRelInsertInput;
  JourneysBoolExp: JourneysBoolExp;
  JourneysInsertInput: JourneysInsertInput;
  JourneysMaxFields: JourneysMaxFields;
  JourneysMaxOrderBy: JourneysMaxOrderBy;
  JourneysMinFields: JourneysMinFields;
  JourneysMinOrderBy: JourneysMinOrderBy;
  JourneysMutationResponse: JourneysMutationResponse;
  JourneysObjRelInsertInput: JourneysObjRelInsertInput;
  JourneysOnConflict: JourneysOnConflict;
  JourneysOrderBy: JourneysOrderBy;
  JourneysPkColumnsInput: JourneysPkColumnsInput;
  JourneysSetInput: JourneysSetInput;
  JourneysStreamCursorInput: JourneysStreamCursorInput;
  JourneysStreamCursorValueInput: JourneysStreamCursorValueInput;
  JourneysUpdates: JourneysUpdates;
  JsonComparisonExp: JsonComparisonExp;
  JsonbCastExp: JsonbCastExp;
  JsonbComparisonExp: JsonbComparisonExp;
  Ledger: Ledger;
  LedgerAggregate: LedgerAggregate;
  LedgerAggregateFields: LedgerAggregateFields;
  LedgerAvgFields: LedgerAvgFields;
  LedgerBoolExp: LedgerBoolExp;
  LedgerIncInput: LedgerIncInput;
  LedgerInsertInput: LedgerInsertInput;
  LedgerMaxFields: LedgerMaxFields;
  LedgerMinFields: LedgerMinFields;
  LedgerMutationResponse: LedgerMutationResponse;
  LedgerOnConflict: LedgerOnConflict;
  LedgerOrderBy: LedgerOrderBy;
  LedgerPkColumnsInput: LedgerPkColumnsInput;
  LedgerProductEnum: LedgerProductEnum;
  LedgerProductEnumAggregate: LedgerProductEnumAggregate;
  LedgerProductEnumAggregateFields: LedgerProductEnumAggregateFields;
  LedgerProductEnumBoolExp: LedgerProductEnumBoolExp;
  LedgerProductEnumEnumComparisonExp: LedgerProductEnumEnumComparisonExp;
  LedgerProductEnumInsertInput: LedgerProductEnumInsertInput;
  LedgerProductEnumMaxFields: LedgerProductEnumMaxFields;
  LedgerProductEnumMinFields: LedgerProductEnumMinFields;
  LedgerProductEnumMutationResponse: LedgerProductEnumMutationResponse;
  LedgerProductEnumOnConflict: LedgerProductEnumOnConflict;
  LedgerProductEnumOrderBy: LedgerProductEnumOrderBy;
  LedgerProductEnumPkColumnsInput: LedgerProductEnumPkColumnsInput;
  LedgerProductEnumSetInput: LedgerProductEnumSetInput;
  LedgerProductEnumStreamCursorInput: LedgerProductEnumStreamCursorInput;
  LedgerProductEnumStreamCursorValueInput: LedgerProductEnumStreamCursorValueInput;
  LedgerProductEnumUpdates: LedgerProductEnumUpdates;
  LedgerSetInput: LedgerSetInput;
  LedgerStddevFields: LedgerStddevFields;
  LedgerStddevPopFields: LedgerStddevPopFields;
  LedgerStddevSampFields: LedgerStddevSampFields;
  LedgerStreamCursorInput: LedgerStreamCursorInput;
  LedgerStreamCursorValueInput: LedgerStreamCursorValueInput;
  LedgerSumFields: LedgerSumFields;
  LedgerTransactionTypeEnum: LedgerTransactionTypeEnum;
  LedgerTransactionTypeEnumAggregate: LedgerTransactionTypeEnumAggregate;
  LedgerTransactionTypeEnumAggregateFields: LedgerTransactionTypeEnumAggregateFields;
  LedgerTransactionTypeEnumBoolExp: LedgerTransactionTypeEnumBoolExp;
  LedgerTransactionTypeEnumEnumComparisonExp: LedgerTransactionTypeEnumEnumComparisonExp;
  LedgerTransactionTypeEnumInsertInput: LedgerTransactionTypeEnumInsertInput;
  LedgerTransactionTypeEnumMaxFields: LedgerTransactionTypeEnumMaxFields;
  LedgerTransactionTypeEnumMinFields: LedgerTransactionTypeEnumMinFields;
  LedgerTransactionTypeEnumMutationResponse: LedgerTransactionTypeEnumMutationResponse;
  LedgerTransactionTypeEnumOnConflict: LedgerTransactionTypeEnumOnConflict;
  LedgerTransactionTypeEnumOrderBy: LedgerTransactionTypeEnumOrderBy;
  LedgerTransactionTypeEnumPkColumnsInput: LedgerTransactionTypeEnumPkColumnsInput;
  LedgerTransactionTypeEnumSetInput: LedgerTransactionTypeEnumSetInput;
  LedgerTransactionTypeEnumStreamCursorInput: LedgerTransactionTypeEnumStreamCursorInput;
  LedgerTransactionTypeEnumStreamCursorValueInput: LedgerTransactionTypeEnumStreamCursorValueInput;
  LedgerTransactionTypeEnumUpdates: LedgerTransactionTypeEnumUpdates;
  LedgerUpdates: LedgerUpdates;
  LedgerVarPopFields: LedgerVarPopFields;
  LedgerVarSampFields: LedgerVarSampFields;
  LedgerVarianceFields: LedgerVarianceFields;
  LightningSession: LightningSession;
  LightningSessionAggregate: LightningSessionAggregate;
  LightningSessionAggregateFields: LightningSessionAggregateFields;
  LightningSessionAppendInput: LightningSessionAppendInput;
  LightningSessionBoolExp: LightningSessionBoolExp;
  LightningSessionDeleteAtPathInput: LightningSessionDeleteAtPathInput;
  LightningSessionDeleteElemInput: LightningSessionDeleteElemInput;
  LightningSessionDeleteKeyInput: LightningSessionDeleteKeyInput;
  LightningSessionInsertInput: LightningSessionInsertInput;
  LightningSessionMaxFields: LightningSessionMaxFields;
  LightningSessionMinFields: LightningSessionMinFields;
  LightningSessionMutationResponse: LightningSessionMutationResponse;
  LightningSessionObjRelInsertInput: LightningSessionObjRelInsertInput;
  LightningSessionOnConflict: LightningSessionOnConflict;
  LightningSessionOrderBy: LightningSessionOrderBy;
  LightningSessionPkColumnsInput: LightningSessionPkColumnsInput;
  LightningSessionPrependInput: LightningSessionPrependInput;
  LightningSessionSetInput: LightningSessionSetInput;
  LightningSessionStreamCursorInput: LightningSessionStreamCursorInput;
  LightningSessionStreamCursorValueInput: LightningSessionStreamCursorValueInput;
  LightningSessionSubmissions: LightningSessionSubmissions;
  LightningSessionSubmissionsAggregate: LightningSessionSubmissionsAggregate;
  LightningSessionSubmissionsAggregateBoolExp: LightningSessionSubmissionsAggregateBoolExp;
  LightningSessionSubmissionsAggregateFields: LightningSessionSubmissionsAggregateFields;
  LightningSessionSubmissionsAggregateOrderBy: LightningSessionSubmissionsAggregateOrderBy;
  LightningSessionSubmissionsArrRelInsertInput: LightningSessionSubmissionsArrRelInsertInput;
  LightningSessionSubmissionsAvgFields: LightningSessionSubmissionsAvgFields;
  LightningSessionSubmissionsAvgOrderBy: LightningSessionSubmissionsAvgOrderBy;
  LightningSessionSubmissionsBoolExp: LightningSessionSubmissionsBoolExp;
  LightningSessionSubmissionsIncInput: LightningSessionSubmissionsIncInput;
  LightningSessionSubmissionsInsertInput: LightningSessionSubmissionsInsertInput;
  LightningSessionSubmissionsMaxFields: LightningSessionSubmissionsMaxFields;
  LightningSessionSubmissionsMaxOrderBy: LightningSessionSubmissionsMaxOrderBy;
  LightningSessionSubmissionsMinFields: LightningSessionSubmissionsMinFields;
  LightningSessionSubmissionsMinOrderBy: LightningSessionSubmissionsMinOrderBy;
  LightningSessionSubmissionsMutationResponse: LightningSessionSubmissionsMutationResponse;
  LightningSessionSubmissionsObjRelInsertInput: LightningSessionSubmissionsObjRelInsertInput;
  LightningSessionSubmissionsOnConflict: LightningSessionSubmissionsOnConflict;
  LightningSessionSubmissionsOrderBy: LightningSessionSubmissionsOrderBy;
  LightningSessionSubmissionsPkColumnsInput: LightningSessionSubmissionsPkColumnsInput;
  LightningSessionSubmissionsSetInput: LightningSessionSubmissionsSetInput;
  LightningSessionSubmissionsStatusEnumComparisonExp: LightningSessionSubmissionsStatusEnumComparisonExp;
  LightningSessionSubmissionsStddevFields: LightningSessionSubmissionsStddevFields;
  LightningSessionSubmissionsStddevOrderBy: LightningSessionSubmissionsStddevOrderBy;
  LightningSessionSubmissionsStddevPopFields: LightningSessionSubmissionsStddevPopFields;
  LightningSessionSubmissionsStddevPopOrderBy: LightningSessionSubmissionsStddevPopOrderBy;
  LightningSessionSubmissionsStddevSampFields: LightningSessionSubmissionsStddevSampFields;
  LightningSessionSubmissionsStddevSampOrderBy: LightningSessionSubmissionsStddevSampOrderBy;
  LightningSessionSubmissionsStreamCursorInput: LightningSessionSubmissionsStreamCursorInput;
  LightningSessionSubmissionsStreamCursorValueInput: LightningSessionSubmissionsStreamCursorValueInput;
  LightningSessionSubmissionsSumFields: LightningSessionSubmissionsSumFields;
  LightningSessionSubmissionsSumOrderBy: LightningSessionSubmissionsSumOrderBy;
  LightningSessionSubmissionsUpdates: LightningSessionSubmissionsUpdates;
  LightningSessionSubmissionsUsersRel: LightningSessionSubmissionsUsersRel;
  LightningSessionSubmissionsUsersRelAggregate: LightningSessionSubmissionsUsersRelAggregate;
  LightningSessionSubmissionsUsersRelAggregateBoolExp: LightningSessionSubmissionsUsersRelAggregateBoolExp;
  LightningSessionSubmissionsUsersRelAggregateFields: LightningSessionSubmissionsUsersRelAggregateFields;
  LightningSessionSubmissionsUsersRelAggregateOrderBy: LightningSessionSubmissionsUsersRelAggregateOrderBy;
  LightningSessionSubmissionsUsersRelArrRelInsertInput: LightningSessionSubmissionsUsersRelArrRelInsertInput;
  LightningSessionSubmissionsUsersRelBoolExp: LightningSessionSubmissionsUsersRelBoolExp;
  LightningSessionSubmissionsUsersRelInsertInput: LightningSessionSubmissionsUsersRelInsertInput;
  LightningSessionSubmissionsUsersRelMaxFields: LightningSessionSubmissionsUsersRelMaxFields;
  LightningSessionSubmissionsUsersRelMaxOrderBy: LightningSessionSubmissionsUsersRelMaxOrderBy;
  LightningSessionSubmissionsUsersRelMinFields: LightningSessionSubmissionsUsersRelMinFields;
  LightningSessionSubmissionsUsersRelMinOrderBy: LightningSessionSubmissionsUsersRelMinOrderBy;
  LightningSessionSubmissionsUsersRelMutationResponse: LightningSessionSubmissionsUsersRelMutationResponse;
  LightningSessionSubmissionsUsersRelOnConflict: LightningSessionSubmissionsUsersRelOnConflict;
  LightningSessionSubmissionsUsersRelOrderBy: LightningSessionSubmissionsUsersRelOrderBy;
  LightningSessionSubmissionsUsersRelPkColumnsInput: LightningSessionSubmissionsUsersRelPkColumnsInput;
  LightningSessionSubmissionsUsersRelSetInput: LightningSessionSubmissionsUsersRelSetInput;
  LightningSessionSubmissionsUsersRelStreamCursorInput: LightningSessionSubmissionsUsersRelStreamCursorInput;
  LightningSessionSubmissionsUsersRelStreamCursorValueInput: LightningSessionSubmissionsUsersRelStreamCursorValueInput;
  LightningSessionSubmissionsUsersRelUpdates: LightningSessionSubmissionsUsersRelUpdates;
  LightningSessionSubmissionsVarPopFields: LightningSessionSubmissionsVarPopFields;
  LightningSessionSubmissionsVarPopOrderBy: LightningSessionSubmissionsVarPopOrderBy;
  LightningSessionSubmissionsVarSampFields: LightningSessionSubmissionsVarSampFields;
  LightningSessionSubmissionsVarSampOrderBy: LightningSessionSubmissionsVarSampOrderBy;
  LightningSessionSubmissionsVarianceFields: LightningSessionSubmissionsVarianceFields;
  LightningSessionSubmissionsVarianceOrderBy: LightningSessionSubmissionsVarianceOrderBy;
  LightningSessionUpdates: LightningSessionUpdates;
  LiveClasses: LiveClasses;
  LiveClassesAggregate: LiveClassesAggregate;
  LiveClassesAggregateFields: LiveClassesAggregateFields;
  LiveClassesAvgFields: LiveClassesAvgFields;
  LiveClassesBoolExp: LiveClassesBoolExp;
  LiveClassesMaxFields: LiveClassesMaxFields;
  LiveClassesMinFields: LiveClassesMinFields;
  LiveClassesOrderBy: LiveClassesOrderBy;
  LiveClassesStddevFields: LiveClassesStddevFields;
  LiveClassesStddevPopFields: LiveClassesStddevPopFields;
  LiveClassesStddevSampFields: LiveClassesStddevSampFields;
  LiveClassesStreamCursorInput: LiveClassesStreamCursorInput;
  LiveClassesStreamCursorValueInput: LiveClassesStreamCursorValueInput;
  LiveClassesSumFields: LiveClassesSumFields;
  LiveClassesVarPopFields: LiveClassesVarPopFields;
  LiveClassesVarSampFields: LiveClassesVarSampFields;
  LiveClassesVarianceFields: LiveClassesVarianceFields;
  ManagerSessionEmails: ManagerSessionEmails;
  ManagerSessionEmailsAggregate: ManagerSessionEmailsAggregate;
  ManagerSessionEmailsAggregateFields: ManagerSessionEmailsAggregateFields;
  ManagerSessionEmailsAvgFields: ManagerSessionEmailsAvgFields;
  ManagerSessionEmailsBoolExp: ManagerSessionEmailsBoolExp;
  ManagerSessionEmailsIncInput: ManagerSessionEmailsIncInput;
  ManagerSessionEmailsInsertInput: ManagerSessionEmailsInsertInput;
  ManagerSessionEmailsMaxFields: ManagerSessionEmailsMaxFields;
  ManagerSessionEmailsMinFields: ManagerSessionEmailsMinFields;
  ManagerSessionEmailsMutationResponse: ManagerSessionEmailsMutationResponse;
  ManagerSessionEmailsOnConflict: ManagerSessionEmailsOnConflict;
  ManagerSessionEmailsOrderBy: ManagerSessionEmailsOrderBy;
  ManagerSessionEmailsPkColumnsInput: ManagerSessionEmailsPkColumnsInput;
  ManagerSessionEmailsSetInput: ManagerSessionEmailsSetInput;
  ManagerSessionEmailsStddevFields: ManagerSessionEmailsStddevFields;
  ManagerSessionEmailsStddevPopFields: ManagerSessionEmailsStddevPopFields;
  ManagerSessionEmailsStddevSampFields: ManagerSessionEmailsStddevSampFields;
  ManagerSessionEmailsStreamCursorInput: ManagerSessionEmailsStreamCursorInput;
  ManagerSessionEmailsStreamCursorValueInput: ManagerSessionEmailsStreamCursorValueInput;
  ManagerSessionEmailsSumFields: ManagerSessionEmailsSumFields;
  ManagerSessionEmailsUpdates: ManagerSessionEmailsUpdates;
  ManagerSessionEmailsVarPopFields: ManagerSessionEmailsVarPopFields;
  ManagerSessionEmailsVarSampFields: ManagerSessionEmailsVarSampFields;
  ManagerSessionEmailsVarianceFields: ManagerSessionEmailsVarianceFields;
  MarkRecordingWatchedOutput: MarkRecordingWatchedOutput;
  MyCohortLearningStatus: MyCohortLearningStatus;
  MyCohortLearningStatusAggregate: MyCohortLearningStatusAggregate;
  MyCohortLearningStatusAggregateFields: MyCohortLearningStatusAggregateFields;
  MyCohortLearningStatusAvgFields: MyCohortLearningStatusAvgFields;
  MyCohortLearningStatusBoolExp: MyCohortLearningStatusBoolExp;
  MyCohortLearningStatusMaxFields: MyCohortLearningStatusMaxFields;
  MyCohortLearningStatusMinFields: MyCohortLearningStatusMinFields;
  MyCohortLearningStatusOrderBy: MyCohortLearningStatusOrderBy;
  MyCohortLearningStatusStddevFields: MyCohortLearningStatusStddevFields;
  MyCohortLearningStatusStddevPopFields: MyCohortLearningStatusStddevPopFields;
  MyCohortLearningStatusStddevSampFields: MyCohortLearningStatusStddevSampFields;
  MyCohortLearningStatusStreamCursorInput: MyCohortLearningStatusStreamCursorInput;
  MyCohortLearningStatusStreamCursorValueInput: MyCohortLearningStatusStreamCursorValueInput;
  MyCohortLearningStatusSumFields: MyCohortLearningStatusSumFields;
  MyCohortLearningStatusVarPopFields: MyCohortLearningStatusVarPopFields;
  MyCohortLearningStatusVarSampFields: MyCohortLearningStatusVarSampFields;
  MyCohortLearningStatusVarianceFields: MyCohortLearningStatusVarianceFields;
  MyLearnerHomeCourses: MyLearnerHomeCourses;
  MyLearnerHomeCoursesAggregate: MyLearnerHomeCoursesAggregate;
  MyLearnerHomeCoursesAggregateFields: MyLearnerHomeCoursesAggregateFields;
  MyLearnerHomeCoursesAvgFields: MyLearnerHomeCoursesAvgFields;
  MyLearnerHomeCoursesBoolExp: MyLearnerHomeCoursesBoolExp;
  MyLearnerHomeCoursesMaxFields: MyLearnerHomeCoursesMaxFields;
  MyLearnerHomeCoursesMinFields: MyLearnerHomeCoursesMinFields;
  MyLearnerHomeCoursesOrderBy: MyLearnerHomeCoursesOrderBy;
  MyLearnerHomeCoursesStddevFields: MyLearnerHomeCoursesStddevFields;
  MyLearnerHomeCoursesStddevPopFields: MyLearnerHomeCoursesStddevPopFields;
  MyLearnerHomeCoursesStddevSampFields: MyLearnerHomeCoursesStddevSampFields;
  MyLearnerHomeCoursesStreamCursorInput: MyLearnerHomeCoursesStreamCursorInput;
  MyLearnerHomeCoursesStreamCursorValueInput: MyLearnerHomeCoursesStreamCursorValueInput;
  MyLearnerHomeCoursesSumFields: MyLearnerHomeCoursesSumFields;
  MyLearnerHomeCoursesVarPopFields: MyLearnerHomeCoursesVarPopFields;
  MyLearnerHomeCoursesVarSampFields: MyLearnerHomeCoursesVarSampFields;
  MyLearnerHomeCoursesVarianceFields: MyLearnerHomeCoursesVarianceFields;
  MyLearnerHomeLiveCoaching: MyLearnerHomeLiveCoaching;
  MyLearnerHomeLiveCoachingAggregate: MyLearnerHomeLiveCoachingAggregate;
  MyLearnerHomeLiveCoachingAggregateFields: MyLearnerHomeLiveCoachingAggregateFields;
  MyLearnerHomeLiveCoachingAvgFields: MyLearnerHomeLiveCoachingAvgFields;
  MyLearnerHomeLiveCoachingBoolExp: MyLearnerHomeLiveCoachingBoolExp;
  MyLearnerHomeLiveCoachingMaxFields: MyLearnerHomeLiveCoachingMaxFields;
  MyLearnerHomeLiveCoachingMinFields: MyLearnerHomeLiveCoachingMinFields;
  MyLearnerHomeLiveCoachingOrderBy: MyLearnerHomeLiveCoachingOrderBy;
  MyLearnerHomeLiveCoachingStddevFields: MyLearnerHomeLiveCoachingStddevFields;
  MyLearnerHomeLiveCoachingStddevPopFields: MyLearnerHomeLiveCoachingStddevPopFields;
  MyLearnerHomeLiveCoachingStddevSampFields: MyLearnerHomeLiveCoachingStddevSampFields;
  MyLearnerHomeLiveCoachingStreamCursorInput: MyLearnerHomeLiveCoachingStreamCursorInput;
  MyLearnerHomeLiveCoachingStreamCursorValueInput: MyLearnerHomeLiveCoachingStreamCursorValueInput;
  MyLearnerHomeLiveCoachingSumFields: MyLearnerHomeLiveCoachingSumFields;
  MyLearnerHomeLiveCoachingVarPopFields: MyLearnerHomeLiveCoachingVarPopFields;
  MyLearnerHomeLiveCoachingVarSampFields: MyLearnerHomeLiveCoachingVarSampFields;
  MyLearnerHomeLiveCoachingVarianceFields: MyLearnerHomeLiveCoachingVarianceFields;
  MyLightningSessionCohortsLearningStatus: MyLightningSessionCohortsLearningStatus;
  MyLightningSessionCohortsLearningStatusAggregate: MyLightningSessionCohortsLearningStatusAggregate;
  MyLightningSessionCohortsLearningStatusAggregateFields: MyLightningSessionCohortsLearningStatusAggregateFields;
  MyLightningSessionCohortsLearningStatusAvgFields: MyLightningSessionCohortsLearningStatusAvgFields;
  MyLightningSessionCohortsLearningStatusBoolExp: MyLightningSessionCohortsLearningStatusBoolExp;
  MyLightningSessionCohortsLearningStatusMaxFields: MyLightningSessionCohortsLearningStatusMaxFields;
  MyLightningSessionCohortsLearningStatusMinFields: MyLightningSessionCohortsLearningStatusMinFields;
  MyLightningSessionCohortsLearningStatusOrderBy: MyLightningSessionCohortsLearningStatusOrderBy;
  MyLightningSessionCohortsLearningStatusStddevFields: MyLightningSessionCohortsLearningStatusStddevFields;
  MyLightningSessionCohortsLearningStatusStddevPopFields: MyLightningSessionCohortsLearningStatusStddevPopFields;
  MyLightningSessionCohortsLearningStatusStddevSampFields: MyLightningSessionCohortsLearningStatusStddevSampFields;
  MyLightningSessionCohortsLearningStatusStreamCursorInput: MyLightningSessionCohortsLearningStatusStreamCursorInput;
  MyLightningSessionCohortsLearningStatusStreamCursorValueInput: MyLightningSessionCohortsLearningStatusStreamCursorValueInput;
  MyLightningSessionCohortsLearningStatusSumFields: MyLightningSessionCohortsLearningStatusSumFields;
  MyLightningSessionCohortsLearningStatusVarPopFields: MyLightningSessionCohortsLearningStatusVarPopFields;
  MyLightningSessionCohortsLearningStatusVarSampFields: MyLightningSessionCohortsLearningStatusVarSampFields;
  MyLightningSessionCohortsLearningStatusVarianceFields: MyLightningSessionCohortsLearningStatusVarianceFields;
  MyTeamMember: MyTeamMember;
  MyTeamMembersOutput: MyTeamMembersOutput;
  MyTraditionalCohortsLearningStatus: MyTraditionalCohortsLearningStatus;
  MyTraditionalCohortsLearningStatusAggregate: MyTraditionalCohortsLearningStatusAggregate;
  MyTraditionalCohortsLearningStatusAggregateFields: MyTraditionalCohortsLearningStatusAggregateFields;
  MyTraditionalCohortsLearningStatusAvgFields: MyTraditionalCohortsLearningStatusAvgFields;
  MyTraditionalCohortsLearningStatusBoolExp: MyTraditionalCohortsLearningStatusBoolExp;
  MyTraditionalCohortsLearningStatusMaxFields: MyTraditionalCohortsLearningStatusMaxFields;
  MyTraditionalCohortsLearningStatusMinFields: MyTraditionalCohortsLearningStatusMinFields;
  MyTraditionalCohortsLearningStatusOrderBy: MyTraditionalCohortsLearningStatusOrderBy;
  MyTraditionalCohortsLearningStatusStddevFields: MyTraditionalCohortsLearningStatusStddevFields;
  MyTraditionalCohortsLearningStatusStddevPopFields: MyTraditionalCohortsLearningStatusStddevPopFields;
  MyTraditionalCohortsLearningStatusStddevSampFields: MyTraditionalCohortsLearningStatusStddevSampFields;
  MyTraditionalCohortsLearningStatusStreamCursorInput: MyTraditionalCohortsLearningStatusStreamCursorInput;
  MyTraditionalCohortsLearningStatusStreamCursorValueInput: MyTraditionalCohortsLearningStatusStreamCursorValueInput;
  MyTraditionalCohortsLearningStatusSumFields: MyTraditionalCohortsLearningStatusSumFields;
  MyTraditionalCohortsLearningStatusVarPopFields: MyTraditionalCohortsLearningStatusVarPopFields;
  MyTraditionalCohortsLearningStatusVarSampFields: MyTraditionalCohortsLearningStatusVarSampFields;
  MyTraditionalCohortsLearningStatusVarianceFields: MyTraditionalCohortsLearningStatusVarianceFields;
  NonceAccessToken: NonceAccessToken;
  NonceAccessTokenAggregate: NonceAccessTokenAggregate;
  NonceAccessTokenAggregateFields: NonceAccessTokenAggregateFields;
  NonceAccessTokenBoolExp: NonceAccessTokenBoolExp;
  NonceAccessTokenInsertInput: NonceAccessTokenInsertInput;
  NonceAccessTokenMaxFields: NonceAccessTokenMaxFields;
  NonceAccessTokenMinFields: NonceAccessTokenMinFields;
  NonceAccessTokenMutationResponse: NonceAccessTokenMutationResponse;
  NonceAccessTokenOnConflict: NonceAccessTokenOnConflict;
  NonceAccessTokenOrderBy: NonceAccessTokenOrderBy;
  NonceAccessTokenPkColumnsInput: NonceAccessTokenPkColumnsInput;
  NonceAccessTokenSetInput: NonceAccessTokenSetInput;
  NonceAccessTokenStreamCursorInput: NonceAccessTokenStreamCursorInput;
  NonceAccessTokenStreamCursorValueInput: NonceAccessTokenStreamCursorValueInput;
  NonceAccessTokenUpdates: NonceAccessTokenUpdates;
  NumericComparisonExp: NumericComparisonExp;
  OrganizationUsers: OrganizationUsers;
  OrganizationUsersAggregate: OrganizationUsersAggregate;
  OrganizationUsersAggregateBoolExp: OrganizationUsersAggregateBoolExp;
  OrganizationUsersAggregateBoolExpCount: OrganizationUsersAggregateBoolExpCount;
  OrganizationUsersAggregateFields: OrganizationUsersAggregateFields;
  OrganizationUsersAggregateOrderBy: OrganizationUsersAggregateOrderBy;
  OrganizationUsersArrRelInsertInput: OrganizationUsersArrRelInsertInput;
  OrganizationUsersAvgFields: OrganizationUsersAvgFields;
  OrganizationUsersAvgOrderBy: OrganizationUsersAvgOrderBy;
  OrganizationUsersBoolExp: OrganizationUsersBoolExp;
  OrganizationUsersIncInput: OrganizationUsersIncInput;
  OrganizationUsersInsertInput: OrganizationUsersInsertInput;
  OrganizationUsersMaxFields: OrganizationUsersMaxFields;
  OrganizationUsersMaxOrderBy: OrganizationUsersMaxOrderBy;
  OrganizationUsersMinFields: OrganizationUsersMinFields;
  OrganizationUsersMinOrderBy: OrganizationUsersMinOrderBy;
  OrganizationUsersMutationResponse: OrganizationUsersMutationResponse;
  OrganizationUsersOnConflict: OrganizationUsersOnConflict;
  OrganizationUsersOrderBy: OrganizationUsersOrderBy;
  OrganizationUsersPkColumnsInput: OrganizationUsersPkColumnsInput;
  OrganizationUsersSetInput: OrganizationUsersSetInput;
  OrganizationUsersStddevFields: OrganizationUsersStddevFields;
  OrganizationUsersStddevOrderBy: OrganizationUsersStddevOrderBy;
  OrganizationUsersStddevPopFields: OrganizationUsersStddevPopFields;
  OrganizationUsersStddevPopOrderBy: OrganizationUsersStddevPopOrderBy;
  OrganizationUsersStddevSampFields: OrganizationUsersStddevSampFields;
  OrganizationUsersStddevSampOrderBy: OrganizationUsersStddevSampOrderBy;
  OrganizationUsersStreamCursorInput: OrganizationUsersStreamCursorInput;
  OrganizationUsersStreamCursorValueInput: OrganizationUsersStreamCursorValueInput;
  OrganizationUsersSumFields: OrganizationUsersSumFields;
  OrganizationUsersSumOrderBy: OrganizationUsersSumOrderBy;
  OrganizationUsersUpdates: OrganizationUsersUpdates;
  OrganizationUsersVarPopFields: OrganizationUsersVarPopFields;
  OrganizationUsersVarPopOrderBy: OrganizationUsersVarPopOrderBy;
  OrganizationUsersVarSampFields: OrganizationUsersVarSampFields;
  OrganizationUsersVarSampOrderBy: OrganizationUsersVarSampOrderBy;
  OrganizationUsersVarianceFields: OrganizationUsersVarianceFields;
  OrganizationUsersVarianceOrderBy: OrganizationUsersVarianceOrderBy;
  Organizations: Organizations;
  OrganizationsAggregate: OrganizationsAggregate;
  OrganizationsAggregateFields: OrganizationsAggregateFields;
  OrganizationsAvgFields: OrganizationsAvgFields;
  OrganizationsBoolExp: OrganizationsBoolExp;
  OrganizationsIncInput: OrganizationsIncInput;
  OrganizationsInsertInput: OrganizationsInsertInput;
  OrganizationsMaxFields: OrganizationsMaxFields;
  OrganizationsMeta: OrganizationsMeta;
  OrganizationsMetaAggregate: OrganizationsMetaAggregate;
  OrganizationsMetaAggregateFields: OrganizationsMetaAggregateFields;
  OrganizationsMetaBoolExp: OrganizationsMetaBoolExp;
  OrganizationsMetaInsertInput: OrganizationsMetaInsertInput;
  OrganizationsMetaMaxFields: OrganizationsMetaMaxFields;
  OrganizationsMetaMinFields: OrganizationsMetaMinFields;
  OrganizationsMetaMutationResponse: OrganizationsMetaMutationResponse;
  OrganizationsMetaObjRelInsertInput: OrganizationsMetaObjRelInsertInput;
  OrganizationsMetaOnConflict: OrganizationsMetaOnConflict;
  OrganizationsMetaOrderBy: OrganizationsMetaOrderBy;
  OrganizationsMetaPkColumnsInput: OrganizationsMetaPkColumnsInput;
  OrganizationsMetaSetInput: OrganizationsMetaSetInput;
  OrganizationsMetaStreamCursorInput: OrganizationsMetaStreamCursorInput;
  OrganizationsMetaStreamCursorValueInput: OrganizationsMetaStreamCursorValueInput;
  OrganizationsMetaUpdates: OrganizationsMetaUpdates;
  OrganizationsMinFields: OrganizationsMinFields;
  OrganizationsMutationResponse: OrganizationsMutationResponse;
  OrganizationsObjRelInsertInput: OrganizationsObjRelInsertInput;
  OrganizationsOnConflict: OrganizationsOnConflict;
  OrganizationsOrderBy: OrganizationsOrderBy;
  OrganizationsPkColumnsInput: OrganizationsPkColumnsInput;
  OrganizationsSetInput: OrganizationsSetInput;
  OrganizationsStddevFields: OrganizationsStddevFields;
  OrganizationsStddevPopFields: OrganizationsStddevPopFields;
  OrganizationsStddevSampFields: OrganizationsStddevSampFields;
  OrganizationsStreamCursorInput: OrganizationsStreamCursorInput;
  OrganizationsStreamCursorValueInput: OrganizationsStreamCursorValueInput;
  OrganizationsSumFields: OrganizationsSumFields;
  OrganizationsUpdates: OrganizationsUpdates;
  OrganizationsVarPopFields: OrganizationsVarPopFields;
  OrganizationsVarSampFields: OrganizationsVarSampFields;
  OrganizationsVarianceFields: OrganizationsVarianceFields;
  ParticipantInput: ParticipantInput;
  PlanSubscriptions: PlanSubscriptions;
  PlanSubscriptionsAggregate: PlanSubscriptionsAggregate;
  PlanSubscriptionsAggregateBoolExp: PlanSubscriptionsAggregateBoolExp;
  PlanSubscriptionsAggregateFields: PlanSubscriptionsAggregateFields;
  PlanSubscriptionsAggregateOrderBy: PlanSubscriptionsAggregateOrderBy;
  PlanSubscriptionsArrRelInsertInput: PlanSubscriptionsArrRelInsertInput;
  PlanSubscriptionsBoolExp: PlanSubscriptionsBoolExp;
  PlanSubscriptionsInsertInput: PlanSubscriptionsInsertInput;
  PlanSubscriptionsMaxFields: PlanSubscriptionsMaxFields;
  PlanSubscriptionsMaxOrderBy: PlanSubscriptionsMaxOrderBy;
  PlanSubscriptionsMinFields: PlanSubscriptionsMinFields;
  PlanSubscriptionsMinOrderBy: PlanSubscriptionsMinOrderBy;
  PlanSubscriptionsMutationResponse: PlanSubscriptionsMutationResponse;
  PlanSubscriptionsOnConflict: PlanSubscriptionsOnConflict;
  PlanSubscriptionsOrderBy: PlanSubscriptionsOrderBy;
  PlanSubscriptionsPkColumnsInput: PlanSubscriptionsPkColumnsInput;
  PlanSubscriptionsSetInput: PlanSubscriptionsSetInput;
  PlanSubscriptionsStreamCursorInput: PlanSubscriptionsStreamCursorInput;
  PlanSubscriptionsStreamCursorValueInput: PlanSubscriptionsStreamCursorValueInput;
  PlanSubscriptionsUpdates: PlanSubscriptionsUpdates;
  Plans: Plans;
  PlansAggregate: PlansAggregate;
  PlansAggregateFields: PlansAggregateFields;
  PlansAggregateOrderBy: PlansAggregateOrderBy;
  PlansAppendInput: PlansAppendInput;
  PlansBoolExp: PlansBoolExp;
  PlansDeleteAtPathInput: PlansDeleteAtPathInput;
  PlansDeleteElemInput: PlansDeleteElemInput;
  PlansDeleteKeyInput: PlansDeleteKeyInput;
  PlansInsertInput: PlansInsertInput;
  PlansMaxFields: PlansMaxFields;
  PlansMaxOrderBy: PlansMaxOrderBy;
  PlansMinFields: PlansMinFields;
  PlansMinOrderBy: PlansMinOrderBy;
  PlansMutationResponse: PlansMutationResponse;
  PlansObjRelInsertInput: PlansObjRelInsertInput;
  PlansOnConflict: PlansOnConflict;
  PlansOrderBy: PlansOrderBy;
  PlansPkColumnsInput: PlansPkColumnsInput;
  PlansPrependInput: PlansPrependInput;
  PlansSetInput: PlansSetInput;
  PlansStreamCursorInput: PlansStreamCursorInput;
  PlansStreamCursorValueInput: PlansStreamCursorValueInput;
  PlansUpdates: PlansUpdates;
  PlayExecution: PlayExecution;
  PlayExecutionAggregate: PlayExecutionAggregate;
  PlayExecutionAggregateFields: PlayExecutionAggregateFields;
  PlayExecutionAppendInput: PlayExecutionAppendInput;
  PlayExecutionBoolExp: PlayExecutionBoolExp;
  PlayExecutionDeleteAtPathInput: PlayExecutionDeleteAtPathInput;
  PlayExecutionDeleteElemInput: PlayExecutionDeleteElemInput;
  PlayExecutionDeleteKeyInput: PlayExecutionDeleteKeyInput;
  PlayExecutionInsertInput: PlayExecutionInsertInput;
  PlayExecutionMaxFields: PlayExecutionMaxFields;
  PlayExecutionMinFields: PlayExecutionMinFields;
  PlayExecutionMutationResponse: PlayExecutionMutationResponse;
  PlayExecutionOnConflict: PlayExecutionOnConflict;
  PlayExecutionOrderBy: PlayExecutionOrderBy;
  PlayExecutionPkColumnsInput: PlayExecutionPkColumnsInput;
  PlayExecutionPrependInput: PlayExecutionPrependInput;
  PlayExecutionSetInput: PlayExecutionSetInput;
  PlayExecutionStreamCursorInput: PlayExecutionStreamCursorInput;
  PlayExecutionStreamCursorValueInput: PlayExecutionStreamCursorValueInput;
  PlayExecutionUpdates: PlayExecutionUpdates;
  PlaybackTimes: PlaybackTimes;
  ProgramActionsTaken: ProgramActionsTaken;
  ProgramActionsTakenAggregate: ProgramActionsTakenAggregate;
  ProgramActionsTakenAggregateBoolExp: ProgramActionsTakenAggregateBoolExp;
  ProgramActionsTakenAggregateFields: ProgramActionsTakenAggregateFields;
  ProgramActionsTakenAggregateOrderBy: ProgramActionsTakenAggregateOrderBy;
  ProgramActionsTakenArrRelInsertInput: ProgramActionsTakenArrRelInsertInput;
  ProgramActionsTakenBoolExp: ProgramActionsTakenBoolExp;
  ProgramActionsTakenInsertInput: ProgramActionsTakenInsertInput;
  ProgramActionsTakenMaxFields: ProgramActionsTakenMaxFields;
  ProgramActionsTakenMaxOrderBy: ProgramActionsTakenMaxOrderBy;
  ProgramActionsTakenMinFields: ProgramActionsTakenMinFields;
  ProgramActionsTakenMinOrderBy: ProgramActionsTakenMinOrderBy;
  ProgramActionsTakenMutationResponse: ProgramActionsTakenMutationResponse;
  ProgramActionsTakenOrderBy: ProgramActionsTakenOrderBy;
  ProgramActionsTakenSetInput: ProgramActionsTakenSetInput;
  ProgramActionsTakenStreamCursorInput: ProgramActionsTakenStreamCursorInput;
  ProgramActionsTakenStreamCursorValueInput: ProgramActionsTakenStreamCursorValueInput;
  ProgramActionsTakenUpdates: ProgramActionsTakenUpdates;
  ProgramCoursesShown: ProgramCoursesShown;
  ProgramCoursesShownAggregate: ProgramCoursesShownAggregate;
  ProgramCoursesShownAggregateBoolExp: ProgramCoursesShownAggregateBoolExp;
  ProgramCoursesShownAggregateFields: ProgramCoursesShownAggregateFields;
  ProgramCoursesShownAggregateOrderBy: ProgramCoursesShownAggregateOrderBy;
  ProgramCoursesShownArrRelInsertInput: ProgramCoursesShownArrRelInsertInput;
  ProgramCoursesShownBoolExp: ProgramCoursesShownBoolExp;
  ProgramCoursesShownInsertInput: ProgramCoursesShownInsertInput;
  ProgramCoursesShownMaxFields: ProgramCoursesShownMaxFields;
  ProgramCoursesShownMaxOrderBy: ProgramCoursesShownMaxOrderBy;
  ProgramCoursesShownMinFields: ProgramCoursesShownMinFields;
  ProgramCoursesShownMinOrderBy: ProgramCoursesShownMinOrderBy;
  ProgramCoursesShownMutationResponse: ProgramCoursesShownMutationResponse;
  ProgramCoursesShownOnConflict: ProgramCoursesShownOnConflict;
  ProgramCoursesShownOrderBy: ProgramCoursesShownOrderBy;
  ProgramCoursesShownPkColumnsInput: ProgramCoursesShownPkColumnsInput;
  ProgramCoursesShownSetInput: ProgramCoursesShownSetInput;
  ProgramCoursesShownStreamCursorInput: ProgramCoursesShownStreamCursorInput;
  ProgramCoursesShownStreamCursorValueInput: ProgramCoursesShownStreamCursorValueInput;
  ProgramCoursesShownUpdates: ProgramCoursesShownUpdates;
  ProgramTags: ProgramTags;
  ProgramTagsAggregate: ProgramTagsAggregate;
  ProgramTagsAggregateBoolExp: ProgramTagsAggregateBoolExp;
  ProgramTagsAggregateFields: ProgramTagsAggregateFields;
  ProgramTagsAggregateOrderBy: ProgramTagsAggregateOrderBy;
  ProgramTagsArrRelInsertInput: ProgramTagsArrRelInsertInput;
  ProgramTagsBoolExp: ProgramTagsBoolExp;
  ProgramTagsInsertInput: ProgramTagsInsertInput;
  ProgramTagsMaxFields: ProgramTagsMaxFields;
  ProgramTagsMaxOrderBy: ProgramTagsMaxOrderBy;
  ProgramTagsMinFields: ProgramTagsMinFields;
  ProgramTagsMinOrderBy: ProgramTagsMinOrderBy;
  ProgramTagsMutationResponse: ProgramTagsMutationResponse;
  ProgramTagsOnConflict: ProgramTagsOnConflict;
  ProgramTagsOrderBy: ProgramTagsOrderBy;
  ProgramTagsPkColumnsInput: ProgramTagsPkColumnsInput;
  ProgramTagsSetInput: ProgramTagsSetInput;
  ProgramTagsStreamCursorInput: ProgramTagsStreamCursorInput;
  ProgramTagsStreamCursorValueInput: ProgramTagsStreamCursorValueInput;
  ProgramTagsUpdates: ProgramTagsUpdates;
  ProgramUsersSelected: ProgramUsersSelected;
  ProgramUsersSelectedAggregate: ProgramUsersSelectedAggregate;
  ProgramUsersSelectedAggregateBoolExp: ProgramUsersSelectedAggregateBoolExp;
  ProgramUsersSelectedAggregateFields: ProgramUsersSelectedAggregateFields;
  ProgramUsersSelectedAggregateOrderBy: ProgramUsersSelectedAggregateOrderBy;
  ProgramUsersSelectedArrRelInsertInput: ProgramUsersSelectedArrRelInsertInput;
  ProgramUsersSelectedBoolExp: ProgramUsersSelectedBoolExp;
  ProgramUsersSelectedInsertInput: ProgramUsersSelectedInsertInput;
  ProgramUsersSelectedMaxFields: ProgramUsersSelectedMaxFields;
  ProgramUsersSelectedMaxOrderBy: ProgramUsersSelectedMaxOrderBy;
  ProgramUsersSelectedMinFields: ProgramUsersSelectedMinFields;
  ProgramUsersSelectedMinOrderBy: ProgramUsersSelectedMinOrderBy;
  ProgramUsersSelectedMutationResponse: ProgramUsersSelectedMutationResponse;
  ProgramUsersSelectedOnConflict: ProgramUsersSelectedOnConflict;
  ProgramUsersSelectedOrderBy: ProgramUsersSelectedOrderBy;
  ProgramUsersSelectedPkColumnsInput: ProgramUsersSelectedPkColumnsInput;
  ProgramUsersSelectedSetInput: ProgramUsersSelectedSetInput;
  ProgramUsersSelectedStreamCursorInput: ProgramUsersSelectedStreamCursorInput;
  ProgramUsersSelectedStreamCursorValueInput: ProgramUsersSelectedStreamCursorValueInput;
  ProgramUsersSelectedUpdates: ProgramUsersSelectedUpdates;
  Programs: Programs;
  ProgramsAggregate: ProgramsAggregate;
  ProgramsAggregateFields: ProgramsAggregateFields;
  ProgramsBoolExp: ProgramsBoolExp;
  ProgramsInsertInput: ProgramsInsertInput;
  ProgramsMaxFields: ProgramsMaxFields;
  ProgramsMinFields: ProgramsMinFields;
  ProgramsMutationResponse: ProgramsMutationResponse;
  ProgramsOnConflict: ProgramsOnConflict;
  ProgramsOrderBy: ProgramsOrderBy;
  ProgramsPkColumnsInput: ProgramsPkColumnsInput;
  ProgramsSetInput: ProgramsSetInput;
  ProgramsStreamCursorInput: ProgramsStreamCursorInput;
  ProgramsStreamCursorValueInput: ProgramsStreamCursorValueInput;
  ProgramsUpdates: ProgramsUpdates;
  QuestionType: QuestionType;
  QuestionTypeAggregate: QuestionTypeAggregate;
  QuestionTypeAggregateFields: QuestionTypeAggregateFields;
  QuestionTypeBoolExp: QuestionTypeBoolExp;
  QuestionTypeEnumComparisonExp: QuestionTypeEnumComparisonExp;
  QuestionTypeInsertInput: QuestionTypeInsertInput;
  QuestionTypeMaxFields: QuestionTypeMaxFields;
  QuestionTypeMinFields: QuestionTypeMinFields;
  QuestionTypeMutationResponse: QuestionTypeMutationResponse;
  QuestionTypeObjRelInsertInput: QuestionTypeObjRelInsertInput;
  QuestionTypeOnConflict: QuestionTypeOnConflict;
  QuestionTypeOrderBy: QuestionTypeOrderBy;
  QuestionTypePkColumnsInput: QuestionTypePkColumnsInput;
  QuestionTypeSetInput: QuestionTypeSetInput;
  QuestionTypeStreamCursorInput: QuestionTypeStreamCursorInput;
  QuestionTypeStreamCursorValueInput: QuestionTypeStreamCursorValueInput;
  QuestionTypeUpdates: QuestionTypeUpdates;
  RecordingActivity: RecordingActivity;
  RecordingActivityAggregate: RecordingActivityAggregate;
  RecordingActivityAggregateFields: RecordingActivityAggregateFields;
  RecordingActivityAppendInput: RecordingActivityAppendInput;
  RecordingActivityBoolExp: RecordingActivityBoolExp;
  RecordingActivityDeleteAtPathInput: RecordingActivityDeleteAtPathInput;
  RecordingActivityDeleteElemInput: RecordingActivityDeleteElemInput;
  RecordingActivityDeleteKeyInput: RecordingActivityDeleteKeyInput;
  RecordingActivityInsertInput: RecordingActivityInsertInput;
  RecordingActivityMaxFields: RecordingActivityMaxFields;
  RecordingActivityMinFields: RecordingActivityMinFields;
  RecordingActivityMutationResponse: RecordingActivityMutationResponse;
  RecordingActivityOnConflict: RecordingActivityOnConflict;
  RecordingActivityOrderBy: RecordingActivityOrderBy;
  RecordingActivityPkColumnsInput: RecordingActivityPkColumnsInput;
  RecordingActivityPrependInput: RecordingActivityPrependInput;
  RecordingActivitySetInput: RecordingActivitySetInput;
  RecordingActivityStreamCursorInput: RecordingActivityStreamCursorInput;
  RecordingActivityStreamCursorValueInput: RecordingActivityStreamCursorValueInput;
  RecordingActivityUpdates: RecordingActivityUpdates;
  Recordings: Recordings;
  RecordingsAggregate: RecordingsAggregate;
  RecordingsAggregateFields: RecordingsAggregateFields;
  RecordingsAppendInput: RecordingsAppendInput;
  RecordingsBoolExp: RecordingsBoolExp;
  RecordingsDeleteAtPathInput: RecordingsDeleteAtPathInput;
  RecordingsDeleteElemInput: RecordingsDeleteElemInput;
  RecordingsDeleteKeyInput: RecordingsDeleteKeyInput;
  RecordingsInsertInput: RecordingsInsertInput;
  RecordingsMaxFields: RecordingsMaxFields;
  RecordingsMinFields: RecordingsMinFields;
  RecordingsMutationResponse: RecordingsMutationResponse;
  RecordingsOnConflict: RecordingsOnConflict;
  RecordingsOrderBy: RecordingsOrderBy;
  RecordingsPkColumnsInput: RecordingsPkColumnsInput;
  RecordingsPrependInput: RecordingsPrependInput;
  RecordingsSetInput: RecordingsSetInput;
  RecordingsStreamCursorInput: RecordingsStreamCursorInput;
  RecordingsStreamCursorValueInput: RecordingsStreamCursorValueInput;
  RecordingsUpdates: RecordingsUpdates;
  RegisterAnonOutput: RegisterAnonOutput;
  RelatedCourses: RelatedCourses;
  RelatedCoursesAggregate: RelatedCoursesAggregate;
  RelatedCoursesAggregateBoolExp: RelatedCoursesAggregateBoolExp;
  RelatedCoursesAggregateBoolExpCount: RelatedCoursesAggregateBoolExpCount;
  RelatedCoursesAggregateFields: RelatedCoursesAggregateFields;
  RelatedCoursesAggregateOrderBy: RelatedCoursesAggregateOrderBy;
  RelatedCoursesArrRelInsertInput: RelatedCoursesArrRelInsertInput;
  RelatedCoursesBoolExp: RelatedCoursesBoolExp;
  RelatedCoursesInsertInput: RelatedCoursesInsertInput;
  RelatedCoursesMaxFields: RelatedCoursesMaxFields;
  RelatedCoursesMaxOrderBy: RelatedCoursesMaxOrderBy;
  RelatedCoursesMinFields: RelatedCoursesMinFields;
  RelatedCoursesMinOrderBy: RelatedCoursesMinOrderBy;
  RelatedCoursesMutationResponse: RelatedCoursesMutationResponse;
  RelatedCoursesOnConflict: RelatedCoursesOnConflict;
  RelatedCoursesOrderBy: RelatedCoursesOrderBy;
  RelatedCoursesPkColumnsInput: RelatedCoursesPkColumnsInput;
  RelatedCoursesSetInput: RelatedCoursesSetInput;
  RelatedCoursesStreamCursorInput: RelatedCoursesStreamCursorInput;
  RelatedCoursesStreamCursorValueInput: RelatedCoursesStreamCursorValueInput;
  RelatedCoursesUpdates: RelatedCoursesUpdates;
  ReportPublishStatus: ReportPublishStatus;
  ReportPublishStatusAggregate: ReportPublishStatusAggregate;
  ReportPublishStatusAggregateFields: ReportPublishStatusAggregateFields;
  ReportPublishStatusBoolExp: ReportPublishStatusBoolExp;
  ReportPublishStatusEnumComparisonExp: ReportPublishStatusEnumComparisonExp;
  ReportPublishStatusInsertInput: ReportPublishStatusInsertInput;
  ReportPublishStatusMaxFields: ReportPublishStatusMaxFields;
  ReportPublishStatusMinFields: ReportPublishStatusMinFields;
  ReportPublishStatusMutationResponse: ReportPublishStatusMutationResponse;
  ReportPublishStatusOnConflict: ReportPublishStatusOnConflict;
  ReportPublishStatusOrderBy: ReportPublishStatusOrderBy;
  ReportPublishStatusPkColumnsInput: ReportPublishStatusPkColumnsInput;
  ReportPublishStatusSetInput: ReportPublishStatusSetInput;
  ReportPublishStatusStreamCursorInput: ReportPublishStatusStreamCursorInput;
  ReportPublishStatusStreamCursorValueInput: ReportPublishStatusStreamCursorValueInput;
  ReportPublishStatusUpdates: ReportPublishStatusUpdates;
  ReportType: ReportType;
  ReportTypeAggregate: ReportTypeAggregate;
  ReportTypeAggregateFields: ReportTypeAggregateFields;
  ReportTypeBoolExp: ReportTypeBoolExp;
  ReportTypeEnumComparisonExp: ReportTypeEnumComparisonExp;
  ReportTypeInsertInput: ReportTypeInsertInput;
  ReportTypeMaxFields: ReportTypeMaxFields;
  ReportTypeMinFields: ReportTypeMinFields;
  ReportTypeMutationResponse: ReportTypeMutationResponse;
  ReportTypeOnConflict: ReportTypeOnConflict;
  ReportTypeOrderBy: ReportTypeOrderBy;
  ReportTypePkColumnsInput: ReportTypePkColumnsInput;
  ReportTypeSetInput: ReportTypeSetInput;
  ReportTypeStreamCursorInput: ReportTypeStreamCursorInput;
  ReportTypeStreamCursorValueInput: ReportTypeStreamCursorValueInput;
  ReportTypeUpdates: ReportTypeUpdates;
  Reports: Reports;
  ReportsAggregate: ReportsAggregate;
  ReportsAggregateFields: ReportsAggregateFields;
  ReportsAvgFields: ReportsAvgFields;
  ReportsBoolExp: ReportsBoolExp;
  ReportsIncInput: ReportsIncInput;
  ReportsInsertInput: ReportsInsertInput;
  ReportsMaxFields: ReportsMaxFields;
  ReportsMinFields: ReportsMinFields;
  ReportsMutationResponse: ReportsMutationResponse;
  ReportsOnConflict: ReportsOnConflict;
  ReportsOrderBy: ReportsOrderBy;
  ReportsPkColumnsInput: ReportsPkColumnsInput;
  ReportsSetInput: ReportsSetInput;
  ReportsStddevFields: ReportsStddevFields;
  ReportsStddevPopFields: ReportsStddevPopFields;
  ReportsStddevSampFields: ReportsStddevSampFields;
  ReportsStreamCursorInput: ReportsStreamCursorInput;
  ReportsStreamCursorValueInput: ReportsStreamCursorValueInput;
  ReportsSumFields: ReportsSumFields;
  ReportsUpdates: ReportsUpdates;
  ReportsVarPopFields: ReportsVarPopFields;
  ReportsVarSampFields: ReportsVarSampFields;
  ReportsVarianceFields: ReportsVarianceFields;
  ResourceType: ResourceType;
  ResourceTypeAggregate: ResourceTypeAggregate;
  ResourceTypeAggregateFields: ResourceTypeAggregateFields;
  ResourceTypeBoolExp: ResourceTypeBoolExp;
  ResourceTypeEnumComparisonExp: ResourceTypeEnumComparisonExp;
  ResourceTypeInsertInput: ResourceTypeInsertInput;
  ResourceTypeMaxFields: ResourceTypeMaxFields;
  ResourceTypeMinFields: ResourceTypeMinFields;
  ResourceTypeMutationResponse: ResourceTypeMutationResponse;
  ResourceTypeOnConflict: ResourceTypeOnConflict;
  ResourceTypeOrderBy: ResourceTypeOrderBy;
  ResourceTypePkColumnsInput: ResourceTypePkColumnsInput;
  ResourceTypeSetInput: ResourceTypeSetInput;
  ResourceTypeStreamCursorInput: ResourceTypeStreamCursorInput;
  ResourceTypeStreamCursorValueInput: ResourceTypeStreamCursorValueInput;
  ResourceTypeUpdates: ResourceTypeUpdates;
  Resources: Resources;
  ResourcesAggregate: ResourcesAggregate;
  ResourcesAggregateFields: ResourcesAggregateFields;
  ResourcesAvgFields: ResourcesAvgFields;
  ResourcesBoolExp: ResourcesBoolExp;
  ResourcesIncInput: ResourcesIncInput;
  ResourcesInsertInput: ResourcesInsertInput;
  ResourcesMaxFields: ResourcesMaxFields;
  ResourcesMinFields: ResourcesMinFields;
  ResourcesMutationResponse: ResourcesMutationResponse;
  ResourcesObjRelInsertInput: ResourcesObjRelInsertInput;
  ResourcesOnConflict: ResourcesOnConflict;
  ResourcesOrderBy: ResourcesOrderBy;
  ResourcesPkColumnsInput: ResourcesPkColumnsInput;
  ResourcesSetInput: ResourcesSetInput;
  ResourcesStddevFields: ResourcesStddevFields;
  ResourcesStddevPopFields: ResourcesStddevPopFields;
  ResourcesStddevSampFields: ResourcesStddevSampFields;
  ResourcesStreamCursorInput: ResourcesStreamCursorInput;
  ResourcesStreamCursorValueInput: ResourcesStreamCursorValueInput;
  ResourcesSumFields: ResourcesSumFields;
  ResourcesUpdates: ResourcesUpdates;
  ResourcesVarPopFields: ResourcesVarPopFields;
  ResourcesVarSampFields: ResourcesVarSampFields;
  ResourcesVarianceFields: ResourcesVarianceFields;
  ResumeAssessmentOutput: ResumeAssessmentOutput;
  RoomInput: RoomInput;
  RunPlayOutput: RunPlayOutput;
  SampleOutput: SampleOutput;
  SaveAssessmentOutput: SaveAssessmentOutput;
  Schools: Schools;
  SchoolsAggregate: SchoolsAggregate;
  SchoolsAggregateFields: SchoolsAggregateFields;
  SchoolsBoolExp: SchoolsBoolExp;
  SchoolsInsertInput: SchoolsInsertInput;
  SchoolsMaxFields: SchoolsMaxFields;
  SchoolsMinFields: SchoolsMinFields;
  SchoolsMutationResponse: SchoolsMutationResponse;
  SchoolsObjRelInsertInput: SchoolsObjRelInsertInput;
  SchoolsOnConflict: SchoolsOnConflict;
  SchoolsOrderBy: SchoolsOrderBy;
  SchoolsPkColumnsInput: SchoolsPkColumnsInput;
  SchoolsSetInput: SchoolsSetInput;
  SchoolsStreamCursorInput: SchoolsStreamCursorInput;
  SchoolsStreamCursorValueInput: SchoolsStreamCursorValueInput;
  SchoolsUpdates: SchoolsUpdates;
  SeatType: SeatType;
  SeatTypeAggregate: SeatTypeAggregate;
  SeatTypeAggregateFields: SeatTypeAggregateFields;
  SeatTypeBoolExp: SeatTypeBoolExp;
  SeatTypeEnumComparisonExp: SeatTypeEnumComparisonExp;
  SeatTypeInsertInput: SeatTypeInsertInput;
  SeatTypeMaxFields: SeatTypeMaxFields;
  SeatTypeMinFields: SeatTypeMinFields;
  SeatTypeMutationResponse: SeatTypeMutationResponse;
  SeatTypeOnConflict: SeatTypeOnConflict;
  SeatTypeOrderBy: SeatTypeOrderBy;
  SeatTypePkColumnsInput: SeatTypePkColumnsInput;
  SeatTypeSetInput: SeatTypeSetInput;
  SeatTypeStreamCursorInput: SeatTypeStreamCursorInput;
  SeatTypeStreamCursorValueInput: SeatTypeStreamCursorValueInput;
  SeatTypeUpdates: SeatTypeUpdates;
  Seats: Seats;
  SeatsAggregate: SeatsAggregate;
  SeatsAggregateFields: SeatsAggregateFields;
  SeatsBoolExp: SeatsBoolExp;
  SeatsInsertInput: SeatsInsertInput;
  SeatsMaxFields: SeatsMaxFields;
  SeatsMinFields: SeatsMinFields;
  SeatsMutationResponse: SeatsMutationResponse;
  SeatsOnConflict: SeatsOnConflict;
  SeatsOrderBy: SeatsOrderBy;
  SeatsPkColumnsInput: SeatsPkColumnsInput;
  SeatsSetInput: SeatsSetInput;
  SeatsStreamCursorInput: SeatsStreamCursorInput;
  SeatsStreamCursorValueInput: SeatsStreamCursorValueInput;
  SeatsUpdates: SeatsUpdates;
  SetClassStatusContext: SetClassStatusContext;
  SetClassStatusOutput: SetClassStatusOutput;
  SmallintComparisonExp: SmallintComparisonExp;
  StartAssessmentOutput: StartAssessmentOutput;
  StartPlaybackInput: StartPlaybackInput;
  StartPlaybackOutput: StartPlaybackOutput;
  StreamMessages: StreamMessages;
  StreamMessagesAggregate: StreamMessagesAggregate;
  StreamMessagesAggregateBoolExp: StreamMessagesAggregateBoolExp;
  StreamMessagesAggregateFields: StreamMessagesAggregateFields;
  StreamMessagesAggregateOrderBy: StreamMessagesAggregateOrderBy;
  StreamMessagesAppendInput: StreamMessagesAppendInput;
  StreamMessagesArrRelInsertInput: StreamMessagesArrRelInsertInput;
  StreamMessagesBoolExp: StreamMessagesBoolExp;
  StreamMessagesDeleteAtPathInput: StreamMessagesDeleteAtPathInput;
  StreamMessagesDeleteElemInput: StreamMessagesDeleteElemInput;
  StreamMessagesDeleteKeyInput: StreamMessagesDeleteKeyInput;
  StreamMessagesInsertInput: StreamMessagesInsertInput;
  StreamMessagesMaxFields: StreamMessagesMaxFields;
  StreamMessagesMaxOrderBy: StreamMessagesMaxOrderBy;
  StreamMessagesMinFields: StreamMessagesMinFields;
  StreamMessagesMinOrderBy: StreamMessagesMinOrderBy;
  StreamMessagesMutationResponse: StreamMessagesMutationResponse;
  StreamMessagesOnConflict: StreamMessagesOnConflict;
  StreamMessagesOrderBy: StreamMessagesOrderBy;
  StreamMessagesPkColumnsInput: StreamMessagesPkColumnsInput;
  StreamMessagesPrependInput: StreamMessagesPrependInput;
  StreamMessagesSetInput: StreamMessagesSetInput;
  StreamMessagesStreamCursorInput: StreamMessagesStreamCursorInput;
  StreamMessagesStreamCursorValueInput: StreamMessagesStreamCursorValueInput;
  StreamMessagesUpdates: StreamMessagesUpdates;
  String: Scalars['String'];
  StringArrayComparisonExp: StringArrayComparisonExp;
  StringComparisonExp: StringComparisonExp;
  SubmitAndGradeAssessmentOutput: SubmitAndGradeAssessmentOutput;
  Subordinates: Subordinates;
  SubordinatesAggregate: SubordinatesAggregate;
  SubordinatesAggregateFields: SubordinatesAggregateFields;
  SubordinatesBoolExp: SubordinatesBoolExp;
  SubordinatesMaxFields: SubordinatesMaxFields;
  SubordinatesMinFields: SubordinatesMinFields;
  SubordinatesOrderBy: SubordinatesOrderBy;
  SubordinatesStreamCursorInput: SubordinatesStreamCursorInput;
  SubordinatesStreamCursorValueInput: SubordinatesStreamCursorValueInput;
  SuccessOutput: SuccessOutput;
  TagTypeScope: TagTypeScope;
  TagTypeScopeAggregate: TagTypeScopeAggregate;
  TagTypeScopeAggregateFields: TagTypeScopeAggregateFields;
  TagTypeScopeBoolExp: TagTypeScopeBoolExp;
  TagTypeScopeEnumComparisonExp: TagTypeScopeEnumComparisonExp;
  TagTypeScopeInsertInput: TagTypeScopeInsertInput;
  TagTypeScopeMaxFields: TagTypeScopeMaxFields;
  TagTypeScopeMinFields: TagTypeScopeMinFields;
  TagTypeScopeMutationResponse: TagTypeScopeMutationResponse;
  TagTypeScopeOnConflict: TagTypeScopeOnConflict;
  TagTypeScopeOrderBy: TagTypeScopeOrderBy;
  TagTypeScopePkColumnsInput: TagTypeScopePkColumnsInput;
  TagTypeScopeSetInput: TagTypeScopeSetInput;
  TagTypeScopeStreamCursorInput: TagTypeScopeStreamCursorInput;
  TagTypeScopeStreamCursorValueInput: TagTypeScopeStreamCursorValueInput;
  TagTypeScopeUpdates: TagTypeScopeUpdates;
  TagTypes: TagTypes;
  TagTypesAggregate: TagTypesAggregate;
  TagTypesAggregateFields: TagTypesAggregateFields;
  TagTypesBoolExp: TagTypesBoolExp;
  TagTypesInsertInput: TagTypesInsertInput;
  TagTypesMaxFields: TagTypesMaxFields;
  TagTypesMinFields: TagTypesMinFields;
  TagTypesMutationResponse: TagTypesMutationResponse;
  TagTypesObjRelInsertInput: TagTypesObjRelInsertInput;
  TagTypesOnConflict: TagTypesOnConflict;
  TagTypesOrderBy: TagTypesOrderBy;
  TagTypesPkColumnsInput: TagTypesPkColumnsInput;
  TagTypesSetInput: TagTypesSetInput;
  TagTypesStreamCursorInput: TagTypesStreamCursorInput;
  TagTypesStreamCursorValueInput: TagTypesStreamCursorValueInput;
  TagTypesUpdates: TagTypesUpdates;
  Tags: Tags;
  TagsAggregate: TagsAggregate;
  TagsAggregateBoolExp: TagsAggregateBoolExp;
  TagsAggregateFields: TagsAggregateFields;
  TagsAggregateOrderBy: TagsAggregateOrderBy;
  TagsArrRelInsertInput: TagsArrRelInsertInput;
  TagsBoolExp: TagsBoolExp;
  TagsInsertInput: TagsInsertInput;
  TagsMaxFields: TagsMaxFields;
  TagsMaxOrderBy: TagsMaxOrderBy;
  TagsMinFields: TagsMinFields;
  TagsMinOrderBy: TagsMinOrderBy;
  TagsMutationResponse: TagsMutationResponse;
  TagsObjRelInsertInput: TagsObjRelInsertInput;
  TagsOnConflict: TagsOnConflict;
  TagsOrderBy: TagsOrderBy;
  TagsPkColumnsInput: TagsPkColumnsInput;
  TagsSetInput: TagsSetInput;
  TagsStreamCursorInput: TagsStreamCursorInput;
  TagsStreamCursorValueInput: TagsStreamCursorValueInput;
  TagsUpdates: TagsUpdates;
  TaskDefinition: TaskDefinition;
  TaskDefinitionAggregate: TaskDefinitionAggregate;
  TaskDefinitionAggregateFields: TaskDefinitionAggregateFields;
  TaskDefinitionBoolExp: TaskDefinitionBoolExp;
  TaskDefinitionInsertInput: TaskDefinitionInsertInput;
  TaskDefinitionMaxFields: TaskDefinitionMaxFields;
  TaskDefinitionMinFields: TaskDefinitionMinFields;
  TaskDefinitionMutationResponse: TaskDefinitionMutationResponse;
  TaskDefinitionObjRelInsertInput: TaskDefinitionObjRelInsertInput;
  TaskDefinitionOnConflict: TaskDefinitionOnConflict;
  TaskDefinitionOrderBy: TaskDefinitionOrderBy;
  TaskDefinitionPkColumnsInput: TaskDefinitionPkColumnsInput;
  TaskDefinitionSetInput: TaskDefinitionSetInput;
  TaskDefinitionStreamCursorInput: TaskDefinitionStreamCursorInput;
  TaskDefinitionStreamCursorValueInput: TaskDefinitionStreamCursorValueInput;
  TaskDefinitionType: TaskDefinitionType;
  TaskDefinitionTypeAggregate: TaskDefinitionTypeAggregate;
  TaskDefinitionTypeAggregateFields: TaskDefinitionTypeAggregateFields;
  TaskDefinitionTypeBoolExp: TaskDefinitionTypeBoolExp;
  TaskDefinitionTypeEnumComparisonExp: TaskDefinitionTypeEnumComparisonExp;
  TaskDefinitionTypeInsertInput: TaskDefinitionTypeInsertInput;
  TaskDefinitionTypeMaxFields: TaskDefinitionTypeMaxFields;
  TaskDefinitionTypeMinFields: TaskDefinitionTypeMinFields;
  TaskDefinitionTypeMutationResponse: TaskDefinitionTypeMutationResponse;
  TaskDefinitionTypeOnConflict: TaskDefinitionTypeOnConflict;
  TaskDefinitionTypeOrderBy: TaskDefinitionTypeOrderBy;
  TaskDefinitionTypePkColumnsInput: TaskDefinitionTypePkColumnsInput;
  TaskDefinitionTypeSetInput: TaskDefinitionTypeSetInput;
  TaskDefinitionTypeStreamCursorInput: TaskDefinitionTypeStreamCursorInput;
  TaskDefinitionTypeStreamCursorValueInput: TaskDefinitionTypeStreamCursorValueInput;
  TaskDefinitionTypeUpdates: TaskDefinitionTypeUpdates;
  TaskDefinitionUpdates: TaskDefinitionUpdates;
  Tasks: Tasks;
  TasksAggregate: TasksAggregate;
  TasksAggregateBoolExp: TasksAggregateBoolExp;
  TasksAggregateFields: TasksAggregateFields;
  TasksAggregateOrderBy: TasksAggregateOrderBy;
  TasksAppendInput: TasksAppendInput;
  TasksArrRelInsertInput: TasksArrRelInsertInput;
  TasksBoolExp: TasksBoolExp;
  TasksDeleteAtPathInput: TasksDeleteAtPathInput;
  TasksDeleteElemInput: TasksDeleteElemInput;
  TasksDeleteKeyInput: TasksDeleteKeyInput;
  TasksInsertInput: TasksInsertInput;
  TasksMaxFields: TasksMaxFields;
  TasksMaxOrderBy: TasksMaxOrderBy;
  TasksMinFields: TasksMinFields;
  TasksMinOrderBy: TasksMinOrderBy;
  TasksMutationResponse: TasksMutationResponse;
  TasksObjRelInsertInput: TasksObjRelInsertInput;
  TasksOnConflict: TasksOnConflict;
  TasksOrderBy: TasksOrderBy;
  TasksPkColumnsInput: TasksPkColumnsInput;
  TasksPrependInput: TasksPrependInput;
  TasksSetInput: TasksSetInput;
  TasksStreamCursorInput: TasksStreamCursorInput;
  TasksStreamCursorValueInput: TasksStreamCursorValueInput;
  TasksUpdates: TasksUpdates;
  TeamOutput: TeamOutput;
  Teams: Teams;
  TeamsAggregate: TeamsAggregate;
  TeamsAggregateFields: TeamsAggregateFields;
  TeamsBoolExp: TeamsBoolExp;
  TeamsInsertInput: TeamsInsertInput;
  TeamsMaxFields: TeamsMaxFields;
  TeamsMinFields: TeamsMinFields;
  TeamsMutationResponse: TeamsMutationResponse;
  TeamsObjRelInsertInput: TeamsObjRelInsertInput;
  TeamsOnConflict: TeamsOnConflict;
  TeamsOrderBy: TeamsOrderBy;
  TeamsPkColumnsInput: TeamsPkColumnsInput;
  TeamsSetInput: TeamsSetInput;
  TeamsStreamCursorInput: TeamsStreamCursorInput;
  TeamsStreamCursorValueInput: TeamsStreamCursorValueInput;
  TeamsUpdates: TeamsUpdates;
  TeamsUsers: TeamsUsers;
  TeamsUsersAggregate: TeamsUsersAggregate;
  TeamsUsersAggregateBoolExp: TeamsUsersAggregateBoolExp;
  TeamsUsersAggregateBoolExpCount: TeamsUsersAggregateBoolExpCount;
  TeamsUsersAggregateFields: TeamsUsersAggregateFields;
  TeamsUsersAggregateOrderBy: TeamsUsersAggregateOrderBy;
  TeamsUsersArrRelInsertInput: TeamsUsersArrRelInsertInput;
  TeamsUsersBoolExp: TeamsUsersBoolExp;
  TeamsUsersInsertInput: TeamsUsersInsertInput;
  TeamsUsersMaxFields: TeamsUsersMaxFields;
  TeamsUsersMaxOrderBy: TeamsUsersMaxOrderBy;
  TeamsUsersMinFields: TeamsUsersMinFields;
  TeamsUsersMinOrderBy: TeamsUsersMinOrderBy;
  TeamsUsersMutationResponse: TeamsUsersMutationResponse;
  TeamsUsersOnConflict: TeamsUsersOnConflict;
  TeamsUsersOrderBy: TeamsUsersOrderBy;
  TeamsUsersPkColumnsInput: TeamsUsersPkColumnsInput;
  TeamsUsersSetInput: TeamsUsersSetInput;
  TeamsUsersStreamCursorInput: TeamsUsersStreamCursorInput;
  TeamsUsersStreamCursorValueInput: TeamsUsersStreamCursorValueInput;
  TeamsUsersUpdates: TeamsUsersUpdates;
  TimeElapsedLiveClasses: TimeElapsedLiveClasses;
  TimeElapsedLiveClassesAggregate: TimeElapsedLiveClassesAggregate;
  TimeElapsedLiveClassesAggregateFields: TimeElapsedLiveClassesAggregateFields;
  TimeElapsedLiveClassesAvgFields: TimeElapsedLiveClassesAvgFields;
  TimeElapsedLiveClassesBoolExp: TimeElapsedLiveClassesBoolExp;
  TimeElapsedLiveClassesMaxFields: TimeElapsedLiveClassesMaxFields;
  TimeElapsedLiveClassesMinFields: TimeElapsedLiveClassesMinFields;
  TimeElapsedLiveClassesOrderBy: TimeElapsedLiveClassesOrderBy;
  TimeElapsedLiveClassesStddevFields: TimeElapsedLiveClassesStddevFields;
  TimeElapsedLiveClassesStddevPopFields: TimeElapsedLiveClassesStddevPopFields;
  TimeElapsedLiveClassesStddevSampFields: TimeElapsedLiveClassesStddevSampFields;
  TimeElapsedLiveClassesStreamCursorInput: TimeElapsedLiveClassesStreamCursorInput;
  TimeElapsedLiveClassesStreamCursorValueInput: TimeElapsedLiveClassesStreamCursorValueInput;
  TimeElapsedLiveClassesSumFields: TimeElapsedLiveClassesSumFields;
  TimeElapsedLiveClassesVarPopFields: TimeElapsedLiveClassesVarPopFields;
  TimeElapsedLiveClassesVarSampFields: TimeElapsedLiveClassesVarSampFields;
  TimeElapsedLiveClassesVarianceFields: TimeElapsedLiveClassesVarianceFields;
  TimestampComparisonExp: TimestampComparisonExp;
  TimestamptzComparisonExp: TimestamptzComparisonExp;
  TypeformSubmissions: TypeformSubmissions;
  TypeformSubmissionsAggregate: TypeformSubmissionsAggregate;
  TypeformSubmissionsAggregateFields: TypeformSubmissionsAggregateFields;
  TypeformSubmissionsAppendInput: TypeformSubmissionsAppendInput;
  TypeformSubmissionsBoolExp: TypeformSubmissionsBoolExp;
  TypeformSubmissionsDeleteAtPathInput: TypeformSubmissionsDeleteAtPathInput;
  TypeformSubmissionsDeleteElemInput: TypeformSubmissionsDeleteElemInput;
  TypeformSubmissionsDeleteKeyInput: TypeformSubmissionsDeleteKeyInput;
  TypeformSubmissionsInsertInput: TypeformSubmissionsInsertInput;
  TypeformSubmissionsMaxFields: TypeformSubmissionsMaxFields;
  TypeformSubmissionsMinFields: TypeformSubmissionsMinFields;
  TypeformSubmissionsMutationResponse: TypeformSubmissionsMutationResponse;
  TypeformSubmissionsOnConflict: TypeformSubmissionsOnConflict;
  TypeformSubmissionsOrderBy: TypeformSubmissionsOrderBy;
  TypeformSubmissionsPkColumnsInput: TypeformSubmissionsPkColumnsInput;
  TypeformSubmissionsPrependInput: TypeformSubmissionsPrependInput;
  TypeformSubmissionsSetInput: TypeformSubmissionsSetInput;
  TypeformSubmissionsStreamCursorInput: TypeformSubmissionsStreamCursorInput;
  TypeformSubmissionsStreamCursorValueInput: TypeformSubmissionsStreamCursorValueInput;
  TypeformSubmissionsUpdates: TypeformSubmissionsUpdates;
  UnenrollInput: UnenrollInput;
  UnenrollOutput: UnenrollOutput;
  UpdatePlaybackInput: UpdatePlaybackInput;
  UpdatePlaybackOutput: UpdatePlaybackOutput;
  UserCompletedSurveys: UserCompletedSurveys;
  UserCompletedSurveysAggregate: UserCompletedSurveysAggregate;
  UserCompletedSurveysAggregateFields: UserCompletedSurveysAggregateFields;
  UserCompletedSurveysAvgFields: UserCompletedSurveysAvgFields;
  UserCompletedSurveysBoolExp: UserCompletedSurveysBoolExp;
  UserCompletedSurveysMaxFields: UserCompletedSurveysMaxFields;
  UserCompletedSurveysMinFields: UserCompletedSurveysMinFields;
  UserCompletedSurveysOrderBy: UserCompletedSurveysOrderBy;
  UserCompletedSurveysStddevFields: UserCompletedSurveysStddevFields;
  UserCompletedSurveysStddevPopFields: UserCompletedSurveysStddevPopFields;
  UserCompletedSurveysStddevSampFields: UserCompletedSurveysStddevSampFields;
  UserCompletedSurveysStreamCursorInput: UserCompletedSurveysStreamCursorInput;
  UserCompletedSurveysStreamCursorValueInput: UserCompletedSurveysStreamCursorValueInput;
  UserCompletedSurveysSumFields: UserCompletedSurveysSumFields;
  UserCompletedSurveysVarPopFields: UserCompletedSurveysVarPopFields;
  UserCompletedSurveysVarSampFields: UserCompletedSurveysVarSampFields;
  UserCompletedSurveysVarianceFields: UserCompletedSurveysVarianceFields;
  UserNumCompletedClasses: UserNumCompletedClasses;
  UserNumCompletedClassesAggregate: UserNumCompletedClassesAggregate;
  UserNumCompletedClassesAggregateFields: UserNumCompletedClassesAggregateFields;
  UserNumCompletedClassesAvgFields: UserNumCompletedClassesAvgFields;
  UserNumCompletedClassesBoolExp: UserNumCompletedClassesBoolExp;
  UserNumCompletedClassesMaxFields: UserNumCompletedClassesMaxFields;
  UserNumCompletedClassesMinFields: UserNumCompletedClassesMinFields;
  UserNumCompletedClassesOrderBy: UserNumCompletedClassesOrderBy;
  UserNumCompletedClassesStddevFields: UserNumCompletedClassesStddevFields;
  UserNumCompletedClassesStddevPopFields: UserNumCompletedClassesStddevPopFields;
  UserNumCompletedClassesStddevSampFields: UserNumCompletedClassesStddevSampFields;
  UserNumCompletedClassesStreamCursorInput: UserNumCompletedClassesStreamCursorInput;
  UserNumCompletedClassesStreamCursorValueInput: UserNumCompletedClassesStreamCursorValueInput;
  UserNumCompletedClassesSumFields: UserNumCompletedClassesSumFields;
  UserNumCompletedClassesVarPopFields: UserNumCompletedClassesVarPopFields;
  UserNumCompletedClassesVarSampFields: UserNumCompletedClassesVarSampFields;
  UserNumCompletedClassesVarianceFields: UserNumCompletedClassesVarianceFields;
  UserProfileCaptureOutput: UserProfileCaptureOutput;
  UserSessionEmails: UserSessionEmails;
  UserSessionEmailsAggregate: UserSessionEmailsAggregate;
  UserSessionEmailsAggregateFields: UserSessionEmailsAggregateFields;
  UserSessionEmailsBoolExp: UserSessionEmailsBoolExp;
  UserSessionEmailsInsertInput: UserSessionEmailsInsertInput;
  UserSessionEmailsMaxFields: UserSessionEmailsMaxFields;
  UserSessionEmailsMinFields: UserSessionEmailsMinFields;
  UserSessionEmailsMutationResponse: UserSessionEmailsMutationResponse;
  UserSessionEmailsOnConflict: UserSessionEmailsOnConflict;
  UserSessionEmailsOrderBy: UserSessionEmailsOrderBy;
  UserSessionEmailsPkColumnsInput: UserSessionEmailsPkColumnsInput;
  UserSessionEmailsSetInput: UserSessionEmailsSetInput;
  UserSessionEmailsStreamCursorInput: UserSessionEmailsStreamCursorInput;
  UserSessionEmailsStreamCursorValueInput: UserSessionEmailsStreamCursorValueInput;
  UserSessionEmailsUpdates: UserSessionEmailsUpdates;
  Users: Users;
  UsersAggregate: UsersAggregate;
  UsersAggregateFields: UsersAggregateFields;
  UsersAvgFields: UsersAvgFields;
  UsersBoolExp: UsersBoolExp;
  UsersFunctions: UsersFunctions;
  UsersFunctionsAggregate: UsersFunctionsAggregate;
  UsersFunctionsAggregateFields: UsersFunctionsAggregateFields;
  UsersFunctionsBoolExp: UsersFunctionsBoolExp;
  UsersFunctionsEnumComparisonExp: UsersFunctionsEnumComparisonExp;
  UsersFunctionsInsertInput: UsersFunctionsInsertInput;
  UsersFunctionsMaxFields: UsersFunctionsMaxFields;
  UsersFunctionsMinFields: UsersFunctionsMinFields;
  UsersFunctionsMutationResponse: UsersFunctionsMutationResponse;
  UsersFunctionsObjRelInsertInput: UsersFunctionsObjRelInsertInput;
  UsersFunctionsOnConflict: UsersFunctionsOnConflict;
  UsersFunctionsOrderBy: UsersFunctionsOrderBy;
  UsersFunctionsPkColumnsInput: UsersFunctionsPkColumnsInput;
  UsersFunctionsSetInput: UsersFunctionsSetInput;
  UsersFunctionsStreamCursorInput: UsersFunctionsStreamCursorInput;
  UsersFunctionsStreamCursorValueInput: UsersFunctionsStreamCursorValueInput;
  UsersFunctionsUpdates: UsersFunctionsUpdates;
  UsersIncInput: UsersIncInput;
  UsersInsertInput: UsersInsertInput;
  UsersLengthInFunction: UsersLengthInFunction;
  UsersLengthInFunctionAggregate: UsersLengthInFunctionAggregate;
  UsersLengthInFunctionAggregateFields: UsersLengthInFunctionAggregateFields;
  UsersLengthInFunctionBoolExp: UsersLengthInFunctionBoolExp;
  UsersLengthInFunctionEnumComparisonExp: UsersLengthInFunctionEnumComparisonExp;
  UsersLengthInFunctionInsertInput: UsersLengthInFunctionInsertInput;
  UsersLengthInFunctionMaxFields: UsersLengthInFunctionMaxFields;
  UsersLengthInFunctionMinFields: UsersLengthInFunctionMinFields;
  UsersLengthInFunctionMutationResponse: UsersLengthInFunctionMutationResponse;
  UsersLengthInFunctionOnConflict: UsersLengthInFunctionOnConflict;
  UsersLengthInFunctionOrderBy: UsersLengthInFunctionOrderBy;
  UsersLengthInFunctionPkColumnsInput: UsersLengthInFunctionPkColumnsInput;
  UsersLengthInFunctionSetInput: UsersLengthInFunctionSetInput;
  UsersLengthInFunctionStreamCursorInput: UsersLengthInFunctionStreamCursorInput;
  UsersLengthInFunctionStreamCursorValueInput: UsersLengthInFunctionStreamCursorValueInput;
  UsersLengthInFunctionUpdates: UsersLengthInFunctionUpdates;
  UsersMaxFields: UsersMaxFields;
  UsersMeta: UsersMeta;
  UsersMetaAggregate: UsersMetaAggregate;
  UsersMetaAggregateFields: UsersMetaAggregateFields;
  UsersMetaAppendInput: UsersMetaAppendInput;
  UsersMetaBoolExp: UsersMetaBoolExp;
  UsersMetaDeleteAtPathInput: UsersMetaDeleteAtPathInput;
  UsersMetaDeleteElemInput: UsersMetaDeleteElemInput;
  UsersMetaDeleteKeyInput: UsersMetaDeleteKeyInput;
  UsersMetaInsertInput: UsersMetaInsertInput;
  UsersMetaMaxFields: UsersMetaMaxFields;
  UsersMetaMinFields: UsersMetaMinFields;
  UsersMetaMutationResponse: UsersMetaMutationResponse;
  UsersMetaObjRelInsertInput: UsersMetaObjRelInsertInput;
  UsersMetaOnConflict: UsersMetaOnConflict;
  UsersMetaOrderBy: UsersMetaOrderBy;
  UsersMetaPkColumnsInput: UsersMetaPkColumnsInput;
  UsersMetaPrependInput: UsersMetaPrependInput;
  UsersMetaSetInput: UsersMetaSetInput;
  UsersMetaStreamCursorInput: UsersMetaStreamCursorInput;
  UsersMetaStreamCursorValueInput: UsersMetaStreamCursorValueInput;
  UsersMetaUpdates: UsersMetaUpdates;
  UsersMinFields: UsersMinFields;
  UsersMutationResponse: UsersMutationResponse;
  UsersObjRelInsertInput: UsersObjRelInsertInput;
  UsersOnConflict: UsersOnConflict;
  UsersOrderBy: UsersOrderBy;
  UsersPkColumnsInput: UsersPkColumnsInput;
  UsersSetInput: UsersSetInput;
  UsersStddevFields: UsersStddevFields;
  UsersStddevPopFields: UsersStddevPopFields;
  UsersStddevSampFields: UsersStddevSampFields;
  UsersStreamCursorInput: UsersStreamCursorInput;
  UsersStreamCursorValueInput: UsersStreamCursorValueInput;
  UsersSumFields: UsersSumFields;
  UsersUpdates: UsersUpdates;
  UsersVarPopFields: UsersVarPopFields;
  UsersVarSampFields: UsersVarSampFields;
  UsersVarianceFields: UsersVarianceFields;
  UuidArrayComparisonExp: UuidArrayComparisonExp;
  UuidComparisonExp: UuidComparisonExp;
  ZoomOauthTokens: ZoomOauthTokens;
  ZoomOauthTokensAggregate: ZoomOauthTokensAggregate;
  ZoomOauthTokensAggregateFields: ZoomOauthTokensAggregateFields;
  ZoomOauthTokensAvgFields: ZoomOauthTokensAvgFields;
  ZoomOauthTokensBoolExp: ZoomOauthTokensBoolExp;
  ZoomOauthTokensIncInput: ZoomOauthTokensIncInput;
  ZoomOauthTokensInsertInput: ZoomOauthTokensInsertInput;
  ZoomOauthTokensMaxFields: ZoomOauthTokensMaxFields;
  ZoomOauthTokensMinFields: ZoomOauthTokensMinFields;
  ZoomOauthTokensMutationResponse: ZoomOauthTokensMutationResponse;
  ZoomOauthTokensOnConflict: ZoomOauthTokensOnConflict;
  ZoomOauthTokensOrderBy: ZoomOauthTokensOrderBy;
  ZoomOauthTokensPkColumnsInput: ZoomOauthTokensPkColumnsInput;
  ZoomOauthTokensSetInput: ZoomOauthTokensSetInput;
  ZoomOauthTokensStddevFields: ZoomOauthTokensStddevFields;
  ZoomOauthTokensStddevPopFields: ZoomOauthTokensStddevPopFields;
  ZoomOauthTokensStddevSampFields: ZoomOauthTokensStddevSampFields;
  ZoomOauthTokensStreamCursorInput: ZoomOauthTokensStreamCursorInput;
  ZoomOauthTokensStreamCursorValueInput: ZoomOauthTokensStreamCursorValueInput;
  ZoomOauthTokensSumFields: ZoomOauthTokensSumFields;
  ZoomOauthTokensUpdates: ZoomOauthTokensUpdates;
  ZoomOauthTokensVarPopFields: ZoomOauthTokensVarPopFields;
  ZoomOauthTokensVarSampFields: ZoomOauthTokensVarSampFields;
  ZoomOauthTokensVarianceFields: ZoomOauthTokensVarianceFields;
  bigint: Scalars['bigint'];
  bpchar: Scalars['bpchar'];
  catalogsJourneysRelAggregateBoolExpCount: CatalogsJourneysRelAggregateBoolExpCount;
  classEventActivityAggregateBoolExpCount: ClassEventActivityAggregateBoolExpCount;
  classEventMeetingParticipantsAggregateBoolExpCount: ClassEventMeetingParticipantsAggregateBoolExpCount;
  classEventMeetingSegmentsAggregateBoolExpBool_and: ClassEventMeetingSegmentsAggregateBoolExpBool_And;
  classEventMeetingSegmentsAggregateBoolExpBool_or: ClassEventMeetingSegmentsAggregateBoolExpBool_Or;
  classEventMeetingSegmentsAggregateBoolExpCount: ClassEventMeetingSegmentsAggregateBoolExpCount;
  classEventMeetingsAggregateBoolExpBool_and: ClassEventMeetingsAggregateBoolExpBool_And;
  classEventMeetingsAggregateBoolExpBool_or: ClassEventMeetingsAggregateBoolExpBool_Or;
  classEventMeetingsAggregateBoolExpCount: ClassEventMeetingsAggregateBoolExpCount;
  classEventsAggregateBoolExpCount: ClassEventsAggregateBoolExpCount;
  classesAggregateBoolExpBool_and: ClassesAggregateBoolExpBool_And;
  classesAggregateBoolExpBool_or: ClassesAggregateBoolExpBool_Or;
  classesAggregateBoolExpCount: ClassesAggregateBoolExpCount;
  classesTagsRelAggregateBoolExpCount: ClassesTagsRelAggregateBoolExpCount;
  coachesAggregateBoolExpCount: CoachesAggregateBoolExpCount;
  cohortsAggregateBoolExpBool_and: CohortsAggregateBoolExpBool_And;
  cohortsAggregateBoolExpBool_or: CohortsAggregateBoolExpBool_Or;
  cohortsAggregateBoolExpCount: CohortsAggregateBoolExpCount;
  cohortsResourcesRelAggregateBoolExpCount: CohortsResourcesRelAggregateBoolExpCount;
  coursesAggregateBoolExpCount: CoursesAggregateBoolExpCount;
  coursesTagsRelAggregateBoolExpCount: CoursesTagsRelAggregateBoolExpCount;
  creditUsage: CreditUsage;
  creditUsageBoolExpBoolExp: CreditUsageBoolExpBoolExp;
  creditUsageByUser: CreditUsageByUser;
  creditUsageByUserBoolExpBoolExp: CreditUsageByUserBoolExpBoolExp;
  creditUsageByUserOrderBy: CreditUsageByUserOrderBy;
  creditUsageOrderBy: CreditUsageOrderBy;
  date: Scalars['date'];
  enrollmentWaitlistsAggregateBoolExpBool_and: EnrollmentWaitlistsAggregateBoolExpBool_And;
  enrollmentWaitlistsAggregateBoolExpBool_or: EnrollmentWaitlistsAggregateBoolExpBool_Or;
  enrollmentWaitlistsAggregateBoolExpCount: EnrollmentWaitlistsAggregateBoolExpCount;
  enrollmentsAggregateBoolExpCount: EnrollmentsAggregateBoolExpCount;
  float8: Scalars['float8'];
  fusionauthUserRegistrationsAggregateBoolExpBool_and: FusionauthUserRegistrationsAggregateBoolExpBool_And;
  fusionauthUserRegistrationsAggregateBoolExpBool_or: FusionauthUserRegistrationsAggregateBoolExpBool_Or;
  fusionauthUserRegistrationsAggregateBoolExpCount: FusionauthUserRegistrationsAggregateBoolExpCount;
  getAssessmentQuestionsArgs: GetAssessmentQuestionsArgs;
  getTeamMembersNew: GetTeamMembersNew;
  getTeamMembers_arguments: GetTeamMembers_Arguments;
  impactAssessmentAttemptsAggregateBoolExpBool_and: ImpactAssessmentAttemptsAggregateBoolExpBool_And;
  impactAssessmentAttemptsAggregateBoolExpBool_or: ImpactAssessmentAttemptsAggregateBoolExpBool_Or;
  impactAssessmentAttemptsAggregateBoolExpCount: ImpactAssessmentAttemptsAggregateBoolExpCount;
  impactAssessmentQuestionsAggregateBoolExpCount: ImpactAssessmentQuestionsAggregateBoolExpCount;
  journeyEnrollmentsAggregateBoolExpBool_and: JourneyEnrollmentsAggregateBoolExpBool_And;
  journeyEnrollmentsAggregateBoolExpBool_or: JourneyEnrollmentsAggregateBoolExpBool_Or;
  journeyEnrollmentsAggregateBoolExpCount: JourneyEnrollmentsAggregateBoolExpCount;
  journeysAggregateBoolExpBool_and: JourneysAggregateBoolExpBool_And;
  journeysAggregateBoolExpBool_or: JourneysAggregateBoolExpBool_Or;
  journeysAggregateBoolExpCount: JourneysAggregateBoolExpCount;
  json: Scalars['json'];
  jsonb: Scalars['jsonb'];
  learner_task: Learner_Task;
  learner_taskBoolExpBoolExp: Learner_TaskBoolExpBoolExp;
  learner_taskOrderBy: Learner_TaskOrderBy;
  lightningSessionSubmissionsAggregateBoolExpBool_and: LightningSessionSubmissionsAggregateBoolExpBool_And;
  lightningSessionSubmissionsAggregateBoolExpBool_or: LightningSessionSubmissionsAggregateBoolExpBool_Or;
  lightningSessionSubmissionsAggregateBoolExpCount: LightningSessionSubmissionsAggregateBoolExpCount;
  lightningSessionSubmissionsStatus: LightningSessionSubmissionsStatus;
  lightningSessionSubmissionsStatusAggregate: LightningSessionSubmissionsStatusAggregate;
  lightningSessionSubmissionsStatusAggregateFields: LightningSessionSubmissionsStatusAggregateFields;
  lightningSessionSubmissionsStatusBoolExp: LightningSessionSubmissionsStatusBoolExp;
  lightningSessionSubmissionsStatusInsertInput: LightningSessionSubmissionsStatusInsertInput;
  lightningSessionSubmissionsStatusMaxFields: LightningSessionSubmissionsStatusMaxFields;
  lightningSessionSubmissionsStatusMinFields: LightningSessionSubmissionsStatusMinFields;
  lightningSessionSubmissionsStatusMutationResponse: LightningSessionSubmissionsStatusMutationResponse;
  lightningSessionSubmissionsStatusOnConflict: LightningSessionSubmissionsStatusOnConflict;
  lightningSessionSubmissionsStatusOrderBy: LightningSessionSubmissionsStatusOrderBy;
  lightningSessionSubmissionsStatusPkColumnsInput: LightningSessionSubmissionsStatusPkColumnsInput;
  lightningSessionSubmissionsStatusSetInput: LightningSessionSubmissionsStatusSetInput;
  lightningSessionSubmissionsStatusStreamCursorInput: LightningSessionSubmissionsStatusStreamCursorInput;
  lightningSessionSubmissionsStatusStreamCursorValueInput: LightningSessionSubmissionsStatusStreamCursorValueInput;
  lightningSessionSubmissionsStatusUpdates: LightningSessionSubmissionsStatusUpdates;
  lightningSessionSubmissionsUsersRelAggregateBoolExpCount: LightningSessionSubmissionsUsersRelAggregateBoolExpCount;
  mutation_root: {};
  myTeamFlatTree_arguments: MyTeamFlatTree_Arguments;
  my_learner_task_native_query_arguments: My_Learner_Task_Native_Query_Arguments;
  numeric: Scalars['numeric'];
  planSubscriptionsAggregateBoolExpCount: PlanSubscriptionsAggregateBoolExpCount;
  programActionsTakenAggregateBoolExpCount: ProgramActionsTakenAggregateBoolExpCount;
  programCoursesShownAggregateBoolExpCount: ProgramCoursesShownAggregateBoolExpCount;
  programTagsAggregateBoolExpCount: ProgramTagsAggregateBoolExpCount;
  programUsersSelectedAggregateBoolExpCount: ProgramUsersSelectedAggregateBoolExpCount;
  publishCourseArgs: PublishCourseArgs;
  query_root: {};
  smallint: Scalars['smallint'];
  streamMessagesAggregateBoolExpCount: StreamMessagesAggregateBoolExpCount;
  subscription_root: {};
  tagsAggregateBoolExpCount: TagsAggregateBoolExpCount;
  tasksAggregateBoolExpCount: TasksAggregateBoolExpCount;
  teamMember: TeamMember;
  teamMemberBoolExpBoolExp: TeamMemberBoolExpBoolExp;
  teamMemberNewBoolExpBoolExp: TeamMemberNewBoolExpBoolExp;
  teamMemberNewOrderBy: TeamMemberNewOrderBy;
  teamMemberOrderBy: TeamMemberOrderBy;
  teamMemberPollSubmissions: TeamMemberPollSubmissions;
  teamMemberPollSubmissionsBoolExpBoolExp: TeamMemberPollSubmissionsBoolExpBoolExp;
  teamMemberPollSubmissionsOrderBy: TeamMemberPollSubmissionsOrderBy;
  team_flat_tree_node: Team_Flat_Tree_Node;
  team_flat_tree_nodeBoolExpBoolExp: Team_Flat_Tree_NodeBoolExpBoolExp;
  team_flat_tree_nodeOrderBy: Team_Flat_Tree_NodeOrderBy;
  timestamp: Scalars['timestamp'];
  timestamptz: Scalars['timestamptz'];
  userManagedTeamsFlattenedTree: UserManagedTeamsFlattenedTree;
  userManagedTeamsFlattenedTreeBoolExpBoolExp: UserManagedTeamsFlattenedTreeBoolExpBoolExp;
  userManagedTeamsFlattenedTreeOrderBy: UserManagedTeamsFlattenedTreeOrderBy;
  userManagedTeamsFlattenedTree_arguments: UserManagedTeamsFlattenedTree_Arguments;
  uuid: Scalars['uuid'];
  versionImpactAssessmentArgs: VersionImpactAssessmentArgs;
};

export type CachedDirectiveArgs = {
  refresh?: Scalars['Boolean'];
  ttl?: Scalars['Int'];
};

export type CachedDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = CachedDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type ActivityTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActivityType'] = ResolversParentTypes['ActivityType']
> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityTypeAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActivityTypeAggregate'] = ResolversParentTypes['ActivityTypeAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['ActivityTypeAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['ActivityType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityTypeAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActivityTypeAggregateFields'] = ResolversParentTypes['ActivityTypeAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ActivityTypeAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['ActivityTypeMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ActivityTypeMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityTypeMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActivityTypeMaxFields'] = ResolversParentTypes['ActivityTypeMaxFields']
> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityTypeMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActivityTypeMinFields'] = ResolversParentTypes['ActivityTypeMinFields']
> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityTypeMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActivityTypeMutationResponse'] = ResolversParentTypes['ActivityTypeMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ActivityType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddUserToCourseWaitlistOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddUserToCourseWaitlistOutput'] = ResolversParentTypes['AddUserToCourseWaitlistOutput']
> = {
  details?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttemptStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttemptStatus'] = ResolversParentTypes['AttemptStatus']
> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttemptStatusAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttemptStatusAggregate'] = ResolversParentTypes['AttemptStatusAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['AttemptStatusAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['AttemptStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttemptStatusAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttemptStatusAggregateFields'] = ResolversParentTypes['AttemptStatusAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<AttemptStatusAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['AttemptStatusMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['AttemptStatusMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttemptStatusMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttemptStatusMaxFields'] = ResolversParentTypes['AttemptStatusMaxFields']
> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttemptStatusMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttemptStatusMinFields'] = ResolversParentTypes['AttemptStatusMinFields']
> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttemptStatusMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttemptStatusMutationResponse'] = ResolversParentTypes['AttemptStatusMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['AttemptStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CatalogCoursesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CatalogCourses'] = ResolversParentTypes['CatalogCourses']
> = {
  catalog?: Resolver<ResolversTypes['Catalogs'], ParentType, ContextType>;
  catalogId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  course?: Resolver<ResolversTypes['Courses'], ParentType, ContextType>;
  courseId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  isEnrollable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CatalogCoursesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CatalogCoursesAggregate'] = ResolversParentTypes['CatalogCoursesAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['CatalogCoursesAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['CatalogCourses']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CatalogCoursesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CatalogCoursesAggregateFields'] = ResolversParentTypes['CatalogCoursesAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<CatalogCoursesAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['CatalogCoursesMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CatalogCoursesMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CatalogCoursesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CatalogCoursesMaxFields'] = ResolversParentTypes['CatalogCoursesMaxFields']
> = {
  catalogId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CatalogCoursesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CatalogCoursesMinFields'] = ResolversParentTypes['CatalogCoursesMinFields']
> = {
  catalogId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CatalogCoursesMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CatalogCoursesMutationResponse'] = ResolversParentTypes['CatalogCoursesMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CatalogCourses']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CatalogsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Catalogs'] = ResolversParentTypes['Catalogs']
> = {
  courses?: Resolver<
    Array<ResolversTypes['CatalogCourses']>,
    ParentType,
    ContextType,
    RequireFields<CatalogsCoursesArgs, never>
  >;
  coursesAggregate?: Resolver<
    ResolversTypes['CatalogCoursesAggregate'],
    ParentType,
    ContextType,
    RequireFields<CatalogsCoursesAggregateArgs, never>
  >;
  createdAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['Users'], ParentType, ContextType>;
  createdById?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  journeys?: Resolver<
    Array<ResolversTypes['CatalogsJourneysRel']>,
    ParentType,
    ContextType,
    RequireFields<CatalogsJourneysArgs, never>
  >;
  journeysAggregate?: Resolver<
    ResolversTypes['CatalogsJourneysRelAggregate'],
    ParentType,
    ContextType,
    RequireFields<CatalogsJourneysAggregateArgs, never>
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CatalogsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CatalogsAggregate'] = ResolversParentTypes['CatalogsAggregate']
> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CatalogsAggregateFields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Catalogs']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CatalogsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CatalogsAggregateFields'] = ResolversParentTypes['CatalogsAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<CatalogsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['CatalogsMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CatalogsMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CatalogsJourneysRelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CatalogsJourneysRel'] = ResolversParentTypes['CatalogsJourneysRel']
> = {
  catalog?: Resolver<Maybe<ResolversTypes['Catalogs']>, ParentType, ContextType>;
  catalogId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  journey?: Resolver<Maybe<ResolversTypes['Journeys']>, ParentType, ContextType>;
  journeyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CatalogsJourneysRelAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CatalogsJourneysRelAggregate'] = ResolversParentTypes['CatalogsJourneysRelAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['CatalogsJourneysRelAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['CatalogsJourneysRel']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CatalogsJourneysRelAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CatalogsJourneysRelAggregateFields'] = ResolversParentTypes['CatalogsJourneysRelAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<CatalogsJourneysRelAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['CatalogsJourneysRelMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CatalogsJourneysRelMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CatalogsJourneysRelMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CatalogsJourneysRelMaxFields'] = ResolversParentTypes['CatalogsJourneysRelMaxFields']
> = {
  catalogId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  journeyId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CatalogsJourneysRelMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CatalogsJourneysRelMinFields'] = ResolversParentTypes['CatalogsJourneysRelMinFields']
> = {
  catalogId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  journeyId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CatalogsJourneysRelMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CatalogsJourneysRelMutationResponse'] = ResolversParentTypes['CatalogsJourneysRelMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CatalogsJourneysRel']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CatalogsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CatalogsMaxFields'] = ResolversParentTypes['CatalogsMaxFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CatalogsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CatalogsMinFields'] = ResolversParentTypes['CatalogsMinFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CatalogsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CatalogsMutationResponse'] = ResolversParentTypes['CatalogsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Catalogs']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CheckMyTeamCourseWaitlistOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CheckMyTeamCourseWaitlistOutput'] = ResolversParentTypes['CheckMyTeamCourseWaitlistOutput']
> = {
  onWaitlist?: Resolver<Maybe<Array<Maybe<ResolversTypes['uuid']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassCoachesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassCoaches'] = ResolversParentTypes['ClassCoaches']
> = {
  class?: Resolver<ResolversTypes['Classes'], ParentType, ContextType>;
  classId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  coach?: Resolver<ResolversTypes['Coaches'], ParentType, ContextType>;
  coachId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassCoachesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassCoachesAggregate'] = ResolversParentTypes['ClassCoachesAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['ClassCoachesAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['ClassCoaches']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassCoachesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassCoachesAggregateFields'] = ResolversParentTypes['ClassCoachesAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ClassCoachesAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['ClassCoachesMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ClassCoachesMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassCoachesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassCoachesMaxFields'] = ResolversParentTypes['ClassCoachesMaxFields']
> = {
  classId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  coachId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassCoachesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassCoachesMinFields'] = ResolversParentTypes['ClassCoachesMinFields']
> = {
  classId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  coachId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassCoachesMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassCoachesMutationResponse'] = ResolversParentTypes['ClassCoachesMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ClassCoaches']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventActivityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventActivity'] = ResolversParentTypes['ClassEventActivity']
> = {
  classEvent?: Resolver<Maybe<ResolversTypes['ClassEvents']>, ParentType, ContextType>;
  classEventId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  context?: Resolver<
    Maybe<ResolversTypes['jsonb']>,
    ParentType,
    ContextType,
    RequireFields<ClassEventActivityContextArgs, never>
  >;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['ActivityTypeEnum']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventActivityAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventActivityAggregate'] = ResolversParentTypes['ClassEventActivityAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['ClassEventActivityAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['ClassEventActivity']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventActivityAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventActivityAggregateFields'] = ResolversParentTypes['ClassEventActivityAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ClassEventActivityAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['ClassEventActivityMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ClassEventActivityMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventActivityMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventActivityMaxFields'] = ResolversParentTypes['ClassEventActivityMaxFields']
> = {
  classEventId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventActivityMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventActivityMinFields'] = ResolversParentTypes['ClassEventActivityMinFields']
> = {
  classEventId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventActivityMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventActivityMutationResponse'] = ResolversParentTypes['ClassEventActivityMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ClassEventActivity']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventAnonRegistrationsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventAnonRegistrations'] = ResolversParentTypes['ClassEventAnonRegistrations']
> = {
  classEvent?: Resolver<Maybe<ResolversTypes['ClassEvents']>, ParentType, ContextType>;
  classEventId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  zoomJoinLinkUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventAnonRegistrationsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventAnonRegistrationsAggregate'] = ResolversParentTypes['ClassEventAnonRegistrationsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['ClassEventAnonRegistrationsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['ClassEventAnonRegistrations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventAnonRegistrationsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventAnonRegistrationsAggregateFields'] = ResolversParentTypes['ClassEventAnonRegistrationsAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ClassEventAnonRegistrationsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['ClassEventAnonRegistrationsMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['ClassEventAnonRegistrationsMinFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventAnonRegistrationsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventAnonRegistrationsMaxFields'] = ResolversParentTypes['ClassEventAnonRegistrationsMaxFields']
> = {
  classEventId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zoomJoinLinkUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventAnonRegistrationsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventAnonRegistrationsMinFields'] = ResolversParentTypes['ClassEventAnonRegistrationsMinFields']
> = {
  classEventId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zoomJoinLinkUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventAnonRegistrationsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventAnonRegistrationsMutationResponse'] = ResolversParentTypes['ClassEventAnonRegistrationsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['ClassEventAnonRegistrations']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventInvitationsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventInvitations'] = ResolversParentTypes['ClassEventInvitations']
> = {
  classEvent?: Resolver<Maybe<ResolversTypes['ClassEvents']>, ParentType, ContextType>;
  classEventId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  classEventVersion?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  context?: Resolver<
    Maybe<ResolversTypes['json']>,
    ParentType,
    ContextType,
    RequireFields<ClassEventInvitationsContextArgs, never>
  >;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventInvitationsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventInvitationsAggregate'] = ResolversParentTypes['ClassEventInvitationsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['ClassEventInvitationsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['ClassEventInvitations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventInvitationsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventInvitationsAggregateFields'] = ResolversParentTypes['ClassEventInvitationsAggregateFields']
> = {
  avg?: Resolver<Maybe<ResolversTypes['ClassEventInvitationsAvgFields']>, ParentType, ContextType>;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ClassEventInvitationsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['ClassEventInvitationsMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ClassEventInvitationsMinFields']>, ParentType, ContextType>;
  stddev?: Resolver<
    Maybe<ResolversTypes['ClassEventInvitationsStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['ClassEventInvitationsStddevPopFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['ClassEventInvitationsStddevSampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<Maybe<ResolversTypes['ClassEventInvitationsSumFields']>, ParentType, ContextType>;
  varPop?: Resolver<
    Maybe<ResolversTypes['ClassEventInvitationsVarPopFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['ClassEventInvitationsVarSampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['ClassEventInvitationsVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventInvitationsAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventInvitationsAvgFields'] = ResolversParentTypes['ClassEventInvitationsAvgFields']
> = {
  classEventVersion?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventInvitationsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventInvitationsMaxFields'] = ResolversParentTypes['ClassEventInvitationsMaxFields']
> = {
  classEventId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  classEventVersion?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventInvitationsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventInvitationsMinFields'] = ResolversParentTypes['ClassEventInvitationsMinFields']
> = {
  classEventId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  classEventVersion?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventInvitationsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventInvitationsMutationResponse'] = ResolversParentTypes['ClassEventInvitationsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ClassEventInvitations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventInvitationsStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventInvitationsStddevFields'] = ResolversParentTypes['ClassEventInvitationsStddevFields']
> = {
  classEventVersion?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventInvitationsStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventInvitationsStddevPopFields'] = ResolversParentTypes['ClassEventInvitationsStddevPopFields']
> = {
  classEventVersion?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventInvitationsStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventInvitationsStddevSampFields'] = ResolversParentTypes['ClassEventInvitationsStddevSampFields']
> = {
  classEventVersion?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventInvitationsSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventInvitationsSumFields'] = ResolversParentTypes['ClassEventInvitationsSumFields']
> = {
  classEventVersion?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventInvitationsVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventInvitationsVarPopFields'] = ResolversParentTypes['ClassEventInvitationsVarPopFields']
> = {
  classEventVersion?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventInvitationsVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventInvitationsVarSampFields'] = ResolversParentTypes['ClassEventInvitationsVarSampFields']
> = {
  classEventVersion?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventInvitationsVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventInvitationsVarianceFields'] = ResolversParentTypes['ClassEventInvitationsVarianceFields']
> = {
  classEventVersion?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingParticipantsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingParticipants'] = ResolversParentTypes['ClassEventMeetingParticipants']
> = {
  classEventMeetingId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  joinTime?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  meeting?: Resolver<ResolversTypes['ClassEventMeetings'], ParentType, ContextType>;
  participantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  segments?: Resolver<
    Array<ResolversTypes['ClassEventMeetingSegments']>,
    ParentType,
    ContextType,
    RequireFields<ClassEventMeetingParticipantsSegmentsArgs, never>
  >;
  segmentsAggregate?: Resolver<
    ResolversTypes['ClassEventMeetingSegmentsAggregate'],
    ParentType,
    ContextType,
    RequireFields<ClassEventMeetingParticipantsSegmentsAggregateArgs, never>
  >;
  user?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingParticipantsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingParticipantsAggregate'] = ResolversParentTypes['ClassEventMeetingParticipantsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingParticipantsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['ClassEventMeetingParticipants']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingParticipantsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingParticipantsAggregateFields'] = ResolversParentTypes['ClassEventMeetingParticipantsAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingParticipantsAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ClassEventMeetingParticipantsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingParticipantsMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingParticipantsMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingParticipantsStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingParticipantsStddevPopFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingParticipantsStddevSampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingParticipantsSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingParticipantsVarPopFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingParticipantsVarSampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingParticipantsVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingParticipantsAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingParticipantsAvgFields'] = ResolversParentTypes['ClassEventMeetingParticipantsAvgFields']
> = {
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingParticipantsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingParticipantsMaxFields'] = ResolversParentTypes['ClassEventMeetingParticipantsMaxFields']
> = {
  classEventMeetingId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  joinTime?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  participantId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingParticipantsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingParticipantsMinFields'] = ResolversParentTypes['ClassEventMeetingParticipantsMinFields']
> = {
  classEventMeetingId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  joinTime?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  participantId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingParticipantsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingParticipantsMutationResponse'] = ResolversParentTypes['ClassEventMeetingParticipantsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['ClassEventMeetingParticipants']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingParticipantsStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingParticipantsStddevFields'] = ResolversParentTypes['ClassEventMeetingParticipantsStddevFields']
> = {
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingParticipantsStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingParticipantsStddevPopFields'] = ResolversParentTypes['ClassEventMeetingParticipantsStddevPopFields']
> = {
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingParticipantsStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingParticipantsStddevSampFields'] = ResolversParentTypes['ClassEventMeetingParticipantsStddevSampFields']
> = {
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingParticipantsSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingParticipantsSumFields'] = ResolversParentTypes['ClassEventMeetingParticipantsSumFields']
> = {
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingParticipantsVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingParticipantsVarPopFields'] = ResolversParentTypes['ClassEventMeetingParticipantsVarPopFields']
> = {
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingParticipantsVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingParticipantsVarSampFields'] = ResolversParentTypes['ClassEventMeetingParticipantsVarSampFields']
> = {
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingParticipantsVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingParticipantsVarianceFields'] = ResolversParentTypes['ClassEventMeetingParticipantsVarianceFields']
> = {
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingSegmentsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingSegments'] = ResolversParentTypes['ClassEventMeetingSegments']
> = {
  audioClipUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classEventMeeting?: Resolver<ResolversTypes['ClassEventMeetings'], ParentType, ContextType>;
  classEventMeetingId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  endTimecode?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  inResponseToSegment?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingSegments']>,
    ParentType,
    ContextType
  >;
  inResponseToSegmentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  jobs?: Resolver<
    Maybe<ResolversTypes['jsonb']>,
    ParentType,
    ContextType,
    RequireFields<ClassEventMeetingSegmentsJobsArgs, never>
  >;
  lightningSubmissions?: Resolver<
    Array<ResolversTypes['LightningSessionSubmissions']>,
    ParentType,
    ContextType,
    RequireFields<ClassEventMeetingSegmentsLightningSubmissionsArgs, never>
  >;
  lightningSubmissionsAggregate?: Resolver<
    ResolversTypes['LightningSessionSubmissionsAggregate'],
    ParentType,
    ContextType,
    RequireFields<ClassEventMeetingSegmentsLightningSubmissionsAggregateArgs, never>
  >;
  segmentResponses?: Resolver<
    Array<ResolversTypes['ClassEventMeetingSegments']>,
    ParentType,
    ContextType,
    RequireFields<ClassEventMeetingSegmentsSegmentResponsesArgs, never>
  >;
  segmentResponsesAggregate?: Resolver<
    ResolversTypes['ClassEventMeetingSegmentsAggregate'],
    ParentType,
    ContextType,
    RequireFields<ClassEventMeetingSegmentsSegmentResponsesAggregateArgs, never>
  >;
  speakerParticipant?: Resolver<
    ResolversTypes['ClassEventMeetingParticipants'],
    ParentType,
    ContextType
  >;
  speakerParticipantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startTimecode?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  synced?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  transcription?: Resolver<
    Maybe<ResolversTypes['jsonb']>,
    ParentType,
    ContextType,
    RequireFields<ClassEventMeetingSegmentsTranscriptionArgs, never>
  >;
  videoClipUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingSegmentsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingSegmentsAggregate'] = ResolversParentTypes['ClassEventMeetingSegmentsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingSegmentsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['ClassEventMeetingSegments']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingSegmentsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingSegmentsAggregateFields'] = ResolversParentTypes['ClassEventMeetingSegmentsAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingSegmentsAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ClassEventMeetingSegmentsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingSegmentsMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingSegmentsMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingSegmentsStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingSegmentsStddevPopFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingSegmentsStddevSampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingSegmentsSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingSegmentsVarPopFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingSegmentsVarSampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingSegmentsVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingSegmentsAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingSegmentsAvgFields'] = ResolversParentTypes['ClassEventMeetingSegmentsAvgFields']
> = {
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  endTimecode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  startTimecode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingSegmentsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingSegmentsMaxFields'] = ResolversParentTypes['ClassEventMeetingSegmentsMaxFields']
> = {
  audioClipUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classEventMeetingId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  endTimecode?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  inResponseToSegmentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  speakerParticipantId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTimecode?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  videoClipUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingSegmentsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingSegmentsMinFields'] = ResolversParentTypes['ClassEventMeetingSegmentsMinFields']
> = {
  audioClipUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classEventMeetingId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  endTimecode?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  inResponseToSegmentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  speakerParticipantId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTimecode?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  videoClipUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingSegmentsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingSegmentsMutationResponse'] = ResolversParentTypes['ClassEventMeetingSegmentsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ClassEventMeetingSegments']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingSegmentsStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingSegmentsStddevFields'] = ResolversParentTypes['ClassEventMeetingSegmentsStddevFields']
> = {
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  endTimecode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  startTimecode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingSegmentsStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingSegmentsStddevPopFields'] = ResolversParentTypes['ClassEventMeetingSegmentsStddevPopFields']
> = {
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  endTimecode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  startTimecode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingSegmentsStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingSegmentsStddevSampFields'] = ResolversParentTypes['ClassEventMeetingSegmentsStddevSampFields']
> = {
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  endTimecode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  startTimecode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingSegmentsSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingSegmentsSumFields'] = ResolversParentTypes['ClassEventMeetingSegmentsSumFields']
> = {
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  endTimecode?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  startTimecode?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingSegmentsVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingSegmentsVarPopFields'] = ResolversParentTypes['ClassEventMeetingSegmentsVarPopFields']
> = {
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  endTimecode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  startTimecode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingSegmentsVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingSegmentsVarSampFields'] = ResolversParentTypes['ClassEventMeetingSegmentsVarSampFields']
> = {
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  endTimecode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  startTimecode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingSegmentsVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingSegmentsVarianceFields'] = ResolversParentTypes['ClassEventMeetingSegmentsVarianceFields']
> = {
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  endTimecode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  startTimecode?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetings'] = ResolversParentTypes['ClassEventMeetings']
> = {
  altRecordingUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  awsMediaConvertJobId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  breakoutMeetings?: Resolver<
    Array<ResolversTypes['ClassEventMeetings']>,
    ParentType,
    ContextType,
    RequireFields<ClassEventMeetingsBreakoutMeetingsArgs, never>
  >;
  breakoutMeetingsAggregate?: Resolver<
    ResolversTypes['ClassEventMeetingsAggregate'],
    ParentType,
    ContextType,
    RequireFields<ClassEventMeetingsBreakoutMeetingsAggregateArgs, never>
  >;
  classEvent?: Resolver<Maybe<ResolversTypes['ClassEvents']>, ParentType, ContextType>;
  classEventId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  dailySessionId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  maxParticipants?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  parentMeeting?: Resolver<Maybe<ResolversTypes['ClassEventMeetings']>, ParentType, ContextType>;
  parentMeetingId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  participants?: Resolver<
    Array<ResolversTypes['ClassEventMeetingParticipants']>,
    ParentType,
    ContextType,
    RequireFields<ClassEventMeetingsParticipantsArgs, never>
  >;
  participantsAggregate?: Resolver<
    ResolversTypes['ClassEventMeetingParticipantsAggregate'],
    ParentType,
    ContextType,
    RequireFields<ClassEventMeetingsParticipantsAggregateArgs, never>
  >;
  posterFrameUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recordingUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  room?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  segments?: Resolver<
    Array<ResolversTypes['ClassEventMeetingSegments']>,
    ParentType,
    ContextType,
    RequireFields<ClassEventMeetingsSegmentsArgs, never>
  >;
  segmentsAggregate?: Resolver<
    ResolversTypes['ClassEventMeetingSegmentsAggregate'],
    ParentType,
    ContextType,
    RequireFields<ClassEventMeetingsSegmentsAggregateArgs, never>
  >;
  startTime?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  synced?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingsAggregate'] = ResolversParentTypes['ClassEventMeetingsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['ClassEventMeetings']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingsAggregateFields'] = ResolversParentTypes['ClassEventMeetingsAggregateFields']
> = {
  avg?: Resolver<Maybe<ResolversTypes['ClassEventMeetingsAvgFields']>, ParentType, ContextType>;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ClassEventMeetingsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['ClassEventMeetingsMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ClassEventMeetingsMinFields']>, ParentType, ContextType>;
  stddev?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingsStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingsStddevPopFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingsStddevSampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<Maybe<ResolversTypes['ClassEventMeetingsSumFields']>, ParentType, ContextType>;
  varPop?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingsVarPopFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingsVarSampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingsVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingsAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingsAvgFields'] = ResolversParentTypes['ClassEventMeetingsAvgFields']
> = {
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxParticipants?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingsMaxFields'] = ResolversParentTypes['ClassEventMeetingsMaxFields']
> = {
  altRecordingUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  awsMediaConvertJobId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classEventId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  dailySessionId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  maxParticipants?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  parentMeetingId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  posterFrameUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recordingUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  room?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingsMinFields'] = ResolversParentTypes['ClassEventMeetingsMinFields']
> = {
  altRecordingUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  awsMediaConvertJobId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classEventId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  dailySessionId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  maxParticipants?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  parentMeetingId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  posterFrameUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recordingUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  room?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingsMutationResponse'] = ResolversParentTypes['ClassEventMeetingsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ClassEventMeetings']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingsStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingsStddevFields'] = ResolversParentTypes['ClassEventMeetingsStddevFields']
> = {
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxParticipants?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingsStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingsStddevPopFields'] = ResolversParentTypes['ClassEventMeetingsStddevPopFields']
> = {
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxParticipants?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingsStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingsStddevSampFields'] = ResolversParentTypes['ClassEventMeetingsStddevSampFields']
> = {
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxParticipants?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingsSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingsSumFields'] = ResolversParentTypes['ClassEventMeetingsSumFields']
> = {
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  maxParticipants?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingsVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingsVarPopFields'] = ResolversParentTypes['ClassEventMeetingsVarPopFields']
> = {
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxParticipants?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingsVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingsVarSampFields'] = ResolversParentTypes['ClassEventMeetingsVarSampFields']
> = {
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxParticipants?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventMeetingsVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventMeetingsVarianceFields'] = ResolversParentTypes['ClassEventMeetingsVarianceFields']
> = {
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxParticipants?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventRegistrationsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventRegistrations'] = ResolversParentTypes['ClassEventRegistrations']
> = {
  classEvent?: Resolver<Maybe<ResolversTypes['ClassEvents']>, ParentType, ContextType>;
  classEventId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  zoomJoinLinkUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventRegistrationsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventRegistrationsAggregate'] = ResolversParentTypes['ClassEventRegistrationsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['ClassEventRegistrationsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['ClassEventRegistrations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventRegistrationsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventRegistrationsAggregateFields'] = ResolversParentTypes['ClassEventRegistrationsAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ClassEventRegistrationsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['ClassEventRegistrationsMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['ClassEventRegistrationsMinFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventRegistrationsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventRegistrationsMaxFields'] = ResolversParentTypes['ClassEventRegistrationsMaxFields']
> = {
  classEventId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  zoomJoinLinkUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventRegistrationsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventRegistrationsMinFields'] = ResolversParentTypes['ClassEventRegistrationsMinFields']
> = {
  classEventId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  zoomJoinLinkUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventRegistrationsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventRegistrationsMutationResponse'] = ResolversParentTypes['ClassEventRegistrationsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ClassEventRegistrations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEvents'] = ResolversParentTypes['ClassEvents']
> = {
  class?: Resolver<Maybe<ResolversTypes['Classes']>, ParentType, ContextType>;
  classEventActivities?: Resolver<
    Array<ResolversTypes['ClassEventActivity']>,
    ParentType,
    ContextType,
    RequireFields<ClassEventsClassEventActivitiesArgs, never>
  >;
  classEventActivitiesAggregate?: Resolver<
    ResolversTypes['ClassEventActivityAggregate'],
    ParentType,
    ContextType,
    RequireFields<ClassEventsClassEventActivitiesAggregateArgs, never>
  >;
  classId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohort?: Resolver<Maybe<ResolversTypes['Cohorts']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  durationInSeconds?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  endTime?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  eventInviteVersion?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  lightningSession?: Resolver<Maybe<ResolversTypes['LightningSession']>, ParentType, ContextType>;
  meetings?: Resolver<
    Array<ResolversTypes['ClassEventMeetings']>,
    ParentType,
    ContextType,
    RequireFields<ClassEventsMeetingsArgs, never>
  >;
  meetingsAggregate?: Resolver<
    ResolversTypes['ClassEventMeetingsAggregate'],
    ParentType,
    ContextType,
    RequireFields<ClassEventsMeetingsAggregateArgs, never>
  >;
  numMeetings?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  recordingUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  transcript?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  zoomLinkUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zoomMeetingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventsAggregate'] = ResolversParentTypes['ClassEventsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['ClassEventsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['ClassEvents']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventsAggregateFields'] = ResolversParentTypes['ClassEventsAggregateFields']
> = {
  avg?: Resolver<Maybe<ResolversTypes['ClassEventsAvgFields']>, ParentType, ContextType>;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ClassEventsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['ClassEventsMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ClassEventsMinFields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['ClassEventsStddevFields']>, ParentType, ContextType>;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['ClassEventsStddevPopFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['ClassEventsStddevSampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<Maybe<ResolversTypes['ClassEventsSumFields']>, ParentType, ContextType>;
  varPop?: Resolver<Maybe<ResolversTypes['ClassEventsVarPopFields']>, ParentType, ContextType>;
  varSamp?: Resolver<Maybe<ResolversTypes['ClassEventsVarSampFields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['ClassEventsVarianceFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventsAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventsAvgFields'] = ResolversParentTypes['ClassEventsAvgFields']
> = {
  durationInSeconds?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  eventInviteVersion?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numMeetings?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventsMaxFields'] = ResolversParentTypes['ClassEventsMaxFields']
> = {
  classId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  durationInSeconds?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  endTime?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  eventInviteVersion?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  numMeetings?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  recordingUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  transcript?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  zoomLinkUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zoomMeetingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventsMinFields'] = ResolversParentTypes['ClassEventsMinFields']
> = {
  classId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  durationInSeconds?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  endTime?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  eventInviteVersion?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  numMeetings?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  recordingUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  transcript?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  zoomLinkUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zoomMeetingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventsMutationResponse'] = ResolversParentTypes['ClassEventsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ClassEvents']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventsStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventsStddevFields'] = ResolversParentTypes['ClassEventsStddevFields']
> = {
  durationInSeconds?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  eventInviteVersion?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numMeetings?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventsStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventsStddevPopFields'] = ResolversParentTypes['ClassEventsStddevPopFields']
> = {
  durationInSeconds?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  eventInviteVersion?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numMeetings?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventsStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventsStddevSampFields'] = ResolversParentTypes['ClassEventsStddevSampFields']
> = {
  durationInSeconds?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  eventInviteVersion?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numMeetings?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventsSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventsSumFields'] = ResolversParentTypes['ClassEventsSumFields']
> = {
  durationInSeconds?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  eventInviteVersion?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  numMeetings?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventsVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventsVarPopFields'] = ResolversParentTypes['ClassEventsVarPopFields']
> = {
  durationInSeconds?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  eventInviteVersion?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numMeetings?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventsVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventsVarSampFields'] = ResolversParentTypes['ClassEventsVarSampFields']
> = {
  durationInSeconds?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  eventInviteVersion?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numMeetings?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassEventsVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassEventsVarianceFields'] = ResolversParentTypes['ClassEventsVarianceFields']
> = {
  durationInSeconds?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  eventInviteVersion?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numMeetings?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassPlaybackActivityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassPlaybackActivity'] = ResolversParentTypes['ClassPlaybackActivity']
> = {
  classEventId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  class_event?: Resolver<ResolversTypes['ClassEvents'], ParentType, ContextType>;
  context?: Resolver<
    Maybe<ResolversTypes['jsonb']>,
    ParentType,
    ContextType,
    RequireFields<ClassPlaybackActivityContextArgs, never>
  >;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ClassPlaybackActivityTypeEnum'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  userByUserId?: Resolver<ResolversTypes['Users'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassPlaybackActivityAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassPlaybackActivityAggregate'] = ResolversParentTypes['ClassPlaybackActivityAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['ClassPlaybackActivityAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['ClassPlaybackActivity']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassPlaybackActivityAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassPlaybackActivityAggregateFields'] = ResolversParentTypes['ClassPlaybackActivityAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ClassPlaybackActivityAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['ClassPlaybackActivityMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ClassPlaybackActivityMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassPlaybackActivityMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassPlaybackActivityMaxFields'] = ResolversParentTypes['ClassPlaybackActivityMaxFields']
> = {
  classEventId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassPlaybackActivityMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassPlaybackActivityMinFields'] = ResolversParentTypes['ClassPlaybackActivityMinFields']
> = {
  classEventId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassPlaybackActivityMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassPlaybackActivityMutationResponse'] = ResolversParentTypes['ClassPlaybackActivityMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ClassPlaybackActivity']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassPlaybackActivityTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassPlaybackActivityType'] = ResolversParentTypes['ClassPlaybackActivityType']
> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassPlaybackActivityTypeAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassPlaybackActivityTypeAggregate'] = ResolversParentTypes['ClassPlaybackActivityTypeAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['ClassPlaybackActivityTypeAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['ClassPlaybackActivityType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassPlaybackActivityTypeAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassPlaybackActivityTypeAggregateFields'] = ResolversParentTypes['ClassPlaybackActivityTypeAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ClassPlaybackActivityTypeAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['ClassPlaybackActivityTypeMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['ClassPlaybackActivityTypeMinFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassPlaybackActivityTypeMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassPlaybackActivityTypeMaxFields'] = ResolversParentTypes['ClassPlaybackActivityTypeMaxFields']
> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassPlaybackActivityTypeMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassPlaybackActivityTypeMinFields'] = ResolversParentTypes['ClassPlaybackActivityTypeMinFields']
> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassPlaybackActivityTypeMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassPlaybackActivityTypeMutationResponse'] = ResolversParentTypes['ClassPlaybackActivityTypeMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ClassPlaybackActivityType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassResourcesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassResources'] = ResolversParentTypes['ClassResources']
> = {
  class?: Resolver<ResolversTypes['Classes'], ParentType, ContextType>;
  classId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  resource?: Resolver<ResolversTypes['Resources'], ParentType, ContextType>;
  resourceId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassResourcesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassResourcesAggregate'] = ResolversParentTypes['ClassResourcesAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['ClassResourcesAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['ClassResources']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassResourcesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassResourcesAggregateFields'] = ResolversParentTypes['ClassResourcesAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ClassResourcesAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['ClassResourcesMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ClassResourcesMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassResourcesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassResourcesMaxFields'] = ResolversParentTypes['ClassResourcesMaxFields']
> = {
  classId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  resourceId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassResourcesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassResourcesMinFields'] = ResolversParentTypes['ClassResourcesMinFields']
> = {
  classId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  resourceId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassResourcesMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassResourcesMutationResponse'] = ResolversParentTypes['ClassResourcesMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ClassResources']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Classes'] = ResolversParentTypes['Classes']
> = {
  classEvents?: Resolver<
    Array<ResolversTypes['ClassEvents']>,
    ParentType,
    ContextType,
    RequireFields<ClassesClassEventsArgs, never>
  >;
  classEventsAggregate?: Resolver<
    ResolversTypes['ClassEventsAggregate'],
    ParentType,
    ContextType,
    RequireFields<ClassesClassEventsAggregateArgs, never>
  >;
  coaches?: Resolver<
    Array<ResolversTypes['ClassCoaches']>,
    ParentType,
    ContextType,
    RequireFields<ClassesCoachesArgs, never>
  >;
  coachesAggregate?: Resolver<
    ResolversTypes['ClassCoachesAggregate'],
    ParentType,
    ContextType,
    RequireFields<ClassesCoachesAggregateArgs, never>
  >;
  course?: Resolver<Maybe<ResolversTypes['Courses']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  descriptionRich?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  durationInMinutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isLive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  listDescription?: Resolver<
    Maybe<ResolversTypes['json']>,
    ParentType,
    ContextType,
    RequireFields<ClassesListDescriptionArgs, never>
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  resources?: Resolver<
    Array<ResolversTypes['ClassResources']>,
    ParentType,
    ContextType,
    RequireFields<ClassesResourcesArgs, never>
  >;
  resourcesAggregate?: Resolver<
    ResolversTypes['ClassResourcesAggregate'],
    ParentType,
    ContextType,
    RequireFields<ClassesResourcesAggregateArgs, never>
  >;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tags?: Resolver<
    Array<ResolversTypes['ClassesTagsRel']>,
    ParentType,
    ContextType,
    RequireFields<ClassesTagsArgs, never>
  >;
  tagsAggregate?: Resolver<
    ResolversTypes['ClassesTagsRelAggregate'],
    ParentType,
    ContextType,
    RequireFields<ClassesTagsAggregateArgs, never>
  >;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassesAggregate'] = ResolversParentTypes['ClassesAggregate']
> = {
  aggregate?: Resolver<Maybe<ResolversTypes['ClassesAggregateFields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Classes']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassesAggregateFields'] = ResolversParentTypes['ClassesAggregateFields']
> = {
  avg?: Resolver<Maybe<ResolversTypes['ClassesAvgFields']>, ParentType, ContextType>;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ClassesAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['ClassesMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ClassesMinFields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['ClassesStddevFields']>, ParentType, ContextType>;
  stddevPop?: Resolver<Maybe<ResolversTypes['ClassesStddevPopFields']>, ParentType, ContextType>;
  stddevSamp?: Resolver<Maybe<ResolversTypes['ClassesStddevSampFields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['ClassesSumFields']>, ParentType, ContextType>;
  varPop?: Resolver<Maybe<ResolversTypes['ClassesVarPopFields']>, ParentType, ContextType>;
  varSamp?: Resolver<Maybe<ResolversTypes['ClassesVarSampFields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['ClassesVarianceFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassesAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassesAvgFields'] = ResolversParentTypes['ClassesAvgFields']
> = {
  durationInMinutes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassesMaxFields'] = ResolversParentTypes['ClassesMaxFields']
> = {
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  descriptionRich?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  durationInMinutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassesMinFields'] = ResolversParentTypes['ClassesMinFields']
> = {
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  descriptionRich?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  durationInMinutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassesMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassesMutationResponse'] = ResolversParentTypes['ClassesMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Classes']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassesStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassesStddevFields'] = ResolversParentTypes['ClassesStddevFields']
> = {
  durationInMinutes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassesStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassesStddevPopFields'] = ResolversParentTypes['ClassesStddevPopFields']
> = {
  durationInMinutes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassesStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassesStddevSampFields'] = ResolversParentTypes['ClassesStddevSampFields']
> = {
  durationInMinutes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassesSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassesSumFields'] = ResolversParentTypes['ClassesSumFields']
> = {
  durationInMinutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassesTagsRelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassesTagsRel'] = ResolversParentTypes['ClassesTagsRel']
> = {
  class?: Resolver<Maybe<ResolversTypes['Classes']>, ParentType, ContextType>;
  classId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  tag?: Resolver<Maybe<ResolversTypes['Tags']>, ParentType, ContextType>;
  tagId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassesTagsRelAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassesTagsRelAggregate'] = ResolversParentTypes['ClassesTagsRelAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['ClassesTagsRelAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['ClassesTagsRel']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassesTagsRelAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassesTagsRelAggregateFields'] = ResolversParentTypes['ClassesTagsRelAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ClassesTagsRelAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['ClassesTagsRelMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ClassesTagsRelMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassesTagsRelMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassesTagsRelMaxFields'] = ResolversParentTypes['ClassesTagsRelMaxFields']
> = {
  classId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  tagId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassesTagsRelMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassesTagsRelMinFields'] = ResolversParentTypes['ClassesTagsRelMinFields']
> = {
  classId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  tagId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassesTagsRelMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassesTagsRelMutationResponse'] = ResolversParentTypes['ClassesTagsRelMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ClassesTagsRel']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassesVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassesVarPopFields'] = ResolversParentTypes['ClassesVarPopFields']
> = {
  durationInMinutes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassesVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassesVarSampFields'] = ResolversParentTypes['ClassesVarSampFields']
> = {
  durationInMinutes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassesVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassesVarianceFields'] = ResolversParentTypes['ClassesVarianceFields']
> = {
  durationInMinutes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoachesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Coaches'] = ResolversParentTypes['Coaches']
> = {
  avatarUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classes?: Resolver<
    Array<ResolversTypes['ClassCoaches']>,
    ParentType,
    ContextType,
    RequireFields<CoachesClassesArgs, never>
  >;
  classesAggregate?: Resolver<
    ResolversTypes['ClassCoachesAggregate'],
    ParentType,
    ContextType,
    RequireFields<CoachesClassesAggregateArgs, never>
  >;
  cohorts?: Resolver<
    Array<ResolversTypes['CohortCoaches']>,
    ParentType,
    ContextType,
    RequireFields<CoachesCohortsArgs, never>
  >;
  cohortsAggregate?: Resolver<
    ResolversTypes['CohortCoachesAggregate'],
    ParentType,
    ContextType,
    RequireFields<CoachesCohortsAggregateArgs, never>
  >;
  company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courses?: Resolver<
    Array<ResolversTypes['CourseCoaches']>,
    ParentType,
    ContextType,
    RequireFields<CoachesCoursesArgs, never>
  >;
  coursesAggregate?: Resolver<
    ResolversTypes['CourseCoachesAggregate'],
    ParentType,
    ContextType,
    RequireFields<CoachesCoursesAggregateArgs, never>
  >;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  headshotUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  introVideoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  linkedinUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  longBioRich?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shortBio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  twitterUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoachesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoachesAggregate'] = ResolversParentTypes['CoachesAggregate']
> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CoachesAggregateFields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Coaches']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoachesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoachesAggregateFields'] = ResolversParentTypes['CoachesAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<CoachesAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['CoachesMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CoachesMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoachesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoachesMaxFields'] = ResolversParentTypes['CoachesMaxFields']
> = {
  avatarUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  headshotUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  introVideoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  linkedinUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  longBioRich?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shortBio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  twitterUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoachesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoachesMinFields'] = ResolversParentTypes['CoachesMinFields']
> = {
  avatarUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  headshotUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  introVideoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  linkedinUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  longBioRich?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shortBio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  twitterUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoachesMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoachesMutationResponse'] = ResolversParentTypes['CoachesMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Coaches']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortCoachesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortCoaches'] = ResolversParentTypes['CohortCoaches']
> = {
  coach?: Resolver<ResolversTypes['Coaches'], ParentType, ContextType>;
  coachId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  cohort?: Resolver<ResolversTypes['Cohorts'], ParentType, ContextType>;
  cohortId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  isLeadCoach?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortCoachesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortCoachesAggregate'] = ResolversParentTypes['CohortCoachesAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['CohortCoachesAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['CohortCoaches']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortCoachesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortCoachesAggregateFields'] = ResolversParentTypes['CohortCoachesAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<CohortCoachesAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['CohortCoachesMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CohortCoachesMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortCoachesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortCoachesMaxFields'] = ResolversParentTypes['CohortCoachesMaxFields']
> = {
  coachId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortCoachesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortCoachesMinFields'] = ResolversParentTypes['CohortCoachesMinFields']
> = {
  coachId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortCoachesMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortCoachesMutationResponse'] = ResolversParentTypes['CohortCoachesMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CohortCoaches']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortProgressResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortProgress'] = ResolversParentTypes['CohortProgress']
> = {
  cohort?: Resolver<Maybe<ResolversTypes['Cohorts']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  numClasses?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  numClassesCompleted?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortProgressAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortProgressAggregate'] = ResolversParentTypes['CohortProgressAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['CohortProgressAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['CohortProgress']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortProgressAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortProgressAggregateFields'] = ResolversParentTypes['CohortProgressAggregateFields']
> = {
  avg?: Resolver<Maybe<ResolversTypes['CohortProgressAvgFields']>, ParentType, ContextType>;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<CohortProgressAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['CohortProgressMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CohortProgressMinFields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['CohortProgressStddevFields']>, ParentType, ContextType>;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['CohortProgressStddevPopFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['CohortProgressStddevSampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<Maybe<ResolversTypes['CohortProgressSumFields']>, ParentType, ContextType>;
  varPop?: Resolver<Maybe<ResolversTypes['CohortProgressVarPopFields']>, ParentType, ContextType>;
  varSamp?: Resolver<Maybe<ResolversTypes['CohortProgressVarSampFields']>, ParentType, ContextType>;
  variance?: Resolver<
    Maybe<ResolversTypes['CohortProgressVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortProgressAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortProgressAvgFields'] = ResolversParentTypes['CohortProgressAvgFields']
> = {
  numClasses?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numClassesCompleted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortProgressMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortProgressMaxFields'] = ResolversParentTypes['CohortProgressMaxFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  numClasses?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  numClassesCompleted?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortProgressMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortProgressMinFields'] = ResolversParentTypes['CohortProgressMinFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  numClasses?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  numClassesCompleted?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortProgressStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortProgressStddevFields'] = ResolversParentTypes['CohortProgressStddevFields']
> = {
  numClasses?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numClassesCompleted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortProgressStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortProgressStddevPopFields'] = ResolversParentTypes['CohortProgressStddevPopFields']
> = {
  numClasses?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numClassesCompleted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortProgressStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortProgressStddevSampFields'] = ResolversParentTypes['CohortProgressStddevSampFields']
> = {
  numClasses?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numClassesCompleted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortProgressSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortProgressSumFields'] = ResolversParentTypes['CohortProgressSumFields']
> = {
  numClasses?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  numClassesCompleted?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortProgressVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortProgressVarPopFields'] = ResolversParentTypes['CohortProgressVarPopFields']
> = {
  numClasses?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numClassesCompleted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortProgressVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortProgressVarSampFields'] = ResolversParentTypes['CohortProgressVarSampFields']
> = {
  numClasses?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numClassesCompleted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortProgressVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortProgressVarianceFields'] = ResolversParentTypes['CohortProgressVarianceFields']
> = {
  numClasses?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numClassesCompleted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortType'] = ResolversParentTypes['CohortType']
> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortTypeAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortTypeAggregate'] = ResolversParentTypes['CohortTypeAggregate']
> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CohortTypeAggregateFields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CohortType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortTypeAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortTypeAggregateFields'] = ResolversParentTypes['CohortTypeAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<CohortTypeAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['CohortTypeMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CohortTypeMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortTypeMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortTypeMaxFields'] = ResolversParentTypes['CohortTypeMaxFields']
> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortTypeMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortTypeMinFields'] = ResolversParentTypes['CohortTypeMinFields']
> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortTypeMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortTypeMutationResponse'] = ResolversParentTypes['CohortTypeMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CohortType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortVisibilityEnumResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortVisibilityEnum'] = ResolversParentTypes['CohortVisibilityEnum']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortVisibilityEnumAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortVisibilityEnumAggregate'] = ResolversParentTypes['CohortVisibilityEnumAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['CohortVisibilityEnumAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['CohortVisibilityEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortVisibilityEnumAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortVisibilityEnumAggregateFields'] = ResolversParentTypes['CohortVisibilityEnumAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<CohortVisibilityEnumAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['CohortVisibilityEnumMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CohortVisibilityEnumMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortVisibilityEnumMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortVisibilityEnumMaxFields'] = ResolversParentTypes['CohortVisibilityEnumMaxFields']
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortVisibilityEnumMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortVisibilityEnumMinFields'] = ResolversParentTypes['CohortVisibilityEnumMinFields']
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortVisibilityEnumMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortVisibilityEnumMutationResponse'] = ResolversParentTypes['CohortVisibilityEnumMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CohortVisibilityEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Cohorts'] = ResolversParentTypes['Cohorts']
> = {
  assessmentDueDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  assessmentLiveDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  classEvents?: Resolver<
    Array<ResolversTypes['ClassEvents']>,
    ParentType,
    ContextType,
    RequireFields<CohortsClassEventsArgs, never>
  >;
  classEventsAggregate?: Resolver<
    ResolversTypes['ClassEventsAggregate'],
    ParentType,
    ContextType,
    RequireFields<CohortsClassEventsAggregateArgs, never>
  >;
  classTimeOfDay?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  coaches?: Resolver<
    Array<ResolversTypes['CohortCoaches']>,
    ParentType,
    ContextType,
    RequireFields<CohortsCoachesArgs, never>
  >;
  coachesAggregate?: Resolver<
    ResolversTypes['CohortCoachesAggregate'],
    ParentType,
    ContextType,
    RequireFields<CohortsCoachesAggregateArgs, never>
  >;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  communitySlackChannelUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  communitySlackInviteUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  course?: Resolver<Maybe<ResolversTypes['Courses']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  dayOfTheWeek?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  enrollmentWaitlist?: Resolver<
    Array<ResolversTypes['EnrollmentWaitlists']>,
    ParentType,
    ContextType,
    RequireFields<CohortsEnrollmentWaitlistArgs, never>
  >;
  enrollmentWaitlistAggregate?: Resolver<
    ResolversTypes['EnrollmentWaitlistsAggregate'],
    ParentType,
    ContextType,
    RequireFields<CohortsEnrollmentWaitlistAggregateArgs, never>
  >;
  enrollments?: Resolver<
    Array<ResolversTypes['Enrollments']>,
    ParentType,
    ContextType,
    RequireFields<CohortsEnrollmentsArgs, never>
  >;
  enrollmentsAggregate?: Resolver<
    ResolversTypes['EnrollmentsAggregate'],
    ParentType,
    ContextType,
    RequireFields<CohortsEnrollmentsAggregateArgs, never>
  >;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  impactAssessmentAttempts?: Resolver<
    Array<ResolversTypes['ImpactAssessmentAttempts']>,
    ParentType,
    ContextType,
    RequireFields<CohortsImpactAssessmentAttemptsArgs, never>
  >;
  impactAssessmentAttemptsAggregate?: Resolver<
    ResolversTypes['ImpactAssessmentAttemptsAggregate'],
    ParentType,
    ContextType,
    RequireFields<CohortsImpactAssessmentAttemptsAggregateArgs, never>
  >;
  inAppVideo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isAssessmentLive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isPostCourseSurveyAvailable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isUserEnrolled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  maxEnrollment?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  preworkRichText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preworkVideoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  promoVideoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requiresPractice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  resources?: Resolver<
    Array<ResolversTypes['CohortsResourcesRel']>,
    ParentType,
    ContextType,
    RequireFields<CohortsResourcesArgs, never>
  >;
  resourcesAggregate?: Resolver<
    ResolversTypes['CohortsResourcesRelAggregate'],
    ParentType,
    ContextType,
    RequireFields<CohortsResourcesAggregateArgs, never>
  >;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  submissions?: Resolver<
    Array<ResolversTypes['LightningSessionSubmissions']>,
    ParentType,
    ContextType,
    RequireFields<CohortsSubmissionsArgs, never>
  >;
  submissionsAggregate?: Resolver<
    ResolversTypes['LightningSessionSubmissionsAggregate'],
    ParentType,
    ContextType,
    RequireFields<CohortsSubmissionsAggregateArgs, never>
  >;
  surveyLinkUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalEnrollments?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['CohortTypeEnum'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  visibility?: Resolver<ResolversTypes['CohortVisibilityEnumEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortsAggregate'] = ResolversParentTypes['CohortsAggregate']
> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CohortsAggregateFields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Cohorts']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortsAggregateFields'] = ResolversParentTypes['CohortsAggregateFields']
> = {
  avg?: Resolver<Maybe<ResolversTypes['CohortsAvgFields']>, ParentType, ContextType>;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<CohortsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['CohortsMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CohortsMinFields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['CohortsStddevFields']>, ParentType, ContextType>;
  stddevPop?: Resolver<Maybe<ResolversTypes['CohortsStddevPopFields']>, ParentType, ContextType>;
  stddevSamp?: Resolver<Maybe<ResolversTypes['CohortsStddevSampFields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['CohortsSumFields']>, ParentType, ContextType>;
  varPop?: Resolver<Maybe<ResolversTypes['CohortsVarPopFields']>, ParentType, ContextType>;
  varSamp?: Resolver<Maybe<ResolversTypes['CohortsVarSampFields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['CohortsVarianceFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortsAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortsAvgFields'] = ResolversParentTypes['CohortsAvgFields']
> = {
  maxEnrollment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalEnrollments?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortsMaxFields'] = ResolversParentTypes['CohortsMaxFields']
> = {
  assessmentDueDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  assessmentLiveDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  classTimeOfDay?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  communitySlackChannelUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  communitySlackInviteUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  dayOfTheWeek?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  maxEnrollment?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preworkRichText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preworkVideoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  promoVideoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  surveyLinkUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalEnrollments?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortsMinFields'] = ResolversParentTypes['CohortsMinFields']
> = {
  assessmentDueDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  assessmentLiveDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  classTimeOfDay?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  communitySlackChannelUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  communitySlackInviteUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  dayOfTheWeek?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  maxEnrollment?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preworkRichText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preworkVideoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  promoVideoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  surveyLinkUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalEnrollments?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortsMutationResponse'] = ResolversParentTypes['CohortsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Cohorts']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortsResourcesRelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortsResourcesRel'] = ResolversParentTypes['CohortsResourcesRel']
> = {
  cohort?: Resolver<Maybe<ResolversTypes['Cohorts']>, ParentType, ContextType>;
  cohortId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  resource?: Resolver<Maybe<ResolversTypes['Resources']>, ParentType, ContextType>;
  resourceId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortsResourcesRelAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortsResourcesRelAggregate'] = ResolversParentTypes['CohortsResourcesRelAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['CohortsResourcesRelAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['CohortsResourcesRel']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortsResourcesRelAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortsResourcesRelAggregateFields'] = ResolversParentTypes['CohortsResourcesRelAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<CohortsResourcesRelAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['CohortsResourcesRelMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CohortsResourcesRelMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortsResourcesRelMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortsResourcesRelMaxFields'] = ResolversParentTypes['CohortsResourcesRelMaxFields']
> = {
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  resourceId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortsResourcesRelMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortsResourcesRelMinFields'] = ResolversParentTypes['CohortsResourcesRelMinFields']
> = {
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  resourceId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortsResourcesRelMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortsResourcesRelMutationResponse'] = ResolversParentTypes['CohortsResourcesRelMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CohortsResourcesRel']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortsStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortsStddevFields'] = ResolversParentTypes['CohortsStddevFields']
> = {
  maxEnrollment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalEnrollments?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortsStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortsStddevPopFields'] = ResolversParentTypes['CohortsStddevPopFields']
> = {
  maxEnrollment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalEnrollments?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortsStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortsStddevSampFields'] = ResolversParentTypes['CohortsStddevSampFields']
> = {
  maxEnrollment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalEnrollments?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortsSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortsSumFields'] = ResolversParentTypes['CohortsSumFields']
> = {
  maxEnrollment?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalEnrollments?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortsVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortsVarPopFields'] = ResolversParentTypes['CohortsVarPopFields']
> = {
  maxEnrollment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalEnrollments?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortsVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortsVarSampFields'] = ResolversParentTypes['CohortsVarSampFields']
> = {
  maxEnrollment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalEnrollments?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortsVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CohortsVarianceFields'] = ResolversParentTypes['CohortsVarianceFields']
> = {
  maxEnrollment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalEnrollments?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConfigurationsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Configurations'] = ResolversParentTypes['Configurations']
> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<
    ResolversTypes['jsonb'],
    ParentType,
    ContextType,
    RequireFields<ConfigurationsValueArgs, never>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConfigurationsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConfigurationsAggregate'] = ResolversParentTypes['ConfigurationsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['ConfigurationsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['Configurations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConfigurationsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConfigurationsAggregateFields'] = ResolversParentTypes['ConfigurationsAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ConfigurationsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['ConfigurationsMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ConfigurationsMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConfigurationsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConfigurationsMaxFields'] = ResolversParentTypes['ConfigurationsMaxFields']
> = {
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConfigurationsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConfigurationsMinFields'] = ResolversParentTypes['ConfigurationsMinFields']
> = {
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConfigurationsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConfigurationsMutationResponse'] = ResolversParentTypes['ConfigurationsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Configurations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountTeamSessionsItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CountTeamSessionsItem'] = ResolversParentTypes['CountTeamSessionsItem']
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  imageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountTeamSessionsOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CountTeamSessionsOutput'] = ResolversParentTypes['CountTeamSessionsOutput']
> = {
  users?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CountTeamSessionsItem']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseCertificationsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseCertifications'] = ResolversParentTypes['CourseCertifications']
> = {
  certificationFileUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  certificationImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohort?: Resolver<Maybe<ResolversTypes['Cohorts']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  impactAssessment?: Resolver<Maybe<ResolversTypes['ImpactAssessments']>, ParentType, ContextType>;
  impactAssessmentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseCertificationsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseCertificationsAggregate'] = ResolversParentTypes['CourseCertificationsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['CourseCertificationsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['CourseCertifications']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseCertificationsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseCertificationsAggregateFields'] = ResolversParentTypes['CourseCertificationsAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<CourseCertificationsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['CourseCertificationsMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CourseCertificationsMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseCertificationsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseCertificationsMaxFields'] = ResolversParentTypes['CourseCertificationsMaxFields']
> = {
  certificationFileUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  certificationImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  impactAssessmentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseCertificationsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseCertificationsMinFields'] = ResolversParentTypes['CourseCertificationsMinFields']
> = {
  certificationFileUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  certificationImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  impactAssessmentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseCertificationsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseCertificationsMutationResponse'] = ResolversParentTypes['CourseCertificationsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CourseCertifications']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseCoachesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseCoaches'] = ResolversParentTypes['CourseCoaches']
> = {
  coach?: Resolver<ResolversTypes['Coaches'], ParentType, ContextType>;
  coachId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  course?: Resolver<ResolversTypes['Courses'], ParentType, ContextType>;
  courseId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseCoachesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseCoachesAggregate'] = ResolversParentTypes['CourseCoachesAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['CourseCoachesAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['CourseCoaches']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseCoachesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseCoachesAggregateFields'] = ResolversParentTypes['CourseCoachesAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<CourseCoachesAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['CourseCoachesMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CourseCoachesMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseCoachesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseCoachesMaxFields'] = ResolversParentTypes['CourseCoachesMaxFields']
> = {
  coachId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseCoachesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseCoachesMinFields'] = ResolversParentTypes['CourseCoachesMinFields']
> = {
  coachId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseCoachesMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseCoachesMutationResponse'] = ResolversParentTypes['CourseCoachesMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CourseCoaches']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseEnrollmentStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseEnrollmentStatus'] = ResolversParentTypes['CourseEnrollmentStatus']
> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseEnrollmentStatusAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseEnrollmentStatusAggregate'] = ResolversParentTypes['CourseEnrollmentStatusAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['CourseEnrollmentStatusAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['CourseEnrollmentStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseEnrollmentStatusAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseEnrollmentStatusAggregateFields'] = ResolversParentTypes['CourseEnrollmentStatusAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<CourseEnrollmentStatusAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['CourseEnrollmentStatusMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CourseEnrollmentStatusMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseEnrollmentStatusMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseEnrollmentStatusMaxFields'] = ResolversParentTypes['CourseEnrollmentStatusMaxFields']
> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseEnrollmentStatusMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseEnrollmentStatusMinFields'] = ResolversParentTypes['CourseEnrollmentStatusMinFields']
> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseEnrollmentStatusMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseEnrollmentStatusMutationResponse'] = ResolversParentTypes['CourseEnrollmentStatusMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CourseEnrollmentStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursePublishStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursePublishStatus'] = ResolversParentTypes['CoursePublishStatus']
> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursePublishStatusAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursePublishStatusAggregate'] = ResolversParentTypes['CoursePublishStatusAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['CoursePublishStatusAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['CoursePublishStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursePublishStatusAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursePublishStatusAggregateFields'] = ResolversParentTypes['CoursePublishStatusAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<CoursePublishStatusAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['CoursePublishStatusMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CoursePublishStatusMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursePublishStatusMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursePublishStatusMaxFields'] = ResolversParentTypes['CoursePublishStatusMaxFields']
> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursePublishStatusMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursePublishStatusMinFields'] = ResolversParentTypes['CoursePublishStatusMinFields']
> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursePublishStatusMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursePublishStatusMutationResponse'] = ResolversParentTypes['CoursePublishStatusMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CoursePublishStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseType'] = ResolversParentTypes['CourseType']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseTypeAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseTypeAggregate'] = ResolversParentTypes['CourseTypeAggregate']
> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CourseTypeAggregateFields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CourseType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseTypeAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseTypeAggregateFields'] = ResolversParentTypes['CourseTypeAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<CourseTypeAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['CourseTypeMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CourseTypeMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseTypeMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseTypeMaxFields'] = ResolversParentTypes['CourseTypeMaxFields']
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseTypeMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseTypeMinFields'] = ResolversParentTypes['CourseTypeMinFields']
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseTypeMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseTypeMutationResponse'] = ResolversParentTypes['CourseTypeMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CourseType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseWaitlistResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseWaitlist'] = ResolversParentTypes['CourseWaitlist']
> = {
  UpdatedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  availabilityNotificationSent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  course?: Resolver<Maybe<ResolversTypes['Courses']>, ParentType, ContextType>;
  courseSlug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['Users'], ParentType, ContextType>;
  createdById?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  createdOnBehalfOf?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdOnBehalfOfId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedOnBehalfOfId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  desiredTimezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  desiredUrgency?: Resolver<
    ResolversTypes['CourseWaitlistDesiredUrgencyTypeEnum'],
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isNotDeleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  organization?: Resolver<Maybe<ResolversTypes['Organizations']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseWaitlistAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseWaitlistAggregate'] = ResolversParentTypes['CourseWaitlistAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['CourseWaitlistAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['CourseWaitlist']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseWaitlistAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseWaitlistAggregateFields'] = ResolversParentTypes['CourseWaitlistAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<CourseWaitlistAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['CourseWaitlistMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CourseWaitlistMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseWaitlistDesiredUrgencyTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseWaitlistDesiredUrgencyType'] = ResolversParentTypes['CourseWaitlistDesiredUrgencyType']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseWaitlistDesiredUrgencyTypeAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseWaitlistDesiredUrgencyTypeAggregate'] = ResolversParentTypes['CourseWaitlistDesiredUrgencyTypeAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['CourseWaitlistDesiredUrgencyTypeAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['CourseWaitlistDesiredUrgencyType']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseWaitlistDesiredUrgencyTypeAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseWaitlistDesiredUrgencyTypeAggregateFields'] = ResolversParentTypes['CourseWaitlistDesiredUrgencyTypeAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<CourseWaitlistDesiredUrgencyTypeAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['CourseWaitlistDesiredUrgencyTypeMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['CourseWaitlistDesiredUrgencyTypeMinFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseWaitlistDesiredUrgencyTypeMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseWaitlistDesiredUrgencyTypeMaxFields'] = ResolversParentTypes['CourseWaitlistDesiredUrgencyTypeMaxFields']
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseWaitlistDesiredUrgencyTypeMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseWaitlistDesiredUrgencyTypeMinFields'] = ResolversParentTypes['CourseWaitlistDesiredUrgencyTypeMinFields']
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseWaitlistDesiredUrgencyTypeMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseWaitlistDesiredUrgencyTypeMutationResponse'] = ResolversParentTypes['CourseWaitlistDesiredUrgencyTypeMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['CourseWaitlistDesiredUrgencyType']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseWaitlistMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseWaitlistMaxFields'] = ResolversParentTypes['CourseWaitlistMaxFields']
> = {
  courseSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdOnBehalfOfId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedOnBehalfOfId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  desiredTimezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseWaitlistMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseWaitlistMinFields'] = ResolversParentTypes['CourseWaitlistMinFields']
> = {
  courseSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdOnBehalfOfId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedOnBehalfOfId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  desiredTimezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseWaitlistMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseWaitlistMutationResponse'] = ResolversParentTypes['CourseWaitlistMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CourseWaitlist']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseWaitlistSendAvailabilityEmailOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseWaitlistSendAvailabilityEmailOutput'] = ResolversParentTypes['CourseWaitlistSendAvailabilityEmailOutput']
> = {
  details?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  results?: Resolver<
    Array<ResolversTypes['CourseWaitlistSendAvailabilityEmailUserOutPut']>,
    ParentType,
    ContextType
  >;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseWaitlistSendAvailabilityEmailUserOutPutResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourseWaitlistSendAvailabilityEmailUserOutPut'] = ResolversParentTypes['CourseWaitlistSendAvailabilityEmailUserOutPut']
> = {
  sent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  userFullname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Courses'] = ResolversParentTypes['Courses']
> = {
  applicationRich?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  audience?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  availableCohorts?: Resolver<
    Maybe<Array<ResolversTypes['Cohorts']>>,
    ParentType,
    ContextType,
    RequireFields<CoursesAvailableCohortsArgs, never>
  >;
  backgroundImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bannerUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  catalogs?: Resolver<
    Array<ResolversTypes['CatalogCourses']>,
    ParentType,
    ContextType,
    RequireFields<CoursesCatalogsArgs, never>
  >;
  catalogsAggregate?: Resolver<
    ResolversTypes['CatalogCoursesAggregate'],
    ParentType,
    ContextType,
    RequireFields<CoursesCatalogsAggregateArgs, never>
  >;
  certificateBackgroundUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  certificateTemplate?: Resolver<
    Maybe<ResolversTypes['json']>,
    ParentType,
    ContextType,
    RequireFields<CoursesCertificateTemplateArgs, never>
  >;
  changeDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classes?: Resolver<
    Array<ResolversTypes['Classes']>,
    ParentType,
    ContextType,
    RequireFields<CoursesClassesArgs, never>
  >;
  classesAggregate?: Resolver<
    ResolversTypes['ClassesAggregate'],
    ParentType,
    ContextType,
    RequireFields<CoursesClassesAggregateArgs, never>
  >;
  coaches?: Resolver<
    Array<ResolversTypes['CourseCoaches']>,
    ParentType,
    ContextType,
    RequireFields<CoursesCoachesArgs, never>
  >;
  coachesAggregate?: Resolver<
    ResolversTypes['CourseCoachesAggregate'],
    ParentType,
    ContextType,
    RequireFields<CoursesCoachesAggregateArgs, never>
  >;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohorts?: Resolver<
    Array<ResolversTypes['Cohorts']>,
    ParentType,
    ContextType,
    RequireFields<CoursesCohortsArgs, never>
  >;
  cohortsAggregate?: Resolver<
    ResolversTypes['CohortsAggregate'],
    ParentType,
    ContextType,
    RequireFields<CoursesCohortsAggregateArgs, never>
  >;
  course_publish_status_enum?: Resolver<
    Maybe<ResolversTypes['CoursePublishStatus']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  enrollmentStatus?: Resolver<
    Maybe<ResolversTypes['CourseEnrollmentStatusEnum']>,
    ParentType,
    ContextType
  >;
  frameworkDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  frameworkHighlight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iconUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  impactAssessment?: Resolver<Maybe<ResolversTypes['ImpactAssessments']>, ParentType, ContextType>;
  impactAssessmentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  impactDescriptionList?: Resolver<
    Maybe<ResolversTypes['json']>,
    ParentType,
    ContextType,
    RequireFields<CoursesImpactDescriptionListArgs, never>
  >;
  impactDescriptionRich?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  impactRich?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  is_permitted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  latestPublishedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  latestVersion?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  longDescriptionRich?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manager?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  managerId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nextCohortStartDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  nextCohortStartTimeOfDay?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  postCourseSurveyFormId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  practicedRich?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preCourseSurveyFormId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  promoVideoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  publishStatus?: Resolver<
    Maybe<ResolversTypes['CoursePublishStatusEnum']>,
    ParentType,
    ContextType
  >;
  publishedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  publishedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  publishedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  relatedCourses?: Resolver<
    Array<ResolversTypes['RelatedCourses']>,
    ParentType,
    ContextType,
    RequireFields<CoursesRelatedCoursesArgs, never>
  >;
  relatedCoursesAggregate?: Resolver<
    ResolversTypes['RelatedCoursesAggregate'],
    ParentType,
    ContextType,
    RequireFields<CoursesRelatedCoursesAggregateArgs, never>
  >;
  resources?: Resolver<
    Array<ResolversTypes['CoursesResourcesRel']>,
    ParentType,
    ContextType,
    RequireFields<CoursesResourcesArgs, never>
  >;
  resourcesAggregate?: Resolver<
    ResolversTypes['CoursesResourcesRelAggregate'],
    ParentType,
    ContextType,
    RequireFields<CoursesResourcesAggregateArgs, never>
  >;
  school?: Resolver<Maybe<ResolversTypes['Schools']>, ParentType, ContextType>;
  schoolId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  shortDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tags?: Resolver<
    Array<ResolversTypes['CoursesTagsRel']>,
    ParentType,
    ContextType,
    RequireFields<CoursesTagsArgs, never>
  >;
  tagsAggregate?: Resolver<
    ResolversTypes['CoursesTagsRelAggregate'],
    ParentType,
    ContextType,
    RequireFields<CoursesTagsAggregateArgs, never>
  >;
  thumbnailCardUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnailListUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timeLengthInClasses?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  timeLengthInWeeks?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['CourseTypeEnum'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesAggregate'] = ResolversParentTypes['CoursesAggregate']
> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CoursesAggregateFields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Courses']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesAggregateFields'] = ResolversParentTypes['CoursesAggregateFields']
> = {
  avg?: Resolver<Maybe<ResolversTypes['CoursesAvgFields']>, ParentType, ContextType>;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<CoursesAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['CoursesMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CoursesMinFields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['CoursesStddevFields']>, ParentType, ContextType>;
  stddevPop?: Resolver<Maybe<ResolversTypes['CoursesStddevPopFields']>, ParentType, ContextType>;
  stddevSamp?: Resolver<Maybe<ResolversTypes['CoursesStddevSampFields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['CoursesSumFields']>, ParentType, ContextType>;
  varPop?: Resolver<Maybe<ResolversTypes['CoursesVarPopFields']>, ParentType, ContextType>;
  varSamp?: Resolver<Maybe<ResolversTypes['CoursesVarSampFields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['CoursesVarianceFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesAvgFields'] = ResolversParentTypes['CoursesAvgFields']
> = {
  latestVersion?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  timeLengthInClasses?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeLengthInWeeks?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesByPlanResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesByPlan'] = ResolversParentTypes['CoursesByPlan']
> = {
  backgroundImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bannerUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  coaches?: Resolver<
    Array<ResolversTypes['CourseCoaches']>,
    ParentType,
    ContextType,
    RequireFields<CoursesByPlanCoachesArgs, never>
  >;
  coachesAggregate?: Resolver<
    ResolversTypes['CourseCoachesAggregate'],
    ParentType,
    ContextType,
    RequireFields<CoursesByPlanCoachesAggregateArgs, never>
  >;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortTypeBucket?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohorts?: Resolver<
    Array<ResolversTypes['Cohorts']>,
    ParentType,
    ContextType,
    RequireFields<CoursesByPlanCohortsArgs, never>
  >;
  cohortsAggregate?: Resolver<
    ResolversTypes['CohortsAggregate'],
    ParentType,
    ContextType,
    RequireFields<CoursesByPlanCohortsAggregateArgs, never>
  >;
  courseType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  enrollmentStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstClassStartTime?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  is_permitted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nextCohortStartDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  nextCohortStartTimeOfDay?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  publishStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  school?: Resolver<Maybe<ResolversTypes['Schools']>, ParentType, ContextType>;
  schoolId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  shortDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  tags?: Resolver<
    Array<ResolversTypes['CoursesTagsRel']>,
    ParentType,
    ContextType,
    RequireFields<CoursesByPlanTagsArgs, never>
  >;
  tagsAggregate?: Resolver<
    ResolversTypes['CoursesTagsRelAggregate'],
    ParentType,
    ContextType,
    RequireFields<CoursesByPlanTagsAggregateArgs, never>
  >;
  thumbnailCardUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timeLengthInClasses?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  timeLengthInWeeks?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesByPlanAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesByPlanAggregate'] = ResolversParentTypes['CoursesByPlanAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['CoursesByPlanAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['CoursesByPlan']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesByPlanAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesByPlanAggregateFields'] = ResolversParentTypes['CoursesByPlanAggregateFields']
> = {
  avg?: Resolver<Maybe<ResolversTypes['CoursesByPlanAvgFields']>, ParentType, ContextType>;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<CoursesByPlanAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['CoursesByPlanMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CoursesByPlanMinFields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['CoursesByPlanStddevFields']>, ParentType, ContextType>;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['CoursesByPlanStddevPopFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['CoursesByPlanStddevSampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<Maybe<ResolversTypes['CoursesByPlanSumFields']>, ParentType, ContextType>;
  varPop?: Resolver<Maybe<ResolversTypes['CoursesByPlanVarPopFields']>, ParentType, ContextType>;
  varSamp?: Resolver<Maybe<ResolversTypes['CoursesByPlanVarSampFields']>, ParentType, ContextType>;
  variance?: Resolver<
    Maybe<ResolversTypes['CoursesByPlanVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesByPlanAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesByPlanAvgFields'] = ResolversParentTypes['CoursesByPlanAvgFields']
> = {
  timeLengthInClasses?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeLengthInWeeks?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesByPlanMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesByPlanMaxFields'] = ResolversParentTypes['CoursesByPlanMaxFields']
> = {
  backgroundImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bannerUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortTypeBucket?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  enrollmentStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstClassStartTime?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nextCohortStartDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  nextCohortStartTimeOfDay?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  publishStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  schoolId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  shortDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  thumbnailCardUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timeLengthInClasses?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  timeLengthInWeeks?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesByPlanMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesByPlanMinFields'] = ResolversParentTypes['CoursesByPlanMinFields']
> = {
  backgroundImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bannerUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortTypeBucket?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  enrollmentStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstClassStartTime?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nextCohortStartDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  nextCohortStartTimeOfDay?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  publishStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  schoolId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  shortDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  thumbnailCardUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timeLengthInClasses?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  timeLengthInWeeks?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesByPlanStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesByPlanStddevFields'] = ResolversParentTypes['CoursesByPlanStddevFields']
> = {
  timeLengthInClasses?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeLengthInWeeks?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesByPlanStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesByPlanStddevPopFields'] = ResolversParentTypes['CoursesByPlanStddevPopFields']
> = {
  timeLengthInClasses?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeLengthInWeeks?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesByPlanStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesByPlanStddevSampFields'] = ResolversParentTypes['CoursesByPlanStddevSampFields']
> = {
  timeLengthInClasses?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeLengthInWeeks?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesByPlanSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesByPlanSumFields'] = ResolversParentTypes['CoursesByPlanSumFields']
> = {
  timeLengthInClasses?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  timeLengthInWeeks?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesByPlanVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesByPlanVarPopFields'] = ResolversParentTypes['CoursesByPlanVarPopFields']
> = {
  timeLengthInClasses?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeLengthInWeeks?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesByPlanVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesByPlanVarSampFields'] = ResolversParentTypes['CoursesByPlanVarSampFields']
> = {
  timeLengthInClasses?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeLengthInWeeks?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesByPlanVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesByPlanVarianceFields'] = ResolversParentTypes['CoursesByPlanVarianceFields']
> = {
  timeLengthInClasses?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeLengthInWeeks?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesMaxFields'] = ResolversParentTypes['CoursesMaxFields']
> = {
  applicationRich?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  audience?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  backgroundImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bannerUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  certificateBackgroundUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  changeDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  frameworkDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  frameworkHighlight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iconUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  impactAssessmentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  impactDescriptionRich?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  impactRich?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latestPublishedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  latestVersion?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  longDescriptionRich?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  managerId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nextCohortStartDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  nextCohortStartTimeOfDay?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  postCourseSurveyFormId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  practicedRich?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preCourseSurveyFormId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  promoVideoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  publishedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  schoolId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  shortDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnailCardUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnailListUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timeLengthInClasses?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  timeLengthInWeeks?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesMinFields'] = ResolversParentTypes['CoursesMinFields']
> = {
  applicationRich?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  audience?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  backgroundImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bannerUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  certificateBackgroundUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  changeDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  frameworkDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  frameworkHighlight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iconUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  impactAssessmentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  impactDescriptionRich?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  impactRich?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latestPublishedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  latestVersion?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  longDescriptionRich?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  managerId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nextCohortStartDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  nextCohortStartTimeOfDay?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  postCourseSurveyFormId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  practicedRich?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preCourseSurveyFormId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  promoVideoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  publishedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  schoolId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  shortDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnailCardUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnailListUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timeLengthInClasses?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  timeLengthInWeeks?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesMutationResponse'] = ResolversParentTypes['CoursesMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Courses']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesResourcesRelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesResourcesRel'] = ResolversParentTypes['CoursesResourcesRel']
> = {
  course?: Resolver<ResolversTypes['Courses'], ParentType, ContextType>;
  courseId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  resource?: Resolver<ResolversTypes['Resources'], ParentType, ContextType>;
  resourceId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesResourcesRelAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesResourcesRelAggregate'] = ResolversParentTypes['CoursesResourcesRelAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['CoursesResourcesRelAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['CoursesResourcesRel']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesResourcesRelAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesResourcesRelAggregateFields'] = ResolversParentTypes['CoursesResourcesRelAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<CoursesResourcesRelAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['CoursesResourcesRelMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CoursesResourcesRelMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesResourcesRelMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesResourcesRelMaxFields'] = ResolversParentTypes['CoursesResourcesRelMaxFields']
> = {
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  resourceId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesResourcesRelMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesResourcesRelMinFields'] = ResolversParentTypes['CoursesResourcesRelMinFields']
> = {
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  resourceId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesResourcesRelMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesResourcesRelMutationResponse'] = ResolversParentTypes['CoursesResourcesRelMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CoursesResourcesRel']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesStddevFields'] = ResolversParentTypes['CoursesStddevFields']
> = {
  latestVersion?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  timeLengthInClasses?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeLengthInWeeks?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesStddevPopFields'] = ResolversParentTypes['CoursesStddevPopFields']
> = {
  latestVersion?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  timeLengthInClasses?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeLengthInWeeks?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesStddevSampFields'] = ResolversParentTypes['CoursesStddevSampFields']
> = {
  latestVersion?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  timeLengthInClasses?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeLengthInWeeks?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesSumFields'] = ResolversParentTypes['CoursesSumFields']
> = {
  latestVersion?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  timeLengthInClasses?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  timeLengthInWeeks?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesTagsRelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesTagsRel'] = ResolversParentTypes['CoursesTagsRel']
> = {
  course?: Resolver<Maybe<ResolversTypes['Courses']>, ParentType, ContextType>;
  courseId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  courseSlug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  tag?: Resolver<Maybe<ResolversTypes['Tags']>, ParentType, ContextType>;
  tagId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesTagsRelAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesTagsRelAggregate'] = ResolversParentTypes['CoursesTagsRelAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['CoursesTagsRelAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['CoursesTagsRel']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesTagsRelAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesTagsRelAggregateFields'] = ResolversParentTypes['CoursesTagsRelAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<CoursesTagsRelAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['CoursesTagsRelMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CoursesTagsRelMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesTagsRelMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesTagsRelMaxFields'] = ResolversParentTypes['CoursesTagsRelMaxFields']
> = {
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  courseSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  tagId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesTagsRelMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesTagsRelMinFields'] = ResolversParentTypes['CoursesTagsRelMinFields']
> = {
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  courseSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  tagId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesTagsRelMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesTagsRelMutationResponse'] = ResolversParentTypes['CoursesTagsRelMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CoursesTagsRel']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesVarPopFields'] = ResolversParentTypes['CoursesVarPopFields']
> = {
  latestVersion?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  timeLengthInClasses?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeLengthInWeeks?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesVarSampFields'] = ResolversParentTypes['CoursesVarSampFields']
> = {
  latestVersion?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  timeLengthInClasses?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeLengthInWeeks?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoursesVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoursesVarianceFields'] = ResolversParentTypes['CoursesVarianceFields']
> = {
  latestVersion?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  timeLengthInClasses?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeLengthInWeeks?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateOrganizationOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateOrganizationOutput'] = ResolversParentTypes['CreateOrganizationOutput']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateUserOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateUserOutput'] = ResolversParentTypes['CreateUserOutput']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailQueueResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailQueue'] = ResolversParentTypes['EmailQueue']
> = {
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  data?: Resolver<
    Maybe<ResolversTypes['jsonb']>,
    ParentType,
    ContextType,
    RequireFields<EmailQueueDataArgs, never>
  >;
  emailName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  processed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  processedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailQueueAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailQueueAggregate'] = ResolversParentTypes['EmailQueueAggregate']
> = {
  aggregate?: Resolver<Maybe<ResolversTypes['EmailQueueAggregateFields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['EmailQueue']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailQueueAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailQueueAggregateFields'] = ResolversParentTypes['EmailQueueAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<EmailQueueAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['EmailQueueMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['EmailQueueMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailQueueMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailQueueMaxFields'] = ResolversParentTypes['EmailQueueMaxFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  emailName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  processedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailQueueMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailQueueMinFields'] = ResolversParentTypes['EmailQueueMinFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  emailName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  processedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailQueueMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailQueueMutationResponse'] = ResolversParentTypes['EmailQueueMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['EmailQueue']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EnrollOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EnrollOutput'] = ResolversParentTypes['EnrollOutput']
> = {
  enrollmentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  waitlistId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EnrollmentWaitlistsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EnrollmentWaitlists'] = ResolversParentTypes['EnrollmentWaitlists']
> = {
  cohort?: Resolver<Maybe<ResolversTypes['Cohorts']>, ParentType, ContextType>;
  cohortId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  createdOnBehalfOf?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdOnBehalfOfId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedOnBehalfOf?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isNotDeleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  organization?: Resolver<Maybe<ResolversTypes['Organizations']>, ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EnrollmentWaitlistsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EnrollmentWaitlistsAggregate'] = ResolversParentTypes['EnrollmentWaitlistsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['EnrollmentWaitlistsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['EnrollmentWaitlists']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EnrollmentWaitlistsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EnrollmentWaitlistsAggregateFields'] = ResolversParentTypes['EnrollmentWaitlistsAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<EnrollmentWaitlistsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['EnrollmentWaitlistsMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['EnrollmentWaitlistsMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EnrollmentWaitlistsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EnrollmentWaitlistsMaxFields'] = ResolversParentTypes['EnrollmentWaitlistsMaxFields']
> = {
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdOnBehalfOfId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedOnBehalfOf?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EnrollmentWaitlistsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EnrollmentWaitlistsMinFields'] = ResolversParentTypes['EnrollmentWaitlistsMinFields']
> = {
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdOnBehalfOfId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedOnBehalfOf?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EnrollmentWaitlistsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EnrollmentWaitlistsMutationResponse'] = ResolversParentTypes['EnrollmentWaitlistsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['EnrollmentWaitlists']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EnrollmentsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Enrollments'] = ResolversParentTypes['Enrollments']
> = {
  cohort?: Resolver<Maybe<ResolversTypes['Cohorts']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdOnBehalfOf?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdOnBehalfOfId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedOnBehalfOf?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  deletedOnBehalfOfId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  organization?: Resolver<Maybe<ResolversTypes['Organizations']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EnrollmentsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EnrollmentsAggregate'] = ResolversParentTypes['EnrollmentsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['EnrollmentsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['Enrollments']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EnrollmentsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EnrollmentsAggregateFields'] = ResolversParentTypes['EnrollmentsAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<EnrollmentsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['EnrollmentsMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['EnrollmentsMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EnrollmentsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EnrollmentsMaxFields'] = ResolversParentTypes['EnrollmentsMaxFields']
> = {
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdOnBehalfOfId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedOnBehalfOfId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EnrollmentsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EnrollmentsMinFields'] = ResolversParentTypes['EnrollmentsMinFields']
> = {
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdOnBehalfOfId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedOnBehalfOfId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EnrollmentsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EnrollmentsMutationResponse'] = ResolversParentTypes['EnrollmentsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Enrollments']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FusionauthExternalIdentifiersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FusionauthExternalIdentifiers'] = ResolversParentTypes['FusionauthExternalIdentifiers']
> = {
  applicationsId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expirationInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  insertInstant?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  tenantsId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['smallint'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  usersId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FusionauthExternalIdentifiersAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FusionauthExternalIdentifiersAggregate'] = ResolversParentTypes['FusionauthExternalIdentifiersAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['FusionauthExternalIdentifiersAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['FusionauthExternalIdentifiers']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FusionauthExternalIdentifiersAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FusionauthExternalIdentifiersAggregateFields'] = ResolversParentTypes['FusionauthExternalIdentifiersAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['FusionauthExternalIdentifiersAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<FusionauthExternalIdentifiersAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['FusionauthExternalIdentifiersMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['FusionauthExternalIdentifiersMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['FusionauthExternalIdentifiersStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['FusionauthExternalIdentifiersStddevPopFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['FusionauthExternalIdentifiersStddevSampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['FusionauthExternalIdentifiersSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['FusionauthExternalIdentifiersVarPopFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['FusionauthExternalIdentifiersVarSampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['FusionauthExternalIdentifiersVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FusionauthExternalIdentifiersAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FusionauthExternalIdentifiersAvgFields'] = ResolversParentTypes['FusionauthExternalIdentifiersAvgFields']
> = {
  expirationInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FusionauthExternalIdentifiersMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FusionauthExternalIdentifiersMaxFields'] = ResolversParentTypes['FusionauthExternalIdentifiersMaxFields']
> = {
  applicationsId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expirationInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  tenantsId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  usersId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FusionauthExternalIdentifiersMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FusionauthExternalIdentifiersMinFields'] = ResolversParentTypes['FusionauthExternalIdentifiersMinFields']
> = {
  applicationsId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expirationInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  tenantsId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  usersId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FusionauthExternalIdentifiersMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FusionauthExternalIdentifiersMutationResponse'] = ResolversParentTypes['FusionauthExternalIdentifiersMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['FusionauthExternalIdentifiers']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FusionauthExternalIdentifiersStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FusionauthExternalIdentifiersStddevFields'] = ResolversParentTypes['FusionauthExternalIdentifiersStddevFields']
> = {
  expirationInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FusionauthExternalIdentifiersStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FusionauthExternalIdentifiersStddevPopFields'] = ResolversParentTypes['FusionauthExternalIdentifiersStddevPopFields']
> = {
  expirationInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FusionauthExternalIdentifiersStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FusionauthExternalIdentifiersStddevSampFields'] = ResolversParentTypes['FusionauthExternalIdentifiersStddevSampFields']
> = {
  expirationInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FusionauthExternalIdentifiersSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FusionauthExternalIdentifiersSumFields'] = ResolversParentTypes['FusionauthExternalIdentifiersSumFields']
> = {
  expirationInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FusionauthExternalIdentifiersVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FusionauthExternalIdentifiersVarPopFields'] = ResolversParentTypes['FusionauthExternalIdentifiersVarPopFields']
> = {
  expirationInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FusionauthExternalIdentifiersVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FusionauthExternalIdentifiersVarSampFields'] = ResolversParentTypes['FusionauthExternalIdentifiersVarSampFields']
> = {
  expirationInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FusionauthExternalIdentifiersVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FusionauthExternalIdentifiersVarianceFields'] = ResolversParentTypes['FusionauthExternalIdentifiersVarianceFields']
> = {
  expirationInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FusionauthUserRegistrationsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FusionauthUserRegistrations'] = ResolversParentTypes['FusionauthUserRegistrations']
> = {
  applicationsId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  authenticationToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cleanSpeakId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  insertInstant?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  usernameStatus?: Resolver<ResolversTypes['smallint'], ParentType, ContextType>;
  usersId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  verified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FusionauthUserRegistrationsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FusionauthUserRegistrationsAggregate'] = ResolversParentTypes['FusionauthUserRegistrationsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['FusionauthUserRegistrationsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['FusionauthUserRegistrations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FusionauthUserRegistrationsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FusionauthUserRegistrationsAggregateFields'] = ResolversParentTypes['FusionauthUserRegistrationsAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['FusionauthUserRegistrationsAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<FusionauthUserRegistrationsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['FusionauthUserRegistrationsMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['FusionauthUserRegistrationsMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['FusionauthUserRegistrationsStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['FusionauthUserRegistrationsStddevPopFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['FusionauthUserRegistrationsStddevSampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['FusionauthUserRegistrationsSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['FusionauthUserRegistrationsVarPopFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['FusionauthUserRegistrationsVarSampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['FusionauthUserRegistrationsVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FusionauthUserRegistrationsAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FusionauthUserRegistrationsAvgFields'] = ResolversParentTypes['FusionauthUserRegistrationsAvgFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  usernameStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FusionauthUserRegistrationsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FusionauthUserRegistrationsMaxFields'] = ResolversParentTypes['FusionauthUserRegistrationsMaxFields']
> = {
  applicationsId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  authenticationToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cleanSpeakId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  usernameStatus?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  usersId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FusionauthUserRegistrationsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FusionauthUserRegistrationsMinFields'] = ResolversParentTypes['FusionauthUserRegistrationsMinFields']
> = {
  applicationsId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  authenticationToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cleanSpeakId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  usernameStatus?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  usersId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FusionauthUserRegistrationsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FusionauthUserRegistrationsMutationResponse'] = ResolversParentTypes['FusionauthUserRegistrationsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['FusionauthUserRegistrations']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FusionauthUserRegistrationsStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FusionauthUserRegistrationsStddevFields'] = ResolversParentTypes['FusionauthUserRegistrationsStddevFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  usernameStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FusionauthUserRegistrationsStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FusionauthUserRegistrationsStddevPopFields'] = ResolversParentTypes['FusionauthUserRegistrationsStddevPopFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  usernameStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FusionauthUserRegistrationsStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FusionauthUserRegistrationsStddevSampFields'] = ResolversParentTypes['FusionauthUserRegistrationsStddevSampFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  usernameStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FusionauthUserRegistrationsSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FusionauthUserRegistrationsSumFields'] = ResolversParentTypes['FusionauthUserRegistrationsSumFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  usernameStatus?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FusionauthUserRegistrationsVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FusionauthUserRegistrationsVarPopFields'] = ResolversParentTypes['FusionauthUserRegistrationsVarPopFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  usernameStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FusionauthUserRegistrationsVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FusionauthUserRegistrationsVarSampFields'] = ResolversParentTypes['FusionauthUserRegistrationsVarSampFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  usernameStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FusionauthUserRegistrationsVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FusionauthUserRegistrationsVarianceFields'] = ResolversParentTypes['FusionauthUserRegistrationsVarianceFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  usernameStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetCohortEnrollmentStatusOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetCohortEnrollmentStatusOutput'] = ResolversParentTypes['GetCohortEnrollmentStatusOutput']
> = {
  isFull?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  remainingSeats?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetMySessionsAttendedCountOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetMySessionsAttendedCountOutput'] = ResolversParentTypes['GetMySessionsAttendedCountOutput']
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetPastSessionAsyncAverageMetricsOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetPastSessionAsyncAverageMetricsOutput'] = ResolversParentTypes['GetPastSessionAsyncAverageMetricsOutput']
> = {
  asyncMetrics?: Resolver<
    Maybe<ResolversTypes['GetPastSessionAsyncAverageMetricsSnowflakeMetrics']>,
    ParentType,
    ContextType
  >;
  pastSessionsWithMetrics?: Resolver<
    Array<Maybe<ResolversTypes['GetPastSessionAsyncAverageMetricsPastSession']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetPastSessionAsyncAverageMetricsPastSessionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetPastSessionAsyncAverageMetricsPastSession'] = ResolversParentTypes['GetPastSessionAsyncAverageMetricsPastSession']
> = {
  cohortId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  metrics?: Resolver<
    Maybe<ResolversTypes['GetPastSessionAsyncMetricsSnowflakeMetrics']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetPastSessionAsyncAverageMetricsSnowflakeMetricsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetPastSessionAsyncAverageMetricsSnowflakeMetrics'] = ResolversParentTypes['GetPastSessionAsyncAverageMetricsSnowflakeMetrics']
> = {
  averageAttendanceRateMetric?: Resolver<
    Maybe<ResolversTypes['GetPastSessionAsyncMetricsMetric']>,
    ParentType,
    ContextType
  >;
  averageHighConfidenceMetric?: Resolver<
    Maybe<ResolversTypes['GetPastSessionAsyncMetricsMetric']>,
    ParentType,
    ContextType
  >;
  averageHighEngagementMetric?: Resolver<
    Maybe<ResolversTypes['GetPastSessionAsyncMetricsMetric']>,
    ParentType,
    ContextType
  >;
  averageHighJobImpactMetric?: Resolver<
    Maybe<ResolversTypes['GetPastSessionAsyncMetricsMetric']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetPastSessionAsyncMetricsMemberSessionNpsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetPastSessionAsyncMetricsMemberSessionNps'] = ResolversParentTypes['GetPastSessionAsyncMetricsMemberSessionNps']
> = {
  confidenceScore?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  engagementScore?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  jobImpactScore?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  npsScore?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numDealsWhereApplied?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  takeawayQuote?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  whichDeals?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetPastSessionAsyncMetricsMemberWithSessionNpsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetPastSessionAsyncMetricsMemberWithSessionNps'] = ResolversParentTypes['GetPastSessionAsyncMetricsMemberWithSessionNps']
> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  pollSubmission?: Resolver<
    Maybe<ResolversTypes['GetPastSessionAsyncMetricsMemberSessionNps']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetPastSessionAsyncMetricsMetricResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetPastSessionAsyncMetricsMetric'] = ResolversParentTypes['GetPastSessionAsyncMetricsMetric']
> = {
  async?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  benchmark?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetPastSessionAsyncMetricsOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetPastSessionAsyncMetricsOutput'] = ResolversParentTypes['GetPastSessionAsyncMetricsOutput']
> = {
  asyncMetrics?: Resolver<
    Maybe<ResolversTypes['GetPastSessionAsyncMetricsSnowflakeMetrics']>,
    ParentType,
    ContextType
  >;
  sessionMembersWithNPS?: Resolver<
    Array<Maybe<ResolversTypes['GetPastSessionAsyncMetricsMemberWithSessionNps']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetPastSessionAsyncMetricsSnowflakeMetricsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetPastSessionAsyncMetricsSnowflakeMetrics'] = ResolversParentTypes['GetPastSessionAsyncMetricsSnowflakeMetrics']
> = {
  attendanceRateMetric?: Resolver<
    Maybe<ResolversTypes['GetPastSessionAsyncMetricsMetric']>,
    ParentType,
    ContextType
  >;
  highCoachFeedbackMetric?: Resolver<
    Maybe<ResolversTypes['GetPastSessionAsyncMetricsMetric']>,
    ParentType,
    ContextType
  >;
  highConfidenceMetric?: Resolver<
    Maybe<ResolversTypes['GetPastSessionAsyncMetricsMetric']>,
    ParentType,
    ContextType
  >;
  highEngagementMetric?: Resolver<
    Maybe<ResolversTypes['GetPastSessionAsyncMetricsMetric']>,
    ParentType,
    ContextType
  >;
  highJobImpactMetric?: Resolver<
    Maybe<ResolversTypes['GetPastSessionAsyncMetricsMetric']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentitiesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Identities'] = ResolversParentTypes['Identities']
> = {
  breachedPasswordLastCheckedInstant?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  breachedPasswordStatus?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  connectorsId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  encryptionScheme?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  factor?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  insertInstant?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  password?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  passwordChangeReason?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  passwordChangeRequired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  passwordLastUpdateInstant?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  salt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['smallint'], ParentType, ContextType>;
  tenantsId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['Users'], ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  usernameIndex?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  usernameStatus?: Resolver<ResolversTypes['smallint'], ParentType, ContextType>;
  usersId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  verified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentitiesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentitiesAggregate'] = ResolversParentTypes['IdentitiesAggregate']
> = {
  aggregate?: Resolver<Maybe<ResolversTypes['IdentitiesAggregateFields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Identities']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentitiesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentitiesAggregateFields'] = ResolversParentTypes['IdentitiesAggregateFields']
> = {
  avg?: Resolver<Maybe<ResolversTypes['IdentitiesAvgFields']>, ParentType, ContextType>;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<IdentitiesAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['IdentitiesMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['IdentitiesMinFields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['IdentitiesStddevFields']>, ParentType, ContextType>;
  stddevPop?: Resolver<Maybe<ResolversTypes['IdentitiesStddevPopFields']>, ParentType, ContextType>;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['IdentitiesStddevSampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<Maybe<ResolversTypes['IdentitiesSumFields']>, ParentType, ContextType>;
  varPop?: Resolver<Maybe<ResolversTypes['IdentitiesVarPopFields']>, ParentType, ContextType>;
  varSamp?: Resolver<Maybe<ResolversTypes['IdentitiesVarSampFields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['IdentitiesVarianceFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentitiesAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentitiesAvgFields'] = ResolversParentTypes['IdentitiesAvgFields']
> = {
  breachedPasswordLastCheckedInstant?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  breachedPasswordStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  passwordChangeReason?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  passwordLastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  usernameStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentitiesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentitiesMaxFields'] = ResolversParentTypes['IdentitiesMaxFields']
> = {
  breachedPasswordLastCheckedInstant?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  breachedPasswordStatus?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  connectorsId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  encryptionScheme?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  factor?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  password?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passwordChangeReason?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  passwordLastUpdateInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  salt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  tenantsId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  usernameIndex?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  usernameStatus?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  usersId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentitiesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentitiesMinFields'] = ResolversParentTypes['IdentitiesMinFields']
> = {
  breachedPasswordLastCheckedInstant?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  breachedPasswordStatus?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  connectorsId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  encryptionScheme?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  factor?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  password?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passwordChangeReason?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  passwordLastUpdateInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  salt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  tenantsId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  usernameIndex?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  usernameStatus?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  usersId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentitiesMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentitiesMutationResponse'] = ResolversParentTypes['IdentitiesMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Identities']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentitiesStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentitiesStddevFields'] = ResolversParentTypes['IdentitiesStddevFields']
> = {
  breachedPasswordLastCheckedInstant?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  breachedPasswordStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  passwordChangeReason?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  passwordLastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  usernameStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentitiesStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentitiesStddevPopFields'] = ResolversParentTypes['IdentitiesStddevPopFields']
> = {
  breachedPasswordLastCheckedInstant?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  breachedPasswordStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  passwordChangeReason?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  passwordLastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  usernameStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentitiesStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentitiesStddevSampFields'] = ResolversParentTypes['IdentitiesStddevSampFields']
> = {
  breachedPasswordLastCheckedInstant?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  breachedPasswordStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  passwordChangeReason?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  passwordLastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  usernameStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentitiesSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentitiesSumFields'] = ResolversParentTypes['IdentitiesSumFields']
> = {
  breachedPasswordLastCheckedInstant?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  breachedPasswordStatus?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  factor?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  passwordChangeReason?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  passwordLastUpdateInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  usernameStatus?: Resolver<Maybe<ResolversTypes['smallint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentitiesVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentitiesVarPopFields'] = ResolversParentTypes['IdentitiesVarPopFields']
> = {
  breachedPasswordLastCheckedInstant?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  breachedPasswordStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  passwordChangeReason?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  passwordLastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  usernameStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentitiesVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentitiesVarSampFields'] = ResolversParentTypes['IdentitiesVarSampFields']
> = {
  breachedPasswordLastCheckedInstant?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  breachedPasswordStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  passwordChangeReason?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  passwordLastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  usernameStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentitiesVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentitiesVarianceFields'] = ResolversParentTypes['IdentitiesVarianceFields']
> = {
  breachedPasswordLastCheckedInstant?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  breachedPasswordStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  passwordChangeReason?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  passwordLastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  usernameStatus?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityProviderLinksResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityProviderLinks'] = ResolversParentTypes['IdentityProviderLinks']
> = {
  data?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  identityProvider?: Resolver<ResolversTypes['IdentityProviders'], ParentType, ContextType>;
  identityProvidersId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  identityProvidersUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  insertInstant?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  lastLoginInstant?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  tenantsId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['Users'], ParentType, ContextType>;
  usersId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityProviderLinksAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityProviderLinksAggregate'] = ResolversParentTypes['IdentityProviderLinksAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['IdentityProviderLinksAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['IdentityProviderLinks']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityProviderLinksAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityProviderLinksAggregateFields'] = ResolversParentTypes['IdentityProviderLinksAggregateFields']
> = {
  avg?: Resolver<Maybe<ResolversTypes['IdentityProviderLinksAvgFields']>, ParentType, ContextType>;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<IdentityProviderLinksAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['IdentityProviderLinksMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['IdentityProviderLinksMinFields']>, ParentType, ContextType>;
  stddev?: Resolver<
    Maybe<ResolversTypes['IdentityProviderLinksStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['IdentityProviderLinksStddevPopFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['IdentityProviderLinksStddevSampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<Maybe<ResolversTypes['IdentityProviderLinksSumFields']>, ParentType, ContextType>;
  varPop?: Resolver<
    Maybe<ResolversTypes['IdentityProviderLinksVarPopFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['IdentityProviderLinksVarSampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['IdentityProviderLinksVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityProviderLinksAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityProviderLinksAvgFields'] = ResolversParentTypes['IdentityProviderLinksAvgFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityProviderLinksMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityProviderLinksMaxFields'] = ResolversParentTypes['IdentityProviderLinksMaxFields']
> = {
  data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  identityProvidersId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  identityProvidersUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  tenantsId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  usersId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityProviderLinksMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityProviderLinksMinFields'] = ResolversParentTypes['IdentityProviderLinksMinFields']
> = {
  data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  identityProvidersId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  identityProvidersUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  tenantsId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  usersId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityProviderLinksMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityProviderLinksMutationResponse'] = ResolversParentTypes['IdentityProviderLinksMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['IdentityProviderLinks']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityProviderLinksStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityProviderLinksStddevFields'] = ResolversParentTypes['IdentityProviderLinksStddevFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityProviderLinksStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityProviderLinksStddevPopFields'] = ResolversParentTypes['IdentityProviderLinksStddevPopFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityProviderLinksStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityProviderLinksStddevSampFields'] = ResolversParentTypes['IdentityProviderLinksStddevSampFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityProviderLinksSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityProviderLinksSumFields'] = ResolversParentTypes['IdentityProviderLinksSumFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityProviderLinksVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityProviderLinksVarPopFields'] = ResolversParentTypes['IdentityProviderLinksVarPopFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityProviderLinksVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityProviderLinksVarSampFields'] = ResolversParentTypes['IdentityProviderLinksVarSampFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityProviderLinksVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityProviderLinksVarianceFields'] = ResolversParentTypes['IdentityProviderLinksVarianceFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastLoginInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityProvidersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityProviders'] = ResolversParentTypes['IdentityProviders']
> = {
  data?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  identityProviderLinks?: Resolver<
    Array<ResolversTypes['IdentityProviderLinks']>,
    ParentType,
    ContextType,
    RequireFields<IdentityProvidersIdentityProviderLinksArgs, never>
  >;
  identityProviderLinksAggregate?: Resolver<
    ResolversTypes['IdentityProviderLinksAggregate'],
    ParentType,
    ContextType,
    RequireFields<IdentityProvidersIdentityProviderLinksAggregateArgs, never>
  >;
  insertInstant?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  keysId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reconcileLambdasId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  requestSigningKeysId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityProvidersAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityProvidersAggregate'] = ResolversParentTypes['IdentityProvidersAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['IdentityProvidersAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['IdentityProviders']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityProvidersAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityProvidersAggregateFields'] = ResolversParentTypes['IdentityProvidersAggregateFields']
> = {
  avg?: Resolver<Maybe<ResolversTypes['IdentityProvidersAvgFields']>, ParentType, ContextType>;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<IdentityProvidersAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['IdentityProvidersMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['IdentityProvidersMinFields']>, ParentType, ContextType>;
  stddev?: Resolver<
    Maybe<ResolversTypes['IdentityProvidersStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['IdentityProvidersStddevPopFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['IdentityProvidersStddevSampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<Maybe<ResolversTypes['IdentityProvidersSumFields']>, ParentType, ContextType>;
  varPop?: Resolver<
    Maybe<ResolversTypes['IdentityProvidersVarPopFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['IdentityProvidersVarSampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['IdentityProvidersVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityProvidersAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityProvidersAvgFields'] = ResolversParentTypes['IdentityProvidersAvgFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityProvidersMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityProvidersMaxFields'] = ResolversParentTypes['IdentityProvidersMaxFields']
> = {
  data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  keysId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reconcileLambdasId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  requestSigningKeysId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityProvidersMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityProvidersMinFields'] = ResolversParentTypes['IdentityProvidersMinFields']
> = {
  data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  keysId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reconcileLambdasId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  requestSigningKeysId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityProvidersMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityProvidersMutationResponse'] = ResolversParentTypes['IdentityProvidersMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['IdentityProviders']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityProvidersStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityProvidersStddevFields'] = ResolversParentTypes['IdentityProvidersStddevFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityProvidersStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityProvidersStddevPopFields'] = ResolversParentTypes['IdentityProvidersStddevPopFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityProvidersStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityProvidersStddevSampFields'] = ResolversParentTypes['IdentityProvidersStddevSampFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityProvidersSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityProvidersSumFields'] = ResolversParentTypes['IdentityProvidersSumFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityProvidersVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityProvidersVarPopFields'] = ResolversParentTypes['IdentityProvidersVarPopFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityProvidersVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityProvidersVarSampFields'] = ResolversParentTypes['IdentityProvidersVarSampFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityProvidersVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityProvidersVarianceFields'] = ResolversParentTypes['IdentityProvidersVarianceFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentAttemptsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentAttempts'] = ResolversParentTypes['ImpactAssessmentAttempts']
> = {
  cohort?: Resolver<ResolversTypes['Cohorts'], ParentType, ContextType>;
  cohortId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  impactAssessment?: Resolver<Maybe<ResolversTypes['ImpactAssessments']>, ParentType, ContextType>;
  impactAssessmentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  isPassing?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  learnerAnswers?: Resolver<
    Maybe<ResolversTypes['json']>,
    ParentType,
    ContextType,
    RequireFields<ImpactAssessmentAttemptsLearnerAnswersArgs, never>
  >;
  percentCorrect?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  questionIds?: Resolver<
    Maybe<ResolversTypes['json']>,
    ParentType,
    ContextType,
    RequireFields<ImpactAssessmentAttemptsQuestionIdsArgs, never>
  >;
  questions?: Resolver<
    Maybe<Array<ResolversTypes['ImpactAssessmentQuestions']>>,
    ParentType,
    ContextType,
    RequireFields<ImpactAssessmentAttemptsQuestionsArgs, never>
  >;
  remainingTime?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  startedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['AttemptStatusEnum']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentAttemptsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentAttemptsAggregate'] = ResolversParentTypes['ImpactAssessmentAttemptsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentAttemptsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['ImpactAssessmentAttempts']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentAttemptsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentAttemptsAggregateFields'] = ResolversParentTypes['ImpactAssessmentAttemptsAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentAttemptsAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ImpactAssessmentAttemptsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentAttemptsMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentAttemptsMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentAttemptsStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentAttemptsStddevPopFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentAttemptsStddevSampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentAttemptsSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentAttemptsVarPopFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentAttemptsVarSampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentAttemptsVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentAttemptsAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentAttemptsAvgFields'] = ResolversParentTypes['ImpactAssessmentAttemptsAvgFields']
> = {
  percentCorrect?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  remainingTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentAttemptsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentAttemptsMaxFields'] = ResolversParentTypes['ImpactAssessmentAttemptsMaxFields']
> = {
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  impactAssessmentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  percentCorrect?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  remainingTime?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  startedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentAttemptsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentAttemptsMinFields'] = ResolversParentTypes['ImpactAssessmentAttemptsMinFields']
> = {
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  impactAssessmentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  percentCorrect?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  remainingTime?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  startedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentAttemptsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentAttemptsMutationResponse'] = ResolversParentTypes['ImpactAssessmentAttemptsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ImpactAssessmentAttempts']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentAttemptsStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentAttemptsStddevFields'] = ResolversParentTypes['ImpactAssessmentAttemptsStddevFields']
> = {
  percentCorrect?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  remainingTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentAttemptsStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentAttemptsStddevPopFields'] = ResolversParentTypes['ImpactAssessmentAttemptsStddevPopFields']
> = {
  percentCorrect?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  remainingTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentAttemptsStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentAttemptsStddevSampFields'] = ResolversParentTypes['ImpactAssessmentAttemptsStddevSampFields']
> = {
  percentCorrect?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  remainingTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentAttemptsSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentAttemptsSumFields'] = ResolversParentTypes['ImpactAssessmentAttemptsSumFields']
> = {
  percentCorrect?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  remainingTime?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentAttemptsVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentAttemptsVarPopFields'] = ResolversParentTypes['ImpactAssessmentAttemptsVarPopFields']
> = {
  percentCorrect?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  remainingTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentAttemptsVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentAttemptsVarSampFields'] = ResolversParentTypes['ImpactAssessmentAttemptsVarSampFields']
> = {
  percentCorrect?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  remainingTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentAttemptsVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentAttemptsVarianceFields'] = ResolversParentTypes['ImpactAssessmentAttemptsVarianceFields']
> = {
  percentCorrect?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  remainingTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentQuestionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentQuestions'] = ResolversParentTypes['ImpactAssessmentQuestions']
> = {
  answers?: Resolver<
    Maybe<ResolversTypes['json']>,
    ParentType,
    ContextType,
    RequireFields<ImpactAssessmentQuestionsAnswersArgs, never>
  >;
  correctAnswers?: Resolver<
    Maybe<ResolversTypes['json']>,
    ParentType,
    ContextType,
    RequireFields<ImpactAssessmentQuestionsCorrectAnswersArgs, never>
  >;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  impactAssessment?: Resolver<Maybe<ResolversTypes['ImpactAssessments']>, ParentType, ContextType>;
  impactAssessmentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  points?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  questionRich?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  questionType?: Resolver<Maybe<ResolversTypes['QuestionType']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['QuestionTypeEnum']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentQuestionsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentQuestionsAggregate'] = ResolversParentTypes['ImpactAssessmentQuestionsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentQuestionsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['ImpactAssessmentQuestions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentQuestionsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentQuestionsAggregateFields'] = ResolversParentTypes['ImpactAssessmentQuestionsAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentQuestionsAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ImpactAssessmentQuestionsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentQuestionsMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentQuestionsMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentQuestionsStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentQuestionsStddevPopFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentQuestionsStddevSampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentQuestionsSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentQuestionsVarPopFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentQuestionsVarSampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentQuestionsVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentQuestionsAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentQuestionsAvgFields'] = ResolversParentTypes['ImpactAssessmentQuestionsAvgFields']
> = {
  points?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentQuestionsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentQuestionsMaxFields'] = ResolversParentTypes['ImpactAssessmentQuestionsMaxFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  impactAssessmentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  questionRich?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentQuestionsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentQuestionsMinFields'] = ResolversParentTypes['ImpactAssessmentQuestionsMinFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  impactAssessmentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  questionRich?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentQuestionsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentQuestionsMutationResponse'] = ResolversParentTypes['ImpactAssessmentQuestionsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ImpactAssessmentQuestions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentQuestionsStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentQuestionsStddevFields'] = ResolversParentTypes['ImpactAssessmentQuestionsStddevFields']
> = {
  points?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentQuestionsStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentQuestionsStddevPopFields'] = ResolversParentTypes['ImpactAssessmentQuestionsStddevPopFields']
> = {
  points?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentQuestionsStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentQuestionsStddevSampFields'] = ResolversParentTypes['ImpactAssessmentQuestionsStddevSampFields']
> = {
  points?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentQuestionsSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentQuestionsSumFields'] = ResolversParentTypes['ImpactAssessmentQuestionsSumFields']
> = {
  points?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentQuestionsVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentQuestionsVarPopFields'] = ResolversParentTypes['ImpactAssessmentQuestionsVarPopFields']
> = {
  points?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentQuestionsVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentQuestionsVarSampFields'] = ResolversParentTypes['ImpactAssessmentQuestionsVarSampFields']
> = {
  points?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentQuestionsVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentQuestionsVarianceFields'] = ResolversParentTypes['ImpactAssessmentQuestionsVarianceFields']
> = {
  points?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessments'] = ResolversParentTypes['ImpactAssessments']
> = {
  course?: Resolver<Maybe<ResolversTypes['Courses']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  descriptionRich?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dueWithinDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isLastestVersion?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  percentToPass?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  questionCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  questions?: Resolver<
    Array<ResolversTypes['ImpactAssessmentQuestions']>,
    ParentType,
    ContextType,
    RequireFields<ImpactAssessmentsQuestionsArgs, never>
  >;
  questionsAggregate?: Resolver<
    ResolversTypes['ImpactAssessmentQuestionsAggregate'],
    ParentType,
    ContextType,
    RequireFields<ImpactAssessmentsQuestionsAggregateArgs, never>
  >;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timeLimitInMinutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentsAggregate'] = ResolversParentTypes['ImpactAssessmentsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['ImpactAssessments']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentsAggregateFields'] = ResolversParentTypes['ImpactAssessmentsAggregateFields']
> = {
  avg?: Resolver<Maybe<ResolversTypes['ImpactAssessmentsAvgFields']>, ParentType, ContextType>;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ImpactAssessmentsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['ImpactAssessmentsMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ImpactAssessmentsMinFields']>, ParentType, ContextType>;
  stddev?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentsStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentsStddevPopFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentsStddevSampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<Maybe<ResolversTypes['ImpactAssessmentsSumFields']>, ParentType, ContextType>;
  varPop?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentsVarPopFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentsVarSampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentsVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentsAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentsAvgFields'] = ResolversParentTypes['ImpactAssessmentsAvgFields']
> = {
  dueWithinDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentToPass?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  questionCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeLimitInMinutes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentsMaxFields'] = ResolversParentTypes['ImpactAssessmentsMaxFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  descriptionRich?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dueWithinDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  percentToPass?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  questionCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timeLimitInMinutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentsMinFields'] = ResolversParentTypes['ImpactAssessmentsMinFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  descriptionRich?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dueWithinDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  percentToPass?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  questionCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timeLimitInMinutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentsMutationResponse'] = ResolversParentTypes['ImpactAssessmentsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ImpactAssessments']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentsStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentsStddevFields'] = ResolversParentTypes['ImpactAssessmentsStddevFields']
> = {
  dueWithinDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentToPass?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  questionCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeLimitInMinutes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentsStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentsStddevPopFields'] = ResolversParentTypes['ImpactAssessmentsStddevPopFields']
> = {
  dueWithinDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentToPass?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  questionCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeLimitInMinutes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentsStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentsStddevSampFields'] = ResolversParentTypes['ImpactAssessmentsStddevSampFields']
> = {
  dueWithinDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentToPass?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  questionCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeLimitInMinutes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentsSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentsSumFields'] = ResolversParentTypes['ImpactAssessmentsSumFields']
> = {
  dueWithinDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  percentToPass?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  questionCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  timeLimitInMinutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentsVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentsVarPopFields'] = ResolversParentTypes['ImpactAssessmentsVarPopFields']
> = {
  dueWithinDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentToPass?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  questionCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeLimitInMinutes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentsVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentsVarSampFields'] = ResolversParentTypes['ImpactAssessmentsVarSampFields']
> = {
  dueWithinDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentToPass?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  questionCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeLimitInMinutes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactAssessmentsVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImpactAssessmentsVarianceFields'] = ResolversParentTypes['ImpactAssessmentsVarianceFields']
> = {
  dueWithinDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentToPass?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  questionCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeLimitInMinutes?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JourneyEnrollmentsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JourneyEnrollments'] = ResolversParentTypes['JourneyEnrollments']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isComplete?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  journey?: Resolver<Maybe<ResolversTypes['Journeys']>, ParentType, ContextType>;
  journeyId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  meta?: Resolver<
    Maybe<ResolversTypes['jsonb']>,
    ParentType,
    ContextType,
    RequireFields<JourneyEnrollmentsMetaArgs, never>
  >;
  nextTask?: Resolver<Maybe<ResolversTypes['Tasks']>, ParentType, ContextType>;
  nextTaskAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  nextTaskId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  organization?: Resolver<Maybe<ResolversTypes['Organizations']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JourneyEnrollmentsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JourneyEnrollmentsAggregate'] = ResolversParentTypes['JourneyEnrollmentsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['JourneyEnrollmentsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['JourneyEnrollments']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JourneyEnrollmentsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JourneyEnrollmentsAggregateFields'] = ResolversParentTypes['JourneyEnrollmentsAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<JourneyEnrollmentsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['JourneyEnrollmentsMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['JourneyEnrollmentsMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JourneyEnrollmentsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JourneyEnrollmentsMaxFields'] = ResolversParentTypes['JourneyEnrollmentsMaxFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  journeyId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  nextTaskAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  nextTaskId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JourneyEnrollmentsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JourneyEnrollmentsMinFields'] = ResolversParentTypes['JourneyEnrollmentsMinFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  journeyId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  nextTaskAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  nextTaskId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JourneyEnrollmentsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JourneyEnrollmentsMutationResponse'] = ResolversParentTypes['JourneyEnrollmentsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['JourneyEnrollments']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JourneysResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Journeys'] = ResolversParentTypes['Journeys']
> = {
  assignInternalLeader?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  behaviorsToInfluence?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  businessGoalsOutcomes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createSiaSlackChannel?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  cspSfId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  giagFollowUp?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  giagMandatory?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  leadingIndicatorsOfBehaviorChange?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  learnerReview?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lunchLearnReview?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  managerIdentified?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  managerMeetingBooked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organization?: Resolver<Maybe<ResolversTypes['Organizations']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  reinforcementPlanNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  relevantLearningsIdentified?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  roiDelivered?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  roiDeliveredDetails?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  skillGaps?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tasks?: Resolver<
    Array<ResolversTypes['Tasks']>,
    ParentType,
    ContextType,
    RequireFields<JourneysTasksArgs, never>
  >;
  tasksAggregate?: Resolver<
    ResolversTypes['TasksAggregate'],
    ParentType,
    ContextType,
    RequireFields<JourneysTasksAggregateArgs, never>
  >;
  topLearnerIncentive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  watchClasses?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JourneysAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JourneysAggregate'] = ResolversParentTypes['JourneysAggregate']
> = {
  aggregate?: Resolver<Maybe<ResolversTypes['JourneysAggregateFields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Journeys']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JourneysAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JourneysAggregateFields'] = ResolversParentTypes['JourneysAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<JourneysAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['JourneysMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['JourneysMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JourneysMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JourneysMaxFields'] = ResolversParentTypes['JourneysMaxFields']
> = {
  behaviorsToInfluence?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  businessGoalsOutcomes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cspSfId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  leadingIndicatorsOfBehaviorChange?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  reinforcementPlanNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roiDeliveredDetails?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  skillGaps?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JourneysMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JourneysMinFields'] = ResolversParentTypes['JourneysMinFields']
> = {
  behaviorsToInfluence?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  businessGoalsOutcomes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cspSfId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  leadingIndicatorsOfBehaviorChange?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  reinforcementPlanNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roiDeliveredDetails?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  skillGaps?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JourneysMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JourneysMutationResponse'] = ResolversParentTypes['JourneysMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Journeys']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LedgerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Ledger'] = ResolversParentTypes['Ledger']
> = {
  allocatedCreditMovement?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  availableCreditBalance?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  creditBalance?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  creditLedgerId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  creditModel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  creditMovement?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  enrollment?: Resolver<Maybe<ResolversTypes['Enrollments']>, ParentType, ContextType>;
  enrollmentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isCurrentSubscription?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isReconciled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  organization?: Resolver<Maybe<ResolversTypes['Organizations']>, ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  product?: Resolver<Maybe<ResolversTypes['LedgerProductEnumEnum']>, ParentType, ContextType>;
  subscriptionCreditsBought?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  subscriptionEndDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  subscriptionStartDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  totalAllocatedCredits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalCreditsSpent?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  transactionDate?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  transactionType?: Resolver<
    ResolversTypes['LedgerTransactionTypeEnumEnum'],
    ParentType,
    ContextType
  >;
  user?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LedgerAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LedgerAggregate'] = ResolversParentTypes['LedgerAggregate']
> = {
  aggregate?: Resolver<Maybe<ResolversTypes['LedgerAggregateFields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Ledger']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LedgerAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LedgerAggregateFields'] = ResolversParentTypes['LedgerAggregateFields']
> = {
  avg?: Resolver<Maybe<ResolversTypes['LedgerAvgFields']>, ParentType, ContextType>;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<LedgerAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['LedgerMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['LedgerMinFields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['LedgerStddevFields']>, ParentType, ContextType>;
  stddevPop?: Resolver<Maybe<ResolversTypes['LedgerStddevPopFields']>, ParentType, ContextType>;
  stddevSamp?: Resolver<Maybe<ResolversTypes['LedgerStddevSampFields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['LedgerSumFields']>, ParentType, ContextType>;
  varPop?: Resolver<Maybe<ResolversTypes['LedgerVarPopFields']>, ParentType, ContextType>;
  varSamp?: Resolver<Maybe<ResolversTypes['LedgerVarSampFields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['LedgerVarianceFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LedgerAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LedgerAvgFields'] = ResolversParentTypes['LedgerAvgFields']
> = {
  allocatedCreditMovement?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  availableCreditBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  creditBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  creditLedgerId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  creditMovement?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  subscriptionCreditsBought?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAllocatedCredits?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalCreditsSpent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LedgerMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LedgerMaxFields'] = ResolversParentTypes['LedgerMaxFields']
> = {
  allocatedCreditMovement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  availableCreditBalance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  creditBalance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  creditLedgerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  creditModel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  creditMovement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  enrollmentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  subscriptionCreditsBought?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  subscriptionEndDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  subscriptionStartDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  totalAllocatedCredits?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalCreditsSpent?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  transactionDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LedgerMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LedgerMinFields'] = ResolversParentTypes['LedgerMinFields']
> = {
  allocatedCreditMovement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  availableCreditBalance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  creditBalance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  creditLedgerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  creditModel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  creditMovement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  enrollmentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  subscriptionCreditsBought?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  subscriptionEndDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  subscriptionStartDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  totalAllocatedCredits?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalCreditsSpent?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  transactionDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LedgerMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LedgerMutationResponse'] = ResolversParentTypes['LedgerMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Ledger']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LedgerProductEnumResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LedgerProductEnum'] = ResolversParentTypes['LedgerProductEnum']
> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LedgerProductEnumAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LedgerProductEnumAggregate'] = ResolversParentTypes['LedgerProductEnumAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['LedgerProductEnumAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['LedgerProductEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LedgerProductEnumAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LedgerProductEnumAggregateFields'] = ResolversParentTypes['LedgerProductEnumAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<LedgerProductEnumAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['LedgerProductEnumMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['LedgerProductEnumMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LedgerProductEnumMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LedgerProductEnumMaxFields'] = ResolversParentTypes['LedgerProductEnumMaxFields']
> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LedgerProductEnumMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LedgerProductEnumMinFields'] = ResolversParentTypes['LedgerProductEnumMinFields']
> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LedgerProductEnumMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LedgerProductEnumMutationResponse'] = ResolversParentTypes['LedgerProductEnumMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['LedgerProductEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LedgerStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LedgerStddevFields'] = ResolversParentTypes['LedgerStddevFields']
> = {
  allocatedCreditMovement?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  availableCreditBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  creditBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  creditLedgerId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  creditMovement?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  subscriptionCreditsBought?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAllocatedCredits?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalCreditsSpent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LedgerStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LedgerStddevPopFields'] = ResolversParentTypes['LedgerStddevPopFields']
> = {
  allocatedCreditMovement?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  availableCreditBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  creditBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  creditLedgerId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  creditMovement?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  subscriptionCreditsBought?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAllocatedCredits?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalCreditsSpent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LedgerStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LedgerStddevSampFields'] = ResolversParentTypes['LedgerStddevSampFields']
> = {
  allocatedCreditMovement?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  availableCreditBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  creditBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  creditLedgerId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  creditMovement?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  subscriptionCreditsBought?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAllocatedCredits?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalCreditsSpent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LedgerSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LedgerSumFields'] = ResolversParentTypes['LedgerSumFields']
> = {
  allocatedCreditMovement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  availableCreditBalance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  creditBalance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  creditLedgerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  creditMovement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  subscriptionCreditsBought?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalAllocatedCredits?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalCreditsSpent?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LedgerTransactionTypeEnumResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LedgerTransactionTypeEnum'] = ResolversParentTypes['LedgerTransactionTypeEnum']
> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LedgerTransactionTypeEnumAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LedgerTransactionTypeEnumAggregate'] = ResolversParentTypes['LedgerTransactionTypeEnumAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['LedgerTransactionTypeEnumAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['LedgerTransactionTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LedgerTransactionTypeEnumAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LedgerTransactionTypeEnumAggregateFields'] = ResolversParentTypes['LedgerTransactionTypeEnumAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<LedgerTransactionTypeEnumAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['LedgerTransactionTypeEnumMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['LedgerTransactionTypeEnumMinFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LedgerTransactionTypeEnumMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LedgerTransactionTypeEnumMaxFields'] = ResolversParentTypes['LedgerTransactionTypeEnumMaxFields']
> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LedgerTransactionTypeEnumMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LedgerTransactionTypeEnumMinFields'] = ResolversParentTypes['LedgerTransactionTypeEnumMinFields']
> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LedgerTransactionTypeEnumMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LedgerTransactionTypeEnumMutationResponse'] = ResolversParentTypes['LedgerTransactionTypeEnumMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['LedgerTransactionTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LedgerVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LedgerVarPopFields'] = ResolversParentTypes['LedgerVarPopFields']
> = {
  allocatedCreditMovement?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  availableCreditBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  creditBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  creditLedgerId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  creditMovement?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  subscriptionCreditsBought?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAllocatedCredits?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalCreditsSpent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LedgerVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LedgerVarSampFields'] = ResolversParentTypes['LedgerVarSampFields']
> = {
  allocatedCreditMovement?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  availableCreditBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  creditBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  creditLedgerId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  creditMovement?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  subscriptionCreditsBought?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAllocatedCredits?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalCreditsSpent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LedgerVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LedgerVarianceFields'] = ResolversParentTypes['LedgerVarianceFields']
> = {
  allocatedCreditMovement?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  availableCreditBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  creditBalance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  creditLedgerId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  creditMovement?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  subscriptionCreditsBought?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAllocatedCredits?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalCreditsSpent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LightningSession'] = ResolversParentTypes['LightningSession']
> = {
  borDetails?: Resolver<
    Maybe<ResolversTypes['jsonb']>,
    ParentType,
    ContextType,
    RequireFields<LightningSessionBorDetailsArgs, never>
  >;
  classEvent?: Resolver<ResolversTypes['ClassEvents'], ParentType, ContextType>;
  classEventId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['Users'], ParentType, ContextType>;
  createdById?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  endTime?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  rooms?: Resolver<
    ResolversTypes['jsonb'],
    ParentType,
    ContextType,
    RequireFields<LightningSessionRoomsArgs, never>
  >;
  startTime?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LightningSessionAggregate'] = ResolversParentTypes['LightningSessionAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['LightningSessionAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['LightningSession']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LightningSessionAggregateFields'] = ResolversParentTypes['LightningSessionAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<LightningSessionAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['LightningSessionMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['LightningSessionMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LightningSessionMaxFields'] = ResolversParentTypes['LightningSessionMaxFields']
> = {
  classEventId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  endTime?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LightningSessionMinFields'] = ResolversParentTypes['LightningSessionMinFields']
> = {
  classEventId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  endTime?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LightningSessionMutationResponse'] = ResolversParentTypes['LightningSessionMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['LightningSession']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionSubmissionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LightningSessionSubmissions'] = ResolversParentTypes['LightningSessionSubmissions']
> = {
  coach?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  coachEmailSent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  coachUserId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohort?: Resolver<ResolversTypes['Cohorts'], ParentType, ContextType>;
  cohortId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  interactionsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isNotDeleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  learnerEmailSent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  pitchSegment?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingSegments']>,
    ParentType,
    ContextType
  >;
  pitchSegmentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  relatedUsers?: Resolver<
    Array<ResolversTypes['LightningSessionSubmissionsUsersRel']>,
    ParentType,
    ContextType,
    RequireFields<LightningSessionSubmissionsRelatedUsersArgs, never>
  >;
  relatedUsersAggregate?: Resolver<
    ResolversTypes['LightningSessionSubmissionsUsersRelAggregate'],
    ParentType,
    ContextType,
    RequireFields<LightningSessionSubmissionsRelatedUsersAggregateArgs, never>
  >;
  score?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<
    ResolversTypes['lightningSessionSubmissionsStatusEnum'],
    ParentType,
    ContextType
  >;
  streamChannelId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  streamMessages?: Resolver<
    Array<ResolversTypes['StreamMessages']>,
    ParentType,
    ContextType,
    RequireFields<LightningSessionSubmissionsStreamMessagesArgs, never>
  >;
  streamMessagesAggregate?: Resolver<
    ResolversTypes['StreamMessagesAggregate'],
    ParentType,
    ContextType,
    RequireFields<LightningSessionSubmissionsStreamMessagesAggregateArgs, never>
  >;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['Users'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionSubmissionsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LightningSessionSubmissionsAggregate'] = ResolversParentTypes['LightningSessionSubmissionsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['LightningSessionSubmissionsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['LightningSessionSubmissions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionSubmissionsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LightningSessionSubmissionsAggregateFields'] = ResolversParentTypes['LightningSessionSubmissionsAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['LightningSessionSubmissionsAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<LightningSessionSubmissionsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['LightningSessionSubmissionsMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['LightningSessionSubmissionsMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['LightningSessionSubmissionsStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['LightningSessionSubmissionsStddevPopFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['LightningSessionSubmissionsStddevSampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['LightningSessionSubmissionsSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['LightningSessionSubmissionsVarPopFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['LightningSessionSubmissionsVarSampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['LightningSessionSubmissionsVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionSubmissionsAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LightningSessionSubmissionsAvgFields'] = ResolversParentTypes['LightningSessionSubmissionsAvgFields']
> = {
  interactionsCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionSubmissionsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LightningSessionSubmissionsMaxFields'] = ResolversParentTypes['LightningSessionSubmissionsMaxFields']
> = {
  coachUserId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  interactionsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pitchSegmentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  streamChannelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionSubmissionsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LightningSessionSubmissionsMinFields'] = ResolversParentTypes['LightningSessionSubmissionsMinFields']
> = {
  coachUserId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  interactionsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pitchSegmentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  streamChannelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionSubmissionsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LightningSessionSubmissionsMutationResponse'] = ResolversParentTypes['LightningSessionSubmissionsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['LightningSessionSubmissions']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionSubmissionsStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LightningSessionSubmissionsStddevFields'] = ResolversParentTypes['LightningSessionSubmissionsStddevFields']
> = {
  interactionsCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionSubmissionsStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LightningSessionSubmissionsStddevPopFields'] = ResolversParentTypes['LightningSessionSubmissionsStddevPopFields']
> = {
  interactionsCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionSubmissionsStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LightningSessionSubmissionsStddevSampFields'] = ResolversParentTypes['LightningSessionSubmissionsStddevSampFields']
> = {
  interactionsCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionSubmissionsSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LightningSessionSubmissionsSumFields'] = ResolversParentTypes['LightningSessionSubmissionsSumFields']
> = {
  interactionsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionSubmissionsUsersRelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LightningSessionSubmissionsUsersRel'] = ResolversParentTypes['LightningSessionSubmissionsUsersRel']
> = {
  lightningSessionSubmissionId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  submission?: Resolver<ResolversTypes['LightningSessionSubmissions'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['Users'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionSubmissionsUsersRelAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LightningSessionSubmissionsUsersRelAggregate'] = ResolversParentTypes['LightningSessionSubmissionsUsersRelAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['LightningSessionSubmissionsUsersRelAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['LightningSessionSubmissionsUsersRel']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionSubmissionsUsersRelAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LightningSessionSubmissionsUsersRelAggregateFields'] = ResolversParentTypes['LightningSessionSubmissionsUsersRelAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<LightningSessionSubmissionsUsersRelAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['LightningSessionSubmissionsUsersRelMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['LightningSessionSubmissionsUsersRelMinFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionSubmissionsUsersRelMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LightningSessionSubmissionsUsersRelMaxFields'] = ResolversParentTypes['LightningSessionSubmissionsUsersRelMaxFields']
> = {
  lightningSessionSubmissionId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionSubmissionsUsersRelMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LightningSessionSubmissionsUsersRelMinFields'] = ResolversParentTypes['LightningSessionSubmissionsUsersRelMinFields']
> = {
  lightningSessionSubmissionId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionSubmissionsUsersRelMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LightningSessionSubmissionsUsersRelMutationResponse'] = ResolversParentTypes['LightningSessionSubmissionsUsersRelMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['LightningSessionSubmissionsUsersRel']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionSubmissionsVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LightningSessionSubmissionsVarPopFields'] = ResolversParentTypes['LightningSessionSubmissionsVarPopFields']
> = {
  interactionsCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionSubmissionsVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LightningSessionSubmissionsVarSampFields'] = ResolversParentTypes['LightningSessionSubmissionsVarSampFields']
> = {
  interactionsCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionSubmissionsVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LightningSessionSubmissionsVarianceFields'] = ResolversParentTypes['LightningSessionSubmissionsVarianceFields']
> = {
  interactionsCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LiveClassesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LiveClasses'] = ResolversParentTypes['LiveClasses']
> = {
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  totalActionableClasses?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LiveClassesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LiveClassesAggregate'] = ResolversParentTypes['LiveClassesAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['LiveClassesAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['LiveClasses']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LiveClassesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LiveClassesAggregateFields'] = ResolversParentTypes['LiveClassesAggregateFields']
> = {
  avg?: Resolver<Maybe<ResolversTypes['LiveClassesAvgFields']>, ParentType, ContextType>;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<LiveClassesAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['LiveClassesMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['LiveClassesMinFields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['LiveClassesStddevFields']>, ParentType, ContextType>;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['LiveClassesStddevPopFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['LiveClassesStddevSampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<Maybe<ResolversTypes['LiveClassesSumFields']>, ParentType, ContextType>;
  varPop?: Resolver<Maybe<ResolversTypes['LiveClassesVarPopFields']>, ParentType, ContextType>;
  varSamp?: Resolver<Maybe<ResolversTypes['LiveClassesVarSampFields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['LiveClassesVarianceFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LiveClassesAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LiveClassesAvgFields'] = ResolversParentTypes['LiveClassesAvgFields']
> = {
  totalActionableClasses?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LiveClassesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LiveClassesMaxFields'] = ResolversParentTypes['LiveClassesMaxFields']
> = {
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  totalActionableClasses?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LiveClassesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LiveClassesMinFields'] = ResolversParentTypes['LiveClassesMinFields']
> = {
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  totalActionableClasses?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LiveClassesStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LiveClassesStddevFields'] = ResolversParentTypes['LiveClassesStddevFields']
> = {
  totalActionableClasses?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LiveClassesStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LiveClassesStddevPopFields'] = ResolversParentTypes['LiveClassesStddevPopFields']
> = {
  totalActionableClasses?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LiveClassesStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LiveClassesStddevSampFields'] = ResolversParentTypes['LiveClassesStddevSampFields']
> = {
  totalActionableClasses?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LiveClassesSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LiveClassesSumFields'] = ResolversParentTypes['LiveClassesSumFields']
> = {
  totalActionableClasses?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LiveClassesVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LiveClassesVarPopFields'] = ResolversParentTypes['LiveClassesVarPopFields']
> = {
  totalActionableClasses?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LiveClassesVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LiveClassesVarSampFields'] = ResolversParentTypes['LiveClassesVarSampFields']
> = {
  totalActionableClasses?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LiveClassesVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LiveClassesVarianceFields'] = ResolversParentTypes['LiveClassesVarianceFields']
> = {
  totalActionableClasses?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagerSessionEmailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ManagerSessionEmails'] = ResolversParentTypes['ManagerSessionEmails']
> = {
  attendeesCommaSeparatedList?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  avgImpactScore?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  cohort?: Resolver<ResolversTypes['Cohorts'], ParentType, ContextType>;
  cohortId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  courseName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  coursePlatformUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  creditModel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  csmEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  csmFullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  csmSignature?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrolleeEmails?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  enrolleesCommaSeparatedList?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  enrolleesForPast?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  enrolleesForUpcoming?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstClassDuration?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  firstClassStartTime?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  frameworkHighlight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasPrivateBor?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasSentPostSessionEmail?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasSentPreSessionEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  highImpactAttendees?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isAllFeedbackSkippedOrReady?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isStartingSoon?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isWithinLast7Days?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  managerCohortId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  managerId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  managerPastSessionUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  managerUpcomingSessionUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numHighImpactAttendees?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  organization?: Resolver<ResolversTypes['Organizations'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  percentUsersAttended?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  posterFrameUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recommendations?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sentPostSessionEmailAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  sentPreSessionEmailAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  skills?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTimeFormatted?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['Users'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagerSessionEmailsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ManagerSessionEmailsAggregate'] = ResolversParentTypes['ManagerSessionEmailsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['ManagerSessionEmailsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['ManagerSessionEmails']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagerSessionEmailsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ManagerSessionEmailsAggregateFields'] = ResolversParentTypes['ManagerSessionEmailsAggregateFields']
> = {
  avg?: Resolver<Maybe<ResolversTypes['ManagerSessionEmailsAvgFields']>, ParentType, ContextType>;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ManagerSessionEmailsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['ManagerSessionEmailsMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ManagerSessionEmailsMinFields']>, ParentType, ContextType>;
  stddev?: Resolver<
    Maybe<ResolversTypes['ManagerSessionEmailsStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['ManagerSessionEmailsStddevPopFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['ManagerSessionEmailsStddevSampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<Maybe<ResolversTypes['ManagerSessionEmailsSumFields']>, ParentType, ContextType>;
  varPop?: Resolver<
    Maybe<ResolversTypes['ManagerSessionEmailsVarPopFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['ManagerSessionEmailsVarSampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['ManagerSessionEmailsVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagerSessionEmailsAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ManagerSessionEmailsAvgFields'] = ResolversParentTypes['ManagerSessionEmailsAvgFields']
> = {
  avgImpactScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  firstClassDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numHighImpactAttendees?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentUsersAttended?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagerSessionEmailsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ManagerSessionEmailsMaxFields'] = ResolversParentTypes['ManagerSessionEmailsMaxFields']
> = {
  attendeesCommaSeparatedList?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  avgImpactScore?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  courseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  coursePlatformUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  creditModel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  csmEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  csmFullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  csmSignature?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrolleeEmails?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrolleesCommaSeparatedList?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrolleesForPast?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrolleesForUpcoming?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstClassDuration?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  firstClassStartTime?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  frameworkHighlight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  highImpactAttendees?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  managerCohortId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  managerId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  managerPastSessionUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  managerUpcomingSessionUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  numHighImpactAttendees?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  percentUsersAttended?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  posterFrameUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recommendations?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sentPostSessionEmailAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  sentPreSessionEmailAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  skills?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTimeFormatted?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagerSessionEmailsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ManagerSessionEmailsMinFields'] = ResolversParentTypes['ManagerSessionEmailsMinFields']
> = {
  attendeesCommaSeparatedList?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  avgImpactScore?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  courseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  coursePlatformUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  creditModel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  csmEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  csmFullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  csmSignature?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrolleeEmails?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrolleesCommaSeparatedList?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrolleesForPast?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrolleesForUpcoming?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstClassDuration?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  firstClassStartTime?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  frameworkHighlight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  highImpactAttendees?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  managerCohortId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  managerId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  managerPastSessionUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  managerUpcomingSessionUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  numHighImpactAttendees?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  percentUsersAttended?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  posterFrameUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recommendations?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sentPostSessionEmailAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  sentPreSessionEmailAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  skills?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTimeFormatted?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagerSessionEmailsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ManagerSessionEmailsMutationResponse'] = ResolversParentTypes['ManagerSessionEmailsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ManagerSessionEmails']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagerSessionEmailsStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ManagerSessionEmailsStddevFields'] = ResolversParentTypes['ManagerSessionEmailsStddevFields']
> = {
  avgImpactScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  firstClassDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numHighImpactAttendees?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentUsersAttended?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagerSessionEmailsStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ManagerSessionEmailsStddevPopFields'] = ResolversParentTypes['ManagerSessionEmailsStddevPopFields']
> = {
  avgImpactScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  firstClassDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numHighImpactAttendees?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentUsersAttended?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagerSessionEmailsStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ManagerSessionEmailsStddevSampFields'] = ResolversParentTypes['ManagerSessionEmailsStddevSampFields']
> = {
  avgImpactScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  firstClassDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numHighImpactAttendees?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentUsersAttended?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagerSessionEmailsSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ManagerSessionEmailsSumFields'] = ResolversParentTypes['ManagerSessionEmailsSumFields']
> = {
  avgImpactScore?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  firstClassDuration?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  numHighImpactAttendees?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  percentUsersAttended?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagerSessionEmailsVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ManagerSessionEmailsVarPopFields'] = ResolversParentTypes['ManagerSessionEmailsVarPopFields']
> = {
  avgImpactScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  firstClassDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numHighImpactAttendees?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentUsersAttended?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagerSessionEmailsVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ManagerSessionEmailsVarSampFields'] = ResolversParentTypes['ManagerSessionEmailsVarSampFields']
> = {
  avgImpactScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  firstClassDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numHighImpactAttendees?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentUsersAttended?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagerSessionEmailsVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ManagerSessionEmailsVarianceFields'] = ResolversParentTypes['ManagerSessionEmailsVarianceFields']
> = {
  avgImpactScore?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  firstClassDuration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numHighImpactAttendees?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentUsersAttended?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarkRecordingWatchedOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MarkRecordingWatchedOutput'] = ResolversParentTypes['MarkRecordingWatchedOutput']
> = {
  done?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyCohortLearningStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyCohortLearningStatus'] = ResolversParentTypes['MyCohortLearningStatus']
> = {
  assessmentPassed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  certDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  classCount?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  cohortCompleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  cohortDayOfWeek?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortEnd?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortHasPrework?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortInAppVideo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  cohortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortRequiresPractice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  cohortSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortStart?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortTimeOfDay?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseBackgroundImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  courseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrolledAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  hasUserCompletedAllClasses?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasUserSkippedPractice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isFeedbackReady?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isOnTrack?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isWaitlisted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastCompletedClassDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  nextClassDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  schoolName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  schoolSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyCohortLearningStatusAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyCohortLearningStatusAggregate'] = ResolversParentTypes['MyCohortLearningStatusAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['MyCohortLearningStatusAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['MyCohortLearningStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyCohortLearningStatusAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyCohortLearningStatusAggregateFields'] = ResolversParentTypes['MyCohortLearningStatusAggregateFields']
> = {
  avg?: Resolver<Maybe<ResolversTypes['MyCohortLearningStatusAvgFields']>, ParentType, ContextType>;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<MyCohortLearningStatusAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['MyCohortLearningStatusMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['MyCohortLearningStatusMinFields']>, ParentType, ContextType>;
  stddev?: Resolver<
    Maybe<ResolversTypes['MyCohortLearningStatusStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['MyCohortLearningStatusStddevPopFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['MyCohortLearningStatusStddevSampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<Maybe<ResolversTypes['MyCohortLearningStatusSumFields']>, ParentType, ContextType>;
  varPop?: Resolver<
    Maybe<ResolversTypes['MyCohortLearningStatusVarPopFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['MyCohortLearningStatusVarSampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['MyCohortLearningStatusVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyCohortLearningStatusAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyCohortLearningStatusAvgFields'] = ResolversParentTypes['MyCohortLearningStatusAvgFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyCohortLearningStatusMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyCohortLearningStatusMaxFields'] = ResolversParentTypes['MyCohortLearningStatusMaxFields']
> = {
  certDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  classCount?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  cohortDayOfWeek?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortEnd?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortStart?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortTimeOfDay?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseBackgroundImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  courseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrolledAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  lastCompletedClassDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  nextClassDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  schoolName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  schoolSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyCohortLearningStatusMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyCohortLearningStatusMinFields'] = ResolversParentTypes['MyCohortLearningStatusMinFields']
> = {
  certDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  classCount?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  cohortDayOfWeek?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortEnd?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortStart?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortTimeOfDay?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseBackgroundImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  courseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrolledAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  lastCompletedClassDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  nextClassDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  schoolName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  schoolSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyCohortLearningStatusStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyCohortLearningStatusStddevFields'] = ResolversParentTypes['MyCohortLearningStatusStddevFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyCohortLearningStatusStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyCohortLearningStatusStddevPopFields'] = ResolversParentTypes['MyCohortLearningStatusStddevPopFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyCohortLearningStatusStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyCohortLearningStatusStddevSampFields'] = ResolversParentTypes['MyCohortLearningStatusStddevSampFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyCohortLearningStatusSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyCohortLearningStatusSumFields'] = ResolversParentTypes['MyCohortLearningStatusSumFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyCohortLearningStatusVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyCohortLearningStatusVarPopFields'] = ResolversParentTypes['MyCohortLearningStatusVarPopFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyCohortLearningStatusVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyCohortLearningStatusVarSampFields'] = ResolversParentTypes['MyCohortLearningStatusVarSampFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyCohortLearningStatusVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyCohortLearningStatusVarianceFields'] = ResolversParentTypes['MyCohortLearningStatusVarianceFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLearnerHomeCoursesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLearnerHomeCourses'] = ResolversParentTypes['MyLearnerHomeCourses']
> = {
  assessmentPassed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  certDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  classCount?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  cohort?: Resolver<Maybe<ResolversTypes['Cohorts']>, ParentType, ContextType>;
  cohortCompleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  cohortDayOfWeek?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortEnd?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortHasPrework?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortInAppVideo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  cohortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortRequiresPractice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  cohortSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortStart?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortTimeOfDay?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseBackgroundImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  courseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrolledAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  hasUserCompletedAllClasses?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasUserSkippedPractice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isFeedbackReady?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isOnTrack?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastCompletedClassDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  nextClassDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  schoolName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  schoolSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tags?: Resolver<
    Array<ResolversTypes['CoursesTagsRel']>,
    ParentType,
    ContextType,
    RequireFields<MyLearnerHomeCoursesTagsArgs, never>
  >;
  tagsAggregate?: Resolver<
    ResolversTypes['CoursesTagsRelAggregate'],
    ParentType,
    ContextType,
    RequireFields<MyLearnerHomeCoursesTagsAggregateArgs, never>
  >;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLearnerHomeCoursesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLearnerHomeCoursesAggregate'] = ResolversParentTypes['MyLearnerHomeCoursesAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['MyLearnerHomeCoursesAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['MyLearnerHomeCourses']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLearnerHomeCoursesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLearnerHomeCoursesAggregateFields'] = ResolversParentTypes['MyLearnerHomeCoursesAggregateFields']
> = {
  avg?: Resolver<Maybe<ResolversTypes['MyLearnerHomeCoursesAvgFields']>, ParentType, ContextType>;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<MyLearnerHomeCoursesAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['MyLearnerHomeCoursesMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['MyLearnerHomeCoursesMinFields']>, ParentType, ContextType>;
  stddev?: Resolver<
    Maybe<ResolversTypes['MyLearnerHomeCoursesStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['MyLearnerHomeCoursesStddevPopFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['MyLearnerHomeCoursesStddevSampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<Maybe<ResolversTypes['MyLearnerHomeCoursesSumFields']>, ParentType, ContextType>;
  varPop?: Resolver<
    Maybe<ResolversTypes['MyLearnerHomeCoursesVarPopFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['MyLearnerHomeCoursesVarSampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['MyLearnerHomeCoursesVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLearnerHomeCoursesAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLearnerHomeCoursesAvgFields'] = ResolversParentTypes['MyLearnerHomeCoursesAvgFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLearnerHomeCoursesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLearnerHomeCoursesMaxFields'] = ResolversParentTypes['MyLearnerHomeCoursesMaxFields']
> = {
  certDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  classCount?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  cohortDayOfWeek?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortEnd?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortStart?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortTimeOfDay?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseBackgroundImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  courseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrolledAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  lastCompletedClassDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  nextClassDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  schoolName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  schoolSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLearnerHomeCoursesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLearnerHomeCoursesMinFields'] = ResolversParentTypes['MyLearnerHomeCoursesMinFields']
> = {
  certDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  classCount?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  cohortDayOfWeek?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortEnd?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortStart?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortTimeOfDay?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseBackgroundImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  courseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrolledAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  lastCompletedClassDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  nextClassDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  schoolName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  schoolSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLearnerHomeCoursesStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLearnerHomeCoursesStddevFields'] = ResolversParentTypes['MyLearnerHomeCoursesStddevFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLearnerHomeCoursesStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLearnerHomeCoursesStddevPopFields'] = ResolversParentTypes['MyLearnerHomeCoursesStddevPopFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLearnerHomeCoursesStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLearnerHomeCoursesStddevSampFields'] = ResolversParentTypes['MyLearnerHomeCoursesStddevSampFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLearnerHomeCoursesSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLearnerHomeCoursesSumFields'] = ResolversParentTypes['MyLearnerHomeCoursesSumFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLearnerHomeCoursesVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLearnerHomeCoursesVarPopFields'] = ResolversParentTypes['MyLearnerHomeCoursesVarPopFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLearnerHomeCoursesVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLearnerHomeCoursesVarSampFields'] = ResolversParentTypes['MyLearnerHomeCoursesVarSampFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLearnerHomeCoursesVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLearnerHomeCoursesVarianceFields'] = ResolversParentTypes['MyLearnerHomeCoursesVarianceFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLearnerHomeLiveCoachingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLearnerHomeLiveCoaching'] = ResolversParentTypes['MyLearnerHomeLiveCoaching']
> = {
  assessmentPassed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  certDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  classCount?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  cohortCompleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  cohortDayOfWeek?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortEnd?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortHasPrework?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortInAppVideo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  cohortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortRequiresPractice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  cohortSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortStart?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortTimeOfDay?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseBackgroundImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  courseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrolledAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  hasUserCompletedAllClasses?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasUserSkippedPractice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isFeedbackReady?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isOnTrack?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isWaitlisted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastCompletedClassDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  nextClassDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  schoolName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  schoolSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tags?: Resolver<
    Array<ResolversTypes['CoursesTagsRel']>,
    ParentType,
    ContextType,
    RequireFields<MyLearnerHomeLiveCoachingTagsArgs, never>
  >;
  tagsAggregate?: Resolver<
    ResolversTypes['CoursesTagsRelAggregate'],
    ParentType,
    ContextType,
    RequireFields<MyLearnerHomeLiveCoachingTagsAggregateArgs, never>
  >;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLearnerHomeLiveCoachingAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLearnerHomeLiveCoachingAggregate'] = ResolversParentTypes['MyLearnerHomeLiveCoachingAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['MyLearnerHomeLiveCoachingAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['MyLearnerHomeLiveCoaching']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLearnerHomeLiveCoachingAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLearnerHomeLiveCoachingAggregateFields'] = ResolversParentTypes['MyLearnerHomeLiveCoachingAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['MyLearnerHomeLiveCoachingAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<MyLearnerHomeLiveCoachingAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['MyLearnerHomeLiveCoachingMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['MyLearnerHomeLiveCoachingMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['MyLearnerHomeLiveCoachingStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['MyLearnerHomeLiveCoachingStddevPopFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['MyLearnerHomeLiveCoachingStddevSampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['MyLearnerHomeLiveCoachingSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['MyLearnerHomeLiveCoachingVarPopFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['MyLearnerHomeLiveCoachingVarSampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['MyLearnerHomeLiveCoachingVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLearnerHomeLiveCoachingAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLearnerHomeLiveCoachingAvgFields'] = ResolversParentTypes['MyLearnerHomeLiveCoachingAvgFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLearnerHomeLiveCoachingMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLearnerHomeLiveCoachingMaxFields'] = ResolversParentTypes['MyLearnerHomeLiveCoachingMaxFields']
> = {
  certDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  classCount?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  cohortDayOfWeek?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortEnd?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortStart?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortTimeOfDay?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseBackgroundImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  courseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrolledAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  lastCompletedClassDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  nextClassDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  schoolName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  schoolSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLearnerHomeLiveCoachingMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLearnerHomeLiveCoachingMinFields'] = ResolversParentTypes['MyLearnerHomeLiveCoachingMinFields']
> = {
  certDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  classCount?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  cohortDayOfWeek?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortEnd?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortStart?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortTimeOfDay?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseBackgroundImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  courseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrolledAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  lastCompletedClassDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  nextClassDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  schoolName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  schoolSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLearnerHomeLiveCoachingStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLearnerHomeLiveCoachingStddevFields'] = ResolversParentTypes['MyLearnerHomeLiveCoachingStddevFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLearnerHomeLiveCoachingStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLearnerHomeLiveCoachingStddevPopFields'] = ResolversParentTypes['MyLearnerHomeLiveCoachingStddevPopFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLearnerHomeLiveCoachingStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLearnerHomeLiveCoachingStddevSampFields'] = ResolversParentTypes['MyLearnerHomeLiveCoachingStddevSampFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLearnerHomeLiveCoachingSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLearnerHomeLiveCoachingSumFields'] = ResolversParentTypes['MyLearnerHomeLiveCoachingSumFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLearnerHomeLiveCoachingVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLearnerHomeLiveCoachingVarPopFields'] = ResolversParentTypes['MyLearnerHomeLiveCoachingVarPopFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLearnerHomeLiveCoachingVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLearnerHomeLiveCoachingVarSampFields'] = ResolversParentTypes['MyLearnerHomeLiveCoachingVarSampFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLearnerHomeLiveCoachingVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLearnerHomeLiveCoachingVarianceFields'] = ResolversParentTypes['MyLearnerHomeLiveCoachingVarianceFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLightningSessionCohortsLearningStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLightningSessionCohortsLearningStatus'] = ResolversParentTypes['MyLightningSessionCohortsLearningStatus']
> = {
  assessmentPassed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  certDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  classCount?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  cohortCompleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  cohortDayOfWeek?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortEnd?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortHasPrework?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortInAppVideo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  cohortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortRequiresPractice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  cohortSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortStart?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortTimeOfDay?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseBackgroundImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  courseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrolledAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  hasUserCompletedAllClasses?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasUserSkippedPractice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isFeedbackReady?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isOnTrack?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isWaitlisted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastCompletedClassDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  nextClassDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  schoolName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  schoolSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLightningSessionCohortsLearningStatusAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLightningSessionCohortsLearningStatusAggregate'] = ResolversParentTypes['MyLightningSessionCohortsLearningStatusAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['MyLightningSessionCohortsLearningStatusAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['MyLightningSessionCohortsLearningStatus']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLightningSessionCohortsLearningStatusAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLightningSessionCohortsLearningStatusAggregateFields'] = ResolversParentTypes['MyLightningSessionCohortsLearningStatusAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['MyLightningSessionCohortsLearningStatusAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<MyLightningSessionCohortsLearningStatusAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['MyLightningSessionCohortsLearningStatusMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['MyLightningSessionCohortsLearningStatusMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['MyLightningSessionCohortsLearningStatusStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['MyLightningSessionCohortsLearningStatusStddevPopFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['MyLightningSessionCohortsLearningStatusStddevSampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['MyLightningSessionCohortsLearningStatusSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['MyLightningSessionCohortsLearningStatusVarPopFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['MyLightningSessionCohortsLearningStatusVarSampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['MyLightningSessionCohortsLearningStatusVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLightningSessionCohortsLearningStatusAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLightningSessionCohortsLearningStatusAvgFields'] = ResolversParentTypes['MyLightningSessionCohortsLearningStatusAvgFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLightningSessionCohortsLearningStatusMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLightningSessionCohortsLearningStatusMaxFields'] = ResolversParentTypes['MyLightningSessionCohortsLearningStatusMaxFields']
> = {
  certDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  classCount?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  cohortDayOfWeek?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortEnd?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortStart?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortTimeOfDay?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseBackgroundImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  courseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrolledAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  lastCompletedClassDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  nextClassDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  schoolName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  schoolSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLightningSessionCohortsLearningStatusMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLightningSessionCohortsLearningStatusMinFields'] = ResolversParentTypes['MyLightningSessionCohortsLearningStatusMinFields']
> = {
  certDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  classCount?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  cohortDayOfWeek?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortEnd?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortStart?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortTimeOfDay?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseBackgroundImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  courseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrolledAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  lastCompletedClassDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  nextClassDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  schoolName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  schoolSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLightningSessionCohortsLearningStatusStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLightningSessionCohortsLearningStatusStddevFields'] = ResolversParentTypes['MyLightningSessionCohortsLearningStatusStddevFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLightningSessionCohortsLearningStatusStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLightningSessionCohortsLearningStatusStddevPopFields'] = ResolversParentTypes['MyLightningSessionCohortsLearningStatusStddevPopFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLightningSessionCohortsLearningStatusStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLightningSessionCohortsLearningStatusStddevSampFields'] = ResolversParentTypes['MyLightningSessionCohortsLearningStatusStddevSampFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLightningSessionCohortsLearningStatusSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLightningSessionCohortsLearningStatusSumFields'] = ResolversParentTypes['MyLightningSessionCohortsLearningStatusSumFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLightningSessionCohortsLearningStatusVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLightningSessionCohortsLearningStatusVarPopFields'] = ResolversParentTypes['MyLightningSessionCohortsLearningStatusVarPopFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLightningSessionCohortsLearningStatusVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLightningSessionCohortsLearningStatusVarSampFields'] = ResolversParentTypes['MyLightningSessionCohortsLearningStatusVarSampFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyLightningSessionCohortsLearningStatusVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyLightningSessionCohortsLearningStatusVarianceFields'] = ResolversParentTypes['MyLightningSessionCohortsLearningStatusVarianceFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyTeamMemberResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyTeamMember'] = ResolversParentTypes['MyTeamMember']
> = {
  avatarUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  teamId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyTeamMembersOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyTeamMembersOutput'] = ResolversParentTypes['MyTeamMembersOutput']
> = {
  members?: Resolver<Maybe<Array<ResolversTypes['MyTeamMember']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyTraditionalCohortsLearningStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyTraditionalCohortsLearningStatus'] = ResolversParentTypes['MyTraditionalCohortsLearningStatus']
> = {
  assessmentPassed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  certDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  classCount?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  cohortCompleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  cohortDayOfWeek?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortEnd?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortHasPrework?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortInAppVideo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  cohortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortRequiresPractice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  cohortSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortStart?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortTimeOfDay?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseBackgroundImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  courseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrolledAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  hasUserCompletedAllClasses?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasUserSkippedPractice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isFeedbackReady?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isOnTrack?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastCompletedClassDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  nextClassDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  schoolName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  schoolSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyTraditionalCohortsLearningStatusAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyTraditionalCohortsLearningStatusAggregate'] = ResolversParentTypes['MyTraditionalCohortsLearningStatusAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['MyTraditionalCohortsLearningStatusAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['MyTraditionalCohortsLearningStatus']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyTraditionalCohortsLearningStatusAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyTraditionalCohortsLearningStatusAggregateFields'] = ResolversParentTypes['MyTraditionalCohortsLearningStatusAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['MyTraditionalCohortsLearningStatusAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<MyTraditionalCohortsLearningStatusAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['MyTraditionalCohortsLearningStatusMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['MyTraditionalCohortsLearningStatusMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['MyTraditionalCohortsLearningStatusStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['MyTraditionalCohortsLearningStatusStddevPopFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['MyTraditionalCohortsLearningStatusStddevSampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['MyTraditionalCohortsLearningStatusSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['MyTraditionalCohortsLearningStatusVarPopFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['MyTraditionalCohortsLearningStatusVarSampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['MyTraditionalCohortsLearningStatusVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyTraditionalCohortsLearningStatusAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyTraditionalCohortsLearningStatusAvgFields'] = ResolversParentTypes['MyTraditionalCohortsLearningStatusAvgFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyTraditionalCohortsLearningStatusMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyTraditionalCohortsLearningStatusMaxFields'] = ResolversParentTypes['MyTraditionalCohortsLearningStatusMaxFields']
> = {
  certDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  classCount?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  cohortDayOfWeek?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortEnd?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortStart?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortTimeOfDay?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseBackgroundImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  courseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrolledAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  lastCompletedClassDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  nextClassDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  schoolName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  schoolSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyTraditionalCohortsLearningStatusMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyTraditionalCohortsLearningStatusMinFields'] = ResolversParentTypes['MyTraditionalCohortsLearningStatusMinFields']
> = {
  certDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  classCount?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  cohortDayOfWeek?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortEnd?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortStart?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortTimeOfDay?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseBackgroundImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  courseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrolledAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  lastCompletedClassDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  nextClassDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  schoolName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  schoolSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyTraditionalCohortsLearningStatusStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyTraditionalCohortsLearningStatusStddevFields'] = ResolversParentTypes['MyTraditionalCohortsLearningStatusStddevFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyTraditionalCohortsLearningStatusStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyTraditionalCohortsLearningStatusStddevPopFields'] = ResolversParentTypes['MyTraditionalCohortsLearningStatusStddevPopFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyTraditionalCohortsLearningStatusStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyTraditionalCohortsLearningStatusStddevSampFields'] = ResolversParentTypes['MyTraditionalCohortsLearningStatusStddevSampFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyTraditionalCohortsLearningStatusSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyTraditionalCohortsLearningStatusSumFields'] = ResolversParentTypes['MyTraditionalCohortsLearningStatusSumFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['float8']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyTraditionalCohortsLearningStatusVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyTraditionalCohortsLearningStatusVarPopFields'] = ResolversParentTypes['MyTraditionalCohortsLearningStatusVarPopFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyTraditionalCohortsLearningStatusVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyTraditionalCohortsLearningStatusVarSampFields'] = ResolversParentTypes['MyTraditionalCohortsLearningStatusVarSampFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyTraditionalCohortsLearningStatusVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyTraditionalCohortsLearningStatusVarianceFields'] = ResolversParentTypes['MyTraditionalCohortsLearningStatusVarianceFields']
> = {
  classCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numTasksRequired?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalAssessments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NonceAccessTokenResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NonceAccessToken'] = ResolversParentTypes['NonceAccessToken']
> = {
  createdAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  expirationDate?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  targetResource?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NonceAccessTokenAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NonceAccessTokenAggregate'] = ResolversParentTypes['NonceAccessTokenAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['NonceAccessTokenAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['NonceAccessToken']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NonceAccessTokenAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NonceAccessTokenAggregateFields'] = ResolversParentTypes['NonceAccessTokenAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<NonceAccessTokenAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['NonceAccessTokenMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['NonceAccessTokenMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NonceAccessTokenMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NonceAccessTokenMaxFields'] = ResolversParentTypes['NonceAccessTokenMaxFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  expirationDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  targetResource?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NonceAccessTokenMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NonceAccessTokenMinFields'] = ResolversParentTypes['NonceAccessTokenMinFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  expirationDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  targetResource?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NonceAccessTokenMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NonceAccessTokenMutationResponse'] = ResolversParentTypes['NonceAccessTokenMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['NonceAccessToken']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationUsersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationUsers'] = ResolversParentTypes['OrganizationUsers']
> = {
  data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groupsId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  insertInstant?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  organization?: Resolver<ResolversTypes['Organizations'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['Users'], ParentType, ContextType>;
  usersId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationUsersAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationUsersAggregate'] = ResolversParentTypes['OrganizationUsersAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['OrganizationUsersAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['OrganizationUsers']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationUsersAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationUsersAggregateFields'] = ResolversParentTypes['OrganizationUsersAggregateFields']
> = {
  avg?: Resolver<Maybe<ResolversTypes['OrganizationUsersAvgFields']>, ParentType, ContextType>;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<OrganizationUsersAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['OrganizationUsersMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['OrganizationUsersMinFields']>, ParentType, ContextType>;
  stddev?: Resolver<
    Maybe<ResolversTypes['OrganizationUsersStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['OrganizationUsersStddevPopFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['OrganizationUsersStddevSampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<Maybe<ResolversTypes['OrganizationUsersSumFields']>, ParentType, ContextType>;
  varPop?: Resolver<
    Maybe<ResolversTypes['OrganizationUsersVarPopFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['OrganizationUsersVarSampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['OrganizationUsersVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationUsersAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationUsersAvgFields'] = ResolversParentTypes['OrganizationUsersAvgFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationUsersMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationUsersMaxFields'] = ResolversParentTypes['OrganizationUsersMaxFields']
> = {
  data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groupsId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  usersId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationUsersMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationUsersMinFields'] = ResolversParentTypes['OrganizationUsersMinFields']
> = {
  data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groupsId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  usersId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationUsersMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationUsersMutationResponse'] = ResolversParentTypes['OrganizationUsersMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['OrganizationUsers']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationUsersStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationUsersStddevFields'] = ResolversParentTypes['OrganizationUsersStddevFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationUsersStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationUsersStddevPopFields'] = ResolversParentTypes['OrganizationUsersStddevPopFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationUsersStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationUsersStddevSampFields'] = ResolversParentTypes['OrganizationUsersStddevSampFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationUsersSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationUsersSumFields'] = ResolversParentTypes['OrganizationUsersSumFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationUsersVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationUsersVarPopFields'] = ResolversParentTypes['OrganizationUsersVarPopFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationUsersVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationUsersVarSampFields'] = ResolversParentTypes['OrganizationUsersVarSampFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationUsersVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationUsersVarianceFields'] = ResolversParentTypes['OrganizationUsersVarianceFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Organizations'] = ResolversParentTypes['Organizations']
> = {
  data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  insertInstant?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  journeys?: Resolver<
    Array<ResolversTypes['Journeys']>,
    ParentType,
    ContextType,
    RequireFields<OrganizationsJourneysArgs, never>
  >;
  journeysAggregate?: Resolver<
    ResolversTypes['JourneysAggregate'],
    ParentType,
    ContextType,
    RequireFields<OrganizationsJourneysAggregateArgs, never>
  >;
  lastUpdateInstant?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['OrganizationsMeta']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizationUsers?: Resolver<
    Array<ResolversTypes['OrganizationUsers']>,
    ParentType,
    ContextType,
    RequireFields<OrganizationsOrganizationUsersArgs, never>
  >;
  organizationUsersAggregate?: Resolver<
    ResolversTypes['OrganizationUsersAggregate'],
    ParentType,
    ContextType,
    RequireFields<OrganizationsOrganizationUsersAggregateArgs, never>
  >;
  planSubscriptions?: Resolver<
    Array<ResolversTypes['PlanSubscriptions']>,
    ParentType,
    ContextType,
    RequireFields<OrganizationsPlanSubscriptionsArgs, never>
  >;
  planSubscriptionsAggregate?: Resolver<
    ResolversTypes['PlanSubscriptionsAggregate'],
    ParentType,
    ContextType,
    RequireFields<OrganizationsPlanSubscriptionsAggregateArgs, never>
  >;
  tenantsId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationsAggregate'] = ResolversParentTypes['OrganizationsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['OrganizationsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['Organizations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationsAggregateFields'] = ResolversParentTypes['OrganizationsAggregateFields']
> = {
  avg?: Resolver<Maybe<ResolversTypes['OrganizationsAvgFields']>, ParentType, ContextType>;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<OrganizationsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['OrganizationsMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['OrganizationsMinFields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['OrganizationsStddevFields']>, ParentType, ContextType>;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['OrganizationsStddevPopFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['OrganizationsStddevSampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<Maybe<ResolversTypes['OrganizationsSumFields']>, ParentType, ContextType>;
  varPop?: Resolver<Maybe<ResolversTypes['OrganizationsVarPopFields']>, ParentType, ContextType>;
  varSamp?: Resolver<Maybe<ResolversTypes['OrganizationsVarSampFields']>, ParentType, ContextType>;
  variance?: Resolver<
    Maybe<ResolversTypes['OrganizationsVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationsAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationsAvgFields'] = ResolversParentTypes['OrganizationsAvgFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationsMaxFields'] = ResolversParentTypes['OrganizationsMaxFields']
> = {
  data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tenantsId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationsMetaResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationsMeta'] = ResolversParentTypes['OrganizationsMeta']
> = {
  companySize?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  creditModel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerCreatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  industry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  linkedInUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organization?: Resolver<ResolversTypes['Organizations'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  productSold?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  salesforceAccountId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  targetCustomer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  websiteLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationsMetaAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationsMetaAggregate'] = ResolversParentTypes['OrganizationsMetaAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['OrganizationsMetaAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['OrganizationsMeta']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationsMetaAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationsMetaAggregateFields'] = ResolversParentTypes['OrganizationsMetaAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<OrganizationsMetaAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['OrganizationsMetaMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['OrganizationsMetaMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationsMetaMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationsMetaMaxFields'] = ResolversParentTypes['OrganizationsMetaMaxFields']
> = {
  companySize?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  creditModel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerCreatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  industry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  linkedInUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  productSold?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  salesforceAccountId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  targetCustomer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  websiteLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationsMetaMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationsMetaMinFields'] = ResolversParentTypes['OrganizationsMetaMinFields']
> = {
  companySize?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  creditModel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerCreatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  industry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  linkedInUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  productSold?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  salesforceAccountId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  targetCustomer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  websiteLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationsMetaMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationsMetaMutationResponse'] = ResolversParentTypes['OrganizationsMetaMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['OrganizationsMeta']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationsMinFields'] = ResolversParentTypes['OrganizationsMinFields']
> = {
  data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tenantsId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationsMutationResponse'] = ResolversParentTypes['OrganizationsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Organizations']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationsStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationsStddevFields'] = ResolversParentTypes['OrganizationsStddevFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationsStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationsStddevPopFields'] = ResolversParentTypes['OrganizationsStddevPopFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationsStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationsStddevSampFields'] = ResolversParentTypes['OrganizationsStddevSampFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationsSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationsSumFields'] = ResolversParentTypes['OrganizationsSumFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationsVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationsVarPopFields'] = ResolversParentTypes['OrganizationsVarPopFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationsVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationsVarSampFields'] = ResolversParentTypes['OrganizationsVarSampFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationsVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationsVarianceFields'] = ResolversParentTypes['OrganizationsVarianceFields']
> = {
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanSubscriptionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlanSubscriptions'] = ResolversParentTypes['PlanSubscriptions']
> = {
  createdAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['Users'], ParentType, ContextType>;
  createdById?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organization?: Resolver<ResolversTypes['Organizations'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  plan?: Resolver<ResolversTypes['Plans'], ParentType, ContextType>;
  planId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanSubscriptionsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlanSubscriptionsAggregate'] = ResolversParentTypes['PlanSubscriptionsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['PlanSubscriptionsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['PlanSubscriptions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanSubscriptionsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlanSubscriptionsAggregateFields'] = ResolversParentTypes['PlanSubscriptionsAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<PlanSubscriptionsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['PlanSubscriptionsMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['PlanSubscriptionsMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanSubscriptionsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlanSubscriptionsMaxFields'] = ResolversParentTypes['PlanSubscriptionsMaxFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  planId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanSubscriptionsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlanSubscriptionsMinFields'] = ResolversParentTypes['PlanSubscriptionsMinFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  planId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanSubscriptionsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlanSubscriptionsMutationResponse'] = ResolversParentTypes['PlanSubscriptionsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['PlanSubscriptions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlansResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Plans'] = ResolversParentTypes['Plans']
> = {
  createdAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['Users'], ParentType, ContextType>;
  createdById?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  policy?: Resolver<
    ResolversTypes['jsonb'],
    ParentType,
    ContextType,
    RequireFields<PlansPolicyArgs, never>
  >;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subscriptions?: Resolver<
    Array<ResolversTypes['PlanSubscriptions']>,
    ParentType,
    ContextType,
    RequireFields<PlansSubscriptionsArgs, never>
  >;
  subscriptionsAggregate?: Resolver<
    ResolversTypes['PlanSubscriptionsAggregate'],
    ParentType,
    ContextType,
    RequireFields<PlansSubscriptionsAggregateArgs, never>
  >;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlansAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlansAggregate'] = ResolversParentTypes['PlansAggregate']
> = {
  aggregate?: Resolver<Maybe<ResolversTypes['PlansAggregateFields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Plans']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlansAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlansAggregateFields'] = ResolversParentTypes['PlansAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<PlansAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['PlansMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['PlansMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlansMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlansMaxFields'] = ResolversParentTypes['PlansMaxFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlansMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlansMinFields'] = ResolversParentTypes['PlansMinFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlansMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlansMutationResponse'] = ResolversParentTypes['PlansMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Plans']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayExecutionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayExecution'] = ResolversParentTypes['PlayExecution']
> = {
  createdAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  data?: Resolver<
    Maybe<ResolversTypes['jsonb']>,
    ParentType,
    ContextType,
    RequireFields<PlayExecutionDataArgs, never>
  >;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  params?: Resolver<
    Maybe<ResolversTypes['jsonb']>,
    ParentType,
    ContextType,
    RequireFields<PlayExecutionParamsArgs, never>
  >;
  playName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  users?: Resolver<
    Maybe<ResolversTypes['jsonb']>,
    ParentType,
    ContextType,
    RequireFields<PlayExecutionUsersArgs, never>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayExecutionAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayExecutionAggregate'] = ResolversParentTypes['PlayExecutionAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['PlayExecutionAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['PlayExecution']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayExecutionAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayExecutionAggregateFields'] = ResolversParentTypes['PlayExecutionAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<PlayExecutionAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['PlayExecutionMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['PlayExecutionMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayExecutionMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayExecutionMaxFields'] = ResolversParentTypes['PlayExecutionMaxFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  playName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayExecutionMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayExecutionMinFields'] = ResolversParentTypes['PlayExecutionMinFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  playName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayExecutionMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayExecutionMutationResponse'] = ResolversParentTypes['PlayExecutionMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['PlayExecution']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramActionsTakenResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramActionsTaken'] = ResolversParentTypes['ProgramActionsTaken']
> = {
  action?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  courseId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  programId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramActionsTakenAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramActionsTakenAggregate'] = ResolversParentTypes['ProgramActionsTakenAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['ProgramActionsTakenAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['ProgramActionsTaken']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramActionsTakenAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramActionsTakenAggregateFields'] = ResolversParentTypes['ProgramActionsTakenAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ProgramActionsTakenAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['ProgramActionsTakenMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ProgramActionsTakenMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramActionsTakenMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramActionsTakenMaxFields'] = ResolversParentTypes['ProgramActionsTakenMaxFields']
> = {
  action?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  programId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramActionsTakenMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramActionsTakenMinFields'] = ResolversParentTypes['ProgramActionsTakenMinFields']
> = {
  action?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  programId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramActionsTakenMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramActionsTakenMutationResponse'] = ResolversParentTypes['ProgramActionsTakenMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ProgramActionsTaken']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramCoursesShownResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramCoursesShown'] = ResolversParentTypes['ProgramCoursesShown']
> = {
  courseId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  programId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramCoursesShownAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramCoursesShownAggregate'] = ResolversParentTypes['ProgramCoursesShownAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['ProgramCoursesShownAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['ProgramCoursesShown']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramCoursesShownAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramCoursesShownAggregateFields'] = ResolversParentTypes['ProgramCoursesShownAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ProgramCoursesShownAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['ProgramCoursesShownMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ProgramCoursesShownMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramCoursesShownMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramCoursesShownMaxFields'] = ResolversParentTypes['ProgramCoursesShownMaxFields']
> = {
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  programId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramCoursesShownMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramCoursesShownMinFields'] = ResolversParentTypes['ProgramCoursesShownMinFields']
> = {
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  programId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramCoursesShownMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramCoursesShownMutationResponse'] = ResolversParentTypes['ProgramCoursesShownMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ProgramCoursesShown']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramTagsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramTags'] = ResolversParentTypes['ProgramTags']
> = {
  programId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  tagId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramTagsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramTagsAggregate'] = ResolversParentTypes['ProgramTagsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['ProgramTagsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['ProgramTags']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramTagsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramTagsAggregateFields'] = ResolversParentTypes['ProgramTagsAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ProgramTagsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['ProgramTagsMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ProgramTagsMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramTagsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramTagsMaxFields'] = ResolversParentTypes['ProgramTagsMaxFields']
> = {
  programId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  tagId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramTagsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramTagsMinFields'] = ResolversParentTypes['ProgramTagsMinFields']
> = {
  programId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  tagId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramTagsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramTagsMutationResponse'] = ResolversParentTypes['ProgramTagsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ProgramTags']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramUsersSelectedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramUsersSelected'] = ResolversParentTypes['ProgramUsersSelected']
> = {
  programId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramUsersSelectedAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramUsersSelectedAggregate'] = ResolversParentTypes['ProgramUsersSelectedAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['ProgramUsersSelectedAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['ProgramUsersSelected']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramUsersSelectedAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramUsersSelectedAggregateFields'] = ResolversParentTypes['ProgramUsersSelectedAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ProgramUsersSelectedAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['ProgramUsersSelectedMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ProgramUsersSelectedMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramUsersSelectedMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramUsersSelectedMaxFields'] = ResolversParentTypes['ProgramUsersSelectedMaxFields']
> = {
  programId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramUsersSelectedMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramUsersSelectedMinFields'] = ResolversParentTypes['ProgramUsersSelectedMinFields']
> = {
  programId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramUsersSelectedMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramUsersSelectedMutationResponse'] = ResolversParentTypes['ProgramUsersSelectedMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ProgramUsersSelected']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Programs'] = ResolversParentTypes['Programs']
> = {
  actionsTaken?: Resolver<
    Array<ResolversTypes['ProgramActionsTaken']>,
    ParentType,
    ContextType,
    RequireFields<ProgramsActionsTakenArgs, never>
  >;
  actionsTakenAggregate?: Resolver<
    ResolversTypes['ProgramActionsTakenAggregate'],
    ParentType,
    ContextType,
    RequireFields<ProgramsActionsTakenAggregateArgs, never>
  >;
  coursesShown?: Resolver<
    Array<ResolversTypes['ProgramCoursesShown']>,
    ParentType,
    ContextType,
    RequireFields<ProgramsCoursesShownArgs, never>
  >;
  coursesShownAggregate?: Resolver<
    ResolversTypes['ProgramCoursesShownAggregate'],
    ParentType,
    ContextType,
    RequireFields<ProgramsCoursesShownAggregateArgs, never>
  >;
  createdAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  createdById?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  methodology?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  selectedUsers?: Resolver<
    Array<ResolversTypes['ProgramUsersSelected']>,
    ParentType,
    ContextType,
    RequireFields<ProgramsSelectedUsersArgs, never>
  >;
  selectedUsersAggregate?: Resolver<
    ResolversTypes['ProgramUsersSelectedAggregate'],
    ParentType,
    ContextType,
    RequireFields<ProgramsSelectedUsersAggregateArgs, never>
  >;
  tags?: Resolver<
    Array<ResolversTypes['ProgramTags']>,
    ParentType,
    ContextType,
    RequireFields<ProgramsTagsArgs, never>
  >;
  tagsAggregate?: Resolver<
    ResolversTypes['ProgramTagsAggregate'],
    ParentType,
    ContextType,
    RequireFields<ProgramsTagsAggregateArgs, never>
  >;
  timeframe?: Resolver<
    ResolversTypes['CourseWaitlistDesiredUrgencyTypeEnum'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramsAggregate'] = ResolversParentTypes['ProgramsAggregate']
> = {
  aggregate?: Resolver<Maybe<ResolversTypes['ProgramsAggregateFields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Programs']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramsAggregateFields'] = ResolversParentTypes['ProgramsAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ProgramsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['ProgramsMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ProgramsMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramsMaxFields'] = ResolversParentTypes['ProgramsMaxFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  methodology?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramsMinFields'] = ResolversParentTypes['ProgramsMinFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  methodology?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProgramsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProgramsMutationResponse'] = ResolversParentTypes['ProgramsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Programs']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QuestionType'] = ResolversParentTypes['QuestionType']
> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionTypeAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QuestionTypeAggregate'] = ResolversParentTypes['QuestionTypeAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['QuestionTypeAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['QuestionType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionTypeAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QuestionTypeAggregateFields'] = ResolversParentTypes['QuestionTypeAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<QuestionTypeAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['QuestionTypeMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['QuestionTypeMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionTypeMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QuestionTypeMaxFields'] = ResolversParentTypes['QuestionTypeMaxFields']
> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionTypeMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QuestionTypeMinFields'] = ResolversParentTypes['QuestionTypeMinFields']
> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionTypeMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QuestionTypeMutationResponse'] = ResolversParentTypes['QuestionTypeMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['QuestionType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordingActivityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecordingActivity'] = ResolversParentTypes['RecordingActivity']
> = {
  context?: Resolver<
    Maybe<ResolversTypes['jsonb']>,
    ParentType,
    ContextType,
    RequireFields<RecordingActivityContextArgs, never>
  >;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  hash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordingActivityAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecordingActivityAggregate'] = ResolversParentTypes['RecordingActivityAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['RecordingActivityAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['RecordingActivity']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordingActivityAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecordingActivityAggregateFields'] = ResolversParentTypes['RecordingActivityAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<RecordingActivityAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['RecordingActivityMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['RecordingActivityMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordingActivityMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecordingActivityMaxFields'] = ResolversParentTypes['RecordingActivityMaxFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordingActivityMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecordingActivityMinFields'] = ResolversParentTypes['RecordingActivityMinFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordingActivityMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecordingActivityMutationResponse'] = ResolversParentTypes['RecordingActivityMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['RecordingActivity']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordingsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Recordings'] = ResolversParentTypes['Recordings']
> = {
  createdAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fullTranscription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  lightningSubmissionId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  meta?: Resolver<
    Maybe<ResolversTypes['jsonb']>,
    ParentType,
    ContextType,
    RequireFields<RecordingsMetaArgs, never>
  >;
  public?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  publicUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sentToChannel?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sourceUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  summary?: Resolver<
    Maybe<ResolversTypes['jsonb']>,
    ParentType,
    ContextType,
    RequireFields<RecordingsSummaryArgs, never>
  >;
  synced?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  transcription?: Resolver<
    Maybe<ResolversTypes['jsonb']>,
    ParentType,
    ContextType,
    RequireFields<RecordingsTranscriptionArgs, never>
  >;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordingsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecordingsAggregate'] = ResolversParentTypes['RecordingsAggregate']
> = {
  aggregate?: Resolver<Maybe<ResolversTypes['RecordingsAggregateFields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Recordings']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordingsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecordingsAggregateFields'] = ResolversParentTypes['RecordingsAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<RecordingsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['RecordingsMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['RecordingsMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordingsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecordingsMaxFields'] = ResolversParentTypes['RecordingsMaxFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fullTranscription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  lightningSubmissionId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  publicUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sourceUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordingsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecordingsMinFields'] = ResolversParentTypes['RecordingsMinFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fullTranscription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  lightningSubmissionId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  publicUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sourceUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordingsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecordingsMutationResponse'] = ResolversParentTypes['RecordingsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Recordings']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegisterAnonOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RegisterAnonOutput'] = ResolversParentTypes['RegisterAnonOutput']
> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  joinLinkUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RelatedCoursesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RelatedCourses'] = ResolversParentTypes['RelatedCourses']
> = {
  course?: Resolver<Maybe<ResolversTypes['Courses']>, ParentType, ContextType>;
  courseId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  relatedCourse?: Resolver<
    Maybe<Array<ResolversTypes['Courses']>>,
    ParentType,
    ContextType,
    RequireFields<RelatedCoursesRelatedCourseArgs, never>
  >;
  relatedCourseSlug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RelatedCoursesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RelatedCoursesAggregate'] = ResolversParentTypes['RelatedCoursesAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['RelatedCoursesAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['RelatedCourses']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RelatedCoursesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RelatedCoursesAggregateFields'] = ResolversParentTypes['RelatedCoursesAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<RelatedCoursesAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['RelatedCoursesMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['RelatedCoursesMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RelatedCoursesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RelatedCoursesMaxFields'] = ResolversParentTypes['RelatedCoursesMaxFields']
> = {
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  relatedCourseSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RelatedCoursesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RelatedCoursesMinFields'] = ResolversParentTypes['RelatedCoursesMinFields']
> = {
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  relatedCourseSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RelatedCoursesMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RelatedCoursesMutationResponse'] = ResolversParentTypes['RelatedCoursesMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['RelatedCourses']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportPublishStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportPublishStatus'] = ResolversParentTypes['ReportPublishStatus']
> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportPublishStatusAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportPublishStatusAggregate'] = ResolversParentTypes['ReportPublishStatusAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['ReportPublishStatusAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['ReportPublishStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportPublishStatusAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportPublishStatusAggregateFields'] = ResolversParentTypes['ReportPublishStatusAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ReportPublishStatusAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['ReportPublishStatusMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ReportPublishStatusMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportPublishStatusMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportPublishStatusMaxFields'] = ResolversParentTypes['ReportPublishStatusMaxFields']
> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportPublishStatusMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportPublishStatusMinFields'] = ResolversParentTypes['ReportPublishStatusMinFields']
> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportPublishStatusMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportPublishStatusMutationResponse'] = ResolversParentTypes['ReportPublishStatusMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ReportPublishStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportType'] = ResolversParentTypes['ReportType']
> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportTypeAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportTypeAggregate'] = ResolversParentTypes['ReportTypeAggregate']
> = {
  aggregate?: Resolver<Maybe<ResolversTypes['ReportTypeAggregateFields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['ReportType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportTypeAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportTypeAggregateFields'] = ResolversParentTypes['ReportTypeAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ReportTypeAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['ReportTypeMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ReportTypeMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportTypeMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportTypeMaxFields'] = ResolversParentTypes['ReportTypeMaxFields']
> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportTypeMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportTypeMinFields'] = ResolversParentTypes['ReportTypeMinFields']
> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportTypeMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportTypeMutationResponse'] = ResolversParentTypes['ReportTypeMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ReportType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Reports'] = ResolversParentTypes['Reports']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  desc?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  embedPath?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  publishStatus?: Resolver<ResolversTypes['ReportPublishStatusEnum'], ParentType, ContextType>;
  reportRefUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ReportTypeEnum'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportsAggregate'] = ResolversParentTypes['ReportsAggregate']
> = {
  aggregate?: Resolver<Maybe<ResolversTypes['ReportsAggregateFields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Reports']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportsAggregateFields'] = ResolversParentTypes['ReportsAggregateFields']
> = {
  avg?: Resolver<Maybe<ResolversTypes['ReportsAvgFields']>, ParentType, ContextType>;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ReportsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['ReportsMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ReportsMinFields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['ReportsStddevFields']>, ParentType, ContextType>;
  stddevPop?: Resolver<Maybe<ResolversTypes['ReportsStddevPopFields']>, ParentType, ContextType>;
  stddevSamp?: Resolver<Maybe<ResolversTypes['ReportsStddevSampFields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['ReportsSumFields']>, ParentType, ContextType>;
  varPop?: Resolver<Maybe<ResolversTypes['ReportsVarPopFields']>, ParentType, ContextType>;
  varSamp?: Resolver<Maybe<ResolversTypes['ReportsVarSampFields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['ReportsVarianceFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportsAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportsAvgFields'] = ResolversParentTypes['ReportsAvgFields']
> = {
  height?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportsMaxFields'] = ResolversParentTypes['ReportsMaxFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  desc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  embedPath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reportRefUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportsMinFields'] = ResolversParentTypes['ReportsMinFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  desc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  embedPath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reportRefUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportsMutationResponse'] = ResolversParentTypes['ReportsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Reports']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportsStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportsStddevFields'] = ResolversParentTypes['ReportsStddevFields']
> = {
  height?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportsStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportsStddevPopFields'] = ResolversParentTypes['ReportsStddevPopFields']
> = {
  height?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportsStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportsStddevSampFields'] = ResolversParentTypes['ReportsStddevSampFields']
> = {
  height?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportsSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportsSumFields'] = ResolversParentTypes['ReportsSumFields']
> = {
  height?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportsVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportsVarPopFields'] = ResolversParentTypes['ReportsVarPopFields']
> = {
  height?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportsVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportsVarSampFields'] = ResolversParentTypes['ReportsVarSampFields']
> = {
  height?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportsVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportsVarianceFields'] = ResolversParentTypes['ReportsVarianceFields']
> = {
  height?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourceTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResourceType'] = ResolversParentTypes['ResourceType']
> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourceTypeAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResourceTypeAggregate'] = ResolversParentTypes['ResourceTypeAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['ResourceTypeAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['ResourceType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourceTypeAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResourceTypeAggregateFields'] = ResolversParentTypes['ResourceTypeAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ResourceTypeAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['ResourceTypeMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ResourceTypeMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourceTypeMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResourceTypeMaxFields'] = ResolversParentTypes['ResourceTypeMaxFields']
> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourceTypeMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResourceTypeMinFields'] = ResolversParentTypes['ResourceTypeMinFields']
> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourceTypeMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResourceTypeMutationResponse'] = ResolversParentTypes['ResourceTypeMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ResourceType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourcesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Resources'] = ResolversParentTypes['Resources']
> = {
  contentType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  descriptionRich?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  filename?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resourceClass?: Resolver<Maybe<ResolversTypes['ClassResources']>, ParentType, ContextType>;
  resourceCohort?: Resolver<Maybe<ResolversTypes['CohortsResourcesRel']>, ParentType, ContextType>;
  resourceCourse?: Resolver<Maybe<ResolversTypes['CoursesResourcesRel']>, ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ResourceTypeEnum'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourcesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResourcesAggregate'] = ResolversParentTypes['ResourcesAggregate']
> = {
  aggregate?: Resolver<Maybe<ResolversTypes['ResourcesAggregateFields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Resources']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourcesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResourcesAggregateFields'] = ResolversParentTypes['ResourcesAggregateFields']
> = {
  avg?: Resolver<Maybe<ResolversTypes['ResourcesAvgFields']>, ParentType, ContextType>;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ResourcesAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['ResourcesMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ResourcesMinFields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['ResourcesStddevFields']>, ParentType, ContextType>;
  stddevPop?: Resolver<Maybe<ResolversTypes['ResourcesStddevPopFields']>, ParentType, ContextType>;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['ResourcesStddevSampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<Maybe<ResolversTypes['ResourcesSumFields']>, ParentType, ContextType>;
  varPop?: Resolver<Maybe<ResolversTypes['ResourcesVarPopFields']>, ParentType, ContextType>;
  varSamp?: Resolver<Maybe<ResolversTypes['ResourcesVarSampFields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['ResourcesVarianceFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourcesAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResourcesAvgFields'] = ResolversParentTypes['ResourcesAvgFields']
> = {
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourcesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResourcesMaxFields'] = ResolversParentTypes['ResourcesMaxFields']
> = {
  contentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  descriptionRich?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  filename?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourcesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResourcesMinFields'] = ResolversParentTypes['ResourcesMinFields']
> = {
  contentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  descriptionRich?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  filename?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourcesMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResourcesMutationResponse'] = ResolversParentTypes['ResourcesMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Resources']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourcesStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResourcesStddevFields'] = ResolversParentTypes['ResourcesStddevFields']
> = {
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourcesStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResourcesStddevPopFields'] = ResolversParentTypes['ResourcesStddevPopFields']
> = {
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourcesStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResourcesStddevSampFields'] = ResolversParentTypes['ResourcesStddevSampFields']
> = {
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourcesSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResourcesSumFields'] = ResolversParentTypes['ResourcesSumFields']
> = {
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourcesVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResourcesVarPopFields'] = ResolversParentTypes['ResourcesVarPopFields']
> = {
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourcesVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResourcesVarSampFields'] = ResolversParentTypes['ResourcesVarSampFields']
> = {
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourcesVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResourcesVarianceFields'] = ResolversParentTypes['ResourcesVarianceFields']
> = {
  version?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResumeAssessmentOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResumeAssessmentOutput'] = ResolversParentTypes['ResumeAssessmentOutput']
> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  remainingTime?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RunPlayOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RunPlayOutput'] = ResolversParentTypes['RunPlayOutput']
> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SampleOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SampleOutput'] = ResolversParentTypes['SampleOutput']
> = {
  assumeSessionUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SaveAssessmentOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SaveAssessmentOutput'] = ResolversParentTypes['SaveAssessmentOutput']
> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  remainingTime?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  startedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SchoolsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Schools'] = ResolversParentTypes['Schools']
> = {
  color?: Resolver<Maybe<ResolversTypes['bpchar']>, ParentType, ContextType>;
  courses?: Resolver<
    Array<ResolversTypes['Courses']>,
    ParentType,
    ContextType,
    RequireFields<SchoolsCoursesArgs, never>
  >;
  coursesAggregate?: Resolver<
    ResolversTypes['CoursesAggregate'],
    ParentType,
    ContextType,
    RequireFields<SchoolsCoursesAggregateArgs, never>
  >;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  iconUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SchoolsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SchoolsAggregate'] = ResolversParentTypes['SchoolsAggregate']
> = {
  aggregate?: Resolver<Maybe<ResolversTypes['SchoolsAggregateFields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Schools']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SchoolsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SchoolsAggregateFields'] = ResolversParentTypes['SchoolsAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<SchoolsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['SchoolsMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['SchoolsMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SchoolsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SchoolsMaxFields'] = ResolversParentTypes['SchoolsMaxFields']
> = {
  color?: Resolver<Maybe<ResolversTypes['bpchar']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  iconUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SchoolsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SchoolsMinFields'] = ResolversParentTypes['SchoolsMinFields']
> = {
  color?: Resolver<Maybe<ResolversTypes['bpchar']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  iconUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SchoolsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SchoolsMutationResponse'] = ResolversParentTypes['SchoolsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Schools']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeatTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeatType'] = ResolversParentTypes['SeatType']
> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeatTypeAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeatTypeAggregate'] = ResolversParentTypes['SeatTypeAggregate']
> = {
  aggregate?: Resolver<Maybe<ResolversTypes['SeatTypeAggregateFields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['SeatType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeatTypeAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeatTypeAggregateFields'] = ResolversParentTypes['SeatTypeAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<SeatTypeAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['SeatTypeMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['SeatTypeMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeatTypeMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeatTypeMaxFields'] = ResolversParentTypes['SeatTypeMaxFields']
> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeatTypeMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeatTypeMinFields'] = ResolversParentTypes['SeatTypeMinFields']
> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeatTypeMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeatTypeMutationResponse'] = ResolversParentTypes['SeatTypeMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['SeatType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Seats'] = ResolversParentTypes['Seats']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  organization?: Resolver<Maybe<ResolversTypes['Organizations']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['SeatTypeEnum']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeatsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeatsAggregate'] = ResolversParentTypes['SeatsAggregate']
> = {
  aggregate?: Resolver<Maybe<ResolversTypes['SeatsAggregateFields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Seats']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeatsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeatsAggregateFields'] = ResolversParentTypes['SeatsAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<SeatsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['SeatsMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['SeatsMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeatsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeatsMaxFields'] = ResolversParentTypes['SeatsMaxFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeatsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeatsMinFields'] = ResolversParentTypes['SeatsMinFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeatsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeatsMutationResponse'] = ResolversParentTypes['SeatsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Seats']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SetClassStatusOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SetClassStatusOutput'] = ResolversParentTypes['SetClassStatusOutput']
> = {
  done?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StartAssessmentOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StartAssessmentOutput'] = ResolversParentTypes['StartAssessmentOutput']
> = {
  cohortSlug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  impactAssessmentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StartPlaybackOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StartPlaybackOutput'] = ResolversParentTypes['StartPlaybackOutput']
> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StreamMessagesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StreamMessages'] = ResolversParentTypes['StreamMessages']
> = {
  author?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  authorId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  channelId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  data?: Resolver<
    ResolversTypes['jsonb'],
    ParentType,
    ContextType,
    RequireFields<StreamMessagesDataArgs, never>
  >;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  lightningSessionSubmission?: Resolver<
    Maybe<ResolversTypes['LightningSessionSubmissions']>,
    ParentType,
    ContextType
  >;
  messageId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StreamMessagesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StreamMessagesAggregate'] = ResolversParentTypes['StreamMessagesAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['StreamMessagesAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['StreamMessages']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StreamMessagesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StreamMessagesAggregateFields'] = ResolversParentTypes['StreamMessagesAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<StreamMessagesAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['StreamMessagesMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['StreamMessagesMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StreamMessagesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StreamMessagesMaxFields'] = ResolversParentTypes['StreamMessagesMaxFields']
> = {
  authorId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  channelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  messageId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StreamMessagesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StreamMessagesMinFields'] = ResolversParentTypes['StreamMessagesMinFields']
> = {
  authorId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  channelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  messageId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StreamMessagesMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StreamMessagesMutationResponse'] = ResolversParentTypes['StreamMessagesMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['StreamMessages']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubmitAndGradeAssessmentOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SubmitAndGradeAssessmentOutput'] = ResolversParentTypes['SubmitAndGradeAssessmentOutput']
> = {
  impactAssessmentAttemptId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  impactAssessmentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  learnerScoreIsPassing?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  learnerScorePercent?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubordinatesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Subordinates'] = ResolversParentTypes['Subordinates']
> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrollments?: Resolver<Maybe<Array<ResolversTypes['uuid']>>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manager?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  managerId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  teamIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  teamNames?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  waitlists?: Resolver<Maybe<Array<ResolversTypes['uuid']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubordinatesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SubordinatesAggregate'] = ResolversParentTypes['SubordinatesAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['SubordinatesAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['Subordinates']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubordinatesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SubordinatesAggregateFields'] = ResolversParentTypes['SubordinatesAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<SubordinatesAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['SubordinatesMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['SubordinatesMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubordinatesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SubordinatesMaxFields'] = ResolversParentTypes['SubordinatesMaxFields']
> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrollments?: Resolver<Maybe<Array<ResolversTypes['uuid']>>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  managerId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  teamIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  teamNames?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  waitlists?: Resolver<Maybe<Array<ResolversTypes['uuid']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubordinatesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SubordinatesMinFields'] = ResolversParentTypes['SubordinatesMinFields']
> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrollments?: Resolver<Maybe<Array<ResolversTypes['uuid']>>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  managerId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  teamIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  teamNames?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  waitlists?: Resolver<Maybe<Array<ResolversTypes['uuid']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SuccessOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SuccessOutput'] = ResolversParentTypes['SuccessOutput']
> = {
  details?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagTypeScopeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagTypeScope'] = ResolversParentTypes['TagTypeScope']
> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagTypeScopeAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagTypeScopeAggregate'] = ResolversParentTypes['TagTypeScopeAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['TagTypeScopeAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['TagTypeScope']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagTypeScopeAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagTypeScopeAggregateFields'] = ResolversParentTypes['TagTypeScopeAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<TagTypeScopeAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['TagTypeScopeMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['TagTypeScopeMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagTypeScopeMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagTypeScopeMaxFields'] = ResolversParentTypes['TagTypeScopeMaxFields']
> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagTypeScopeMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagTypeScopeMinFields'] = ResolversParentTypes['TagTypeScopeMinFields']
> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagTypeScopeMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagTypeScopeMutationResponse'] = ResolversParentTypes['TagTypeScopeMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['TagTypeScope']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagTypesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagTypes'] = ResolversParentTypes['TagTypes']
> = {
  color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  scope?: Resolver<ResolversTypes['TagTypeScopeEnum'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tags?: Resolver<
    Array<ResolversTypes['Tags']>,
    ParentType,
    ContextType,
    RequireFields<TagTypesTagsArgs, never>
  >;
  tagsAggregate?: Resolver<
    ResolversTypes['TagsAggregate'],
    ParentType,
    ContextType,
    RequireFields<TagTypesTagsAggregateArgs, never>
  >;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagTypesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagTypesAggregate'] = ResolversParentTypes['TagTypesAggregate']
> = {
  aggregate?: Resolver<Maybe<ResolversTypes['TagTypesAggregateFields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['TagTypes']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagTypesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagTypesAggregateFields'] = ResolversParentTypes['TagTypesAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<TagTypesAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['TagTypesMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['TagTypesMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagTypesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagTypesMaxFields'] = ResolversParentTypes['TagTypesMaxFields']
> = {
  color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagTypesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagTypesMinFields'] = ResolversParentTypes['TagTypesMinFields']
> = {
  color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagTypesMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagTypesMutationResponse'] = ResolversParentTypes['TagTypesMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['TagTypes']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Tags'] = ResolversParentTypes['Tags']
> = {
  createdAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tagType?: Resolver<Maybe<ResolversTypes['TagTypes']>, ParentType, ContextType>;
  tagTypeId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  taggedClasses?: Resolver<
    Array<ResolversTypes['ClassesTagsRel']>,
    ParentType,
    ContextType,
    RequireFields<TagsTaggedClassesArgs, never>
  >;
  taggedClassesAggregate?: Resolver<
    ResolversTypes['ClassesTagsRelAggregate'],
    ParentType,
    ContextType,
    RequireFields<TagsTaggedClassesAggregateArgs, never>
  >;
  taggedCourses?: Resolver<
    Array<ResolversTypes['CoursesTagsRel']>,
    ParentType,
    ContextType,
    RequireFields<TagsTaggedCoursesArgs, never>
  >;
  taggedCoursesAggregate?: Resolver<
    ResolversTypes['CoursesTagsRelAggregate'],
    ParentType,
    ContextType,
    RequireFields<TagsTaggedCoursesAggregateArgs, never>
  >;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagsAggregate'] = ResolversParentTypes['TagsAggregate']
> = {
  aggregate?: Resolver<Maybe<ResolversTypes['TagsAggregateFields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Tags']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagsAggregateFields'] = ResolversParentTypes['TagsAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<TagsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['TagsMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['TagsMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagsMaxFields'] = ResolversParentTypes['TagsMaxFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tagTypeId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagsMinFields'] = ResolversParentTypes['TagsMinFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  deletedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tagTypeId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagsMutationResponse'] = ResolversParentTypes['TagsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Tags']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskDefinitionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskDefinition'] = ResolversParentTypes['TaskDefinition']
> = {
  createdAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  schema?: Resolver<
    ResolversTypes['json'],
    ParentType,
    ContextType,
    RequireFields<TaskDefinitionSchemaArgs, never>
  >;
  type?: Resolver<ResolversTypes['TaskDefinitionTypeEnum'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskDefinitionAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskDefinitionAggregate'] = ResolversParentTypes['TaskDefinitionAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['TaskDefinitionAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['TaskDefinition']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskDefinitionAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskDefinitionAggregateFields'] = ResolversParentTypes['TaskDefinitionAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<TaskDefinitionAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['TaskDefinitionMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['TaskDefinitionMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskDefinitionMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskDefinitionMaxFields'] = ResolversParentTypes['TaskDefinitionMaxFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskDefinitionMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskDefinitionMinFields'] = ResolversParentTypes['TaskDefinitionMinFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskDefinitionMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskDefinitionMutationResponse'] = ResolversParentTypes['TaskDefinitionMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['TaskDefinition']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskDefinitionTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskDefinitionType'] = ResolversParentTypes['TaskDefinitionType']
> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskDefinitionTypeAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskDefinitionTypeAggregate'] = ResolversParentTypes['TaskDefinitionTypeAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['TaskDefinitionTypeAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['TaskDefinitionType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskDefinitionTypeAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskDefinitionTypeAggregateFields'] = ResolversParentTypes['TaskDefinitionTypeAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<TaskDefinitionTypeAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['TaskDefinitionTypeMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['TaskDefinitionTypeMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskDefinitionTypeMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskDefinitionTypeMaxFields'] = ResolversParentTypes['TaskDefinitionTypeMaxFields']
> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskDefinitionTypeMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskDefinitionTypeMinFields'] = ResolversParentTypes['TaskDefinitionTypeMinFields']
> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskDefinitionTypeMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskDefinitionTypeMutationResponse'] = ResolversParentTypes['TaskDefinitionTypeMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['TaskDefinitionType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TasksResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Tasks'] = ResolversParentTypes['Tasks']
> = {
  createdAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  data?: Resolver<
    ResolversTypes['jsonb'],
    ParentType,
    ContextType,
    RequireFields<TasksDataArgs, never>
  >;
  dueDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  effectiveDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  journey?: Resolver<Maybe<ResolversTypes['Journeys']>, ParentType, ContextType>;
  journeyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  seq?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taskDefinition?: Resolver<Maybe<ResolversTypes['TaskDefinition']>, ParentType, ContextType>;
  taskDefinitionId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TasksAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TasksAggregate'] = ResolversParentTypes['TasksAggregate']
> = {
  aggregate?: Resolver<Maybe<ResolversTypes['TasksAggregateFields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Tasks']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TasksAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TasksAggregateFields'] = ResolversParentTypes['TasksAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<TasksAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['TasksMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['TasksMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TasksMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TasksMaxFields'] = ResolversParentTypes['TasksMaxFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  dueDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  effectiveDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  journeyId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  seq?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taskDefinitionId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TasksMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TasksMinFields'] = ResolversParentTypes['TasksMinFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  dueDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  effectiveDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  journeyId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  seq?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taskDefinitionId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TasksMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TasksMutationResponse'] = ResolversParentTypes['TasksMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Tasks']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamOutput'] = ResolversParentTypes['TeamOutput']
> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Teams'] = ResolversParentTypes['Teams']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  manualLearnerJourneyStartDate?: Resolver<Maybe<ResolversTypes['date']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  onManualLearnerJourney?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  organization?: Resolver<Maybe<ResolversTypes['Organizations']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  parentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  parentTeam?: Resolver<Maybe<ResolversTypes['Teams']>, ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  teamJobFunction?: Resolver<Maybe<ResolversTypes['UsersFunctionsEnum']>, ParentType, ContextType>;
  teamUsers?: Resolver<
    Array<ResolversTypes['TeamsUsers']>,
    ParentType,
    ContextType,
    RequireFields<TeamsTeamUsersArgs, never>
  >;
  teamUsersAggregate?: Resolver<
    ResolversTypes['TeamsUsersAggregate'],
    ParentType,
    ContextType,
    RequireFields<TeamsTeamUsersAggregateArgs, never>
  >;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamsAggregate'] = ResolversParentTypes['TeamsAggregate']
> = {
  aggregate?: Resolver<Maybe<ResolversTypes['TeamsAggregateFields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Teams']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamsAggregateFields'] = ResolversParentTypes['TeamsAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<TeamsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['TeamsMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['TeamsMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamsMaxFields'] = ResolversParentTypes['TeamsMaxFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  manualLearnerJourneyStartDate?: Resolver<Maybe<ResolversTypes['date']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  parentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamsMinFields'] = ResolversParentTypes['TeamsMinFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  manualLearnerJourneyStartDate?: Resolver<Maybe<ResolversTypes['date']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  parentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamsMutationResponse'] = ResolversParentTypes['TeamsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Teams']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamsUsersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamsUsers'] = ResolversParentTypes['TeamsUsers']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  team?: Resolver<ResolversTypes['Teams'], ParentType, ContextType>;
  teamId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['Users'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamsUsersAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamsUsersAggregate'] = ResolversParentTypes['TeamsUsersAggregate']
> = {
  aggregate?: Resolver<Maybe<ResolversTypes['TeamsUsersAggregateFields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['TeamsUsers']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamsUsersAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamsUsersAggregateFields'] = ResolversParentTypes['TeamsUsersAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<TeamsUsersAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['TeamsUsersMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['TeamsUsersMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamsUsersMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamsUsersMaxFields'] = ResolversParentTypes['TeamsUsersMaxFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teamId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamsUsersMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamsUsersMinFields'] = ResolversParentTypes['TeamsUsersMinFields']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teamId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  updatedById?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamsUsersMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamsUsersMutationResponse'] = ResolversParentTypes['TeamsUsersMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['TeamsUsers']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeElapsedLiveClassesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimeElapsedLiveClasses'] = ResolversParentTypes['TimeElapsedLiveClasses']
> = {
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  numLiveClassesElapsed?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeElapsedLiveClassesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimeElapsedLiveClassesAggregate'] = ResolversParentTypes['TimeElapsedLiveClassesAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['TimeElapsedLiveClassesAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['TimeElapsedLiveClasses']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeElapsedLiveClassesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimeElapsedLiveClassesAggregateFields'] = ResolversParentTypes['TimeElapsedLiveClassesAggregateFields']
> = {
  avg?: Resolver<Maybe<ResolversTypes['TimeElapsedLiveClassesAvgFields']>, ParentType, ContextType>;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<TimeElapsedLiveClassesAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['TimeElapsedLiveClassesMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['TimeElapsedLiveClassesMinFields']>, ParentType, ContextType>;
  stddev?: Resolver<
    Maybe<ResolversTypes['TimeElapsedLiveClassesStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['TimeElapsedLiveClassesStddevPopFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['TimeElapsedLiveClassesStddevSampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<Maybe<ResolversTypes['TimeElapsedLiveClassesSumFields']>, ParentType, ContextType>;
  varPop?: Resolver<
    Maybe<ResolversTypes['TimeElapsedLiveClassesVarPopFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['TimeElapsedLiveClassesVarSampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['TimeElapsedLiveClassesVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeElapsedLiveClassesAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimeElapsedLiveClassesAvgFields'] = ResolversParentTypes['TimeElapsedLiveClassesAvgFields']
> = {
  numLiveClassesElapsed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeElapsedLiveClassesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimeElapsedLiveClassesMaxFields'] = ResolversParentTypes['TimeElapsedLiveClassesMaxFields']
> = {
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  numLiveClassesElapsed?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeElapsedLiveClassesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimeElapsedLiveClassesMinFields'] = ResolversParentTypes['TimeElapsedLiveClassesMinFields']
> = {
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  numLiveClassesElapsed?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeElapsedLiveClassesStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimeElapsedLiveClassesStddevFields'] = ResolversParentTypes['TimeElapsedLiveClassesStddevFields']
> = {
  numLiveClassesElapsed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeElapsedLiveClassesStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimeElapsedLiveClassesStddevPopFields'] = ResolversParentTypes['TimeElapsedLiveClassesStddevPopFields']
> = {
  numLiveClassesElapsed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeElapsedLiveClassesStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimeElapsedLiveClassesStddevSampFields'] = ResolversParentTypes['TimeElapsedLiveClassesStddevSampFields']
> = {
  numLiveClassesElapsed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeElapsedLiveClassesSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimeElapsedLiveClassesSumFields'] = ResolversParentTypes['TimeElapsedLiveClassesSumFields']
> = {
  numLiveClassesElapsed?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeElapsedLiveClassesVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimeElapsedLiveClassesVarPopFields'] = ResolversParentTypes['TimeElapsedLiveClassesVarPopFields']
> = {
  numLiveClassesElapsed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeElapsedLiveClassesVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimeElapsedLiveClassesVarSampFields'] = ResolversParentTypes['TimeElapsedLiveClassesVarSampFields']
> = {
  numLiveClassesElapsed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeElapsedLiveClassesVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimeElapsedLiveClassesVarianceFields'] = ResolversParentTypes['TimeElapsedLiveClassesVarianceFields']
> = {
  numLiveClassesElapsed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TypeformSubmissionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TypeformSubmissions'] = ResolversParentTypes['TypeformSubmissions']
> = {
  classEventId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  data?: Resolver<
    Maybe<ResolversTypes['jsonb']>,
    ParentType,
    ContextType,
    RequireFields<TypeformSubmissionsDataArgs, never>
  >;
  eventId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  formId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  responseId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  submittedAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TypeformSubmissionsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TypeformSubmissionsAggregate'] = ResolversParentTypes['TypeformSubmissionsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['TypeformSubmissionsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['TypeformSubmissions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TypeformSubmissionsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TypeformSubmissionsAggregateFields'] = ResolversParentTypes['TypeformSubmissionsAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<TypeformSubmissionsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['TypeformSubmissionsMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['TypeformSubmissionsMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TypeformSubmissionsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TypeformSubmissionsMaxFields'] = ResolversParentTypes['TypeformSubmissionsMaxFields']
> = {
  classEventId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  eventId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  formId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  responseId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  submittedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TypeformSubmissionsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TypeformSubmissionsMinFields'] = ResolversParentTypes['TypeformSubmissionsMinFields']
> = {
  classEventId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  eventId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  formId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  responseId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  submittedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TypeformSubmissionsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TypeformSubmissionsMutationResponse'] = ResolversParentTypes['TypeformSubmissionsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['TypeformSubmissions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnenrollOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnenrollOutput'] = ResolversParentTypes['UnenrollOutput']
> = {
  enrollmentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  unenrollmentStatus?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  waitlistId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdatePlaybackOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdatePlaybackOutput'] = ResolversParentTypes['UpdatePlaybackOutput']
> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserCompletedSurveysResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserCompletedSurveys'] = ResolversParentTypes['UserCompletedSurveys']
> = {
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  numCompleted?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserCompletedSurveysAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserCompletedSurveysAggregate'] = ResolversParentTypes['UserCompletedSurveysAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['UserCompletedSurveysAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['UserCompletedSurveys']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserCompletedSurveysAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserCompletedSurveysAggregateFields'] = ResolversParentTypes['UserCompletedSurveysAggregateFields']
> = {
  avg?: Resolver<Maybe<ResolversTypes['UserCompletedSurveysAvgFields']>, ParentType, ContextType>;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<UserCompletedSurveysAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['UserCompletedSurveysMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['UserCompletedSurveysMinFields']>, ParentType, ContextType>;
  stddev?: Resolver<
    Maybe<ResolversTypes['UserCompletedSurveysStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['UserCompletedSurveysStddevPopFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['UserCompletedSurveysStddevSampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<Maybe<ResolversTypes['UserCompletedSurveysSumFields']>, ParentType, ContextType>;
  varPop?: Resolver<
    Maybe<ResolversTypes['UserCompletedSurveysVarPopFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['UserCompletedSurveysVarSampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['UserCompletedSurveysVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserCompletedSurveysAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserCompletedSurveysAvgFields'] = ResolversParentTypes['UserCompletedSurveysAvgFields']
> = {
  numCompleted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserCompletedSurveysMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserCompletedSurveysMaxFields'] = ResolversParentTypes['UserCompletedSurveysMaxFields']
> = {
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  numCompleted?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserCompletedSurveysMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserCompletedSurveysMinFields'] = ResolversParentTypes['UserCompletedSurveysMinFields']
> = {
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  numCompleted?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserCompletedSurveysStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserCompletedSurveysStddevFields'] = ResolversParentTypes['UserCompletedSurveysStddevFields']
> = {
  numCompleted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserCompletedSurveysStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserCompletedSurveysStddevPopFields'] = ResolversParentTypes['UserCompletedSurveysStddevPopFields']
> = {
  numCompleted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserCompletedSurveysStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserCompletedSurveysStddevSampFields'] = ResolversParentTypes['UserCompletedSurveysStddevSampFields']
> = {
  numCompleted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserCompletedSurveysSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserCompletedSurveysSumFields'] = ResolversParentTypes['UserCompletedSurveysSumFields']
> = {
  numCompleted?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserCompletedSurveysVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserCompletedSurveysVarPopFields'] = ResolversParentTypes['UserCompletedSurveysVarPopFields']
> = {
  numCompleted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserCompletedSurveysVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserCompletedSurveysVarSampFields'] = ResolversParentTypes['UserCompletedSurveysVarSampFields']
> = {
  numCompleted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserCompletedSurveysVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserCompletedSurveysVarianceFields'] = ResolversParentTypes['UserCompletedSurveysVarianceFields']
> = {
  numCompleted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserNumCompletedClassesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserNumCompletedClasses'] = ResolversParentTypes['UserNumCompletedClasses']
> = {
  activityDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  classEventStartDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  numCompleted?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserNumCompletedClassesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserNumCompletedClassesAggregate'] = ResolversParentTypes['UserNumCompletedClassesAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['UserNumCompletedClassesAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['UserNumCompletedClasses']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserNumCompletedClassesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserNumCompletedClassesAggregateFields'] = ResolversParentTypes['UserNumCompletedClassesAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['UserNumCompletedClassesAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<UserNumCompletedClassesAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['UserNumCompletedClassesMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['UserNumCompletedClassesMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['UserNumCompletedClassesStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['UserNumCompletedClassesStddevPopFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['UserNumCompletedClassesStddevSampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['UserNumCompletedClassesSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['UserNumCompletedClassesVarPopFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['UserNumCompletedClassesVarSampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['UserNumCompletedClassesVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserNumCompletedClassesAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserNumCompletedClassesAvgFields'] = ResolversParentTypes['UserNumCompletedClassesAvgFields']
> = {
  numCompleted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserNumCompletedClassesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserNumCompletedClassesMaxFields'] = ResolversParentTypes['UserNumCompletedClassesMaxFields']
> = {
  activityDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  classEventStartDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  numCompleted?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserNumCompletedClassesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserNumCompletedClassesMinFields'] = ResolversParentTypes['UserNumCompletedClassesMinFields']
> = {
  activityDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  classEventStartDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  numCompleted?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserNumCompletedClassesStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserNumCompletedClassesStddevFields'] = ResolversParentTypes['UserNumCompletedClassesStddevFields']
> = {
  numCompleted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserNumCompletedClassesStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserNumCompletedClassesStddevPopFields'] = ResolversParentTypes['UserNumCompletedClassesStddevPopFields']
> = {
  numCompleted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserNumCompletedClassesStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserNumCompletedClassesStddevSampFields'] = ResolversParentTypes['UserNumCompletedClassesStddevSampFields']
> = {
  numCompleted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserNumCompletedClassesSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserNumCompletedClassesSumFields'] = ResolversParentTypes['UserNumCompletedClassesSumFields']
> = {
  numCompleted?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserNumCompletedClassesVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserNumCompletedClassesVarPopFields'] = ResolversParentTypes['UserNumCompletedClassesVarPopFields']
> = {
  numCompleted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserNumCompletedClassesVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserNumCompletedClassesVarSampFields'] = ResolversParentTypes['UserNumCompletedClassesVarSampFields']
> = {
  numCompleted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserNumCompletedClassesVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserNumCompletedClassesVarianceFields'] = ResolversParentTypes['UserNumCompletedClassesVarianceFields']
> = {
  numCompleted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserProfileCaptureOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserProfileCaptureOutput'] = ResolversParentTypes['UserProfileCaptureOutput']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserSessionEmailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserSessionEmails'] = ResolversParentTypes['UserSessionEmails']
> = {
  attendanceStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  classEvent?: Resolver<ResolversTypes['ClassEvents'], ParentType, ContextType>;
  classEventEndTime?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  classEventStartTime?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  classEventsId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  cohort?: Resolver<ResolversTypes['Cohorts'], ParentType, ContextType>;
  cohortId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  cohortSlug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cohortType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  courseName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  courseType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  creditModel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrollment?: Resolver<ResolversTypes['Enrollments'], ParentType, ContextType>;
  enrollmentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  formattedTimestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  frameworkUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasSentHourReminderEmail?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasSentMondayReminderEmail?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isReturningUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isStartingSoon?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isThisWeek?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  leadCoachCompany?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  leadCoachName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sentHourReminderEmailAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  sentMondayReminderEmailAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  user?: Resolver<ResolversTypes['Users'], ParentType, ContextType>;
  userClassEventId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserSessionEmailsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserSessionEmailsAggregate'] = ResolversParentTypes['UserSessionEmailsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['UserSessionEmailsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['UserSessionEmails']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserSessionEmailsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserSessionEmailsAggregateFields'] = ResolversParentTypes['UserSessionEmailsAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<UserSessionEmailsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['UserSessionEmailsMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['UserSessionEmailsMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserSessionEmailsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserSessionEmailsMaxFields'] = ResolversParentTypes['UserSessionEmailsMaxFields']
> = {
  attendanceStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classEventEndTime?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  classEventStartTime?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  classEventsId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  creditModel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrollmentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  formattedTimestamp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  frameworkUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  leadCoachCompany?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  leadCoachName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sentHourReminderEmailAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  sentMondayReminderEmailAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userClassEventId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserSessionEmailsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserSessionEmailsMinFields'] = ResolversParentTypes['UserSessionEmailsMinFields']
> = {
  attendanceStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classEventEndTime?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  classEventStartTime?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  classEventsId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cohortSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cohortType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  creditModel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrollmentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  formattedTimestamp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  frameworkUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  leadCoachCompany?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  leadCoachName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sentHourReminderEmailAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  sentMondayReminderEmailAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userClassEventId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserSessionEmailsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserSessionEmailsMutationResponse'] = ResolversParentTypes['UserSessionEmailsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['UserSessionEmails']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Users'] = ResolversParentTypes['Users']
> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  activeSubscription?: Resolver<
    Maybe<Array<ResolversTypes['Plans']>>,
    ParentType,
    ContextType,
    RequireFields<UsersActiveSubscriptionArgs, never>
  >;
  allowEnrollmentsBySubscription?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  birthDate?: Resolver<Maybe<ResolversTypes['bpchar']>, ParentType, ContextType>;
  classEventMeetingParticipants?: Resolver<
    Array<ResolversTypes['ClassEventMeetingParticipants']>,
    ParentType,
    ContextType,
    RequireFields<UsersClassEventMeetingParticipantsArgs, never>
  >;
  classEventMeetingParticipantsAggregate?: Resolver<
    ResolversTypes['ClassEventMeetingParticipantsAggregate'],
    ParentType,
    ContextType,
    RequireFields<UsersClassEventMeetingParticipantsAggregateArgs, never>
  >;
  cleanSpeakId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  coachLightningSessionSubmissions?: Resolver<
    Array<ResolversTypes['LightningSessionSubmissions']>,
    ParentType,
    ContextType,
    RequireFields<UsersCoachLightningSessionSubmissionsArgs, never>
  >;
  coachLightningSessionSubmissionsAggregate?: Resolver<
    ResolversTypes['LightningSessionSubmissionsAggregate'],
    ParentType,
    ContextType,
    RequireFields<UsersCoachLightningSessionSubmissionsAggregateArgs, never>
  >;
  coaches?: Resolver<
    Array<ResolversTypes['Coaches']>,
    ParentType,
    ContextType,
    RequireFields<UsersCoachesArgs, never>
  >;
  coachesAggregate?: Resolver<
    ResolversTypes['CoachesAggregate'],
    ParentType,
    ContextType,
    RequireFields<UsersCoachesAggregateArgs, never>
  >;
  data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrollments?: Resolver<
    Array<ResolversTypes['Enrollments']>,
    ParentType,
    ContextType,
    RequireFields<UsersEnrollmentsArgs, never>
  >;
  enrollmentsAggregate?: Resolver<
    ResolversTypes['EnrollmentsAggregate'],
    ParentType,
    ContextType,
    RequireFields<UsersEnrollmentsAggregateArgs, never>
  >;
  expiry?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  identity?: Resolver<
    Array<ResolversTypes['Identities']>,
    ParentType,
    ContextType,
    RequireFields<UsersIdentityArgs, never>
  >;
  identityAggregate?: Resolver<
    ResolversTypes['IdentitiesAggregate'],
    ParentType,
    ContextType,
    RequireFields<UsersIdentityAggregateArgs, never>
  >;
  identityProviderLinks?: Resolver<
    Array<ResolversTypes['IdentityProviderLinks']>,
    ParentType,
    ContextType,
    RequireFields<UsersIdentityProviderLinksArgs, never>
  >;
  identityProviderLinksAggregate?: Resolver<
    ResolversTypes['IdentityProviderLinksAggregate'],
    ParentType,
    ContextType,
    RequireFields<UsersIdentityProviderLinksAggregateArgs, never>
  >;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  insertInstant?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  journeys?: Resolver<
    Array<ResolversTypes['JourneyEnrollments']>,
    ParentType,
    ContextType,
    RequireFields<UsersJourneysArgs, never>
  >;
  journeysAggregate?: Resolver<
    ResolversTypes['JourneyEnrollmentsAggregate'],
    ParentType,
    ContextType,
    RequireFields<UsersJourneysAggregateArgs, never>
  >;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  learnerLightningSessionSubmissions?: Resolver<
    Array<ResolversTypes['LightningSessionSubmissions']>,
    ParentType,
    ContextType,
    RequireFields<UsersLearnerLightningSessionSubmissionsArgs, never>
  >;
  learnerLightningSessionSubmissionsAggregate?: Resolver<
    ResolversTypes['LightningSessionSubmissionsAggregate'],
    ParentType,
    ContextType,
    RequireFields<UsersLearnerLightningSessionSubmissionsAggregateArgs, never>
  >;
  maxSubscriptionEnrollments?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['UsersMeta']>, ParentType, ContextType>;
  middleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobilePhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationUsers?: Resolver<
    Array<ResolversTypes['OrganizationUsers']>,
    ParentType,
    ContextType,
    RequireFields<UsersOrganizationUsersArgs, never>
  >;
  organizationUsersAggregate?: Resolver<
    ResolversTypes['OrganizationUsersAggregate'],
    ParentType,
    ContextType,
    RequireFields<UsersOrganizationUsersAggregateArgs, never>
  >;
  parentEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teams?: Resolver<
    Array<ResolversTypes['TeamsUsers']>,
    ParentType,
    ContextType,
    RequireFields<UsersTeamsArgs, never>
  >;
  teamsAggregate?: Resolver<
    ResolversTypes['TeamsUsersAggregate'],
    ParentType,
    ContextType,
    RequireFields<UsersTeamsAggregateArgs, never>
  >;
  tenantsId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userRegistrations?: Resolver<
    Array<ResolversTypes['FusionauthUserRegistrations']>,
    ParentType,
    ContextType,
    RequireFields<UsersUserRegistrationsArgs, never>
  >;
  userRegistrationsAggregate?: Resolver<
    ResolversTypes['FusionauthUserRegistrationsAggregate'],
    ParentType,
    ContextType,
    RequireFields<UsersUserRegistrationsAggregateArgs, never>
  >;
  usersClassEventActivities?: Resolver<
    Array<ResolversTypes['ClassEventActivity']>,
    ParentType,
    ContextType,
    RequireFields<UsersUsersClassEventActivitiesArgs, never>
  >;
  usersClassEventActivitiesAggregate?: Resolver<
    ResolversTypes['ClassEventActivityAggregate'],
    ParentType,
    ContextType,
    RequireFields<UsersUsersClassEventActivitiesAggregateArgs, never>
  >;
  waitlists?: Resolver<
    Array<ResolversTypes['EnrollmentWaitlists']>,
    ParentType,
    ContextType,
    RequireFields<UsersWaitlistsArgs, never>
  >;
  waitlistsAggregate?: Resolver<
    ResolversTypes['EnrollmentWaitlistsAggregate'],
    ParentType,
    ContextType,
    RequireFields<UsersWaitlistsAggregateArgs, never>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersAggregate'] = ResolversParentTypes['UsersAggregate']
> = {
  aggregate?: Resolver<Maybe<ResolversTypes['UsersAggregateFields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Users']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersAggregateFields'] = ResolversParentTypes['UsersAggregateFields']
> = {
  avg?: Resolver<Maybe<ResolversTypes['UsersAvgFields']>, ParentType, ContextType>;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<UsersAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['UsersMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['UsersMinFields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['UsersStddevFields']>, ParentType, ContextType>;
  stddevPop?: Resolver<Maybe<ResolversTypes['UsersStddevPopFields']>, ParentType, ContextType>;
  stddevSamp?: Resolver<Maybe<ResolversTypes['UsersStddevSampFields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['UsersSumFields']>, ParentType, ContextType>;
  varPop?: Resolver<Maybe<ResolversTypes['UsersVarPopFields']>, ParentType, ContextType>;
  varSamp?: Resolver<Maybe<ResolversTypes['UsersVarSampFields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['UsersVarianceFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersAvgFields'] = ResolversParentTypes['UsersAvgFields']
> = {
  expiry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxSubscriptionEnrollments?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFunctionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersFunctions'] = ResolversParentTypes['UsersFunctions']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFunctionsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersFunctionsAggregate'] = ResolversParentTypes['UsersFunctionsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['UsersFunctionsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['UsersFunctions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFunctionsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersFunctionsAggregateFields'] = ResolversParentTypes['UsersFunctionsAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<UsersFunctionsAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['UsersFunctionsMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['UsersFunctionsMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFunctionsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersFunctionsMaxFields'] = ResolversParentTypes['UsersFunctionsMaxFields']
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFunctionsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersFunctionsMinFields'] = ResolversParentTypes['UsersFunctionsMinFields']
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersFunctionsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersFunctionsMutationResponse'] = ResolversParentTypes['UsersFunctionsMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['UsersFunctions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersLengthInFunctionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersLengthInFunction'] = ResolversParentTypes['UsersLengthInFunction']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersLengthInFunctionAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersLengthInFunctionAggregate'] = ResolversParentTypes['UsersLengthInFunctionAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['UsersLengthInFunctionAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['UsersLengthInFunction']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersLengthInFunctionAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersLengthInFunctionAggregateFields'] = ResolversParentTypes['UsersLengthInFunctionAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<UsersLengthInFunctionAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['UsersLengthInFunctionMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['UsersLengthInFunctionMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersLengthInFunctionMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersLengthInFunctionMaxFields'] = ResolversParentTypes['UsersLengthInFunctionMaxFields']
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersLengthInFunctionMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersLengthInFunctionMinFields'] = ResolversParentTypes['UsersLengthInFunctionMinFields']
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersLengthInFunctionMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersLengthInFunctionMutationResponse'] = ResolversParentTypes['UsersLengthInFunctionMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['UsersLengthInFunction']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersMaxFields'] = ResolversParentTypes['UsersMaxFields']
> = {
  birthDate?: Resolver<Maybe<ResolversTypes['bpchar']>, ParentType, ContextType>;
  cleanSpeakId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expiry?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  maxSubscriptionEnrollments?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  middleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobilePhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  parentEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tenantsId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersMetaResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersMeta'] = ResolversParentTypes['UsersMeta']
> = {
  acceptedTermsOfUse?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasFilledUserProfileData?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasParticipatedInLightningSession?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  isSubscribedToRecommendationEmails?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSubscribedToReminderEmails?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  jobFunction?: Resolver<Maybe<ResolversTypes['UsersFunctionsEnum']>, ParentType, ContextType>;
  jobFunctionData?: Resolver<Maybe<ResolversTypes['UsersFunctions']>, ParentType, ContextType>;
  jobFunctionLength?: Resolver<
    Maybe<ResolversTypes['UsersLengthInFunctionEnum']>,
    ParentType,
    ContextType
  >;
  jobLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  jobTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  joinDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  legacy?: Resolver<
    Maybe<ResolversTypes['jsonb']>,
    ParentType,
    ContextType,
    RequireFields<UsersMetaLegacyArgs, never>
  >;
  locale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  managerId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  organization?: Resolver<Maybe<ResolversTypes['Organizations']>, ParentType, ContextType>;
  selectedOrgId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['Users'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersMetaAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersMetaAggregate'] = ResolversParentTypes['UsersMetaAggregate']
> = {
  aggregate?: Resolver<Maybe<ResolversTypes['UsersMetaAggregateFields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['UsersMeta']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersMetaAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersMetaAggregateFields'] = ResolversParentTypes['UsersMetaAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<UsersMetaAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['UsersMetaMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['UsersMetaMinFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersMetaMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersMetaMaxFields'] = ResolversParentTypes['UsersMetaMaxFields']
> = {
  jobLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  jobTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  joinDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  locale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  managerId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  selectedOrgId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersMetaMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersMetaMinFields'] = ResolversParentTypes['UsersMetaMinFields']
> = {
  jobLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  jobTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  joinDate?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  locale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  managerId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  selectedOrgId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersMetaMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersMetaMutationResponse'] = ResolversParentTypes['UsersMetaMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['UsersMeta']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersMinFields'] = ResolversParentTypes['UsersMinFields']
> = {
  birthDate?: Resolver<Maybe<ResolversTypes['bpchar']>, ParentType, ContextType>;
  cleanSpeakId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expiry?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  maxSubscriptionEnrollments?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  middleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mobilePhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  parentEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tenantsId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersMutationResponse'] = ResolversParentTypes['UsersMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Users']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersStddevFields'] = ResolversParentTypes['UsersStddevFields']
> = {
  expiry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxSubscriptionEnrollments?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersStddevPopFields'] = ResolversParentTypes['UsersStddevPopFields']
> = {
  expiry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxSubscriptionEnrollments?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersStddevSampFields'] = ResolversParentTypes['UsersStddevSampFields']
> = {
  expiry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxSubscriptionEnrollments?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersSumFields'] = ResolversParentTypes['UsersSumFields']
> = {
  expiry?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  maxSubscriptionEnrollments?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersVarPopFields'] = ResolversParentTypes['UsersVarPopFields']
> = {
  expiry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxSubscriptionEnrollments?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersVarSampFields'] = ResolversParentTypes['UsersVarSampFields']
> = {
  expiry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxSubscriptionEnrollments?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersVarianceFields'] = ResolversParentTypes['UsersVarianceFields']
> = {
  expiry?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  insertInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastUpdateInstant?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxSubscriptionEnrollments?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ZoomOauthTokensResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ZoomOauthTokens'] = ResolversParentTypes['ZoomOauthTokens']
> = {
  bearerToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  expiresAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ZoomOauthTokensAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ZoomOauthTokensAggregate'] = ResolversParentTypes['ZoomOauthTokensAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['ZoomOauthTokensAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['ZoomOauthTokens']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ZoomOauthTokensAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ZoomOauthTokensAggregateFields'] = ResolversParentTypes['ZoomOauthTokensAggregateFields']
> = {
  avg?: Resolver<Maybe<ResolversTypes['ZoomOauthTokensAvgFields']>, ParentType, ContextType>;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<ZoomOauthTokensAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<Maybe<ResolversTypes['ZoomOauthTokensMaxFields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ZoomOauthTokensMinFields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['ZoomOauthTokensStddevFields']>, ParentType, ContextType>;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['ZoomOauthTokensStddevPopFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['ZoomOauthTokensStddevSampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<Maybe<ResolversTypes['ZoomOauthTokensSumFields']>, ParentType, ContextType>;
  varPop?: Resolver<Maybe<ResolversTypes['ZoomOauthTokensVarPopFields']>, ParentType, ContextType>;
  varSamp?: Resolver<
    Maybe<ResolversTypes['ZoomOauthTokensVarSampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['ZoomOauthTokensVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ZoomOauthTokensAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ZoomOauthTokensAvgFields'] = ResolversParentTypes['ZoomOauthTokensAvgFields']
> = {
  index?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ZoomOauthTokensMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ZoomOauthTokensMaxFields'] = ResolversParentTypes['ZoomOauthTokensMaxFields']
> = {
  bearerToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  expiresAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  index?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ZoomOauthTokensMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ZoomOauthTokensMinFields'] = ResolversParentTypes['ZoomOauthTokensMinFields']
> = {
  bearerToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  expiresAt?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  index?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ZoomOauthTokensMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ZoomOauthTokensMutationResponse'] = ResolversParentTypes['ZoomOauthTokensMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ZoomOauthTokens']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ZoomOauthTokensStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ZoomOauthTokensStddevFields'] = ResolversParentTypes['ZoomOauthTokensStddevFields']
> = {
  index?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ZoomOauthTokensStddevPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ZoomOauthTokensStddevPopFields'] = ResolversParentTypes['ZoomOauthTokensStddevPopFields']
> = {
  index?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ZoomOauthTokensStddevSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ZoomOauthTokensStddevSampFields'] = ResolversParentTypes['ZoomOauthTokensStddevSampFields']
> = {
  index?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ZoomOauthTokensSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ZoomOauthTokensSumFields'] = ResolversParentTypes['ZoomOauthTokensSumFields']
> = {
  index?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ZoomOauthTokensVarPopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ZoomOauthTokensVarPopFields'] = ResolversParentTypes['ZoomOauthTokensVarPopFields']
> = {
  index?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ZoomOauthTokensVarSampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ZoomOauthTokensVarSampFields'] = ResolversParentTypes['ZoomOauthTokensVarSampFields']
> = {
  index?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ZoomOauthTokensVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ZoomOauthTokensVarianceFields'] = ResolversParentTypes['ZoomOauthTokensVarianceFields']
> = {
  index?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface BigintScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['bigint'], any> {
  name: 'bigint';
}

export interface BpcharScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['bpchar'], any> {
  name: 'bpchar';
}

export type CreditUsageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['creditUsage'] = ResolversParentTypes['creditUsage']
> = {
  cohortName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cohortStartDate?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  courseName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  enrollmentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  scheduled?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  spent?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditUsageByUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['creditUsageByUser'] = ResolversParentTypes['creditUsageByUser']
> = {
  organizationId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  scheduled?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  spent?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['date'], any> {
  name: 'date';
}

export interface Float8ScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['float8'], any> {
  name: 'float8';
}

export type GetTeamMembersNewResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['getTeamMembersNew'] = ResolversParentTypes['getTeamMembersNew']
> = {
  avatarUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  creditUsage?: Resolver<
    Maybe<Array<ResolversTypes['creditUsage']>>,
    ParentType,
    ContextType,
    RequireFields<GetTeamMembersNewCreditUsageArgs, never>
  >;
  creditUsageAggregate?: Resolver<
    Maybe<ResolversTypes['creditUsageByUser']>,
    ParentType,
    ContextType
  >;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  pollSubmissions?: Resolver<
    Maybe<Array<ResolversTypes['teamMemberPollSubmissions']>>,
    ParentType,
    ContextType,
    RequireFields<GetTeamMembersNewPollSubmissionsArgs, never>
  >;
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  teamId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['json'], any> {
  name: 'json';
}

export interface JsonbScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['jsonb'], any> {
  name: 'jsonb';
}

export type Learner_TaskResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['learner_task'] = ResolversParentTypes['learner_task']
> = {
  cohort_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  cohort_slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  course_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  course_type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  is_done?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  join_id_or_link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  task_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionSubmissionsStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['lightningSessionSubmissionsStatus'] = ResolversParentTypes['lightningSessionSubmissionsStatus']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionSubmissionsStatusAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['lightningSessionSubmissionsStatusAggregate'] = ResolversParentTypes['lightningSessionSubmissionsStatusAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['lightningSessionSubmissionsStatusAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['lightningSessionSubmissionsStatus']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionSubmissionsStatusAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['lightningSessionSubmissionsStatusAggregateFields'] = ResolversParentTypes['lightningSessionSubmissionsStatusAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<LightningSessionSubmissionsStatusAggregateFieldsCountArgs, never>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['lightningSessionSubmissionsStatusMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['lightningSessionSubmissionsStatusMinFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionSubmissionsStatusMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['lightningSessionSubmissionsStatusMaxFields'] = ResolversParentTypes['lightningSessionSubmissionsStatusMaxFields']
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionSubmissionsStatusMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['lightningSessionSubmissionsStatusMinFields'] = ResolversParentTypes['lightningSessionSubmissionsStatusMinFields']
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LightningSessionSubmissionsStatusMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['lightningSessionSubmissionsStatusMutationResponse'] = ResolversParentTypes['lightningSessionSubmissionsStatusMutationResponse']
> = {
  affectedRows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['lightningSessionSubmissionsStatus']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Mutation_RootResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['mutation_root'] = ResolversParentTypes['mutation_root']
> = {
  AddMembersToTeam?: Resolver<
    Maybe<ResolversTypes['SuccessOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootAddMembersToTeamArgs, 'teamId' | 'userIds'>
  >;
  AddUserToCourseWaitlist?: Resolver<
    Maybe<ResolversTypes['AddUserToCourseWaitlistOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootAddUserToCourseWaitlistArgs, 'input'>
  >;
  AddUserToOrganization?: Resolver<
    Maybe<ResolversTypes['SuccessOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootAddUserToOrganizationArgs, 'organizationId' | 'teamId' | 'userId'>
  >;
  BeginClass?: Resolver<
    Maybe<ResolversTypes['SuccessOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootBeginClassArgs, 'classEventId'>
  >;
  BulkImportUsers?: Resolver<
    Maybe<ResolversTypes['SuccessOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootBulkImportUsersArgs, 'users'>
  >;
  ChangeTeamMemberRole?: Resolver<
    Maybe<ResolversTypes['SuccessOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootChangeTeamMemberRoleArgs, 'role' | 'teamId' | 'userId'>
  >;
  CourseWaitlistSendAvailabilityEmail?: Resolver<
    Maybe<ResolversTypes['CourseWaitlistSendAvailabilityEmailOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootCourseWaitlistSendAvailabilityEmailArgs, 'args'>
  >;
  CreateOrganization?: Resolver<
    Maybe<ResolversTypes['CreateOrganizationOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootCreateOrganizationArgs, 'name' | 'salesforceAccountId'>
  >;
  CreateRecording?: Resolver<
    Maybe<ResolversTypes['SuccessOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootCreateRecordingArgs, 'hash' | 'type'>
  >;
  CreateUser?: Resolver<
    Maybe<ResolversTypes['CreateUserOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootCreateUserArgs, 'email' | 'firstName' | 'lastName'>
  >;
  DeactivateUser?: Resolver<
    Maybe<ResolversTypes['SuccessOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeactivateUserArgs, 'userId'>
  >;
  DeleteTeam?: Resolver<
    Maybe<ResolversTypes['SuccessOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTeamArgs, 'id'>
  >;
  EndClass?: Resolver<
    Maybe<ResolversTypes['SuccessOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootEndClassArgs, 'classEventId'>
  >;
  EnrollLearner?: Resolver<
    Maybe<ResolversTypes['EnrollOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootEnrollLearnerArgs, 'input'>
  >;
  GetCohortEnrollmentStatus?: Resolver<
    Maybe<ResolversTypes['GetCohortEnrollmentStatusOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootGetCohortEnrollmentStatusArgs, 'cohortId'>
  >;
  ImpersonateUser?: Resolver<
    Maybe<ResolversTypes['SampleOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootImpersonateUserArgs, 'userId'>
  >;
  LaunchClassSurvey?: Resolver<
    Maybe<ResolversTypes['SuccessOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootLaunchClassSurveyArgs, 'classEventId'>
  >;
  LightningSession?: Resolver<
    Maybe<ResolversTypes['SuccessOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootLightningSessionArgs, 'action' | 'classEventId'>
  >;
  MarkRecordingWatched?: Resolver<
    Maybe<ResolversTypes['MarkRecordingWatchedOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootMarkRecordingWatchedArgs, 'hash'>
  >;
  MigrateSingleUser?: Resolver<
    Maybe<ResolversTypes['SuccessOutput']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootMigrateSingleUserArgs,
      'planId' | 'salesforceAccountId' | 'subscriptionNotes' | 'userId'
    >
  >;
  MoveMemberToTeam?: Resolver<
    Maybe<ResolversTypes['SuccessOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootMoveMemberToTeamArgs, 'fromTeamId' | 'memberUserId' | 'toTeamId'>
  >;
  PosterFrameBackfill?: Resolver<Maybe<ResolversTypes['SuccessOutput']>, ParentType, ContextType>;
  PurgePii?: Resolver<
    Maybe<ResolversTypes['SuccessOutput']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootPurgePiiArgs,
      'organizationId' | 'postHogApiKey' | 'postHogProjectId'
    >
  >;
  ReactivateUser?: Resolver<
    Maybe<ResolversTypes['SuccessOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootReactivateUserArgs, 'userId'>
  >;
  RegisterAnon?: Resolver<
    Maybe<ResolversTypes['RegisterAnonOutput']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootRegisterAnonArgs,
      'classEventId' | 'email' | 'firstName' | 'lastName'
    >
  >;
  RegisterLearner?: Resolver<
    Maybe<ResolversTypes['SuccessOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootRegisterLearnerArgs, 'classEventId' | 'userId'>
  >;
  RemoveTeamMember?: Resolver<
    Maybe<ResolversTypes['SuccessOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootRemoveTeamMemberArgs, 'organizationId' | 'teamId' | 'userId'>
  >;
  RemoveUserFromOrganization?: Resolver<
    Maybe<ResolversTypes['SuccessOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootRemoveUserFromOrganizationArgs, 'organizationId' | 'userId'>
  >;
  ResumeAssessment?: Resolver<
    Maybe<ResolversTypes['ResumeAssessmentOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootResumeAssessmentArgs, 'input'>
  >;
  RunPlay?: Resolver<
    Maybe<ResolversTypes['RunPlayOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootRunPlayArgs, 'playName'>
  >;
  SaveAssessment?: Resolver<
    Maybe<ResolversTypes['SaveAssessmentOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootSaveAssessmentArgs, 'attemptId'>
  >;
  SendEventInvite?: Resolver<
    Maybe<ResolversTypes['SuccessOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootSendEventInviteArgs, 'cohortId' | 'userId'>
  >;
  SetClassStatus?: Resolver<
    Maybe<ResolversTypes['SetClassStatusOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootSetClassStatusArgs, 'classEventId' | 'context'>
  >;
  SignToken?: Resolver<
    Maybe<ResolversTypes['SuccessOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootSignTokenArgs, 'classEventId' | 'roomName'>
  >;
  SignUpload?: Resolver<
    Maybe<ResolversTypes['SuccessOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootSignUploadArgs, 's3path'>
  >;
  StartAssessment?: Resolver<
    Maybe<ResolversTypes['StartAssessmentOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootStartAssessmentArgs, 'assessmentId' | 'cohortSlug'>
  >;
  StartPlayback?: Resolver<
    Maybe<ResolversTypes['StartPlaybackOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootStartPlaybackArgs, never>
  >;
  SubmitAndGradeAssessment?: Resolver<
    Maybe<ResolversTypes['SubmitAndGradeAssessmentOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootSubmitAndGradeAssessmentArgs, never>
  >;
  SyncZoomMeeting?: Resolver<
    Maybe<ResolversTypes['SuccessOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootSyncZoomMeetingArgs, 'classEventId'>
  >;
  UnenrollLearner?: Resolver<
    Maybe<ResolversTypes['UnenrollOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUnenrollLearnerArgs, 'input'>
  >;
  UpdatePlayback?: Resolver<
    Maybe<ResolversTypes['UpdatePlaybackOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePlaybackArgs, 'input'>
  >;
  UpdateUser?: Resolver<
    Maybe<ResolversTypes['SuccessOutput']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootUpdateUserArgs,
      'email' | 'firstName' | 'id' | 'lastName' | 'organizationId'
    >
  >;
  UpsertTeam?: Resolver<
    Maybe<ResolversTypes['TeamOutput']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootUpsertTeamArgs,
      'name' | 'organizationId' | 'parentId' | 'slug' | 'teamJobFunction'
    >
  >;
  UserProfileCapture?: Resolver<
    Maybe<ResolversTypes['UserProfileCaptureOutput']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootUserProfileCaptureArgs,
      'acceptedTermsOfUse' | 'firstName' | 'jobFunction' | 'jobFunctionLength' | 'lastName'
    >
  >;
  deleteActivityType?: Resolver<
    Maybe<ResolversTypes['ActivityTypeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteActivityTypeArgs, 'where'>
  >;
  deleteActivityTypeByPk?: Resolver<
    Maybe<ResolversTypes['ActivityType']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteActivityTypeByPkArgs, 'value'>
  >;
  deleteAttemptStatus?: Resolver<
    Maybe<ResolversTypes['AttemptStatusMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteAttemptStatusArgs, 'where'>
  >;
  deleteAttemptStatusByPk?: Resolver<
    Maybe<ResolversTypes['AttemptStatus']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteAttemptStatusByPkArgs, 'value'>
  >;
  deleteCatalogCourses?: Resolver<
    Maybe<ResolversTypes['CatalogCoursesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCatalogCoursesArgs, 'where'>
  >;
  deleteCatalogCoursesByPk?: Resolver<
    Maybe<ResolversTypes['CatalogCourses']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCatalogCoursesByPkArgs, 'catalogId' | 'courseId'>
  >;
  deleteCatalogs?: Resolver<
    Maybe<ResolversTypes['CatalogsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCatalogsArgs, 'where'>
  >;
  deleteCatalogsByPk?: Resolver<
    Maybe<ResolversTypes['Catalogs']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCatalogsByPkArgs, 'id'>
  >;
  deleteCatalogsJourneysRel?: Resolver<
    Maybe<ResolversTypes['CatalogsJourneysRelMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCatalogsJourneysRelArgs, 'where'>
  >;
  deleteCatalogsJourneysRelByPk?: Resolver<
    Maybe<ResolversTypes['CatalogsJourneysRel']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCatalogsJourneysRelByPkArgs, 'catalogId' | 'journeyId'>
  >;
  deleteClassCoaches?: Resolver<
    Maybe<ResolversTypes['ClassCoachesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteClassCoachesArgs, 'where'>
  >;
  deleteClassCoachesByPk?: Resolver<
    Maybe<ResolversTypes['ClassCoaches']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteClassCoachesByPkArgs, 'classId' | 'coachId'>
  >;
  deleteClassEventActivity?: Resolver<
    Maybe<ResolversTypes['ClassEventActivityMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteClassEventActivityArgs, 'where'>
  >;
  deleteClassEventActivityByPk?: Resolver<
    Maybe<ResolversTypes['ClassEventActivity']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteClassEventActivityByPkArgs, 'id'>
  >;
  deleteClassEventAnonRegistrations?: Resolver<
    Maybe<ResolversTypes['ClassEventAnonRegistrationsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteClassEventAnonRegistrationsArgs, 'where'>
  >;
  deleteClassEventAnonRegistrationsByPk?: Resolver<
    Maybe<ResolversTypes['ClassEventAnonRegistrations']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteClassEventAnonRegistrationsByPkArgs, 'id'>
  >;
  deleteClassEventInvitations?: Resolver<
    Maybe<ResolversTypes['ClassEventInvitationsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteClassEventInvitationsArgs, 'where'>
  >;
  deleteClassEventInvitationsByPk?: Resolver<
    Maybe<ResolversTypes['ClassEventInvitations']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteClassEventInvitationsByPkArgs, 'id'>
  >;
  deleteClassEventMeetingParticipants?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingParticipantsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteClassEventMeetingParticipantsArgs, 'where'>
  >;
  deleteClassEventMeetingParticipantsByPk?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingParticipants']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteClassEventMeetingParticipantsByPkArgs, 'participantId'>
  >;
  deleteClassEventMeetingSegments?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingSegmentsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteClassEventMeetingSegmentsArgs, 'where'>
  >;
  deleteClassEventMeetingSegmentsByPk?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingSegments']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteClassEventMeetingSegmentsByPkArgs, 'id'>
  >;
  deleteClassEventMeetings?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteClassEventMeetingsArgs, 'where'>
  >;
  deleteClassEventMeetingsByPk?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetings']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteClassEventMeetingsByPkArgs, 'id'>
  >;
  deleteClassEventRegistrations?: Resolver<
    Maybe<ResolversTypes['ClassEventRegistrationsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteClassEventRegistrationsArgs, 'where'>
  >;
  deleteClassEventRegistrationsByPk?: Resolver<
    Maybe<ResolversTypes['ClassEventRegistrations']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteClassEventRegistrationsByPkArgs, 'id'>
  >;
  deleteClassEvents?: Resolver<
    Maybe<ResolversTypes['ClassEventsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteClassEventsArgs, 'where'>
  >;
  deleteClassEventsByPk?: Resolver<
    Maybe<ResolversTypes['ClassEvents']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteClassEventsByPkArgs, 'id'>
  >;
  deleteClassPlaybackActivity?: Resolver<
    Maybe<ResolversTypes['ClassPlaybackActivityMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteClassPlaybackActivityArgs, 'where'>
  >;
  deleteClassPlaybackActivityByPk?: Resolver<
    Maybe<ResolversTypes['ClassPlaybackActivity']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteClassPlaybackActivityByPkArgs, 'id'>
  >;
  deleteClassPlaybackActivityType?: Resolver<
    Maybe<ResolversTypes['ClassPlaybackActivityTypeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteClassPlaybackActivityTypeArgs, 'where'>
  >;
  deleteClassPlaybackActivityTypeByPk?: Resolver<
    Maybe<ResolversTypes['ClassPlaybackActivityType']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteClassPlaybackActivityTypeByPkArgs, 'value'>
  >;
  deleteClassResources?: Resolver<
    Maybe<ResolversTypes['ClassResourcesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteClassResourcesArgs, 'where'>
  >;
  deleteClassResourcesByPk?: Resolver<
    Maybe<ResolversTypes['ClassResources']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteClassResourcesByPkArgs, 'classId' | 'resourceId'>
  >;
  deleteClasses?: Resolver<
    Maybe<ResolversTypes['ClassesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteClassesArgs, 'where'>
  >;
  deleteClassesByPk?: Resolver<
    Maybe<ResolversTypes['Classes']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteClassesByPkArgs, 'id'>
  >;
  deleteClassesTagsRel?: Resolver<
    Maybe<ResolversTypes['ClassesTagsRelMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteClassesTagsRelArgs, 'where'>
  >;
  deleteClassesTagsRelByPk?: Resolver<
    Maybe<ResolversTypes['ClassesTagsRel']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteClassesTagsRelByPkArgs, 'classId' | 'tagId'>
  >;
  deleteCoaches?: Resolver<
    Maybe<ResolversTypes['CoachesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCoachesArgs, 'where'>
  >;
  deleteCoachesByPk?: Resolver<
    Maybe<ResolversTypes['Coaches']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCoachesByPkArgs, 'id'>
  >;
  deleteCohortCoaches?: Resolver<
    Maybe<ResolversTypes['CohortCoachesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCohortCoachesArgs, 'where'>
  >;
  deleteCohortCoachesByPk?: Resolver<
    Maybe<ResolversTypes['CohortCoaches']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCohortCoachesByPkArgs, 'coachId' | 'cohortId'>
  >;
  deleteCohortType?: Resolver<
    Maybe<ResolversTypes['CohortTypeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCohortTypeArgs, 'where'>
  >;
  deleteCohortTypeByPk?: Resolver<
    Maybe<ResolversTypes['CohortType']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCohortTypeByPkArgs, 'value'>
  >;
  deleteCohortVisibilityEnum?: Resolver<
    Maybe<ResolversTypes['CohortVisibilityEnumMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCohortVisibilityEnumArgs, 'where'>
  >;
  deleteCohortVisibilityEnumByPk?: Resolver<
    Maybe<ResolversTypes['CohortVisibilityEnum']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCohortVisibilityEnumByPkArgs, 'value'>
  >;
  deleteCohorts?: Resolver<
    Maybe<ResolversTypes['CohortsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCohortsArgs, 'where'>
  >;
  deleteCohortsByPk?: Resolver<
    Maybe<ResolversTypes['Cohorts']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCohortsByPkArgs, 'id'>
  >;
  deleteCohortsResourcesRel?: Resolver<
    Maybe<ResolversTypes['CohortsResourcesRelMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCohortsResourcesRelArgs, 'where'>
  >;
  deleteCohortsResourcesRelByPk?: Resolver<
    Maybe<ResolversTypes['CohortsResourcesRel']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCohortsResourcesRelByPkArgs, 'cohortId' | 'resourceId'>
  >;
  deleteConfigurations?: Resolver<
    Maybe<ResolversTypes['ConfigurationsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteConfigurationsArgs, 'where'>
  >;
  deleteConfigurationsByPk?: Resolver<
    Maybe<ResolversTypes['Configurations']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteConfigurationsByPkArgs, 'key'>
  >;
  deleteCourseCertifications?: Resolver<
    Maybe<ResolversTypes['CourseCertificationsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCourseCertificationsArgs, 'where'>
  >;
  deleteCourseCertificationsByPk?: Resolver<
    Maybe<ResolversTypes['CourseCertifications']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCourseCertificationsByPkArgs, 'id'>
  >;
  deleteCourseCoaches?: Resolver<
    Maybe<ResolversTypes['CourseCoachesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCourseCoachesArgs, 'where'>
  >;
  deleteCourseCoachesByPk?: Resolver<
    Maybe<ResolversTypes['CourseCoaches']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCourseCoachesByPkArgs, 'coachId' | 'courseId'>
  >;
  deleteCourseEnrollmentStatus?: Resolver<
    Maybe<ResolversTypes['CourseEnrollmentStatusMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCourseEnrollmentStatusArgs, 'where'>
  >;
  deleteCourseEnrollmentStatusByPk?: Resolver<
    Maybe<ResolversTypes['CourseEnrollmentStatus']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCourseEnrollmentStatusByPkArgs, 'value'>
  >;
  deleteCoursePublishStatus?: Resolver<
    Maybe<ResolversTypes['CoursePublishStatusMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCoursePublishStatusArgs, 'where'>
  >;
  deleteCoursePublishStatusByPk?: Resolver<
    Maybe<ResolversTypes['CoursePublishStatus']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCoursePublishStatusByPkArgs, 'value'>
  >;
  deleteCourseType?: Resolver<
    Maybe<ResolversTypes['CourseTypeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCourseTypeArgs, 'where'>
  >;
  deleteCourseTypeByPk?: Resolver<
    Maybe<ResolversTypes['CourseType']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCourseTypeByPkArgs, 'value'>
  >;
  deleteCourseWaitlist?: Resolver<
    Maybe<ResolversTypes['CourseWaitlistMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCourseWaitlistArgs, 'where'>
  >;
  deleteCourseWaitlistByPk?: Resolver<
    Maybe<ResolversTypes['CourseWaitlist']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCourseWaitlistByPkArgs, 'id'>
  >;
  deleteCourseWaitlistDesiredUrgencyType?: Resolver<
    Maybe<ResolversTypes['CourseWaitlistDesiredUrgencyTypeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCourseWaitlistDesiredUrgencyTypeArgs, 'where'>
  >;
  deleteCourseWaitlistDesiredUrgencyTypeByPk?: Resolver<
    Maybe<ResolversTypes['CourseWaitlistDesiredUrgencyType']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCourseWaitlistDesiredUrgencyTypeByPkArgs, 'value'>
  >;
  deleteCourses?: Resolver<
    Maybe<ResolversTypes['CoursesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCoursesArgs, 'where'>
  >;
  deleteCoursesByPk?: Resolver<
    Maybe<ResolversTypes['Courses']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCoursesByPkArgs, 'id'>
  >;
  deleteCoursesResourcesRel?: Resolver<
    Maybe<ResolversTypes['CoursesResourcesRelMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCoursesResourcesRelArgs, 'where'>
  >;
  deleteCoursesResourcesRelByPk?: Resolver<
    Maybe<ResolversTypes['CoursesResourcesRel']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCoursesResourcesRelByPkArgs, 'courseId' | 'resourceId'>
  >;
  deleteCoursesTagsRel?: Resolver<
    Maybe<ResolversTypes['CoursesTagsRelMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCoursesTagsRelArgs, 'where'>
  >;
  deleteCoursesTagsRelByPk?: Resolver<
    Maybe<ResolversTypes['CoursesTagsRel']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCoursesTagsRelByPkArgs, 'courseId' | 'tagId'>
  >;
  deleteEmailQueue?: Resolver<
    Maybe<ResolversTypes['EmailQueueMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteEmailQueueArgs, 'where'>
  >;
  deleteEmailQueueByPk?: Resolver<
    Maybe<ResolversTypes['EmailQueue']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteEmailQueueByPkArgs, 'id'>
  >;
  deleteEnrollmentWaitlists?: Resolver<
    Maybe<ResolversTypes['EnrollmentWaitlistsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteEnrollmentWaitlistsArgs, 'where'>
  >;
  deleteEnrollmentWaitlistsByPk?: Resolver<
    Maybe<ResolversTypes['EnrollmentWaitlists']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteEnrollmentWaitlistsByPkArgs, 'id'>
  >;
  deleteEnrollments?: Resolver<
    Maybe<ResolversTypes['EnrollmentsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteEnrollmentsArgs, 'where'>
  >;
  deleteEnrollmentsByPk?: Resolver<
    Maybe<ResolversTypes['Enrollments']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteEnrollmentsByPkArgs, 'id'>
  >;
  deleteFusionauthExternalIdentifiers?: Resolver<
    Maybe<ResolversTypes['FusionauthExternalIdentifiersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteFusionauthExternalIdentifiersArgs, 'where'>
  >;
  deleteFusionauthExternalIdentifiersByPk?: Resolver<
    Maybe<ResolversTypes['FusionauthExternalIdentifiers']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteFusionauthExternalIdentifiersByPkArgs, 'id'>
  >;
  deleteFusionauthUserRegistrations?: Resolver<
    Maybe<ResolversTypes['FusionauthUserRegistrationsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteFusionauthUserRegistrationsArgs, 'where'>
  >;
  deleteFusionauthUserRegistrationsByPk?: Resolver<
    Maybe<ResolversTypes['FusionauthUserRegistrations']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteFusionauthUserRegistrationsByPkArgs, 'id'>
  >;
  deleteIdentities?: Resolver<
    Maybe<ResolversTypes['IdentitiesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteIdentitiesArgs, 'where'>
  >;
  deleteIdentitiesByPk?: Resolver<
    Maybe<ResolversTypes['Identities']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteIdentitiesByPkArgs, 'id'>
  >;
  deleteIdentityProviderLinks?: Resolver<
    Maybe<ResolversTypes['IdentityProviderLinksMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteIdentityProviderLinksArgs, 'where'>
  >;
  deleteIdentityProviders?: Resolver<
    Maybe<ResolversTypes['IdentityProvidersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteIdentityProvidersArgs, 'where'>
  >;
  deleteIdentityProvidersByPk?: Resolver<
    Maybe<ResolversTypes['IdentityProviders']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteIdentityProvidersByPkArgs, 'id'>
  >;
  deleteImpactAssessmentAttempts?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentAttemptsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteImpactAssessmentAttemptsArgs, 'where'>
  >;
  deleteImpactAssessmentAttemptsByPk?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentAttempts']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteImpactAssessmentAttemptsByPkArgs, 'id'>
  >;
  deleteImpactAssessmentQuestions?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentQuestionsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteImpactAssessmentQuestionsArgs, 'where'>
  >;
  deleteImpactAssessments?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteImpactAssessmentsArgs, 'where'>
  >;
  deleteImpactAssessmentsByPk?: Resolver<
    Maybe<ResolversTypes['ImpactAssessments']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteImpactAssessmentsByPkArgs, 'id'>
  >;
  deleteJourneyEnrollments?: Resolver<
    Maybe<ResolversTypes['JourneyEnrollmentsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteJourneyEnrollmentsArgs, 'where'>
  >;
  deleteJourneyEnrollmentsByPk?: Resolver<
    Maybe<ResolversTypes['JourneyEnrollments']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteJourneyEnrollmentsByPkArgs, 'id'>
  >;
  deleteJourneys?: Resolver<
    Maybe<ResolversTypes['JourneysMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteJourneysArgs, 'where'>
  >;
  deleteJourneysByPk?: Resolver<
    Maybe<ResolversTypes['Journeys']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteJourneysByPkArgs, 'id'>
  >;
  deleteLedger?: Resolver<
    Maybe<ResolversTypes['LedgerMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteLedgerArgs, 'where'>
  >;
  deleteLedgerByPk?: Resolver<
    Maybe<ResolversTypes['Ledger']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteLedgerByPkArgs, 'id'>
  >;
  deleteLedgerProductEnum?: Resolver<
    Maybe<ResolversTypes['LedgerProductEnumMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteLedgerProductEnumArgs, 'where'>
  >;
  deleteLedgerProductEnumByPk?: Resolver<
    Maybe<ResolversTypes['LedgerProductEnum']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteLedgerProductEnumByPkArgs, 'value'>
  >;
  deleteLedgerTransactionTypeEnum?: Resolver<
    Maybe<ResolversTypes['LedgerTransactionTypeEnumMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteLedgerTransactionTypeEnumArgs, 'where'>
  >;
  deleteLedgerTransactionTypeEnumByPk?: Resolver<
    Maybe<ResolversTypes['LedgerTransactionTypeEnum']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteLedgerTransactionTypeEnumByPkArgs, 'value'>
  >;
  deleteLightningSession?: Resolver<
    Maybe<ResolversTypes['LightningSessionMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteLightningSessionArgs, 'where'>
  >;
  deleteLightningSessionByPk?: Resolver<
    Maybe<ResolversTypes['LightningSession']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteLightningSessionByPkArgs, 'id'>
  >;
  deleteLightningSessionSubmissions?: Resolver<
    Maybe<ResolversTypes['LightningSessionSubmissionsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteLightningSessionSubmissionsArgs, 'where'>
  >;
  deleteLightningSessionSubmissionsByPk?: Resolver<
    Maybe<ResolversTypes['LightningSessionSubmissions']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteLightningSessionSubmissionsByPkArgs, 'id'>
  >;
  deleteLightningSessionSubmissionsStatus?: Resolver<
    Maybe<ResolversTypes['lightningSessionSubmissionsStatusMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteLightningSessionSubmissionsStatusArgs, 'where'>
  >;
  deleteLightningSessionSubmissionsStatusByPk?: Resolver<
    Maybe<ResolversTypes['lightningSessionSubmissionsStatus']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteLightningSessionSubmissionsStatusByPkArgs, 'value'>
  >;
  deleteLightningSessionSubmissionsUsersRel?: Resolver<
    Maybe<ResolversTypes['LightningSessionSubmissionsUsersRelMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteLightningSessionSubmissionsUsersRelArgs, 'where'>
  >;
  deleteLightningSessionSubmissionsUsersRelByPk?: Resolver<
    Maybe<ResolversTypes['LightningSessionSubmissionsUsersRel']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootDeleteLightningSessionSubmissionsUsersRelByPkArgs,
      'lightningSessionSubmissionId' | 'userId'
    >
  >;
  deleteManagerSessionEmails?: Resolver<
    Maybe<ResolversTypes['ManagerSessionEmailsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteManagerSessionEmailsArgs, 'where'>
  >;
  deleteManagerSessionEmailsByPk?: Resolver<
    Maybe<ResolversTypes['ManagerSessionEmails']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteManagerSessionEmailsByPkArgs, 'managerCohortId'>
  >;
  deleteNonceAccessToken?: Resolver<
    Maybe<ResolversTypes['NonceAccessTokenMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteNonceAccessTokenArgs, 'where'>
  >;
  deleteNonceAccessTokenByPk?: Resolver<
    Maybe<ResolversTypes['NonceAccessToken']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteNonceAccessTokenByPkArgs, 'id'>
  >;
  deleteOrganizationUsers?: Resolver<
    Maybe<ResolversTypes['OrganizationUsersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteOrganizationUsersArgs, 'where'>
  >;
  deleteOrganizationUsersByPk?: Resolver<
    Maybe<ResolversTypes['OrganizationUsers']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteOrganizationUsersByPkArgs, 'id'>
  >;
  deleteOrganizations?: Resolver<
    Maybe<ResolversTypes['OrganizationsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteOrganizationsArgs, 'where'>
  >;
  deleteOrganizationsByPk?: Resolver<
    Maybe<ResolversTypes['Organizations']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteOrganizationsByPkArgs, 'id'>
  >;
  deleteOrganizationsMeta?: Resolver<
    Maybe<ResolversTypes['OrganizationsMetaMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteOrganizationsMetaArgs, 'where'>
  >;
  deleteOrganizationsMetaByPk?: Resolver<
    Maybe<ResolversTypes['OrganizationsMeta']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteOrganizationsMetaByPkArgs, 'organizationId'>
  >;
  deletePlanSubscriptions?: Resolver<
    Maybe<ResolversTypes['PlanSubscriptionsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeletePlanSubscriptionsArgs, 'where'>
  >;
  deletePlanSubscriptionsByPk?: Resolver<
    Maybe<ResolversTypes['PlanSubscriptions']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeletePlanSubscriptionsByPkArgs, 'id'>
  >;
  deletePlans?: Resolver<
    Maybe<ResolversTypes['PlansMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeletePlansArgs, 'where'>
  >;
  deletePlansByPk?: Resolver<
    Maybe<ResolversTypes['Plans']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeletePlansByPkArgs, 'id'>
  >;
  deletePlayExecution?: Resolver<
    Maybe<ResolversTypes['PlayExecutionMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeletePlayExecutionArgs, 'where'>
  >;
  deletePlayExecutionByPk?: Resolver<
    Maybe<ResolversTypes['PlayExecution']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeletePlayExecutionByPkArgs, 'id'>
  >;
  deleteProgramActionsTaken?: Resolver<
    Maybe<ResolversTypes['ProgramActionsTakenMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteProgramActionsTakenArgs, 'where'>
  >;
  deleteProgramCoursesShown?: Resolver<
    Maybe<ResolversTypes['ProgramCoursesShownMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteProgramCoursesShownArgs, 'where'>
  >;
  deleteProgramCoursesShownByPk?: Resolver<
    Maybe<ResolversTypes['ProgramCoursesShown']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteProgramCoursesShownByPkArgs, 'courseId' | 'programId'>
  >;
  deleteProgramTags?: Resolver<
    Maybe<ResolversTypes['ProgramTagsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteProgramTagsArgs, 'where'>
  >;
  deleteProgramTagsByPk?: Resolver<
    Maybe<ResolversTypes['ProgramTags']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteProgramTagsByPkArgs, 'programId' | 'tagId'>
  >;
  deleteProgramUsersSelected?: Resolver<
    Maybe<ResolversTypes['ProgramUsersSelectedMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteProgramUsersSelectedArgs, 'where'>
  >;
  deleteProgramUsersSelectedByPk?: Resolver<
    Maybe<ResolversTypes['ProgramUsersSelected']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteProgramUsersSelectedByPkArgs, 'programId' | 'userId'>
  >;
  deletePrograms?: Resolver<
    Maybe<ResolversTypes['ProgramsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteProgramsArgs, 'where'>
  >;
  deleteProgramsByPk?: Resolver<
    Maybe<ResolversTypes['Programs']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteProgramsByPkArgs, 'id'>
  >;
  deleteQuestionType?: Resolver<
    Maybe<ResolversTypes['QuestionTypeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteQuestionTypeArgs, 'where'>
  >;
  deleteQuestionTypeByPk?: Resolver<
    Maybe<ResolversTypes['QuestionType']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteQuestionTypeByPkArgs, 'value'>
  >;
  deleteRecordingActivity?: Resolver<
    Maybe<ResolversTypes['RecordingActivityMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteRecordingActivityArgs, 'where'>
  >;
  deleteRecordingActivityByPk?: Resolver<
    Maybe<ResolversTypes['RecordingActivity']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteRecordingActivityByPkArgs, 'id'>
  >;
  deleteRecordings?: Resolver<
    Maybe<ResolversTypes['RecordingsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteRecordingsArgs, 'where'>
  >;
  deleteRecordingsByPk?: Resolver<
    Maybe<ResolversTypes['Recordings']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteRecordingsByPkArgs, 'id'>
  >;
  deleteRelatedCourses?: Resolver<
    Maybe<ResolversTypes['RelatedCoursesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteRelatedCoursesArgs, 'where'>
  >;
  deleteRelatedCoursesByPk?: Resolver<
    Maybe<ResolversTypes['RelatedCourses']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteRelatedCoursesByPkArgs, 'courseId' | 'relatedCourseSlug'>
  >;
  deleteReportPublishStatus?: Resolver<
    Maybe<ResolversTypes['ReportPublishStatusMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteReportPublishStatusArgs, 'where'>
  >;
  deleteReportPublishStatusByPk?: Resolver<
    Maybe<ResolversTypes['ReportPublishStatus']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteReportPublishStatusByPkArgs, 'value'>
  >;
  deleteReportType?: Resolver<
    Maybe<ResolversTypes['ReportTypeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteReportTypeArgs, 'where'>
  >;
  deleteReportTypeByPk?: Resolver<
    Maybe<ResolversTypes['ReportType']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteReportTypeByPkArgs, 'value'>
  >;
  deleteReports?: Resolver<
    Maybe<ResolversTypes['ReportsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteReportsArgs, 'where'>
  >;
  deleteReportsByPk?: Resolver<
    Maybe<ResolversTypes['Reports']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteReportsByPkArgs, 'id'>
  >;
  deleteResourceType?: Resolver<
    Maybe<ResolversTypes['ResourceTypeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteResourceTypeArgs, 'where'>
  >;
  deleteResourceTypeByPk?: Resolver<
    Maybe<ResolversTypes['ResourceType']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteResourceTypeByPkArgs, 'value'>
  >;
  deleteResources?: Resolver<
    Maybe<ResolversTypes['ResourcesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteResourcesArgs, 'where'>
  >;
  deleteResourcesByPk?: Resolver<
    Maybe<ResolversTypes['Resources']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteResourcesByPkArgs, 'id'>
  >;
  deleteSchools?: Resolver<
    Maybe<ResolversTypes['SchoolsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteSchoolsArgs, 'where'>
  >;
  deleteSchoolsByPk?: Resolver<
    Maybe<ResolversTypes['Schools']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteSchoolsByPkArgs, 'id'>
  >;
  deleteSeatType?: Resolver<
    Maybe<ResolversTypes['SeatTypeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteSeatTypeArgs, 'where'>
  >;
  deleteSeatTypeByPk?: Resolver<
    Maybe<ResolversTypes['SeatType']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteSeatTypeByPkArgs, 'value'>
  >;
  deleteSeats?: Resolver<
    Maybe<ResolversTypes['SeatsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteSeatsArgs, 'where'>
  >;
  deleteSeatsByPk?: Resolver<
    Maybe<ResolversTypes['Seats']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteSeatsByPkArgs, 'id'>
  >;
  deleteStreamMessages?: Resolver<
    Maybe<ResolversTypes['StreamMessagesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteStreamMessagesArgs, 'where'>
  >;
  deleteStreamMessagesByPk?: Resolver<
    Maybe<ResolversTypes['StreamMessages']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteStreamMessagesByPkArgs, 'id'>
  >;
  deleteTagTypeScope?: Resolver<
    Maybe<ResolversTypes['TagTypeScopeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTagTypeScopeArgs, 'where'>
  >;
  deleteTagTypeScopeByPk?: Resolver<
    Maybe<ResolversTypes['TagTypeScope']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTagTypeScopeByPkArgs, 'value'>
  >;
  deleteTagTypes?: Resolver<
    Maybe<ResolversTypes['TagTypesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTagTypesArgs, 'where'>
  >;
  deleteTagTypesByPk?: Resolver<
    Maybe<ResolversTypes['TagTypes']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTagTypesByPkArgs, 'id'>
  >;
  deleteTags?: Resolver<
    Maybe<ResolversTypes['TagsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTagsArgs, 'where'>
  >;
  deleteTagsByPk?: Resolver<
    Maybe<ResolversTypes['Tags']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTagsByPkArgs, 'id'>
  >;
  deleteTaskDefinition?: Resolver<
    Maybe<ResolversTypes['TaskDefinitionMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTaskDefinitionArgs, 'where'>
  >;
  deleteTaskDefinitionByPk?: Resolver<
    Maybe<ResolversTypes['TaskDefinition']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTaskDefinitionByPkArgs, 'id'>
  >;
  deleteTaskDefinitionType?: Resolver<
    Maybe<ResolversTypes['TaskDefinitionTypeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTaskDefinitionTypeArgs, 'where'>
  >;
  deleteTaskDefinitionTypeByPk?: Resolver<
    Maybe<ResolversTypes['TaskDefinitionType']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTaskDefinitionTypeByPkArgs, 'value'>
  >;
  deleteTasks?: Resolver<
    Maybe<ResolversTypes['TasksMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTasksArgs, 'where'>
  >;
  deleteTasksByPk?: Resolver<
    Maybe<ResolversTypes['Tasks']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTasksByPkArgs, 'id'>
  >;
  deleteTeams?: Resolver<
    Maybe<ResolversTypes['TeamsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTeamsArgs, 'where'>
  >;
  deleteTeamsByPk?: Resolver<
    Maybe<ResolversTypes['Teams']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTeamsByPkArgs, 'id'>
  >;
  deleteTeamsUsers?: Resolver<
    Maybe<ResolversTypes['TeamsUsersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTeamsUsersArgs, 'where'>
  >;
  deleteTeamsUsersByPk?: Resolver<
    Maybe<ResolversTypes['TeamsUsers']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTeamsUsersByPkArgs, 'teamId' | 'userId'>
  >;
  deleteTypeformSubmissions?: Resolver<
    Maybe<ResolversTypes['TypeformSubmissionsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTypeformSubmissionsArgs, 'where'>
  >;
  deleteTypeformSubmissionsByPk?: Resolver<
    Maybe<ResolversTypes['TypeformSubmissions']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTypeformSubmissionsByPkArgs, 'id'>
  >;
  deleteUserSessionEmails?: Resolver<
    Maybe<ResolversTypes['UserSessionEmailsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteUserSessionEmailsArgs, 'where'>
  >;
  deleteUserSessionEmailsByPk?: Resolver<
    Maybe<ResolversTypes['UserSessionEmails']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteUserSessionEmailsByPkArgs, 'userClassEventId'>
  >;
  deleteUsers?: Resolver<
    Maybe<ResolversTypes['UsersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteUsersArgs, 'where'>
  >;
  deleteUsersByPk?: Resolver<
    Maybe<ResolversTypes['Users']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteUsersByPkArgs, 'id'>
  >;
  deleteUsersFunctions?: Resolver<
    Maybe<ResolversTypes['UsersFunctionsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteUsersFunctionsArgs, 'where'>
  >;
  deleteUsersFunctionsByPk?: Resolver<
    Maybe<ResolversTypes['UsersFunctions']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteUsersFunctionsByPkArgs, 'value'>
  >;
  deleteUsersLengthInFunction?: Resolver<
    Maybe<ResolversTypes['UsersLengthInFunctionMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteUsersLengthInFunctionArgs, 'where'>
  >;
  deleteUsersLengthInFunctionByPk?: Resolver<
    Maybe<ResolversTypes['UsersLengthInFunction']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteUsersLengthInFunctionByPkArgs, 'value'>
  >;
  deleteUsersMeta?: Resolver<
    Maybe<ResolversTypes['UsersMetaMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteUsersMetaArgs, 'where'>
  >;
  deleteUsersMetaByPk?: Resolver<
    Maybe<ResolversTypes['UsersMeta']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteUsersMetaByPkArgs, 'userId'>
  >;
  deleteZoomOauthTokens?: Resolver<
    Maybe<ResolversTypes['ZoomOauthTokensMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteZoomOauthTokensArgs, 'where'>
  >;
  deleteZoomOauthTokensByPk?: Resolver<
    Maybe<ResolversTypes['ZoomOauthTokens']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteZoomOauthTokensByPkArgs, 'bearerToken'>
  >;
  insertActivityType?: Resolver<
    Maybe<ResolversTypes['ActivityTypeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertActivityTypeArgs, 'objects'>
  >;
  insertActivityTypeOne?: Resolver<
    Maybe<ResolversTypes['ActivityType']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertActivityTypeOneArgs, 'object'>
  >;
  insertAttemptStatus?: Resolver<
    Maybe<ResolversTypes['AttemptStatusMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertAttemptStatusArgs, 'objects'>
  >;
  insertAttemptStatusOne?: Resolver<
    Maybe<ResolversTypes['AttemptStatus']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertAttemptStatusOneArgs, 'object'>
  >;
  insertCatalogCourses?: Resolver<
    Maybe<ResolversTypes['CatalogCoursesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCatalogCoursesArgs, 'objects'>
  >;
  insertCatalogCoursesOne?: Resolver<
    Maybe<ResolversTypes['CatalogCourses']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCatalogCoursesOneArgs, 'object'>
  >;
  insertCatalogs?: Resolver<
    Maybe<ResolversTypes['CatalogsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCatalogsArgs, 'objects'>
  >;
  insertCatalogsJourneysRel?: Resolver<
    Maybe<ResolversTypes['CatalogsJourneysRelMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCatalogsJourneysRelArgs, 'objects'>
  >;
  insertCatalogsJourneysRelOne?: Resolver<
    Maybe<ResolversTypes['CatalogsJourneysRel']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCatalogsJourneysRelOneArgs, 'object'>
  >;
  insertCatalogsOne?: Resolver<
    Maybe<ResolversTypes['Catalogs']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCatalogsOneArgs, 'object'>
  >;
  insertClassCoaches?: Resolver<
    Maybe<ResolversTypes['ClassCoachesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertClassCoachesArgs, 'objects'>
  >;
  insertClassCoachesOne?: Resolver<
    Maybe<ResolversTypes['ClassCoaches']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertClassCoachesOneArgs, 'object'>
  >;
  insertClassEventActivity?: Resolver<
    Maybe<ResolversTypes['ClassEventActivityMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertClassEventActivityArgs, 'objects'>
  >;
  insertClassEventActivityOne?: Resolver<
    Maybe<ResolversTypes['ClassEventActivity']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertClassEventActivityOneArgs, 'object'>
  >;
  insertClassEventAnonRegistrations?: Resolver<
    Maybe<ResolversTypes['ClassEventAnonRegistrationsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertClassEventAnonRegistrationsArgs, 'objects'>
  >;
  insertClassEventAnonRegistrationsOne?: Resolver<
    Maybe<ResolversTypes['ClassEventAnonRegistrations']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertClassEventAnonRegistrationsOneArgs, 'object'>
  >;
  insertClassEventInvitations?: Resolver<
    Maybe<ResolversTypes['ClassEventInvitationsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertClassEventInvitationsArgs, 'objects'>
  >;
  insertClassEventInvitationsOne?: Resolver<
    Maybe<ResolversTypes['ClassEventInvitations']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertClassEventInvitationsOneArgs, 'object'>
  >;
  insertClassEventMeetingParticipants?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingParticipantsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertClassEventMeetingParticipantsArgs, 'objects'>
  >;
  insertClassEventMeetingParticipantsOne?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingParticipants']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertClassEventMeetingParticipantsOneArgs, 'object'>
  >;
  insertClassEventMeetingSegments?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingSegmentsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertClassEventMeetingSegmentsArgs, 'objects'>
  >;
  insertClassEventMeetingSegmentsOne?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingSegments']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertClassEventMeetingSegmentsOneArgs, 'object'>
  >;
  insertClassEventMeetings?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertClassEventMeetingsArgs, 'objects'>
  >;
  insertClassEventMeetingsOne?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetings']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertClassEventMeetingsOneArgs, 'object'>
  >;
  insertClassEventRegistrations?: Resolver<
    Maybe<ResolversTypes['ClassEventRegistrationsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertClassEventRegistrationsArgs, 'objects'>
  >;
  insertClassEventRegistrationsOne?: Resolver<
    Maybe<ResolversTypes['ClassEventRegistrations']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertClassEventRegistrationsOneArgs, 'object'>
  >;
  insertClassEvents?: Resolver<
    Maybe<ResolversTypes['ClassEventsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertClassEventsArgs, 'objects'>
  >;
  insertClassEventsOne?: Resolver<
    Maybe<ResolversTypes['ClassEvents']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertClassEventsOneArgs, 'object'>
  >;
  insertClassPlaybackActivity?: Resolver<
    Maybe<ResolversTypes['ClassPlaybackActivityMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertClassPlaybackActivityArgs, 'objects'>
  >;
  insertClassPlaybackActivityOne?: Resolver<
    Maybe<ResolversTypes['ClassPlaybackActivity']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertClassPlaybackActivityOneArgs, 'object'>
  >;
  insertClassPlaybackActivityType?: Resolver<
    Maybe<ResolversTypes['ClassPlaybackActivityTypeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertClassPlaybackActivityTypeArgs, 'objects'>
  >;
  insertClassPlaybackActivityTypeOne?: Resolver<
    Maybe<ResolversTypes['ClassPlaybackActivityType']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertClassPlaybackActivityTypeOneArgs, 'object'>
  >;
  insertClassResources?: Resolver<
    Maybe<ResolversTypes['ClassResourcesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertClassResourcesArgs, 'objects'>
  >;
  insertClassResourcesOne?: Resolver<
    Maybe<ResolversTypes['ClassResources']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertClassResourcesOneArgs, 'object'>
  >;
  insertClasses?: Resolver<
    Maybe<ResolversTypes['ClassesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertClassesArgs, 'objects'>
  >;
  insertClassesOne?: Resolver<
    Maybe<ResolversTypes['Classes']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertClassesOneArgs, 'object'>
  >;
  insertClassesTagsRel?: Resolver<
    Maybe<ResolversTypes['ClassesTagsRelMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertClassesTagsRelArgs, 'objects'>
  >;
  insertClassesTagsRelOne?: Resolver<
    Maybe<ResolversTypes['ClassesTagsRel']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertClassesTagsRelOneArgs, 'object'>
  >;
  insertCoaches?: Resolver<
    Maybe<ResolversTypes['CoachesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCoachesArgs, 'objects'>
  >;
  insertCoachesOne?: Resolver<
    Maybe<ResolversTypes['Coaches']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCoachesOneArgs, 'object'>
  >;
  insertCohortCoaches?: Resolver<
    Maybe<ResolversTypes['CohortCoachesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCohortCoachesArgs, 'objects'>
  >;
  insertCohortCoachesOne?: Resolver<
    Maybe<ResolversTypes['CohortCoaches']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCohortCoachesOneArgs, 'object'>
  >;
  insertCohortType?: Resolver<
    Maybe<ResolversTypes['CohortTypeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCohortTypeArgs, 'objects'>
  >;
  insertCohortTypeOne?: Resolver<
    Maybe<ResolversTypes['CohortType']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCohortTypeOneArgs, 'object'>
  >;
  insertCohortVisibilityEnum?: Resolver<
    Maybe<ResolversTypes['CohortVisibilityEnumMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCohortVisibilityEnumArgs, 'objects'>
  >;
  insertCohortVisibilityEnumOne?: Resolver<
    Maybe<ResolversTypes['CohortVisibilityEnum']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCohortVisibilityEnumOneArgs, 'object'>
  >;
  insertCohorts?: Resolver<
    Maybe<ResolversTypes['CohortsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCohortsArgs, 'objects'>
  >;
  insertCohortsOne?: Resolver<
    Maybe<ResolversTypes['Cohorts']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCohortsOneArgs, 'object'>
  >;
  insertCohortsResourcesRel?: Resolver<
    Maybe<ResolversTypes['CohortsResourcesRelMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCohortsResourcesRelArgs, 'objects'>
  >;
  insertCohortsResourcesRelOne?: Resolver<
    Maybe<ResolversTypes['CohortsResourcesRel']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCohortsResourcesRelOneArgs, 'object'>
  >;
  insertConfigurations?: Resolver<
    Maybe<ResolversTypes['ConfigurationsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertConfigurationsArgs, 'objects'>
  >;
  insertConfigurationsOne?: Resolver<
    Maybe<ResolversTypes['Configurations']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertConfigurationsOneArgs, 'object'>
  >;
  insertCourseCertifications?: Resolver<
    Maybe<ResolversTypes['CourseCertificationsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCourseCertificationsArgs, 'objects'>
  >;
  insertCourseCertificationsOne?: Resolver<
    Maybe<ResolversTypes['CourseCertifications']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCourseCertificationsOneArgs, 'object'>
  >;
  insertCourseCoaches?: Resolver<
    Maybe<ResolversTypes['CourseCoachesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCourseCoachesArgs, 'objects'>
  >;
  insertCourseCoachesOne?: Resolver<
    Maybe<ResolversTypes['CourseCoaches']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCourseCoachesOneArgs, 'object'>
  >;
  insertCourseEnrollmentStatus?: Resolver<
    Maybe<ResolversTypes['CourseEnrollmentStatusMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCourseEnrollmentStatusArgs, 'objects'>
  >;
  insertCourseEnrollmentStatusOne?: Resolver<
    Maybe<ResolversTypes['CourseEnrollmentStatus']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCourseEnrollmentStatusOneArgs, 'object'>
  >;
  insertCoursePublishStatus?: Resolver<
    Maybe<ResolversTypes['CoursePublishStatusMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCoursePublishStatusArgs, 'objects'>
  >;
  insertCoursePublishStatusOne?: Resolver<
    Maybe<ResolversTypes['CoursePublishStatus']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCoursePublishStatusOneArgs, 'object'>
  >;
  insertCourseType?: Resolver<
    Maybe<ResolversTypes['CourseTypeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCourseTypeArgs, 'objects'>
  >;
  insertCourseTypeOne?: Resolver<
    Maybe<ResolversTypes['CourseType']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCourseTypeOneArgs, 'object'>
  >;
  insertCourseWaitlist?: Resolver<
    Maybe<ResolversTypes['CourseWaitlistMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCourseWaitlistArgs, 'objects'>
  >;
  insertCourseWaitlistDesiredUrgencyType?: Resolver<
    Maybe<ResolversTypes['CourseWaitlistDesiredUrgencyTypeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCourseWaitlistDesiredUrgencyTypeArgs, 'objects'>
  >;
  insertCourseWaitlistDesiredUrgencyTypeOne?: Resolver<
    Maybe<ResolversTypes['CourseWaitlistDesiredUrgencyType']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCourseWaitlistDesiredUrgencyTypeOneArgs, 'object'>
  >;
  insertCourseWaitlistOne?: Resolver<
    Maybe<ResolversTypes['CourseWaitlist']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCourseWaitlistOneArgs, 'object'>
  >;
  insertCourses?: Resolver<
    Maybe<ResolversTypes['CoursesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCoursesArgs, 'objects'>
  >;
  insertCoursesOne?: Resolver<
    Maybe<ResolversTypes['Courses']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCoursesOneArgs, 'object'>
  >;
  insertCoursesResourcesRel?: Resolver<
    Maybe<ResolversTypes['CoursesResourcesRelMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCoursesResourcesRelArgs, 'objects'>
  >;
  insertCoursesResourcesRelOne?: Resolver<
    Maybe<ResolversTypes['CoursesResourcesRel']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCoursesResourcesRelOneArgs, 'object'>
  >;
  insertCoursesTagsRel?: Resolver<
    Maybe<ResolversTypes['CoursesTagsRelMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCoursesTagsRelArgs, 'objects'>
  >;
  insertCoursesTagsRelOne?: Resolver<
    Maybe<ResolversTypes['CoursesTagsRel']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCoursesTagsRelOneArgs, 'object'>
  >;
  insertEmailQueue?: Resolver<
    Maybe<ResolversTypes['EmailQueueMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertEmailQueueArgs, 'objects'>
  >;
  insertEmailQueueOne?: Resolver<
    Maybe<ResolversTypes['EmailQueue']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertEmailQueueOneArgs, 'object'>
  >;
  insertEnrollmentWaitlists?: Resolver<
    Maybe<ResolversTypes['EnrollmentWaitlistsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertEnrollmentWaitlistsArgs, 'objects'>
  >;
  insertEnrollmentWaitlistsOne?: Resolver<
    Maybe<ResolversTypes['EnrollmentWaitlists']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertEnrollmentWaitlistsOneArgs, 'object'>
  >;
  insertEnrollments?: Resolver<
    Maybe<ResolversTypes['EnrollmentsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertEnrollmentsArgs, 'objects'>
  >;
  insertEnrollmentsOne?: Resolver<
    Maybe<ResolversTypes['Enrollments']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertEnrollmentsOneArgs, 'object'>
  >;
  insertFusionauthExternalIdentifiers?: Resolver<
    Maybe<ResolversTypes['FusionauthExternalIdentifiersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertFusionauthExternalIdentifiersArgs, 'objects'>
  >;
  insertFusionauthExternalIdentifiersOne?: Resolver<
    Maybe<ResolversTypes['FusionauthExternalIdentifiers']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertFusionauthExternalIdentifiersOneArgs, 'object'>
  >;
  insertFusionauthUserRegistrations?: Resolver<
    Maybe<ResolversTypes['FusionauthUserRegistrationsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertFusionauthUserRegistrationsArgs, 'objects'>
  >;
  insertFusionauthUserRegistrationsOne?: Resolver<
    Maybe<ResolversTypes['FusionauthUserRegistrations']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertFusionauthUserRegistrationsOneArgs, 'object'>
  >;
  insertIdentities?: Resolver<
    Maybe<ResolversTypes['IdentitiesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertIdentitiesArgs, 'objects'>
  >;
  insertIdentitiesOne?: Resolver<
    Maybe<ResolversTypes['Identities']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertIdentitiesOneArgs, 'object'>
  >;
  insertIdentityProviderLinks?: Resolver<
    Maybe<ResolversTypes['IdentityProviderLinksMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertIdentityProviderLinksArgs, 'objects'>
  >;
  insertIdentityProviderLinksOne?: Resolver<
    Maybe<ResolversTypes['IdentityProviderLinks']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertIdentityProviderLinksOneArgs, 'object'>
  >;
  insertIdentityProviders?: Resolver<
    Maybe<ResolversTypes['IdentityProvidersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertIdentityProvidersArgs, 'objects'>
  >;
  insertIdentityProvidersOne?: Resolver<
    Maybe<ResolversTypes['IdentityProviders']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertIdentityProvidersOneArgs, 'object'>
  >;
  insertImpactAssessmentAttempts?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentAttemptsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertImpactAssessmentAttemptsArgs, 'objects'>
  >;
  insertImpactAssessmentAttemptsOne?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentAttempts']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertImpactAssessmentAttemptsOneArgs, 'object'>
  >;
  insertImpactAssessmentQuestions?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentQuestionsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertImpactAssessmentQuestionsArgs, 'objects'>
  >;
  insertImpactAssessmentQuestionsOne?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentQuestions']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertImpactAssessmentQuestionsOneArgs, 'object'>
  >;
  insertImpactAssessments?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertImpactAssessmentsArgs, 'objects'>
  >;
  insertImpactAssessmentsOne?: Resolver<
    Maybe<ResolversTypes['ImpactAssessments']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertImpactAssessmentsOneArgs, 'object'>
  >;
  insertJourneyEnrollments?: Resolver<
    Maybe<ResolversTypes['JourneyEnrollmentsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertJourneyEnrollmentsArgs, 'objects'>
  >;
  insertJourneyEnrollmentsOne?: Resolver<
    Maybe<ResolversTypes['JourneyEnrollments']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertJourneyEnrollmentsOneArgs, 'object'>
  >;
  insertJourneys?: Resolver<
    Maybe<ResolversTypes['JourneysMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertJourneysArgs, 'objects'>
  >;
  insertJourneysOne?: Resolver<
    Maybe<ResolversTypes['Journeys']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertJourneysOneArgs, 'object'>
  >;
  insertLedger?: Resolver<
    Maybe<ResolversTypes['LedgerMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertLedgerArgs, 'objects'>
  >;
  insertLedgerOne?: Resolver<
    Maybe<ResolversTypes['Ledger']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertLedgerOneArgs, 'object'>
  >;
  insertLedgerProductEnum?: Resolver<
    Maybe<ResolversTypes['LedgerProductEnumMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertLedgerProductEnumArgs, 'objects'>
  >;
  insertLedgerProductEnumOne?: Resolver<
    Maybe<ResolversTypes['LedgerProductEnum']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertLedgerProductEnumOneArgs, 'object'>
  >;
  insertLedgerTransactionTypeEnum?: Resolver<
    Maybe<ResolversTypes['LedgerTransactionTypeEnumMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertLedgerTransactionTypeEnumArgs, 'objects'>
  >;
  insertLedgerTransactionTypeEnumOne?: Resolver<
    Maybe<ResolversTypes['LedgerTransactionTypeEnum']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertLedgerTransactionTypeEnumOneArgs, 'object'>
  >;
  insertLightningSession?: Resolver<
    Maybe<ResolversTypes['LightningSessionMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertLightningSessionArgs, 'objects'>
  >;
  insertLightningSessionOne?: Resolver<
    Maybe<ResolversTypes['LightningSession']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertLightningSessionOneArgs, 'object'>
  >;
  insertLightningSessionSubmissions?: Resolver<
    Maybe<ResolversTypes['LightningSessionSubmissionsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertLightningSessionSubmissionsArgs, 'objects'>
  >;
  insertLightningSessionSubmissionsOne?: Resolver<
    Maybe<ResolversTypes['LightningSessionSubmissions']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertLightningSessionSubmissionsOneArgs, 'object'>
  >;
  insertLightningSessionSubmissionsStatus?: Resolver<
    Maybe<ResolversTypes['lightningSessionSubmissionsStatusMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertLightningSessionSubmissionsStatusArgs, 'objects'>
  >;
  insertLightningSessionSubmissionsStatusOne?: Resolver<
    Maybe<ResolversTypes['lightningSessionSubmissionsStatus']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertLightningSessionSubmissionsStatusOneArgs, 'object'>
  >;
  insertLightningSessionSubmissionsUsersRel?: Resolver<
    Maybe<ResolversTypes['LightningSessionSubmissionsUsersRelMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertLightningSessionSubmissionsUsersRelArgs, 'objects'>
  >;
  insertLightningSessionSubmissionsUsersRelOne?: Resolver<
    Maybe<ResolversTypes['LightningSessionSubmissionsUsersRel']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertLightningSessionSubmissionsUsersRelOneArgs, 'object'>
  >;
  insertManagerSessionEmails?: Resolver<
    Maybe<ResolversTypes['ManagerSessionEmailsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertManagerSessionEmailsArgs, 'objects'>
  >;
  insertManagerSessionEmailsOne?: Resolver<
    Maybe<ResolversTypes['ManagerSessionEmails']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertManagerSessionEmailsOneArgs, 'object'>
  >;
  insertNonceAccessToken?: Resolver<
    Maybe<ResolversTypes['NonceAccessTokenMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertNonceAccessTokenArgs, 'objects'>
  >;
  insertNonceAccessTokenOne?: Resolver<
    Maybe<ResolversTypes['NonceAccessToken']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertNonceAccessTokenOneArgs, 'object'>
  >;
  insertOrganizationUsers?: Resolver<
    Maybe<ResolversTypes['OrganizationUsersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertOrganizationUsersArgs, 'objects'>
  >;
  insertOrganizationUsersOne?: Resolver<
    Maybe<ResolversTypes['OrganizationUsers']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertOrganizationUsersOneArgs, 'object'>
  >;
  insertOrganizations?: Resolver<
    Maybe<ResolversTypes['OrganizationsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertOrganizationsArgs, 'objects'>
  >;
  insertOrganizationsMeta?: Resolver<
    Maybe<ResolversTypes['OrganizationsMetaMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertOrganizationsMetaArgs, 'objects'>
  >;
  insertOrganizationsMetaOne?: Resolver<
    Maybe<ResolversTypes['OrganizationsMeta']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertOrganizationsMetaOneArgs, 'object'>
  >;
  insertOrganizationsOne?: Resolver<
    Maybe<ResolversTypes['Organizations']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertOrganizationsOneArgs, 'object'>
  >;
  insertPlanSubscriptions?: Resolver<
    Maybe<ResolversTypes['PlanSubscriptionsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertPlanSubscriptionsArgs, 'objects'>
  >;
  insertPlanSubscriptionsOne?: Resolver<
    Maybe<ResolversTypes['PlanSubscriptions']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertPlanSubscriptionsOneArgs, 'object'>
  >;
  insertPlans?: Resolver<
    Maybe<ResolversTypes['PlansMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertPlansArgs, 'objects'>
  >;
  insertPlansOne?: Resolver<
    Maybe<ResolversTypes['Plans']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertPlansOneArgs, 'object'>
  >;
  insertPlayExecution?: Resolver<
    Maybe<ResolversTypes['PlayExecutionMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertPlayExecutionArgs, 'objects'>
  >;
  insertPlayExecutionOne?: Resolver<
    Maybe<ResolversTypes['PlayExecution']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertPlayExecutionOneArgs, 'object'>
  >;
  insertProgramActionsTaken?: Resolver<
    Maybe<ResolversTypes['ProgramActionsTakenMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertProgramActionsTakenArgs, 'objects'>
  >;
  insertProgramActionsTakenOne?: Resolver<
    Maybe<ResolversTypes['ProgramActionsTaken']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertProgramActionsTakenOneArgs, 'object'>
  >;
  insertProgramCoursesShown?: Resolver<
    Maybe<ResolversTypes['ProgramCoursesShownMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertProgramCoursesShownArgs, 'objects'>
  >;
  insertProgramCoursesShownOne?: Resolver<
    Maybe<ResolversTypes['ProgramCoursesShown']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertProgramCoursesShownOneArgs, 'object'>
  >;
  insertProgramTags?: Resolver<
    Maybe<ResolversTypes['ProgramTagsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertProgramTagsArgs, 'objects'>
  >;
  insertProgramTagsOne?: Resolver<
    Maybe<ResolversTypes['ProgramTags']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertProgramTagsOneArgs, 'object'>
  >;
  insertProgramUsersSelected?: Resolver<
    Maybe<ResolversTypes['ProgramUsersSelectedMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertProgramUsersSelectedArgs, 'objects'>
  >;
  insertProgramUsersSelectedOne?: Resolver<
    Maybe<ResolversTypes['ProgramUsersSelected']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertProgramUsersSelectedOneArgs, 'object'>
  >;
  insertPrograms?: Resolver<
    Maybe<ResolversTypes['ProgramsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertProgramsArgs, 'objects'>
  >;
  insertProgramsOne?: Resolver<
    Maybe<ResolversTypes['Programs']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertProgramsOneArgs, 'object'>
  >;
  insertQuestionType?: Resolver<
    Maybe<ResolversTypes['QuestionTypeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertQuestionTypeArgs, 'objects'>
  >;
  insertQuestionTypeOne?: Resolver<
    Maybe<ResolversTypes['QuestionType']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertQuestionTypeOneArgs, 'object'>
  >;
  insertRecordingActivity?: Resolver<
    Maybe<ResolversTypes['RecordingActivityMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertRecordingActivityArgs, 'objects'>
  >;
  insertRecordingActivityOne?: Resolver<
    Maybe<ResolversTypes['RecordingActivity']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertRecordingActivityOneArgs, 'object'>
  >;
  insertRecordings?: Resolver<
    Maybe<ResolversTypes['RecordingsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertRecordingsArgs, 'objects'>
  >;
  insertRecordingsOne?: Resolver<
    Maybe<ResolversTypes['Recordings']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertRecordingsOneArgs, 'object'>
  >;
  insertRelatedCourses?: Resolver<
    Maybe<ResolversTypes['RelatedCoursesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertRelatedCoursesArgs, 'objects'>
  >;
  insertRelatedCoursesOne?: Resolver<
    Maybe<ResolversTypes['RelatedCourses']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertRelatedCoursesOneArgs, 'object'>
  >;
  insertReportPublishStatus?: Resolver<
    Maybe<ResolversTypes['ReportPublishStatusMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertReportPublishStatusArgs, 'objects'>
  >;
  insertReportPublishStatusOne?: Resolver<
    Maybe<ResolversTypes['ReportPublishStatus']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertReportPublishStatusOneArgs, 'object'>
  >;
  insertReportType?: Resolver<
    Maybe<ResolversTypes['ReportTypeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertReportTypeArgs, 'objects'>
  >;
  insertReportTypeOne?: Resolver<
    Maybe<ResolversTypes['ReportType']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertReportTypeOneArgs, 'object'>
  >;
  insertReports?: Resolver<
    Maybe<ResolversTypes['ReportsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertReportsArgs, 'objects'>
  >;
  insertReportsOne?: Resolver<
    Maybe<ResolversTypes['Reports']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertReportsOneArgs, 'object'>
  >;
  insertResourceType?: Resolver<
    Maybe<ResolversTypes['ResourceTypeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertResourceTypeArgs, 'objects'>
  >;
  insertResourceTypeOne?: Resolver<
    Maybe<ResolversTypes['ResourceType']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertResourceTypeOneArgs, 'object'>
  >;
  insertResources?: Resolver<
    Maybe<ResolversTypes['ResourcesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertResourcesArgs, 'objects'>
  >;
  insertResourcesOne?: Resolver<
    Maybe<ResolversTypes['Resources']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertResourcesOneArgs, 'object'>
  >;
  insertSchools?: Resolver<
    Maybe<ResolversTypes['SchoolsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertSchoolsArgs, 'objects'>
  >;
  insertSchoolsOne?: Resolver<
    Maybe<ResolversTypes['Schools']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertSchoolsOneArgs, 'object'>
  >;
  insertSeatType?: Resolver<
    Maybe<ResolversTypes['SeatTypeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertSeatTypeArgs, 'objects'>
  >;
  insertSeatTypeOne?: Resolver<
    Maybe<ResolversTypes['SeatType']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertSeatTypeOneArgs, 'object'>
  >;
  insertSeats?: Resolver<
    Maybe<ResolversTypes['SeatsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertSeatsArgs, 'objects'>
  >;
  insertSeatsOne?: Resolver<
    Maybe<ResolversTypes['Seats']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertSeatsOneArgs, 'object'>
  >;
  insertStreamMessages?: Resolver<
    Maybe<ResolversTypes['StreamMessagesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertStreamMessagesArgs, 'objects'>
  >;
  insertStreamMessagesOne?: Resolver<
    Maybe<ResolversTypes['StreamMessages']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertStreamMessagesOneArgs, 'object'>
  >;
  insertTagTypeScope?: Resolver<
    Maybe<ResolversTypes['TagTypeScopeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTagTypeScopeArgs, 'objects'>
  >;
  insertTagTypeScopeOne?: Resolver<
    Maybe<ResolversTypes['TagTypeScope']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTagTypeScopeOneArgs, 'object'>
  >;
  insertTagTypes?: Resolver<
    Maybe<ResolversTypes['TagTypesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTagTypesArgs, 'objects'>
  >;
  insertTagTypesOne?: Resolver<
    Maybe<ResolversTypes['TagTypes']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTagTypesOneArgs, 'object'>
  >;
  insertTags?: Resolver<
    Maybe<ResolversTypes['TagsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTagsArgs, 'objects'>
  >;
  insertTagsOne?: Resolver<
    Maybe<ResolversTypes['Tags']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTagsOneArgs, 'object'>
  >;
  insertTaskDefinition?: Resolver<
    Maybe<ResolversTypes['TaskDefinitionMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTaskDefinitionArgs, 'objects'>
  >;
  insertTaskDefinitionOne?: Resolver<
    Maybe<ResolversTypes['TaskDefinition']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTaskDefinitionOneArgs, 'object'>
  >;
  insertTaskDefinitionType?: Resolver<
    Maybe<ResolversTypes['TaskDefinitionTypeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTaskDefinitionTypeArgs, 'objects'>
  >;
  insertTaskDefinitionTypeOne?: Resolver<
    Maybe<ResolversTypes['TaskDefinitionType']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTaskDefinitionTypeOneArgs, 'object'>
  >;
  insertTasks?: Resolver<
    Maybe<ResolversTypes['TasksMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTasksArgs, 'objects'>
  >;
  insertTasksOne?: Resolver<
    Maybe<ResolversTypes['Tasks']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTasksOneArgs, 'object'>
  >;
  insertTeams?: Resolver<
    Maybe<ResolversTypes['TeamsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTeamsArgs, 'objects'>
  >;
  insertTeamsOne?: Resolver<
    Maybe<ResolversTypes['Teams']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTeamsOneArgs, 'object'>
  >;
  insertTeamsUsers?: Resolver<
    Maybe<ResolversTypes['TeamsUsersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTeamsUsersArgs, 'objects'>
  >;
  insertTeamsUsersOne?: Resolver<
    Maybe<ResolversTypes['TeamsUsers']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTeamsUsersOneArgs, 'object'>
  >;
  insertTypeformSubmissions?: Resolver<
    Maybe<ResolversTypes['TypeformSubmissionsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTypeformSubmissionsArgs, 'objects'>
  >;
  insertTypeformSubmissionsOne?: Resolver<
    Maybe<ResolversTypes['TypeformSubmissions']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTypeformSubmissionsOneArgs, 'object'>
  >;
  insertUserSessionEmails?: Resolver<
    Maybe<ResolversTypes['UserSessionEmailsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertUserSessionEmailsArgs, 'objects'>
  >;
  insertUserSessionEmailsOne?: Resolver<
    Maybe<ResolversTypes['UserSessionEmails']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertUserSessionEmailsOneArgs, 'object'>
  >;
  insertUsers?: Resolver<
    Maybe<ResolversTypes['UsersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertUsersArgs, 'objects'>
  >;
  insertUsersFunctions?: Resolver<
    Maybe<ResolversTypes['UsersFunctionsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertUsersFunctionsArgs, 'objects'>
  >;
  insertUsersFunctionsOne?: Resolver<
    Maybe<ResolversTypes['UsersFunctions']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertUsersFunctionsOneArgs, 'object'>
  >;
  insertUsersLengthInFunction?: Resolver<
    Maybe<ResolversTypes['UsersLengthInFunctionMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertUsersLengthInFunctionArgs, 'objects'>
  >;
  insertUsersLengthInFunctionOne?: Resolver<
    Maybe<ResolversTypes['UsersLengthInFunction']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertUsersLengthInFunctionOneArgs, 'object'>
  >;
  insertUsersMeta?: Resolver<
    Maybe<ResolversTypes['UsersMetaMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertUsersMetaArgs, 'objects'>
  >;
  insertUsersMetaOne?: Resolver<
    Maybe<ResolversTypes['UsersMeta']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertUsersMetaOneArgs, 'object'>
  >;
  insertUsersOne?: Resolver<
    Maybe<ResolversTypes['Users']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertUsersOneArgs, 'object'>
  >;
  insertZoomOauthTokens?: Resolver<
    Maybe<ResolversTypes['ZoomOauthTokensMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertZoomOauthTokensArgs, 'objects'>
  >;
  insertZoomOauthTokensOne?: Resolver<
    Maybe<ResolversTypes['ZoomOauthTokens']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertZoomOauthTokensOneArgs, 'object'>
  >;
  publishCourse?: Resolver<
    Array<ResolversTypes['Courses']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootPublishCourseArgs, 'args'>
  >;
  updateActivityType?: Resolver<
    Maybe<ResolversTypes['ActivityTypeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateActivityTypeArgs, 'where'>
  >;
  updateActivityTypeByPk?: Resolver<
    Maybe<ResolversTypes['ActivityType']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateActivityTypeByPkArgs, 'pkColumns'>
  >;
  updateActivityTypeMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ActivityTypeMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateActivityTypeManyArgs, 'updates'>
  >;
  updateAttemptStatus?: Resolver<
    Maybe<ResolversTypes['AttemptStatusMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateAttemptStatusArgs, 'where'>
  >;
  updateAttemptStatusByPk?: Resolver<
    Maybe<ResolversTypes['AttemptStatus']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateAttemptStatusByPkArgs, 'pkColumns'>
  >;
  updateAttemptStatusMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['AttemptStatusMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateAttemptStatusManyArgs, 'updates'>
  >;
  updateCatalogCourses?: Resolver<
    Maybe<ResolversTypes['CatalogCoursesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCatalogCoursesArgs, 'where'>
  >;
  updateCatalogCoursesByPk?: Resolver<
    Maybe<ResolversTypes['CatalogCourses']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCatalogCoursesByPkArgs, 'pkColumns'>
  >;
  updateCatalogCoursesMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CatalogCoursesMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCatalogCoursesManyArgs, 'updates'>
  >;
  updateCatalogs?: Resolver<
    Maybe<ResolversTypes['CatalogsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCatalogsArgs, 'where'>
  >;
  updateCatalogsByPk?: Resolver<
    Maybe<ResolversTypes['Catalogs']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCatalogsByPkArgs, 'pkColumns'>
  >;
  updateCatalogsJourneysRel?: Resolver<
    Maybe<ResolversTypes['CatalogsJourneysRelMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCatalogsJourneysRelArgs, 'where'>
  >;
  updateCatalogsJourneysRelByPk?: Resolver<
    Maybe<ResolversTypes['CatalogsJourneysRel']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCatalogsJourneysRelByPkArgs, 'pkColumns'>
  >;
  updateCatalogsJourneysRelMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CatalogsJourneysRelMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCatalogsJourneysRelManyArgs, 'updates'>
  >;
  updateCatalogsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CatalogsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCatalogsManyArgs, 'updates'>
  >;
  updateClassCoaches?: Resolver<
    Maybe<ResolversTypes['ClassCoachesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassCoachesArgs, 'where'>
  >;
  updateClassCoachesByPk?: Resolver<
    Maybe<ResolversTypes['ClassCoaches']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassCoachesByPkArgs, 'pkColumns'>
  >;
  updateClassCoachesMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ClassCoachesMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassCoachesManyArgs, 'updates'>
  >;
  updateClassEventActivity?: Resolver<
    Maybe<ResolversTypes['ClassEventActivityMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassEventActivityArgs, 'where'>
  >;
  updateClassEventActivityByPk?: Resolver<
    Maybe<ResolversTypes['ClassEventActivity']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassEventActivityByPkArgs, 'pkColumns'>
  >;
  updateClassEventActivityMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ClassEventActivityMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassEventActivityManyArgs, 'updates'>
  >;
  updateClassEventAnonRegistrations?: Resolver<
    Maybe<ResolversTypes['ClassEventAnonRegistrationsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassEventAnonRegistrationsArgs, 'where'>
  >;
  updateClassEventAnonRegistrationsByPk?: Resolver<
    Maybe<ResolversTypes['ClassEventAnonRegistrations']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassEventAnonRegistrationsByPkArgs, 'pkColumns'>
  >;
  updateClassEventAnonRegistrationsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ClassEventAnonRegistrationsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassEventAnonRegistrationsManyArgs, 'updates'>
  >;
  updateClassEventInvitations?: Resolver<
    Maybe<ResolversTypes['ClassEventInvitationsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassEventInvitationsArgs, 'where'>
  >;
  updateClassEventInvitationsByPk?: Resolver<
    Maybe<ResolversTypes['ClassEventInvitations']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassEventInvitationsByPkArgs, 'pkColumns'>
  >;
  updateClassEventInvitationsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ClassEventInvitationsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassEventInvitationsManyArgs, 'updates'>
  >;
  updateClassEventMeetingParticipants?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingParticipantsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassEventMeetingParticipantsArgs, 'where'>
  >;
  updateClassEventMeetingParticipantsByPk?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingParticipants']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassEventMeetingParticipantsByPkArgs, 'pkColumns'>
  >;
  updateClassEventMeetingParticipantsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ClassEventMeetingParticipantsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassEventMeetingParticipantsManyArgs, 'updates'>
  >;
  updateClassEventMeetingSegments?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingSegmentsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassEventMeetingSegmentsArgs, 'where'>
  >;
  updateClassEventMeetingSegmentsByPk?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingSegments']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassEventMeetingSegmentsByPkArgs, 'pkColumns'>
  >;
  updateClassEventMeetingSegmentsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ClassEventMeetingSegmentsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassEventMeetingSegmentsManyArgs, 'updates'>
  >;
  updateClassEventMeetings?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassEventMeetingsArgs, 'where'>
  >;
  updateClassEventMeetingsByPk?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetings']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassEventMeetingsByPkArgs, 'pkColumns'>
  >;
  updateClassEventMeetingsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ClassEventMeetingsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassEventMeetingsManyArgs, 'updates'>
  >;
  updateClassEventRegistrations?: Resolver<
    Maybe<ResolversTypes['ClassEventRegistrationsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassEventRegistrationsArgs, 'where'>
  >;
  updateClassEventRegistrationsByPk?: Resolver<
    Maybe<ResolversTypes['ClassEventRegistrations']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassEventRegistrationsByPkArgs, 'pkColumns'>
  >;
  updateClassEventRegistrationsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ClassEventRegistrationsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassEventRegistrationsManyArgs, 'updates'>
  >;
  updateClassEvents?: Resolver<
    Maybe<ResolversTypes['ClassEventsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassEventsArgs, 'where'>
  >;
  updateClassEventsByPk?: Resolver<
    Maybe<ResolversTypes['ClassEvents']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassEventsByPkArgs, 'pkColumns'>
  >;
  updateClassEventsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ClassEventsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassEventsManyArgs, 'updates'>
  >;
  updateClassPlaybackActivity?: Resolver<
    Maybe<ResolversTypes['ClassPlaybackActivityMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassPlaybackActivityArgs, 'where'>
  >;
  updateClassPlaybackActivityByPk?: Resolver<
    Maybe<ResolversTypes['ClassPlaybackActivity']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassPlaybackActivityByPkArgs, 'pkColumns'>
  >;
  updateClassPlaybackActivityMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ClassPlaybackActivityMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassPlaybackActivityManyArgs, 'updates'>
  >;
  updateClassPlaybackActivityType?: Resolver<
    Maybe<ResolversTypes['ClassPlaybackActivityTypeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassPlaybackActivityTypeArgs, 'where'>
  >;
  updateClassPlaybackActivityTypeByPk?: Resolver<
    Maybe<ResolversTypes['ClassPlaybackActivityType']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassPlaybackActivityTypeByPkArgs, 'pkColumns'>
  >;
  updateClassPlaybackActivityTypeMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ClassPlaybackActivityTypeMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassPlaybackActivityTypeManyArgs, 'updates'>
  >;
  updateClassResources?: Resolver<
    Maybe<ResolversTypes['ClassResourcesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassResourcesArgs, 'where'>
  >;
  updateClassResourcesByPk?: Resolver<
    Maybe<ResolversTypes['ClassResources']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassResourcesByPkArgs, 'pkColumns'>
  >;
  updateClassResourcesMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ClassResourcesMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassResourcesManyArgs, 'updates'>
  >;
  updateClasses?: Resolver<
    Maybe<ResolversTypes['ClassesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassesArgs, 'where'>
  >;
  updateClassesByPk?: Resolver<
    Maybe<ResolversTypes['Classes']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassesByPkArgs, 'pkColumns'>
  >;
  updateClassesMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ClassesMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassesManyArgs, 'updates'>
  >;
  updateClassesTagsRel?: Resolver<
    Maybe<ResolversTypes['ClassesTagsRelMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassesTagsRelArgs, 'where'>
  >;
  updateClassesTagsRelByPk?: Resolver<
    Maybe<ResolversTypes['ClassesTagsRel']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassesTagsRelByPkArgs, 'pkColumns'>
  >;
  updateClassesTagsRelMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ClassesTagsRelMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateClassesTagsRelManyArgs, 'updates'>
  >;
  updateCoaches?: Resolver<
    Maybe<ResolversTypes['CoachesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCoachesArgs, 'where'>
  >;
  updateCoachesByPk?: Resolver<
    Maybe<ResolversTypes['Coaches']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCoachesByPkArgs, 'pkColumns'>
  >;
  updateCoachesMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CoachesMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCoachesManyArgs, 'updates'>
  >;
  updateCohortCoaches?: Resolver<
    Maybe<ResolversTypes['CohortCoachesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCohortCoachesArgs, 'where'>
  >;
  updateCohortCoachesByPk?: Resolver<
    Maybe<ResolversTypes['CohortCoaches']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCohortCoachesByPkArgs, 'pkColumns'>
  >;
  updateCohortCoachesMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CohortCoachesMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCohortCoachesManyArgs, 'updates'>
  >;
  updateCohortType?: Resolver<
    Maybe<ResolversTypes['CohortTypeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCohortTypeArgs, 'where'>
  >;
  updateCohortTypeByPk?: Resolver<
    Maybe<ResolversTypes['CohortType']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCohortTypeByPkArgs, 'pkColumns'>
  >;
  updateCohortTypeMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CohortTypeMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCohortTypeManyArgs, 'updates'>
  >;
  updateCohortVisibilityEnum?: Resolver<
    Maybe<ResolversTypes['CohortVisibilityEnumMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCohortVisibilityEnumArgs, 'where'>
  >;
  updateCohortVisibilityEnumByPk?: Resolver<
    Maybe<ResolversTypes['CohortVisibilityEnum']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCohortVisibilityEnumByPkArgs, 'pkColumns'>
  >;
  updateCohortVisibilityEnumMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CohortVisibilityEnumMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCohortVisibilityEnumManyArgs, 'updates'>
  >;
  updateCohorts?: Resolver<
    Maybe<ResolversTypes['CohortsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCohortsArgs, 'where'>
  >;
  updateCohortsByPk?: Resolver<
    Maybe<ResolversTypes['Cohorts']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCohortsByPkArgs, 'pkColumns'>
  >;
  updateCohortsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CohortsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCohortsManyArgs, 'updates'>
  >;
  updateCohortsResourcesRel?: Resolver<
    Maybe<ResolversTypes['CohortsResourcesRelMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCohortsResourcesRelArgs, 'where'>
  >;
  updateCohortsResourcesRelByPk?: Resolver<
    Maybe<ResolversTypes['CohortsResourcesRel']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCohortsResourcesRelByPkArgs, 'pkColumns'>
  >;
  updateCohortsResourcesRelMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CohortsResourcesRelMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCohortsResourcesRelManyArgs, 'updates'>
  >;
  updateConfigurations?: Resolver<
    Maybe<ResolversTypes['ConfigurationsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateConfigurationsArgs, 'where'>
  >;
  updateConfigurationsByPk?: Resolver<
    Maybe<ResolversTypes['Configurations']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateConfigurationsByPkArgs, 'pkColumns'>
  >;
  updateConfigurationsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ConfigurationsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateConfigurationsManyArgs, 'updates'>
  >;
  updateCourseCertifications?: Resolver<
    Maybe<ResolversTypes['CourseCertificationsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCourseCertificationsArgs, 'where'>
  >;
  updateCourseCertificationsByPk?: Resolver<
    Maybe<ResolversTypes['CourseCertifications']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCourseCertificationsByPkArgs, 'pkColumns'>
  >;
  updateCourseCertificationsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CourseCertificationsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCourseCertificationsManyArgs, 'updates'>
  >;
  updateCourseCoaches?: Resolver<
    Maybe<ResolversTypes['CourseCoachesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCourseCoachesArgs, 'where'>
  >;
  updateCourseCoachesByPk?: Resolver<
    Maybe<ResolversTypes['CourseCoaches']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCourseCoachesByPkArgs, 'pkColumns'>
  >;
  updateCourseCoachesMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CourseCoachesMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCourseCoachesManyArgs, 'updates'>
  >;
  updateCourseEnrollmentStatus?: Resolver<
    Maybe<ResolversTypes['CourseEnrollmentStatusMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCourseEnrollmentStatusArgs, 'where'>
  >;
  updateCourseEnrollmentStatusByPk?: Resolver<
    Maybe<ResolversTypes['CourseEnrollmentStatus']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCourseEnrollmentStatusByPkArgs, 'pkColumns'>
  >;
  updateCourseEnrollmentStatusMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CourseEnrollmentStatusMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCourseEnrollmentStatusManyArgs, 'updates'>
  >;
  updateCoursePublishStatus?: Resolver<
    Maybe<ResolversTypes['CoursePublishStatusMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCoursePublishStatusArgs, 'where'>
  >;
  updateCoursePublishStatusByPk?: Resolver<
    Maybe<ResolversTypes['CoursePublishStatus']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCoursePublishStatusByPkArgs, 'pkColumns'>
  >;
  updateCoursePublishStatusMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CoursePublishStatusMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCoursePublishStatusManyArgs, 'updates'>
  >;
  updateCourseType?: Resolver<
    Maybe<ResolversTypes['CourseTypeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCourseTypeArgs, 'where'>
  >;
  updateCourseTypeByPk?: Resolver<
    Maybe<ResolversTypes['CourseType']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCourseTypeByPkArgs, 'pkColumns'>
  >;
  updateCourseTypeMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CourseTypeMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCourseTypeManyArgs, 'updates'>
  >;
  updateCourseWaitlist?: Resolver<
    Maybe<ResolversTypes['CourseWaitlistMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCourseWaitlistArgs, 'where'>
  >;
  updateCourseWaitlistByPk?: Resolver<
    Maybe<ResolversTypes['CourseWaitlist']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCourseWaitlistByPkArgs, 'pkColumns'>
  >;
  updateCourseWaitlistDesiredUrgencyType?: Resolver<
    Maybe<ResolversTypes['CourseWaitlistDesiredUrgencyTypeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCourseWaitlistDesiredUrgencyTypeArgs, 'where'>
  >;
  updateCourseWaitlistDesiredUrgencyTypeByPk?: Resolver<
    Maybe<ResolversTypes['CourseWaitlistDesiredUrgencyType']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCourseWaitlistDesiredUrgencyTypeByPkArgs, 'pkColumns'>
  >;
  updateCourseWaitlistDesiredUrgencyTypeMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CourseWaitlistDesiredUrgencyTypeMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCourseWaitlistDesiredUrgencyTypeManyArgs, 'updates'>
  >;
  updateCourseWaitlistMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CourseWaitlistMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCourseWaitlistManyArgs, 'updates'>
  >;
  updateCourses?: Resolver<
    Maybe<ResolversTypes['CoursesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCoursesArgs, 'where'>
  >;
  updateCoursesByPk?: Resolver<
    Maybe<ResolversTypes['Courses']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCoursesByPkArgs, 'pkColumns'>
  >;
  updateCoursesMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CoursesMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCoursesManyArgs, 'updates'>
  >;
  updateCoursesResourcesRel?: Resolver<
    Maybe<ResolversTypes['CoursesResourcesRelMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCoursesResourcesRelArgs, 'where'>
  >;
  updateCoursesResourcesRelByPk?: Resolver<
    Maybe<ResolversTypes['CoursesResourcesRel']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCoursesResourcesRelByPkArgs, 'pkColumns'>
  >;
  updateCoursesResourcesRelMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CoursesResourcesRelMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCoursesResourcesRelManyArgs, 'updates'>
  >;
  updateCoursesTagsRel?: Resolver<
    Maybe<ResolversTypes['CoursesTagsRelMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCoursesTagsRelArgs, 'where'>
  >;
  updateCoursesTagsRelByPk?: Resolver<
    Maybe<ResolversTypes['CoursesTagsRel']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCoursesTagsRelByPkArgs, 'pkColumns'>
  >;
  updateCoursesTagsRelMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CoursesTagsRelMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCoursesTagsRelManyArgs, 'updates'>
  >;
  updateEmailQueue?: Resolver<
    Maybe<ResolversTypes['EmailQueueMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateEmailQueueArgs, 'where'>
  >;
  updateEmailQueueByPk?: Resolver<
    Maybe<ResolversTypes['EmailQueue']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateEmailQueueByPkArgs, 'pkColumns'>
  >;
  updateEmailQueueMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['EmailQueueMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateEmailQueueManyArgs, 'updates'>
  >;
  updateEnrollmentWaitlists?: Resolver<
    Maybe<ResolversTypes['EnrollmentWaitlistsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateEnrollmentWaitlistsArgs, 'where'>
  >;
  updateEnrollmentWaitlistsByPk?: Resolver<
    Maybe<ResolversTypes['EnrollmentWaitlists']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateEnrollmentWaitlistsByPkArgs, 'pkColumns'>
  >;
  updateEnrollmentWaitlistsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['EnrollmentWaitlistsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateEnrollmentWaitlistsManyArgs, 'updates'>
  >;
  updateEnrollments?: Resolver<
    Maybe<ResolversTypes['EnrollmentsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateEnrollmentsArgs, 'where'>
  >;
  updateEnrollmentsByPk?: Resolver<
    Maybe<ResolversTypes['Enrollments']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateEnrollmentsByPkArgs, 'pkColumns'>
  >;
  updateEnrollmentsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['EnrollmentsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateEnrollmentsManyArgs, 'updates'>
  >;
  updateFusionauthExternalIdentifiers?: Resolver<
    Maybe<ResolversTypes['FusionauthExternalIdentifiersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateFusionauthExternalIdentifiersArgs, 'where'>
  >;
  updateFusionauthExternalIdentifiersByPk?: Resolver<
    Maybe<ResolversTypes['FusionauthExternalIdentifiers']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateFusionauthExternalIdentifiersByPkArgs, 'pkColumns'>
  >;
  updateFusionauthExternalIdentifiersMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['FusionauthExternalIdentifiersMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateFusionauthExternalIdentifiersManyArgs, 'updates'>
  >;
  updateFusionauthUserRegistrations?: Resolver<
    Maybe<ResolversTypes['FusionauthUserRegistrationsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateFusionauthUserRegistrationsArgs, 'where'>
  >;
  updateFusionauthUserRegistrationsByPk?: Resolver<
    Maybe<ResolversTypes['FusionauthUserRegistrations']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateFusionauthUserRegistrationsByPkArgs, 'pkColumns'>
  >;
  updateFusionauthUserRegistrationsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['FusionauthUserRegistrationsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateFusionauthUserRegistrationsManyArgs, 'updates'>
  >;
  updateIdentities?: Resolver<
    Maybe<ResolversTypes['IdentitiesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateIdentitiesArgs, 'where'>
  >;
  updateIdentitiesByPk?: Resolver<
    Maybe<ResolversTypes['Identities']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateIdentitiesByPkArgs, 'pkColumns'>
  >;
  updateIdentitiesMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['IdentitiesMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateIdentitiesManyArgs, 'updates'>
  >;
  updateIdentityProviderLinks?: Resolver<
    Maybe<ResolversTypes['IdentityProviderLinksMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateIdentityProviderLinksArgs, 'where'>
  >;
  updateIdentityProviderLinksMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['IdentityProviderLinksMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateIdentityProviderLinksManyArgs, 'updates'>
  >;
  updateIdentityProviders?: Resolver<
    Maybe<ResolversTypes['IdentityProvidersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateIdentityProvidersArgs, 'where'>
  >;
  updateIdentityProvidersByPk?: Resolver<
    Maybe<ResolversTypes['IdentityProviders']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateIdentityProvidersByPkArgs, 'pkColumns'>
  >;
  updateIdentityProvidersMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['IdentityProvidersMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateIdentityProvidersManyArgs, 'updates'>
  >;
  updateImpactAssessmentAttempts?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentAttemptsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateImpactAssessmentAttemptsArgs, 'where'>
  >;
  updateImpactAssessmentAttemptsByPk?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentAttempts']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateImpactAssessmentAttemptsByPkArgs, 'pkColumns'>
  >;
  updateImpactAssessmentAttemptsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ImpactAssessmentAttemptsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateImpactAssessmentAttemptsManyArgs, 'updates'>
  >;
  updateImpactAssessmentQuestions?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentQuestionsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateImpactAssessmentQuestionsArgs, 'where'>
  >;
  updateImpactAssessmentQuestionsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ImpactAssessmentQuestionsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateImpactAssessmentQuestionsManyArgs, 'updates'>
  >;
  updateImpactAssessments?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateImpactAssessmentsArgs, 'where'>
  >;
  updateImpactAssessmentsByPk?: Resolver<
    Maybe<ResolversTypes['ImpactAssessments']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateImpactAssessmentsByPkArgs, 'pkColumns'>
  >;
  updateImpactAssessmentsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ImpactAssessmentsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateImpactAssessmentsManyArgs, 'updates'>
  >;
  updateJourneyEnrollments?: Resolver<
    Maybe<ResolversTypes['JourneyEnrollmentsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateJourneyEnrollmentsArgs, 'where'>
  >;
  updateJourneyEnrollmentsByPk?: Resolver<
    Maybe<ResolversTypes['JourneyEnrollments']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateJourneyEnrollmentsByPkArgs, 'pkColumns'>
  >;
  updateJourneyEnrollmentsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['JourneyEnrollmentsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateJourneyEnrollmentsManyArgs, 'updates'>
  >;
  updateJourneys?: Resolver<
    Maybe<ResolversTypes['JourneysMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateJourneysArgs, 'where'>
  >;
  updateJourneysByPk?: Resolver<
    Maybe<ResolversTypes['Journeys']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateJourneysByPkArgs, 'pkColumns'>
  >;
  updateJourneysMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['JourneysMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateJourneysManyArgs, 'updates'>
  >;
  updateLedger?: Resolver<
    Maybe<ResolversTypes['LedgerMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateLedgerArgs, 'where'>
  >;
  updateLedgerByPk?: Resolver<
    Maybe<ResolversTypes['Ledger']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateLedgerByPkArgs, 'pkColumns'>
  >;
  updateLedgerMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['LedgerMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateLedgerManyArgs, 'updates'>
  >;
  updateLedgerProductEnum?: Resolver<
    Maybe<ResolversTypes['LedgerProductEnumMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateLedgerProductEnumArgs, 'where'>
  >;
  updateLedgerProductEnumByPk?: Resolver<
    Maybe<ResolversTypes['LedgerProductEnum']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateLedgerProductEnumByPkArgs, 'pkColumns'>
  >;
  updateLedgerProductEnumMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['LedgerProductEnumMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateLedgerProductEnumManyArgs, 'updates'>
  >;
  updateLedgerTransactionTypeEnum?: Resolver<
    Maybe<ResolversTypes['LedgerTransactionTypeEnumMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateLedgerTransactionTypeEnumArgs, 'where'>
  >;
  updateLedgerTransactionTypeEnumByPk?: Resolver<
    Maybe<ResolversTypes['LedgerTransactionTypeEnum']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateLedgerTransactionTypeEnumByPkArgs, 'pkColumns'>
  >;
  updateLedgerTransactionTypeEnumMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['LedgerTransactionTypeEnumMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateLedgerTransactionTypeEnumManyArgs, 'updates'>
  >;
  updateLightningSession?: Resolver<
    Maybe<ResolversTypes['LightningSessionMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateLightningSessionArgs, 'where'>
  >;
  updateLightningSessionByPk?: Resolver<
    Maybe<ResolversTypes['LightningSession']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateLightningSessionByPkArgs, 'pkColumns'>
  >;
  updateLightningSessionMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['LightningSessionMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateLightningSessionManyArgs, 'updates'>
  >;
  updateLightningSessionSubmissions?: Resolver<
    Maybe<ResolversTypes['LightningSessionSubmissionsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateLightningSessionSubmissionsArgs, 'where'>
  >;
  updateLightningSessionSubmissionsByPk?: Resolver<
    Maybe<ResolversTypes['LightningSessionSubmissions']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateLightningSessionSubmissionsByPkArgs, 'pkColumns'>
  >;
  updateLightningSessionSubmissionsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['LightningSessionSubmissionsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateLightningSessionSubmissionsManyArgs, 'updates'>
  >;
  updateLightningSessionSubmissionsStatus?: Resolver<
    Maybe<ResolversTypes['lightningSessionSubmissionsStatusMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateLightningSessionSubmissionsStatusArgs, 'where'>
  >;
  updateLightningSessionSubmissionsStatusByPk?: Resolver<
    Maybe<ResolversTypes['lightningSessionSubmissionsStatus']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateLightningSessionSubmissionsStatusByPkArgs, 'pkColumns'>
  >;
  updateLightningSessionSubmissionsStatusMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['lightningSessionSubmissionsStatusMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateLightningSessionSubmissionsStatusManyArgs, 'updates'>
  >;
  updateLightningSessionSubmissionsUsersRel?: Resolver<
    Maybe<ResolversTypes['LightningSessionSubmissionsUsersRelMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateLightningSessionSubmissionsUsersRelArgs, 'where'>
  >;
  updateLightningSessionSubmissionsUsersRelByPk?: Resolver<
    Maybe<ResolversTypes['LightningSessionSubmissionsUsersRel']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateLightningSessionSubmissionsUsersRelByPkArgs, 'pkColumns'>
  >;
  updateLightningSessionSubmissionsUsersRelMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['LightningSessionSubmissionsUsersRelMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateLightningSessionSubmissionsUsersRelManyArgs, 'updates'>
  >;
  updateManagerSessionEmails?: Resolver<
    Maybe<ResolversTypes['ManagerSessionEmailsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateManagerSessionEmailsArgs, 'where'>
  >;
  updateManagerSessionEmailsByPk?: Resolver<
    Maybe<ResolversTypes['ManagerSessionEmails']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateManagerSessionEmailsByPkArgs, 'pkColumns'>
  >;
  updateManagerSessionEmailsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ManagerSessionEmailsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateManagerSessionEmailsManyArgs, 'updates'>
  >;
  updateManyPlayExecution?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['PlayExecutionMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateManyPlayExecutionArgs, 'updates'>
  >;
  updateNonceAccessToken?: Resolver<
    Maybe<ResolversTypes['NonceAccessTokenMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateNonceAccessTokenArgs, 'where'>
  >;
  updateNonceAccessTokenByPk?: Resolver<
    Maybe<ResolversTypes['NonceAccessToken']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateNonceAccessTokenByPkArgs, 'pkColumns'>
  >;
  updateNonceAccessTokenMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['NonceAccessTokenMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateNonceAccessTokenManyArgs, 'updates'>
  >;
  updateOrganizationUsers?: Resolver<
    Maybe<ResolversTypes['OrganizationUsersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateOrganizationUsersArgs, 'where'>
  >;
  updateOrganizationUsersByPk?: Resolver<
    Maybe<ResolversTypes['OrganizationUsers']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateOrganizationUsersByPkArgs, 'pkColumns'>
  >;
  updateOrganizationUsersMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['OrganizationUsersMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateOrganizationUsersManyArgs, 'updates'>
  >;
  updateOrganizations?: Resolver<
    Maybe<ResolversTypes['OrganizationsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateOrganizationsArgs, 'where'>
  >;
  updateOrganizationsByPk?: Resolver<
    Maybe<ResolversTypes['Organizations']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateOrganizationsByPkArgs, 'pkColumns'>
  >;
  updateOrganizationsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['OrganizationsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateOrganizationsManyArgs, 'updates'>
  >;
  updateOrganizationsMeta?: Resolver<
    Maybe<ResolversTypes['OrganizationsMetaMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateOrganizationsMetaArgs, 'where'>
  >;
  updateOrganizationsMetaByPk?: Resolver<
    Maybe<ResolversTypes['OrganizationsMeta']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateOrganizationsMetaByPkArgs, 'pkColumns'>
  >;
  updateOrganizationsMetaMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['OrganizationsMetaMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateOrganizationsMetaManyArgs, 'updates'>
  >;
  updatePlanSubscriptions?: Resolver<
    Maybe<ResolversTypes['PlanSubscriptionsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePlanSubscriptionsArgs, 'where'>
  >;
  updatePlanSubscriptionsByPk?: Resolver<
    Maybe<ResolversTypes['PlanSubscriptions']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePlanSubscriptionsByPkArgs, 'pkColumns'>
  >;
  updatePlanSubscriptionsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['PlanSubscriptionsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePlanSubscriptionsManyArgs, 'updates'>
  >;
  updatePlans?: Resolver<
    Maybe<ResolversTypes['PlansMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePlansArgs, 'where'>
  >;
  updatePlansByPk?: Resolver<
    Maybe<ResolversTypes['Plans']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePlansByPkArgs, 'pkColumns'>
  >;
  updatePlansMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['PlansMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePlansManyArgs, 'updates'>
  >;
  updatePlayExecution?: Resolver<
    Maybe<ResolversTypes['PlayExecutionMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePlayExecutionArgs, 'where'>
  >;
  updatePlayExecutionByPk?: Resolver<
    Maybe<ResolversTypes['PlayExecution']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePlayExecutionByPkArgs, 'pkColumns'>
  >;
  updateProgramActionsTaken?: Resolver<
    Maybe<ResolversTypes['ProgramActionsTakenMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateProgramActionsTakenArgs, 'where'>
  >;
  updateProgramActionsTakenMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ProgramActionsTakenMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateProgramActionsTakenManyArgs, 'updates'>
  >;
  updateProgramCoursesShown?: Resolver<
    Maybe<ResolversTypes['ProgramCoursesShownMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateProgramCoursesShownArgs, 'where'>
  >;
  updateProgramCoursesShownByPk?: Resolver<
    Maybe<ResolversTypes['ProgramCoursesShown']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateProgramCoursesShownByPkArgs, 'pkColumns'>
  >;
  updateProgramCoursesShownMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ProgramCoursesShownMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateProgramCoursesShownManyArgs, 'updates'>
  >;
  updateProgramTags?: Resolver<
    Maybe<ResolversTypes['ProgramTagsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateProgramTagsArgs, 'where'>
  >;
  updateProgramTagsByPk?: Resolver<
    Maybe<ResolversTypes['ProgramTags']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateProgramTagsByPkArgs, 'pkColumns'>
  >;
  updateProgramTagsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ProgramTagsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateProgramTagsManyArgs, 'updates'>
  >;
  updateProgramUsersSelected?: Resolver<
    Maybe<ResolversTypes['ProgramUsersSelectedMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateProgramUsersSelectedArgs, 'where'>
  >;
  updateProgramUsersSelectedByPk?: Resolver<
    Maybe<ResolversTypes['ProgramUsersSelected']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateProgramUsersSelectedByPkArgs, 'pkColumns'>
  >;
  updateProgramUsersSelectedMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ProgramUsersSelectedMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateProgramUsersSelectedManyArgs, 'updates'>
  >;
  updatePrograms?: Resolver<
    Maybe<ResolversTypes['ProgramsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateProgramsArgs, 'where'>
  >;
  updateProgramsByPk?: Resolver<
    Maybe<ResolversTypes['Programs']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateProgramsByPkArgs, 'pkColumns'>
  >;
  updateProgramsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ProgramsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateProgramsManyArgs, 'updates'>
  >;
  updateQuestionType?: Resolver<
    Maybe<ResolversTypes['QuestionTypeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateQuestionTypeArgs, 'where'>
  >;
  updateQuestionTypeByPk?: Resolver<
    Maybe<ResolversTypes['QuestionType']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateQuestionTypeByPkArgs, 'pkColumns'>
  >;
  updateQuestionTypeMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['QuestionTypeMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateQuestionTypeManyArgs, 'updates'>
  >;
  updateRecordingActivity?: Resolver<
    Maybe<ResolversTypes['RecordingActivityMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateRecordingActivityArgs, 'where'>
  >;
  updateRecordingActivityByPk?: Resolver<
    Maybe<ResolversTypes['RecordingActivity']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateRecordingActivityByPkArgs, 'pkColumns'>
  >;
  updateRecordingActivityMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RecordingActivityMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateRecordingActivityManyArgs, 'updates'>
  >;
  updateRecordings?: Resolver<
    Maybe<ResolversTypes['RecordingsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateRecordingsArgs, 'where'>
  >;
  updateRecordingsByPk?: Resolver<
    Maybe<ResolversTypes['Recordings']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateRecordingsByPkArgs, 'pkColumns'>
  >;
  updateRecordingsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RecordingsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateRecordingsManyArgs, 'updates'>
  >;
  updateRelatedCourses?: Resolver<
    Maybe<ResolversTypes['RelatedCoursesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateRelatedCoursesArgs, 'where'>
  >;
  updateRelatedCoursesByPk?: Resolver<
    Maybe<ResolversTypes['RelatedCourses']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateRelatedCoursesByPkArgs, 'pkColumns'>
  >;
  updateRelatedCoursesMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['RelatedCoursesMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateRelatedCoursesManyArgs, 'updates'>
  >;
  updateReportPublishStatus?: Resolver<
    Maybe<ResolversTypes['ReportPublishStatusMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateReportPublishStatusArgs, 'where'>
  >;
  updateReportPublishStatusByPk?: Resolver<
    Maybe<ResolversTypes['ReportPublishStatus']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateReportPublishStatusByPkArgs, 'pkColumns'>
  >;
  updateReportPublishStatusMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ReportPublishStatusMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateReportPublishStatusManyArgs, 'updates'>
  >;
  updateReportType?: Resolver<
    Maybe<ResolversTypes['ReportTypeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateReportTypeArgs, 'where'>
  >;
  updateReportTypeByPk?: Resolver<
    Maybe<ResolversTypes['ReportType']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateReportTypeByPkArgs, 'pkColumns'>
  >;
  updateReportTypeMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ReportTypeMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateReportTypeManyArgs, 'updates'>
  >;
  updateReports?: Resolver<
    Maybe<ResolversTypes['ReportsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateReportsArgs, 'where'>
  >;
  updateReportsByPk?: Resolver<
    Maybe<ResolversTypes['Reports']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateReportsByPkArgs, 'pkColumns'>
  >;
  updateReportsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ReportsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateReportsManyArgs, 'updates'>
  >;
  updateResourceType?: Resolver<
    Maybe<ResolversTypes['ResourceTypeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateResourceTypeArgs, 'where'>
  >;
  updateResourceTypeByPk?: Resolver<
    Maybe<ResolversTypes['ResourceType']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateResourceTypeByPkArgs, 'pkColumns'>
  >;
  updateResourceTypeMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ResourceTypeMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateResourceTypeManyArgs, 'updates'>
  >;
  updateResources?: Resolver<
    Maybe<ResolversTypes['ResourcesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateResourcesArgs, 'where'>
  >;
  updateResourcesByPk?: Resolver<
    Maybe<ResolversTypes['Resources']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateResourcesByPkArgs, 'pkColumns'>
  >;
  updateResourcesMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ResourcesMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateResourcesManyArgs, 'updates'>
  >;
  updateSchools?: Resolver<
    Maybe<ResolversTypes['SchoolsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateSchoolsArgs, 'where'>
  >;
  updateSchoolsByPk?: Resolver<
    Maybe<ResolversTypes['Schools']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateSchoolsByPkArgs, 'pkColumns'>
  >;
  updateSchoolsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['SchoolsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateSchoolsManyArgs, 'updates'>
  >;
  updateSeatType?: Resolver<
    Maybe<ResolversTypes['SeatTypeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateSeatTypeArgs, 'where'>
  >;
  updateSeatTypeByPk?: Resolver<
    Maybe<ResolversTypes['SeatType']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateSeatTypeByPkArgs, 'pkColumns'>
  >;
  updateSeatTypeMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['SeatTypeMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateSeatTypeManyArgs, 'updates'>
  >;
  updateSeats?: Resolver<
    Maybe<ResolversTypes['SeatsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateSeatsArgs, 'where'>
  >;
  updateSeatsByPk?: Resolver<
    Maybe<ResolversTypes['Seats']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateSeatsByPkArgs, 'pkColumns'>
  >;
  updateSeatsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['SeatsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateSeatsManyArgs, 'updates'>
  >;
  updateStreamMessages?: Resolver<
    Maybe<ResolversTypes['StreamMessagesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateStreamMessagesArgs, 'where'>
  >;
  updateStreamMessagesByPk?: Resolver<
    Maybe<ResolversTypes['StreamMessages']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateStreamMessagesByPkArgs, 'pkColumns'>
  >;
  updateStreamMessagesMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['StreamMessagesMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateStreamMessagesManyArgs, 'updates'>
  >;
  updateTagTypeScope?: Resolver<
    Maybe<ResolversTypes['TagTypeScopeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTagTypeScopeArgs, 'where'>
  >;
  updateTagTypeScopeByPk?: Resolver<
    Maybe<ResolversTypes['TagTypeScope']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTagTypeScopeByPkArgs, 'pkColumns'>
  >;
  updateTagTypeScopeMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TagTypeScopeMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTagTypeScopeManyArgs, 'updates'>
  >;
  updateTagTypes?: Resolver<
    Maybe<ResolversTypes['TagTypesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTagTypesArgs, 'where'>
  >;
  updateTagTypesByPk?: Resolver<
    Maybe<ResolversTypes['TagTypes']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTagTypesByPkArgs, 'pkColumns'>
  >;
  updateTagTypesMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TagTypesMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTagTypesManyArgs, 'updates'>
  >;
  updateTags?: Resolver<
    Maybe<ResolversTypes['TagsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTagsArgs, 'where'>
  >;
  updateTagsByPk?: Resolver<
    Maybe<ResolversTypes['Tags']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTagsByPkArgs, 'pkColumns'>
  >;
  updateTagsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TagsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTagsManyArgs, 'updates'>
  >;
  updateTaskDefinition?: Resolver<
    Maybe<ResolversTypes['TaskDefinitionMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTaskDefinitionArgs, 'where'>
  >;
  updateTaskDefinitionByPk?: Resolver<
    Maybe<ResolversTypes['TaskDefinition']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTaskDefinitionByPkArgs, 'pkColumns'>
  >;
  updateTaskDefinitionMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TaskDefinitionMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTaskDefinitionManyArgs, 'updates'>
  >;
  updateTaskDefinitionType?: Resolver<
    Maybe<ResolversTypes['TaskDefinitionTypeMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTaskDefinitionTypeArgs, 'where'>
  >;
  updateTaskDefinitionTypeByPk?: Resolver<
    Maybe<ResolversTypes['TaskDefinitionType']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTaskDefinitionTypeByPkArgs, 'pkColumns'>
  >;
  updateTaskDefinitionTypeMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TaskDefinitionTypeMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTaskDefinitionTypeManyArgs, 'updates'>
  >;
  updateTasks?: Resolver<
    Maybe<ResolversTypes['TasksMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTasksArgs, 'where'>
  >;
  updateTasksByPk?: Resolver<
    Maybe<ResolversTypes['Tasks']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTasksByPkArgs, 'pkColumns'>
  >;
  updateTasksMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TasksMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTasksManyArgs, 'updates'>
  >;
  updateTeams?: Resolver<
    Maybe<ResolversTypes['TeamsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTeamsArgs, 'where'>
  >;
  updateTeamsByPk?: Resolver<
    Maybe<ResolversTypes['Teams']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTeamsByPkArgs, 'pkColumns'>
  >;
  updateTeamsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TeamsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTeamsManyArgs, 'updates'>
  >;
  updateTeamsUsers?: Resolver<
    Maybe<ResolversTypes['TeamsUsersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTeamsUsersArgs, 'where'>
  >;
  updateTeamsUsersByPk?: Resolver<
    Maybe<ResolversTypes['TeamsUsers']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTeamsUsersByPkArgs, 'pkColumns'>
  >;
  updateTeamsUsersMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TeamsUsersMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTeamsUsersManyArgs, 'updates'>
  >;
  updateTypeformSubmissions?: Resolver<
    Maybe<ResolversTypes['TypeformSubmissionsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTypeformSubmissionsArgs, 'where'>
  >;
  updateTypeformSubmissionsByPk?: Resolver<
    Maybe<ResolversTypes['TypeformSubmissions']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTypeformSubmissionsByPkArgs, 'pkColumns'>
  >;
  updateTypeformSubmissionsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TypeformSubmissionsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTypeformSubmissionsManyArgs, 'updates'>
  >;
  updateUserSessionEmails?: Resolver<
    Maybe<ResolversTypes['UserSessionEmailsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateUserSessionEmailsArgs, 'where'>
  >;
  updateUserSessionEmailsByPk?: Resolver<
    Maybe<ResolversTypes['UserSessionEmails']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateUserSessionEmailsByPkArgs, 'pkColumns'>
  >;
  updateUserSessionEmailsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['UserSessionEmailsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateUserSessionEmailsManyArgs, 'updates'>
  >;
  updateUsers?: Resolver<
    Maybe<ResolversTypes['UsersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateUsersArgs, 'where'>
  >;
  updateUsersByPk?: Resolver<
    Maybe<ResolversTypes['Users']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateUsersByPkArgs, 'pkColumns'>
  >;
  updateUsersFunctions?: Resolver<
    Maybe<ResolversTypes['UsersFunctionsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateUsersFunctionsArgs, 'where'>
  >;
  updateUsersFunctionsByPk?: Resolver<
    Maybe<ResolversTypes['UsersFunctions']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateUsersFunctionsByPkArgs, 'pkColumns'>
  >;
  updateUsersFunctionsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['UsersFunctionsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateUsersFunctionsManyArgs, 'updates'>
  >;
  updateUsersLengthInFunction?: Resolver<
    Maybe<ResolversTypes['UsersLengthInFunctionMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateUsersLengthInFunctionArgs, 'where'>
  >;
  updateUsersLengthInFunctionByPk?: Resolver<
    Maybe<ResolversTypes['UsersLengthInFunction']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateUsersLengthInFunctionByPkArgs, 'pkColumns'>
  >;
  updateUsersLengthInFunctionMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['UsersLengthInFunctionMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateUsersLengthInFunctionManyArgs, 'updates'>
  >;
  updateUsersMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['UsersMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateUsersManyArgs, 'updates'>
  >;
  updateUsersMeta?: Resolver<
    Maybe<ResolversTypes['UsersMetaMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateUsersMetaArgs, 'where'>
  >;
  updateUsersMetaByPk?: Resolver<
    Maybe<ResolversTypes['UsersMeta']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateUsersMetaByPkArgs, 'pkColumns'>
  >;
  updateUsersMetaMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['UsersMetaMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateUsersMetaManyArgs, 'updates'>
  >;
  updateZoomOauthTokens?: Resolver<
    Maybe<ResolversTypes['ZoomOauthTokensMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateZoomOauthTokensArgs, 'where'>
  >;
  updateZoomOauthTokensByPk?: Resolver<
    Maybe<ResolversTypes['ZoomOauthTokens']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateZoomOauthTokensByPkArgs, 'pkColumns'>
  >;
  updateZoomOauthTokensMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ZoomOauthTokensMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateZoomOauthTokensManyArgs, 'updates'>
  >;
  versionImpactAssessment?: Resolver<
    Array<ResolversTypes['ImpactAssessments']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootVersionImpactAssessmentArgs, 'args'>
  >;
};

export interface NumericScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['numeric'], any> {
  name: 'numeric';
}

export type Query_RootResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['query_root'] = ResolversParentTypes['query_root']
> = {
  CheckMyTeamCourseWaitlist?: Resolver<
    Maybe<ResolversTypes['CheckMyTeamCourseWaitlistOutput']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCheckMyTeamCourseWaitlistArgs, 'courseSlug'>
  >;
  CountTeamSessions?: Resolver<
    Maybe<ResolversTypes['CountTeamSessionsOutput']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCountTeamSessionsArgs, 'courseSlug'>
  >;
  GetMySessionsAttendedCount?: Resolver<
    Maybe<ResolversTypes['GetMySessionsAttendedCountOutput']>,
    ParentType,
    ContextType
  >;
  GetPastSessionAsyncMetrics?: Resolver<
    Maybe<ResolversTypes['GetPastSessionAsyncMetricsOutput']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootGetPastSessionAsyncMetricsArgs, 'cohortId'>
  >;
  GetPastSessionsAsyncAverageMetrics?: Resolver<
    Maybe<ResolversTypes['GetPastSessionAsyncAverageMetricsOutput']>,
    ParentType,
    ContextType
  >;
  MyTeamMembers?: Resolver<
    Maybe<ResolversTypes['MyTeamMembersOutput']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootMyTeamMembersArgs, never>
  >;
  activityType?: Resolver<
    Array<ResolversTypes['ActivityType']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootActivityTypeArgs, never>
  >;
  activityTypeAggregate?: Resolver<
    ResolversTypes['ActivityTypeAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootActivityTypeAggregateArgs, never>
  >;
  activityTypeByPk?: Resolver<
    Maybe<ResolversTypes['ActivityType']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootActivityTypeByPkArgs, 'value'>
  >;
  attemptStatus?: Resolver<
    Array<ResolversTypes['AttemptStatus']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootAttemptStatusArgs, never>
  >;
  attemptStatusAggregate?: Resolver<
    ResolversTypes['AttemptStatusAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootAttemptStatusAggregateArgs, never>
  >;
  attemptStatusByPk?: Resolver<
    Maybe<ResolversTypes['AttemptStatus']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootAttemptStatusByPkArgs, 'value'>
  >;
  catalogCourses?: Resolver<
    Array<ResolversTypes['CatalogCourses']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCatalogCoursesArgs, never>
  >;
  catalogCoursesAggregate?: Resolver<
    ResolversTypes['CatalogCoursesAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootCatalogCoursesAggregateArgs, never>
  >;
  catalogCoursesByPk?: Resolver<
    Maybe<ResolversTypes['CatalogCourses']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCatalogCoursesByPkArgs, 'catalogId' | 'courseId'>
  >;
  catalogs?: Resolver<
    Array<ResolversTypes['Catalogs']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCatalogsArgs, never>
  >;
  catalogsAggregate?: Resolver<
    ResolversTypes['CatalogsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootCatalogsAggregateArgs, never>
  >;
  catalogsByPk?: Resolver<
    Maybe<ResolversTypes['Catalogs']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCatalogsByPkArgs, 'id'>
  >;
  catalogsJourneysRel?: Resolver<
    Array<ResolversTypes['CatalogsJourneysRel']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCatalogsJourneysRelArgs, never>
  >;
  catalogsJourneysRelAggregate?: Resolver<
    ResolversTypes['CatalogsJourneysRelAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootCatalogsJourneysRelAggregateArgs, never>
  >;
  catalogsJourneysRelByPk?: Resolver<
    Maybe<ResolversTypes['CatalogsJourneysRel']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCatalogsJourneysRelByPkArgs, 'catalogId' | 'journeyId'>
  >;
  classCoaches?: Resolver<
    Array<ResolversTypes['ClassCoaches']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootClassCoachesArgs, never>
  >;
  classCoachesAggregate?: Resolver<
    ResolversTypes['ClassCoachesAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootClassCoachesAggregateArgs, never>
  >;
  classCoachesByPk?: Resolver<
    Maybe<ResolversTypes['ClassCoaches']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootClassCoachesByPkArgs, 'classId' | 'coachId'>
  >;
  classEventActivity?: Resolver<
    Array<ResolversTypes['ClassEventActivity']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootClassEventActivityArgs, never>
  >;
  classEventActivityAggregate?: Resolver<
    ResolversTypes['ClassEventActivityAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootClassEventActivityAggregateArgs, never>
  >;
  classEventActivityByPk?: Resolver<
    Maybe<ResolversTypes['ClassEventActivity']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootClassEventActivityByPkArgs, 'id'>
  >;
  classEventAnonRegistrations?: Resolver<
    Array<ResolversTypes['ClassEventAnonRegistrations']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootClassEventAnonRegistrationsArgs, never>
  >;
  classEventAnonRegistrationsAggregate?: Resolver<
    ResolversTypes['ClassEventAnonRegistrationsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootClassEventAnonRegistrationsAggregateArgs, never>
  >;
  classEventAnonRegistrationsByPk?: Resolver<
    Maybe<ResolversTypes['ClassEventAnonRegistrations']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootClassEventAnonRegistrationsByPkArgs, 'id'>
  >;
  classEventInvitations?: Resolver<
    Array<ResolversTypes['ClassEventInvitations']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootClassEventInvitationsArgs, never>
  >;
  classEventInvitationsAggregate?: Resolver<
    ResolversTypes['ClassEventInvitationsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootClassEventInvitationsAggregateArgs, never>
  >;
  classEventInvitationsByPk?: Resolver<
    Maybe<ResolversTypes['ClassEventInvitations']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootClassEventInvitationsByPkArgs, 'id'>
  >;
  classEventMeetingParticipants?: Resolver<
    Array<ResolversTypes['ClassEventMeetingParticipants']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootClassEventMeetingParticipantsArgs, never>
  >;
  classEventMeetingParticipantsAggregate?: Resolver<
    ResolversTypes['ClassEventMeetingParticipantsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootClassEventMeetingParticipantsAggregateArgs, never>
  >;
  classEventMeetingParticipantsByPk?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingParticipants']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootClassEventMeetingParticipantsByPkArgs, 'participantId'>
  >;
  classEventMeetingSegments?: Resolver<
    Array<ResolversTypes['ClassEventMeetingSegments']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootClassEventMeetingSegmentsArgs, never>
  >;
  classEventMeetingSegmentsAggregate?: Resolver<
    ResolversTypes['ClassEventMeetingSegmentsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootClassEventMeetingSegmentsAggregateArgs, never>
  >;
  classEventMeetingSegmentsByPk?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetingSegments']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootClassEventMeetingSegmentsByPkArgs, 'id'>
  >;
  classEventMeetings?: Resolver<
    Array<ResolversTypes['ClassEventMeetings']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootClassEventMeetingsArgs, never>
  >;
  classEventMeetingsAggregate?: Resolver<
    ResolversTypes['ClassEventMeetingsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootClassEventMeetingsAggregateArgs, never>
  >;
  classEventMeetingsByPk?: Resolver<
    Maybe<ResolversTypes['ClassEventMeetings']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootClassEventMeetingsByPkArgs, 'id'>
  >;
  classEventRegistrations?: Resolver<
    Array<ResolversTypes['ClassEventRegistrations']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootClassEventRegistrationsArgs, never>
  >;
  classEventRegistrationsAggregate?: Resolver<
    ResolversTypes['ClassEventRegistrationsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootClassEventRegistrationsAggregateArgs, never>
  >;
  classEventRegistrationsByPk?: Resolver<
    Maybe<ResolversTypes['ClassEventRegistrations']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootClassEventRegistrationsByPkArgs, 'id'>
  >;
  classEvents?: Resolver<
    Array<ResolversTypes['ClassEvents']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootClassEventsArgs, never>
  >;
  classEventsAggregate?: Resolver<
    ResolversTypes['ClassEventsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootClassEventsAggregateArgs, never>
  >;
  classEventsByPk?: Resolver<
    Maybe<ResolversTypes['ClassEvents']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootClassEventsByPkArgs, 'id'>
  >;
  classPlaybackActivity?: Resolver<
    Array<ResolversTypes['ClassPlaybackActivity']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootClassPlaybackActivityArgs, never>
  >;
  classPlaybackActivityAggregate?: Resolver<
    ResolversTypes['ClassPlaybackActivityAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootClassPlaybackActivityAggregateArgs, never>
  >;
  classPlaybackActivityByPk?: Resolver<
    Maybe<ResolversTypes['ClassPlaybackActivity']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootClassPlaybackActivityByPkArgs, 'id'>
  >;
  classPlaybackActivityType?: Resolver<
    Array<ResolversTypes['ClassPlaybackActivityType']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootClassPlaybackActivityTypeArgs, never>
  >;
  classPlaybackActivityTypeAggregate?: Resolver<
    ResolversTypes['ClassPlaybackActivityTypeAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootClassPlaybackActivityTypeAggregateArgs, never>
  >;
  classPlaybackActivityTypeByPk?: Resolver<
    Maybe<ResolversTypes['ClassPlaybackActivityType']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootClassPlaybackActivityTypeByPkArgs, 'value'>
  >;
  classResources?: Resolver<
    Array<ResolversTypes['ClassResources']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootClassResourcesArgs, never>
  >;
  classResourcesAggregate?: Resolver<
    ResolversTypes['ClassResourcesAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootClassResourcesAggregateArgs, never>
  >;
  classResourcesByPk?: Resolver<
    Maybe<ResolversTypes['ClassResources']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootClassResourcesByPkArgs, 'classId' | 'resourceId'>
  >;
  classes?: Resolver<
    Array<ResolversTypes['Classes']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootClassesArgs, never>
  >;
  classesAggregate?: Resolver<
    ResolversTypes['ClassesAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootClassesAggregateArgs, never>
  >;
  classesByPk?: Resolver<
    Maybe<ResolversTypes['Classes']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootClassesByPkArgs, 'id'>
  >;
  classesTagsRel?: Resolver<
    Array<ResolversTypes['ClassesTagsRel']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootClassesTagsRelArgs, never>
  >;
  classesTagsRelAggregate?: Resolver<
    ResolversTypes['ClassesTagsRelAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootClassesTagsRelAggregateArgs, never>
  >;
  classesTagsRelByPk?: Resolver<
    Maybe<ResolversTypes['ClassesTagsRel']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootClassesTagsRelByPkArgs, 'classId' | 'tagId'>
  >;
  coaches?: Resolver<
    Array<ResolversTypes['Coaches']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCoachesArgs, never>
  >;
  coachesAggregate?: Resolver<
    ResolversTypes['CoachesAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootCoachesAggregateArgs, never>
  >;
  coachesByPk?: Resolver<
    Maybe<ResolversTypes['Coaches']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCoachesByPkArgs, 'id'>
  >;
  cohortCoaches?: Resolver<
    Array<ResolversTypes['CohortCoaches']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCohortCoachesArgs, never>
  >;
  cohortCoachesAggregate?: Resolver<
    ResolversTypes['CohortCoachesAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootCohortCoachesAggregateArgs, never>
  >;
  cohortCoachesByPk?: Resolver<
    Maybe<ResolversTypes['CohortCoaches']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCohortCoachesByPkArgs, 'coachId' | 'cohortId'>
  >;
  cohortProgress?: Resolver<
    Array<ResolversTypes['CohortProgress']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCohortProgressArgs, never>
  >;
  cohortProgressAggregate?: Resolver<
    ResolversTypes['CohortProgressAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootCohortProgressAggregateArgs, never>
  >;
  cohortType?: Resolver<
    Array<ResolversTypes['CohortType']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCohortTypeArgs, never>
  >;
  cohortTypeAggregate?: Resolver<
    ResolversTypes['CohortTypeAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootCohortTypeAggregateArgs, never>
  >;
  cohortTypeByPk?: Resolver<
    Maybe<ResolversTypes['CohortType']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCohortTypeByPkArgs, 'value'>
  >;
  cohortVisibilityEnum?: Resolver<
    Array<ResolversTypes['CohortVisibilityEnum']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCohortVisibilityEnumArgs, never>
  >;
  cohortVisibilityEnumAggregate?: Resolver<
    ResolversTypes['CohortVisibilityEnumAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootCohortVisibilityEnumAggregateArgs, never>
  >;
  cohortVisibilityEnumByPk?: Resolver<
    Maybe<ResolversTypes['CohortVisibilityEnum']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCohortVisibilityEnumByPkArgs, 'value'>
  >;
  cohorts?: Resolver<
    Array<ResolversTypes['Cohorts']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCohortsArgs, never>
  >;
  cohortsAggregate?: Resolver<
    ResolversTypes['CohortsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootCohortsAggregateArgs, never>
  >;
  cohortsByPk?: Resolver<
    Maybe<ResolversTypes['Cohorts']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCohortsByPkArgs, 'id'>
  >;
  cohortsResourcesRel?: Resolver<
    Array<ResolversTypes['CohortsResourcesRel']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCohortsResourcesRelArgs, never>
  >;
  cohortsResourcesRelAggregate?: Resolver<
    ResolversTypes['CohortsResourcesRelAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootCohortsResourcesRelAggregateArgs, never>
  >;
  cohortsResourcesRelByPk?: Resolver<
    Maybe<ResolversTypes['CohortsResourcesRel']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCohortsResourcesRelByPkArgs, 'cohortId' | 'resourceId'>
  >;
  configurations?: Resolver<
    Array<ResolversTypes['Configurations']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootConfigurationsArgs, never>
  >;
  configurationsAggregate?: Resolver<
    ResolversTypes['ConfigurationsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootConfigurationsAggregateArgs, never>
  >;
  configurationsByPk?: Resolver<
    Maybe<ResolversTypes['Configurations']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootConfigurationsByPkArgs, 'key'>
  >;
  courseCertifications?: Resolver<
    Array<ResolversTypes['CourseCertifications']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCourseCertificationsArgs, never>
  >;
  courseCertificationsAggregate?: Resolver<
    ResolversTypes['CourseCertificationsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootCourseCertificationsAggregateArgs, never>
  >;
  courseCertificationsByPk?: Resolver<
    Maybe<ResolversTypes['CourseCertifications']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCourseCertificationsByPkArgs, 'id'>
  >;
  courseCoaches?: Resolver<
    Array<ResolversTypes['CourseCoaches']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCourseCoachesArgs, never>
  >;
  courseCoachesAggregate?: Resolver<
    ResolversTypes['CourseCoachesAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootCourseCoachesAggregateArgs, never>
  >;
  courseCoachesByPk?: Resolver<
    Maybe<ResolversTypes['CourseCoaches']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCourseCoachesByPkArgs, 'coachId' | 'courseId'>
  >;
  courseEnrollmentStatus?: Resolver<
    Array<ResolversTypes['CourseEnrollmentStatus']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCourseEnrollmentStatusArgs, never>
  >;
  courseEnrollmentStatusAggregate?: Resolver<
    ResolversTypes['CourseEnrollmentStatusAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootCourseEnrollmentStatusAggregateArgs, never>
  >;
  courseEnrollmentStatusByPk?: Resolver<
    Maybe<ResolversTypes['CourseEnrollmentStatus']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCourseEnrollmentStatusByPkArgs, 'value'>
  >;
  coursePublishStatus?: Resolver<
    Array<ResolversTypes['CoursePublishStatus']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCoursePublishStatusArgs, never>
  >;
  coursePublishStatusAggregate?: Resolver<
    ResolversTypes['CoursePublishStatusAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootCoursePublishStatusAggregateArgs, never>
  >;
  coursePublishStatusByPk?: Resolver<
    Maybe<ResolversTypes['CoursePublishStatus']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCoursePublishStatusByPkArgs, 'value'>
  >;
  courseType?: Resolver<
    Array<ResolversTypes['CourseType']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCourseTypeArgs, never>
  >;
  courseTypeAggregate?: Resolver<
    ResolversTypes['CourseTypeAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootCourseTypeAggregateArgs, never>
  >;
  courseTypeByPk?: Resolver<
    Maybe<ResolversTypes['CourseType']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCourseTypeByPkArgs, 'value'>
  >;
  courseWaitlist?: Resolver<
    Array<ResolversTypes['CourseWaitlist']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCourseWaitlistArgs, never>
  >;
  courseWaitlistAggregate?: Resolver<
    ResolversTypes['CourseWaitlistAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootCourseWaitlistAggregateArgs, never>
  >;
  courseWaitlistByPk?: Resolver<
    Maybe<ResolversTypes['CourseWaitlist']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCourseWaitlistByPkArgs, 'id'>
  >;
  courseWaitlistDesiredUrgencyType?: Resolver<
    Array<ResolversTypes['CourseWaitlistDesiredUrgencyType']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCourseWaitlistDesiredUrgencyTypeArgs, never>
  >;
  courseWaitlistDesiredUrgencyTypeAggregate?: Resolver<
    ResolversTypes['CourseWaitlistDesiredUrgencyTypeAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootCourseWaitlistDesiredUrgencyTypeAggregateArgs, never>
  >;
  courseWaitlistDesiredUrgencyTypeByPk?: Resolver<
    Maybe<ResolversTypes['CourseWaitlistDesiredUrgencyType']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCourseWaitlistDesiredUrgencyTypeByPkArgs, 'value'>
  >;
  courses?: Resolver<
    Array<ResolversTypes['Courses']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCoursesArgs, never>
  >;
  coursesAggregate?: Resolver<
    ResolversTypes['CoursesAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootCoursesAggregateArgs, never>
  >;
  coursesByPk?: Resolver<
    Maybe<ResolversTypes['Courses']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCoursesByPkArgs, 'id'>
  >;
  coursesByPlan?: Resolver<
    Array<ResolversTypes['CoursesByPlan']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCoursesByPlanArgs, never>
  >;
  coursesByPlanAggregate?: Resolver<
    ResolversTypes['CoursesByPlanAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootCoursesByPlanAggregateArgs, never>
  >;
  coursesResourcesRel?: Resolver<
    Array<ResolversTypes['CoursesResourcesRel']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCoursesResourcesRelArgs, never>
  >;
  coursesResourcesRelAggregate?: Resolver<
    ResolversTypes['CoursesResourcesRelAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootCoursesResourcesRelAggregateArgs, never>
  >;
  coursesResourcesRelByPk?: Resolver<
    Maybe<ResolversTypes['CoursesResourcesRel']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCoursesResourcesRelByPkArgs, 'courseId' | 'resourceId'>
  >;
  coursesTagsRel?: Resolver<
    Array<ResolversTypes['CoursesTagsRel']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCoursesTagsRelArgs, never>
  >;
  coursesTagsRelAggregate?: Resolver<
    ResolversTypes['CoursesTagsRelAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootCoursesTagsRelAggregateArgs, never>
  >;
  coursesTagsRelByPk?: Resolver<
    Maybe<ResolversTypes['CoursesTagsRel']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCoursesTagsRelByPkArgs, 'courseId' | 'tagId'>
  >;
  creditUsage?: Resolver<
    Array<ResolversTypes['creditUsage']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCreditUsageArgs, never>
  >;
  creditUsageByUser?: Resolver<
    Array<ResolversTypes['creditUsageByUser']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCreditUsageByUserArgs, never>
  >;
  emailQueue?: Resolver<
    Array<ResolversTypes['EmailQueue']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootEmailQueueArgs, never>
  >;
  emailQueueAggregate?: Resolver<
    ResolversTypes['EmailQueueAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootEmailQueueAggregateArgs, never>
  >;
  emailQueueByPk?: Resolver<
    Maybe<ResolversTypes['EmailQueue']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootEmailQueueByPkArgs, 'id'>
  >;
  enrollmentWaitlists?: Resolver<
    Array<ResolversTypes['EnrollmentWaitlists']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootEnrollmentWaitlistsArgs, never>
  >;
  enrollmentWaitlistsAggregate?: Resolver<
    ResolversTypes['EnrollmentWaitlistsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootEnrollmentWaitlistsAggregateArgs, never>
  >;
  enrollmentWaitlistsByPk?: Resolver<
    Maybe<ResolversTypes['EnrollmentWaitlists']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootEnrollmentWaitlistsByPkArgs, 'id'>
  >;
  enrollments?: Resolver<
    Array<ResolversTypes['Enrollments']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootEnrollmentsArgs, never>
  >;
  enrollmentsAggregate?: Resolver<
    ResolversTypes['EnrollmentsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootEnrollmentsAggregateArgs, never>
  >;
  enrollmentsByPk?: Resolver<
    Maybe<ResolversTypes['Enrollments']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootEnrollmentsByPkArgs, 'id'>
  >;
  fusionauthExternalIdentifiers?: Resolver<
    Array<ResolversTypes['FusionauthExternalIdentifiers']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootFusionauthExternalIdentifiersArgs, never>
  >;
  fusionauthExternalIdentifiersAggregate?: Resolver<
    ResolversTypes['FusionauthExternalIdentifiersAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootFusionauthExternalIdentifiersAggregateArgs, never>
  >;
  fusionauthExternalIdentifiersByPk?: Resolver<
    Maybe<ResolversTypes['FusionauthExternalIdentifiers']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootFusionauthExternalIdentifiersByPkArgs, 'id'>
  >;
  fusionauthUserRegistrations?: Resolver<
    Array<ResolversTypes['FusionauthUserRegistrations']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootFusionauthUserRegistrationsArgs, never>
  >;
  fusionauthUserRegistrationsAggregate?: Resolver<
    ResolversTypes['FusionauthUserRegistrationsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootFusionauthUserRegistrationsAggregateArgs, never>
  >;
  fusionauthUserRegistrationsByPk?: Resolver<
    Maybe<ResolversTypes['FusionauthUserRegistrations']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootFusionauthUserRegistrationsByPkArgs, 'id'>
  >;
  getAssessmentQuestions?: Resolver<
    Array<ResolversTypes['ImpactAssessmentQuestions']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootGetAssessmentQuestionsArgs, 'args'>
  >;
  getAssessmentQuestionsAggregate?: Resolver<
    ResolversTypes['ImpactAssessmentQuestionsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootGetAssessmentQuestionsAggregateArgs, 'args'>
  >;
  getTeamMembers?: Resolver<
    Array<ResolversTypes['teamMember']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootGetTeamMembersArgs, 'args'>
  >;
  getTeamMembersNew?: Resolver<
    Array<ResolversTypes['getTeamMembersNew']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootGetTeamMembersNewArgs, never>
  >;
  identities?: Resolver<
    Array<ResolversTypes['Identities']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootIdentitiesArgs, never>
  >;
  identitiesAggregate?: Resolver<
    ResolversTypes['IdentitiesAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootIdentitiesAggregateArgs, never>
  >;
  identitiesByPk?: Resolver<
    Maybe<ResolversTypes['Identities']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootIdentitiesByPkArgs, 'id'>
  >;
  identityProviderLinks?: Resolver<
    Array<ResolversTypes['IdentityProviderLinks']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootIdentityProviderLinksArgs, never>
  >;
  identityProviderLinksAggregate?: Resolver<
    ResolversTypes['IdentityProviderLinksAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootIdentityProviderLinksAggregateArgs, never>
  >;
  identityProviders?: Resolver<
    Array<ResolversTypes['IdentityProviders']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootIdentityProvidersArgs, never>
  >;
  identityProvidersAggregate?: Resolver<
    ResolversTypes['IdentityProvidersAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootIdentityProvidersAggregateArgs, never>
  >;
  identityProvidersByPk?: Resolver<
    Maybe<ResolversTypes['IdentityProviders']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootIdentityProvidersByPkArgs, 'id'>
  >;
  impactAssessmentAttempts?: Resolver<
    Array<ResolversTypes['ImpactAssessmentAttempts']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootImpactAssessmentAttemptsArgs, never>
  >;
  impactAssessmentAttemptsAggregate?: Resolver<
    ResolversTypes['ImpactAssessmentAttemptsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootImpactAssessmentAttemptsAggregateArgs, never>
  >;
  impactAssessmentAttemptsByPk?: Resolver<
    Maybe<ResolversTypes['ImpactAssessmentAttempts']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootImpactAssessmentAttemptsByPkArgs, 'id'>
  >;
  impactAssessmentQuestions?: Resolver<
    Array<ResolversTypes['ImpactAssessmentQuestions']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootImpactAssessmentQuestionsArgs, never>
  >;
  impactAssessmentQuestionsAggregate?: Resolver<
    ResolversTypes['ImpactAssessmentQuestionsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootImpactAssessmentQuestionsAggregateArgs, never>
  >;
  impactAssessments?: Resolver<
    Array<ResolversTypes['ImpactAssessments']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootImpactAssessmentsArgs, never>
  >;
  impactAssessmentsAggregate?: Resolver<
    ResolversTypes['ImpactAssessmentsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootImpactAssessmentsAggregateArgs, never>
  >;
  impactAssessmentsByPk?: Resolver<
    Maybe<ResolversTypes['ImpactAssessments']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootImpactAssessmentsByPkArgs, 'id'>
  >;
  journeyEnrollments?: Resolver<
    Array<ResolversTypes['JourneyEnrollments']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootJourneyEnrollmentsArgs, never>
  >;
  journeyEnrollmentsAggregate?: Resolver<
    ResolversTypes['JourneyEnrollmentsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootJourneyEnrollmentsAggregateArgs, never>
  >;
  journeyEnrollmentsByPk?: Resolver<
    Maybe<ResolversTypes['JourneyEnrollments']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootJourneyEnrollmentsByPkArgs, 'id'>
  >;
  journeys?: Resolver<
    Array<ResolversTypes['Journeys']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootJourneysArgs, never>
  >;
  journeysAggregate?: Resolver<
    ResolversTypes['JourneysAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootJourneysAggregateArgs, never>
  >;
  journeysByPk?: Resolver<
    Maybe<ResolversTypes['Journeys']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootJourneysByPkArgs, 'id'>
  >;
  ledger?: Resolver<
    Array<ResolversTypes['Ledger']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootLedgerArgs, never>
  >;
  ledgerAggregate?: Resolver<
    ResolversTypes['LedgerAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootLedgerAggregateArgs, never>
  >;
  ledgerByPk?: Resolver<
    Maybe<ResolversTypes['Ledger']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootLedgerByPkArgs, 'id'>
  >;
  ledgerProductEnum?: Resolver<
    Array<ResolversTypes['LedgerProductEnum']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootLedgerProductEnumArgs, never>
  >;
  ledgerProductEnumAggregate?: Resolver<
    ResolversTypes['LedgerProductEnumAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootLedgerProductEnumAggregateArgs, never>
  >;
  ledgerProductEnumByPk?: Resolver<
    Maybe<ResolversTypes['LedgerProductEnum']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootLedgerProductEnumByPkArgs, 'value'>
  >;
  ledgerTransactionTypeEnum?: Resolver<
    Array<ResolversTypes['LedgerTransactionTypeEnum']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootLedgerTransactionTypeEnumArgs, never>
  >;
  ledgerTransactionTypeEnumAggregate?: Resolver<
    ResolversTypes['LedgerTransactionTypeEnumAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootLedgerTransactionTypeEnumAggregateArgs, never>
  >;
  ledgerTransactionTypeEnumByPk?: Resolver<
    Maybe<ResolversTypes['LedgerTransactionTypeEnum']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootLedgerTransactionTypeEnumByPkArgs, 'value'>
  >;
  lightningSession?: Resolver<
    Array<ResolversTypes['LightningSession']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootLightningSessionArgs, never>
  >;
  lightningSessionAggregate?: Resolver<
    ResolversTypes['LightningSessionAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootLightningSessionAggregateArgs, never>
  >;
  lightningSessionByPk?: Resolver<
    Maybe<ResolversTypes['LightningSession']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootLightningSessionByPkArgs, 'id'>
  >;
  lightningSessionSubmissions?: Resolver<
    Array<ResolversTypes['LightningSessionSubmissions']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootLightningSessionSubmissionsArgs, never>
  >;
  lightningSessionSubmissionsAggregate?: Resolver<
    ResolversTypes['LightningSessionSubmissionsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootLightningSessionSubmissionsAggregateArgs, never>
  >;
  lightningSessionSubmissionsByPk?: Resolver<
    Maybe<ResolversTypes['LightningSessionSubmissions']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootLightningSessionSubmissionsByPkArgs, 'id'>
  >;
  lightningSessionSubmissionsStatus?: Resolver<
    Array<ResolversTypes['lightningSessionSubmissionsStatus']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootLightningSessionSubmissionsStatusArgs, never>
  >;
  lightningSessionSubmissionsStatusAggregate?: Resolver<
    ResolversTypes['lightningSessionSubmissionsStatusAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootLightningSessionSubmissionsStatusAggregateArgs, never>
  >;
  lightningSessionSubmissionsStatusByPk?: Resolver<
    Maybe<ResolversTypes['lightningSessionSubmissionsStatus']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootLightningSessionSubmissionsStatusByPkArgs, 'value'>
  >;
  lightningSessionSubmissionsUsersRel?: Resolver<
    Array<ResolversTypes['LightningSessionSubmissionsUsersRel']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootLightningSessionSubmissionsUsersRelArgs, never>
  >;
  lightningSessionSubmissionsUsersRelAggregate?: Resolver<
    ResolversTypes['LightningSessionSubmissionsUsersRelAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootLightningSessionSubmissionsUsersRelAggregateArgs, never>
  >;
  lightningSessionSubmissionsUsersRelByPk?: Resolver<
    Maybe<ResolversTypes['LightningSessionSubmissionsUsersRel']>,
    ParentType,
    ContextType,
    RequireFields<
      Query_RootLightningSessionSubmissionsUsersRelByPkArgs,
      'lightningSessionSubmissionId' | 'userId'
    >
  >;
  liveClasses?: Resolver<
    Array<ResolversTypes['LiveClasses']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootLiveClassesArgs, never>
  >;
  liveClassesAggregate?: Resolver<
    ResolversTypes['LiveClassesAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootLiveClassesAggregateArgs, never>
  >;
  managerSessionEmails?: Resolver<
    Array<ResolversTypes['ManagerSessionEmails']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootManagerSessionEmailsArgs, never>
  >;
  managerSessionEmailsAggregate?: Resolver<
    ResolversTypes['ManagerSessionEmailsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootManagerSessionEmailsAggregateArgs, never>
  >;
  managerSessionEmailsByPk?: Resolver<
    Maybe<ResolversTypes['ManagerSessionEmails']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootManagerSessionEmailsByPkArgs, 'managerCohortId'>
  >;
  myCohortLearningStatus?: Resolver<
    Array<ResolversTypes['MyCohortLearningStatus']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootMyCohortLearningStatusArgs, never>
  >;
  myCohortLearningStatusAggregate?: Resolver<
    ResolversTypes['MyCohortLearningStatusAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootMyCohortLearningStatusAggregateArgs, never>
  >;
  myLearnerHomeCourses?: Resolver<
    Array<ResolversTypes['MyLearnerHomeCourses']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootMyLearnerHomeCoursesArgs, never>
  >;
  myLearnerHomeCoursesAggregate?: Resolver<
    ResolversTypes['MyLearnerHomeCoursesAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootMyLearnerHomeCoursesAggregateArgs, never>
  >;
  myLearnerHomeLiveCoaching?: Resolver<
    Array<ResolversTypes['MyLearnerHomeLiveCoaching']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootMyLearnerHomeLiveCoachingArgs, never>
  >;
  myLearnerHomeLiveCoachingAggregate?: Resolver<
    ResolversTypes['MyLearnerHomeLiveCoachingAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootMyLearnerHomeLiveCoachingAggregateArgs, never>
  >;
  myLightningSessionCohortsLearningStatus?: Resolver<
    Array<ResolversTypes['MyLightningSessionCohortsLearningStatus']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootMyLightningSessionCohortsLearningStatusArgs, never>
  >;
  myLightningSessionCohortsLearningStatusAggregate?: Resolver<
    ResolversTypes['MyLightningSessionCohortsLearningStatusAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootMyLightningSessionCohortsLearningStatusAggregateArgs, never>
  >;
  myTeamFlatTree?: Resolver<
    Array<ResolversTypes['team_flat_tree_node']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootMyTeamFlatTreeArgs, 'args'>
  >;
  myTraditionalCohortsLearningStatus?: Resolver<
    Array<ResolversTypes['MyTraditionalCohortsLearningStatus']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootMyTraditionalCohortsLearningStatusArgs, never>
  >;
  myTraditionalCohortsLearningStatusAggregate?: Resolver<
    ResolversTypes['MyTraditionalCohortsLearningStatusAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootMyTraditionalCohortsLearningStatusAggregateArgs, never>
  >;
  my_learner_task_native_query?: Resolver<
    Array<ResolversTypes['learner_task']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootMy_Learner_Task_Native_QueryArgs, 'args'>
  >;
  nonceAccessToken?: Resolver<
    Array<ResolversTypes['NonceAccessToken']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootNonceAccessTokenArgs, never>
  >;
  nonceAccessTokenAggregate?: Resolver<
    ResolversTypes['NonceAccessTokenAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootNonceAccessTokenAggregateArgs, never>
  >;
  nonceAccessTokenByPk?: Resolver<
    Maybe<ResolversTypes['NonceAccessToken']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootNonceAccessTokenByPkArgs, 'id'>
  >;
  organizationUsers?: Resolver<
    Array<ResolversTypes['OrganizationUsers']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootOrganizationUsersArgs, never>
  >;
  organizationUsersAggregate?: Resolver<
    ResolversTypes['OrganizationUsersAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootOrganizationUsersAggregateArgs, never>
  >;
  organizationUsersByPk?: Resolver<
    Maybe<ResolversTypes['OrganizationUsers']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootOrganizationUsersByPkArgs, 'id'>
  >;
  organizations?: Resolver<
    Array<ResolversTypes['Organizations']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootOrganizationsArgs, never>
  >;
  organizationsAggregate?: Resolver<
    ResolversTypes['OrganizationsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootOrganizationsAggregateArgs, never>
  >;
  organizationsByPk?: Resolver<
    Maybe<ResolversTypes['Organizations']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootOrganizationsByPkArgs, 'id'>
  >;
  organizationsMeta?: Resolver<
    Array<ResolversTypes['OrganizationsMeta']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootOrganizationsMetaArgs, never>
  >;
  organizationsMetaAggregate?: Resolver<
    ResolversTypes['OrganizationsMetaAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootOrganizationsMetaAggregateArgs, never>
  >;
  organizationsMetaByPk?: Resolver<
    Maybe<ResolversTypes['OrganizationsMeta']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootOrganizationsMetaByPkArgs, 'organizationId'>
  >;
  planSubscriptions?: Resolver<
    Array<ResolversTypes['PlanSubscriptions']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootPlanSubscriptionsArgs, never>
  >;
  planSubscriptionsAggregate?: Resolver<
    ResolversTypes['PlanSubscriptionsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootPlanSubscriptionsAggregateArgs, never>
  >;
  planSubscriptionsByPk?: Resolver<
    Maybe<ResolversTypes['PlanSubscriptions']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootPlanSubscriptionsByPkArgs, 'id'>
  >;
  plans?: Resolver<
    Array<ResolversTypes['Plans']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootPlansArgs, never>
  >;
  plansAggregate?: Resolver<
    ResolversTypes['PlansAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootPlansAggregateArgs, never>
  >;
  plansByPk?: Resolver<
    Maybe<ResolversTypes['Plans']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootPlansByPkArgs, 'id'>
  >;
  playExecution?: Resolver<
    Array<ResolversTypes['PlayExecution']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootPlayExecutionArgs, never>
  >;
  playExecutionAggregate?: Resolver<
    ResolversTypes['PlayExecutionAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootPlayExecutionAggregateArgs, never>
  >;
  playExecutionByPk?: Resolver<
    Maybe<ResolversTypes['PlayExecution']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootPlayExecutionByPkArgs, 'id'>
  >;
  programActionsTaken?: Resolver<
    Array<ResolversTypes['ProgramActionsTaken']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootProgramActionsTakenArgs, never>
  >;
  programActionsTakenAggregate?: Resolver<
    ResolversTypes['ProgramActionsTakenAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootProgramActionsTakenAggregateArgs, never>
  >;
  programCoursesShown?: Resolver<
    Array<ResolversTypes['ProgramCoursesShown']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootProgramCoursesShownArgs, never>
  >;
  programCoursesShownAggregate?: Resolver<
    ResolversTypes['ProgramCoursesShownAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootProgramCoursesShownAggregateArgs, never>
  >;
  programCoursesShownByPk?: Resolver<
    Maybe<ResolversTypes['ProgramCoursesShown']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootProgramCoursesShownByPkArgs, 'courseId' | 'programId'>
  >;
  programTags?: Resolver<
    Array<ResolversTypes['ProgramTags']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootProgramTagsArgs, never>
  >;
  programTagsAggregate?: Resolver<
    ResolversTypes['ProgramTagsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootProgramTagsAggregateArgs, never>
  >;
  programTagsByPk?: Resolver<
    Maybe<ResolversTypes['ProgramTags']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootProgramTagsByPkArgs, 'programId' | 'tagId'>
  >;
  programUsersSelected?: Resolver<
    Array<ResolversTypes['ProgramUsersSelected']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootProgramUsersSelectedArgs, never>
  >;
  programUsersSelectedAggregate?: Resolver<
    ResolversTypes['ProgramUsersSelectedAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootProgramUsersSelectedAggregateArgs, never>
  >;
  programUsersSelectedByPk?: Resolver<
    Maybe<ResolversTypes['ProgramUsersSelected']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootProgramUsersSelectedByPkArgs, 'programId' | 'userId'>
  >;
  programs?: Resolver<
    Array<ResolversTypes['Programs']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootProgramsArgs, never>
  >;
  programsAggregate?: Resolver<
    ResolversTypes['ProgramsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootProgramsAggregateArgs, never>
  >;
  programsByPk?: Resolver<
    Maybe<ResolversTypes['Programs']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootProgramsByPkArgs, 'id'>
  >;
  questionType?: Resolver<
    Array<ResolversTypes['QuestionType']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootQuestionTypeArgs, never>
  >;
  questionTypeAggregate?: Resolver<
    ResolversTypes['QuestionTypeAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootQuestionTypeAggregateArgs, never>
  >;
  questionTypeByPk?: Resolver<
    Maybe<ResolversTypes['QuestionType']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootQuestionTypeByPkArgs, 'value'>
  >;
  recordingActivity?: Resolver<
    Array<ResolversTypes['RecordingActivity']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootRecordingActivityArgs, never>
  >;
  recordingActivityAggregate?: Resolver<
    ResolversTypes['RecordingActivityAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootRecordingActivityAggregateArgs, never>
  >;
  recordingActivityByPk?: Resolver<
    Maybe<ResolversTypes['RecordingActivity']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootRecordingActivityByPkArgs, 'id'>
  >;
  recordings?: Resolver<
    Array<ResolversTypes['Recordings']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootRecordingsArgs, never>
  >;
  recordingsAggregate?: Resolver<
    ResolversTypes['RecordingsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootRecordingsAggregateArgs, never>
  >;
  recordingsByPk?: Resolver<
    Maybe<ResolversTypes['Recordings']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootRecordingsByPkArgs, 'id'>
  >;
  relatedCourses?: Resolver<
    Array<ResolversTypes['RelatedCourses']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootRelatedCoursesArgs, never>
  >;
  relatedCoursesAggregate?: Resolver<
    ResolversTypes['RelatedCoursesAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootRelatedCoursesAggregateArgs, never>
  >;
  relatedCoursesByPk?: Resolver<
    Maybe<ResolversTypes['RelatedCourses']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootRelatedCoursesByPkArgs, 'courseId' | 'relatedCourseSlug'>
  >;
  reportPublishStatus?: Resolver<
    Array<ResolversTypes['ReportPublishStatus']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootReportPublishStatusArgs, never>
  >;
  reportPublishStatusAggregate?: Resolver<
    ResolversTypes['ReportPublishStatusAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootReportPublishStatusAggregateArgs, never>
  >;
  reportPublishStatusByPk?: Resolver<
    Maybe<ResolversTypes['ReportPublishStatus']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootReportPublishStatusByPkArgs, 'value'>
  >;
  reportType?: Resolver<
    Array<ResolversTypes['ReportType']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootReportTypeArgs, never>
  >;
  reportTypeAggregate?: Resolver<
    ResolversTypes['ReportTypeAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootReportTypeAggregateArgs, never>
  >;
  reportTypeByPk?: Resolver<
    Maybe<ResolversTypes['ReportType']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootReportTypeByPkArgs, 'value'>
  >;
  reports?: Resolver<
    Array<ResolversTypes['Reports']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootReportsArgs, never>
  >;
  reportsAggregate?: Resolver<
    ResolversTypes['ReportsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootReportsAggregateArgs, never>
  >;
  reportsByPk?: Resolver<
    Maybe<ResolversTypes['Reports']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootReportsByPkArgs, 'id'>
  >;
  resourceType?: Resolver<
    Array<ResolversTypes['ResourceType']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootResourceTypeArgs, never>
  >;
  resourceTypeAggregate?: Resolver<
    ResolversTypes['ResourceTypeAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootResourceTypeAggregateArgs, never>
  >;
  resourceTypeByPk?: Resolver<
    Maybe<ResolversTypes['ResourceType']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootResourceTypeByPkArgs, 'value'>
  >;
  resources?: Resolver<
    Array<ResolversTypes['Resources']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootResourcesArgs, never>
  >;
  resourcesAggregate?: Resolver<
    ResolversTypes['ResourcesAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootResourcesAggregateArgs, never>
  >;
  resourcesByPk?: Resolver<
    Maybe<ResolversTypes['Resources']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootResourcesByPkArgs, 'id'>
  >;
  schools?: Resolver<
    Array<ResolversTypes['Schools']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootSchoolsArgs, never>
  >;
  schoolsAggregate?: Resolver<
    ResolversTypes['SchoolsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootSchoolsAggregateArgs, never>
  >;
  schoolsByPk?: Resolver<
    Maybe<ResolversTypes['Schools']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootSchoolsByPkArgs, 'id'>
  >;
  seatType?: Resolver<
    Array<ResolversTypes['SeatType']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootSeatTypeArgs, never>
  >;
  seatTypeAggregate?: Resolver<
    ResolversTypes['SeatTypeAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootSeatTypeAggregateArgs, never>
  >;
  seatTypeByPk?: Resolver<
    Maybe<ResolversTypes['SeatType']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootSeatTypeByPkArgs, 'value'>
  >;
  seats?: Resolver<
    Array<ResolversTypes['Seats']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootSeatsArgs, never>
  >;
  seatsAggregate?: Resolver<
    ResolversTypes['SeatsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootSeatsAggregateArgs, never>
  >;
  seatsByPk?: Resolver<
    Maybe<ResolversTypes['Seats']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootSeatsByPkArgs, 'id'>
  >;
  streamMessages?: Resolver<
    Array<ResolversTypes['StreamMessages']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootStreamMessagesArgs, never>
  >;
  streamMessagesAggregate?: Resolver<
    ResolversTypes['StreamMessagesAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootStreamMessagesAggregateArgs, never>
  >;
  streamMessagesByPk?: Resolver<
    Maybe<ResolversTypes['StreamMessages']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootStreamMessagesByPkArgs, 'id'>
  >;
  subordinates?: Resolver<
    Array<ResolversTypes['Subordinates']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootSubordinatesArgs, never>
  >;
  subordinatesAggregate?: Resolver<
    ResolversTypes['SubordinatesAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootSubordinatesAggregateArgs, never>
  >;
  tagTypeScope?: Resolver<
    Array<ResolversTypes['TagTypeScope']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTagTypeScopeArgs, never>
  >;
  tagTypeScopeAggregate?: Resolver<
    ResolversTypes['TagTypeScopeAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootTagTypeScopeAggregateArgs, never>
  >;
  tagTypeScopeByPk?: Resolver<
    Maybe<ResolversTypes['TagTypeScope']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTagTypeScopeByPkArgs, 'value'>
  >;
  tagTypes?: Resolver<
    Array<ResolversTypes['TagTypes']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTagTypesArgs, never>
  >;
  tagTypesAggregate?: Resolver<
    ResolversTypes['TagTypesAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootTagTypesAggregateArgs, never>
  >;
  tagTypesByPk?: Resolver<
    Maybe<ResolversTypes['TagTypes']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTagTypesByPkArgs, 'id'>
  >;
  tags?: Resolver<
    Array<ResolversTypes['Tags']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTagsArgs, never>
  >;
  tagsAggregate?: Resolver<
    ResolversTypes['TagsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootTagsAggregateArgs, never>
  >;
  tagsByPk?: Resolver<
    Maybe<ResolversTypes['Tags']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTagsByPkArgs, 'id'>
  >;
  taskDefinition?: Resolver<
    Array<ResolversTypes['TaskDefinition']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTaskDefinitionArgs, never>
  >;
  taskDefinitionAggregate?: Resolver<
    ResolversTypes['TaskDefinitionAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootTaskDefinitionAggregateArgs, never>
  >;
  taskDefinitionByPk?: Resolver<
    Maybe<ResolversTypes['TaskDefinition']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTaskDefinitionByPkArgs, 'id'>
  >;
  taskDefinitionType?: Resolver<
    Array<ResolversTypes['TaskDefinitionType']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTaskDefinitionTypeArgs, never>
  >;
  taskDefinitionTypeAggregate?: Resolver<
    ResolversTypes['TaskDefinitionTypeAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootTaskDefinitionTypeAggregateArgs, never>
  >;
  taskDefinitionTypeByPk?: Resolver<
    Maybe<ResolversTypes['TaskDefinitionType']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTaskDefinitionTypeByPkArgs, 'value'>
  >;
  tasks?: Resolver<
    Array<ResolversTypes['Tasks']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTasksArgs, never>
  >;
  tasksAggregate?: Resolver<
    ResolversTypes['TasksAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootTasksAggregateArgs, never>
  >;
  tasksByPk?: Resolver<
    Maybe<ResolversTypes['Tasks']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTasksByPkArgs, 'id'>
  >;
  teamMemberPollSubmissions?: Resolver<
    Array<ResolversTypes['teamMemberPollSubmissions']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTeamMemberPollSubmissionsArgs, never>
  >;
  teams?: Resolver<
    Array<ResolversTypes['Teams']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTeamsArgs, never>
  >;
  teamsAggregate?: Resolver<
    ResolversTypes['TeamsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootTeamsAggregateArgs, never>
  >;
  teamsByPk?: Resolver<
    Maybe<ResolversTypes['Teams']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTeamsByPkArgs, 'id'>
  >;
  teamsUsers?: Resolver<
    Array<ResolversTypes['TeamsUsers']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTeamsUsersArgs, never>
  >;
  teamsUsersAggregate?: Resolver<
    ResolversTypes['TeamsUsersAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootTeamsUsersAggregateArgs, never>
  >;
  teamsUsersByPk?: Resolver<
    Maybe<ResolversTypes['TeamsUsers']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTeamsUsersByPkArgs, 'teamId' | 'userId'>
  >;
  timeElapsedLiveClasses?: Resolver<
    Array<ResolversTypes['TimeElapsedLiveClasses']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTimeElapsedLiveClassesArgs, never>
  >;
  timeElapsedLiveClassesAggregate?: Resolver<
    ResolversTypes['TimeElapsedLiveClassesAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootTimeElapsedLiveClassesAggregateArgs, never>
  >;
  typeformSubmissions?: Resolver<
    Array<ResolversTypes['TypeformSubmissions']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTypeformSubmissionsArgs, never>
  >;
  typeformSubmissionsAggregate?: Resolver<
    ResolversTypes['TypeformSubmissionsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootTypeformSubmissionsAggregateArgs, never>
  >;
  typeformSubmissionsByPk?: Resolver<
    Maybe<ResolversTypes['TypeformSubmissions']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTypeformSubmissionsByPkArgs, 'id'>
  >;
  userCompletedSurveys?: Resolver<
    Array<ResolversTypes['UserCompletedSurveys']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootUserCompletedSurveysArgs, never>
  >;
  userCompletedSurveysAggregate?: Resolver<
    ResolversTypes['UserCompletedSurveysAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootUserCompletedSurveysAggregateArgs, never>
  >;
  userManagedTeamsFlattenedTree?: Resolver<
    Array<ResolversTypes['userManagedTeamsFlattenedTree']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootUserManagedTeamsFlattenedTreeArgs, 'args'>
  >;
  userNumCompletedClasses?: Resolver<
    Array<ResolversTypes['UserNumCompletedClasses']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootUserNumCompletedClassesArgs, never>
  >;
  userNumCompletedClassesAggregate?: Resolver<
    ResolversTypes['UserNumCompletedClassesAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootUserNumCompletedClassesAggregateArgs, never>
  >;
  userSessionEmails?: Resolver<
    Array<ResolversTypes['UserSessionEmails']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootUserSessionEmailsArgs, never>
  >;
  userSessionEmailsAggregate?: Resolver<
    ResolversTypes['UserSessionEmailsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootUserSessionEmailsAggregateArgs, never>
  >;
  userSessionEmailsByPk?: Resolver<
    Maybe<ResolversTypes['UserSessionEmails']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootUserSessionEmailsByPkArgs, 'userClassEventId'>
  >;
  users?: Resolver<
    Array<ResolversTypes['Users']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootUsersArgs, never>
  >;
  usersAggregate?: Resolver<
    ResolversTypes['UsersAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootUsersAggregateArgs, never>
  >;
  usersByPk?: Resolver<
    Maybe<ResolversTypes['Users']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootUsersByPkArgs, 'id'>
  >;
  usersFunctions?: Resolver<
    Array<ResolversTypes['UsersFunctions']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootUsersFunctionsArgs, never>
  >;
  usersFunctionsAggregate?: Resolver<
    ResolversTypes['UsersFunctionsAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootUsersFunctionsAggregateArgs, never>
  >;
  usersFunctionsByPk?: Resolver<
    Maybe<ResolversTypes['UsersFunctions']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootUsersFunctionsByPkArgs, 'value'>
  >;
  usersLengthInFunction?: Resolver<
    Array<ResolversTypes['UsersLengthInFunction']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootUsersLengthInFunctionArgs, never>
  >;
  usersLengthInFunctionAggregate?: Resolver<
    ResolversTypes['UsersLengthInFunctionAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootUsersLengthInFunctionAggregateArgs, never>
  >;
  usersLengthInFunctionByPk?: Resolver<
    Maybe<ResolversTypes['UsersLengthInFunction']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootUsersLengthInFunctionByPkArgs, 'value'>
  >;
  usersMeta?: Resolver<
    Array<ResolversTypes['UsersMeta']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootUsersMetaArgs, never>
  >;
  usersMetaAggregate?: Resolver<
    ResolversTypes['UsersMetaAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootUsersMetaAggregateArgs, never>
  >;
  usersMetaByPk?: Resolver<
    Maybe<ResolversTypes['UsersMeta']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootUsersMetaByPkArgs, 'userId'>
  >;
  zoomOauthTokens?: Resolver<
    Array<ResolversTypes['ZoomOauthTokens']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootZoomOauthTokensArgs, never>
  >;
  zoomOauthTokensAggregate?: Resolver<
    ResolversTypes['ZoomOauthTokensAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_RootZoomOauthTokensAggregateArgs, never>
  >;
  zoomOauthTokensByPk?: Resolver<
    Maybe<ResolversTypes['ZoomOauthTokens']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootZoomOauthTokensByPkArgs, 'bearerToken'>
  >;
};

export interface SmallintScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['smallint'], any> {
  name: 'smallint';
}

export type Subscription_RootResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['subscription_root'] = ResolversParentTypes['subscription_root']
> = {
  ActivityTypeStream?: SubscriptionResolver<
    Array<ResolversTypes['ActivityType']>,
    'ActivityTypeStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootActivityTypeStreamArgs, 'batchSize' | 'cursor'>
  >;
  AttemptStatusStream?: SubscriptionResolver<
    Array<ResolversTypes['AttemptStatus']>,
    'AttemptStatusStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootAttemptStatusStreamArgs, 'batchSize' | 'cursor'>
  >;
  CatalogCoursesStream?: SubscriptionResolver<
    Array<ResolversTypes['CatalogCourses']>,
    'CatalogCoursesStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCatalogCoursesStreamArgs, 'batchSize' | 'cursor'>
  >;
  ClassCoachesStream?: SubscriptionResolver<
    Array<ResolversTypes['ClassCoaches']>,
    'ClassCoachesStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassCoachesStreamArgs, 'batchSize' | 'cursor'>
  >;
  ClassPlaybackActivityTypeStream?: SubscriptionResolver<
    Array<ResolversTypes['ClassPlaybackActivityType']>,
    'ClassPlaybackActivityTypeStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassPlaybackActivityTypeStreamArgs, 'batchSize' | 'cursor'>
  >;
  ClassResourcesStream?: SubscriptionResolver<
    Array<ResolversTypes['ClassResources']>,
    'ClassResourcesStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassResourcesStreamArgs, 'batchSize' | 'cursor'>
  >;
  CohortCoachesStream?: SubscriptionResolver<
    Array<ResolversTypes['CohortCoaches']>,
    'CohortCoachesStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCohortCoachesStreamArgs, 'batchSize' | 'cursor'>
  >;
  CohortProgressStream?: SubscriptionResolver<
    Array<ResolversTypes['CohortProgress']>,
    'CohortProgressStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCohortProgressStreamArgs, 'batchSize' | 'cursor'>
  >;
  CohortTypeStream?: SubscriptionResolver<
    Array<ResolversTypes['CohortType']>,
    'CohortTypeStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCohortTypeStreamArgs, 'batchSize' | 'cursor'>
  >;
  CourseCoachesStream?: SubscriptionResolver<
    Array<ResolversTypes['CourseCoaches']>,
    'CourseCoachesStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCourseCoachesStreamArgs, 'batchSize' | 'cursor'>
  >;
  CourseEnrollmentStatusStream?: SubscriptionResolver<
    Array<ResolversTypes['CourseEnrollmentStatus']>,
    'CourseEnrollmentStatusStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCourseEnrollmentStatusStreamArgs, 'batchSize' | 'cursor'>
  >;
  CoursePublishStatusStream?: SubscriptionResolver<
    Array<ResolversTypes['CoursePublishStatus']>,
    'CoursePublishStatusStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCoursePublishStatusStreamArgs, 'batchSize' | 'cursor'>
  >;
  CourseTypeStream?: SubscriptionResolver<
    Array<ResolversTypes['CourseType']>,
    'CourseTypeStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCourseTypeStreamArgs, 'batchSize' | 'cursor'>
  >;
  CoursesResourcesRelStream?: SubscriptionResolver<
    Array<ResolversTypes['CoursesResourcesRel']>,
    'CoursesResourcesRelStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCoursesResourcesRelStreamArgs, 'batchSize' | 'cursor'>
  >;
  IdentitiesStream?: SubscriptionResolver<
    Array<ResolversTypes['Identities']>,
    'IdentitiesStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootIdentitiesStreamArgs, 'batchSize' | 'cursor'>
  >;
  IdentityProviderLinksStream?: SubscriptionResolver<
    Array<ResolversTypes['IdentityProviderLinks']>,
    'IdentityProviderLinksStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootIdentityProviderLinksStreamArgs, 'batchSize' | 'cursor'>
  >;
  IdentityProvidersStream?: SubscriptionResolver<
    Array<ResolversTypes['IdentityProviders']>,
    'IdentityProvidersStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootIdentityProvidersStreamArgs, 'batchSize' | 'cursor'>
  >;
  OrganizationUsersStream?: SubscriptionResolver<
    Array<ResolversTypes['OrganizationUsers']>,
    'OrganizationUsersStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootOrganizationUsersStreamArgs, 'batchSize' | 'cursor'>
  >;
  OrganizationsStream?: SubscriptionResolver<
    Array<ResolversTypes['Organizations']>,
    'OrganizationsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootOrganizationsStreamArgs, 'batchSize' | 'cursor'>
  >;
  PlayExecutionStream?: SubscriptionResolver<
    Array<ResolversTypes['PlayExecution']>,
    'PlayExecutionStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootPlayExecutionStreamArgs, 'batchSize' | 'cursor'>
  >;
  QuestionTypeStream?: SubscriptionResolver<
    Array<ResolversTypes['QuestionType']>,
    'QuestionTypeStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootQuestionTypeStreamArgs, 'batchSize' | 'cursor'>
  >;
  RelatedCoursesStream?: SubscriptionResolver<
    Array<ResolversTypes['RelatedCourses']>,
    'RelatedCoursesStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootRelatedCoursesStreamArgs, 'batchSize' | 'cursor'>
  >;
  ReportPublishStatusStream?: SubscriptionResolver<
    Array<ResolversTypes['ReportPublishStatus']>,
    'ReportPublishStatusStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootReportPublishStatusStreamArgs, 'batchSize' | 'cursor'>
  >;
  ReportTypeStream?: SubscriptionResolver<
    Array<ResolversTypes['ReportType']>,
    'ReportTypeStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootReportTypeStreamArgs, 'batchSize' | 'cursor'>
  >;
  ResourceTypeStream?: SubscriptionResolver<
    Array<ResolversTypes['ResourceType']>,
    'ResourceTypeStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootResourceTypeStreamArgs, 'batchSize' | 'cursor'>
  >;
  SeatTypeStream?: SubscriptionResolver<
    Array<ResolversTypes['SeatType']>,
    'SeatTypeStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootSeatTypeStreamArgs, 'batchSize' | 'cursor'>
  >;
  TagTypeScopeStream?: SubscriptionResolver<
    Array<ResolversTypes['TagTypeScope']>,
    'TagTypeScopeStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTagTypeScopeStreamArgs, 'batchSize' | 'cursor'>
  >;
  TaskDefinitionTypeStream?: SubscriptionResolver<
    Array<ResolversTypes['TaskDefinitionType']>,
    'TaskDefinitionTypeStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTaskDefinitionTypeStreamArgs, 'batchSize' | 'cursor'>
  >;
  TeamsUsersStream?: SubscriptionResolver<
    Array<ResolversTypes['TeamsUsers']>,
    'TeamsUsersStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTeamsUsersStreamArgs, 'batchSize' | 'cursor'>
  >;
  UsersFunctionsStream?: SubscriptionResolver<
    Array<ResolversTypes['UsersFunctions']>,
    'UsersFunctionsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUsersFunctionsStreamArgs, 'batchSize' | 'cursor'>
  >;
  UsersLengthInFunctionStream?: SubscriptionResolver<
    Array<ResolversTypes['UsersLengthInFunction']>,
    'UsersLengthInFunctionStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUsersLengthInFunctionStreamArgs, 'batchSize' | 'cursor'>
  >;
  UsersStream?: SubscriptionResolver<
    Array<ResolversTypes['Users']>,
    'UsersStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUsersStreamArgs, 'batchSize' | 'cursor'>
  >;
  activityType?: SubscriptionResolver<
    Array<ResolversTypes['ActivityType']>,
    'activityType',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootActivityTypeArgs, never>
  >;
  activityTypeAggregate?: SubscriptionResolver<
    ResolversTypes['ActivityTypeAggregate'],
    'activityTypeAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootActivityTypeAggregateArgs, never>
  >;
  activityTypeByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['ActivityType']>,
    'activityTypeByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootActivityTypeByPkArgs, 'value'>
  >;
  attemptStatus?: SubscriptionResolver<
    Array<ResolversTypes['AttemptStatus']>,
    'attemptStatus',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootAttemptStatusArgs, never>
  >;
  attemptStatusAggregate?: SubscriptionResolver<
    ResolversTypes['AttemptStatusAggregate'],
    'attemptStatusAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootAttemptStatusAggregateArgs, never>
  >;
  attemptStatusByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['AttemptStatus']>,
    'attemptStatusByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootAttemptStatusByPkArgs, 'value'>
  >;
  catalogCourses?: SubscriptionResolver<
    Array<ResolversTypes['CatalogCourses']>,
    'catalogCourses',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCatalogCoursesArgs, never>
  >;
  catalogCoursesAggregate?: SubscriptionResolver<
    ResolversTypes['CatalogCoursesAggregate'],
    'catalogCoursesAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCatalogCoursesAggregateArgs, never>
  >;
  catalogCoursesByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['CatalogCourses']>,
    'catalogCoursesByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCatalogCoursesByPkArgs, 'catalogId' | 'courseId'>
  >;
  catalogs?: SubscriptionResolver<
    Array<ResolversTypes['Catalogs']>,
    'catalogs',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCatalogsArgs, never>
  >;
  catalogsAggregate?: SubscriptionResolver<
    ResolversTypes['CatalogsAggregate'],
    'catalogsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCatalogsAggregateArgs, never>
  >;
  catalogsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Catalogs']>,
    'catalogsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCatalogsByPkArgs, 'id'>
  >;
  catalogsJourneysRel?: SubscriptionResolver<
    Array<ResolversTypes['CatalogsJourneysRel']>,
    'catalogsJourneysRel',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCatalogsJourneysRelArgs, never>
  >;
  catalogsJourneysRelAggregate?: SubscriptionResolver<
    ResolversTypes['CatalogsJourneysRelAggregate'],
    'catalogsJourneysRelAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCatalogsJourneysRelAggregateArgs, never>
  >;
  catalogsJourneysRelByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['CatalogsJourneysRel']>,
    'catalogsJourneysRelByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCatalogsJourneysRelByPkArgs, 'catalogId' | 'journeyId'>
  >;
  catalogsJourneysRelStream?: SubscriptionResolver<
    Array<ResolversTypes['CatalogsJourneysRel']>,
    'catalogsJourneysRelStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCatalogsJourneysRelStreamArgs, 'batchSize' | 'cursor'>
  >;
  catalogsStream?: SubscriptionResolver<
    Array<ResolversTypes['Catalogs']>,
    'catalogsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCatalogsStreamArgs, 'batchSize' | 'cursor'>
  >;
  classCoaches?: SubscriptionResolver<
    Array<ResolversTypes['ClassCoaches']>,
    'classCoaches',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassCoachesArgs, never>
  >;
  classCoachesAggregate?: SubscriptionResolver<
    ResolversTypes['ClassCoachesAggregate'],
    'classCoachesAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassCoachesAggregateArgs, never>
  >;
  classCoachesByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['ClassCoaches']>,
    'classCoachesByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassCoachesByPkArgs, 'classId' | 'coachId'>
  >;
  classEventActivity?: SubscriptionResolver<
    Array<ResolversTypes['ClassEventActivity']>,
    'classEventActivity',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventActivityArgs, never>
  >;
  classEventActivityAggregate?: SubscriptionResolver<
    ResolversTypes['ClassEventActivityAggregate'],
    'classEventActivityAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventActivityAggregateArgs, never>
  >;
  classEventActivityByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['ClassEventActivity']>,
    'classEventActivityByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventActivityByPkArgs, 'id'>
  >;
  classEventActivityStream?: SubscriptionResolver<
    Array<ResolversTypes['ClassEventActivity']>,
    'classEventActivityStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventActivityStreamArgs, 'batchSize' | 'cursor'>
  >;
  classEventAnonRegistrations?: SubscriptionResolver<
    Array<ResolversTypes['ClassEventAnonRegistrations']>,
    'classEventAnonRegistrations',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventAnonRegistrationsArgs, never>
  >;
  classEventAnonRegistrationsAggregate?: SubscriptionResolver<
    ResolversTypes['ClassEventAnonRegistrationsAggregate'],
    'classEventAnonRegistrationsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventAnonRegistrationsAggregateArgs, never>
  >;
  classEventAnonRegistrationsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['ClassEventAnonRegistrations']>,
    'classEventAnonRegistrationsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventAnonRegistrationsByPkArgs, 'id'>
  >;
  classEventAnonRegistrationsStream?: SubscriptionResolver<
    Array<ResolversTypes['ClassEventAnonRegistrations']>,
    'classEventAnonRegistrationsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventAnonRegistrationsStreamArgs, 'batchSize' | 'cursor'>
  >;
  classEventInvitations?: SubscriptionResolver<
    Array<ResolversTypes['ClassEventInvitations']>,
    'classEventInvitations',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventInvitationsArgs, never>
  >;
  classEventInvitationsAggregate?: SubscriptionResolver<
    ResolversTypes['ClassEventInvitationsAggregate'],
    'classEventInvitationsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventInvitationsAggregateArgs, never>
  >;
  classEventInvitationsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['ClassEventInvitations']>,
    'classEventInvitationsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventInvitationsByPkArgs, 'id'>
  >;
  classEventInvitationsStream?: SubscriptionResolver<
    Array<ResolversTypes['ClassEventInvitations']>,
    'classEventInvitationsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventInvitationsStreamArgs, 'batchSize' | 'cursor'>
  >;
  classEventMeetingParticipants?: SubscriptionResolver<
    Array<ResolversTypes['ClassEventMeetingParticipants']>,
    'classEventMeetingParticipants',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventMeetingParticipantsArgs, never>
  >;
  classEventMeetingParticipantsAggregate?: SubscriptionResolver<
    ResolversTypes['ClassEventMeetingParticipantsAggregate'],
    'classEventMeetingParticipantsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventMeetingParticipantsAggregateArgs, never>
  >;
  classEventMeetingParticipantsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['ClassEventMeetingParticipants']>,
    'classEventMeetingParticipantsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventMeetingParticipantsByPkArgs, 'participantId'>
  >;
  classEventMeetingParticipantsStream?: SubscriptionResolver<
    Array<ResolversTypes['ClassEventMeetingParticipants']>,
    'classEventMeetingParticipantsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventMeetingParticipantsStreamArgs, 'batchSize' | 'cursor'>
  >;
  classEventMeetingSegments?: SubscriptionResolver<
    Array<ResolversTypes['ClassEventMeetingSegments']>,
    'classEventMeetingSegments',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventMeetingSegmentsArgs, never>
  >;
  classEventMeetingSegmentsAggregate?: SubscriptionResolver<
    ResolversTypes['ClassEventMeetingSegmentsAggregate'],
    'classEventMeetingSegmentsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventMeetingSegmentsAggregateArgs, never>
  >;
  classEventMeetingSegmentsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['ClassEventMeetingSegments']>,
    'classEventMeetingSegmentsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventMeetingSegmentsByPkArgs, 'id'>
  >;
  classEventMeetingSegmentsStream?: SubscriptionResolver<
    Array<ResolversTypes['ClassEventMeetingSegments']>,
    'classEventMeetingSegmentsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventMeetingSegmentsStreamArgs, 'batchSize' | 'cursor'>
  >;
  classEventMeetings?: SubscriptionResolver<
    Array<ResolversTypes['ClassEventMeetings']>,
    'classEventMeetings',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventMeetingsArgs, never>
  >;
  classEventMeetingsAggregate?: SubscriptionResolver<
    ResolversTypes['ClassEventMeetingsAggregate'],
    'classEventMeetingsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventMeetingsAggregateArgs, never>
  >;
  classEventMeetingsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['ClassEventMeetings']>,
    'classEventMeetingsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventMeetingsByPkArgs, 'id'>
  >;
  classEventMeetingsStream?: SubscriptionResolver<
    Array<ResolversTypes['ClassEventMeetings']>,
    'classEventMeetingsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventMeetingsStreamArgs, 'batchSize' | 'cursor'>
  >;
  classEventRegistrations?: SubscriptionResolver<
    Array<ResolversTypes['ClassEventRegistrations']>,
    'classEventRegistrations',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventRegistrationsArgs, never>
  >;
  classEventRegistrationsAggregate?: SubscriptionResolver<
    ResolversTypes['ClassEventRegistrationsAggregate'],
    'classEventRegistrationsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventRegistrationsAggregateArgs, never>
  >;
  classEventRegistrationsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['ClassEventRegistrations']>,
    'classEventRegistrationsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventRegistrationsByPkArgs, 'id'>
  >;
  classEventRegistrationsStream?: SubscriptionResolver<
    Array<ResolversTypes['ClassEventRegistrations']>,
    'classEventRegistrationsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventRegistrationsStreamArgs, 'batchSize' | 'cursor'>
  >;
  classEvents?: SubscriptionResolver<
    Array<ResolversTypes['ClassEvents']>,
    'classEvents',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventsArgs, never>
  >;
  classEventsAggregate?: SubscriptionResolver<
    ResolversTypes['ClassEventsAggregate'],
    'classEventsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventsAggregateArgs, never>
  >;
  classEventsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['ClassEvents']>,
    'classEventsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventsByPkArgs, 'id'>
  >;
  classEventsStream?: SubscriptionResolver<
    Array<ResolversTypes['ClassEvents']>,
    'classEventsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassEventsStreamArgs, 'batchSize' | 'cursor'>
  >;
  classPlaybackActivity?: SubscriptionResolver<
    Array<ResolversTypes['ClassPlaybackActivity']>,
    'classPlaybackActivity',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassPlaybackActivityArgs, never>
  >;
  classPlaybackActivityAggregate?: SubscriptionResolver<
    ResolversTypes['ClassPlaybackActivityAggregate'],
    'classPlaybackActivityAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassPlaybackActivityAggregateArgs, never>
  >;
  classPlaybackActivityByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['ClassPlaybackActivity']>,
    'classPlaybackActivityByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassPlaybackActivityByPkArgs, 'id'>
  >;
  classPlaybackActivityStream?: SubscriptionResolver<
    Array<ResolversTypes['ClassPlaybackActivity']>,
    'classPlaybackActivityStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassPlaybackActivityStreamArgs, 'batchSize' | 'cursor'>
  >;
  classPlaybackActivityType?: SubscriptionResolver<
    Array<ResolversTypes['ClassPlaybackActivityType']>,
    'classPlaybackActivityType',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassPlaybackActivityTypeArgs, never>
  >;
  classPlaybackActivityTypeAggregate?: SubscriptionResolver<
    ResolversTypes['ClassPlaybackActivityTypeAggregate'],
    'classPlaybackActivityTypeAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassPlaybackActivityTypeAggregateArgs, never>
  >;
  classPlaybackActivityTypeByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['ClassPlaybackActivityType']>,
    'classPlaybackActivityTypeByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassPlaybackActivityTypeByPkArgs, 'value'>
  >;
  classResources?: SubscriptionResolver<
    Array<ResolversTypes['ClassResources']>,
    'classResources',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassResourcesArgs, never>
  >;
  classResourcesAggregate?: SubscriptionResolver<
    ResolversTypes['ClassResourcesAggregate'],
    'classResourcesAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassResourcesAggregateArgs, never>
  >;
  classResourcesByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['ClassResources']>,
    'classResourcesByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassResourcesByPkArgs, 'classId' | 'resourceId'>
  >;
  classes?: SubscriptionResolver<
    Array<ResolversTypes['Classes']>,
    'classes',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassesArgs, never>
  >;
  classesAggregate?: SubscriptionResolver<
    ResolversTypes['ClassesAggregate'],
    'classesAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassesAggregateArgs, never>
  >;
  classesByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Classes']>,
    'classesByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassesByPkArgs, 'id'>
  >;
  classesStream?: SubscriptionResolver<
    Array<ResolversTypes['Classes']>,
    'classesStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassesStreamArgs, 'batchSize' | 'cursor'>
  >;
  classesTagsRel?: SubscriptionResolver<
    Array<ResolversTypes['ClassesTagsRel']>,
    'classesTagsRel',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassesTagsRelArgs, never>
  >;
  classesTagsRelAggregate?: SubscriptionResolver<
    ResolversTypes['ClassesTagsRelAggregate'],
    'classesTagsRelAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassesTagsRelAggregateArgs, never>
  >;
  classesTagsRelByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['ClassesTagsRel']>,
    'classesTagsRelByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassesTagsRelByPkArgs, 'classId' | 'tagId'>
  >;
  classesTagsRelStream?: SubscriptionResolver<
    Array<ResolversTypes['ClassesTagsRel']>,
    'classesTagsRelStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootClassesTagsRelStreamArgs, 'batchSize' | 'cursor'>
  >;
  coaches?: SubscriptionResolver<
    Array<ResolversTypes['Coaches']>,
    'coaches',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCoachesArgs, never>
  >;
  coachesAggregate?: SubscriptionResolver<
    ResolversTypes['CoachesAggregate'],
    'coachesAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCoachesAggregateArgs, never>
  >;
  coachesByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Coaches']>,
    'coachesByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCoachesByPkArgs, 'id'>
  >;
  coachesStream?: SubscriptionResolver<
    Array<ResolversTypes['Coaches']>,
    'coachesStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCoachesStreamArgs, 'batchSize' | 'cursor'>
  >;
  cohortCoaches?: SubscriptionResolver<
    Array<ResolversTypes['CohortCoaches']>,
    'cohortCoaches',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCohortCoachesArgs, never>
  >;
  cohortCoachesAggregate?: SubscriptionResolver<
    ResolversTypes['CohortCoachesAggregate'],
    'cohortCoachesAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCohortCoachesAggregateArgs, never>
  >;
  cohortCoachesByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['CohortCoaches']>,
    'cohortCoachesByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCohortCoachesByPkArgs, 'coachId' | 'cohortId'>
  >;
  cohortProgress?: SubscriptionResolver<
    Array<ResolversTypes['CohortProgress']>,
    'cohortProgress',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCohortProgressArgs, never>
  >;
  cohortProgressAggregate?: SubscriptionResolver<
    ResolversTypes['CohortProgressAggregate'],
    'cohortProgressAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCohortProgressAggregateArgs, never>
  >;
  cohortType?: SubscriptionResolver<
    Array<ResolversTypes['CohortType']>,
    'cohortType',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCohortTypeArgs, never>
  >;
  cohortTypeAggregate?: SubscriptionResolver<
    ResolversTypes['CohortTypeAggregate'],
    'cohortTypeAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCohortTypeAggregateArgs, never>
  >;
  cohortTypeByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['CohortType']>,
    'cohortTypeByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCohortTypeByPkArgs, 'value'>
  >;
  cohortVisibilityEnum?: SubscriptionResolver<
    Array<ResolversTypes['CohortVisibilityEnum']>,
    'cohortVisibilityEnum',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCohortVisibilityEnumArgs, never>
  >;
  cohortVisibilityEnumAggregate?: SubscriptionResolver<
    ResolversTypes['CohortVisibilityEnumAggregate'],
    'cohortVisibilityEnumAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCohortVisibilityEnumAggregateArgs, never>
  >;
  cohortVisibilityEnumByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['CohortVisibilityEnum']>,
    'cohortVisibilityEnumByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCohortVisibilityEnumByPkArgs, 'value'>
  >;
  cohortVisibilityEnumStream?: SubscriptionResolver<
    Array<ResolversTypes['CohortVisibilityEnum']>,
    'cohortVisibilityEnumStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCohortVisibilityEnumStreamArgs, 'batchSize' | 'cursor'>
  >;
  cohorts?: SubscriptionResolver<
    Array<ResolversTypes['Cohorts']>,
    'cohorts',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCohortsArgs, never>
  >;
  cohortsAggregate?: SubscriptionResolver<
    ResolversTypes['CohortsAggregate'],
    'cohortsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCohortsAggregateArgs, never>
  >;
  cohortsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Cohorts']>,
    'cohortsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCohortsByPkArgs, 'id'>
  >;
  cohortsResourcesRel?: SubscriptionResolver<
    Array<ResolversTypes['CohortsResourcesRel']>,
    'cohortsResourcesRel',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCohortsResourcesRelArgs, never>
  >;
  cohortsResourcesRelAggregate?: SubscriptionResolver<
    ResolversTypes['CohortsResourcesRelAggregate'],
    'cohortsResourcesRelAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCohortsResourcesRelAggregateArgs, never>
  >;
  cohortsResourcesRelByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['CohortsResourcesRel']>,
    'cohortsResourcesRelByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCohortsResourcesRelByPkArgs, 'cohortId' | 'resourceId'>
  >;
  cohortsResourcesRelStream?: SubscriptionResolver<
    Array<ResolversTypes['CohortsResourcesRel']>,
    'cohortsResourcesRelStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCohortsResourcesRelStreamArgs, 'batchSize' | 'cursor'>
  >;
  cohortsStream?: SubscriptionResolver<
    Array<ResolversTypes['Cohorts']>,
    'cohortsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCohortsStreamArgs, 'batchSize' | 'cursor'>
  >;
  configurations?: SubscriptionResolver<
    Array<ResolversTypes['Configurations']>,
    'configurations',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootConfigurationsArgs, never>
  >;
  configurationsAggregate?: SubscriptionResolver<
    ResolversTypes['ConfigurationsAggregate'],
    'configurationsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootConfigurationsAggregateArgs, never>
  >;
  configurationsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Configurations']>,
    'configurationsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootConfigurationsByPkArgs, 'key'>
  >;
  configurationsStream?: SubscriptionResolver<
    Array<ResolversTypes['Configurations']>,
    'configurationsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootConfigurationsStreamArgs, 'batchSize' | 'cursor'>
  >;
  courseCertifications?: SubscriptionResolver<
    Array<ResolversTypes['CourseCertifications']>,
    'courseCertifications',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCourseCertificationsArgs, never>
  >;
  courseCertificationsAggregate?: SubscriptionResolver<
    ResolversTypes['CourseCertificationsAggregate'],
    'courseCertificationsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCourseCertificationsAggregateArgs, never>
  >;
  courseCertificationsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['CourseCertifications']>,
    'courseCertificationsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCourseCertificationsByPkArgs, 'id'>
  >;
  courseCertificationsStream?: SubscriptionResolver<
    Array<ResolversTypes['CourseCertifications']>,
    'courseCertificationsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCourseCertificationsStreamArgs, 'batchSize' | 'cursor'>
  >;
  courseCoaches?: SubscriptionResolver<
    Array<ResolversTypes['CourseCoaches']>,
    'courseCoaches',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCourseCoachesArgs, never>
  >;
  courseCoachesAggregate?: SubscriptionResolver<
    ResolversTypes['CourseCoachesAggregate'],
    'courseCoachesAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCourseCoachesAggregateArgs, never>
  >;
  courseCoachesByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['CourseCoaches']>,
    'courseCoachesByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCourseCoachesByPkArgs, 'coachId' | 'courseId'>
  >;
  courseEnrollmentStatus?: SubscriptionResolver<
    Array<ResolversTypes['CourseEnrollmentStatus']>,
    'courseEnrollmentStatus',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCourseEnrollmentStatusArgs, never>
  >;
  courseEnrollmentStatusAggregate?: SubscriptionResolver<
    ResolversTypes['CourseEnrollmentStatusAggregate'],
    'courseEnrollmentStatusAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCourseEnrollmentStatusAggregateArgs, never>
  >;
  courseEnrollmentStatusByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['CourseEnrollmentStatus']>,
    'courseEnrollmentStatusByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCourseEnrollmentStatusByPkArgs, 'value'>
  >;
  coursePublishStatus?: SubscriptionResolver<
    Array<ResolversTypes['CoursePublishStatus']>,
    'coursePublishStatus',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCoursePublishStatusArgs, never>
  >;
  coursePublishStatusAggregate?: SubscriptionResolver<
    ResolversTypes['CoursePublishStatusAggregate'],
    'coursePublishStatusAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCoursePublishStatusAggregateArgs, never>
  >;
  coursePublishStatusByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['CoursePublishStatus']>,
    'coursePublishStatusByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCoursePublishStatusByPkArgs, 'value'>
  >;
  courseType?: SubscriptionResolver<
    Array<ResolversTypes['CourseType']>,
    'courseType',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCourseTypeArgs, never>
  >;
  courseTypeAggregate?: SubscriptionResolver<
    ResolversTypes['CourseTypeAggregate'],
    'courseTypeAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCourseTypeAggregateArgs, never>
  >;
  courseTypeByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['CourseType']>,
    'courseTypeByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCourseTypeByPkArgs, 'value'>
  >;
  courseWaitlist?: SubscriptionResolver<
    Array<ResolversTypes['CourseWaitlist']>,
    'courseWaitlist',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCourseWaitlistArgs, never>
  >;
  courseWaitlistAggregate?: SubscriptionResolver<
    ResolversTypes['CourseWaitlistAggregate'],
    'courseWaitlistAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCourseWaitlistAggregateArgs, never>
  >;
  courseWaitlistByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['CourseWaitlist']>,
    'courseWaitlistByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCourseWaitlistByPkArgs, 'id'>
  >;
  courseWaitlistDesiredUrgencyType?: SubscriptionResolver<
    Array<ResolversTypes['CourseWaitlistDesiredUrgencyType']>,
    'courseWaitlistDesiredUrgencyType',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCourseWaitlistDesiredUrgencyTypeArgs, never>
  >;
  courseWaitlistDesiredUrgencyTypeAggregate?: SubscriptionResolver<
    ResolversTypes['CourseWaitlistDesiredUrgencyTypeAggregate'],
    'courseWaitlistDesiredUrgencyTypeAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCourseWaitlistDesiredUrgencyTypeAggregateArgs, never>
  >;
  courseWaitlistDesiredUrgencyTypeByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['CourseWaitlistDesiredUrgencyType']>,
    'courseWaitlistDesiredUrgencyTypeByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCourseWaitlistDesiredUrgencyTypeByPkArgs, 'value'>
  >;
  courseWaitlistDesiredUrgencyTypeStream?: SubscriptionResolver<
    Array<ResolversTypes['CourseWaitlistDesiredUrgencyType']>,
    'courseWaitlistDesiredUrgencyTypeStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootCourseWaitlistDesiredUrgencyTypeStreamArgs,
      'batchSize' | 'cursor'
    >
  >;
  courseWaitlistStream?: SubscriptionResolver<
    Array<ResolversTypes['CourseWaitlist']>,
    'courseWaitlistStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCourseWaitlistStreamArgs, 'batchSize' | 'cursor'>
  >;
  courses?: SubscriptionResolver<
    Array<ResolversTypes['Courses']>,
    'courses',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCoursesArgs, never>
  >;
  coursesAggregate?: SubscriptionResolver<
    ResolversTypes['CoursesAggregate'],
    'coursesAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCoursesAggregateArgs, never>
  >;
  coursesByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Courses']>,
    'coursesByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCoursesByPkArgs, 'id'>
  >;
  coursesByPlan?: SubscriptionResolver<
    Array<ResolversTypes['CoursesByPlan']>,
    'coursesByPlan',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCoursesByPlanArgs, never>
  >;
  coursesByPlanAggregate?: SubscriptionResolver<
    ResolversTypes['CoursesByPlanAggregate'],
    'coursesByPlanAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCoursesByPlanAggregateArgs, never>
  >;
  coursesByPlanStream?: SubscriptionResolver<
    Array<ResolversTypes['CoursesByPlan']>,
    'coursesByPlanStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCoursesByPlanStreamArgs, 'batchSize' | 'cursor'>
  >;
  coursesResourcesRel?: SubscriptionResolver<
    Array<ResolversTypes['CoursesResourcesRel']>,
    'coursesResourcesRel',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCoursesResourcesRelArgs, never>
  >;
  coursesResourcesRelAggregate?: SubscriptionResolver<
    ResolversTypes['CoursesResourcesRelAggregate'],
    'coursesResourcesRelAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCoursesResourcesRelAggregateArgs, never>
  >;
  coursesResourcesRelByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['CoursesResourcesRel']>,
    'coursesResourcesRelByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCoursesResourcesRelByPkArgs, 'courseId' | 'resourceId'>
  >;
  coursesStream?: SubscriptionResolver<
    Array<ResolversTypes['Courses']>,
    'coursesStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCoursesStreamArgs, 'batchSize' | 'cursor'>
  >;
  coursesTagsRel?: SubscriptionResolver<
    Array<ResolversTypes['CoursesTagsRel']>,
    'coursesTagsRel',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCoursesTagsRelArgs, never>
  >;
  coursesTagsRelAggregate?: SubscriptionResolver<
    ResolversTypes['CoursesTagsRelAggregate'],
    'coursesTagsRelAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCoursesTagsRelAggregateArgs, never>
  >;
  coursesTagsRelByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['CoursesTagsRel']>,
    'coursesTagsRelByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCoursesTagsRelByPkArgs, 'courseId' | 'tagId'>
  >;
  coursesTagsRelStream?: SubscriptionResolver<
    Array<ResolversTypes['CoursesTagsRel']>,
    'coursesTagsRelStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCoursesTagsRelStreamArgs, 'batchSize' | 'cursor'>
  >;
  creditUsage?: SubscriptionResolver<
    Array<ResolversTypes['creditUsage']>,
    'creditUsage',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCreditUsageArgs, never>
  >;
  creditUsageByUser?: SubscriptionResolver<
    Array<ResolversTypes['creditUsageByUser']>,
    'creditUsageByUser',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCreditUsageByUserArgs, never>
  >;
  emailQueue?: SubscriptionResolver<
    Array<ResolversTypes['EmailQueue']>,
    'emailQueue',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootEmailQueueArgs, never>
  >;
  emailQueueAggregate?: SubscriptionResolver<
    ResolversTypes['EmailQueueAggregate'],
    'emailQueueAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootEmailQueueAggregateArgs, never>
  >;
  emailQueueByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['EmailQueue']>,
    'emailQueueByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootEmailQueueByPkArgs, 'id'>
  >;
  emailQueueStream?: SubscriptionResolver<
    Array<ResolversTypes['EmailQueue']>,
    'emailQueueStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootEmailQueueStreamArgs, 'batchSize' | 'cursor'>
  >;
  enrollmentWaitlists?: SubscriptionResolver<
    Array<ResolversTypes['EnrollmentWaitlists']>,
    'enrollmentWaitlists',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootEnrollmentWaitlistsArgs, never>
  >;
  enrollmentWaitlistsAggregate?: SubscriptionResolver<
    ResolversTypes['EnrollmentWaitlistsAggregate'],
    'enrollmentWaitlistsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootEnrollmentWaitlistsAggregateArgs, never>
  >;
  enrollmentWaitlistsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['EnrollmentWaitlists']>,
    'enrollmentWaitlistsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootEnrollmentWaitlistsByPkArgs, 'id'>
  >;
  enrollmentWaitlistsStream?: SubscriptionResolver<
    Array<ResolversTypes['EnrollmentWaitlists']>,
    'enrollmentWaitlistsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootEnrollmentWaitlistsStreamArgs, 'batchSize' | 'cursor'>
  >;
  enrollments?: SubscriptionResolver<
    Array<ResolversTypes['Enrollments']>,
    'enrollments',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootEnrollmentsArgs, never>
  >;
  enrollmentsAggregate?: SubscriptionResolver<
    ResolversTypes['EnrollmentsAggregate'],
    'enrollmentsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootEnrollmentsAggregateArgs, never>
  >;
  enrollmentsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Enrollments']>,
    'enrollmentsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootEnrollmentsByPkArgs, 'id'>
  >;
  enrollmentsStream?: SubscriptionResolver<
    Array<ResolversTypes['Enrollments']>,
    'enrollmentsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootEnrollmentsStreamArgs, 'batchSize' | 'cursor'>
  >;
  fusionauthExternalIdentifiers?: SubscriptionResolver<
    Array<ResolversTypes['FusionauthExternalIdentifiers']>,
    'fusionauthExternalIdentifiers',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootFusionauthExternalIdentifiersArgs, never>
  >;
  fusionauthExternalIdentifiersAggregate?: SubscriptionResolver<
    ResolversTypes['FusionauthExternalIdentifiersAggregate'],
    'fusionauthExternalIdentifiersAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootFusionauthExternalIdentifiersAggregateArgs, never>
  >;
  fusionauthExternalIdentifiersByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['FusionauthExternalIdentifiers']>,
    'fusionauthExternalIdentifiersByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootFusionauthExternalIdentifiersByPkArgs, 'id'>
  >;
  fusionauthExternalIdentifiersStream?: SubscriptionResolver<
    Array<ResolversTypes['FusionauthExternalIdentifiers']>,
    'fusionauthExternalIdentifiersStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootFusionauthExternalIdentifiersStreamArgs, 'batchSize' | 'cursor'>
  >;
  fusionauthUserRegistrations?: SubscriptionResolver<
    Array<ResolversTypes['FusionauthUserRegistrations']>,
    'fusionauthUserRegistrations',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootFusionauthUserRegistrationsArgs, never>
  >;
  fusionauthUserRegistrationsAggregate?: SubscriptionResolver<
    ResolversTypes['FusionauthUserRegistrationsAggregate'],
    'fusionauthUserRegistrationsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootFusionauthUserRegistrationsAggregateArgs, never>
  >;
  fusionauthUserRegistrationsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['FusionauthUserRegistrations']>,
    'fusionauthUserRegistrationsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootFusionauthUserRegistrationsByPkArgs, 'id'>
  >;
  fusionauthUserRegistrationsStream?: SubscriptionResolver<
    Array<ResolversTypes['FusionauthUserRegistrations']>,
    'fusionauthUserRegistrationsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootFusionauthUserRegistrationsStreamArgs, 'batchSize' | 'cursor'>
  >;
  getAssessmentQuestions?: SubscriptionResolver<
    Array<ResolversTypes['ImpactAssessmentQuestions']>,
    'getAssessmentQuestions',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootGetAssessmentQuestionsArgs, 'args'>
  >;
  getAssessmentQuestionsAggregate?: SubscriptionResolver<
    ResolversTypes['ImpactAssessmentQuestionsAggregate'],
    'getAssessmentQuestionsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootGetAssessmentQuestionsAggregateArgs, 'args'>
  >;
  getTeamMembers?: SubscriptionResolver<
    Array<ResolversTypes['teamMember']>,
    'getTeamMembers',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootGetTeamMembersArgs, 'args'>
  >;
  getTeamMembersNew?: SubscriptionResolver<
    Array<ResolversTypes['getTeamMembersNew']>,
    'getTeamMembersNew',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootGetTeamMembersNewArgs, never>
  >;
  identities?: SubscriptionResolver<
    Array<ResolversTypes['Identities']>,
    'identities',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootIdentitiesArgs, never>
  >;
  identitiesAggregate?: SubscriptionResolver<
    ResolversTypes['IdentitiesAggregate'],
    'identitiesAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootIdentitiesAggregateArgs, never>
  >;
  identitiesByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Identities']>,
    'identitiesByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootIdentitiesByPkArgs, 'id'>
  >;
  identityProviderLinks?: SubscriptionResolver<
    Array<ResolversTypes['IdentityProviderLinks']>,
    'identityProviderLinks',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootIdentityProviderLinksArgs, never>
  >;
  identityProviderLinksAggregate?: SubscriptionResolver<
    ResolversTypes['IdentityProviderLinksAggregate'],
    'identityProviderLinksAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootIdentityProviderLinksAggregateArgs, never>
  >;
  identityProviders?: SubscriptionResolver<
    Array<ResolversTypes['IdentityProviders']>,
    'identityProviders',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootIdentityProvidersArgs, never>
  >;
  identityProvidersAggregate?: SubscriptionResolver<
    ResolversTypes['IdentityProvidersAggregate'],
    'identityProvidersAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootIdentityProvidersAggregateArgs, never>
  >;
  identityProvidersByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['IdentityProviders']>,
    'identityProvidersByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootIdentityProvidersByPkArgs, 'id'>
  >;
  impactAssessmentAttempts?: SubscriptionResolver<
    Array<ResolversTypes['ImpactAssessmentAttempts']>,
    'impactAssessmentAttempts',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootImpactAssessmentAttemptsArgs, never>
  >;
  impactAssessmentAttemptsAggregate?: SubscriptionResolver<
    ResolversTypes['ImpactAssessmentAttemptsAggregate'],
    'impactAssessmentAttemptsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootImpactAssessmentAttemptsAggregateArgs, never>
  >;
  impactAssessmentAttemptsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['ImpactAssessmentAttempts']>,
    'impactAssessmentAttemptsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootImpactAssessmentAttemptsByPkArgs, 'id'>
  >;
  impactAssessmentAttemptsStream?: SubscriptionResolver<
    Array<ResolversTypes['ImpactAssessmentAttempts']>,
    'impactAssessmentAttemptsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootImpactAssessmentAttemptsStreamArgs, 'batchSize' | 'cursor'>
  >;
  impactAssessmentQuestions?: SubscriptionResolver<
    Array<ResolversTypes['ImpactAssessmentQuestions']>,
    'impactAssessmentQuestions',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootImpactAssessmentQuestionsArgs, never>
  >;
  impactAssessmentQuestionsAggregate?: SubscriptionResolver<
    ResolversTypes['ImpactAssessmentQuestionsAggregate'],
    'impactAssessmentQuestionsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootImpactAssessmentQuestionsAggregateArgs, never>
  >;
  impactAssessmentQuestionsStream?: SubscriptionResolver<
    Array<ResolversTypes['ImpactAssessmentQuestions']>,
    'impactAssessmentQuestionsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootImpactAssessmentQuestionsStreamArgs, 'batchSize' | 'cursor'>
  >;
  impactAssessments?: SubscriptionResolver<
    Array<ResolversTypes['ImpactAssessments']>,
    'impactAssessments',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootImpactAssessmentsArgs, never>
  >;
  impactAssessmentsAggregate?: SubscriptionResolver<
    ResolversTypes['ImpactAssessmentsAggregate'],
    'impactAssessmentsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootImpactAssessmentsAggregateArgs, never>
  >;
  impactAssessmentsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['ImpactAssessments']>,
    'impactAssessmentsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootImpactAssessmentsByPkArgs, 'id'>
  >;
  impactAssessmentsStream?: SubscriptionResolver<
    Array<ResolversTypes['ImpactAssessments']>,
    'impactAssessmentsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootImpactAssessmentsStreamArgs, 'batchSize' | 'cursor'>
  >;
  journeyEnrollments?: SubscriptionResolver<
    Array<ResolversTypes['JourneyEnrollments']>,
    'journeyEnrollments',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootJourneyEnrollmentsArgs, never>
  >;
  journeyEnrollmentsAggregate?: SubscriptionResolver<
    ResolversTypes['JourneyEnrollmentsAggregate'],
    'journeyEnrollmentsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootJourneyEnrollmentsAggregateArgs, never>
  >;
  journeyEnrollmentsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['JourneyEnrollments']>,
    'journeyEnrollmentsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootJourneyEnrollmentsByPkArgs, 'id'>
  >;
  journeyEnrollmentsStream?: SubscriptionResolver<
    Array<ResolversTypes['JourneyEnrollments']>,
    'journeyEnrollmentsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootJourneyEnrollmentsStreamArgs, 'batchSize' | 'cursor'>
  >;
  journeys?: SubscriptionResolver<
    Array<ResolversTypes['Journeys']>,
    'journeys',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootJourneysArgs, never>
  >;
  journeysAggregate?: SubscriptionResolver<
    ResolversTypes['JourneysAggregate'],
    'journeysAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootJourneysAggregateArgs, never>
  >;
  journeysByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Journeys']>,
    'journeysByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootJourneysByPkArgs, 'id'>
  >;
  journeysStream?: SubscriptionResolver<
    Array<ResolversTypes['Journeys']>,
    'journeysStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootJourneysStreamArgs, 'batchSize' | 'cursor'>
  >;
  ledger?: SubscriptionResolver<
    Array<ResolversTypes['Ledger']>,
    'ledger',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLedgerArgs, never>
  >;
  ledgerAggregate?: SubscriptionResolver<
    ResolversTypes['LedgerAggregate'],
    'ledgerAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLedgerAggregateArgs, never>
  >;
  ledgerByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Ledger']>,
    'ledgerByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLedgerByPkArgs, 'id'>
  >;
  ledgerProductEnum?: SubscriptionResolver<
    Array<ResolversTypes['LedgerProductEnum']>,
    'ledgerProductEnum',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLedgerProductEnumArgs, never>
  >;
  ledgerProductEnumAggregate?: SubscriptionResolver<
    ResolversTypes['LedgerProductEnumAggregate'],
    'ledgerProductEnumAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLedgerProductEnumAggregateArgs, never>
  >;
  ledgerProductEnumByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['LedgerProductEnum']>,
    'ledgerProductEnumByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLedgerProductEnumByPkArgs, 'value'>
  >;
  ledgerProductEnumStream?: SubscriptionResolver<
    Array<ResolversTypes['LedgerProductEnum']>,
    'ledgerProductEnumStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLedgerProductEnumStreamArgs, 'batchSize' | 'cursor'>
  >;
  ledgerStream?: SubscriptionResolver<
    Array<ResolversTypes['Ledger']>,
    'ledgerStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLedgerStreamArgs, 'batchSize' | 'cursor'>
  >;
  ledgerTransactionTypeEnum?: SubscriptionResolver<
    Array<ResolversTypes['LedgerTransactionTypeEnum']>,
    'ledgerTransactionTypeEnum',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLedgerTransactionTypeEnumArgs, never>
  >;
  ledgerTransactionTypeEnumAggregate?: SubscriptionResolver<
    ResolversTypes['LedgerTransactionTypeEnumAggregate'],
    'ledgerTransactionTypeEnumAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLedgerTransactionTypeEnumAggregateArgs, never>
  >;
  ledgerTransactionTypeEnumByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['LedgerTransactionTypeEnum']>,
    'ledgerTransactionTypeEnumByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLedgerTransactionTypeEnumByPkArgs, 'value'>
  >;
  ledgerTransactionTypeEnumStream?: SubscriptionResolver<
    Array<ResolversTypes['LedgerTransactionTypeEnum']>,
    'ledgerTransactionTypeEnumStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLedgerTransactionTypeEnumStreamArgs, 'batchSize' | 'cursor'>
  >;
  lightningSession?: SubscriptionResolver<
    Array<ResolversTypes['LightningSession']>,
    'lightningSession',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLightningSessionArgs, never>
  >;
  lightningSessionAggregate?: SubscriptionResolver<
    ResolversTypes['LightningSessionAggregate'],
    'lightningSessionAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLightningSessionAggregateArgs, never>
  >;
  lightningSessionByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['LightningSession']>,
    'lightningSessionByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLightningSessionByPkArgs, 'id'>
  >;
  lightningSessionStream?: SubscriptionResolver<
    Array<ResolversTypes['LightningSession']>,
    'lightningSessionStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLightningSessionStreamArgs, 'batchSize' | 'cursor'>
  >;
  lightningSessionSubmissions?: SubscriptionResolver<
    Array<ResolversTypes['LightningSessionSubmissions']>,
    'lightningSessionSubmissions',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLightningSessionSubmissionsArgs, never>
  >;
  lightningSessionSubmissionsAggregate?: SubscriptionResolver<
    ResolversTypes['LightningSessionSubmissionsAggregate'],
    'lightningSessionSubmissionsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLightningSessionSubmissionsAggregateArgs, never>
  >;
  lightningSessionSubmissionsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['LightningSessionSubmissions']>,
    'lightningSessionSubmissionsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLightningSessionSubmissionsByPkArgs, 'id'>
  >;
  lightningSessionSubmissionsStatus?: SubscriptionResolver<
    Array<ResolversTypes['lightningSessionSubmissionsStatus']>,
    'lightningSessionSubmissionsStatus',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLightningSessionSubmissionsStatusArgs, never>
  >;
  lightningSessionSubmissionsStatusAggregate?: SubscriptionResolver<
    ResolversTypes['lightningSessionSubmissionsStatusAggregate'],
    'lightningSessionSubmissionsStatusAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLightningSessionSubmissionsStatusAggregateArgs, never>
  >;
  lightningSessionSubmissionsStatusByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['lightningSessionSubmissionsStatus']>,
    'lightningSessionSubmissionsStatusByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLightningSessionSubmissionsStatusByPkArgs, 'value'>
  >;
  lightningSessionSubmissionsStatusStream?: SubscriptionResolver<
    Array<ResolversTypes['lightningSessionSubmissionsStatus']>,
    'lightningSessionSubmissionsStatusStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootLightningSessionSubmissionsStatusStreamArgs,
      'batchSize' | 'cursor'
    >
  >;
  lightningSessionSubmissionsStream?: SubscriptionResolver<
    Array<ResolversTypes['LightningSessionSubmissions']>,
    'lightningSessionSubmissionsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLightningSessionSubmissionsStreamArgs, 'batchSize' | 'cursor'>
  >;
  lightningSessionSubmissionsUsersRel?: SubscriptionResolver<
    Array<ResolversTypes['LightningSessionSubmissionsUsersRel']>,
    'lightningSessionSubmissionsUsersRel',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLightningSessionSubmissionsUsersRelArgs, never>
  >;
  lightningSessionSubmissionsUsersRelAggregate?: SubscriptionResolver<
    ResolversTypes['LightningSessionSubmissionsUsersRelAggregate'],
    'lightningSessionSubmissionsUsersRelAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLightningSessionSubmissionsUsersRelAggregateArgs, never>
  >;
  lightningSessionSubmissionsUsersRelByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['LightningSessionSubmissionsUsersRel']>,
    'lightningSessionSubmissionsUsersRelByPk',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootLightningSessionSubmissionsUsersRelByPkArgs,
      'lightningSessionSubmissionId' | 'userId'
    >
  >;
  lightningSessionSubmissionsUsersRelStream?: SubscriptionResolver<
    Array<ResolversTypes['LightningSessionSubmissionsUsersRel']>,
    'lightningSessionSubmissionsUsersRelStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootLightningSessionSubmissionsUsersRelStreamArgs,
      'batchSize' | 'cursor'
    >
  >;
  liveClasses?: SubscriptionResolver<
    Array<ResolversTypes['LiveClasses']>,
    'liveClasses',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLiveClassesArgs, never>
  >;
  liveClassesAggregate?: SubscriptionResolver<
    ResolversTypes['LiveClassesAggregate'],
    'liveClassesAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLiveClassesAggregateArgs, never>
  >;
  liveClassesStream?: SubscriptionResolver<
    Array<ResolversTypes['LiveClasses']>,
    'liveClassesStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootLiveClassesStreamArgs, 'batchSize' | 'cursor'>
  >;
  managerSessionEmails?: SubscriptionResolver<
    Array<ResolversTypes['ManagerSessionEmails']>,
    'managerSessionEmails',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootManagerSessionEmailsArgs, never>
  >;
  managerSessionEmailsAggregate?: SubscriptionResolver<
    ResolversTypes['ManagerSessionEmailsAggregate'],
    'managerSessionEmailsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootManagerSessionEmailsAggregateArgs, never>
  >;
  managerSessionEmailsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['ManagerSessionEmails']>,
    'managerSessionEmailsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootManagerSessionEmailsByPkArgs, 'managerCohortId'>
  >;
  managerSessionEmailsStream?: SubscriptionResolver<
    Array<ResolversTypes['ManagerSessionEmails']>,
    'managerSessionEmailsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootManagerSessionEmailsStreamArgs, 'batchSize' | 'cursor'>
  >;
  myCohortLearningStatus?: SubscriptionResolver<
    Array<ResolversTypes['MyCohortLearningStatus']>,
    'myCohortLearningStatus',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootMyCohortLearningStatusArgs, never>
  >;
  myCohortLearningStatusAggregate?: SubscriptionResolver<
    ResolversTypes['MyCohortLearningStatusAggregate'],
    'myCohortLearningStatusAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootMyCohortLearningStatusAggregateArgs, never>
  >;
  myCohortLearningStatusStream?: SubscriptionResolver<
    Array<ResolversTypes['MyCohortLearningStatus']>,
    'myCohortLearningStatusStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootMyCohortLearningStatusStreamArgs, 'batchSize' | 'cursor'>
  >;
  myLearnerHomeCourses?: SubscriptionResolver<
    Array<ResolversTypes['MyLearnerHomeCourses']>,
    'myLearnerHomeCourses',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootMyLearnerHomeCoursesArgs, never>
  >;
  myLearnerHomeCoursesAggregate?: SubscriptionResolver<
    ResolversTypes['MyLearnerHomeCoursesAggregate'],
    'myLearnerHomeCoursesAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootMyLearnerHomeCoursesAggregateArgs, never>
  >;
  myLearnerHomeCoursesStream?: SubscriptionResolver<
    Array<ResolversTypes['MyLearnerHomeCourses']>,
    'myLearnerHomeCoursesStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootMyLearnerHomeCoursesStreamArgs, 'batchSize' | 'cursor'>
  >;
  myLearnerHomeLiveCoaching?: SubscriptionResolver<
    Array<ResolversTypes['MyLearnerHomeLiveCoaching']>,
    'myLearnerHomeLiveCoaching',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootMyLearnerHomeLiveCoachingArgs, never>
  >;
  myLearnerHomeLiveCoachingAggregate?: SubscriptionResolver<
    ResolversTypes['MyLearnerHomeLiveCoachingAggregate'],
    'myLearnerHomeLiveCoachingAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootMyLearnerHomeLiveCoachingAggregateArgs, never>
  >;
  myLearnerHomeLiveCoachingStream?: SubscriptionResolver<
    Array<ResolversTypes['MyLearnerHomeLiveCoaching']>,
    'myLearnerHomeLiveCoachingStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootMyLearnerHomeLiveCoachingStreamArgs, 'batchSize' | 'cursor'>
  >;
  myLightningSessionCohortsLearningStatus?: SubscriptionResolver<
    Array<ResolversTypes['MyLightningSessionCohortsLearningStatus']>,
    'myLightningSessionCohortsLearningStatus',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootMyLightningSessionCohortsLearningStatusArgs, never>
  >;
  myLightningSessionCohortsLearningStatusAggregate?: SubscriptionResolver<
    ResolversTypes['MyLightningSessionCohortsLearningStatusAggregate'],
    'myLightningSessionCohortsLearningStatusAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootMyLightningSessionCohortsLearningStatusAggregateArgs, never>
  >;
  myLightningSessionCohortsLearningStatusStream?: SubscriptionResolver<
    Array<ResolversTypes['MyLightningSessionCohortsLearningStatus']>,
    'myLightningSessionCohortsLearningStatusStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootMyLightningSessionCohortsLearningStatusStreamArgs,
      'batchSize' | 'cursor'
    >
  >;
  myTeamFlatTree?: SubscriptionResolver<
    Array<ResolversTypes['team_flat_tree_node']>,
    'myTeamFlatTree',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootMyTeamFlatTreeArgs, 'args'>
  >;
  myTraditionalCohortsLearningStatus?: SubscriptionResolver<
    Array<ResolversTypes['MyTraditionalCohortsLearningStatus']>,
    'myTraditionalCohortsLearningStatus',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootMyTraditionalCohortsLearningStatusArgs, never>
  >;
  myTraditionalCohortsLearningStatusAggregate?: SubscriptionResolver<
    ResolversTypes['MyTraditionalCohortsLearningStatusAggregate'],
    'myTraditionalCohortsLearningStatusAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootMyTraditionalCohortsLearningStatusAggregateArgs, never>
  >;
  myTraditionalCohortsLearningStatusStream?: SubscriptionResolver<
    Array<ResolversTypes['MyTraditionalCohortsLearningStatus']>,
    'myTraditionalCohortsLearningStatusStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootMyTraditionalCohortsLearningStatusStreamArgs,
      'batchSize' | 'cursor'
    >
  >;
  my_learner_task_native_query?: SubscriptionResolver<
    Array<ResolversTypes['learner_task']>,
    'my_learner_task_native_query',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootMy_Learner_Task_Native_QueryArgs, 'args'>
  >;
  nonceAccessToken?: SubscriptionResolver<
    Array<ResolversTypes['NonceAccessToken']>,
    'nonceAccessToken',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootNonceAccessTokenArgs, never>
  >;
  nonceAccessTokenAggregate?: SubscriptionResolver<
    ResolversTypes['NonceAccessTokenAggregate'],
    'nonceAccessTokenAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootNonceAccessTokenAggregateArgs, never>
  >;
  nonceAccessTokenByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['NonceAccessToken']>,
    'nonceAccessTokenByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootNonceAccessTokenByPkArgs, 'id'>
  >;
  nonceAccessTokenStream?: SubscriptionResolver<
    Array<ResolversTypes['NonceAccessToken']>,
    'nonceAccessTokenStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootNonceAccessTokenStreamArgs, 'batchSize' | 'cursor'>
  >;
  organizationUsers?: SubscriptionResolver<
    Array<ResolversTypes['OrganizationUsers']>,
    'organizationUsers',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootOrganizationUsersArgs, never>
  >;
  organizationUsersAggregate?: SubscriptionResolver<
    ResolversTypes['OrganizationUsersAggregate'],
    'organizationUsersAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootOrganizationUsersAggregateArgs, never>
  >;
  organizationUsersByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['OrganizationUsers']>,
    'organizationUsersByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootOrganizationUsersByPkArgs, 'id'>
  >;
  organizations?: SubscriptionResolver<
    Array<ResolversTypes['Organizations']>,
    'organizations',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootOrganizationsArgs, never>
  >;
  organizationsAggregate?: SubscriptionResolver<
    ResolversTypes['OrganizationsAggregate'],
    'organizationsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootOrganizationsAggregateArgs, never>
  >;
  organizationsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Organizations']>,
    'organizationsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootOrganizationsByPkArgs, 'id'>
  >;
  organizationsMeta?: SubscriptionResolver<
    Array<ResolversTypes['OrganizationsMeta']>,
    'organizationsMeta',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootOrganizationsMetaArgs, never>
  >;
  organizationsMetaAggregate?: SubscriptionResolver<
    ResolversTypes['OrganizationsMetaAggregate'],
    'organizationsMetaAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootOrganizationsMetaAggregateArgs, never>
  >;
  organizationsMetaByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['OrganizationsMeta']>,
    'organizationsMetaByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootOrganizationsMetaByPkArgs, 'organizationId'>
  >;
  organizationsMetaStream?: SubscriptionResolver<
    Array<ResolversTypes['OrganizationsMeta']>,
    'organizationsMetaStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootOrganizationsMetaStreamArgs, 'batchSize' | 'cursor'>
  >;
  planSubscriptions?: SubscriptionResolver<
    Array<ResolversTypes['PlanSubscriptions']>,
    'planSubscriptions',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootPlanSubscriptionsArgs, never>
  >;
  planSubscriptionsAggregate?: SubscriptionResolver<
    ResolversTypes['PlanSubscriptionsAggregate'],
    'planSubscriptionsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootPlanSubscriptionsAggregateArgs, never>
  >;
  planSubscriptionsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['PlanSubscriptions']>,
    'planSubscriptionsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootPlanSubscriptionsByPkArgs, 'id'>
  >;
  planSubscriptionsStream?: SubscriptionResolver<
    Array<ResolversTypes['PlanSubscriptions']>,
    'planSubscriptionsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootPlanSubscriptionsStreamArgs, 'batchSize' | 'cursor'>
  >;
  plans?: SubscriptionResolver<
    Array<ResolversTypes['Plans']>,
    'plans',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootPlansArgs, never>
  >;
  plansAggregate?: SubscriptionResolver<
    ResolversTypes['PlansAggregate'],
    'plansAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootPlansAggregateArgs, never>
  >;
  plansByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Plans']>,
    'plansByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootPlansByPkArgs, 'id'>
  >;
  plansStream?: SubscriptionResolver<
    Array<ResolversTypes['Plans']>,
    'plansStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootPlansStreamArgs, 'batchSize' | 'cursor'>
  >;
  playExecution?: SubscriptionResolver<
    Array<ResolversTypes['PlayExecution']>,
    'playExecution',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootPlayExecutionArgs, never>
  >;
  playExecutionAggregate?: SubscriptionResolver<
    ResolversTypes['PlayExecutionAggregate'],
    'playExecutionAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootPlayExecutionAggregateArgs, never>
  >;
  playExecutionByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['PlayExecution']>,
    'playExecutionByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootPlayExecutionByPkArgs, 'id'>
  >;
  programActionsTaken?: SubscriptionResolver<
    Array<ResolversTypes['ProgramActionsTaken']>,
    'programActionsTaken',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootProgramActionsTakenArgs, never>
  >;
  programActionsTakenAggregate?: SubscriptionResolver<
    ResolversTypes['ProgramActionsTakenAggregate'],
    'programActionsTakenAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootProgramActionsTakenAggregateArgs, never>
  >;
  programActionsTakenStream?: SubscriptionResolver<
    Array<ResolversTypes['ProgramActionsTaken']>,
    'programActionsTakenStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootProgramActionsTakenStreamArgs, 'batchSize' | 'cursor'>
  >;
  programCoursesShown?: SubscriptionResolver<
    Array<ResolversTypes['ProgramCoursesShown']>,
    'programCoursesShown',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootProgramCoursesShownArgs, never>
  >;
  programCoursesShownAggregate?: SubscriptionResolver<
    ResolversTypes['ProgramCoursesShownAggregate'],
    'programCoursesShownAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootProgramCoursesShownAggregateArgs, never>
  >;
  programCoursesShownByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['ProgramCoursesShown']>,
    'programCoursesShownByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootProgramCoursesShownByPkArgs, 'courseId' | 'programId'>
  >;
  programCoursesShownStream?: SubscriptionResolver<
    Array<ResolversTypes['ProgramCoursesShown']>,
    'programCoursesShownStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootProgramCoursesShownStreamArgs, 'batchSize' | 'cursor'>
  >;
  programTags?: SubscriptionResolver<
    Array<ResolversTypes['ProgramTags']>,
    'programTags',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootProgramTagsArgs, never>
  >;
  programTagsAggregate?: SubscriptionResolver<
    ResolversTypes['ProgramTagsAggregate'],
    'programTagsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootProgramTagsAggregateArgs, never>
  >;
  programTagsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['ProgramTags']>,
    'programTagsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootProgramTagsByPkArgs, 'programId' | 'tagId'>
  >;
  programTagsStream?: SubscriptionResolver<
    Array<ResolversTypes['ProgramTags']>,
    'programTagsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootProgramTagsStreamArgs, 'batchSize' | 'cursor'>
  >;
  programUsersSelected?: SubscriptionResolver<
    Array<ResolversTypes['ProgramUsersSelected']>,
    'programUsersSelected',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootProgramUsersSelectedArgs, never>
  >;
  programUsersSelectedAggregate?: SubscriptionResolver<
    ResolversTypes['ProgramUsersSelectedAggregate'],
    'programUsersSelectedAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootProgramUsersSelectedAggregateArgs, never>
  >;
  programUsersSelectedByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['ProgramUsersSelected']>,
    'programUsersSelectedByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootProgramUsersSelectedByPkArgs, 'programId' | 'userId'>
  >;
  programUsersSelectedStream?: SubscriptionResolver<
    Array<ResolversTypes['ProgramUsersSelected']>,
    'programUsersSelectedStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootProgramUsersSelectedStreamArgs, 'batchSize' | 'cursor'>
  >;
  programs?: SubscriptionResolver<
    Array<ResolversTypes['Programs']>,
    'programs',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootProgramsArgs, never>
  >;
  programsAggregate?: SubscriptionResolver<
    ResolversTypes['ProgramsAggregate'],
    'programsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootProgramsAggregateArgs, never>
  >;
  programsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Programs']>,
    'programsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootProgramsByPkArgs, 'id'>
  >;
  programsStream?: SubscriptionResolver<
    Array<ResolversTypes['Programs']>,
    'programsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootProgramsStreamArgs, 'batchSize' | 'cursor'>
  >;
  questionType?: SubscriptionResolver<
    Array<ResolversTypes['QuestionType']>,
    'questionType',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootQuestionTypeArgs, never>
  >;
  questionTypeAggregate?: SubscriptionResolver<
    ResolversTypes['QuestionTypeAggregate'],
    'questionTypeAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootQuestionTypeAggregateArgs, never>
  >;
  questionTypeByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['QuestionType']>,
    'questionTypeByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootQuestionTypeByPkArgs, 'value'>
  >;
  recordingActivity?: SubscriptionResolver<
    Array<ResolversTypes['RecordingActivity']>,
    'recordingActivity',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootRecordingActivityArgs, never>
  >;
  recordingActivityAggregate?: SubscriptionResolver<
    ResolversTypes['RecordingActivityAggregate'],
    'recordingActivityAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootRecordingActivityAggregateArgs, never>
  >;
  recordingActivityByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['RecordingActivity']>,
    'recordingActivityByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootRecordingActivityByPkArgs, 'id'>
  >;
  recordingActivityStream?: SubscriptionResolver<
    Array<ResolversTypes['RecordingActivity']>,
    'recordingActivityStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootRecordingActivityStreamArgs, 'batchSize' | 'cursor'>
  >;
  recordings?: SubscriptionResolver<
    Array<ResolversTypes['Recordings']>,
    'recordings',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootRecordingsArgs, never>
  >;
  recordingsAggregate?: SubscriptionResolver<
    ResolversTypes['RecordingsAggregate'],
    'recordingsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootRecordingsAggregateArgs, never>
  >;
  recordingsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Recordings']>,
    'recordingsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootRecordingsByPkArgs, 'id'>
  >;
  recordingsStream?: SubscriptionResolver<
    Array<ResolversTypes['Recordings']>,
    'recordingsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootRecordingsStreamArgs, 'batchSize' | 'cursor'>
  >;
  relatedCourses?: SubscriptionResolver<
    Array<ResolversTypes['RelatedCourses']>,
    'relatedCourses',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootRelatedCoursesArgs, never>
  >;
  relatedCoursesAggregate?: SubscriptionResolver<
    ResolversTypes['RelatedCoursesAggregate'],
    'relatedCoursesAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootRelatedCoursesAggregateArgs, never>
  >;
  relatedCoursesByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['RelatedCourses']>,
    'relatedCoursesByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootRelatedCoursesByPkArgs, 'courseId' | 'relatedCourseSlug'>
  >;
  reportPublishStatus?: SubscriptionResolver<
    Array<ResolversTypes['ReportPublishStatus']>,
    'reportPublishStatus',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootReportPublishStatusArgs, never>
  >;
  reportPublishStatusAggregate?: SubscriptionResolver<
    ResolversTypes['ReportPublishStatusAggregate'],
    'reportPublishStatusAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootReportPublishStatusAggregateArgs, never>
  >;
  reportPublishStatusByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['ReportPublishStatus']>,
    'reportPublishStatusByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootReportPublishStatusByPkArgs, 'value'>
  >;
  reportType?: SubscriptionResolver<
    Array<ResolversTypes['ReportType']>,
    'reportType',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootReportTypeArgs, never>
  >;
  reportTypeAggregate?: SubscriptionResolver<
    ResolversTypes['ReportTypeAggregate'],
    'reportTypeAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootReportTypeAggregateArgs, never>
  >;
  reportTypeByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['ReportType']>,
    'reportTypeByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootReportTypeByPkArgs, 'value'>
  >;
  reports?: SubscriptionResolver<
    Array<ResolversTypes['Reports']>,
    'reports',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootReportsArgs, never>
  >;
  reportsAggregate?: SubscriptionResolver<
    ResolversTypes['ReportsAggregate'],
    'reportsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootReportsAggregateArgs, never>
  >;
  reportsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Reports']>,
    'reportsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootReportsByPkArgs, 'id'>
  >;
  reportsStream?: SubscriptionResolver<
    Array<ResolversTypes['Reports']>,
    'reportsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootReportsStreamArgs, 'batchSize' | 'cursor'>
  >;
  resourceType?: SubscriptionResolver<
    Array<ResolversTypes['ResourceType']>,
    'resourceType',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootResourceTypeArgs, never>
  >;
  resourceTypeAggregate?: SubscriptionResolver<
    ResolversTypes['ResourceTypeAggregate'],
    'resourceTypeAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootResourceTypeAggregateArgs, never>
  >;
  resourceTypeByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['ResourceType']>,
    'resourceTypeByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootResourceTypeByPkArgs, 'value'>
  >;
  resources?: SubscriptionResolver<
    Array<ResolversTypes['Resources']>,
    'resources',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootResourcesArgs, never>
  >;
  resourcesAggregate?: SubscriptionResolver<
    ResolversTypes['ResourcesAggregate'],
    'resourcesAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootResourcesAggregateArgs, never>
  >;
  resourcesByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Resources']>,
    'resourcesByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootResourcesByPkArgs, 'id'>
  >;
  resourcesStream?: SubscriptionResolver<
    Array<ResolversTypes['Resources']>,
    'resourcesStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootResourcesStreamArgs, 'batchSize' | 'cursor'>
  >;
  schools?: SubscriptionResolver<
    Array<ResolversTypes['Schools']>,
    'schools',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootSchoolsArgs, never>
  >;
  schoolsAggregate?: SubscriptionResolver<
    ResolversTypes['SchoolsAggregate'],
    'schoolsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootSchoolsAggregateArgs, never>
  >;
  schoolsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Schools']>,
    'schoolsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootSchoolsByPkArgs, 'id'>
  >;
  schoolsStream?: SubscriptionResolver<
    Array<ResolversTypes['Schools']>,
    'schoolsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootSchoolsStreamArgs, 'batchSize' | 'cursor'>
  >;
  seatType?: SubscriptionResolver<
    Array<ResolversTypes['SeatType']>,
    'seatType',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootSeatTypeArgs, never>
  >;
  seatTypeAggregate?: SubscriptionResolver<
    ResolversTypes['SeatTypeAggregate'],
    'seatTypeAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootSeatTypeAggregateArgs, never>
  >;
  seatTypeByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['SeatType']>,
    'seatTypeByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootSeatTypeByPkArgs, 'value'>
  >;
  seats?: SubscriptionResolver<
    Array<ResolversTypes['Seats']>,
    'seats',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootSeatsArgs, never>
  >;
  seatsAggregate?: SubscriptionResolver<
    ResolversTypes['SeatsAggregate'],
    'seatsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootSeatsAggregateArgs, never>
  >;
  seatsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Seats']>,
    'seatsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootSeatsByPkArgs, 'id'>
  >;
  seatsStream?: SubscriptionResolver<
    Array<ResolversTypes['Seats']>,
    'seatsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootSeatsStreamArgs, 'batchSize' | 'cursor'>
  >;
  streamMessages?: SubscriptionResolver<
    Array<ResolversTypes['StreamMessages']>,
    'streamMessages',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootStreamMessagesArgs, never>
  >;
  streamMessagesAggregate?: SubscriptionResolver<
    ResolversTypes['StreamMessagesAggregate'],
    'streamMessagesAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootStreamMessagesAggregateArgs, never>
  >;
  streamMessagesByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['StreamMessages']>,
    'streamMessagesByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootStreamMessagesByPkArgs, 'id'>
  >;
  streamMessagesStream?: SubscriptionResolver<
    Array<ResolversTypes['StreamMessages']>,
    'streamMessagesStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootStreamMessagesStreamArgs, 'batchSize' | 'cursor'>
  >;
  subordinates?: SubscriptionResolver<
    Array<ResolversTypes['Subordinates']>,
    'subordinates',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootSubordinatesArgs, never>
  >;
  subordinatesAggregate?: SubscriptionResolver<
    ResolversTypes['SubordinatesAggregate'],
    'subordinatesAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootSubordinatesAggregateArgs, never>
  >;
  subordinatesStream?: SubscriptionResolver<
    Array<ResolversTypes['Subordinates']>,
    'subordinatesStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootSubordinatesStreamArgs, 'batchSize' | 'cursor'>
  >;
  tagTypeScope?: SubscriptionResolver<
    Array<ResolversTypes['TagTypeScope']>,
    'tagTypeScope',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTagTypeScopeArgs, never>
  >;
  tagTypeScopeAggregate?: SubscriptionResolver<
    ResolversTypes['TagTypeScopeAggregate'],
    'tagTypeScopeAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTagTypeScopeAggregateArgs, never>
  >;
  tagTypeScopeByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['TagTypeScope']>,
    'tagTypeScopeByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTagTypeScopeByPkArgs, 'value'>
  >;
  tagTypes?: SubscriptionResolver<
    Array<ResolversTypes['TagTypes']>,
    'tagTypes',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTagTypesArgs, never>
  >;
  tagTypesAggregate?: SubscriptionResolver<
    ResolversTypes['TagTypesAggregate'],
    'tagTypesAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTagTypesAggregateArgs, never>
  >;
  tagTypesByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['TagTypes']>,
    'tagTypesByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTagTypesByPkArgs, 'id'>
  >;
  tagTypesStream?: SubscriptionResolver<
    Array<ResolversTypes['TagTypes']>,
    'tagTypesStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTagTypesStreamArgs, 'batchSize' | 'cursor'>
  >;
  tags?: SubscriptionResolver<
    Array<ResolversTypes['Tags']>,
    'tags',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTagsArgs, never>
  >;
  tagsAggregate?: SubscriptionResolver<
    ResolversTypes['TagsAggregate'],
    'tagsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTagsAggregateArgs, never>
  >;
  tagsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Tags']>,
    'tagsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTagsByPkArgs, 'id'>
  >;
  tagsStream?: SubscriptionResolver<
    Array<ResolversTypes['Tags']>,
    'tagsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTagsStreamArgs, 'batchSize' | 'cursor'>
  >;
  taskDefinition?: SubscriptionResolver<
    Array<ResolversTypes['TaskDefinition']>,
    'taskDefinition',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTaskDefinitionArgs, never>
  >;
  taskDefinitionAggregate?: SubscriptionResolver<
    ResolversTypes['TaskDefinitionAggregate'],
    'taskDefinitionAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTaskDefinitionAggregateArgs, never>
  >;
  taskDefinitionByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['TaskDefinition']>,
    'taskDefinitionByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTaskDefinitionByPkArgs, 'id'>
  >;
  taskDefinitionStream?: SubscriptionResolver<
    Array<ResolversTypes['TaskDefinition']>,
    'taskDefinitionStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTaskDefinitionStreamArgs, 'batchSize' | 'cursor'>
  >;
  taskDefinitionType?: SubscriptionResolver<
    Array<ResolversTypes['TaskDefinitionType']>,
    'taskDefinitionType',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTaskDefinitionTypeArgs, never>
  >;
  taskDefinitionTypeAggregate?: SubscriptionResolver<
    ResolversTypes['TaskDefinitionTypeAggregate'],
    'taskDefinitionTypeAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTaskDefinitionTypeAggregateArgs, never>
  >;
  taskDefinitionTypeByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['TaskDefinitionType']>,
    'taskDefinitionTypeByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTaskDefinitionTypeByPkArgs, 'value'>
  >;
  tasks?: SubscriptionResolver<
    Array<ResolversTypes['Tasks']>,
    'tasks',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTasksArgs, never>
  >;
  tasksAggregate?: SubscriptionResolver<
    ResolversTypes['TasksAggregate'],
    'tasksAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTasksAggregateArgs, never>
  >;
  tasksByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Tasks']>,
    'tasksByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTasksByPkArgs, 'id'>
  >;
  tasksStream?: SubscriptionResolver<
    Array<ResolversTypes['Tasks']>,
    'tasksStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTasksStreamArgs, 'batchSize' | 'cursor'>
  >;
  teamMemberPollSubmissions?: SubscriptionResolver<
    Array<ResolversTypes['teamMemberPollSubmissions']>,
    'teamMemberPollSubmissions',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTeamMemberPollSubmissionsArgs, never>
  >;
  teams?: SubscriptionResolver<
    Array<ResolversTypes['Teams']>,
    'teams',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTeamsArgs, never>
  >;
  teamsAggregate?: SubscriptionResolver<
    ResolversTypes['TeamsAggregate'],
    'teamsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTeamsAggregateArgs, never>
  >;
  teamsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Teams']>,
    'teamsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTeamsByPkArgs, 'id'>
  >;
  teamsStream?: SubscriptionResolver<
    Array<ResolversTypes['Teams']>,
    'teamsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTeamsStreamArgs, 'batchSize' | 'cursor'>
  >;
  teamsUsers?: SubscriptionResolver<
    Array<ResolversTypes['TeamsUsers']>,
    'teamsUsers',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTeamsUsersArgs, never>
  >;
  teamsUsersAggregate?: SubscriptionResolver<
    ResolversTypes['TeamsUsersAggregate'],
    'teamsUsersAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTeamsUsersAggregateArgs, never>
  >;
  teamsUsersByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['TeamsUsers']>,
    'teamsUsersByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTeamsUsersByPkArgs, 'teamId' | 'userId'>
  >;
  timeElapsedLiveClasses?: SubscriptionResolver<
    Array<ResolversTypes['TimeElapsedLiveClasses']>,
    'timeElapsedLiveClasses',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTimeElapsedLiveClassesArgs, never>
  >;
  timeElapsedLiveClassesAggregate?: SubscriptionResolver<
    ResolversTypes['TimeElapsedLiveClassesAggregate'],
    'timeElapsedLiveClassesAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTimeElapsedLiveClassesAggregateArgs, never>
  >;
  timeElapsedLiveClassesStream?: SubscriptionResolver<
    Array<ResolversTypes['TimeElapsedLiveClasses']>,
    'timeElapsedLiveClassesStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTimeElapsedLiveClassesStreamArgs, 'batchSize' | 'cursor'>
  >;
  typeformSubmissions?: SubscriptionResolver<
    Array<ResolversTypes['TypeformSubmissions']>,
    'typeformSubmissions',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTypeformSubmissionsArgs, never>
  >;
  typeformSubmissionsAggregate?: SubscriptionResolver<
    ResolversTypes['TypeformSubmissionsAggregate'],
    'typeformSubmissionsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTypeformSubmissionsAggregateArgs, never>
  >;
  typeformSubmissionsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['TypeformSubmissions']>,
    'typeformSubmissionsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTypeformSubmissionsByPkArgs, 'id'>
  >;
  typeformSubmissionsStream?: SubscriptionResolver<
    Array<ResolversTypes['TypeformSubmissions']>,
    'typeformSubmissionsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTypeformSubmissionsStreamArgs, 'batchSize' | 'cursor'>
  >;
  userCompletedSurveys?: SubscriptionResolver<
    Array<ResolversTypes['UserCompletedSurveys']>,
    'userCompletedSurveys',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUserCompletedSurveysArgs, never>
  >;
  userCompletedSurveysAggregate?: SubscriptionResolver<
    ResolversTypes['UserCompletedSurveysAggregate'],
    'userCompletedSurveysAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUserCompletedSurveysAggregateArgs, never>
  >;
  userCompletedSurveysStream?: SubscriptionResolver<
    Array<ResolversTypes['UserCompletedSurveys']>,
    'userCompletedSurveysStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUserCompletedSurveysStreamArgs, 'batchSize' | 'cursor'>
  >;
  userManagedTeamsFlattenedTree?: SubscriptionResolver<
    Array<ResolversTypes['userManagedTeamsFlattenedTree']>,
    'userManagedTeamsFlattenedTree',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUserManagedTeamsFlattenedTreeArgs, 'args'>
  >;
  userNumCompletedClasses?: SubscriptionResolver<
    Array<ResolversTypes['UserNumCompletedClasses']>,
    'userNumCompletedClasses',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUserNumCompletedClassesArgs, never>
  >;
  userNumCompletedClassesAggregate?: SubscriptionResolver<
    ResolversTypes['UserNumCompletedClassesAggregate'],
    'userNumCompletedClassesAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUserNumCompletedClassesAggregateArgs, never>
  >;
  userNumCompletedClassesStream?: SubscriptionResolver<
    Array<ResolversTypes['UserNumCompletedClasses']>,
    'userNumCompletedClassesStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUserNumCompletedClassesStreamArgs, 'batchSize' | 'cursor'>
  >;
  userSessionEmails?: SubscriptionResolver<
    Array<ResolversTypes['UserSessionEmails']>,
    'userSessionEmails',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUserSessionEmailsArgs, never>
  >;
  userSessionEmailsAggregate?: SubscriptionResolver<
    ResolversTypes['UserSessionEmailsAggregate'],
    'userSessionEmailsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUserSessionEmailsAggregateArgs, never>
  >;
  userSessionEmailsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['UserSessionEmails']>,
    'userSessionEmailsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUserSessionEmailsByPkArgs, 'userClassEventId'>
  >;
  userSessionEmailsStream?: SubscriptionResolver<
    Array<ResolversTypes['UserSessionEmails']>,
    'userSessionEmailsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUserSessionEmailsStreamArgs, 'batchSize' | 'cursor'>
  >;
  users?: SubscriptionResolver<
    Array<ResolversTypes['Users']>,
    'users',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUsersArgs, never>
  >;
  usersAggregate?: SubscriptionResolver<
    ResolversTypes['UsersAggregate'],
    'usersAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUsersAggregateArgs, never>
  >;
  usersByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Users']>,
    'usersByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUsersByPkArgs, 'id'>
  >;
  usersFunctions?: SubscriptionResolver<
    Array<ResolversTypes['UsersFunctions']>,
    'usersFunctions',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUsersFunctionsArgs, never>
  >;
  usersFunctionsAggregate?: SubscriptionResolver<
    ResolversTypes['UsersFunctionsAggregate'],
    'usersFunctionsAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUsersFunctionsAggregateArgs, never>
  >;
  usersFunctionsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['UsersFunctions']>,
    'usersFunctionsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUsersFunctionsByPkArgs, 'value'>
  >;
  usersLengthInFunction?: SubscriptionResolver<
    Array<ResolversTypes['UsersLengthInFunction']>,
    'usersLengthInFunction',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUsersLengthInFunctionArgs, never>
  >;
  usersLengthInFunctionAggregate?: SubscriptionResolver<
    ResolversTypes['UsersLengthInFunctionAggregate'],
    'usersLengthInFunctionAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUsersLengthInFunctionAggregateArgs, never>
  >;
  usersLengthInFunctionByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['UsersLengthInFunction']>,
    'usersLengthInFunctionByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUsersLengthInFunctionByPkArgs, 'value'>
  >;
  usersMeta?: SubscriptionResolver<
    Array<ResolversTypes['UsersMeta']>,
    'usersMeta',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUsersMetaArgs, never>
  >;
  usersMetaAggregate?: SubscriptionResolver<
    ResolversTypes['UsersMetaAggregate'],
    'usersMetaAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUsersMetaAggregateArgs, never>
  >;
  usersMetaByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['UsersMeta']>,
    'usersMetaByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUsersMetaByPkArgs, 'userId'>
  >;
  usersMetaStream?: SubscriptionResolver<
    Array<ResolversTypes['UsersMeta']>,
    'usersMetaStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUsersMetaStreamArgs, 'batchSize' | 'cursor'>
  >;
  zoomOauthTokens?: SubscriptionResolver<
    Array<ResolversTypes['ZoomOauthTokens']>,
    'zoomOauthTokens',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootZoomOauthTokensArgs, never>
  >;
  zoomOauthTokensAggregate?: SubscriptionResolver<
    ResolversTypes['ZoomOauthTokensAggregate'],
    'zoomOauthTokensAggregate',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootZoomOauthTokensAggregateArgs, never>
  >;
  zoomOauthTokensByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['ZoomOauthTokens']>,
    'zoomOauthTokensByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootZoomOauthTokensByPkArgs, 'bearerToken'>
  >;
  zoomOauthTokensStream?: SubscriptionResolver<
    Array<ResolversTypes['ZoomOauthTokens']>,
    'zoomOauthTokensStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootZoomOauthTokensStreamArgs, 'batchSize' | 'cursor'>
  >;
};

export type TeamMemberResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['teamMember'] = ResolversParentTypes['teamMember']
> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  jobFunction?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  jobFunctionLength?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  teamId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  teamIds?: Resolver<
    ResolversTypes['jsonb'],
    ParentType,
    ContextType,
    RequireFields<TeamMemberTeamIdsArgs, never>
  >;
  teamName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  teamNames?: Resolver<
    ResolversTypes['jsonb'],
    ParentType,
    ContextType,
    RequireFields<TeamMemberTeamNamesArgs, never>
  >;
  userId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamMemberPollSubmissionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['teamMemberPollSubmissions'] = ResolversParentTypes['teamMemberPollSubmissions']
> = {
  classEventId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  cohortId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  confidenceScore?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  courseId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  engagementScore?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  formId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  formName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  jobImpactScore?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  learnerUserId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  npsScore?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numDealsWhereApplied?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pollSubmissionId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  submittedAt?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  takeawayQuote?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  whichDeals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Team_Flat_Tree_NodeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['team_flat_tree_node'] = ResolversParentTypes['team_flat_tree_node']
> = {
  branch?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasChildrenTeams?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  jobFunction?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  managerNames?: Resolver<
    ResolversTypes['jsonb'],
    ParentType,
    ContextType,
    RequireFields<Team_Flat_Tree_NodeManagerNamesArgs, never>
  >;
  memberNames?: Resolver<
    ResolversTypes['jsonb'],
    ParentType,
    ContextType,
    RequireFields<Team_Flat_Tree_NodeMemberNamesArgs, never>
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nodeHeight?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  parentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  teamId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  treeId?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface TimestampScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['timestamp'], any> {
  name: 'timestamp';
}

export interface TimestamptzScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['timestamptz'], any> {
  name: 'timestamptz';
}

export type UserManagedTeamsFlattenedTreeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['userManagedTeamsFlattenedTree'] = ResolversParentTypes['userManagedTeamsFlattenedTree']
> = {
  branch?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  members?: Resolver<
    Maybe<Array<ResolversTypes['getTeamMembersNew']>>,
    ParentType,
    ContextType,
    RequireFields<UserManagedTeamsFlattenedTreeMembersArgs, never>
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nodeHeight?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  parentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  teamJobFunction?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  treeId?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UuidScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['uuid'], any> {
  name: 'uuid';
}

export type Resolvers<ContextType = any> = {
  ActivityType?: ActivityTypeResolvers<ContextType>;
  ActivityTypeAggregate?: ActivityTypeAggregateResolvers<ContextType>;
  ActivityTypeAggregateFields?: ActivityTypeAggregateFieldsResolvers<ContextType>;
  ActivityTypeMaxFields?: ActivityTypeMaxFieldsResolvers<ContextType>;
  ActivityTypeMinFields?: ActivityTypeMinFieldsResolvers<ContextType>;
  ActivityTypeMutationResponse?: ActivityTypeMutationResponseResolvers<ContextType>;
  AddUserToCourseWaitlistOutput?: AddUserToCourseWaitlistOutputResolvers<ContextType>;
  AttemptStatus?: AttemptStatusResolvers<ContextType>;
  AttemptStatusAggregate?: AttemptStatusAggregateResolvers<ContextType>;
  AttemptStatusAggregateFields?: AttemptStatusAggregateFieldsResolvers<ContextType>;
  AttemptStatusMaxFields?: AttemptStatusMaxFieldsResolvers<ContextType>;
  AttemptStatusMinFields?: AttemptStatusMinFieldsResolvers<ContextType>;
  AttemptStatusMutationResponse?: AttemptStatusMutationResponseResolvers<ContextType>;
  CatalogCourses?: CatalogCoursesResolvers<ContextType>;
  CatalogCoursesAggregate?: CatalogCoursesAggregateResolvers<ContextType>;
  CatalogCoursesAggregateFields?: CatalogCoursesAggregateFieldsResolvers<ContextType>;
  CatalogCoursesMaxFields?: CatalogCoursesMaxFieldsResolvers<ContextType>;
  CatalogCoursesMinFields?: CatalogCoursesMinFieldsResolvers<ContextType>;
  CatalogCoursesMutationResponse?: CatalogCoursesMutationResponseResolvers<ContextType>;
  Catalogs?: CatalogsResolvers<ContextType>;
  CatalogsAggregate?: CatalogsAggregateResolvers<ContextType>;
  CatalogsAggregateFields?: CatalogsAggregateFieldsResolvers<ContextType>;
  CatalogsJourneysRel?: CatalogsJourneysRelResolvers<ContextType>;
  CatalogsJourneysRelAggregate?: CatalogsJourneysRelAggregateResolvers<ContextType>;
  CatalogsJourneysRelAggregateFields?: CatalogsJourneysRelAggregateFieldsResolvers<ContextType>;
  CatalogsJourneysRelMaxFields?: CatalogsJourneysRelMaxFieldsResolvers<ContextType>;
  CatalogsJourneysRelMinFields?: CatalogsJourneysRelMinFieldsResolvers<ContextType>;
  CatalogsJourneysRelMutationResponse?: CatalogsJourneysRelMutationResponseResolvers<ContextType>;
  CatalogsMaxFields?: CatalogsMaxFieldsResolvers<ContextType>;
  CatalogsMinFields?: CatalogsMinFieldsResolvers<ContextType>;
  CatalogsMutationResponse?: CatalogsMutationResponseResolvers<ContextType>;
  CheckMyTeamCourseWaitlistOutput?: CheckMyTeamCourseWaitlistOutputResolvers<ContextType>;
  ClassCoaches?: ClassCoachesResolvers<ContextType>;
  ClassCoachesAggregate?: ClassCoachesAggregateResolvers<ContextType>;
  ClassCoachesAggregateFields?: ClassCoachesAggregateFieldsResolvers<ContextType>;
  ClassCoachesMaxFields?: ClassCoachesMaxFieldsResolvers<ContextType>;
  ClassCoachesMinFields?: ClassCoachesMinFieldsResolvers<ContextType>;
  ClassCoachesMutationResponse?: ClassCoachesMutationResponseResolvers<ContextType>;
  ClassEventActivity?: ClassEventActivityResolvers<ContextType>;
  ClassEventActivityAggregate?: ClassEventActivityAggregateResolvers<ContextType>;
  ClassEventActivityAggregateFields?: ClassEventActivityAggregateFieldsResolvers<ContextType>;
  ClassEventActivityMaxFields?: ClassEventActivityMaxFieldsResolvers<ContextType>;
  ClassEventActivityMinFields?: ClassEventActivityMinFieldsResolvers<ContextType>;
  ClassEventActivityMutationResponse?: ClassEventActivityMutationResponseResolvers<ContextType>;
  ClassEventAnonRegistrations?: ClassEventAnonRegistrationsResolvers<ContextType>;
  ClassEventAnonRegistrationsAggregate?: ClassEventAnonRegistrationsAggregateResolvers<ContextType>;
  ClassEventAnonRegistrationsAggregateFields?: ClassEventAnonRegistrationsAggregateFieldsResolvers<ContextType>;
  ClassEventAnonRegistrationsMaxFields?: ClassEventAnonRegistrationsMaxFieldsResolvers<ContextType>;
  ClassEventAnonRegistrationsMinFields?: ClassEventAnonRegistrationsMinFieldsResolvers<ContextType>;
  ClassEventAnonRegistrationsMutationResponse?: ClassEventAnonRegistrationsMutationResponseResolvers<ContextType>;
  ClassEventInvitations?: ClassEventInvitationsResolvers<ContextType>;
  ClassEventInvitationsAggregate?: ClassEventInvitationsAggregateResolvers<ContextType>;
  ClassEventInvitationsAggregateFields?: ClassEventInvitationsAggregateFieldsResolvers<ContextType>;
  ClassEventInvitationsAvgFields?: ClassEventInvitationsAvgFieldsResolvers<ContextType>;
  ClassEventInvitationsMaxFields?: ClassEventInvitationsMaxFieldsResolvers<ContextType>;
  ClassEventInvitationsMinFields?: ClassEventInvitationsMinFieldsResolvers<ContextType>;
  ClassEventInvitationsMutationResponse?: ClassEventInvitationsMutationResponseResolvers<ContextType>;
  ClassEventInvitationsStddevFields?: ClassEventInvitationsStddevFieldsResolvers<ContextType>;
  ClassEventInvitationsStddevPopFields?: ClassEventInvitationsStddevPopFieldsResolvers<ContextType>;
  ClassEventInvitationsStddevSampFields?: ClassEventInvitationsStddevSampFieldsResolvers<ContextType>;
  ClassEventInvitationsSumFields?: ClassEventInvitationsSumFieldsResolvers<ContextType>;
  ClassEventInvitationsVarPopFields?: ClassEventInvitationsVarPopFieldsResolvers<ContextType>;
  ClassEventInvitationsVarSampFields?: ClassEventInvitationsVarSampFieldsResolvers<ContextType>;
  ClassEventInvitationsVarianceFields?: ClassEventInvitationsVarianceFieldsResolvers<ContextType>;
  ClassEventMeetingParticipants?: ClassEventMeetingParticipantsResolvers<ContextType>;
  ClassEventMeetingParticipantsAggregate?: ClassEventMeetingParticipantsAggregateResolvers<ContextType>;
  ClassEventMeetingParticipantsAggregateFields?: ClassEventMeetingParticipantsAggregateFieldsResolvers<ContextType>;
  ClassEventMeetingParticipantsAvgFields?: ClassEventMeetingParticipantsAvgFieldsResolvers<ContextType>;
  ClassEventMeetingParticipantsMaxFields?: ClassEventMeetingParticipantsMaxFieldsResolvers<ContextType>;
  ClassEventMeetingParticipantsMinFields?: ClassEventMeetingParticipantsMinFieldsResolvers<ContextType>;
  ClassEventMeetingParticipantsMutationResponse?: ClassEventMeetingParticipantsMutationResponseResolvers<ContextType>;
  ClassEventMeetingParticipantsStddevFields?: ClassEventMeetingParticipantsStddevFieldsResolvers<ContextType>;
  ClassEventMeetingParticipantsStddevPopFields?: ClassEventMeetingParticipantsStddevPopFieldsResolvers<ContextType>;
  ClassEventMeetingParticipantsStddevSampFields?: ClassEventMeetingParticipantsStddevSampFieldsResolvers<ContextType>;
  ClassEventMeetingParticipantsSumFields?: ClassEventMeetingParticipantsSumFieldsResolvers<ContextType>;
  ClassEventMeetingParticipantsVarPopFields?: ClassEventMeetingParticipantsVarPopFieldsResolvers<ContextType>;
  ClassEventMeetingParticipantsVarSampFields?: ClassEventMeetingParticipantsVarSampFieldsResolvers<ContextType>;
  ClassEventMeetingParticipantsVarianceFields?: ClassEventMeetingParticipantsVarianceFieldsResolvers<ContextType>;
  ClassEventMeetingSegments?: ClassEventMeetingSegmentsResolvers<ContextType>;
  ClassEventMeetingSegmentsAggregate?: ClassEventMeetingSegmentsAggregateResolvers<ContextType>;
  ClassEventMeetingSegmentsAggregateFields?: ClassEventMeetingSegmentsAggregateFieldsResolvers<ContextType>;
  ClassEventMeetingSegmentsAvgFields?: ClassEventMeetingSegmentsAvgFieldsResolvers<ContextType>;
  ClassEventMeetingSegmentsMaxFields?: ClassEventMeetingSegmentsMaxFieldsResolvers<ContextType>;
  ClassEventMeetingSegmentsMinFields?: ClassEventMeetingSegmentsMinFieldsResolvers<ContextType>;
  ClassEventMeetingSegmentsMutationResponse?: ClassEventMeetingSegmentsMutationResponseResolvers<ContextType>;
  ClassEventMeetingSegmentsStddevFields?: ClassEventMeetingSegmentsStddevFieldsResolvers<ContextType>;
  ClassEventMeetingSegmentsStddevPopFields?: ClassEventMeetingSegmentsStddevPopFieldsResolvers<ContextType>;
  ClassEventMeetingSegmentsStddevSampFields?: ClassEventMeetingSegmentsStddevSampFieldsResolvers<ContextType>;
  ClassEventMeetingSegmentsSumFields?: ClassEventMeetingSegmentsSumFieldsResolvers<ContextType>;
  ClassEventMeetingSegmentsVarPopFields?: ClassEventMeetingSegmentsVarPopFieldsResolvers<ContextType>;
  ClassEventMeetingSegmentsVarSampFields?: ClassEventMeetingSegmentsVarSampFieldsResolvers<ContextType>;
  ClassEventMeetingSegmentsVarianceFields?: ClassEventMeetingSegmentsVarianceFieldsResolvers<ContextType>;
  ClassEventMeetings?: ClassEventMeetingsResolvers<ContextType>;
  ClassEventMeetingsAggregate?: ClassEventMeetingsAggregateResolvers<ContextType>;
  ClassEventMeetingsAggregateFields?: ClassEventMeetingsAggregateFieldsResolvers<ContextType>;
  ClassEventMeetingsAvgFields?: ClassEventMeetingsAvgFieldsResolvers<ContextType>;
  ClassEventMeetingsMaxFields?: ClassEventMeetingsMaxFieldsResolvers<ContextType>;
  ClassEventMeetingsMinFields?: ClassEventMeetingsMinFieldsResolvers<ContextType>;
  ClassEventMeetingsMutationResponse?: ClassEventMeetingsMutationResponseResolvers<ContextType>;
  ClassEventMeetingsStddevFields?: ClassEventMeetingsStddevFieldsResolvers<ContextType>;
  ClassEventMeetingsStddevPopFields?: ClassEventMeetingsStddevPopFieldsResolvers<ContextType>;
  ClassEventMeetingsStddevSampFields?: ClassEventMeetingsStddevSampFieldsResolvers<ContextType>;
  ClassEventMeetingsSumFields?: ClassEventMeetingsSumFieldsResolvers<ContextType>;
  ClassEventMeetingsVarPopFields?: ClassEventMeetingsVarPopFieldsResolvers<ContextType>;
  ClassEventMeetingsVarSampFields?: ClassEventMeetingsVarSampFieldsResolvers<ContextType>;
  ClassEventMeetingsVarianceFields?: ClassEventMeetingsVarianceFieldsResolvers<ContextType>;
  ClassEventRegistrations?: ClassEventRegistrationsResolvers<ContextType>;
  ClassEventRegistrationsAggregate?: ClassEventRegistrationsAggregateResolvers<ContextType>;
  ClassEventRegistrationsAggregateFields?: ClassEventRegistrationsAggregateFieldsResolvers<ContextType>;
  ClassEventRegistrationsMaxFields?: ClassEventRegistrationsMaxFieldsResolvers<ContextType>;
  ClassEventRegistrationsMinFields?: ClassEventRegistrationsMinFieldsResolvers<ContextType>;
  ClassEventRegistrationsMutationResponse?: ClassEventRegistrationsMutationResponseResolvers<ContextType>;
  ClassEvents?: ClassEventsResolvers<ContextType>;
  ClassEventsAggregate?: ClassEventsAggregateResolvers<ContextType>;
  ClassEventsAggregateFields?: ClassEventsAggregateFieldsResolvers<ContextType>;
  ClassEventsAvgFields?: ClassEventsAvgFieldsResolvers<ContextType>;
  ClassEventsMaxFields?: ClassEventsMaxFieldsResolvers<ContextType>;
  ClassEventsMinFields?: ClassEventsMinFieldsResolvers<ContextType>;
  ClassEventsMutationResponse?: ClassEventsMutationResponseResolvers<ContextType>;
  ClassEventsStddevFields?: ClassEventsStddevFieldsResolvers<ContextType>;
  ClassEventsStddevPopFields?: ClassEventsStddevPopFieldsResolvers<ContextType>;
  ClassEventsStddevSampFields?: ClassEventsStddevSampFieldsResolvers<ContextType>;
  ClassEventsSumFields?: ClassEventsSumFieldsResolvers<ContextType>;
  ClassEventsVarPopFields?: ClassEventsVarPopFieldsResolvers<ContextType>;
  ClassEventsVarSampFields?: ClassEventsVarSampFieldsResolvers<ContextType>;
  ClassEventsVarianceFields?: ClassEventsVarianceFieldsResolvers<ContextType>;
  ClassPlaybackActivity?: ClassPlaybackActivityResolvers<ContextType>;
  ClassPlaybackActivityAggregate?: ClassPlaybackActivityAggregateResolvers<ContextType>;
  ClassPlaybackActivityAggregateFields?: ClassPlaybackActivityAggregateFieldsResolvers<ContextType>;
  ClassPlaybackActivityMaxFields?: ClassPlaybackActivityMaxFieldsResolvers<ContextType>;
  ClassPlaybackActivityMinFields?: ClassPlaybackActivityMinFieldsResolvers<ContextType>;
  ClassPlaybackActivityMutationResponse?: ClassPlaybackActivityMutationResponseResolvers<ContextType>;
  ClassPlaybackActivityType?: ClassPlaybackActivityTypeResolvers<ContextType>;
  ClassPlaybackActivityTypeAggregate?: ClassPlaybackActivityTypeAggregateResolvers<ContextType>;
  ClassPlaybackActivityTypeAggregateFields?: ClassPlaybackActivityTypeAggregateFieldsResolvers<ContextType>;
  ClassPlaybackActivityTypeMaxFields?: ClassPlaybackActivityTypeMaxFieldsResolvers<ContextType>;
  ClassPlaybackActivityTypeMinFields?: ClassPlaybackActivityTypeMinFieldsResolvers<ContextType>;
  ClassPlaybackActivityTypeMutationResponse?: ClassPlaybackActivityTypeMutationResponseResolvers<ContextType>;
  ClassResources?: ClassResourcesResolvers<ContextType>;
  ClassResourcesAggregate?: ClassResourcesAggregateResolvers<ContextType>;
  ClassResourcesAggregateFields?: ClassResourcesAggregateFieldsResolvers<ContextType>;
  ClassResourcesMaxFields?: ClassResourcesMaxFieldsResolvers<ContextType>;
  ClassResourcesMinFields?: ClassResourcesMinFieldsResolvers<ContextType>;
  ClassResourcesMutationResponse?: ClassResourcesMutationResponseResolvers<ContextType>;
  Classes?: ClassesResolvers<ContextType>;
  ClassesAggregate?: ClassesAggregateResolvers<ContextType>;
  ClassesAggregateFields?: ClassesAggregateFieldsResolvers<ContextType>;
  ClassesAvgFields?: ClassesAvgFieldsResolvers<ContextType>;
  ClassesMaxFields?: ClassesMaxFieldsResolvers<ContextType>;
  ClassesMinFields?: ClassesMinFieldsResolvers<ContextType>;
  ClassesMutationResponse?: ClassesMutationResponseResolvers<ContextType>;
  ClassesStddevFields?: ClassesStddevFieldsResolvers<ContextType>;
  ClassesStddevPopFields?: ClassesStddevPopFieldsResolvers<ContextType>;
  ClassesStddevSampFields?: ClassesStddevSampFieldsResolvers<ContextType>;
  ClassesSumFields?: ClassesSumFieldsResolvers<ContextType>;
  ClassesTagsRel?: ClassesTagsRelResolvers<ContextType>;
  ClassesTagsRelAggregate?: ClassesTagsRelAggregateResolvers<ContextType>;
  ClassesTagsRelAggregateFields?: ClassesTagsRelAggregateFieldsResolvers<ContextType>;
  ClassesTagsRelMaxFields?: ClassesTagsRelMaxFieldsResolvers<ContextType>;
  ClassesTagsRelMinFields?: ClassesTagsRelMinFieldsResolvers<ContextType>;
  ClassesTagsRelMutationResponse?: ClassesTagsRelMutationResponseResolvers<ContextType>;
  ClassesVarPopFields?: ClassesVarPopFieldsResolvers<ContextType>;
  ClassesVarSampFields?: ClassesVarSampFieldsResolvers<ContextType>;
  ClassesVarianceFields?: ClassesVarianceFieldsResolvers<ContextType>;
  Coaches?: CoachesResolvers<ContextType>;
  CoachesAggregate?: CoachesAggregateResolvers<ContextType>;
  CoachesAggregateFields?: CoachesAggregateFieldsResolvers<ContextType>;
  CoachesMaxFields?: CoachesMaxFieldsResolvers<ContextType>;
  CoachesMinFields?: CoachesMinFieldsResolvers<ContextType>;
  CoachesMutationResponse?: CoachesMutationResponseResolvers<ContextType>;
  CohortCoaches?: CohortCoachesResolvers<ContextType>;
  CohortCoachesAggregate?: CohortCoachesAggregateResolvers<ContextType>;
  CohortCoachesAggregateFields?: CohortCoachesAggregateFieldsResolvers<ContextType>;
  CohortCoachesMaxFields?: CohortCoachesMaxFieldsResolvers<ContextType>;
  CohortCoachesMinFields?: CohortCoachesMinFieldsResolvers<ContextType>;
  CohortCoachesMutationResponse?: CohortCoachesMutationResponseResolvers<ContextType>;
  CohortProgress?: CohortProgressResolvers<ContextType>;
  CohortProgressAggregate?: CohortProgressAggregateResolvers<ContextType>;
  CohortProgressAggregateFields?: CohortProgressAggregateFieldsResolvers<ContextType>;
  CohortProgressAvgFields?: CohortProgressAvgFieldsResolvers<ContextType>;
  CohortProgressMaxFields?: CohortProgressMaxFieldsResolvers<ContextType>;
  CohortProgressMinFields?: CohortProgressMinFieldsResolvers<ContextType>;
  CohortProgressStddevFields?: CohortProgressStddevFieldsResolvers<ContextType>;
  CohortProgressStddevPopFields?: CohortProgressStddevPopFieldsResolvers<ContextType>;
  CohortProgressStddevSampFields?: CohortProgressStddevSampFieldsResolvers<ContextType>;
  CohortProgressSumFields?: CohortProgressSumFieldsResolvers<ContextType>;
  CohortProgressVarPopFields?: CohortProgressVarPopFieldsResolvers<ContextType>;
  CohortProgressVarSampFields?: CohortProgressVarSampFieldsResolvers<ContextType>;
  CohortProgressVarianceFields?: CohortProgressVarianceFieldsResolvers<ContextType>;
  CohortType?: CohortTypeResolvers<ContextType>;
  CohortTypeAggregate?: CohortTypeAggregateResolvers<ContextType>;
  CohortTypeAggregateFields?: CohortTypeAggregateFieldsResolvers<ContextType>;
  CohortTypeMaxFields?: CohortTypeMaxFieldsResolvers<ContextType>;
  CohortTypeMinFields?: CohortTypeMinFieldsResolvers<ContextType>;
  CohortTypeMutationResponse?: CohortTypeMutationResponseResolvers<ContextType>;
  CohortVisibilityEnum?: CohortVisibilityEnumResolvers<ContextType>;
  CohortVisibilityEnumAggregate?: CohortVisibilityEnumAggregateResolvers<ContextType>;
  CohortVisibilityEnumAggregateFields?: CohortVisibilityEnumAggregateFieldsResolvers<ContextType>;
  CohortVisibilityEnumMaxFields?: CohortVisibilityEnumMaxFieldsResolvers<ContextType>;
  CohortVisibilityEnumMinFields?: CohortVisibilityEnumMinFieldsResolvers<ContextType>;
  CohortVisibilityEnumMutationResponse?: CohortVisibilityEnumMutationResponseResolvers<ContextType>;
  Cohorts?: CohortsResolvers<ContextType>;
  CohortsAggregate?: CohortsAggregateResolvers<ContextType>;
  CohortsAggregateFields?: CohortsAggregateFieldsResolvers<ContextType>;
  CohortsAvgFields?: CohortsAvgFieldsResolvers<ContextType>;
  CohortsMaxFields?: CohortsMaxFieldsResolvers<ContextType>;
  CohortsMinFields?: CohortsMinFieldsResolvers<ContextType>;
  CohortsMutationResponse?: CohortsMutationResponseResolvers<ContextType>;
  CohortsResourcesRel?: CohortsResourcesRelResolvers<ContextType>;
  CohortsResourcesRelAggregate?: CohortsResourcesRelAggregateResolvers<ContextType>;
  CohortsResourcesRelAggregateFields?: CohortsResourcesRelAggregateFieldsResolvers<ContextType>;
  CohortsResourcesRelMaxFields?: CohortsResourcesRelMaxFieldsResolvers<ContextType>;
  CohortsResourcesRelMinFields?: CohortsResourcesRelMinFieldsResolvers<ContextType>;
  CohortsResourcesRelMutationResponse?: CohortsResourcesRelMutationResponseResolvers<ContextType>;
  CohortsStddevFields?: CohortsStddevFieldsResolvers<ContextType>;
  CohortsStddevPopFields?: CohortsStddevPopFieldsResolvers<ContextType>;
  CohortsStddevSampFields?: CohortsStddevSampFieldsResolvers<ContextType>;
  CohortsSumFields?: CohortsSumFieldsResolvers<ContextType>;
  CohortsVarPopFields?: CohortsVarPopFieldsResolvers<ContextType>;
  CohortsVarSampFields?: CohortsVarSampFieldsResolvers<ContextType>;
  CohortsVarianceFields?: CohortsVarianceFieldsResolvers<ContextType>;
  Configurations?: ConfigurationsResolvers<ContextType>;
  ConfigurationsAggregate?: ConfigurationsAggregateResolvers<ContextType>;
  ConfigurationsAggregateFields?: ConfigurationsAggregateFieldsResolvers<ContextType>;
  ConfigurationsMaxFields?: ConfigurationsMaxFieldsResolvers<ContextType>;
  ConfigurationsMinFields?: ConfigurationsMinFieldsResolvers<ContextType>;
  ConfigurationsMutationResponse?: ConfigurationsMutationResponseResolvers<ContextType>;
  CountTeamSessionsItem?: CountTeamSessionsItemResolvers<ContextType>;
  CountTeamSessionsOutput?: CountTeamSessionsOutputResolvers<ContextType>;
  CourseCertifications?: CourseCertificationsResolvers<ContextType>;
  CourseCertificationsAggregate?: CourseCertificationsAggregateResolvers<ContextType>;
  CourseCertificationsAggregateFields?: CourseCertificationsAggregateFieldsResolvers<ContextType>;
  CourseCertificationsMaxFields?: CourseCertificationsMaxFieldsResolvers<ContextType>;
  CourseCertificationsMinFields?: CourseCertificationsMinFieldsResolvers<ContextType>;
  CourseCertificationsMutationResponse?: CourseCertificationsMutationResponseResolvers<ContextType>;
  CourseCoaches?: CourseCoachesResolvers<ContextType>;
  CourseCoachesAggregate?: CourseCoachesAggregateResolvers<ContextType>;
  CourseCoachesAggregateFields?: CourseCoachesAggregateFieldsResolvers<ContextType>;
  CourseCoachesMaxFields?: CourseCoachesMaxFieldsResolvers<ContextType>;
  CourseCoachesMinFields?: CourseCoachesMinFieldsResolvers<ContextType>;
  CourseCoachesMutationResponse?: CourseCoachesMutationResponseResolvers<ContextType>;
  CourseEnrollmentStatus?: CourseEnrollmentStatusResolvers<ContextType>;
  CourseEnrollmentStatusAggregate?: CourseEnrollmentStatusAggregateResolvers<ContextType>;
  CourseEnrollmentStatusAggregateFields?: CourseEnrollmentStatusAggregateFieldsResolvers<ContextType>;
  CourseEnrollmentStatusMaxFields?: CourseEnrollmentStatusMaxFieldsResolvers<ContextType>;
  CourseEnrollmentStatusMinFields?: CourseEnrollmentStatusMinFieldsResolvers<ContextType>;
  CourseEnrollmentStatusMutationResponse?: CourseEnrollmentStatusMutationResponseResolvers<ContextType>;
  CoursePublishStatus?: CoursePublishStatusResolvers<ContextType>;
  CoursePublishStatusAggregate?: CoursePublishStatusAggregateResolvers<ContextType>;
  CoursePublishStatusAggregateFields?: CoursePublishStatusAggregateFieldsResolvers<ContextType>;
  CoursePublishStatusMaxFields?: CoursePublishStatusMaxFieldsResolvers<ContextType>;
  CoursePublishStatusMinFields?: CoursePublishStatusMinFieldsResolvers<ContextType>;
  CoursePublishStatusMutationResponse?: CoursePublishStatusMutationResponseResolvers<ContextType>;
  CourseType?: CourseTypeResolvers<ContextType>;
  CourseTypeAggregate?: CourseTypeAggregateResolvers<ContextType>;
  CourseTypeAggregateFields?: CourseTypeAggregateFieldsResolvers<ContextType>;
  CourseTypeMaxFields?: CourseTypeMaxFieldsResolvers<ContextType>;
  CourseTypeMinFields?: CourseTypeMinFieldsResolvers<ContextType>;
  CourseTypeMutationResponse?: CourseTypeMutationResponseResolvers<ContextType>;
  CourseWaitlist?: CourseWaitlistResolvers<ContextType>;
  CourseWaitlistAggregate?: CourseWaitlistAggregateResolvers<ContextType>;
  CourseWaitlistAggregateFields?: CourseWaitlistAggregateFieldsResolvers<ContextType>;
  CourseWaitlistDesiredUrgencyType?: CourseWaitlistDesiredUrgencyTypeResolvers<ContextType>;
  CourseWaitlistDesiredUrgencyTypeAggregate?: CourseWaitlistDesiredUrgencyTypeAggregateResolvers<ContextType>;
  CourseWaitlistDesiredUrgencyTypeAggregateFields?: CourseWaitlistDesiredUrgencyTypeAggregateFieldsResolvers<ContextType>;
  CourseWaitlistDesiredUrgencyTypeMaxFields?: CourseWaitlistDesiredUrgencyTypeMaxFieldsResolvers<ContextType>;
  CourseWaitlistDesiredUrgencyTypeMinFields?: CourseWaitlistDesiredUrgencyTypeMinFieldsResolvers<ContextType>;
  CourseWaitlistDesiredUrgencyTypeMutationResponse?: CourseWaitlistDesiredUrgencyTypeMutationResponseResolvers<ContextType>;
  CourseWaitlistMaxFields?: CourseWaitlistMaxFieldsResolvers<ContextType>;
  CourseWaitlistMinFields?: CourseWaitlistMinFieldsResolvers<ContextType>;
  CourseWaitlistMutationResponse?: CourseWaitlistMutationResponseResolvers<ContextType>;
  CourseWaitlistSendAvailabilityEmailOutput?: CourseWaitlistSendAvailabilityEmailOutputResolvers<ContextType>;
  CourseWaitlistSendAvailabilityEmailUserOutPut?: CourseWaitlistSendAvailabilityEmailUserOutPutResolvers<ContextType>;
  Courses?: CoursesResolvers<ContextType>;
  CoursesAggregate?: CoursesAggregateResolvers<ContextType>;
  CoursesAggregateFields?: CoursesAggregateFieldsResolvers<ContextType>;
  CoursesAvgFields?: CoursesAvgFieldsResolvers<ContextType>;
  CoursesByPlan?: CoursesByPlanResolvers<ContextType>;
  CoursesByPlanAggregate?: CoursesByPlanAggregateResolvers<ContextType>;
  CoursesByPlanAggregateFields?: CoursesByPlanAggregateFieldsResolvers<ContextType>;
  CoursesByPlanAvgFields?: CoursesByPlanAvgFieldsResolvers<ContextType>;
  CoursesByPlanMaxFields?: CoursesByPlanMaxFieldsResolvers<ContextType>;
  CoursesByPlanMinFields?: CoursesByPlanMinFieldsResolvers<ContextType>;
  CoursesByPlanStddevFields?: CoursesByPlanStddevFieldsResolvers<ContextType>;
  CoursesByPlanStddevPopFields?: CoursesByPlanStddevPopFieldsResolvers<ContextType>;
  CoursesByPlanStddevSampFields?: CoursesByPlanStddevSampFieldsResolvers<ContextType>;
  CoursesByPlanSumFields?: CoursesByPlanSumFieldsResolvers<ContextType>;
  CoursesByPlanVarPopFields?: CoursesByPlanVarPopFieldsResolvers<ContextType>;
  CoursesByPlanVarSampFields?: CoursesByPlanVarSampFieldsResolvers<ContextType>;
  CoursesByPlanVarianceFields?: CoursesByPlanVarianceFieldsResolvers<ContextType>;
  CoursesMaxFields?: CoursesMaxFieldsResolvers<ContextType>;
  CoursesMinFields?: CoursesMinFieldsResolvers<ContextType>;
  CoursesMutationResponse?: CoursesMutationResponseResolvers<ContextType>;
  CoursesResourcesRel?: CoursesResourcesRelResolvers<ContextType>;
  CoursesResourcesRelAggregate?: CoursesResourcesRelAggregateResolvers<ContextType>;
  CoursesResourcesRelAggregateFields?: CoursesResourcesRelAggregateFieldsResolvers<ContextType>;
  CoursesResourcesRelMaxFields?: CoursesResourcesRelMaxFieldsResolvers<ContextType>;
  CoursesResourcesRelMinFields?: CoursesResourcesRelMinFieldsResolvers<ContextType>;
  CoursesResourcesRelMutationResponse?: CoursesResourcesRelMutationResponseResolvers<ContextType>;
  CoursesStddevFields?: CoursesStddevFieldsResolvers<ContextType>;
  CoursesStddevPopFields?: CoursesStddevPopFieldsResolvers<ContextType>;
  CoursesStddevSampFields?: CoursesStddevSampFieldsResolvers<ContextType>;
  CoursesSumFields?: CoursesSumFieldsResolvers<ContextType>;
  CoursesTagsRel?: CoursesTagsRelResolvers<ContextType>;
  CoursesTagsRelAggregate?: CoursesTagsRelAggregateResolvers<ContextType>;
  CoursesTagsRelAggregateFields?: CoursesTagsRelAggregateFieldsResolvers<ContextType>;
  CoursesTagsRelMaxFields?: CoursesTagsRelMaxFieldsResolvers<ContextType>;
  CoursesTagsRelMinFields?: CoursesTagsRelMinFieldsResolvers<ContextType>;
  CoursesTagsRelMutationResponse?: CoursesTagsRelMutationResponseResolvers<ContextType>;
  CoursesVarPopFields?: CoursesVarPopFieldsResolvers<ContextType>;
  CoursesVarSampFields?: CoursesVarSampFieldsResolvers<ContextType>;
  CoursesVarianceFields?: CoursesVarianceFieldsResolvers<ContextType>;
  CreateOrganizationOutput?: CreateOrganizationOutputResolvers<ContextType>;
  CreateUserOutput?: CreateUserOutputResolvers<ContextType>;
  EmailQueue?: EmailQueueResolvers<ContextType>;
  EmailQueueAggregate?: EmailQueueAggregateResolvers<ContextType>;
  EmailQueueAggregateFields?: EmailQueueAggregateFieldsResolvers<ContextType>;
  EmailQueueMaxFields?: EmailQueueMaxFieldsResolvers<ContextType>;
  EmailQueueMinFields?: EmailQueueMinFieldsResolvers<ContextType>;
  EmailQueueMutationResponse?: EmailQueueMutationResponseResolvers<ContextType>;
  EnrollOutput?: EnrollOutputResolvers<ContextType>;
  EnrollmentWaitlists?: EnrollmentWaitlistsResolvers<ContextType>;
  EnrollmentWaitlistsAggregate?: EnrollmentWaitlistsAggregateResolvers<ContextType>;
  EnrollmentWaitlistsAggregateFields?: EnrollmentWaitlistsAggregateFieldsResolvers<ContextType>;
  EnrollmentWaitlistsMaxFields?: EnrollmentWaitlistsMaxFieldsResolvers<ContextType>;
  EnrollmentWaitlistsMinFields?: EnrollmentWaitlistsMinFieldsResolvers<ContextType>;
  EnrollmentWaitlistsMutationResponse?: EnrollmentWaitlistsMutationResponseResolvers<ContextType>;
  Enrollments?: EnrollmentsResolvers<ContextType>;
  EnrollmentsAggregate?: EnrollmentsAggregateResolvers<ContextType>;
  EnrollmentsAggregateFields?: EnrollmentsAggregateFieldsResolvers<ContextType>;
  EnrollmentsMaxFields?: EnrollmentsMaxFieldsResolvers<ContextType>;
  EnrollmentsMinFields?: EnrollmentsMinFieldsResolvers<ContextType>;
  EnrollmentsMutationResponse?: EnrollmentsMutationResponseResolvers<ContextType>;
  FusionauthExternalIdentifiers?: FusionauthExternalIdentifiersResolvers<ContextType>;
  FusionauthExternalIdentifiersAggregate?: FusionauthExternalIdentifiersAggregateResolvers<ContextType>;
  FusionauthExternalIdentifiersAggregateFields?: FusionauthExternalIdentifiersAggregateFieldsResolvers<ContextType>;
  FusionauthExternalIdentifiersAvgFields?: FusionauthExternalIdentifiersAvgFieldsResolvers<ContextType>;
  FusionauthExternalIdentifiersMaxFields?: FusionauthExternalIdentifiersMaxFieldsResolvers<ContextType>;
  FusionauthExternalIdentifiersMinFields?: FusionauthExternalIdentifiersMinFieldsResolvers<ContextType>;
  FusionauthExternalIdentifiersMutationResponse?: FusionauthExternalIdentifiersMutationResponseResolvers<ContextType>;
  FusionauthExternalIdentifiersStddevFields?: FusionauthExternalIdentifiersStddevFieldsResolvers<ContextType>;
  FusionauthExternalIdentifiersStddevPopFields?: FusionauthExternalIdentifiersStddevPopFieldsResolvers<ContextType>;
  FusionauthExternalIdentifiersStddevSampFields?: FusionauthExternalIdentifiersStddevSampFieldsResolvers<ContextType>;
  FusionauthExternalIdentifiersSumFields?: FusionauthExternalIdentifiersSumFieldsResolvers<ContextType>;
  FusionauthExternalIdentifiersVarPopFields?: FusionauthExternalIdentifiersVarPopFieldsResolvers<ContextType>;
  FusionauthExternalIdentifiersVarSampFields?: FusionauthExternalIdentifiersVarSampFieldsResolvers<ContextType>;
  FusionauthExternalIdentifiersVarianceFields?: FusionauthExternalIdentifiersVarianceFieldsResolvers<ContextType>;
  FusionauthUserRegistrations?: FusionauthUserRegistrationsResolvers<ContextType>;
  FusionauthUserRegistrationsAggregate?: FusionauthUserRegistrationsAggregateResolvers<ContextType>;
  FusionauthUserRegistrationsAggregateFields?: FusionauthUserRegistrationsAggregateFieldsResolvers<ContextType>;
  FusionauthUserRegistrationsAvgFields?: FusionauthUserRegistrationsAvgFieldsResolvers<ContextType>;
  FusionauthUserRegistrationsMaxFields?: FusionauthUserRegistrationsMaxFieldsResolvers<ContextType>;
  FusionauthUserRegistrationsMinFields?: FusionauthUserRegistrationsMinFieldsResolvers<ContextType>;
  FusionauthUserRegistrationsMutationResponse?: FusionauthUserRegistrationsMutationResponseResolvers<ContextType>;
  FusionauthUserRegistrationsStddevFields?: FusionauthUserRegistrationsStddevFieldsResolvers<ContextType>;
  FusionauthUserRegistrationsStddevPopFields?: FusionauthUserRegistrationsStddevPopFieldsResolvers<ContextType>;
  FusionauthUserRegistrationsStddevSampFields?: FusionauthUserRegistrationsStddevSampFieldsResolvers<ContextType>;
  FusionauthUserRegistrationsSumFields?: FusionauthUserRegistrationsSumFieldsResolvers<ContextType>;
  FusionauthUserRegistrationsVarPopFields?: FusionauthUserRegistrationsVarPopFieldsResolvers<ContextType>;
  FusionauthUserRegistrationsVarSampFields?: FusionauthUserRegistrationsVarSampFieldsResolvers<ContextType>;
  FusionauthUserRegistrationsVarianceFields?: FusionauthUserRegistrationsVarianceFieldsResolvers<ContextType>;
  GetCohortEnrollmentStatusOutput?: GetCohortEnrollmentStatusOutputResolvers<ContextType>;
  GetMySessionsAttendedCountOutput?: GetMySessionsAttendedCountOutputResolvers<ContextType>;
  GetPastSessionAsyncAverageMetricsOutput?: GetPastSessionAsyncAverageMetricsOutputResolvers<ContextType>;
  GetPastSessionAsyncAverageMetricsPastSession?: GetPastSessionAsyncAverageMetricsPastSessionResolvers<ContextType>;
  GetPastSessionAsyncAverageMetricsSnowflakeMetrics?: GetPastSessionAsyncAverageMetricsSnowflakeMetricsResolvers<ContextType>;
  GetPastSessionAsyncMetricsMemberSessionNps?: GetPastSessionAsyncMetricsMemberSessionNpsResolvers<ContextType>;
  GetPastSessionAsyncMetricsMemberWithSessionNps?: GetPastSessionAsyncMetricsMemberWithSessionNpsResolvers<ContextType>;
  GetPastSessionAsyncMetricsMetric?: GetPastSessionAsyncMetricsMetricResolvers<ContextType>;
  GetPastSessionAsyncMetricsOutput?: GetPastSessionAsyncMetricsOutputResolvers<ContextType>;
  GetPastSessionAsyncMetricsSnowflakeMetrics?: GetPastSessionAsyncMetricsSnowflakeMetricsResolvers<ContextType>;
  Identities?: IdentitiesResolvers<ContextType>;
  IdentitiesAggregate?: IdentitiesAggregateResolvers<ContextType>;
  IdentitiesAggregateFields?: IdentitiesAggregateFieldsResolvers<ContextType>;
  IdentitiesAvgFields?: IdentitiesAvgFieldsResolvers<ContextType>;
  IdentitiesMaxFields?: IdentitiesMaxFieldsResolvers<ContextType>;
  IdentitiesMinFields?: IdentitiesMinFieldsResolvers<ContextType>;
  IdentitiesMutationResponse?: IdentitiesMutationResponseResolvers<ContextType>;
  IdentitiesStddevFields?: IdentitiesStddevFieldsResolvers<ContextType>;
  IdentitiesStddevPopFields?: IdentitiesStddevPopFieldsResolvers<ContextType>;
  IdentitiesStddevSampFields?: IdentitiesStddevSampFieldsResolvers<ContextType>;
  IdentitiesSumFields?: IdentitiesSumFieldsResolvers<ContextType>;
  IdentitiesVarPopFields?: IdentitiesVarPopFieldsResolvers<ContextType>;
  IdentitiesVarSampFields?: IdentitiesVarSampFieldsResolvers<ContextType>;
  IdentitiesVarianceFields?: IdentitiesVarianceFieldsResolvers<ContextType>;
  IdentityProviderLinks?: IdentityProviderLinksResolvers<ContextType>;
  IdentityProviderLinksAggregate?: IdentityProviderLinksAggregateResolvers<ContextType>;
  IdentityProviderLinksAggregateFields?: IdentityProviderLinksAggregateFieldsResolvers<ContextType>;
  IdentityProviderLinksAvgFields?: IdentityProviderLinksAvgFieldsResolvers<ContextType>;
  IdentityProviderLinksMaxFields?: IdentityProviderLinksMaxFieldsResolvers<ContextType>;
  IdentityProviderLinksMinFields?: IdentityProviderLinksMinFieldsResolvers<ContextType>;
  IdentityProviderLinksMutationResponse?: IdentityProviderLinksMutationResponseResolvers<ContextType>;
  IdentityProviderLinksStddevFields?: IdentityProviderLinksStddevFieldsResolvers<ContextType>;
  IdentityProviderLinksStddevPopFields?: IdentityProviderLinksStddevPopFieldsResolvers<ContextType>;
  IdentityProviderLinksStddevSampFields?: IdentityProviderLinksStddevSampFieldsResolvers<ContextType>;
  IdentityProviderLinksSumFields?: IdentityProviderLinksSumFieldsResolvers<ContextType>;
  IdentityProviderLinksVarPopFields?: IdentityProviderLinksVarPopFieldsResolvers<ContextType>;
  IdentityProviderLinksVarSampFields?: IdentityProviderLinksVarSampFieldsResolvers<ContextType>;
  IdentityProviderLinksVarianceFields?: IdentityProviderLinksVarianceFieldsResolvers<ContextType>;
  IdentityProviders?: IdentityProvidersResolvers<ContextType>;
  IdentityProvidersAggregate?: IdentityProvidersAggregateResolvers<ContextType>;
  IdentityProvidersAggregateFields?: IdentityProvidersAggregateFieldsResolvers<ContextType>;
  IdentityProvidersAvgFields?: IdentityProvidersAvgFieldsResolvers<ContextType>;
  IdentityProvidersMaxFields?: IdentityProvidersMaxFieldsResolvers<ContextType>;
  IdentityProvidersMinFields?: IdentityProvidersMinFieldsResolvers<ContextType>;
  IdentityProvidersMutationResponse?: IdentityProvidersMutationResponseResolvers<ContextType>;
  IdentityProvidersStddevFields?: IdentityProvidersStddevFieldsResolvers<ContextType>;
  IdentityProvidersStddevPopFields?: IdentityProvidersStddevPopFieldsResolvers<ContextType>;
  IdentityProvidersStddevSampFields?: IdentityProvidersStddevSampFieldsResolvers<ContextType>;
  IdentityProvidersSumFields?: IdentityProvidersSumFieldsResolvers<ContextType>;
  IdentityProvidersVarPopFields?: IdentityProvidersVarPopFieldsResolvers<ContextType>;
  IdentityProvidersVarSampFields?: IdentityProvidersVarSampFieldsResolvers<ContextType>;
  IdentityProvidersVarianceFields?: IdentityProvidersVarianceFieldsResolvers<ContextType>;
  ImpactAssessmentAttempts?: ImpactAssessmentAttemptsResolvers<ContextType>;
  ImpactAssessmentAttemptsAggregate?: ImpactAssessmentAttemptsAggregateResolvers<ContextType>;
  ImpactAssessmentAttemptsAggregateFields?: ImpactAssessmentAttemptsAggregateFieldsResolvers<ContextType>;
  ImpactAssessmentAttemptsAvgFields?: ImpactAssessmentAttemptsAvgFieldsResolvers<ContextType>;
  ImpactAssessmentAttemptsMaxFields?: ImpactAssessmentAttemptsMaxFieldsResolvers<ContextType>;
  ImpactAssessmentAttemptsMinFields?: ImpactAssessmentAttemptsMinFieldsResolvers<ContextType>;
  ImpactAssessmentAttemptsMutationResponse?: ImpactAssessmentAttemptsMutationResponseResolvers<ContextType>;
  ImpactAssessmentAttemptsStddevFields?: ImpactAssessmentAttemptsStddevFieldsResolvers<ContextType>;
  ImpactAssessmentAttemptsStddevPopFields?: ImpactAssessmentAttemptsStddevPopFieldsResolvers<ContextType>;
  ImpactAssessmentAttemptsStddevSampFields?: ImpactAssessmentAttemptsStddevSampFieldsResolvers<ContextType>;
  ImpactAssessmentAttemptsSumFields?: ImpactAssessmentAttemptsSumFieldsResolvers<ContextType>;
  ImpactAssessmentAttemptsVarPopFields?: ImpactAssessmentAttemptsVarPopFieldsResolvers<ContextType>;
  ImpactAssessmentAttemptsVarSampFields?: ImpactAssessmentAttemptsVarSampFieldsResolvers<ContextType>;
  ImpactAssessmentAttemptsVarianceFields?: ImpactAssessmentAttemptsVarianceFieldsResolvers<ContextType>;
  ImpactAssessmentQuestions?: ImpactAssessmentQuestionsResolvers<ContextType>;
  ImpactAssessmentQuestionsAggregate?: ImpactAssessmentQuestionsAggregateResolvers<ContextType>;
  ImpactAssessmentQuestionsAggregateFields?: ImpactAssessmentQuestionsAggregateFieldsResolvers<ContextType>;
  ImpactAssessmentQuestionsAvgFields?: ImpactAssessmentQuestionsAvgFieldsResolvers<ContextType>;
  ImpactAssessmentQuestionsMaxFields?: ImpactAssessmentQuestionsMaxFieldsResolvers<ContextType>;
  ImpactAssessmentQuestionsMinFields?: ImpactAssessmentQuestionsMinFieldsResolvers<ContextType>;
  ImpactAssessmentQuestionsMutationResponse?: ImpactAssessmentQuestionsMutationResponseResolvers<ContextType>;
  ImpactAssessmentQuestionsStddevFields?: ImpactAssessmentQuestionsStddevFieldsResolvers<ContextType>;
  ImpactAssessmentQuestionsStddevPopFields?: ImpactAssessmentQuestionsStddevPopFieldsResolvers<ContextType>;
  ImpactAssessmentQuestionsStddevSampFields?: ImpactAssessmentQuestionsStddevSampFieldsResolvers<ContextType>;
  ImpactAssessmentQuestionsSumFields?: ImpactAssessmentQuestionsSumFieldsResolvers<ContextType>;
  ImpactAssessmentQuestionsVarPopFields?: ImpactAssessmentQuestionsVarPopFieldsResolvers<ContextType>;
  ImpactAssessmentQuestionsVarSampFields?: ImpactAssessmentQuestionsVarSampFieldsResolvers<ContextType>;
  ImpactAssessmentQuestionsVarianceFields?: ImpactAssessmentQuestionsVarianceFieldsResolvers<ContextType>;
  ImpactAssessments?: ImpactAssessmentsResolvers<ContextType>;
  ImpactAssessmentsAggregate?: ImpactAssessmentsAggregateResolvers<ContextType>;
  ImpactAssessmentsAggregateFields?: ImpactAssessmentsAggregateFieldsResolvers<ContextType>;
  ImpactAssessmentsAvgFields?: ImpactAssessmentsAvgFieldsResolvers<ContextType>;
  ImpactAssessmentsMaxFields?: ImpactAssessmentsMaxFieldsResolvers<ContextType>;
  ImpactAssessmentsMinFields?: ImpactAssessmentsMinFieldsResolvers<ContextType>;
  ImpactAssessmentsMutationResponse?: ImpactAssessmentsMutationResponseResolvers<ContextType>;
  ImpactAssessmentsStddevFields?: ImpactAssessmentsStddevFieldsResolvers<ContextType>;
  ImpactAssessmentsStddevPopFields?: ImpactAssessmentsStddevPopFieldsResolvers<ContextType>;
  ImpactAssessmentsStddevSampFields?: ImpactAssessmentsStddevSampFieldsResolvers<ContextType>;
  ImpactAssessmentsSumFields?: ImpactAssessmentsSumFieldsResolvers<ContextType>;
  ImpactAssessmentsVarPopFields?: ImpactAssessmentsVarPopFieldsResolvers<ContextType>;
  ImpactAssessmentsVarSampFields?: ImpactAssessmentsVarSampFieldsResolvers<ContextType>;
  ImpactAssessmentsVarianceFields?: ImpactAssessmentsVarianceFieldsResolvers<ContextType>;
  JourneyEnrollments?: JourneyEnrollmentsResolvers<ContextType>;
  JourneyEnrollmentsAggregate?: JourneyEnrollmentsAggregateResolvers<ContextType>;
  JourneyEnrollmentsAggregateFields?: JourneyEnrollmentsAggregateFieldsResolvers<ContextType>;
  JourneyEnrollmentsMaxFields?: JourneyEnrollmentsMaxFieldsResolvers<ContextType>;
  JourneyEnrollmentsMinFields?: JourneyEnrollmentsMinFieldsResolvers<ContextType>;
  JourneyEnrollmentsMutationResponse?: JourneyEnrollmentsMutationResponseResolvers<ContextType>;
  Journeys?: JourneysResolvers<ContextType>;
  JourneysAggregate?: JourneysAggregateResolvers<ContextType>;
  JourneysAggregateFields?: JourneysAggregateFieldsResolvers<ContextType>;
  JourneysMaxFields?: JourneysMaxFieldsResolvers<ContextType>;
  JourneysMinFields?: JourneysMinFieldsResolvers<ContextType>;
  JourneysMutationResponse?: JourneysMutationResponseResolvers<ContextType>;
  Ledger?: LedgerResolvers<ContextType>;
  LedgerAggregate?: LedgerAggregateResolvers<ContextType>;
  LedgerAggregateFields?: LedgerAggregateFieldsResolvers<ContextType>;
  LedgerAvgFields?: LedgerAvgFieldsResolvers<ContextType>;
  LedgerMaxFields?: LedgerMaxFieldsResolvers<ContextType>;
  LedgerMinFields?: LedgerMinFieldsResolvers<ContextType>;
  LedgerMutationResponse?: LedgerMutationResponseResolvers<ContextType>;
  LedgerProductEnum?: LedgerProductEnumResolvers<ContextType>;
  LedgerProductEnumAggregate?: LedgerProductEnumAggregateResolvers<ContextType>;
  LedgerProductEnumAggregateFields?: LedgerProductEnumAggregateFieldsResolvers<ContextType>;
  LedgerProductEnumMaxFields?: LedgerProductEnumMaxFieldsResolvers<ContextType>;
  LedgerProductEnumMinFields?: LedgerProductEnumMinFieldsResolvers<ContextType>;
  LedgerProductEnumMutationResponse?: LedgerProductEnumMutationResponseResolvers<ContextType>;
  LedgerStddevFields?: LedgerStddevFieldsResolvers<ContextType>;
  LedgerStddevPopFields?: LedgerStddevPopFieldsResolvers<ContextType>;
  LedgerStddevSampFields?: LedgerStddevSampFieldsResolvers<ContextType>;
  LedgerSumFields?: LedgerSumFieldsResolvers<ContextType>;
  LedgerTransactionTypeEnum?: LedgerTransactionTypeEnumResolvers<ContextType>;
  LedgerTransactionTypeEnumAggregate?: LedgerTransactionTypeEnumAggregateResolvers<ContextType>;
  LedgerTransactionTypeEnumAggregateFields?: LedgerTransactionTypeEnumAggregateFieldsResolvers<ContextType>;
  LedgerTransactionTypeEnumMaxFields?: LedgerTransactionTypeEnumMaxFieldsResolvers<ContextType>;
  LedgerTransactionTypeEnumMinFields?: LedgerTransactionTypeEnumMinFieldsResolvers<ContextType>;
  LedgerTransactionTypeEnumMutationResponse?: LedgerTransactionTypeEnumMutationResponseResolvers<ContextType>;
  LedgerVarPopFields?: LedgerVarPopFieldsResolvers<ContextType>;
  LedgerVarSampFields?: LedgerVarSampFieldsResolvers<ContextType>;
  LedgerVarianceFields?: LedgerVarianceFieldsResolvers<ContextType>;
  LightningSession?: LightningSessionResolvers<ContextType>;
  LightningSessionAggregate?: LightningSessionAggregateResolvers<ContextType>;
  LightningSessionAggregateFields?: LightningSessionAggregateFieldsResolvers<ContextType>;
  LightningSessionMaxFields?: LightningSessionMaxFieldsResolvers<ContextType>;
  LightningSessionMinFields?: LightningSessionMinFieldsResolvers<ContextType>;
  LightningSessionMutationResponse?: LightningSessionMutationResponseResolvers<ContextType>;
  LightningSessionSubmissions?: LightningSessionSubmissionsResolvers<ContextType>;
  LightningSessionSubmissionsAggregate?: LightningSessionSubmissionsAggregateResolvers<ContextType>;
  LightningSessionSubmissionsAggregateFields?: LightningSessionSubmissionsAggregateFieldsResolvers<ContextType>;
  LightningSessionSubmissionsAvgFields?: LightningSessionSubmissionsAvgFieldsResolvers<ContextType>;
  LightningSessionSubmissionsMaxFields?: LightningSessionSubmissionsMaxFieldsResolvers<ContextType>;
  LightningSessionSubmissionsMinFields?: LightningSessionSubmissionsMinFieldsResolvers<ContextType>;
  LightningSessionSubmissionsMutationResponse?: LightningSessionSubmissionsMutationResponseResolvers<ContextType>;
  LightningSessionSubmissionsStddevFields?: LightningSessionSubmissionsStddevFieldsResolvers<ContextType>;
  LightningSessionSubmissionsStddevPopFields?: LightningSessionSubmissionsStddevPopFieldsResolvers<ContextType>;
  LightningSessionSubmissionsStddevSampFields?: LightningSessionSubmissionsStddevSampFieldsResolvers<ContextType>;
  LightningSessionSubmissionsSumFields?: LightningSessionSubmissionsSumFieldsResolvers<ContextType>;
  LightningSessionSubmissionsUsersRel?: LightningSessionSubmissionsUsersRelResolvers<ContextType>;
  LightningSessionSubmissionsUsersRelAggregate?: LightningSessionSubmissionsUsersRelAggregateResolvers<ContextType>;
  LightningSessionSubmissionsUsersRelAggregateFields?: LightningSessionSubmissionsUsersRelAggregateFieldsResolvers<ContextType>;
  LightningSessionSubmissionsUsersRelMaxFields?: LightningSessionSubmissionsUsersRelMaxFieldsResolvers<ContextType>;
  LightningSessionSubmissionsUsersRelMinFields?: LightningSessionSubmissionsUsersRelMinFieldsResolvers<ContextType>;
  LightningSessionSubmissionsUsersRelMutationResponse?: LightningSessionSubmissionsUsersRelMutationResponseResolvers<ContextType>;
  LightningSessionSubmissionsVarPopFields?: LightningSessionSubmissionsVarPopFieldsResolvers<ContextType>;
  LightningSessionSubmissionsVarSampFields?: LightningSessionSubmissionsVarSampFieldsResolvers<ContextType>;
  LightningSessionSubmissionsVarianceFields?: LightningSessionSubmissionsVarianceFieldsResolvers<ContextType>;
  LiveClasses?: LiveClassesResolvers<ContextType>;
  LiveClassesAggregate?: LiveClassesAggregateResolvers<ContextType>;
  LiveClassesAggregateFields?: LiveClassesAggregateFieldsResolvers<ContextType>;
  LiveClassesAvgFields?: LiveClassesAvgFieldsResolvers<ContextType>;
  LiveClassesMaxFields?: LiveClassesMaxFieldsResolvers<ContextType>;
  LiveClassesMinFields?: LiveClassesMinFieldsResolvers<ContextType>;
  LiveClassesStddevFields?: LiveClassesStddevFieldsResolvers<ContextType>;
  LiveClassesStddevPopFields?: LiveClassesStddevPopFieldsResolvers<ContextType>;
  LiveClassesStddevSampFields?: LiveClassesStddevSampFieldsResolvers<ContextType>;
  LiveClassesSumFields?: LiveClassesSumFieldsResolvers<ContextType>;
  LiveClassesVarPopFields?: LiveClassesVarPopFieldsResolvers<ContextType>;
  LiveClassesVarSampFields?: LiveClassesVarSampFieldsResolvers<ContextType>;
  LiveClassesVarianceFields?: LiveClassesVarianceFieldsResolvers<ContextType>;
  ManagerSessionEmails?: ManagerSessionEmailsResolvers<ContextType>;
  ManagerSessionEmailsAggregate?: ManagerSessionEmailsAggregateResolvers<ContextType>;
  ManagerSessionEmailsAggregateFields?: ManagerSessionEmailsAggregateFieldsResolvers<ContextType>;
  ManagerSessionEmailsAvgFields?: ManagerSessionEmailsAvgFieldsResolvers<ContextType>;
  ManagerSessionEmailsMaxFields?: ManagerSessionEmailsMaxFieldsResolvers<ContextType>;
  ManagerSessionEmailsMinFields?: ManagerSessionEmailsMinFieldsResolvers<ContextType>;
  ManagerSessionEmailsMutationResponse?: ManagerSessionEmailsMutationResponseResolvers<ContextType>;
  ManagerSessionEmailsStddevFields?: ManagerSessionEmailsStddevFieldsResolvers<ContextType>;
  ManagerSessionEmailsStddevPopFields?: ManagerSessionEmailsStddevPopFieldsResolvers<ContextType>;
  ManagerSessionEmailsStddevSampFields?: ManagerSessionEmailsStddevSampFieldsResolvers<ContextType>;
  ManagerSessionEmailsSumFields?: ManagerSessionEmailsSumFieldsResolvers<ContextType>;
  ManagerSessionEmailsVarPopFields?: ManagerSessionEmailsVarPopFieldsResolvers<ContextType>;
  ManagerSessionEmailsVarSampFields?: ManagerSessionEmailsVarSampFieldsResolvers<ContextType>;
  ManagerSessionEmailsVarianceFields?: ManagerSessionEmailsVarianceFieldsResolvers<ContextType>;
  MarkRecordingWatchedOutput?: MarkRecordingWatchedOutputResolvers<ContextType>;
  MyCohortLearningStatus?: MyCohortLearningStatusResolvers<ContextType>;
  MyCohortLearningStatusAggregate?: MyCohortLearningStatusAggregateResolvers<ContextType>;
  MyCohortLearningStatusAggregateFields?: MyCohortLearningStatusAggregateFieldsResolvers<ContextType>;
  MyCohortLearningStatusAvgFields?: MyCohortLearningStatusAvgFieldsResolvers<ContextType>;
  MyCohortLearningStatusMaxFields?: MyCohortLearningStatusMaxFieldsResolvers<ContextType>;
  MyCohortLearningStatusMinFields?: MyCohortLearningStatusMinFieldsResolvers<ContextType>;
  MyCohortLearningStatusStddevFields?: MyCohortLearningStatusStddevFieldsResolvers<ContextType>;
  MyCohortLearningStatusStddevPopFields?: MyCohortLearningStatusStddevPopFieldsResolvers<ContextType>;
  MyCohortLearningStatusStddevSampFields?: MyCohortLearningStatusStddevSampFieldsResolvers<ContextType>;
  MyCohortLearningStatusSumFields?: MyCohortLearningStatusSumFieldsResolvers<ContextType>;
  MyCohortLearningStatusVarPopFields?: MyCohortLearningStatusVarPopFieldsResolvers<ContextType>;
  MyCohortLearningStatusVarSampFields?: MyCohortLearningStatusVarSampFieldsResolvers<ContextType>;
  MyCohortLearningStatusVarianceFields?: MyCohortLearningStatusVarianceFieldsResolvers<ContextType>;
  MyLearnerHomeCourses?: MyLearnerHomeCoursesResolvers<ContextType>;
  MyLearnerHomeCoursesAggregate?: MyLearnerHomeCoursesAggregateResolvers<ContextType>;
  MyLearnerHomeCoursesAggregateFields?: MyLearnerHomeCoursesAggregateFieldsResolvers<ContextType>;
  MyLearnerHomeCoursesAvgFields?: MyLearnerHomeCoursesAvgFieldsResolvers<ContextType>;
  MyLearnerHomeCoursesMaxFields?: MyLearnerHomeCoursesMaxFieldsResolvers<ContextType>;
  MyLearnerHomeCoursesMinFields?: MyLearnerHomeCoursesMinFieldsResolvers<ContextType>;
  MyLearnerHomeCoursesStddevFields?: MyLearnerHomeCoursesStddevFieldsResolvers<ContextType>;
  MyLearnerHomeCoursesStddevPopFields?: MyLearnerHomeCoursesStddevPopFieldsResolvers<ContextType>;
  MyLearnerHomeCoursesStddevSampFields?: MyLearnerHomeCoursesStddevSampFieldsResolvers<ContextType>;
  MyLearnerHomeCoursesSumFields?: MyLearnerHomeCoursesSumFieldsResolvers<ContextType>;
  MyLearnerHomeCoursesVarPopFields?: MyLearnerHomeCoursesVarPopFieldsResolvers<ContextType>;
  MyLearnerHomeCoursesVarSampFields?: MyLearnerHomeCoursesVarSampFieldsResolvers<ContextType>;
  MyLearnerHomeCoursesVarianceFields?: MyLearnerHomeCoursesVarianceFieldsResolvers<ContextType>;
  MyLearnerHomeLiveCoaching?: MyLearnerHomeLiveCoachingResolvers<ContextType>;
  MyLearnerHomeLiveCoachingAggregate?: MyLearnerHomeLiveCoachingAggregateResolvers<ContextType>;
  MyLearnerHomeLiveCoachingAggregateFields?: MyLearnerHomeLiveCoachingAggregateFieldsResolvers<ContextType>;
  MyLearnerHomeLiveCoachingAvgFields?: MyLearnerHomeLiveCoachingAvgFieldsResolvers<ContextType>;
  MyLearnerHomeLiveCoachingMaxFields?: MyLearnerHomeLiveCoachingMaxFieldsResolvers<ContextType>;
  MyLearnerHomeLiveCoachingMinFields?: MyLearnerHomeLiveCoachingMinFieldsResolvers<ContextType>;
  MyLearnerHomeLiveCoachingStddevFields?: MyLearnerHomeLiveCoachingStddevFieldsResolvers<ContextType>;
  MyLearnerHomeLiveCoachingStddevPopFields?: MyLearnerHomeLiveCoachingStddevPopFieldsResolvers<ContextType>;
  MyLearnerHomeLiveCoachingStddevSampFields?: MyLearnerHomeLiveCoachingStddevSampFieldsResolvers<ContextType>;
  MyLearnerHomeLiveCoachingSumFields?: MyLearnerHomeLiveCoachingSumFieldsResolvers<ContextType>;
  MyLearnerHomeLiveCoachingVarPopFields?: MyLearnerHomeLiveCoachingVarPopFieldsResolvers<ContextType>;
  MyLearnerHomeLiveCoachingVarSampFields?: MyLearnerHomeLiveCoachingVarSampFieldsResolvers<ContextType>;
  MyLearnerHomeLiveCoachingVarianceFields?: MyLearnerHomeLiveCoachingVarianceFieldsResolvers<ContextType>;
  MyLightningSessionCohortsLearningStatus?: MyLightningSessionCohortsLearningStatusResolvers<ContextType>;
  MyLightningSessionCohortsLearningStatusAggregate?: MyLightningSessionCohortsLearningStatusAggregateResolvers<ContextType>;
  MyLightningSessionCohortsLearningStatusAggregateFields?: MyLightningSessionCohortsLearningStatusAggregateFieldsResolvers<ContextType>;
  MyLightningSessionCohortsLearningStatusAvgFields?: MyLightningSessionCohortsLearningStatusAvgFieldsResolvers<ContextType>;
  MyLightningSessionCohortsLearningStatusMaxFields?: MyLightningSessionCohortsLearningStatusMaxFieldsResolvers<ContextType>;
  MyLightningSessionCohortsLearningStatusMinFields?: MyLightningSessionCohortsLearningStatusMinFieldsResolvers<ContextType>;
  MyLightningSessionCohortsLearningStatusStddevFields?: MyLightningSessionCohortsLearningStatusStddevFieldsResolvers<ContextType>;
  MyLightningSessionCohortsLearningStatusStddevPopFields?: MyLightningSessionCohortsLearningStatusStddevPopFieldsResolvers<ContextType>;
  MyLightningSessionCohortsLearningStatusStddevSampFields?: MyLightningSessionCohortsLearningStatusStddevSampFieldsResolvers<ContextType>;
  MyLightningSessionCohortsLearningStatusSumFields?: MyLightningSessionCohortsLearningStatusSumFieldsResolvers<ContextType>;
  MyLightningSessionCohortsLearningStatusVarPopFields?: MyLightningSessionCohortsLearningStatusVarPopFieldsResolvers<ContextType>;
  MyLightningSessionCohortsLearningStatusVarSampFields?: MyLightningSessionCohortsLearningStatusVarSampFieldsResolvers<ContextType>;
  MyLightningSessionCohortsLearningStatusVarianceFields?: MyLightningSessionCohortsLearningStatusVarianceFieldsResolvers<ContextType>;
  MyTeamMember?: MyTeamMemberResolvers<ContextType>;
  MyTeamMembersOutput?: MyTeamMembersOutputResolvers<ContextType>;
  MyTraditionalCohortsLearningStatus?: MyTraditionalCohortsLearningStatusResolvers<ContextType>;
  MyTraditionalCohortsLearningStatusAggregate?: MyTraditionalCohortsLearningStatusAggregateResolvers<ContextType>;
  MyTraditionalCohortsLearningStatusAggregateFields?: MyTraditionalCohortsLearningStatusAggregateFieldsResolvers<ContextType>;
  MyTraditionalCohortsLearningStatusAvgFields?: MyTraditionalCohortsLearningStatusAvgFieldsResolvers<ContextType>;
  MyTraditionalCohortsLearningStatusMaxFields?: MyTraditionalCohortsLearningStatusMaxFieldsResolvers<ContextType>;
  MyTraditionalCohortsLearningStatusMinFields?: MyTraditionalCohortsLearningStatusMinFieldsResolvers<ContextType>;
  MyTraditionalCohortsLearningStatusStddevFields?: MyTraditionalCohortsLearningStatusStddevFieldsResolvers<ContextType>;
  MyTraditionalCohortsLearningStatusStddevPopFields?: MyTraditionalCohortsLearningStatusStddevPopFieldsResolvers<ContextType>;
  MyTraditionalCohortsLearningStatusStddevSampFields?: MyTraditionalCohortsLearningStatusStddevSampFieldsResolvers<ContextType>;
  MyTraditionalCohortsLearningStatusSumFields?: MyTraditionalCohortsLearningStatusSumFieldsResolvers<ContextType>;
  MyTraditionalCohortsLearningStatusVarPopFields?: MyTraditionalCohortsLearningStatusVarPopFieldsResolvers<ContextType>;
  MyTraditionalCohortsLearningStatusVarSampFields?: MyTraditionalCohortsLearningStatusVarSampFieldsResolvers<ContextType>;
  MyTraditionalCohortsLearningStatusVarianceFields?: MyTraditionalCohortsLearningStatusVarianceFieldsResolvers<ContextType>;
  NonceAccessToken?: NonceAccessTokenResolvers<ContextType>;
  NonceAccessTokenAggregate?: NonceAccessTokenAggregateResolvers<ContextType>;
  NonceAccessTokenAggregateFields?: NonceAccessTokenAggregateFieldsResolvers<ContextType>;
  NonceAccessTokenMaxFields?: NonceAccessTokenMaxFieldsResolvers<ContextType>;
  NonceAccessTokenMinFields?: NonceAccessTokenMinFieldsResolvers<ContextType>;
  NonceAccessTokenMutationResponse?: NonceAccessTokenMutationResponseResolvers<ContextType>;
  OrganizationUsers?: OrganizationUsersResolvers<ContextType>;
  OrganizationUsersAggregate?: OrganizationUsersAggregateResolvers<ContextType>;
  OrganizationUsersAggregateFields?: OrganizationUsersAggregateFieldsResolvers<ContextType>;
  OrganizationUsersAvgFields?: OrganizationUsersAvgFieldsResolvers<ContextType>;
  OrganizationUsersMaxFields?: OrganizationUsersMaxFieldsResolvers<ContextType>;
  OrganizationUsersMinFields?: OrganizationUsersMinFieldsResolvers<ContextType>;
  OrganizationUsersMutationResponse?: OrganizationUsersMutationResponseResolvers<ContextType>;
  OrganizationUsersStddevFields?: OrganizationUsersStddevFieldsResolvers<ContextType>;
  OrganizationUsersStddevPopFields?: OrganizationUsersStddevPopFieldsResolvers<ContextType>;
  OrganizationUsersStddevSampFields?: OrganizationUsersStddevSampFieldsResolvers<ContextType>;
  OrganizationUsersSumFields?: OrganizationUsersSumFieldsResolvers<ContextType>;
  OrganizationUsersVarPopFields?: OrganizationUsersVarPopFieldsResolvers<ContextType>;
  OrganizationUsersVarSampFields?: OrganizationUsersVarSampFieldsResolvers<ContextType>;
  OrganizationUsersVarianceFields?: OrganizationUsersVarianceFieldsResolvers<ContextType>;
  Organizations?: OrganizationsResolvers<ContextType>;
  OrganizationsAggregate?: OrganizationsAggregateResolvers<ContextType>;
  OrganizationsAggregateFields?: OrganizationsAggregateFieldsResolvers<ContextType>;
  OrganizationsAvgFields?: OrganizationsAvgFieldsResolvers<ContextType>;
  OrganizationsMaxFields?: OrganizationsMaxFieldsResolvers<ContextType>;
  OrganizationsMeta?: OrganizationsMetaResolvers<ContextType>;
  OrganizationsMetaAggregate?: OrganizationsMetaAggregateResolvers<ContextType>;
  OrganizationsMetaAggregateFields?: OrganizationsMetaAggregateFieldsResolvers<ContextType>;
  OrganizationsMetaMaxFields?: OrganizationsMetaMaxFieldsResolvers<ContextType>;
  OrganizationsMetaMinFields?: OrganizationsMetaMinFieldsResolvers<ContextType>;
  OrganizationsMetaMutationResponse?: OrganizationsMetaMutationResponseResolvers<ContextType>;
  OrganizationsMinFields?: OrganizationsMinFieldsResolvers<ContextType>;
  OrganizationsMutationResponse?: OrganizationsMutationResponseResolvers<ContextType>;
  OrganizationsStddevFields?: OrganizationsStddevFieldsResolvers<ContextType>;
  OrganizationsStddevPopFields?: OrganizationsStddevPopFieldsResolvers<ContextType>;
  OrganizationsStddevSampFields?: OrganizationsStddevSampFieldsResolvers<ContextType>;
  OrganizationsSumFields?: OrganizationsSumFieldsResolvers<ContextType>;
  OrganizationsVarPopFields?: OrganizationsVarPopFieldsResolvers<ContextType>;
  OrganizationsVarSampFields?: OrganizationsVarSampFieldsResolvers<ContextType>;
  OrganizationsVarianceFields?: OrganizationsVarianceFieldsResolvers<ContextType>;
  PlanSubscriptions?: PlanSubscriptionsResolvers<ContextType>;
  PlanSubscriptionsAggregate?: PlanSubscriptionsAggregateResolvers<ContextType>;
  PlanSubscriptionsAggregateFields?: PlanSubscriptionsAggregateFieldsResolvers<ContextType>;
  PlanSubscriptionsMaxFields?: PlanSubscriptionsMaxFieldsResolvers<ContextType>;
  PlanSubscriptionsMinFields?: PlanSubscriptionsMinFieldsResolvers<ContextType>;
  PlanSubscriptionsMutationResponse?: PlanSubscriptionsMutationResponseResolvers<ContextType>;
  Plans?: PlansResolvers<ContextType>;
  PlansAggregate?: PlansAggregateResolvers<ContextType>;
  PlansAggregateFields?: PlansAggregateFieldsResolvers<ContextType>;
  PlansMaxFields?: PlansMaxFieldsResolvers<ContextType>;
  PlansMinFields?: PlansMinFieldsResolvers<ContextType>;
  PlansMutationResponse?: PlansMutationResponseResolvers<ContextType>;
  PlayExecution?: PlayExecutionResolvers<ContextType>;
  PlayExecutionAggregate?: PlayExecutionAggregateResolvers<ContextType>;
  PlayExecutionAggregateFields?: PlayExecutionAggregateFieldsResolvers<ContextType>;
  PlayExecutionMaxFields?: PlayExecutionMaxFieldsResolvers<ContextType>;
  PlayExecutionMinFields?: PlayExecutionMinFieldsResolvers<ContextType>;
  PlayExecutionMutationResponse?: PlayExecutionMutationResponseResolvers<ContextType>;
  ProgramActionsTaken?: ProgramActionsTakenResolvers<ContextType>;
  ProgramActionsTakenAggregate?: ProgramActionsTakenAggregateResolvers<ContextType>;
  ProgramActionsTakenAggregateFields?: ProgramActionsTakenAggregateFieldsResolvers<ContextType>;
  ProgramActionsTakenMaxFields?: ProgramActionsTakenMaxFieldsResolvers<ContextType>;
  ProgramActionsTakenMinFields?: ProgramActionsTakenMinFieldsResolvers<ContextType>;
  ProgramActionsTakenMutationResponse?: ProgramActionsTakenMutationResponseResolvers<ContextType>;
  ProgramCoursesShown?: ProgramCoursesShownResolvers<ContextType>;
  ProgramCoursesShownAggregate?: ProgramCoursesShownAggregateResolvers<ContextType>;
  ProgramCoursesShownAggregateFields?: ProgramCoursesShownAggregateFieldsResolvers<ContextType>;
  ProgramCoursesShownMaxFields?: ProgramCoursesShownMaxFieldsResolvers<ContextType>;
  ProgramCoursesShownMinFields?: ProgramCoursesShownMinFieldsResolvers<ContextType>;
  ProgramCoursesShownMutationResponse?: ProgramCoursesShownMutationResponseResolvers<ContextType>;
  ProgramTags?: ProgramTagsResolvers<ContextType>;
  ProgramTagsAggregate?: ProgramTagsAggregateResolvers<ContextType>;
  ProgramTagsAggregateFields?: ProgramTagsAggregateFieldsResolvers<ContextType>;
  ProgramTagsMaxFields?: ProgramTagsMaxFieldsResolvers<ContextType>;
  ProgramTagsMinFields?: ProgramTagsMinFieldsResolvers<ContextType>;
  ProgramTagsMutationResponse?: ProgramTagsMutationResponseResolvers<ContextType>;
  ProgramUsersSelected?: ProgramUsersSelectedResolvers<ContextType>;
  ProgramUsersSelectedAggregate?: ProgramUsersSelectedAggregateResolvers<ContextType>;
  ProgramUsersSelectedAggregateFields?: ProgramUsersSelectedAggregateFieldsResolvers<ContextType>;
  ProgramUsersSelectedMaxFields?: ProgramUsersSelectedMaxFieldsResolvers<ContextType>;
  ProgramUsersSelectedMinFields?: ProgramUsersSelectedMinFieldsResolvers<ContextType>;
  ProgramUsersSelectedMutationResponse?: ProgramUsersSelectedMutationResponseResolvers<ContextType>;
  Programs?: ProgramsResolvers<ContextType>;
  ProgramsAggregate?: ProgramsAggregateResolvers<ContextType>;
  ProgramsAggregateFields?: ProgramsAggregateFieldsResolvers<ContextType>;
  ProgramsMaxFields?: ProgramsMaxFieldsResolvers<ContextType>;
  ProgramsMinFields?: ProgramsMinFieldsResolvers<ContextType>;
  ProgramsMutationResponse?: ProgramsMutationResponseResolvers<ContextType>;
  QuestionType?: QuestionTypeResolvers<ContextType>;
  QuestionTypeAggregate?: QuestionTypeAggregateResolvers<ContextType>;
  QuestionTypeAggregateFields?: QuestionTypeAggregateFieldsResolvers<ContextType>;
  QuestionTypeMaxFields?: QuestionTypeMaxFieldsResolvers<ContextType>;
  QuestionTypeMinFields?: QuestionTypeMinFieldsResolvers<ContextType>;
  QuestionTypeMutationResponse?: QuestionTypeMutationResponseResolvers<ContextType>;
  RecordingActivity?: RecordingActivityResolvers<ContextType>;
  RecordingActivityAggregate?: RecordingActivityAggregateResolvers<ContextType>;
  RecordingActivityAggregateFields?: RecordingActivityAggregateFieldsResolvers<ContextType>;
  RecordingActivityMaxFields?: RecordingActivityMaxFieldsResolvers<ContextType>;
  RecordingActivityMinFields?: RecordingActivityMinFieldsResolvers<ContextType>;
  RecordingActivityMutationResponse?: RecordingActivityMutationResponseResolvers<ContextType>;
  Recordings?: RecordingsResolvers<ContextType>;
  RecordingsAggregate?: RecordingsAggregateResolvers<ContextType>;
  RecordingsAggregateFields?: RecordingsAggregateFieldsResolvers<ContextType>;
  RecordingsMaxFields?: RecordingsMaxFieldsResolvers<ContextType>;
  RecordingsMinFields?: RecordingsMinFieldsResolvers<ContextType>;
  RecordingsMutationResponse?: RecordingsMutationResponseResolvers<ContextType>;
  RegisterAnonOutput?: RegisterAnonOutputResolvers<ContextType>;
  RelatedCourses?: RelatedCoursesResolvers<ContextType>;
  RelatedCoursesAggregate?: RelatedCoursesAggregateResolvers<ContextType>;
  RelatedCoursesAggregateFields?: RelatedCoursesAggregateFieldsResolvers<ContextType>;
  RelatedCoursesMaxFields?: RelatedCoursesMaxFieldsResolvers<ContextType>;
  RelatedCoursesMinFields?: RelatedCoursesMinFieldsResolvers<ContextType>;
  RelatedCoursesMutationResponse?: RelatedCoursesMutationResponseResolvers<ContextType>;
  ReportPublishStatus?: ReportPublishStatusResolvers<ContextType>;
  ReportPublishStatusAggregate?: ReportPublishStatusAggregateResolvers<ContextType>;
  ReportPublishStatusAggregateFields?: ReportPublishStatusAggregateFieldsResolvers<ContextType>;
  ReportPublishStatusMaxFields?: ReportPublishStatusMaxFieldsResolvers<ContextType>;
  ReportPublishStatusMinFields?: ReportPublishStatusMinFieldsResolvers<ContextType>;
  ReportPublishStatusMutationResponse?: ReportPublishStatusMutationResponseResolvers<ContextType>;
  ReportType?: ReportTypeResolvers<ContextType>;
  ReportTypeAggregate?: ReportTypeAggregateResolvers<ContextType>;
  ReportTypeAggregateFields?: ReportTypeAggregateFieldsResolvers<ContextType>;
  ReportTypeMaxFields?: ReportTypeMaxFieldsResolvers<ContextType>;
  ReportTypeMinFields?: ReportTypeMinFieldsResolvers<ContextType>;
  ReportTypeMutationResponse?: ReportTypeMutationResponseResolvers<ContextType>;
  Reports?: ReportsResolvers<ContextType>;
  ReportsAggregate?: ReportsAggregateResolvers<ContextType>;
  ReportsAggregateFields?: ReportsAggregateFieldsResolvers<ContextType>;
  ReportsAvgFields?: ReportsAvgFieldsResolvers<ContextType>;
  ReportsMaxFields?: ReportsMaxFieldsResolvers<ContextType>;
  ReportsMinFields?: ReportsMinFieldsResolvers<ContextType>;
  ReportsMutationResponse?: ReportsMutationResponseResolvers<ContextType>;
  ReportsStddevFields?: ReportsStddevFieldsResolvers<ContextType>;
  ReportsStddevPopFields?: ReportsStddevPopFieldsResolvers<ContextType>;
  ReportsStddevSampFields?: ReportsStddevSampFieldsResolvers<ContextType>;
  ReportsSumFields?: ReportsSumFieldsResolvers<ContextType>;
  ReportsVarPopFields?: ReportsVarPopFieldsResolvers<ContextType>;
  ReportsVarSampFields?: ReportsVarSampFieldsResolvers<ContextType>;
  ReportsVarianceFields?: ReportsVarianceFieldsResolvers<ContextType>;
  ResourceType?: ResourceTypeResolvers<ContextType>;
  ResourceTypeAggregate?: ResourceTypeAggregateResolvers<ContextType>;
  ResourceTypeAggregateFields?: ResourceTypeAggregateFieldsResolvers<ContextType>;
  ResourceTypeMaxFields?: ResourceTypeMaxFieldsResolvers<ContextType>;
  ResourceTypeMinFields?: ResourceTypeMinFieldsResolvers<ContextType>;
  ResourceTypeMutationResponse?: ResourceTypeMutationResponseResolvers<ContextType>;
  Resources?: ResourcesResolvers<ContextType>;
  ResourcesAggregate?: ResourcesAggregateResolvers<ContextType>;
  ResourcesAggregateFields?: ResourcesAggregateFieldsResolvers<ContextType>;
  ResourcesAvgFields?: ResourcesAvgFieldsResolvers<ContextType>;
  ResourcesMaxFields?: ResourcesMaxFieldsResolvers<ContextType>;
  ResourcesMinFields?: ResourcesMinFieldsResolvers<ContextType>;
  ResourcesMutationResponse?: ResourcesMutationResponseResolvers<ContextType>;
  ResourcesStddevFields?: ResourcesStddevFieldsResolvers<ContextType>;
  ResourcesStddevPopFields?: ResourcesStddevPopFieldsResolvers<ContextType>;
  ResourcesStddevSampFields?: ResourcesStddevSampFieldsResolvers<ContextType>;
  ResourcesSumFields?: ResourcesSumFieldsResolvers<ContextType>;
  ResourcesVarPopFields?: ResourcesVarPopFieldsResolvers<ContextType>;
  ResourcesVarSampFields?: ResourcesVarSampFieldsResolvers<ContextType>;
  ResourcesVarianceFields?: ResourcesVarianceFieldsResolvers<ContextType>;
  ResumeAssessmentOutput?: ResumeAssessmentOutputResolvers<ContextType>;
  RunPlayOutput?: RunPlayOutputResolvers<ContextType>;
  SampleOutput?: SampleOutputResolvers<ContextType>;
  SaveAssessmentOutput?: SaveAssessmentOutputResolvers<ContextType>;
  Schools?: SchoolsResolvers<ContextType>;
  SchoolsAggregate?: SchoolsAggregateResolvers<ContextType>;
  SchoolsAggregateFields?: SchoolsAggregateFieldsResolvers<ContextType>;
  SchoolsMaxFields?: SchoolsMaxFieldsResolvers<ContextType>;
  SchoolsMinFields?: SchoolsMinFieldsResolvers<ContextType>;
  SchoolsMutationResponse?: SchoolsMutationResponseResolvers<ContextType>;
  SeatType?: SeatTypeResolvers<ContextType>;
  SeatTypeAggregate?: SeatTypeAggregateResolvers<ContextType>;
  SeatTypeAggregateFields?: SeatTypeAggregateFieldsResolvers<ContextType>;
  SeatTypeMaxFields?: SeatTypeMaxFieldsResolvers<ContextType>;
  SeatTypeMinFields?: SeatTypeMinFieldsResolvers<ContextType>;
  SeatTypeMutationResponse?: SeatTypeMutationResponseResolvers<ContextType>;
  Seats?: SeatsResolvers<ContextType>;
  SeatsAggregate?: SeatsAggregateResolvers<ContextType>;
  SeatsAggregateFields?: SeatsAggregateFieldsResolvers<ContextType>;
  SeatsMaxFields?: SeatsMaxFieldsResolvers<ContextType>;
  SeatsMinFields?: SeatsMinFieldsResolvers<ContextType>;
  SeatsMutationResponse?: SeatsMutationResponseResolvers<ContextType>;
  SetClassStatusOutput?: SetClassStatusOutputResolvers<ContextType>;
  StartAssessmentOutput?: StartAssessmentOutputResolvers<ContextType>;
  StartPlaybackOutput?: StartPlaybackOutputResolvers<ContextType>;
  StreamMessages?: StreamMessagesResolvers<ContextType>;
  StreamMessagesAggregate?: StreamMessagesAggregateResolvers<ContextType>;
  StreamMessagesAggregateFields?: StreamMessagesAggregateFieldsResolvers<ContextType>;
  StreamMessagesMaxFields?: StreamMessagesMaxFieldsResolvers<ContextType>;
  StreamMessagesMinFields?: StreamMessagesMinFieldsResolvers<ContextType>;
  StreamMessagesMutationResponse?: StreamMessagesMutationResponseResolvers<ContextType>;
  SubmitAndGradeAssessmentOutput?: SubmitAndGradeAssessmentOutputResolvers<ContextType>;
  Subordinates?: SubordinatesResolvers<ContextType>;
  SubordinatesAggregate?: SubordinatesAggregateResolvers<ContextType>;
  SubordinatesAggregateFields?: SubordinatesAggregateFieldsResolvers<ContextType>;
  SubordinatesMaxFields?: SubordinatesMaxFieldsResolvers<ContextType>;
  SubordinatesMinFields?: SubordinatesMinFieldsResolvers<ContextType>;
  SuccessOutput?: SuccessOutputResolvers<ContextType>;
  TagTypeScope?: TagTypeScopeResolvers<ContextType>;
  TagTypeScopeAggregate?: TagTypeScopeAggregateResolvers<ContextType>;
  TagTypeScopeAggregateFields?: TagTypeScopeAggregateFieldsResolvers<ContextType>;
  TagTypeScopeMaxFields?: TagTypeScopeMaxFieldsResolvers<ContextType>;
  TagTypeScopeMinFields?: TagTypeScopeMinFieldsResolvers<ContextType>;
  TagTypeScopeMutationResponse?: TagTypeScopeMutationResponseResolvers<ContextType>;
  TagTypes?: TagTypesResolvers<ContextType>;
  TagTypesAggregate?: TagTypesAggregateResolvers<ContextType>;
  TagTypesAggregateFields?: TagTypesAggregateFieldsResolvers<ContextType>;
  TagTypesMaxFields?: TagTypesMaxFieldsResolvers<ContextType>;
  TagTypesMinFields?: TagTypesMinFieldsResolvers<ContextType>;
  TagTypesMutationResponse?: TagTypesMutationResponseResolvers<ContextType>;
  Tags?: TagsResolvers<ContextType>;
  TagsAggregate?: TagsAggregateResolvers<ContextType>;
  TagsAggregateFields?: TagsAggregateFieldsResolvers<ContextType>;
  TagsMaxFields?: TagsMaxFieldsResolvers<ContextType>;
  TagsMinFields?: TagsMinFieldsResolvers<ContextType>;
  TagsMutationResponse?: TagsMutationResponseResolvers<ContextType>;
  TaskDefinition?: TaskDefinitionResolvers<ContextType>;
  TaskDefinitionAggregate?: TaskDefinitionAggregateResolvers<ContextType>;
  TaskDefinitionAggregateFields?: TaskDefinitionAggregateFieldsResolvers<ContextType>;
  TaskDefinitionMaxFields?: TaskDefinitionMaxFieldsResolvers<ContextType>;
  TaskDefinitionMinFields?: TaskDefinitionMinFieldsResolvers<ContextType>;
  TaskDefinitionMutationResponse?: TaskDefinitionMutationResponseResolvers<ContextType>;
  TaskDefinitionType?: TaskDefinitionTypeResolvers<ContextType>;
  TaskDefinitionTypeAggregate?: TaskDefinitionTypeAggregateResolvers<ContextType>;
  TaskDefinitionTypeAggregateFields?: TaskDefinitionTypeAggregateFieldsResolvers<ContextType>;
  TaskDefinitionTypeMaxFields?: TaskDefinitionTypeMaxFieldsResolvers<ContextType>;
  TaskDefinitionTypeMinFields?: TaskDefinitionTypeMinFieldsResolvers<ContextType>;
  TaskDefinitionTypeMutationResponse?: TaskDefinitionTypeMutationResponseResolvers<ContextType>;
  Tasks?: TasksResolvers<ContextType>;
  TasksAggregate?: TasksAggregateResolvers<ContextType>;
  TasksAggregateFields?: TasksAggregateFieldsResolvers<ContextType>;
  TasksMaxFields?: TasksMaxFieldsResolvers<ContextType>;
  TasksMinFields?: TasksMinFieldsResolvers<ContextType>;
  TasksMutationResponse?: TasksMutationResponseResolvers<ContextType>;
  TeamOutput?: TeamOutputResolvers<ContextType>;
  Teams?: TeamsResolvers<ContextType>;
  TeamsAggregate?: TeamsAggregateResolvers<ContextType>;
  TeamsAggregateFields?: TeamsAggregateFieldsResolvers<ContextType>;
  TeamsMaxFields?: TeamsMaxFieldsResolvers<ContextType>;
  TeamsMinFields?: TeamsMinFieldsResolvers<ContextType>;
  TeamsMutationResponse?: TeamsMutationResponseResolvers<ContextType>;
  TeamsUsers?: TeamsUsersResolvers<ContextType>;
  TeamsUsersAggregate?: TeamsUsersAggregateResolvers<ContextType>;
  TeamsUsersAggregateFields?: TeamsUsersAggregateFieldsResolvers<ContextType>;
  TeamsUsersMaxFields?: TeamsUsersMaxFieldsResolvers<ContextType>;
  TeamsUsersMinFields?: TeamsUsersMinFieldsResolvers<ContextType>;
  TeamsUsersMutationResponse?: TeamsUsersMutationResponseResolvers<ContextType>;
  TimeElapsedLiveClasses?: TimeElapsedLiveClassesResolvers<ContextType>;
  TimeElapsedLiveClassesAggregate?: TimeElapsedLiveClassesAggregateResolvers<ContextType>;
  TimeElapsedLiveClassesAggregateFields?: TimeElapsedLiveClassesAggregateFieldsResolvers<ContextType>;
  TimeElapsedLiveClassesAvgFields?: TimeElapsedLiveClassesAvgFieldsResolvers<ContextType>;
  TimeElapsedLiveClassesMaxFields?: TimeElapsedLiveClassesMaxFieldsResolvers<ContextType>;
  TimeElapsedLiveClassesMinFields?: TimeElapsedLiveClassesMinFieldsResolvers<ContextType>;
  TimeElapsedLiveClassesStddevFields?: TimeElapsedLiveClassesStddevFieldsResolvers<ContextType>;
  TimeElapsedLiveClassesStddevPopFields?: TimeElapsedLiveClassesStddevPopFieldsResolvers<ContextType>;
  TimeElapsedLiveClassesStddevSampFields?: TimeElapsedLiveClassesStddevSampFieldsResolvers<ContextType>;
  TimeElapsedLiveClassesSumFields?: TimeElapsedLiveClassesSumFieldsResolvers<ContextType>;
  TimeElapsedLiveClassesVarPopFields?: TimeElapsedLiveClassesVarPopFieldsResolvers<ContextType>;
  TimeElapsedLiveClassesVarSampFields?: TimeElapsedLiveClassesVarSampFieldsResolvers<ContextType>;
  TimeElapsedLiveClassesVarianceFields?: TimeElapsedLiveClassesVarianceFieldsResolvers<ContextType>;
  TypeformSubmissions?: TypeformSubmissionsResolvers<ContextType>;
  TypeformSubmissionsAggregate?: TypeformSubmissionsAggregateResolvers<ContextType>;
  TypeformSubmissionsAggregateFields?: TypeformSubmissionsAggregateFieldsResolvers<ContextType>;
  TypeformSubmissionsMaxFields?: TypeformSubmissionsMaxFieldsResolvers<ContextType>;
  TypeformSubmissionsMinFields?: TypeformSubmissionsMinFieldsResolvers<ContextType>;
  TypeformSubmissionsMutationResponse?: TypeformSubmissionsMutationResponseResolvers<ContextType>;
  UnenrollOutput?: UnenrollOutputResolvers<ContextType>;
  UpdatePlaybackOutput?: UpdatePlaybackOutputResolvers<ContextType>;
  UserCompletedSurveys?: UserCompletedSurveysResolvers<ContextType>;
  UserCompletedSurveysAggregate?: UserCompletedSurveysAggregateResolvers<ContextType>;
  UserCompletedSurveysAggregateFields?: UserCompletedSurveysAggregateFieldsResolvers<ContextType>;
  UserCompletedSurveysAvgFields?: UserCompletedSurveysAvgFieldsResolvers<ContextType>;
  UserCompletedSurveysMaxFields?: UserCompletedSurveysMaxFieldsResolvers<ContextType>;
  UserCompletedSurveysMinFields?: UserCompletedSurveysMinFieldsResolvers<ContextType>;
  UserCompletedSurveysStddevFields?: UserCompletedSurveysStddevFieldsResolvers<ContextType>;
  UserCompletedSurveysStddevPopFields?: UserCompletedSurveysStddevPopFieldsResolvers<ContextType>;
  UserCompletedSurveysStddevSampFields?: UserCompletedSurveysStddevSampFieldsResolvers<ContextType>;
  UserCompletedSurveysSumFields?: UserCompletedSurveysSumFieldsResolvers<ContextType>;
  UserCompletedSurveysVarPopFields?: UserCompletedSurveysVarPopFieldsResolvers<ContextType>;
  UserCompletedSurveysVarSampFields?: UserCompletedSurveysVarSampFieldsResolvers<ContextType>;
  UserCompletedSurveysVarianceFields?: UserCompletedSurveysVarianceFieldsResolvers<ContextType>;
  UserNumCompletedClasses?: UserNumCompletedClassesResolvers<ContextType>;
  UserNumCompletedClassesAggregate?: UserNumCompletedClassesAggregateResolvers<ContextType>;
  UserNumCompletedClassesAggregateFields?: UserNumCompletedClassesAggregateFieldsResolvers<ContextType>;
  UserNumCompletedClassesAvgFields?: UserNumCompletedClassesAvgFieldsResolvers<ContextType>;
  UserNumCompletedClassesMaxFields?: UserNumCompletedClassesMaxFieldsResolvers<ContextType>;
  UserNumCompletedClassesMinFields?: UserNumCompletedClassesMinFieldsResolvers<ContextType>;
  UserNumCompletedClassesStddevFields?: UserNumCompletedClassesStddevFieldsResolvers<ContextType>;
  UserNumCompletedClassesStddevPopFields?: UserNumCompletedClassesStddevPopFieldsResolvers<ContextType>;
  UserNumCompletedClassesStddevSampFields?: UserNumCompletedClassesStddevSampFieldsResolvers<ContextType>;
  UserNumCompletedClassesSumFields?: UserNumCompletedClassesSumFieldsResolvers<ContextType>;
  UserNumCompletedClassesVarPopFields?: UserNumCompletedClassesVarPopFieldsResolvers<ContextType>;
  UserNumCompletedClassesVarSampFields?: UserNumCompletedClassesVarSampFieldsResolvers<ContextType>;
  UserNumCompletedClassesVarianceFields?: UserNumCompletedClassesVarianceFieldsResolvers<ContextType>;
  UserProfileCaptureOutput?: UserProfileCaptureOutputResolvers<ContextType>;
  UserSessionEmails?: UserSessionEmailsResolvers<ContextType>;
  UserSessionEmailsAggregate?: UserSessionEmailsAggregateResolvers<ContextType>;
  UserSessionEmailsAggregateFields?: UserSessionEmailsAggregateFieldsResolvers<ContextType>;
  UserSessionEmailsMaxFields?: UserSessionEmailsMaxFieldsResolvers<ContextType>;
  UserSessionEmailsMinFields?: UserSessionEmailsMinFieldsResolvers<ContextType>;
  UserSessionEmailsMutationResponse?: UserSessionEmailsMutationResponseResolvers<ContextType>;
  Users?: UsersResolvers<ContextType>;
  UsersAggregate?: UsersAggregateResolvers<ContextType>;
  UsersAggregateFields?: UsersAggregateFieldsResolvers<ContextType>;
  UsersAvgFields?: UsersAvgFieldsResolvers<ContextType>;
  UsersFunctions?: UsersFunctionsResolvers<ContextType>;
  UsersFunctionsAggregate?: UsersFunctionsAggregateResolvers<ContextType>;
  UsersFunctionsAggregateFields?: UsersFunctionsAggregateFieldsResolvers<ContextType>;
  UsersFunctionsMaxFields?: UsersFunctionsMaxFieldsResolvers<ContextType>;
  UsersFunctionsMinFields?: UsersFunctionsMinFieldsResolvers<ContextType>;
  UsersFunctionsMutationResponse?: UsersFunctionsMutationResponseResolvers<ContextType>;
  UsersLengthInFunction?: UsersLengthInFunctionResolvers<ContextType>;
  UsersLengthInFunctionAggregate?: UsersLengthInFunctionAggregateResolvers<ContextType>;
  UsersLengthInFunctionAggregateFields?: UsersLengthInFunctionAggregateFieldsResolvers<ContextType>;
  UsersLengthInFunctionMaxFields?: UsersLengthInFunctionMaxFieldsResolvers<ContextType>;
  UsersLengthInFunctionMinFields?: UsersLengthInFunctionMinFieldsResolvers<ContextType>;
  UsersLengthInFunctionMutationResponse?: UsersLengthInFunctionMutationResponseResolvers<ContextType>;
  UsersMaxFields?: UsersMaxFieldsResolvers<ContextType>;
  UsersMeta?: UsersMetaResolvers<ContextType>;
  UsersMetaAggregate?: UsersMetaAggregateResolvers<ContextType>;
  UsersMetaAggregateFields?: UsersMetaAggregateFieldsResolvers<ContextType>;
  UsersMetaMaxFields?: UsersMetaMaxFieldsResolvers<ContextType>;
  UsersMetaMinFields?: UsersMetaMinFieldsResolvers<ContextType>;
  UsersMetaMutationResponse?: UsersMetaMutationResponseResolvers<ContextType>;
  UsersMinFields?: UsersMinFieldsResolvers<ContextType>;
  UsersMutationResponse?: UsersMutationResponseResolvers<ContextType>;
  UsersStddevFields?: UsersStddevFieldsResolvers<ContextType>;
  UsersStddevPopFields?: UsersStddevPopFieldsResolvers<ContextType>;
  UsersStddevSampFields?: UsersStddevSampFieldsResolvers<ContextType>;
  UsersSumFields?: UsersSumFieldsResolvers<ContextType>;
  UsersVarPopFields?: UsersVarPopFieldsResolvers<ContextType>;
  UsersVarSampFields?: UsersVarSampFieldsResolvers<ContextType>;
  UsersVarianceFields?: UsersVarianceFieldsResolvers<ContextType>;
  ZoomOauthTokens?: ZoomOauthTokensResolvers<ContextType>;
  ZoomOauthTokensAggregate?: ZoomOauthTokensAggregateResolvers<ContextType>;
  ZoomOauthTokensAggregateFields?: ZoomOauthTokensAggregateFieldsResolvers<ContextType>;
  ZoomOauthTokensAvgFields?: ZoomOauthTokensAvgFieldsResolvers<ContextType>;
  ZoomOauthTokensMaxFields?: ZoomOauthTokensMaxFieldsResolvers<ContextType>;
  ZoomOauthTokensMinFields?: ZoomOauthTokensMinFieldsResolvers<ContextType>;
  ZoomOauthTokensMutationResponse?: ZoomOauthTokensMutationResponseResolvers<ContextType>;
  ZoomOauthTokensStddevFields?: ZoomOauthTokensStddevFieldsResolvers<ContextType>;
  ZoomOauthTokensStddevPopFields?: ZoomOauthTokensStddevPopFieldsResolvers<ContextType>;
  ZoomOauthTokensStddevSampFields?: ZoomOauthTokensStddevSampFieldsResolvers<ContextType>;
  ZoomOauthTokensSumFields?: ZoomOauthTokensSumFieldsResolvers<ContextType>;
  ZoomOauthTokensVarPopFields?: ZoomOauthTokensVarPopFieldsResolvers<ContextType>;
  ZoomOauthTokensVarSampFields?: ZoomOauthTokensVarSampFieldsResolvers<ContextType>;
  ZoomOauthTokensVarianceFields?: ZoomOauthTokensVarianceFieldsResolvers<ContextType>;
  bigint?: GraphQLScalarType;
  bpchar?: GraphQLScalarType;
  creditUsage?: CreditUsageResolvers<ContextType>;
  creditUsageByUser?: CreditUsageByUserResolvers<ContextType>;
  date?: GraphQLScalarType;
  float8?: GraphQLScalarType;
  getTeamMembersNew?: GetTeamMembersNewResolvers<ContextType>;
  json?: GraphQLScalarType;
  jsonb?: GraphQLScalarType;
  learner_task?: Learner_TaskResolvers<ContextType>;
  lightningSessionSubmissionsStatus?: LightningSessionSubmissionsStatusResolvers<ContextType>;
  lightningSessionSubmissionsStatusAggregate?: LightningSessionSubmissionsStatusAggregateResolvers<ContextType>;
  lightningSessionSubmissionsStatusAggregateFields?: LightningSessionSubmissionsStatusAggregateFieldsResolvers<ContextType>;
  lightningSessionSubmissionsStatusMaxFields?: LightningSessionSubmissionsStatusMaxFieldsResolvers<ContextType>;
  lightningSessionSubmissionsStatusMinFields?: LightningSessionSubmissionsStatusMinFieldsResolvers<ContextType>;
  lightningSessionSubmissionsStatusMutationResponse?: LightningSessionSubmissionsStatusMutationResponseResolvers<ContextType>;
  mutation_root?: Mutation_RootResolvers<ContextType>;
  numeric?: GraphQLScalarType;
  query_root?: Query_RootResolvers<ContextType>;
  smallint?: GraphQLScalarType;
  subscription_root?: Subscription_RootResolvers<ContextType>;
  teamMember?: TeamMemberResolvers<ContextType>;
  teamMemberPollSubmissions?: TeamMemberPollSubmissionsResolvers<ContextType>;
  team_flat_tree_node?: Team_Flat_Tree_NodeResolvers<ContextType>;
  timestamp?: GraphQLScalarType;
  timestamptz?: GraphQLScalarType;
  userManagedTeamsFlattenedTree?: UserManagedTeamsFlattenedTreeResolvers<ContextType>;
  uuid?: GraphQLScalarType;
};

export type DirectiveResolvers<ContextType = any> = {
  cached?: CachedDirectiveResolver<any, any, ContextType>;
};
