import {Subject, Subscription, from} from 'rxjs';
import {UserPropertiesToSet} from 'lib/types';

const refreshPage = (url?: string) => {
  if (url) {
    window.location.href = url;
  } else {
    window.location.reload();
  }
};

export interface SignUpEventProps extends UserPropertiesToSet {
  userId?: string;
}

export interface SignInEventProps extends UserPropertiesToSet {
  userId?: string;
  signInRedirectUri?: string;
}

type SubSignUpEventFunction = (signUpEventProps: SignUpEventProps) => void;
type SubSignInEventFunction = (signInEventProps: SignInEventProps) => Promise<void>;
type SubSignOutEventFunction = () => void;

/** Sign up events */
const signUpEvent = new Subject<SignUpEventProps>();
export const doSignUpEvent = (args: SignUpEventProps): void => signUpEvent.next(args);
export const subSignUpEvent = (args: SubSignUpEventFunction): Subscription =>
  signUpEvent.subscribe(args);
export const unsubSignUpEvent = (signUpSub: Subscription): void => signUpSub.unsubscribe();

/** Sign in events */
export const signInEvent = new Subject<SignInEventProps>();
export const doSignInEvent = (someArgs: SignInEventProps): void => signInEvent.next(someArgs);

const signInQueue = new Set<SubSignInEventFunction>();

export const subSignInEvent = (args: SubSignInEventFunction): Subscription => {
  signInQueue.add(args);
  return signInEvent.subscribe((p) => {
    from(Promise.all(Array.from<SubSignInEventFunction>(signInQueue).map((x) => x(p)))).subscribe(
      () => {
        refreshPage(p.signInRedirectUri);
      }
    );
  });
};

export const unsubSignInEvent = (
  signInSub: Subscription,
  analyticsSubSignInCallback: SubSignInEventFunction
): void => {
  signInQueue.delete(analyticsSubSignInCallback);
  signInSub.unsubscribe();
};

/** Sign out events */
//TODO: update subSignOutEvent to run the subscription with setSession last since it refreshes the page.
export const signOutEvent = new Subject();
export const doSignOutEvent = (): void => signOutEvent.next({});
export const subSignOutEvent = (args: SubSignOutEventFunction): Subscription =>
  signOutEvent.subscribe(args);
export const unsubSignOutEvent = (signOutSub: Subscription): void => signOutSub.unsubscribe();
