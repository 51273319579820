import React, {FC, createContext, useMemo, useContext, useState, useEffect} from 'react';
import {analyticsWebClient} from 'lib/analyticsWebClient';
import {useRouter} from 'next/router';
import {useSession} from 'context/SessionContext';
import AnalyticsClient from 'lib/types';
import {
  subSignInEvent,
  unsubSignInEvent,
  subSignOutEvent,
  unsubSignOutEvent,
  SignInEventProps
} from 'utils/behaviorEvents/clientAuthBehaviors';
import hsq from 'utils/hubspot';

const AnalyticsWebContext = createContext<AnalyticsClient | undefined>(undefined);

interface AnalyticsWebProviderProps {
  children?: React.ReactNode;
}

export const AnalyticsWebProvider: FC<React.PropsWithChildren<unknown>> = ({
  children
}: AnalyticsWebProviderProps): JSX.Element => {
  const router = useRouter();
  const {data: session} = useSession();
  const [client] = useState<AnalyticsClient>(() => {
    // console.log('analyticsWebClient user', session?.impersonator ?? session?.user);
    // console.log('analyticsWebClient email', session?.impersonator?.email ?? session?.email);

    return new analyticsWebClient(
      session?.impersonator ?? session?.user,
      session?.impersonator?.email ?? session?.email
    );
  });
  useEffect(() => {
    const analyticsSubSignInCallback = async (signInEventProps: SignInEventProps) => {
      const {userId, firstName, lastName, email, joinDate} = signInEventProps;
      const userPropsToSet = {firstName, lastName, email, joinDate};
      if (userId) {
        client.identifyUser({userId, userPropsToSet});
        // Hubspot identify user
        hsq.push(['identify', {email}]);
      }
      return Promise.resolve();
    };
    const signInSub = subSignInEvent(analyticsSubSignInCallback);
    return () => unsubSignInEvent(signInSub, analyticsSubSignInCallback);
  }, [client]);

  useEffect(() => {
    const signOutSub = subSignOutEvent(() => {
      client.destroy();
    });
    return () => unsubSignOutEvent(signOutSub);
  }, [client]);

  useEffect(() => {
    const handleRouteChange = () => client.trackPage({});
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => router.events.off('routeChangeComplete', handleRouteChange);
  }, [client, router.events]);

  return (
    <AnalyticsWebContext.Provider value={client}>
      {useMemo(() => children, [children])}
    </AnalyticsWebContext.Provider>
  );
};

export const useAnalytics = (): AnalyticsClient | undefined => useContext(AnalyticsWebContext);
