export interface RequestParams {
  url: string;
  payload?: Record<string, string | number | boolean>;
  headers?: HeadersInit;
  method?: 'GET' | 'POST' | 'DELETE' | 'PUT';
  signal?: AbortSignal;
}

export default function request(reqParams: RequestParams): Promise<Response> {
  const {payload, url, headers, method, signal} = reqParams;

  const genUrl = (payload: Record<string, string | boolean | number>) => {
    const payloadKeys = Object.keys(payload);
    return payloadKeys.length === 0
      ? url
      : payloadKeys.reduce(
          (cum, index, i) =>
            `${cum}${index}=${payload[index]}${i === payloadKeys.length - 1 ? '' : '&'}`,
          `${url}?`
        );
  };

  if (method === 'GET') {
    return fetch(genUrl(payload || {}), {
      method,
      headers,
      signal
    }).then((resp) => {
      if (!resp.ok) return Promise.reject(resp);
      return resp;
    });
  } else {
    return fetch(url, {
      body: JSON.stringify(payload),
      method,
      headers,
      signal
    }).then((resp) => {
      if (!resp.ok) return Promise.reject(resp);
      return resp;
    });
  }
}
