/***************************************
 **** READ the utils/env/README.md *****
 ***************************************/
import * as publicEnvVars from 'utils/env/public';
import * as serverEnvVars from 'utils/env/server';

class MissingPublicEnvVarException extends Error {
  constructor(message?: string | undefined) {
    super(message);
    if (process.env.NODE_ENV !== 'production')
      console.error('Verify env value for [%s] client side', message);
    this.name = 'MissingPublicEnvVarException';
  }
}

class MissingServerEnvVarException extends Error {
  constructor(message?: string | undefined) {
    super(message);
    // server side - always log
    console.error('Verify env value for [%s]', message);
    this.name = 'MissingServerEnvVarException';
  }
}

function convertToCleanRecord<T>(envObject: T) {
  // freeze the objects - now env vars live as props on an object
  // this prevents setting values.
  return Object.freeze(
    Object.fromEntries(Object.entries(envObject).map(([key, value]) => [key, value ?? '']))
  ) as Record<keyof T, string>;
}

const publicVars: Record<keyof typeof publicEnvVars, string> =
  convertToCleanRecord<typeof publicEnvVars>(publicEnvVars);

const serverVars: Record<keyof typeof serverEnvVars, string> =
  convertToCleanRecord<typeof serverEnvVars>(serverEnvVars);

const handler = <T extends Record<string, string>>(
  Exception: new (message?: string | undefined) =>
    | MissingServerEnvVarException
    | MissingPublicEnvVarException
) => ({
  get(module: T, prop: keyof T extends string ? string : string) {
    // an issue with the way the compiler/hmr works
    // this is mainly a dev environment issue.
    if (prop === '$$typeof') return;

    if (prop in module) {
      try {
        const val = module[prop].valueOf();
        if (val === 'undefined' || val === '') throw Error();
        return val;
      } catch (e) {
        throw new Exception(prop);
      }
    }
    throw new Exception(prop);
  }
});

export const PUBLIC_ENV = new Proxy(
  publicVars,
  handler<typeof publicVars>(MissingPublicEnvVarException)
);
export const SERVER_ENV = new Proxy(
  serverVars,
  handler<typeof serverVars>(MissingServerEnvVarException)
);
