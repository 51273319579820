import moment from 'moment';

export function formatUserJoinDateToDateOnly(
  joinDate: string | number | undefined
): string | undefined {
  if (joinDate) return moment(joinDate).format('YYYY-MM-DD');
}

export function formatInsertInstant(joinDate: string | number | undefined): string | undefined {
  if (joinDate) return moment(joinDate).format('YYYY-MM-DD HH:mm:ss.SSSSSS');
}
