const styles = {
  global: {
    'html, body': {
      bg: 'black',
      color: 'white',
      lineHeight: 'tall'
    },
    a: {
      '&.chakra-button:hover, &.chakra-button:focus': {
        textDecoration: 'none'
      }
    },
    abbr: {
      '&[title]': {
        textDecoration: 'none'
      }
    },
    // want this globally available to work with custom <Link /> component
    // to not have to worry about adding a layer style
    '.active-side-nav': {
      boxShadow: `inset 4px 0 0 0 #1A202C`,
      color: 'gray.900',
      '&:focus': {
        boxShadow: `inset 4px 0 0 0 #1A202C`
      }
    }
  }
};

export default styles;
