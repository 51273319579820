import {DataExportNameEnum} from './dataExports/types';
import {getBaseAppPath} from './routeFactory';

const apiBase = `${getBaseAppPath()}/api`;

/************************************************
 ************************************************
 ******************** Auth **********************
 ************************************************
 ************************************************/
export function googleSignInApi(): string {
  return `${apiBase}/google-sign-in`;
}

export function sessionApi(): string {
  return `${apiBase}/session`;
}

export function signInApi(): string {
  return `${apiBase}/sign-in`;
}

export function signOutApi(): string {
  return `${apiBase}/sign-out`;
}

/************************************************
 ************************************************
 ******************** User **********************
 ************************************************
 ************************************************/
export function userApi(): string {
  return `/user`;
}

export function userUpdateApi(userId: string): string {
  return `${userApi()}/update/${userId}`;
}

export function userWebhooksApi(): string {
  return `${userApi()}/webhooks`;
}

export function userWebhooksBulkImportApi(): string {
  return `${userWebhooksApi()}/bulk-import`;
}

/************************************************
 ************************************************
 ******************** Zoom **********************
 ************************************************
 ************************************************/
export function zoomApi(): string {
  return `/zoom`;
}

export function zoomWebhooksApi(): string {
  return `${zoomApi()}/webhooks`;
}

export function zoomWebhooksRegisterLearnerApi(): string {
  return `${zoomWebhooksApi()}/register-learner`;
}

export function zoomWebhooksSyncApi(): string {
  return `${zoomWebhooksApi()}/sync`;
}

/************************************************
 ************************************************
 ***************** Assessments ******************
 ************************************************
 ************************************************/
export function assessmentsApi(): string {
  return '/assessments';
}

export function assessmentsWebhooksApi(): string {
  return `${assessmentsApi()}/webhooks`;
}

export function assessmentsWebhooksResumeApi(): string {
  return `${assessmentsWebhooksApi()}/resume-assessment`;
}

export function assessmentsWebhooksSaveApi(): string {
  return `${assessmentsWebhooksApi()}/save-assessment`;
}

export function assessmentsWebhooksStartApi(): string {
  return `${assessmentsWebhooksApi()}/start-assessment`;
}

export function assessmentsWebhooksSubmitGradeApi(): string {
  return `${assessmentsWebhooksApi()}/submit-grade-assessment`;
}

/************************************************
 ************************************************
 ******************* Events *********************
 ************************************************
 ************************************************/
export function eventsApi(): string {
  return `/events`;
}

export function eventsWebhooksApi(): string {
  return `${eventsApi()}/webhooks`;
}

export function eventsWebhooksEnrollLearnerApi(): string {
  return `${eventsWebhooksApi()}/enroll-learner`;
}

export function eventsWebhooksSendInviteApi(): string {
  return `${eventsWebhooksApi()}/send-invite`;
}

export function eventsWebhooksWatchRecordingApi(): string {
  return `${eventsWebhooksApi()}/watch-recording`;
}

/************************************************
 ************************************************
 **************** Passwordless ******************
 ************************************************
 ************************************************/
export function passwordlessApi(code: string): string {
  return `/passwordless/${code}`;
}

/************************************************
 ************************************************
 ****************** Resources *******************
 ************************************************
 ************************************************/
export function resourcesApi(): string {
  return `${apiBase}/resources`;
}

export function resourcesFileApi(id: string): string {
  return `${resourcesApi()}/file/${id}`;
}

/************************************************
 ************************************************
 ****************** Reports *********************
 ************************************************
 ************************************************/
export function reportsGenerateEmbedUrlApi(): string {
  return `${apiBase}/reports/generate-embed-url`;
}

export function reportsGenerateDataExportApi(exportName: DataExportNameEnum): string {
  return `${apiBase}/reports/generate-data-export?export_name=${exportName}`;
}

/***********************************************
 ***********************************************
 ****************** Images *********************
 ***********************************************
 ************************************************/
export function imagesImgproxyLoaderApi(): string {
  return `${apiBase}/images/imgproxy-loader`;
}
