import {SetOption} from 'cookies';
import Tokens from 'csrf';

export const tokens = new Tokens();
export const secret = tokens.secretSync();

// Uncomment these lines to generate a secret
// const token = tokens.create(secret);
// console.log({ secret, token });

export const defaultCookieConfig: SetOption = {
  secure:
    process.env.NODE_ENV === 'production' || process.env.ENABLE_SAME_SITE_NONE_COOKIE === 'true',
  sameSite:
    process.env.NODE_ENV === 'production' || process.env.ENABLE_SAME_SITE_NONE_COOKIE === 'true'
      ? 'none'
      : undefined,
  httpOnly: true,
  expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 30)
};
