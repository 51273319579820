import React, {Dispatch, FC, SetStateAction, useEffect} from 'react';
import {subSignOutEvent, unsubSignOutEvent} from 'utils/behaviorEvents/clientAuthBehaviors';
import {signOut} from 'utils/session';
import {ApolloClient} from '@apollo/client';
import {NormalizedCacheObject} from '@apollo/client/cache';
import ISession from '../../types/ISession';
import {googleLogout} from '@react-oauth/google';
import {catalogPath} from 'utils/routeFactory';

type AuthHandlerPropTypes = {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  setSession: Dispatch<SetStateAction<ISession>>;
};

export const AuthHandler: FC<React.PropsWithChildren<AuthHandlerPropTypes>> = ({
  apolloClient,
  setSession
}) => {
  useEffect(() => {
    const signOutSub = subSignOutEvent(() => {
      signOut().then(() => {
        apolloClient.clearStore();
        setSession({status: 'unauthenticated'});
        googleLogout();
        window.location.href = catalogPath().catalog();
      });
    });
    return () => unsubSignOutEvent(signOutSub);
  }, [apolloClient, setSession]);

  return <></>;
};

export default AuthHandler;
