import {buildProxyImagePath} from '@bitpatty/next-image-s3-imgproxy-loader';
import pb, {ParamBuilder} from '@bitpatty/imgproxy-url-builder';
import {ImageLoaderProps} from 'next/legacy/image';
import isRelativeUrl from 'is-relative-url';
import {imagesImgproxyLoaderApi} from 'utils/apiFactory';
import {MakeOptional} from 'generated/graphql';

type IPO = Record<keyof Omit<ParamBuilder, 'clone' | 'build'>, string>;
type OptionalIPO = MakeOptional<IPO, keyof IPO>;
type ImgProxyOptions = MakeOptional<Omit<ImageLoaderProps, 'config'>, 'width' | 'quality'> &
  OptionalIPO;

export function selfImgproxyOptimizer({src, width, quality, ...props}: ImgProxyOptions) {
  // Default images will be served statically from '/images/placeholders/' folder
  if (src.startsWith('/')) return src;

  const s3Scheme = 's3://';
  if (src.startsWith(s3Scheme)) {
    src = src.slice(s3Scheme.length);
  }
  if (isRelativeUrl(src)) {
    let builder = pb();

    if (width) builder = builder.resize({width});
    if (Number.isInteger(quality)) builder = builder.quality(quality ?? 75);
    // Default to 75% because Nextjs does the same https://nextjs.org/docs/api-reference/next/future/image#quality
    else builder.quality(75);

    /* eslint-disable @typescript-eslint/no-explicit-any */
    Object.entries<any>(props).forEach(([prop, value]: [string, any]) => {
      if (typeof builder[prop as keyof ParamBuilder] === 'function') {
        builder = (builder as any)[prop as keyof ParamBuilder](value);
      }
    });
    /* eslint-enable @typescript-eslint/no-explicit-any */

    return buildProxyImagePath(src, {
      endpoint: imagesImgproxyLoaderApi(),
      proxyParams: builder.build()
    });
  }
  return src;
}

export function imgproxyOptimizer(
  props: MakeOptional<Omit<ImageLoaderProps, 'config'>, 'width' | 'quality'>
) {
  return selfImgproxyOptimizer(props as ImgProxyOptions);
}
