import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps
} from '@chakra-ui/react';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {cloneElement, FC, isValidElement, ReactElement, useEffect, useState} from 'react';
import {BehaviorSubject} from 'rxjs';

//  TODO:::
// Implement a priority queue
// modals can then be stacked on and prioritized in how they render

export type ModalsBehaviorType = {
  onClose?: () => void;
  variant?: 'info' | 'success' | 'error' | 'warning';
  modalProps?: Omit<ModalProps, 'children' | 'isOpen' | 'onClose'>;
  contentProps?: ModalContentProps;
  title?: string;
  overlay?: boolean;
  Body?: ReactElement;
  Footer?: ReactElement;
};

export const modals$ = new BehaviorSubject<ModalsBehaviorType | undefined>(undefined);
export const openModal = (modalConfig: ModalsBehaviorType) => modals$.next(modalConfig);
export const closeModal = () => modals$.next(undefined);

const AppModal: FC<React.PropsWithChildren<unknown>> = () => {
  const [modal, updateModal] = useState<ModalsBehaviorType>();

  const {
    contentProps,
    modalProps,
    variant = 'info',
    onClose,
    title,
    overlay = true,
    Body,
    Footer
  } = modal ?? {};

  useEffect(() => {
    const sub = modals$.subscribe((modal) => {
      updateModal(modal);

      if (!modal) onClose?.();
    });

    return () => {
      sub.unsubscribe();
    };
  }, [onClose]);

  return (
    <Modal
      isCentered
      {...modalProps}
      isOpen={!!modal}
      onClose={() => {
        updateModal(undefined);
        if (onClose) onClose();
      }}
    >
      {overlay && <ModalOverlay />}
      <ModalContent
        minWidth="40rem"
        paddingInline="2rem"
        paddingBlock="1rem"
        justifyContent="center"
        alignItems="center"
        display="flex"
        flexDirection="column"
        {...contentProps}
      >
        {title && (
          <ModalHeader display="flex" flexDirection="column" alignItems="center" textAlign="center">
            {variant === 'error' && (
              <FontAwesomeIcon icon={faExclamationCircle} size="3x" color="white" />
            )}
            <Heading
              aria-hidden={!title}
              size="lg"
              mt="1rem"
              textTransform="capitalize"
              fontWeight="600"
              lineHeight="100%"
              letterSpacing={0}
            >
              {title}
            </Heading>
          </ModalHeader>
        )}
        <ModalCloseButton />
        <ModalBody width="100%">{isValidElement(Body) && cloneElement(Body)}</ModalBody>
        {isValidElement(Footer) && <ModalFooter>{cloneElement(Footer)}</ModalFooter>}
      </ModalContent>
    </Modal>
  );
};

export default AppModal;
