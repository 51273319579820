import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Button,
  Text,
  FormControl,
  FormLabel,
  Input,
  Box,
  Link as CLink,
  Checkbox,
  Center,
  Divider,
  useDisclosure,
  Heading
} from '@chakra-ui/react';
import {useState} from 'react';
import request from 'utils/api';
import ErrorModal from '../ErrorModal';
import {Formik, FormikHelpers} from 'formik';
import * as Yup from 'yup';
import {useSignUpModal} from 'context/SignUpModalContext';
import {useSignInModal} from 'context/SignInModalContext';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGoogle} from '@fortawesome/free-brands-svg-icons';
import {doSignUpEvent} from 'utils/behaviorEvents/clientAuthBehaviors';

interface SignUpFormValues {
  email: string;
  hasAcceptedTandC: boolean;
}

export default function SignUpModal(): React.ReactElement {
  const {
    isOpen: isErroModalOpen,
    onOpen: onOpenErrorModal,
    onClose: onCloseErrorModal
  } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const {closeModal, isShowingSignUpModal} = useSignUpModal();
  const {openSignInModal} = useSignInModal();

  const onHandleSubmit: (
    values: SignUpFormValues,
    actions: FormikHelpers<SignUpFormValues>
  ) => Promise<void> = async (values, actions) => {
    const {email} = values;

    setIsLoading(true);

    const payload = {
      email
    };

    try {
      const signUpResponse = await request({
        url: '/api/sign-up',
        method: 'POST',
        payload
      });

      closeModal();
      actions.resetForm({values: {hasAcceptedTandC: true, email: ''}});
      const {userId, firstName, lastName, email, joinDate} = await signUpResponse?.json();
      const userPropsToSet = {firstName, lastName, email, joinDate};
      // TODO - will need to hook this up to a subscribe event when we add sign up functionality
      doSignUpEvent({userId, ...userPropsToSet});
    } catch (exp) {
      onOpenErrorModal();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ErrorModal
        onOpen={onOpenErrorModal}
        onClose={onCloseErrorModal}
        isOpen={isErroModalOpen}
        title="Unable to sign up"
        message="We're sorry, your request was unable to be completed for some reason. Try hitting the back button and restarting the process to see if it fixes the problem."
      />
      <Modal
        isCentered
        onClose={closeModal}
        isOpen={isShowingSignUpModal}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent
          justifyContent="center"
          alignItems="center"
          display="flex"
          flexDirection="column"
          padding="30px"
          maxWidth="550px"
          width="90%"
        >
          <ModalHeader>
            <Heading size="lg" color="gray.700">
              Sign up to SIA
            </Heading>
          </ModalHeader>
          <Box w="100%" px={{sm: '0px', md: '60px'}} py="0px">
            <Formik
              initialValues={{email: '', hasAcceptedTandC: false}}
              validationSchema={Yup.object({
                email: Yup.string().email('Invalid email address').required('Required')
              })}
              onSubmit={onHandleSubmit}
            >
              {(formikProps) => {
                const {
                  values: {email, hasAcceptedTandC},
                  errors,
                  handleChange,
                  handleSubmit
                } = formikProps;

                const isSignUpButtonDisabled = email.length === 0 || !hasAcceptedTandC;

                return (
                  <form onSubmit={handleSubmit}>
                    <FormControl id="email" color="gray.700">
                      <FormLabel fontWeight="500" fontSize="md" lineHeight="24px">
                        Email address
                      </FormLabel>
                      <Input
                        name="email"
                        isInvalid={Boolean(errors.email)}
                        required
                        fontSize="sm"
                        type="email"
                        placeholder="you@yourworkdomain.com"
                        value={email}
                        onChange={handleChange}
                      />
                      {Boolean(errors.email) && (
                        <Text color="red" fontSize="xs" marginTop="5px">
                          {errors.email}
                        </Text>
                      )}
                    </FormControl>
                    <Button
                      disabled={isSignUpButtonDisabled}
                      type="submit"
                      isLoading={isLoading}
                      marginTop={4}
                      width="100%"
                    >
                      Sign Up
                    </Button>
                    <Checkbox
                      paddingY="15px"
                      name="hasAcceptedTandC"
                      checked={hasAcceptedTandC}
                      onChange={handleChange}
                    >
                      <Text fontSize="sm" fontWeight="normal" lineHeight="150%">
                        I agree to the{' '}
                        <CLink href="https://www.salesimpact.io/terms-and-conditions/" isExternal>
                          Terms of Service
                        </CLink>{' '}
                        &{' '}
                        <CLink href="https://www.salesimpact.io/privacy-policy/" isExternal>
                          Privacy Policy
                        </CLink>
                      </Text>
                    </Checkbox>
                  </form>
                );
              }}
            </Formik>

            <Center color="white" position="relative" marginY="8px">
              <Divider bg="gray.300" height="1px" />
              <Text
                position="absolute"
                bg="white"
                color="gray.500"
                fontSize="sm"
                paddingX="20px"
                fontWeight="600"
                lineHeight="20px"
              >
                or sign up with
              </Text>
            </Center>

            <Button
              marginTop="15px"
              fontSize="md"
              fontWeight="600"
              type="submit"
              width="100%"
              leftIcon={<FontAwesomeIcon icon={faGoogle} />}
            >
              Google
            </Button>

            <Center marginTop="16px">
              <Text fontSize="sm">
                Already have an account?{' '}
                <Button
                  variant="link"
                  onClick={() => {
                    closeModal();
                    openSignInModal();
                  }}
                  textDecoration="underline"
                >
                  Sign in instead
                </Button>
              </Text>
            </Center>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
}
