// Check to make sure userId is an allowed string:
// https://posthog.com/docs/integrate/identifying-users
export const isAllowedAnalyticsIdentityId = (userId: string): boolean => {
  const id = userId.toLowerCase();
  if (
    id === 'anonymous' ||
    id === 'guest' ||
    id === 'distinctid' ||
    id === 'distinct_id' ||
    id === 'id' ||
    id === 'not_authenticated' ||
    id === 'email' ||
    id === 'undefined' ||
    id === 'true' ||
    id === 'false' ||
    id === '[object object]' ||
    id === 'nan' ||
    id === 'none' ||
    id === 'null' ||
    id === '0' ||
    id.includes(' ')
  ) {
    //TODO - Set this up in error logging tool
    console.error(
      `UserId: ${userId} is not allowed to be used to identify users in analytics client`
    );
    return false;
  }
  return true;
};
