// disabled because of dynamic typing issue on conditional types
// https://stackoverflow.com/questions/55641731/typescript-conditional-type-complains-type-not-assignable
// https://github.com/Microsoft/TypeScript/issues/13995#issuecomment-363265172
/* eslint-disable @typescript-eslint/no-explicit-any */

import {Url} from 'url';
import {PUBLIC_ENV} from 'utils/env/getter';

/*********************************************
 *********************************************
 ***** Custom Types for route factory ********
 *********************************************
 *********************************************/
export type CustomUrlObject = Url; // & {pathname: string} & {query?: ParsedUrlQueryInput};
export type PathCreationArgs = {absolute?: boolean; asUrl?: boolean};
export type InitReturnType<K extends string, T> = Record<K, (...args: any[]) => GetType<T>>;

export type GetType<T> = T extends {asUrl: true} ? Url : string;

/************************************************
 ************************************************
 **************** route to Page title *************
 ************************************************
 ************************************************/
export const routeToPageTitle = new Map<string, string>([
  ['schedule', 'Schedule'],
  [learnPath({asUrl: true}).schedule().pathname as string, 'Schedule'],
  ['resources', 'Resources'],
  ['certified', 'Certified'],
  ['assessment', 'Get Certified'],
  ['prepare', 'Prepare'],
  ['submission', 'Coach Feedback']
]);

function getArgs(args = {}): PathCreationArgs {
  const {absolute, asUrl} = (args ?? {}) as PathCreationArgs;
  return {absolute, asUrl};
}

/*********************************************
 *********************************************
 *************** Base Path *******************
 *********************************************
 *********************************************/
export function getBaseAppPath(): string {
  // we don't want your crazy today Burt.
  // remove trailing / (if there)
  return PUBLIC_ENV.nextPublicAppHostUrl.trim().replace(/\/$/, '');
}

/*********************************************
 *********************************************
 *************** Entry Path *******************
 *********************************************
 *********************************************/
export function entryPath<T extends PathCreationArgs>(args?: T): InitReturnType<'path', T> {
  const {asUrl} = getArgs(args);

  if (asUrl)
    return {
      path: (): any => ({pathname: '/'})
    };
  return {path: (): any => '/'};
}

/************************************************
 ************************************************
 ******************** Auth **********************
 ************************************************
 ************************************************/
export function auth<T extends Record<string, string>>(): InitReturnType<
  'magicLinkExpired' | 'magicLinkInvalid',
  T
> {
  return {
    magicLinkExpired: (): any => `${getBaseAppPath()}/magic-link-expired`,
    magicLinkInvalid: (): any => `${getBaseAppPath()}/magic-link-invalid`
  };
}

/*********************************************
 *********************************************
 ***************** Catalog *******************
 *********************************************
 *********************************************/
export function catalogPath<T extends PathCreationArgs>(
  args?: T
): InitReturnType<'catalog' | 'catalogQuery', T> {
  const {absolute, asUrl} = getArgs(args);
  const base = [absolute && getBaseAppPath(), '/catalog'].filter(Boolean).join('');

  if (asUrl) {
    return {
      catalog(): any {
        return {pathname: base};
      },
      catalogQuery(query: Record<string, string> = {}): any {
        if (!Object.keys(query ?? {}).length) return this.catalog();
        return {pathname: base, query};
      }
    };
  }

  return {
    catalog: (): any => base,
    catalogQuery(query: Record<string, string> = {}): any {
      const params = new URLSearchParams();
      Object.keys(query).forEach((k) => {
        params.append(k, query[k]);
      });
      return `${base}?${params.toString()}`;
    }
  };
}

/*********************************************
 *********************************************
 ************* Upcoming Cohorts **************
 *********************************************
 *********************************************/
export function upcomingCohorts<T extends PathCreationArgs>(
  args?: T
): InitReturnType<'upcoming', T> {
  const {absolute, asUrl} = getArgs(args);
  const base = [absolute && getBaseAppPath(), '/upcoming'].filter(Boolean).join('');

  if (asUrl) {
    return {
      upcoming(): any {
        return {pathname: base};
      }
    };
  }

  return {
    upcoming: (): any => base
  };
}

/*********************************************
 *********************************************
 ***************** Course ********************
 *********************************************
 *********************************************/
export function coursePath<T extends PathCreationArgs>(
  args?: T
): InitReturnType<'course' | 'courseDetails' | 'sessionDetail', T> {
  const {absolute, asUrl} = getArgs(args);
  const base = [absolute && getBaseAppPath(), '/course'].filter(Boolean).join('');
  const sessionBase = [absolute && getBaseAppPath(), '/session'].filter(Boolean).join('');

  if (asUrl) {
    return {
      course(): any {
        return {pathname: base};
      },
      courseDetails(courseSlug?: string): any {
        if (!courseSlug) return this.course();
        return {pathname: `${base}/[courseSlug]`, query: {courseSlug}};
      },
      sessionDetail(cohortSlug: string): any {
        return {pathname: `${sessionBase}/[cohortSlug]`, query: {cohortSlug: cohortSlug}};
      }
    };
  }
  return {
    course(): any {
      return base;
    },
    courseDetails(slug?: string): any {
      if (!slug) return this.course();
      return `${this.course()}/${slug}`;
    },
    sessionDetail(cohortSlug: string): any {
      return `${sessionBase}/${cohortSlug}`;
    }
  };
}

/*********************************************
 *********************************************
 ***************** My Team *******************
 *********************************************
 *********************************************/
export function managePath<T extends PathCreationArgs>(
  args?: T
): InitReturnType<
  | 'home'
  | 'teams'
  | 'teamMembers'
  | 'pastCoachingSessions'
  | 'pastCoachingSession'
  | 'upcomingCoachingSessions'
  | 'upcomingCoachingSession'
  | 'submissions',
  T
> {
  const {absolute, asUrl} = getArgs(args);
  const base = [absolute && getBaseAppPath(), '/manage'].filter(Boolean).join('');

  if (asUrl) {
    return {
      home(): any {
        return {pathname: `${base}/coaching-sessions/upcoming`};
      },
      teams(): any {
        return {pathname: `${base}/teams`};
      },
      teamMembers(teamId: string): any {
        return {pathname: `${base}/team-members/[teamId]`, query: {teamId}};
      },
      pastCoachingSessions(): any {
        return {pathname: `${base}/coaching-sessions/past`};
      },
      pastCoachingSession(cohortSlug: string): any {
        return {pathname: `${base}/coaching-sessions/past/[cohortSlug]`, query: {cohortSlug}};
      },
      upcomingCoachingSessions(): any {
        return {pathname: `${base}/coaching-sessions/upcoming`};
      },
      upcomingCoachingSession(cohortSlug: string): any {
        return {pathname: `${base}/coaching-sessions/upcoming/[cohortSlug]`, query: {cohortSlug}};
      },
      submissions(cohortId: string, userId: string): any {
        return {
          pathname: `${base}/submissions/[cohort]/[user]`,
          query: {cohortId, userId}
        };
      }
    };
  }
  return {
    home(): any {
      return `${base}/coaching-sessions/upcoming`;
    },
    teams(): any {
      return `${base}/teams`;
    },
    teamMembers(teamId: string): any {
      return `${base}/team-members/${teamId}`;
    },
    pastCoachingSessions(): any {
      return `${base}/coaching-sessions/past`;
    },
    pastCoachingSession(cohortSlug: string): any {
      return `${base}/coaching-sessions/past/${cohortSlug}`;
    },
    upcomingCoachingSessions(): any {
      return `${base}/coaching-sessions/upcoming`;
    },
    upcomingCoachingSession(cohortSlug: string): any {
      return `${base}/coaching-sessions/upcoming/${cohortSlug}`;
    },
    submissions(cohortId: string, userId: string): any {
      return `${base}/submissions/${cohortId}/${userId}`;
    }
  };
}

/*********************************************
 *********************************************
 *************** My Courses *****************
 *********************************************
 *********************************************/
export function learnPath<T extends PathCreationArgs>(
  args?: T
): InitReturnType<
  | 'home'
  | '_cohort'
  | 'certified'
  | 'assessment'
  | 'schedule'
  | 'resources'
  | 'submission'
  | 'prepare'
  | 'unenroll'
  | 'onDemand',
  T
> {
  const {absolute, asUrl} = getArgs(args);
  const base = [absolute && getBaseAppPath(), '/learn'].filter(Boolean).join('');
  const cohortSlugPath = 'course/[cohortSlug]';

  if (asUrl) {
    return {
      home(): any {
        return {pathname: base};
      },
      _cohort(cohort?: string): any {
        if (!cohort) return this.home();
        return {
          pathname: `${base}/${cohortSlugPath}`,
          query: {cohortSlug: cohort}
        };
      },
      schedule(cohort?: string): any {
        if (!cohort) return this.home();
        return {
          pathname: `${base}/${cohortSlugPath}/schedule`,
          query: {cohortSlug: cohort}
        };
      },
      unenroll(cohort?: string): any {
        if (!cohort) return this.home();
        return {
          pathname: `${base}/${cohortSlugPath}/unenroll`,
          query: {cohortSlug: cohort}
        };
      },
      submission(cohort?: string): any {
        if (!cohort) return this.home();
        return {
          pathname: `${base}/${cohortSlugPath}/submission`,
          query: {cohortSlug: cohort}
        };
      },
      resources(cohort?: string): any {
        if (!cohort) return this.home();
        return {
          pathname: `${base}/${cohortSlugPath}/resources`,
          query: {cohortSlug: cohort}
        };
      },
      certified(cohort?: string): any {
        if (!cohort) return this.home();
        return {
          pathname: `${base}/${cohortSlugPath}/certified`,
          query: {cohortSlug: cohort}
        };
      },
      assessment(cohort?: string, id?: string): any {
        if (!cohort) return this.home();
        if (!id) {
          return {
            pathname: `${base}/${cohortSlugPath}/assessment`,
            query: {cohortSlug: cohort}
          };
        }
        return {
          pathname: `${base}/${cohortSlugPath}/assessment/[id]`,
          query: {cohortSlug: cohort, id}
        };
      },
      prepare(cohort?: string): any {
        if (!cohort) return this.home();
        return {
          pathname: `${base}/${cohortSlugPath}/prepare`,
          query: {cohortSlug: cohort}
        };
      },
      onDemand(cohortSlugPath: string): any {
        return {
          pathname: `${base}/${cohortSlugPath}/on-demand`,
          query: {cohortSlugPath}
        };
      }
    };
  }

  return {
    home(): any {
      return base;
    },
    _cohort(cohort?: string): any {
      if (!cohort) return this.home();
      return `${this.home()}/course/${cohort}`;
    },
    resources(cohort?: string): any {
      if (!cohort) return this.home();
      return `${this._cohort(cohort)}/resources`;
    },
    schedule(cohort?: string): any {
      if (!cohort) return this.home();
      return `${this._cohort(cohort)}/schedule`;
    },
    unenroll(cohort?: string): any {
      if (!cohort) return this.home();
      return `${this._cohort(cohort)}/unenroll`;
    },
    submission(cohort?: string): any {
      if (!cohort) return this.home();
      return `${this._cohort(cohort)}/submission`;
    },
    certified(cohort?: string): any {
      if (!cohort) return this._cohort(cohort);
      return `${this._cohort(cohort)}/certified`;
    },
    assessment(cohort?: string, id?: string): any {
      if (!cohort) return this._cohort(cohort);
      if (!id) return `${this._cohort(cohort)}/assessment`;
      return `${this._cohort(cohort)}/assessment/${id}`;
    },
    prepare(cohort?: string): any {
      if (!cohort) return this._cohort(cohort);
      return `${this._cohort(cohort)}/prepare`;
    },
    onDemand(cohort?: string): any {
      if (!cohort) return this._cohort(cohort);
      return `${this._cohort(cohort)}/on-demand`;
    }
  };
}

/*********************************************
 *********************************************
 *************** Login Page ******************
 *********************************************
 *********************************************/

export function loginPath<T extends PathCreationArgs>(args?: T): InitReturnType<'authenticate', T> {
  const {absolute, asUrl} = getArgs(args);
  const base = [absolute && getBaseAppPath(), '/login'].filter(Boolean).join('');

  if (asUrl) {
    return {
      authenticate(redirectUrl?: string): any {
        const query = redirectUrl ? {redirectUrl} : undefined;
        return {pathname: base, query};
      }
    };
  }
  return {
    authenticate(redirectUrl?: string): any {
      const query = redirectUrl ? `?redirectUrl=${redirectUrl}` : '';
      return `${base}${query}`;
    }
  };
}

/*********************************************
 *********************************************
 *************** Join Class ******************
 *********************************************
 *********************************************/

export function joinSessionPath<T extends PathCreationArgs>(args?: T): InitReturnType<'join', T> {
  const {absolute, asUrl} = getArgs(args);
  const base = [absolute && getBaseAppPath(), '/join/session'].filter(Boolean).join('');

  if (asUrl) {
    return {
      join(id: string): any {
        return {pathname: `${base}/[class]`, query: {class: id}};
      }
    };
  }
  return {
    join(id: string): any {
      return `${base}/${id}`;
    }
  };
}

export function joinClassPath<T extends PathCreationArgs>(args?: T): InitReturnType<'join', T> {
  const {absolute, asUrl} = getArgs(args);
  const base = [absolute && getBaseAppPath(), '/join/session'].filter(Boolean).join('');

  if (asUrl) {
    return {
      join(id: string): any {
        return {pathname: `${base}/[class]`, query: {class: id}};
      }
    };
  }
  return {
    join(id: string): any {
      return `${base}/${id}`;
    }
  };
}

export function joinMeetingPath<T extends PathCreationArgs>(
  args?: T
): InitReturnType<'joinMeeting', T> {
  const {absolute, asUrl} = getArgs(args);
  const base = [absolute && getBaseAppPath(), '/join/meeting'].filter(Boolean).join('');

  if (asUrl) {
    return {
      joinMeeting(token: string): any {
        return {pathname: `${base}/[token]`, query: {token}};
      }
    };
  }
  return {
    joinMeeting(token: string): any {
      return `${base}/${token}`;
    }
  };
}

/*********************************************
 *********************************************
 *************** Class Room ******************
 *********************************************
 *********************************************/
export function classRoomPath<T extends PathCreationArgs>(args?: T): InitReturnType<'stats', T> {
  const {absolute, asUrl} = getArgs(args);
  const base = [absolute && getBaseAppPath(), '/class'].filter(Boolean).join('');

  if (asUrl) {
    return {
      stats(classEventId: string, roomName: string, token?: string): any {
        return {
          pathname: `${base}/participants-stats`,
          query: {
            classEventId,
            roomName,
            token
          }
        };
      }
    };
  }
  return {
    stats(classEventId: string, roomName: string, token?: string): any {
      const mutualBase = `${base}/participants-stats?classEventId=${classEventId}&roomName=${roomName}`;
      if (token) {
        return `${mutualBase}&token=${token}`;
      }
      return mutualBase;
    }
  };
}

/*********************************************
 *********************************************
 ***************** Error pages ***************
 *********************************************
 *********************************************/
export function errorPath<T extends Record<string, string>>(): InitReturnType<
  'four04' | '404' | 'notFound' | 'error',
  T
> {
  return {
    four04: (): any => `${getBaseAppPath()}/404`,
    // alias to four04
    404(): any {
      return this.four04();
    },
    notFound: (): any => `${getBaseAppPath()}/not-found`,
    error: (): any => `${getBaseAppPath()}/error`
  };
}

/*********************************************
 *********************************************
 ***************** Contact Us ********************
 *********************************************
 *********************************************/
export function contactUsPath<T extends PathCreationArgs>(
  args?: T
): InitReturnType<'contactUs', T> {
  const {absolute, asUrl} = getArgs(args);
  const base = [absolute && getBaseAppPath(), '/contact-us'].filter(Boolean).join('');

  if (asUrl) {
    return {
      contactUs(): any {
        return {pathname: base};
      }
    };
  }
  return {
    contactUs(): any {
      return base;
    }
  };
}

/*********************************************
 *********************************************
 ***************** Profile Page **************
 *********************************************
 *********************************************/
export function profilePath<T extends PathCreationArgs>(args?: T): InitReturnType<'profile', T> {
  const {absolute, asUrl} = getArgs(args);
  const base = [absolute && getBaseAppPath(), '/profile'].filter(Boolean).join('');

  if (asUrl) {
    return {
      profile(): any {
        return {pathname: base};
      }
    };
  }
  return {
    profile(): any {
      return base;
    }
  };
}

/***************************************
 ***************************************
 *************** Coach *****************
 ***************************************
 ***************************************/
export function coachPath<T extends PathCreationArgs>(
  args?: T
): InitReturnType<'home' | 'submission' | 'prep' | 'onDemand', T> {
  const {absolute, asUrl} = getArgs(args);
  const base = [absolute && getBaseAppPath(), '/coach'].filter(Boolean).join('');

  if (asUrl) {
    return {
      home(): any {
        return {pathname: base};
      },
      submission(id: string): any {
        return {pathname: `${base}/submissions/[id]`, query: {id}};
      },
      prep(id: string): any {
        return {pathname: `${base}/prep/[id]`, query: {id}};
      },
      onDemand(id: string): any {
        return {pathname: `${base}/on-demand/[id]`, query: {id}};
      }
    };
  }

  return {
    home(): any {
      return base;
    },
    submission(id: string): any {
      return `${base}/submissions/${id}`;
    },
    prep(id: string): any {
      return `${base}/prep/${id}`;
    },
    onDemand(id: string): any {
      return `${base}/on-demand/${id}`;
    }
  };
}
