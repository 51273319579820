import crypto from 'crypto';
import {SERVER_ENV} from './env/getter';

const algorithm = 'aes-256-cbc';

export function encrypt(text: string): string {
  const iv = Buffer.from(SERVER_ENV.cryptoIV || '', 'hex');
  const key = Buffer.from(SERVER_ENV.cryptoKey || '', 'hex');
  const cipher = crypto.createCipheriv(SERVER_ENV.cryptoAlgorithm || algorithm, key, iv);
  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return encrypted.toString('hex');
}

export function decrypt(text: string): string {
  const iv = Buffer.from(SERVER_ENV.cryptoIV || '', 'hex');
  const key = Buffer.from(SERVER_ENV.cryptoKey || '', 'hex');
  const encryptedText = Buffer.from(text, 'hex');
  const decipher = crypto.createDecipheriv(algorithm, key, iv);
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
}

export function hashMD5(value: string) {
  return crypto.createHash('md5').update(value).digest('hex');
}
