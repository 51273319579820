//TODO: Implement contextual redirection after successful sign in (e.g. to the profile page)
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Button,
  Text,
  FormControl,
  FormLabel,
  Input,
  Divider,
  useDisclosure,
  Heading,
  ModalBody,
  HStack,
  Flex,
  Center,
  Stack,
  StackItem,
  Box,
  Link as CLink,
  ModalCloseButton,
  VStack
} from '@chakra-ui/react';
import {FormikHelpers, useFormik} from 'formik';
import {useMemo, useState} from 'react';
import request from 'utils/api';
import ErrorModal from '../ErrorModal';
import SignInSuccessModal from '../SignInSuccessModal';
import {useSignInModal} from 'context/SignInModalContext';
import {SignInValidationSchema} from 'utils/ValidationSchemas';
import {CodeResponse, useGoogleLogin} from '@react-oauth/google';
import {HttpError} from 'error';
import {doSignInEvent} from 'utils/behaviorEvents/clientAuthBehaviors';
import Link from 'next/link';
import {imgproxyOptimizer} from 'utils/images/imageOptimization/imgproxyOptimizer';
import Image from 'next/image';

const MARKETING_ITEMS = [
  {
    text: `
      <p>“This was the best training I’ve ever had on the subject.</p>
      <p> My coach made the experience very comfortable. I loved the energy and the open healthy space.”</p>`,
    name: 'BDR at G2',
    image: '/images/logos/g2.webp',
    imageSize: {
      width: 20,
      height: 20
    }
  },
  {
    text: `<p>“I do really like the role plays, especially with my experience pretty much every single time I'm in a breakout room I have Toast people with me. So, it’s nice to see other colleagues in there and hear how they navigate certain conversations or the verbiage they use, especially in a remote setting.”</p>`,
    name: `CSM at Toast`,
    image: '/images/logos/toast.png',
    imageSize: {
      width: 76,
      height: 20
    }
  },
  {
    text: `<p>“I got a meeting out of that and of course it was influenced by one thing my coach told me on handling objection.”</p>`,
    name: `SDR at Playvox`,
    image: '/images/logos/playvox.avif',
    imageSize: {
      width: 20,
      height: 20
    }
  },
  {
    text: `<p>“A lot of us have been enrolled in sales things in the past, and they're usually pretty wishy-washy - there's not a lot of substance. But diving deeper, and having actionable insights is what makes SIA's sessions worth going to.”</p>`,
    name: `BDR at Klaviyo`,
    image: '/images/logos/klaviyo.png',
    imageSize: {
      width: 76,
      height: 20
    }
  }
];

export default function SignInModal(): React.ReactElement {
  const [tempEmail, setTempEmail] = useState('');
  const {
    isOpen: isErrorModalOpen,
    onOpen: onOpenErrorModal,
    onClose: onCloseErrorModal
  } = useDisclosure();
  const {
    isOpen: isSuccessModalOpen,
    onOpen: onOpenSuccessModal,
    onClose: onCloseSuccessModal
  } = useDisclosure();

  const [isLoading, setIsLoading] = useState(false);
  const {closeSignInModal, isShowingSignInModal, signInRedirectUri} = useSignInModal();

  const performSignIn = async (email: string, forceResend = false) => {
    const payload = {
      redirectUri: signInRedirectUri
        ? signInRedirectUri
        : window.location.pathname + window.location.search,
      forceSendLogin: forceResend,
      email
    };

    try {
      setIsLoading(true);
      await request({
        url: '/api/sign-in',
        method: 'POST',
        payload
      });
      closeSignInModal();
      onOpenSuccessModal();
    } catch (exp) {
      onOpenErrorModal();
    } finally {
      setIsLoading(false);
    }
  };

  const onHandleSubmit = async (
    values: {email: string},
    actions: FormikHelpers<{email: string}>
  ) => {
    setTempEmail(values.email);
    await performSignIn(values.email);
    actions.resetForm();
  };

  const onHandleGoogleLogin = async (response: CodeResponse) => {
    if (!response.code) throw new HttpError('Missing google response credential token', 422);

    const payload = {
      googleCode: response.code
    };

    try {
      const googleSignInResponse = await request({
        url: '/api/google-sign-in',
        method: 'POST',
        payload
      });
      closeSignInModal();

      const {userId, firstName, lastName, email, joinDate} = await googleSignInResponse?.json();
      const userPropsToSet = {firstName, lastName, email, joinDate};
      doSignInEvent({userId, ...userPropsToSet, signInRedirectUri});
    } catch (exp) {
      onOpenErrorModal();
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {email: ''},
    validationSchema: SignInValidationSchema,
    onSubmit: onHandleSubmit
  });

  const isSignInButtonDisabled = useMemo(() => {
    return formik.values.email.length === 0 || isLoading;
  }, [formik.values.email, isLoading]);

  const googleLogin = useGoogleLogin({
    onSuccess: onHandleGoogleLogin,
    onError: onOpenErrorModal,
    flow: 'auth-code'
  });

  const selectedMarketingItem = useMemo(() => {
    return MARKETING_ITEMS[Math.floor(Math.random() * MARKETING_ITEMS.length)];
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowingSignInModal]);

  return (
    <>
      <ErrorModal
        onOpen={onOpenErrorModal}
        onClose={onCloseErrorModal}
        isOpen={isErrorModalOpen}
        title="Unable to Sign In"
        message="We’re sorry, we are unable to complete your request. Please reach out to support to access."
      />
      <SignInSuccessModal
        onOpen={onOpenSuccessModal}
        onClose={onCloseSuccessModal}
        isOpen={isSuccessModalOpen}
        title="Check your email"
        email={tempEmail}
        resendFn={performSignIn}
      />
      <Modal
        isCentered
        onClose={closeSignInModal}
        isOpen={isShowingSignInModal}
        motionPreset="slideInBottom"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent
          justifyContent="center"
          alignItems="center"
          display="flex"
          flexDirection="column"
          maxWidth="894px"
          width="95%"
        >
          <ModalCloseButton zIndex={9999} top="10px" right="10px" />
          <ModalBody width="100%" bg="black" padding={0}>
            <Stack direction={{base: 'column', md: 'row'}}>
              <StackItem flex={1}>
                <Box maxW="320px" margin="0 auto" pb="85px">
                  <Box pb="60px" pt="70px">
                    <Heading
                      as="h3"
                      textAlign="center"
                      fontSize="20px"
                      fontWeight="bold"
                      textTransform="none"
                      color="white"
                    >
                      Already have an account?
                    </Heading>
                    <Heading
                      as="h4"
                      textAlign="center"
                      fontSize="16px"
                      fontWeight="400"
                      textTransform="none"
                      color="gray.25"
                    >
                      {' '}
                      Sign In{' '}
                    </Heading>
                  </Box>
                  <form onSubmit={formik.handleSubmit}>
                    <FormControl id="email">
                      <FormLabel fontWeight="600" color="gray.25" fontSize="16px">
                        Email address
                      </FormLabel>
                      <Input
                        borderRadius={0}
                        border="1px solid"
                        borderColor="gray.25"
                        bg="gray.700"
                        isInvalid={(formik.errors.email || '').length !== 0}
                        required
                        fontSize="sm"
                        size="md"
                        type="email"
                        placeholder="you@yourworkdomain.com"
                        value={formik.values.email}
                        onChange={({target}) => formik.setFieldValue('email', target.value, true)}
                      />
                      {Boolean(formik.errors.email) && (
                        <Text color="red" fontSize="xs" marginTop="5px">
                          {formik.errors.email}
                        </Text>
                      )}
                    </FormControl>
                    <Button
                      disabled={isSignInButtonDisabled}
                      opacity={isSignInButtonDisabled ? '0.5' : '1'}
                      type="submit"
                      isLoading={isLoading}
                      marginTop={4}
                      width="100%"
                      maxW="160px"
                      variant="outline"
                    >
                      Send Magic Link
                    </Button>
                  </form>
                  <Center
                    color="white"
                    position="relative"
                    marginTop={4}
                    marginBottom={4}
                    height="1em"
                  >
                    <Divider bg="gray.300" size="1px" />
                    <Text
                      position="absolute"
                      paddingX="20px"
                      textStyle="dividerWithText"
                      color="gray.500"
                      backgroundColor="black"
                      fontSize="12px"
                      fontWeight="500"
                    >
                      or continue with
                    </Text>
                  </Center>
                  <div data-testid="google-sign-in">
                    <Button
                      variant="solid"
                      color="white"
                      backgroundColor="#4285f4"
                      fontWeight="bold"
                      fontFamily="Roboto"
                      padding="1px"
                      borderRadius="2px"
                      width="100%"
                      textTransform="none"
                      _hover={{backgroundColor: '#357ae8'}}
                      onClick={() => googleLogin()}
                    >
                      <HStack spacing={2} justifyContent="space-between" width="100%">
                        <Center
                          width="40px"
                          height="40px"
                          borderRadius="2px"
                          backgroundColor="white"
                        >
                          <picture>
                            <img
                              alt="google logo"
                              width={18}
                              height={18}
                              src="/images/logos/g-logo.png"
                            />
                          </picture>
                        </Center>
                        <Flex grow={1} justifyContent="center">
                          Sign in with Google
                        </Flex>
                      </HStack>
                    </Button>
                  </div>
                </Box>
              </StackItem>
              <StackItem width={{base: '100%', md: '315px'}} background="rgba(32, 33, 38, 0.20)">
                <Box position="relative" paddingX="24px" overflow="hidden">
                  <Image
                    style={{
                      position: 'absolute',
                      left: '0',
                      top: '0',
                      zIndex: 1
                    }}
                    width="315"
                    height="372"
                    src="/images/backgrounds/login-modal-gd@2x.png"
                    loader={imgproxyOptimizer}
                    alt="Quote Icon"
                  />
                  <Box pb={{base: '40px', md: '87px'}} pt="68px" position="relative" zIndex={2}>
                    <Heading
                      as="h3"
                      textAlign="center"
                      fontSize="20px"
                      fontWeight="bold"
                      textTransform="none"
                      color="white"
                    >
                      No account? No problem!
                    </Heading>
                    <Heading
                      as="h4"
                      textAlign="center"
                      fontSize="16px"
                      fontWeight="400"
                      textTransform="none"
                      color="gray.25"
                    >
                      {' '}
                      Try your first session for{' '}
                      <span style={{position: 'relative'}}>
                        <b>free</b>
                        <Image
                          style={{
                            position: 'absolute',
                            left: '0',
                            top: '18px'
                          }}
                          width="36"
                          height="4"
                          src="/images/icons/underline-free.svg"
                          loader={imgproxyOptimizer}
                          alt="Quote Icon"
                        />
                      </span>
                      !{' '}
                    </Heading>
                  </Box>
                  <Box position="relative" zIndex={2}>
                    <Link href="https://www.salesimpact.io/get-started" target="_blank">
                      <Button
                        as={CLink}
                        variant="outline"
                        height="50px"
                        margin="0 auto"
                        maxWidth="240px"
                        width="100%"
                        display="flex"
                      >
                        Get Started For Free!
                      </Button>
                    </Link>
                    <Box pt={{base: '40px', md: '120px'}} position="relative">
                      <Image
                        style={{
                          position: 'absolute',
                          left: '-28px',
                          top: '95px'
                        }}
                        width="72"
                        height="59"
                        src="/images/icons/quotes.svg"
                        loader={imgproxyOptimizer}
                        alt="Quote Icon"
                      />
                      <Image
                        style={{
                          position: 'absolute',
                          right: '-65px',
                          bottom: '-45px',
                          rotate: '180deg'
                        }}
                        width="194"
                        height="159"
                        src="/images/icons/quotes.svg"
                        loader={imgproxyOptimizer}
                        alt="Quote Icon"
                      />
                      <Heading
                        as="h5"
                        fontSize="14px"
                        fontWeight="600"
                        textTransform="none"
                        color="gray.50"
                      >
                        What people are saying
                      </Heading>

                      <VStack
                        className="login-marketing-text"
                        fontSize="16px"
                        color="gray.25"
                        lineHeight="20px"
                        fontWeight="600"
                      >
                        <div dangerouslySetInnerHTML={{__html: selectedMarketingItem.text}} />
                      </VStack>
                      <HStack spacing={2}>
                        <Image
                          {...selectedMarketingItem.imageSize}
                          src={selectedMarketingItem.image}
                          loader={imgproxyOptimizer}
                          alt="Quote Icon"
                        />
                        <Text fontSize="12px" fontWeight="500" py="24px" fontStyle="italic">
                          <div dangerouslySetInnerHTML={{__html: selectedMarketingItem.name}} />
                        </Text>
                      </HStack>
                    </Box>
                  </Box>
                </Box>
              </StackItem>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
