import {SystemStyleObject} from '@chakra-ui/react';

const layerStyles: Record<string, SystemStyleObject> = {
  'inner-page': {
    ml: 'auto',
    mr: 'auto',
    bgColor: '#0F1015'
  },
  'gray800-box': {
    color: '#F6F7FF',
    bgColor: '#202126',
    borderRadius: 'calc(0.375 * 1rem)'
  },
  'gray-header': {
    color: 'white',
    bgColor: 'gray.800',
    colorScheme: 'gray',
    fontWeight: 'bold',
    height: '3.5rem',
    padding: '0 4rem',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  'cohort-type': {
    fontSize: '.75rem'
  },
  'side-nav-link': {
    color: '#D9D9D9',
    display: 'block',
    fontWeight: '400',
    lineHeight: '100%',
    transition: 'all 100ms ease-in-out',
    width: '100%',
    '&.indent': {
      padding: '0rem 0.625rem'
    },
    '&.active': {
      fontWeight: '600'
    },
    '&.active.indent': {
      borderLeft: '4px solid #E2FE62'
    }
  },
  'course-tile': {
    maxWidth: '21.5rem',
    borderRadius: '0.5rem',
    boxShadow: 'md'
  },
  'course-tile-image': {
    boxSize: '100%',
    borderTopRightRadius: '0.5rem',
    borderTopLeftRadius: '0.5rem'
  },
  'schedule-accordion-button': {
    colorScheme: 'gray',
    borderRadius: '0.5rem',
    padding: '1rem 1.5rem',
    '&:hover, &:focus': {
      backgroundColor: 'gray.800'
    }
  },
  'schedule-accordion-item': {
    border: '1px solid rgba(255,255,255,0.08)',
    borderRadius: '0.5rem',
    bg: '#202126'
  },
  'schedule-accordion-expanded': {
    borderRadius: '0.5rem 0.5rem 0 0'
  },
  'schedule-action-button': {
    border: '1px solid rgba(255,255,255,0.08)',
    textTransform: 'capitalize',
    fontWeight: '400',
    width: '100%',
    fontSize: '14px'
  },
  'accordion-inner-box': {
    borderRadius: '0.5rem',
    boxShadow: 'base',
    padding: '1.5rem'
  },
  'accordion-panel': {
    padding: '0.25rem 1.5rem 1.5rem 1.5rem'
  },
  tag: {
    colorScheme: 'brand',
    backgroundColor: 'brand.100',
    borderRadius: 'calc(0.375 * 1rem)',
    padding: '0px calc(0.375 * 1rem)'
  },
  'button-link': {
    textDecoration: 'none',
    '&:hover, &:focus': {
      textDecoration: 'none'
    }
  },
  'outline-gray': {
    background: 'transparent',
    border: '1px solid ',
    borderColor: 'white',
    opacity: 0.5,

    '&:hover, &:focus, &:active': {
      background: 'lime.200',
      color: '#0E1015',
      borderColor: 'lime.200',
      opacity: 1
    }
  },
  truncate: {
    '--truncate-lines': '3',
    WebkitLineClamp: 'var(--truncate-lines)',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  },
  upcomingCohortWrapper: {
    borderTop: '1px solid #202126',
    minHeight: '112px',
    padding: '24px',
    margin: '0 !important',
    width: '100%',
    '&:hover, &:focus': {
      cursor: 'pointer',
      background: '#202126'
    },
    '&:last-of-type': {
      borderBottom: '1px solid #202126'
    }
  },
  upcomingDate: {
    margin: '0 !important',
    lineHeight: 1,
    fontFamily: 'Clash Display, sans-serif'
  },
  upcomingSchoolLabel: {
    fontFamily: 'Clash Display, sans-serif'
  },
  upcomingAvatar: {
    marginLeft: '-8px !important',
    fontFamily: 'Clash Display, sans-serif'
  },
  strChatWrapper: {
    position: 'relative',
    background: '#202126',
    border: '1px solid rgba(255, 255, 255, 0.12)',
    width: '100%'
  },
  strChatDropzoneContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    left: '0',
    top: '0',
    zIndex: '5',
    background: '#151617',
    border: '1px dashed #202126',
    color: '#ccc'
  }

  /*
  To set a custom value for --truncate-lines in Chakra component add:
  sx={{'--truncate-lines': '1'}}
  */
};
export default layerStyles;
